import axios from "axios";
import useSWR from "swr";
import authHeader from "../services/auth-header";
import { useSelector } from "react-redux";

export const SettingsSWR = () => {
    const { user: currentUser } = useSelector((state) => state.auth);

    const fetcher = (url) =>
    axios
      .get(url, { headers: authHeader() })
      .then((res) => res.data);

    const userID = currentUser && currentUser.data ? currentUser.data.profile.id : '';

    const { data, error } = useSWR(`/settings/${userID}`,fetcher);
    if (error) console.log(error);
    if (data && data.type === 'success'){
      return data && data.data ? data.data : null;
    } 

  return null
}