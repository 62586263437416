import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getValueFirstStrategy, modifyValueFirstStrategy } from "../../state/features/ValueFirstStrategySlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import sum from "../../utils/sum";

export default function ValueFirstStrategyDevelopmentContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        ValueFirstStrategyC11 : '',
        ValueFirstStrategyE11 : '',
        ValueFirstStrategyG11 : '',
        ValueFirstStrategyH11 : '',
        ValueFirstStrategyI11 : '',
        ValueFirstStrategyJ11 : '',
        ValueFirstStrategyK11 : '',
        ValueFirstStrategyL11 : '',
        ValueFirstStrategyC12 : '',
        ValueFirstStrategyE12 : '',
        ValueFirstStrategyG12 : '',
        ValueFirstStrategyH12 : '',
        ValueFirstStrategyI12 : '',
        ValueFirstStrategyJ12 : '',
        ValueFirstStrategyK12 : '',
        ValueFirstStrategyL12 : '',
        ValueFirstStrategyC13 : '',
        ValueFirstStrategyE13 : '',
        ValueFirstStrategyG13 : '',
        ValueFirstStrategyH13 : '',
        ValueFirstStrategyI13 : '',
        ValueFirstStrategyJ13 : '',
        ValueFirstStrategyK13 : '',
        ValueFirstStrategyL13 : '',
        ValueFirstStrategyC14 : '',
        ValueFirstStrategyE14 : '',
        ValueFirstStrategyG14 : '',
        ValueFirstStrategyH14 : '',
        ValueFirstStrategyI14 : '',
        ValueFirstStrategyJ14 : '',
        ValueFirstStrategyK14 : '',
        ValueFirstStrategyL14 : '',
        ValueFirstStrategyC15 : '',
        ValueFirstStrategyE15 : '',
        ValueFirstStrategyG15 : '',
        ValueFirstStrategyH15 : '',
        ValueFirstStrategyI15 : '',
        ValueFirstStrategyJ15 : '',
        ValueFirstStrategyK15 : '',
        ValueFirstStrategyL15 : '',
        ValueFirstStrategyC16 : '',
        ValueFirstStrategyE16 : '',
        ValueFirstStrategyG16 : '',
        ValueFirstStrategyH16 : '',
        ValueFirstStrategyI16 : '',
        ValueFirstStrategyJ16 : '',
        ValueFirstStrategyK16 : '',
        ValueFirstStrategyL16 : '',
        ValueFirstStrategyC17 : '',
        ValueFirstStrategyE17 : '',
        ValueFirstStrategyG17 : '',
        ValueFirstStrategyH17 : '',
        ValueFirstStrategyI17 : '',
        ValueFirstStrategyJ17 : '',
        ValueFirstStrategyK17 : '',
        ValueFirstStrategyL17 : '',
        ValueFirstStrategyC22 : '',
        ValueFirstStrategyE22 : '',
        ValueFirstStrategyG22 : '',
        ValueFirstStrategyH22 : '',
        ValueFirstStrategyI22 : '',
        ValueFirstStrategyJ22 : '',
        ValueFirstStrategyK22 : '',
        ValueFirstStrategyL22 : '',
        ValueFirstStrategyC23 : '',
        ValueFirstStrategyE23 : '',
        ValueFirstStrategyG23 : '',
        ValueFirstStrategyH23 : '',
        ValueFirstStrategyI23 : '',
        ValueFirstStrategyJ23 : '',
        ValueFirstStrategyK23 : '',
        ValueFirstStrategyL23 : '',
        ValueFirstStrategyC24 : '',
        ValueFirstStrategyE24 : '',
        ValueFirstStrategyG24 : '',
        ValueFirstStrategyH24 : '',
        ValueFirstStrategyI24 : '',
        ValueFirstStrategyJ24 : '',
        ValueFirstStrategyK24 : '',
        ValueFirstStrategyL24 : '',
        ValueFirstStrategyC25 : '',
        ValueFirstStrategyE25 : '',
        ValueFirstStrategyG25 : '',
        ValueFirstStrategyH25 : '',
        ValueFirstStrategyI25 : '',
        ValueFirstStrategyJ25 : '',
        ValueFirstStrategyK25 : '',
        ValueFirstStrategyL25 : '',
        ValueFirstStrategyC26 : '',
        ValueFirstStrategyE26 : '',
        ValueFirstStrategyG26 : '',
        ValueFirstStrategyH26 : '',
        ValueFirstStrategyI26 : '',
        ValueFirstStrategyJ26 : '',
        ValueFirstStrategyK26 : '',
        ValueFirstStrategyL26 : '',
        ValueFirstStrategyC27 : '',
        ValueFirstStrategyE27 : '',
        ValueFirstStrategyG27 : '',
        ValueFirstStrategyH27 : '',
        ValueFirstStrategyI27 : '',
        ValueFirstStrategyJ27 : '',
        ValueFirstStrategyK27 : '',
        ValueFirstStrategyL27 : '',
        ValueFirstStrategyC28 : '',
        ValueFirstStrategyE28 : '',
        ValueFirstStrategyG28 : '',
        ValueFirstStrategyH28 : '',
        ValueFirstStrategyI28 : '',
        ValueFirstStrategyJ28 : '',
        ValueFirstStrategyK28 : '',
        ValueFirstStrategyL28 : '',
        ValueFirstStrategyC35 : '',
        ValueFirstStrategyF35 : 0,
        ValueFirstStrategyG35 : 0,
        ValueFirstStrategyH35 : 0,
        ValueFirstStrategyI35 : 0,
        ValueFirstStrategyJ35 : 0,
        ValueFirstStrategyK35 : 0,
        ValueFirstStrategyL35 : 0,
        ValueFirstStrategyM35 : 0,
        ValueFirstStrategyN35 : 0,
        ValueFirstStrategyO35 : 0,
        ValueFirstStrategyP35 : 0,
        ValueFirstStrategyQ35 : 0,
        ValueFirstStrategyR35 : 0,
        ValueFirstStrategyC36 : '',
        ValueFirstStrategyF36 : 0,
        ValueFirstStrategyG36 : 0,
        ValueFirstStrategyH36 : 0,
        ValueFirstStrategyI36 : 0,
        ValueFirstStrategyJ36 : 0,
        ValueFirstStrategyK36 : 0,
        ValueFirstStrategyL36 : 0,
        ValueFirstStrategyM36 : 0,
        ValueFirstStrategyN36 : 0,
        ValueFirstStrategyO36 : 0,
        ValueFirstStrategyP36 : 0,
        ValueFirstStrategyQ36 : 0,
        ValueFirstStrategyR36 : 0,
        ValueFirstStrategyC37 : '',
        ValueFirstStrategyF37 : 0,
        ValueFirstStrategyG37 : 0,
        ValueFirstStrategyH37 : 0,
        ValueFirstStrategyI37 : 0,
        ValueFirstStrategyJ37 : 0,
        ValueFirstStrategyK37 : 0,
        ValueFirstStrategyL37 : 0,
        ValueFirstStrategyM37 : 0,
        ValueFirstStrategyN37 : 0,
        ValueFirstStrategyO37 : 0,
        ValueFirstStrategyP37 : 0,
        ValueFirstStrategyQ37 : 0,
        ValueFirstStrategyR37 : 0,
        ValueFirstStrategyC38 : '',
        ValueFirstStrategyF38 : 0,
        ValueFirstStrategyG38 : 0,
        ValueFirstStrategyH38 : 0,
        ValueFirstStrategyI38 : 0,
        ValueFirstStrategyJ38 : 0,
        ValueFirstStrategyK38 : 0,
        ValueFirstStrategyL38 : 0,
        ValueFirstStrategyM38 : 0,
        ValueFirstStrategyN38 : 0,
        ValueFirstStrategyO38 : 0,
        ValueFirstStrategyP38 : 0,
        ValueFirstStrategyQ38 : 0,
        ValueFirstStrategyR38 : 0,
        ValueFirstStrategyC39 : '',
        ValueFirstStrategyF39 : 0,
        ValueFirstStrategyG39 : 0,
        ValueFirstStrategyH39 : 0,
        ValueFirstStrategyI39 : 0,
        ValueFirstStrategyJ39 : 0,
        ValueFirstStrategyK39 : 0,
        ValueFirstStrategyL39 : 0,
        ValueFirstStrategyM39 : 0,
        ValueFirstStrategyN39 : 0,
        ValueFirstStrategyO39 : 0,
        ValueFirstStrategyP39 : 0,
        ValueFirstStrategyQ39 : 0,
        ValueFirstStrategyR39 : 0,
        ValueFirstStrategyS35 : 0,
        ValueFirstStrategyS36 : 0,
        ValueFirstStrategyS37 : 0,
        ValueFirstStrategyS38 : 0,
        ValueFirstStrategyS39 : 0,
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getValueFirstStrategy({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.revenueGrowth)
            if(response!= undefined  && response.data.revenueGrowth){
                let revenueGrowth = response.data.revenueGrowth[0];
                console.log(revenueGrowth)
                delete revenueGrowth.id;
                delete revenueGrowth.user_id;
                delete revenueGrowth.created_at;
                delete revenueGrowth.updated_at;
                const newObj = { ...Inputs }
                for (const key in revenueGrowth) {
                    if (revenueGrowth.hasOwnProperty(key)) {
                        if(revenueGrowth[key])
                        newObj[key] = revenueGrowth[key]
                    }
                }
                newObj['ValueFirstStrategyS35'] = sum(newObj['ValueFirstStrategyF35'], newObj['ValueFirstStrategyG35'], newObj['ValueFirstStrategyH35'], newObj['ValueFirstStrategyI35'], newObj['ValueFirstStrategyJ35'], newObj['ValueFirstStrategyK35'], newObj['ValueFirstStrategyL35'], newObj['ValueFirstStrategyM35'], newObj['ValueFirstStrategyN35'], newObj['ValueFirstStrategyO35'], newObj['ValueFirstStrategyP35'], newObj['ValueFirstStrategyQ35'], newObj['ValueFirstStrategyR35']).toFixed(2);
                newObj['ValueFirstStrategyS36'] = sum(newObj['ValueFirstStrategyF36'], newObj['ValueFirstStrategyG36'], newObj['ValueFirstStrategyH36'], newObj['ValueFirstStrategyI36'], newObj['ValueFirstStrategyJ36'], newObj['ValueFirstStrategyK36'], newObj['ValueFirstStrategyL36'], newObj['ValueFirstStrategyM36'], newObj['ValueFirstStrategyN36'], newObj['ValueFirstStrategyO36'], newObj['ValueFirstStrategyP36'], newObj['ValueFirstStrategyQ36'], newObj['ValueFirstStrategyR36']).toFixed(2);
                newObj['ValueFirstStrategyS37'] = sum(newObj['ValueFirstStrategyF37'], newObj['ValueFirstStrategyG37'], newObj['ValueFirstStrategyH37'], newObj['ValueFirstStrategyI37'], newObj['ValueFirstStrategyJ37'], newObj['ValueFirstStrategyK37'], newObj['ValueFirstStrategyL37'], newObj['ValueFirstStrategyM37'], newObj['ValueFirstStrategyN37'], newObj['ValueFirstStrategyO37'], newObj['ValueFirstStrategyP37'], newObj['ValueFirstStrategyQ37'], newObj['ValueFirstStrategyR37']).toFixed(2);
                newObj['ValueFirstStrategyS38'] = sum(newObj['ValueFirstStrategyF38'], newObj['ValueFirstStrategyG38'], newObj['ValueFirstStrategyH38'], newObj['ValueFirstStrategyI38'], newObj['ValueFirstStrategyJ38'], newObj['ValueFirstStrategyK38'], newObj['ValueFirstStrategyL38'], newObj['ValueFirstStrategyM38'], newObj['ValueFirstStrategyN38'], newObj['ValueFirstStrategyO38'], newObj['ValueFirstStrategyP38'], newObj['ValueFirstStrategyQ38'], newObj['ValueFirstStrategyR38']).toFixed(2);
                newObj['ValueFirstStrategyS39'] = sum(newObj['ValueFirstStrategyF39'], newObj['ValueFirstStrategyG39'], newObj['ValueFirstStrategyH39'], newObj['ValueFirstStrategyI39'], newObj['ValueFirstStrategyJ39'], newObj['ValueFirstStrategyK39'], newObj['ValueFirstStrategyL39'], newObj['ValueFirstStrategyM39'], newObj['ValueFirstStrategyN39'], newObj['ValueFirstStrategyO39'], newObj['ValueFirstStrategyP39'], newObj['ValueFirstStrategyQ39'], newObj['ValueFirstStrategyR39']).toFixed(2);
                
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                let thenum = event.target.id.match(/\d+/)[0];
                let thestr = event.target.id.replace(thenum,'');
                let overallScore = 0;
                if(thestr != 'ValueFirstStrategyC'){
                    if(thestr == 'ValueFirstStrategyF'){
                        overallScore = sum(event.target.value, Inputs['ValueFirstStrategyG'+thenum], Inputs['ValueFirstStrategyH'+thenum], Inputs['ValueFirstStrategyI'+thenum], Inputs['ValueFirstStrategyJ'+thenum], Inputs['ValueFirstStrategyK'+thenum], Inputs['ValueFirstStrategyL'+thenum], Inputs['ValueFirstStrategyM'+thenum], Inputs['ValueFirstStrategyN'+thenum], Inputs['ValueFirstStrategyO'+thenum], Inputs['ValueFirstStrategyP'+thenum], Inputs['ValueFirstStrategyQ'+thenum], Inputs['ValueFirstStrategyR'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'ValueFirstStrategyG'){
                        overallScore = sum(event.target.value, Inputs['ValueFirstStrategyF'+thenum], Inputs['ValueFirstStrategyH'+thenum], Inputs['ValueFirstStrategyI'+thenum], Inputs['ValueFirstStrategyJ'+thenum], Inputs['ValueFirstStrategyK'+thenum], Inputs['ValueFirstStrategyL'+thenum], Inputs['ValueFirstStrategyM'+thenum], Inputs['ValueFirstStrategyN'+thenum], Inputs['ValueFirstStrategyO'+thenum], Inputs['ValueFirstStrategyP'+thenum], Inputs['ValueFirstStrategyQ'+thenum], Inputs['ValueFirstStrategyR'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'ValueFirstStrategyH'){
                        overallScore = sum(event.target.value, Inputs['ValueFirstStrategyF'+thenum], Inputs['ValueFirstStrategyG'+thenum], Inputs['ValueFirstStrategyI'+thenum], Inputs['ValueFirstStrategyJ'+thenum], Inputs['ValueFirstStrategyK'+thenum], Inputs['ValueFirstStrategyL'+thenum], Inputs['ValueFirstStrategyM'+thenum], Inputs['ValueFirstStrategyN'+thenum], Inputs['ValueFirstStrategyO'+thenum], Inputs['ValueFirstStrategyP'+thenum], Inputs['ValueFirstStrategyQ'+thenum], Inputs['ValueFirstStrategyR'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'ValueFirstStrategyI'){
                        overallScore = sum(event.target.value, Inputs['ValueFirstStrategyF'+thenum], Inputs['ValueFirstStrategyG'+thenum], Inputs['ValueFirstStrategyH'+thenum], Inputs['ValueFirstStrategyJ'+thenum], Inputs['ValueFirstStrategyK'+thenum], Inputs['ValueFirstStrategyL'+thenum], Inputs['ValueFirstStrategyM'+thenum], Inputs['ValueFirstStrategyN'+thenum], Inputs['ValueFirstStrategyO'+thenum], Inputs['ValueFirstStrategyP'+thenum], Inputs['ValueFirstStrategyQ'+thenum], Inputs['ValueFirstStrategyR'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'ValueFirstStrategyJ'){
                        overallScore = sum(event.target.value, Inputs['ValueFirstStrategyF'+thenum], Inputs['ValueFirstStrategyG'+thenum], Inputs['ValueFirstStrategyH'+thenum], Inputs['ValueFirstStrategyI'+thenum], Inputs['ValueFirstStrategyK'+thenum], Inputs['ValueFirstStrategyL'+thenum], Inputs['ValueFirstStrategyM'+thenum], Inputs['ValueFirstStrategyN'+thenum], Inputs['ValueFirstStrategyO'+thenum], Inputs['ValueFirstStrategyP'+thenum], Inputs['ValueFirstStrategyQ'+thenum], Inputs['ValueFirstStrategyR'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'ValueFirstStrategyK'){
                        overallScore = sum(event.target.value, Inputs['ValueFirstStrategyF'+thenum], Inputs['ValueFirstStrategyG'+thenum], Inputs['ValueFirstStrategyH'+thenum], Inputs['ValueFirstStrategyI'+thenum], Inputs['ValueFirstStrategyJ'+thenum], Inputs['ValueFirstStrategyL'+thenum], Inputs['ValueFirstStrategyM'+thenum], Inputs['ValueFirstStrategyN'+thenum], Inputs['ValueFirstStrategyO'+thenum], Inputs['ValueFirstStrategyP'+thenum], Inputs['ValueFirstStrategyQ'+thenum], Inputs['ValueFirstStrategyR'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'ValueFirstStrategyL'){
                        overallScore = sum(event.target.value, Inputs['ValueFirstStrategyF'+thenum], Inputs['ValueFirstStrategyG'+thenum], Inputs['ValueFirstStrategyH'+thenum], Inputs['ValueFirstStrategyI'+thenum], Inputs['ValueFirstStrategyJ'+thenum], Inputs['ValueFirstStrategyK'+thenum], Inputs['ValueFirstStrategyM'+thenum], Inputs['ValueFirstStrategyN'+thenum], Inputs['ValueFirstStrategyO'+thenum], Inputs['ValueFirstStrategyP'+thenum], Inputs['ValueFirstStrategyQ'+thenum], Inputs['ValueFirstStrategyR'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'ValueFirstStrategyM'){
                        overallScore = sum(event.target.value, Inputs['ValueFirstStrategyF'+thenum], Inputs['ValueFirstStrategyG'+thenum], Inputs['ValueFirstStrategyH'+thenum], Inputs['ValueFirstStrategyI'+thenum], Inputs['ValueFirstStrategyJ'+thenum], Inputs['ValueFirstStrategyK'+thenum], Inputs['ValueFirstStrategyL'+thenum], Inputs['ValueFirstStrategyN'+thenum], Inputs['ValueFirstStrategyO'+thenum], Inputs['ValueFirstStrategyP'+thenum], Inputs['ValueFirstStrategyQ'+thenum], Inputs['ValueFirstStrategyR'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'ValueFirstStrategyN'){
                        overallScore = sum(event.target.value, Inputs['ValueFirstStrategyF'+thenum], Inputs['ValueFirstStrategyG'+thenum], Inputs['ValueFirstStrategyH'+thenum], Inputs['ValueFirstStrategyI'+thenum], Inputs['ValueFirstStrategyJ'+thenum], Inputs['ValueFirstStrategyK'+thenum], Inputs['ValueFirstStrategyL'+thenum], Inputs['ValueFirstStrategyM'+thenum], Inputs['ValueFirstStrategyO'+thenum], Inputs['ValueFirstStrategyP'+thenum], Inputs['ValueFirstStrategyQ'+thenum], Inputs['ValueFirstStrategyR'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'ValueFirstStrategyO'){
                        overallScore = sum(event.target.value, Inputs['ValueFirstStrategyF'+thenum], Inputs['ValueFirstStrategyG'+thenum], Inputs['ValueFirstStrategyH'+thenum], Inputs['ValueFirstStrategyI'+thenum], Inputs['ValueFirstStrategyJ'+thenum], Inputs['ValueFirstStrategyK'+thenum], Inputs['ValueFirstStrategyL'+thenum], Inputs['ValueFirstStrategyM'+thenum], Inputs['ValueFirstStrategyN'+thenum],  Inputs['ValueFirstStrategyP'+thenum], Inputs['ValueFirstStrategyQ'+thenum], Inputs['ValueFirstStrategyR'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'ValueFirstStrategyP'){
                        overallScore = sum(event.target.value, Inputs['ValueFirstStrategyF'+thenum], Inputs['ValueFirstStrategyG'+thenum], Inputs['ValueFirstStrategyH'+thenum], Inputs['ValueFirstStrategyI'+thenum], Inputs['ValueFirstStrategyJ'+thenum], Inputs['ValueFirstStrategyK'+thenum], Inputs['ValueFirstStrategyL'+thenum], Inputs['ValueFirstStrategyM'+thenum], Inputs['ValueFirstStrategyN'+thenum], Inputs['ValueFirstStrategyO'+thenum], Inputs['ValueFirstStrategyQ'+thenum], Inputs['ValueFirstStrategyR'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'ValueFirstStrategyQ'){
                        overallScore = sum(event.target.value, Inputs['ValueFirstStrategyF'+thenum], Inputs['ValueFirstStrategyG'+thenum], Inputs['ValueFirstStrategyH'+thenum], Inputs['ValueFirstStrategyI'+thenum], Inputs['ValueFirstStrategyJ'+thenum], Inputs['ValueFirstStrategyK'+thenum], Inputs['ValueFirstStrategyL'+thenum], Inputs['ValueFirstStrategyM'+thenum], Inputs['ValueFirstStrategyN'+thenum], Inputs['ValueFirstStrategyO'+thenum], Inputs['ValueFirstStrategyP'+thenum], Inputs['ValueFirstStrategyR'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'ValueFirstStrategyR'){
                        overallScore = sum(event.target.value, Inputs['ValueFirstStrategyF'+thenum], Inputs['ValueFirstStrategyG'+thenum], Inputs['ValueFirstStrategyH'+thenum], Inputs['ValueFirstStrategyI'+thenum], Inputs['ValueFirstStrategyJ'+thenum], Inputs['ValueFirstStrategyK'+thenum], Inputs['ValueFirstStrategyL'+thenum], Inputs['ValueFirstStrategyM'+thenum], Inputs['ValueFirstStrategyN'+thenum], Inputs['ValueFirstStrategyO'+thenum], Inputs['ValueFirstStrategyP'+thenum], Inputs['ValueFirstStrategyQ'+thenum]).toFixed(2);
                    }
                    
                    setInputs({ ...Inputs, ['ValueFirstStrategyS'+thenum] : parseFloat(overallScore) })
                }
               
                dispatch(modifyValueFirstStrategy({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }
    
  return (
    <div className="content-body">
        <UserDropdown />
        {/* row */}
        <div className="container-fluid">
            <div className="head-title">
                <p className="mb-0">Sales &amp; Sales Management </p>
                <h2 className="mb-1">"Value First" Strategy Development</h2>	
                <ul className="list-unstyled values-list-ul mb-4">
                <li className="mb-2">What value can you offer your prospects that will help them "sample" the benefits you provide, and reduce the perceived risk of working with you? </li>
                <li><b>"Value First" Ideas</b>- Diagnostic or assessment, strategic planning session, analysis, project planning session, training session, overview of new methodology, half day workshop, first couple of hours of a small project, and introduction to peers. </li>
                </ul>            					
            </div>
            <Spinner />
            <div className="row">
                <div className="col-xl-12 contentleft">
                <div className="card">
                    <div className="card-body pl-0">
                    <div className="table-responsive table-dta">
                        {/* table 1 */}
                        <table className="table mb-0">
                        <thead>
                            <tr>
                            <th colSpan={8} className="bg-color5 text-white">
                                <div className="outr-div1">
                                <div className="image-bx-ioo1">
                                    <img src="../images/value-first/value.png" className="img-fluid" alt="value" />
                                </div>
                                <div className="content-ioo-values">
                                    Value First Offers We Can Deliver Now
                                </div>
                                </div>
                            </th>
                            </tr>
                            <tr>
                            <th className="bg-color1 text-white" rowSpan={2}>
                                <div className="values-div1">Offer We Can Deliver Now</div>
                            </th>
                            <th className="bg-color1 text-white" rowSpan={2}>
                                <div className="values-div1">Leads to This Paid <br /> Product or Service</div>
                            </th>
                            <th className="bg-color1 text-white" rowSpan={2}>
                                <div className="values-div1">Revenue <br /> Potential </div>
                            </th>
                            <th className="bg-color1 text-white text-center-c" colSpan={4}>
                                <div className="values-div1">Whole Brain Thinking Style</div>
                            </th>
                            <th className="bg-color1 text-white text-center-c" rowSpan={2}>
                                <div className="values-div1">Prospects or Clients This <br /> Offer Would Appeal To</div>
                            </th>
                            </tr>
                            <tr>
                            <td className="bg-color2 border-0 text-center-c">Analytical</td>
                            <td className="bg-color2 border-0 text-center-c">Experimental</td>
                            <td className="bg-color2 border-0 text-center-c">Practical</td>
                            <td className="bg-color2 border-0 text-center-c">Relational</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyC11"
                                    value={Inputs.ValueFirstStrategyC11}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyC11: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyE11"
                                    value={Inputs.ValueFirstStrategyE11}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyE11: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyG11"
                                    value={Inputs.ValueFirstStrategyG11}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyG11: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyH11"
                                    value={Inputs.ValueFirstStrategyH11}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyH11: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyI11"
                                    value={Inputs.ValueFirstStrategyI11}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyI11: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyJ11"
                                    value={Inputs.ValueFirstStrategyJ11}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyJ11: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyK11"
                                    value={Inputs.ValueFirstStrategyK11}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyK11: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyL11"
                                    value={Inputs.ValueFirstStrategyL11}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyL11: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyC12"
                                    value={Inputs.ValueFirstStrategyC12}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyC12: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyE12"
                                    value={Inputs.ValueFirstStrategyE12}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyE12: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyG12"
                                    value={Inputs.ValueFirstStrategyG12}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyG12: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyH12"
                                    value={Inputs.ValueFirstStrategyH12}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyH12: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyI12"
                                    value={Inputs.ValueFirstStrategyI12}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyI12: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyJ12"
                                    value={Inputs.ValueFirstStrategyJ12}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyJ12: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyK12"
                                    value={Inputs.ValueFirstStrategyK12}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyK12: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyL12"
                                    value={Inputs.ValueFirstStrategyL12}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyL12: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyC13"
                                    value={Inputs.ValueFirstStrategyC13}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyC13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyE13"
                                    value={Inputs.ValueFirstStrategyE13}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyE13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyG13"
                                    value={Inputs.ValueFirstStrategyG13}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyG13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyH13"
                                    value={Inputs.ValueFirstStrategyH13}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyH13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyI13"
                                    value={Inputs.ValueFirstStrategyI13}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyI13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyJ13"
                                    value={Inputs.ValueFirstStrategyJ13}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyJ13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyK13"
                                    value={Inputs.ValueFirstStrategyK13}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyK13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyL13"
                                    value={Inputs.ValueFirstStrategyL13}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyL13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyC14"
                                    value={Inputs.ValueFirstStrategyC14}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyC14: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyE14"
                                    value={Inputs.ValueFirstStrategyE14}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyE14: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyG14"
                                    value={Inputs.ValueFirstStrategyG14}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyG14: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyH14"
                                    value={Inputs.ValueFirstStrategyH14}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyH14: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyI14"
                                    value={Inputs.ValueFirstStrategyI14}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyI14: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyJ14"
                                    value={Inputs.ValueFirstStrategyJ14}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyJ14: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyK14"
                                    value={Inputs.ValueFirstStrategyK14}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyK14: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyL14"
                                    value={Inputs.ValueFirstStrategyL14}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyL14: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyC15"
                                    value={Inputs.ValueFirstStrategyC15}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyC15: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyE15"
                                    value={Inputs.ValueFirstStrategyE15}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyE15: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyG15"
                                    value={Inputs.ValueFirstStrategyG15}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyG15: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyH15"
                                    value={Inputs.ValueFirstStrategyH15}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyH15: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyI15"
                                    value={Inputs.ValueFirstStrategyI15}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyI15: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyJ15"
                                    value={Inputs.ValueFirstStrategyJ15}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyJ15: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyK15"
                                    value={Inputs.ValueFirstStrategyK15}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyK15: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyL15"
                                    value={Inputs.ValueFirstStrategyL15}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyL15: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyC16"
                                    value={Inputs.ValueFirstStrategyC16}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyC16: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyE16"
                                    value={Inputs.ValueFirstStrategyE16}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyE16: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyG16"
                                    value={Inputs.ValueFirstStrategyG16}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyG16: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyH16"
                                    value={Inputs.ValueFirstStrategyH16}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyH16: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyI16"
                                    value={Inputs.ValueFirstStrategyI16}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyI16: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyJ16"
                                    value={Inputs.ValueFirstStrategyJ16}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyJ16: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyK16"
                                    value={Inputs.ValueFirstStrategyK16}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyK16: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyL16"
                                    value={Inputs.ValueFirstStrategyL16}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyL16: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyC17"
                                    value={Inputs.ValueFirstStrategyC17}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyC17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyE17"
                                    value={Inputs.ValueFirstStrategyE17}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyE17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyG17"
                                    value={Inputs.ValueFirstStrategyG17}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyG17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyH17"
                                    value={Inputs.ValueFirstStrategyH17}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyH17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyI17"
                                    value={Inputs.ValueFirstStrategyI17}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyI17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyJ17"
                                    value={Inputs.ValueFirstStrategyJ17}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyJ17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyK17"
                                    value={Inputs.ValueFirstStrategyK17}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyK17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyL17"
                                    value={Inputs.ValueFirstStrategyL17}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyL17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                        </tbody>
                        </table>
                        {/* table 2 */}
                        <table className="table mb-0 mt-3">
                        <thead>
                            <tr>
                            <th colSpan={8} className="bg-color5 text-white">
                                <div className="outr-div1">
                                <div className="image-bx-ioo1">
                                    <img src="../images/value-first/develop.png" className="img-fluid" alt="develop" />
                                </div>
                                <div className="content-ioo-values">
                                    Value First Offers We Need to Develop
                                </div>
                                </div>
                            </th>
                            </tr>
                            <tr>
                            <th className="bg-color1 text-white" rowSpan={2}>
                                <div className="values-div1">Offer We Can Deliver Now</div>
                            </th>
                            <th className="bg-color1 text-white" rowSpan={2}>
                                <div className="values-div1">Leads to This Paid <br /> Product or Service</div>
                            </th>
                            <th className="bg-color1 text-white" rowSpan={2}>
                                <div className="values-div1">Revenue <br /> Potential </div>
                            </th>
                            <th className="bg-color1 text-white text-center-c" colSpan={4}>
                                <div className="values-div1">Whole Brain Thinking Style</div>
                            </th>
                            <th className="bg-color1 text-white text-center-c" rowSpan={2}>
                                <div className="values-div1">Prospects or Clients This <br /> Offer Would Appeal To</div>
                            </th>
                            </tr>
                            <tr>
                            <td className="bg-color2 border-0 text-center-c">Analytical</td>
                            <td className="bg-color2 border-0 text-center-c">Experimental</td>
                            <td className="bg-color2 border-0 text-center-c">Practical</td>
                            <td className="bg-color2 border-0 text-center-c">Relational</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyC22"
                                    value={Inputs.ValueFirstStrategyC22}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyC22: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyE22"
                                    value={Inputs.ValueFirstStrategyE22}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyE22: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyG22"
                                    value={Inputs.ValueFirstStrategyG22}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyG22: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyH22"
                                    value={Inputs.ValueFirstStrategyH22}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyH22: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyI22"
                                    value={Inputs.ValueFirstStrategyI22}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyI22: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyJ22"
                                    value={Inputs.ValueFirstStrategyJ22}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyJ22: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyK22"
                                    value={Inputs.ValueFirstStrategyK22}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyK22: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyL22"
                                    value={Inputs.ValueFirstStrategyL22}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyL22: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyC23"
                                    value={Inputs.ValueFirstStrategyC23}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyC23: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyE23"
                                    value={Inputs.ValueFirstStrategyE23}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyE23: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyG23"
                                    value={Inputs.ValueFirstStrategyG23}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyG23: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyH23"
                                    value={Inputs.ValueFirstStrategyH23}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyH23: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyI23"
                                    value={Inputs.ValueFirstStrategyI23}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyI23: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyJ23"
                                    value={Inputs.ValueFirstStrategyJ23}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyJ23: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyK23"
                                    value={Inputs.ValueFirstStrategyK23}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyK23: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyL23"
                                    value={Inputs.ValueFirstStrategyL23}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyL23: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyC24"
                                    value={Inputs.ValueFirstStrategyC24}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyC24: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyE24"
                                    value={Inputs.ValueFirstStrategyE24}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyE24: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyG24"
                                    value={Inputs.ValueFirstStrategyG24}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyG24: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyH24"
                                    value={Inputs.ValueFirstStrategyH24}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyH24: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyI24"
                                    value={Inputs.ValueFirstStrategyI24}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyI24: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyJ24"
                                    value={Inputs.ValueFirstStrategyJ24}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyJ24: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyK24"
                                    value={Inputs.ValueFirstStrategyK24}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyK24: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyL24"
                                    value={Inputs.ValueFirstStrategyL24}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyL24: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyC25"
                                    value={Inputs.ValueFirstStrategyC25}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyC25: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyE25"
                                    value={Inputs.ValueFirstStrategyE25}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyE25: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyG25"
                                    value={Inputs.ValueFirstStrategyG25}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyG25: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyH25"
                                    value={Inputs.ValueFirstStrategyH25}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyH25: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyI25"
                                    value={Inputs.ValueFirstStrategyI25}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyI25: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyJ25"
                                    value={Inputs.ValueFirstStrategyJ25}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyJ25: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyK25"
                                    value={Inputs.ValueFirstStrategyK25}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyK25: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyL25"
                                    value={Inputs.ValueFirstStrategyL25}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyL25: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyC26"
                                    value={Inputs.ValueFirstStrategyC26}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyC26: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyE26"
                                    value={Inputs.ValueFirstStrategyE26}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyE26: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyG26"
                                    value={Inputs.ValueFirstStrategyG26}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyG26: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyH26"
                                    value={Inputs.ValueFirstStrategyH26}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyH26: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyI26"
                                    value={Inputs.ValueFirstStrategyI26}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyI26: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyJ26"
                                    value={Inputs.ValueFirstStrategyJ26}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyJ26: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyK26"
                                    value={Inputs.ValueFirstStrategyK26}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyK26: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyL26"
                                    value={Inputs.ValueFirstStrategyL26}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyL26: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyC27"
                                    value={Inputs.ValueFirstStrategyC27}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyC27: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyE27"
                                    value={Inputs.ValueFirstStrategyE27}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyE27: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyG27"
                                    value={Inputs.ValueFirstStrategyG27}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyG27: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyH27"
                                    value={Inputs.ValueFirstStrategyH27}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyH27: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyI27"
                                    value={Inputs.ValueFirstStrategyI27}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyI27: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyJ27"
                                    value={Inputs.ValueFirstStrategyJ27}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyJ27: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyK27"
                                    value={Inputs.ValueFirstStrategyK27}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyK27: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyL27"
                                    value={Inputs.ValueFirstStrategyL27}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyL27: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyC28"
                                    value={Inputs.ValueFirstStrategyC28}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyC28: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyE28"
                                    value={Inputs.ValueFirstStrategyE28}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyE28: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyG28"
                                    value={Inputs.ValueFirstStrategyG28}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyG28: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyH28"
                                    value={Inputs.ValueFirstStrategyH28}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyH28: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyI28"
                                    value={Inputs.ValueFirstStrategyI28}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyI28: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyJ28"
                                    value={Inputs.ValueFirstStrategyJ28}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyJ28: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyK28"
                                    value={Inputs.ValueFirstStrategyK28}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyK28: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ValueFirstStrategyL28"
                                    value={Inputs.ValueFirstStrategyL28}
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyL28: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    {/* table 3 */}
                    <div className="table-responsive table-dta">
                        {/* table 1 */}
                        <h1 className="values-first">Value First Offers Scorecard</h1><table className="table mb-0">
                        <thead>
                            <tr>
                            <th className="bg-color1 text-white" rowSpan={2}>
                                <div className="values-potential">Potential Value First Offers</div>
                            </th>
                            <th className="bg-color1 text-white text-center-c" colSpan={15}>
                                <div className="values-div1">Traits</div>
                            </th>
                            </tr>
                            <tr>
                            <td className="bg-color3 text-white border-0 text-center-c">
                                <div className="values-div2">Solves a Relevant Problem</div>
                            </td>
                            <td className="bg-color3 text-white border-0 text-center-c">
                                <div className="values-div2">Willing to Invest in This Area</div>
                            </td>
                            <td className="bg-color3 text-white border-0 text-center-c">
                                <div className="values-div2">Demonstrates ROI Over 3 Years</div>
                            </td>
                            <td className="bg-color3 text-white border-0 text-center-c">
                                <div className="values-div2">Price Easy to Approve</div>
                            </td>
                            <td className="bg-color3 text-white border-0 text-center-c">
                                <div className="values-div2">Prospect Gets to Experience Your Benefits</div>
                            </td>
                            <td className="bg-color3 text-white border-0 text-center-c">
                                <div className="values-div2">Creates Example Output</div>
                            </td>
                            <td className="bg-color3 text-white border-0 text-center-c">
                                <div className="values-div2">Creates Example Output</div>
                            </td>
                            <td className="bg-color3 text-white border-0 text-center-c">
                                <div className="values-div2">Demonstrates Your Expertise</div>
                            </td>
                            <td className="bg-color3 text-white border-0 text-center-c">
                                <div className="values-div2">Develop Trust, Credibility &amp; Influence</div>
                            </td>
                            <td className="bg-color3 text-white border-0 text-center-c">
                                <div className="values-div2">Builds a Sense of Urgency</div>
                            </td>
                            <td className="bg-color3 text-white border-0 text-center-c">
                                <div className="values-div2">Creates Curiosity</div>
                            </td>
                            <td className="bg-color3 text-white border-0 text-center-c">
                                <div className="values-div2">Sets Buying Criteria in Your Favor</div>
                            </td>
                            <td className="bg-color3 text-white border-0 text-center-c">
                                <div className="values-div2"> Introduce Something New (Approach or Technology)</div>
                            </td>
                            <td className="bg-color3 text-white border-0 text-center-c">Score</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <th className="bg-color4 padding-0">
                                <form className="values-form-deve">
                                    <input type="text" className="inputText"
                                        id="ValueFirstStrategyC35"
                                        value={Inputs.ValueFirstStrategyC35}
                                        onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyC35: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                        placeholder="Option" />
                                </form>
                            </th>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyF35" 
                                    value={Inputs.ValueFirstStrategyF35} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyF35: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyG35" 
                                    value={Inputs.ValueFirstStrategyG35} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyG35: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyH35" 
                                    value={Inputs.ValueFirstStrategyH35} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyH35: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyI35" 
                                    value={Inputs.ValueFirstStrategyI35} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyI35: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyJ35" 
                                    value={Inputs.ValueFirstStrategyJ35} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyJ35: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyK35" 
                                    value={Inputs.ValueFirstStrategyK35} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyK35: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyL35" 
                                    value={Inputs.ValueFirstStrategyL35} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyL35: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyM35" 
                                    value={Inputs.ValueFirstStrategyM35} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyM35: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyN35" 
                                    value={Inputs.ValueFirstStrategyN35} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyN35: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyO35" 
                                    value={Inputs.ValueFirstStrategyO35} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyO35: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyP35" 
                                    value={Inputs.ValueFirstStrategyP35} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyP35: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyQ35" 
                                    value={Inputs.ValueFirstStrategyQ35} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyQ35: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyR35" 
                                    value={Inputs.ValueFirstStrategyR35} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyR35: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color1 text-white text-center-c">
                                {Number(Inputs.ValueFirstStrategyS35).toLocaleString('en-US')}
                            </td>
                            </tr>
                            <tr>
                            <th className="bg-color4 padding-0">
                                <form className="values-form-deve">
                                    <input type="text" className="inputText"
                                        id="ValueFirstStrategyC36"
                                        value={Inputs.ValueFirstStrategyC36}
                                        onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyC36: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                        placeholder="Option" />
                                </form>
                            </th>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyF36" 
                                    value={Inputs.ValueFirstStrategyF36} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyF36: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyG36" 
                                    value={Inputs.ValueFirstStrategyG36} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyG36: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyH36" 
                                    value={Inputs.ValueFirstStrategyH36} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyH36: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyI36" 
                                    value={Inputs.ValueFirstStrategyI36} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyI36: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyJ36" 
                                    value={Inputs.ValueFirstStrategyJ36} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyJ36: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyK36" 
                                    value={Inputs.ValueFirstStrategyK36} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyK36: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyL36" 
                                    value={Inputs.ValueFirstStrategyL36} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyL36: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyM36" 
                                    value={Inputs.ValueFirstStrategyM36} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyM36: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyN36" 
                                    value={Inputs.ValueFirstStrategyN36} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyN36: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyO36" 
                                    value={Inputs.ValueFirstStrategyO36} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyO36: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyP36" 
                                    value={Inputs.ValueFirstStrategyP36} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyP36: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyQ36" 
                                    value={Inputs.ValueFirstStrategyQ36} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyQ36: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyR36" 
                                    value={Inputs.ValueFirstStrategyR36} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyR36: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color1 text-white text-center-c">
                                {Number(Inputs.ValueFirstStrategyS36).toLocaleString('en-US')}
                            </td>
                            </tr>
                            <tr>
                            <th className="bg-color4 padding-0">
                                <form className="values-form-deve">
                                    <input type="text" className="inputText"
                                        id="ValueFirstStrategyC37"
                                        value={Inputs.ValueFirstStrategyC37}
                                        onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyC37: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                        placeholder="Option" />
                                </form>
                            </th>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyF37" 
                                    value={Inputs.ValueFirstStrategyF37} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyF37: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyG37" 
                                    value={Inputs.ValueFirstStrategyG37} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyG37: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyH37" 
                                    value={Inputs.ValueFirstStrategyH37} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyH37: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyI37" 
                                    value={Inputs.ValueFirstStrategyI37} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyI37: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyJ37" 
                                    value={Inputs.ValueFirstStrategyJ37} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyJ37: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyK37" 
                                    value={Inputs.ValueFirstStrategyK37} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyK37: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyL37" 
                                    value={Inputs.ValueFirstStrategyL37} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyL37: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyM37" 
                                    value={Inputs.ValueFirstStrategyM37} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyM37: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyN37" 
                                    value={Inputs.ValueFirstStrategyN37} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyN37: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyO37" 
                                    value={Inputs.ValueFirstStrategyO37} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyO37: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyP37" 
                                    value={Inputs.ValueFirstStrategyP37} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyP37: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyQ37" 
                                    value={Inputs.ValueFirstStrategyQ37} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyQ37: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyR37" 
                                    value={Inputs.ValueFirstStrategyR37} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyR37: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color1 text-white text-center-c">
                                {Number(Inputs.ValueFirstStrategyS37).toLocaleString('en-US')}
                            </td>
                            </tr>
                            <tr>
                            <th className="bg-color4 padding-0">
                                <form className="values-form-deve">
                                    <input type="text" className="inputText"
                                        id="ValueFirstStrategyC38"
                                        value={Inputs.ValueFirstStrategyC38}
                                        onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyC38: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                        placeholder="Option" />
                                </form>
                            </th>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyF38" 
                                    value={Inputs.ValueFirstStrategyF38} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyF38: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyG38" 
                                    value={Inputs.ValueFirstStrategyG38} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyG38: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyH38" 
                                    value={Inputs.ValueFirstStrategyH38} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyH38: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyI38" 
                                    value={Inputs.ValueFirstStrategyI38} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyI38: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyJ38" 
                                    value={Inputs.ValueFirstStrategyJ38} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyJ38: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyK38" 
                                    value={Inputs.ValueFirstStrategyK38} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyK38: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyL38" 
                                    value={Inputs.ValueFirstStrategyL38} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyL38: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyM38" 
                                    value={Inputs.ValueFirstStrategyM38} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyM38: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyN38" 
                                    value={Inputs.ValueFirstStrategyN38} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyN38: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyO38" 
                                    value={Inputs.ValueFirstStrategyO38} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyO38: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyP38" 
                                    value={Inputs.ValueFirstStrategyP38} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyP38: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyQ38" 
                                    value={Inputs.ValueFirstStrategyQ38} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyQ38: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyR38" 
                                    value={Inputs.ValueFirstStrategyR38} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyR38: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color1 text-white text-center-c">
                                {Number(Inputs.ValueFirstStrategyS38).toLocaleString('en-US')}
                            </td>
                            </tr>
                            <tr>
                            <th className="bg-color4 padding-0">
                                <form className="values-form-deve">
                                    <input type="text" className="inputText"
                                        id="ValueFirstStrategyC39"
                                        value={Inputs.ValueFirstStrategyC39}
                                        onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyC39: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                        placeholder="Option" />
                                </form>
                            </th>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyF39" 
                                    value={Inputs.ValueFirstStrategyF39} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyF39: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyG39" 
                                    value={Inputs.ValueFirstStrategyG39} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyG39: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyH39" 
                                    value={Inputs.ValueFirstStrategyH39} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyH39: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyI39" 
                                    value={Inputs.ValueFirstStrategyI39} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyI39: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyJ39" 
                                    value={Inputs.ValueFirstStrategyJ39} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyJ39: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyK39" 
                                    value={Inputs.ValueFirstStrategyK39} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyK39: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyL39" 
                                    value={Inputs.ValueFirstStrategyL39} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyL39: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyM39" 
                                    value={Inputs.ValueFirstStrategyM39} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyM39: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyN39" 
                                    value={Inputs.ValueFirstStrategyN39} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyN39: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyO39" 
                                    value={Inputs.ValueFirstStrategyO39} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyO39: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyP39" 
                                    value={Inputs.ValueFirstStrategyP39} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyP39: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyQ39" 
                                    value={Inputs.ValueFirstStrategyQ39} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyQ39: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color2 text-center-c">
                                <input type="text" className="form-control inputText" 
                                    id="ValueFirstStrategyR39" 
                                    value={Inputs.ValueFirstStrategyR39} 
                                    onChange={(event) => setInputs({ ...Inputs, ValueFirstStrategyR39: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="10" 
                                    onKeyPress={(event) => {
                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                    }
                                    }} />
                            </td>
                            <td className="bg-color1 text-white text-center-c">
                                {Number(Inputs.ValueFirstStrategyS39).toLocaleString('en-US')}
                            </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>            			          			
                </div>
            </div>
        </div>

    </div>
  )
}
