import sum from "../../utils/sum";
const initialState = {
    impact120GrowthROIE21: 0,
    impact120GrowthROIF21: 0,
    impact120GrowthROIG21: 0,
    impact120GrowthROIH21: 0,
    impact120GrowthROII21: 0,
    impact120GrowthROIJ21: 0,
    impact120GrowthROIK21: 0,
    impact120GrowthROIL21: 0,
    impact120GrowthROIM21: 0,
    impact120GrowthROIN21: 0,
    impact120GrowthROIO21: 0,
    impact120GrowthROIP21: 0,
    impact120GrowthROID22: 0, 
    impact120GrowthROIE22: 0, 
    impact120GrowthROID23: 0, 
    impact120GrowthROIE23: 0, 
    impact120GrowthROID24: 0,
    impact120GrowthROIE24: 0,
    impact120GrowthROIF22: 0,
    impact120GrowthROIG22: 0,
    impact120GrowthROIH22: 0,
    impact120GrowthROII22: 0,
    impact120GrowthROIJ22: 0,
    impact120GrowthROIK22: 0,
    impact120GrowthROIL22: 0,
    impact120GrowthROIM22: 0,
    impact120GrowthROIN22: 0,
    impact120GrowthROIO22: 0,
    impact120GrowthROIP22: 0,
    impact120GrowthROIF23: 0,
    impact120GrowthROIG23: 0,
    impact120GrowthROIH23: 0,
    impact120GrowthROII23: 0,
    impact120GrowthROIJ23: 0,
    impact120GrowthROIK23: 0,
    impact120GrowthROIL23: 0,
    impact120GrowthROIM23: 0,
    impact120GrowthROIN23: 0,
    impact120GrowthROIO23: 0,
    impact120GrowthROIP23: 0,
    impact120GrowthROIF24: 0,
    impact120GrowthROIG24: 0,
    impact120GrowthROIH24: 0,
    impact120GrowthROII24: 0,
    impact120GrowthROIJ24: 0,
    impact120GrowthROIK24: 0,
    impact120GrowthROIL24: 0,
    impact120GrowthROIM24: 0,
    impact120GrowthROIN24: 0,
    impact120GrowthROIO24: 0,
    impact120GrowthROIP24: 0,
    impact120GrowthROID16: 0,
    impact120GrowthROIE16: 0,
    impact120GrowthROIF16: 0,
    impact120GrowthROID17: 0,
    impact120GrowthROIE17: 0,
    impact120GrowthROIF17: 0
}
const reducer = (state = initialState, action) => {
    if (action.type === 'impactof120GrowthROIAll') {

        const {
            clientInputsH41,
            impactof120GrowthOS,
            impact120GrowthROID13,
            impact120GrowthROID21 } = action.payload

        const { impactof120GrowthOSF44, impactof120GrowthOSE44, impactof120GrowthOSE45, impactof120GrowthOSF45, impactof120GrowthOSF46, impactof120GrowthOSE46, impactof120GrowthOSE47, impactof120GrowthOSF47, impactof120GrowthOSE_48, impactof120GrowthOSF_48, impactof120GrowthOSE_49, impactof120GrowthOSF_49 } = impactof120GrowthOS

        let impact120GrowthROIE21 = (parseFloat(impact120GrowthROID13) * 3).toFixed(2);
        let impact120GrowthROIF21 = (parseFloat(impact120GrowthROID13) * 3).toFixed(2);
        let impact120GrowthROIG21 = (parseFloat(impact120GrowthROID13) * 3).toFixed(2);
        let impact120GrowthROIH21 = (parseFloat(impact120GrowthROID13) * 3).toFixed(2);
        let impact120GrowthROII21 = (parseFloat(impact120GrowthROID13) * 3).toFixed(2);
        let impact120GrowthROIJ21 = (parseFloat(impact120GrowthROID13) * 3).toFixed(2);
        let impact120GrowthROIK21 = (parseFloat(impact120GrowthROID13) * 3).toFixed(2);
        let impact120GrowthROIL21 = (parseFloat(impact120GrowthROID13) * 3).toFixed(2);
        let impact120GrowthROIM21 = (parseFloat(impact120GrowthROID13) * 3).toFixed(2);
        let impact120GrowthROIN21 = (parseFloat(impact120GrowthROID13) * 3).toFixed(2);
        let impact120GrowthROIO21 = (parseFloat(impact120GrowthROID13) * 3).toFixed(2);
        let impact120GrowthROIP21 = (parseFloat(impact120GrowthROID13) * 3).toFixed(2);

        let impact120GrowthROIF22 = ((parseFloat(impactof120GrowthOSF44) - parseFloat(impactof120GrowthOSE44)) / 4).toFixed(2);
        let impact120GrowthROIG22 = ((parseFloat(impactof120GrowthOSF44) - parseFloat(impactof120GrowthOSE44)) / 4).toFixed(2);
        let impact120GrowthROIH22 = ((parseFloat(impactof120GrowthOSF44) - parseFloat(impactof120GrowthOSE44)) / 4).toFixed(2);
        let impact120GrowthROII22 = ((parseFloat(impactof120GrowthOSF46) - parseFloat(impactof120GrowthOSE46)) / 4).toFixed(2);
        let impact120GrowthROIJ22 = ((parseFloat(impactof120GrowthOSF46) - parseFloat(impactof120GrowthOSE46)) / 4).toFixed(2);
        let impact120GrowthROIK22 = ((parseFloat(impactof120GrowthOSF46) - parseFloat(impactof120GrowthOSE46)) / 4).toFixed(2);
        let impact120GrowthROIL22 = ((parseFloat(impactof120GrowthOSF46) - parseFloat(impactof120GrowthOSE46)) / 4).toFixed(2);
        let impact120GrowthROIM22 = ((parseFloat(impactof120GrowthOSF_48) - parseFloat(impactof120GrowthOSE_48)) / 4).toFixed(2);
        let impact120GrowthROIN22 = ((parseFloat(impactof120GrowthOSF_48) - parseFloat(impactof120GrowthOSE_48)) / 4).toFixed(2);
        let impact120GrowthROIO22 = ((parseFloat(impactof120GrowthOSF_48) - parseFloat(impactof120GrowthOSE_48)) / 4).toFixed(2);
        let impact120GrowthROIP22 = ((parseFloat(impactof120GrowthOSF_48) - parseFloat(impactof120GrowthOSE_48)) / 4).toFixed(2);

        let impact120GrowthROIF23 = ((parseFloat(impactof120GrowthOSF45) - parseFloat(impactof120GrowthOSE45)) / 4).toFixed(2);
        let impact120GrowthROIG23 = ((parseFloat(impactof120GrowthOSF45) - parseFloat(impactof120GrowthOSE45)) / 4).toFixed(2);
        let impact120GrowthROIH23 = ((parseFloat(impactof120GrowthOSF45) - parseFloat(impactof120GrowthOSE45)) / 4).toFixed(2);
        let impact120GrowthROII23 = ((parseFloat(impactof120GrowthOSF47) - parseFloat(impactof120GrowthOSE47)) / 4).toFixed(2);
        let impact120GrowthROIJ23 = ((parseFloat(impactof120GrowthOSF47) - parseFloat(impactof120GrowthOSE47)) / 4).toFixed(2);
        let impact120GrowthROIK23 = ((parseFloat(impactof120GrowthOSF47) - parseFloat(impactof120GrowthOSE47)) / 4).toFixed(2);
        let impact120GrowthROIL23 = ((parseFloat(impactof120GrowthOSF47) - parseFloat(impactof120GrowthOSE47)) / 4).toFixed(2);
        let impact120GrowthROIM23 = ((parseFloat(impactof120GrowthOSF_49) - parseFloat(impactof120GrowthOSE_49)) / 4).toFixed(2);
        let impact120GrowthROIN23 = ((parseFloat(impactof120GrowthOSF_49) - parseFloat(impactof120GrowthOSE_49)) / 4).toFixed(2);
        let impact120GrowthROIO23 = ((parseFloat(impactof120GrowthOSF_49) - parseFloat(impactof120GrowthOSE_49)) / 4).toFixed(2);
        let impact120GrowthROIP23 = ((parseFloat(impactof120GrowthOSF_49) - parseFloat(impactof120GrowthOSE_49)) / 4).toFixed(2);

        let impact120GrowthROIF24 = (parseFloat(impact120GrowthROIF23) * parseFloat(clientInputsH41)).toFixed(2);
        let impact120GrowthROIG24 = (parseFloat(impact120GrowthROIG23) * parseFloat(clientInputsH41)).toFixed(2);
        let impact120GrowthROIH24 = (parseFloat(impact120GrowthROIH23) * parseFloat(clientInputsH41)).toFixed(2);
        let impact120GrowthROII24 = (parseFloat(impact120GrowthROII23) * parseFloat(clientInputsH41)).toFixed(2);
        let impact120GrowthROIJ24 = (parseFloat(impact120GrowthROIJ23) * parseFloat(clientInputsH41)).toFixed(2);
        let impact120GrowthROIK24 = (parseFloat(impact120GrowthROIK23) * parseFloat(clientInputsH41)).toFixed(2);
        let impact120GrowthROIL24 = (parseFloat(impact120GrowthROIL23) * parseFloat(clientInputsH41)).toFixed(2);
        let impact120GrowthROIM24 = (parseFloat(impact120GrowthROIM23) * parseFloat(clientInputsH41)).toFixed(2) 
        let impact120GrowthROIN24 = (parseFloat(impact120GrowthROIN23) * parseFloat(clientInputsH41)).toFixed(2);
        let impact120GrowthROIO24 = (parseFloat(impact120GrowthROIO23) * parseFloat(clientInputsH41)).toFixed(2);
        let impact120GrowthROIP24 = (parseFloat(impact120GrowthROIP23) * parseFloat(clientInputsH41)).toFixed(2);
        
        // sum(f22+g22+h22)/sum(d21+e21+f21+g21+f21)

        let impact120GrowthROID16 = (parseFloat(sum(impact120GrowthROIF22, impact120GrowthROIG22, impact120GrowthROIH22) / sum(impact120GrowthROID21, impact120GrowthROIE21, impact120GrowthROIF21, impact120GrowthROIG21, impact120GrowthROIF21)) * 100).toFixed(1);
        // --------------------------------------------------------------
        // sum(f22, g22, h22, i22, j22, k22, l22) / sum(d21, e21, f21, g21, h21, i21, j21, k21, l21)
        let impact120GrowthROIE16 = (parseFloat(sum(impact120GrowthROIF22, impact120GrowthROIG22, impact120GrowthROIH22, impact120GrowthROII22, impact120GrowthROIJ22, impact120GrowthROIK22, impact120GrowthROIL22) / sum(impact120GrowthROID21, impact120GrowthROIE21, impact120GrowthROIF21, impact120GrowthROIG21, impact120GrowthROIH21, impact120GrowthROII21, impact120GrowthROIJ21, impact120GrowthROIK21, impact120GrowthROIL21)) * 100).toFixed(1);
        // --------------------------------------------------------------
        // sum(f22, g22, h22, i22, j22, k22, l22, m22, n22, o22, p22) / sum(d21, e21, f21, g21, h21, i21, j21, k21, l21, m21, n21, o21, p21)
        let impact120GrowthROIF16 = (parseFloat(sum(impact120GrowthROIF22, impact120GrowthROIG22, impact120GrowthROIH22, impact120GrowthROII22, impact120GrowthROIJ22, impact120GrowthROIK22, impact120GrowthROIL22, impact120GrowthROIM22, impact120GrowthROIN22, impact120GrowthROIO22, impact120GrowthROIP22) / sum(impact120GrowthROID21, impact120GrowthROIE21, impact120GrowthROIF21, impact120GrowthROIG21, impact120GrowthROIH21, impact120GrowthROII21, impact120GrowthROIJ21, impact120GrowthROIK21, impact120GrowthROIL21, impact120GrowthROIM21, impact120GrowthROIN21, impact120GrowthROIO21, impact120GrowthROIP21)) * 100).toFixed(1);
        
        // SUM(F23, G23, H23)/SUM(D21, E21, F21, G21, F21)
        let impact120GrowthROID17 = (parseFloat(sum(impact120GrowthROIF23, impact120GrowthROIG23, impact120GrowthROIH23) / sum(impact120GrowthROID21, impact120GrowthROIE21, impact120GrowthROIF21, impact120GrowthROIG21, impact120GrowthROIF21)) * 100).toFixed(1);
        
        // SUM(F23, G23, H23, I23, J23, K23, L23)/SUM(D21, E21, F21, G21, H21, I21, J21, K21, L21)
        let impact120GrowthROIE17 = (parseFloat(sum(impact120GrowthROIF23, impact120GrowthROIG23, impact120GrowthROIH23, impact120GrowthROII23, impact120GrowthROIJ23, impact120GrowthROIK23, impact120GrowthROIL23) / sum(impact120GrowthROID21, impact120GrowthROIE21, impact120GrowthROIF21, impact120GrowthROIG21, impact120GrowthROIH21, impact120GrowthROII21, impact120GrowthROIJ21, impact120GrowthROIK21, impact120GrowthROIL21)) * 100).toFixed(1);
        
        // SUM(F23, G23, H23, I23, J23, K23, L23, M23, N23, O23, P23) / SUM(D21, E21, F21, G21, H21, I21, J21, K21, L21, M21, N21, O21, P21)
        let impact120GrowthROIF17 = (parseFloat(sum(impact120GrowthROIF23, impact120GrowthROIG23, impact120GrowthROIH23, impact120GrowthROII23, impact120GrowthROIJ23, impact120GrowthROIK23, impact120GrowthROIL23, impact120GrowthROIM23, impact120GrowthROIN23, impact120GrowthROIO23, impact120GrowthROIP23) / sum(impact120GrowthROID21, impact120GrowthROIE21, impact120GrowthROIF21, impact120GrowthROIG21, impact120GrowthROIH21, impact120GrowthROII21, impact120GrowthROIJ21, impact120GrowthROIK21, impact120GrowthROIL21, impact120GrowthROIM21, impact120GrowthROIN21, impact120GrowthROIO21, impact120GrowthROIP21)) * 100).toFixed(1);

        return {
            ...state, //copying the original state
            impact120GrowthROIE21: (impact120GrowthROIE21 != 'NaN') ? impact120GrowthROIE21 : 0,
            impact120GrowthROIF21: (impact120GrowthROIF21 != 'NaN') ? impact120GrowthROIF21 : 0,
            impact120GrowthROIG21: (impact120GrowthROIG21 != 'NaN') ? impact120GrowthROIG21 : 0,
            impact120GrowthROIH21: (impact120GrowthROIH21 != 'NaN') ? impact120GrowthROIH21 : 0,
            impact120GrowthROII21: (impact120GrowthROII21 != 'NaN') ? impact120GrowthROII21 : 0,
            impact120GrowthROIJ21: (impact120GrowthROIJ21 != 'NaN') ? impact120GrowthROIJ21 : 0,
            impact120GrowthROIK21: (impact120GrowthROIK21 != 'NaN') ? impact120GrowthROIK21 : 0,
            impact120GrowthROIL21: (impact120GrowthROIL21 != 'NaN') ? impact120GrowthROIL21 : 0,
            impact120GrowthROIM21: (impact120GrowthROIM21 != 'NaN') ? impact120GrowthROIM21 : 0,
            impact120GrowthROIN21: (impact120GrowthROIN21 != 'NaN') ? impact120GrowthROIN21 : 0,
            impact120GrowthROIO21: (impact120GrowthROIO21 != 'NaN') ? impact120GrowthROIO21 : 0,
            impact120GrowthROIP21: (impact120GrowthROIP21 != 'NaN') ? impact120GrowthROIP21 : 0,
            impact120GrowthROID22: 0, 
            impact120GrowthROIE22: 0, 
            impact120GrowthROID23: 0, 
            impact120GrowthROIE23: 0, 
            impact120GrowthROID24: 0,
            impact120GrowthROIE24: 0,
            impact120GrowthROIF22: (impact120GrowthROIF22 != 'NaN') ? impact120GrowthROIF22 : 0,
            impact120GrowthROIG22: (impact120GrowthROIG22 != 'NaN') ? impact120GrowthROIG22 : 0,
            impact120GrowthROIH22: (impact120GrowthROIH22 != 'NaN') ? impact120GrowthROIH22 : 0,
            impact120GrowthROII22: (impact120GrowthROII22 != 'NaN') ? impact120GrowthROII22 : 0,
            impact120GrowthROIJ22: (impact120GrowthROIJ22 != 'NaN') ? impact120GrowthROIJ22 : 0,
            impact120GrowthROIK22: (impact120GrowthROIK22 != 'NaN') ? impact120GrowthROIK22 : 0,
            impact120GrowthROIL22: (impact120GrowthROIL22 != 'NaN') ? impact120GrowthROIL22 : 0,
            impact120GrowthROIM22: (impact120GrowthROIM22 != 'NaN') ? impact120GrowthROIM22 : 0,
            impact120GrowthROIN22: (impact120GrowthROIN22 != 'NaN') ? impact120GrowthROIN22 : 0,
            impact120GrowthROIO22: (impact120GrowthROIO22 != 'NaN') ? impact120GrowthROIO22 : 0,
            impact120GrowthROIP22: (impact120GrowthROIP22 != 'NaN') ? impact120GrowthROIP22 : 0,
            impact120GrowthROIF23: (impact120GrowthROIF23 != 'NaN') ? impact120GrowthROIF23 : 0,
            impact120GrowthROIG23: (impact120GrowthROIG23 != 'NaN') ? impact120GrowthROIG23 : 0,
            impact120GrowthROIH23: (impact120GrowthROIH23 != 'NaN') ? impact120GrowthROIH23 : 0,
            impact120GrowthROII23: (impact120GrowthROII23 != 'NaN') ? impact120GrowthROII23 : 0,
            impact120GrowthROIJ23: (impact120GrowthROIJ23 != 'NaN') ? impact120GrowthROIJ23 : 0,
            impact120GrowthROIK23: (impact120GrowthROIK23 != 'NaN') ? impact120GrowthROIK23 : 0,
            impact120GrowthROIL23: (impact120GrowthROIL23 != 'NaN') ? impact120GrowthROIL23 : 0,
            impact120GrowthROIM23: (impact120GrowthROIM23 != 'NaN') ? impact120GrowthROIM23 : 0,
            impact120GrowthROIN23: (impact120GrowthROIN23 != 'NaN') ? impact120GrowthROIN23 : 0,
            impact120GrowthROIO23: (impact120GrowthROIO23 != 'NaN') ? impact120GrowthROIO23 : 0,
            impact120GrowthROIP23: (impact120GrowthROIP23 != 'NaN') ? impact120GrowthROIP23 : 0,
            impact120GrowthROIF24: (impact120GrowthROIF24 != 'NaN') ? impact120GrowthROIF24 : 0,
            impact120GrowthROIG24: (impact120GrowthROIG24 != 'NaN') ? impact120GrowthROIG24 : 0,
            impact120GrowthROIH24: (impact120GrowthROIH24 != 'NaN') ? impact120GrowthROIH24 : 0,
            impact120GrowthROII24: (impact120GrowthROII24 != 'NaN') ? impact120GrowthROII24 : 0,
            impact120GrowthROIJ24: (impact120GrowthROIJ24 != 'NaN') ? impact120GrowthROIJ24 : 0,
            impact120GrowthROIK24: (impact120GrowthROIK24 != 'NaN') ? impact120GrowthROIK24 : 0,
            impact120GrowthROIL24: (impact120GrowthROIL24 != 'NaN') ? impact120GrowthROIL24 : 0,
            impact120GrowthROIM24: (impact120GrowthROIM24 != 'NaN') ? impact120GrowthROIM24 : 0,
            impact120GrowthROIN24: (impact120GrowthROIN24 != 'NaN') ? impact120GrowthROIN24 : 0,
            impact120GrowthROIO24: (impact120GrowthROIO24 != 'NaN') ? impact120GrowthROIO24 : 0,
            impact120GrowthROIP24: (impact120GrowthROIP24 != 'NaN') ? impact120GrowthROIP24 : 0,
            impact120GrowthROID16: (impact120GrowthROID16 != 'NaN') ? impact120GrowthROID16 : 0,
            impact120GrowthROIE16: (impact120GrowthROIE16 != 'NaN') ? impact120GrowthROIE16 : 0,
            impact120GrowthROIF16: (impact120GrowthROIF16 != 'NaN') ? impact120GrowthROIF16 : 0,
            impact120GrowthROID17: (impact120GrowthROID17 != 'NaN') ? impact120GrowthROID17 : 0,
            impact120GrowthROIE17: (impact120GrowthROIE17 != 'NaN') ? impact120GrowthROIE17 : 0,
            impact120GrowthROIF17: (impact120GrowthROIF17 != 'NaN') ? impact120GrowthROIF17 : 0,
        }
    } else {
        return state
    }

}

export default reducer