import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import {ThreeDots} from "react-loader-spinner";
import "./spinner.css";

export const Spinner = (props) => {
  const { promiseInProgress } = usePromiseTracker({delay: 500});

  return (
    promiseInProgress && (
      <div className="spinner">
        <ThreeDots 
        height="80" 
        width="80" 
        radius="9"
        color="#297cf8" 
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
        />
        Please wait...
      </div>
    )
  );
};

