import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getCustomerPersonas, modifyCustomerPersonas } from "../../state/features/CustomerPersonasSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';

export default function CustomerPersonasContent() {

    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        CustomerPersonasD7 : '',
        CustomerPersonasD8 : '',
        CustomerPersonasD9 : '',
        CustomerPersonasC13 : '',
        CustomerPersonasE13 : '',
        CustomerPersonasF13 : '',
        CustomerPersonasC14 : '',
        CustomerPersonasE14 : '',
        CustomerPersonasF14 : '',
        CustomerPersonasC15 : '',
        CustomerPersonasE15 : '',
        CustomerPersonasF15 : '',
        CustomerPersonasC16 : '',
        CustomerPersonasE16 : '',
        CustomerPersonasF16 : '',
        CustomerPersonasC17 : '',
        CustomerPersonasE17 : '',
        CustomerPersonasF17 : '',
        CustomerPersonasC20 : '',
        CustomerPersonasC21 : '',
        CustomerPersonasC22 : '',
        CustomerPersonasC23 : '',
        CustomerPersonasC24 : '',
        CustomerPersonasD26 : '',
        CustomerPersonasD27 : '',
        CustomerPersonasD28 : '',
        CustomerPersonasC32 : '',
        CustomerPersonasE32 : '',
        CustomerPersonasF32 : '',
        CustomerPersonasC33 : '',
        CustomerPersonasE33 : '',
        CustomerPersonasF33 : '',
        CustomerPersonasC34 : '',
        CustomerPersonasE34 : '',
        CustomerPersonasF34 : '',
        CustomerPersonasC35 : '',
        CustomerPersonasE35 : '',
        CustomerPersonasF35 : '',
        CustomerPersonasC36 : '',
        CustomerPersonasE36 : '',
        CustomerPersonasF36 : '',
        CustomerPersonasC39 : '',
        CustomerPersonasC40 : '',
        CustomerPersonasC41 : '',
        CustomerPersonasC42 : '',
        CustomerPersonasC43 : '',
        CustomerPersonasD45 : '',
        CustomerPersonasD46 : '',
        CustomerPersonasD47 : '',
        CustomerPersonasC51 : '',
        CustomerPersonasE51 : '',
        CustomerPersonasF51 : '',
        CustomerPersonasC52 : '',
        CustomerPersonasE52 : '',
        CustomerPersonasF52 : '',
        CustomerPersonasC53 : '',
        CustomerPersonasE53 : '',
        CustomerPersonasF53 : '',
        CustomerPersonasC54 : '',
        CustomerPersonasE54 : '',
        CustomerPersonasF54 : '',
        CustomerPersonasC55 : '',
        CustomerPersonasE55 : '',
        CustomerPersonasF55 : '',
        CustomerPersonasC58 : '',
        CustomerPersonasC59 : '',
        CustomerPersonasC60 : '',
        CustomerPersonasC61 : '',
        CustomerPersonasC62 : '',
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getCustomerPersonas({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.growthPlanning)
            if(response!= undefined  && response.data.growthPlanning){
                let growthPlanning = response.data.growthPlanning[0];
                console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key]
                    }
                }
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyCustomerPersonas({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

  return (
    <div className="content-body">
    <UserDropdown />
    
        {/* row */}
        <div className="container-fluid">
            <div className="head-title bigsout">
                <h2>Customer Personas</h2>  
                <p>Choosing a&nbsp;Persona&nbsp;to develop should be aligned with the company’s or business unit goals–either by trying to attract a new customer segment or increasing sales from an existing one.  To develop a&nbsp;Persona,&nbsp;it is best to start with a template and outline the basic characteristics of the individual.  Devising a set of marketing messages that are tailored to the&nbsp;Persona’s&nbsp;specific needs, emotions, desires, etc. will increase their appeal and impact.</p>			
            </div>     
            <Spinner />                                           	
            <div className="card">
                <div className="card-body pl-0">  
                <div className="row">
                    <div className="col-md-8">                   
                    <div className="table-responsive">
                        <table className="table">
                        <tbody>
                            <tr className="thead-primary">
                            <th>Persona Name</th>
                            <th className="lightyello">
                                <input type="text" className="form-control text-left inputText"
                                    id="CustomerPersonasD7"
                                    value={Inputs.CustomerPersonasD7}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerPersonasD7: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </th>
                            </tr>
                            <tr>
                            <th className="bluetab">Occupation</th>
                                <td>
                                    <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasD8"
                                        value={Inputs.CustomerPersonasD8}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasD8: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                                </td>
                            </tr>
                            <tr>
                            <th className="bluetab">Other Details</th>
                                <td>
                                    <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasD9"
                                        value={Inputs.CustomerPersonasD9}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasD9: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                                </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body pl-0">
                <div className="table-responsive">
                    <table className="table">
                    <thead>
                        <tr className="thead-primary">
                        <th>Goals <span><img src="../images/svg/icon-1.png" /></span></th>
                        <th>Challenges <span><img src="../images/svg/icon-2.png" /></span></th>
                        <th>Aspirations <span><img src="../images/svg/icon-3.png" /></span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td className="bgradiusf">What is the customer trying to achieve or do?</td>
                        <td className="bgradiusf">What does the customer need to solve or overcome?</td>
                        <td className="bgradiusf">What does the customer want to see in the future?</td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasC13"
                                        value={Inputs.CustomerPersonasC13}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasC13: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasE13"
                                        value={Inputs.CustomerPersonasE13}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasE13: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasF13"
                                        value={Inputs.CustomerPersonasF13}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasF13: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasC14"
                                        value={Inputs.CustomerPersonasC14}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasC14: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasE14"
                                        value={Inputs.CustomerPersonasE14}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasE14: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasF14"
                                        value={Inputs.CustomerPersonasF14}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasF14: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasC15"
                                        value={Inputs.CustomerPersonasC15}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasC15: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasE15"
                                        value={Inputs.CustomerPersonasE15}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasE15: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasF15"
                                        value={Inputs.CustomerPersonasF15}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasF15: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasC16"
                                        value={Inputs.CustomerPersonasC16}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasC16: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasE16"
                                        value={Inputs.CustomerPersonasE16}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasE16: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasF16"
                                        value={Inputs.CustomerPersonasF16}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasF16: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasC17"
                                        value={Inputs.CustomerPersonasC17}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasC17: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasE17"
                                        value={Inputs.CustomerPersonasE17}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasE17: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasF17"
                                        value={Inputs.CustomerPersonasF17}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasF17: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                        </tr>                                    
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body pl-0">
                <div className="table-responsive">
                    <table className="table">
                    <thead>
                        <tr className="thead-primary">
                        <th>Top Opportunities</th>
                        </tr>                                    
                    </thead>
                    <tbody>
                        <tr>
                        <td className="bgradiusf">What are the top opportunities for this persona?</td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasC20"
                                        value={Inputs.CustomerPersonasC20}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasC20: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasC21"
                                        value={Inputs.CustomerPersonasC21}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasC21: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasC22"
                                        value={Inputs.CustomerPersonasC22}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasC22: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasC23"
                                        value={Inputs.CustomerPersonasC23}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasC23: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasC24"
                                        value={Inputs.CustomerPersonasC24}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasC24: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body pl-0">  
                <div className="row">
                    <div className="col-md-8">                   
                    <div className="table-responsive">
                        <table className="table">
                        <tbody>
                            <tr className="thead-primary">
                            <th>Persona Name</th>
                            <th className="lightyello">
                                <input type="text" className="form-control text-left inputText"
                                    id="CustomerPersonasD26"
                                    value={Inputs.CustomerPersonasD26}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerPersonasD26: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </th>
                            </tr>
                            <tr>
                            <th className="bluetab">Occupation</th>
                                <td>
                                    <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasD27"
                                        value={Inputs.CustomerPersonasD27}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasD27: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                                </td>
                            </tr>
                            <tr>
                            <th className="bluetab">Other Details</th>
                                <td>
                                    <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasD28"
                                        value={Inputs.CustomerPersonasD28}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasD28: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                                </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body pl-0">
                <div className="table-responsive">
                    <table className="table">
                    <thead>
                        <tr className="thead-primary">
                        <th>Goals</th>
                        <th>Challenges</th>
                        <th>Aspirations</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td className="bgradiusf">What is the customer trying to achieve or do?</td>
                        <td className="bgradiusf">What does the customer need to solve or overcome?</td>
                        <td className="bgradiusf">What does the customer want to see in the future?</td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasC32"
                                        value={Inputs.CustomerPersonasC32}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasC32: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasE32"
                                        value={Inputs.CustomerPersonasE32}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasE32: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasF32"
                                        value={Inputs.CustomerPersonasF32}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasF32: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasC33"
                                        value={Inputs.CustomerPersonasC33}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasC33: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasE33"
                                        value={Inputs.CustomerPersonasE33}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasE33: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasF33"
                                        value={Inputs.CustomerPersonasF33}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasF33: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasC34"
                                        value={Inputs.CustomerPersonasC34}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasC34: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasE34"
                                        value={Inputs.CustomerPersonasE34}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasE34: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasF34"
                                        value={Inputs.CustomerPersonasF34}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasF34: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasC35"
                                        value={Inputs.CustomerPersonasC35}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasC35: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasE35"
                                        value={Inputs.CustomerPersonasE35}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasE35: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasF35"
                                        value={Inputs.CustomerPersonasF35}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasF35: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasC36"
                                        value={Inputs.CustomerPersonasC36}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasC36: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasE36"
                                        value={Inputs.CustomerPersonasE36}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasE36: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasF36"
                                        value={Inputs.CustomerPersonasF36}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasF36: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                        </tr>                                    
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body pl-0">
                <div className="table-responsive">
                    <table className="table">
                    <thead>
                        <tr className="thead-primary">
                        <th>Top Opportunities</th>
                        </tr>                                    
                    </thead>
                    <tbody>
                        <tr>
                        <td className="bgradiusf">What are the top opportunities for this persona?</td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasC39"
                                        value={Inputs.CustomerPersonasC39}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasC39: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasC40"
                                        value={Inputs.CustomerPersonasC40}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasC40: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasC41"
                                        value={Inputs.CustomerPersonasC41}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasC41: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasC42"
                                        value={Inputs.CustomerPersonasC42}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasC42: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasC43"
                                        value={Inputs.CustomerPersonasC43}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasC43: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body pl-0">  
                <div className="row">
                    <div className="col-md-8">                   
                    <div className="table-responsive">
                        <table className="table">
                        <tbody>
                            <tr className="thead-primary">
                            <th>Persona Name</th>
                            <th className="lightyello">
                                <input type="text" className="form-control text-left inputText"
                                    id="CustomerPersonasD45"
                                    value={Inputs.CustomerPersonasD45}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerPersonasD45: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </th>
                            </tr>
                            <tr>
                            <th className="bluetab">Occupation</th>
                                <td>
                                    <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasD46"
                                        value={Inputs.CustomerPersonasD46}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasD46: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                                </td>
                            </tr>
                            <tr>
                            <th className="bluetab">Other Details</th>
                                <td>
                                    <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasD47"
                                        value={Inputs.CustomerPersonasD47}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasD47: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                                </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body pl-0">
                <div className="table-responsive">
                    <table className="table">
                    <thead>
                        <tr className="thead-primary">
                        <th>Goals</th>
                        <th>Challenges</th>
                        <th>Aspirations</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td className="bgradiusf">What is the customer trying to achieve or do?</td>
                        <td className="bgradiusf">What does the customer need to solve or overcome?</td>
                        <td className="bgradiusf">What does the customer want to see in the future?</td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasC51"
                                        value={Inputs.CustomerPersonasC51}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasC51: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasE51"
                                        value={Inputs.CustomerPersonasE51}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasE51: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasF51"
                                        value={Inputs.CustomerPersonasF51}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasF51: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasC52"
                                        value={Inputs.CustomerPersonasC52}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasC52: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasE52"
                                        value={Inputs.CustomerPersonasE52}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasE52: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasF52"
                                        value={Inputs.CustomerPersonasF52}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasF52: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasC53"
                                        value={Inputs.CustomerPersonasC53}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasC53: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasE53"
                                        value={Inputs.CustomerPersonasE53}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasE53: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasF53"
                                        value={Inputs.CustomerPersonasF53}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasF53: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasC54"
                                        value={Inputs.CustomerPersonasC54}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasC54: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasE54"
                                        value={Inputs.CustomerPersonasE54}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasE54: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasF54"
                                        value={Inputs.CustomerPersonasF54}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasF54: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasC55"
                                        value={Inputs.CustomerPersonasC55}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasC55: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasE55"
                                        value={Inputs.CustomerPersonasE55}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasE55: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasF55"
                                        value={Inputs.CustomerPersonasF55}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasF55: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                        </tr>                                    
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body pl-0">
                <div className="table-responsive">
                    <table className="table">
                    <thead>
                        <tr className="thead-primary">
                        <th>Top Opportunities</th>
                        </tr>                                    
                    </thead>
                    <tbody>
                        <tr>
                        <td className="bgradiusf">What are the top opportunities for this persona?</td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasC58"
                                        value={Inputs.CustomerPersonasC58}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasC58: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasC59"
                                        value={Inputs.CustomerPersonasC59}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasC59: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasC60"
                                        value={Inputs.CustomerPersonasC60}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasC60: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasC61"
                                        value={Inputs.CustomerPersonasC61}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasC61: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" className="form-control text-left inputText"
                                        id="CustomerPersonasC62"
                                        value={Inputs.CustomerPersonasC62}
                                        onChange={(event) => setInputs({ ...Inputs, CustomerPersonasC62: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                            </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            </div>

    </div>

  )
}
