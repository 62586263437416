import React from 'react'
import {useSWRConfig} from "swr";

export default function Footer() {
    const { fallback, isLoading } = useSWRConfig()
    return (
        <div className="footer">
            <div className="copyright">
                <p>
                {
                    (fallback && fallback.settings.is_allow_branding == 1 && fallback.settings.copyRightText) ? 
                    <>{fallback.settings.copyRightText}.</> 
                    : <>Copyright 2023 by RevGrowth OS and Russ Holder.</>
                }
                </p>
            </div>
        </div>
    )
}
