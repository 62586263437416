import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getGrowthProgressReport, modifyGrowthProgressReport } from "../../state/features/GrowthProgressReportSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';

export default function GrowthProgressReportContentNew() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        GrowthProgressReportC14 : '',
        GrowthProgressReportC26 : '',
        GrowthProgressReportD14 : '',
        GrowthProgressReportD26 : '',
        GrowthProgressReportE14 : '',
        GrowthProgressReportE26 : '',
        GrowthProgressReportF14 : '',
        GrowthProgressReportF26 : '',
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getGrowthProgressReport({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.growthPlanning)
            if(response!= undefined){
                let growthPlanning = response.data.growthPlanning[0];
                // console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key]
                    }
                }
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyGrowthProgressReport({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                    // console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
            <div className="container-fluid">
                <div className="head-title">
                    <h3 className='preheading'>Grow</h3>
                    <h1 className='mainheading mb-1'>Growth Progress Report</h1>
                </div>
                <div className="opportunity-page-table">
                    <div className="opportunity-page-table-inner opportunity-abl">
                        <Spinner/>
                        <div className="table-responsive mb-3">
                            <table className='table' style={{width: 1200}}>
                                <thead>
                                    <tr>
                                        <td className='bg-color01 p-2' style={{width: 300}}>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <p className='ms-text-left font18px text-white mb-0'>Goals</p>
                                                <img src={window.location.origin+"/images/growth-operating/image742.png"}  alt="img_01" style={{width:40, height: 40}}/>
                                            </div>
                                        </td>
                                        <td className='bg-color01 p-2' style={{width: 300}}>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <p className='ms-text-left font18px text-white mb-0'>Progress</p>
                                                <img src={window.location.origin+"/images/growth-operating/image743.png"}  alt="img_01" style={{width:40, height: 40}}/>
                                            </div>
                                        </td>
                                        <td className='bg-color01 p-2' style={{width: 300}}>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <p className='ms-text-left font18px text-white mb-0'>Momentum</p>
                                                <img src={window.location.origin+"/images/growth-operating/image745.png"}  alt="img_01" style={{width:40, height: 40}}/>
                                            </div>
                                        </td>
                                        <td className='bg-color01 p-2' style={{width: 300}}>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <p className='ms-text-left font18px text-white mb-0'>Motivation</p>
                                                <img src={window.location.origin+"/images/growth-operating/image746.png"}  alt="img_01" style={{width:40, height: 40}}/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-03 ms-text-left font12px text-white'>
                                        Building a Desired Future
                                        </td>
                                        <td className='bg-color-03 ms-text-left font12px text-white'>
                                        What We've Accomplished
                                        </td>
                                        <td className='bg-color-03 ms-text-left font12px text-white'>
                                        Building Confidence
                                        </td>
                                        <td className='bg-color-03 ms-text-left font12px text-white'>
                                        Constant Progress Toward Goals
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color-blue-gray text-white font11px">What are the strategic goals you would like to achieve over the next quarter and year?</td>
                                        <td className="bg-color-blue-gray text-white font11px">What have you achieved in the past quarter that makes you proud?</td>
                                        <td className="bg-color-blue-gray text-white font11px">What areas of focus, progress, or performance make you the most confident?</td>
                                        <td className="bg-color-blue-gray text-white font11px">What new opportunities or strategies give you the greatest sense of excitement?</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{height: 200}} className='padding-0 bg-color-02'>
                                            <div className="input-fiel-cn bg-color-02">
                                                <textarea type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                id="GrowthProgressReportC14"
                                                value={Inputs.GrowthProgressReportC14}
                                                onChange={(event) => setInputs({ ...Inputs, GrowthProgressReportC14: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td style={{height: 200}} className='padding-0 bg-color-02'>
                                            <div className="input-fiel-cn bg-color-02">
                                                <textarea type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                id="GrowthProgressReportD14"
                                                value={Inputs.GrowthProgressReportD14}
                                                onChange={(event) => setInputs({ ...Inputs, GrowthProgressReportD14: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td style={{height: 200}} className='padding-0 bg-color-02'>
                                            <div className="input-fiel-cn bg-color-02">
                                                <textarea type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                id="GrowthProgressReportE14"
                                                value={Inputs.GrowthProgressReportE14}
                                                onChange={(event) => setInputs({ ...Inputs, GrowthProgressReportE14: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td style={{height: 200}} className='padding-0 bg-color-02'>
                                            <div className="input-fiel-cn bg-color-02">
                                                <textarea type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                id="GrowthProgressReportF14"
                                                value={Inputs.GrowthProgressReportF14}
                                                onChange={(event) => setInputs({ ...Inputs, GrowthProgressReportF14: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-03 text-white text-center font14px'>
                                        Achievement 1
                                        </td>
                                        <td className='bg-color-03 text-white text-center font14px'>
                                        Achievement 2
                                        </td>
                                        <td className='bg-color-03 text-white text-center font14px'>
                                        Achievement 3
                                        </td>
                                        <td className='bg-color-03 text-white text-center font14px'>
                                        Achievement 4
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{height: 120}} className='padding-0 bg-color-02'>
                                            <div className="input-fiel-cn bg-color-02">
                                                <textarea type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                id="GrowthProgressReportC26"
                                                value={Inputs.GrowthProgressReportC26}
                                                onChange={(event) => setInputs({ ...Inputs, GrowthProgressReportC26: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td style={{height: 120}} className='padding-0 bg-color-02'>
                                            <div className="input-fiel-cn bg-color-02">
                                                <textarea type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                id="GrowthProgressReportD26"
                                                value={Inputs.GrowthProgressReportD26}
                                                onChange={(event) => setInputs({ ...Inputs, GrowthProgressReportD26: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td style={{height: 120}} className='padding-0 bg-color-02'>
                                            <div className="input-fiel-cn bg-color-02">
                                                <textarea type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                id="GrowthProgressReportE26"
                                                value={Inputs.GrowthProgressReportE26}
                                                onChange={(event) => setInputs({ ...Inputs, GrowthProgressReportE26: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td style={{height: 120}} className='padding-0 bg-color-02'>
                                            <div className="input-fiel-cn bg-color-02">
                                                <textarea type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                id="GrowthProgressReportF26"
                                                value={Inputs.GrowthProgressReportF26}
                                                onChange={(event) => setInputs({ ...Inputs, GrowthProgressReportF26: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
