import React from 'react'
import Sidebar from '../components/SidebarNew';
import Content from '../components/content/RevenueGenerationScorecardContent';
import Footer from '../components/Footer';

export default function RevenueGenerationScorecard() {

    return (
        <div>

            <div id="main-wrapper">

                <Sidebar />

                <Content />

            </div>

            <Footer />

        </div>
    );
}