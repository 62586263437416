import React from 'react';
import { Link, useLocation } from "react-router-dom";
import  UserDropdown  from '../UserDropdown';
import CopyRightText from '../CopyRightText';
import {useSWRConfig} from "swr";
import getMenuChildren from '../../utils/getMenuChildren';
import MenuSingleList from '../List/MenuSingleList';
import MenuMultiList from '../List/MenuMultiList';

const PrescribeContent = () => {
    const { fallback, isLoading } = useSWRConfig();
    const location = useLocation();
    const submenus = getMenuChildren(location.pathname, fallback?.menus[0].children)
    return (
        <div className="content-body bg-dard-color bg_bottom">
            <UserDropdown />
            {/* row */}
            <div className="container-fluid">
                <div className="head-title">
                    <Link to="/home"><h3 className='font-sise-20'>The Growth Catalyst</h3></Link>
                    <h1 className='font-sise-42 the-revense'>Prescribe</h1>
                    <div className='row'>
                        <div className="col-12 col-md-8 col-lg-10">
                            <div className="leftContetn">
                                <p className='font-sise-16'>
                                    Identify the best opportunities and most direct path to achieve your growth goals.
                                </p>
                                <ul className='list-of-data'>
                                    <li className='font-sise-16'>Quantify the potential benefit for each of the Nine Revenue Growth Drivers.</li>
                                    <li className='font-sise-16'>Calculate how a few simple 1% improvements can drive exponential growth in sales, profit, and valuation.</li>
                                    <li className='font-sise-16'>Choose the tactics and activities that have the greatest potential for success.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-2">
                            <div className="imageBx">
                                <img src={window.location.origin+"/images/Home_page_img/Picture3.png"} width={150 + 'px'} height={150 + 'px'} alt="Revenue Growth Drivers" title="Revenue Growth Drivers" />
                            </div>
                        </div>
                    </div>
                    <div className="row card-rwo diagnose-page mt-5" style={{maxWidth: 1400}}>
                        {submenus.map((menu, index) => (
                            <div className={`col-12 col-md-6 ${(menu.children.length < 10)? 'col-lg-3' : ''} mar-b`} key={menu.parent_id+"_"+index}>
                                <div className="diagnosediv prescribe-hg">
                                    <h3 className='font-sise-bold-18'>
                                        <Link to="#">{menu.label}</Link>
                                    </h3>
                                    {
                                        (menu.children.length < 10)?
                                        <MenuSingleList submenu={menu.children} id={menu.parent_id+"_"+index}/>
                                        :
                                        <MenuMultiList submenu={menu.children} id={menu.parent_id+"_"+index}/>
                                    }
                                </div>
                            </div>
                        ))}
                        {/* <div className="col-12 col-md-6 col-lg-3 mar-b">
                            <div className="diagnosediv prescribe-hg">
                                <h3 className='font-sise-bold-18'>
                                    <Link to="#">Revenue Drivers</Link>
                                </h3>
                                <ul className='list-of-data'>
                                    <li className='font-sise-16'><Link to="/prescribe/lead-generation">Lead Generation</Link></li>
                                    <li className='font-sise-16'><Link to="/prescribe/conversion">Conversion</Link></li>
                                    <li className='font-sise-16'><Link to="/prescribe/referrals">Referrals</Link></li>
                                    <li className='font-sise-16'><Link to="/prescribe/purchase-frequency">Purchase Frequency</Link></li>
                                    <li className='font-sise-16'><Link to="/prescribe/buying-lifetime">Buying Lifetime</Link></li>
                                    <li className='font-sise-16'><Link to="/prescribe/customer-attrition">Reduce Attrition</Link></li>
                                    <li className='font-sise-16'><Link to="/prescribe/customer-reactivation">Customer Reactivation</Link></li>
                                    <li className='font-sise-16'><Link to="/prescribe/average-transaction-value">Average Transaction Value</Link></li>
                                    <li className='font-sise-16'><Link to="/prescribe/profit-margin">Profit Margin</Link></li>
                                    
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mar-b">
                            <div className="diagnosediv prescribe-hg">
                                <h3 className='font-sise-bold-18'>
                                    <Link to="#">Opportunity Analysis</Link>
                                </h3>
                                <ul className='list-of-data'>
                                    <li className='font-sise-16'><Link to="/prescribe/the-compounding-effect">The Compounding Effect</Link></li>
                                    <li className='font-sise-16'><Link to="/prescribe/find-the-Low-Hanging-Fruit">Low-Hanging Fruit</Link></li>
                                    <li className='font-sise-16'><Link to="/prescribe/opportunity-ranking-analysis">Opportunity Ranking Analysis</Link></li>
                                    <li className='font-sise-16'><Link to="/prescribe/weighted-decision-matrix">Weighted Decision Matrix</Link></li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>

                <div className='copyRgight'>
                    <CopyRightText />
                </div>

                <div className='space-di'></div>

            </div>
        </div>
    );
}

export default PrescribeContent;
