import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import sum from "../../utils/sum";
import { Link } from 'react-router-dom';
import formatPrice from '../../utils/formatPrice';
import { getReferralGenerationScorecard, modifyReferralGenerationScorecard } from '../../state/features/ReferralGenerationScorecardSlice';

export default function ReferralGenerationScorecardContent() {

    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        ReferralGenerationScorecardF6: 0,
        ReferralGenerationScorecardF7: 0,
        ReferralGenerationScorecardF8: 0,
        ReferralGenerationScorecardF9: 0,
        ReferralGenerationScorecardF10: 0,
        ReferralGenerationScorecardF11: 0,
        ReferralGenerationScorecardF12: 0,
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getReferralGenerationScorecard({user_id}))
        .unwrap()
        .then((response) => {
            console.log("response1",response.data)
            if(response!= undefined  && response.data.clientGrowth){
                let clientGrowth = response.data.clientGrowth[0];
                // console.log(clientGrowth)
                delete clientGrowth.id;
                delete clientGrowth.user_id;
                delete clientGrowth.created_at;
                delete clientGrowth.updated_at;
                const newObj = { ...Inputs }
                for (const key in clientGrowth) {
                    if (clientGrowth.hasOwnProperty(key)) {
                        if(clientGrowth[key])
                        newObj[key] = clientGrowth[key]
                    }
                }
                
                newObj['ReferralGenerationScorecardF12'] = sum(newObj['ReferralGenerationScorecardF6'], newObj['ReferralGenerationScorecardF7'], newObj['ReferralGenerationScorecardF8'], newObj['ReferralGenerationScorecardF9'], newObj['ReferralGenerationScorecardF10'], newObj['ReferralGenerationScorecardF11']);

                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                event.target.value = event.target.value ? event.target.value : 0
                dispatch(modifyReferralGenerationScorecard({ user_id, ...Inputs,[event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                    // console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }
    
    useEffect(() => {
        let ReferralGenerationScorecardF12 = sum(Inputs.ReferralGenerationScorecardF6, Inputs.ReferralGenerationScorecardF7, Inputs.ReferralGenerationScorecardF8, Inputs.ReferralGenerationScorecardF9, Inputs.ReferralGenerationScorecardF10, Inputs.ReferralGenerationScorecardF11)

        setInputs({...Inputs, ReferralGenerationScorecardF12});

    }, [Inputs.ReferralGenerationScorecardF6, Inputs.ReferralGenerationScorecardF7, Inputs.ReferralGenerationScorecardF8, Inputs.ReferralGenerationScorecardF9, Inputs.ReferralGenerationScorecardF10, Inputs.ReferralGenerationScorecardF11]);

    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
            <div className="container-fluid">
                <div className="head-title">
                    <h3 className='font-sise-20'>Diagnose - Benchmark Tools</h3>
                    <h1 className='font-sise-42 color-drk'>Referral Generation Scorecard</h1>
                </div>
                <Spinner />
                <div className="target-markets-score-card-table">
                    <div className="table-responsive">
                        <table className="table" style={{maxWidth: 1000}}>
                            <thead>
                                <tr>
                                    <th className='black-bg text-white font12px text-center' style={{width: 45}}>24</th>
                                    <th className='black-bg text-white' colSpan={3} style={{width: 400}}>
                                        <div className="before-your justify-content-left">
                                            <p>What do you know about referral generation in your business?</p>
                                        </div>
                                    </th>
                                    <th className='black-bg text-white' style={{width: 200}}>
                                        <div className="score-point-each">
                                            <p><strong className='scre-fonts'>Score</strong><small>(1=Low; 5=High)</small></p>
                                        </div>
                                    </th>
                                    <th className='hide-content'></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th className='hide-content'></th>
                                    <td className='black-bg text-white font12px text-center' style={{width: 45}}>a</td>
                                    <td className='bg-color-light-blue color-black font15px' colSpan={2}>
                                        <div className="we-understand">
                                            <p>Our products and services are of excellent quality and generate referrals regularly.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font15px bg-color-01 text-center text-black" 
                                                id="ReferralGenerationScorecardF6" 
                                                value={Inputs.ReferralGenerationScorecardF6} 
                                                onChange={(event) => setInputs({ ...Inputs, ReferralGenerationScorecardF6: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score Range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='hide-content'></td>
                                </tr>
                                <tr>
                                    <th className='hide-content'></th>
                                    <td className='black-bg text-white font12px text-center' style={{width: 45}}>b</td>
                                    <td className='bg-color-light-blue color-black font15px' colSpan={2}>
                                        <div className="we-understand">
                                            <p>Our customer service is excellent.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font15px bg-color-01 text-center text-black" 
                                                id="ReferralGenerationScorecardF7" 
                                                value={Inputs.ReferralGenerationScorecardF7} 
                                                onChange={(event) => setInputs({ ...Inputs, ReferralGenerationScorecardF7: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score Range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='hide-content'></td>
                                </tr>
                                <tr>
                                    <th className='hide-content'></th>
                                    <td className='black-bg text-white font12px text-center' style={{width: 45}}>c</td>
                                    <td className='bg-color-light-blue color-black font15px' colSpan={2}>
                                        <div className="we-understand">
                                            <p>We have a system in place to follow up with referrals; includes tracking and accountabilities.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font15px bg-color-01 text-center text-black" 
                                                id="ReferralGenerationScorecardF8" 
                                                value={Inputs.ReferralGenerationScorecardF8} 
                                                onChange={(event) => setInputs({ ...Inputs, ReferralGenerationScorecardF8: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score Range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='hide-content'></td>
                                </tr>
                                <tr>
                                    <th className='hide-content'></th>
                                    <td className='black-bg text-white font12px text-center' style={{width: 45}}>d</td>
                                    <td className='bg-color-light-blue color-black font15px' colSpan={2}>
                                        <div className="we-understand">
                                            <p>We have a system in place to reward people for their referrals.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font15px bg-color-01 text-center text-black" 
                                                id="ReferralGenerationScorecardF9" 
                                                value={Inputs.ReferralGenerationScorecardF9} 
                                                onChange={(event) => setInputs({ ...Inputs, ReferralGenerationScorecardF9: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score Range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='hide-content'></td>
                                </tr>
                                <tr>
                                    <th className='hide-content'></th>
                                    <td className='black-bg text-white font12px text-center' style={{width: 45}}>e</td>
                                    <td className='bg-color-light-blue color-black font15px' colSpan={2}>
                                        <div className="we-understand">
                                            <p>We know exactly how many referrals we generated last year.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font15px bg-color-01 text-center text-black" 
                                                id="ReferralGenerationScorecardF10" 
                                                value={Inputs.ReferralGenerationScorecardF10} 
                                                onChange={(event) => setInputs({ ...Inputs, ReferralGenerationScorecardF10: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score Range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='hide-content'></td>
                                </tr>
                                <tr>
                                    <th className='hide-content'></th>
                                    <td className='black-bg text-white font12px text-center' style={{width: 45}}>f</td>
                                    <td className='bg-color-light-blue color-black font15px' colSpan={2}>
                                        <div className="we-understand">
                                            <p>We know the number of referrals we get each year is increasing (tracking).</p>
                                        </div>
                                    </td>
                                    <td className='padding-0'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font15px bg-color-01 text-center text-black" 
                                                id="ReferralGenerationScorecardF11" 
                                                value={Inputs.ReferralGenerationScorecardF11} 
                                                onChange={(event) => setInputs({ ...Inputs, ReferralGenerationScorecardF11: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score Range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='hide-content'></td>
                                </tr>
                                <tr>
                                    <td className='hide-content' colSpan={3}></td>
                                    <th className='bg-color-blue text-white text-center'>
                                        <div className="score-point-each ms-text-center">
                                            <strong className='scre-fonts'>Total</strong>
                                        </div>
                                    </th>
                                    <th className='bg-color-blue text-white text-center'>
                                        <div className="do-you-number">{formatPrice(Inputs.ReferralGenerationScorecardF12, 0)}</div>
                                    </th>
                                    <td className='hide-content' colSpan={2}></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="nextInput">
                        <ul>
                            <li>
                                <Link className="next-link" to="/diagnose/customer-acquisition"> Return to Benchmark Questions
                                    <span className="icon-three">
                                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                                    </span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}