import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getSCQAFramework, modifySCQAFramework } from "../../state/features/SCQAFrameworkSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';

export default function SCQAFrameworkContent() {

    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        SCQAFrameworkG10 : '',
        SCQAFrameworkG15 : '',
        SCQAFrameworkG20 : '',
        SCQAFrameworkG27 : '',
        SCQAFrameworkG32 : '',
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getSCQAFramework({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.growthPlanning)
            if(response!= undefined  && response.data.growthPlanning){
                let growthPlanning = response.data.growthPlanning[0];
                console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key]
                    }
                }
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifySCQAFramework({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        //window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

  return (
    <div className="content-body">
        <UserDropdown />
        
        {/* row */}
       <div className="container-fluid">
            <div className="head-title">
                <p>Messaging </p>
                <h2>SCQA Framework </h2>
                <p className="mb-5">SCQA is a framework for structuring information.  Using this framework, you can structure your message in a clear, attractive and narrative way.  The SCQA Framework is a proven system used often in business, science, policy, and many others. </p>
            </div>
            <Spinner />
            <div className="card">
                <div className="card-body pl-0">
                <div className="table-responsive table-dta">
                    <table className="table mb-0">
                    <tbody>
                        <tr>
                        <td className="width-10 bg-color1 text-center-c padding-10">
                            <div className="images-bx">
                            <img src="../images/icon01-c.png" className="img-fluid" alt="icon_1" />
                            </div>
                        </td>
                        <td colSpan={4} className="bg-color1 text-white width-35 font-size-18">Situation</td>
                        <td rowSpan={4} className="width-5 bg-color3 text-white text-center-c">
                            <span className="rotate-tex">
                            Situation
                            </span>
                        </td>
                        <td rowSpan={4} colSpan={4} className="bg-color2 font-size-18 text-white text-center-c width-50">
                            <textarea type="text" className="inputText"
                                rows={10} 
                                cols={20}
                                id="SCQAFrameworkG10"
                                value={Inputs.SCQAFrameworkG10}
                                onChange={(event) => setInputs({ ...Inputs, SCQAFrameworkG10: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td className="width-10 text-center-c">1</td>
                        <td colSpan={4}>What is happening?</td>
                        </tr>
                        <tr>
                        <td className="width-10 text-center-c">2</td>
                        <td colSpan={4}>What is the current state?</td>
                        </tr>
                        <tr>
                        <td className="width-10 text-center-c">3</td>
                        <td colSpan={4}>What is the base state that you can agree on?</td>
                        </tr>
                    </tbody>
                    </table>
                    {/* table 2 */}
                    <table className="table mb-0 mt-3">
                    <tbody>
                        <tr>
                        <td className="width-10 bg-color1 text-center-c padding-10">
                            <div className="images-bx">
                            <img src="../images/icon02-c.png" className="img-fluid" alt="icon_1" />
                            </div>
                        </td>
                        <td colSpan={4} className="bg-color1 text-white width-35 font-size-18">Complication</td>
                        <td rowSpan={4} className="width-5 bg-color3 text-white text-center-c">
                            <span className="rotate-tex">
                            Complication
                            </span>
                        </td>
                        <td rowSpan={4} colSpan={4} className="bg-color2 font-size-18 text-white text-center-c width-50">
                            <textarea type="text" className="inputText"
                                rows={10} 
                                cols={20}
                                id="SCQAFrameworkG15"
                                value={Inputs.SCQAFrameworkG15}
                                onChange={(event) => setInputs({ ...Inputs, SCQAFrameworkG15: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td className="width-10 text-center-c">1</td>
                        <td colSpan={4}>What is the complication?</td>
                        </tr>
                        <tr>
                        <td className="width-10 text-center-c">2</td>
                        <td colSpan={4}>What is the tension?</td>
                        </tr>
                        <tr>
                        <td className="width-10 text-center-c">3</td>
                        <td colSpan={4}>What has changed to cause the need for a new answer?</td>
                        </tr>
                    </tbody>
                    </table>
                    {/* table 3 */}
                    <table className="table mb-0 mt-3">
                    <tbody>
                        <tr>
                        <td className="width-10 bg-color1 text-center-c padding-10">
                            <div className="images-bx">
                            <img src="../images/icon03-c.png" className="img-fluid" alt="icon_1" />
                            </div>
                        </td>
                        <td colSpan={4} className="bg-color1 text-white width-35 font-size-18">Hypothesis:  Why Problem Exists</td>
                        <td rowSpan={5} className="width-5 bg-color3 text-white text-center-c">
                            <span className="rotate-tex">
                            Hypothesis
                            </span>
                        </td>
                        <td rowSpan={5} colSpan={4} className="bg-color2 font-size-18 text-white text-center-c width-50">
                            <textarea type="text" className="inputText"
                                rows={10} 
                                cols={20}
                                id="SCQAFrameworkG20"
                                value={Inputs.SCQAFrameworkG20}
                                onChange={(event) => setInputs({ ...Inputs, SCQAFrameworkG20: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td className="width-10 text-center-c">1</td>
                        <td colSpan={4}>Are these at the same level of logic?</td>
                        </tr>
                        <tr>
                        <td className="width-10 text-center-c">2</td>
                        <td colSpan={4}>Are they Mutually Exclusive?</td>
                        </tr>
                        <tr>
                        <td className="width-10 text-center-c">3</td>
                        <td colSpan={4}>Are they Collectively Exhaustive?</td>
                        </tr>
                        <tr>
                        <td className="width-10 text-center-c">4</td>
                        <td colSpan={4}>Should have three supporting points of evidence for each.</td>
                        </tr>
                    </tbody>
                    </table>
                    {/* table 4 */}
                    <table className="table mb-0 mt-3">
                    <tbody>
                        <tr>
                        <td className="width-10 bg-color1 text-center-c padding-10">
                            <div className="images-bx">
                            <img src="../images/icon04-c.png" className="img-fluid" alt="icon_1" />
                            </div>
                        </td>
                        <td colSpan={4} className="bg-color1 text-white width-35 font-size-18">Problem Statement (Big Idea)</td>
                        <td rowSpan={2} className="width-5 bg-color3 text-white text-center-c">
                            <span className="rotate-tex">
                            Problem Statement
                            </span>
                        </td>
                        <td rowSpan={2} colSpan={4} className="bg-color2 text-white text-center-c width-50">
                            <textarea type="text" className="inputText"
                                rows={10} 
                                cols={20}
                                id="SCQAFrameworkG27"
                                value={Inputs.SCQAFrameworkG27}
                                onChange={(event) => setInputs({ ...Inputs, SCQAFrameworkG27: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td className="width-10 text-center-c">1</td>
                        <td colSpan={4}>What is the overall problem, based on the three hypothesis?</td>
                        </tr>
                    </tbody>
                    </table>
                    {/* table 5 */}
                    <table className="table mb-0 mt-3">
                    <tbody>
                        <tr>
                        <td className="width-10 bg-color1 text-center-c padding-10">
                            <div className="images-bx">
                            <img src="../images/icon05-c.png" className="img-fluid" alt="icon_1" />
                            </div>
                        </td>
                        <td colSpan={4} className="bg-color1 text-white width-35 font-size-18">Answer (Solution)</td>
                        <td rowSpan={2} className="width-5 bg-color3 text-white text-center-c">
                            <span className="rotate-tex">
                            Solution
                            </span>
                        </td>
                        <td rowSpan={2} colSpan={4} className="bg-color2 text-white text-center-c width-50">
                            <textarea type="text" className="inputText"
                                rows={10} 
                                cols={20}
                                id="SCQAFrameworkG32"
                                value={Inputs.SCQAFrameworkG32}
                                onChange={(event) => setInputs({ ...Inputs, SCQAFrameworkG32: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td className="width-10 text-center-c">1</td>
                        <td colSpan={4}>What solves the question and the problem?</td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            </div>

    </div>
  )
}
