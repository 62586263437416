import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import sum from "../../utils/sum";
import { Link } from 'react-router-dom';
import formatPrice from '../../utils/formatPrice';
import { getCustomerAcquisitionScorecard, modifyCustomerAcquisitionScorecard } from '../../state/features/CustomerAcquisitionScorecardSlice';

export default function CustomerAcquisitionScorecardContentNew() {

    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        CustomerAcquisitionScorecardD10: 0,
        CustomerAcquisitionScorecardD11: 0,
        CustomerAcquisitionScorecardD12: 0,
        CustomerAcquisitionScorecardD13: 0,
        CustomerAcquisitionScorecardD14: 0,
        CustomerAcquisitionScorecardE9: "",
        CustomerAcquisitionScorecardF10: "",
        CustomerAcquisitionScorecardD16: 0,
        CustomerAcquisitionScorecardD17: 0,
        CustomerAcquisitionScorecardD18: 0,
        CustomerAcquisitionScorecardD19: 0,
        CustomerAcquisitionScorecardD20: 0,
        CustomerAcquisitionScorecardE15: "",
        CustomerAcquisitionScorecardF16: "",
        CustomerAcquisitionScorecardD22: 0,
        CustomerAcquisitionScorecardD23: 0,
        CustomerAcquisitionScorecardD24: 0,
        CustomerAcquisitionScorecardD25: 0,
        CustomerAcquisitionScorecardD26: 0,
        CustomerAcquisitionScorecardD27: 0,
        CustomerAcquisitionScorecardD28: 0,
        CustomerAcquisitionScorecardD29: 0,
        CustomerAcquisitionScorecardD30: 0,
        CustomerAcquisitionScorecardD31: 0,
        CustomerAcquisitionScorecardE21: "",
        CustomerAcquisitionScorecardF22: "",
        CustomerAcquisitionScorecardE33: "",
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getCustomerAcquisitionScorecard({user_id}))
        .unwrap()
        .then((response) => {
            console.log("response1",response.data)
            if(response!= undefined  && response.data.clientGrowth){
                let clientGrowth = response.data.clientGrowth[0];
                // console.log(clientGrowth)
                delete clientGrowth.id;
                delete clientGrowth.user_id;
                delete clientGrowth.created_at;
                delete clientGrowth.updated_at;
                const newObj = { ...Inputs }
                for (const key in clientGrowth) {
                    if (clientGrowth.hasOwnProperty(key)) {
                        if(clientGrowth[key])
                        newObj[key] = clientGrowth[key]
                    }
                }

                // newObj['CustomerAcquisitionScorecardE9'] = sum(newObj['CustomerAcquisitionScorecardD10'], newObj['CustomerAcquisitionScorecardD11'], newObj['CustomerAcquisitionScorecardD12'], newObj['CustomerAcquisitionScorecardD13'], newObj['CustomerAcquisitionScorecardD14']);
                // newObj['CustomerAcquisitionScorecardE15'] = sum(newObj['CustomerAcquisitionScorecardD16'], newObj['CustomerAcquisitionScorecardD17'], newObj['CustomerAcquisitionScorecardD18'], newObj['CustomerAcquisitionScorecardD19'], newObj['CustomerAcquisitionScorecardD20']);
                // newObj['CustomerAcquisitionScorecardE21'] = sum(newObj['CustomerAcquisitionScorecardD22'], newObj['CustomerAcquisitionScorecardD23'], newObj['CustomerAcquisitionScorecardD24'], newObj['CustomerAcquisitionScorecardD25'], newObj['CustomerAcquisitionScorecardD26'], newObj['CustomerAcquisitionScorecardD27'], newObj['CustomerAcquisitionScorecardD28'], newObj['CustomerAcquisitionScorecardD29'], newObj['CustomerAcquisitionScorecardD30'], newObj['CustomerAcquisitionScorecardD31']);

                // newObj['CustomerAcquisitionScorecardE33'] = newObj['CustomerAcquisitionScorecardE9'] + newObj['CustomerAcquisitionScorecardE15'] + newObj['CustomerAcquisitionScorecardE21'];
                
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

    }, [dispatch])
    //
    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                event.target.value = event.target.value ? event.target.value : 0
                dispatch(modifyCustomerAcquisitionScorecard({ user_id, [event.target.id] : event.target.value, 'CustomerAcquisitionScorecardE9': String(Inputs.CustomerAcquisitionScorecardE9), 'CustomerAcquisitionScorecardE15': String(Inputs.CustomerAcquisitionScorecardE15), 'CustomerAcquisitionScorecardE21': String(Inputs.CustomerAcquisitionScorecardE21), 'CustomerAcquisitionScorecardE33': String(Inputs.CustomerAcquisitionScorecardE33)}))
                .unwrap()
                .then((response) => {
                    // console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        //window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

    useEffect(() => {
        let CustomerAcquisitionScorecardE9 = sum(Number(Inputs.CustomerAcquisitionScorecardD10), Number(Inputs.CustomerAcquisitionScorecardD11), Number(Inputs.CustomerAcquisitionScorecardD12), Number(Inputs.CustomerAcquisitionScorecardD13), Number(Inputs.CustomerAcquisitionScorecardD14));
        let CustomerAcquisitionScorecardE15 = sum(Number(Inputs.CustomerAcquisitionScorecardD16), Number(Inputs.CustomerAcquisitionScorecardD17), Number(Inputs.CustomerAcquisitionScorecardD18), Number(Inputs.CustomerAcquisitionScorecardD19), Number(Inputs.CustomerAcquisitionScorecardD20));
        let CustomerAcquisitionScorecardE21 = sum(Number(Inputs.CustomerAcquisitionScorecardD22), Number(Inputs.CustomerAcquisitionScorecardD23), Number(Inputs.CustomerAcquisitionScorecardD24), Number(Inputs.CustomerAcquisitionScorecardD25), Number(Inputs.CustomerAcquisitionScorecardD26), Number(Inputs.CustomerAcquisitionScorecardD27), Number(Inputs.CustomerAcquisitionScorecardD28), Number(Inputs.CustomerAcquisitionScorecardD29), Number(Inputs.CustomerAcquisitionScorecardD30), Number(Inputs.CustomerAcquisitionScorecardD31));

        let CustomerAcquisitionScorecardE33 = CustomerAcquisitionScorecardE9 + CustomerAcquisitionScorecardE15 + CustomerAcquisitionScorecardE21;

        setInputs({...Inputs, CustomerAcquisitionScorecardE9, CustomerAcquisitionScorecardE15, CustomerAcquisitionScorecardE21, CustomerAcquisitionScorecardE33});

    }, [Inputs.CustomerAcquisitionScorecardD10, Inputs.CustomerAcquisitionScorecardD11, Inputs.CustomerAcquisitionScorecardD12, Inputs.CustomerAcquisitionScorecardD13, Inputs.CustomerAcquisitionScorecardD14, Inputs.CustomerAcquisitionScorecardD16, Inputs.CustomerAcquisitionScorecardD17, Inputs.CustomerAcquisitionScorecardD18, Inputs.CustomerAcquisitionScorecardD19, Inputs.CustomerAcquisitionScorecardD20, Inputs.CustomerAcquisitionScorecardD22, Inputs.CustomerAcquisitionScorecardD23, Inputs.CustomerAcquisitionScorecardD24, Inputs.CustomerAcquisitionScorecardD25, Inputs.CustomerAcquisitionScorecardD26, Inputs.CustomerAcquisitionScorecardD27, Inputs.CustomerAcquisitionScorecardD28, Inputs.CustomerAcquisitionScorecardD29, Inputs.CustomerAcquisitionScorecardD30, Inputs.CustomerAcquisitionScorecardD31]);


    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
            <div className="container-fluid">
                <div className="head-title">
                    <h3 className='preheading'>Diagnose - Benchmark Tools</h3>
                    <h1 className='mainheading mb-1'>Customer Acquisition Scorecard</h1>
                </div>
                <div className="target-markets-score-card-table">
                    <div className="table-responsive">
                        <table className="table" style={{width: 1300}}>
                            {/* table 01 */}
                            <thead>
                                <tr>
                                    <td className='black-bg text-white' style={{width: 700}}>
                                        <div className="before-your justify-content-between">
                                            <p className='font18px'>Lead Generation</p>
                                            <img src={window.location.origin+"/images/customer-data-scorecard/image294.png"} alt=""/>
                                        </div>
                                    </td>
                                    <td className='black-bg text-white text-center' style={{width: 150}}>
                                        <span className='font14px'>Score</span><br/><span className='font11px'>(1 point each)</span>
                                    </td>
                                    <td className='bg-color-blue text-white text-center font16px' style={{width: 50}}>
                                        {formatPrice(Inputs.CustomerAcquisitionScorecardE9, 0)}
                                    </td>
                                    <td className='black-bg text-white text-center' style={{width: 500}}>
                                        <div className="before-your justify-content-between">
                                            <p className='font18px'>Opportunities to Improve</p>
                                            <img src={window.location.origin+"/images/customer-data-scorecard/image305.png"}  alt=""/>
                                        </div>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>We understand how many leads need to be generated monthly to achieve our growth goals.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerAcquisitionScorecardD10" 
                                                value={Inputs.CustomerAcquisitionScorecardD10} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerAcquisitionScorecardD10: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td rowSpan={5} className='bg-white padding-0'>
                                        <div className="arrow-targer-market"></div>
                                    </td>
                                    <td rowSpan={5} className='padding-0 bg-color-02'>
                                        <div className="input-fiel-cn">
                                            <textarea type="text" className="field-ipt bg-color-02 font11px color-dark"
                                            id="CustomerAcquisitionScorecardF10"
                                            value={Inputs.CustomerAcquisitionScorecardF10}
                                            onChange={(event) => setInputs({ ...Inputs, CustomerAcquisitionScorecardF10: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>We produce enough leads for our company to be successful.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerAcquisitionScorecardD11" 
                                                value={Inputs.CustomerAcquisitionScorecardD11} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerAcquisitionScorecardD11: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>We track how many leads are generated through marketing, sales prospecting, and referrals.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerAcquisitionScorecardD12" 
                                                value={Inputs.CustomerAcquisitionScorecardD12} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerAcquisitionScorecardD12: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>Management, sales, and marketing have a common definition of a qualified lead.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerAcquisitionScorecardD13" 
                                                value={Inputs.CustomerAcquisitionScorecardD13} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerAcquisitionScorecardD13: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>We understand the journey a prospect goes through when researching our products, services, solutions, or company.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerAcquisitionScorecardD14" 
                                                value={Inputs.CustomerAcquisitionScorecardD14} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerAcquisitionScorecardD14: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            {/* table 02 */}
                            <thead>
                                <tr>
                                    <td className='black-bg text-white' style={{width: 700}}>
                                        <div className="before-your justify-content-between">
                                            <p className='font18px'>Conversion Tracking</p>
                                            <img src={window.location.origin+"/images/customer-data-scorecard/image296.png"} alt=""/>
                                        </div>
                                    </td>
                                    <td className='black-bg text-white text-center' style={{width: 150}}>
                                        <span className='font14px'>Score</span><br/><span className='font11px'>(1 point each)</span>
                                    </td>
                                    <td className='bg-color-blue text-white text-center font16px' style={{width: 50}}>
                                        {formatPrice(Inputs.CustomerAcquisitionScorecardE15, 0)}
                                    </td>
                                    <td className='black-bg text-white text-center' style={{width: 500}}>
                                        <div className="before-your justify-content-between">
                                            <p className='font18px'>Opportunities to Improve</p>
                                            <img src={window.location.origin+"/images/customer-data-scorecard/image307.png"}  alt=""/>
                                        </div>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>We track the exact number of transactions closed each month.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerAcquisitionScorecardD16" 
                                                value={Inputs.CustomerAcquisitionScorecardD16} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerAcquisitionScorecardD16: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td rowSpan={5} className='bg-white padding-0'>
                                        <div className="arrow-targer-market"></div>
                                    </td>
                                    <td rowSpan={5} className='padding-0 bg-color-02'>
                                        <div className="input-fiel-cn">
                                            <textarea type="text" className="field-ipt bg-color-02 font11px color-dark"
                                            id="CustomerAcquisitionScorecardF16"
                                            value={Inputs.CustomerAcquisitionScorecardF16}
                                            onChange={(event) => setInputs({ ...Inputs, CustomerAcquisitionScorecardF16: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>We track the exact number of new customers closed each month.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerAcquisitionScorecardD17" 
                                                value={Inputs.CustomerAcquisitionScorecardD17} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerAcquisitionScorecardD17: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>We track our closing ratio each month.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerAcquisitionScorecardD18" 
                                                value={Inputs.CustomerAcquisitionScorecardD18} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerAcquisitionScorecardD18: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>We track our closing ratio for each product and service offering.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerAcquisitionScorecardD19" 
                                                value={Inputs.CustomerAcquisitionScorecardD19} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerAcquisitionScorecardD19: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>Our closing ratios are higher than industry averages.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerAcquisitionScorecardD20" 
                                                value={Inputs.CustomerAcquisitionScorecardD20} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerAcquisitionScorecardD20: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            {/* table 03 */}
                            <thead>
                                <tr>
                                    <td className='black-bg text-white' style={{width: 700}}>
                                        <div className="before-your justify-content-between">
                                            <p className='font18px'>Conversion Optimization Tools</p>
                                            <img src={window.location.origin+"/images/customer-data-scorecard/image297.png"} alt=""/>
                                        </div>
                                    </td>
                                    <td className='black-bg text-white text-center' style={{width: 150}}>
                                        <span className='font14px'>Score</span><br/><span className='font11px'>(1 point each)</span>
                                    </td>
                                    <td className='bg-color-blue text-white text-center font16px' style={{width: 50}}>
                                        {formatPrice(Inputs.CustomerAcquisitionScorecardE21, 0)}
                                    </td>
                                    <td className='black-bg text-white text-center' style={{width: 500}}>
                                        <div className="before-your justify-content-between">
                                            <p className='font18px'>Opportunities to Improve</p>
                                            <img src={window.location.origin+"/images/customer-data-scorecard/image309.png"}  alt=""/>
                                        </div>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>An effective system for qualifying leads</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerAcquisitionScorecardD22" 
                                                value={Inputs.CustomerAcquisitionScorecardD22} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerAcquisitionScorecardD22: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td rowSpan={10} className='bg-white padding-0'>
                                        <div className="arrow-targer-market"></div>
                                    </td>
                                    <td rowSpan={10} className='padding-0 bg-color-02'>
                                        <div className="input-fiel-cn">
                                            <textarea type="text" className="field-ipt bg-color-02 font11px color-dark"
                                            id="CustomerAcquisitionScorecardF22"
                                            value={Inputs.CustomerAcquisitionScorecardF22}
                                            onChange={(event) => setInputs({ ...Inputs, CustomerAcquisitionScorecardF22: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>An effective system to follow up on leads</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerAcquisitionScorecardD23" 
                                                value={Inputs.CustomerAcquisitionScorecardD23} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerAcquisitionScorecardD23: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>We use CRM software to track progress through the sales funnel</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerAcquisitionScorecardD24" 
                                                value={Inputs.CustomerAcquisitionScorecardD24} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerAcquisitionScorecardD24: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>Well-differentiated value propositions</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerAcquisitionScorecardD25" 
                                                value={Inputs.CustomerAcquisitionScorecardD25} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerAcquisitionScorecardD25: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>Testimonials, case studies, customer success stories, and customer lists</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerAcquisitionScorecardD26" 
                                                value={Inputs.CustomerAcquisitionScorecardD26} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerAcquisitionScorecardD26: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>Guarantees of quality, performance, and outcomes</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerAcquisitionScorecardD27" 
                                                value={Inputs.CustomerAcquisitionScorecardD27} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerAcquisitionScorecardD27: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>Bonuses and incentives to purchase immediately</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerAcquisitionScorecardD28" 
                                                value={Inputs.CustomerAcquisitionScorecardD28} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerAcquisitionScorecardD28: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>ROI calculations / calculators</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerAcquisitionScorecardD29" 
                                                value={Inputs.CustomerAcquisitionScorecardD29} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerAcquisitionScorecardD29: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>Sales training to improve closing ratios</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerAcquisitionScorecardD30" 
                                                value={Inputs.CustomerAcquisitionScorecardD30} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerAcquisitionScorecardD30: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>Share closing best practices among sales team members</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerAcquisitionScorecardD31" 
                                                value={Inputs.CustomerAcquisitionScorecardD31} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerAcquisitionScorecardD31: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='hide-content' colSpan={4} style={{height: 20}}></td>
                                </tr>
                            </tbody>
                            {/* table 04 */}
                            <thead>
                                <tr>
                                    <td className='bg-white'>
                                        <div className="nextInput">
                                            <ul>
                                                <li>
                                                    <Link className="next-link" to="/diagnose/customer-acquisition"> Return to Benchmark Questions
                                                        <span className="icon-three">
                                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                                        </span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td className='black-bg text-white font16px fontBold text-center'>
                                    Total
                                    </td>
                                    <td className='bg-color-blue text-white text-center font16px'>
                                    {formatPrice(Inputs.CustomerAcquisitionScorecardE33, 0)}
                                    </td>
                                    <td className='hide-content'></td>
                                </tr>
                            </thead>
                        </table>
                    </div> 
                     
                </div>
            </div>
        </div>
    );
}