import React from "react";
import { Chart } from "react-google-charts";

export function BarChartGraph({dataInput}) {

    const data = dataInput.GraphData;
    const Title = dataInput.Title ? dataInput.Title : "";
    const vTitle = dataInput.vTitle ? dataInput.vTitle : "";
    const hTitle = dataInput.hTitle ? dataInput.hTitle : "";
    const subTitle = dataInput.subTitle ? dataInput.subTitle : "";
    const ColorCodes = dataInput.ColorCodes ? dataInput.ColorCodes : ["#c2c7cd", "#8497b0", "#44546a"];
    const height = dataInput.height ? dataInput.height : "300px";
    const backgroundColor = dataInput.backgroundColor ? dataInput.backgroundColor : '';
    const legendTextStyle = dataInput.legendTextStyle ? dataInput.legendTextStyle : {color: "#fff"};
    const titleTextStyle = dataInput.titleTextStyle ? dataInput.titleTextStyle : {color: "#fff", bold: true};
    const textStyle = dataInput.textStyle ? dataInput.textStyle : {color: "#fff"};

    const options = {
        title: Title,
        titleTextStyle: {
          color: 'white',
          fontSize: 16,
          bold: true
        },
        chartArea: { 
          width: "90%",
          right : 10,
          top: 70,
          bottom: 50 
        },
        colors: ColorCodes,
        backgroundColor,
        hAxis: {
          title: hTitle,
          format: 'currency',
          textStyle
        },
        vAxis: {
          title: vTitle,
          format: 'currency',
          textStyle,
        },
        annotations: {
          alwaysOutside: false,
          highContrast : false,
          datum : {
            stem : {
              color : "#FFF",
              length : 0
            }
          },
          textStyle : {
            color : "#FFF",
          }
        },
        subtitle: subTitle,
        legend: { position: "none" },
        legendTextStyle,
        // titleTextStyle,
    };
      
  return (
    <Chart
      chartType="ColumnChart"
      width='100%'
      height={400}
      data={data}
      options={options}
    />
  );
}
