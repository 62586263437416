// useDocumentTitle.js
import { useRef, useEffect } from 'react'
import {useSWRConfig} from "swr";

function useDocumentTitle(title) {
  const { fallback, isLoading } = useSWRConfig()

  useEffect(() => {
    if(fallback && fallback.settings.siteName)
    document.title = fallback.settings.siteName;
  }, [fallback]);
}

export default useDocumentTitle
