import React from 'react'
import Sidebar from '../components/SidebarNew';
import Content from '../components/content/AlignmentAssessmentContentNew';
import Footer from '../components/Footer';

export default function AlignmentAssessmentNew() {

    return (
        <div>
    
          <div id="main-wrapper">
    
            <Sidebar />
    
            <Content />
    
          </div>
    
          <Footer />
    
        </div>
      )
}