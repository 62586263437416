import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getCriticalGrowthFactors, modifyCriticalGrowthFactors } from "../../state/features/CriticalGrowthFactorsSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import sum from "../../utils/sum";
import is_Numeric from '../../utils/is_Numeric';
import checkRange from '../../utils/checkRange';
import formatPrice from '../../utils/formatPrice';

export default function CriticalGrowthFactorsContentNew() {

    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        CriticalGrowthFactorsE12 : '',
        CriticalGrowthFactorsF12 : '',
        CriticalGrowthFactorsG12 : '',
        CriticalGrowthFactorsH12 : '',
        CriticalGrowthFactorsE13 : '',
        CriticalGrowthFactorsF13 : '',
        CriticalGrowthFactorsG13 : '',
        CriticalGrowthFactorsH13 : '',
        CriticalGrowthFactorsE14 : '',
        CriticalGrowthFactorsF14 : '',
        CriticalGrowthFactorsG14 : '',
        CriticalGrowthFactorsH14 : '',
        CriticalGrowthFactorsE15 : '',
        CriticalGrowthFactorsF15 : '',
        CriticalGrowthFactorsG15 : '',
        CriticalGrowthFactorsH15 : '',
        CriticalGrowthFactorsE16 : '',
        CriticalGrowthFactorsF16 : '',
        CriticalGrowthFactorsG16 : '',
        CriticalGrowthFactorsH16 : '',
        CriticalGrowthFactorsE17 : '',
        CriticalGrowthFactorsF17 : '',
        CriticalGrowthFactorsG17 : '',
        CriticalGrowthFactorsH17 : '',
        CriticalGrowthFactorsE18 : '',
        CriticalGrowthFactorsF18 : '',
        CriticalGrowthFactorsG18 : '',
        CriticalGrowthFactorsH18 : '',
        CriticalGrowthFactorsE19 : '',
        CriticalGrowthFactorsF19 : '',
        CriticalGrowthFactorsG19 : '',
        CriticalGrowthFactorsH19 : '',
        CriticalGrowthFactorsE20 : '',
        CriticalGrowthFactorsF20 : '',
        CriticalGrowthFactorsG20 : '',
        CriticalGrowthFactorsH20 : '',
        CriticalGrowthFactorsE21 : '',
        CriticalGrowthFactorsF21 : '',
        CriticalGrowthFactorsG21 : '',
        CriticalGrowthFactorsH21 : '',
        CriticalGrowthFactorsE22 : '',
        CriticalGrowthFactorsF22 : '',
        CriticalGrowthFactorsG22 : '',
        CriticalGrowthFactorsH22 : '',
        CriticalGrowthFactorsE23 : '',
        CriticalGrowthFactorsF23 : '',
        CriticalGrowthFactorsG23 : '',
        CriticalGrowthFactorsH23 : '',
        CriticalGrowthFactorsE24 : '',
        CriticalGrowthFactorsF24 : '',
        CriticalGrowthFactorsG24 : '',
        CriticalGrowthFactorsH24 : '',
        CriticalGrowthFactorsE25 : '',
        CriticalGrowthFactorsF25 : '',
        CriticalGrowthFactorsG25 : '',
        CriticalGrowthFactorsH25 : '',
        CriticalGrowthFactorsE26 : '',
        CriticalGrowthFactorsF26 : '',
        CriticalGrowthFactorsG26 : '',
        CriticalGrowthFactorsH26 : '',
        CriticalGrowthFactorsE27 : '',
        CriticalGrowthFactorsF27 : '',
        CriticalGrowthFactorsG27 : '',
        CriticalGrowthFactorsH27 : '',
        CriticalGrowthFactorsE28 : '',
        CriticalGrowthFactorsF28 : '',
        CriticalGrowthFactorsG28 : '',
        CriticalGrowthFactorsH28 : '',
        CriticalGrowthFactorsE29 : '',
        CriticalGrowthFactorsF29 : '',
        CriticalGrowthFactorsG29 : '',
        CriticalGrowthFactorsH29 : '',
        CriticalGrowthFactorsE30 : '',
        CriticalGrowthFactorsF30 : '',
        CriticalGrowthFactorsG30 : '',
        CriticalGrowthFactorsH30 : '',
        CriticalGrowthFactorsD24: "Other Critical Factor",
		CriticalGrowthFactorsD25: "Other Critical Factor",
		CriticalGrowthFactorsD26: "Other Critical Factor",
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getCriticalGrowthFactors({user_id}))
        .unwrap()
        .then((response) => {
            //console.log("response",response.data.growthPlanning)
            if(response!= undefined  && response.data.growthPlanning){
                let growthPlanning = response.data.growthPlanning[0];
                // console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key]
                    }
                }

                newObj['CriticalGrowthFactorsH12'] = sum((is_Numeric(newObj['CriticalGrowthFactorsF12'])? newObj['CriticalGrowthFactorsF12'] : 0), (is_Numeric(newObj['CriticalGrowthFactorsG12'])? newObj['CriticalGrowthFactorsG12'] : 0));
                newObj['CriticalGrowthFactorsH13'] = sum((is_Numeric(newObj['CriticalGrowthFactorsF13'])? newObj['CriticalGrowthFactorsF13'] : 0), (is_Numeric(newObj['CriticalGrowthFactorsG13'])? newObj['CriticalGrowthFactorsG13'] : 0));
                newObj['CriticalGrowthFactorsH14'] = sum((is_Numeric(newObj['CriticalGrowthFactorsF14'])? newObj['CriticalGrowthFactorsF14'] : 0), (is_Numeric(newObj['CriticalGrowthFactorsG14'])? newObj['CriticalGrowthFactorsG14'] : 0));
                newObj['CriticalGrowthFactorsH15'] = sum((is_Numeric(newObj['CriticalGrowthFactorsF15'])? newObj['CriticalGrowthFactorsF15'] : 0), (is_Numeric(newObj['CriticalGrowthFactorsG15'])? newObj['CriticalGrowthFactorsG15'] : 0));
                newObj['CriticalGrowthFactorsH16'] = sum((is_Numeric(newObj['CriticalGrowthFactorsF16'])? newObj['CriticalGrowthFactorsF16'] : 0), (is_Numeric(newObj['CriticalGrowthFactorsG16'])? newObj['CriticalGrowthFactorsG16'] : 0));
                newObj['CriticalGrowthFactorsH17'] = sum((is_Numeric(newObj['CriticalGrowthFactorsF17'])? newObj['CriticalGrowthFactorsF17'] : 0), (is_Numeric(newObj['CriticalGrowthFactorsG17'])? newObj['CriticalGrowthFactorsG17'] : 0));
                newObj['CriticalGrowthFactorsH18'] = sum((is_Numeric(newObj['CriticalGrowthFactorsF18'])? newObj['CriticalGrowthFactorsF18'] : 0), (is_Numeric(newObj['CriticalGrowthFactorsG18'])? newObj['CriticalGrowthFactorsG18'] : 0));
                newObj['CriticalGrowthFactorsH19'] = sum((is_Numeric(newObj['CriticalGrowthFactorsF19'])? newObj['CriticalGrowthFactorsF19'] : 0), (is_Numeric(newObj['CriticalGrowthFactorsG19'])? newObj['CriticalGrowthFactorsG19'] : 0));
                newObj['CriticalGrowthFactorsH20'] = sum((is_Numeric(newObj['CriticalGrowthFactorsF20'])? newObj['CriticalGrowthFactorsF20'] : 0), (is_Numeric(newObj['CriticalGrowthFactorsG20'])? newObj['CriticalGrowthFactorsG20'] : 0));
                newObj['CriticalGrowthFactorsH21'] = sum((is_Numeric(newObj['CriticalGrowthFactorsF21'])? newObj['CriticalGrowthFactorsF21'] : 0), (is_Numeric(newObj['CriticalGrowthFactorsG21'])? newObj['CriticalGrowthFactorsG21'] : 0));
                newObj['CriticalGrowthFactorsH22'] = sum((is_Numeric(newObj['CriticalGrowthFactorsF22'])? newObj['CriticalGrowthFactorsF22'] : 0), (is_Numeric(newObj['CriticalGrowthFactorsG22'])? newObj['CriticalGrowthFactorsG22'] : 0));
                newObj['CriticalGrowthFactorsH23'] = sum((is_Numeric(newObj['CriticalGrowthFactorsF23'])? newObj['CriticalGrowthFactorsF23'] : 0), (is_Numeric(newObj['CriticalGrowthFactorsG23'])? newObj['CriticalGrowthFactorsG23'] : 0));
                newObj['CriticalGrowthFactorsH24'] = sum((is_Numeric(newObj['CriticalGrowthFactorsF24'])? newObj['CriticalGrowthFactorsF24'] : 0), (is_Numeric(newObj['CriticalGrowthFactorsG24'])? newObj['CriticalGrowthFactorsG24'] : 0));
                newObj['CriticalGrowthFactorsH25'] = sum((is_Numeric(newObj['CriticalGrowthFactorsF25'])? newObj['CriticalGrowthFactorsF25'] : 0), (is_Numeric(newObj['CriticalGrowthFactorsG25'])? newObj['CriticalGrowthFactorsG25'] : 0));
                newObj['CriticalGrowthFactorsH26'] = sum((is_Numeric(newObj['CriticalGrowthFactorsF26'])? newObj['CriticalGrowthFactorsF26'] : 0), (is_Numeric(newObj['CriticalGrowthFactorsG26'])? newObj['CriticalGrowthFactorsG26'] : 0));
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyCriticalGrowthFactors({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

    useEffect(() => {
        let CriticalGrowthFactorsH12 = sum((is_Numeric(Inputs.CriticalGrowthFactorsF12)? Inputs.CriticalGrowthFactorsF12 : 0), (is_Numeric(Inputs.CriticalGrowthFactorsG12)? Inputs.CriticalGrowthFactorsG12 : 0));
        let CriticalGrowthFactorsH13 = sum((is_Numeric(Inputs.CriticalGrowthFactorsF13)? Inputs.CriticalGrowthFactorsF13 : 0), (is_Numeric(Inputs.CriticalGrowthFactorsG13)? Inputs.CriticalGrowthFactorsG13 : 0));
        let CriticalGrowthFactorsH14 = sum((is_Numeric(Inputs.CriticalGrowthFactorsF14)? Inputs.CriticalGrowthFactorsF14 : 0), (is_Numeric(Inputs.CriticalGrowthFactorsG14)? Inputs.CriticalGrowthFactorsG14 : 0));
        let CriticalGrowthFactorsH15 = sum((is_Numeric(Inputs.CriticalGrowthFactorsF15)? Inputs.CriticalGrowthFactorsF15 : 0), (is_Numeric(Inputs.CriticalGrowthFactorsG15)? Inputs.CriticalGrowthFactorsG15 : 0));
        let CriticalGrowthFactorsH16 = sum((is_Numeric(Inputs.CriticalGrowthFactorsF16)? Inputs.CriticalGrowthFactorsF16 : 0), (is_Numeric(Inputs.CriticalGrowthFactorsG16)? Inputs.CriticalGrowthFactorsG16 : 0));
        let CriticalGrowthFactorsH17 = sum((is_Numeric(Inputs.CriticalGrowthFactorsF17)? Inputs.CriticalGrowthFactorsF17 : 0), (is_Numeric(Inputs.CriticalGrowthFactorsG17)? Inputs.CriticalGrowthFactorsG17 : 0));
        let CriticalGrowthFactorsH18 = sum((is_Numeric(Inputs.CriticalGrowthFactorsF18)? Inputs.CriticalGrowthFactorsF18 : 0), (is_Numeric(Inputs.CriticalGrowthFactorsG18)? Inputs.CriticalGrowthFactorsG18 : 0));
        let CriticalGrowthFactorsH19 = sum((is_Numeric(Inputs.CriticalGrowthFactorsF19)? Inputs.CriticalGrowthFactorsF19 : 0), (is_Numeric(Inputs.CriticalGrowthFactorsG19)? Inputs.CriticalGrowthFactorsG19 : 0));
        let CriticalGrowthFactorsH20 = sum((is_Numeric(Inputs.CriticalGrowthFactorsF20)? Inputs.CriticalGrowthFactorsF20 : 0), (is_Numeric(Inputs.CriticalGrowthFactorsG20)? Inputs.CriticalGrowthFactorsG20 : 0));
        let CriticalGrowthFactorsH21 = sum((is_Numeric(Inputs.CriticalGrowthFactorsF21)? Inputs.CriticalGrowthFactorsF21 : 0), (is_Numeric(Inputs.CriticalGrowthFactorsG21)? Inputs.CriticalGrowthFactorsG21 : 0));
        let CriticalGrowthFactorsH22 = sum((is_Numeric(Inputs.CriticalGrowthFactorsF22)? Inputs.CriticalGrowthFactorsF22 : 0), (is_Numeric(Inputs.CriticalGrowthFactorsG22)? Inputs.CriticalGrowthFactorsG22 : 0));
        let CriticalGrowthFactorsH23 = sum((is_Numeric(Inputs.CriticalGrowthFactorsF23)? Inputs.CriticalGrowthFactorsF23 : 0), (is_Numeric(Inputs.CriticalGrowthFactorsG23)? Inputs.CriticalGrowthFactorsG23 : 0));
        let CriticalGrowthFactorsH24 = sum((is_Numeric(Inputs.CriticalGrowthFactorsF24)? Inputs.CriticalGrowthFactorsF24 : 0), (is_Numeric(Inputs.CriticalGrowthFactorsG24)? Inputs.CriticalGrowthFactorsG24 : 0));
        let CriticalGrowthFactorsH25 = sum((is_Numeric(Inputs.CriticalGrowthFactorsF25)? Inputs.CriticalGrowthFactorsF25 : 0), (is_Numeric(Inputs.CriticalGrowthFactorsG25)? Inputs.CriticalGrowthFactorsG25 : 0));
        let CriticalGrowthFactorsH26 = sum((is_Numeric(Inputs.CriticalGrowthFactorsF26)? Inputs.CriticalGrowthFactorsF26 : 0), (is_Numeric(Inputs.CriticalGrowthFactorsG26)? Inputs.CriticalGrowthFactorsG26 : 0));

        setInputs({...Inputs, CriticalGrowthFactorsH12, CriticalGrowthFactorsH13, CriticalGrowthFactorsH14, CriticalGrowthFactorsH15, CriticalGrowthFactorsH16, CriticalGrowthFactorsH17, CriticalGrowthFactorsH18, CriticalGrowthFactorsH19, CriticalGrowthFactorsH20, CriticalGrowthFactorsH21, CriticalGrowthFactorsH22, CriticalGrowthFactorsH23, CriticalGrowthFactorsH24, CriticalGrowthFactorsH25, CriticalGrowthFactorsH26});

    }, [Inputs.CriticalGrowthFactorsF12, Inputs.CriticalGrowthFactorsG12, Inputs.CriticalGrowthFactorsF13, Inputs.CriticalGrowthFactorsG13, Inputs.CriticalGrowthFactorsF14, Inputs.CriticalGrowthFactorsG14, Inputs.CriticalGrowthFactorsF15, Inputs.CriticalGrowthFactorsG15, Inputs.CriticalGrowthFactorsF16, Inputs.CriticalGrowthFactorsG16, Inputs.CriticalGrowthFactorsF17, Inputs.CriticalGrowthFactorsG17, Inputs.CriticalGrowthFactorsF18, Inputs.CriticalGrowthFactorsG18, Inputs.CriticalGrowthFactorsF19, Inputs.CriticalGrowthFactorsG19, Inputs.CriticalGrowthFactorsF20, Inputs.CriticalGrowthFactorsG20, Inputs.CriticalGrowthFactorsF21, Inputs.CriticalGrowthFactorsG21, Inputs.CriticalGrowthFactorsF22, Inputs.CriticalGrowthFactorsG22, Inputs.CriticalGrowthFactorsF23, Inputs.CriticalGrowthFactorsG23, Inputs.CriticalGrowthFactorsF24, Inputs.CriticalGrowthFactorsG24, Inputs.CriticalGrowthFactorsF25, Inputs.CriticalGrowthFactorsG25, Inputs.CriticalGrowthFactorsF26, Inputs.CriticalGrowthFactorsG26]);
   
    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />

            <div className="container-fluid">
                <div className="head-title critical-factors mb-2">
                    <h3 className='preheading'>Tools - Growth Strategy</h3>
                    <h1 className='mainheading mb-1'>Critical Growth Factors</h1>
                </div>
                <div className="customer-transformation">
                    <div className="customer-transformation-inner">
                    <Spinner/>
                        <div className="table-responsive">
                            <table className="table" style={{width: 1100}}>
                                <thead>
                                    <tr>
                                        <td colSpan={4}>
                                            <p className="body-text color-light mb-3 d-block">Critical Growth Factors are the core attributes that propel an organization’s ability to outgrow their competition.  Most are the same for every organization, but some can be industry specific.  Feel free to add critical growth factors to this list if needed.</p>
                                        </td>
                                        <td colSpan={2} className="hide-content"></td>
                                    </tr>
                                    <tr className="critical-factors-head">
                                        <td className="bg-color01 text-white text-center" rowSpan={2}>
                                            <div className="critical-factors-igm">
                                                <img src="../images/critical-factors/icon_01.png" alt="factors 01" />
                                            </div>
                                        </td>
                                        <td className="bg-color01 text-white text-center font14px">Critical Factors</td>
                                        <td className="bg-color01 text-white text-center font14px">Description</td>
                                        <td className="bg-color01 text-white text-center font14px">Importance</td>
                                        <td className="bg-color01 text-white text-center font14px">Urgency</td>
                                        <td className="bg-color01 text-white text-center font14px">Overall Score</td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color-03 text-white text-center padidng-ty">
                                            <div className="factors-content">
                                                <p className='font11px'>What are the critical growth factors?</p>
                                            </div>
                                        </td>
                                        <td className="bg-color-03 text-white text-center padidng-ty">
                                            <div className="factors-description">
                                                <p className='font11px'>Why is it critical and what impact could it have?</p>
                                            </div>
                                        </td>
                                        <td className="bg-color-03 text-white text-center padidng-ty">
                                            <div className="factors-content">
                                                <p className='font11px'>Importance compared to other critical growth factors?  (1=Low; 10=High)</p>
                                            </div>
                                        </td>
                                        <td className="bg-color-03 text-white text-center padidng-ty">
                                            <div className="factors-content">
                                                <p className='font11px'>How urgent is it that we address?  (1=Low; 10=High)</p>
                                            </div>
                                        </td>
                                        <td className="bg-color-03 text-white text-center padidng-ty">
                                            <div className="factors-content">
                                                <p className='font11px'>Score of 15 or higher indicates top priority critical growth factor.</p>
                                            </div>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="bg-color01 text-white text-center padidng-ty" style={{width: 50}}>
                                            <div className="factor-numbers font24px fontExtraBold">1</div>
                                        </td>
                                        <td className="Blue-gray-bg" style={{width: 200}}>
                                            <div className="factors-content-tbody-content">
                                                <p className='font11px'>Markets & Customers</p>
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0" style={{width: 400}}>
                                            <div className="input-fiel-cn bg-color-02">
                                                <textarea className="bg-color-02 font11px color-dark w-100"
                                                    id="CriticalGrowthFactorsE12"
                                                    value={Inputs.CriticalGrowthFactorsE12}
                                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsE12: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading} 
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-01 padding-0" style={{width: 150}}>
                                            <div className="bg-color-01 factor-inputs changes-input">
                                                <input type="text" className="bg-color-01 font12px color-dark"
                                                    id="CriticalGrowthFactorsF12"
                                                    value={Inputs.CriticalGrowthFactorsF12}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, CriticalGrowthFactorsF12: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-01 padding-0" style={{width: 150}}>
                                            <div className="bg-color-01 factor-inputs changes-input">
                                                <input type="text" className="bg-color-01 font12px color-dark"
                                                    id="CriticalGrowthFactorsG12"
                                                    value={Inputs.CriticalGrowthFactorsG12}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, CriticalGrowthFactorsG12: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-blue text-white text-center font12px" style={{width: 150}}>
                                            {formatPrice(Inputs.CriticalGrowthFactorsH12, 0)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color01 text-white text-center padidng-ty">
                                            <div className="factor-numbers font24px fontExtraBold">2</div>
                                        </td>
                                        <td className="Blue-gray-bg">
                                            <div className="factors-content-tbody-content">
                                                <p className='font11px'>Value Creation</p>
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="input-fiel-cn bg-color-02">
                                                <textarea className="bg-color-02 font11px color-dark w-100"
                                                    id="CriticalGrowthFactorsE13"
                                                    value={Inputs.CriticalGrowthFactorsE13}
                                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsE13: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading} 
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-01 padding-0">
                                            <div className="bg-color-01 factor-inputs changes-input">
                                                <input type="text" className="bg-color-01 font12px color-dark"
                                                    id="CriticalGrowthFactorsF13"
                                                    value={Inputs.CriticalGrowthFactorsF13}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, CriticalGrowthFactorsF13: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-01 padding-0">
                                            <div className="bg-color-01 factor-inputs changes-input">
                                                <input type="text" className="bg-color-01 font12px color-dark"
                                                    id="CriticalGrowthFactorsG13"
                                                    value={Inputs.CriticalGrowthFactorsG13}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, CriticalGrowthFactorsG13: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-blue text-white text-center font12px">
                                            {formatPrice(Inputs.CriticalGrowthFactorsH13, 0)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color01 text-white text-center padidng-ty">
                                            <div className="factor-numbers font24px fontExtraBold">3</div>
                                        </td>
                                        <td className="Blue-gray-bg">
                                            <div className="factors-content-tbody-content">
                                                <p className='font11px'>Innovative Offerings</p>
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="input-fiel-cn bg-color-02">
                                                <textarea className="bg-color-02 font11px color-dark w-100"
                                                    id="CriticalGrowthFactorsE14"
                                                    value={Inputs.CriticalGrowthFactorsE14}
                                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsE14: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading} 
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-01 padding-0">
                                            <div className="bg-color-01 factor-inputs changes-input">
                                                <input type="text" className="bg-color-01 font12px color-dark"
                                                    id="CriticalGrowthFactorsF14"
                                                    value={Inputs.CriticalGrowthFactorsF14}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, CriticalGrowthFactorsF14: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-01 padding-0">
                                            <div className="bg-color-01 factor-inputs changes-input">
                                                <input type="text" className="bg-color-01 font12px color-dark"
                                                    id="CriticalGrowthFactorsG14"
                                                    value={Inputs.CriticalGrowthFactorsG14}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, CriticalGrowthFactorsG14: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-blue text-white text-center font12px">
                                            {formatPrice(Inputs.CriticalGrowthFactorsH14, 0)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color01 text-white text-center padidng-ty">
                                            <div className="factor-numbers font24px fontExtraBold">4</div>
                                        </td>
                                        <td className="Blue-gray-bg">
                                            <div className="factors-content-tbody-content">
                                                <p className='font11px'>Positioning & Messaging</p>
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="input-fiel-cn bg-color-02">
                                                <textarea className="bg-color-02 font11px color-dark w-100"
                                                    id="CriticalGrowthFactorsE15"
                                                    value={Inputs.CriticalGrowthFactorsE15}
                                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsE15: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading} 
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-01 padding-0">
                                            <div className="bg-color-01 factor-inputs changes-input">
                                                <input type="text" className="bg-color-01 font12px color-dark"
                                                    id="CriticalGrowthFactorsF15"
                                                    value={Inputs.CriticalGrowthFactorsF15}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, CriticalGrowthFactorsF15: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-01 padding-0">
                                            <div className="bg-color-01 factor-inputs changes-input">
                                                <input type="text" className="bg-color-01 font12px color-dark"
                                                    id="CriticalGrowthFactorsG15"
                                                    value={Inputs.CriticalGrowthFactorsG15}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, CriticalGrowthFactorsG15: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-blue text-white text-center font12px">
                                            {formatPrice(Inputs.CriticalGrowthFactorsH15, 0)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color01 text-white text-center padidng-ty">
                                            <div className="factor-numbers font24px fontExtraBold">5</div>
                                        </td>
                                        <td className="Blue-gray-bg">
                                            <div className="factors-content-tbody-content">
                                                <p className='font11px'>Back-End Development</p>
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="input-fiel-cn bg-color-02">
                                                <textarea className="bg-color-02 font11px color-dark w-100"
                                                    id="CriticalGrowthFactorsE16"
                                                    value={Inputs.CriticalGrowthFactorsE16}
                                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsE16: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading} 
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-01 padding-0">
                                            <div className="bg-color-01 factor-inputs changes-input">
                                                <input type="text" className="bg-color-01 font12px color-dark"
                                                    id="CriticalGrowthFactorsF16"
                                                    value={Inputs.CriticalGrowthFactorsF16}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, CriticalGrowthFactorsF16: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-01 padding-0">
                                            <div className="bg-color-01 factor-inputs changes-input">
                                                <input type="text" className="bg-color-01 font12px color-dark"
                                                    id="CriticalGrowthFactorsG16"
                                                    value={Inputs.CriticalGrowthFactorsG16}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, CriticalGrowthFactorsG16: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-blue text-white text-center font12px">
                                            {formatPrice(Inputs.CriticalGrowthFactorsH16, 0)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color01 text-white text-center padidng-ty">
                                            <div className="factor-numbers font24px fontExtraBold">6</div>
                                        </td>
                                        <td className="Blue-gray-bg">
                                            <div className="factors-content-tbody-content">
                                                <p className='font11px'>Strategic Alignment</p>
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="input-fiel-cn bg-color-02">
                                                <textarea className="bg-color-02 font11px color-dark w-100"
                                                    id="CriticalGrowthFactorsE17"
                                                    value={Inputs.CriticalGrowthFactorsE17}
                                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsE17: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading} 
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-01 padding-0">
                                            <div className="bg-color-01 factor-inputs changes-input">
                                                <input type="text" className="bg-color-01 font12px color-dark"
                                                    id="CriticalGrowthFactorsF17"
                                                    value={Inputs.CriticalGrowthFactorsF17}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, CriticalGrowthFactorsF17: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-01 padding-0">
                                            <div className="bg-color-01 factor-inputs changes-input">
                                                <input type="text" className="bg-color-01 font12px color-dark"
                                                    id="CriticalGrowthFactorsG17"
                                                    value={Inputs.CriticalGrowthFactorsG17}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, CriticalGrowthFactorsG17: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-blue text-white text-center font12px">
                                            {formatPrice(Inputs.CriticalGrowthFactorsH17, 0)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color01 text-white text-center padidng-ty">
                                            <div className="factor-numbers font24px fontExtraBold">7</div>
                                        </td>
                                        <td className="Blue-gray-bg">
                                            <div className="factors-content-tbody-content">
                                                <p className='font11px'>Financial Compounding</p>
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="input-fiel-cn bg-color-02">
                                                <textarea className="bg-color-02 font11px color-dark w-100"
                                                    id="CriticalGrowthFactorsE18"
                                                    value={Inputs.CriticalGrowthFactorsE18}
                                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsE18: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading} 
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-01 padding-0">
                                            <div className="bg-color-01 factor-inputs changes-input">
                                                <input type="text" className="bg-color-01 font12px color-dark"
                                                    id="CriticalGrowthFactorsF18"
                                                    value={Inputs.CriticalGrowthFactorsF18}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, CriticalGrowthFactorsF18: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-01 padding-0">
                                            <div className="bg-color-01 factor-inputs changes-input">
                                                <input type="text" className="bg-color-01 font12px color-dark"
                                                    id="CriticalGrowthFactorsG18"
                                                    value={Inputs.CriticalGrowthFactorsG18}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, CriticalGrowthFactorsG18: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }} 
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-blue text-white text-center font12px">
                                            {formatPrice(Inputs.CriticalGrowthFactorsH18, 0)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color01 text-white text-center padidng-ty">
                                            <div className="factor-numbers font24px fontExtraBold">8</div>
                                        </td>
                                        <td className="Blue-gray-bg">
                                            <div className="factors-content-tbody-content">
                                                <p className='font11px'>Continuous Improvement</p>
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="input-fiel-cn bg-color-02">
                                                <textarea className="bg-color-02 font11px color-dark w-100"
                                                    id="CriticalGrowthFactorsE19"
                                                    value={Inputs.CriticalGrowthFactorsE19}
                                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsE19: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading} 
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-01 padding-0">
                                            <div className="bg-color-01 factor-inputs changes-input">
                                                <input type="text" className="bg-color-01 font12px color-dark"
                                                    id="CriticalGrowthFactorsF19"
                                                    value={Inputs.CriticalGrowthFactorsF19}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, CriticalGrowthFactorsF19: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }} 
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-01 padding-0">
                                            <div className="bg-color-01 factor-inputs changes-input">
                                                <input type="text" className="bg-color-01 font12px color-dark"
                                                    id="CriticalGrowthFactorsG19"
                                                    value={Inputs.CriticalGrowthFactorsG19}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, CriticalGrowthFactorsG19: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }} 
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-blue text-white text-center font12px">
                                            {formatPrice(Inputs.CriticalGrowthFactorsH19, 0)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color01 text-white text-center padidng-ty">
                                            <div className="factor-numbers font24px fontExtraBold">9</div>
                                        </td>
                                        <td className="Blue-gray-bg">
                                            <div className="factors-content-tbody-content">
                                                <p className='font11px'>Agility</p>
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="input-fiel-cn bg-color-02">
                                                <textarea className="bg-color-02 font11px color-dark w-100"
                                                    id="CriticalGrowthFactorsE20"
                                                    value={Inputs.CriticalGrowthFactorsE20}
                                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsE20: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading} 
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-01 padding-0">
                                            <div className="bg-color-01 factor-inputs changes-input">
                                                <input type="text" className="bg-color-01 font12px color-dark"
                                                    id="CriticalGrowthFactorsF20"
                                                    value={Inputs.CriticalGrowthFactorsF20}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, CriticalGrowthFactorsF20: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }} 
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-01 padding-0">
                                            <div className="bg-color-01 factor-inputs changes-input">
                                                <input type="text" className="bg-color-01 font12px color-dark"
                                                    id="CriticalGrowthFactorsG20"
                                                    value={Inputs.CriticalGrowthFactorsG20}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, CriticalGrowthFactorsG20: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }}  
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-blue text-white text-center font12px">
                                            {formatPrice(Inputs.CriticalGrowthFactorsH20, 0)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color01 text-white text-center padidng-ty">
                                            <div className="factor-numbers font24px fontExtraBold">10</div>
                                        </td>
                                        <td className="Blue-gray-bg">
                                            <div className="factors-content-tbody-content">
                                                <p className='font11px'>Growth Capabilities</p>
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="input-fiel-cn bg-color-02">
                                                <textarea className="bg-color-02 font11px color-dark w-100"
                                                    id="CriticalGrowthFactorsE21"
                                                    value={Inputs.CriticalGrowthFactorsE21}
                                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsE21: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading} 
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-01 padding-0">
                                            <div className="bg-color-01 factor-inputs changes-input">
                                                <input type="text" className="bg-color-01 font12px color-dark"
                                                    id="CriticalGrowthFactorsF21"
                                                    value={Inputs.CriticalGrowthFactorsF21}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, CriticalGrowthFactorsF21: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }}  
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-01 padding-0">
                                            <div className="bg-color-01 factor-inputs changes-input">
                                                <input type="text" className="bg-color-01 font12px color-dark"
                                                    id="CriticalGrowthFactorsG21"
                                                    value={Inputs.CriticalGrowthFactorsG21}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, CriticalGrowthFactorsG21: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }} 
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-blue text-white text-center">
                                            {formatPrice(Inputs.CriticalGrowthFactorsH21, 0)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color01 text-white text-center padidng-ty">
                                            <div className="factor-numbers font24px fontExtraBold">11</div>
                                        </td>
                                        <td className="Blue-gray-bg">
                                            <div className="factors-content-tbody-content">
                                                <p className='font11px'>Sales Capabilities</p>
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="input-fiel-cn bg-color-02">
                                                <textarea className="bg-color-02 font11px color-dark w-100"
                                                    id="CriticalGrowthFactorsE22"
                                                    value={Inputs.CriticalGrowthFactorsE22}
                                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsE22: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading} 
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-01 padding-0">
                                            <div className="bg-color-01 factor-inputs changes-input">
                                                <input type="text" className="bg-color-01 font12px color-dark"
                                                    id="CriticalGrowthFactorsF22"
                                                    value={Inputs.CriticalGrowthFactorsF22}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, CriticalGrowthFactorsF22: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-01 padding-0">
                                            <div className="bg-color-01 factor-inputs changes-input">
                                                <input type="text" className="bg-color-01 font12px color-dark"
                                                    id="CriticalGrowthFactorsG22"
                                                    value={Inputs.CriticalGrowthFactorsG22}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, CriticalGrowthFactorsG22: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }} 
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-blue text-white text-center font12px">
                                            {formatPrice(Inputs.CriticalGrowthFactorsH22, 0)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color01 text-white text-center padidng-ty">
                                            <div className="factor-numbers font24px fontExtraBold">12</div>
                                        </td>
                                        <td className="Blue-gray-bg">
                                            <div className="factors-content-tbody-content">
                                                <p className='font11px'>Marketing Capabilities</p>
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="input-fiel-cn bg-color-02">
                                                <textarea className="bg-color-02 font11px color-dark w-100"
                                                    id="CriticalGrowthFactorsE23"
                                                    value={Inputs.CriticalGrowthFactorsE23}
                                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsE23: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading} 
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-01 padding-0">
                                            <div className="bg-color-01 factor-inputs changes-input">
                                                <input type="text" className="bg-color-01 font12px color-dark"
                                                    id="CriticalGrowthFactorsF23"
                                                    value={Inputs.CriticalGrowthFactorsF23}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, CriticalGrowthFactorsF23: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }} 
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-01 padding-0">
                                            <div className="bg-color-01 factor-inputs changes-input">
                                                <input type="text" className="bg-color-01 font12px color-dark"
                                                    id="CriticalGrowthFactorsG23"
                                                    value={Inputs.CriticalGrowthFactorsG23}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, CriticalGrowthFactorsG23: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }} 
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-blue text-white text-center font12px">
                                            {formatPrice(Inputs.CriticalGrowthFactorsH23, 0)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color01 text-white text-center padidng-ty">
                                            <div className="factor-numbers font24px fontExtraBold">13</div>
                                        </td>
                                        <td className="bg-color-01 padding-0">
                                            <div className="bg-color-01 factor-inputs">
                                                <input type="text" className="bg-color-01 font11px color-dark"
                                                    id="CriticalGrowthFactorsD24"
                                                    value={Inputs.CriticalGrowthFactorsD24}
                                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsD24: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading} 
                                                    placeholder={'Other Critical Factor'}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="input-fiel-cn bg-color-02">
                                                <textarea className="bg-color-02 font11px color-dark w-100"
                                                    id="CriticalGrowthFactorsE24"
                                                    value={Inputs.CriticalGrowthFactorsE24}
                                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsE24: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading} 
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-01 padding-0">
                                            <div className="bg-color-01 factor-inputs changes-input">
                                                <input type="text" className="bg-color-01 font12px color-dark"
                                                    id="CriticalGrowthFactorsF24"
                                                    value={Inputs.CriticalGrowthFactorsF24}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, CriticalGrowthFactorsF24: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-01 padding-0">
                                            <div className="bg-color-01 factor-inputs changes-input">
                                                <input type="text" className="bg-color-01 font12px color-dark"
                                                    id="CriticalGrowthFactorsG24"
                                                    value={Inputs.CriticalGrowthFactorsG24}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, CriticalGrowthFactorsG24: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-blue text-white text-center font12px">
                                            {formatPrice(Inputs.CriticalGrowthFactorsH24, 0)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color01 text-white text-center padidng-ty">
                                            <div className="factor-numbers font24px fontExtraBold">14</div>
                                        </td>
                                        <td className="bg-color-01 padding-0">
                                            <div className="bg-color-01 factor-inputs">
                                                <input type="text" className="bg-color-01 font11px color-dark"
                                                    id="CriticalGrowthFactorsD25"
                                                    value={Inputs.CriticalGrowthFactorsD25}
                                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsD25: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading} 
                                                    placeholder={'Other Critical Factor'}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="input-fiel-cn bg-color-02">
                                                <textarea className="bg-color-02 font11px color-dark w-100"
                                                    id="CriticalGrowthFactorsE25"
                                                    value={Inputs.CriticalGrowthFactorsE25}
                                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsE25: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading} 
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-01 padding-0">
                                            <div className="bg-color-01 factor-inputs changes-input">
                                                <input type="text" className="bg-color-01 font12px color-dark"
                                                    id="CriticalGrowthFactorsF25"
                                                    value={Inputs.CriticalGrowthFactorsF25}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, CriticalGrowthFactorsF25: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }} 
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-01 padding-0">
                                            <div className="bg-color-01 factor-inputs changes-input">
                                                <input type="text" className="bg-color-01 font12px color-dark"
                                                    id="CriticalGrowthFactorsG25"
                                                    value={Inputs.CriticalGrowthFactorsG25}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, CriticalGrowthFactorsG25: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }} 
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-blue text-white text-center font12px">
                                            {formatPrice(Inputs.CriticalGrowthFactorsH25, 0)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color01 text-white text-center padidng-ty">
                                            <div className="factor-numbers font24px fontExtraBold">15</div>
                                        </td>
                                        <td className="bg-color-01 padding-0">
                                            <div className="bg-color-01 factor-inputs">
                                                <input type="text" className="bg-color-01 font11px color-dark"
                                                    id="CriticalGrowthFactorsD26"
                                                    value={Inputs.CriticalGrowthFactorsD26}
                                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsD26: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading} 
                                                    placeholder={'Other Critical Factor'}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="input-fiel-cn bg-color-02">
                                                <textarea className="bg-color-02 font11px color-dark w-100"
                                                    id="CriticalGrowthFactorsE26"
                                                    value={Inputs.CriticalGrowthFactorsE26}
                                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsE26: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading} 
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-01 padding-0">
                                            <div className="bg-color-01 factor-inputs changes-input">
                                                <input type="text" className="bg-color-01 color-dark font12px"
                                                    id="CriticalGrowthFactorsF26"
                                                    value={Inputs.CriticalGrowthFactorsF26}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, CriticalGrowthFactorsF26: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }}  
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-01 padding-0">
                                            <div className="bg-color-01 factor-inputs changes-input">
                                                <input type="text" className="bg-color-01 color-dark font12px"
                                                    id="CriticalGrowthFactorsG26"
                                                    value={Inputs.CriticalGrowthFactorsG26}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, CriticalGrowthFactorsG26: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }} 
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-blue text-white text-center font12px">
                                            {formatPrice(Inputs.CriticalGrowthFactorsH26, 0)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
