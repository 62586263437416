import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getCustomerTransformation, modifyCustomerTransformation } from "../../state/features/CustomerTransformationSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';

export default function CustomerTransformationModelContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        CustomerTransformationD9 : '',
        CustomerTransformationG9 : '',
        CustomerTransformationD10 : '',
        CustomerTransformationG10 : '',
        CustomerTransformationD11 : '',
        CustomerTransformationG11 : '',
        CustomerTransformationD12 : '',
        CustomerTransformationG12 : '',
        CustomerTransformationD13 : '',
        CustomerTransformationG13 : '',
        CustomerTransformationD14 : '',
        CustomerTransformationG14 : '',
        CustomerTransformationD15 : '',
        CustomerTransformationG15 : '',
        CustomerTransformationD16 : '',
        CustomerTransformationG16 : '',
        CustomerTransformationD17 : '',
        CustomerTransformationG17 : '',
        CustomerTransformationD18 : '',
        CustomerTransformationG18 : '',
        CustomerTransformationC22 : '',
        CustomerTransformationC23 : '',
        CustomerTransformationC25 : '',
        CustomerTransformationC26 : '',
        CustomerTransformationC28 : '',
        CustomerTransformationC29 : '',
        CustomerTransformationC31 : '',
        CustomerTransformationC32 : '',
        CustomerTransformationC34 : '',
        CustomerTransformationC35 : '',
        CustomerTransformationC37 : '',
        CustomerTransformationC38 : '',
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getCustomerTransformation({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.growthPlanning)
            if(response!= undefined  && response.data.growthPlanning){
                let growthPlanning = response.data.growthPlanning[0];
                console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key]
                    }
                }
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyCustomerTransformation({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        //window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

  return (
    <div className="content-body">
        <UserDropdown />
        {/* row */}
        <div className="container-fluid">
            <div className="head-title">
                <p> Value Creation </p>
                <h2 className="mb-5"> Customer Transformation Model </h2>
            </div>
            <Spinner />
            <div className="card">
                <div className="card-body pl-0">
                <div className="table-responsive table-dta">
                    <table className="table mb-0">
                    <thead>
                        <tr>
                        <th scope="row" colSpan={5} className="bg-color1 text-white padding-10" width="45%">
                            <div className="forces-div">
                            <span className="forces-img-bx">
                                <img src="../images/forces-icon1.png" alt="forces-icon1" />
                            </span>
                            <span className="forces-span">Before Your Product or Service</span>
                            </div>
                        </th>
                        <td className="width-5 d-hidden">cell</td>
                        <td colSpan={5} className="bg-color1 text-white padding-10" width="45%">
                            <div className="forces-div">
                            <span className="forces-img-bx">
                                <img src="../images/forces-icon3.png" alt="forces-icon1" />
                            </span>
                            <span className="forces-span">After Your Product or Service</span>
                            </div>
                        </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td className="width-5 bg-color3 text-white text-center-c">1</td>
                        <td colSpan={4}>
                            <input type="text" className="form-control inputText"
                                id="CustomerTransformationD9"
                                value={Inputs.CustomerTransformationD9}
                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationD9: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td className="width-5 d-hidden">cell</td>
                        <td className="width-5 bg-color3 text-white text-center-c">1</td>
                        <td colSpan={4}>
                            <input type="text" className="form-control inputText"
                                id="CustomerTransformationG9"
                                value={Inputs.CustomerTransformationG9}
                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationG9: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td className="width-5 bg-color3 text-white text-center-c">2</td>
                        <td colSpan={4}>
                            <input type="text" className="form-control inputText"
                                id="CustomerTransformationD10"
                                value={Inputs.CustomerTransformationD10}
                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationD10: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td className="width-5 d-hidden">cell</td>
                        <td className="width-5 bg-color3 text-white text-center-c">2</td>
                        <td colSpan={4}>
                            <input type="text" className="form-control inputText"
                                id="CustomerTransformationG10"
                                value={Inputs.CustomerTransformationG10}
                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationG10: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td className="width-5 bg-color3 text-white text-center-c">3</td>
                        <td colSpan={4}>
                            <input type="text" className="form-control inputText"
                                id="CustomerTransformationD11"
                                value={Inputs.CustomerTransformationD11}
                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationD11: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td className="width-5 d-hidden">cell</td>
                        <td className="width-5 bg-color3 text-white text-center-c">3</td>
                        <td colSpan={4}>
                            <input type="text" className="form-control inputText"
                                id="CustomerTransformationG11"
                                value={Inputs.CustomerTransformationG11}
                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationG11: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td className="width-5 bg-color3 text-white text-center-c">4</td>
                        <td colSpan={4}>
                            <input type="text" className="form-control inputText"
                                id="CustomerTransformationD12"
                                value={Inputs.CustomerTransformationD12}
                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationD12: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td className="width-5 d-hidden">cell</td>
                        <td className="width-5 bg-color3 text-white text-center-c">4</td>
                        <td colSpan={4}>
                            <input type="text" className="form-control inputText"
                                id="CustomerTransformationG12"
                                value={Inputs.CustomerTransformationG12}
                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationG12: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td className="width-5 bg-color3 text-white text-center-c">5</td>
                        <td colSpan={4}>
                            <input type="text" className="form-control inputText"
                                id="CustomerTransformationD13"
                                value={Inputs.CustomerTransformationD13}
                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationD13: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td className="width-5 d-hidden">cell</td>
                        <td className="width-5 bg-color3 text-white text-center-c">5</td>
                        <td colSpan={4}>
                            <input type="text" className="form-control inputText"
                                id="CustomerTransformationG13"
                                value={Inputs.CustomerTransformationG13}
                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationG13: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td className="width-5 bg-color3 text-white text-center-c">6</td>
                        <td colSpan={4}>
                            <input type="text" className="form-control inputText"
                                id="CustomerTransformationD14"
                                value={Inputs.CustomerTransformationD14}
                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationD14: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td className="width-5 d-hidden">cell</td>
                        <td className="width-5 bg-color3 text-white text-center-c">6</td>
                        <td colSpan={4}>
                            <input type="text" className="form-control inputText"
                                id="CustomerTransformationG14"
                                value={Inputs.CustomerTransformationG14}
                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationG14: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td className="width-5 bg-color3 text-white text-center-c">7</td>
                        <td colSpan={4}>
                            <input type="text" className="form-control inputText"
                                id="CustomerTransformationD15"
                                value={Inputs.CustomerTransformationD15}
                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationD15: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td className="width-5 d-hidden">cell</td>
                        <td className="width-5 bg-color3 text-white text-center-c">7</td>
                        <td colSpan={4}>
                            <input type="text" className="form-control inputText"
                                id="CustomerTransformationG15"
                                value={Inputs.CustomerTransformationG15}
                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationG15: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td className="width-5 bg-color3 text-white text-center-c">8</td>
                        <td colSpan={4}>
                            <input type="text" className="form-control inputText"
                                id="CustomerTransformationD16"
                                value={Inputs.CustomerTransformationD16}
                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationD16: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td className="width-5 d-hidden">cell</td>
                        <td className="width-5 bg-color3 text-white text-center-c">8</td>
                        <td colSpan={4}>
                            <input type="text" className="form-control inputText"
                                id="CustomerTransformationG16"
                                value={Inputs.CustomerTransformationG16}
                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationG16: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td className="width-5 bg-color3 text-white text-center-c">9</td>
                        <td colSpan={4}>
                            <input type="text" className="form-control inputText"
                                id="CustomerTransformationD17"
                                value={Inputs.CustomerTransformationD17}
                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationD17: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td className="width-5 d-hidden">cell</td>
                        <td className="width-5 bg-color3 text-white text-center-c">9</td>
                        <td colSpan={4}>
                            <input type="text" className="form-control inputText"
                                id="CustomerTransformationG17"
                                value={Inputs.CustomerTransformationG17}
                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationG17: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td className="width-5 bg-color3 text-white text-center-c">10</td>
                        <td colSpan={4}>
                            <input type="text" className="form-control inputText"
                                id="CustomerTransformationD18"
                                value={Inputs.CustomerTransformationD18}
                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationD18: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td className="width-5 d-hidden">cell</td>
                        <td className="width-5 bg-color3 text-white text-center-c">10</td>
                        <td colSpan={4}>
                            <input type="text" className="form-control inputText"
                                id="CustomerTransformationG18"
                                value={Inputs.CustomerTransformationG18}
                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationG18: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                    </tbody>
                    </table>
                    {/* table 2 */}
                    <h2 className="tp-heading mt-5 mb-0">Build Your Customer Transformation Story</h2>
                    <table className="table mb-0">
                    <thead>
                        <tr>
                        <th scope="row" colSpan={10} className="bg-color1 text-white padding-10" width="45%">
                            <div className="forces-div">
                            <span className="forces-img-bx">
                                <img src="../images/forces-icon1.png" alt="forces-icon1" />
                            </span>
                            <span className="forces-span text-font-size-14"><b className="text-font-size-16">Why</b> (What do you want the insight scenario to accomplish?  How do you want the buyer to see things differently?)</span>
                            </div>
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td colSpan={10} className="width-100 bg-color3 text-white text-center-c">
                            <input type="text" className="form-control inputTextf text-white"
                                id="CustomerTransformationC22"
                                value={Inputs.CustomerTransformationC22}
                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationC22: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={10} className="width-100 bg-color3 text-white text-center-c">
                            <input type="text" className="form-control inputTextf text-white"
                                id="CustomerTransformationC23"
                                value={Inputs.CustomerTransformationC23}
                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationC23: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                    </tbody>
                    </table>
                    {/* table 3 */}
                    <table className="table mb-0">
                    <thead>
                        <tr>
                        <td colSpan={10} className="bg-color1 width-100 text-white padding-10" width="45%">
                            <div className="forces-div">
                            <span className="forces-img-bx">
                                <img src="../images/forces-icon2.png" alt="forces-icon1" />
                            </span>
                            <span className="forces-span text-font-size-14"><b className="text-font-size-16">Setting</b> (Pick a goal for a buyer, vertical, and capability. "Let me tell you about Jim, President of a manufacturer looking to reduce inventory costs.")
                            </span>
                            </div>
                        </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td colSpan={10} className="width-100 bg-color3 text-white text-center-c">
                            <input type="text" className="form-control inputTextf text-white"
                                id="CustomerTransformationC25"
                                value={Inputs.CustomerTransformationC25}
                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationC25: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={10} className="width-100 bg-color3 text-white text-center-c">
                            <input type="text" className="form-control inputTextf text-white"
                                id="CustomerTransformationC26"
                                value={Inputs.CustomerTransformationC26}
                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationC26: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                    </tbody>
                    </table>
                    {/* table 4 */}
                    <table className="table mb-0">
                    <thead>
                        <tr>
                        <th scope="row" colSpan={10} className="bg-color1 text-white padding-10" width="45%">
                            <div className="forces-div">
                            <span className="forces-img-bx">
                                <img src="../images/forces-icon1.png" alt="forces-icon1" />
                            </span>
                            <span className="forces-span text-font-size-14"><b className="text-font-size-16">Complications</b> (Describe buyer's painful situation.  Use emotion, imagery, metahor, analogy, and add a villian.)</span>
                            </div>
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td colSpan={10} className="width-100 bg-color3 text-white text-center-c">
                            <input type="text" className="form-control inputTextf text-white"
                                id="CustomerTransformationC28"
                                value={Inputs.CustomerTransformationC28}
                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationC28: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={10} className="width-100 bg-color3 text-white text-center-c">
                            <input type="text" className="form-control inputTextf text-white"
                                id="CustomerTransformationC29"
                                value={Inputs.CustomerTransformationC29}
                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationC29: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                    </tbody>
                    </table>
                    {/* table 5 */}
                    <table className="table mb-0">
                    <thead>
                        <tr>
                        <td colSpan={10} className="bg-color1 width-100 text-white padding-10" width="45%">
                            <div className="forces-div">
                            <span className="forces-img-bx">
                                <img src="../images/forces-icon3.png" alt="forces-icon1" />
                            </span>
                            <span className="forces-span text-font-size-14"><b className="text-font-size-16">Turning Point</b>  (This is the "aha" moment when the buyer realized they must change - either new insight or can't take status quote any longer.)</span>
                            </div>
                        </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td colSpan={10} className="width-100 bg-color3 text-white text-center-c">
                            <input type="text" className="form-control inputTextf text-white"
                                id="CustomerTransformationC31"
                                value={Inputs.CustomerTransformationC31}
                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationC31: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={10} className="width-100 bg-color3 text-white text-center-c">
                            <input type="text" className="form-control inputTextf text-white"
                                id="CustomerTransformationC32"
                                value={Inputs.CustomerTransformationC32}
                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationC32: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                    </tbody>
                    </table>
                    {/* table 6 */}
                    <table className="table mb-0">
                    <thead>
                        <tr>
                        <td colSpan={10} className="bg-color1 width-100 text-white padding-10" width="45%">
                            <div className="forces-div">
                            <span className="forces-img-bx">
                                <img src="../images/forces-icon4.png" alt="forces-icon1" />
                            </span>
                            <span className="forces-span text-font-size-14"><b className="text-font-size-16">Resolution</b>  (Describe how the buyer used your offering to solve their problem, how people were affected, and quantify the value.  )</span>
                            </div>
                        </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td colSpan={10} className="width-100 bg-color3 text-white text-center-c">
                            <input type="text" className="form-control inputTextf text-white"
                                id="CustomerTransformationC34"
                                value={Inputs.CustomerTransformationC34}
                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationC34: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={10} className="width-100 bg-color3 text-white text-center-c">
                            <input type="text" className="form-control inputTextf text-white"
                                id="CustomerTransformationC35"
                                value={Inputs.CustomerTransformationC35}
                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationC35: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                    </tbody>
                    </table>
                    {/* table 7 */}
                    <table className="table mb-0">
                    <thead>
                        <tr>
                        <td colSpan={10} className="bg-color1 width-100 text-white padding-10" width="45%">
                            <div className="forces-div">
                            <span className="forces-img-bx">
                                <img src="../images/forces-icon3.png" alt="forces-icon1" />
                            </span>
                            <span className="forces-span text-font-size-14"><b className="text-font-size-16">Questions</b>  ("That's enough about Jim, what's your situation?"  If your story doesn't resonate, move to the next insight story.)</span>
                            </div>
                        </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td colSpan={10} className="width-100 bg-color3 text-white text-center-c">
                            <input type="text" className="form-control inputTextf text-white"
                                id="CustomerTransformationC37"
                                value={Inputs.CustomerTransformationC37}
                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationC37: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={10} className="width-100 bg-color3 text-white text-center-c">
                            <input type="text" className="form-control inputTextf text-white"
                                id="CustomerTransformationC38"
                                value={Inputs.CustomerTransformationC38}
                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationC38: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}
