import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import  UserDropdown  from '../UserDropdown';
import CopyRightText from '../CopyRightText';
import {useSWRConfig} from "swr";
import { userData } from "../../state/features/AuthenticationSlice";
import getMenuChildren from '../../utils/getMenuChildren';

const HomeContent = () => {
    const { fallback, isLoading } = useSWRConfig()
    const users = useSelector(userData);
    const userID = users.data.profile.id;
    const location = useLocation();
    // const currentPath = location.pathname;
    const submenus = getMenuChildren(location.pathname, fallback?.menus)
    // useEffect(() => {
    //     mutate(`/settings/${userID}`,true);
    // }, []);

    return (
        <div className="content-body bg-dard-color bg_bottom">
            
            {/* row */}
            <div className="container-fluid">
                <UserDropdown />
                <div className="head-title">
                    <h3 className='font-sise-20'>Predictable growth… simplified.</h3>
                    <h2 className='font32px fontExtraBold text-white'>The Growth Catalyst</h2>
                </div>
                <div className='row'>
                    <div className="col-12 col-md-8 col-lg-10">
                        <div className="leftContetn">
                            <p className='font-sise-16'>
                                The Growth Catalyst helps businesses grow faster and more efficiently in competitive and disrupted environments.
                            </p>
                            <ul className='list-of-data'>
                                <li className='font-sise-16'>Unleash the growth potential of your company in just two days.</li>
                                <li className='font-sise-16'>Grow your business in a controlled, repeatable, and methodical way.</li>
                                <li className='font-sise-16'>Simplify creating sustainable growth in competitive and disruptive markets.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-2">
                        <div className="imageBx">
                            <img src={window.location.origin+"/images/Home_page_img/Picture1.png"} width={150 + 'px'} height={150 + 'px'} alt="Revenue Growth Drivers" title="Revenue Growth Drivers" />
                        </div>
                    </div>
                </div>
                <div className="row card-rwo mt-5" style={{maxWidth: 1400}}>
                    {submenus.slice(0,3).map((menu, index) => (
                        <div className="col-12 col-md-4 col-lg-3 mar-b" key={menu.parent_id+"_"+index}>
                            <div className="diagnosediv" style={[1, 2, 3].includes(menu.position)? {backgroundColor: '#44546a'} : {}}>
                                <h3 className='font-sise-bold-18'>
                                    <Link to={menu.menu_slug}>{`${menu.position}. ${menu.label}`}</Link>
                                </h3>
                                {/* <p className='font-size-16 text-white m-0'>Collect the quantitative and qualitative data needed to build your sustainable, predictable growth strategy.</p> */}
                                <p className='font-size-16 text-white m-0'>{menu.caption}</p>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="row card-rwo" style={{maxWidth: 1400}}>
                    {submenus.slice(3).map((menu, index) => (
                        <div className="col-12 col-md-4 col-lg-3 mar-b" key={menu.parent_id+"_"+index}>
                            <div className="diagnosediv" style={[1, 2, 3].includes(menu.position)? {backgroundColor: '#44546a'} : {}}>
                                <h3 className='font-sise-bold-18'>
                                    <Link to={menu.menu_slug}>{`${menu.position}. ${menu.label}`}</Link>
                                </h3>
                                {/* <p className='font-size-16 text-white m-0'>Collect the quantitative and qualitative data needed to build your sustainable, predictable growth strategy.</p> */}
                                <p className='font-size-16 text-white m-0'>{menu.caption}</p>
                            </div>
                        </div>
                    ))}
                </div>

                <div className='copyRgight'>
                    <CopyRightText />
                </div>

                <div className='space-di'></div>
            </div>
        </div>
    );
}

export default HomeContent;
