import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getFiveStepStrategyGrowthPlanning, modifyFiveStepStrategyGrowthPlanning } from "../../state/features/fiveStepStrategySlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';

export default function FiveStepStrategyContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        fiveStepStrategyC13 : '',
        fiveStepStrategyC14 : '',
        fiveStepStrategyC15 : '',
        fiveStepStrategyC16 : '',
        fiveStepStrategyD13 : '',
        fiveStepStrategyD14 : '',
        fiveStepStrategyD15 : '',
        fiveStepStrategyD16 : '',
        fiveStepStrategyE13 : '',
        fiveStepStrategyE14 : '',
        fiveStepStrategyE15 : '',
        fiveStepStrategyE16 : '',
        fiveStepStrategyF13 : '',
        fiveStepStrategyF14 : '',
        fiveStepStrategyF15 : '',
        fiveStepStrategyF16 : '',
        fiveStepStrategyG13 : '',
        fiveStepStrategyG14 : '',
        fiveStepStrategyG15 : '',
        fiveStepStrategyG16 : ''
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getFiveStepStrategyGrowthPlanning({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.growthPlanning)
            if(response!= undefined){
                let growthPlanning = response.data.growthPlanning[0];
                console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key]
                    }
                }
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyFiveStepStrategyGrowthPlanning({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

  return (
    <div className="content-body">
    <UserDropdown />
    
        {/* row */}
        <div className="container-fluid">
            <div className="head-title">
                <h2>Five-Step Strategy</h2>	            					
            </div>
            <div className="card">
                <div className="card-body">
                <div className="row">
                    <div className="col-md-12 contentleft">
                    <p>There are Nine Organic Revenue Growth Drivers.  The 120 Growth Catalyst can help you activate, align, and improve the performance of all nine, generating controlled and repeatable growth.</p>
                    </div>
                </div>
                </div>
            </div>
            <Spinner />
            <div className="card">
                <div className="card-body pl-0 fivestep tenyears">
                <table className="table">
                    <thead>
                    <tr className="thead-primary">
                        <th>Winning Aspiration</th>
                        <th>Where To Play</th>
                        <th>How to Win</th>
                        <th>Capabilities</th>
                        <th>Management System</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className="bgradiusf">What is your winning aspiration for the business?</td>
                        <td className="bgradiusf">In What markets will you play?</td>
                        <td className="bgradiusf">How will you win within markets you play?</td>
                        <td className="bgradiusf">What capabilities are required to win? </td>
                        <td className="bgradiusf">What management system are required to win?</td>
                    </tr>
                    <tr>
                        <td><input type="text" className="form-control"
                                id="fiveStepStrategyC13"
                                value={Inputs.fiveStepStrategyC13}
                                onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyC13: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Enter aspiration here" /></td>
                        <td><input type="text" className="form-control"
                                id="fiveStepStrategyC14"
                                value={Inputs.fiveStepStrategyC14}
                                onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyC14: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Enter market here" /></td>
                        <td><input type="text" className="form-control"
                                id="fiveStepStrategyC15"
                                value={Inputs.fiveStepStrategyC15}
                                onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyC15: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Enter how to win here " /></td>
                        <td><input type="text" className="form-control"
                                id="fiveStepStrategyC16"
                                value={Inputs.fiveStepStrategyC16}
                                onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyC16: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Enter capabilities here " /></td>
                        <td><input type="text" className="form-control"
                                id="fiveStepStrategyD13"
                                value={Inputs.fiveStepStrategyD13}
                                onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyD13: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Enter management system here" /></td>
                    </tr>
                    <tr>
                        <td><input type="text" className="form-control"
                                id="fiveStepStrategyD14"
                                value={Inputs.fiveStepStrategyD14}
                                onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyD14: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                /></td>
                        <td><input type="text" className="form-control"
                                id="fiveStepStrategyD15"
                                value={Inputs.fiveStepStrategyD15}
                                onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyD15: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                /></td>
                        <td><input type="text" className="form-control"
                                id="fiveStepStrategyD16"
                                value={Inputs.fiveStepStrategyD16}
                                onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyD16: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                /></td>
                        <td><input type="text" className="form-control"
                                id="fiveStepStrategyE13"
                                value={Inputs.fiveStepStrategyE13}
                                onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyE13: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                /></td>
                        <td><input type="text" className="form-control"
                                id="fiveStepStrategyE14"
                                value={Inputs.fiveStepStrategyE14}
                                onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyE14: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                /></td>
                    </tr>
                    <tr>
                        <td><input type="text" className="form-control"
                                id="fiveStepStrategyE15"
                                value={Inputs.fiveStepStrategyE15}
                                onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyE15: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                /></td>
                        <td><input type="text" className="form-control"
                                id="fiveStepStrategyE16"
                                value={Inputs.fiveStepStrategyE16}
                                onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyE16: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                /></td>
                        <td><input type="text" className="form-control"
                                id="fiveStepStrategyF13"
                                value={Inputs.fiveStepStrategyF13}
                                onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyF13: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                /></td>
                        <td><input type="text" className="form-control"
                                id="fiveStepStrategyF14"
                                value={Inputs.fiveStepStrategyF14}
                                onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyF14: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                /></td>
                        <td><input type="text" className="form-control"
                                id="fiveStepStrategyF15"
                                value={Inputs.fiveStepStrategyF15}
                                onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyF15: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                /></td>
                    </tr>
                    <tr>
                        <td><input type="text" className="form-control"
                                id="fiveStepStrategyF16"
                                value={Inputs.fiveStepStrategyF16}
                                onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyF16: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                /></td>
                        <td><input type="text" className="form-control"
                                id="fiveStepStrategyG13"
                                value={Inputs.fiveStepStrategyG13}
                                onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyG13: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                /></td>
                        <td><input type="text" className="form-control"
                                id="fiveStepStrategyG14"
                                value={Inputs.fiveStepStrategyG14}
                                onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyG14: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                /></td>
                        <td><input type="text" className="form-control"
                                id="fiveStepStrategyG15"
                                value={Inputs.fiveStepStrategyG15}
                                onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyG15: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                /></td>
                        <td><input type="text" className="form-control"
                                id="fiveStepStrategyG16"
                                value={Inputs.fiveStepStrategyG16}
                                onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyG16: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                /></td>
                    </tr>                                
                    </tbody>
                </table>
                </div>
            </div>
            </div>

    </div>
  )
}
