const initialState = {
    CustomerFinancialValuesD16 : 0,
    CustomerFinancialValuesE16 : 0,
    CustomerFinancialValuesF16 : 0,
    CustomerFinancialValuesG16 : 0,
    CustomerFinancialValuesH16 : 0,
    CustomerFinancialValuesI16 : 0,
    CustomerFinancialValuesD17 : 0,
    CustomerFinancialValuesE17 : 0,
    CustomerFinancialValuesF17 : 0,
    CustomerFinancialValuesG17 : 0,
    CustomerFinancialValuesH17 : 0,
    CustomerFinancialValuesI17 : 0,
    CustomerFinancialValuesD18 : 0,
    CustomerFinancialValuesE18 : 0,
    CustomerFinancialValuesF18 : 0,
    CustomerFinancialValuesG18 : 0,
    CustomerFinancialValuesH18 : 0,
    CustomerFinancialValuesI18 : 0,
    CustomerFinancialValuesD19 : 0,
    CustomerFinancialValuesE19 : 0,
    CustomerFinancialValuesF19 : 0,
    CustomerFinancialValuesG19 : 0,
    CustomerFinancialValuesH19 : 0,
    CustomerFinancialValuesI19 : 0,
    CustomerFinancialValuesD20 : 0,
    CustomerFinancialValuesE20 : 0,
    CustomerFinancialValuesF20 : 0,
    CustomerFinancialValuesG20 : 0,
    CustomerFinancialValuesH20 : 0,
    CustomerFinancialValuesI20 : 0,
    CustomerFinancialValuesD24 : 0,
    CustomerFinancialValuesE24 : 0,
    CustomerFinancialValuesF24 : 0,
    CustomerFinancialValuesG24 : 0,
    CustomerFinancialValuesH24 : 0,
    CustomerFinancialValuesI24 : 0,
    CustomerFinancialValuesD25 : 0,
    CustomerFinancialValuesE25 : 0,
    CustomerFinancialValuesF25 : 0,
    CustomerFinancialValuesG25 : 0,
    CustomerFinancialValuesH25 : 0,
    CustomerFinancialValuesI25 : 0,
    CustomerFinancialValuesD26 : 0,
    CustomerFinancialValuesE26 : 0,
    CustomerFinancialValuesF26 : 0,
    CustomerFinancialValuesG26 : 0,
    CustomerFinancialValuesH26 : 0,
    CustomerFinancialValuesI26 : 0,
    CustomerFinancialValuesD27 : 0,
    CustomerFinancialValuesE27 : 0,
    CustomerFinancialValuesF27 : 0,
    CustomerFinancialValuesG27 : 0,
    CustomerFinancialValuesH27 : 0,
    CustomerFinancialValuesI27 : 0
}
const reducer = (state = initialState, action) => {
    if(action.type === "CustomerFinancialValuesAllStats") {
        const { averageTransactionValueE18, averageTransactionValueF18, averageTransactionValueG18, averageTransactionValueH18, averageTransactionValueI18 } = action.payload.averageTransactionValue  
        const { purchaseFrequencyE18, purchaseFrequencyF18, purchaseFrequencyG18, purchaseFrequencyH18, purchaseFrequencyI18 } = action.payload.purchaseFrequency
        const { buyingLifeTimeE18, buyingLifeTimeF18, buyingLifeTimeG18, buyingLifeTimeH18, buyingLifeTimeI18 } = action.payload.buyingLifeTime
        const { profitMarginE18, profitMarginF18, profitMarginG18, profitMarginH18, profitMarginI18 } = action.payload.profitMargin

        let CustomerFinancialValuesD16 = (parseFloat(action.payload.clientInputsH33)).toFixed(2);
        let CustomerFinancialValuesE16 = (parseFloat(averageTransactionValueE18)).toFixed(2);
        let CustomerFinancialValuesF16 = (parseFloat(averageTransactionValueF18)).toFixed(2);
        let CustomerFinancialValuesG16 = (parseFloat(averageTransactionValueG18)).toFixed(2);
        let CustomerFinancialValuesH16 = (parseFloat(averageTransactionValueH18)).toFixed(2);
        let CustomerFinancialValuesI16 = (parseFloat(averageTransactionValueI18)).toFixed(2);

        let CustomerFinancialValuesD17 = (parseFloat(action.payload.clientInputsH30) / parseFloat(action.payload.clientInputsH16)).toFixed(1);
        let CustomerFinancialValuesE17 = (parseFloat(purchaseFrequencyE18)).toFixed(1);
        let CustomerFinancialValuesF17 = (parseFloat(purchaseFrequencyF18)).toFixed(1);
        let CustomerFinancialValuesG17 = (parseFloat(purchaseFrequencyG18)).toFixed(1);
        let CustomerFinancialValuesH17 = (parseFloat(purchaseFrequencyH18)).toFixed(1);
        let CustomerFinancialValuesI17 = (parseFloat(purchaseFrequencyI18)).toFixed(1);

        let CustomerFinancialValuesD18 = (parseFloat(action.payload.clientInputsH19)).toFixed(1);
        let CustomerFinancialValuesE18 = (parseFloat(buyingLifeTimeE18)).toFixed(1);
        let CustomerFinancialValuesF18 = (parseFloat(buyingLifeTimeF18)).toFixed(1);
        let CustomerFinancialValuesG18 = (parseFloat(buyingLifeTimeG18)).toFixed(1);
        let CustomerFinancialValuesH18 = (parseFloat(buyingLifeTimeH18)).toFixed(1);
        let CustomerFinancialValuesI18 = (parseFloat(buyingLifeTimeI18)).toFixed(1);

        let CustomerFinancialValuesD19 = (parseFloat(action.payload.clientInputsH36)).toFixed(1);;
        let CustomerFinancialValuesE19 = (parseFloat(profitMarginE18)).toFixed(1);
        let CustomerFinancialValuesF19 = (parseFloat(profitMarginF18)).toFixed(1);
        let CustomerFinancialValuesG19 = (parseFloat(profitMarginG18)).toFixed(1);
        let CustomerFinancialValuesH19 = (parseFloat(profitMarginH18)).toFixed(1);
        let CustomerFinancialValuesI19 = (parseFloat(profitMarginI18)).toFixed(1);

        let CustomerFinancialValuesD20 = parseFloat(action.payload.clientInputsH16).toFixed(1);
        let CustomerFinancialValuesE20 = parseFloat(action.payload.impactof120GrowthOSG27).toFixed(1);
        let CustomerFinancialValuesF20 = parseFloat(action.payload.impactof120GrowthOSH27).toFixed(1);
        let CustomerFinancialValuesG20 = parseFloat(action.payload.impactof120GrowthOSI27).toFixed(1);
        let CustomerFinancialValuesH20 = parseFloat(action.payload.impactof120GrowthOSJ27).toFixed(1);
        let CustomerFinancialValuesI20 = parseFloat(action.payload.impactof120GrowthOSK27).toFixed(1);

        let CustomerFinancialValuesD24 = (parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD17)).toFixed(2);
        let CustomerFinancialValuesE24 = (parseFloat(CustomerFinancialValuesE16) * parseFloat(CustomerFinancialValuesE17)).toFixed(2);
        let CustomerFinancialValuesF24 = (parseFloat(CustomerFinancialValuesF16) * parseFloat(CustomerFinancialValuesF17)).toFixed(2);
        let CustomerFinancialValuesG24 = (parseFloat(CustomerFinancialValuesG16) * parseFloat(CustomerFinancialValuesG17)).toFixed(2);
        let CustomerFinancialValuesH24 = (parseFloat(CustomerFinancialValuesH16) * parseFloat(CustomerFinancialValuesH17)).toFixed(2);
        let CustomerFinancialValuesI24 = (parseFloat(CustomerFinancialValuesI16) * parseFloat(CustomerFinancialValuesI17)).toFixed(2);

        let CustomerFinancialValuesD25 = (parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD17) * parseFloat(CustomerFinancialValuesD19) / 100).toFixed(2);
        let CustomerFinancialValuesE25 = (parseFloat(CustomerFinancialValuesE16) * parseFloat(CustomerFinancialValuesE17) * parseFloat(CustomerFinancialValuesE19) / 100).toFixed(2);
        let CustomerFinancialValuesF25 = (parseFloat(CustomerFinancialValuesF16) * parseFloat(CustomerFinancialValuesF17) * parseFloat(CustomerFinancialValuesF19) / 100).toFixed(2);
        let CustomerFinancialValuesG25 = (parseFloat(CustomerFinancialValuesG16) * parseFloat(CustomerFinancialValuesG17) * parseFloat(CustomerFinancialValuesG19) / 100).toFixed(2);
        let CustomerFinancialValuesH25 = (parseFloat(CustomerFinancialValuesH16) * parseFloat(CustomerFinancialValuesH17) * parseFloat(CustomerFinancialValuesH19) / 100).toFixed(2);
        let CustomerFinancialValuesI25 = (parseFloat(CustomerFinancialValuesI16) * parseFloat(CustomerFinancialValuesI17) * parseFloat(CustomerFinancialValuesI19) / 100).toFixed(2);

        let CustomerFinancialValuesD26 = (parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD17) * parseFloat(CustomerFinancialValuesD18)).toFixed(2);
        let CustomerFinancialValuesE26 = (parseFloat(CustomerFinancialValuesE16) * parseFloat(CustomerFinancialValuesE17) * parseFloat(CustomerFinancialValuesE18)).toFixed(2);
        let CustomerFinancialValuesF26 = (parseFloat(CustomerFinancialValuesF16) * parseFloat(CustomerFinancialValuesF17) * parseFloat(CustomerFinancialValuesF18)).toFixed(2);
        let CustomerFinancialValuesG26 = (parseFloat(CustomerFinancialValuesG16) * parseFloat(CustomerFinancialValuesG17) * parseFloat(CustomerFinancialValuesG18)).toFixed(2);
        let CustomerFinancialValuesH26 = (parseFloat(CustomerFinancialValuesH16) * parseFloat(CustomerFinancialValuesH17) * parseFloat(CustomerFinancialValuesH18)).toFixed(2);
        let CustomerFinancialValuesI26 = (parseFloat(CustomerFinancialValuesI16) * parseFloat(CustomerFinancialValuesI17) * parseFloat(CustomerFinancialValuesI18)).toFixed(2);

        let CustomerFinancialValuesD27 = (parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD17) * parseFloat(CustomerFinancialValuesD18) * parseFloat(CustomerFinancialValuesD19) / 100).toFixed(2);
        let CustomerFinancialValuesE27 = (parseFloat(CustomerFinancialValuesE16) * parseFloat(CustomerFinancialValuesE17) * parseFloat(CustomerFinancialValuesE18) * parseFloat(CustomerFinancialValuesE19) / 100).toFixed(2);
        let CustomerFinancialValuesF27 = (parseFloat(CustomerFinancialValuesF16) * parseFloat(CustomerFinancialValuesF17) * parseFloat(CustomerFinancialValuesF18) * parseFloat(CustomerFinancialValuesF19) / 100).toFixed(2);
        let CustomerFinancialValuesG27 = (parseFloat(CustomerFinancialValuesG16) * parseFloat(CustomerFinancialValuesG17) * parseFloat(CustomerFinancialValuesG18) * parseFloat(CustomerFinancialValuesG19) / 100).toFixed(2);
        let CustomerFinancialValuesH27 = (parseFloat(CustomerFinancialValuesH16) * parseFloat(CustomerFinancialValuesH17) * parseFloat(CustomerFinancialValuesH18) * parseFloat(CustomerFinancialValuesH19) / 100).toFixed(2);
        let CustomerFinancialValuesI27 = (parseFloat(CustomerFinancialValuesI16) * parseFloat(CustomerFinancialValuesI17) * parseFloat(CustomerFinancialValuesI18) * parseFloat(CustomerFinancialValuesI19) / 100).toFixed(2);

        return {
            ...state,
            CustomerFinancialValuesD16 : (CustomerFinancialValuesD16 != 'NaN') ? CustomerFinancialValuesD16 : 0,
            CustomerFinancialValuesE16 : (CustomerFinancialValuesE16 != 'NaN') ? CustomerFinancialValuesE16 : 0,
            CustomerFinancialValuesF16 : (CustomerFinancialValuesF16 != 'NaN') ? CustomerFinancialValuesF16 : 0,
            CustomerFinancialValuesG16 : (CustomerFinancialValuesG16 != 'NaN') ? CustomerFinancialValuesG16 : 0,
            CustomerFinancialValuesH16 : (CustomerFinancialValuesH16 != 'NaN') ? CustomerFinancialValuesH16 : 0,
            CustomerFinancialValuesI16 : (CustomerFinancialValuesI16 != 'NaN') ? CustomerFinancialValuesI16 : 0,
            CustomerFinancialValuesD17 : (CustomerFinancialValuesD17 != 'NaN') ? CustomerFinancialValuesD17 : 0,
            CustomerFinancialValuesE17 : (CustomerFinancialValuesE17 != 'NaN') ? CustomerFinancialValuesE17 : 0,
            CustomerFinancialValuesF17 : (CustomerFinancialValuesF17 != 'NaN') ? CustomerFinancialValuesF17 : 0,
            CustomerFinancialValuesG17 : (CustomerFinancialValuesG17 != 'NaN') ? CustomerFinancialValuesG17 : 0,
            CustomerFinancialValuesH17 : (CustomerFinancialValuesH17 != 'NaN') ? CustomerFinancialValuesH17 : 0,
            CustomerFinancialValuesI17 : (CustomerFinancialValuesI17 != 'NaN') ? CustomerFinancialValuesI17 : 0,
            CustomerFinancialValuesD18 : (CustomerFinancialValuesD18 != 'NaN') ? CustomerFinancialValuesD18 : 0,
            CustomerFinancialValuesE18 : (CustomerFinancialValuesE18 != 'NaN') ? CustomerFinancialValuesE18 : 0,
            CustomerFinancialValuesF18 : (CustomerFinancialValuesF18 != 'NaN') ? CustomerFinancialValuesF18 : 0,
            CustomerFinancialValuesG18 : (CustomerFinancialValuesG18 != 'NaN') ? CustomerFinancialValuesG18 : 0,
            CustomerFinancialValuesH18 : (CustomerFinancialValuesH18 != 'NaN') ? CustomerFinancialValuesH18 : 0,
            CustomerFinancialValuesI18 : (CustomerFinancialValuesI18 != 'NaN') ? CustomerFinancialValuesI18 : 0,
            CustomerFinancialValuesD19 : (CustomerFinancialValuesD19 != 'NaN') ? CustomerFinancialValuesD19 : 0,
            CustomerFinancialValuesE19 : (CustomerFinancialValuesE19 != 'NaN') ? CustomerFinancialValuesE19 : 0,
            CustomerFinancialValuesF19 : (CustomerFinancialValuesF19 != 'NaN') ? CustomerFinancialValuesF19 : 0,
            CustomerFinancialValuesG19 : (CustomerFinancialValuesG19 != 'NaN') ? CustomerFinancialValuesG19 : 0,
            CustomerFinancialValuesH19 : (CustomerFinancialValuesH19 != 'NaN') ? CustomerFinancialValuesH19 : 0,
            CustomerFinancialValuesI19 : (CustomerFinancialValuesI19 != 'NaN') ? CustomerFinancialValuesI19 : 0,
            CustomerFinancialValuesD20 : (CustomerFinancialValuesD20 != 'NaN') ? CustomerFinancialValuesD20 : 0,
            CustomerFinancialValuesE20 : (CustomerFinancialValuesE20 != 'NaN') ? CustomerFinancialValuesE20 : 0,
            CustomerFinancialValuesF20 : (CustomerFinancialValuesF20 != 'NaN') ? CustomerFinancialValuesF20 : 0,
            CustomerFinancialValuesG20 : (CustomerFinancialValuesG20 != 'NaN') ? CustomerFinancialValuesG20 : 0,
            CustomerFinancialValuesH20 : (CustomerFinancialValuesH20 != 'NaN') ? CustomerFinancialValuesH20 : 0,
            CustomerFinancialValuesI20 : (CustomerFinancialValuesI20 != 'NaN') ? CustomerFinancialValuesI20 : 0,
            CustomerFinancialValuesD24 : (CustomerFinancialValuesD24 != 'NaN') ? CustomerFinancialValuesD24 : 0,
            CustomerFinancialValuesE24 : (CustomerFinancialValuesE24 != 'NaN') ? CustomerFinancialValuesE24 : 0,
            CustomerFinancialValuesF24 : (CustomerFinancialValuesF24 != 'NaN') ? CustomerFinancialValuesF24 : 0,
            CustomerFinancialValuesG24 : (CustomerFinancialValuesG24 != 'NaN') ? CustomerFinancialValuesG24 : 0,
            CustomerFinancialValuesH24 : (CustomerFinancialValuesH24 != 'NaN') ? CustomerFinancialValuesH24 : 0,
            CustomerFinancialValuesI24 : (CustomerFinancialValuesI24 != 'NaN') ? CustomerFinancialValuesI24 : 0,
            CustomerFinancialValuesD25 : (CustomerFinancialValuesD25 != 'NaN') ? CustomerFinancialValuesD25 : 0,
            CustomerFinancialValuesE25 : (CustomerFinancialValuesE25 != 'NaN') ? CustomerFinancialValuesE25 : 0,
            CustomerFinancialValuesF25 : (CustomerFinancialValuesF25 != 'NaN') ? CustomerFinancialValuesF25 : 0,
            CustomerFinancialValuesG25 : (CustomerFinancialValuesG25 != 'NaN') ? CustomerFinancialValuesG25 : 0,
            CustomerFinancialValuesH25 : (CustomerFinancialValuesH25 != 'NaN') ? CustomerFinancialValuesH25 : 0,
            CustomerFinancialValuesI25 : (CustomerFinancialValuesI25 != 'NaN') ? CustomerFinancialValuesI25 : 0,
            CustomerFinancialValuesD26 : (CustomerFinancialValuesD26 != 'NaN') ? CustomerFinancialValuesD26 : 0,
            CustomerFinancialValuesE26 : (CustomerFinancialValuesE26 != 'NaN') ? CustomerFinancialValuesE26 : 0,
            CustomerFinancialValuesF26 : (CustomerFinancialValuesF26 != 'NaN') ? CustomerFinancialValuesF26 : 0,
            CustomerFinancialValuesG26 : (CustomerFinancialValuesG26 != 'NaN') ? CustomerFinancialValuesG26 : 0,
            CustomerFinancialValuesH26 : (CustomerFinancialValuesH26 != 'NaN') ? CustomerFinancialValuesH26 : 0,
            CustomerFinancialValuesI26 : (CustomerFinancialValuesI26 != 'NaN') ? CustomerFinancialValuesI26 : 0,
            CustomerFinancialValuesD27 : (CustomerFinancialValuesD27 != 'NaN') ? CustomerFinancialValuesD27 : 0,
            CustomerFinancialValuesE27 : (CustomerFinancialValuesE27 != 'NaN') ? CustomerFinancialValuesE27 : 0,
            CustomerFinancialValuesF27 : (CustomerFinancialValuesF27 != 'NaN') ? CustomerFinancialValuesF27 : 0,
            CustomerFinancialValuesG27 : (CustomerFinancialValuesG27 != 'NaN') ? CustomerFinancialValuesG27 : 0,
            CustomerFinancialValuesH27 : (CustomerFinancialValuesH27 != 'NaN') ? CustomerFinancialValuesH27 : 0,
            CustomerFinancialValuesI27 : (CustomerFinancialValuesI27 != 'NaN') ? CustomerFinancialValuesI27 : 0
        }
        
    }
    // else if (action.type === "INITIAL_STATE") {
    //     state = initialState;
    //     return state
    // } 
    else {
        return state
    }

}

export default reducer