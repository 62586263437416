import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../features/Message";
import clientInputService from "../../services/clientInput.service";

//Get Client Input fields data
export const getClientInputs = createAsyncThunk(
  "clientInputs/getClientInputs",
  async (userID, thunkAPI) => {
    try {
      const response = await clientInputService.getData(userID);
      //console.log(response)
      thunkAPI.dispatch(setMessage(response.data.message));
      return response.data
    } catch (error) {
      console.log(error)
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

//Update Client Input Data
export const updateClientInputs = createAsyncThunk(
  "clientInputs/postClientInputData",
  async (postClientData , thunkAPI) => {
    try {
      const data = await clientInputService.postData(postClientData);
      thunkAPI.dispatch(setMessage(""));
      // console.log(data)
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

//update percentage improvement data
export const addEditImprovementPercentage = createAsyncThunk(
  "clientInputs/addEditImprovementPercentage",
  async (postImprovementPrcntData, thunkAPI) => {
    try {
      const response = await clientInputService.addEditImprovementPercentage(postImprovementPrcntData);
      //console.log(response)
      thunkAPI.dispatch(setMessage(response.data.message));
      return response.data
    } catch (error) {
      console.log(error)
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

//define initial states
const initialState = {
  clientInputsD11: 0,
  clientInputsD12: 0,
  clientInputsD13: 0,
  clientInputsE11: 0,
  clientInputsE12: 0,
  clientInputsF11: 0,
  clientInputsH16: 0,
  clientInputsH17: 0,
  clientInputsH18: 0,
  clientInputsH19: 0,
  clientInputsH20: 0,
  clientInputsH21: 0,
  clientInputsH22: 0,
  clientInputsH23: 0,
  clientInputsH24: 0,
  clientInputsH25: 0,
  clientInputsH26: 0,
  clientInputsH27: 0,
  clientInputsH30: 0,
  clientInputsH31: 0,
  clientInputsH32: 0,
  clientInputsH33: 0,
  clientInputsH36: 0,
  clientInputsH37: 0,
  clientInputsH38: 0,
  clientInputsH41: 0,
  improvementPercentage: {},
  loading: false,
}

export const clientInputSlice = createSlice({
  name: 'clientInputs',
  initialState,
  reducers: {
    clientInputsD11(state, action) {
      console.log("-----first------", action)
      state.clientInputsD11 = parseFloat(action.payload)
    },
    clientInputsD12(state, action) {
      state.clientInputsD12 = parseFloat(action.payload)
    },
    clientInputsD13(state, action) {
      state.clientInputsD13 = parseFloat(action.payload)
    },
    clientInputsH16(state, action) {
      state.clientInputsH16 = parseFloat(action.payload)
    },
    clientInputsH17(state, action) {
      state.clientInputsH17 = parseFloat(action.payload)
    },
    clientInputsH18(state, action) {
      state.clientInputsH18 = parseFloat(action.payload)
    },
    clientInputsH19(state, action) {
      state.clientInputsH19 = parseFloat(action.payload)
    },
    clientInputsH20(state, action) {
      state.clientInputsH20 = parseFloat(action.payload)
    },
    clientInputsH21(state, action) {
      state.clientInputsH21 = parseFloat(action.payload)
    },
    clientInputsH22(state, action) {
      state.clientInputsH22 = parseFloat(action.payload)
    },
    clientInputsH23(state, action) {
      state.clientInputsH23 = parseFloat(action.payload)
    },
    clientInputsH24(state, action) {
      state.clientInputsH24 = parseFloat(action.payload)
    },
    clientInputsH25(state, action) {
      state.clientInputsH25 = parseFloat(action.payload)
    },
    clientInputsH26(state, action) {
      state.clientInputsH26 = parseFloat(action.payload)
    },
    clientInputsH27(state, action) {
      state.clientInputsH27 = parseFloat(action.payload)
    },
    clientInputsH30(state, action) {
      state.clientInputsH30 = parseFloat(action.payload)
    },
    clientInputsH31(state, action) {
      state.clientInputsH31 = parseFloat(action.payload)
    },
    clientInputsH32(state, action) {
      state.clientInputsH32 = parseFloat(action.payload)
    },
    clientInputsH33(state, action) {
      state.clientInputsH33 = parseFloat(action.payload)
    },
    clientInputsH36(state, action) {
      state.clientInputsH36 = parseFloat(action.payload)
    },
    clientInputsH37(state, action) {
      state.clientInputsH37 = parseFloat(action.payload)
    },
    clientInputsH38(state, action) {
      state.clientInputsH38 = parseFloat(action.payload)
    },
    clientInputsH41(state, action) {
      state.clientInputsH41 = parseFloat(action.payload)
    },
    clientInputsGrowthRate(state, action) {
      let PrevAbsoluteChange = parseFloat(state.clientInputsD12) - parseFloat(state.clientInputsD13);
      let PresentAbsoluteChange = parseFloat(state.clientInputsD11) - parseFloat(state.clientInputsD12);
      let PrevGrowthRate = (PrevAbsoluteChange / state.clientInputsD13) * 100;
      let PresentGrowthRate = (PresentAbsoluteChange / state.clientInputsD12) * 100;
      //PrevGrowthRate = parseFloat(state.clientInputsD12) > parseFloat(state.clientInputsD13) ? PrevGrowthRate : 0;
      //PresentGrowthRate = parseFloat(state.clientInputsD11) > parseFloat(state.clientInputsD12) ? PresentGrowthRate : 0;
      if (!isNaN(PrevGrowthRate) && !isNaN(PresentGrowthRate)) {
        PrevGrowthRate = PrevGrowthRate.toFixed(2);
        PresentGrowthRate = PresentGrowthRate.toFixed(2);
      }

      state.clientInputsE11 = PresentGrowthRate
      state.clientInputsE12 = PrevGrowthRate
    },
    clientInputsCAGR(state, action) {
      let CAGRValue = 0;
      if (!isNaN(state.clientInputsD11) && !isNaN(state.clientInputsD13)) {
        let annualSalesRatio = parseFloat(state.clientInputsD11) / parseFloat(state.clientInputsD13);
        CAGRValue = (Math.pow(annualSalesRatio, 0.5) - 1) * 100;
        CAGRValue = CAGRValue > 0 ? CAGRValue.toFixed(2) : 0;
      }

      state.clientInputsF11 = CAGRValue
    },
  },
  extraReducers: {
    [getClientInputs.pending]: (state) => {
      state.loading = true
    },
    [getClientInputs.fulfilled]: (state, { payload }) => {
      console.log('clientInputPayload',payload)
      //console.log("---------get client Input Data -----------", payload.data.clientInputs)
      state.loading = false
      state.clientInputsD11 = (payload.data != undefined) ? payload.data.clientInputs.clientInputsD11 : 0
      state.clientInputsD12 = (payload.data != undefined) ? payload.data.clientInputs.clientInputsD12 : 0
      state.clientInputsD13 = (payload.data != undefined) ? payload.data.clientInputs.clientInputsD13 : 0
      state.clientInputsE11 = (payload.data != undefined) ? payload.data.clientInputs.clientInputsE11 : 0
      state.clientInputsE12 = (payload.data != undefined) ? payload.data.clientInputs.clientInputsE12 : 0
      state.clientInputsF11 = (payload.data != undefined) ? payload.data.clientInputs.clientInputsF11 : 0
      state.clientInputsH16 = (payload.data != undefined) ? payload.data.clientInputs.clientInputsH16 : 0
      state.clientInputsH17 = (payload.data != undefined) ? payload.data.clientInputs.clientInputsH17 : 0
      state.clientInputsH18 = (payload.data != undefined) ? payload.data.clientInputs.clientInputsH18 : 0
      state.clientInputsH19 = (payload.data != undefined) ? payload.data.clientInputs.clientInputsH19 : 0
      state.clientInputsH20 = (payload.data != undefined) ? payload.data.clientInputs.clientInputsH20 : 0
      state.clientInputsH21 = (payload.data != undefined) ? payload.data.clientInputs.clientInputsH21 : 0
      state.clientInputsH22 = (payload.data != undefined) ? payload.data.clientInputs.clientInputsH22 : 0
      state.clientInputsH23 = (payload.data != undefined) ? payload.data.clientInputs.clientInputsH23 : 0
      state.clientInputsH24 = (payload.data != undefined) ? payload.data.clientInputs.clientInputsH24 : 0
      state.clientInputsH25 = (payload.data != undefined) ? payload.data.clientInputs.clientInputsH25 : 0
      state.clientInputsH26 = (payload.data != undefined) ? payload.data.clientInputs.clientInputsH26 : 0
      state.clientInputsH27 = (payload.data != undefined) ? payload.data.clientInputs.clientInputsH27 : 0
      state.clientInputsH30 = (payload.data != undefined) ? payload.data.clientInputs.clientInputsH30 : 0
      state.clientInputsH31 = (payload.data != undefined) ? payload.data.clientInputs.clientInputsH31 : 0
      state.clientInputsH32 = (payload.data != undefined) ? payload.data.clientInputs.clientInputsH32 : 0
      state.clientInputsH33 = (payload.data != undefined) ? payload.data.clientInputs.clientInputsH33 : 0
      state.clientInputsH36 = (payload.data != undefined) ? payload.data.clientInputs.clientInputsH36 : 0
      state.clientInputsH37 = (payload.data != undefined) ? payload.data.clientInputs.clientInputsH37 : 0
      state.clientInputsH38 = (payload.data != undefined) ? payload.data.clientInputs.clientInputsH38 : 0
      state.clientInputsH41 = (payload.data != undefined) ? payload.data.clientInputs.clientInputsH41 : 0
      state.improvementPercentage = (payload.data != undefined) ? payload.data.improvementPercentage : 0
    },
    [getClientInputs.rejected]: (state) => {
      state.loading = false
    },
  },
})


const { reducer } = clientInputSlice;
export default reducer;
export const { clientInputsGrowthRate, clientInputsCAGR, clientInputsD11, clientInputsD12, clientInputsD13, clientInputsE11, clientInputsE12, clientInputsF11, clientInputsH16, clientInputsH17, clientInputsH18, clientInputsH19, clientInputsH20, clientInputsH21, clientInputsH22, clientInputsH23, clientInputsH24, clientInputsH25, clientInputsH26, clientInputsH27, clientInputsH30, clientInputsH31, clientInputsH32, clientInputsH33, clientInputsH36, clientInputsH37, clientInputsH38, clientInputsH41, } = clientInputSlice.actions
