import sum from "../../utils/sum";
import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getWhatIsWorkingNow, modifyWhatIsWorkingNow } from "../../state/features/WhatIsWorkingNowSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

const labels = ['Customer Communication Program', 'Targeted Accounts Program', 'Reactivation Program (offer 6)', 'PoP upsell & cross-sell (inside sales)', 'Referral Program (offer 3)', 'PoP "did you know…" ', 'Insight Story Webinar', 'Organic Search', 'Package Inserts', 'Google Adwords (10 keywords)', 'LinkedIn prospecting', 'Sales Prospecting', 'Trade Shows'];

export default function WhatIsWorkingNowContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        WhatWorkingNowM12 : 0,
        WhatWorkingNowM13 : 0,
        WhatWorkingNowM14 : 0,
        WhatWorkingNowM15 : 0,
        WhatWorkingNowM16 : 0,
        WhatWorkingNowM17 : 0,
        WhatWorkingNowM18 : 0,
        WhatWorkingNowM19 : 0,
        WhatWorkingNowM20 : 0,
        WhatWorkingNowM21 : 0,
        WhatWorkingNowM22 : 0,
        WhatWorkingNowM23 : 0,
        WhatWorkingNowM24 : 0,
        WhatWorkingNowM25 : 0,
        WhatWorkingNowM26 : 0,
        WhatWorkingNowM27 : 0,
        WhatWorkingNowN12 : 0,
        WhatWorkingNowN13 : 0,
        WhatWorkingNowN14 : 0,
        WhatWorkingNowN15 : 0,
        WhatWorkingNowN16 : 0,
        WhatWorkingNowN17 : 0,
        WhatWorkingNowN18 : 0,
        WhatWorkingNowN19 : 0,
        WhatWorkingNowN20 : 0,
        WhatWorkingNowN21 : 0,
        WhatWorkingNowN22 : 0,
        WhatWorkingNowN23 : 0,
        WhatWorkingNowN24 : 0,
        WhatWorkingNowN25 : 0,
        WhatWorkingNowN26 : 0,
        WhatWorkingNowN10 : 80
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getWhatIsWorkingNow({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.growthPlanning)
            if(response!= undefined  && response.data.growthPlanning){
                let growthPlanning = response.data.growthPlanning[0];
                console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key]
                    }
                }
                newObj['WhatWorkingNowM27'] = (sum(newObj['WhatWorkingNowM12'], newObj['WhatWorkingNowM13'], newObj['WhatWorkingNowM14'], newObj['WhatWorkingNowM15'], newObj['WhatWorkingNowM16'], newObj['WhatWorkingNowM17'], newObj['WhatWorkingNowM18'], newObj['WhatWorkingNowM19'], newObj['WhatWorkingNowM20'], newObj['WhatWorkingNowM21'], newObj['WhatWorkingNowM22'], newObj['WhatWorkingNowM23'], newObj['WhatWorkingNowM24'], newObj['WhatWorkingNowM25'], newObj['WhatWorkingNowM26'])).toFixed(2);

                newObj['WhatWorkingNowN12'] = ((parseFloat(newObj['WhatWorkingNowM12']) / parseFloat(newObj['WhatWorkingNowM27'])) * 100).toFixed(2);
                newObj['WhatWorkingNowN13'] = ((sum(newObj['WhatWorkingNowM12'], newObj['WhatWorkingNowM13']) / parseFloat(newObj['WhatWorkingNowM27'])) * 100).toFixed(2);
                newObj['WhatWorkingNowN14'] = ((sum(newObj['WhatWorkingNowM12'], newObj['WhatWorkingNowM13'], newObj['WhatWorkingNowM14']) / parseFloat(newObj['WhatWorkingNowM27'])) * 100).toFixed(2);
                newObj['WhatWorkingNowN15'] = ((sum(newObj['WhatWorkingNowM12'], newObj['WhatWorkingNowM13'], newObj['WhatWorkingNowM14'], newObj['WhatWorkingNowM15']) / parseFloat(newObj['WhatWorkingNowM27'])) * 100).toFixed(2);
                newObj['WhatWorkingNowN16'] = ((sum(newObj['WhatWorkingNowM12'], newObj['WhatWorkingNowM13'], newObj['WhatWorkingNowM14'], newObj['WhatWorkingNowM15'], newObj['WhatWorkingNowM16']) / parseFloat(newObj['WhatWorkingNowM27'])) * 100).toFixed(2);
                newObj['WhatWorkingNowN17'] = ((sum(newObj['WhatWorkingNowM12'], newObj['WhatWorkingNowM13'], newObj['WhatWorkingNowM14'], newObj['WhatWorkingNowM15'], newObj['WhatWorkingNowM16'], newObj['WhatWorkingNowM17'], newObj['WhatWorkingNowM18']) / parseFloat(newObj['WhatWorkingNowM27'])) * 100).toFixed(2);
                newObj['WhatWorkingNowN18'] = ((sum(newObj['WhatWorkingNowM12'], newObj['WhatWorkingNowM13'], newObj['WhatWorkingNowM14'], newObj['WhatWorkingNowM15'], newObj['WhatWorkingNowM16'], newObj['WhatWorkingNowM17'], newObj['WhatWorkingNowM18'], newObj['WhatWorkingNowM19']) / parseFloat(newObj['WhatWorkingNowM27'])) * 100).toFixed(2);
                newObj['WhatWorkingNowN19'] = ((sum(newObj['WhatWorkingNowM12'], newObj['WhatWorkingNowM13'], newObj['WhatWorkingNowM14'], newObj['WhatWorkingNowM15'], newObj['WhatWorkingNowM16'], newObj['WhatWorkingNowM17'], newObj['WhatWorkingNowM18'], newObj['WhatWorkingNowM19'], newObj['WhatWorkingNowM20']) / parseFloat(newObj['WhatWorkingNowM27'])) * 100).toFixed(2);
                newObj['WhatWorkingNowN20'] = ((sum(newObj['WhatWorkingNowM12'], newObj['WhatWorkingNowM13'], newObj['WhatWorkingNowM14'], newObj['WhatWorkingNowM15'], newObj['WhatWorkingNowM16'], newObj['WhatWorkingNowM17'], newObj['WhatWorkingNowM18'], newObj['WhatWorkingNowM19'], newObj['WhatWorkingNowM20'], newObj['WhatWorkingNowM21']) / parseFloat(newObj['WhatWorkingNowM27'])) * 100).toFixed(2);
                newObj['WhatWorkingNowN21'] = ((sum(newObj['WhatWorkingNowM12'], newObj['WhatWorkingNowM13'], newObj['WhatWorkingNowM14'], newObj['WhatWorkingNowM15'], newObj['WhatWorkingNowM16'], newObj['WhatWorkingNowM17'], newObj['WhatWorkingNowM18'], newObj['WhatWorkingNowM19'], newObj['WhatWorkingNowM20'], newObj['WhatWorkingNowM21'], newObj['WhatWorkingNowM22']) / parseFloat(newObj['WhatWorkingNowM27'])) * 100).toFixed(2);
                newObj['WhatWorkingNowN22'] = ((sum(newObj['WhatWorkingNowM12'], newObj['WhatWorkingNowM13'], newObj['WhatWorkingNowM14'], newObj['WhatWorkingNowM15'], newObj['WhatWorkingNowM16'], newObj['WhatWorkingNowM17'], newObj['WhatWorkingNowM18'], newObj['WhatWorkingNowM19'], newObj['WhatWorkingNowM20'], newObj['WhatWorkingNowM21'], newObj['WhatWorkingNowM22'], newObj['WhatWorkingNowM23']) / parseFloat(newObj['WhatWorkingNowM27'])) * 100).toFixed(2);
                newObj['WhatWorkingNowN23'] = ((sum(newObj['WhatWorkingNowM12'], newObj['WhatWorkingNowM13'], newObj['WhatWorkingNowM14'], newObj['WhatWorkingNowM15'], newObj['WhatWorkingNowM16'], newObj['WhatWorkingNowM17'], newObj['WhatWorkingNowM18'], newObj['WhatWorkingNowM19'], newObj['WhatWorkingNowM20'], newObj['WhatWorkingNowM21'], newObj['WhatWorkingNowM22'], newObj['WhatWorkingNowM23']) / parseFloat(newObj['WhatWorkingNowM27'])) * 100).toFixed(2);
                newObj['WhatWorkingNowN24'] = ((sum(newObj['WhatWorkingNowM12'], newObj['WhatWorkingNowM13'], newObj['WhatWorkingNowM14'], newObj['WhatWorkingNowM15'], newObj['WhatWorkingNowM16'], newObj['WhatWorkingNowM17'], newObj['WhatWorkingNowM18'], newObj['WhatWorkingNowM19'], newObj['WhatWorkingNowM20'], newObj['WhatWorkingNowM21'], newObj['WhatWorkingNowM22'], newObj['WhatWorkingNowM23'], newObj['WhatWorkingNowM24']) / parseFloat(newObj['WhatWorkingNowM27'])) * 100).toFixed(2);
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value.toString().replace(/,/g, '')){
                dispatch(modifyWhatIsWorkingNow({ user_id, [event.target.id] : event.target.value.toString().replace(/,/g, '')}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value.toString().replace(/,/g, '')});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

    useEffect(() => {
      let WhatWorkingNowM12 = isNaN(Inputs.WhatWorkingNowM12) ? 0 : Inputs.WhatWorkingNowM12;
      let WhatWorkingNowM13 = isNaN(Inputs.WhatWorkingNowM13) ? 0 : Inputs.WhatWorkingNowM13;
      let WhatWorkingNowM14 = isNaN(Inputs.WhatWorkingNowM14) ? 0 : Inputs.WhatWorkingNowM14;
      let WhatWorkingNowM15 = isNaN(Inputs.WhatWorkingNowM15) ? 0 : Inputs.WhatWorkingNowM15;
      let WhatWorkingNowM16 = isNaN(Inputs.WhatWorkingNowM16) ? 0 : Inputs.WhatWorkingNowM16;
      let WhatWorkingNowM17 = isNaN(Inputs.WhatWorkingNowM17) ? 0 : Inputs.WhatWorkingNowM17;
      let WhatWorkingNowM18 = isNaN(Inputs.WhatWorkingNowM18) ? 0 : Inputs.WhatWorkingNowM18;
      let WhatWorkingNowM19 = isNaN(Inputs.WhatWorkingNowM19) ? 0 : Inputs.WhatWorkingNowM19;
      let WhatWorkingNowM20 = isNaN(Inputs.WhatWorkingNowM20) ? 0 : Inputs.WhatWorkingNowM20;
      let WhatWorkingNowM21 = isNaN(Inputs.WhatWorkingNowM21) ? 0 : Inputs.WhatWorkingNowM21;
      let WhatWorkingNowM22 = isNaN(Inputs.WhatWorkingNowM22) ? 0 : Inputs.WhatWorkingNowM22;
      let WhatWorkingNowM23 = isNaN(Inputs.WhatWorkingNowM23) ? 0 : Inputs.WhatWorkingNowM23;
      let WhatWorkingNowM24 = isNaN(Inputs.WhatWorkingNowM24) ? 0 : Inputs.WhatWorkingNowM24;
      let WhatWorkingNowM25 = isNaN(Inputs.WhatWorkingNowM25) ? 0 : Inputs.WhatWorkingNowM25;
      let WhatWorkingNowM26 = isNaN(Inputs.WhatWorkingNowM26) ? 0 : Inputs.WhatWorkingNowM26;

        let WhatWorkingNowM27 = (sum(WhatWorkingNowM12, WhatWorkingNowM13, WhatWorkingNowM14, WhatWorkingNowM15, WhatWorkingNowM16, WhatWorkingNowM17, WhatWorkingNowM18, WhatWorkingNowM19, WhatWorkingNowM20, WhatWorkingNowM21, WhatWorkingNowM22, WhatWorkingNowM23, WhatWorkingNowM24, WhatWorkingNowM25, WhatWorkingNowM26)).toFixed(2);
        if(WhatWorkingNowM27 != 'NaN'){
          let WhatWorkingNowN12 = ((parseFloat(WhatWorkingNowM12) / parseFloat(WhatWorkingNowM27)) * 100).toFixed(2);
          let WhatWorkingNowN13 = ((sum(WhatWorkingNowM12, WhatWorkingNowM13) / parseFloat(WhatWorkingNowM27)) * 100).toFixed(2);
          let WhatWorkingNowN14 = ((sum(WhatWorkingNowM12, WhatWorkingNowM13, WhatWorkingNowM14) / parseFloat(WhatWorkingNowM27)) * 100).toFixed(2);
          let WhatWorkingNowN15 = ((sum(WhatWorkingNowM12, WhatWorkingNowM13, WhatWorkingNowM14, WhatWorkingNowM15) / parseFloat(WhatWorkingNowM27)) * 100).toFixed(2);
          let WhatWorkingNowN16 = ((sum(WhatWorkingNowM12, WhatWorkingNowM13, WhatWorkingNowM14, WhatWorkingNowM15, WhatWorkingNowM16) / parseFloat(WhatWorkingNowM27)) * 100).toFixed(2);
          let WhatWorkingNowN17 = ((sum(WhatWorkingNowM12, WhatWorkingNowM13, WhatWorkingNowM14, WhatWorkingNowM15, WhatWorkingNowM16, WhatWorkingNowM17, WhatWorkingNowM18) / parseFloat(WhatWorkingNowM27)) * 100).toFixed(2);
          let WhatWorkingNowN18 = ((sum(WhatWorkingNowM12, WhatWorkingNowM13, WhatWorkingNowM14, WhatWorkingNowM15, WhatWorkingNowM16, WhatWorkingNowM17, WhatWorkingNowM18, WhatWorkingNowM19) / parseFloat(WhatWorkingNowM27)) * 100).toFixed(2);
          let WhatWorkingNowN19 = ((sum(WhatWorkingNowM12, WhatWorkingNowM13, WhatWorkingNowM14, WhatWorkingNowM15, WhatWorkingNowM16, WhatWorkingNowM17, WhatWorkingNowM18, WhatWorkingNowM19, WhatWorkingNowM20) / parseFloat(WhatWorkingNowM27)) * 100).toFixed(2);
          let WhatWorkingNowN20 = ((sum(WhatWorkingNowM12, WhatWorkingNowM13, WhatWorkingNowM14, WhatWorkingNowM15, WhatWorkingNowM16, WhatWorkingNowM17, WhatWorkingNowM18, WhatWorkingNowM19, WhatWorkingNowM20, WhatWorkingNowM21) / parseFloat(WhatWorkingNowM27)) * 100).toFixed(2);
          let WhatWorkingNowN21 = ((sum(WhatWorkingNowM12, WhatWorkingNowM13, WhatWorkingNowM14, WhatWorkingNowM15, WhatWorkingNowM16, WhatWorkingNowM17, WhatWorkingNowM18, WhatWorkingNowM19, WhatWorkingNowM20, WhatWorkingNowM21, WhatWorkingNowM22) / parseFloat(WhatWorkingNowM27)) * 100).toFixed(2);
          let WhatWorkingNowN22 = ((sum(WhatWorkingNowM12, WhatWorkingNowM13, WhatWorkingNowM14, WhatWorkingNowM15, WhatWorkingNowM16, WhatWorkingNowM17, WhatWorkingNowM18, WhatWorkingNowM19, WhatWorkingNowM20, WhatWorkingNowM21, WhatWorkingNowM22, WhatWorkingNowM23) / parseFloat(WhatWorkingNowM27)) * 100).toFixed(2);
          let WhatWorkingNowN23 = ((sum(WhatWorkingNowM12, WhatWorkingNowM13, WhatWorkingNowM14, WhatWorkingNowM15, WhatWorkingNowM16, WhatWorkingNowM17, WhatWorkingNowM18, WhatWorkingNowM19, WhatWorkingNowM20, WhatWorkingNowM21, WhatWorkingNowM22, WhatWorkingNowM23) / parseFloat(WhatWorkingNowM27)) * 100).toFixed(2);
          let WhatWorkingNowN24 = ((sum(WhatWorkingNowM12, WhatWorkingNowM13, WhatWorkingNowM14, WhatWorkingNowM15, WhatWorkingNowM16, WhatWorkingNowM17, WhatWorkingNowM18, WhatWorkingNowM19, WhatWorkingNowM20, WhatWorkingNowM21, WhatWorkingNowM22, WhatWorkingNowM23, WhatWorkingNowM24) / parseFloat(WhatWorkingNowM27)) * 100).toFixed(2);

          setInputs({...Inputs, WhatWorkingNowN12, WhatWorkingNowN13, WhatWorkingNowN14, WhatWorkingNowN15, WhatWorkingNowN16, WhatWorkingNowN17, WhatWorkingNowN18, WhatWorkingNowN19, WhatWorkingNowN20, WhatWorkingNowN21, WhatWorkingNowN22, WhatWorkingNowN23, WhatWorkingNowN24, WhatWorkingNowM27})
        }
    }, [Inputs.WhatWorkingNowM12, Inputs.WhatWorkingNowM13, Inputs.WhatWorkingNowM14, Inputs.WhatWorkingNowM15, Inputs.WhatWorkingNowM16, Inputs.WhatWorkingNowM17, Inputs.WhatWorkingNowM18, Inputs.WhatWorkingNowM19, Inputs.WhatWorkingNowM20, Inputs.WhatWorkingNowM21, Inputs.WhatWorkingNowM22, Inputs.WhatWorkingNowM23, Inputs.WhatWorkingNowM24])

    const options = {
      responsive: true,
      interaction: {
        mode: 'index',
        intersect: false,
      },
      stacked: false,
      plugins: {
        title: {
          display: true,
          text: 'Chart.js Line Chart - Multi Axis',
        },
      },
      scales: {
        y: {
          type: 'linear',
          display: true,
          position: 'left',
        },
        y1: {
          type: 'linear',
          display: true,
          position: 'right',
          grid: {
            drawOnChartArea: false,
          },
        },
      },
    };
    
    const data = {
      labels,
      datasets: [
        {
          type: 'line',
          label: 'Cut off %',
          borderColor: '#0d6df7',
          backgroundColor : '#0d6df7',
          borderWidth: 1,
          fill: false,
          data: [80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80],
          yAxisID: 'y1',
        },
        {
          type: 'line',
          label: 'Cumulative %',
          borderColor: '#9ca2ac',
          backgroundColor : '#9ca2ac',
          borderWidth: 1,
          fill: false,
          data: [Inputs.WhatWorkingNowN12, Inputs.WhatWorkingNowN13, Inputs.WhatWorkingNowN14, Inputs.WhatWorkingNowN15, Inputs.WhatWorkingNowN16, Inputs.WhatWorkingNowN17, Inputs.WhatWorkingNowN18, Inputs.WhatWorkingNowN19, Inputs.WhatWorkingNowN20, Inputs.WhatWorkingNowN21, Inputs.WhatWorkingNowN22, Inputs.WhatWorkingNowN23, Inputs.WhatWorkingNowN24, Inputs.WhatWorkingNowN25, Inputs.WhatWorkingNowN26],
          yAxisID: 'y1',
        },
        {
          type: 'bar',
          label: 'Quarterly Sales $',
          backgroundColor: '#5dbab6',
          data: [Inputs.WhatWorkingNowM12, Inputs.WhatWorkingNowM13, Inputs.WhatWorkingNowM14, Inputs.WhatWorkingNowM15, Inputs.WhatWorkingNowM16, Inputs.WhatWorkingNowM17, Inputs.WhatWorkingNowM18, Inputs.WhatWorkingNowM19, Inputs.WhatWorkingNowM20, Inputs.WhatWorkingNowM21, Inputs.WhatWorkingNowM22, Inputs.WhatWorkingNowM23, Inputs.WhatWorkingNowM24, Inputs.WhatWorkingNowM25, Inputs.WhatWorkingNowM26],
          yAxisID: 'y',
        },
      ],
    };

  return (
    <div className="content-body">
    <UserDropdown />
    
        {/* row */}
       <div className="container-fluid">
            <div className="head-title">
                <h2>What's Working Now</h2>	            					
            </div> 
            <Spinner />
            <div className="card">
                <div className="card-body pl-0">
                <div className="row">
                    <div className="col-md-6">
                    <br /><br /><br /><br /><br />
                    <Chart options={options} data={data}/>
                    </div>
                    <div className="col-md-6">
                    <div className="table-responsive">
                        <table className="table table-responsive">
                        <thead>
                            <tr>
                            <th className="bgradius" colSpan={3}>Cumulative Percentage Cutoff:</th>
                            <th className="bgradius">{Inputs.WhatWorkingNowN10}%</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="thead-primary">
                            <th>#</th>
                            <th>Tactics / Activities</th>
                            <th>Quarterly Sales</th>
                            <th>Cumulative %</th>
                            </tr>
                            <tr>
                            <td>1</td>
                            <td>Customer Communication Program</td>
                            <td>
                                <div className="inputbages">
                                <span className="dollors">$</span>
                                <input
                                type="text"
                                id="WhatWorkingNowM12"
                                disabled={isLoading}
                                className={"form-control"}
                                value={Inputs.WhatWorkingNowM12.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                onChange={(event) => setInputs({ ...Inputs, WhatWorkingNowM12: event.target.value.replace(/,/g, '') })}
                                onBlur={(event) => { onBlurHandler(event) }}
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                                </div>
                            </td>
                            <td>{Inputs.WhatWorkingNowN12}%</td>
                            </tr>
                            <tr>
                            <td>2</td>
                            <td>Targeted Accounts Program</td>
                            <td>
                                <div className="inputbages">
                                <span className="dollors">$</span>
                                <input
                                type="text"
                                id="WhatWorkingNowM13"
                                disabled={isLoading}
                                className={"form-control"}
                                value={Inputs.WhatWorkingNowM13.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                onChange={(event) => setInputs({ ...Inputs, WhatWorkingNowM13: event.target.value.replace(/,/g, '') })}
                                onBlur={(event) => { onBlurHandler(event) }}
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                                </div>
                            </td>
                            <td>{Inputs.WhatWorkingNowN13}%</td>
                            </tr>
                            <tr>
                            <td>3</td>
                            <td>Reactivation Program (offer 6)</td>
                            <td>
                                <div className="inputbages">
                                <span className="dollors">$</span>
                                <input
                                type="text"
                                id="WhatWorkingNowM14"
                                disabled={isLoading}
                                className={"form-control"}
                                value={Inputs.WhatWorkingNowM14.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                onChange={(event) => setInputs({ ...Inputs, WhatWorkingNowM14: event.target.value.replace(/,/g, '') })}
                                onBlur={(event) => { onBlurHandler(event) }}
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                                </div>
                            </td>
                            <td>{Inputs.WhatWorkingNowN14}%</td>
                            </tr>
                            <tr>
                            <td>4</td>
                            <td>PoP upsell &amp; cross-sell (inside sales)</td>
                            <td>
                                <div className="inputbages">
                                <span className="dollors">$</span>
                                <input
                                type="text"
                                id="WhatWorkingNowM15"
                                disabled={isLoading}
                                className={"form-control"}
                                value={Inputs.WhatWorkingNowM15.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                onChange={(event) => setInputs({ ...Inputs, WhatWorkingNowM15: event.target.value.replace(/,/g, '') })}
                                onBlur={(event) => { onBlurHandler(event) }}
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                                </div>
                            </td>
                            <td>{Inputs.WhatWorkingNowN15}%</td>
                            </tr>
                            <tr>
                            <td>5</td>
                            <td>Referral Program (offer 3)</td>
                            <td>
                                <div className="inputbages">
                                <span className="dollors">$</span>
                                <input
                                type="text"
                                id="WhatWorkingNowM16"
                                disabled={isLoading}
                                className={"form-control"}
                                value={Inputs.WhatWorkingNowM16.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                onChange={(event) => setInputs({ ...Inputs, WhatWorkingNowM16: event.target.value.replace(/,/g, '') })}
                                onBlur={(event) => { onBlurHandler(event) }}
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                                </div>
                            </td>
                            <td>{Inputs.WhatWorkingNowN16}%</td>
                            </tr>
                            <tr>
                            <td>6</td>
                            <td>PoP "did you know…"</td>
                            <td>
                                <div className="inputbages">
                                <span className="dollors">$</span>
                                <input
                                type="text"
                                id="WhatWorkingNowM17"
                                disabled={isLoading}
                                className={"form-control"}
                                value={Inputs.WhatWorkingNowM17.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                onChange={(event) => setInputs({ ...Inputs, WhatWorkingNowM17: event.target.value.replace(/,/g, '') })}
                                onBlur={(event) => { onBlurHandler(event) }}
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                                </div>
                            </td>
                            <td>{Inputs.WhatWorkingNowN17}%</td>
                            </tr>
                            <tr>
                            <td>7</td>
                            <td>Insight Story Webinar</td>
                            <td>
                                <div className="inputbages">
                                <span className="dollors">$</span>
                                <input
                                type="text"
                                id="WhatWorkingNowM18"
                                disabled={isLoading}
                                className={"form-control"}
                                value={Inputs.WhatWorkingNowM18.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                onChange={(event) => setInputs({ ...Inputs, WhatWorkingNowM18: event.target.value.replace(/,/g, '') })}
                                onBlur={(event) => { onBlurHandler(event) }}
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                                </div>
                            </td>
                            <td>{Inputs.WhatWorkingNowN18}%</td>
                            </tr>
                            <tr>
                            <td>8</td>
                            <td>Organic Search</td>
                            <td>
                                <div className="inputbages">
                                <span className="dollors">$</span>
                                <input
                                type="text"
                                id="WhatWorkingNowM19"
                                disabled={isLoading}
                                className={"form-control"}
                                value={Inputs.WhatWorkingNowM19.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                onChange={(event) => setInputs({ ...Inputs, WhatWorkingNowM19: event.target.value.replace(/,/g, '') })}
                                onBlur={(event) => { onBlurHandler(event) }}
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                                </div>
                            </td>
                            <td>{Inputs.WhatWorkingNowN19}%</td>
                            </tr>
                            <tr>
                            <td>9</td>
                            <td>Package Inserts</td>
                            <td>
                                <div className="inputbages">
                                <span className="dollors">$</span>
                                <input
                                type="text"
                                id="WhatWorkingNowM20"
                                disabled={isLoading}
                                className={"form-control"}
                                value={Inputs.WhatWorkingNowM20.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                onChange={(event) => setInputs({ ...Inputs, WhatWorkingNowM20: event.target.value.replace(/,/g, '') })}
                                onBlur={(event) => { onBlurHandler(event) }}
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                                </div>
                            </td>
                            <td>{Inputs.WhatWorkingNowN20}%</td>
                            </tr>
                            <tr>
                            <td>10</td>
                            <td>Google Adwords (10 keywords)</td>
                            <td>
                                <div className="inputbages">
                                <span className="dollors">$</span>
                                <input
                                type="text"
                                id="WhatWorkingNowM21"
                                disabled={isLoading}
                                className={"form-control"}
                                value={Inputs.WhatWorkingNowM21.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                onChange={(event) => setInputs({ ...Inputs, WhatWorkingNowM21: event.target.value.replace(/,/g, '') })}
                                onBlur={(event) => { onBlurHandler(event) }}
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                                </div>
                            </td>
                            <td>{Inputs.WhatWorkingNowN21}%</td>
                            </tr>
                            <tr>
                            <td>11</td>
                            <td>LinkedIn prospecting</td>
                            <td>
                                <div className="inputbages">
                                <span className="dollors">$</span>
                                <input
                                type="text"
                                id="WhatWorkingNowM22"
                                disabled={isLoading}
                                className={"form-control"}
                                value={Inputs.WhatWorkingNowM22.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                onChange={(event) => setInputs({ ...Inputs, WhatWorkingNowM22: event.target.value.replace(/,/g, '') })}
                                onBlur={(event) => { onBlurHandler(event) }}
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                                </div>
                            </td>
                            <td>{Inputs.WhatWorkingNowN22}%</td>
                            </tr>
                            <tr>
                            <td>12</td>
                            <td>Sales Prospecting</td>
                            <td>
                                <div className="inputbages">
                                <span className="dollors">$</span>
                                <input
                                type="text"
                                id="WhatWorkingNowM23"
                                disabled={isLoading}
                                className={"form-control"}
                                value={Inputs.WhatWorkingNowM23.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                onChange={(event) => setInputs({ ...Inputs, WhatWorkingNowM23: event.target.value.replace(/,/g, '') })}
                                onBlur={(event) => { onBlurHandler(event) }}
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                                </div>
                            </td>
                            <td>{Inputs.WhatWorkingNowN23}%</td>
                            </tr>
                            <tr>
                            <td>13</td>
                            <td>Trade Shows</td>
                            <td>
                                <div className="inputbages">
                                <span className="dollors">$</span>
                                <input
                                type="text"
                                id="WhatWorkingNowM24"
                                disabled={isLoading}
                                className={"form-control"}
                                value={Inputs.WhatWorkingNowM24.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                onChange={(event) => setInputs({ ...Inputs, WhatWorkingNowM24: event.target.value.replace(/,/g, '') })}
                                onBlur={(event) => { onBlurHandler(event) }}
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                                </div>
                            </td>
                            <td>{Inputs.WhatWorkingNowN24}%</td>
                            </tr>
                            <tr>
                            <td>14</td>
                            <td />
                            <td />
                            <td>{Inputs.WhatWorkingNowN25}%</td>
                            </tr>
                            <tr>
                            <td>15</td>
                            <td />
                            <td />
                            <td>{Inputs.WhatWorkingNowN26}%</td>
                            </tr>
                            <tr className="thead-primary">
                            <th>13</th>
                            <th />
                            <th>${Number(Inputs.WhatWorkingNowM27).toLocaleString('en-US')}</th>
                            <th />
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>
                </div>
            </div>   
            </div>

    </div>
  )
}