import axios from "axios";
import authHeader from "./auth-header";

const updateManageBranding = (userId, data) => {
    return axios({
        method: "post",
        url:`settings/${userId}`,
        data,
        headers: authHeader()
    })
};

const manageBrandingService = {
    updateManageBranding
};
export default manageBrandingService;