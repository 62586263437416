import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart } from "react-google-charts";

export const LabelingBarsGraphNew = ({dataInput}) => {
    const data = dataInput.GraphData;
    const Title = dataInput.Title ? dataInput.Title : "";
    const backgroundColor = dataInput.backgroundColor ? dataInput.backgroundColor : '';
    const legendTextStyle = dataInput.legendTextStyle ? dataInput.legendTextStyle : '';
    const titleTextStyle = dataInput.titleTextStyle ? dataInput.titleTextStyle : '';
    const textStyle = dataInput.textStyle ? dataInput.textStyle : '';

    // const options = {
    //     title: Title,
    //     width: "100%",
    //     height: 400,
    //     bar: { groupWidth: "80%" },
    //     legend: { position: "none" },
    //     backgroundColor,
    //     hAxis: {
    //       format: '$#,###,###.##',
    //       textStyle
    //     },
    //     vAxis: {
    //       format: '$#,###,###.##',
    //       textStyle
    //     },
    //     legendTextStyle,
    //     titleTextStyle,
    //   };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      title: Title,
      indexAxis: 'y',
      tooltips: {
        enabled: false
      },
      scales: {
        x: {
            ticks: {
                stepSize: 200,
                tickColor: '#88a2c3',
                color: '#88a2c3'
            },
            grid: {
                color: '#334966'
            }
        },
        y: {
            ticks: {
              tickColor: '#88a2c3',
              color: '#88a2c3',
            },
            grid: {
              display: false
            }
        }
      },
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: dataInput.Title,
          color: 'white',
          font: {
            size: 20,
            weight: 400
          }
        }
      }
    };
    const plugins = {
      beforeDraw(chart, args, options) {
        console.log("beforeDraw",chart, args, options)
        const {ctx, scales: {x, y}} = chart;
        // ctx.save();
        ctx.textAlign = 'center';
        ctx.fillStyle = "rgba(0, 0, 0, 1)";
        ctx.textBaseline = 'bottom';

        // Loop through each data in the datasets

        data.datasets.forEach(function (dataset, i) {
          var meta = chart.controller.getDatasetMeta(i);
          meta.data.forEach(function (bar, index) {
            var data = dataset.data[index];
            ctx.fillText(data, bar._model.x, bar._model.y - 5);
          });
        });
        // ctx.restore();
      },
    }


  return (
    // <Chart
    //   chartType="BarChart"
    //   width="100%"
    //   height="400px"
    //   data={data}
    //   options={options}
    // />
    <Bar options={options} data={data} />
  )
}
