import React from 'react'
import { Link } from "react-router-dom";
import  UserDropdown  from '../UserDropdown';

export default function RevanueGrowthDriversContent() {
  return (
      <div className="content-body">
    <UserDropdown />
    
          {/* row */}
         <div className="container-fluid">
            <div className="head-title">
                <h2>Revenue Growth Drivers</h2>	           					
            </div>
            <div className="card">
                <div className="card-body">
                <div className="row">
                    <div className="col-md-9 contentleft">
                    <p>There are Nine Organic Revenue Growth Drivers.  The 120 Growth Catalyst can help you activate, align, and improve the performance of all nine, generating controlled and repeatable growth.</p>
                    </div>
                    <div className="col-md-3">
                        <img src={window.location.origin+"/images/revenue-growth.png"} alt="Revenue Growth Drivers" title="Revenue Growth Drivers" />
                    </div>
                </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 contentleft table-responsive">
                <div className="card">
                    <div className="card-body">
                    <h3>Customer Acquisition</h3>
                    <ul>
                        <li><Link to="/prescribe/lead-generation">1.  Lead Generation</Link></li>
                        <li><Link to="/prescribe/conversion">2.  Conversion</Link></li>
                        <li><Link to="/prescribe/referrals">3.  Referrals</Link></li>
                    </ul>
                    </div>   
                </div>
                </div>
                <div className="col-md-4 contentleft table-responsive">
                <div className="card">
                    <div className="card-body">
                    <h3>Customer Retention</h3>
                    <ul>
                        <li><Link to="/prescribe/purchase-frequency">4.  Purchase Frequency</Link></li>
                        <li><Link to="/prescribe/buying-lifetime">5.  Buying Lifetime</Link></li>
                        <li><Link to="/prescribe/customer-attrition">6.  Customer Attrition</Link></li>
                        <li><Link to="/prescribe/customer-reactivation">7.  Reactivation</Link></li>
                    </ul>
                    </div>   
                </div>
                </div>
                <div className="col-md-4 contentleft table-responsive">
                <div className="card">
                    <div className="card-body">
                    <h3>Customer Transactions</h3>
                    <ul>
                        <li><Link to="/prescribe/average-transaction-value">8.  Average Transaction Value</Link></li>
                        <li><Link to="/prescribe/profit-margin">9.  Profit Margin</Link></li>
                    </ul>
                    </div>   
                </div>
                </div>
            </div>
        </div>
    </div>

  )
}
