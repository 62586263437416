import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../features/Message";
import positioningMatrixService from "../../services/positioningMatrix.service";

//Get data based on user id
export const getPositioningMatrix = createAsyncThunk(
  "strategicFoundation/getPositioningMatrix",
  async ({user_id}, thunkAPI) => {
    try {
      const response = await positioningMatrixService.getData(user_id);
      //console.log(response)
      thunkAPI.dispatch(setMessage(""));
      return response.data
    } catch (error) {
      console.log(error)
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

//add or edit PositioningMatrix
export const modifyPositioningMatrix = createAsyncThunk(
  "strategicFoundation/modifyPositioningMatrix",
  async (postClientData , thunkAPI) => {
    try {
      const data = await positioningMatrixService.postData(postClientData);
      thunkAPI.dispatch(setMessage(""));
      console.log(data)
      return { growthPlanning: data };
    } catch (error) {
      console.log('errorcode',error.code)
      if(error.code == 'ERR_BAD_REQUEST'){
        return {status : 401}
      }
      else{
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const PositioningMatrixSlice = createSlice({
    name: 'growthPlanning',
    reducers: {
      
    },
    extraReducers: {
      [getPositioningMatrix.pending]: (state) => {
        state.loading = true
      },
      [getPositioningMatrix.fulfilled]: (state, { payload }) => {
        //console.log("---------get Data -----------", payload.data)
        state.loading = false
      },
      [getPositioningMatrix.rejected]: (state) => {
        state.loading = false
      },
    },
  })

const { reducer } = PositioningMatrixSlice;
export default reducer;