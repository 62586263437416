import React from 'react';
import { NavLink  } from "react-router-dom";
import {useSWRConfig} from "swr";

export default function Sidebar() {
    const { cache, mutate, fallback } = useSWRConfig()
    return (
        <div className="dlabnav">
            <div className="dlabnav-scroll">
                <ul className="metismenu" id="menu">
                    <li>
                        <NavLink to="/contents" className="has-arrow" aria-expanded="false">
                            <img src={window.location.origin+"/images/svg/content.png"} alt="img" />
                            <span className="nav-text"> Contents</span>
                        </NavLink >
                    </li>
                    <li><NavLink to="/state-of-business" className="has-arrow" aria-expanded="false">
                        <img src={window.location.origin+"/images/svg/2.png"} alt="img"/>
                        <span className="nav-text"> State of the Business</span>
                    </NavLink >
                    </li>
                    <li><NavLink to="/revenue-growth-drivers" className="has-arrow" aria-expanded="false">
                        <img src={window.location.origin+"/images/svg/3.png"} alt="img"/>
                        <span className="nav-text"> Revenue Growth Drivers </span>
                    </NavLink >
                    </li>
                    <li><NavLink className="has-arrow" to="/120-growth-benchmark" aria-expanded="false">
                        <img src={window.location.origin+"/images/svg/4.png"} alt="img"/>
                        <span className="nav-text"> 120 Growth Benchmark</span>
                    </NavLink >
                    </li>
                    <li><NavLink to="/growth-planning" className="has-arrow" aria-expanded="false">
                        <img src={window.location.origin+"/images/svg/5.png"} alt="img"/>
                        <span className="nav-text"> Growth Planning </span>
                    </NavLink >
                    </li>
                    <li><NavLink to="/strategic-foundation" className="has-arrow" aria-expanded="false">
                        <img src={window.location.origin+"/images/svg/3.png"} alt="img"/>
                        <span className="nav-text"> Strategic Foundation </span>
                    </NavLink >
                    </li>
                    <li><NavLink to="/revenue-growth-tools" className="has-arrow" aria-expanded="false">
                        <img src={window.location.origin+"/images/svg/4.png"} alt="img"/>
                        <span className="nav-text"> Revenue Growth</span>
                    </NavLink >
                    </li>
                    {/* <li><NavLink to="/" className="has-arrow" aria-expanded="false">
                        <img src={window.location.origin+"/images/svg/8.png"} alt="img"/>
                        <span className="nav-text"> Ability &amp; Agility </span>
                    </NavLink >
                    </li>
                    <li><NavLink to="/" className="has-arrow" aria-expanded="false"> 
                    	<img src={window.location.origin+"/images/svg/6.png"} alt="img"/> Problem Solving Tools </NavLink >
                    </li>
                    <li><NavLink to="/" className="has-arrow" aria-expanded="false">
                        <img src={window.location.origin+"/images/svg/7.png"} alt="img"/> Miscallaneous Tools </NavLink >
                    </li> */}
                </ul>
                {/* <div className="cLinkpyright">
                <p><strong>Invome Admin Dashboard</strong> © 2021 All Rights Reserved</p>
                <p className="fs-12">Made with <span className="heart"></span> by DexignLabs</p>
            </div> */}
            </div>
        </div>
    )
}
