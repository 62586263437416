import React from 'react'
import { Link } from 'react-router-dom'
import  UserDropdown  from '../UserDropdown';

export default function RevenueGrowthToolsContent() {
  return (
        <div className="content-body">
            <UserDropdown />
            {/* row */}
            <div className="container-fluid">
                <div className="head-title">
                    <h2>Revenue Growth Tools </h2> 
                </div>
                <div className="card">
                    <div className="card-body">
                    <div className="row">
                        <div className="col-md-9 contentleft">
                            <p>Growth Planning is not something that can be done just once per year.  The 120 Growth Catalyst makes growth planning easy, fast, and thorough.  The result:  an agile growth strategy that keeps you consistently advancing toward your goals. </p>
                        </div>
                        <div className="col-md-3">
                            <img src={window.location.origin+"/images/revenue-growth-tools.png"} alt="Revenue Growth tools" title="Revenue Growth tools" />
                        </div>
                    </div>
                    </div>
                </div>
                <div className="row strategicpage">
                    <div className="col-md-4 contentleft">
                    <div className="card add-new-card">
                        <div className="card-body">
                        <h4>Revenue Growth Tools </h4>
                        <ul>
                            <li><Link to="/revenue-growth-tools/growth-option-scorecard">Growth Option Scorecard </Link></li>
                            <li>90-Day Jumpstart </li>
                            <li><Link to="/revenue-growth-tools/whats-working-now">What's Working Now </Link></li>
                            <li><Link to="#">Marketing Plan</Link></li>
                            <li><Link to="#">Sales Elevation Plan</Link></li>
                            <li><Link to="#">Customer Communication Plan</Link></li>
                        </ul>
                        </div>   
                    </div>
                    </div>
                    <div className="col-md-4 contentleft">
                    <div className="card add-new-card">
                        <div className="card-body">
                        <h4>Opportunity Analysis</h4>
                        <ul>
                            <li><Link to="/revenue-growth-tools/find-the-Low-Hanging-Fruit">Find the Low Hanging Fruit</Link></li>
                            <li><Link to="/prescribe/opportunity-ranking-analysis">Opportunity Ranking Analysis</Link></li>
                            <li><Link to="#">Project Prioritization Matrix</Link></li>
                            <li><Link to="/revenue-growth-tools/synergy-scoring-worksheet">Synergy Scoring Framework </Link></li>
                            <li><Link to="/revenue-growth-tools/alliance-evaluation-worksheet">Alliance Opportunity Evaluation</Link></li>
                        </ul>
                        </div>   
                    </div>
                    </div>
                    <div className="col-md-4 contentleft">
                    <div className="card add-new-card">
                        <div className="card-body">
                        <h4>Sales &amp; Sales Management</h4>
                        <ul>
                            <li>Sales Elevation</li>
                            <li><Link to="#">Sales Elevation Planning</Link></li>
                            <li><Link to="/revenue-growth-tools/sales-call-objectives">Sales Call Objectives</Link></li>
                            <li><Link to="/revenue-growth-tools/account-penetration-matrix">Account Penetration Matrix</Link></li>
                            <li><Link to="/revenue-growth-tools/objection-resolution-worksheet">Objection Resolution Worksheet</Link></li>
                            <li><Link to="/revenue-growth-tools/target-account-list-prioritization">Target Account Prioritization</Link></li>
                            <li><Link to="#">Target Account Planning</Link></li>
                            <li><Link to="/revenue-growth-tools/value-first-strategy-development">Value First Strategy</Link></li>
                            <li><Link to="/revenue-growth-tools/customer-transformation-model">Customer Transformation Worksheet </Link></li>
                            <li><Link to="/revenue-growth-tools/referral-strategy-worksheet">Referral Strategy</Link></li>
                        </ul>
                        </div>   
                    </div>
                    </div>
                    <div className="col-md-4 contentleft">
                    <div className="card add-new-card">
                        <div className="card-body">
                        <h4>Innovation</h4>
                        <ul>
                            <li><Link to="/revenue-growth-tools/innovate-or-optimize">Innovate or Optimize</Link></li>
                            <li><Link to="/revenue-growth-tools/innovation-concept-development">Innovation Concept Development</Link></li>
                            <li><Link to="/revenue-growth-tools/SCAMPER-Ideation">SCAMPER Ideation</Link></li>
                            <li><Link to="/revenue-growth-tools/PAINstorming">PAINstorming</Link></li>
                            <li><Link to="/revenue-growth-tools/outcome-driven-innovation">Outcome Driven Innovation</Link></li>
                            <li><Link to="/revenue-growth-tools/six-thinking-hats">Six Thinking Hats</Link></li>
                            <li><Link to="/revenue-growth-tools/agile-innovation">Agile Innovation</Link></li>
                            <li><Link to="/revenue-growth-tools/innovation-ranking-matrix">Innovation Ranking Analysis</Link></li>
                            <li><Link to="/revenue-growth-tools/jobs-to-be-done">Jobs To Be Done</Link></li>
                            <li><Link to="/revenue-growth-tools/insights-to-action-to-impact-worksheet">Insights to Action to Impact</Link></li>
                            <li><Link to="/revenue-growth-tools/disruptive-innovation">Disruptive Innovation</Link></li>
                            <li><Link to="/revenue-growth-tools/7-step-case-for-change">7-Step Case for Change</Link></li>
                            <li><Link to="/revenue-growth-tools/optimization-accelerator">Optimization Accelerator</Link></li>
                        </ul>
                        </div>   
                    </div>
                    </div>
                    <div className="col-md-4 contentleft">
                    <div className="card add-new-card">
                        <div className="card-body">
                        <h4>Customer Retention, Loyalty &amp; Experience </h4>
                        <ul>
                            <li><Link to="/revenue-growth-tools/customer-retention-loop">Customer Retention Loop </Link></li>
                            <li><Link to="/revenue-growth-tools/net-promoter-score">Net Promoter Score</Link></li>
                            <li><Link to="/revenue-growth-tools/customer-journey-mapping">Customer Journey Mapping</Link></li>
                            <li><Link to="/revenue-growth-tools/voice-of-the-customer-table">Voice of the Customer</Link></li>
                        </ul>
                        </div>   
                    </div>
                    </div>
                </div>
            </div>

        </div>

  )
}
