import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getNetPromoterScore, modifyNetPromoterScore } from "../../state/features/NetPromoterScoreSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';

export default function NetPromoterScoreContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        NetPromoterScoreD19 : 0,
        NetPromoterScoreE19 : 0,
        NetPromoterScoreD20 : 0,
        NetPromoterScoreE20 : 0,
        NetPromoterScoreD21 : 0,
        NetPromoterScoreE21 : 0,
        NetPromoterScoreD22 : 0,
        NetPromoterScoreE22 : 0,
        NetPromoterScoreG19 : 0,
        NetPromoterScoreI19 : '',
        NetPromoterScoreJ19 : '',
        NetPromoterScoreK19 : '',
        NetPromoterScoreI21 : '',
        NetPromoterScoreJ21 : '',
        NetPromoterScoreK21 : '',
        NetPromoterScoreI23 : '',
        NetPromoterScoreJ23 : '',
        NetPromoterScoreK23 : '',
        NetPromoterScoreI25 : '',
        NetPromoterScoreJ25 : '',
        NetPromoterScoreK25 : '',
        NetPromoterScoreI27 : '',
        NetPromoterScoreJ27 : '',
        NetPromoterScoreK27 : ''
    });
    
    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getNetPromoterScore({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.revenueGrowth)
            if(response!= undefined  && response.data.revenueGrowth){
                let revenueGrowth = response.data.revenueGrowth[0];
                console.log(revenueGrowth)
                delete revenueGrowth.id;
                delete revenueGrowth.user_id;
                delete revenueGrowth.created_at;
                delete revenueGrowth.updated_at;
                const newObj = { ...Inputs }
                for (const key in revenueGrowth) {
                    if (revenueGrowth.hasOwnProperty(key)) {
                        if(revenueGrowth[key])
                        newObj[key] = revenueGrowth[key];
                    }
                }
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = async (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                let NetPromoterScoreD19 = (event.target.id == 'NetPromoterScoreD19' && event.target.value) ? parseFloat(event.target.value) : parseFloat(Inputs.NetPromoterScoreD19);
                let NetPromoterScoreD20 = (event.target.id == 'NetPromoterScoreD20' && event.target.value) ? parseFloat(event.target.value) : parseFloat(Inputs.NetPromoterScoreD20);
                let NetPromoterScoreD21 = (event.target.id == 'NetPromoterScoreD21' && event.target.value) ? parseFloat(event.target.value) : parseFloat(Inputs.NetPromoterScoreD21);

                let NetPromoterScoreD22 = Math.trunc((NetPromoterScoreD19 ? parseFloat(NetPromoterScoreD19) : 0) + (NetPromoterScoreD20 ? parseFloat(NetPromoterScoreD20) : 0) + (NetPromoterScoreD21 ? parseFloat(NetPromoterScoreD21) : 0));
                let NetPromoterScoreE19 = Math.trunc((NetPromoterScoreD19 ? parseFloat(NetPromoterScoreD19) : 0)/parseFloat(NetPromoterScoreD22)*100);
                let NetPromoterScoreE20 = Math.trunc(NetPromoterScoreD20 ? (parseFloat(NetPromoterScoreD20)/parseFloat(NetPromoterScoreD22))*100 : 0);
                let NetPromoterScoreE21 = Math.trunc(NetPromoterScoreD21 ? (parseFloat(NetPromoterScoreD21)/parseFloat(NetPromoterScoreD22))*100 : 0);
                let NetPromoterScoreE22 = Math.trunc(parseFloat(NetPromoterScoreE19) + parseFloat(NetPromoterScoreE20) + parseFloat(NetPromoterScoreE21));
                let NetPromoterScoreG19 = (((NetPromoterScoreD19 ? parseFloat(NetPromoterScoreD19) : 0)-parseFloat(NetPromoterScoreD21))/NetPromoterScoreD22*100).toFixed(2);

                setInputs({ ...Inputs, NetPromoterScoreD22, NetPromoterScoreE19, NetPromoterScoreE20, NetPromoterScoreE21, NetPromoterScoreE22, NetPromoterScoreG19})
                dispatch(modifyNetPromoterScore({ user_id, [event.target.id] : event.target.value, NetPromoterScoreD22 : NetPromoterScoreD22.toString(), NetPromoterScoreE19 : NetPromoterScoreE19.toString(), NetPromoterScoreE20 : NetPromoterScoreE20.toString(), NetPromoterScoreE21 : NetPromoterScoreE21.toString(), NetPromoterScoreE22 : NetPromoterScoreE22.toString(), NetPromoterScoreG19 : NetPromoterScoreG19.toString()}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value, NetPromoterScoreD22, NetPromoterScoreE19, NetPromoterScoreE20, NetPromoterScoreE21, NetPromoterScoreE22, NetPromoterScoreG19});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

  return (
    <div className="content-body">
        <UserDropdown />
        {/* row */}
        <div className="container-fluid">
            <div className="head-title">
                <p className="mb-0"> Customer Retention, Loyalty &amp; Experience</p>
                <h2 className="mb-3"> Net Promoter Score </h2>
                <ul className="list-unstyled">
                <li className="net-page-lit mb-2">
                    <b>Net Promoter Score</b> is based on a single survey question:  "On a scale of 0 to 10, how likely are you to recommend this company's products or services to a friend or colleague?"  
                </li>
                <li className="net-page-lit">
                    Rank 9-10 are Promoters:  considered loyal customers, repeat buyers, and brand advocates.
                </li>
                <li className="net-page-lit">
                    Rank 7-8 are Passives:  satisfied with their purchase, but not committed to the brand; susceptible to purchasing from competitors.  
                </li>
                <li className="net-page-lit mb-2">
                    Rank 0-6 are Detractors:  dissatisfied with their experience and could potentially damage your brand's reputation by sharing negative feedback.  
                </li>
                <li className="net-page-lit mb-4">
                    Subtracting the percentage of Detractors from the percentage of Promoters produces a score between -100 and 100, which is the Net Promoter Score.  By asking customers to explain their rationale for their ratings in a follow-up, open-ended question allows companies to get direct feedback and identify areas for improvement.  
                </li>
                </ul>
            </div>
            <div className="row net-promotor">
                <div className="col-12 col-md-12 col-lg-12 col-xl-6">
                <div className="number-line-main">
                    <div className="title-numer mb-2">Detractors </div>
                    <div className="border-overlay" />
                    <div className="total-number-div">
                    <div className="numberdiv">
                        <div className="inner-number">1</div>
                    </div>
                    <div className="numberdiv">
                        <div className="inner-number">2</div>
                    </div>
                    <div className="numberdiv">
                        <div className="inner-number">3</div>
                    </div>
                    <div className="numberdiv">
                        <div className="inner-number">4</div>
                    </div>
                    <div className="numberdiv">
                        <div className="inner-number">5</div>
                    </div>
                    <div className="numberdiv">
                        <div className="inner-number">6</div>
                    </div>
                    </div>
                </div>
                </div>
                <div className="col-12 col-md-12 col-lg-12 col-xl-3">
                <div className="number-line-main">
                    <div className="title-numer mb-2">Detractors </div>
                    <div className="border-overlay" />
                    <div className="total-number-div">
                    <div className="numberdiv number78">
                        <div className="inner-number">7</div>
                    </div>
                    <div className="numberdiv number78">
                        <div className="inner-number">8</div>
                    </div>
                    </div>
                </div>
                </div>
                <div className="col-12 col-md-12 col-lg-12 col-xl-3">
                <div className="number-line-main">
                    <div className="title-numer mb-2">Detractors </div>
                    <div className="border-overlay" />
                    <div className="total-number-div">
                    <div className="numberdiv number910">
                        <div className="inner-number">9</div>
                    </div>
                    <div className="numberdiv number910">
                        <div className="inner-number">10</div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12 contentleft">
                <div className="card">
                    <div className="card-body pl-0">
                    {/* table 1 */}
                    <div className="table-responsive table-dta">
                        <table className="table mb-0 customer-table">
                        <thead>
                            <tr>
                            <th colSpan={1} className="bg-color5 text-white d-hidden">Number</th>
                            <th colSpan={1} className="bg-color1 text-white text-center-c">Number</th>
                            <th colSpan={1} className="bg-color1 text-white text-center-c">Percent</th>
                            <th colSpan={1} className="bg-color3 text-white d-hidden" />
                            <th colSpan={1} className="bg-color1 text-white text-center-c">Net Promoter <br /> Score</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <th className="bg-color2 text-font-size-15">Promoters</th>
                            <td className="bg-color2 text-font-size-15 text-center-c">
                                <input type="text" className="inputText" 
                                    id="NetPromoterScoreD19" 
                                    value={Inputs.NetPromoterScoreD19} 
                                    onChange={(event) => setInputs({ ...Inputs, NetPromoterScoreD19: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="6" 
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }} />
                            </td>
                            <td className="bg-color2 text-font-size-15 text-center-c">{Inputs.NetPromoterScoreE19}%</td>
                            <td className="bg-color2 text-font-size-15 d-hidden" />
                            <td className="text-font-size-15 text-center-c color-n text-white" rowSpan={2}>{Inputs.NetPromoterScoreG19}</td>
                            </tr>
                            <tr>
                            <th className="bg-color2 text-font-size-15">Passives</th>
                            <td className="bg-color2 text-font-size-15 text-center-c">
                                <input type="text" className="inputText" 
                                    id="NetPromoterScoreD20" 
                                    value={Inputs.NetPromoterScoreD20} 
                                    onChange={(event) => setInputs({ ...Inputs, NetPromoterScoreD20: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="6" 
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }} />
                            </td>
                            <td className="bg-color2 text-font-size-15 text-center-c">{Inputs.NetPromoterScoreE20}%</td>
                            <td className="bg-color2 text-font-size-15 d-hidden" />
                            </tr>
                            <tr>
                            <th className="bg-color2 text-font-size-15">Detractors</th>
                            <td className="bg-color2 text-font-size-15 text-center-c">
                                <input type="text" className="inputText" 
                                    id="NetPromoterScoreD21" 
                                    value={Inputs.NetPromoterScoreD21} 
                                    onChange={(event) => setInputs({ ...Inputs, NetPromoterScoreD21: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="6" 
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }} />
                            </td>
                            <td className="bg-color2 text-font-size-15 text-center-c">{Inputs.NetPromoterScoreE21}%</td>
                            <td className="bg-color2 text-font-size-15 d-hidden" />
                            </tr>
                            <tr>
                            <th className="bg-color2 text-font-size-15">Total Response</th>
                            <td className="bg-color2 text-font-size-15 text-center-c">{Inputs.NetPromoterScoreD22}</td>
                            <td className="bg-color2 text-font-size-15 text-center-c">{Inputs.NetPromoterScoreE22}%</td>
                            <td className="bg-color2 text-font-size-15 d-hidden" />
                            <td className="text-font-size-15 text-center-c color-n d-hidden text-white" rowSpan={2}></td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    {/* table 1 */}
                    <div className="table-responsive table-dta">
                        <table className="table mb-0 mt-3 customer-table">
                        <thead>
                            <tr>
                            <th colSpan={1} className="bg-color1 text-white text-center-c">Top Detractor Reasons</th>
                            <th colSpan={1} className="bg-color1 text-white text-center-c">Improvement Strategy</th>
                            <th colSpan={1} className="bg-color1 text-white text-center-c">Expected NPS <br /> Change </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td className="bg-color2 text-font-size-15">
                                <input type="text" className="inputText"
                                id="NetPromoterScoreI19"
                                value={Inputs.NetPromoterScoreI19}
                                onChange={(event) => setInputs({ ...Inputs, NetPromoterScoreI19 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Enter reason here" /></td>
                            <td className="bg-color2 text-font-size-15">
                                <input type="text" className="inputText"
                                id="NetPromoterScoreJ19"
                                value={Inputs.NetPromoterScoreJ19}
                                onChange={(event) => setInputs({ ...Inputs, NetPromoterScoreJ19 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Enter reason here" /></td>
                            <td className="bg-color2 text-font-size-15 ms-text-right">
                            <input type="text" className="inputText"
                                id="NetPromoterScoreK19"
                                value={Inputs.NetPromoterScoreK19}
                                onChange={(event) => setInputs({ ...Inputs, NetPromoterScoreK19 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="+/- XX%" /></td>
                            </tr>
                            <tr>
                            <td className="bg-color2 text-font-size-15">
                                <input type="text" className="inputText"
                                id="NetPromoterScoreI21"
                                value={Inputs.NetPromoterScoreI21}
                                onChange={(event) => setInputs({ ...Inputs, NetPromoterScoreI21 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Enter reason here" /></td>
                            <td className="bg-color2 text-font-size-15">
                                <input type="text" className="inputText"
                                id="NetPromoterScoreJ21"
                                value={Inputs.NetPromoterScoreJ21}
                                onChange={(event) => setInputs({ ...Inputs, NetPromoterScoreJ21 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Enter reason here" /></td>
                            <td className="bg-color2 text-font-size-15 ms-text-right">
                            <input type="text" className="inputText"
                                id="NetPromoterScoreK21"
                                value={Inputs.NetPromoterScoreK21}
                                onChange={(event) => setInputs({ ...Inputs, NetPromoterScoreK21 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="+/- XX%" /></td>
                            </tr>
                            <tr>
                            <td className="bg-color2 text-font-size-15">
                                <input type="text" className="inputText"
                                id="NetPromoterScoreI23"
                                value={Inputs.NetPromoterScoreI23}
                                onChange={(event) => setInputs({ ...Inputs, NetPromoterScoreI23 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Enter reason here" /></td>
                            <td className="bg-color2 text-font-size-15">
                                <input type="text" className="inputText"
                                id="NetPromoterScoreJ23"
                                value={Inputs.NetPromoterScoreJ23}
                                onChange={(event) => setInputs({ ...Inputs, NetPromoterScoreJ23 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Enter reason here" /></td>
                            <td className="bg-color2 text-font-size-15 ms-text-right">
                            <input type="text" className="inputText"
                                id="NetPromoterScoreK23"
                                value={Inputs.NetPromoterScoreK23}
                                onChange={(event) => setInputs({ ...Inputs, NetPromoterScoreK23 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="+/- XX%" /></td>
                            </tr>
                            <tr>
                            <td className="bg-color2 text-font-size-15">
                                <input type="text" className="inputText"
                                id="NetPromoterScoreI25"
                                value={Inputs.NetPromoterScoreI25}
                                onChange={(event) => setInputs({ ...Inputs, NetPromoterScoreI25 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Enter reason here" /></td>
                            <td className="bg-color2 text-font-size-15">
                                <input type="text" className="inputText"
                                id="NetPromoterScoreJ25"
                                value={Inputs.NetPromoterScoreJ25}
                                onChange={(event) => setInputs({ ...Inputs, NetPromoterScoreJ25 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Enter reason here" /></td>
                            <td className="bg-color2 text-font-size-15 ms-text-right">
                            <input type="text" className="inputText"
                                id="NetPromoterScoreK25"
                                value={Inputs.NetPromoterScoreK25}
                                onChange={(event) => setInputs({ ...Inputs, NetPromoterScoreK25 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="+/- XX%" /></td>
                            </tr>
                            <tr>
                            <td className="bg-color2 text-font-size-15">
                                <input type="text" className="inputText"
                                id="NetPromoterScoreI27"
                                value={Inputs.NetPromoterScoreI27}
                                onChange={(event) => setInputs({ ...Inputs, NetPromoterScoreI27 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Enter reason here" /></td>
                            <td className="bg-color2 text-font-size-15">
                                <input type="text" className="inputText"
                                id="NetPromoterScoreJ27"
                                value={Inputs.NetPromoterScoreJ27}
                                onChange={(event) => setInputs({ ...Inputs, NetPromoterScoreJ27 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Enter reason here" /></td>
                            <td className="bg-color2 text-font-size-15 ms-text-right">
                            <input type="text" className="inputText"
                                id="NetPromoterScoreK27"
                                value={Inputs.NetPromoterScoreK27}
                                onChange={(event) => setInputs({ ...Inputs, NetPromoterScoreK27 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="+/- XX%" /></td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>                                          
                </div>
            </div>
        </div>
    </div>
  )
}
