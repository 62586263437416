import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getQuestionsWithOptionsList, modifyUserScore } from "../../state/features/The120GrowthBenchmarkSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';

export default function AbilityContent() {

    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const userID = users.data.profile.id;
    const [questionOptionList, setQuestionOptionList] = useState([]);
    const [userSelectedOptionIds, setUserSelectedOptionIds] = useState([]);
    
    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(clearMessage());
        dispatch(getQuestionsWithOptionsList({user_id : userID, slug : "ability"}))
        .unwrap()
        .then((response) => {
          console.log("response",response.data.questionOptionList)
            if(response!= undefined){
                //console.log(response.data);
                setQuestionOptionList(response.data.questionOptionList)
                let optionIds = response.data.userScoreOption && response.data.userScoreOption.optionIds ? response.data.userScoreOption.optionIds.split(',') : []
                console.log(optionIds)
                return optionIds
            }
        })
        .then((optionIds=[]) => {
            console.log(optionIds)
            setUserSelectedOptionIds(optionIds)
        })
        .catch((error) => {
          console.log("error",error)
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onChangeHandler = (event) => {
        let is_checked = (event.target.checked) ? '1' : '0';
        let nameArr = event.target.id.split('_');
        console.log(nameArr)
        let is_multiselect = nameArr[3];
        let question_id = nameArr[1];
        let group = "checkbox_"+question_id;
        if(is_multiselect == 0){
            [...document.querySelectorAll("input[name="+group+"]")].map((input) => {
                if (input.checked && input.id !== event.target.id) {
                    input.checked = false
                }
                return null;
            })
        }
        
        let sendData = {user_id : userID, option_id: event.target.value, is_checked : is_checked, question_id : question_id}
        dispatch(modifyUserScore(sendData))
        .unwrap()
        .then((response) => {
          console.log("response",response)
            if(response!= undefined && response.status && response.status == 401){
                console.log('session expired...')
                window.location.reload()
            }
            else if(response!= undefined){
                console.log('success')
            }
        })
        .catch((error) => {
          console.log("error",error.status)
            { message && alert.show(message) }
        });
    }

  return (
    <div className="content-body">
    <UserDropdown />
    
    {/* row */}
        <div className="container-fluid">
            <div className="head-title">
                <h2>Ability</h2>	            					
            </div>
            <div className="row">
                <Spinner />
                <div className="col-xl-12 contentleft">
                    <div className="card">
                        <div className="card-body pl-0">
                        
                        { (questionOptionList) ? questionOptionList.map((category, catKey) => {
                            return(
                            <>
                                <h4 key={'cat'+catKey} style={{padding: '10px 0 10px 10px'}}>{category.category_name} </h4>
                                { (category.questions) ? category.questions.map((quesObj, quesKey) => {
                                    return(
                                    <>
                                        <div className="questns table-responsive">
                                            <table className="table">
                                                <thead key={'ques'+quesKey}>
                                                    <tr className="thead-primary">
                                                        <th style={{width: 50}}>{quesObj.question_id}</th>
                                                        <th colSpan={2}>{quesObj.bq_question}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                { (quesObj.options) ? quesObj.options.map((optionObj, optKey) => {
                                                        return(
                                                            <>
                                                                <tr key={'opt'+optKey}>
                                                                    <td />
                                                                    <td>{String.fromCharCode(97+optKey)}.</td>
                                                                    <td>
                                                                        <input 
                                                                        type="checkbox" 
                                                                        name={`checkbox_${optionObj.question_id}`} 
                                                                        id={`checkbox_${optionObj.question_id}_${optionObj.option_id}_${optionObj.is_multiselect}`}    
                                                                        defaultChecked={userSelectedOptionIds.includes(optionObj.option_id.toString())}
                                                                        value={optionObj.option_id} 
                                                                        onChange={(event) => {
                                                                            onChangeHandler(event)
                                                                        }}
                                                                        /> 
                                                                        &nbsp;&nbsp;{ optionObj.option }
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )
                                                    })
                                                    : <h4>No data</h4>
                                                }
                                                </tbody>
                                            </table>
                                        </div>  
                                    </>
                                    )
                                    })
                                    : <h4>No data</h4>
                                }
                            </>
                            )
                        }) : <h4>No data</h4>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
