import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state/index';
import { BarChartGraph } from "../graph/BarChartGraph";
import { Link } from "react-router-dom";
import  UserDropdown  from '../UserDropdown';
import { addEditImprovementPercentage } from "../../state/features/ClentInputSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { useAlert } from 'react-alert'
import { Spinner } from '../spinner'
import { StatesUseEffect } from '../../utils/StatesUseEffect';
import { BarChartGraphNew } from '../graph/BarChartGraphNew';

export default function LeadGenerationContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const users = useSelector(userData);
    const userID = users.data.profile.id;
    const { message } = useSelector((state) => state.message);
    
    const dispatch = useDispatch();
    const { leadGenerationData } = bindActionCreators(actionCreators, dispatch)
    
    const clientInputs = useSelector(state => state.clientInputs)
    const leadGeneration = useSelector(state => state.leadGeneration)
    // console.log(leadGeneration)

    const [Inputs, setInputs] = useState({
        leadGenerationD16: Number(leadGeneration.leadGenerationD16).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})
    });

    const { clientInputsH25 } = clientInputs
    let leadGenerationD15 = parseFloat(clientInputsH25) ? parseFloat(clientInputsH25) : 0 

    useEffect(() => {
        // console.log('first');
        leadGenerationData("leadGenerationD15", leadGenerationD15);
        setInputs({ ...Inputs, leadGenerationD16: Number(leadGeneration.leadGenerationD16).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1}) });
    }, []);

    const { leadGenerationD20, leadGenerationE20, leadGenerationF20, leadGenerationG20, leadGenerationH20, leadGenerationI20, leadGenerationD21, leadGenerationE21, leadGenerationF21, leadGenerationG21, leadGenerationH21, leadGenerationI21, leadGenerationD25, leadGenerationE25, leadGenerationF25, leadGenerationG25, leadGenerationH25, leadGenerationI25, leadGenerationD26, leadGenerationE26, leadGenerationF26, leadGenerationG26, leadGenerationH26, leadGenerationI26, leadGenerationD28, leadGenerationE28, leadGenerationF28, leadGenerationG28, leadGenerationH28, leadGenerationI28,leadGenerationD29, leadGenerationE29, leadGenerationF29, leadGenerationG29, leadGenerationH29, leadGenerationI29,leadGenerationD31, leadGenerationE31, leadGenerationF31, leadGenerationG31, leadGenerationH31, leadGenerationI31 } = leadGeneration //object destructuring for leadGeneration

    const improvementPrcntHandler = (impPrcnt) => {
        setIsLoading(true);
        let sendData = {user_id : userID, leadGenerationD16: impPrcnt}
        dispatch(addEditImprovementPercentage(sendData))
        .unwrap()
        .then((response) => {
            // console.log("response",response)
            if(response!= undefined){
               console.log('success')
            }
            setIsLoading(false)   // Hide loading screen 
        })
        .catch((error) => {
            // console.log("error",error)
            { message && alert.show(message) }
            setIsLoading(false)   // Hide loading screen 
        });
    }
    
    // call states useEffect utils
    StatesUseEffect()

  return (
      <div className="content-body content-body-new bg-white-color">
        <UserDropdown />
    
          {/* row */}
          <div className="container-fluid lead-generation-new">
            <div className="head-title mb-5">
                <h3 className='preheading'>Prescribe - Growth Drivers</h3>
                <h1 className='mainheading mb-1'>Lead Generation</h1>	
            </div>
            {/* I created this code date : 01/02/2023 */}
            <div className="row lead-genearation-rw mb-5" style={{maxWidth: 1200}}>
                <div className="col-12 col-lg-6">
                    {/* <div className="potential-growth-ld mb-3" style={{maxWidth: 450}}>
                        <h3><strong>Potential Growth Activities or Tactics:</strong></h3>
                        <ol>
                            <li>Activity 1</li>
                            <li>Activity 2</li>
                            <li>Activity 3</li>
                        </ol>
                    </div> */}
                    <div className="lead-generation-table mt-218" style={{overflowY: 'auto'}}>
                        <div className="responsive-table">
                            <table className='table' style={{width: 450}}>
                                <thead>
                                    <tr>
                                        <th scope='col' className='black-bg text-white fontBold' style={{width: 300}}>Improving Lead Generation</th>
                                        <th className='black-bg hide-content' style={{width: 150}}>2</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td scope='row' className='bg-color-light-blue font12px color-black'>Leads Generated Annually</td>
                                        <td className='bg-color-light-blue font12px color-black text-right-rt'>{Number(clientInputsH25).toLocaleString('en-US')}</td>
                                    </tr>
                                    <tr>
                                        <td scope='row' className='bg-color-light-blue font12px color-black'>Projected Improvement</td>
                                        <th scope='row' className='padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="input-fiel-cn bg-color-01 client-inpt-field">
                                                    <input type="text" 
                                                    name="improvementPrcnt" 
                                                    className="field-ipt wthinherit font12px bg-color-01 text-right text-black" 
                                                    placeholder={0}
                                                    value={Inputs.leadGenerationD16}
                                                    onChange={(event) => {
                                                        setInputs({ ...Inputs, leadGenerationD16: event.target.value });
                                                        leadGenerationData("leadGenerationD16", event.target.value);
                                                        improvementPrcntHandler(event.target.value)
                                                    }}
                                                    onKeyPress={(event) => {
                                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                          event.preventDefault();
                                                        }
                                                        else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                          event.preventDefault();
                                                          alert.show('Please enter a valid number.')
                                                        }
                                                    }}
                                                    disabled={isLoading} />
                                                </div>
                                                <div className="perecentage">%</div>
                                            </div>
                                        </th>
                                    </tr>
                                </tbody>
                            </table>
                            <Spinner />
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6" style={{minHeight: 400}}>
                    <BarChartGraphNew dataInput={{
                        "Title"      : "Lead Generation:  Impact on Financial Performance",
                        "ColorCodes" : ["#d6dce5", "#8497b0", "#1e2b3c"],
                        "GraphData"  : {
                            labels: [["Your","Projection"], "Year 1", "Year 2", "Year 3", "Year 4", "Year 5"],
                            datasets: [
                                {
                                    label: "Profit",
                                    backgroundColor: "#1e65a7", //"#d6dce5",
                                    data: [Number(leadGenerationD26),Number(leadGenerationE26),Number(leadGenerationF26),Number(leadGenerationG26),Number(leadGenerationH26),Number(leadGenerationI26)]
                                },
                                {
                                    label: "Sales",
                                    backgroundColor: "#8497b0",
                                    data: [Number(leadGenerationD25),Number(leadGenerationE25),Number(leadGenerationF25),Number(leadGenerationG25),Number(leadGenerationH25),Number(leadGenerationI25)]
                                },
                                {
                                    label: "Valuation",
                                    backgroundColor: "#1e2b3c",
                                    data: [Number(leadGenerationD31),Number(leadGenerationE31),Number(leadGenerationF31),Number(leadGenerationG31),Number(leadGenerationH31),Number(leadGenerationI31)]
                                }
                            ]
                        }
                    }} />
                </div>
            </div>
            <div className="card update-class-lead">
                <div className="card-body pl-0">
                    <div className="table-responsive">
                        <table className="table table-responsive-md table-striped mb-0" style={{width: 1200}}>
                            <thead>
                                <tr>
                                <th className="table-primar" style={{width: 300}}/>
                                <th colSpan={2} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Year 1 Gains</th>
                                <th colSpan={2} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Mid-Term Gains</th>
                                <th colSpan={5} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Long-Term Gains </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="thead-primary">
                                    <th className='black-bg fontBold' style={{width: 300}}>Lead Generation Improvements</th>
                                    <th className='black-bg' style={{width: 150}}>Your Projection</th>
                                    <th className='black-bg' style={{width: 150}}>1% Quarterly Improvement</th>
                                    <th className='black-bg' style={{width: 150}}>2 Years of 1% Improvements</th>
                                    <th className='black-bg' style={{width: 150}}>3 Years of 1% Improvements</th>
                                    <th className='black-bg' style={{width: 150}}>4 Years of 1% Improvements</th>
                                    <th className='black-bg' style={{width: 150}}>5 Years of 1% Improvements</th>
                                </tr>
                                <tr>
                                <td className='bg-color-light-blue font12px color-black'>New Leads Generated Annually</td>
                                    <td className='bg-color-blue-gray text-white font12px'>{Number(leadGenerationD20).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</td>
                                    <td className='bg-color-light-blue font12px color-black'>{Number(leadGenerationE20).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</td>
                                    <td className='bg-color-light-blue font12px color-black'>{Number(leadGenerationF20).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</td>
                                    <td className='bg-color-light-blue font12px color-black'>{Number(leadGenerationG20).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</td>
                                    <td className='bg-color-light-blue font12px color-black'>{Number(leadGenerationH20).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</td>
                                    <td className='bg-color-light-blue font12px color-black'>{Number(leadGenerationI20).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</td>
                                </tr>
                                <tr>
                                <td className='bg-color-light-blue font12px color-black'><strong>Total Numbers of Leads Generated Annually</strong></td>
                                <td className='bg-color-blue-gray text-white font12px'><strong>{Number(leadGenerationD21).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong></td>
                                <td className='bg-color-teal text-white font12px'><strong>{Number(leadGenerationE21).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong></td>
                                <td className='bg-color-teal text-white font12px'><strong>{Number(leadGenerationF21).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong></td>
                                <td className='bg-color-teal text-white font12px'><strong>{Number(leadGenerationG21).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong></td>
                                <td className='bg-color-teal text-white font12px'><strong>{Number(leadGenerationH21).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong></td>
                                <td className='bg-color-teal text-white font12px'><strong>{Number(leadGenerationI21).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="card update-class-lead">
                <div className="card-body pl-0">
                <div className="table-responsive">
                    <table className="table table-responsive-md table-striped mb-0" style={{width: 1200}}>
                    <thead>
                        <tr>
                        <th className='nowrap' style={{background:'#fff', width: 300}}><h3>Financial Impact</h3></th>
                        <th colSpan={2} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Year 1 Gains</th>
                        <th colSpan={2} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Mid-Term Gains</th>
                        <th colSpan={5} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Long-Term Gains </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="thead-primary">
                            <th className='black-bg fontBold' style={{width: 300}}>Annual Impact of Leads Generation</th>
                            <th className='black-bg' style={{width: 150}}>Your Projection</th>
                            <th className='black-bg' style={{width: 150}}>1% Quarterly Improvement</th>
                            <th className='black-bg' style={{width: 150}}>2 Years of 1% Improvements</th>
                            <th className='black-bg' style={{width: 150}}>3 Years of 1% Improvements</th>
                            <th className='black-bg' style={{width: 150}}>4 Years of 1% Improvements</th>
                            <th className='black-bg' style={{width: 150}}>5 Years of 1% Improvements</th>
                        </tr>
                        <tr>
                        <td className='bg-color-light-blue font12px color-black'>Annual Sales</td>
                        <td className='bg-color-blue-gray text-white font12px'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px text-white"> $ </div>
                                <div className="annual-dl-number font12px text-white">
                                    {Number(leadGenerationD25).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(leadGenerationE25).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(leadGenerationF25).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(leadGenerationG25).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(leadGenerationH25).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(leadGenerationI25).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <td className='bg-color-light-blue font12px color-black'>Annual Profit</td>
                        <td className='bg-color-blue-gray text-white font12px'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                {Number(leadGenerationD26).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(leadGenerationE26).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(leadGenerationF26).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(leadGenerationG26).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(leadGenerationH26).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(leadGenerationI26).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        </tr>
                        <tr className="thead-primary">
                        <th className='black-bg fontBold'>Lifetime Impact of Lead Generation</th>
                        <th className='black-bg'>Your Projection</th>
                        <th className='black-bg'>1% Quarterly Improvement</th>
                        <th className='black-bg'>2 Years of 1% Improvements</th>
                        <th className='black-bg'>3 Years of 1% Improvements</th>
                        <th className='black-bg'>4 Years of 1% Improvements</th>
                        <th className='black-bg'>5 Years of 1% Improvements</th>
                        </tr>
                        <tr>
                        <td className='bg-color-light-blue font12px color-black'>Lifetime Sales</td>
                        <td className='bg-color-blue-gray text-white font12px'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(leadGenerationD28).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(leadGenerationE28).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(leadGenerationF28).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(leadGenerationG28).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(leadGenerationH28).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(leadGenerationI28).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <td className='bg-color-light-blue font12px color-black'>Lifetime Profit</td>
                        <td className='bg-color-blue-gray text-white font12px'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(leadGenerationD29).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(leadGenerationE29).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(leadGenerationF29).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(leadGenerationG29).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(leadGenerationH29).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(leadGenerationI29).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        </tr>
                        <tr className="thead-primary">
                        <th className='black-bg fontBold'>Valuation Impact of Lead Generation</th>
                        <th className='black-bg'>Your Projection</th>
                        <th className='black-bg'>1% Quarterly Improvement</th>
                        <th className='black-bg'>2 Years of 1% Improvements</th>
                        <th className='black-bg'>3 Years of 1% Improvements</th>
                        <th className='black-bg'>4 Years of 1% Improvements</th>
                        <th className='black-bg'>5 Years of 1% Improvements</th>
                        </tr>
                        <tr>
                        <td className='bg-color-light-blue font12px color-black'>Estimated Valuation Impact</td>
                        <td className='bg-color-blue-gray text-white font12px'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(leadGenerationD31).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(leadGenerationE31).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(leadGenerationF31).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(leadGenerationG31).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(leadGenerationH31).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(leadGenerationI31).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                <div className="nextInput">
                    <ul>
                        <li>
                            <Link to="/prescribe/conversion" className='next-link'> Next:  Conversion  
                                <span className='icon-three'>
                                    <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                    <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                    <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                </span>
                            </Link>
                        </li>
                    </ul>
                </div>
                </div>
            </div>
            </div>

    </div>

  )
}
