import React from "react";
import { Chart } from "react-google-charts";

export function PieChartGraph({dataInput}) {

    const data = dataInput.GraphData;
    const Title = dataInput.Title ? dataInput.Title : "";
    const vTitle = dataInput.vTitle ? dataInput.vTitle : "";
    const hTitle = dataInput.hTitle ? dataInput.hTitle : "";
    const subTitle = dataInput.subTitle ? dataInput.subTitle : "";
    const ColorCodes = dataInput.ColorCodes ? dataInput.ColorCodes : ["#efbc44", "#447fef", "#0e8262", "#aa28d9"];
      
    const options = {
        title: Title,
        pieHole: 0.5,
        is3D: false,
        pieSliceTextStyle: {
            color: 'white',
            fontSize: 15
        },
        colors: ColorCodes,
        legend: {
            layout: 'horizontal',
            position: "bottom",
            alignment: "center",
            textStyle: {
              color: "#44546a",
              fontSize: 12
            }
        },
        chartArea: {
            left: 0,
            top: 0,
            width: "95%",
            height: "80%"
        },
        tooltip: {
            showColorCode: true
        },
    };

    return (
        <Chart
        chartType="PieChart"
        width={"100%"}
        height={"300px"}
        data={data}
        options={options}
        />
    );
}