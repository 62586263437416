import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getJobsToBeDone, modifyJobsToBeDone } from "../../state/features/JobsToBeDoneSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';

export default function JobsToBeDoneContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        JobsToBeDoneC12 : '',
        JobsToBeDoneD12 : '',
        JobsToBeDoneE12 : '',
        JobsToBeDoneC13 : '',
        JobsToBeDoneD13 : '',
        JobsToBeDoneE13 : '',
        JobsToBeDoneC14 : '',
        JobsToBeDoneD14 : '',
        JobsToBeDoneE14 : '',
        JobsToBeDoneC15 : '',
        JobsToBeDoneD15 : '',
        JobsToBeDoneE15 : '',
        JobsToBeDoneC16 : '',
        JobsToBeDoneD16 : '',
        JobsToBeDoneE16 : '',
        JobsToBeDoneC17 : '',
        JobsToBeDoneD17 : '',
        JobsToBeDoneE17 : '',
        JobsToBeDoneC18 : '',
        JobsToBeDoneD18 : '',
        JobsToBeDoneE18 : '',
        JobsToBeDoneC19 : '',
        JobsToBeDoneD19 : '',
        JobsToBeDoneE19 : '',
        JobsToBeDoneC20 : '',
        JobsToBeDoneD20 : '',
        JobsToBeDoneE20 : '',
        JobsToBeDoneC21 : '',
        JobsToBeDoneD21 : '',
        JobsToBeDoneE21 : '',
        JobsToBeDoneF12 : 0,
        JobsToBeDoneF13 : 0,
        JobsToBeDoneF14 : 0,
        JobsToBeDoneF15 : 0,
        JobsToBeDoneF16 : 0,
        JobsToBeDoneF17 : 0,
        JobsToBeDoneF18 : 0,
        JobsToBeDoneF19 : 0,
        JobsToBeDoneF20 : 0,
        JobsToBeDoneF21 : 0,
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getJobsToBeDone({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.revenueGrowth)
            if(response!= undefined  && response.data.revenueGrowth){
                let revenueGrowth = response.data.revenueGrowth[0];
                console.log(revenueGrowth)
                delete revenueGrowth.id;
                delete revenueGrowth.user_id;
                delete revenueGrowth.created_at;
                delete revenueGrowth.updated_at;
                const newObj = { ...Inputs }
                for (const key in revenueGrowth) {
                    if (revenueGrowth.hasOwnProperty(key)) {
                        if(revenueGrowth[key])
                        newObj[key] = revenueGrowth[key]
                    }
                }
                newObj['JobsToBeDoneF12'] = (revenueGrowth['JobsToBeDoneD12'] ? parseFloat(revenueGrowth['JobsToBeDoneD12']) : 0) + (revenueGrowth['JobsToBeDoneE12'] ? parseFloat(revenueGrowth['JobsToBeDoneE12']) : 0);
                newObj['JobsToBeDoneF13'] = (revenueGrowth['JobsToBeDoneD13'] ? parseFloat(revenueGrowth['JobsToBeDoneD13']) : 0) + (revenueGrowth['JobsToBeDoneE13'] ? parseFloat(revenueGrowth['JobsToBeDoneE13']) : 0);
                newObj['JobsToBeDoneF14'] = (revenueGrowth['JobsToBeDoneD14'] ? parseFloat(revenueGrowth['JobsToBeDoneD14']) : 0) + (revenueGrowth['JobsToBeDoneE14'] ? parseFloat(revenueGrowth['JobsToBeDoneE14']) : 0);
                newObj['JobsToBeDoneF15'] = (revenueGrowth['JobsToBeDoneD15'] ? parseFloat(revenueGrowth['JobsToBeDoneD15']) : 0) + (revenueGrowth['JobsToBeDoneE15'] ? parseFloat(revenueGrowth['JobsToBeDoneE15']) : 0);
                newObj['JobsToBeDoneF16'] = (revenueGrowth['JobsToBeDoneD16'] ? parseFloat(revenueGrowth['JobsToBeDoneD16']) : 0) + (revenueGrowth['JobsToBeDoneE16'] ? parseFloat(revenueGrowth['JobsToBeDoneE16']) : 0);
                newObj['JobsToBeDoneF17'] = (revenueGrowth['JobsToBeDoneD17'] ? parseFloat(revenueGrowth['JobsToBeDoneD17']) : 0) + (revenueGrowth['JobsToBeDoneE17'] ? parseFloat(revenueGrowth['JobsToBeDoneE17']) : 0);
                newObj['JobsToBeDoneF18'] = (revenueGrowth['JobsToBeDoneD18'] ? parseFloat(revenueGrowth['JobsToBeDoneD18']) : 0) + (revenueGrowth['JobsToBeDoneE18'] ? parseFloat(revenueGrowth['JobsToBeDoneE18']) : 0);
                newObj['JobsToBeDoneF19'] = (revenueGrowth['JobsToBeDoneD19'] ? parseFloat(revenueGrowth['JobsToBeDoneD19']) : 0) + (revenueGrowth['JobsToBeDoneE19'] ? parseFloat(revenueGrowth['JobsToBeDoneE19']) : 0);
                newObj['JobsToBeDoneF20'] = (revenueGrowth['JobsToBeDoneD20'] ? parseFloat(revenueGrowth['JobsToBeDoneD20']) : 0) + (revenueGrowth['JobsToBeDoneE20'] ? parseFloat(revenueGrowth['JobsToBeDoneE20']) : 0);
                newObj['JobsToBeDoneF21'] = (revenueGrowth['JobsToBeDoneD21'] ? parseFloat(revenueGrowth['JobsToBeDoneD21']) : 0) + (revenueGrowth['JobsToBeDoneE21'] ? parseFloat(revenueGrowth['JobsToBeDoneE21']) : 0);

                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                let thenum = event.target.id.match(/\d+/)[0];
                let thestr = event.target.id.replace(thenum,'');
                let overallScore = 0;
                if(thestr == 'JobsToBeDoneD'){
                    overallScore = (event.target.value ? parseFloat(event.target.value) : 0) + (Inputs['JobsToBeDoneE'+thenum] ? parseFloat(Inputs['JobsToBeDoneE'+thenum]) : 0);
                    setInputs({ ...Inputs, ['JobsToBeDoneF'+thenum] : parseFloat(overallScore) })
                }
                else if(thestr == 'JobsToBeDoneE'){
                    overallScore = (Inputs['JobsToBeDoneD'+thenum] ? parseFloat(Inputs['JobsToBeDoneD'+thenum]) : 0) + (event.target.value ? parseFloat(event.target.value) : 0);
                    setInputs({ ...Inputs, ['JobsToBeDoneF'+thenum] : parseFloat(overallScore) })
                }              
                
                dispatch(modifyJobsToBeDone({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value, ['JobsToBeDoneF'+thenum] : parseFloat(overallScore)});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

  return (
    <div className="content-body">
        <UserDropdown />
        {/* row */}
        <div className="container-fluid">
            <div className="head-title">
                <p className="mb-0">Innovation</p>
                <h2 className="mb-0 mt-0">Jobs To Be Done</h2>
                <p className="mb-5">Jobs to be Done is a lens through which companies can view their innovation initiatives. People buy products and services to get a “job” done, and the products that are successful are those which help the customer get a job done faster, more easily and less expensively. When a company understands in detail what a functional job is, it is more likely to be able to create solutions to help the customer get a job done more effectively. When the customer can get a job done more easily with a given product, the product will likely be more successful. </p>
            </div>
            <Spinner />
            <div className="card">
                <div className="card-body pl-0">
                <div className="table-responsive table-dta">
                    {/* table 2 */}
                    <table className="table mb-0">
                    <tbody>
                        <tr>
                        <td colSpan={4} className="width-20 text-white bg-color1 padding-10">
                            <div className="white-hats-agile">
                            <div className="White-Hat-title-agile">Jobs To Be Done</div>
                            <img src="../images/job-to-be-done/jobs.png" className="img-fluid" alt="jobs" />
                            </div>
                        </td>
                        <td className="width-2 bg-color1 text-white">
                            <div className="white-hats-agile">
                            <div className="White-Hat-title-agile">Importance</div>
                            <img src="../images/job-to-be-done/importance.png" className="img-fluid" alt="importance" />
                            </div>
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color1">
                            <div className="white-hats-agile">
                            <div className="White-Hat-title-agile">Difficulty</div>
                            <img src="../images/job-to-be-done/difficulty.png" className="img-fluid" alt="difficulty" />
                            </div>
                        </td>
                        <td className="width-2 bg-color1 text-white">
                            <div className="white-hats-agile">
                            <div className="White-Hat-title-agile">Top Opportunities</div>
                            <img src="../images/job-to-be-done/opportunities.png" className="img-fluid" alt="opportunities" />
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4} className="width-20 text-white bg-color3 padding-15">
                            What are customers' top "jobs to be done"?
                        </td>
                        <td className="width-2 text-white bg-color3">
                            What is the relative importance compared to other jobs?  (1=low; 10=high)
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color3">
                            What is the relative difficulty compared to other jobs?  (1=low; 10=high)
                        </td>
                        <td className="width-2 text-white bg-color3">
                            Top jobs to address that are the biggest opportunities.
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4} className="width-20 padding-15">
                            <input type="text" className="form-control inputText"
                                id="JobsToBeDoneC12"
                                value={Inputs.JobsToBeDoneC12}
                                onChange={(event) => setInputs({ ...Inputs, JobsToBeDoneC12 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td className="width-2">
                            <input type="text" className="form-control inputText" 
                                id="JobsToBeDoneD12" 
                                value={Inputs.JobsToBeDoneD12} 
                                onChange={(event) => setInputs({ ...Inputs, JobsToBeDoneD12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter number between 1 to 10.')
                                    }
                                }} />
                        </td>
                        <td colSpan={4} className="width-20">
                            <input type="text" className="form-control inputText" 
                                id="JobsToBeDoneE12" 
                                value={Inputs.JobsToBeDoneE12} 
                                onChange={(event) => setInputs({ ...Inputs, JobsToBeDoneE12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter number between 1 to 10.')
                                    }
                                }} />
                        </td>
                        <td className="width-2 color-g text-center-c">
                            {Inputs.JobsToBeDoneF12}
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4} className="width-20 padding-15">
                            <input type="text" className="form-control inputText"
                                id="JobsToBeDoneC13"
                                value={Inputs.JobsToBeDoneC13}
                                onChange={(event) => setInputs({ ...Inputs, JobsToBeDoneC13 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td className="width-2">
                            <input type="text" className="form-control inputText" 
                                id="JobsToBeDoneD13" 
                                value={Inputs.JobsToBeDoneD13} 
                                onChange={(event) => setInputs({ ...Inputs, JobsToBeDoneD13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter number between 1 to 10.')
                                    }
                                }} />
                        </td>
                        <td colSpan={4} className="width-20">
                            <input type="text" className="form-control inputText" 
                                id="JobsToBeDoneE13" 
                                value={Inputs.JobsToBeDoneE13} 
                                onChange={(event) => setInputs({ ...Inputs, JobsToBeDoneE13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter number between 1 to 10.')
                                    }
                                }} />
                        </td>
                        <td className="width-2 color-g text-center-c">
                            {Inputs.JobsToBeDoneF13}
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4} className="width-20 padding-15">
                            <input type="text" className="form-control inputText"
                                id="JobsToBeDoneC14"
                                value={Inputs.JobsToBeDoneC14}
                                onChange={(event) => setInputs({ ...Inputs, JobsToBeDoneC14 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td className="width-2">
                            <input type="text" className="form-control inputText" 
                                id="JobsToBeDoneD14" 
                                value={Inputs.JobsToBeDoneD14} 
                                onChange={(event) => setInputs({ ...Inputs, JobsToBeDoneD14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter number between 1 to 10.')
                                    }
                                }} />
                        </td>
                        <td colSpan={4} className="width-20">
                            <input type="text" className="form-control inputText" 
                                id="JobsToBeDoneE14" 
                                value={Inputs.JobsToBeDoneE14} 
                                onChange={(event) => setInputs({ ...Inputs, JobsToBeDoneE14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter number between 1 to 10.')
                                    }
                                }} />
                        </td>
                        <td className="width-2 color-g text-center-c">
                            {Inputs.JobsToBeDoneF14}
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4} className="width-20 padding-15">
                            <input type="text" className="form-control inputText"
                                id="JobsToBeDoneC15"
                                value={Inputs.JobsToBeDoneC15}
                                onChange={(event) => setInputs({ ...Inputs, JobsToBeDoneC15 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td className="width-2">
                            <input type="text" className="form-control inputText" 
                                id="JobsToBeDoneD15" 
                                value={Inputs.JobsToBeDoneD15} 
                                onChange={(event) => setInputs({ ...Inputs, JobsToBeDoneD15: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter number between 1 to 10.')
                                    }
                                }} />
                        </td>
                        <td colSpan={4} className="width-20">
                            <input type="text" className="form-control inputText" 
                                id="JobsToBeDoneE15" 
                                value={Inputs.JobsToBeDoneE15} 
                                onChange={(event) => setInputs({ ...Inputs, JobsToBeDoneE15: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter number between 1 to 10.')
                                    }
                                }} />
                        </td>
                        <td className="width-2 color-g text-center-c">
                            {Inputs.JobsToBeDoneF15}
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4} className="width-20 padding-15">
                            <input type="text" className="form-control inputText"
                                id="JobsToBeDoneC16"
                                value={Inputs.JobsToBeDoneC16}
                                onChange={(event) => setInputs({ ...Inputs, JobsToBeDoneC16 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td className="width-2">
                            <input type="text" className="form-control inputText" 
                                id="JobsToBeDoneD16" 
                                value={Inputs.JobsToBeDoneD16} 
                                onChange={(event) => setInputs({ ...Inputs, JobsToBeDoneD16: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter number between 1 to 10.')
                                    }
                                }} />
                        </td>
                        <td colSpan={4} className="width-20">
                            <input type="text" className="form-control inputText" 
                                id="JobsToBeDoneE16" 
                                value={Inputs.JobsToBeDoneE16} 
                                onChange={(event) => setInputs({ ...Inputs, JobsToBeDoneE16: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter number between 1 to 10.')
                                    }
                                }} />
                        </td>
                        <td className="width-2 color-g text-center-c">
                            {Inputs.JobsToBeDoneF16}
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4} className="width-20 padding-15">
                            <input type="text" className="form-control inputText"
                                id="JobsToBeDoneC17"
                                value={Inputs.JobsToBeDoneC17}
                                onChange={(event) => setInputs({ ...Inputs, JobsToBeDoneC17 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td className="width-2">
                            <input type="text" className="form-control inputText" 
                                id="JobsToBeDoneD17" 
                                value={Inputs.JobsToBeDoneD17} 
                                onChange={(event) => setInputs({ ...Inputs, JobsToBeDoneD17: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter number between 1 to 10.')
                                    }
                                }} />
                        </td>
                        <td colSpan={4} className="width-20">
                            <input type="text" className="form-control inputText" 
                                id="JobsToBeDoneE17" 
                                value={Inputs.JobsToBeDoneE17} 
                                onChange={(event) => setInputs({ ...Inputs, JobsToBeDoneE17: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter number between 1 to 10.')
                                    }
                                }} />
                        </td>
                        <td className="width-2 color-g text-center-c">
                            {Inputs.JobsToBeDoneF17}
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4} className="width-20 padding-15">
                            <input type="text" className="form-control inputText"
                                id="JobsToBeDoneC18"
                                value={Inputs.JobsToBeDoneC18}
                                onChange={(event) => setInputs({ ...Inputs, JobsToBeDoneC18 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td className="width-2">
                            <input type="text" className="form-control inputText" 
                                id="JobsToBeDoneD18" 
                                value={Inputs.JobsToBeDoneD18} 
                                onChange={(event) => setInputs({ ...Inputs, JobsToBeDoneD18: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter number between 1 to 10.')
                                    }
                                }} />
                        </td>
                        <td colSpan={4} className="width-20">
                            <input type="text" className="form-control inputText" 
                                id="JobsToBeDoneE18" 
                                value={Inputs.JobsToBeDoneE18} 
                                onChange={(event) => setInputs({ ...Inputs, JobsToBeDoneE18: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter number between 1 to 10.')
                                    }
                                }} />
                        </td>
                        <td className="width-2 color-g text-center-c">
                            {Inputs.JobsToBeDoneF18}
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4} className="width-20 padding-15">
                            <input type="text" className="form-control inputText"
                                id="JobsToBeDoneC19"
                                value={Inputs.JobsToBeDoneC19}
                                onChange={(event) => setInputs({ ...Inputs, JobsToBeDoneC19 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td className="width-2">
                            <input type="text" className="form-control inputText" 
                                id="JobsToBeDoneD19" 
                                value={Inputs.JobsToBeDoneD19} 
                                onChange={(event) => setInputs({ ...Inputs, JobsToBeDoneD19: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter number between 1 to 10.')
                                    }
                                }} />
                        </td>
                        <td colSpan={4} className="width-20">
                            <input type="text" className="form-control inputText" 
                                id="JobsToBeDoneE19" 
                                value={Inputs.JobsToBeDoneE19} 
                                onChange={(event) => setInputs({ ...Inputs, JobsToBeDoneE19: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter number between 1 to 10.')
                                    }
                                }} />
                        </td>
                        <td className="width-2 color-g text-center-c">
                            {Inputs.JobsToBeDoneF19}
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4} className="width-20 padding-15">
                            <input type="text" className="form-control inputText"
                                id="JobsToBeDoneC20"
                                value={Inputs.JobsToBeDoneC20}
                                onChange={(event) => setInputs({ ...Inputs, JobsToBeDoneC20 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td className="width-2">
                            <input type="text" className="form-control inputText" 
                                id="JobsToBeDoneD20" 
                                value={Inputs.JobsToBeDoneD20} 
                                onChange={(event) => setInputs({ ...Inputs, JobsToBeDoneD20: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter number between 1 to 10.')
                                    }
                                }} />
                        </td>
                        <td colSpan={4} className="width-20">
                            <input type="text" className="form-control inputText" 
                                id="JobsToBeDoneE20" 
                                value={Inputs.JobsToBeDoneE20} 
                                onChange={(event) => setInputs({ ...Inputs, JobsToBeDoneE20: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter number between 1 to 10.')
                                    }
                                }} />
                        </td>
                        <td className="width-2 color-g text-center-c">
                            {Inputs.JobsToBeDoneF20}
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4} className="width-20 padding-15">
                            <input type="text" className="form-control inputText"
                                id="JobsToBeDoneC21"
                                value={Inputs.JobsToBeDoneC21}
                                onChange={(event) => setInputs({ ...Inputs, JobsToBeDoneC21 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td className="width-2">
                            <input type="text" className="form-control inputText" 
                                id="JobsToBeDoneD21" 
                                value={Inputs.JobsToBeDoneD21} 
                                onChange={(event) => setInputs({ ...Inputs, JobsToBeDoneD21: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter number between 1 to 10.')
                                    }
                                }} />
                        </td>
                        <td colSpan={4} className="width-20">
                            <input type="text" className="form-control inputText" 
                                id="JobsToBeDoneE21" 
                                value={Inputs.JobsToBeDoneE21} 
                                onChange={(event) => setInputs({ ...Inputs, JobsToBeDoneE21: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter number between 1 to 10.')
                                    }
                                }} />
                        </td>
                        <td className="width-2 color-g text-center-c">
                            {Inputs.JobsToBeDoneF21}
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}
