import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import { getZeroInOnValue, modifyZeroInOnValue } from '../../state/features/ZeroInOnValueSlice';

const ZeroInOnValueContentNew = () => {

    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        ZeroInOnValueD9 : "",
        ZeroInOnValueF9 : "",
        ZeroInOnValueD10 : "",
        ZeroInOnValueF10 : "",
        ZeroInOnValueD11 : "",
        ZeroInOnValueF11 : "",
        ZeroInOnValueD12 : "",
        ZeroInOnValueF12 : "",
        ZeroInOnValueD13 : "",
        ZeroInOnValueF13 : "",
        ZeroInOnValueD15 : "",
        ZeroInOnValueF15 : "",
        ZeroInOnValueD16 : "",
        ZeroInOnValueF16 : "",
        ZeroInOnValueD17 : "",
        ZeroInOnValueF17 : "",
        ZeroInOnValueD18 : "",
        ZeroInOnValueF18 : "",
        ZeroInOnValueD19 : "",
        ZeroInOnValueF19 : "",
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getZeroInOnValue({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.growthPlanning)
            if(response!= undefined  && response.data.growthPlanning){
                let growthPlanning = response.data.growthPlanning[0];
                // console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key]
                    }
                }
                
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyZeroInOnValue({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        //window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
            {/* row */}
            <div className="container-fluid">
                <div className="head-title mb-4">
                    <h3 className='preheading'>Tools - Value Creation</h3>
                    <h1 className='mainheading mb-1'>Zero In on Value</h1>
                </div>
                <div className="Reverser-engineering-planning">
                    <div className="Reverser-engineering-planning-inner">
                        <Spinner/>
                        <div className="table-responsive">
                            {/* zero value table 01 */}
                            <table className="table circle-set-div" style={{width: 1125}}>
                                <thead>
                                    <tr>
                                        <td className='number-width-td hide-content'></td>
                                        <td colSpan={3} className='bg-color-blue-gray text-white text-center'>
                                            <div className="tangible">
                                                <p className='font16px'>Tangible</p>
                                            </div>
                                        </td>
                                        <td className='number-width-td hide-content'></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='bg-color-blue-gray text-white text-center' rowSpan={13} style={{width: 50}}>
                                            <div className="rotate-text">
                                                <p className='professional-text font16px'>Professional</p>
                                            </div>
                                        </td>
                                        <td className='bg-color01 text-white text-center' style={{width: 500}}>
                                            <div className="quantifiable">
                                                <img src={window.location.origin+"/images/Zero/cash-flow.png"}  alt="img_01" />
                                                <p className='font14px fontExtraBold'>Quantifiable Benefits</p>
                                            </div>
                                        </td>
                                        <td className='hide-content' style={{width: 25}}></td>
                                        <td className='bg-color01 text-white text-center' style={{width: 500}}>
                                            <div className="quantifiable">
                                                <p className='font14px fontExtraBold'>Other Measurable Benefits</p>
                                                <img src={window.location.origin+"/images/Zero/pencil-and-ruler.png"}  alt="img_01" className='w-100' style={{maxWidth:40, height: 40}}/>
                                            </div>
                                        </td>
                                        <td rowSpan={13} className='bg-color-blue-gray text-white text-center' style={{width: 50}}>
                                            <div className="rotate-text">
                                                <p className='font16px'>Personal</p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark"
                                                id="ZeroInOnValueD9"
                                                value={Inputs.ZeroInOnValueD9}
                                                onChange={(event) => setInputs({ ...Inputs, ZeroInOnValueD9: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark"
                                                id="ZeroInOnValueF9" dir="rtl"
                                                value={Inputs.ZeroInOnValueF9}
                                                onChange={(event) => setInputs({ ...Inputs, ZeroInOnValueF9: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>   
                                    <tr>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark"
                                                id="ZeroInOnValueD10"
                                                value={Inputs.ZeroInOnValueD10}
                                                onChange={(event) => setInputs({ ...Inputs, ZeroInOnValueD10: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark"
                                                id="ZeroInOnValueF10" dir="rtl"
                                                value={Inputs.ZeroInOnValueF10}
                                                onChange={(event) => setInputs({ ...Inputs, ZeroInOnValueF10: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr> 
                                    <tr>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark"
                                                id="ZeroInOnValueD11"
                                                value={Inputs.ZeroInOnValueD11}
                                                onChange={(event) => setInputs({ ...Inputs, ZeroInOnValueD11: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark"
                                                id="ZeroInOnValueF11" dir="rtl"
                                                value={Inputs.ZeroInOnValueF11}
                                                onChange={(event) => setInputs({ ...Inputs, ZeroInOnValueF11: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr> 
                                    <tr>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <textarea className="inputfield-limit-lt field-ipt bg-color-02 font11px color-dark"
                                                id="ZeroInOnValueD12"
                                                value={Inputs.ZeroInOnValueD12}
                                                onChange={(event) => setInputs({ ...Inputs, ZeroInOnValueD12: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <textarea className=" field-ipt bg-color-02 font11px color-dark"
                                                id="ZeroInOnValueF12" dir="rtl"
                                                value={Inputs.ZeroInOnValueF12}
                                                onChange={(event) => setInputs({ ...Inputs, ZeroInOnValueF12: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr> 
                                    <tr>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <textarea className="inputfield-limit-lt field-ipt bg-color-02 font11px color-dark"
                                                id="ZeroInOnValueD13"
                                                value={Inputs.ZeroInOnValueD13}
                                                onChange={(event) => setInputs({ ...Inputs, ZeroInOnValueD13: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <textarea className=" field-ipt bg-color-02 font11px color-dark"
                                                id="ZeroInOnValueF13" dir="rtl"
                                                value={Inputs.ZeroInOnValueF13}
                                                onChange={(event) => setInputs({ ...Inputs, ZeroInOnValueF13: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr> 
                                    <tr>
                                        <td className='hide-content' colSpan={3} style={{height: 25}}>hide</td>
                                    </tr> 

                                    <tr>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <textarea className="inputfield-limit-lt field-ipt bg-color-02 font11px color-dark"
                                                id="ZeroInOnValueD15"
                                                value={Inputs.ZeroInOnValueD15}
                                                onChange={(event) => setInputs({ ...Inputs, ZeroInOnValueD15: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <textarea className=" field-ipt bg-color-02 font11px color-dark"
                                                id="ZeroInOnValueF15" dir="rtl"
                                                value={Inputs.ZeroInOnValueF15}
                                                onChange={(event) => setInputs({ ...Inputs, ZeroInOnValueF15: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr> 
                                    <tr>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <textarea className="inputfield-limit-lt field-ipt bg-color-02 font11px color-dark"
                                                id="ZeroInOnValueD16"
                                                value={Inputs.ZeroInOnValueD16}
                                                onChange={(event) => setInputs({ ...Inputs, ZeroInOnValueD16: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <textarea className=" field-ipt bg-color-02 font11px color-dark"
                                                id="ZeroInOnValueF16" dir="rtl"
                                                value={Inputs.ZeroInOnValueF16}
                                                onChange={(event) => setInputs({ ...Inputs, ZeroInOnValueF16: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr> 
                                    <tr>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark"
                                                id="ZeroInOnValueD17"
                                                value={Inputs.ZeroInOnValueD17}
                                                onChange={(event) => setInputs({ ...Inputs, ZeroInOnValueD17: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark"
                                                id="ZeroInOnValueF17" dir="rtl"
                                                value={Inputs.ZeroInOnValueF17}
                                                onChange={(event) => setInputs({ ...Inputs, ZeroInOnValueF17: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr> 
                                    <tr>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark"
                                                id="ZeroInOnValueD18"
                                                value={Inputs.ZeroInOnValueD18}
                                                onChange={(event) => setInputs({ ...Inputs, ZeroInOnValueD18: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark"
                                                id="ZeroInOnValueF18" dir="rtl"
                                                value={Inputs.ZeroInOnValueF18}
                                                onChange={(event) => setInputs({ ...Inputs, ZeroInOnValueF18: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr> 
                                    <tr>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark"
                                                id="ZeroInOnValueD19"
                                                value={Inputs.ZeroInOnValueD19}
                                                onChange={(event) => setInputs({ ...Inputs, ZeroInOnValueD19: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark"
                                                id="ZeroInOnValueF19" dir="rtl"
                                                value={Inputs.ZeroInOnValueF19}
                                                onChange={(event) => setInputs({ ...Inputs, ZeroInOnValueF19: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr> 
                                    <tr>
                                        <td className='bg-color01 text-white text-center'>
                                            <div className="quantifiable">
                                                <img src={window.location.origin+"/images/Zero/earth.png"} alt="img_01" />
                                                <p className='font14px fontExtraBold'>Organizational Benefits</p>
                                            </div>
                                        </td>
                                        <td className='hide-content'>3</td>
                                        <td className='bg-color01 text-white text-center'>
                                            <div className="quantifiable">
                                                <p className='font14px fontExtraBold'>Personal Benefits</p>
                                                <img src={window.location.origin+"/images/Zero/stopwatch.png"}  alt="img_01" className='w-100' style={{maxWidth:40, height: 40}} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td colSpan={3} className='bg-color-blue-gray text-white text-center'>
                                            <div className="tangible">
                                                <p className='font16px'>Intangible</p>
                                            </div>
                                        </td>
                                        {/* <th>3</td>
                                        <th>4</td> */}
                                        <td className='hide-content'>5</td>
                                    </tr>
                                    
                                </tbody>
                                <div className="zero-in-on">
                                    <h1><strong>Zero In<br/>on Value</strong></h1>
                                </div>
                            </table>
                            {/* zero value table 01 */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ZeroInOnValueContentNew;