import React from "react";
import { Line } from "react-chartjs-2";

export function AreaLineChartGraphNew({dataInput}) {
  const data = dataInput.GraphData;
  const Title = dataInput.Title ? dataInput.Title : "";
  const vTitle = dataInput.vTitle ? dataInput.vTitle : "";
  const hTitle = dataInput.hTitle ? dataInput.hTitle : "";
  const subTitle = dataInput.subTitle ? dataInput.subTitle : "";
  const ColorCodes = dataInput.ColorCodes ? dataInput.ColorCodes : ["#c2c7cd", "#8497b0", "#44546a"];

  // const options = {
  //   title: Title,
  //   curveType: "function",
  //   interval: {
  //     i0: { style: "area", color: "#114daf ", lineWidth: 0.5 },
  //     i0: { style: "area", color: "#114daf ", lineWidth: 2 },
  //     i1: { style: "area", color: "#2cab86", lineWidth: 2 },
  //     i1: { style: "area", color: "#2cab86", lineWidth: 2 },
  //   },
  //   legend: "none",
  //   colors: ColorCodes,
  //   hAxis: {
  //     title: hTitle
  //   },
  //   vAxis: {
  //     title: vTitle,
  //   },
  //   subtitle: subTitle,
  // };

  // const options = {
  //   title: Title,
  //   curveType: "function",
  //   series: [{ color: "#fcba03" }, { color : "#4887d4"}],
  //   intervals: { style: "area" },
  //   legend: "none",
  // };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    title: Title,
    scales: {
      x: {
          ticks: {
              tickColor: '#88a2c3',
              color: '#88a2c3'
          },
          grid: {
              display: false
          }
      },
      y: {
          ticks: {
            stepSize: 5000000,
            tickColor: '#88a2c3',
            color: '#88a2c3',
            callback: function(value, index, ticks) {
              return '$' + Number(value).toLocaleString('en-US');
            }
          },
          grid: {
            color: '#334966'
          }
      }
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          boxWidth: 15,
          boxHeight: 2
        }
      },
      title: {
        display: false,
        text: dataInput.Title,
        color: 'white',
        font: {
          size: 20,
          weight: 400
        }
      }
    }
  }

  return (
    // <Chart
    //   chartType="LineChart"
    //   width="100%"
    //   height="400px"
    //   data={data}
    //   options={options}
    // />
    <Line options={options} data={data} />
  );
}