import axios from "axios";
import authHeader from "./auth-header";
import { trackPromise } from 'react-promise-tracker';

const getData = (user_id) => {
    return trackPromise (
        axios({
            method: "post",
            url: "diagnose/getPositioningScorecard",
            data: {user_id},
            headers: authHeader()
        })
    )
};
const postData = (data) => {
    return trackPromise (
        axios({
            method: "post",
            url: "diagnose/modifyPositioningScorecard",
            data,
            headers: authHeader()
        })
    )
};

const PositioningScorecardService = {
    getData,
    postData,
};

export default PositioningScorecardService;