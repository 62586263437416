import React from "react";
import { Chart } from "react-google-charts";

export default function WaterFallGraphNew({dataInput}) {

  const data = dataInput.GraphData;
  const Title = dataInput.Title ? dataInput.Title : "";
  const vTitle = dataInput.vTitle ? dataInput.vTitle : "";
  const hTitle = dataInput.hTitle ? dataInput.hTitle : "";
  const subTitle = dataInput.subTitle ? dataInput.subTitle : "";
  const ColorCodes = dataInput.ColorCodes ? dataInput.ColorCodes : ["#c2c7cd", "#8497b0", "#44546a"];
  const height = dataInput.height ? dataInput.height : "300px";
  const backgroundColor = dataInput.backgroundColor ? dataInput.backgroundColor : '';
  const legendTextStyle = dataInput.legendTextStyle ? dataInput.legendTextStyle : {color: "#fff"};
  const titleTextStyle = dataInput.titleTextStyle ? dataInput.titleTextStyle : {color: "#fff", bold: true};
  const textStyle = dataInput.textStyle ? dataInput.textStyle : {color: "#fff"};

  const options = {
    title: Title,
    // chartArea: { width: "80%" },
    colors: ColorCodes,
    backgroundColor,
    width: '80%',
      height: '350',
      chartArea:{
          right:5,
          width: '80%',
          height: '250',
      },
      vAxis: {
          format: 'currency'
      },
      hAxis: {
        textStyle: {
          fontSize: 15
        },
        showTextEvery: 1,
        slantedText: false,
      },
      annotations: {
        textStyle : {
            color : "black",
            fontSize: 12,
            bold: true,
        }
      },
    subtitle: subTitle,
    legend: { position: "none" },
    bar: {
      groupWidth: '85%',
    },
    legendTextStyle,
    titleTextStyle,
  };

  return (
    <Chart
      chartType="CandlestickChart"
      width='100%'
      height={400}
      data={data}
      options={options}
    />
  );
}