import React from "react";
import { Chart } from "react-google-charts";

export default function QuadrantChartGraphNew({dataInput}) {

    const data = dataInput.GraphData;
    const Title = dataInput.Title ? dataInput.Title : "";
    const vTitle = dataInput.vTitle ? dataInput.vTitle : "";
    const hTitle = dataInput.hTitle ? dataInput.hTitle : "";
    const subTitle = dataInput.subTitle ? dataInput.subTitle : "";
    const ColorCodes = dataInput.ColorCodes ? dataInput.ColorCodes : ["#c2c7cd", "#8497b0", "#44546a"];
    const backgroundColor = dataInput.backgroundColor ? dataInput.backgroundColor : '#f1f7f9'

    const options = {
        // width: '100%',
        // chartArea: { width: "60%" },
        chartArea:{
            right: 10,
            top: 10,
            width: '85%',
            height: '80%'
        },
        backgroundColor: 'transparent',
        seriesType: 'scatter',
        pointSize: 10,
        series: {
            5: {
                pointSize: 0,
                color: '#fff',
                enableInteractivity: false,
                type: 'line'
            },
            6: {
                pointSize: 0,
                color: '#fff',
                enableInteractivity: false,
                type: 'line'
            },
            7: {
                pointSize: 10,
                annotations: {
                    textStyle: {
                        color: '#50627c',
                        bold: true,
                        fontSize: 30
                    },
                    stem: {
                        length: 0
                    },
                },
                color: 'transparent',
                enableInteractivity: false
            }
        },
        vAxis: {
            title: vTitle,
            titleTextStyle: {
                color: '#fff',
                bold: true,
                fontSize: 20,
                italic: false
            },
            ticks: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            textStyle: {
                color: '#fff'
            },
            gridlines: {
                color: '#bfbfbf'
            }
        },
        hAxis: {
            title: hTitle,
            titleTextStyle: {
                color: '#fff',
                bold: true,
                fontSize: 20,
                italic: false
            },
            ticks: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            textStyle: {
                color: '#fff'
            },
            gridlines: {
                color: '#bfbfbf'
            }
        },
        // annotations: {
        //     textStyle: {
        //       fontName: 'Times-Roman',
        //       fontSize: 18,
        //       bold: true,
        //       italic: true,
        //       // The color of the text.
        //       color: '#FFF',
        //     }
        // },
        colors: ['#FFFFFF'],
        legend: 'none',
    }

    // return <Scatter options={options} data={data} plugins={[ chartAreaBorder ]}/>
    return <Chart
        chartType="ComboChart"
        width="100%"
        height={500}
        data={data}
        options={options}
    />;
}

/*
import { Chart } from "react-chartjs-2";


export default function QuadrantChartGraph({data}) {

    const chartAreaBorder = {
        id: 'quadrants',
        beforeDraw(chart, args, options) {
            const {ctx, chartArea: {left, top, right, bottom}, scales: {x, y}} = chart;
            const midX = x.getPixelForValue(5);
            const midY = y.getPixelForValue(5);
            ctx.save();
            ctx.fillStyle = '#1e2b3c';
            ctx.fillRect(left, top, midX - left, midY - top);
            ctx.fillStyle = '#1e2b3c';
            ctx.fillRect(midX, top, right - midX, midY - top);
            ctx.fillStyle = '#1e2b3c';
            ctx.fillRect(midX, midY, right - midX, bottom - midY);
            ctx.fillStyle = '#1e2b3c';
            ctx.fillRect(left, midY, midX - left, bottom - midY);

            ctx.fillStyle = '#fff';
            ctx.fillRect(0, midY, right, 2);
            ctx.fillStyle = '#fff';
            ctx.fillRect(midX, 0, 2, right);
            ctx.restore();
        }
    };

    const options = {
        type: 'bubble',
        plugins: [ chartAreaBorder ],
        options: {
            animation: true,
            aspectRatio: 1,
            scales: {
                x: {
                    title: {
                        display: true,
                        text: 'Effort / Cost'
                    },
                    min: 0,
                    max: 10,
                    ticks: {
                        stepSize: 2
                    }
                },
                y: {
                    title: {
                        display: true,
                        text: 'Value / Benefit'
                    },
                    min: 0,
                    max: 10,
                    ticks: {
                        stepSize: 1
                    }
                }
            },
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    enabled: false
                },
            }
        },
    };

    return (
        <Chart
            chartType="bubble"
            width="100%"
            data={data}
            options={options}
        />
    )
}

*/