import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getMessageSelection, modifyMessageSelection } from "../../state/features/MessageSelectionSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import sum from "../../utils/sum";

export default function MessageSelectionMatrixContent() {

    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        MessageSelectionD12 : '',
        MessageSelectionE12 : 0,
        MessageSelectionF12 : 0,
        MessageSelectionG12 : 0,
        MessageSelectionH12 : 0,
        MessageSelectionI12 : 0,
        MessageSelectionJ12 : 0,
        MessageSelectionD13 : '',
        MessageSelectionE13 : 0,
        MessageSelectionF13 : 0,
        MessageSelectionG13 : 0,
        MessageSelectionH13 : 0,
        MessageSelectionI13 : 0,
        MessageSelectionJ13 : 0,
        MessageSelectionD14 : '',
        MessageSelectionE14 : 0,
        MessageSelectionF14 : 0,
        MessageSelectionG14 : 0,
        MessageSelectionH14 : 0,
        MessageSelectionI14 : 0,
        MessageSelectionJ14 : 0,
        MessageSelectionD15 : '',
        MessageSelectionE15 : 0,
        MessageSelectionF15 : 0,
        MessageSelectionG15 : 0,
        MessageSelectionH15 : 0,
        MessageSelectionI15 : 0,
        MessageSelectionJ15 : 0,
        MessageSelectionD16 : '',
        MessageSelectionE16 : 0,
        MessageSelectionF16 : 0,
        MessageSelectionG16 : 0,
        MessageSelectionH16 : 0,
        MessageSelectionI16 : 0,
        MessageSelectionJ16 : 0,
        MessageSelectionD20 : '',
        MessageSelectionE20 : 0,
        MessageSelectionF20 : 0,
        MessageSelectionG20 : 0,
        MessageSelectionD21 : '',
        MessageSelectionE21 : 0,
        MessageSelectionF21 : 0,
        MessageSelectionG21 : 0,
        MessageSelectionD22 : '',
        MessageSelectionE22 : 0,
        MessageSelectionF22 : 0,
        MessageSelectionG22 : 0,
        MessageSelectionD23 : '',
        MessageSelectionE23 : 0,
        MessageSelectionF23 : 0,
        MessageSelectionG23 : 0,
        MessageSelectionD24 : '',
        MessageSelectionE24 : 0,
        MessageSelectionF24 : 0,
        MessageSelectionG24 : 0,
        MessageSelectionD28 : '',
        MessageSelectionE28 : 0,
        MessageSelectionF28 : 0,
        MessageSelectionG28 : 0,
        MessageSelectionH28 : 0,
        MessageSelectionD29 : '',
        MessageSelectionE29 : 0,
        MessageSelectionF29 : 0,
        MessageSelectionG29 : 0,
        MessageSelectionH29 : 0,
        MessageSelectionD30 : '',
        MessageSelectionE30 : 0,
        MessageSelectionF30 : 0,
        MessageSelectionG30 : 0,
        MessageSelectionH30 : 0,
        MessageSelectionD31 : '',
        MessageSelectionE31 : 0,
        MessageSelectionF31 : 0,
        MessageSelectionG31 : 0,
        MessageSelectionH31 : 0,
        MessageSelectionD32 : '',
        MessageSelectionE32 : 0,
        MessageSelectionF32 : 0,
        MessageSelectionG32 : 0,
        MessageSelectionH32 : 0,
        
        MessageSelectionK12 : 0,
        MessageSelectionK13 : 0,
        MessageSelectionK14 : 0,
        MessageSelectionK15 : 0,
        MessageSelectionK16 : 0,
        MessageSelectionH20 : 0,
        MessageSelectionH21 : 0,
        MessageSelectionH22 : 0,
        MessageSelectionH23 : 0,
        MessageSelectionH24 : 0,
        MessageSelectionI28 : 0,
        MessageSelectionI29 : 0,
        MessageSelectionI30 : 0,
        MessageSelectionI31 : 0,
        MessageSelectionI32 : 0,
        MessageSelectionD37 : '',
        MessageSelectionD38 : '',
        MessageSelectionD39 : '',
        MessageSelectionD40 : '',
        MessageSelectionD41 : '',
        MessageSelectionE37 : 0,
        MessageSelectionF37 : 0,
        MessageSelectionG37 : 0,
        MessageSelectionH37 : 0,
        MessageSelectionE38 : 0,
        MessageSelectionF38 : 0,
        MessageSelectionG38 : 0,
        MessageSelectionH38 : 0,
        MessageSelectionE39 : 0,
        MessageSelectionF39 : 0,
        MessageSelectionG39 : 0,
        MessageSelectionH39 : 0,
        MessageSelectionE40 : 0,
        MessageSelectionF40 : 0,
        MessageSelectionG40 : 0,
        MessageSelectionH40 : 0,
        MessageSelectionE41 : 0,
        MessageSelectionF41 : 0,
        MessageSelectionG41 : 0,
        MessageSelectionH41 : 0,
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getMessageSelection({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.growthPlanning)
            if(response!= undefined  && response.data.growthPlanning){
                let growthPlanning = response.data.growthPlanning[0];
                console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key]
                    }
                }
                newObj['MessageSelectionK12'] = sum(newObj['MessageSelectionE12'], newObj['MessageSelectionF12'],newObj['MessageSelectionG12'], newObj['MessageSelectionH12'], newObj['MessageSelectionI12'], newObj['MessageSelectionJ12']);
                newObj['MessageSelectionK13'] = sum(newObj['MessageSelectionE13'], newObj['MessageSelectionF13'],newObj['MessageSelectionG13'], newObj['MessageSelectionH13'], newObj['MessageSelectionI13'], newObj['MessageSelectionJ13']);
                newObj['MessageSelectionK14'] = sum(newObj['MessageSelectionE14'], newObj['MessageSelectionF14'],newObj['MessageSelectionG14'], newObj['MessageSelectionH14'], newObj['MessageSelectionI14'], newObj['MessageSelectionJ14']);
                newObj['MessageSelectionK15'] = sum(newObj['MessageSelectionE15'], newObj['MessageSelectionF15'],newObj['MessageSelectionG15'], newObj['MessageSelectionH15'], newObj['MessageSelectionI15'], newObj['MessageSelectionJ15']);
                newObj['MessageSelectionK16'] = sum(newObj['MessageSelectionE16'], newObj['MessageSelectionF16'],newObj['MessageSelectionG16'], newObj['MessageSelectionH16'], newObj['MessageSelectionI16'], newObj['MessageSelectionJ16']);

                newObj['MessageSelectionH20'] = sum(newObj['MessageSelectionE20'], newObj['MessageSelectionF20'],newObj['MessageSelectionG20']);
                newObj['MessageSelectionH21'] = sum(newObj['MessageSelectionE21'], newObj['MessageSelectionF21'],newObj['MessageSelectionG21']);
                newObj['MessageSelectionH22'] = sum(newObj['MessageSelectionE22'], newObj['MessageSelectionF22'],newObj['MessageSelectionG22']);
                newObj['MessageSelectionH23'] = sum(newObj['MessageSelectionE23'], newObj['MessageSelectionF23'],newObj['MessageSelectionG23']);
                newObj['MessageSelectionH24'] = sum(newObj['MessageSelectionE24'], newObj['MessageSelectionF24'],newObj['MessageSelectionG24']);

                newObj['MessageSelectionI28'] = sum(newObj['MessageSelectionE28'], newObj['MessageSelectionF28'],newObj['MessageSelectionG28'], newObj['MessageSelectionH28']);
                newObj['MessageSelectionI29'] = sum(newObj['MessageSelectionE29'], newObj['MessageSelectionF29'],newObj['MessageSelectionG29'], newObj['MessageSelectionH29']);
                newObj['MessageSelectionI30'] = sum(newObj['MessageSelectionE30'], newObj['MessageSelectionF30'],newObj['MessageSelectionG30'], newObj['MessageSelectionH30']);
                newObj['MessageSelectionI31'] = sum(newObj['MessageSelectionE31'], newObj['MessageSelectionF31'],newObj['MessageSelectionG31'], newObj['MessageSelectionH31']);
                newObj['MessageSelectionI32'] = sum(newObj['MessageSelectionE32'], newObj['MessageSelectionF32'],newObj['MessageSelectionG32'], newObj['MessageSelectionH32']);

                newObj['MessageSelectionE37'] = parseFloat(newObj['MessageSelectionK12']);
                newObj['MessageSelectionE38'] = parseFloat(newObj['MessageSelectionK13']);
                newObj['MessageSelectionE39'] = parseFloat(newObj['MessageSelectionK14']);
                newObj['MessageSelectionE40'] = parseFloat(newObj['MessageSelectionK15']);
                newObj['MessageSelectionE41'] = parseFloat(newObj['MessageSelectionK16']);

                newObj['MessageSelectionF37'] = parseFloat(newObj['MessageSelectionH20']);
                newObj['MessageSelectionF38'] = parseFloat(newObj['MessageSelectionH21']);
                newObj['MessageSelectionF39'] = parseFloat(newObj['MessageSelectionH22']);
                newObj['MessageSelectionF40'] = parseFloat(newObj['MessageSelectionH23']);
                newObj['MessageSelectionF41'] = parseFloat(newObj['MessageSelectionH24']);

                newObj['MessageSelectionG37'] = parseFloat(newObj['MessageSelectionI28']);
                newObj['MessageSelectionG38'] = parseFloat(newObj['MessageSelectionI29']);
                newObj['MessageSelectionG39'] = parseFloat(newObj['MessageSelectionI30']);
                newObj['MessageSelectionG40'] = parseFloat(newObj['MessageSelectionI31']);
                newObj['MessageSelectionG41'] = parseFloat(newObj['MessageSelectionI32']);

                newObj['MessageSelectionH37'] = sum(newObj['MessageSelectionE37'], newObj['MessageSelectionF37'],newObj['MessageSelectionG37']);
                newObj['MessageSelectionH38'] = sum(newObj['MessageSelectionE38'], newObj['MessageSelectionF38'],newObj['MessageSelectionG38']);
                newObj['MessageSelectionH39'] = sum(newObj['MessageSelectionE39'], newObj['MessageSelectionF39'],newObj['MessageSelectionG39']);
                newObj['MessageSelectionH40'] = sum(newObj['MessageSelectionE40'], newObj['MessageSelectionF40'],newObj['MessageSelectionG40']);
                newObj['MessageSelectionH41'] = sum(newObj['MessageSelectionE41'], newObj['MessageSelectionF41'],newObj['MessageSelectionG41']);
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                event.target.value = event.target.value ? event.target.value : 0
                dispatch(modifyMessageSelection({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        //window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

    useEffect(() => {
        let MessageSelectionK12 = sum(Inputs.MessageSelectionE12, Inputs.MessageSelectionF12,Inputs.MessageSelectionG12, Inputs.MessageSelectionH12, Inputs.MessageSelectionI12, Inputs.MessageSelectionJ12);
        let MessageSelectionK13 = sum(Inputs.MessageSelectionE13, Inputs.MessageSelectionF13,Inputs.MessageSelectionG13, Inputs.MessageSelectionH13, Inputs.MessageSelectionI13, Inputs.MessageSelectionJ13);
        let MessageSelectionK14 = sum(Inputs.MessageSelectionE14, Inputs.MessageSelectionF14,Inputs.MessageSelectionG14, Inputs.MessageSelectionH14, Inputs.MessageSelectionI14, Inputs.MessageSelectionJ14);
        let MessageSelectionK15 = sum(Inputs.MessageSelectionE15, Inputs.MessageSelectionF15,Inputs.MessageSelectionG15, Inputs.MessageSelectionH15, Inputs.MessageSelectionI15, Inputs.MessageSelectionJ15);
        let MessageSelectionK16 = sum(Inputs.MessageSelectionE16, Inputs.MessageSelectionF16,Inputs.MessageSelectionG16, Inputs.MessageSelectionH16, Inputs.MessageSelectionI16, Inputs.MessageSelectionJ16);

        let MessageSelectionH20 = sum(Inputs.MessageSelectionE20, Inputs.MessageSelectionF20,Inputs.MessageSelectionG20);
        let MessageSelectionH21 = sum(Inputs.MessageSelectionE21, Inputs.MessageSelectionF21,Inputs.MessageSelectionG21);
        let MessageSelectionH22 = sum(Inputs.MessageSelectionE22, Inputs.MessageSelectionF22,Inputs.MessageSelectionG22);
        let MessageSelectionH23 = sum(Inputs.MessageSelectionE23, Inputs.MessageSelectionF23,Inputs.MessageSelectionG23);
        let MessageSelectionH24 = sum(Inputs.MessageSelectionE24, Inputs.MessageSelectionF24,Inputs.MessageSelectionG24);

        let MessageSelectionI28 = sum(Inputs.MessageSelectionE28, Inputs.MessageSelectionF28,Inputs.MessageSelectionG28, Inputs.MessageSelectionH28);
        let MessageSelectionI29 = sum(Inputs.MessageSelectionE29, Inputs.MessageSelectionF29,Inputs.MessageSelectionG29, Inputs.MessageSelectionH29);
        let MessageSelectionI30 = sum(Inputs.MessageSelectionE30, Inputs.MessageSelectionF30,Inputs.MessageSelectionG30, Inputs.MessageSelectionH30);
        let MessageSelectionI31 = sum(Inputs.MessageSelectionE31, Inputs.MessageSelectionF31,Inputs.MessageSelectionG31, Inputs.MessageSelectionH31);
        let MessageSelectionI32 = sum(Inputs.MessageSelectionE32, Inputs.MessageSelectionF32,Inputs.MessageSelectionG32, Inputs.MessageSelectionH32);

        let MessageSelectionE37 = parseFloat(MessageSelectionK12);
        let MessageSelectionE38 = parseFloat(MessageSelectionK13);
        let MessageSelectionE39 = parseFloat(MessageSelectionK14);
        let MessageSelectionE40 = parseFloat(MessageSelectionK15);
        let MessageSelectionE41 = parseFloat(MessageSelectionK16);

        let MessageSelectionF37 = parseFloat(MessageSelectionH20);
        let MessageSelectionF38 = parseFloat(MessageSelectionH21);
        let MessageSelectionF39 = parseFloat(MessageSelectionH22);
        let MessageSelectionF40 = parseFloat(MessageSelectionH23);
        let MessageSelectionF41 = parseFloat(MessageSelectionH24);

        let MessageSelectionG37 = parseFloat(MessageSelectionI28);
        let MessageSelectionG38 = parseFloat(MessageSelectionI29);
        let MessageSelectionG39 = parseFloat(MessageSelectionI30);
        let MessageSelectionG40 = parseFloat(MessageSelectionI31);
        let MessageSelectionG41 = parseFloat(MessageSelectionI32);

        let MessageSelectionH37 = sum(MessageSelectionE37, MessageSelectionF37,MessageSelectionG37);
        let MessageSelectionH38 = sum(MessageSelectionE38, MessageSelectionF38,MessageSelectionG38);
        let MessageSelectionH39 = sum(MessageSelectionE39, MessageSelectionF39,MessageSelectionG39);
        let MessageSelectionH40 = sum(MessageSelectionE40, MessageSelectionF40,MessageSelectionG40);
        let MessageSelectionH41 = sum(MessageSelectionE41, MessageSelectionF41,MessageSelectionG41);

        setInputs({ ...Inputs, MessageSelectionK12, MessageSelectionK13, MessageSelectionK14, MessageSelectionK15, MessageSelectionK16, MessageSelectionH20, MessageSelectionH21, MessageSelectionH22, MessageSelectionH23, MessageSelectionH24, MessageSelectionI28, MessageSelectionI29, MessageSelectionI30, MessageSelectionI31, MessageSelectionI32, MessageSelectionE37, MessageSelectionE38, MessageSelectionE39, MessageSelectionE40, MessageSelectionE41, MessageSelectionF37, MessageSelectionF38, MessageSelectionF39, MessageSelectionF40, MessageSelectionF41, MessageSelectionG37, MessageSelectionG38, MessageSelectionG39, MessageSelectionG40, MessageSelectionG41, MessageSelectionH37, MessageSelectionH38, MessageSelectionH39, MessageSelectionH40, MessageSelectionH41});

    },[Inputs.MessageSelectionE12, Inputs.MessageSelectionF12, Inputs.MessageSelectionG12, Inputs.MessageSelectionH12, Inputs.MessageSelectionI12, Inputs.MessageSelectionJ12, Inputs.MessageSelectionE13, Inputs.MessageSelectionF13, Inputs.MessageSelectionG13, Inputs.MessageSelectionH13, Inputs.MessageSelectionI13, Inputs.MessageSelectionJ13, Inputs.MessageSelectionE14, Inputs.MessageSelectionF14, Inputs.MessageSelectionG14, Inputs.MessageSelectionH14, Inputs.MessageSelectionI14, Inputs.MessageSelectionJ14, Inputs.MessageSelectionE15, Inputs.MessageSelectionF15, Inputs.MessageSelectionG15, Inputs.MessageSelectionH15, Inputs.MessageSelectionI15, Inputs.MessageSelectionJ15, Inputs.MessageSelectionE16, Inputs.MessageSelectionF16, Inputs.MessageSelectionG16, Inputs.MessageSelectionH16, Inputs.MessageSelectionI16, Inputs.MessageSelectionJ16, Inputs.MessageSelectionE20, Inputs.MessageSelectionF20, Inputs.MessageSelectionG20, Inputs.MessageSelectionE21, Inputs.MessageSelectionF21, Inputs.MessageSelectionG21, Inputs.MessageSelectionE22, Inputs.MessageSelectionF22, Inputs.MessageSelectionG22, Inputs.MessageSelectionE23, Inputs.MessageSelectionF23, Inputs.MessageSelectionG23, Inputs.MessageSelectionE24, Inputs.MessageSelectionF24, Inputs.MessageSelectionG24, Inputs.MessageSelectionE28, Inputs.MessageSelectionF28, Inputs.MessageSelectionG28, Inputs.MessageSelectionH28, Inputs.MessageSelectionE29, Inputs.MessageSelectionF29,Inputs.MessageSelectionG29, Inputs.MessageSelectionH29, Inputs.MessageSelectionE30, Inputs.MessageSelectionF30, Inputs.MessageSelectionG30, Inputs.MessageSelectionH30, Inputs.MessageSelectionE31, Inputs.MessageSelectionF31, Inputs.MessageSelectionG31, Inputs.MessageSelectionH31, Inputs.MessageSelectionE32, Inputs.MessageSelectionF32, Inputs.MessageSelectionG32, Inputs.MessageSelectionH32])

  return (
      <div className="content-body">
       <UserDropdown />
    
        {/* row */}
       <div className="container-fluid">
            <div className="head-title">
                <p>Messaging</p>
                <h2 className="mb-5">Message Selection Matrix</h2>
            </div>
            <Spinner />
            <div className="card">
                <div className="card-body pl-0">
                <div className="table-responsive table-dta">
                    {/* table 1 */}
                    <table className="table mb-0">
                    <tbody>
                        <tr>
                        <td rowSpan={2} colSpan={5} className="bg-color1 text-font-size-16 text-center-c text-white padding-10 width-20">
                            Message:  Premise, Big Idea, Tagline, etc.
                        </td>
                        <td colSpan={7} className="bg-color3 font-size-20 text-white padding-15 text-center-c">
                            Transformative
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color1 text-center-c text-white">Startling</td>
                        <td className="bg-color1 text-center-c text-white">Emotionally Compelling</td>
                        <td className="bg-color1 text-center-c text-white">Logically Compelling</td>
                        <td className="bg-color1 text-center-c text-white">Credibility</td>
                        <td className="bg-color1 text-center-c text-white">Proof</td>
                        <td className="bg-color1 text-center-c text-white">Urgency</td>
                        <td className="bg-color3 text-center-c text-white">Score</td>
                        </tr>
                        <tr>
                        <td className="bg-color3 text-white text-center-c height30 width-5">1</td>
                        <td className="width-35 padding-0 height30" colSpan={4}>
                            <form className="Focused-planning-f height30">
                                <input type="text" className="fucus-pla"
                                    id="MessageSelectionD12"
                                    value={Inputs.MessageSelectionD12}
                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionD12: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                    placeholder="Message 1" />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c height30">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionE12" 
                                value={Inputs.MessageSelectionE12} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionE12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                            }} />
                        </td>
                        <td className="bg-color2 text-center-c height30">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionF12" 
                                value={Inputs.MessageSelectionF12} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionF12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                            }} />
                        </td>
                        <td className="bg-color2 text-center-c height30">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionG12" 
                                value={Inputs.MessageSelectionG12} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionG12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                            }} />
                        </td>
                        <td className="bg-color2 text-center-c height30">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionH12" 
                                value={Inputs.MessageSelectionH12} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionH12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                            }} />
                        </td>
                        <td className="bg-color2 text-center-c height30">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionI12" 
                                value={Inputs.MessageSelectionI12} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionI12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                            }} />
                        </td>
                        <td className="bg-color2 text-center-c height30">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionJ12" 
                                value={Inputs.MessageSelectionJ12} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionJ12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                            }} />
                        </td>
                        <td className="bg-color3 text-center-c height30 text-white">
                            {Number(Inputs.MessageSelectionK12).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color3 text-white height30 text-center-c width-5">2</td>
                        <td className="width-35 height30 padding-0" colSpan={4}>
                            <form className="Focused-planning-f height30">
                                <input type="text" className="fucus-pla"
                                    id="MessageSelectionD13"
                                    value={Inputs.MessageSelectionD13}
                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionD13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                    placeholder="Message 2" />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c height30">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionE13" 
                                value={Inputs.MessageSelectionE13} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionE13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                            }} />
                        </td>
                        <td className="bg-color2 text-center-c height30">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionF13" 
                                value={Inputs.MessageSelectionF13} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionF13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                            }} />
                        </td>
                        <td className="bg-color2 text-center-c height30">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionG13" 
                                value={Inputs.MessageSelectionG13} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionG13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                            }} />
                        </td>
                        <td className="bg-color2 text-center-c height30">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionH13" 
                                value={Inputs.MessageSelectionH13} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionH13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                            }} />
                        </td>
                        <td className="bg-color2 text-center-c height30">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionI13" 
                                value={Inputs.MessageSelectionI13} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionI13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                            }} />
                        </td>
                        <td className="bg-color2 text-center-c height30">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionJ13" 
                                value={Inputs.MessageSelectionJ13} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionJ13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                            }} />
                        </td>
                        <td className="bg-color3 text-center-c text-white height30">
                            {Number(Inputs.MessageSelectionK13).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color3 height30 text-white text-center-c width-5">3</td>
                        <td className="width-35 height30 padding-0" colSpan={4}>
                            <form className="Focused-planning-f height30">
                                <input type="text" className="fucus-pla"
                                    id="MessageSelectionD14"
                                    value={Inputs.MessageSelectionD14}
                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionD14: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                    placeholder="Message 3" />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c height30">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionE14" 
                                value={Inputs.MessageSelectionE14} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionE14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                            }} />
                        </td>
                        <td className="bg-color2 text-center-c height30">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionF14" 
                                value={Inputs.MessageSelectionF14} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionF14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                            }} />
                        </td>
                        <td className="bg-color2 text-center-c height30">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionG14" 
                                value={Inputs.MessageSelectionG14} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionG14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                            }} />
                        </td>
                        <td className="bg-color2 text-center-c height30">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionH14" 
                                value={Inputs.MessageSelectionH14} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionH14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                            }} />
                        </td>
                        <td className="bg-color2 text-center-c height30">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionI14" 
                                value={Inputs.MessageSelectionI14} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionI14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                            }} />
                        </td>
                        <td className="bg-color2 text-center-c height30">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionJ14" 
                                value={Inputs.MessageSelectionJ14} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionJ14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                            }} />
                        </td>
                        <td className="bg-color3 text-center-c height30 text-white">
                            {Number(Inputs.MessageSelectionK14).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color3 height30 text-white text-center-c width-5">4</td>
                        <td className="width-35 height30 padding-0" colSpan={4}>
                            <form className="Focused-planning-f height30">
                                <input type="text" className="fucus-pla"
                                    id="MessageSelectionD15"
                                    value={Inputs.MessageSelectionD15}
                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionD15: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                    placeholder="Message 4" />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c height30">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionE15" 
                                value={Inputs.MessageSelectionE15} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionE15: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                            }} />
                        </td>
                        <td className="bg-color2 text-center-c height30">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionF15" 
                                value={Inputs.MessageSelectionF15} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionF15: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                            }} />
                        </td>
                        <td className="bg-color2 text-center-c height30">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionG15" 
                                value={Inputs.MessageSelectionG15} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionG15: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                            }} />
                        </td>
                        <td className="bg-color2 text-center-c height30">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionH15" 
                                value={Inputs.MessageSelectionH15} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionH15: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                            }} />
                        </td>
                        <td className="bg-color2 text-center-c height30">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionI15" 
                                value={Inputs.MessageSelectionI15} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionI15: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                            }} />
                        </td>
                        <td className="bg-color2 text-center-c height30">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionJ15" 
                                value={Inputs.MessageSelectionJ15} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionJ15: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                            }} />
                        </td>
                        <td className="bg-color3 text-center-c height30 text-white">
                            {Number(Inputs.MessageSelectionK15).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color3 height30 text-white text-center-c width-5">5</td>
                        <td className="width-35 height30 padding-0" colSpan={4}>
                            <form className="Focused-planning-f height30">
                                <input type="text" className="fucus-pla"
                                    id="MessageSelectionD16"
                                    value={Inputs.MessageSelectionD16}
                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionD16: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                    placeholder="Message 5" />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c height30">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionE16" 
                                value={Inputs.MessageSelectionE16} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionE16: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                            }} />
                        </td>
                        <td className="bg-color2 text-center-c height30">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionF16" 
                                value={Inputs.MessageSelectionF16} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionF16: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                            }} />
                        </td>
                        <td className="bg-color2 text-center-c height30">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionG16" 
                                value={Inputs.MessageSelectionG16} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionG16: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                            }} />
                        </td>
                        <td className="bg-color2 text-center-c height30">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionH16" 
                                value={Inputs.MessageSelectionH16} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionH16: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                            }} />
                        </td>
                        <td className="bg-color2 text-center-c height30">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionI16" 
                                value={Inputs.MessageSelectionI16} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionI16: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                            }} />
                        </td>
                        <td className="bg-color2 text-center-c height30">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionJ16" 
                                value={Inputs.MessageSelectionJ16} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionJ16: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                            }} />
                        </td>
                        <td className="bg-color3 text-center-c height30 text-white">
                            {Number(Inputs.MessageSelectionK16).toLocaleString('en-US')}
                        </td>
                        </tr>
                    </tbody>
                    </table>
                    {/* table 2 */}
                    <table className="table mb-0 mt-5">
                    <tbody>
                        <tr>
                        <td rowSpan={2} colSpan={5} className="bg-color1 text-font-size-16 text-center-c text-white padding-10 width-20">
                            Message:  Premise, Big Idea, Tagline, etc.
                        </td>
                        <td colSpan={7} className="bg-color3 font-size-20 text-white padding-15 text-center-c">
                            Unique
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color1 text-center-c text-white">Unique Offer</td>
                        <td className="bg-color1 text-center-c text-white">Unique Mechanism</td>
                        <td className="bg-color1 text-center-c text-white">Outpositions Competitors</td>
                        <td className="bg-color3 text-center-c text-white">Score</td>
                        </tr>
                        <tr>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c width-5">1</td>
                        <td className="width-35 text-font-size-15 height30" colSpan={4}>
                            <input type="text" className="inputText form-control"
                                id="MessageSelectionD20"
                                value={Inputs.MessageSelectionD20}
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionD20: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Message 1" />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionE20" 
                                value={Inputs.MessageSelectionE20} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionE20: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionF20" 
                                value={Inputs.MessageSelectionF20} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionF20: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionG20" 
                                value={Inputs.MessageSelectionG20} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionG20: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color3 text-center-c height30 text-white">
                            {Number(Inputs.MessageSelectionH20).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c width-5">2</td>
                        <td className="width-35 text-font-size-15 height30" colSpan={4}>
                            <input type="text" className="inputText form-control"
                                id="MessageSelectionD21"
                                value={Inputs.MessageSelectionD21}
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionD21: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Message 2" />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionE21" 
                                value={Inputs.MessageSelectionE21} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionE21: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionF21" 
                                value={Inputs.MessageSelectionF21} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionF21: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionG21" 
                                value={Inputs.MessageSelectionG21} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionG21: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color3 text-center-c height30 text-white">
                            {Number(Inputs.MessageSelectionH21).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c width-5">3</td>
                        <td className="width-35 text-font-size-15 height30" colSpan={4}>
                            <input type="text" className="inputText form-control"
                                id="MessageSelectionD22"
                                value={Inputs.MessageSelectionD22}
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionD22: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Message 3" />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionE22" 
                                value={Inputs.MessageSelectionE22} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionE22: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionF22" 
                                value={Inputs.MessageSelectionF22} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionF22: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionG22" 
                                value={Inputs.MessageSelectionG22} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionG22: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color3 text-center-c height30 text-white">
                            {Number(Inputs.MessageSelectionH22).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c width-5">4</td>
                        <td className="width-35 text-font-size-15 height30" colSpan={4}>
                            <input type="text" className="inputText form-control"
                                id="MessageSelectionD23"
                                value={Inputs.MessageSelectionD23}
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionD23: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Message 4" />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionE23" 
                                value={Inputs.MessageSelectionE23} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionE23: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionF23" 
                                value={Inputs.MessageSelectionF23} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionF23: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionG23" 
                                value={Inputs.MessageSelectionG23} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionG23: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color3 text-center-c height30 text-white">
                            {Number(Inputs.MessageSelectionH23).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c width-5">5</td>
                        <td className="width-35 text-font-size-15 height30" colSpan={4}>
                            <input type="text" className="inputText form-control"
                                id="MessageSelectionD24"
                                value={Inputs.MessageSelectionD24}
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionD24: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Message 5" />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionE24" 
                                value={Inputs.MessageSelectionE24} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionE24: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionF24" 
                                value={Inputs.MessageSelectionF24} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionF24: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionG24" 
                                value={Inputs.MessageSelectionG24} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionG24: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color3 text-center-c height30 text-white">
                            {Number(Inputs.MessageSelectionH24).toLocaleString('en-US')}
                        </td>
                        </tr>
                    </tbody>
                    </table>
                    {/* table 3 */}
                    <table className="table mb-0 mt-5">
                    <tbody>
                        <tr>
                        <td rowSpan={2} colSpan={5} className="bg-color1 text-font-size-16 text-center-c text-white padding-10 width-20">
                            Message:  Premise, Big Idea, Tagline, etc.
                        </td>
                        <td colSpan={7} className="bg-color3 font-size-20 text-white padding-15 text-center-c">
                            Simple
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color1 text-center-c text-white">Bold</td>
                        <td className="bg-color1 text-center-c text-white">Metaphor</td>
                        <td className="bg-color1 text-center-c text-white">Irresistible Offer</td>
                        <td className="bg-color1 text-center-c text-white">Brevity</td>
                        <td className="bg-color3 text-center-c text-white">Score</td>
                        </tr>
                        <tr>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c width-5">1</td>
                        <td className="width-35 text-font-size-15 height30" colSpan={4}>
                            <input type="text" className="inputText form-control"
                                id="MessageSelectionD28"
                                value={Inputs.MessageSelectionD28}
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionD28: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Message 1" />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionE28" 
                                value={Inputs.MessageSelectionE28} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionE28: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionF28" 
                                value={Inputs.MessageSelectionF28} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionF28: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionG28" 
                                value={Inputs.MessageSelectionG28} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionG28: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionH28" 
                                value={Inputs.MessageSelectionH28} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionH28: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color3 text-center-c height30 text-white">
                            {Number(Inputs.MessageSelectionI28).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c width-5">2</td>
                        <td className="width-35 text-font-size-15 height30" colSpan={4}>
                            <input type="text" className="inputText form-control"
                                id="MessageSelectionD29"
                                value={Inputs.MessageSelectionD29}
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionD29: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Message 2" />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionE29" 
                                value={Inputs.MessageSelectionE29} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionE29: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionF29" 
                                value={Inputs.MessageSelectionF29} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionF29: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionG29" 
                                value={Inputs.MessageSelectionG29} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionG29: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionH29" 
                                value={Inputs.MessageSelectionH29} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionH29: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color3 text-center-c height30 text-white">
                            {Number(Inputs.MessageSelectionI29).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c width-5">3</td>
                        <td className="width-35 text-font-size-15 height30" colSpan={4}>
                            <input type="text" className="inputText form-control"
                                id="MessageSelectionD30"
                                value={Inputs.MessageSelectionD30}
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionD30: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Message 3" />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionE30" 
                                value={Inputs.MessageSelectionE30} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionE30: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionF30" 
                                value={Inputs.MessageSelectionF30} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionF30: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionG30" 
                                value={Inputs.MessageSelectionG30} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionG30: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionH30" 
                                value={Inputs.MessageSelectionH30} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionH30: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color3 text-center-c height30 text-white">
                            {Number(Inputs.MessageSelectionI30).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c width-5">4</td>
                        <td className="width-35 text-font-size-15 height30" colSpan={4}>
                            <input type="text" className="inputText form-control"
                                id="MessageSelectionD31"
                                value={Inputs.MessageSelectionD31}
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionD31: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Message 4" />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionE31" 
                                value={Inputs.MessageSelectionE31} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionE31: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionF31" 
                                value={Inputs.MessageSelectionF31} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionF31: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionG31" 
                                value={Inputs.MessageSelectionG31} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionG31: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionH31" 
                                value={Inputs.MessageSelectionH31} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionH31: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color3 text-center-c height30 text-white">
                            {Number(Inputs.MessageSelectionI31).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c width-5">5</td>
                        <td className="width-35 text-font-size-15 height30" colSpan={4}>
                            <input type="text" className="inputText form-control"
                                id="MessageSelectionD32"
                                value={Inputs.MessageSelectionD32}
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionD32: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Message 5" />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionE32" 
                                value={Inputs.MessageSelectionE32} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionE32: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionF32" 
                                value={Inputs.MessageSelectionF32} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionF32: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionG32" 
                                value={Inputs.MessageSelectionG32} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionG32: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            <input type="text" className="form-control inputText" 
                                id="MessageSelectionH32" 
                                value={Inputs.MessageSelectionH32} 
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionH32: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color3 text-center-c height30 text-white">
                            {Number(Inputs.MessageSelectionI32).toLocaleString('en-US')}
                        </td>
                        </tr>
                    </tbody>
                    </table>
                    {/* table 4 */}
                    <table className="table mb-0 mt-5">
                    <tbody>
                        <tr>
                        <td rowSpan={2} colSpan={5} className="bg-color1 text-font-size-16 text-center-c text-white padding-10 width-20">
                            Message:  Premise, Big Idea, Tagline, etc.
                        </td>
                        <td colSpan={7} className="bg-color3 font-size-20 text-white padding-15 text-center-c">
                            Message Impact Score
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color1 text-center-c text-white">Transformative</td>
                        <td className="bg-color1 text-center-c text-white">Unique</td>
                        <td className="bg-color1 text-center-c text-white">Simple</td>
                        <td className="bg-color3 text-center-c text-white">Total Score</td>
                        </tr>
                        <tr>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c width-5">1</td>
                        <td className="width-35 text-font-size-15 height30" colSpan={4}>
                            <input type="text" className="inputText form-control"
                                id="MessageSelectionD37"
                                value={Inputs.MessageSelectionD37}
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionD37: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Message 1" />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            {Number(Inputs.MessageSelectionE37).toLocaleString('en-US')}
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            {Number(Inputs.MessageSelectionF37).toLocaleString('en-US')}
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            {Number(Inputs.MessageSelectionG37).toLocaleString('en-US')}
                        </td>
                        <td className="bg-color3 text-center-c height30 text-white">
                            {Number(Inputs.MessageSelectionH37).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c width-5">2</td>
                        <td className="width-35 text-font-size-15 height30" colSpan={4}>
                            <input type="text" className="inputText form-control"
                                id="MessageSelectionD38"
                                value={Inputs.MessageSelectionD38}
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionD38: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Message 2" />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            {Number(Inputs.MessageSelectionE38).toLocaleString('en-US')}
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            {Number(Inputs.MessageSelectionF38).toLocaleString('en-US')}
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            {Number(Inputs.MessageSelectionG38).toLocaleString('en-US')}
                        </td>
                        <td className="bg-color3 text-center-c height30 text-white">
                            {Number(Inputs.MessageSelectionH38).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c width-5">3</td>
                        <td className="width-35 text-font-size-15 height30" colSpan={4}>
                            <input type="text" className="inputText form-control"
                                id="MessageSelectionD39"
                                value={Inputs.MessageSelectionD39}
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionD39: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Message 3" />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            {Number(Inputs.MessageSelectionE39).toLocaleString('en-US')}
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            {Number(Inputs.MessageSelectionF39).toLocaleString('en-US')}
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            {Number(Inputs.MessageSelectionG39).toLocaleString('en-US')}
                        </td>
                        <td className="bg-color3 text-center-c height30 text-white">
                            {Number(Inputs.MessageSelectionH39).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c width-5">4</td>
                        <td className="width-35 text-font-size-15 height30" colSpan={4}>
                            <input type="text" className="inputText form-control"
                                id="MessageSelectionD40"
                                value={Inputs.MessageSelectionD40}
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionD40: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Message 4" />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            {Number(Inputs.MessageSelectionE40).toLocaleString('en-US')}
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            {Number(Inputs.MessageSelectionF40).toLocaleString('en-US')}
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            {Number(Inputs.MessageSelectionG40).toLocaleString('en-US')}
                        </td>
                        <td className="bg-color3 text-center-c height30 text-white">
                            {Number(Inputs.MessageSelectionH40).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c width-5">5</td>
                        <td className="width-35 text-font-size-15 height30" colSpan={4}>
                            <input type="text" className="inputText form-control"
                                id="MessageSelectionD41"
                                value={Inputs.MessageSelectionD41}
                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionD41: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Message 5" />
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            {Number(Inputs.MessageSelectionE41).toLocaleString('en-US')}
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            {Number(Inputs.MessageSelectionF41).toLocaleString('en-US')}
                        </td>
                        <td className="bg-color2 text-font-size-15 height30 text-center-c ">
                            {Number(Inputs.MessageSelectionG41).toLocaleString('en-US')}
                        </td>
                        <td className="bg-color3 text-center-c height30 text-white">
                            {Number(Inputs.MessageSelectionH41).toLocaleString('en-US')}
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}
