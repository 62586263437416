import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state/index';
import { MultipleLineChartGraph } from "../graph/MultipleLineChartGraph";
import  UserDropdown  from '../UserDropdown';

import { getImpact120GrowthROI, modifyImpact120GrowthROI } from "../../state/features/ClientGrowthSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';

export default function Impact120GrowthROIContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;

    const clientInputs = useSelector(state => state.clientInputs)
    const impactof120GrowthOS = useSelector(state => state.impactof120GrowthOS)
    const impact120GrowthROI = useSelector(state => state.impact120GrowthROI)
    const dispatch = useDispatch();
    const { impact120GrowthROIData } = bindActionCreators(actionCreators, dispatch)
 
    const [Inputs, setInputs] = useState({
        impact120GrowthROID21: 0,
        impact120GrowthROIE21: 0,
        impact120GrowthROIF21: 0,
        impact120GrowthROIG21: 0,
        impact120GrowthROIH21: 0,
        impact120GrowthROII21: 0,
        impact120GrowthROIJ21: 0,
        impact120GrowthROIK21: 0,
        impact120GrowthROIL21: 0,
        impact120GrowthROIM21: 0,
        impact120GrowthROIN21: 0,
        impact120GrowthROIO21: 0,
        impact120GrowthROIP21: 0,
    });

    const {clientInputsH41} = clientInputs

    useEffect(() => {
        impact120GrowthROIData("impactof120GrowthROIAll", {
            clientInputsH41,
            impactof120GrowthOS,
            impact120GrowthROID21 : Inputs.impact120GrowthROID21,
            impact120GrowthROIE21 : Inputs.impact120GrowthROIE21,
            impact120GrowthROIF21 : Inputs.impact120GrowthROIF21,
            impact120GrowthROIG21 : Inputs.impact120GrowthROIG21,
            impact120GrowthROIH21 : Inputs.impact120GrowthROIH21,
            impact120GrowthROII21 : Inputs.impact120GrowthROII21,
            impact120GrowthROIJ21 : Inputs.impact120GrowthROIJ21,
            impact120GrowthROIK21 : Inputs.impact120GrowthROIK21,
            impact120GrowthROIL21 : Inputs.impact120GrowthROIL21,
            impact120GrowthROIM21 : Inputs.impact120GrowthROIM21,
            impact120GrowthROIN21 : Inputs.impact120GrowthROIN21,
            impact120GrowthROIO21 : Inputs.impact120GrowthROIO21,
            impact120GrowthROIP21 : Inputs.impact120GrowthROIP21,
        });
    }, [Inputs.impact120GrowthROID21,
        Inputs.impact120GrowthROIE21,
        Inputs.impact120GrowthROIF21,
        Inputs.impact120GrowthROIG21,
        Inputs.impact120GrowthROIH21,
        Inputs.impact120GrowthROII21,
        Inputs.impact120GrowthROIJ21,
        Inputs.impact120GrowthROIK21,
        Inputs.impact120GrowthROIL21,
        Inputs.impact120GrowthROIM21,
        Inputs.impact120GrowthROIN21,
        Inputs.impact120GrowthROIO21,
        Inputs.impact120GrowthROIP21]);

    const [Cache, setCache] = useState(Inputs);

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getImpact120GrowthROI({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.clientGrowth)
            if(response!= undefined && response.data.clientGrowth[0]){
                let clientGrowth = response.data.clientGrowth[0];
                console.log(clientGrowth)
                delete clientGrowth.id;
                delete clientGrowth.user_id;
                delete clientGrowth.created_at;
                delete clientGrowth.updated_at;
                const newObj = { ...Inputs }
                for (const key in clientGrowth) {
                    if (clientGrowth.hasOwnProperty(key)) {
                        if(clientGrowth[key])
                        newObj[key] = clientGrowth[key]
                    }
                }
                console.log('newObj',newObj)
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

    }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value.replace(/,/g, '')){
                dispatch(modifyImpact120GrowthROI({ user_id, [event.target.id] : event.target.value.replace(/,/g, '')}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        //window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value.replace(/,/g, '')});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

    const { impact120GrowthROID22, impact120GrowthROIE22, impact120GrowthROID23, impact120GrowthROIE23, impact120GrowthROID24, impact120GrowthROIE24, impact120GrowthROIF22, impact120GrowthROIG22, impact120GrowthROIH22, impact120GrowthROII22, impact120GrowthROIJ22, impact120GrowthROIK22, impact120GrowthROIL22, impact120GrowthROIM22, impact120GrowthROIN22, impact120GrowthROIO22, impact120GrowthROIP22, impact120GrowthROIF23, impact120GrowthROIG23, impact120GrowthROIH23, impact120GrowthROII23, impact120GrowthROIJ23, impact120GrowthROIK23, impact120GrowthROIL23, impact120GrowthROIM23, impact120GrowthROIN23, impact120GrowthROIO23, impact120GrowthROIP23, impact120GrowthROIF24, impact120GrowthROIG24, impact120GrowthROIH24, impact120GrowthROII24, impact120GrowthROIJ24, impact120GrowthROIK24, impact120GrowthROIL24, impact120GrowthROIM24, impact120GrowthROIN24,  impact120GrowthROIO24, impact120GrowthROIP24, impact120GrowthROID16, impact120GrowthROIE16, impact120GrowthROIF16, impact120GrowthROIG16, impact120GrowthROID17, impact120GrowthROIE17, impact120GrowthROIF17, impact120GrowthROIG17, } = impact120GrowthROI

    return (
        <div className="content-body">
    <UserDropdown />
    
            {/* row */}
            <div className="container-fluid">
                <div className="head-title">
                    <p className="mb-0">Your Growth Potential</p>
                    <h2 className="mb-4 mt-0">120 Growth - Return On Investment</h2>
                </div>
                <div className="row">
                    <div className="col-xl-6 contentleft">
                        <div className="card">
                            <div className="card-body contentleft">
                                <p>The 120 Growth Catalyst™ can quickly delivery dramatic ROI for you business.  Regardless if we work together on implementation or not, you will gain new skills, tools, and strategies to grow your business quickly and confidently into the future. </p>
                                <p>If you would like to continue working with 120 Growth to start the implementation process, or even continuously as you grow your business, the return is always far more profitable than the expense. </p>
                                <p>And remember, this is based on just a few, consistent 1% improvements.  Many companies grow much faster.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 contentleft">
                        <div className="textdiv">
                            <div className="card">
                                <div className="card-body">
                                    <MultipleLineChartGraph dataInput={{
                                        "Title"      : "Financial Impact vs Investment (quarterly)",
                                        "vTitle"     : "",
                                        "hTitle"     : "",
                                        "subTitle"   : "",
                                        "ColorCodes" : ["#efbc44", "#447fef", "#0e8262", "#aa28d9"],
                                        "GraphData"  : [
                                            ["", "Valuation Impact", "Sales Growth", "Profit Growth", "Investment"],
                                            ["W1", impact120GrowthROID24, Number(impact120GrowthROID22), Number(impact120GrowthROID23), Number(Inputs.impact120GrowthROID21)],
                                            ["Q1", Number(impact120GrowthROIE24), Number(impact120GrowthROIE22), Number(impact120GrowthROIE23), Number(Inputs.impact120GrowthROIE21)],
                                            ["Q2", Number(impact120GrowthROIF24), Number(impact120GrowthROIF22), Number(impact120GrowthROIF23), Number(Inputs.impact120GrowthROIF21)],
                                            ["Q3", Number(impact120GrowthROIG24), Number(impact120GrowthROIG22), Number(impact120GrowthROIG23), Number(Inputs.impact120GrowthROIG21)],
                                            ["Q4", Number(impact120GrowthROIH24), Number(impact120GrowthROIH22), Number(impact120GrowthROIH23), Number(Inputs.impact120GrowthROIH21)],
                                            ["Q5", Number(impact120GrowthROII24), Number(impact120GrowthROII22), Number(impact120GrowthROII23), Number(Inputs.impact120GrowthROII21)],
                                            ["Q6", Number(impact120GrowthROIJ24), Number(impact120GrowthROIJ22), Number(impact120GrowthROIJ23), Number(Inputs.impact120GrowthROIJ21)],
                                            ["Q7", Number(impact120GrowthROIK24), Number(impact120GrowthROIK22), Number(impact120GrowthROIK23), Number(Inputs.impact120GrowthROIK21)],
                                            ["Q8", Number(impact120GrowthROIL24), Number(impact120GrowthROIL22), Number(impact120GrowthROIL23), Number(Inputs.impact120GrowthROIL21)],
                                            ["Q9", Number(impact120GrowthROIM24), Number(impact120GrowthROIM22), Number(impact120GrowthROIM23), Number(Inputs.impact120GrowthROIM21)],
                                            ["Q10", Number(impact120GrowthROIN24), Number(impact120GrowthROIN22), Number(impact120GrowthROIN23), Number(Inputs.impact120GrowthROIN21)],
                                            ["Q11", Number(impact120GrowthROIO24), Number(impact120GrowthROIO22), Number(impact120GrowthROIO23), Number(Inputs.impact120GrowthROIO21)],
                                            ["Q12", Number(impact120GrowthROIP24), Number(impact120GrowthROIP22), Number(impact120GrowthROIP23), Number(Inputs.impact120GrowthROIP21)],
                                        ]
                                    }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body pl-0 pr-0">
                        {/* table 1 */}
                        <div className="table-responsive lefttab1 mb-0">
                            <table className="table table-striped table-responsive-md mb-0">
                                <thead>
                                    <tr>
                                        <th scope="row" className="bg-color1 text-white text-center-c"></th>
                                        <th scope="row" className="bg-color1 text-white text-center-c">90-Day ROI</th>
                                        <th scope="row" className="bg-color1 text-white text-center-c">Year 1 ROI</th>
                                        <th scope="row" className="bg-color1 text-white text-center-c">Year 2 ROI</th>
                                        <th scope="row" className="bg-color1 text-white text-center-c">Year 3 ROI</th>
                                    </tr>
                                </thead> 
                                <tbody>
                                    <tr className="thead-primary">
                                        <td className="bg-color1 text-white">Revenue</td>
                                        <td className="color-g text-center-c text-white">{Number(impact120GrowthROID16).toLocaleString('en-US')}%</td>
                                        <td className="color-g text-center-c text-white">{Number(impact120GrowthROIE16).toLocaleString('en-US')}%</td>
                                        <td className="color-g text-center-c text-white">{Number(impact120GrowthROIF16).toLocaleString('en-US')}%</td>
                                        <td className="color-g text-center-c text-white">{Number(impact120GrowthROIG16).toLocaleString('en-US')}%</td>
                                    </tr>
                                    <tr className="thead-primary">
                                        <td className="bg-color1 text-white">Profit</td>
                                        <td className="color-n text-center-c text-white">{Number(impact120GrowthROID17).toLocaleString('en-US')}%</td>
                                        <td className="color-n text-center-c text-white">{Number(impact120GrowthROIE17).toLocaleString('en-US')}%</td>
                                        <td className="color-n text-center-c text-white">{Number(impact120GrowthROIF17).toLocaleString('en-US')}%</td>
                                        <td className="color-n text-center-c text-white">{Number(impact120GrowthROIG17).toLocaleString('en-US')}%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <Spinner />
                        {/* table 2 */}
                        <div className="table-responsive lefttab1 mb-0">
                            <table className="table table-responsive-md mb-0 mt-5">
                                <thead>
                                    <tr>
                                        <th scope="row" className="bg-color3 text-white text-center-c" style={{ fontSize: '1.1em', padding: '5px 30px' }}>120 Growth <br /> Programs</th>
                                        <th scope="row" className="bg-color3 text-white text-center-c" style={{ fontSize: '1.1em' }}>120 Growth <br /> Catalyst</th>
                                        <th scope="row" className="bg-color3 text-white text-center-c" style={{ fontSize: '1.1em' }}>Jumpstart <br /> Program</th>
                                        <th scope="row" colSpan={11} className="bg-color3 text-white text-center-c" style={{ fontSize: '1.1em' }}>
                                            <div className="Growth-Assurance">Growth Assurance</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="thead-primary">
                                        <th scope="row" className="bg-color1 text-white text-white">Period</th>
                                        <td className="bg-color1 text-white text-center-c">W1</td>
                                        <td className="bg-color1 text-white text-center-c">Q1</td>
                                        <td className="bg-color1 text-white text-center-c">Q2</td>
                                        <td className="bg-color1 text-white text-center-c">Q3</td>
                                        <td className="bg-color1 text-white text-center-c">Q4</td>
                                        <td className="bg-color1 text-white text-center-c">Q5</td>
                                        <td className="bg-color1 text-white text-center-c">Q6</td>
                                        <td className="bg-color1 text-white text-center-c">Q7</td>
                                        <td className="bg-color1 text-white text-center-c">Q8</td>
                                        <td className="bg-color1 text-white text-center-c">Q9</td>
                                        <td className="bg-color1 text-white text-center-c">Q10</td>
                                        <td className="bg-color1 text-white text-center-c">Q11</td>
                                        <td className="bg-color1 text-white text-center-c">Q12</td>
                                    </tr>
                                    <tr className="thead-primary">
                                        <td className="bg-color2">Investment</td>
                                        <td className="bg-color2 text-center-c">
                                            <div className="inputbages">
                                            <span className="dollors">$</span>
                                            <input
                                                type="text"
                                                name="Inputs.averageTransactionValueE26"
                                                className="form-control"
                                                placeholder={0}
                                                id="impact120GrowthROID21"
                                                value={Inputs.impact120GrowthROID21.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                onChange={(event) => {
                                                    setInputs({ ...Inputs, impact120GrowthROID21: event.target.value.replace(/,/g, '') });
                                                    impact120GrowthROIData("impact120GrowthROID21", event.target.value.replace(/,/g, ''))
                                                }}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                disabled={isLoading}
                                            />
                                            </div>
                                        </td>
                                        <td className="bg-color2 text-center-c">
                                            <div className="inputbages">
                                            <span className="dollors">$</span>
                                            <input
                                                type="text"
                                                name="Inputs.averageTransactionValueE26"
                                                className="form-control"
                                                placeholder={0}
                                                id="impact120GrowthROIE21"
                                                value={Inputs.impact120GrowthROIE21.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                onChange={(event) => {
                                                    setInputs({ ...Inputs, impact120GrowthROIE21: event.target.value.replace(/,/g, '') });
                                                    impact120GrowthROIData("impact120GrowthROIE21", event.target.value.replace(/,/g, ''))
                                                }}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                disabled={isLoading}
                                            />
                                            </div>
                                        </td>
                                        <td className="bg-color2 text-center-c">
                                            <div className="inputbages">
                                            <span className="dollors">$</span>
                                            <input
                                                type="text"
                                                name="Inputs.averageTransactionValueE26"
                                                className="form-control"
                                                placeholder={0}
                                                id="impact120GrowthROIF21"
                                                value={Inputs.impact120GrowthROIF21.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                onChange={(event) => {
                                                    setInputs({ ...Inputs, impact120GrowthROIF21: event.target.value.replace(/,/g, '') });
                                                    impact120GrowthROIData("impact120GrowthROIF21", event.target.value.replace(/,/g, ''))
                                                }}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                disabled={isLoading}
                                            />
                                            </div>
                                        </td>
                                        <td className="bg-color2 text-center-c">
                                            <div className="inputbages">
                                            <span className="dollors">$</span>
                                            <input
                                                type="text"
                                                name="Inputs.averageTransactionValueE26"
                                                className="form-control"
                                                placeholder={0}
                                                id="impact120GrowthROIG21"
                                                value={Inputs.impact120GrowthROIG21.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                onChange={(event) => {
                                                    setInputs({ ...Inputs, impact120GrowthROIG21: event.target.value.replace(/,/g, '') });
                                                    impact120GrowthROIData("impact120GrowthROIG21", event.target.value.replace(/,/g, ''))
                                                }}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                disabled={isLoading}
                                            />
                                            </div>
                                        </td>
                                        <td className="bg-color2 text-center-c">
                                            <div className="inputbages">
                                            <span className="dollors">$</span>
                                            <input
                                                type="text"
                                                name="Inputs.averageTransactionValueE26"
                                                className="form-control"
                                                placeholder={0}
                                                id="impact120GrowthROIH21"
                                                value={Inputs.impact120GrowthROIH21.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                onChange={(event) => {
                                                    setInputs({ ...Inputs, impact120GrowthROIH21: event.target.value.replace(/,/g, '') });
                                                    impact120GrowthROIData("impact120GrowthROIH21", event.target.value.replace(/,/g, ''))
                                                }}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                disabled={isLoading}
                                            />
                                            </div>
                                        </td>
                                        <td className="bg-color2 text-center-c">
                                            <div className="inputbages">
                                            <span className="dollors">$</span>
                                            <input
                                                type="text"
                                                name="Inputs.averageTransactionValueE26"
                                                className="form-control"
                                                placeholder={0}
                                                id="impact120GrowthROII21"
                                                value={Inputs.impact120GrowthROII21.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                onChange={(event) => {
                                                    setInputs({ ...Inputs, impact120GrowthROII21: event.target.value.replace(/,/g, '') });
                                                    impact120GrowthROIData("impact120GrowthROII21", event.target.value.replace(/,/g, ''))
                                                }}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                disabled={isLoading}
                                            />
                                            </div>
                                        </td>
                                        <td className="bg-color2 text-center-c">
                                            <div className="inputbages">
                                            <span className="dollors">$</span>
                                            <input
                                                type="text"
                                                name="Inputs.averageTransactionValueE26"
                                                className="form-control"
                                                placeholder={0}
                                                id="impact120GrowthROIJ21"
                                                value={Inputs.impact120GrowthROIJ21.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                onChange={(event) => {
                                                    setInputs({ ...Inputs, impact120GrowthROIJ21: event.target.value.replace(/,/g, '') });
                                                    impact120GrowthROIData("impact120GrowthROIJ21", event.target.value.replace(/,/g, ''))
                                                }}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                disabled={isLoading}
                                            />
                                            </div>
                                        </td>
                                        <td className="bg-color2 text-center-c">
                                            <div className="inputbages">
                                            <span className="dollors">$</span>
                                            <input
                                                type="text"
                                                name="Inputs.averageTransactionValueE26"
                                                className="form-control"
                                                placeholder={0}
                                                id="impact120GrowthROIK21"
                                                value={Inputs.impact120GrowthROIK21.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                onChange={(event) => {
                                                    setInputs({ ...Inputs, impact120GrowthROIK21: event.target.value.replace(/,/g, '') });
                                                    impact120GrowthROIData("impact120GrowthROIK21", event.target.value.replace(/,/g, ''))
                                                }}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                disabled={isLoading}
                                            />
                                            </div>
                                        </td>
                                        <td className="bg-color2 text-center-c">
                                            <div className="inputbages">
                                            <span className="dollors">$</span>
                                            <input
                                                type="text"
                                                name="Inputs.averageTransactionValueE26"
                                                className="form-control"
                                                placeholder={0}
                                                id="impact120GrowthROIL21"
                                                value={Inputs.impact120GrowthROIL21.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                onChange={(event) => {
                                                    setInputs({ ...Inputs, impact120GrowthROIL21: event.target.value.replace(/,/g, '') });
                                                    impact120GrowthROIData("impact120GrowthROIL21", event.target.value.replace(/,/g, ''))
                                                }}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                disabled={isLoading}
                                            />
                                            </div>
                                        </td>
                                        <td className="bg-color2 text-center-c">
                                            <div className="inputbages">
                                            <span className="dollors">$</span>
                                            <input
                                                type="text"
                                                name="Inputs.averageTransactionValueE26"
                                                className="form-control"
                                                placeholder={0}
                                                id="impact120GrowthROIM21"
                                                value={Inputs.impact120GrowthROIM21.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                onChange={(event) => {
                                                    setInputs({ ...Inputs, impact120GrowthROIM21: event.target.value.replace(/,/g, '') });
                                                    impact120GrowthROIData("impact120GrowthROIM21", event.target.value.replace(/,/g, ''))
                                                }}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                disabled={isLoading}
                                            />
                                            </div>
                                        </td>
                                        <td className="bg-color2 text-center-c">
                                            <div className="inputbages">
                                            <span className="dollors">$</span>
                                            <input
                                                type="text"
                                                name="Inputs.averageTransactionValueE26"
                                                className="form-control"
                                                placeholder={0}
                                                id="impact120GrowthROIN21"
                                                value={Inputs.impact120GrowthROIN21.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                onChange={(event) => {
                                                    setInputs({ ...Inputs, impact120GrowthROIN21: event.target.value.replace(/,/g, '') });
                                                    impact120GrowthROIData("impact120GrowthROIN21", event.target.value.replace(/,/g, ''))
                                                }}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                disabled={isLoading}
                                            />
                                            </div>
                                        </td>
                                        <td className="bg-color2 text-center-c">
                                            <div className="inputbages">
                                            <span className="dollors">$</span>
                                            <input
                                                type="text"
                                                name="Inputs.averageTransactionValueE26"
                                                className="form-control"
                                                placeholder={0}
                                                id="impact120GrowthROIO21"
                                                value={Inputs.impact120GrowthROIO21.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                onChange={(event) => {
                                                    setInputs({ ...Inputs, impact120GrowthROIO21: event.target.value.replace(/,/g, '') });
                                                    impact120GrowthROIData("impact120GrowthROIO21", event.target.value.replace(/,/g, ''))
                                                }}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                disabled={isLoading}
                                            />
                                            </div>
                                        </td>
                                        <td className="bg-color2 text-center-c">
                                            <div className="inputbages">
                                            <span className="dollors">$</span>
                                            <input
                                                type="text"
                                                name="Inputs.averageTransactionValueE26"
                                                className="form-control"
                                                placeholder={0}
                                                id="impact120GrowthROIP21"
                                                value={Inputs.impact120GrowthROIP21.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                onChange={(event) => {
                                                    setInputs({ ...Inputs, impact120GrowthROIP21: event.target.value.replace(/,/g, '') });
                                                    impact120GrowthROIData("impact120GrowthROIP21", event.target.value.replace(/,/g, ''))
                                                }}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                disabled={isLoading}
                                            />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="thead-primary">
                                        <td className="bg-color2">Revenue Growth</td>
                                        <td className="bg-color1 text-center-c">
                                            <div className="dolor-icon">
                                                <div> $ </div>
                                                <div> - </div>
                                            </div>
                                        </td>
                                        <td className="bg-color1 text-center-c"><div className="dolor-icon">
                                            <div> $ </div>
                                            <div> - </div>
                                        </div></td>
                                        <td className="bg-color2 text-center-c">{Number(impact120GrowthROIF22).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="bg-color2 text-center-c">{Number(impact120GrowthROIG22).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="bg-color2 text-center-c">{Number(impact120GrowthROIH22).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="bg-color2 text-center-c">{Number(impact120GrowthROII22).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="bg-color2 text-center-c">{Number(impact120GrowthROIJ22).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="bg-color2 text-center-c">{Number(impact120GrowthROIK22).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="bg-color2 text-center-c">{Number(impact120GrowthROIL22).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="bg-color2 text-center-c">{Number(impact120GrowthROIM22).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="bg-color2 text-center-c">{Number(impact120GrowthROIN22).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="bg-color2 text-center-c">{Number(impact120GrowthROIO22).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="bg-color2 text-center-c">{Number(impact120GrowthROIP22).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                    </tr>
                                    <tr className="thead-primary">
                                        <td className="bg-color2">Profit Growth</td>
                                        <td className="bg-color1 text-center-c">
                                            <div className="dolor-icon">
                                                <div> $ </div>
                                                <div> - </div>
                                            </div>
                                        </td>
                                        <td className="bg-color1 text-center-c"><div className="dolor-icon">
                                            <div> $ </div>
                                            <div> - </div>
                                        </div></td>
                                        <td className="bg-color2 text-center-c">{Number(impact120GrowthROIF23).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="bg-color2 text-center-c">{Number(impact120GrowthROIG23).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="bg-color2 text-center-c">{Number(impact120GrowthROIH23).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="bg-color2 text-center-c">{Number(impact120GrowthROII23).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="bg-color2 text-center-c">{Number(impact120GrowthROIJ23).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="bg-color2 text-center-c">{Number(impact120GrowthROIK23).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="bg-color2 text-center-c">{Number(impact120GrowthROIL23).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="bg-color2 text-center-c">{Number(impact120GrowthROIM23).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="bg-color2 text-center-c">{Number(impact120GrowthROIN23).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="bg-color2 text-center-c">{Number(impact120GrowthROIO23).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="bg-color2 text-center-c">{Number(impact120GrowthROIP23).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                    </tr>
                                    <tr className="thead-primary">
                                        <td className="bg-color2">Valuation Impact</td>
                                        <td className="bg-color1 text-center-c">
                                            <div className="dolor-icon">
                                                <div> $ </div>
                                                <div> - </div>
                                            </div>
                                        </td>
                                        <td className="bg-color1 text-center-c"><div className="dolor-icon">
                                            <div> $ </div>
                                            <div> - </div>
                                        </div></td>
                                        <td className="bg-color2 text-center-c">{Number(impact120GrowthROIF24).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="bg-color2 text-center-c">{Number(impact120GrowthROIG24).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="bg-color2 text-center-c">{Number(impact120GrowthROIH24).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="bg-color2 text-center-c">{Number(impact120GrowthROII24).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="bg-color2 text-center-c">{Number(impact120GrowthROIJ24).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="bg-color2 text-center-c">{Number(impact120GrowthROIK24).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="bg-color2 text-center-c">{Number(impact120GrowthROIL24).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="bg-color2 text-center-c">{Number(impact120GrowthROIM24).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="bg-color2 text-center-c">{Number(impact120GrowthROIN24).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="bg-color2 text-center-c">{Number(impact120GrowthROIO24).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="bg-color2 text-center-c">{Number(impact120GrowthROIP24).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
