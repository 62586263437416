import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state/index';
import  UserDropdown  from '../UserDropdown';
import { BarChartGraph } from "../graph/BarChartGraph";
import { LabelingBarsGraph } from "../graph/LabelingBarsGraph";
import { ComboChartGraph } from '../graph/ComboChartGraph';

import { getBenchmarkScoreGapAnalysis } from "../../state/features/BenchmarkScoreGapAnalysisSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import { useAlert } from 'react-alert'

import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';

import { Radar } from 'react-chartjs-2';
import { BarChartGraphDark } from '../graph/BarChartGraphDark';

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);

export const options = {
    animation: true,
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: false,
        }
    },
    scales: {
        r: {
            angleLines: {
                display: true,
                color: '#2DE3B3',
            },
            ticks: {
                color: '#D6DCE5',
                backdropColor: 'transparent',
                z: 999999,
                font: {
                    size: 10,
                    weight: 300,
                }
            },
            pointLabels: {
                color: '#D6DCE5',
                font: {
                    size: 10,
                    weight: 300
                }
            },
            grid: {
                z: 999999,
                color: '#334966',
            },
            min: 0,
            max: 100
        },
    }
};

export default function GrowthPotentialOverviewContent() {

  const [isLoading, setIsLoading] = useState(false);
  const alert = useAlert();
  const { message } = useSelector((state) => state.message);
  const users = useSelector(userData);
  const user_id = users.data.profile.id;
  
  const dispatch = useDispatch();
  const CustomerFinancialValues = useSelector(state => state.CustomerFinancialValues)
  const { CustomerFinancialValuesD24, CustomerFinancialValuesD25, CustomerFinancialValuesD26,  CustomerFinancialValuesD27} = CustomerFinancialValues //object destructuring for CustomerFinancialValues
  const impactof120GrowthOS = useSelector(state => state.impactof120GrowthOS)
  const { impactof120GrowthOSE27, impactof120GrowthOSG27, impactof120GrowthOSH27, impactof120GrowthOSI27, impactof120GrowthOSJ27, impactof120GrowthOSK27, impactof120GrowthOSG35, impactof120GrowthOSH35, impactof120GrowthOSI35, impactof120GrowthOSJ35, impactof120GrowthOSK35, impactof120GrowthOSG36, impactof120GrowthOSH36, impactof120GrowthOSI36, impactof120GrowthOSJ36, impactof120GrowthOSK36, impactof120GrowthOSG37, impactof120GrowthOSH37, impactof120GrowthOSI37, impactof120GrowthOSJ37, impactof120GrowthOSK37, impactof120GrowthOSST42, impactof120GrowthOSST43 } = impactof120GrowthOS
  const clientInputs = useSelector(state => state.clientInputs)
  const { clientInputsD11, clientInputsH36, clientInputsH41 } = clientInputs
  const creatingGrowthMomentum = useSelector(state => state.creatingGrowthMomentum)
  const { creatingGrowthMomentumJ12, creatingGrowthMomentumJ13, creatingGrowthMomentumJ14, creatingGrowthMomentumJ15, creatingGrowthMomentumJ16, creatingGrowthMomentumJ17, creatingGrowthMomentumJ18, creatingGrowthMomentumJ19, creatingGrowthMomentumJ20, creatingGrowthMomentumJ21, creatingGrowthMomentumJ22, creatingGrowthMomentumG55, creatingGrowthMomentumG56, creatingGrowthMomentumG57, creatingGrowthMomentumG58, creatingGrowthMomentumG59, creatingGrowthMomentumG60, creatingGrowthMomentumG61, creatingGrowthMomentumG62, creatingGrowthMomentumG63, creatingGrowthMomentumG64, creatingGrowthMomentumG65 } = creatingGrowthMomentum
  const benchmarkScoreGapAnalysis = useSelector(state => state.benchmarkScoreGapAnalysis)
    const { benchmarkScoreGapAnalysisData, equityEvolutionTheBigBonusReducerData } = bindActionCreators(actionCreators, dispatch)

    const [Inputs, setInputs] = useState({
        benchmarkScoreGapAnalysisM20: 0,
        benchmarkScoreGapAnalysisR13: 0,
        benchmarkScoreGapAnalysisR14: 0,
        benchmarkScoreGapAnalysisR15: 0,
        benchmarkScoreGapAnalysisR16: 0,
        benchmarkScoreGapAnalysisR17: 0,
        benchmarkScoreGapAnalysisR18: 0,
        benchmarkScoreGapAnalysisR19: 0,
        benchmarkScoreGapAnalysisR20: 0,
        benchmarkScoreGapAnalysisR21: 0,
        benchmarkScoreGapAnalysisR22: 0,
        benchmarkScoreGapAnalysisR23: 0,
        benchmarkScoreGapAnalysisR24: 0,
        benchmarkScoreGapAnalysisW13: 30,
        benchmarkScoreGapAnalysisW14: 30,
        benchmarkScoreGapAnalysisW15: 30,
        benchmarkScoreGapAnalysisW16: 30,
        benchmarkScoreGapAnalysisW17: 30,
        benchmarkScoreGapAnalysisW18: 30,
        benchmarkScoreGapAnalysisW19: 30,
        benchmarkScoreGapAnalysisW20: 30,
        benchmarkScoreGapAnalysisW21: 30,
        benchmarkScoreGapAnalysisW22: 30,
        benchmarkScoreGapAnalysisW23: 30,
        benchmarkScoreGapAnalysisX13: 80,
        benchmarkScoreGapAnalysisX14: 80,
        benchmarkScoreGapAnalysisX15: 80,
        benchmarkScoreGapAnalysisX16: 80,
        benchmarkScoreGapAnalysisX17: 80,
        benchmarkScoreGapAnalysisX18: 80,
        benchmarkScoreGapAnalysisX19: 80,
        benchmarkScoreGapAnalysisX20: 80,
        benchmarkScoreGapAnalysisX21: 80,
        benchmarkScoreGapAnalysisX22: 80,
        benchmarkScoreGapAnalysisX23: 80,
        benchmarkScoreGapAnalysisZ13: 100,
        benchmarkScoreGapAnalysisZ14: 100,
        benchmarkScoreGapAnalysisZ15: 100,
        benchmarkScoreGapAnalysisZ16: 100,
        benchmarkScoreGapAnalysisZ17: 100,
        benchmarkScoreGapAnalysisZ18: 100,
        benchmarkScoreGapAnalysisZ19: 100,
        benchmarkScoreGapAnalysisZ20: 100,
        benchmarkScoreGapAnalysisZ21: 100,
        benchmarkScoreGapAnalysisZ22: 100,
        benchmarkScoreGapAnalysisZ23: 100
    });

    useEffect(() => {
        // console.log('R23 set.......', Inputs)
        benchmarkScoreGapAnalysisData("benchmarkScoreGapAnalysisAll", {
            benchmarkScoreGapAnalysisR13 : Inputs.benchmarkScoreGapAnalysisR13, benchmarkScoreGapAnalysisR14 : Inputs.benchmarkScoreGapAnalysisR14, benchmarkScoreGapAnalysisR15 : Inputs.benchmarkScoreGapAnalysisR15, benchmarkScoreGapAnalysisR16 : Inputs.benchmarkScoreGapAnalysisR16, benchmarkScoreGapAnalysisR17 : Inputs.benchmarkScoreGapAnalysisR17, benchmarkScoreGapAnalysisR18 : Inputs.benchmarkScoreGapAnalysisR18, benchmarkScoreGapAnalysisR19 : Inputs.benchmarkScoreGapAnalysisR19, benchmarkScoreGapAnalysisR20 : Inputs.benchmarkScoreGapAnalysisR20, benchmarkScoreGapAnalysisR21 : Inputs.benchmarkScoreGapAnalysisR21, benchmarkScoreGapAnalysisR22 : Inputs.benchmarkScoreGapAnalysisR22, benchmarkScoreGapAnalysisR23 : Inputs.benchmarkScoreGapAnalysisR23,
            benchmarkScoreGapAnalysisM20 : Inputs.benchmarkScoreGapAnalysisM20
        });
    }, [Inputs.benchmarkScoreGapAnalysisM20]);

    const { benchmarkScoreGapAnalysisR13, benchmarkScoreGapAnalysisR14, benchmarkScoreGapAnalysisR15, benchmarkScoreGapAnalysisR16, benchmarkScoreGapAnalysisR17, benchmarkScoreGapAnalysisR18, benchmarkScoreGapAnalysisR19, benchmarkScoreGapAnalysisR20, benchmarkScoreGapAnalysisR21, benchmarkScoreGapAnalysisR22, benchmarkScoreGapAnalysisR23 } = benchmarkScoreGapAnalysis

    /*
    datasets: [
        {
            label: '# Series 3 # score',
            data: [benchmarkScoreGapAnalysisR13, benchmarkScoreGapAnalysisR14, benchmarkScoreGapAnalysisR15, benchmarkScoreGapAnalysisR16, benchmarkScoreGapAnalysisR17, benchmarkScoreGapAnalysisR18, benchmarkScoreGapAnalysisR19, benchmarkScoreGapAnalysisR20, benchmarkScoreGapAnalysisR21, benchmarkScoreGapAnalysisR22, benchmarkScoreGapAnalysisR23],
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: '#FFFFFF',
            borderWidth: 2,
        },
        {
            label: '# Series 1',
            data: [Inputs.benchmarkScoreGapAnalysisW13, Inputs.benchmarkScoreGapAnalysisW14, Inputs.benchmarkScoreGapAnalysisW15, Inputs.benchmarkScoreGapAnalysisW16, Inputs.benchmarkScoreGapAnalysisW17, Inputs.benchmarkScoreGapAnalysisW18, Inputs.benchmarkScoreGapAnalysisW19, Inputs.benchmarkScoreGapAnalysisW20, Inputs.benchmarkScoreGapAnalysisW21, Inputs.benchmarkScoreGapAnalysisW22, Inputs.benchmarkScoreGapAnalysisW23],
            backgroundColor: 'rgb(255, 51, 88)',
            borderColor: 'rgb(255, 99, 132)',
            borderWidth: 1,
        },
        {
            label: '# Series 2',
            data: [Inputs.benchmarkScoreGapAnalysisX13, Inputs.benchmarkScoreGapAnalysisX14, Inputs.benchmarkScoreGapAnalysisX15, Inputs.benchmarkScoreGapAnalysisX16, Inputs.benchmarkScoreGapAnalysisX17, Inputs.benchmarkScoreGapAnalysisX18, Inputs.benchmarkScoreGapAnalysisX19, Inputs.benchmarkScoreGapAnalysisX20, Inputs.benchmarkScoreGapAnalysisX21, Inputs.benchmarkScoreGapAnalysisX22, Inputs.benchmarkScoreGapAnalysisX23],
            backgroundColor: '#36a47b63',
            borderColor: '#36a47b65',
            borderWidth: 1,
        },
        {
            label: '# Series 4',
            data: [Inputs.benchmarkScoreGapAnalysisZ13, Inputs.benchmarkScoreGapAnalysisZ14, Inputs.benchmarkScoreGapAnalysisZ15, Inputs.benchmarkScoreGapAnalysisZ16, Inputs.benchmarkScoreGapAnalysisZ17, Inputs.benchmarkScoreGapAnalysisZ18, Inputs.benchmarkScoreGapAnalysisZ19, Inputs.benchmarkScoreGapAnalysisZ20, Inputs.benchmarkScoreGapAnalysisZ21, Inputs.benchmarkScoreGapAnalysisZ22, Inputs.benchmarkScoreGapAnalysisZ23],
            backgroundColor: '#36a47b63',
            borderColor: '#36a47b65',
            borderWidth: 1,
        },
    ],
    */

    const chartData = {
        labels: [['Competitive', 'Advantage'], ['Financial', 'Performance'], ['Customer', 'Acquisition'], ['Customer', 'Retention'], ['Customer', 'Transactions'], 'Ability', 'Agility', ['Strategic', 'Value'], 'Customers', 'Operations', ['Growth', 'Platform']],
        datasets: [
            {
                backgroundColor: 'transparent',
                borderColor: 'rgb(54, 162, 235)',
                borderWidth: 2,
                data: [benchmarkScoreGapAnalysisR13, benchmarkScoreGapAnalysisR14, benchmarkScoreGapAnalysisR15, benchmarkScoreGapAnalysisR16, benchmarkScoreGapAnalysisR17, benchmarkScoreGapAnalysisR18, benchmarkScoreGapAnalysisR19, benchmarkScoreGapAnalysisR20, benchmarkScoreGapAnalysisR21, benchmarkScoreGapAnalysisR22, benchmarkScoreGapAnalysisR23],
                pointRadius: 0
            },
            {
                backgroundColor : '#D20000',
                data: [Inputs.benchmarkScoreGapAnalysisW13, Inputs.benchmarkScoreGapAnalysisW14, Inputs.benchmarkScoreGapAnalysisW15, Inputs.benchmarkScoreGapAnalysisW16, Inputs.benchmarkScoreGapAnalysisW17, Inputs.benchmarkScoreGapAnalysisW18, Inputs.benchmarkScoreGapAnalysisW19, Inputs.benchmarkScoreGapAnalysisW20, Inputs.benchmarkScoreGapAnalysisW21, Inputs.benchmarkScoreGapAnalysisW22, Inputs.benchmarkScoreGapAnalysisW23],
                pointRadius: 0
            },
            {
                backgroundColor: '#1e2b3c',
                data: [Inputs.benchmarkScoreGapAnalysisX13, Inputs.benchmarkScoreGapAnalysisX14, Inputs.benchmarkScoreGapAnalysisX15, Inputs.benchmarkScoreGapAnalysisX16, Inputs.benchmarkScoreGapAnalysisX17, Inputs.benchmarkScoreGapAnalysisX18, Inputs.benchmarkScoreGapAnalysisX19, Inputs.benchmarkScoreGapAnalysisX20, Inputs.benchmarkScoreGapAnalysisX21, Inputs.benchmarkScoreGapAnalysisX22, Inputs.benchmarkScoreGapAnalysisX23],
                pointRadius: 0
            },
            {
                backgroundColor: '#2BA193',
                data: [Inputs.benchmarkScoreGapAnalysisZ13, Inputs.benchmarkScoreGapAnalysisZ14, Inputs.benchmarkScoreGapAnalysisZ15, Inputs.benchmarkScoreGapAnalysisZ16, Inputs.benchmarkScoreGapAnalysisZ17, Inputs.benchmarkScoreGapAnalysisZ18, Inputs.benchmarkScoreGapAnalysisZ19, Inputs.benchmarkScoreGapAnalysisZ20, Inputs.benchmarkScoreGapAnalysisZ21, Inputs.benchmarkScoreGapAnalysisZ22, Inputs.benchmarkScoreGapAnalysisZ23],
                pointRadius: 0
            },
        ]
    };

    const [benchmarkTotalUserScoreList, setBenchmarkTotalUserScoreList] = useState([]);

    useEffect(() => {
        const newBenchmarkScoreObj = { ...Inputs }
        let indexR = 12;
        let sbqUserTotalScore = 0;
        let bqTotalUserScoreList = benchmarkTotalUserScoreList ? benchmarkTotalUserScoreList : '';
        if(bqTotalUserScoreList){
            bqTotalUserScoreList.map((scoreList, scoreKey) => {
                let bqUserScore = scoreList.user_score ? scoreList.user_score : 0;
                let bqTarget = scoreList.bq_total_score ? scoreList.bq_total_score : 0;
                let bqGrade = Math.round(parseFloat(bqUserScore) / parseFloat(bqTarget) * 100);
                indexR = indexR + 1;
                newBenchmarkScoreObj["benchmarkScoreGapAnalysisR"+indexR] = bqGrade;
                sbqUserTotalScore = sbqUserTotalScore + parseFloat(bqUserScore);
                // console.log('--loop--')
                if((scoreKey+1) == bqTotalUserScoreList.length){
                    newBenchmarkScoreObj["benchmarkScoreGapAnalysisM20"] = sbqUserTotalScore;
                    setInputs(newBenchmarkScoreObj);
                    // console.log('key--', scoreKey);
                    //return response
                }
            })
        }
    }, [benchmarkTotalUserScoreList])

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getBenchmarkScoreGapAnalysis({user_id}))
        .unwrap()
        .then(async(response=[]) => {
            setBenchmarkTotalUserScoreList(response.data.benchmarkTotalUserScoreList);         
        })
        .then(() => {
            setIsLoading(false)   // Hide loading screen         
        })
        .catch((error) => {
            console.log("error",error)
            setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])
      
      useEffect(() => {
        equityEvolutionTheBigBonusReducerData("equityEvolutionTheBigBonusReducerAllStats", {
            clientInputsD11,
            clientInputsH36,
            clientInputsH41,
            impactof120GrowthOSG36,
            impactof120GrowthOSH36,
            impactof120GrowthOSI36,
            impactof120GrowthOSJ36,
            impactof120GrowthOSK36
        });
      }, [])

      const equityEvolutionTheBigBonus = useSelector(state => state.equityEvolutionTheBigBonus)
      const { equityEvolutionTheBigBonusF24, equityEvolutionTheBigBonusG24, equityEvolutionTheBigBonusH24, equityEvolutionTheBigBonusI24, equityEvolutionTheBigBonusJ24, equityEvolutionTheBigBonusK24, equityEvolutionTheBigBonusF25, equityEvolutionTheBigBonusG25, equityEvolutionTheBigBonusH25, equityEvolutionTheBigBonusI25, equityEvolutionTheBigBonusJ25, equityEvolutionTheBigBonusK25,  equityEvolutionTheBigBonusF26, equityEvolutionTheBigBonusG26, equityEvolutionTheBigBonusH26, equityEvolutionTheBigBonusI26, equityEvolutionTheBigBonusJ26, equityEvolutionTheBigBonusK26, equityEvolutionTheBigBonusL26,  equityEvolutionTheBigBonusF27, equityEvolutionTheBigBonusG27, equityEvolutionTheBigBonusH27, equityEvolutionTheBigBonusI27, equityEvolutionTheBigBonusJ27, equityEvolutionTheBigBonusK27 } = equityEvolutionTheBigBonus //object destructuring for equityEvolutionTheBigBonus

    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
            {/* row */}
            <div className="container-fluid">
                <div className="head-title">
                    <h3 className='preheading'>Your Growth Potential</h3>
                    <h1 className='mainheading mb-1'>Growth Potential Overview</h1>
                </div>
                <Spinner />
                <div className="growth-potential">
                    <div className="growth-potential-inner">
                        <div className='row mb-3 potential-overviews' style={{maxWidth: 1400}}>
                            <div className='col-12 col-lg-4 col-xl-3 mb-3'>
                                <div className="graph-content">
                                    <div className="state-of-the mb-3">
                                        <img src="../images/growth-potential/icon_01.png" alt="" />
                                        <h3>State of the Business</h3>
                                    </div>
                                    <div className="graph-content-bx" style={{maxHeight: 300}}>
                                        {/* <img src="../images/growth-potential/graph_01.png" alt="graph 01" /> */}
                                        <Radar options={options} data={chartData} />   
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-lg-4 col-xl-6 mb-3'>
                                <div className="graph-content">
                                    <div className="state-of-the mb-3">
                                        <img src="../images/growth-potential/icon_02.png" alt="02" />
                                        <h3>Impact on Financial Performance</h3>
                                    </div>
                                    <div className="graph-content-bx" style={{maxHeight: 300}}>
                                        {/* <img src="../images/growth-potential/graph_02.png" alt="graph 02" /> */}
                                        <BarChartGraphDark dataInput={{
                                            "Title"      : "",
                                            "showTitle"  : false,
                                            "showLegend" : true,
                                            "ColorCodes" : ["#74bfa9", "#1acb9f", "#297AF1"],
                                            "ColorCodes1" : ["#74bfa9", "#04835d", "#297AF1"],
                                            "GraphData"  : {
                                                labels: ["Year 1", "Year 2", "Year 3", "Year 4", "Year 5"],
                                                datasets: [
                                                    {
                                                        label: "Profit",
                                                        backgroundColor: "#74bfa9",
                                                        data: [Number(impactof120GrowthOSG36),Number(impactof120GrowthOSH36),Number(impactof120GrowthOSI36),Number(impactof120GrowthOSJ36),Number(impactof120GrowthOSK36)]
                                                    },
                                                    {
                                                        label: "Sales",
                                                        backgroundColor: "#138f6f",
                                                        data: [Number(impactof120GrowthOSG35),Number(impactof120GrowthOSH35),Number(impactof120GrowthOSI35),Number(impactof120GrowthOSJ35),Number(impactof120GrowthOSK35)]
                                                    },
                                                    {
                                                        label: "Valuation",
                                                        backgroundColor: "#297AF1",
                                                        data: [Number(impactof120GrowthOSG37),Number(impactof120GrowthOSH37),Number(impactof120GrowthOSI37),Number(impactof120GrowthOSJ37),Number(impactof120GrowthOSK37)]
                                                    }
                                                ]
                                            }
                                        }} />
                                        {/* <BarChartGraph dataInput={{
                                            "Title"      : "",
                                            "vTitle"     : "",
                                            "hTitle"     : "",
                                            "subTitle"   : "",
                                            "ColorCodes" : ["#74bfa9", "#04835d", "#297AF1"],
                                            "GraphData"  : [
                                                ["", "Profit", "Sales", "Valuation"],
                                                ["Year 1", Number(impactof120GrowthOSG36), Number(impactof120GrowthOSG35), Number(impactof120GrowthOSG37)],
                                                ["Year 2", Number(impactof120GrowthOSH36), Number(impactof120GrowthOSH35), Number(impactof120GrowthOSH37)],
                                                ["Year 3", Number(impactof120GrowthOSI36), Number(impactof120GrowthOSI35), Number(impactof120GrowthOSI37)],
                                                ["Year 4", Number(impactof120GrowthOSJ36), Number(impactof120GrowthOSJ35), Number(impactof120GrowthOSJ37)],
                                                ["Year 5", Number(impactof120GrowthOSK36), Number(impactof120GrowthOSK35), Number(impactof120GrowthOSK37)]
                                            ],
                                            backgroundColor: '#1e2b3c',
                                            legendTextStyle: { color: '#bdbdc1' },
                                            textStyle:{color: '#bdbdc1'},
                                        }} /> */}
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-lg-4 col-xl-3 mb-3'>
                                <div className="graph-content">
                                    <div className="state-of-the mb-3">
                                        <img src="../images/growth-potential/icon_03.png" alt="03" />
                                        <h3>5-Year Growth Rate (CAGR)</h3>
                                    </div>
                                    <div className="graph-content-bx">
                                        <div className="list-average">
                                            <ul>
                                                <li className='aunal-list-d'>
                                                    <p>Average Annual Revenue Growth</p>
                                                    <strong>{Number(impactof120GrowthOSST42).toLocaleString('en-US')}%</strong>
                                                </li>
                                                <li className='aunal-list-d'>
                                                    <p>Average Annual Profit Growth</p>
                                                    <strong>{Number(impactof120GrowthOSST43).toLocaleString('en-US')}%</strong>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row potential-overviews' style={{maxWidth: 1400}}>
                            <div className='col-12 col-lg-4 col-xl-3 mb-3'>
                                <div className="graph-content">
                                    <div className="state-of-the mb-3">
                                        <img src="../images/growth-potential/icon_04.svg" alt="" />
                                        <h3>Growth Catalyst Scores</h3>
                                    </div>
                                    <div className="graph-content-bx">
                                        <div className="overall-score">
                                            <ul>
                                                <li className='aunal-list-d'>
                                                    <p>Overall Score</p>
                                                    <strong className='color-green'>462</strong>
                                                </li>
                                                <li className='aunal-list-d'>
                                                    <p>Strategic Foundation</p>
                                                    <strong>43%</strong>
                                                </li>
                                                <li className='aunal-list-d'>
                                                    <p>Short-Term Revenue</p>
                                                    <strong>50%</strong>
                                                </li>
                                                <li className='aunal-list-d'>
                                                    <p>Agility Engine</p>
                                                    <strong>45%</strong>
                                                </li>
                                                <li className='aunal-list-d'>
                                                    <p>Enterprise Value</p>
                                                    <strong>43%</strong>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-lg-4 col-xl-6 mb-3'>
                                <div className="graph-content">
                                    <div className="state-of-the mb-3">
                                        <img src="../images/growth-potential/icon_05.png" alt="02" />
                                        <h3>Estimated Valuation Impact (Equity Evolution<sup>TM</sup>)</h3>
                                    </div>
                                    <div className="graph-content-bx" style={{maxHeight: 400}}>
                                        <BarChartGraphDark dataInput={{
                                            "Title"      : "",
                                            "showTitle"  : false,
                                            "showLegend" : false,
                                            "ColorCodes" : ["#c2c7cd", "#0663b4", "#3881ed", "#04835d", "#2aba91", "#74bfa9"],
                                            "GraphData"  : {
                                                labels: [["Subpar", "Industry", "Multiple"] ,[ "Average", "Industry", "Multiple"], ["Premium", "Industry","Multiple"], ['"Dream"', 'Multiple']],
                                                datasets: [
                                                    {
                                                        label: "",
                                                        backgroundColor: "#c2c7cd",
                                                        data: [Number(equityEvolutionTheBigBonusF24),Number(equityEvolutionTheBigBonusF25),Number(equityEvolutionTheBigBonusF26),Number(equityEvolutionTheBigBonusF27)]
                                                    },
                                                    {
                                                        label: "",
                                                        backgroundColor: "#0663b4",
                                                        data: [Number(equityEvolutionTheBigBonusG24),Number(equityEvolutionTheBigBonusG25),Number(equityEvolutionTheBigBonusG26),Number(equityEvolutionTheBigBonusG27)]
                                                    },
                                                    {
                                                        label: "",
                                                        backgroundColor: "#3881ed",
                                                        data: [Number(equityEvolutionTheBigBonusH24),Number(equityEvolutionTheBigBonusH25),Number(equityEvolutionTheBigBonusH26),Number(equityEvolutionTheBigBonusH27)]
                                                    },
                                                    {
                                                        label: "",
                                                        backgroundColor: "#04835d",
                                                        data: [Number(equityEvolutionTheBigBonusI24),Number(equityEvolutionTheBigBonusI25),Number(equityEvolutionTheBigBonusI26),Number(equityEvolutionTheBigBonusI27)]
                                                    },
                                                    {
                                                        label: "",
                                                        backgroundColor: "#2aba91",
                                                        data: [Number(equityEvolutionTheBigBonusJ24),Number(equityEvolutionTheBigBonusJ25),Number(equityEvolutionTheBigBonusJ26),Number(equityEvolutionTheBigBonusJ27)]
                                                    },
                                                    {
                                                        label: "",
                                                        backgroundColor: "#74bfa9",
                                                        data: [Number(equityEvolutionTheBigBonusK24),Number(equityEvolutionTheBigBonusK25),Number(equityEvolutionTheBigBonusK26),Number(equityEvolutionTheBigBonusK27)]
                                                    }
                                                ]
                                            }
                                        }} />
                                        {/* <BarChartGraph dataInput={{
                                            "Title"      : "",
                                            "vTitle"     : "",
                                            "hTitle"     : "",
                                            "subTitle"   : "",
                                            "ColorCodes" : ["#c2c7cd", "#0663b4", "#3881ed", "#04835d", "#2aba91", "#74bfa9"],
                                            "GraphData"  : [
                                                ["", "Series 1", "Series 2", "Series 3", "Series 4", "Series 5", "Series 6"],
                                                ["Subpar Industry Multiple", Number(equityEvolutionTheBigBonusF24), Number(equityEvolutionTheBigBonusG24), Number(equityEvolutionTheBigBonusH24), Number(equityEvolutionTheBigBonusI24), Number(equityEvolutionTheBigBonusJ24), Number(equityEvolutionTheBigBonusK24)],
                                                ["Average Industry Multiple", Number(equityEvolutionTheBigBonusF25), Number(equityEvolutionTheBigBonusG25), Number(equityEvolutionTheBigBonusH25), Number(equityEvolutionTheBigBonusI25), Number(equityEvolutionTheBigBonusJ25), Number(equityEvolutionTheBigBonusK25)],
                                                ["Premium Industry Multiple", Number(equityEvolutionTheBigBonusF26), Number(equityEvolutionTheBigBonusG26), Number(equityEvolutionTheBigBonusH26), Number(equityEvolutionTheBigBonusI26), Number(equityEvolutionTheBigBonusJ26), Number(equityEvolutionTheBigBonusK26)],
                                                ["'Dream' Multiple", Number(equityEvolutionTheBigBonusF27), Number(equityEvolutionTheBigBonusG27), Number(equityEvolutionTheBigBonusH27), Number(equityEvolutionTheBigBonusI27), Number(equityEvolutionTheBigBonusJ27), Number(equityEvolutionTheBigBonusK27)]
                                            ],
                                            backgroundColor: '#1e2b3c',
                                            legendTextStyle: { color: '#bdbdc1' },
                                            textStyle:{color: '#bdbdc1'},
                                        }} /> */}
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-lg-4 col-xl-3 mb-3'>
                                <div className="graph-content">
                                    <div className="state-of-the mb-3">
                                        <img src="../images/growth-potential/icon_06.png" alt="03" />
                                        <h3>Active Customers</h3>
                                    </div>
                                    <div className="graph-content-bx">
                                        {/* <img src="../images/growth-potential/graph_06.png" alt="graph 03" /> */}
                                        <LabelingBarsGraph dataInput={{
                                            "Title": "",
                                            "GraphData": [
                                                [
                                                    "Element",
                                                    "",
                                                    { role: "style" },
                                                    {
                                                        sourceColumn: 0,
                                                        role: "annotation",
                                                        type: "string",
                                                        calc: "stringify",
                                                    },
                                                ],
                                                ["Year 5", Number(impactof120GrowthOSK27), "#297AF1", Number(impactof120GrowthOSK27).toLocaleString("en-US")],
                                                ["Year 4",  Number(impactof120GrowthOSJ27), "#297AF1", Number(impactof120GrowthOSJ27).toLocaleString("en-US")],
                                                ["Year 3",  Number(impactof120GrowthOSI27), "#297AF1", Number(impactof120GrowthOSI27).toLocaleString("en-US")],
                                                ["Year 2",  Number(impactof120GrowthOSH27), "#297AF1", Number(impactof120GrowthOSH27).toLocaleString("en-US")],
                                                ["Year 1",  Number(impactof120GrowthOSG27), "#297AF1", Number(impactof120GrowthOSG27).toLocaleString("en-US")],
                                                ["Current",  Number(impactof120GrowthOSE27), "#297AF1", Number(impactof120GrowthOSE27).toLocaleString("en-US")],
                                            ],
                                            backgroundColor: '#1e2b3c',
                                            legendTextStyle: { color: '#bdbdc1' },
                                            textStyle:{color: '#bdbdc1'},
                                        }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="row potential-overviews">
                    <div className="col-12 col-md-6 mb-3 col-lg-4 col-padding-left-0">
                        <div className="graph-content">
                            <div className="state-of-the">
                                <img src="../images/growth-potential/icon_01.png" alt="" />
                                <h3>Stat of the Business</h3>
                            </div>
                            <div className="graph-content-bx">
                                {/* <img src="../images/growth-potential/graph_01.png" alt="graph 01" /> 
                                <Radar options={options} data={chartData} />   
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 mb-3 col-lg-4 col-padding-right-0 col-padding-left-0">
                        <div className="graph-content">
                            <div className="state-of-the">
                                <img src="../images/growth-potential/icon_02.png" alt="02" />
                                <h3>Impact on Financial Performance</h3>
                            </div>
                            <div className="graph-content-bx">
                                {/* <img src="../images/growth-potential/graph_02.png" alt="graph 02" /> 
                                <BarChartGraph dataInput={{
                                    "Title"      : "",
                                    "vTitle"     : "",
                                    "hTitle"     : "",
                                    "subTitle"   : "",
                                    "ColorCodes" : ["#74bfa9", "#04835d", "#297AF1"],
                                    "GraphData"  : [
                                        ["", "Profit", "Sales", "Valuation"],
                                        ["Year 1", Number(impactof120GrowthOSG36), Number(impactof120GrowthOSG35), Number(impactof120GrowthOSG37)],
                                        ["Year 2", Number(impactof120GrowthOSH36), Number(impactof120GrowthOSH35), Number(impactof120GrowthOSH37)],
                                        ["Year 3", Number(impactof120GrowthOSI36), Number(impactof120GrowthOSI35), Number(impactof120GrowthOSI37)],
                                        ["Year 4", Number(impactof120GrowthOSJ36), Number(impactof120GrowthOSJ35), Number(impactof120GrowthOSJ37)],
                                        ["Year 5", Number(impactof120GrowthOSK36), Number(impactof120GrowthOSK35), Number(impactof120GrowthOSK37)]
                                    ],
                                    backgroundColor: '#1e2b3c',
                                    legendTextStyle: { color: '#bdbdc1' },
                                    textStyle:{color: '#bdbdc1'},
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 mb-3 col-lg-4 col-padding-right-0">
                        <div className="graph-content">
                            <div className="state-of-the">
                                <img src="../images/growth-potential/icon_03.png" alt="03" />
                                <h3>5-Year Growth Rate (CAGR)</h3>
                            </div>
                            <div className="graph-content-bx">
                                <div className="list-average">
                                    <ul>
                                        <li className='aunal-list-d'>
                                            <p>Average Annual Revenue Growth</p>
                                            <strong>{Number(impactof120GrowthOSST42).toLocaleString('en-US')}%</strong>
                                        </li>
                                        <li className='aunal-list-d'>
                                            <p>Average Annual Profit Growth</p>
                                            <strong>{Number(impactof120GrowthOSST43).toLocaleString('en-US')}%</strong>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* row 2 

                    <div className="col-12 col-md-6 mb-3 col-lg-4 col-padding-left-0">
                        <div className="graph-content">
                            <div className="state-of-the">
                                <img src="../images/growth-potential/icon_04.svg" alt="" />
                                <h3>Benchmark Scores</h3>
                            </div>
                            <div className="graph-content-bx">
                                <div className="overall-score">
                                    <ul>
                                        <li className='aunal-list-d'>
                                            <p>Overall Score</p>
                                            <strong className='color-green'>462</strong>
                                        </li>
                                        <li className='aunal-list-d'>
                                            <p>Strategic Foundation</p>
                                            <strong>43%</strong>
                                        </li>
                                        <li className='aunal-list-d'>
                                            <p>Short-Term Revenue</p>
                                            <strong>50%</strong>
                                        </li>
                                        <li className='aunal-list-d'>
                                            <p>Agility Engine</p>
                                            <strong>45%</strong>
                                        </li>
                                        <li className='aunal-list-d'>
                                            <p>Enterprise Value</p>
                                            <strong>43%</strong>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 mb-3 col-lg-4 col-padding-right-0 col-padding-left-0">
                        <div className="graph-content">
                            <div className="state-of-the">
                                <img src="../images/growth-potential/icon_05.png" alt="02" />
                                <h3>Estimated Valuation Impact (Equity EvolutionTM)</h3>
                            </div>
                            <div className="graph-content-bx">
                                {/* <img src="../images/growth-potential/graph_05.png" alt="graph 02" /> 
                                <BarChartGraph dataInput={{
                                    "Title"      : "",
                                    "vTitle"     : "",
                                    "hTitle"     : "",
                                    "subTitle"   : "",
                                    "ColorCodes" : ["#c2c7cd", "#0663b4", "#3881ed", "#04835d", "#2aba91", "#74bfa9"],
                                    "GraphData"  : [
                                        ["", "Series 1", "Series 2", "Series 3", "Series 4", "Series 5", "Series 6"],
                                        ["Subpar Industry Multiple", Number(equityEvolutionTheBigBonusF24), Number(equityEvolutionTheBigBonusG24), Number(equityEvolutionTheBigBonusH24), Number(equityEvolutionTheBigBonusI24), Number(equityEvolutionTheBigBonusJ24), Number(equityEvolutionTheBigBonusK24)],
                                        ["Average Industry Multiple", Number(equityEvolutionTheBigBonusF25), Number(equityEvolutionTheBigBonusG25), Number(equityEvolutionTheBigBonusH25), Number(equityEvolutionTheBigBonusI25), Number(equityEvolutionTheBigBonusJ25), Number(equityEvolutionTheBigBonusK25)],
                                        ["Premium Industry Multiple", Number(equityEvolutionTheBigBonusF26), Number(equityEvolutionTheBigBonusG26), Number(equityEvolutionTheBigBonusH26), Number(equityEvolutionTheBigBonusI26), Number(equityEvolutionTheBigBonusJ26), Number(equityEvolutionTheBigBonusK26)],
                                        ["'Dream' Multiple", Number(equityEvolutionTheBigBonusF27), Number(equityEvolutionTheBigBonusG27), Number(equityEvolutionTheBigBonusH27), Number(equityEvolutionTheBigBonusI27), Number(equityEvolutionTheBigBonusJ27), Number(equityEvolutionTheBigBonusK27)]
                                    ],
                                    backgroundColor: '#1e2b3c',
                                    legendTextStyle: { color: '#bdbdc1' },
                                    textStyle:{color: '#bdbdc1'},
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 mb-3 col-lg-4 col-padding-right-0">
                        <div className="graph-content">
                            <div className="state-of-the">
                                <img src="../images/growth-potential/icon_06.png" alt="03" />
                                <h3>Active Customers</h3>
                            </div>
                            <div className="graph-content-bx">
                                {/* <img src="../images/growth-potential/graph_06.png" alt="graph 03" /> 
                                <LabelingBarsGraph dataInput={{
                                    "Title": "",
                                    "GraphData": [
                                        [
                                            "Element",
                                            "",
                                            { role: "style" },
                                            {
                                                sourceColumn: 0,
                                                role: "annotation",
                                                type: "string",
                                                calc: "stringify",
                                            },
                                        ],
                                        ["Year 5", Number(impactof120GrowthOSK27), "#297AF1", `$ ${Number(impactof120GrowthOSK27).toLocaleString("en-US")}`],
                                        ["Year 4",  Number(impactof120GrowthOSJ27), "#297AF1", `$ ${Number(impactof120GrowthOSJ27).toLocaleString("en-US")}`],
                                        ["Year 3",  Number(impactof120GrowthOSI27), "#297AF1", `$ ${Number(impactof120GrowthOSI27).toLocaleString("en-US")}`],
                                        ["Year 2",  Number(impactof120GrowthOSH27), "#297AF1", `$ ${Number(impactof120GrowthOSH27).toLocaleString("en-US")}`],
                                        ["Year 1",  Number(impactof120GrowthOSG27), "#297AF1", `$ ${Number(impactof120GrowthOSG27).toLocaleString("en-US")}`],
                                        ["Current",  Number(impactof120GrowthOSE27), "#297AF1", `$ ${Number(impactof120GrowthOSE27).toLocaleString("en-US")}`],
                                    ],
                                    backgroundColor: '#1e2b3c',
                                    legendTextStyle: { color: '#bdbdc1' },
                                    textStyle:{color: '#bdbdc1'},
                                }} />
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="nextInput">
                    <ul>
                        <li>
                            <Link className="next-link" to="/your-growth-potential/creating-growth-momentum"> Next:  Creating Momentum
                                <span className="icon-three">
                                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                                </span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}