import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from 'react-alert'
import UserService from "../../services/user.service";
import { clearMessage } from "../../state/features/Message";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import UserDropdown from '../UserDropdown'
import { userData } from "../../state/features/AuthenticationSlice";

export default function ProfileContent() {
    const users = useSelector(userData);
    const userID = users.data.profile.id;

    const alert = useAlert();
    const [content, setContent] = useState("");
    const [isLoading, setisLoading] = useState(false)
    useEffect(() => {
        UserService.getUserProfile(userID).then(
            (response) => {
                setContent(response.data.data);
                setValue("user_id", response.data.data.profile.id)
                setValue("firstName", response.data.data.profile.first_name)
                setValue("lastName", response.data.data.profile.last_name)
                setValue("email", response.data.data.profile.email)
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setContent(_content);
            }
        );
    }, []);

    const profileSchema = Yup.object().shape({
        firstName: Yup.string().required('First name is required.'),
        lastName: Yup.string().required('Last name is required.'),
        email: Yup.string().email('Enter valid email id.').required('Email id is required.'),
    });
    const formOptions = { resolver: yupResolver(profileSchema) }
    const { register, setValue, formState: { errors, isSubmitting }, handleSubmit, } = useForm(formOptions);

    const onSubmit = formValue => {
        setisLoading(true)
        const { user_id, firstName, lastName, email } = formValue
        const data = {
            email,
            first_name: firstName,
            last_name: lastName
        }
        UserService.updateUserProfile(user_id, data)
            .then(() => {
                setisLoading(false)
                { alert.show("Profile successfully updated.") }
            })
            .catch((error) => {
                setisLoading(false)
                { error && alert.show(error.response.data.message) }
            });
    }


    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
            {/* row */}
            <div className="container-fluid lead-generation-new">
            <div className="head-title">
                <h3 className='font-sise-20'>Edit Profile</h3>
            </div>
                <div className="mian-form-div-outer row">
                    <div className="col-md-12 col-lg-8 col-xl-6">
                        <div className="inner-main-div">
                            <div className="form-container sign-up-container">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <h1>Edit Profile</h1>
                                    <div className="row" style={{ padding: 0, margin: 0 }}>
                                        <div className="col-12 col-md-12 ">
                                            <input type="hidden" {...register("user_id")} />
                                            <input
                                                type="text"
                                                {...register("firstName")}
                                                placeholder="First name"
                                            // className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
                                            />
                                            <span style={{ color: 'red' }}>{errors.firstName?.message}</span>
                                        </div>
                                        <div className="col-12 col-md-12">
                                            <input
                                                type="text"
                                                {...register("lastName")}
                                                placeholder="Last name"
                                            // className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
                                            />
                                            <span style={{ color: 'red' }}>{errors.lastName?.message}</span>
                                        </div>
                                        <div className="col-12 col-md-12">
                                            <input
                                                type="text"
                                                {...register("email")}
                                                placeholder="Email address"
                                                // className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                                readOnly
                                            />
                                            <span style={{ color: 'red' }}>{errors.email?.message}</span>
                                        </div>
                                    </div>
                                    {
                                        isLoading
                                            ?
                                            <button className="mt-3">
                                                <div className="d-flex align-items-center">
                                                    <strong>Update...</strong>
                                                    <div className="spinner-border ml-auto" role="status" aria-hidden="true" />
                                                </div>
                                            </button>

                                            :
                                            <button type='submit' className="mt-3"> Update </button>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
