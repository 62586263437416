import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getCustomerEmpathyMap, modifyCustomerEmpathyMap } from "../../state/features/CustomerEmpathyMapSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';

export default function CustomerEmpathyMapContent() {

    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        CustomerEmpathyMapC17 : '',
        CustomerEmpathyMapD17 : '',
        CustomerEmpathyMapF17 : '',
        CustomerEmpathyMapG17 : '',
        CustomerEmpathyMapC18 : '',
        CustomerEmpathyMapD18 : '',
        CustomerEmpathyMapF18 : '',
        CustomerEmpathyMapG18 : '',
        CustomerEmpathyMapC19 : '',
        CustomerEmpathyMapD19 : '',
        CustomerEmpathyMapF19 : '',
        CustomerEmpathyMapG19 : '',
        CustomerEmpathyMapC20 : '',
        CustomerEmpathyMapD20 : '',
        CustomerEmpathyMapF20 : '',
        CustomerEmpathyMapG20 : '',
        CustomerEmpathyMapC21 : '',
        CustomerEmpathyMapD21 : '',
        CustomerEmpathyMapF21 : '',
        CustomerEmpathyMapG21 : '',
        CustomerEmpathyMapC24 : '',
        CustomerEmpathyMapD24 : '',
        CustomerEmpathyMapC25 : '',
        CustomerEmpathyMapD25 : '',
        CustomerEmpathyMapC26 : '',
        CustomerEmpathyMapD26 : '',
        CustomerEmpathyMapC27 : '',
        CustomerEmpathyMapD27 : '',
        CustomerEmpathyMapC28 : '',
        CustomerEmpathyMapD28 : '',
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getCustomerEmpathyMap({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.growthPlanning)
            if(response!= undefined  && response.data.growthPlanning){
                let growthPlanning = response.data.growthPlanning[0];
                console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key]
                    }
                }
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyCustomerEmpathyMap({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        //window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

  return (
   <div className="content-body">
    <UserDropdown />
    
    {/* row */}
    <div className="container-fluid">
        <div className="head-title">
            <p>Messaging </p>
            <h2>Customer Empathy Map </h2>
            <p>A Customer Empathy Map is a strategic tool that is used to develop a thorough understanding of the customer or client who will be using a new product or service. More specifically, an empathy map forces individuals who are part of innovation or marketing teams to consider customer attitudes towards the product or service and their behavioral response to it. The empathy map should be created at the outset of any product development process. However, it should be constantly adapted as the team accumulates more client knowledge. The four quadrants of the Empathy Map are what the client 1) Says 2) Thinks 3) Feels and 4) Does. </p>
            <p className="mb-5">An&nbsp;Empathy Map&nbsp;should be created for any new product or service developed by the organization because it helps to ensure that every team member has a unified perspective on who the product or service is being created for. By encapsulating knowledge about the user of the product or service, the organization will be able to identify what it does not yet know about its clientele and easily organize information (e.g., research, interview transcripts, survey results etc.) about its customers. Further, client information can easily be transmitted to other relevant parties. </p>
        </div>
        <Spinner />
        <div className="card">
            <div className="card-body pl-0">
            <div className="table-responsive table-dta">
                <table className="table mb-0">
                <thead>
                    <tr>
                    <td className="bg-color1 text-white padding-10 width-25" colSpan={2}>
                        <div className="forces-div">
                        <span className="forces-img-bx">
                            <img src="../images/forces-icon1.png" alt="forces-icon1" />
                        </span>
                        <span className="forces-span">Says</span>
                        </div>
                    </td>
                    <td className="bg-color1 text-white padding-10 width-25" colSpan={2}>
                        <div className="forces-div">
                        <span className="forces-img-bx">
                            <img src="../images/forces-icon2.png" alt="forces-icon1" />
                        </span>
                        <span className="forces-span">Feels</span>
                        </div>
                    </td>
                    <td className="d-hidden width-5" colSpan={2} />
                    <td className="bg-color1 text-white padding-10 width-25" colSpan={2}>
                        <div className="forces-div">
                        <span className="forces-img-bx">
                            <img src="../images/forces-icon3.png" alt="forces-icon1" />
                        </span>
                        <span className="forces-span">Pain</span>
                        </div>
                    </td>
                    <td className="bg-color1 text-white padding-10 width-25" colSpan={2}>
                        <div className="forces-div">
                        <span className="forces-img-bx">
                            <img src="../images/forces-icon4.png" alt="forces-icon1" />
                        </span>
                        <span className="forces-span">Gain</span>
                        </div>
                    </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td className="bg-color3 text-white padding-15-10" colSpan={2}>
                        What are the influences of the environment on what the customer explicity says?
                    </td>
                    <td className="bg-color3 text-white padding-15-10" colSpan={2}>What matters to the customer?</td>
                    <td className="d-hidden" colSpan={2} />
                    <td className="bg-color3 text-white padding-15-10" colSpan={2}>What are the customer's fears and frustrations?</td>
                    <td className="bg-color3 text-white padding-15-10" colSpan={2}>What does the customer hope to accomplish?</td>
                    </tr>
                    <tr>
                    <td className="bg-color2 text-white height30" colSpan={2}>
                        <input type="text" className="form-control inputText"
                            id="CustomerEmpathyMapC17"
                            value={Inputs.CustomerEmpathyMapC17}
                            onChange={(event) => setInputs({ ...Inputs, CustomerEmpathyMapC17: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    <td className="bg-color2 text-white height30" colSpan={2}>
                        <input type="text" className="form-control inputText"
                            id="CustomerEmpathyMapD17"
                            value={Inputs.CustomerEmpathyMapD17}
                            onChange={(event) => setInputs({ ...Inputs, CustomerEmpathyMapD17: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    <td className="d-hidden" colSpan={2} />
                    <td className="bg-color2 text-white height30" colSpan={2}>
                        <input type="text" className="form-control inputText"
                            id="CustomerEmpathyMapF17"
                            value={Inputs.CustomerEmpathyMapF17}
                            onChange={(event) => setInputs({ ...Inputs, CustomerEmpathyMapF17: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    <td className="bg-color2 text-white height30" colSpan={2}>
                        <input type="text" className="form-control inputText"
                            id="CustomerEmpathyMapG17"
                            value={Inputs.CustomerEmpathyMapG17}
                            onChange={(event) => setInputs({ ...Inputs, CustomerEmpathyMapG17: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    </tr>
                    <tr>
                    <td className="bg-color2 text-white height30" colSpan={2}>
                        <input type="text" className="form-control inputText"
                            id="CustomerEmpathyMapC18"
                            value={Inputs.CustomerEmpathyMapC18}
                            onChange={(event) => setInputs({ ...Inputs, CustomerEmpathyMapC18: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    <td className="bg-color2 text-white height30" colSpan={2}>
                        <input type="text" className="form-control inputText"
                            id="CustomerEmpathyMapD18"
                            value={Inputs.CustomerEmpathyMapD18}
                            onChange={(event) => setInputs({ ...Inputs, CustomerEmpathyMapD18: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    <td className="d-hidden" colSpan={2} />
                    <td className="bg-color2 text-white height30" colSpan={2}>
                        <input type="text" className="form-control inputText"
                            id="CustomerEmpathyMapF18"
                            value={Inputs.CustomerEmpathyMapF18}
                            onChange={(event) => setInputs({ ...Inputs, CustomerEmpathyMapF18: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    <td className="bg-color2 text-white height30" colSpan={2}>
                        <input type="text" className="form-control inputText"
                            id="CustomerEmpathyMapG18"
                            value={Inputs.CustomerEmpathyMapG18}
                            onChange={(event) => setInputs({ ...Inputs, CustomerEmpathyMapG18: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    </tr>
                    <tr>
                    <td className="bg-color2 text-white height30" colSpan={2}>
                        <input type="text" className="form-control inputText"
                            id="CustomerEmpathyMapC19"
                            value={Inputs.CustomerEmpathyMapC19}
                            onChange={(event) => setInputs({ ...Inputs, CustomerEmpathyMapC19: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    <td className="bg-color2 text-white height30" colSpan={2}>
                        <input type="text" className="form-control inputText"
                            id="CustomerEmpathyMapD19"
                            value={Inputs.CustomerEmpathyMapD19}
                            onChange={(event) => setInputs({ ...Inputs, CustomerEmpathyMapD19: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    <td className="d-hidden" colSpan={2} />
                    <td className="bg-color2 text-white height30" colSpan={2}>
                        <input type="text" className="form-control inputText"
                            id="CustomerEmpathyMapF19"
                            value={Inputs.CustomerEmpathyMapF19}
                            onChange={(event) => setInputs({ ...Inputs, CustomerEmpathyMapF19: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    <td className="bg-color2 text-white height30" colSpan={2}>
                        <input type="text" className="form-control inputText"
                            id="CustomerEmpathyMapG19"
                            value={Inputs.CustomerEmpathyMapG19}
                            onChange={(event) => setInputs({ ...Inputs, CustomerEmpathyMapG19: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    </tr>
                    <tr>
                    <td className="bg-color2 text-white height30" colSpan={2}>
                        <input type="text" className="form-control inputText"
                            id="CustomerEmpathyMapC20"
                            value={Inputs.CustomerEmpathyMapC20}
                            onChange={(event) => setInputs({ ...Inputs, CustomerEmpathyMapC20: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    <td className="bg-color2 text-white height30" colSpan={2}>
                        <input type="text" className="form-control inputText"
                            id="CustomerEmpathyMapD20"
                            value={Inputs.CustomerEmpathyMapD20}
                            onChange={(event) => setInputs({ ...Inputs, CustomerEmpathyMapD20: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    <td className="d-hidden" colSpan={2} />
                    <td className="bg-color2 text-white height30" colSpan={2}>
                        <input type="text" className="form-control inputText"
                            id="CustomerEmpathyMapF20"
                            value={Inputs.CustomerEmpathyMapF20}
                            onChange={(event) => setInputs({ ...Inputs, CustomerEmpathyMapF20: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    <td className="bg-color2 text-white height30" colSpan={2}>
                        <input type="text" className="form-control inputText"
                            id="CustomerEmpathyMapG20"
                            value={Inputs.CustomerEmpathyMapG20}
                            onChange={(event) => setInputs({ ...Inputs, CustomerEmpathyMapG20: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    </tr>
                    <tr>
                    <td className="bg-color2 text-white height30" colSpan={2}>
                        <input type="text" className="form-control inputText"
                            id="CustomerEmpathyMapC21"
                            value={Inputs.CustomerEmpathyMapC21}
                            onChange={(event) => setInputs({ ...Inputs, CustomerEmpathyMapC21: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    <td className="bg-color2 text-white height30" colSpan={2}>
                        <input type="text" className="form-control inputText"
                            id="CustomerEmpathyMapD21"
                            value={Inputs.CustomerEmpathyMapD21}
                            onChange={(event) => setInputs({ ...Inputs, CustomerEmpathyMapD21: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    <td className="d-hidden" colSpan={2} />
                    <td className="bg-color2 text-white height30" colSpan={2}>
                        <input type="text" className="form-control inputText"
                            id="CustomerEmpathyMapF21"
                            value={Inputs.CustomerEmpathyMapF21}
                            onChange={(event) => setInputs({ ...Inputs, CustomerEmpathyMapF21: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    <td className="bg-color2 text-white height30" colSpan={2}>
                        <input type="text" className="form-control inputText"
                            id="CustomerEmpathyMapG21"
                            value={Inputs.CustomerEmpathyMapG21}
                            onChange={(event) => setInputs({ ...Inputs, CustomerEmpathyMapG21: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    </tr>
                </tbody>
                </table>
                {/* table 2 */}
                <table className="table mb-0">
                <thead>
                    <tr>
                    <td className="bg-color1 text-white padding-0 width-25" colSpan={2}>
                        <div className="forces-div">
                        <span className="forces-img-bx">
                            <img src="../images/forces-icon1.png" alt="forces-icon1" />
                        </span>
                        <span className="forces-span">Thinks</span>
                        </div>
                    </td>
                    <td className="bg-color1 text-white padding-0 width-25" colSpan={2}>
                        <div className="forces-div">
                        <span className="forces-img-bx">
                            <img src="../images/forces-icon2.png" alt="forces-icon1" />
                        </span>
                        <span className="forces-span">Does</span>
                        </div>
                    </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td className="bg-color3 text-white" colSpan={2}>What occupies the customer's thoughts?</td>
                    <td className="bg-color3 text-white" colSpan={2}>What does the customer do in public?</td>
                    </tr>
                    <tr>
                    <td className="bg-color2 text-white height30" colSpan={2}>
                        <input type="text" className="form-control inputText"
                            id="CustomerEmpathyMapC24"
                            value={Inputs.CustomerEmpathyMapC24}
                            onChange={(event) => setInputs({ ...Inputs, CustomerEmpathyMapC24: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    <td className="bg-color2 text-white height30" colSpan={2}>
                        <input type="text" className="form-control inputText"
                            id="CustomerEmpathyMapD24"
                            value={Inputs.CustomerEmpathyMapD24}
                            onChange={(event) => setInputs({ ...Inputs, CustomerEmpathyMapD24: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    <td className="d-hidden" colSpan={2} />
                    </tr>
                    <tr>
                    <td className="bg-color2 text-white height30" colSpan={2}>
                        <input type="text" className="form-control inputText"
                            id="CustomerEmpathyMapC25"
                            value={Inputs.CustomerEmpathyMapC25}
                            onChange={(event) => setInputs({ ...Inputs, CustomerEmpathyMapC25: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    <td className="bg-color2 text-white height30" colSpan={2}>
                        <input type="text" className="form-control inputText"
                            id="CustomerEmpathyMapD25"
                            value={Inputs.CustomerEmpathyMapD25}
                            onChange={(event) => setInputs({ ...Inputs, CustomerEmpathyMapD25: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    <td className="d-hidden" colSpan={2} />
                    </tr>
                    <tr>
                    <td className="bg-color2 text-white height30" colSpan={2}>
                        <input type="text" className="form-control inputText"
                            id="CustomerEmpathyMapC26"
                            value={Inputs.CustomerEmpathyMapC26}
                            onChange={(event) => setInputs({ ...Inputs, CustomerEmpathyMapC26: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    <td className="bg-color2 text-white height30" colSpan={2}>
                        <input type="text" className="form-control inputText"
                            id="CustomerEmpathyMapD26"
                            value={Inputs.CustomerEmpathyMapD26}
                            onChange={(event) => setInputs({ ...Inputs, CustomerEmpathyMapD26: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    <td className="d-hidden" colSpan={2} />
                    </tr>
                    <tr>
                    <td className="bg-color2 text-white height30" colSpan={2}>
                        <input type="text" className="form-control inputText"
                            id="CustomerEmpathyMapC27"
                            value={Inputs.CustomerEmpathyMapC27}
                            onChange={(event) => setInputs({ ...Inputs, CustomerEmpathyMapC27: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    <td className="bg-color2 text-white height30" colSpan={2}>
                        <input type="text" className="form-control inputText"
                            id="CustomerEmpathyMapD27"
                            value={Inputs.CustomerEmpathyMapD27}
                            onChange={(event) => setInputs({ ...Inputs, CustomerEmpathyMapD27: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    <td className="d-hidden" colSpan={2} />
                    </tr>
                    <tr>
                    <td className="bg-color2 text-white height30" colSpan={2}>
                        <input type="text" className="form-control inputText"
                            id="CustomerEmpathyMapC28"
                            value={Inputs.CustomerEmpathyMapC28}
                            onChange={(event) => setInputs({ ...Inputs, CustomerEmpathyMapC28: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    <td className="bg-color2 text-white height30" colSpan={2}>
                        <input type="text" className="form-control inputText"
                            id="CustomerEmpathyMapD28"
                            value={Inputs.CustomerEmpathyMapD28}
                            onChange={(event) => setInputs({ ...Inputs, CustomerEmpathyMapD28: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    <td className="d-hidden" colSpan={2} />
                    </tr>
                </tbody>
                </table>
            </div>
            </div>
        </div>
    </div>
</div>

  )
}
