import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getQuestionsWithOptionsList, modifyUserScore } from "../../state/features/The120GrowthBenchmarkSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import { Link } from 'react-router-dom';

export default function CustomerReactivationContent() {
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const userID = users.data.profile.id;
    const [questionOptionList, setQuestionOptionList] = useState([]);
    const [userSelectedOptionIds, setUserSelectedOptionIds] = useState([]);
    
    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(clearMessage());
        dispatch(getQuestionsWithOptionsList({user_id : userID, slug : "customer-retention"}))
        .unwrap()
        .then((response) => {
          console.log("response",response.data.questionOptionList)
            if(response!= undefined){
                //console.log(response.data);
                setQuestionOptionList(response.data.questionOptionList)
                let optionIds = response.data.userScoreOption && response.data.userScoreOption.optionIds ? response.data.userScoreOption.optionIds.split(',') : []
                console.log(optionIds)
                return optionIds
            }
        })
        .then((optionIds=[]) => {
            console.log(optionIds)
            setUserSelectedOptionIds(optionIds)
        })
        .catch((error) => {
          console.log("error",error)
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onChangeHandler = (event) => {
        let is_checked = (event.target.checked) ? '1' : '0';
        let nameArr = event.target.id.split('_');
        console.log(nameArr)
        let is_multiselect = nameArr[3];
        let question_id = nameArr[1];
        let group = "checkbox_"+question_id;
        if(is_multiselect == 0){
            [...document.querySelectorAll("input[name="+group+"]")].map((input) => {
                if (input.checked && input.id !== event.target.id) {
                    input.checked = false
                }
                return null;
            })
        }
        
        let sendData = {user_id : userID, option_id: event.target.value, is_checked : is_checked, question_id : question_id}
        dispatch(modifyUserScore(sendData))
        .unwrap()
        .then((response) => {
          console.log("response",response)
            if(response!= undefined && response.status && response.status == 401){
                console.log('session expired...')
                window.location.reload()
            }
            else if(response!= undefined){
                console.log('success')
            }
        })
        .catch((error) => {
          console.log("error",error.status)
            { message && alert.show(message) }
        });
    }

  return (
    <div className="content-body content-body-new bg-white-color">
    <UserDropdown />
    {/* row */}
    <div className="container-fluid">
        <div className="head-title">
            <h3 className="preheading">Diagnose - Benchmark Questions</h3>
            <h1 className="mainheading mb-1">Customer Retention</h1>	            					
        </div>
            <div className="row remove-card-sd">
                <Spinner />
                <div className="col-xl-12 contentleft">
                <div className="card">
                <div className="card-body pl-0 mt-2" style={{maxWidth: 800}}>
                
                { (questionOptionList) ? questionOptionList.map((category, catKey) => {
                    return(
                    <>
                        <h4 key={'cat'+catKey} style={{padding: '22px 0px 0px 10px'}} className='font24px mb-3'>{category.category_name} </h4>
                        { (category.questions) ? category.questions.map((quesObj, quesKey) => {
                            return(
                            <>
                                <div className="questns table-responsive mb-3">
                                    <table className="table" style={{width: 800, marginBottom: 0}}>
                                        <thead key={'ques'+quesKey}>
                                            <tr key={'ques_'+quesKey} className="thead-primary">
                                                <td className='black-bg font12px text-white text-center' style={{width: 45}}>{quesObj.q_no_text}</td>
                                                <td colSpan={2} className='black-bg font12px text-white' style={{width: 755}}>
                                                    <div className="do-you-want">
                                                        <div className="do-you-content">{quesObj.bq_question}</div>
                                                        {(quesObj.q_slug) ? 
                                                        <div className="do-you-want-link">
                                                            <Link to={`/diagnose/${quesObj.q_slug}`}>Go To {quesObj.q_text}</Link>
                                                        </div>
                                                        : ''}
                                                    </div>
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        { (!quesObj.q_text && quesObj.options && quesObj.options[quesKey] != null) ? quesObj.options.map((optionObj, optKey) => {
                                                return(
                                                    <>
                                                        <tr key={'opt'+optKey}>
                                                            <td className='hide-content' style={{width: 45}}/>
                                                            <td className='bg-color-light-blue text-center text-black font12px' style={{width: 45}}>{String.fromCharCode(97+optKey)}</td>
                                                            <td className='bg-color-light-blue text-black font12px' style={{width: 710}}>
                                                                <input 
                                                                type="checkbox" 
                                                                name={`checkbox_${optionObj.question_id}`} 
                                                                id={`checkbox_${optionObj.question_id}_${optionObj.option_id}_${optionObj.is_multiselect}`}  
                                                                defaultChecked={userSelectedOptionIds.includes(optionObj.option_id.toString())}
                                                                value={optionObj.option_id} 
                                                                onChange={(event) => {
                                                                    onChangeHandler(event)
                                                                }}
                                                                /> 
                                                                &nbsp;&nbsp;{ optionObj.option }
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            })
                                            : 
                                            <tr>
                                                <td style={{width: 45}}/>
                                                <td className='bg-color1' style={{width: 45}}/>
                                                <td className='bg-color1' style={{width: 710}}/>
                                            </tr>
                                        }
                                        </tbody>
                                    </table>
                                </div>  
                            </>
                            )
                            })
                            : <h4>No data</h4>
                        }
                    </>
                    )
                }) : <h4>No data</h4>}

                <h4 key={'catXX'} style={{padding: '22px 0px 0px 10px'}}></h4>
                    <div className="questns table-responsive">
                        <table className="table" style={{width: 800, marginBottom: 0}}>
                            <thead key={'quesXX'}>
                                <tr key={'ques_XX'} className="thead-primary">
                                    <th className='black-bg font12px text-white text-center' style={{width: 45}}>XX</th>
                                    <th colSpan={2} className='black-bg font12px text-white' style={{width: 755}}>
                                        <div className="do-you-want">
                                            <div className="do-you-content">Points pulled from the Customer Retention Scorecard & Revenue Generation Scorecard</div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{width: 45}}/>
                                    <td className='bg-color1 text-white font12px text-white' style={{width: 45}}>a</td>
                                    <td className='bg-color1 text-white font12px text-white' style={{width: 710}}>
                                        <div className="do-you-want">
                                            <div className="do-you-content">Purchase Frequency</div>
                                            <div className="do-you-want-link">
                                                <Link to={`/diagnose/customer-retention-scorecard`}>Go To Customer Retention Scorecard</Link>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td />
                                    <td className='bg-color1 text-white font12px text-white'>b</td>
                                    <td className='bg-color1 text-white font12px text-white'> Customer Buying Lifetime</td>
                                </tr>
                                <tr>
                                    <td />
                                    <td className='bg-color1 text-white font12px text-white'>c</td>
                                    <td className='bg-color1 text-white font12px text-white'> Customer Service</td>
                                </tr>
                                <tr>
                                    <td />
                                    <td className='bg-color1 text-white font12px text-white'>d</td>
                                    <td className='bg-color1 text-white font12px text-white'> 
                                        <div className="do-you-want">
                                            <div className="do-you-content">Customer Attrition</div>
                                            <div className="do-you-want-link">
                                                <Link to={`/diagnose/revenue-generation-scorecard`}>Go To Revenue Generation Scorecard</Link>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td />
                                    <td className='bg-color1 text-white font12px text-white'>e</td>
                                    <td className='bg-color1 text-white font12px text-white'> Revenue Lost to Customer Attrition</td>
                                </tr>
                                <tr>
                                    <td />
                                    <td className='bg-color1 text-white font12px text-white'>f</td>
                                    <td className='bg-color1 text-white font12px text-white'> Inactive Customers</td>
                                </tr>
                                <tr>
                                    <td />
                                    <td className='bg-color1 text-white font12px text-white'>g</td>
                                    <td className='bg-color1 text-white font12px text-white'> Customer Reactivation</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
                    <div className="nextInput">
                        <ul>
                            <li>
                                <Link className="next-link" to="/diagnose/customer-transactions"> Next:  Customer Transactions
                                    <span className="icon-three">
                                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                                    </span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
