import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../features/Message";
import benchmarkScoreGapAnalysisService from "../../services/benchmarkScoreGapAnalysis.service";

//Get 120 Growth Benchmark data based on segment
export const getBenchmarkScoreGapAnalysis = createAsyncThunk(
  "120GrowthBQ/getBenchmarkScoreGapAnalysis",
  async ({user_id, slug}, thunkAPI) => {
    try {
      const response = await benchmarkScoreGapAnalysisService.getData(user_id, slug);
      //console.log(response)
      //thunkAPI.dispatch(setMessage(response.data.message));
      return response.data
    } catch (error) {
      console.log(error)
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

//add or edit BenchmarkScoreGapAnalysis
export const modifyBenchmarkScoreGapAnalysis = createAsyncThunk(
  "120GrowthBQ/modifyBenchmarkScoreGapAnalysis",
  async (postClientData , thunkAPI) => {
    try {
      const data = await benchmarkScoreGapAnalysisService.postData(postClientData);
      thunkAPI.dispatch(setMessage(""));
      console.log(data)
      return { BenchmarkScoreGapAnalysis: data };
    } catch (error) {
      console.log('errorcode',error.code)
      if(error.code == 'ERR_BAD_REQUEST'){
        return {status : 401}
      }
      else{
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const BenchmarkScoreGapAnalysisSlice = createSlice({
    name: 'BenchmarkScoreGapAnalysis',
    reducers: {
      
    },
    extraReducers: {
      [getBenchmarkScoreGapAnalysis.pending]: (state) => {
        state.loading = true
      },
      [getBenchmarkScoreGapAnalysis.fulfilled]: (state, { payload }) => {
        //console.log("---------get Data -----------", payload.data.BenchmarkScoreGapAnalysis)
        state.loading = false
      },
      [getBenchmarkScoreGapAnalysis.rejected]: (state) => {
        state.loading = false
      },
    },
  })

const { reducer } = BenchmarkScoreGapAnalysisSlice;
export default reducer;