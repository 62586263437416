import axios from "axios";
import authHeader from "./auth-header";
import { trackPromise } from 'react-promise-tracker';

const getData = (user_id, slug) => {
    return trackPromise (
        axios({
            method: "post",
            url: "120GrowthBQ/getQuestionsWithOptionsList",
            data: {user_id, slug},
            headers: authHeader()
        })
    )
};
const postData = (data) => {
    return trackPromise (
        axios({
            method: "post",
            url: "120GrowthBQ/modifyUserScore",
            data,
            headers: authHeader()
        })
    )
};

const the120GrowthBenchmarkService = {
    getData,
    postData,
};
export default the120GrowthBenchmarkService;
