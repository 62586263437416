import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state/index';
import  UserDropdown  from '../UserDropdown';
import { addEditImprovementPercentage } from "../../state/features/ClentInputSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { useAlert } from 'react-alert'
import { Spinner } from '../spinner'
import { StatesUseEffect } from '../../utils/StatesUseEffect';
import { BarChartGraphNew } from '../graph/BarChartGraphNew';

export default function ConversionContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const users = useSelector(userData);
    const userID = users.data.profile.id;
    const { message } = useSelector((state) => state.message);

    const dispatch = useDispatch();
    const {conversionData} = bindActionCreators(actionCreators,dispatch)
    const clientInputs = useSelector(state => state.clientInputs)
    const improvementPercentage = clientInputs.improvementPercentage
   
    const conversion = useSelector(state => state.conversion)

    const [Inputs, setInputs] = useState({
        conversionD14: Number(conversion.conversionD14).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})
    });

    const { clientInputsH26 } = clientInputs
    let conversionD13 = parseFloat(clientInputsH26) ? parseFloat(clientInputsH26) : 0 

    useEffect(() => {
        conversionData("conversionD13", conversionD13)
        conversionData("conversionD14", improvementPercentage.conversionD14)
      }, [])

    const { conversionD18, conversionE18, conversionF18, conversionG18, conversionH18, conversionI18, conversionD19, conversionE19, conversionF19, conversionG19, conversionH19, conversionI19, conversionD23, conversionE23, conversionF23, conversionG23, conversionH23, conversionI23, conversionD24, conversionE24, conversionF24, conversionG24, conversionH24, conversionI24, conversionD25, conversionE25, conversionF25, conversionG25, conversionH25, conversionI25, conversionD26, conversionE26, conversionF26, conversionG26, conversionH26, conversionI26, conversionD28, conversionE28, conversionF28, conversionG28, conversionH28, conversionI28, conversionD33, conversionE33, conversionF33, conversionG33, conversionH33, conversionI33, conversionD34, conversionE34, conversionF34, conversionG34, conversionH34, conversionI34, conversionD36, conversionE36, conversionF36, conversionG36, conversionH36, conversionI36, conversionD37, conversionE37, conversionF37, conversionG37, conversionH37, conversionI37, conversionD39, conversionE39, conversionF39, conversionG39, conversionH39, conversionI39 } = conversion

    const improvementPrcntHandler = (impPrcnt) => {
        setIsLoading(true);
        let sendData = {user_id : userID, conversionD14: impPrcnt}
        dispatch(addEditImprovementPercentage(sendData))
        .unwrap()
        .then((response) => {
          console.log("response",response)
            if(response!= undefined){
               console.log('success')
            }
            setIsLoading(false)   // Hide loading screen 
        })
        .catch((error) => {
          console.log("error",error)
            { message && alert.show(message) }
            setIsLoading(false)   // Hide loading screen 
        });
    }

    // call states useEffect utils
    StatesUseEffect()

    return (
        <div className="content-body content-body-new bg-white-color">
        <UserDropdown />
    
            {/* row */}
            <div className="container-fluid lead-generation-new">
                <div className="head-title mb-5">
                    <h3 className='preheading'>Prescribe - Growth Drivers</h3>
                    <h1 className='mainheading mb-1'>Conversion</h1>	
                </div>
                <div className="row lead-genearation-rw  mb-5" style={{maxWidth: 1200}}>
                    <div className="col-12 col-lg-6">
                        {/* <div className="potential-growth-ld mb-3" style={{maxWidth: 450}}>
                            <h3><strong>Potential Growth Activities or Tactics:</strong></h3>
                            <ol>
                                <li>Activity 1</li>
                                <li>Activity 2</li>
                                <li>Activity 3</li>
                            </ol>
                        </div> */}
                        <div className="lead-generation-table  mt-218" style={{overflowY: 'auto'}}>
                        <div className="responsive-table">
                            <table className='table' style={{width: 450}}>
                                <thead>
                                    <tr>
                                        <th scope='col' className='black-bg text-white fontBold' style={{width: 300}}>Conversion</th>
                                        <th className='black-bg hide-content' style={{width: 150}}>2</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td scope='row' className='bg-color-light-blue font12px color-black'>Beginning Conversion Rate</td>
                                            <td className='bg-color-light-blue font12px color-black text-right-rt'>{Number(clientInputs.clientInputsH26).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}%</td>
                                        </tr>
                                        <tr>
                                            <td scope='row' className='bg-color-light-blue font12px color-black'>Projected Improvement</td>
                                            <th scope='row' className='padding-0'>
                                                <div className="dollor-sin-pr">
                                                    <div className="input-fiel-cn bg-color-01 client-inpt-field">
                                                        <input type="text" 
                                                        name="Inputs.conversionD14"
                                                        className="field-ipt wthinherit font12px bg-color-01 text-right text-black" 
                                                        placeholder={0} 
                                                        value={Inputs.conversionD14} 
                                                        onChange={(event) => {
                                                            setInputs({...Inputs, conversionD14 : event.target.value});
                                                            conversionData("conversionD14", event.target.value);
                                                            improvementPrcntHandler(event.target.value)
                                                        }} 
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                            }
                                                        }}
                                                        disabled={isLoading} />
                                                    </div>
                                                    <div className="perecentage">%</div>
                                                </div>
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                                <Spinner />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6" style={{minHeight: 400}}>
                        <BarChartGraphNew  dataInput={{
                            "Title"      : "Conversion: Impact on Financial Performance",
                            "ColorCodes" : ["#d6dce5", "#8497b0", "#1e2b3c"],
                            "GraphData"  : {
                                labels: [["Your","Projection"], "Year 1", "Year 2", "Year 3", "Year 4", "Year 5"],
                                datasets: [
                                    {
                                        label: "Profit",
                                        backgroundColor: "#1e65a7", //"#d6dce5",
                                        data: [Number(conversionD34),Number(conversionE34),Number(conversionF34),Number(conversionG34),Number(conversionH34),Number(conversionI34)]
                                    },
                                    {
                                        label: "Sales",
                                        backgroundColor: "#8497b0",
                                        data: [Number(conversionD33),Number(conversionE33),Number(conversionF33),Number(conversionG33),Number(conversionH33),Number(conversionI33)]
                                    },
                                    {
                                        label: "Valuation",
                                        backgroundColor: "#1e2b3c",
                                        data: [Number(conversionD39),Number(conversionE39),Number(conversionF39),Number(conversionG39),Number(conversionH39),Number(conversionI39)]
                                    }
                                ]
                            }
                            }} />
                        
                    </div>
                </div>
                {/* <div className="">
                    <h3 className='text-center impact-lead-titile'><strong>Conversion: Impact on Financial Performance</strong></h3> 
                    
                    <BarChartGraph dataInput={{
                        "Title"      : "",
                        "vTitle"     : "",
                        "hTitle"     : "",
                        "subTitle"   : "",
                        "ColorCodes" : ["#c2c7cd", "#8497b0", "#44546a"],
                        "GraphData"  : [
                            ["", "Profit", "Sales", "Valuation"],
                            ["Year 1", Number(conversionE34), Number(conversionE33), Number(conversionE39)],
                            ["Year 2", Number(conversionF34), Number(conversionF33), Number(conversionF39)],
                            ["Year 3", Number(conversionG34), Number(conversionG33), Number(conversionG39)],
                            ["Year 4", Number(conversionH34), Number(conversionH33), Number(conversionH39)],
                            ["Year 5", Number(conversionI34), Number(conversionI33), Number(conversionI39)]
                        ]
                    }} />
                </div> */}
                <div className="card update-class-lead">
                    <div className="card-body pl-0">
                        <div className="table-responsive">
                            <table className="table table-responsive-md table-striped mb-0" style={{width: 1200}}>
                                <thead>
                                    <tr>
                                    <th className="table-primar" style={{width: 300}}/>
                                    <th colSpan={2} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Year 1 Gains</th>
                                    <th colSpan={2} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Mid-Term Gains</th>
                                    <th colSpan={5} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Long-Term Gains </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="thead-primary">
                                        <th className='black-bg fontBold' style={{width: 300}}>Conversion Rate Improvement</th>
                                        <th className='black-bg' style={{width: 150}}>Your Projection</th>
                                        <th className='black-bg' style={{width: 150}}>1% Quarterly Improvement</th>
                                        <th className='black-bg' style={{width: 150}}>2 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>3 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>4 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>5 Years of 1% Improvements</th>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>Potential Improvement in Conversion Rate</td>
                                        <td className='bg-color-blue-gray text-white font12px'>{Number(conversionD18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}%</td>
                                        <td className='bg-color-light-blue font12px color-black'>{Number(conversionE18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}%</td>
                                        <td className='bg-color-light-blue font12px color-black'>{Number(conversionF18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}%</td>
                                        <td className='bg-color-light-blue font12px color-black'>{Number(conversionG18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}%</td>
                                        <td className='bg-color-light-blue font12px color-black'>{Number(conversionH18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}%</td>
                                        <td className='bg-color-light-blue font12px color-black'>{Number(conversionI18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}%</td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'><strong>New Customers from Improved Conversion</strong></td>
                                        <td className='bg-color-blue-gray text-white font12px'><strong>{Number(conversionD19).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong></td>
                                        <td className='bg-color-teal text-white font12px'><strong>{Number(conversionE19).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong></td>
                                        <td className='bg-color-teal text-white font12px'><strong>{Number(conversionF19).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong></td>
                                        <td className='bg-color-teal text-white font12px'><strong>{Number(conversionG19).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong></td>
                                        <td className='bg-color-teal text-white font12px'><strong>{Number(conversionH19).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong></td>
                                        <td className='bg-color-teal text-white font12px'><strong>{Number(conversionI19).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong></td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="card update-class-lead">
                        <div className="card-body pl-0">
                        <div className="table-responsive">
                            <table className="table table-responsive-md table-striped mb-0" style={{width: 1200}}>
                                <thead>
                                    <tr>
                                        <th className="table-primar" style={{width: 300}}/>
                                        <th colSpan={2} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Year 1 Gains</th>
                                        <th colSpan={2} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Mid-Term Gains</th>
                                        <th colSpan={5} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Long-Term Gains </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="thead-primary">
                                        <th className='black-bg fontBold' style={{width: 300}}>Converting Unclosed Leads</th>
                                        <th className='black-bg' style={{width: 150}}>Your Projection</th>
                                        <th className='black-bg' style={{width: 150}}>1% Quarterly Improvement</th>
                                        <th className='black-bg' style={{width: 150}}>2 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>3 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>4 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>5 Years of 1% Improvements</th>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>Number of Unclosed leads Last year</td>
                                        <td className='bg-color-blue-gray text-white font12px'>{Number(conversionD23).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</td>
                                        <td className='bg-color-light-blue font12px color-black'>{Number(conversionE23).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</td>
                                        <td className='bg-color-light-blue font12px color-black'>{Number(conversionF23).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</td>
                                        <td className='bg-color-light-blue font12px color-black'>{Number(conversionG23).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</td>
                                        <td className='bg-color-light-blue font12px color-black'>{Number(conversionH23).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</td>
                                        <td className='bg-color-light-blue font12px color-black'>{Number(conversionI23).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>Number of Unclosed leads From TWO Year Ago</td>
                                        <td className="adl-matrix-color-bg">{Number(conversionD24).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</td>
                                        <td className="adl-matrix-color-bg">{Number(conversionE24).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</td>
                                        <td className='bg-color-light-blue font12px color-black'>{Number(conversionF24).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</td>
                                        <td className='bg-color-light-blue font12px color-black'>{Number(conversionG24).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</td>
                                        <td className='bg-color-light-blue font12px color-black'>{Number(conversionH24).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</td>
                                        <td className='bg-color-light-blue font12px color-black'>{Number(conversionI24).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>Total Number of Unclosed Leads</td>
                                        <td className='bg-color-blue-gray text-white font12px'>{Number(conversionD25).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</td>
                                        <td className='bg-color-light-blue font12px color-black'>{Number(conversionE25).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</td>
                                        <td className='bg-color-light-blue font12px color-black'>{Number(conversionF25).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</td>
                                        <td className='bg-color-light-blue font12px color-black'>{Number(conversionG25).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</td>
                                        <td className='bg-color-light-blue font12px color-black'>{Number(conversionH25).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</td>
                                        <td className='bg-color-light-blue font12px color-black'>{Number(conversionI25).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'><strong>New Customers from Unclosed Leads</strong></td>
                                        <td className='bg-color-blue-gray text-white font12px'><strong>{Number(conversionD26).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong></td>
                                        <td className='bg-color-teal text-white font12px'><strong>{Number(conversionE26).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong></td>
                                        <td className='bg-color-teal text-white font12px'><strong>{Number(conversionF26).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong></td>
                                        <td className='bg-color-teal text-white font12px'><strong>{Number(conversionG26).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong></td>
                                        <td className='bg-color-teal text-white font12px'><strong>{Number(conversionH26).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong></td>
                                        <td className='bg-color-teal text-white font12px'><strong>{Number(conversionI26).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong></td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="card update-class-lead">
                        <div className="card-body pl-0">
                            <div className="table-responsive">
                                <table className="table table-responsive-md table-striped mb-0" style={{width: 1200}}>
                                <tbody>
                                    <tr>
                                        <th className='black-bg fontBold' style={{width: 300}}>Total New Customer from Improved Conversion</th>
                                        <td className='bg-color-blue-gray text-white font12px' style={{width: 150}}><strong>{Number(conversionD28).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong></td>
                                        <td className='bg-color-teal text-white font12px' style={{width: 150}}><strong>{Number(conversionE28).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong></td>
                                        <td className='bg-color-teal text-white font12px' style={{width: 150}}><strong>{Number(conversionF28).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong></td>
                                        <td className='bg-color-teal text-white font12px' style={{width: 150}}><strong>{Number(conversionG28).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong></td>
                                        <td className='bg-color-teal text-white font12px' style={{width: 150}}><strong>{Number(conversionH28).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong></td>
                                        <td className='bg-color-teal text-white font12px' style={{width: 150}}><strong>{Number(conversionI28).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong></td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="card update-class-lead">
                            <div className="card-body pl-0">
                            <div className="table-responsive">
                                <table className="table table-responsive-md table-striped mb-0" style={{width: 1200}}>
                                    <thead>
                                        <tr>
                                            <th className='nowrap' style={{background:'#fff', width: 300}}><h3>Financial Impact</h3></th>
                                            <th colSpan={2} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Year 1 Gains</th>
                                            <th colSpan={2} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Mid-Term Gains</th>
                                            <th colSpan={5} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Long-Term Gains </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <tr className="thead-primary">
                                        <th className='black-bg fontBold' style={{width: 300}}>Annual Impact of Conversion Improvement</th>
                                        <th className='black-bg' style={{width: 150}}>Your Projection</th>
                                        <th className='black-bg' style={{width: 150}}>1% Quarterly Improvement</th>
                                        <th className='black-bg' style={{width: 150}}>2 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>3 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>4 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>5 Years of 1% Improvements</th>
                                    </tr>
                                    <tr className="thead-primary">
                                        <td className='bg-color-light-blue font12px color-black'>Annual Sales</td>
                                        <td className='bg-color-blue-gray text-white font12px'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px text-white"> $ </div>
                                                <div className="annual-dl-number font12px text-white">{Number(conversionD33).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(conversionE33).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(conversionF33).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(conversionG33).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(conversionH33).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(conversionI33).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="thead-primary">
                                        <td className='bg-color-light-blue font12px color-black'>Annual Profit</td>
                                        <td className='bg-color-blue-gray text-white font12px'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px text-white"> $ </div>
                                                <div className="annual-dl-number font12px text-white">
                                                {Number(conversionD34).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(conversionE34).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(conversionF34).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(conversionG34).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(conversionH34).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(conversionI34).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="thead-primary">
                                        <th className='black-bg fontBold' style={{width: 300}}>Lifetime Impact of Conversion Improvement</th>
                                        <th className='black-bg' style={{width: 150}}>Your Projection</th>
                                        <th className='black-bg' style={{width: 150}}>1% Quarterly Improvement</th>
                                        <th className='black-bg' style={{width: 150}}>2 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>3 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>4 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>5 Years of 1% Improvements</th>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>Lifetime Sales</td>
                                        <td className='bg-color-blue-gray text-white font12px'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px text-white"> $ </div>
                                                <div className="annual-dl-number font12px text-white">
                                                    {Number(conversionD36).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(conversionE36).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(conversionF36).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(conversionG36).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(conversionH36).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(conversionI36).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>Lifetime Profit</td>
                                        <td className='bg-color-blue-gray text-white font12px'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px text-white"> $ </div>
                                                <div className="annual-dl-number font12px text-white">
                                                    {Number(conversionD37).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(conversionE37).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(conversionF37).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(conversionG37).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(conversionH37).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(conversionI37).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="thead-primary">
                                        <th className='black-bg fontBold' style={{width: 300}}>Valuation Impact of Conversion Improvement</th>
                                        <th className='black-bg' style={{width: 150}}>Your Projection</th>
                                        <th className='black-bg' style={{width: 150}}>1% Quarterly Improvement</th>
                                        <th className='black-bg' style={{width: 150}}>2 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>3 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>4 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>5 Years of 1% Improvements</th>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>Estimated Valuation Impact</td>
                                        <td className='bg-color-blue-gray text-white font12px'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px text-white"> $ </div>
                                                <div className="annual-dl-number font12px text-white">
                                                {Number(conversionD39).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(conversionE39).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(conversionF39).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(conversionG39).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(conversionH39).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(conversionI39).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="nextInput">
                            <ul>
                                <li>
                                    <Link to="/prescribe/referrals" className='next-link'> Next:  Referrals  
                                        <span className='icon-three'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                        </span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
