import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getPyramidOfPurpose, modifyPyramidOfPurpose } from "../../state/features/PyramidOfPurposeSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';

export default function PyramidOfPurposeContent() {

    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        pyramidOfPurposeI13 : '',
        pyramidOfPurposeI17 : '',
        pyramidOfPurposeI21 : '',
        pyramidOfPurposeI25 : ''
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getPyramidOfPurpose({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.growthPlanning)
            if(response!= undefined){
                let growthPlanning = response.data.growthPlanning[0];
                console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key]
                    }
                }
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyPyramidOfPurpose({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

  return (
      <div className="content-body">
    <UserDropdown />
    
        {/* row */}
      <div className="container-fluid">
        <div className="head-title">
            <h2>Pyramid of Purpose</h2>	  
        </div> 
        <div className="card">
            <div className="card-body contentleft">
            <p>The Pyramid of Purpose is a tool that can help plan and communicate a business strategy across an organization. Each level of the pyramid represents a different question about the strategy that an employee could ask.  Starting at the base and working its way up, the answer to one question leads to the next:</p>  
            <ul>
                <li><b>Why? </b>-  The strategies that are created should all be created in service of fulfilling the mission of the company.</li>
                <li><b>What?</b> - The organization should list out the goals that will further its progress towards making its mission into a reality.</li>
                <li><b>How? </b>- This process involves determining what specific actions will be taken and when, as well as the resources that are needed.</li>
                <li><b>Who? </b>- Finally, in order to answer this question, the organization must identify who will be responsible for completing each task.</li>
            </ul>                 
            </div>
        </div>
        <Spinner />
        <div className="card">
            <div className="card-body pl-2">
            <div className="row">
                <div className="col-md-6 mt-4">
                    <img src={window.location.origin+"/images/pyramid-of-purpose.png"} alt="Strategy Foundation" title="Strategy Foundation" />
                </div>
                <div className="col-md-6">
                    <div className="d-flex flex-colomn">
                        <h3>4. Who?</h3>
                        <div className="formgrp">
                        <textarea className="from-control" rows={3} 
                            id="pyramidOfPurposeI13" 
                            value={Inputs.pyramidOfPurposeI13} 
                            onChange={(event) => setInputs({ ...Inputs, pyramidOfPurposeI13: event.target.value }) } 
                            onBlur={(event) => { onBlurHandler(event) }} 
                            disabled={isLoading}/>
                        </div>
                    </div>
                    <div className="d-flex flex-colomn">
                        <h3>3. How?</h3>
                        <div className="formgrp">
                        <textarea className="from-control" rows={3} 
                            id="pyramidOfPurposeI17" 
                            value={Inputs.pyramidOfPurposeI17} 
                            onChange={(event) => setInputs({ ...Inputs, pyramidOfPurposeI17: event.target.value }) } 
                            onBlur={(event) => { onBlurHandler(event) }} 
                            disabled={isLoading}/>
                        </div>
                    </div>
                    <div className="d-flex flex-colomn">
                        <h3>2. What</h3>
                        <div className="formgrp">
                        <textarea className="from-control" rows={3} 
                            id="pyramidOfPurposeI21" 
                            value={Inputs.pyramidOfPurposeI21} 
                            onChange={(event) => setInputs({ ...Inputs, pyramidOfPurposeI21: event.target.value }) } 
                            onBlur={(event) => { onBlurHandler(event) }} 
                            disabled={isLoading}/>
                        </div>
                    </div>
                    <div className="d-flex flex-colomn">
                        <h3>1. Why?</h3>
                        <div className="formgrp">
                        <textarea className="from-control" rows={3} 
                            id="pyramidOfPurposeI25" 
                            value={Inputs.pyramidOfPurposeI25} 
                            onChange={(event) => setInputs({ ...Inputs, pyramidOfPurposeI25: event.target.value }) } 
                            onBlur={(event) => { onBlurHandler(event) }} 
                            disabled={isLoading}/>
                        </div>
                    </div>
                </div>               
            </div>           
            </div>
        </div>
        </div>

    </div>

  )
}
