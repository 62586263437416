import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import sum from "../../utils/sum";
import { Link } from 'react-router-dom';
import formatPrice from '../../utils/formatPrice';
import { getTargetMarketsScorecard, modifyTargetMarketsScorecard } from '../../state/features/TargetMarketsScorecardSlice';

export default function TargetMarketsScorecardScorecardContentNew() {

    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        TargetMarketsScorecardD9: 0,
        TargetMarketsScorecardD10: 0,
        TargetMarketsScorecardD11: 0,
        TargetMarketsScorecardD12: 0,
        TargetMarketsScorecardD13: 0,
        TargetMarketsScorecardE8: "",
        TargetMarketsScorecardF9: "",
        TargetMarketsScorecardD15: 0,
        TargetMarketsScorecardD16: 0,
        TargetMarketsScorecardD17: 0,
        TargetMarketsScorecardD18: 0,
        TargetMarketsScorecardD19: 0,
        TargetMarketsScorecardE14: "",
        TargetMarketsScorecardF15: "",
        TargetMarketsScorecardE20: "",
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getTargetMarketsScorecard({user_id}))
        .unwrap()
        .then((response) => {
            // console.log("respons23e",response.data)
            if(response!= undefined  && response.data.clientGrowth){
                let clientGrowth = response.data.clientGrowth[0];
                // console.log(clientGrowth)
                delete clientGrowth.id;
                delete clientGrowth.user_id;
                delete clientGrowth.created_at;
                delete clientGrowth.updated_at;
                const newObj = { ...Inputs }
                for (const key in clientGrowth) {
                    if (clientGrowth.hasOwnProperty(key)) {
                        if(clientGrowth[key])
                        newObj[key] = clientGrowth[key]
                    }
                }
                
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])
      //
    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                event.target.value = event.target.value ? event.target.value : 0
                dispatch(modifyTargetMarketsScorecard({ user_id, [event.target.id] : event.target.value, 'TargetMarketsScorecardE8':String(Inputs.TargetMarketsScorecardE8), "TargetMarketsScorecardE14":String(Inputs.TargetMarketsScorecardE14), "TargetMarketsScorecardE20":String(Inputs.TargetMarketsScorecardE20)}))
                .unwrap()
                .then((response) => {
                    // console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        // window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

    useEffect(() => {
        let TargetMarketsScorecardE8 = sum(Number(Inputs.TargetMarketsScorecardD9), Number(Inputs.TargetMarketsScorecardD10), Number(Inputs.TargetMarketsScorecardD11), Number(Inputs.TargetMarketsScorecardD12), Number(Inputs.TargetMarketsScorecardD13));
        let TargetMarketsScorecardE14 = sum(Number(Inputs.TargetMarketsScorecardD15), Number(Inputs.TargetMarketsScorecardD16), Number(Inputs.TargetMarketsScorecardD17), Number(Inputs.TargetMarketsScorecardD18), Number(Inputs.TargetMarketsScorecardD19));
        let TargetMarketsScorecardE20 = TargetMarketsScorecardE8 + TargetMarketsScorecardE14;
        // console.log(Inputs.TargetMarketsScorecardD9, Inputs.TargetMarketsScorecardD10, Inputs.TargetMarketsScorecardD11)
        setInputs({...Inputs, TargetMarketsScorecardE8, TargetMarketsScorecardE14, TargetMarketsScorecardE20});

    }, [Inputs.TargetMarketsScorecardD9, Inputs.TargetMarketsScorecardD10, Inputs.TargetMarketsScorecardD11, Inputs.TargetMarketsScorecardD12, Inputs.TargetMarketsScorecardD13, Inputs.TargetMarketsScorecardD15, Inputs.TargetMarketsScorecardD16, Inputs.TargetMarketsScorecardD17, Inputs.TargetMarketsScorecardD18, Inputs.TargetMarketsScorecardD19]);

    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
            {/* row */}
            <div className="container-fluid">
                <div className="head-title mb-4">
                    <h3 className='preheading'>Diagnose - Benchmark Tools</h3>
                    <h1 className='mainheading'>Target Markets Scorecard</h1>
                </div>
                <div className="target-markets-score-card-table" style={{maxWidth: 1400}}>
                    <div className="table-responsive">
                        <table className="table" style={{width: 1400}}>
                            {/* table 01 */}
                            <thead>
                                <tr>
                                    <th className='black-bg text-white' style={{width: 600}}>
                                        <div className="what-do-you-me">
                                            <p>What do you know about your target markets and buyers?</p>
                                        </div>
                                    </th>
                                    <th className='black-bg text-white text-center' style={{width: 150}}>
                                        <span className='font14px'>Score</span><br/>
                                        <span className='font9px'>(1 point each)</span>
                                    </th>
                                    <th className='bg-color-blue text-white text-center font16px' style={{width: 100}}>
                                        <div className="do-you-number">{formatPrice(Inputs.TargetMarketsScorecardE8, 0)}</div>
                                    </th>
                                    <th className='black-bg text-white text-center' style={{width: 550}}>
                                        <div className="opportunity-improve">
                                            <p>Opportunities to Improve</p>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='bg-color-light-blue font12px color-dark'>
                                        <div className="we-understand">
                                            <p>We understand the size and growth rate for each addressable market.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font12px bg-color-01 text-center text-black" 
                                                id="TargetMarketsScorecardD9" 
                                                value={Inputs.TargetMarketsScorecardD9} 
                                                onChange={(event) => setInputs({ ...Inputs, TargetMarketsScorecardD9: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td rowSpan={5} className='bg-white padding-0'>
                                        <div className="arrow-targer-market"></div>
                                    </td>
                                    <td rowSpan={5} className='padding-0'>
                                        <div className="input-fiel-cn">
                                            <textarea type="text" className="field-ipt bg-color-02 font12px"
                                            id="TargetMarketsScorecardF9"
                                            value={Inputs.TargetMarketsScorecardF9}
                                            onChange={(event) => setInputs({ ...Inputs, TargetMarketsScorecardF9: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue font12px color-dark'>
                                        <div className="we-understand">
                                            <p>We have identified exact companies and contacts we should market to.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font12px bg-color-01 text-center text-black" 
                                                id="TargetMarketsScorecardD10" 
                                                value={Inputs.TargetMarketsScorecardD10} 
                                                onChange={(event) => setInputs({ ...Inputs, TargetMarketsScorecardD10: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue font12px color-dark'>
                                        <div className="we-understand">
                                            <p>We have identified potential major accounts within these markets.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font12px bg-color-01 text-center text-black" 
                                                id="TargetMarketsScorecardD11" 
                                                value={Inputs.TargetMarketsScorecardD11} 
                                                onChange={(event) => setInputs({ ...Inputs, TargetMarketsScorecardD11: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue font12px color-dark'>
                                        <div className="we-understand">
                                            <p>We clearly understand the reasons why they buy and don’t buy from us.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font12px bg-color-01 text-center text-black" 
                                                id="TargetMarketsScorecardD12" 
                                                value={Inputs.TargetMarketsScorecardD12} 
                                                onChange={(event) => setInputs({ ...Inputs, TargetMarketsScorecardD12: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue font12px color-dark'>
                                        <div className="we-understand">
                                            <p>We are actively marketing and selling to each of these target markets.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font12px bg-color-01 text-center text-black" 
                                                id="TargetMarketsScorecardD13" 
                                                value={Inputs.TargetMarketsScorecardD13} 
                                                onChange={(event) => setInputs({ ...Inputs, TargetMarketsScorecardD13: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <thead>
                                <tr>
                                    <th className='black-bg text-white'>
                                        <div className="what-do-you-me">
                                            <p>What do you know about your "Tier 1" customers - the 20% of your prospects that create 80% of your sales?</p>
                                        </div>
                                    </th>
                                    <th className='black-bg text-white text-center' style={{width: 150}}>
                                        <span className='font14px'>Score</span><br/>
                                        <span className='font9px'>(1 point each)</span>
                                    </th>
                                    <th className='bg-color-blue text-white text-center font16px'>
                                        <div className="do-you-number">{formatPrice(Inputs.TargetMarketsScorecardE14, 0)}</div>
                                    </th>
                                    <th className='black-bg text-white text-center'>
                                        <div className="opportunity-improve">
                                            <p>Opportunities to Improve</p>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='bg-color-light-blue font12px color-dark'>
                                        <div className="we-understand">
                                            <p>The number of Tier 1 customers we have; sales and profit they generate; this information is updated and reviewed quarterly.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font12px bg-color-01 text-center text-black" 
                                                id="TargetMarketsScorecardD15" 
                                                value={Inputs.TargetMarketsScorecardD15} 
                                                onChange={(event) => setInputs({ ...Inputs, TargetMarketsScorecardD15: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td rowSpan={5} className='bg-white padding-0'>
                                        <div className="arrow-targer-market"></div>
                                    </td>
                                    <td rowSpan={5} className='padding-0'>
                                        <div className="input-fiel-cn">
                                            <textarea type="text" className="field-ipt bg-color-02 font12px w-100"
                                            id="TargetMarketsScorecardF15"
                                            value={Inputs.TargetMarketsScorecardF15}
                                            onChange={(event) => setInputs({ ...Inputs, TargetMarketsScorecardF15: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue font12px color-dark'>
                                        <div className="we-understand">
                                            <p>We understand the commonalities of our Tier 1 customers and use this information to identify and approach more prospects like them.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font12px bg-color-01 text-center text-black" 
                                                id="TargetMarketsScorecardD16" 
                                                value={Inputs.TargetMarketsScorecardD16} 
                                                onChange={(event) => setInputs({ ...Inputs, TargetMarketsScorecardD16: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue font12px color-dark'>
                                        <div className="we-understand">
                                            <p>We have a formal system with accountabilities to target and win more "Tier 1" customers.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font12px bg-color-01 text-center text-black" 
                                                id="TargetMarketsScorecardD17" 
                                                value={Inputs.TargetMarketsScorecardD17} 
                                                onChange={(event) => setInputs({ ...Inputs, TargetMarketsScorecardD17: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue font12px color-dark'>
                                        <div className="we-understand">
                                            <p>We provide training for our sales and marketing staff on how to identify and communicate specifically with Tier 1 customers.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font12px bg-color-01 text-center text-black" 
                                                id="TargetMarketsScorecardD18" 
                                                value={Inputs.TargetMarketsScorecardD18} 
                                                onChange={(event) => setInputs({ ...Inputs, TargetMarketsScorecardD18: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue font12px color-dark'>
                                        <div className="we-understand">
                                            <p>We have a formal system with accountabilities to move high-potential Tier 2 customers up to Tier 1 status.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font12px bg-color-01 text-center text-black" 
                                                id="TargetMarketsScorecardD19" 
                                                value={Inputs.TargetMarketsScorecardD19} 
                                                onChange={(event) => setInputs({ ...Inputs, TargetMarketsScorecardD19: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            {/* table 03 */}
                            <thead>
                                <tr>
                                    <th className='bg-white'>
                                        <div className="nextInput">
                                            <ul>
                                                <li>
                                                    <Link className="next-link" to="/diagnose/competitive-advantage"> Return to Benchmark Questions
                                                        <span className="icon-three">
                                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                                        </span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </th>
                                    <th className='black-bg text-white'>
                                        <div className="score-point-each">
                                            <p><strong className='scre-fonts'>Total</strong></p>
                                        </div>
                                    </th>
                                    <th className='bg-color-blue text-white text-center font16px'>
                                        <div className="do-you-number">{formatPrice(Inputs.TargetMarketsScorecardE20, 0)}</div>
                                    </th>
                                    <th className='hide-content'>
                                        <div className="opportunity-improve">
                                            <p>Opportunities to Improve</p>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </div>  
                </div>
            </div>
        </div>
    );
}