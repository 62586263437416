import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import sum from "../../utils/sum";
import { Link } from 'react-router-dom';
import formatPrice from '../../utils/formatPrice';
import { getAlignmentAssessment, modifyAlignmentAssessment } from '../../state/features/AlignmentAssessmentSlice';

export default function AlignmentAssessmentContentNew() {

    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        AlignmentAssessmentD9: 0,
        AlignmentAssessmentD10: 0,
        AlignmentAssessmentD11: 0,
        AlignmentAssessmentD12: 0,
        AlignmentAssessmentD13: 0,
        AlignmentAssessmentE8: "",
        AlignmentAssessmentF9: "",
        AlignmentAssessmentD15: 0,
        AlignmentAssessmentD16: 0,
        AlignmentAssessmentD17: 0,
        AlignmentAssessmentD18: 0,
        AlignmentAssessmentD19: 0,
        AlignmentAssessmentE14: "",
        AlignmentAssessmentF15: "",
        AlignmentAssessmentE20: "",
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getAlignmentAssessment({user_id}))
        .unwrap()
        .then((response) => {
            console.log("response1",response.data)
            if(response!= undefined  && response.data.clientGrowth){
                let clientGrowth = response.data.clientGrowth[0];
                // console.log(clientGrowth)
                delete clientGrowth.id;
                delete clientGrowth.user_id;
                delete clientGrowth.created_at;
                delete clientGrowth.updated_at;
                const newObj = { ...Inputs }
                for (const key in clientGrowth) {
                    if (clientGrowth.hasOwnProperty(key)) {
                        if(clientGrowth[key])
                        newObj[key] = clientGrowth[key]
                    }
                }
                
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])
        
    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyAlignmentAssessment({ user_id, [event.target.id] : event.target.value, 'AlignmentAssessmentE8': String(Inputs.AlignmentAssessmentE8), 'AlignmentAssessmentE14': String(Inputs.AlignmentAssessmentE14), 'AlignmentAssessmentE20': String(Inputs.AlignmentAssessmentE20)}))
                .unwrap()
                .then((response) => {
                    // console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        //window.location.reload()
                    }else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }


    useEffect(() => {
        let AlignmentAssessmentE8 = sum(Number(Inputs.AlignmentAssessmentD9), Number(Inputs.AlignmentAssessmentD10), Number(Inputs.AlignmentAssessmentD11), Number(Inputs.AlignmentAssessmentD12), Number(Inputs.AlignmentAssessmentD13));
        let AlignmentAssessmentE14 = sum(Number(Inputs.AlignmentAssessmentD15), Number(Inputs.AlignmentAssessmentD16), Number(Inputs.AlignmentAssessmentD17), Number(Inputs.AlignmentAssessmentD18), Number(Inputs.AlignmentAssessmentD19));
        let AlignmentAssessmentE20 = AlignmentAssessmentE8 + AlignmentAssessmentE14;
        // console.log(Inputs.AlignmentAssessmentD9, Inputs.AlignmentAssessmentD10, Inputs.AlignmentAssessmentD11)
        setInputs({...Inputs, AlignmentAssessmentE8, AlignmentAssessmentE14, AlignmentAssessmentE20});

    }, [Inputs.AlignmentAssessmentD9, Inputs.AlignmentAssessmentD10, Inputs.AlignmentAssessmentD11, Inputs.AlignmentAssessmentD12, Inputs.AlignmentAssessmentD13, Inputs.AlignmentAssessmentD15, Inputs.AlignmentAssessmentD16, Inputs.AlignmentAssessmentD17, Inputs.AlignmentAssessmentD18, Inputs.AlignmentAssessmentD19]);

    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
            {/* row */}
            <div className="container-fluid">
                <div className="head-title">
                    <h3 className='preheading'>Diagnose - Benchmark Tools</h3>
                    <h1 className='mainheading'>Alignment Scorecard</h1>
                </div>
                <div className="target-markets-score-card-table">
                    <div className="table-responsive">
                        <table className="table" style={{width: 1400}}>
                            {/* table 01 */}
                            <thead>
                                <tr>
                                    <td className='black-bg text-white font16px' style={{width: 700, height: 70}}>
                                    How do you ensure all tactics and activities support your overall strategy?
                                    </td>
                                    <td className='black-bg text-white text-center' style={{width: 150}}>
                                        <span className='font14px'>Score</span><br/><span className='font12px'>(1 point each)</span>
                                    </td>
                                    <td className='bg-color-blue text-white text-center' style={{width: 50}}>
                                        <div className="do-you-number font16px">{formatPrice(Inputs.AlignmentAssessmentE8, 0)}</div>
                                    </td>
                                    <td className='black-bg text-white text-center font16px' style={{width: 500}}>
                                    Opportunities to Improve
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='color-dark font12px'>We have a system to evaluate tactics & activities and how well they support our overall strategy.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                id="AlignmentAssessmentD9" 
                                                value={Inputs.AlignmentAssessmentD9} 
                                                onChange={(event) => setInputs({ ...Inputs, AlignmentAssessmentD9: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td rowSpan={5} className='bg-white padding-0'>
                                        <div className="arrow-targer-market"></div>
                                    </td>
                                    <td rowSpan={5} className='padding-0 bg-color-02'>
                                        <div className="input-fiel-cn">
                                            <textarea type="text" className="field-ipt bg-color-02 font12px color-dark"
                                            id="AlignmentAssessmentF9"
                                            value={Inputs.AlignmentAssessmentF9}
                                            onChange={(event) => setInputs({ ...Inputs, AlignmentAssessmentF9: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='color-dark font12px'>We are disciplined in choosing tactics & activities based on defined risk/return criteria.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                id="AlignmentAssessmentD10" 
                                                value={Inputs.AlignmentAssessmentD10} 
                                                onChange={(event) => setInputs({ ...Inputs, AlignmentAssessmentD10: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='color-dark font12px'>We set specific objectives for each tactic & activity, and we hold them  accountable for results.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                id="AlignmentAssessmentD11" 
                                                value={Inputs.AlignmentAssessmentD11} 
                                                onChange={(event) => setInputs({ ...Inputs, AlignmentAssessmentD11: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='color-dark font12px'>All tactics & activities are consistent with our brand image and include our tagline, value propositions, and core marketing message.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                id="AlignmentAssessmentD12" 
                                                value={Inputs.AlignmentAssessmentD12} 
                                                onChange={(event) => setInputs({ ...Inputs, AlignmentAssessmentD12: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='color-dark font12px'>Employee performance is linked to achieving specific strategic objectives.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                id="AlignmentAssessmentD13" 
                                                value={Inputs.AlignmentAssessmentD13} 
                                                onChange={(event) => setInputs({ ...Inputs, AlignmentAssessmentD13: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            {/* table 02 */}
                            <thead>
                                <tr>
                                    <td className='black-bg text-white font16px' style={{width: 700, height: 70}}>
                                    How well is your organization set up to achieve strategic alignment?
                                    </td>
                                    <td className='black-bg text-white text-center' style={{width: 150}}>
                                        <span className='font14px'>Score</span><br/><span className='font12px'>(1 point each)</span>
                                    </td>
                                    <td className='bg-color-blue text-white text-center' style={{width: 50}}>
                                        <div className="do-you-number font16px">{formatPrice(Inputs.AlignmentAssessmentE14, 0)}</div>
                                    </td>
                                    <td className='black-bg text-white text-center font16px' style={{width: 500}}>
                                    Opportunities to Improve
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='color-dark font12px'>Resource allocation and budgets are aligned with your strategy.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                id="AlignmentAssessmentD15" 
                                                value={Inputs.AlignmentAssessmentD15} 
                                                onChange={(event) => setInputs({ ...Inputs, AlignmentAssessmentD15: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td rowSpan={5} className='bg-white padding-0'>
                                        <div className="arrow-targer-market"></div>
                                    </td>
                                    <td rowSpan={5} className='padding-0 bg-color-02'>
                                        <div className="input-fiel-cn">
                                            <textarea type="text" className="field-ipt bg-color-02 font12px color-dark"
                                            id="AlignmentAssessmentF15"
                                            value={Inputs.AlignmentAssessmentF15}
                                            onChange={(event) => setInputs({ ...Inputs, AlignmentAssessmentF15: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='color-dark font12px'>Employee performance and accomplishments are linked to your strategy.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                id="AlignmentAssessmentD16" 
                                                value={Inputs.AlignmentAssessmentD16} 
                                                onChange={(event) => setInputs({ ...Inputs, AlignmentAssessmentD16: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='color-dark font12px'>Sales compensation is linked to achieving strategic objectives.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                id="AlignmentAssessmentD17" 
                                                value={Inputs.AlignmentAssessmentD17} 
                                                onChange={(event) => setInputs({ ...Inputs, AlignmentAssessmentD17: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='color-dark font12px'>Compensation of marketing and customer service teams align with overall strategy.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                id="AlignmentAssessmentD18" 
                                                value={Inputs.AlignmentAssessmentD18} 
                                                onChange={(event) => setInputs({ ...Inputs, AlignmentAssessmentD18: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='color-dark font12px'>Executive goals and compensation align with strategic objectives.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                id="AlignmentAssessmentD19" 
                                                value={Inputs.AlignmentAssessmentD19} 
                                                onChange={(event) => setInputs({ ...Inputs, AlignmentAssessmentD19: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            {/* table 03 */}
                            <thead>
                                <tr>
                                    <td className='bg-white'>
                                        <div className="nextInput">
                                            <ul>
                                                <li>
                                                    <Link className="next-link" to="/diagnose/financial-performance"> Return to Benchmark Questions
                                                        <span className="icon-three">
                                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                                        </span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td className='black-bg text-white font16px fontBold text-center'>
                                    Total
                                    </td>
                                    <td className='bg-color-blue text-white text-center font16px'>
                                    {formatPrice(Inputs.AlignmentAssessmentE20, 0)}
                                    </td>
                                    <td className='hide-content'></td>
                                </tr>
                            </thead>
                        </table>
                    </div>  
                </div>
            </div>
        </div>
    )
}