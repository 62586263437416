import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state/index';
import { BarChartGraph } from "../graph/BarChartGraph";
import  UserDropdown  from '../UserDropdown';
import { addEditImprovementPercentage } from "../../state/features/ClentInputSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { useAlert } from 'react-alert'
import { Spinner } from '../spinner'
import { Link } from 'react-router-dom';
import { StatesUseEffect } from '../../utils/StatesUseEffect';
import { BarChartGraphNew } from '../graph/BarChartGraphNew';

export default function PurchaseFrequencyContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const users = useSelector(userData);
    const userID = users.data.profile.id;
    const { message } = useSelector((state) => state.message);

    const clientInputs = useSelector(state => state.clientInputs)
    const improvementPercentage = clientInputs.improvementPercentage
    const dispatch = useDispatch();
    const { purchaseFrequencyData } = bindActionCreators(actionCreators, dispatch)
    const purchaseFrequency = useSelector(state => state.purchaseFrequency)

    const [Inputs, setInputs] = useState({
        purchaseFrequencyD13: Number(purchaseFrequency.purchaseFrequencyD13).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1}),
    });

    const { clientInputsH30, clientInputsH16 } = clientInputs
    let purchaseFrequencyD12 = (parseFloat(clientInputsH30) / parseFloat(clientInputsH16)) ? parseFloat(clientInputsH30) / parseFloat(clientInputsH16) : 0 

    useEffect(() => {
        purchaseFrequencyData("purchaseFrequencyD12",purchaseFrequencyD12);
        purchaseFrequencyData("purchaseFrequencyD13",improvementPercentage.purchaseFrequencyD13);
    }, [])
    
    const { purchaseFrequencyD17, purchaseFrequencyE17, purchaseFrequencyD18, purchaseFrequencyE18, purchaseFrequencyF18, purchaseFrequencyG18, purchaseFrequencyF17, purchaseFrequencyG17, purchaseFrequencyH18, purchaseFrequencyI18, purchaseFrequencyH17, purchaseFrequencyI17, purchaseFrequencyD22, purchaseFrequencyE22, purchaseFrequencyF22, purchaseFrequencyG22, purchaseFrequencyH22, purchaseFrequencyI22, purchaseFrequencyD23, purchaseFrequencyE23, purchaseFrequencyF23, purchaseFrequencyG23, purchaseFrequencyH23, purchaseFrequencyI23, purchaseFrequencyD25, purchaseFrequencyE25, purchaseFrequencyF25, purchaseFrequencyG25, purchaseFrequencyH25, purchaseFrequencyI25, purchaseFrequencyD26, purchaseFrequencyE26, purchaseFrequencyF26, purchaseFrequencyG26, purchaseFrequencyH26, purchaseFrequencyI26, purchaseFrequencyD28, purchaseFrequencyE28, purchaseFrequencyF28, purchaseFrequencyG28, purchaseFrequencyH28, purchaseFrequencyI28 } = purchaseFrequency //object destructuring for purchaseFrequency

    const improvementPrcntHandler = (impPrcnt) => {
        setIsLoading(true);
        let sendData = {user_id : userID, purchaseFrequencyD13: impPrcnt}
        dispatch(addEditImprovementPercentage(sendData))
        .unwrap()
        .then((response) => {
          console.log("response",response)
            if(response!= undefined){
               console.log('success')
            }
            setIsLoading(false)   // Hide loading screen 
        })
        .catch((error) => {
          console.log("error",error)
            { message && alert.show(message) }
            setIsLoading(false)   // Hide loading screen 
        });
    }

    // call states useEffect utils
    StatesUseEffect()

    return (
        <div className="content-body content-body-new bg-white-color">
        <UserDropdown />
    
            {/* row */}
            <div className="container-fluid lead-generation-new">
                <div className="head-title mb-5">
                    <h3 className='preheading'>Prescribe - Growth Drivers</h3>
                    <h1 className='mainheading mb-1'>Purchase Frequency</h1>	
                </div>
                <div className="row lead-genearation-rw mb-5" style={{maxWidth: 1260}}>
                    <div className="col-12 col-lg-6">
                        {/* <div className="potential-growth-ld mb-3" style={{maxWidth: 510}}>
                            <h3><strong>Potential Growth Activities or Tactics:</strong></h3>
                            <ol>
                                <li>Activity 1</li>
                                <li>Activity 2</li>
                                <li>Activity 3</li>
                            </ol>
                        </div> */}
                        <div className="lead-generation-table mt-218" style={{overflowY: 'auto'}}>
                            <div className="responsive-table">
                                <table className='table' style={{width: 510}}>
                                    <thead>
                                        <tr>
                                            <th scope='col' className='black-bg text-white fontBold' style={{width: 360}}>Increasing Purchase Frequency</th>
                                            <th className='black-bg hide-content' style={{width: 150}}>2</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td scope='row' className='bg-color-light-blue font12px color-black'>Customer Purchase Frequency (annually)</td>
                                            <td className='bg-color-light-blue font12px color-black text-right-rt'>{Number(purchaseFrequency.purchaseFrequencyD12).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</td>
                                        </tr>
                                        <tr>
                                            <td scope='row' className='bg-color-light-blue font12px color-black'>Projected Improvement</td>
                                            <th scope='row' className='padding-0'>
                                                <div className="dollor-sin-pr">
                                                    <div className="input-fiel-cn bg-color-01 client-inpt-field">
                                                        <input type="text" 
                                                        name="Inputs.purchaseFrequencyD13"
                                                        className="field-ipt wthinherit font12px bg-color-01 text-right text-black"
                                                        placeholder={0}
                                                        value={Inputs.purchaseFrequencyD13}
                                                        onChange={(event) => {
                                                            setInputs({ ...Inputs, purchaseFrequencyD13: event.target.value });
                                                            purchaseFrequencyData("purchaseFrequencyD13", event.target.value);
                                                            improvementPrcntHandler(event.target.value)
                                                        }}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                            }
                                                        }}
                                                        disabled={isLoading}
                                                    />
                                                    </div>
                                                    <div className="perecentage">%</div>
                                                </div>
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                                <Spinner />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6" style={{minHeight: 400}}>
                        <BarChartGraphNew dataInput={{
                            "Title"      : "Purchase Frequency:  Impact on Financial Performance",
                            "ColorCodes" : ["#d6dce5", "#8497b0", "#1e2b3c"],
                            "GraphData"  : {
                                labels: [["Your","Projection"], "Year 1", "Year 2", "Year 3", "Year 4", "Year 5"],
                                datasets: [
                                    {
                                        label: "Profit",
                                        backgroundColor: "#1e65a7", //"#d6dce5",
                                        data: [Number(purchaseFrequencyD23),Number(purchaseFrequencyE23),Number(purchaseFrequencyF23),Number(purchaseFrequencyG23),Number(purchaseFrequencyH23),Number(purchaseFrequencyI23)]
                                    },
                                    {
                                        label: "Sales",
                                        backgroundColor: "#8497b0",
                                        data: [Number(purchaseFrequencyD22),Number(purchaseFrequencyE22),Number(purchaseFrequencyF22),Number(purchaseFrequencyG22),Number(purchaseFrequencyH22),Number(purchaseFrequencyI22)]
                                    },
                                    {
                                        label: "Valuation",
                                        backgroundColor: "#1e2b3c",
                                        data: [Number(purchaseFrequencyD28),Number(purchaseFrequencyE28),Number(purchaseFrequencyF28),Number(purchaseFrequencyG28),Number(purchaseFrequencyH28),Number(purchaseFrequencyI28)]
                                    }
                                ]
                            }
                        }} />
                        {/* <div className="">
                            <h3 className='text-center impact-lead-titile'><strong>Referrals: Impact on Financial Performance </strong></h3>
                            
                            <BarChartGraph dataInput={{
                                    "Title"      : "Purchase Frequency: Impact on Financial Performance ",
                                    "vTitle"     : "",
                                    "hTitle"     : "",
                                    "subTitle"   : "",
                                    "ColorCodes" : ["#c2c7cd", "#8497b0", "#44546a"],
                                    "GraphData"  : [
                                        ["", "Profit", "Sales", "Valuation"],
                                        ["Year 1", Number(purchaseFrequencyE23), Number(purchaseFrequencyE22), Number(purchaseFrequencyE28)],
                                        ["Year 2", Number(purchaseFrequencyF23), Number(purchaseFrequencyF22), Number(purchaseFrequencyF28)],
                                        ["Year 3", Number(purchaseFrequencyG23), Number(purchaseFrequencyG22), Number(purchaseFrequencyG28)],
                                        ["Year 4", Number(purchaseFrequencyH23), Number(purchaseFrequencyH22), Number(purchaseFrequencyH28)],
                                        ["Year 5", Number(purchaseFrequencyI23), Number(purchaseFrequencyI22), Number(purchaseFrequencyI28)]
                                    ]
                                }} />
                        </div> */}
                    </div>
                </div>
                <div className="card update-class-lead">
                    <div className="card-body pl-0">
                        <div className="table-responsive">
                            <table className="table table-responsive-md table-striped mb-0" style={{width: 1260}}>
                                <thead>
                                    <tr>
                                    <th className="table-primar" style={{width: 360}}/>
                                    <th colSpan={2} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Year 1 Gains</th>
                                    <th colSpan={2} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Mid-Term Gains</th>
                                    <th colSpan={5} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Long-Term Gains </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="thead-primary">
                                        <th className='black-bg fontBold' style={{width: 360}}>Purchase Frequency Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>Your Projection</th>
                                        <th className='black-bg' style={{width: 150}}>1% Quarterly Improvement</th>
                                        <th className='black-bg' style={{width: 150}}>2 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>3 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>4 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>5 Years of 1% Improvements</th>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>Annual Improvement in Purchase Frequency </td>
                                        <td className='bg-color-blue-gray text-white font12px'> {Number(purchaseFrequencyD17).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})} </td>
                                        <td className='bg-color-light-blue font12px color-black'> {Number(purchaseFrequencyE17).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})} </td>
                                        <td className='bg-color-light-blue font12px color-black'> {Number(purchaseFrequencyF17).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})} </td>
                                        <td className='bg-color-light-blue font12px color-black'> {Number(purchaseFrequencyG17).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})} </td>
                                        <td className='bg-color-light-blue font12px color-black'> {Number(purchaseFrequencyH17).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})} </td>
                                        <td className='bg-color-light-blue font12px color-black'> {Number(purchaseFrequencyI17).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})} </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'><strong>New Annual Purchase Frequency</strong></td>
                                        <td className='bg-color-blue-gray text-white font12px'> {Number(purchaseFrequencyD18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})} </td>
                                        <td className='bg-color-teal text-white font12px'><strong> {Number(purchaseFrequencyE18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong> </td>
                                        <td className='bg-color-teal text-white font12px'><strong> {Number(purchaseFrequencyF18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong> </td>
                                        <td className='bg-color-teal text-white font12px'><strong> {Number(purchaseFrequencyG18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong> </td>
                                        <td className='bg-color-teal text-white font12px'><strong> {Number(purchaseFrequencyH18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong> </td>
                                        <td className='bg-color-teal text-white font12px'><strong> {Number(purchaseFrequencyI18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong> </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="card update-class-lead">
                        <div className="card-body pl-0">
                        <div className="table-responsive">
                            <table className="table table-responsive-md table-striped mb-0" style={{width: 1260}}>
                            <thead>
                                <tr>
                                <th style={{background:'#fff', width: 360}}><h3>Financial Improvements</h3></th>
                                <th colSpan={2} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Year 1 Gains</th>
                                <th colSpan={2} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Mid-Term Gains</th>
                                <th colSpan={5} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Long-Term Gains </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="thead-primary">
                                    <th className='black-bg fontBold' style={{width: 360}}>Annual Impact of Increasing Purchase Frequency</th>
                                    <th className='black-bg' style={{width: 150}}>Your Projection</th>
                                    <th className='black-bg' style={{width: 150}}>1% Quarterly Improvement</th>
                                    <th className='black-bg' style={{width: 150}}>2 Years of 1% Improvements</th>
                                    <th className='black-bg' style={{width: 150}}>3 Years of 1% Improvements</th>
                                    <th className='black-bg' style={{width: 150}}>4 Years of 1% Improvements</th>
                                    <th className='black-bg' style={{width: 150}}>5 Years of 1% Improvements</th>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>Annual Sales</td>
                                        <td className='bg-color-blue-gray text-white font12px'>  <div className="annual-dollor-sign">
                                            <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">{Number(purchaseFrequencyD22).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px"> {Number(purchaseFrequencyE22).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px"> {Number(purchaseFrequencyF22).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px"> {Number(purchaseFrequencyG22).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px"> {Number(purchaseFrequencyH22).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px"> {Number(purchaseFrequencyI22).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>Annual Profit</td>
                                        <td className='bg-color-blue-gray text-white font12px'>  <div className="annual-dollor-sign">
                                            <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">{Number(purchaseFrequencyD23).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px"> {Number(purchaseFrequencyE23).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px"> {Number(purchaseFrequencyF23).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px"> {Number(purchaseFrequencyG23).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px"> {Number(purchaseFrequencyH23).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px"> {Number(purchaseFrequencyI23).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="thead-primary">
                                        <th className='black-bg fontBold'>Lifetime Impact of Increasing Purchase Frequency</th>
                                        <th className='black-bg'>Your Projection</th>
                                        <th className='black-bg'>1% Quarterly Improvement</th>
                                        <th className='black-bg'>2 Years of 1% Improvements</th>
                                        <th className='black-bg'>3 Years of 1% Improvements</th>
                                        <th className='black-bg'>4 Years of 1% Improvements</th>
                                        <th className='black-bg'>5 Years of 1% Improvements</th>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>Lifetime Sales</td>
                                        <td className='bg-color-blue-gray text-white font12px'>  <div className="annual-dollor-sign">
                                            <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">{Number(purchaseFrequencyD25).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px"> {Number(purchaseFrequencyE25).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px"> {Number(purchaseFrequencyF25).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px"> {Number(purchaseFrequencyG25).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px"> {Number(purchaseFrequencyH25).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px"> {Number(purchaseFrequencyI25).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>Lifetime Profit</td>
                                        <td className='bg-color-blue-gray text-white font12px'>  <div className="annual-dollor-sign">
                                            <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">{Number(purchaseFrequencyD26).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px"> {Number(purchaseFrequencyE26).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px"> {Number(purchaseFrequencyF26).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px"> {Number(purchaseFrequencyG26).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px"> {Number(purchaseFrequencyH26).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px"> {Number(purchaseFrequencyI26).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="thead-primary">
                                        <th className='black-bg fontBold'>Valuation Impact of Increasing Purchase Frequency</th>
                                        <th className='black-bg'>Your Projection</th>
                                        <th className='black-bg'>1% Quarterly Improvement</th>
                                        <th className='black-bg'>2 Years of 1% Improvements</th>
                                        <th className='black-bg'>3 Years of 1% Improvements</th>
                                        <th className='black-bg'>4 Years of 1% Improvements</th>
                                        <th className='black-bg'>5 Years of 1% Improvements</th>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>Estimated Valuation Impact</td>
                                        <td className='bg-color-blue-gray text-white font12px'>  <div className="annual-dollor-sign">
                                            <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">{Number(purchaseFrequencyD28).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px"> {Number(purchaseFrequencyE28).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px"> {Number(purchaseFrequencyF28).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px"> {Number(purchaseFrequencyG28).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px"> {Number(purchaseFrequencyH28).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px"> {Number(purchaseFrequencyI28).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="nextInput">
                            <ul>
                                <li>
                                    <Link to="/prescribe/buying-lifetime" className='next-link'> Next: Buying Lifetime   
                                        <span className='icon-three'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                        </span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    )
}
