import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getSynergyScoringWorksheet, modifySynergyScoringWorksheet } from "../../state/features/SynergyScoringWorksheetSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';

export default function CustomerTransformationModelContent() {

    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        SynergyScoringWorksheetG11 : '',
        SynergyScoringWorksheetF15 : '',
        SynergyScoringWorksheetG15 : '',
        SynergyScoringWorksheetF16 : '',
        SynergyScoringWorksheetG16 : '',
        SynergyScoringWorksheetF17 : '',
        SynergyScoringWorksheetG17 : '',
        SynergyScoringWorksheetF18 : '',
        SynergyScoringWorksheetG18 : '',
        SynergyScoringWorksheetF19 : '',
        SynergyScoringWorksheetG19 : '',
        SynergyScoringWorksheetF21 : '',
        SynergyScoringWorksheetG21 : '',
        SynergyScoringWorksheetF22 : '',
        SynergyScoringWorksheetG22 : '',
        SynergyScoringWorksheetF23 : '',
        SynergyScoringWorksheetG23 : '',
        SynergyScoringWorksheetF25 : '',
        SynergyScoringWorksheetG25 : '',
        SynergyScoringWorksheetF26 : '',
        SynergyScoringWorksheetG26 : '',
        SynergyScoringWorksheetF27 : '',
        SynergyScoringWorksheetG27 : '',
        SynergyScoringWorksheetF29 : '',
        SynergyScoringWorksheetG29 : '',
        SynergyScoringWorksheetF30 : '',
        SynergyScoringWorksheetG30 : '',
        SynergyScoringWorksheetF31 : '',
        SynergyScoringWorksheetG31 : '',
        SynergyScoringWorksheetF32 : '',
        SynergyScoringWorksheetG32 : '',
        SynergyScoringWorksheetF33 : '',
        SynergyScoringWorksheetG33 : '',
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getSynergyScoringWorksheet({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.revenueGrowth)
            if(response!= undefined  && response.data.revenueGrowth){
                let revenueGrowth = response.data.revenueGrowth[0];
                console.log(revenueGrowth)
                delete revenueGrowth.id;
                delete revenueGrowth.user_id;
                delete revenueGrowth.created_at;
                delete revenueGrowth.updated_at;
                const newObj = { ...Inputs }
                for (const key in revenueGrowth) {
                    if (revenueGrowth.hasOwnProperty(key)) {
                        if(revenueGrowth[key])
                        newObj[key] = revenueGrowth[key]
                    }
                }
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifySynergyScoringWorksheet({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

  return (
    <div className="content-body">
        <UserDropdown />
        {/* row */}
       <div className="container-fluid">
            <div className="head-title">
                <p>Opportunity Analysis </p>
                <h2 className=" mt-0">Synergy Scoring Worksheet</h2>
                <p className="mt-0 mb-5">In evaluating and prioritizing your portfolio of potential strategic and tactical options, which ones are the easiest to execute while driving the most value for the organization?  These are the ones with strong synergies with other dimensions of your existing business model. </p>
            </div>
            <Spinner />
            <div className="card">
                <div className="card-body pl-0">
                <div className="table-responsive table-dta">
                    <div className="inner-table1">
                    </div><div className="inner-table1">
                    </div><div className="inner-table1">
                    </div><table className="table mb-0 mt-0">
                    <tbody>
                        <tr>
                        <td colSpan={3} className="width-25 d-hidden">1</td>
                        <td colSpan={3} className="width-25 bg-color1 text-white text-center-c font-size-18">
                            <div className="dsdbjfkdsgfk">
                            Idea Overview:
                            </div>
                        </td>
                        <td colSpan={10} className="padding-0">
                            <form className="Focused-planning-f new-add-cls-ssw">
                            <input type="text" className="fucus-pla"
                                id="SynergyScoringWorksheetG11"
                                value={Inputs.SynergyScoringWorksheetG11}
                                onChange={(event) => setInputs({ ...Inputs, SynergyScoringWorksheetG11: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                            </form>
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={3} className="width-25 d-hidden">1</td>
                        <td colSpan={3} className="width-25 bg-color1 text-white text-center-c">Synergy Score</td>
                        <td colSpan={10} className="bg-color1 text-white text-center-c">What are the synergies and necessary investments / changes?</td>
                        </tr>
                        <tr>
                        <td colSpan={3} className="width-25 d-hidden">1</td>
                        <td colSpan={3} className="width-25 bg-color3 text-white padding-10 text-center-c">1 = Completely Different / Large Investment    5 = No Change / No Effort / High Synergy</td>
                        <td colSpan={10} className="bg-color3 text-white text-center-c">Look for synergies that create or retain customers, increase revenue, or reduce costs.</td>
                        </tr>
                        <tr>
                        <td className="bg-color1 text-white text-center-c font-size-18" rowSpan={5}>
                        <span className="style-ctn1">Sales &amp; Marketing</span>
                        </td>
                        <td className="bg-color1 padding-10">
                            <div className="ssw-div">
                            <img src="../images/ss_worksheet/ssw-icon1.png" className="img-fluid" alt="ssw-icon1" />
                            </div>
                        </td>
                        <td className="bg-color3 text-white">Customer Segments</td>
                        <td colSpan={3} className="width-25">
                            <input type="text" className="form-control inputText"
                                id="SynergyScoringWorksheetF15"
                                value={Inputs.SynergyScoringWorksheetF15}
                                onChange={(event) => setInputs({ ...Inputs, SynergyScoringWorksheetF15: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td colSpan={10}>
                            <input type="text" className="form-control inputText"
                                id="SynergyScoringWorksheetG15"
                                value={Inputs.SynergyScoringWorksheetG15}
                                onChange={(event) => setInputs({ ...Inputs, SynergyScoringWorksheetG15: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color1 padding-10">
                            <div className="ssw-div">
                            <img src="../images/ss_worksheet/ssw-icon2.png" className="img-fluid" alt="ssw-icon1" />
                            </div>
                        </td>
                        <td className="bg-color3 text-white">Markets</td>
                        <td colSpan={3} className="width-25">
                            <input type="text" className="form-control inputText"
                                id="SynergyScoringWorksheetF16"
                                value={Inputs.SynergyScoringWorksheetF16}
                                onChange={(event) => setInputs({ ...Inputs, SynergyScoringWorksheetF16: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td colSpan={10}>
                            <input type="text" className="form-control inputText"
                                id="SynergyScoringWorksheetG16"
                                value={Inputs.SynergyScoringWorksheetG16}
                                onChange={(event) => setInputs({ ...Inputs, SynergyScoringWorksheetG16: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color1 padding-10">
                            <div className="ssw-div">
                            <img src="../images/ss_worksheet/ssw-icon3.png" className="img-fluid" alt="ssw-icon1" />
                            </div>
                        </td>
                        <td className="bg-color3 text-white">Geographies</td>
                        <td colSpan={3} className="width-25">
                            <input type="text" className="form-control inputText"
                                id="SynergyScoringWorksheetF17"
                                value={Inputs.SynergyScoringWorksheetF17}
                                onChange={(event) => setInputs({ ...Inputs, SynergyScoringWorksheetF17: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td colSpan={10}>
                            <input type="text" className="form-control inputText"
                                id="SynergyScoringWorksheetG17"
                                value={Inputs.SynergyScoringWorksheetG17}
                                onChange={(event) => setInputs({ ...Inputs, SynergyScoringWorksheetG17: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color1 padding-10">
                            <div className="ssw-div">
                            <img src="../images/ss_worksheet/ssw-icon4.png" className="img-fluid" alt="ssw-icon1" />
                            </div>
                        </td>
                        <td className="bg-color3 text-white">Marketing Costs</td>
                        <td colSpan={3} className="width-25">
                            <input type="text" className="form-control inputText"
                                id="SynergyScoringWorksheetF18"
                                value={Inputs.SynergyScoringWorksheetF18}
                                onChange={(event) => setInputs({ ...Inputs, SynergyScoringWorksheetF18: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td colSpan={10}>
                            <input type="text" className="form-control inputText"
                                id="SynergyScoringWorksheetG18"
                                value={Inputs.SynergyScoringWorksheetG18}
                                onChange={(event) => setInputs({ ...Inputs, SynergyScoringWorksheetG18: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color1 padding-10">
                            <div className="ssw-div">
                            <img src="../images/ss_worksheet/ssw-icon5.png" className="img-fluid" alt="ssw-icon1" />
                            </div>
                        </td>
                        <td className="bg-color3 text-white">Sales Effort</td>
                        <td colSpan={3} className="width-25">
                            <input type="text" className="form-control inputText"
                                id="SynergyScoringWorksheetF19"
                                value={Inputs.SynergyScoringWorksheetF19}
                                onChange={(event) => setInputs({ ...Inputs, SynergyScoringWorksheetF19: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td colSpan={10}>
                            <input type="text" className="form-control inputText"
                                id="SynergyScoringWorksheetG19"
                                value={Inputs.SynergyScoringWorksheetG19}
                                onChange={(event) => setInputs({ ...Inputs, SynergyScoringWorksheetG19: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        </tr>
                        {/* table 2 */}
                        <tr>
                        <td className="d-hidden" />
                        <td className="d-hidden" />
                        <td className="d-hidden" />
                        <td className="d-hidden" colSpan={3} />
                        <td className="d-hidden" colSpan={10} />
                        </tr>
                        <tr>
                        <td className="bg-color1 text-white text-center-c font-size-14" rowSpan={3}>
                            <span className="style-ctn1">Value Proposition</span>
                        </td>
                        <td className="bg-color1 padding-10">
                            <div className="ssw-div">
                            <img src="../images/ss_worksheet/ssw-icon6.png" className="img-fluid" alt="ssw-icon1" />
                            </div>
                        </td>
                        <td className="bg-color3 text-white">Products</td>
                        <td colSpan={3} className="width-25">
                            <input type="text" className="form-control inputText"
                                id="SynergyScoringWorksheetF21"
                                value={Inputs.SynergyScoringWorksheetF21}
                                onChange={(event) => setInputs({ ...Inputs, SynergyScoringWorksheetF21: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td colSpan={10}>
                            <input type="text" className="form-control inputText"
                                id="SynergyScoringWorksheetG21"
                                value={Inputs.SynergyScoringWorksheetG21}
                                onChange={(event) => setInputs({ ...Inputs, SynergyScoringWorksheetG21: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color1 padding-10">
                            <div className="ssw-div">
                            <img src="../images/ss_worksheet/ssw-icon7.png" className="img-fluid" alt="ssw-icon1" />
                            </div>
                        </td>
                        <td className="bg-color3 text-white">Services</td>
                        <td colSpan={3} className="width-25">
                            <input type="text" className="form-control inputText"
                                id="SynergyScoringWorksheetF22"
                                value={Inputs.SynergyScoringWorksheetF22}
                                onChange={(event) => setInputs({ ...Inputs, SynergyScoringWorksheetF22: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td colSpan={10}>
                            <input type="text" className="form-control inputText"
                                id="SynergyScoringWorksheetG22"
                                value={Inputs.SynergyScoringWorksheetG22}
                                onChange={(event) => setInputs({ ...Inputs, SynergyScoringWorksheetG22: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color1 padding-10">
                            <div className="ssw-div">
                            <img src="../images/ss_worksheet/ssw-icon8.png" className="img-fluid" alt="ssw-icon1" />
                            </div>
                        </td>
                        <td className="bg-color3 text-white">Pricing</td>
                        <td colSpan={3} className="width-25">
                            <input type="text" className="form-control inputText"
                                id="SynergyScoringWorksheetF23"
                                value={Inputs.SynergyScoringWorksheetF23}
                                onChange={(event) => setInputs({ ...Inputs, SynergyScoringWorksheetF23: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td colSpan={10}>
                            <input type="text" className="form-control inputText"
                                id="SynergyScoringWorksheetG23"
                                value={Inputs.SynergyScoringWorksheetG23}
                                onChange={(event) => setInputs({ ...Inputs, SynergyScoringWorksheetG23: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        </tr>
                        {/* table 3 */}
                        <tr>
                        <td className="d-hidden" />
                        <td className="d-hidden" />
                        <td className="d-hidden" />
                        <td className="d-hidden" colSpan={3} />
                        <td className="d-hidden" colSpan={10} />
                        </tr>
                        <tr>
                        <td className="bg-color1 text-white text-center-c font-size-14" rowSpan={3}>
                            <span className="style-ctn1">Go To Market</span>
                        </td>
                        <td className="bg-color1 padding-10">
                            <div className="ssw-div">
                            <img src="../images/ss_worksheet/ssw-icon9.png" className="img-fluid" alt="ssw-icon1" />
                            </div>
                        </td>
                        <td className="bg-color3 text-white">Distribution</td>
                        <td colSpan={3} className="width-25">
                            <input type="text" className="form-control inputText"
                                id="SynergyScoringWorksheetF25"
                                value={Inputs.SynergyScoringWorksheetF25}
                                onChange={(event) => setInputs({ ...Inputs, SynergyScoringWorksheetF25: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td colSpan={10}>
                            <input type="text" className="form-control inputText"
                                id="SynergyScoringWorksheetG25"
                                value={Inputs.SynergyScoringWorksheetG25}
                                onChange={(event) => setInputs({ ...Inputs, SynergyScoringWorksheetG25: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color1 padding-10">
                            <div className="ssw-div">
                            <img src="../images/ss_worksheet/ssw-icon10.png" className="img-fluid" alt="ssw-icon1" />
                            </div>
                        </td>
                        <td className="bg-color3 text-white">Marketing</td>
                        <td colSpan={3} className="width-25">
                            <input type="text" className="form-control inputText"
                                id="SynergyScoringWorksheetF26"
                                value={Inputs.SynergyScoringWorksheetF26}
                                onChange={(event) => setInputs({ ...Inputs, SynergyScoringWorksheetF26: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td colSpan={10}>
                            <input type="text" className="form-control inputText"
                                id="SynergyScoringWorksheetG26"
                                value={Inputs.SynergyScoringWorksheetG26}
                                onChange={(event) => setInputs({ ...Inputs, SynergyScoringWorksheetG26: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color1 padding-10">
                            <div className="ssw-div">
                            <img src="../images/ss_worksheet/ssw-icon11.png" className="img-fluid" alt="ssw-icon1" />
                            </div>
                        </td>
                        <td className="bg-color3 text-white">Sales</td>
                        <td colSpan={3} className="width-25">
                            <input type="text" className="form-control inputText"
                                id="SynergyScoringWorksheetF27"
                                value={Inputs.SynergyScoringWorksheetF27}
                                onChange={(event) => setInputs({ ...Inputs, SynergyScoringWorksheetF27: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td colSpan={10}>
                            <input type="text" className="form-control inputText"
                                id="SynergyScoringWorksheetG27"
                                value={Inputs.SynergyScoringWorksheetG27}
                                onChange={(event) => setInputs({ ...Inputs, SynergyScoringWorksheetG27: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        </tr>
                        {/* table 4 */}
                        <tr>
                        <td className="d-hidden" />
                        <td className="d-hidden" />
                        <td className="d-hidden" />
                        <td className="d-hidden" colSpan={3} />
                        <td className="d-hidden" colSpan={10} />
                        </tr>
                        <tr>
                        <td className="bg-color1 text-white text-center-c font-size-18" rowSpan={5}>
                            <span className="style-ctn1">Organizational </span>
                        </td>
                        <td className="bg-color1 padding-10">
                            <div className="ssw-div">
                            <img src="../images/ss_worksheet/ssw-icon12.png" className="img-fluid" alt="ssw-icon1" />
                            </div>
                        </td>
                        <td className="bg-color3 text-white">Process / Functions</td>
                        <td colSpan={3} className="width-25">
                            <input type="text" className="form-control inputText"
                                id="SynergyScoringWorksheetF29"
                                value={Inputs.SynergyScoringWorksheetF29}
                                onChange={(event) => setInputs({ ...Inputs, SynergyScoringWorksheetF29: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td colSpan={10}>
                            <input type="text" className="form-control inputText"
                                id="SynergyScoringWorksheetG29"
                                value={Inputs.SynergyScoringWorksheetG29}
                                onChange={(event) => setInputs({ ...Inputs, SynergyScoringWorksheetG29: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color1 padding-10">
                            <div className="ssw-div">
                            <img src="../images/ss_worksheet/ssw-icon13.png" className="img-fluid" alt="ssw-icon1" />
                            </div>
                        </td>
                        <td className="bg-color3 text-white">People</td>
                        <td colSpan={3} className="width-25">
                            <input type="text" className="form-control inputText"
                                id="SynergyScoringWorksheetF30"
                                value={Inputs.SynergyScoringWorksheetF30}
                                onChange={(event) => setInputs({ ...Inputs, SynergyScoringWorksheetF30: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td colSpan={10}>
                            <input type="text" className="form-control inputText"
                                id="SynergyScoringWorksheetG30"
                                value={Inputs.SynergyScoringWorksheetG30}
                                onChange={(event) => setInputs({ ...Inputs, SynergyScoringWorksheetG30: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color1 padding-10">
                            <div className="ssw-div">
                            <img src="../images/ss_worksheet/ssw-icon14.png" className="img-fluid" alt="ssw-icon1" />
                            </div>
                        </td>
                        <td className="bg-color3 text-white">Infrastructure</td>
                        <td colSpan={3} className="width-25">
                            <input type="text" className="form-control inputText"
                                id="SynergyScoringWorksheetF31"
                                value={Inputs.SynergyScoringWorksheetF31}
                                onChange={(event) => setInputs({ ...Inputs, SynergyScoringWorksheetF31: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td colSpan={10}>
                            <input type="text" className="form-control inputText"
                                id="SynergyScoringWorksheetG31"
                                value={Inputs.SynergyScoringWorksheetG31}
                                onChange={(event) => setInputs({ ...Inputs, SynergyScoringWorksheetG31: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color1 padding-10">
                            <div className="ssw-div">
                            <img src="../images/ss_worksheet/ssw-icon15.png" className="img-fluid" alt="ssw-icon1" />
                            </div>
                        </td>
                        <td className="bg-color3 text-white">Cost Reduction</td>
                        <td colSpan={3} className="width-25">
                            <input type="text" className="form-control inputText"
                                id="SynergyScoringWorksheetF32"
                                value={Inputs.SynergyScoringWorksheetF32}
                                onChange={(event) => setInputs({ ...Inputs, SynergyScoringWorksheetF32: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td colSpan={10}>
                            <input type="text" className="form-control inputText"
                                id="SynergyScoringWorksheetG32"
                                value={Inputs.SynergyScoringWorksheetG32}
                                onChange={(event) => setInputs({ ...Inputs, SynergyScoringWorksheetG32: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color1 padding-10">
                            <div className="ssw-div">
                            <img src="../images/ss_worksheet/ssw-icon16.png" className="img-fluid" alt="ssw-icon1" />
                            </div>
                        </td>
                        <td className="bg-color3 text-white">Partnerships</td>
                        <td colSpan={3} className="width-25">
                            <input type="text" className="form-control inputText"
                                id="SynergyScoringWorksheetF33"
                                value={Inputs.SynergyScoringWorksheetF33}
                                onChange={(event) => setInputs({ ...Inputs, SynergyScoringWorksheetF33: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td colSpan={10}>
                            <input type="text" className="form-control inputText"
                                id="SynergyScoringWorksheetG33"
                                value={Inputs.SynergyScoringWorksheetG33}
                                onChange={(event) => setInputs({ ...Inputs, SynergyScoringWorksheetG33: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        </tr>
                    </tbody>
                    </table> 
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}
