
const getMenuChildren  = (route, menus) => {
    // console.log("getMenuChildren",menus)
    if(!menus || menus.length === 0){
        return [];
    }
    const childrens = menus.find(obj => obj.menu_slug === route);

    return childrens? childrens.children : [];
}

export default getMenuChildren;