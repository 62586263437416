import { combineReducers } from "redux";
import conversionReducer from "./conversionReducer";
import leadGenerationReducer from "./leadGenerationReducer";
import storage from 'redux-persist/lib/storage';
import referralsReducer from "./referralsReducer";
import CustomerFinancialValuesReducer from "./CustomerFinancialValuesReducer";
import purchaseFrequencyReducer from "./purchaseFrequencyReducer";
import buyingLifeTimeReducer from "./buyingLifeTimeReducer";
import profitMarginReducer from "./profitMarginReducer";
import customerReactivation from "./customerReactivationReducer";
import impactof120GrowthOS from "./impactof120GrowthOSReducer";
import averageTransactionValue from "./averageTransactionValueReducer";
import reducingAttrition from "./reducingAttritionReducer";
import creatingGrowthMomentum from "./creatingGrowthMomentumReducer";
import findingGrowthInYourBusiness from "./findingGrowthInYourBusinessReducer";
import whatsDrivingGrowth from "./whatsDrivingGrowthReducer";
import equityEvolutionTheBigBonus from "./equityEvolutionTheBigBonusReducer";
import impact120GrowthROI from "./impact120GrowthROIReducer";
import bqMainReducer from "./bqMainReducer";
import benchmarkScoreGapAnalysis from "./benchmarkScoreGapAnalysisReducer";
import benchmarkScorecard from "./benchmarkScorecardReducer";
import authReducer from "../features/AuthenticationSlice";
import messageReducer from "../features/Message";
import clientInputSlice from "../features/ClentInputSlice";
import customerRetentionLoopReducer from "./customerRetentionLoopReducer";

const appReducer = combineReducers({
    clientInputs: clientInputSlice,
    conversion: conversionReducer,
    leadGeneration: leadGenerationReducer,
    referrals: referralsReducer,
    CustomerFinancialValues: CustomerFinancialValuesReducer,
    purchaseFrequency: purchaseFrequencyReducer,
    buyingLifeTime: buyingLifeTimeReducer,
    profitMargin: profitMarginReducer,
    customerReactivation,
    impactof120GrowthOS,
    averageTransactionValue,
    reducingAttrition,
    creatingGrowthMomentum,
    findingGrowthInYourBusiness,
    whatsDrivingGrowth,
    equityEvolutionTheBigBonus,
    impact120GrowthROI,
    customerRetentionLoop : customerRetentionLoopReducer,
    bqMainReducer,
    benchmarkScoreGapAnalysis,
    benchmarkScorecard,
    auth: authReducer,
    message: messageReducer
})

const reducers = (state, action) => {
    // console.log('rootState',state)
    if (action.type === "RESET") {
        // for all keys defined in your persistConfig(s)
        storage.removeItem('persist:root')
        return appReducer(undefined, action);
    }
    return appReducer(state, action)
}

export default reducers