import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../features/Message";
import the120GrowthBenchmarkService from "../../services/the120GrowthBenchmark.service";

//Get 120 Growth Benchmark data based on segment
export const getQuestionsWithOptionsList = createAsyncThunk(
  "120GrowthBQ/getQuestionsWithOptionsList",
  async ({user_id, slug}, thunkAPI) => {
    try {
      const response = await the120GrowthBenchmarkService.getData(user_id, slug);
      //console.log(response)
      //thunkAPI.dispatch(setMessage(response.data.message));
      return response.data
    } catch (error) {
      console.log(error)
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

//add or edit UserScore
export const modifyUserScore = createAsyncThunk(
  "120GrowthBQ/modifyUserScore",
  async (postClientData , thunkAPI) => {
    try {
      const data = await the120GrowthBenchmarkService.postData(postClientData);
      thunkAPI.dispatch(setMessage(""));
      console.log(data)
      return { userScore: data };
    } catch (error) {
      console.log('errorcode',error.code)
      if(error.code == 'ERR_BAD_REQUEST'){
        return {status : 401}
      }
      else{
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const the120GrowthBenchmarkSlice = createSlice({
    name: 'the120GrowthBenchmark',
    reducers: {
      
    },
    extraReducers: {
      [getQuestionsWithOptionsList.pending]: (state) => {
        state.loading = true
      },
      [getQuestionsWithOptionsList.fulfilled]: (state, { payload }) => {
        //console.log("---------get 120 growth benchmark Data -----------", payload.data.the120GrowthBenchmark)
        state.loading = false
      },
      [getQuestionsWithOptionsList.rejected]: (state) => {
        state.loading = false
      },
    },
  })

const { reducer } = the120GrowthBenchmarkSlice;
export default reducer;