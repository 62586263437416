import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import sum from "../../utils/sum";
import { Link } from 'react-router-dom';
import formatPrice from '../../utils/formatPrice';
import { getCustomerDataScorecard, modifyCustomerDataScorecard } from '../../state/features/CustomerDataScorecardSlice';

export default function CustomerDataScorecardContentNew() {

    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        CustomerDataScorecardD10: 0,
        CustomerDataScorecardD11: 0,
        CustomerDataScorecardD12: 0,
        CustomerDataScorecardD13: 0,
        CustomerDataScorecardD14: 0,
        CustomerDataScorecardE9: "",
        CustomerDataScorecardF10: "",
        CustomerDataScorecardD16: 0,
        CustomerDataScorecardD17: 0,
        CustomerDataScorecardD18: 0,
        CustomerDataScorecardD19: 0,
        CustomerDataScorecardD20: 0,
        CustomerDataScorecardE15: "",
        CustomerDataScorecardF16: "",
        CustomerDataScorecardD22: 0,
        CustomerDataScorecardD23: 0,
        CustomerDataScorecardD24: 0,
        CustomerDataScorecardD25: 0,
        CustomerDataScorecardD26: 0,
        CustomerDataScorecardE21: "",
        CustomerDataScorecardF22: "",
        CustomerDataScorecardE28: "",
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getCustomerDataScorecard({user_id}))
        .unwrap()
        .then((response) => {
            console.log("response23",response.data)
            if(response!= undefined  && response.data.clientGrowth){
                let clientGrowth = response.data.clientGrowth[0];
                // console.log(clientGrowth)
                delete clientGrowth.id;
                delete clientGrowth.user_id;
                delete clientGrowth.created_at;
                delete clientGrowth.updated_at;
                const newObj = { ...Inputs }
                for (const key in clientGrowth) {
                    if (clientGrowth.hasOwnProperty(key)) {
                        if(clientGrowth[key])
                        newObj[key] = clientGrowth[key]
                    }
                }
                
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

    }, [dispatch])
    //
    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                event.target.value = event.target.value ? event.target.value : 0
                dispatch(modifyCustomerDataScorecard({ user_id, [event.target.id] : event.target.value, 'CustomerDataScorecardE9': String(Inputs.CustomerDataScorecardE9), 'CustomerDataScorecardE15': String(Inputs.CustomerDataScorecardE15), 'CustomerDataScorecardE21': String(Inputs.CustomerDataScorecardE21), 'CustomerDataScorecardE28': String(Inputs.CustomerDataScorecardE28)}))
                .unwrap()
                .then((response) => {
                    // console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        //window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

    useEffect(() => {
        let CustomerDataScorecardE9 = sum(Number(Inputs.CustomerDataScorecardD10), Number(Inputs.CustomerDataScorecardD11), Number(Inputs.CustomerDataScorecardD12), Number(Inputs.CustomerDataScorecardD13), Number(Inputs.CustomerDataScorecardD14));
        let CustomerDataScorecardE15 = sum(Number(Inputs.CustomerDataScorecardD16), Number(Inputs.CustomerDataScorecardD17), Number(Inputs.CustomerDataScorecardD18), Number(Inputs.CustomerDataScorecardD19), Number(Inputs.CustomerDataScorecardD20));
        let CustomerDataScorecardE21 = sum(Number(Inputs.CustomerDataScorecardD22), Number(Inputs.CustomerDataScorecardD23), Number(Inputs.CustomerDataScorecardD24), Number(Inputs.CustomerDataScorecardD25), Number(Inputs.CustomerDataScorecardD26));

        let CustomerDataScorecardE28 = CustomerDataScorecardE9 + CustomerDataScorecardE15 + CustomerDataScorecardE21;

        setInputs({...Inputs, CustomerDataScorecardE9, CustomerDataScorecardE15, CustomerDataScorecardE21, CustomerDataScorecardE28});

    }, [Inputs.CustomerDataScorecardD10, Inputs.CustomerDataScorecardD11, Inputs.CustomerDataScorecardD12, Inputs.CustomerDataScorecardD13, Inputs.CustomerDataScorecardD14, Inputs.CustomerDataScorecardD16, Inputs.CustomerDataScorecardD17, Inputs.CustomerDataScorecardD18, Inputs.CustomerDataScorecardD19, Inputs.CustomerDataScorecardD20, Inputs.CustomerDataScorecardD22, Inputs.CustomerDataScorecardD23, Inputs.CustomerDataScorecardD24, Inputs.CustomerDataScorecardD25, Inputs.CustomerDataScorecardD26]);


    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
            <div className="container-fluid">
                <div className="head-title">
                    <h3 className='preheading'>Diagnose - Benchmark Tools</h3>
                    <h1 className='mainheading'>Customer Data Scorecard</h1>
                </div>
                <div className="target-markets-score-card-table">
                    <div className="table-responsive">
                        <table className="table" style={{width: 1300}}>
                            {/* table 01 */}
                            <thead>
                                <tr>
                                    <td className='black-bg text-white' style={{width: 700}}>
                                        <div className="before-your justify-content-between">
                                            <p className='font18px'>Customer Financial Values  (do you know…)</p>
                                            <img src={window.location.origin+"/images/customer-data-scorecard/image294.png"} alt=""/>
                                        </div>
                                    </td>
                                    <td className='black-bg text-white text-center' style={{width: 150}}>
                                        <span className='font14px'>Score</span><br/><span className='font11px'>(1 point each)</span>
                                    </td>
                                    <td className='bg-color-blue text-white text-center font16px' style={{width: 50}}>
                                        {formatPrice(Inputs.CustomerDataScorecardE9, 0)}
                                    </td>
                                    <td className='black-bg text-white text-center' style={{width: 500}}>
                                        <div className="before-your justify-content-between">
                                            <p className='font18px'>Opportunities to Improve</p>
                                            <img src={window.location.origin+"/images/customer-data-scorecard/image305.png"}  alt=""/>
                                        </div>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='bg-color-light-blue color-black font15px'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>Number of currently active and inactive customers</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerDataScorecardD10" 
                                                value={Inputs.CustomerDataScorecardD10} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerDataScorecardD10: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td rowSpan={5} className='bg-white padding-0'>
                                        <div className="arrow-targer-market"></div>
                                    </td>
                                    <td rowSpan={5} className='padding-0 bg-color-02'>
                                        <div className="input-fiel-cn">
                                            <textarea type="text" className="field-ipt bg-color-02 font11px color-dark"
                                            id="CustomerDataScorecardF10"
                                            value={Inputs.CustomerDataScorecardF10}
                                            onChange={(event) => setInputs({ ...Inputs, CustomerDataScorecardF10: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue color-black font15px'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>Average annual sales and profit per customer</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerDataScorecardD11" 
                                                value={Inputs.CustomerDataScorecardD11} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerDataScorecardD11: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue color-black font15px'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>Average transaction value (purchase amount)</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerDataScorecardD12" 
                                                value={Inputs.CustomerDataScorecardD12} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerDataScorecardD12: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue color-black font15px'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>Average customer annual purchase frequency</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerDataScorecardD13" 
                                                value={Inputs.CustomerDataScorecardD13} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerDataScorecardD13: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue color-black font15px'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>Average customer buying lifetime (typically in years)</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerDataScorecardD14" 
                                                value={Inputs.CustomerDataScorecardD14} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerDataScorecardD14: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            {/* table 02 */}
                            <thead>
                                <tr>
                                    <td className='black-bg text-white' style={{width: 700}}>
                                        <div className="before-your justify-content-between">
                                            <p className='font18px'>Use of Customer Data</p>
                                            <img src={window.location.origin+"/images/customer-data-scorecard/image296.png"} alt=""/>
                                        </div>
                                    </td>
                                    <td className='black-bg text-white text-center' style={{width: 150}}>
                                        <span className='font14px'>Score</span><br/><span className='font11px'>(1 point each)</span>
                                    </td>
                                    <td className='bg-color-blue text-white text-center font16px' style={{width: 50}}>
                                        {formatPrice(Inputs.CustomerDataScorecardE15, 0)}
                                    </td>
                                    <td className='black-bg text-white text-center' style={{width: 500}}>
                                        <div className="before-your justify-content-between">
                                            <p className='font18px'>Opportunities to Improve</p>
                                            <img src={window.location.origin+"/images/customer-data-scorecard/image307.png"}  alt=""/>
                                        </div>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='bg-color-light-blue color-black font15px'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>Do you target offers to buyers with different buying histories?</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerDataScorecardD16" 
                                                value={Inputs.CustomerDataScorecardD16} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerDataScorecardD16: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td rowSpan={5} className='bg-white padding-0'>
                                        <div className="arrow-targer-market"></div>
                                    </td>
                                    <td rowSpan={5} className='padding-0 bg-color-02'>
                                        <div className="input-fiel-cn">
                                            <textarea type="text" className="field-ipt bg-color-02 font11px color-dark"
                                            id="CustomerDataScorecardF16"
                                            value={Inputs.CustomerDataScorecardF16}
                                            onChange={(event) => setInputs({ ...Inputs, CustomerDataScorecardF16: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue color-black font15px'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>Do you make unique offers to prospects who haven't closed yet?</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerDataScorecardD17" 
                                                value={Inputs.CustomerDataScorecardD17} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerDataScorecardD17: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue color-black font15px'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>Do you make unique offers to customer who haven't purchased recently?</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerDataScorecardD18" 
                                                value={Inputs.CustomerDataScorecardD18} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerDataScorecardD18: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue color-black font15px'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>Do you use data to better understand how customers move along the buyer's journey?</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerDataScorecardD19" 
                                                value={Inputs.CustomerDataScorecardD19} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerDataScorecardD19: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue color-black font15px'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>Do you know which customer groups are most responsive to your marketing?</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerDataScorecardD20" 
                                                value={Inputs.CustomerDataScorecardD20} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerDataScorecardD20: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            {/* table 03 */}
                            <thead>
                                <tr>
                                    <td className='black-bg text-white' style={{width: 700}}>
                                        <div className="before-your justify-content-between">
                                            <p className='font18px'>Does Your Customer Database Include…</p>
                                            <img src={window.location.origin+"/images/customer-data-scorecard/image297.png"} alt=""/>
                                        </div>
                                    </td>
                                    <td className='black-bg text-white text-center' style={{width: 150}}>
                                        <span className='font14px'>Score</span><br/><span className='font11px'>(1 point each)</span>
                                    </td>
                                    <td className='bg-color-blue text-white text-center font16px' style={{width: 50}}>
                                        {formatPrice(Inputs.CustomerDataScorecardE21, 0)}
                                    </td>
                                    <td className='black-bg text-white text-center' style={{width: 500}}>
                                        <div className="before-your justify-content-between">
                                            <p className='font18px'>Opportunities to Improve</p>
                                            <img src={window.location.origin+"/images/customer-data-scorecard/image309.png"}  alt=""/>
                                        </div>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='bg-color-light-blue color-black font15px'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>All current and past customers</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerDataScorecardD22" 
                                                value={Inputs.CustomerDataScorecardD22} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerDataScorecardD22: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td rowSpan={5} className='bg-white padding-0'>
                                        <div className="arrow-targer-market"></div>
                                    </td>
                                    <td rowSpan={5} className='padding-0 bg-color-02'>
                                        <div className="input-fiel-cn">
                                            <textarea type="text" className="field-ipt bg-color-02 font11px color-dark"
                                            id="CustomerDataScorecardF22"
                                            value={Inputs.CustomerDataScorecardF22}
                                            onChange={(event) => setInputs({ ...Inputs, CustomerDataScorecardF22: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue color-black font15px'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>All contact information, including phone numbers and email addresses</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerDataScorecardD23" 
                                                value={Inputs.CustomerDataScorecardD23} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerDataScorecardD23: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue color-black font15px'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>The ability to easily analyze and segment by purchase history</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerDataScorecardD24" 
                                                value={Inputs.CustomerDataScorecardD24} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerDataScorecardD24: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue color-black font15px'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>Where or how the lead was generated</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerDataScorecardD25" 
                                                value={Inputs.CustomerDataScorecardD25} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerDataScorecardD25: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue color-black font15px'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>We have an effective system for keeping our database up-to-date</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerDataScorecardD26" 
                                                value={Inputs.CustomerDataScorecardD26} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerDataScorecardD26: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            {/* table 04 */}
                            <thead>
                                <tr>
                                    <td className='bg-white'>
                                        <div className="nextInput">
                                            <ul>
                                                <li>
                                                    <Link className="next-link" to="/diagnose/financial-performance"> Return to Benchmark Questions
                                                        <span className="icon-three">
                                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                                        </span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td className='black-bg text-white font16px fontBold text-center'>
                                    Total
                                    </td>
                                    <td className='bg-color-blue text-white text-center font16px'>
                                    {formatPrice(Inputs.CustomerDataScorecardE28, 0)}
                                    </td>
                                    <td className='hide-content'></td>
                                </tr>
                            </thead>
                        </table>
                    </div>  
                </div>
            </div>
        </div>
    )
}