import React, { useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { changeUpdateStateFlag } from "../state/features/AuthenticationSlice";
import {ThreeDots} from "react-loader-spinner";
import TopHead from '../components/TopHead';
import Sidebar from '../components/Sidebar';
import UserDropdown from '../components/UserDropdown';
import Footer from '../components/Footer';

function ChangeStateFlagRoute({ children }) {
  const updateStateFlag = useSelector(state => state.auth.updateStateFlag);
  const dispatch = useDispatch();
    useEffect(() => {
        dispatch(changeUpdateStateFlag(true)); 
    }, []);
    return updateStateFlag  ? children : (
        <div>
            <div id="main-wrapper">
                <div id="disable">
                    <TopHead />
                    <Sidebar />
                </div>
                <div className="content-body">
                    <UserDropdown />
                    {/* row */}
                    <div className="container-fluid">
                        <div className="head-title">
                            <h2>Contents</h2>	            					
                        </div>
                        <div className="spinner">
                            <ThreeDots 
                            height="80" 
                            width="80" 
                            radius="9"
                            color="#297cf8" 
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                            />
                            Please wait...
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    ) ; 
}

export default ChangeStateFlagRoute;