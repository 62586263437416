import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getInnovativeOfferingAnalysis, modifyInnovativeOfferingAnalysis } from "../../state/features/InnovativeOfferingAnalysisSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import sum from "../../utils/sum";
import formatPrice from '../../utils/formatPrice';
import { Link } from 'react-router-dom';

export default function InnovativeOfferingAnalysisContentNew() {

    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;

    const [Inputs, setInputs] = useState({
        InnovativeOfferingAnalysisE13: "Product or Service A",
        InnovativeOfferingAnalysisF13: 0,
        InnovativeOfferingAnalysisH13: 0,
        InnovativeOfferingAnalysisJ13: 0,
        InnovativeOfferingAnalysisL13: 0,
        InnovativeOfferingAnalysisN13: 0,
        InnovativeOfferingAnalysisP13: 0,
        InnovativeOfferingAnalysisE14: "Product or Service B",
        InnovativeOfferingAnalysisF14: 0,
        InnovativeOfferingAnalysisH14: 0,
        InnovativeOfferingAnalysisJ14: 0,
        InnovativeOfferingAnalysisL14: 0,
        InnovativeOfferingAnalysisN14: 0,
        InnovativeOfferingAnalysisP14: 0,
        InnovativeOfferingAnalysisE15: "Product or Service C",
        InnovativeOfferingAnalysisF15: 0,
        InnovativeOfferingAnalysisH15: 0,
        InnovativeOfferingAnalysisJ15: 0,
        InnovativeOfferingAnalysisL15: 0,
        InnovativeOfferingAnalysisN15: 0,
        InnovativeOfferingAnalysisP15: 0,
        InnovativeOfferingAnalysisE16: "Product or Service D",
        InnovativeOfferingAnalysisF16: 0,
        InnovativeOfferingAnalysisH16: 0,
        InnovativeOfferingAnalysisJ16: 0,
        InnovativeOfferingAnalysisL16: 0,
        InnovativeOfferingAnalysisN16: 0,
        InnovativeOfferingAnalysisP16: 0,
        InnovativeOfferingAnalysisE17: "Product or Service E",
        InnovativeOfferingAnalysisF17: 0,
        InnovativeOfferingAnalysisH17: 0,
        InnovativeOfferingAnalysisJ17: 0,
        InnovativeOfferingAnalysisL17: 0,
        InnovativeOfferingAnalysisN17: 0,
        InnovativeOfferingAnalysisP17: 0,
        InnovativeOfferingAnalysisF19: 0,
        InnovativeOfferingAnalysisH19: 0,
        InnovativeOfferingAnalysisJ19: 0,
        InnovativeOfferingAnalysisL19: 0,
        InnovativeOfferingAnalysisN19: 0,
        InnovativeOfferingAnalysisP19: 0,
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getInnovativeOfferingAnalysis({user_id}))
        .unwrap()
        .then((response) => {
            console.log("response1",response.data)
            if(response!= undefined  && response.data.growthPlanning){
                let growthPlanning = response.data.growthPlanning[0];
                // console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key]
                    }
                }
                
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

    }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyInnovativeOfferingAnalysis({ user_id, [event.target.id] : event.target.value, 'InnovativeOfferingAnalysisP13': String(Inputs.InnovativeOfferingAnalysisP13), 'InnovativeOfferingAnalysisP14': String(Inputs.InnovativeOfferingAnalysisP14), 'InnovativeOfferingAnalysisP15': String(Inputs.InnovativeOfferingAnalysisP15), 'InnovativeOfferingAnalysisP16': String(Inputs.InnovativeOfferingAnalysisP16), 'InnovativeOfferingAnalysisP17': String(Inputs.InnovativeOfferingAnalysisP17), 'InnovativeOfferingAnalysisP19': String(Inputs.InnovativeOfferingAnalysisP19), 'InnovativeOfferingAnalysisF19': String(Inputs.InnovativeOfferingAnalysisF19), 'InnovativeOfferingAnalysisH19': String(Inputs.InnovativeOfferingAnalysisH19), 'InnovativeOfferingAnalysisJ19': String(Inputs.InnovativeOfferingAnalysisJ19), 'InnovativeOfferingAnalysisL19': String(Inputs.InnovativeOfferingAnalysisL19), 'InnovativeOfferingAnalysisN19': String(Inputs.InnovativeOfferingAnalysisN19)}))
                .unwrap()
                .then((response) => {
                    // console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        //window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }
    }

    useEffect(() => {

        let InnovativeOfferingAnalysisP13 = sum(Number(Inputs.InnovativeOfferingAnalysisF13), Number(Inputs.InnovativeOfferingAnalysisH13), Number(Inputs.InnovativeOfferingAnalysisJ13), Number(Inputs.InnovativeOfferingAnalysisL13), Number(Inputs.InnovativeOfferingAnalysisN13));
        let InnovativeOfferingAnalysisP14 = sum(Number(Inputs.InnovativeOfferingAnalysisF14), Number(Inputs.InnovativeOfferingAnalysisH14), Number(Inputs.InnovativeOfferingAnalysisJ14), Number(Inputs.InnovativeOfferingAnalysisL14), Number(Inputs.InnovativeOfferingAnalysisN14));
        let InnovativeOfferingAnalysisP15 = sum(Number(Inputs.InnovativeOfferingAnalysisF15), Number(Inputs.InnovativeOfferingAnalysisH15), Number(Inputs.InnovativeOfferingAnalysisJ15), Number(Inputs.InnovativeOfferingAnalysisL15), Number(Inputs.InnovativeOfferingAnalysisN15));
        let InnovativeOfferingAnalysisP16 = sum(Number(Inputs.InnovativeOfferingAnalysisF16), Number(Inputs.InnovativeOfferingAnalysisH16), Number(Inputs.InnovativeOfferingAnalysisJ16), Number(Inputs.InnovativeOfferingAnalysisL16), Number(Inputs.InnovativeOfferingAnalysisN16));
        let InnovativeOfferingAnalysisP17 = sum(Number(Inputs.InnovativeOfferingAnalysisF17), Number(Inputs.InnovativeOfferingAnalysisH17), Number(Inputs.InnovativeOfferingAnalysisJ17), Number(Inputs.InnovativeOfferingAnalysisL17), Number(Inputs.InnovativeOfferingAnalysisN17));

        let InnovativeOfferingAnalysisF19 = sum(Number(Inputs.InnovativeOfferingAnalysisF13), Number(Inputs.InnovativeOfferingAnalysisF14), Number(Inputs.InnovativeOfferingAnalysisF15), Number(Inputs.InnovativeOfferingAnalysisF16), Number(Inputs.InnovativeOfferingAnalysisF17));
        let InnovativeOfferingAnalysisH19 = sum(Number(Inputs.InnovativeOfferingAnalysisH13), Number(Inputs.InnovativeOfferingAnalysisH14), Number(Inputs.InnovativeOfferingAnalysisH15), Number(Inputs.InnovativeOfferingAnalysisH16), Number(Inputs.InnovativeOfferingAnalysisH17));
        let InnovativeOfferingAnalysisJ19 = sum(Number(Inputs.InnovativeOfferingAnalysisJ13), Number(Inputs.InnovativeOfferingAnalysisJ14), Number(Inputs.InnovativeOfferingAnalysisJ15), Number(Inputs.InnovativeOfferingAnalysisJ16), Number(Inputs.InnovativeOfferingAnalysisJ17));
        let InnovativeOfferingAnalysisL19 = sum(Number(Inputs.InnovativeOfferingAnalysisL13), Number(Inputs.InnovativeOfferingAnalysisL14), Number(Inputs.InnovativeOfferingAnalysisL15), Number(Inputs.InnovativeOfferingAnalysisL16), Number(Inputs.InnovativeOfferingAnalysisL17));
        let InnovativeOfferingAnalysisN19 = sum(Number(Inputs.InnovativeOfferingAnalysisN13), Number(Inputs.InnovativeOfferingAnalysisN14), Number(Inputs.InnovativeOfferingAnalysisN15), Number(Inputs.InnovativeOfferingAnalysisN16), Number(Inputs.InnovativeOfferingAnalysisN17));
        let InnovativeOfferingAnalysisP19 = sum(InnovativeOfferingAnalysisP13, InnovativeOfferingAnalysisP14, InnovativeOfferingAnalysisP15, InnovativeOfferingAnalysisP16, InnovativeOfferingAnalysisP17);

        setInputs({...Inputs, InnovativeOfferingAnalysisP13, InnovativeOfferingAnalysisP14, InnovativeOfferingAnalysisP15, InnovativeOfferingAnalysisP16, InnovativeOfferingAnalysisP17, InnovativeOfferingAnalysisP19, InnovativeOfferingAnalysisF19, InnovativeOfferingAnalysisH19, InnovativeOfferingAnalysisJ19, InnovativeOfferingAnalysisL19, InnovativeOfferingAnalysisN19});

    }, [Inputs.InnovativeOfferingAnalysisF13, Inputs.InnovativeOfferingAnalysisH13, Inputs.InnovativeOfferingAnalysisJ13, Inputs.InnovativeOfferingAnalysisL13, Inputs.InnovativeOfferingAnalysisN13, Inputs.InnovativeOfferingAnalysisF14, Inputs.InnovativeOfferingAnalysisH14, Inputs.InnovativeOfferingAnalysisJ14, Inputs.InnovativeOfferingAnalysisL14, Inputs.InnovativeOfferingAnalysisN14, Inputs.InnovativeOfferingAnalysisF15, Inputs.InnovativeOfferingAnalysisH15, Inputs.InnovativeOfferingAnalysisJ15, Inputs.InnovativeOfferingAnalysisL15, Inputs.InnovativeOfferingAnalysisN15, Inputs.InnovativeOfferingAnalysisF16, Inputs.InnovativeOfferingAnalysisH16, Inputs.InnovativeOfferingAnalysisJ16, Inputs.InnovativeOfferingAnalysisL16, Inputs.InnovativeOfferingAnalysisN16, Inputs.InnovativeOfferingAnalysisF17, Inputs.InnovativeOfferingAnalysisH17, Inputs.InnovativeOfferingAnalysisJ17, Inputs.InnovativeOfferingAnalysisL17, Inputs.InnovativeOfferingAnalysisN17]);

    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
            <div className="container-fluid">
                <div className="head-title mb-4">
                    <h3 className='preheading'>Diagnose - Benchmark Tools</h3>
                    <h1 className='mainheading'>Innovative Offering Analysis</h1>
                    <div className=' mb-5' style={{maxWidth: 1200}}>
                        <p className='body-text color-black'>Customers see your products, services, and relationships from a competitive position.   Unless you constantly innovate to improve products, services, and releationship, you will slide toward the left of the chart.  Breakthrough is not necessarily the best category, but an organization must decide where it wants to be in the future so resources and investment can be properly allocated today. </p>
                        <p className="body-text color-black">Where are you today, and where would you like to be in the future?  Now compare the two entries.  What will it take you to get there?  (becomes the basis for planning, investment, and resource allocation) </p>
                        {/* <p className="body-text color-black">Customers see your products, services, and relationships from a competitive position.  That means you must constantly innovate and improve.  How do you currently rank?</p> */}
                    </div>
                </div>
                <div className="opportunity-page-table">
                    <div className="opportunity-page-table-inner opportunity-abl">
                        <div className="table-responsive mb-3" style={{maxWidth: 1400}}>
                            <table className="table responsive-table" style={{width: 1400}}>
                                <thead>
                                    <tr>
                                        <td colSpan={2} rowSpan={2} className="font18px black-bg text-white text-center-c" style={{width: 500}}>Top 5 Products & Services</td>
                                        <td colSpan={5} className="bg-color-blue-gray font18px text-white text-center-c" style={{width: 750}}>Value Delivered to Market</td>
                                        <td className='hide-content'></td>
                                    </tr>
                                    <tr>
                                        <td className="black-bg text-white text-center font12px" style={{width: 150}}>Worthless (1)</td>
                                        <td className="black-bg text-white text-center font12px" style={{width: 150}}>Lacking (2)</td>
                                        <td className="black-bg text-white text-center font12px" style={{width: 150}}>Competitive  (3)</td>
                                        <td className="black-bg text-white text-center font12px" style={{width: 150}}>Distinct  (4)</td>
                                        <td className="black-bg text-white text-center font12px" style={{width: 150}}>Breakthrough  (6)</td>
                                        <td className='black-bg text-white text-center font12px' style={{width: 150}}>
                                        Total
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='black-bg text-center text-white'>1</td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01">
                                                <input type="text" className='field-ipt font12px bg-color-01 color-dark'
                                                id="InnovativeOfferingAnalysisE13"
                                                value={Inputs.InnovativeOfferingAnalysisE13}
                                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisE13: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="Product or Service A" />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="InnovativeOfferingAnalysisF13" 
                                                    value={Inputs.InnovativeOfferingAnalysisF13} 
                                                    onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisF13: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0|1]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score 1.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="InnovativeOfferingAnalysisH13" 
                                                    value={Inputs.InnovativeOfferingAnalysisH13} 
                                                    onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisH13: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0|2]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score 2.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="InnovativeOfferingAnalysisJ13" 
                                                    value={Inputs.InnovativeOfferingAnalysisJ13} 
                                                    onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisJ13: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0|3]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score 3.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="InnovativeOfferingAnalysisL13" 
                                                    value={Inputs.InnovativeOfferingAnalysisL13} 
                                                    onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisL13: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0|4]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score 4.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="InnovativeOfferingAnalysisN13" 
                                                    value={Inputs.InnovativeOfferingAnalysisN13} 
                                                    onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisN13: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0|6]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score 6.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center font12px'>
                                            <div className="scrore-col">
                                                {formatPrice(Inputs.InnovativeOfferingAnalysisP13, 0)}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-center text-white'>2</td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01">
                                                <input type="text"  className='field-ipt font12px bg-color-01 color-dark'
                                                id="InnovativeOfferingAnalysisE14"
                                                value={Inputs.InnovativeOfferingAnalysisE14}
                                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisE14: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="Product or Service A" />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="InnovativeOfferingAnalysisF14" 
                                                    value={Inputs.InnovativeOfferingAnalysisF14} 
                                                    onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisF14: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0|1]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score 1.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="InnovativeOfferingAnalysisH14" 
                                                    value={Inputs.InnovativeOfferingAnalysisH14} 
                                                    onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisH14: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0|2]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score 2.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="InnovativeOfferingAnalysisJ14" 
                                                    value={Inputs.InnovativeOfferingAnalysisJ14} 
                                                    onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisJ14: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0|3]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score 3.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="InnovativeOfferingAnalysisL14" 
                                                    value={Inputs.InnovativeOfferingAnalysisL14} 
                                                    onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisL14: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0|4]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score 4.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="InnovativeOfferingAnalysisN14" 
                                                    value={Inputs.InnovativeOfferingAnalysisN14} 
                                                    onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisN14: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0|6]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score 6.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center font12px'>
                                            <div className="scrore-col">
                                                {formatPrice(Inputs.InnovativeOfferingAnalysisP14, 0)}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-center text-white'>3</td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01">
                                                <input type="text"  className='field-ipt font12px bg-color-01 color-dark'
                                                id="InnovativeOfferingAnalysisE15"
                                                value={Inputs.InnovativeOfferingAnalysisE15}
                                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisE15: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="Product or Service A" />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="InnovativeOfferingAnalysisF15" 
                                                    value={Inputs.InnovativeOfferingAnalysisF15} 
                                                    onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisF15: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0|1]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score 1.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="InnovativeOfferingAnalysisH15" 
                                                    value={Inputs.InnovativeOfferingAnalysisH15} 
                                                    onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisH15: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0|2]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score 2.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="InnovativeOfferingAnalysisJ15" 
                                                    value={Inputs.InnovativeOfferingAnalysisJ15} 
                                                    onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisJ15: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0|3]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score 3.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="InnovativeOfferingAnalysisL15" 
                                                    value={Inputs.InnovativeOfferingAnalysisL15} 
                                                    onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisL15: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0|4]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score 4.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="InnovativeOfferingAnalysisN15" 
                                                    value={Inputs.InnovativeOfferingAnalysisN15} 
                                                    onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisN15: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0|6]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score 6.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center font12px'>
                                            <div className="scrore-col">
                                                {formatPrice(Inputs.InnovativeOfferingAnalysisP15, 0)}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-center text-white'>4</td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01">
                                                <input type="text"  className='field-ipt font12px bg-color-01 color-dark'
                                                id="InnovativeOfferingAnalysisE16"
                                                value={Inputs.InnovativeOfferingAnalysisE16}
                                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisE16: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="Product or Service A" />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="InnovativeOfferingAnalysisF16" 
                                                    value={Inputs.InnovativeOfferingAnalysisF16} 
                                                    onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisF16: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0|1]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score 1.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="InnovativeOfferingAnalysisH16" 
                                                    value={Inputs.InnovativeOfferingAnalysisH16} 
                                                    onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisH16: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0|2]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score 2.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="InnovativeOfferingAnalysisJ16" 
                                                    value={Inputs.InnovativeOfferingAnalysisJ16} 
                                                    onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisJ16: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0|3]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score 3.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="InnovativeOfferingAnalysisL16" 
                                                    value={Inputs.InnovativeOfferingAnalysisL16} 
                                                    onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisL16: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0|4]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score 4.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="InnovativeOfferingAnalysisN16" 
                                                    value={Inputs.InnovativeOfferingAnalysisN16} 
                                                    onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisN16: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0|6]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score 6.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center font12px'>
                                            <div className="scrore-col">
                                                {formatPrice(Inputs.InnovativeOfferingAnalysisP16, 0)}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-center text-white'>5</td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01">
                                                <input type="text"  className='field-ipt font12px bg-color-01 color-dark'
                                                id="InnovativeOfferingAnalysisE17"
                                                value={Inputs.InnovativeOfferingAnalysisE17}
                                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisE17: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="Product or Service A" />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="InnovativeOfferingAnalysisF17" 
                                                    value={Inputs.InnovativeOfferingAnalysisF17} 
                                                    onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisF17: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0|1]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score 1.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="InnovativeOfferingAnalysisH17" 
                                                    value={Inputs.InnovativeOfferingAnalysisH17} 
                                                    onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisH17: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0|2]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score 2.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="InnovativeOfferingAnalysisJ17" 
                                                    value={Inputs.InnovativeOfferingAnalysisJ17} 
                                                    onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisJ17: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0|3]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score 3.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="InnovativeOfferingAnalysisL17" 
                                                    value={Inputs.InnovativeOfferingAnalysisL17} 
                                                    onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisL17: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0|4]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score 4.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="InnovativeOfferingAnalysisN17" 
                                                    value={Inputs.InnovativeOfferingAnalysisN17} 
                                                    onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisN17: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0|6]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score 6.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center font12px'>
                                            <div className="scrore-col">
                                                {formatPrice(Inputs.InnovativeOfferingAnalysisP17, 0)}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>5</td>
                                        <td className='bg-color-blue text-white text-left'>
                                            <div className="scrore-col font11px">
                                            Average
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center font12px'>
                                            <div className="scrore-col font12px">
                                                {formatPrice(Inputs.InnovativeOfferingAnalysisF19, 0)}
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center font12px'>
                                            <div className="scrore-col font12px">
                                                {formatPrice(Inputs.InnovativeOfferingAnalysisH19, 0)}
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center font12px'>
                                            <div className="scrore-col font12px">
                                                {formatPrice(Inputs.InnovativeOfferingAnalysisJ19, 0)}
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center font12px'>
                                            <div className="scrore-col font12px">
                                                {formatPrice(Inputs.InnovativeOfferingAnalysisL19, 0)}
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center font12px'>
                                            <div className="scrore-col font12px">
                                                {formatPrice(Inputs.InnovativeOfferingAnalysisN19, 0)}
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center font12px'>
                                            <div className="scrore-col font12px fontBold">
                                                {formatPrice(Inputs.InnovativeOfferingAnalysisP19, 0)}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="nextInput">
                            <ul>
                                <li>
                                    <Link className="next-link" to="/diagnose/competitive-advantage"> Return to Benchmark Questions
                                        <span className="icon-three">
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        </span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}