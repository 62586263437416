export const clientInputsData = (type, inputData) => {
    return (dispatch) => {
        dispatch({
            type    : type,
            payload : inputData
        })
    }
}

export const conversionData = (type, inputData) => {
    return (dispatch) => {
        dispatch({
            type    : type,
            payload : inputData
        })
    }
}

export const leadGenerationData = (type, inputData) => {
    return (dispatch) => {
        dispatch({
            type    : type,
            payload : inputData
        })
    }
}

export const RESET_ACTION  = () => {
    return (dispatch) => {
        dispatch({
            type    : "RESET"
        })
    }
}

export const referralsData = (type, inputData) => {
    return (dispatch) => {
        dispatch({
            type    : type,
            payload : inputData
        })
    }
}

export const CustomerFinancialValuesData = (type, inputData) => {
    return (dispatch) => {
        dispatch({
            type    : type,
            payload : inputData
        })
    }
}

export const purchaseFrequencyData = (type, inputData) => {
    return (dispatch) => {
        dispatch({
            type    : type,
            payload : inputData
        })
    }
}

export const buyingLifeTimeData = (type, inputData) => {
    return (dispatch) => {
        dispatch({
            type    : type,
            payload : inputData
        })
    }
}

export const profitMarginData = (type, inputData) => {
    return (dispatch) => {
        dispatch({
            type    : type,
            payload : inputData
        })
    }
}

export const customerReactivationData = (type, inputData) => {
    return (dispatch) => {
        dispatch({
            type    : type,
            payload : inputData
        })
    }
}

export const impactof120GrowthOSData = (type, inputData) => {
    return (dispatch) => {
        dispatch({
            type    : type,
            payload : inputData
        })
    }
}

export const averageTransactionValueData = (type, inputData) => {
    return (dispatch) => {
        dispatch({
            type    : type,
            payload : inputData
        })
    }
}

export const reducingAttritionData = (type, inputData) => {
    return (dispatch) => {
        dispatch({
            type    : type,
            payload : inputData
        })
    }
}

export const creatingGrowthMomentumData = (type, inputData) => {
    return (dispatch) => {
        dispatch({
            type    : type,
            payload : inputData
        })
    }
}

export const findingGrowthInYourBusinessData = (type, inputData) => {
    return (dispatch) => {
        dispatch({
            type    : type,
            payload : inputData
        })
    }
}

export const whatsDrivingGrowthData = (type, inputData) => {
    return (dispatch) => {
        dispatch({
            type    : type,
            payload : inputData
        })
    }
}

export const equityEvolutionTheBigBonusReducerData = (type, inputData) => {
    return (dispatch) => {
        dispatch({
            type    : type,
            payload : inputData
        })
    }
}

export const impact120GrowthROIData = (type, inputData) => {
    return (dispatch) => {
        dispatch({
            type    : type,
            payload : inputData
        })
    }
}
export const bqMainReducerData = (type, inputData) => {
    return (dispatch) => {
        dispatch({
            type    : type,
            payload : inputData
        })
    }
}
export const benchmarkScoreGapAnalysisData = (type, inputData) => {
    return (dispatch) => {
        dispatch({
            type    : type,
            payload : inputData
        })
    }
}
export const benchmarkScorecardData = (type, inputData) => {
    return (dispatch) => {
        dispatch({
            type    : type,
            payload : inputData
        })
    }
}

export const CustomerRetentionLoopData = (type, inputData) => {
    return (dispatch) => {
        dispatch({
            type    : type,
            payload : inputData
        })
    }
}



