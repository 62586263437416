import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state/index';
import { Link } from "react-router-dom";
import  UserDropdown  from '../UserDropdown';
import { addEditImprovementPercentage } from "../../state/features/ClentInputSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { useAlert } from 'react-alert'
import { Spinner } from '../spinner'
import { StatesUseEffect } from '../../utils/StatesUseEffect';
import { BarChartGraphNew } from '../graph/BarChartGraphNew';

export default function CustomerAttritionContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const users = useSelector(userData);
    const userID = users.data.profile.id;
    const { message } = useSelector((state) => state.message);

    const dispatch = useDispatch();
    const { reducingAttritionData } = bindActionCreators(actionCreators, dispatch)
    
    const clientInputs = useSelector(state => state.clientInputs)
    const improvementPercentage = clientInputs.improvementPercentage
    const reducingAttrition = useSelector(state => state.reducingAttrition)
    //console.log(reducingAttrition)

    const [Inputs, setInputs] = useState({
        reducingAttritionD13: Number(reducingAttrition.reducingAttritionD13).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})
    });

    const { clientInputsH22 } = clientInputs
    let reducingAttritionD12 = parseFloat(clientInputsH22) ? parseFloat(clientInputsH22) : 0 

    useEffect(() => {
        console.log('first');
        reducingAttritionData("reducingAttritionD12", reducingAttritionD12);
        reducingAttritionData("reducingAttritionD13", improvementPercentage.reducingAttritionD13);
    }, []);

    const { reducingAttritionD16, reducingAttritionD17, reducingAttritionD18, reducingAttritionD19, reducingAttritionD23, reducingAttritionE23, reducingAttritionF23, reducingAttritionG23, reducingAttritionH23, reducingAttritionI23, reducingAttritionD24, reducingAttritionE24, reducingAttritionF24, reducingAttritionG24, reducingAttritionH24, reducingAttritionI24, reducingAttritionD25, reducingAttritionE25, reducingAttritionF25, reducingAttritionG25, reducingAttritionH25, reducingAttritionI25, reducingAttritionD26, reducingAttritionE26, reducingAttritionF26, reducingAttritionG26, reducingAttritionH26, reducingAttritionI26, reducingAttritionD30, reducingAttritionE30, reducingAttritionF30, reducingAttritionG30, reducingAttritionH30, reducingAttritionI30,reducingAttritionD31, reducingAttritionE31, reducingAttritionF31, reducingAttritionG31, reducingAttritionH31, reducingAttritionI31, reducingAttritionD33, reducingAttritionE33, reducingAttritionF33, reducingAttritionG33, reducingAttritionH33, reducingAttritionI33, reducingAttritionD34, reducingAttritionE34, reducingAttritionF34, reducingAttritionG34, reducingAttritionH34, reducingAttritionI34, reducingAttritionD36, reducingAttritionE36, reducingAttritionF36, reducingAttritionG36, reducingAttritionH36, reducingAttritionI36  } = reducingAttrition //object destructuring for reducingAttrition

    const improvementPrcntHandler = (impPrcnt) => {
        setIsLoading(true);
        let sendData = {user_id : userID, reducingAttritionD13: impPrcnt}
        dispatch(addEditImprovementPercentage(sendData))
        .unwrap()
        .then((response) => {
          console.log("response",response)
            if(response!= undefined){
               console.log('success')
            }
            setIsLoading(false)   // Hide loading screen 
        })
        .catch((error) => {
          console.log("error",error)
            { message && alert.show(message) }
            setIsLoading(false)   // Hide loading screen 
        });
    }

    // call states useEffect utils
    StatesUseEffect()

  return (
    <div className="content-body content-body-new bg-white-color">
    <UserDropdown />
    
  {/* row */}
        <div className="container-fluid lead-generation-new">
            <div className="head-title mb-5">
              <h3 className='preheading'>Prescribe - Growth Drivers</h3>
              <h1 className='mainheading mb-1'>Reducing Attrition</h1>	
            </div>
            <div className="row lead-genearation-rw mb-5" style={{maxWidth: 1260}}>
                <div className="col-12 col-lg-6">
                    {/* <div className="potential-growth-ld mb-3" style={{maxWidth: 510}}>
                      <h3><strong>Potential Growth Activities or Tactics:</strong></h3>
                      <ol>
                          <li>Activity 1</li>
                          <li>Activity 2</li>
                          <li>Activity 3</li>
                      </ol>
                    </div> */}
                    <div className="lead-generation-table mt-218">
                        <div className="responsive-table mb-3" style={{overflowY: 'auto'}}>
                            <table className='table' style={{width: 510, marginBottom: 0}}>
                              <thead>
                                  <tr>
                                    <th scope='col' className='black-bg text-white fontBold' style={{width: 360}}>Reducing Customer Attrition</th>
                                    <th className='black-bg hide-content' style={{width: 150}}>2</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td scope='row' className='bg-color-light-blue font12px color-black'>Current Customer Attrition Rate</td>
                                <td className='bg-color-light-blue font12px color-black text-right-rt'>{Number(reducingAttritionD12).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}%</td>
                            </tr>
                            <tr>
                                <td scope='row' className='bg-color-light-blue font12px color-black'>Projected Improvement</td>
                                <th scope='row' className='padding-0'>
                                    <div className="dollor-sin-pr">
                                        <div className="input-fiel-cn bg-color-01 client-inpt-field">
                                            <input type="text" 
                                            name="reducingAttritionD13"
                                            className="field-ipt wthinherit font12px bg-color-01 text-right text-black"
                                            placeholder={0}
                                            value={Inputs.reducingAttritionD13}
                                            onChange={(event) => {
                                                setInputs({ ...Inputs, reducingAttritionD13: event.target.value });
                                                reducingAttritionData("reducingAttritionD13", event.target.value);
                                                improvementPrcntHandler(event.target.value)
                                            }}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            disabled={isLoading} />
                                        </div>
                                        <div className="perecentage">%</div>
                                    </div>
                                </th>
                            </tr>
                        </tbody>
                        </table>
                        <Spinner />
                    </div>
                    <div className="responsive-table" style={{overflowY: 'auto'}}>
                            <table className='table' style={{width: 510, marginBottom: 0}}>
                              <thead>
                                  <tr>
                                    <th scope='col' className='black-bg text-white fontBold' style={{width: 360}}>Reducing Customer Attrition</th>
                                    <th className='black-bg hide-content' style={{width: 150}}>2</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr>
                            <td scope='col' className='black-bg text-white font12px'>Losses Due to Customer Attrition</td>
                            <td scope='col' className='black-bg text-white font12px'>Annual Losses</td>
                            </tr>
                            <tr>
                            <td className='bg-color-light-blue font12px color-black'>Total active Customers</td>
                            <td className='bg-color-light-blue font12px color-black'>
                                <div className="annual-dollor-sign">
                                    <div className="annual-dl font12px"></div>
                                    <div className="annual-dl-number font12px">
                                    {Number(reducingAttritionD16).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}
                                    </div>
                                </div>
                            </td>
                            </tr>
                            <tr>
                            <th className='bg-color-light-blue font12px color-black'>Customers Lost Annually</th>
                            <td className="color-dr font12px text-white">
                                <div className="annual-dollor-sign">
                                    <div className="annual-dl font12px text-white"></div>
                                    <div className="annual-dl-number font12px text-white">
                                    {Number(reducingAttritionD17).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}
                                    </div>
                                </div>
                            </td>
                            </tr>
                            <tr>
                            <th className='bg-color-light-blue font12px color-black'>Sales Lost Annually</th>
                            <td className="color-dr font12px text-white">
                                <div className="annual-dollor-sign">
                                    <div className="annual-dl font12px text-white"> $ </div>
                                    <div className="annual-dl-number font12px text-white">
                                        {Number(reducingAttritionD18).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                    </div>
                                </div>
                            </td>
                            </tr>
                            <tr>
                            <th className='bg-color-light-blue font12px color-black'>Profits Lost Annually</th>
                            <td className="color-dr font12px text-white">
                                <div className="annual-dollor-sign">
                                    <div className="annual-dl font12px text-white"> $ </div>
                                    <div className="annual-dl-number font12px text-white">
                                        {Number(reducingAttritionD19).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                    </div>
                                </div>
                            </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="col-12 col-lg-6" style={{minHeight: 400}}>
                <BarChartGraphNew dataInput={{
                    "Title"      : "Reducing Attrition: Impact on Financial Performance ",
                    "ColorCodes" : ["#d6dce5", "#8497b0", "#1e2b3c"],
                    "GraphData"  : {
                        labels: [["Your","Projection"], "Year 1", "Year 2", "Year 3", "Year 4", "Year 5"],
                        datasets: [
                            {
                                label: "Profit",
                                backgroundColor: "#1e65a7", //"#d6dce5",
                                data: [Number(reducingAttritionD31),Number(reducingAttritionE31),Number(reducingAttritionF31),Number(reducingAttritionG31),Number(reducingAttritionH31),Number(reducingAttritionI31)]
                            },
                            {
                                label: "Sales",
                                backgroundColor: "#8497b0",
                                data: [Number(reducingAttritionD30),Number(reducingAttritionE30),Number(reducingAttritionF30),Number(reducingAttritionG30),Number(reducingAttritionH30),Number(reducingAttritionI30)]
                            },
                            {
                                label: "Valuation",
                                backgroundColor: "#1e2b3c",
                                data: [Number(reducingAttritionD36),Number(reducingAttritionE36),Number(reducingAttritionF36),Number(reducingAttritionG36),Number(reducingAttritionH36),Number(reducingAttritionI36)]
                            }
                        ]
                    }
                }} />
                {/* <div className="">
                    <h3 className='text-center impact-lead-titile'><strong>Reducing Attrition: Impact on Financial Performance </strong></h3>
                    
                    <BarChartGraph dataInput={{
                        "Title"      : "",
                        "vTitle"     : "",
                        "hTitle"     : "",
                        "subTitle"   : "",
                        "ColorCodes" : ["#c2c7cd", "#8497b0", "#44546a"],
                        "GraphData"  : [
                            ["", "Profit", "Sales", "Valuation"],
                            ["Year 1", Number(reducingAttritionE31), Number(reducingAttritionE30), Number(reducingAttritionE36)],
                            ["Year 2", Number(reducingAttritionF31), Number(reducingAttritionF30), Number(reducingAttritionF36)],
                            ["Year 3", Number(reducingAttritionG31), Number(reducingAttritionG30), Number(reducingAttritionG36)],
                            ["Year 4", Number(reducingAttritionH31), Number(reducingAttritionH30), Number(reducingAttritionH36)],
                            ["Year 5", Number(reducingAttritionI31), Number(reducingAttritionI30), Number(reducingAttritionI36)]
                        ]
                    }} />
                </div> */}
            </div>
        </div>	
        <div className="card update-class-lead">
            <div className="card-body pl-0">
                <div className="table-responsive">
                    <table className="table table-responsive-md table-striped mb-0" style={{width: 1260}}>
                        <thead>
                            <tr>
                            <th className="table-primar" style={{width: 360}}/>
                            <th colSpan={2} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Year 1 Gains</th>
                            <th colSpan={2} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Mid-Term Gains</th>
                            <th colSpan={5} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Long-Term Gains </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="thead-primary">
                                <th className='black-bg fontBold' style={{width: 360}}>New Customer Attrition Rate</th>
                                <th className='black-bg' style={{width: 150}}>Your Projection</th>
                                <th className='black-bg' style={{width: 150}}>1% Quarterly Improvement</th>
                                <th className='black-bg' style={{width: 150}}>2 Years of 1% Improvements</th>
                                <th className='black-bg' style={{width: 150}}>3 Years of 1% Improvements</th>
                                <th className='black-bg' style={{width: 150}}>4 Years of 1% Improvements</th>
                                <th className='black-bg' style={{width: 150}}>5 Years of 1% Improvements</th>
                        </tr>
                        <tr>
                        <td className='bg-color-light-blue font12px color-black'>Improved Customer Attrition Rate</td>
                        <td className='bg-color-blue-gray text-white font12px'>{Number(reducingAttritionD23).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}%</td>
                        <td className='bg-color-light-blue font12px color-black'>{Number(reducingAttritionE23).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}%</td>
                        <td className='bg-color-light-blue font12px color-black'>{Number(reducingAttritionF23).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}%</td>
                        <td className='bg-color-light-blue font12px color-black'>{Number(reducingAttritionG23).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}%</td>
                        <td className='bg-color-light-blue font12px color-black'>{Number(reducingAttritionH23).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}%</td>
                        <td className='bg-color-light-blue font12px color-black'>{Number(reducingAttritionI23).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}%</td>
                        </tr>
                        <tr>
                        <td className='bg-color-light-blue font12px color-black'>Total Active Customers</td>
                        <td className='bg-color-blue-gray text-white font12px'>{Number(reducingAttritionD24).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</td>
                        <td className='bg-color-light-blue font12px color-black'>{Number(reducingAttritionE24).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</td>
                        <td className='bg-color-light-blue font12px color-black'>{Number(reducingAttritionF24).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</td>
                        <td className='bg-color-light-blue font12px color-black'>{Number(reducingAttritionG24).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</td>
                        <td className='bg-color-light-blue font12px color-black'>{Number(reducingAttritionH24).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</td>
                        <td className='bg-color-light-blue font12px color-black'>{Number(reducingAttritionI24).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</td>
                        </tr>
                        <tr>
                        <td className='bg-color-light-blue font12px color-black'>Customers Lost Annually</td>
                        <td className='bg-color-blue-gray text-white font12px'>{Number(reducingAttritionD25).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</td>
                        <td className='bg-color-light-blue font12px color-black'>{Number(reducingAttritionE25).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</td>
                        <td className='bg-color-light-blue font12px color-black'>{Number(reducingAttritionF25).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</td>
                        <td className='bg-color-light-blue font12px color-black'>{Number(reducingAttritionG25).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</td>
                        <td className='bg-color-light-blue font12px color-black'>{Number(reducingAttritionH25).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</td>
                        <td className='bg-color-light-blue font12px color-black'>{Number(reducingAttritionI25).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</td>
                        </tr>
                        <tr>
                        <td className='bg-color-light-blue font12px color-black'><strong>Customers Gained by Reducing Attrition</strong></td>
                        <td className='bg-color-blue-gray text-white font12px'><strong>{Number(reducingAttritionD26).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong></td>
                        <td className='bg-color-teal text-white font12px'><strong>{Number(reducingAttritionE26).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong></td>
                        <td className='bg-color-teal text-white font12px'><strong>{Number(reducingAttritionF26).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong></td>
                        <td className='bg-color-teal text-white font12px'><strong>{Number(reducingAttritionG26).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong></td>
                        <td className='bg-color-teal text-white font12px'><strong>{Number(reducingAttritionH26).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong></td>
                        <td className='bg-color-teal text-white font12px'><strong>{Number(reducingAttritionI26).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong></td>
                        </tr>
                        </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="card update-class-lead">
                <div className="card-body pl-0">
                <div className="table-responsive">
                    <table className="table table-responsive-md table-striped mb-0" style={{width: 1260}}>
                    <thead>
                        <tr>
                        <th className='nowrap' style={{background:'#fff', width: 360}}><h3>Financial Improvements</h3></th>
                        <th colSpan={2} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Year 1 Gains</th>
                        <th colSpan={2} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Mid-Term Gains</th>
                        <th colSpan={5} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Long-Term Gains </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="thead-primary">
                            <th className='black-bg fontBold' style={{width: 360}}>Annual Impact of Reducing Customer Attrition</th>
                            <th className='black-bg' style={{width: 150}}>Your Projection</th>
                            <th className='black-bg' style={{width: 150}}>1% Quarterly Improvement</th>
                            <th className='black-bg' style={{width: 150}}>2 Years of 1% Improvements</th>
                            <th className='black-bg' style={{width: 150}}>3 Years of 1% Improvements</th>
                            <th className='black-bg' style={{width: 150}}>4 Years of 1% Improvements</th>
                            <th className='black-bg' style={{width: 150}}>5 Years of 1% Improvements</th>
                        </tr>
                        <tr>
                        <td className='bg-color-light-blue font12px color-black'>Annual Sales</td>
                        <td className='bg-color-blue-gray text-white font12px'> 
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(reducingAttritionD30).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(reducingAttritionE30).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(reducingAttritionF30).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(reducingAttritionG30).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(reducingAttritionH30).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(reducingAttritionI30).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <td className='bg-color-light-blue font12px color-black'>Annual Profit</td>
                        <td className='bg-color-blue-gray text-white font12px'> 
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(reducingAttritionD31).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(reducingAttritionE31).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(reducingAttritionF31).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(reducingAttritionG31).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(reducingAttritionH31).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(reducingAttritionI31).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        </tr>
                        <tr className="thead-primary">
                            <th className='black-bg fontBold'>Lifetime Impact of Reducing Customer Attrition</th>
                            <th className='black-bg nowrap'>Your Projection</th>
                            <th className='black-bg'>1% Quarterly Improvement</th>
                            <th className='black-bg'>2 Years of 1% Improvements</th>
                            <th className='black-bg'>3 Years of 1% Improvements</th>
                            <th className='black-bg'>4 Years of 1% Improvements</th>
                            <th className='black-bg'>5 Years of 1% Improvements</th>
                        </tr>
                        <tr>
                        <td className='bg-color-light-blue font12px color-black'>Lifetime Sales</td>
                        <td className='bg-color-blue-gray text-white font12px'> 
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(reducingAttritionD33).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(reducingAttritionE33).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(reducingAttritionF33).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(reducingAttritionG33).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(reducingAttritionH33).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(reducingAttritionI33).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <td className='bg-color-light-blue font12px color-black'>Lifetime Profit</td>
                        <td className='bg-color-blue-gray text-white font12px'> 
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(reducingAttritionD34).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(reducingAttritionE34).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(reducingAttritionF34).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(reducingAttritionG34).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(reducingAttritionH34).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(reducingAttritionI34).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        </tr>
                        <tr className="thead-primary">
                            <th className='black-bg fontBold'>Valuation Impact of Reducing Customer Attrition</th>
                            <th className='black-bg nowrap'>Your Projection</th>
                            <th className='black-bg'>1% Quarterly Improvement</th>
                            <th className='black-bg'>2 Years of 1% Improvements</th>
                            <th className='black-bg'>3 Years of 1% Improvements</th>
                            <th className='black-bg'>4 Years of 1% Improvements</th>
                            <th className='black-bg'>5 Years of 1% Improvements</th>
                        </tr>
                        <tr>
                        <td className='bg-color-light-blue font12px color-black'>Estimated Valuation Impact</td>
                        <td className='bg-color-blue-gray text-white font12px'> 
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(reducingAttritionD36).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(reducingAttritionE36).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(reducingAttritionF36).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(reducingAttritionG36).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(reducingAttritionH36).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        <td className='bg-color-light-blue font12px color-black'>
                            <div className="annual-dollor-sign">
                                <div className="annual-dl font12px"> $ </div>
                                <div className="annual-dl-number font12px">
                                    {Number(reducingAttritionI36).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                </div>
                            </div>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                <div className="nextInput">
                    <ul>
                        <li>
                            <Link to="/prescribe/customer-reactivation" className='next-link'> Next: Customer Reactivation  
                                <span className='icon-three'>
                                    <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                    <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                    <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                </span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        </div>

    </div>

  )
}
