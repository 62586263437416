import axios from "axios";
import authHeader from "./auth-header";
import { trackPromise } from 'react-promise-tracker';

const getData = (user_id) => {
    return trackPromise (
        axios({
            method: "post",
            url: "growthPlanning/getQuarterlyAndMonthlyPlan",
            data: {user_id},
            headers: authHeader()
        })
    )
};
const postData = (data) => {
    return trackPromise (
        axios({
            method: "post",
            url: "growthPlanning/modifyQuarterlyAndMonthlyPlan",
            data,
            headers: authHeader()
        })
    )
};

const QuarterlyAndMonthlyPlanService = {
    getData,
    postData,
};
export default QuarterlyAndMonthlyPlanService;