import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getBrandScorecard, modifyBrandScorecard } from "../../state/features/BrandScorecardSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import sum from "../../utils/sum";

export default function BrandScorecardContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        BrandScorecardF8 : 0,
        BrandScorecardF9 : 0,
        BrandScorecardF10 : 0,
        BrandScorecardF11 : 0,
        BrandScorecardF12 : 0,
        BrandScorecardF13 : 0,
        BrandScorecardF14 : 0,
        BrandScorecardF15 : 0,
        BrandScorecardF16 : 0,
        BrandScorecardF17 : 0,
        BrandScorecardF18 : 0,
        BrandScorecardF19 : 0,
        BrandScorecardF20 : 0,
        BrandScorecardF21 : 0,
        BrandScorecardF22 : 0,
        BrandScorecardF24 : 0,
        BrandScorecardG8 : 0,
        BrandScorecardG9 : 0,
        BrandScorecardG10 : 0,
        BrandScorecardG11 : 0,
        BrandScorecardG12 : 0,
        BrandScorecardG13 : 0,
        BrandScorecardG14 : 0,
        BrandScorecardG15 : 0,
        BrandScorecardG16 : 0,
        BrandScorecardG17 : 0,
        BrandScorecardG18 : 0,
        BrandScorecardG19 : 0,
        BrandScorecardG20 : 0,
        BrandScorecardG21 : 0,
        BrandScorecardG22 : 0,
        OverallBrandScorecard_8 : 0,
        OverallBrandScorecard_9 : 0,
        OverallBrandScorecard_10 : 0,
        OverallBrandScorecard_11 : 0,
        OverallBrandScorecard_12 : 0,
        OverallBrandScorecard_13 : 0,
        OverallBrandScorecard_14 : 0,
        OverallBrandScorecard_15 : 0,
        OverallBrandScorecard_16 : 0,
        OverallBrandScorecard_17 : 0,
        OverallBrandScorecard_18 : 0,
        OverallBrandScorecard_19 : 0,
        OverallBrandScorecard_20 : 0,
        OverallBrandScorecard_21 : 0,
        OverallBrandScorecard_22 : 0,
        BrandScorecardI24 : 0,
        BrandScorecardG24 : 0
    });
    
    const [Cache, setCache] = useState(Inputs);
    const [targetValue, setTargetValue] = useState(false);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getBrandScorecard({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.growthPlanning)
            if(response!= undefined  && response.data.growthPlanning){
                let growthPlanning = response.data.growthPlanning[0];
                console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key];
                    }
                }
                newObj['OverallBrandScorecard_8'] = (sum(growthPlanning['BrandScorecardF8'], growthPlanning['BrandScorecardF9']) / 2).toFixed(1);
                newObj['OverallBrandScorecard_10'] = (sum(growthPlanning['BrandScorecardF10'], growthPlanning['BrandScorecardF11'], growthPlanning['BrandScorecardF12']) / 3).toFixed(1);
                newObj['OverallBrandScorecard_13'] = (sum(growthPlanning['BrandScorecardF13'], growthPlanning['BrandScorecardF14'], growthPlanning['BrandScorecardF15'], growthPlanning['BrandScorecardF16'], growthPlanning['BrandScorecardF17']) / 5).toFixed(1);
                newObj['OverallBrandScorecard_18'] = (sum(growthPlanning['BrandScorecardF18'], growthPlanning['BrandScorecardF19']) / 2).toFixed(1);
                newObj['OverallBrandScorecard_20'] = (sum(growthPlanning['BrandScorecardF20'], growthPlanning['BrandScorecardF21'], growthPlanning['BrandScorecardF22']) / 3).toFixed(1);
                newObj['BrandScorecardG24'] = (sum(newObj['OverallBrandScorecard_8'], newObj['OverallBrandScorecard_10'], newObj['OverallBrandScorecard_13'], newObj['OverallBrandScorecard_18'], newObj['OverallBrandScorecard_20']) / 5).toFixed(1);
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    useEffect(() => {
        if(targetValue){
            Inputs['OverallBrandScorecard_8'] = (sum(Inputs['BrandScorecardF8'], Inputs['BrandScorecardF9']) / 2).toFixed(1);
            Inputs['OverallBrandScorecard_10'] = (sum(Inputs['BrandScorecardF10'], Inputs['BrandScorecardF11'], Inputs['BrandScorecardF12']) / 3).toFixed(1);
            Inputs['OverallBrandScorecard_13'] = (sum(Inputs['BrandScorecardF13'], Inputs['BrandScorecardF14'], Inputs['BrandScorecardF15'], Inputs['BrandScorecardF16'], Inputs['BrandScorecardF17']) / 5).toFixed(1);
            Inputs['OverallBrandScorecard_18'] = (sum(Inputs['BrandScorecardF18'], Inputs['BrandScorecardF19']) / 2).toFixed(1);
            Inputs['OverallBrandScorecard_20'] = (sum(Inputs['BrandScorecardF20'], Inputs['BrandScorecardF21'], Inputs['BrandScorecardF22']) / 3).toFixed(1);
            Inputs['BrandScorecardG24'] = (sum(Inputs['OverallBrandScorecard_8'], Inputs['OverallBrandScorecard_10'], Inputs['OverallBrandScorecard_13'], Inputs['OverallBrandScorecard_18'], Inputs['OverallBrandScorecard_20']) / 5).toFixed(1);
        }
    },[targetValue])

    const onBlurHandler = async (event) => {
        setTargetValue(false)
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value && !isNaN(event.target.value)){
                setInputs({ ...Inputs, [event.target.id] : parseFloat(event.target.value)})
                dispatch(modifyBrandScorecard({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setTargetValue(true)
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

  return (
   <div className="content-body">
    <UserDropdown />
    
    {/* row */}
    <div className="container-fluid">
        <div className="head-title">
            <h2>Brand Scorecard</h2>	            					
        </div>
        <Spinner />
        <div className="card">
            <div className="card-body pl-0 barndpage">
            <table className="table">
                <thead>
                <tr className="thead-primary">
                    <th>Brand Attribute</th>
                    <th>Question</th>
                    <th>Score <br />(1=Low;5=high)</th>
                    <th>Average</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="bgradiusf" rowSpan={2} style={{fontSize: 20}}>Customers</td>
                    <td>How well do you maximize your customer's experience?</td>
                    <td><input type="text" className="form-control"
                            id="BrandScorecardF8"
                            value={Inputs.BrandScorecardF8}
                            onChange={(event) => setInputs({ ...Inputs, BrandScorecardF8: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                            placeholder="0"
                            maxLength="1"
                            onKeyPress={(event) => {
                                if (!/[1-5]/.test(event.key)) {
                                    event.preventDefault();
                                    alert.show('Please Enter Score range 1 to 5.')
                                }
                                }} /></td>
                    <td rowSpan={2}>{Inputs.OverallBrandScorecard_8 != 'NaN' ? Inputs.OverallBrandScorecard_8 : 0}</td>
                </tr>
                <tr>                                   
                    <td>To what extent do your customers and market trust your brand?</td>
                    <td><input type="text" className="form-control" 
                            id="BrandScorecardF9"
                            value={Inputs.BrandScorecardF9}
                            onChange={(event) => setInputs({ ...Inputs, BrandScorecardF9: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                            placeholder="0"
                            maxLength="1"
                            onKeyPress={(event) => {
                                if (!/[1-5]/.test(event.key)) {
                                    event.preventDefault();
                                    alert.show('Please Enter Score range 1 to 5.')
                                }
                                }} /></td>
                </tr>
                <tr>
                    <td className="bgradiusf" rowSpan={3} style={{fontSize: 20}}>Value</td>
                    <td>How well do you deliver the benefits your customers truly want?</td>
                    <td><input type="text" className="form-control"
                        id="BrandScorecardF10"
                        value={Inputs.BrandScorecardF10}
                        onChange={(event) => setInputs({ ...Inputs, BrandScorecardF10: event.target.value }) } 
                        onBlur={(event) => { onBlurHandler(event) }} 
                        disabled={isLoading} 
                        placeholder="0" 
                        maxLength="1"
                        onKeyPress={(event) => {
                            if (!/[1-5]/.test(event.key)) {
                                event.preventDefault();
                                alert.show('Please Enter Score range 1 to 5.')
                            }
                        }} /></td>
                    <td rowSpan={3}>{Inputs.OverallBrandScorecard_10 != 'NaN' ? Inputs.OverallBrandScorecard_10 : 0}</td>
                </tr> 
                <tr>
                    <td>How effectively do you communicate with your market that you deliver real and desired value?</td>
                    <td><input type="text" className="form-control"
                        id="BrandScorecardF11"
                        value={Inputs.BrandScorecardF11}
                        onChange={(event) => setInputs({ ...Inputs, BrandScorecardF11: event.target.value }) } 
                        onBlur={(event) => { onBlurHandler(event) }} 
                        disabled={isLoading} 
                        placeholder="0" 
                        maxLength="1"
                        onKeyPress={(event) => {
                            if (!/[1-5]/.test(event.key)) {
                                event.preventDefault();
                                alert.show('Please Enter Score range 1 to 5.')
                            }
                        }} /></td>
                </tr> 
                <tr>                                    
                    <td>To what extent can you find pricing power in the company's core brand?</td>
                    <td><input type="text" className="form-control"
                        id="BrandScorecardF12"
                        value={Inputs.BrandScorecardF12}
                        onChange={(event) => setInputs({ ...Inputs, BrandScorecardF12: event.target.value }) } 
                        onBlur={(event) => { onBlurHandler(event) }} 
                        disabled={isLoading} 
                        placeholder="0" 
                        maxLength="1"
                        onKeyPress={(event) => {
                            if (!/[1-5]/.test(event.key)) {
                                event.preventDefault();
                                alert.show('Please Enter Score range 1 to 5.')
                            }
                            }} /></td>
                </tr>   
                <tr>
                    <td className="bgradiusf" rowSpan={5} style={{fontSize: 20}}>Positioning</td>
                    <td>How unique is your brand? How effectively are you differentiated from your competitors?</td>
                    <td><input type="text" className="form-control"
                        id="BrandScorecardF13"
                        value={Inputs.BrandScorecardF13}
                        onChange={(event) => setInputs({ ...Inputs, BrandScorecardF13: event.target.value }) } 
                        onBlur={(event) => { onBlurHandler(event) }} 
                        disabled={isLoading} 
                        placeholder="0" 
                        maxLength="1"
                        onKeyPress={(event) => {
                            if (!/[1-5]/.test(event.key)) {
                                event.preventDefault();
                                alert.show('Please Enter Score range 1 to 5.')
                            }
                            }} /></td>
                    <td rowSpan={5}>{Inputs.OverallBrandScorecard_13 != 'NaN' ? Inputs.OverallBrandScorecard_13 : 0}</td>
                </tr> 
                <tr>
                    <td>How consistent is your brand messaging? How confident are you that you're not sending conflicting messages to your market?</td>
                    <td><input type="text" className="form-control"
                        id="BrandScorecardF14"
                        value={Inputs.BrandScorecardF14}
                        onChange={(event) => setInputs({ ...Inputs, BrandScorecardF14: event.target.value }) } 
                        onBlur={(event) => { onBlurHandler(event) }} 
                        disabled={isLoading} 
                        placeholder="0" 
                        maxLength="1"
                        onKeyPress={(event) => {
                            if (!/[1-5]/.test(event.key)) {
                                event.preventDefault();
                                alert.show('Please Enter Score range 1 to 5.')
                            }
                            }} /></td>
                </tr> 
                <tr>                                    
                    <td>How memorable is your brand?</td>
                    <td><input type="text" className="form-control"
                        id="BrandScorecardF15"
                        value={Inputs.BrandScorecardF15}
                        onChange={(event) => setInputs({ ...Inputs, BrandScorecardF15: event.target.value }) } 
                        onBlur={(event) => { onBlurHandler(event) }} 
                        disabled={isLoading} 
                        placeholder="0" 
                        maxLength="1"
                        onKeyPress={(event) => {
                            if (!/[1-5]/.test(event.key)) {
                                event.preventDefault();
                                alert.show('Please Enter Score range 1 to 5.')
                            }
                            }} /></td>
                </tr>   
                <tr>
                    <td>How clear is the Value Proposition of your core brand?</td>
                    <td><input type="text" className="form-control"
                        id="BrandScorecardF16"
                        value={Inputs.BrandScorecardF16}
                        onChange={(event) => setInputs({ ...Inputs, BrandScorecardF16: event.target.value }) } 
                        onBlur={(event) => { onBlurHandler(event) }} 
                        disabled={isLoading} 
                        placeholder="0" 
                        maxLength="1"
                        onKeyPress={(event) => {
                            if (!/[1-5]/.test(event.key)) {
                                event.preventDefault();
                                alert.show('Please Enter Score range 1 to 5.')
                            }
                            }} /></td>
                </tr> 
                <tr>                                    
                    <td>How well have you chosen or designed your brand name, logo, symbol, slogan, packaging, signage, and so forth, to maximize brand awareness?</td>
                    <td><input type="text" className="form-control"
                        id="BrandScorecardF17"
                        value={Inputs.BrandScorecardF17}
                        onChange={(event) => setInputs({ ...Inputs, BrandScorecardF17: event.target.value }) } 
                        onBlur={(event) => { onBlurHandler(event) }} 
                        disabled={isLoading} 
                        placeholder="0" 
                        maxLength="1"
                        onKeyPress={(event) => {
                            if (!/[1-5]/.test(event.key)) {
                                event.preventDefault();
                                alert.show('Please Enter Score range 1 to 5.')
                            }
                            }} /></td>
                </tr>   
                <tr>
                    <td className="bgradiusf" rowSpan={2} style={{fontSize: 20}}>Impact</td>
                    <td>Can you give examples of branding initiatives that were successful and unsuccessful in boosting sales?</td>
                    <td><input type="text" className="form-control"
                        id="BrandScorecardF18"
                        value={Inputs.BrandScorecardF18}
                        onChange={(event) => setInputs({ ...Inputs, BrandScorecardF18: event.target.value }) } 
                        onBlur={(event) => { onBlurHandler(event) }} 
                        disabled={isLoading} 
                        placeholder="0" 
                        maxLength="1"
                        onKeyPress={(event) => {
                            if (!/[1-5]/.test(event.key)) {
                                event.preventDefault();
                                alert.show('Please Enter Score range 1 to 5.')
                            }
                            }} /></td>
                    <td rowSpan={2}>{Inputs.OverallBrandScorecard_18 !='NaN' ? Inputs.OverallBrandScorecard_18 : 0}</td>
                </tr>
                <tr>                                   
                    <td>Do you have multiple customer stories, case studies, and testimonials that exemplify the unique benefits and value you deliver?</td>
                    <td><input type="text" className="form-control"
                        id="BrandScorecardF19"
                        value={Inputs.BrandScorecardF19}
                        onChange={(event) => setInputs({ ...Inputs, BrandScorecardF19: event.target.value }) } 
                        onBlur={(event) => { onBlurHandler(event) }} 
                        disabled={isLoading} 
                        placeholder="0" 
                        maxLength="1"
                        onKeyPress={(event) => {
                            if (!/[1-5]/.test(event.key)) {
                                event.preventDefault();
                                alert.show('Please Enter Score range 1 to 5.')
                            }
                            }} /></td>
                </tr>
                <tr>
                    <td className="bgradiusf" rowSpan={3} style={{fontSize: 20}}>Support</td>
                    <td>To what extent does your marketing team, executive leadership, and the board, have real branding and marketing expertise?</td>
                    <td><input type="text" className="form-control"
                        id="BrandScorecardF20"
                        value={Inputs.BrandScorecardF20}
                        onChange={(event) => setInputs({ ...Inputs, BrandScorecardF20: event.target.value }) } 
                        onBlur={(event) => { onBlurHandler(event) }} 
                        disabled={isLoading} 
                        placeholder="0" 
                        maxLength="1"
                        onKeyPress={(event) => {
                            if (!/[1-5]/.test(event.key)) {
                                event.preventDefault();
                                alert.show('Please Enter Score range 1 to 5.')
                            }
                            }} /></td>
                    <td rowSpan={3}>{Inputs.OverallBrandScorecard_20 != 'NaN' ? Inputs.OverallBrandScorecard_20 : 0}</td>
                </tr> 
                <tr>
                    <td>Does the company has a systematic way to learn and improve, allowing it to achieve higher levels of brand and marketing excellence?</td>
                    <td><input type="text" className="form-control"
                        id="BrandScorecardF21"
                        value={Inputs.BrandScorecardF21}
                        onChange={(event) => setInputs({ ...Inputs, BrandScorecardF21: event.target.value }) } 
                        onBlur={(event) => { onBlurHandler(event) }} 
                        disabled={isLoading} 
                        placeholder="0" 
                        maxLength="1"
                        onKeyPress={(event) => {
                            if (!/[1-5]/.test(event.key)) {
                                event.preventDefault();
                                alert.show('Please Enter Score range 1 to 5.')
                            }
                            }} /></td>
                </tr> 
                <tr>                                    
                    <td>Do you have data to construct benchmarks for your branding activities?</td>
                    <td><input type="text" className="form-control"
                        id="BrandScorecardF22"
                        value={Inputs.BrandScorecardF22}
                        onChange={(event) => setInputs({ ...Inputs, BrandScorecardF22: event.target.value }) } 
                        onBlur={(event) => { onBlurHandler(event) }} 
                        disabled={isLoading} 
                        placeholder="0" 
                        maxLength="1"
                        onKeyPress={(event) => {
                            if (!/[1-5]/.test(event.key)) {
                                event.preventDefault();
                                alert.show('Please Enter Score range 1 to 5.')
                            }
                            }} /></td>
                </tr>                       
                </tbody>
                <tfoot>
                <tr>
                    <td colSpan={1} />
                    <td colSpan={1} style={{textAlign: 'center'}} className="bluetab">Total Score</td>
                    <td colSpan={1} className="bgradiusf"/>
                    <td style={{textAlign: 'center'}} className="bgradiusf">{Inputs.BrandScorecardG24 != 'NaN' ? Inputs.BrandScorecardG24 : 0}</td>
                </tr>
                </tfoot>
            </table>
            </div>
        </div>
        </div>

    </div>

  )
}
