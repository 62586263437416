import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state/index';
import  UserDropdown  from '../UserDropdown';

import { getImpact120GrowthROI, modifyImpact120GrowthROI } from "../../state/features/ClientGrowthSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import formatPrice from '../../utils/formatPrice';
import { MultipleLineChartGraphNew } from '../graph/MultipleLineChartGraphNew';
import { Link } from 'react-router-dom';

export default function Impact120GrowthROIContentNew() {

    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;

    const clientInputs = useSelector(state => state.clientInputs)
    const impactof120GrowthOS = useSelector(state => state.impactof120GrowthOS)
    const impact120GrowthROI = useSelector(state => state.impact120GrowthROI)
    const dispatch = useDispatch();
    const { impact120GrowthROIData } = bindActionCreators(actionCreators, dispatch)
 
    const [Inputs, setInputs] = useState({
        impact120GrowthROID13: 0,
        impact120GrowthROID21: 0
    });

    const {clientInputsH41} = clientInputs

    useEffect(() => {
        impact120GrowthROIData("impactof120GrowthROIAll", {
            clientInputsH41,
            impactof120GrowthOS,
            impact120GrowthROID13 : Inputs.impact120GrowthROID13,
            impact120GrowthROID21 : Inputs.impact120GrowthROID21
        });
    }, [Inputs.impact120GrowthROID13, Inputs.impact120GrowthROID21]);

    const [Cache, setCache] = useState(Inputs);

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getImpact120GrowthROI({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.clientGrowth)
            if(response!= undefined && response.data.clientGrowth[0]){
                let clientGrowth = response.data.clientGrowth[0];
                // console.log(clientGrowth)
                delete clientGrowth.id;
                delete clientGrowth.user_id;
                delete clientGrowth.created_at;
                delete clientGrowth.updated_at;
                const newObj = { ...Inputs }
                for (const key in clientGrowth) {
                    if (clientGrowth.hasOwnProperty(key)) {
                        if(clientGrowth[key])
                        newObj[key] = clientGrowth[key]
                    }
                }
                // console.log('newObj',newObj)
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
        //   console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

    }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value.replace(/,/g, '')){
                dispatch(modifyImpact120GrowthROI({ user_id, [event.target.id] : event.target.value.replace(/,/g, '')}))
                .unwrap()
                .then((response) => {
                    // console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        // console.log('session expired...')
                        //window.location.reload()
                    }
                    else if(response!= undefined){
                        // console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value.replace(/,/g, '')});
                    }
                })
                .catch((error) => {
                    // console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

    const { impact120GrowthROIE21, impact120GrowthROIF21, impact120GrowthROIG21, impact120GrowthROIH21, impact120GrowthROII21, impact120GrowthROIJ21, impact120GrowthROIK21, impact120GrowthROIL21, impact120GrowthROIM21, impact120GrowthROIN21, impact120GrowthROIO21, impact120GrowthROIP21, impact120GrowthROIF22, impact120GrowthROIG22, impact120GrowthROIH22, impact120GrowthROII22, impact120GrowthROIJ22, impact120GrowthROIK22, impact120GrowthROIL22, impact120GrowthROIM22, impact120GrowthROIN22, impact120GrowthROIO22, impact120GrowthROIP22, impact120GrowthROIF23, impact120GrowthROIG23, impact120GrowthROIH23, impact120GrowthROII23, impact120GrowthROIJ23, impact120GrowthROIK23, impact120GrowthROIL23, impact120GrowthROIM23, impact120GrowthROIN23, impact120GrowthROIO23, impact120GrowthROIP23, impact120GrowthROIF24, impact120GrowthROIG24, impact120GrowthROIH24, impact120GrowthROII24, impact120GrowthROIJ24, impact120GrowthROIK24, impact120GrowthROIL24, impact120GrowthROIM24, impact120GrowthROIN24,  impact120GrowthROIO24, impact120GrowthROIP24, impact120GrowthROID16, impact120GrowthROIE16, impact120GrowthROIF16, impact120GrowthROID17, impact120GrowthROIE17, impact120GrowthROIF17} = impact120GrowthROI

    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
            {/* row */}
            <div className="container-fluid">
                <div className="head-title mb-5">
                    <h3 className='preheading'>Your Growth Potential</h3>
                    <h1 className='mainheading mb-1'>Return On Investment</h1>
                </div>
                <div className="opportunity-page-table">
                    <div className="opportunity-page-table-inner opportunity-abl">
                        <div className='row mb-5' style={{maxWidth: 2150}}>
                            <div className='col-12 col-lg-5 mb-3'>
                                {/* <p className='body-text color-light mb-4'>The Revenue Growth Catalyst™ can quickly deliver dramatic ROI for your business.  If you would like to continue working with Revenue Growth OS to start the implementation process, or even continuously as you grow your business, the return is always far more profitable than the expense.
                                <br/><br/>
                                And remember, this is based on just a few, consistent 1% improvements.  Many companies grow much faster.
                                </p>  */}
                                <p className='body-text color-light mb-4'>
                                The RevGrowth OS and Growth Catalyst can quickly deliver dramatic ROI for your business, and the return is always far greater than the expense.<br/><br/>And keep in mind, these results are based based on just a few, consistent 1% improvements.  
                                </p>
                                <div className="table-responsive">
                                    <table className="table" style={{width: 600, marginBottom: 0}}>
                                        <tbody>
                                            <tr>
                                                <td className='black-bg text-white text-center font12px fontBold' style={{width: 150}}>Monthly Fee</td>
                                                <td className='bg-color-01 padding-0' style={{width: 150}}>
                                                    <div className="dollor-sin-pr">
                                                        <div className="dollor-sin fontBold">$</div>
                                                        <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                            <input type="text" className="field-ipt font12px fontBold bg-color-01 text-right text-black" 
                                                            id="impact120GrowthROID13" 
                                                            value={Inputs.impact120GrowthROID13.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                            onChange={(event) => { setInputs({ ...Inputs, impact120GrowthROID13: event.target.value.replace(/,/g, '') });}}
                                                            onKeyPress={(event) => {
                                                              if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                                event.preventDefault();
                                                              }
                                                              else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                                event.preventDefault();
                                                                alert.show('Please enter a valid number.')
                                                              }
                                                            }} 
                                                            onBlur={(event) => { onBlurHandler(event) }} 
                                                            disabled={isLoading} 
                                                            placeholder="0"
                                                            />
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='hide-content' style={{width: 150}}></td>
                                                <td className='hide-content' style={{width: 150}}></td>
                                            </tr>
                                            <tr>
                                                <td colSpan={4} className='hide-content' style={{height: 25}}></td>
                                            </tr>
                                            <tr>
                                                <td className='hide-content'></td>
                                                <td className='black-bg text-white text-center font12px fontBold'>Year 1 ROI</td>
                                                <td className='black-bg text-white text-center font12px fontBold'>Year 2 ROI</td>
                                                <td className='black-bg text-white text-center font12px fontBold'>Year 3 ROI</td>
                                            </tr>
                                            <tr>
                                                <td className='black-bg text-white ms-text-left font12px fontBold' style={{width: 200}}>Revenue</td>
                                                <td className='bg-color-blue text-white ms-text-right font12px fontBold'>
                                                    <div className="scrore-col">{formatPrice(impact120GrowthROID16, 0)}%</div>
                                                </td>
                                                <td className='bg-color-blue text-white ms-text-right font12px fontBold'>
                                                    <div className="scrore-col">{formatPrice(impact120GrowthROIE16, 0)}%</div>
                                                </td>
                                                <td className='bg-color-blue text-white ms-text-right font12px fontBold'>
                                                    <div className="scrore-col">{formatPrice(impact120GrowthROIF16, 0)}%</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='black-bg text-white ms-text-left font12px fontBold' style={{width: 200}}>Profit</td>
                                                <td className='bg-color-teal text-white ms-text-right font12px fontBold'>
                                                    <div className="scrore-col">{formatPrice(impact120GrowthROID17, 0)}%</div>
                                                </td>
                                                <td className='bg-color-teal text-white ms-text-right font12px fontBold'>
                                                    <div className="scrore-col">{formatPrice(impact120GrowthROIE17, 0)}%</div>
                                                </td>
                                                <td className='bg-color-teal text-white ms-text-right font12px fontBold'>
                                                    <div className="scrore-col">{formatPrice(impact120GrowthROIF17, 0)}%</div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='col-12 col-lg-7 mb-3'>
                                <div className="black-bg" style={{borderRadius: 15, padding: 15, minHeight: 300, height: '100%'}}>
                                    <MultipleLineChartGraphNew dataInput={{
                                    "Title"      : "Financial Impact vs Investment (quarterly)",
                                    "vTitle"     : "",
                                    "hTitle"     : "",
                                    "subTitle"   :  "",
                                    "ColorCodes" : ["#efbc44", "#447fef", "#0e8262", "#aa28d9"],
                                    "GraphData"  : {
                                        labels: ["", "A", "Q1", "Q2", "Q3", "Q4", "Q5", "Q66", "Q7", "Q8", "Q9", "Q10", "Q11", "Q12"],
                                        datasets: [
                                            {
                                                label: "Valuation Impact",
                                                borderColor: "#efbc44",
                                                borderWidth: 4,
                                                data: [, 0, 0, impact120GrowthROIF24, impact120GrowthROIG24, impact120GrowthROIH24, impact120GrowthROII24, impact120GrowthROIJ24, impact120GrowthROIK24, impact120GrowthROIL24, impact120GrowthROIM24, impact120GrowthROIN24, impact120GrowthROIO24, impact120GrowthROIP24],
                                                pointRadius: 0,
                                                tension: 0.5,
                                                fill: false
                                            },
                                            {
                                                label: "Sales Growth",
                                                borderColor: "#447fef",
                                                borderWidth: 4,
                                                data: [, 0, 0, impact120GrowthROIF22, impact120GrowthROIG22, impact120GrowthROIH22, impact120GrowthROII22, impact120GrowthROIJ22, impact120GrowthROIK22, impact120GrowthROIL22, impact120GrowthROIM22, impact120GrowthROIN22, impact120GrowthROIO22, impact120GrowthROIP22],
                                                pointRadius: 0,
                                                tension: 0.5,
                                                fill: false
                                            },
                                            {
                                                label: "Profit Growth",
                                                borderColor: "#18b289",
                                                borderWidth: 4,
                                                data: [, 0, 0, impact120GrowthROIF23, impact120GrowthROIG23, impact120GrowthROIH23, impact120GrowthROII23, impact120GrowthROIJ23, impact120GrowthROIK23, impact120GrowthROIL23, impact120GrowthROIM23, impact120GrowthROIN23, impact120GrowthROIO23, impact120GrowthROIP23],
                                                pointRadius: 0,
                                                tension: 0.5,
                                                fill: false
                                            },
                                            {
                                                label: "Investment",
                                                borderColor: "#9933ff",
                                                borderWidth: 4,
                                                data: [, Inputs.impact120GrowthROID21, impact120GrowthROIE21, impact120GrowthROIF21, impact120GrowthROIG21, impact120GrowthROIH21, impact120GrowthROII21, impact120GrowthROIJ21, impact120GrowthROIK21, impact120GrowthROIL21, impact120GrowthROIM21, impact120GrowthROIN21, impact120GrowthROIO21, impact120GrowthROIP21],
                                                pointRadius: 0,
                                                tension: 0.5,
                                                fill: false
                                            }
                                        ]
                                    }
                                    }}/>
                                </div>
                            </div>
                        </div>
                        <Spinner />
                        <div className="table-responsive mb-5">
                            <table className="table" style={{width: 1580}}>
                                <thead>
                                    <tr>
                                        <td className='hide-content'></td>
                                        <td className='bg-color-blue-gray text-white text-center font11px fontBold'>Activation<br/>Fee</td>
                                        <td colSpan={12} className='bg-color-blue-gray text-white text-center font11px fontBold'>Growth Assurance</td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white ms-text-left font12px' style={{width: 150}}>Period</td>
                                        <td className='black-bg text-white text-center font12px' style={{width: 110}}>A</td>
                                        <td className='black-bg text-white text-center font12px' style={{width: 110}}>Q1</td>
                                        <td className='black-bg text-white text-center font12px' style={{width: 110}}>Q2</td>
                                        <td className='black-bg text-white text-center font12px' style={{width: 110}}>Q3</td>
                                        <td className='black-bg text-white text-center font12px' style={{width: 110}}>Q4</td>
                                        <td className='black-bg text-white text-center font12px' style={{width: 110}}>Q5</td>
                                        <td className='black-bg text-white text-center font12px' style={{width: 110}}>Q6</td>
                                        <td className='black-bg text-white text-center font12px' style={{width: 110}}>Q7</td>
                                        <td className='black-bg text-white text-center font12px' style={{width: 110}}>Q8</td>
                                        <td className='black-bg text-white text-center font12px' style={{width: 110}}>Q9</td>
                                        <td className='black-bg text-white text-center font12px' style={{width: 110}}>Q10</td>
                                        <td className='black-bg text-white text-center font12px' style={{width: 110}}>Q11</td>
                                        <td className='black-bg text-white text-center font12px' style={{width: 110}}>Q12</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='bg-color-blue-gray text-white ms-text-left font11px'>Investment</td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt font10px bg-color-01 text-right color-dark" 
                                                    id="impact120GrowthROID21"
                                                    value={Inputs.impact120GrowthROID21.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                    onChange={(event) => { setInputs({ ...Inputs, impact120GrowthROID21: event.target.value.replace(/,/g, '') });}}
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    onKeyPress={(event) => {
                                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                            event.preventDefault();
                                                        }else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+'  && isNaN(event.target.value.replace(/,/g, ''))){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-blue-gray padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-blue-gray client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt font10px bg-color-blue-gray text-right color-dark" 
                                                    id="impact120GrowthROIE21"
                                                    value={formatPrice(impact120GrowthROIE21, 2)}
                                                    disabled={true}  />
                                                </div>  
                                            </div>
                                        </td>
                                        <td className='bg-color-blue-gray padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin color-dark font10px">$</div>
                                                <div className="input-fiel-cn bg-color-blue-gray client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt font10px bg-color-blue-gray text-right color-dark" 
                                                    id="impact120GrowthROIF21"
                                                    value={formatPrice(impact120GrowthROIF21, 2)}
                                                    disabled={true}  />
                                                </div> 
                                            </div>
                                        </td>
                                        <td className='bg-color-blue-gray padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-blue-gray client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-blue-gray text-right font10px color-dark" 
                                                    id="impact120GrowthROIG21"
                                                    value={formatPrice(impact120GrowthROIG21, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-blue-gray padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-blue-gray client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-blue-gray text-right font10px color-dark" 
                                                    id="impact120GrowthROIH21"
                                                    value={formatPrice(impact120GrowthROIH21, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-blue-gray padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-blue-gray client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-blue-gray text-right font10px color-dark" 
                                                    id="impact120GrowthROII21"
                                                    value={formatPrice(impact120GrowthROII21, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-blue-gray padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-blue-gray client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-blue-gray text-right font10px color-dark" 
                                                    id="impact120GrowthROIJ21"
                                                    value={formatPrice(impact120GrowthROIJ21, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-blue-gray padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-blue-gray client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-blue-gray text-right font10px color-dark" 
                                                    id="impact120GrowthROIK21"
                                                    value={formatPrice(impact120GrowthROIK21, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-blue-gray padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-blue-gray client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-blue-gray text-right font10px color-dark" 
                                                    id="impact120GrowthROIL21"
                                                    value={formatPrice(impact120GrowthROIL21, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-blue-gray padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-blue-gray client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-blue-gray text-right font10px color-dark" 
                                                    id="impact120GrowthROIM21"
                                                    value={formatPrice(impact120GrowthROIM21, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-blue-gray padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-blue-gray client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-blue-gray text-right font10px color-dark" 
                                                    id="impact120GrowthROIN21"
                                                    value={formatPrice(impact120GrowthROIN21, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-blue-gray padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-blue-gray client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-blue-gray text-right font10px color-dark" 
                                                    id="impact120GrowthROIO21"
                                                    value={formatPrice(impact120GrowthROIO21, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-blue-gray padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-blue-gray client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-blue-gray text-right font10px color-dark" 
                                                    id="impact120GrowthROIP21"
                                                    value={formatPrice(impact120GrowthROIP21, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-blue-gray text-white ms-text-left font11px'>Revenue Growth</td>
                                        <td className="bg-color1 text-center-c">
                                            <div className="dolor-icon font10px color-dark">
                                                <div> $ </div>
                                                <div> - </div>
                                            </div>
                                        </td>
                                        <td className="bg-color1 text-center-c">
                                            <div className="dolor-icon font10px color-dark">
                                                <div> $ </div>
                                                <div> - </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-light-blue client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-light-blue text-right font10px color-dark" 
                                                    id="impact120GrowthROIF22"
                                                    value={formatPrice(impact120GrowthROIF22, 2)}
                                                    disabled={true}  />
                                                </div> 
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-light-blue client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-light-blue text-right font10px color-dark" 
                                                    id="impact120GrowthROIG22"
                                                    value={formatPrice(impact120GrowthROIG22, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-light-blue client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-light-blue text-right font10px color-dark" 
                                                    id="impact120GrowthROIH22"
                                                    value={formatPrice(impact120GrowthROIH22, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-light-blue client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-light-blue text-right font10px color-dark" 
                                                    id="impact120GrowthROII22"
                                                    value={formatPrice(impact120GrowthROII22, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-light-blue client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-light-blue text-right font10px color-dark" 
                                                    id="impact120GrowthROIJ22"
                                                    value={formatPrice(impact120GrowthROIJ22, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-light-blue client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-light-blue text-right font10px color-dark" 
                                                    id="impact120GrowthROIK22"
                                                    value={formatPrice(impact120GrowthROIK22, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-light-blue client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-light-blue text-right font10px color-dark" 
                                                    id="impact120GrowthROIL22"
                                                    value={formatPrice(impact120GrowthROIL22, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-light-blue client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-light-blue text-right font10px color-dark" 
                                                    id="impact120GrowthROIM22"
                                                    value={formatPrice(impact120GrowthROIM22, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-light-blue client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-light-blue text-right font10px color-dark" 
                                                    id="impact120GrowthROIN22"
                                                    value={formatPrice(impact120GrowthROIN22, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-light-blue client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-light-blue text-right font10px color-dark" 
                                                    id="impact120GrowthROIO22"
                                                    value={formatPrice(impact120GrowthROIO22, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-light-blue client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-light-blue text-right font10px color-dark" 
                                                    id="impact120GrowthROIP22"
                                                    value={formatPrice(impact120GrowthROIP22, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-blue-gray text-white ms-text-left font11px'>Profit Growth</td>
                                        <td className="bg-color1 text-center-c">
                                            <div className="dolor-icon">
                                                <div> $ </div>
                                                <div> - </div>
                                            </div>
                                        </td>
                                        <td className="bg-color1 text-center-c">
                                            <div className="dolor-icon">
                                                <div> $ </div>
                                                <div> - </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-light-blue client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-light-blue text-right font10px color-dark" 
                                                    id="impact120GrowthROIF23"
                                                    value={formatPrice(impact120GrowthROIF23, 2)}
                                                    disabled={true}  />
                                                </div> 
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-light-blue client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-light-blue text-right font10px color-dark" 
                                                    id="impact120GrowthROIG23"
                                                    value={formatPrice(impact120GrowthROIG23, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-light-blue client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-light-blue text-right font10px color-dark" 
                                                    id="impact120GrowthROIH23"
                                                    value={formatPrice(impact120GrowthROIH23, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-light-blue client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-light-blue text-right font10px color-dark" 
                                                    id="impact120GrowthROII23"
                                                    value={formatPrice(impact120GrowthROII23, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-light-blue client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-light-blue text-right font10px color-dark" 
                                                    id="impact120GrowthROIJ23"
                                                    value={formatPrice(impact120GrowthROIJ23, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-light-blue client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-light-blue text-right font10px color-dark" 
                                                    id="impact120GrowthROIK23"
                                                    value={formatPrice(impact120GrowthROIK23, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-light-blue client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-light-blue text-right font10px color-dark" 
                                                    id="impact120GrowthROIL23"
                                                    value={formatPrice(impact120GrowthROIL23, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-light-blue client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-light-blue text-right font10px color-dark" 
                                                    id="impact120GrowthROIM23"
                                                    value={formatPrice(impact120GrowthROIM23, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-light-blue client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-light-blue text-right font10px color-dark" 
                                                    id="impact120GrowthROIN23"
                                                    value={formatPrice(impact120GrowthROIN23, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-light-blue client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-light-blue text-right font10px color-dark" 
                                                    id="impact120GrowthROIO23"
                                                    value={formatPrice(impact120GrowthROIO23, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-light-blue client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-light-blue text-right font10px color-dark" 
                                                    id="impact120GrowthROIP23"
                                                    value={formatPrice(impact120GrowthROIP23, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-blue-gray text-white ms-text-left font11px'>Valuation Impact</td>
                                        <td className="bg-color1 text-center-c">
                                            <div className="dolor-icon font10px color-dark">
                                                <div> $ </div>
                                                <div> - </div>
                                            </div>
                                        </td>
                                        <td className="bg-color1 text-center-c">
                                            <div className="dolor-icon font10px color-dark">
                                                <div> $ </div>
                                                <div> - </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-light-blue client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-light-blue text-right font10px color-dark" 
                                                    id="impact120GrowthROIF24"
                                                    value={formatPrice(impact120GrowthROIF24, 2)}
                                                    disabled={true}  />
                                                </div> 
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-light-blue client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-light-blue text-right font10px color-dark" 
                                                    id="impact120GrowthROIG24"
                                                    value={formatPrice(impact120GrowthROIG24, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-light-blue client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-light-blue text-right font10px color-dark" 
                                                    id="impact120GrowthROIH24"
                                                    value={formatPrice(impact120GrowthROIH24, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-light-blue client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-light-blue text-right font10px color-dark" 
                                                    id="impact120GrowthROII24"
                                                    value={formatPrice(impact120GrowthROII24, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-light-blue client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-light-blue text-right font10px color-dark" 
                                                    id="impact120GrowthROIJ24"
                                                    value={formatPrice(impact120GrowthROIJ24, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-light-blue client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-light-blue text-right font10px color-dark" 
                                                    id="impact120GrowthROIK24"
                                                    value={formatPrice(impact120GrowthROIK24, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-light-blue client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-light-blue text-right font10px color-dark" 
                                                    id="impact120GrowthROIL24"
                                                    value={formatPrice(impact120GrowthROIL24, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-light-blue client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-light-blue text-right font10px color-dark" 
                                                    id="impact120GrowthROIM24"
                                                    value={formatPrice(impact120GrowthROIM24, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-light-blue client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-light-blue text-right font10px color-dark" 
                                                    id="impact120GrowthROIN24"
                                                    value={formatPrice(impact120GrowthROIN24, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-light-blue client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-light-blue text-right font10px color-dark" 
                                                    id="impact120GrowthROIO24"
                                                    value={formatPrice(impact120GrowthROIO24, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font10px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-light-blue client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-light-blue text-right font10px color-dark" 
                                                    id="impact120GrowthROIP24"
                                                    value={formatPrice(impact120GrowthROIP24, 2)}
                                                    disabled={true}  />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="nextInput">
                            <ul>
                                <li>
                                    <Link to="/your-growth-potential/total-financial-impact" className='next-link'>Next: Total Financial Impact
                                        <span className='icon-three'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                        </span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}