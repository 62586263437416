import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state/index';
import { Link } from "react-router-dom";

import { getBenchmarkScoreGapAnalysis, modifyBenchmarkScoreGapAnalysis } from "../../state/features/BenchmarkScoreGapAnalysisSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import { useAlert } from 'react-alert'

import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';

import { Radar } from 'react-chartjs-2';
import  UserDropdown  from '../UserDropdown';
ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);

export const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: false,
        }
    },
    scales: {
        r: {
            angleLines: {
                display: true,
                color: '#8497B0',
            },
            ticks: {
                color: '#44546A',
                backdropColor: 'transparent',
                z: 999999,
                font: {
                    size: 16,
                    weight: 700,
                }
            },
            pointLabels: {
                color: '#44546A',
                font: {
                    size: 16,
                    weight: 600
                }
            },
            grid: {
                z: 999999
            },
            min: 0,
            max: 100
        },
    }
};


export default function GrowthCapabilitiesGapAnalysisContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const colorPallete = ['#F8696B', '#FCAA78', '#FFEB84', '#B1D580', '#63BE7B'];
    const bgColorPoint = (num) => {
        if(isNaN(num)) {
            return colorPallete[2];
        }
        if(num === 50){
            return colorPallete[2];
        }else if(num > 50 && num < 60){
            return colorPallete[4];
        }else if(num > 40 && num < 50){
            return colorPallete[2];
        }else if(num >= 60){
            return colorPallete[4];
        }else if(num <= 40){
            return colorPallete[0];
        }
    }

    const dispatch = useDispatch();
    const benchmarkScoreGapAnalysis = useSelector(state => state.benchmarkScoreGapAnalysis)
    const { benchmarkScoreGapAnalysisData } = bindActionCreators(actionCreators, dispatch)


    const [Inputs, setInputs] = useState({
        benchmarkScoreGapAnalysisM20: 0,
        benchmarkScoreGapAnalysisR13: 0,
        benchmarkScoreGapAnalysisR14: 0,
        benchmarkScoreGapAnalysisR15: 0,
        benchmarkScoreGapAnalysisR16: 0,
        benchmarkScoreGapAnalysisR17: 0,
        benchmarkScoreGapAnalysisR18: 0,
        benchmarkScoreGapAnalysisR19: 0,
        benchmarkScoreGapAnalysisR20: 0,
        benchmarkScoreGapAnalysisR21: 0,
        benchmarkScoreGapAnalysisR22: 0,
        benchmarkScoreGapAnalysisR23: 0,
        benchmarkScoreGapAnalysisR24: 0,
        benchmarkScoreGapAnalysisW13: 30,
        benchmarkScoreGapAnalysisW14: 30,
        benchmarkScoreGapAnalysisW15: 30,
        benchmarkScoreGapAnalysisW16: 30,
        benchmarkScoreGapAnalysisW17: 30,
        benchmarkScoreGapAnalysisW18: 30,
        benchmarkScoreGapAnalysisW19: 30,
        benchmarkScoreGapAnalysisW20: 30,
        benchmarkScoreGapAnalysisW21: 30,
        benchmarkScoreGapAnalysisW22: 30,
        benchmarkScoreGapAnalysisW23: 30,
        benchmarkScoreGapAnalysisX13: 80,
        benchmarkScoreGapAnalysisX14: 80,
        benchmarkScoreGapAnalysisX15: 80,
        benchmarkScoreGapAnalysisX16: 80,
        benchmarkScoreGapAnalysisX17: 80,
        benchmarkScoreGapAnalysisX18: 80,
        benchmarkScoreGapAnalysisX19: 80,
        benchmarkScoreGapAnalysisX20: 80,
        benchmarkScoreGapAnalysisX21: 80,
        benchmarkScoreGapAnalysisX22: 80,
        benchmarkScoreGapAnalysisX23: 80,
        benchmarkScoreGapAnalysisZ13: 100,
        benchmarkScoreGapAnalysisZ14: 100,
        benchmarkScoreGapAnalysisZ15: 100,
        benchmarkScoreGapAnalysisZ16: 100,
        benchmarkScoreGapAnalysisZ17: 100,
        benchmarkScoreGapAnalysisZ18: 100,
        benchmarkScoreGapAnalysisZ19: 100,
        benchmarkScoreGapAnalysisZ20: 100,
        benchmarkScoreGapAnalysisZ21: 100,
        benchmarkScoreGapAnalysisZ22: 100,
        benchmarkScoreGapAnalysisZ23: 100,
        BenchmarkScoreGapAnalysisK36 : '',
        BenchmarkScoreGapAnalysisK37 : '',
        BenchmarkScoreGapAnalysisK38 : '',
        BenchmarkScoreGapAnalysisK39 : '',
        BenchmarkScoreGapAnalysisK40 : '',
        BenchmarkScoreGapAnalysisK41 : '',
        BenchmarkScoreGapAnalysisK42 : '',
        BenchmarkScoreGapAnalysisK43 : '',
        BenchmarkScoreGapAnalysisK44 : '',
        BenchmarkScoreGapAnalysisK47 : '',
        BenchmarkScoreGapAnalysisK48 : '',
        BenchmarkScoreGapAnalysisK49 : '',
        BenchmarkScoreGapAnalysisK50 : '',
        BenchmarkScoreGapAnalysisK51 : '',
        BenchmarkScoreGapAnalysisK52 : '',
        BenchmarkScoreGapAnalysisK53 : '',
        BenchmarkScoreGapAnalysisK54 : '',
        BenchmarkScoreGapAnalysisK55 : '',
        BenchmarkScoreGapAnalysisK56 : '',
        BenchmarkScoreGapAnalysisK59 : '',
        BenchmarkScoreGapAnalysisK60 : '',
        BenchmarkScoreGapAnalysisK61 : '',
        BenchmarkScoreGapAnalysisK62 : '',
        BenchmarkScoreGapAnalysisK63 : '',
        BenchmarkScoreGapAnalysisK64 : '',
        BenchmarkScoreGapAnalysisK65 : '',
        BenchmarkScoreGapAnalysisK66 : '',
        BenchmarkScoreGapAnalysisK67 : '',
        BenchmarkScoreGapAnalysisK70 : '',
        BenchmarkScoreGapAnalysisK71 : '',
        BenchmarkScoreGapAnalysisK72 : '',
        BenchmarkScoreGapAnalysisK73 : '',
        BenchmarkScoreGapAnalysisK74 : '',
        BenchmarkScoreGapAnalysisK75 : '',
        BenchmarkScoreGapAnalysisK76 : '',
        BenchmarkScoreGapAnalysisK77 : '',
        BenchmarkScoreGapAnalysisK78 : '',
        BenchmarkScoreGapAnalysisK79 : '',
        BenchmarkScoreGapAnalysisK80 : '',
        BenchmarkScoreGapAnalysisK81 : '',
        BenchmarkScoreGapAnalysisK82 : ''
    });

    const [Cache, setCache] = useState(Inputs);

    useEffect(() => {
        // console.log('R23 set.......', Inputs)
        benchmarkScoreGapAnalysisData("benchmarkScoreGapAnalysisAll", {
            benchmarkScoreGapAnalysisR13 : Inputs.benchmarkScoreGapAnalysisR13, benchmarkScoreGapAnalysisR14 : Inputs.benchmarkScoreGapAnalysisR14, benchmarkScoreGapAnalysisR15 : Inputs.benchmarkScoreGapAnalysisR15, benchmarkScoreGapAnalysisR16 : Inputs.benchmarkScoreGapAnalysisR16, benchmarkScoreGapAnalysisR17 : Inputs.benchmarkScoreGapAnalysisR17, benchmarkScoreGapAnalysisR18 : Inputs.benchmarkScoreGapAnalysisR18, benchmarkScoreGapAnalysisR19 : Inputs.benchmarkScoreGapAnalysisR19, benchmarkScoreGapAnalysisR20 : Inputs.benchmarkScoreGapAnalysisR20, benchmarkScoreGapAnalysisR21 : Inputs.benchmarkScoreGapAnalysisR21, benchmarkScoreGapAnalysisR22 : Inputs.benchmarkScoreGapAnalysisR22, benchmarkScoreGapAnalysisR23 : Inputs.benchmarkScoreGapAnalysisR23,
            benchmarkScoreGapAnalysisM20 : Inputs.benchmarkScoreGapAnalysisM20
        });
    }, [Inputs.benchmarkScoreGapAnalysisM20]);

    const { benchmarkScoreGapAnalysisR13, benchmarkScoreGapAnalysisR14, benchmarkScoreGapAnalysisR15, benchmarkScoreGapAnalysisR16, benchmarkScoreGapAnalysisR17, benchmarkScoreGapAnalysisR18, benchmarkScoreGapAnalysisR19, benchmarkScoreGapAnalysisR20, benchmarkScoreGapAnalysisR21, benchmarkScoreGapAnalysisR22, benchmarkScoreGapAnalysisR23 } = benchmarkScoreGapAnalysis
    // [
    //     {
    //         // label: '# Series 3 # score',
    //         data: [benchmarkScoreGapAnalysisR13, benchmarkScoreGapAnalysisR14, benchmarkScoreGapAnalysisR15, benchmarkScoreGapAnalysisR16, benchmarkScoreGapAnalysisR17, benchmarkScoreGapAnalysisR18, benchmarkScoreGapAnalysisR19, benchmarkScoreGapAnalysisR20, benchmarkScoreGapAnalysisR21, benchmarkScoreGapAnalysisR22, benchmarkScoreGapAnalysisR23],
    //         backgroundColor: 'rgba(54, 162, 235, 0.2)',
    //         borderColor: '#0075F2',
    //         borderWidth: 2,
    //         pointRadius: 0,
    //     },
    //     {
    //         // label: '# Series 1',
    //         data: [Inputs.benchmarkScoreGapAnalysisW13, Inputs.benchmarkScoreGapAnalysisW14, Inputs.benchmarkScoreGapAnalysisW15, Inputs.benchmarkScoreGapAnalysisW16, Inputs.benchmarkScoreGapAnalysisW17, Inputs.benchmarkScoreGapAnalysisW18, Inputs.benchmarkScoreGapAnalysisW19, Inputs.benchmarkScoreGapAnalysisW20, Inputs.benchmarkScoreGapAnalysisW21, Inputs.benchmarkScoreGapAnalysisW22, Inputs.benchmarkScoreGapAnalysisW23],
    //         backgroundColor: 'rgb(255, 51, 88)',
    //         borderColor: 'rgb(255, 99, 132)',
    //         borderWidth: 1,
    //         pointRadius: 0,
    //     },
    //     {
    //         // label: '# Series 2',
    //         data: [Inputs.benchmarkScoreGapAnalysisX13, Inputs.benchmarkScoreGapAnalysisX14, Inputs.benchmarkScoreGapAnalysisX15, Inputs.benchmarkScoreGapAnalysisX16, Inputs.benchmarkScoreGapAnalysisX17, Inputs.benchmarkScoreGapAnalysisX18, Inputs.benchmarkScoreGapAnalysisX19, Inputs.benchmarkScoreGapAnalysisX20, Inputs.benchmarkScoreGapAnalysisX21, Inputs.benchmarkScoreGapAnalysisX22, Inputs.benchmarkScoreGapAnalysisX23],
    //         backgroundColor: '#36a47b63',
    //         borderColor: '#36a47b65',
    //         borderWidth: 1,
    //         pointRadius: 0,
    //     },
    //     {
    //         // label: '# Series 4',
    //         data: [Inputs.benchmarkScoreGapAnalysisZ13, Inputs.benchmarkScoreGapAnalysisZ14, Inputs.benchmarkScoreGapAnalysisZ15, Inputs.benchmarkScoreGapAnalysisZ16, Inputs.benchmarkScoreGapAnalysisZ17, Inputs.benchmarkScoreGapAnalysisZ18, Inputs.benchmarkScoreGapAnalysisZ19, Inputs.benchmarkScoreGapAnalysisZ20, Inputs.benchmarkScoreGapAnalysisZ21, Inputs.benchmarkScoreGapAnalysisZ22, Inputs.benchmarkScoreGapAnalysisZ23],
    //         backgroundColor: '#36a47b63',
    //         borderColor: '#36a47b65',
    //         borderWidth: 1,
    //         pointRadius: 0,
    //     },
    // ],
    const chartData = {
        labels: [['Competitive','Advantage'], ['Financial', 'Performance'], ['Customer', 'Acquisition'], ['Customer', 'Retention'], ['Customer', 'Transactions'], 'Ability', 'Agility', ['Strategic', 'Value'], 'Customers', 'Operations', ['Growth', 'Platform']],
        datasets: [
            {
                backgroundColor: 'transparent',
                borderColor: 'rgb(0, 171, 242)',
                borderWidth: 3,
                data: [benchmarkScoreGapAnalysisR13, benchmarkScoreGapAnalysisR14, benchmarkScoreGapAnalysisR15, benchmarkScoreGapAnalysisR16, benchmarkScoreGapAnalysisR17, benchmarkScoreGapAnalysisR18, benchmarkScoreGapAnalysisR19, benchmarkScoreGapAnalysisR20, benchmarkScoreGapAnalysisR21, benchmarkScoreGapAnalysisR22, benchmarkScoreGapAnalysisR23],
                pointRadius: 0
            },
            {
                backgroundColor : '#d20000',
                data: [Inputs.benchmarkScoreGapAnalysisW13, Inputs.benchmarkScoreGapAnalysisW14, Inputs.benchmarkScoreGapAnalysisW15, Inputs.benchmarkScoreGapAnalysisW16, Inputs.benchmarkScoreGapAnalysisW17, Inputs.benchmarkScoreGapAnalysisW18, Inputs.benchmarkScoreGapAnalysisW19, Inputs.benchmarkScoreGapAnalysisW20, Inputs.benchmarkScoreGapAnalysisW21, Inputs.benchmarkScoreGapAnalysisW22, Inputs.benchmarkScoreGapAnalysisW23],
                pointRadius: 0
            },
            {
                backgroundColor: 'white',
                data: [Inputs.benchmarkScoreGapAnalysisX13, Inputs.benchmarkScoreGapAnalysisX14, Inputs.benchmarkScoreGapAnalysisX15, Inputs.benchmarkScoreGapAnalysisX16, Inputs.benchmarkScoreGapAnalysisX17, Inputs.benchmarkScoreGapAnalysisX18, Inputs.benchmarkScoreGapAnalysisX19, Inputs.benchmarkScoreGapAnalysisX20, Inputs.benchmarkScoreGapAnalysisX21, Inputs.benchmarkScoreGapAnalysisX22, Inputs.benchmarkScoreGapAnalysisX23],
                pointRadius: 0
            },
            {
                backgroundColor: '#2BA193',
                data: [Inputs.benchmarkScoreGapAnalysisZ13, Inputs.benchmarkScoreGapAnalysisZ14, Inputs.benchmarkScoreGapAnalysisZ15, Inputs.benchmarkScoreGapAnalysisZ16, Inputs.benchmarkScoreGapAnalysisZ17, Inputs.benchmarkScoreGapAnalysisZ18, Inputs.benchmarkScoreGapAnalysisZ19, Inputs.benchmarkScoreGapAnalysisZ20, Inputs.benchmarkScoreGapAnalysisZ21, Inputs.benchmarkScoreGapAnalysisZ22, Inputs.benchmarkScoreGapAnalysisZ23],
                pointRadius: 0
            },
        ]
    };

    const [benchmarkCategoryWiseUserScoreList, setBenchmarkCategoryWiseUserScoreList] = useState([]);
    const [benchmarkTotalUserScoreList, setBenchmarkTotalUserScoreList] = useState([]);

    useEffect(() => {
        const newBenchmarkScoreObj = { ...Inputs }
        let indexR = 12;
        let sbqUserTotalScore = 0;
        let bqTotalUserScoreList = benchmarkTotalUserScoreList ? benchmarkTotalUserScoreList : '';
        if(bqTotalUserScoreList){
            bqTotalUserScoreList.map((scoreList, scoreKey) => {
                let bqUserScore = scoreList.user_score ? scoreList.user_score : 0;
                let bqTarget = scoreList.bq_total_score ? scoreList.bq_total_score : 0;
                let bqGrade = Math.round(parseFloat(bqUserScore) / parseFloat(bqTarget) * 100);
                indexR = indexR + 1;
                newBenchmarkScoreObj["benchmarkScoreGapAnalysisR"+indexR] = bqGrade;
                sbqUserTotalScore = sbqUserTotalScore + parseFloat(bqUserScore);
                // console.log('--loop--')
                if((scoreKey+1) == bqTotalUserScoreList.length){
                    newBenchmarkScoreObj["benchmarkScoreGapAnalysisM20"] = sbqUserTotalScore;
                    setInputs(newBenchmarkScoreObj);
                    // console.log('key--', scoreKey);
                    //return response
                }
            })
        }
    }, [benchmarkTotalUserScoreList])

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getBenchmarkScoreGapAnalysis({user_id}))
        .unwrap()
        .then(async(response) => {
            let bqComments = response.data.bqComments[0] ? response.data.bqComments[0] : '';
            delete bqComments.id;
            delete bqComments.user_id;
            delete bqComments.created_at;
            delete bqComments.updated_at;
            const newObj = { ...Inputs }
            for await (const [key, value] of Object.entries(bqComments)) {
                if (bqComments.hasOwnProperty(key)) {
                    if(value)
                    newObj[key] = value
                }
            }
            setInputs(newObj);
            setCache(newObj);
            return response
        })
        .then((response) => {
            setBenchmarkCategoryWiseUserScoreList(response.data.benchmarkCategoryWiseUserScoreList)
            return response
        })
        .then(async(response=[]) => {
            setBenchmarkTotalUserScoreList(response.data.benchmarkTotalUserScoreList);         
        })
        .then(() => {
            setIsLoading(false)   // Hide loading screen         
        })
        .catch((error) => {
            console.log("error",error)
            setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyBenchmarkScoreGapAnalysis({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                //   console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

    let bqUserTotalScore = 0;
    let bqTotalTarget = 0;
    let overallUserTotalScore = 0;
    let overallTotalTarget = 0;
    let overallTotalGrade = 0;
    let overallTotalScoreGap = 0;
    let index = 32;
    let counter = 0;
    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
    
            {/* row */}
            <div className="container-fluid remove-card-sd">
                <div className="head-title">
                    <h3 class="preheading">State of the Business</h3>
                    <h1 class="mainheading mb-1">Growth Capabilities Gap Analysis</h1>
                </div>
                <Spinner />
                <div className="row mb-5">
                    <div className="col-12 col-lg-6" >
                        <div style={{height: 600}}>
                            <Radar options={options} data={chartData} />
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="table-responsive">
                            <table className="table" style={{width: 550}}>
                                <thead>
                                    <tr className="thead-primary">
                                        <td colSpan={2} className='black-bg font14px text-white'>11 Critical Growth Factors</td>
                                        <td className='black-bg font14px text-white text-center'>Score</td>
                                        <td className='black-bg font14px text-white text-center'>Target</td>
                                        <td className='black-bg font14px text-white text-center'>Grade</td>
                                    </tr>
                                </thead>
                                <tbody>
                                { (benchmarkTotalUserScoreList) ? benchmarkTotalUserScoreList.map((scoreList, scoreKey) => {
                                    let bqUserScore = scoreList.user_score ? scoreList.user_score : 0;
                                    let bqTarget = scoreList.bq_total_score ? scoreList.bq_total_score : 0;
                                    let bqGrade = Math.round(parseFloat(bqUserScore) / parseFloat(bqTarget) * 100);

                                    bqUserTotalScore = bqUserTotalScore + parseFloat(bqUserScore);
                                    bqTotalTarget = bqTotalTarget + parseFloat(bqTarget);
                                    let bqTotalGrade = Math.round((bqUserTotalScore / bqTotalTarget) * 100);

                                    return(
                                    <>
                                    <tr key={scoreKey}>
                                        <td className='black-bg text-center text-white font11px' style={{width: 50}}>{scoreKey+1}</td>
                                        <td className='bg-color-light-blue color-dark font11px' style={{width: 200}}>{scoreList.bq_name}</td>
                                        <td className='bg-color-light-blue color-dark text-center font11px' style={{width: 100}}>{bqUserScore}</td>
                                        <td className='bg-color-light-blue color-dark text-center font11px' style={{width: 100}}>{bqTarget}</td>
                                        <td className='bg-color-blue text-white text-center font11px' style={{width: 100}}>{bqGrade}%</td>
                                    </tr>
                                    { ((scoreKey + 1) == benchmarkTotalUserScoreList.length) ? 
                                    (
                                    <tr key={(scoreKey + 1)}>
                                        <td colSpan="1" className="hide-content"></td>
                                        <td className="bg-color-blue text-white font11px">Overall Score</td>
                                        <td className="bg-color-blue text-white text-center font11px">{bqUserTotalScore}</td>
                                        <td className="bg-color-blue text-white text-center font11px">{bqTotalTarget}</td>
                                        <td className="bg-color-blue text-white text-center font11px">{bqTotalGrade}%</td>
                                    </tr>
                                    )
                                    : <></>
                                    }
                                    </>
                                    )
                                }) 
                                : <h4>No data</h4>
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <h2 className='font24px fontExtraBold'>Growth Driver Benchmark Results</h2>
                { (benchmarkCategoryWiseUserScoreList) ? benchmarkCategoryWiseUserScoreList.map((typeObj, typeKey) => {
                let userTotalScore = 0;
                let totalTarget = 0;
                return(
                    <>
                    <div className="table-responsive" key={"div_"+typeKey}>
                        <table key={"table_"+typeKey} className="table table-responsive mb-4" style={{width: 700}}>
                            <thead className='add-parsonal-class-table'>
                                <tr className="thead-primary">
                                    <td colSpan={2} className='black-bg font14px text-white'>{typeObj.drivers ? typeObj.drivers : 'NA'}</td>
                                    <td className="black-bg font14px text-white text-center">Score</td>
                                    <td className="black-bg font14px text-white text-center">Target</td>
                                    <td className="black-bg font14px text-white text-center">Gap</td>
                                    <td className="black-bg font14px text-white text-center">Grade</td>
                                    <th scope="col" className="displayNone">1</th>
                                    <th scope="col" className="displayNone">Comments</th>
                                </tr>
                            </thead>
                            <tbody>
                                { (typeObj.category) ? typeObj.category.map((list, listKey) => {
                                    let target = (!isNaN(list.bqc_total_score) && list.bqc_total_score) ? parseFloat(list.bqc_total_score) : 0;
                                    let userScore = (!isNaN(list.user_score) && list.user_score ? parseFloat(list.user_score) : 0)
                                    counter++
                                    let ScoreGap = target - userScore
                                    let grade = Math.round((userScore / target) * 100);
                                    
                                    userTotalScore = userTotalScore + parseFloat(userScore);
                                    totalTarget = totalTarget + parseFloat(target);
                                    let totalScoreGap = totalTarget - userTotalScore;
                                    let totalGrade = Math.round((userTotalScore / totalTarget) * 100);

                                    if((listKey + 1) == typeObj.category.length){
                                        overallUserTotalScore = overallUserTotalScore + parseFloat(userTotalScore);
                                        overallTotalTarget = overallTotalTarget + parseFloat(totalTarget);
                                        overallTotalScoreGap = overallTotalTarget - overallUserTotalScore;
                                        overallTotalGrade = Math.round((overallUserTotalScore / overallTotalTarget) * 100);
                                    }

                                    index = (listKey == 0) ? (index + 4) : (index + 1);
                                return(
                                    <>
                                    <tr key={"tr_"+listKey}>
                                    { (listKey == 0) ?
                                    <td className="displayNone" rowSpan={13}>
                                        <span className="style-ctn1" style={{ width: 36, textAlign: 'center', margin: 'auto', display: 'flex', justifyContent: 'center' }}>{typeObj.type_name ? typeObj.type_name : 'NA'}</span>
                                    </td>
                                    : <></>
                                    }
                                    {/* {listKey+1} */}
                                    <td className="text-center black-bg text-white font11px" style={{width: 50}}>{counter}</td>
                                    <td className='bg-color-light-blue color-dark font11px' style={{width: 250}}>{list.category_name.includes('-') ? list.category_name.split('-')[1] : list.category_name}</td>
                                    <td className="text-center bg-color-light-blue color-dark font11px" style={{width: 100}}>{list.user_score ? list.user_score : 0}</td>
                                    <td className="text-center bg-color-light-blue color-dark font11px" style={{width: 100}}>{list.bqc_total_score ? list.bqc_total_score : 0}</td>
                                    <td className="text-center bg-color-light-blue color-dark font11px" style={{width: 100}}>{ScoreGap}</td>
                                    <td className={"text-center color-dark font11px " + (grade >= 80 ? 'color-darkgreen' : (grade < 80 && grade >= 60 ? 'color-lightgreen' : (grade < 60 && grade >= 40 ? 'bg-color6' : (grade < 40 && grade >= 25 ? 'color-r' : (grade < 25 ? 'color-dr' : 'bg-color6')))))} style={{width: 100}}>{grade}%</td>
                                    <td className="displayNone">1</td>
                                    <td className='displayNone'>
                                        <input type="text" className="form-control inputText"
                                        id={"BenchmarkScoreGapAnalysisK"+index}
                                        value={Inputs["BenchmarkScoreGapAnalysisK"+index]}
                                        onChange={(event) => setInputs({ ...Inputs, [event.target.id] : event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading} /></td>
                                    </tr>
                                    { (listKey + 1 == typeObj.category.length) ? 
                                    (<>
                                    <tr>
                                        <td colSpan={2} className="text-center bg-color-blue text-white font11px">Total Score</td>
                                        <td className="text-center bg-color-blue text-white font11px">{userTotalScore}</td>
                                        <td className="text-center bg-color-blue text-white font11px">{totalTarget}</td>
                                        <td className="text-center bg-color-blue text-white font11px">{totalScoreGap}</td>
                                        <td className="text-center bg-color-blue text-white font11px">{totalGrade}%</td>
                                        <td className="displayNone">1</td>
                                        <td className='displayNone'>
                                        <input type="text" className="form-control inputText"
                                        id={"BenchmarkScoreGapAnalysisK"+(index+1)}
                                        value={Inputs["BenchmarkScoreGapAnalysisK"+(index+1)]}
                                        onChange={(event) => setInputs({ ...Inputs, [event.target.id] : event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading} /></td>
                                    </tr></>)
                                    : <></>
                                    }
                                    { ((typeKey + 1 == benchmarkCategoryWiseUserScoreList.length) && (listKey + 1 == typeObj.category.length)) ? 
                                    (<>
                                    <tr><td colSpan={7} scope="row" className="d-hidden">1</td></tr>
                                    <tr className='displayNone'>
                                        <td colSpan={3} className="bg-color1 text-white font11px">Overall Score</td>
                                        <td className="bg-color1 text-white text-center-c font11px">{overallUserTotalScore}</td>
                                        <td className="bg-color1 text-white text-center-c font11px">{overallTotalTarget}</td>
                                        <td className="bg-color1 text-white text-center-c font11px">{overallTotalScoreGap}</td>
                                        <td className="bg-color1 text-white text-center-c font11px">{overallTotalGrade}%</td>
                                    </tr>
                                    </>)
                                    : <></>
                                    }
                                    </>
                                    )
                                }) 
                                : <h4>No data</h4>}
                            </tbody>
                        </table>
                    </div>
                    </>
                    )
                }) 
            : <h4>No data</h4>}

                <div className="nextInput">
                    <ul>
                        <li>
                            <Link className="next-link" to="/state-of-the-business/growth-driver-performance"> Next:  Growth Driver Performance
                                <span className="icon-three">
                                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                                </span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div >
        </div >

    )
}
