import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getReferralStrategy, modifyReferralStrategy } from "../../state/features/ReferralStrategySlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';

export default function ReferralStrategyContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        ReferralStrategyC9 : '',
        ReferralStrategyD9 : '',
        ReferralStrategyE9 : '',
        ReferralStrategyF9 : '',
        ReferralStrategyG9 : '',
        ReferralStrategyH9 : '',
        ReferralStrategyC10 : '',
        ReferralStrategyD10 : '',
        ReferralStrategyE10 : '',
        ReferralStrategyF10 : '',
        ReferralStrategyG10 : '',
        ReferralStrategyH10 : '',
        ReferralStrategyC11 : '',
        ReferralStrategyD11 : '',
        ReferralStrategyE11 : '',
        ReferralStrategyF11 : '',
        ReferralStrategyG11 : '',
        ReferralStrategyH11 : '',
        ReferralStrategyC12 : '',
        ReferralStrategyD12 : '',
        ReferralStrategyE12 : '',
        ReferralStrategyF12 : '',
        ReferralStrategyG12 : '',
        ReferralStrategyH12 : '',
        ReferralStrategyC13 : '',
        ReferralStrategyD13 : '',
        ReferralStrategyE13 : '',
        ReferralStrategyF13 : '',
        ReferralStrategyG13 : '',
        ReferralStrategyH13 : '',
        ReferralStrategyC14 : '',
        ReferralStrategyD14 : '',
        ReferralStrategyE14 : '',
        ReferralStrategyF14 : '',
        ReferralStrategyG14 : '',
        ReferralStrategyH14 : '',
        ReferralStrategyC15 : '',
        ReferralStrategyD15 : '',
        ReferralStrategyE15 : '',
        ReferralStrategyF15 : '',
        ReferralStrategyG15 : '',
        ReferralStrategyH15 : '',
        ReferralStrategyC16 : '',
        ReferralStrategyD16 : '',
        ReferralStrategyE16 : '',
        ReferralStrategyF16 : '',
        ReferralStrategyG16 : '',
        ReferralStrategyH16 : '',
        ReferralStrategyC17 : '',
        ReferralStrategyD17 : '',
        ReferralStrategyE17 : '',
        ReferralStrategyF17 : '',
        ReferralStrategyG17 : '',
        ReferralStrategyH17 : '',
        ReferralStrategyC18 : '',
        ReferralStrategyD18 : '',
        ReferralStrategyE18 : '',
        ReferralStrategyF18 : '',
        ReferralStrategyG18 : '',
        ReferralStrategyH18 : '',
        ReferralStrategyC21 : '',
        ReferralStrategyE21 : '',
        ReferralStrategyG21 : '',
        ReferralStrategyC22 : '',
        ReferralStrategyE22 : '',
        ReferralStrategyG22 : '',
        ReferralStrategyC23 : '',
        ReferralStrategyE23 : '',
        ReferralStrategyG23 : '',
        ReferralStrategyC24 : '',
        ReferralStrategyE24 : '',
        ReferralStrategyG24 : '',
        ReferralStrategyC25 : '',
        ReferralStrategyE25 : '',
        ReferralStrategyG25 : '',
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getReferralStrategy({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.revenueGrowth)
            if(response!= undefined  && response.data.revenueGrowth){
                let revenueGrowth = response.data.revenueGrowth[0];
                console.log(revenueGrowth)
                delete revenueGrowth.id;
                delete revenueGrowth.user_id;
                delete revenueGrowth.created_at;
                delete revenueGrowth.updated_at;
                const newObj = { ...Inputs }
                for (const key in revenueGrowth) {
                    if (revenueGrowth.hasOwnProperty(key)) {
                        if(revenueGrowth[key])
                        newObj[key] = revenueGrowth[key]
                    }
                }
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyReferralStrategy({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

  return (
    <div className="content-body">
        <UserDropdown />
        {/* row */}
        <div className="container-fluid">
            <div className="head-title">
                <p className="mb-0">Sales &amp; Sales Management </p>
                <h2 className="mb-5">Referral Strategy Worksheet </h2>
            </div>
            <Spinner />
            <div className="row">
                <div className="col-xl-12 contentleft">
                <div className="card">
                    <div className="card-body pl-0">
                    {/* table 1 */}
                    <div className="table-responsive table-dta">
                        {/* table 1 */}
                        <table className="table mb-0">
                        <thead>
                            <tr>
                            <th colSpan={3} className="bg-color5 d-hidden text-white"> cell </th>
                            <th colSpan={3} className="bg-color1 text-white text-center-c text-font-size-15 padding-10"> Referral Tools </th>
                            </tr>
                            <tr>
                            <th className="bg-color1 text-white text-center-c text-font-size-15 padding-10">Potential Referral <br /> Source</th>
                            <th className="bg-color1 text-white text-center-c text-font-size-15 padding-10">Company</th>
                            <th className="bg-color1 text-white text-center-c text-font-size-15 padding-10">Potential Referrals</th>
                            <th className="bg-color3 text-white text-center-c text-font-size-15 padding-10">Referral Offer</th>
                            <th className="bg-color3 text-white text-center-c text-font-size-15 padding-10">Referral Framing</th>
                            <th className="bg-color3 text-white text-center-c text-font-size-15 padding-10">Referral Reward</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyC9"
                                    value={Inputs.ReferralStrategyC9}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyC9: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyD9"
                                    value={Inputs.ReferralStrategyD9}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyD9: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyE9"
                                    value={Inputs.ReferralStrategyE9}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyE9: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3 text-white text-center-c">
                                <input type="text" className="inputTextf text-white"
                                    id="ReferralStrategyF9"
                                    value={Inputs.ReferralStrategyF9}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyF9: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3 text-white text-center-c">
                                <input type="text" className="inputTextf text-white"
                                    id="ReferralStrategyG9"
                                    value={Inputs.ReferralStrategyG9}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyG9: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3 text-white text-center-c">
                                <input type="text" className="inputTextf text-white"
                                    id="ReferralStrategyH9"
                                    value={Inputs.ReferralStrategyH9}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyH9: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyC10"
                                    value={Inputs.ReferralStrategyC10}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyC10: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyD10"
                                    value={Inputs.ReferralStrategyD10}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyD10: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyE10"
                                    value={Inputs.ReferralStrategyE10}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyE10: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3 text-white text-center-c">
                                <input type="text" className="inputTextf text-white"
                                    id="ReferralStrategyF10"
                                    value={Inputs.ReferralStrategyF10}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyF10: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3 text-white text-center-c">
                                <input type="text" className="inputTextf text-white"
                                    id="ReferralStrategyG10"
                                    value={Inputs.ReferralStrategyG10}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyG10: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3 text-white text-center-c">
                                <input type="text" className="inputTextf text-white"
                                    id="ReferralStrategyH10"
                                    value={Inputs.ReferralStrategyH10}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyH10: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyC11"
                                    value={Inputs.ReferralStrategyC11}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyC11: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyD11"
                                    value={Inputs.ReferralStrategyD11}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyD11: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyE11"
                                    value={Inputs.ReferralStrategyE11}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyE11: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3 text-white text-center-c">
                                <input type="text" className="inputTextf text-white"
                                    id="ReferralStrategyF11"
                                    value={Inputs.ReferralStrategyF11}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyF11: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3 text-white text-center-c">
                                <input type="text" className="inputTextf text-white"
                                    id="ReferralStrategyG11"
                                    value={Inputs.ReferralStrategyG11}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyG11: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3 text-white text-center-c">
                                <input type="text" className="inputTextf text-white"
                                    id="ReferralStrategyH11"
                                    value={Inputs.ReferralStrategyH11}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyH11: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyC12"
                                    value={Inputs.ReferralStrategyC12}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyC12: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyD12"
                                    value={Inputs.ReferralStrategyD12}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyD12: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyE12"
                                    value={Inputs.ReferralStrategyE12}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyE12: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3 text-white text-center-c">
                                <input type="text" className="inputTextf text-white"
                                    id="ReferralStrategyF12"
                                    value={Inputs.ReferralStrategyF12}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyF12: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3 text-white text-center-c">
                                <input type="text" className="inputTextf text-white"
                                    id="ReferralStrategyG12"
                                    value={Inputs.ReferralStrategyG12}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyG12: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3 text-white text-center-c">
                                <input type="text" className="inputTextf text-white"
                                    id="ReferralStrategyH12"
                                    value={Inputs.ReferralStrategyH12}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyH12: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyC13"
                                    value={Inputs.ReferralStrategyC13}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyC13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyD13"
                                    value={Inputs.ReferralStrategyD13}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyD13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyE13"
                                    value={Inputs.ReferralStrategyE13}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyE13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3 text-white text-center-c">
                                <input type="text" className="inputTextf text-white"
                                    id="ReferralStrategyF13"
                                    value={Inputs.ReferralStrategyF13}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyF13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3 text-white text-center-c">
                                <input type="text" className="inputTextf text-white"
                                    id="ReferralStrategyG13"
                                    value={Inputs.ReferralStrategyG13}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyG13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3 text-white text-center-c">
                                <input type="text" className="inputTextf text-white"
                                    id="ReferralStrategyH13"
                                    value={Inputs.ReferralStrategyH13}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyH13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyC14"
                                    value={Inputs.ReferralStrategyC14}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyC14: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyD14"
                                    value={Inputs.ReferralStrategyD14}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyD14: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyE14"
                                    value={Inputs.ReferralStrategyE14}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyE14: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3 text-white text-center-c">
                                <input type="text" className="inputTextf text-white"
                                    id="ReferralStrategyF14"
                                    value={Inputs.ReferralStrategyF14}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyF14: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3 text-white text-center-c">
                                <input type="text" className="inputTextf text-white"
                                    id="ReferralStrategyG14"
                                    value={Inputs.ReferralStrategyG14}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyG14: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3 text-white text-center-c">
                                <input type="text" className="inputTextf text-white"
                                    id="ReferralStrategyH14"
                                    value={Inputs.ReferralStrategyH14}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyH14: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyC15"
                                    value={Inputs.ReferralStrategyC15}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyC15: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyD15"
                                    value={Inputs.ReferralStrategyD15}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyD15: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyE15"
                                    value={Inputs.ReferralStrategyE15}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyE15: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3 text-white text-center-c">
                                <input type="text" className="inputTextf text-white"
                                    id="ReferralStrategyF15"
                                    value={Inputs.ReferralStrategyF15}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyF15: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3 text-white text-center-c">
                                <input type="text" className="inputTextf text-white"
                                    id="ReferralStrategyG15"
                                    value={Inputs.ReferralStrategyG15}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyG15: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3 text-white text-center-c">
                                <input type="text" className="inputTextf text-white"
                                    id="ReferralStrategyH15"
                                    value={Inputs.ReferralStrategyH15}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyH15: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyC16"
                                    value={Inputs.ReferralStrategyC16}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyC16: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyD16"
                                    value={Inputs.ReferralStrategyD16}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyD16: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyE16"
                                    value={Inputs.ReferralStrategyE16}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyE16: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3 text-white text-center-c">
                                <input type="text" className="inputTextf text-white"
                                    id="ReferralStrategyF16"
                                    value={Inputs.ReferralStrategyF16}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyF16: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3 text-white text-center-c">
                                <input type="text" className="inputTextf text-white"
                                    id="ReferralStrategyG16"
                                    value={Inputs.ReferralStrategyG16}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyG16: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3 text-white text-center-c">
                                <input type="text" className="inputTextf text-white"
                                    id="ReferralStrategyH16"
                                    value={Inputs.ReferralStrategyH16}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyH16: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyC17"
                                    value={Inputs.ReferralStrategyC17}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyC17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyD17"
                                    value={Inputs.ReferralStrategyD17}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyD17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyE17"
                                    value={Inputs.ReferralStrategyE17}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyE17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3 text-white text-center-c">
                                <input type="text" className="inputTextf text-white"
                                    id="ReferralStrategyF17"
                                    value={Inputs.ReferralStrategyF17}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyF17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3 text-white text-center-c">
                                <input type="text" className="inputTextf text-white"
                                    id="ReferralStrategyG17"
                                    value={Inputs.ReferralStrategyG17}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyG17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3 text-white text-center-c">
                                <input type="text" className="inputTextf text-white"
                                    id="ReferralStrategyH17"
                                    value={Inputs.ReferralStrategyH17}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyH17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyC18"
                                    value={Inputs.ReferralStrategyC18}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyC18: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyD18"
                                    value={Inputs.ReferralStrategyD18}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyD18: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyE18"
                                    value={Inputs.ReferralStrategyE18}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyE18: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3 text-white text-center-c">
                                <input type="text" className="inputTextf text-white"
                                    id="ReferralStrategyF18"
                                    value={Inputs.ReferralStrategyF18}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyF18: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3 text-white text-center-c">
                                <input type="text" className="inputTextf text-white"
                                    id="ReferralStrategyG18"
                                    value={Inputs.ReferralStrategyG18}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyG18: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3 text-white text-center-c">
                                <input type="text" className="inputTextf text-white"
                                    id="ReferralStrategyH18"
                                    value={Inputs.ReferralStrategyH18}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyH18: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    {/* table 2 */}
                    <div className="table-responsive table-dta">
                        <table className="table mb-0 mt-4">
                        <thead>
                            <tr>
                            <th colSpan={1} className="bg-color1 text-white text-center-c text-font-size-15"> Referral Offers </th>
                            <th colSpan={1} className="bg-color1 text-white text-center-c text-font-size-15 padding-10">Referral Framings</th>
                            <th colSpan={1} className="bg-color1 text-white text-center-c text-font-size-15 padding-10">Referral Rewards</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyC21"
                                    value={Inputs.ReferralStrategyC21}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyC21: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyE21"
                                    value={Inputs.ReferralStrategyE21}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyE21: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyG21"
                                    value={Inputs.ReferralStrategyG21}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyG21: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyC22"
                                    value={Inputs.ReferralStrategyC22}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyC22: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyE22"
                                    value={Inputs.ReferralStrategyE22}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyE22: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyG22"
                                    value={Inputs.ReferralStrategyG22}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyG22: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyC23"
                                    value={Inputs.ReferralStrategyC23}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyC23: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyE23"
                                    value={Inputs.ReferralStrategyE23}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyE23: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyG23"
                                    value={Inputs.ReferralStrategyG23}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyG23: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyC24"
                                    value={Inputs.ReferralStrategyC24}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyC24: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyE24"
                                    value={Inputs.ReferralStrategyE24}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyE24: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyG24"
                                    value={Inputs.ReferralStrategyG24}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyG24: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyC25"
                                    value={Inputs.ReferralStrategyC25}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyC25: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyE25"
                                    value={Inputs.ReferralStrategyE25}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyE25: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="ReferralStrategyG25"
                                    value={Inputs.ReferralStrategyG25}
                                    onChange={(event) => setInputs({ ...Inputs, ReferralStrategyG25: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>                                          
                </div>
            </div>
        </div>

    </div>
  )
}
