import React from 'react'
import { Link } from "react-router-dom";
import  UserDropdown  from '../UserDropdown';

export default function StrategicFoundationContent() {
  return (
    <div className="content-body">
        <UserDropdown />
        {/* row */}
        <div className="container-fluid">
            <div className="head-title">
                <h2>Strategic Foundation</h2>	           					
            </div>
            <div className="card">
                <div className="card-body">
                <div className="row">
                    <div className="col-md-9 contentleft">
                    <p>Growth Planning is not something that can be done just once per year.  The 120 Growth Catalyst makes growth planning easy, fast, and thorough.  The result:  an agile growth strategy that keeps you consistently advancing toward your goals.</p>
                    </div>
                    <div className="col-md-3">
                        <img src={window.location.origin+"/images/strategy-foundation.png"} alt="Strategy Foundation" title="Strategy Foundation" />
                    </div>
                </div>
                </div>
            </div>
            <div className="row strategicpage">
                <div className="col-md-4 contentleft">
                <div className="card">
                    <div className="card-body">
                    <h4 className="mt-4">General Strategy Tools</h4>
                    <ul>
                        <li><Link to="/strategic-foundation/10-5-3-1-Planning">10-5-3-1 Planning</Link></li>
                        <li><Link to="/strategic-foundation/strategy-and-innovation-map">Strategy &amp; Innovation Map</Link></li>
                        <li><Link to="/strategic-foundation/critical-growth-factors">Critical Growth Factors</Link></li>
                        <li><Link to="/strategic-foundation/strategy-uncertainty-map">Strategy Uncertainty Map</Link></li>
                        <li><Link to="/strategic-foundation/break-through-strategy">Breakthrough Strategy</Link></li>
                        <li><Link to="/strategic-foundation/the-big-SWOT">The Big SWOT</Link></li>
                        <li><Link to="/strategic-foundation/customer-personas">Customer Personas</Link></li>
                        <li><Link to="/strategic-foundation/values-mission-goals">Values-Mission-Goals</Link></li>
                        <li><Link to="/strategic-foundation/go-to-market-strategy">Go-To-Market Strategy</Link></li>
                    </ul>
                    </div>   
                </div>
                </div>
                <div className="col-md-4 contentleft">
                <div className="card">
                    <div className="card-body">
                    <h4 className="mt-4">General Strategy Tools (cont)</h4>
                    <ul>
                        <li><Link to="/strategic-foundation/pyramid-of-purpose">Pyramid of Purpose</Link></li>
                        <li><Link to="/strategic-foundation/growth-progress-report">Growth Progress Report</Link></li>
                    </ul>
                    <h4 className="mt-4">Competitive Strategy</h4>
                    <ul>
                        <li><Link to="/strategic-foundation/five-step-strategy">Five Step Strategy</Link></li>
                        <li><Link to="/strategic-foundation/competitive-analysis">Competitive Analysis</Link></li>
                        <li><Link to="/strategic-foundation/competitive-advantage-audit">Competitive Advantage Audit</Link></li>
                        <li><Link to="/strategic-foundation/VRIO-framework">VRIO Framework</Link></li>
                        <li><Link to="/strategic-foundation/differentiation-diagnostic">Differentiation Diagnostic</Link></li>
                        <li><Link to="/strategic-foundation/ADL-matrix">ADL Matrix</Link></li>
                    </ul>
                    </div>   
                </div>
                </div>
                <div className="col-md-4 contentleft">
                <div className="card">
                    <div className="card-body">
                    <h4 className="mt-4">Competitive Strategy (cont)</h4>
                    <ul>
                        <li><Link to="/strategic-foundation/brand-scorecard">Brand Scorecard</Link></li>
                        <li><Link to="/strategic-foundation/positioning-matrix">Positioning Matrix</Link></li>
                        <li><Link to="/strategic-foundation/five-forces-framework">Five Forces Framework</Link></li>
                        <li><Link to="/strategic-foundation/innovative-offering-analysis">Innovative Offering Analysis</Link></li>
                    </ul>
                    <h4 className="mt-4">Value Creation</h4>
                    <ul>
                        <li><Link to="/strategic-foundation/four-forces-of-value">Four Forces of Value</Link></li>
                        <li><Link to="/strategic-foundation/customer-transformation-model">Customer Transformation Model</Link></li>
                        <li><Link to="#">Value Proposition Template</Link></li>
                    </ul>
                    </div>   
                </div>
                </div>
                <div className="col-md-4 contentleft">
                    <div className="card">
                        <div className="card-body">
                        <h4>Messaging </h4>
                        <ul>
                            <li><Link to="/strategic-foundation/message-selection-matrix">Message Selection Matrix </Link></li>
                            <li><Link to="/strategic-foundation/whole-brain-messaging">Whole Brain Messaging </Link></li>
                            <li><Link to="/strategic-foundation/insight-storyboard-template">Insight Storyboard Template </Link></li>
                            <li><Link to="/strategic-foundation/customer-empathy-map">Customer Empathy Map </Link></li>
                            <li><Link to="/strategic-foundation/SCQA-framework">SCQA Framework </Link></li>
                            <li><Link to="/strategic-foundation/minto-pyramid">Minto Pyramid </Link></li>
                        </ul>
                        </div>   
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
