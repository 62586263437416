import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import  UserDropdown  from '../UserDropdown';

import { getBenchmarkScoreGapAnalysis } from "../../state/features/BenchmarkScoreGapAnalysisSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import { useAlert } from 'react-alert'

export default function GrowthDriverPerformanceContentNew() {

    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;

    const bgColor = (cellnum) => {
        return (cellnum == 80 || cellnum > 80 ? 'color-darkgreen' : (cellnum < 80 && cellnum >= 60 ? 'color-lightgreen' : (cellnum < 60 && cellnum >= 40 ? 'bg-color6' : (cellnum < 40 && cellnum >= 25 ? 'color-r' : (cellnum < 25 ? 'color-dr' : 'bg-color6')))))
    }

    const dispatch = useDispatch();
    const [Inputs, setInputs] = useState({
        grade_1  : 'bg-color6',
        grade_2  : 'bg-color6',
        grade_3  : 'bg-color6',
        grade_4  : 'bg-color6',
        grade_5  : 'bg-color6',
        grade_6  : 'bg-color6',
        grade_7  : 'bg-color6',
        grade_8  : 'bg-color6',
        grade_9  : 'bg-color6',
        grade_10 : 'bg-color6',
        grade_11 : 'bg-color6',
        grade_12 : 'bg-color6',
        grade_13 : 'bg-color6',
        grade_14 : 'bg-color6',
        grade_15 : 'bg-color6',
        grade_16 : 'bg-color6',
        grade_17 : 'bg-color6',
        grade_18 : 'bg-color6',
        grade_19 : 'bg-color6',
        grade_20 : 'bg-color6',
        grade_21 : 'bg-color6',
        grade_22 : 'bg-color6',
        grade_23 : 'bg-color6',
        grade_24 : 'bg-color6',
        grade_25 : 'bg-color6',
        grade_26 : 'bg-color6',
        grade_27 : 'bg-color6',
        grade_28 : 'bg-color6',
        grade_29 : 'bg-color6',
        grade_30 : 'bg-color6',
        grade_31 : 'bg-color6',
        grade_32 : 'bg-color6',
        grade_33 : 'bg-color6',
        grade_34 : 'bg-color6',
        grade_35 : 'bg-color6',
        grade_36 : 'bg-color6',
        grade_37 : 'bg-color6',
    })

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getBenchmarkScoreGapAnalysis({user_id}))
        .unwrap()
        .then((response) => {
            const newBenchmarkScoreObj = { ...Inputs }
            let benchmarkCategoryWiseUserScoreList = response.data.benchmarkCategoryWiseUserScoreList ? response.data.benchmarkCategoryWiseUserScoreList : '';
            if(benchmarkCategoryWiseUserScoreList){
                benchmarkCategoryWiseUserScoreList.map((typeObj, typeKey) => {
                    if(typeObj.category){
                        typeObj.category.map((list, listKey) => {
                            let target = (!isNaN(list.bqc_total_score) && list.bqc_total_score) ? parseFloat(list.bqc_total_score) : 0;
                            let userScore = (!isNaN(list.user_score) && list.user_score ? parseFloat(list.user_score) : 0)
                            let grade = Math.round((userScore / target) * 100);
                            newBenchmarkScoreObj["grade_"+list.id] = grade;
                            // console.log('--loop--')
                            if((listKey+1) == typeObj.category.length){
                                setInputs(newBenchmarkScoreObj);
                                // console.log('key--', listKey);
                                return response
                            }
                        })
                    }else{
                        return response;
                    }
                })
            }else{
                return response;
            }
        })
        .then(() => {
            setIsLoading(false)   // Hide loading screen         
        })
        .catch((error) => {
            // console.log("error",error)
            setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });
    }, [dispatch])

    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
            {/* row */}
            <div className="container-fluid">
                <div className="head-title">
                    <h3 className='preheading'>State of the Business</h3>
                    <h1 className='mainheading mb-1'>Growth Driver Performance</h1>
                </div>
                <div className='row'>
                    <div className='col-12 col-lg-6'>
                        <p className='body-text color-dark'>The heatmap below indicates how your company scored for each of the 37 growth drivers of the RevGrowth Operating System.  Low scores indicate an opportunity for improvement, and are represented with a red background.  Good scores are indicated by a green background.</p>
                    </div>
                    <div className='col-12 col-lg-6'>
                        <div className='d-flex justify-content-center'>
                            <div style={{color: 'white', backgroundColor: '#118164', width: 'auto', padding: '5px 15px', marginRight: 2, display: 'grid', alignItems: 'center'}} className="text-center">Best Practice</div>
                            <div style={{color: 'black', backgroundColor: '#19C598', width: 'auto', padding: '5px 15px', marginRight: 2, display: 'grid', alignItems: 'center'}} className="text-center">Above Average</div>
                            <div style={{color: 'black', backgroundColor: '#B8C3D2', width: 'auto', padding: '5px 15px', marginRight: 2, display: 'grid', alignItems: 'center'}} className="text-center">Average</div>
                            <div style={{color: 'black', backgroundColor: '#FF7171', width: 'auto', padding: '5px 15px', marginRight: 2, display: 'grid', alignItems: 'center'}} className="text-center">Below Average</div>
                            <div style={{color: 'white', backgroundColor: '#FF2F2F', width: 'auto', padding: '5px 15px', display: 'grid', alignItems: 'center'}} className="text-center">Poor</div>
                        </div>
                    </div>
                </div>
                <Spinner />
                <div className="prebenchmark">
                    <div className="innerPrebanchmarks">
                        <div className="table-responsive">
                            {/* table 01 */}
                            <table className="table table-01" style={{width: 1160}}>
                                <thead>
                                    <tr>
                                        <td colSpan={2} className='font20px fontExtraBold text-center color-dark'>Strategic Foundation</td>
                                        <td className='white-bg'></td>
                                        <td colSpan={3} className='font20px fontExtraBold text-center color-dark'>Short-Term Revenue</td>
                                        <td className='white-bg'></td>
                                        <td colSpan={2} className='font20px fontExtraBold text-center color-dark'>Agility Engine</td>
                                        <td className='white-bg'></td>
                                        <td colSpan={4} className='font20px fontExtraBold text-center color-dark'>Enterprise Value</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='black-bg text-white text-center font12px fontBold' style={{width: 100, height: 80}}>Competitive Advantage</td>
                                        <td className='black-bg text-white text-center font12px fontBold' style={{width: 100, height: 80}}>Financial Performance</td>
                                        <td className='white-bg' style={{width: 20}}></td>
                                        <td className='black-bg text-white text-center font12px fontBold' style={{width: 100, height: 80}}>Customer Acquisition</td>
                                        <td className='black-bg text-white text-center font12px fontBold' style={{width: 100, height: 80}}>Customer Retention</td>
                                        <td className='black-bg text-white text-center font12px fontBold' style={{width: 100, height: 80}}>Customer Transactions</td>
                                        <td className='white-bg' style={{width: 20}}></td>
                                        <td className='black-bg text-white text-center font12px fontBold' style={{width: 100, height: 80}}>Ability</td>
                                        <td className='black-bg text-white text-center font12px fontBold' style={{width: 100, height: 80}}>Agility</td>
                                        <td className='white-bg' style={{width: 20}}></td>
                                        <td className='black-bg text-white text-center font12px fontBold' style={{width: 100, height: 80}}>Strategic Value</td>
                                        <td className='black-bg text-white text-center font12px fontBold' style={{width: 100, height: 80}}>Operations</td>
                                        <td className='black-bg text-white text-center font12px fontBold' style={{width: 100, height: 80}}>Customers</td>
                                        <td className='black-bg text-white text-center font12px fontBold' style={{width: 100, height: 80}}>Growth Platform</td>
                                    </tr>

                                    <tr>
                                        <td style={{height: 80}} className={bgColor(Inputs.grade_1) + ' font12px text-center color-dark'}>Markets &amp; Customers</td>
                                        <td style={{height: 80}} className={bgColor(Inputs.grade_7) + ' font12px text-center color-dark'}>Customer Financial Value</td>
                                        <td style={{height: 80}} className='white-bg'></td>
                                        <td style={{height: 80}} className={bgColor(Inputs.grade_9) + ' font12px text-center color-dark'}>Lead Generation</td>
                                        <td style={{height: 80}} className={bgColor(Inputs.grade_12) + ' font12px text-center color-dark'}>Perchase Frequency</td>
                                        <td style={{height: 80}} className={bgColor(Inputs.grade_16) + ' font12px text-center color-dark'}>Transaction Value</td>
                                        <td style={{height: 80}} className='white-bg'></td>
                                        <td style={{height: 80}} className={bgColor(Inputs.grade_18) + ' font12px text-center color-dark'}>Growth Capabilities</td>
                                        <td style={{height: 80}} className={bgColor(Inputs.grade_22) + ' font12px text-center color-dark'}>Continuous Testing</td>
                                        <td style={{height: 80}} className='white-bg'></td>
                                        <td style={{height: 80}} className={bgColor(Inputs.grade_26) + ' font12px text-center color-dark'}>Competitive Advantage</td>
                                        <td style={{height: 80}} className={bgColor(Inputs.grade_35) + ' font12px text-center color-dark'}>Resilience</td>
                                        <td style={{height: 80}} className={bgColor(Inputs.grade_32) + ' font12px text-center color-dark'}>Customer Base</td>
                                        <td style={{height: 80}} className={bgColor(Inputs.grade_29) + ' font12px text-center color-dark'}>Growth Rate</td>
                                        {/* <td style={{height: 80}} className={'color-g'}>Above Average</td>                                   */}
                                    </tr>

                                    <tr>
                                        <td style={{height: 80}} className={bgColor(Inputs.grade_2) + ' font12px text-center color-dark'}>Value Creation</td>
                                        <td style={{height: 80}} className={bgColor(Inputs.grade_8) + ' font12px text-center color-dark'}>Back-end Development</td>
                                        <td style={{height: 80}} className='white-bg'></td>
                                        <td style={{height: 80}} className={bgColor(Inputs.grade_10) + ' font12px text-center color-dark'}>Conversion</td>
                                        <td style={{height: 80}} className={bgColor(Inputs.grade_13) + ' font12px text-center color-dark'}>Buying Lifetime</td>
                                        <td style={{height: 80}} className={bgColor(Inputs.grade_17) + ' font12px text-center color-dark'}>Profit Margin</td>
                                        <td style={{height: 80}} className='white-bg'></td>
                                        <td style={{height: 80}} className={bgColor(Inputs.grade_19) + ' font12px text-center color-dark'}>Growth Leadership</td>
                                        <td style={{height: 80}} className={bgColor(Inputs.grade_23) + ' font12px text-center color-dark'}>Continuous Learning</td>
                                        <td style={{height: 80}} className='white-bg'></td>
                                        <td style={{height: 80}} className={bgColor(Inputs.grade_27) + ' font12px text-center color-dark'}>Intellectual Property</td>
                                        <td style={{height: 80}} className={bgColor(Inputs.grade_36) + ' font12px text-center color-dark'}>Marketing Processes</td>
                                        <td style={{height: 80}} className={bgColor(Inputs.grade_33) + ' font12px text-center color-dark'}>Customer Retention</td>
                                        <td style={{height: 80}} className={bgColor(Inputs.grade_30) + ' font12px text-center color-dark'}>Gross Margins</td>                                  
                                        {/* <td style={{height: 80}} className={'bg-color6'}>Average</td>                                   */}
                                    </tr>

                                    <tr>
                                        <td style={{height: 80}} className={bgColor(Inputs.grade_3) + ' font12px text-center color-dark'}>Products & Services</td>
                                        <td style={{height: 80}} className={bgColor(Inputs.grade_6) + ' font12px text-center color-dark'}>Financial Compounding</td>
                                        <td style={{height: 80}} className='white-bg'></td>
                                        <td style={{height: 80}} className={bgColor(Inputs.grade_11) + ' font12px text-center color-dark'}>Refferals</td>
                                        <td style={{height: 80}} className={bgColor(Inputs.grade_14) + ' font12px text-center color-dark'}>Reduce Attrition</td>
                                        <td style={{height: 80}} className='hide-content' />
                                        <td style={{height: 80}} className='white-bg'></td>
                                        <td style={{height: 80}} className={bgColor(Inputs.grade_20) + ' font12px text-center color-dark'}>Growth Playbook</td>
                                        <td style={{height: 80}} className={bgColor(Inputs.grade_24) + ' font12px text-center color-dark'}>Continuous Adaptation</td>
                                        <td style={{height: 80}} className='white-bg'></td>
                                        <td style={{height: 80}} className={bgColor(Inputs.grade_28) + ' font12px text-center color-dark'}>New Business Optionality</td>
                                        <td style={{height: 80}} className={bgColor(Inputs.grade_37) + ' font12px text-center color-dark'}>Systems and Playbooks</td>
                                        <td style={{height: 80}} className={bgColor(Inputs.grade_34) + ' font12px text-center color-dark'}>Customer Responsiveness</td>
                                        <td style={{height: 80}} className={bgColor(Inputs.grade_31) + ' font12px text-center color-dark'}>Scalability</td>                                
                                        {/* <td style={{height: 80}} className={'color-r'}>Below Average</td>                                   */}
                                    </tr>
                                    <tr>
                                        <td style={{height: 80}} className={bgColor(Inputs.grade_4) + ' font12px text-center color-dark'}>Positioning &amp; Messaging</td>
                                        <td style={{height: 80}} className={bgColor(Inputs.grade_5) + ' font12px text-center color-dark'}>Strategic Alignment</td>
                                        <td style={{height: 80}} className='white-bg'></td>
                                        <td style={{height: 80}} className='hide-content' />
                                        <td style={{height: 80}} className={bgColor(Inputs.grade_15) + ' font12px text-center color-dark'}>Reactivation</td>
                                        <td style={{height: 80}} className='hide-content' />
                                        <td style={{height: 80}} className='white-bg'></td>
                                        <td style={{height: 80}} className={bgColor(Inputs.grade_21) + ' font12px text-center color-dark'}>Growth Culture</td>
                                        <td style={{height: 80}} className={bgColor(Inputs.grade_25) + ' font12px text-center color-dark'}>Continuous Improvement</td>
                                        <td style={{height: 80}} className='white-bg'></td>
                                        <td style={{height: 80}} className='hide-content' />
                                        <td style={{height: 80}} className='hide-content' />
                                        <td style={{height: 80}} className='hide-content' />
                                        <td style={{height: 80}} className='hide-content' />                                
                                        {/* <td className={'color-dr'}>Poor Performance</td>                                   */}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}