import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getCriticalGrowthFactors, modifyCriticalGrowthFactors } from "../../state/features/CriticalGrowthFactorsSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';


export default function CriticalGrowthFactorsContent() {

    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        CriticalGrowthFactorsE12 : '',
        CriticalGrowthFactorsF12 : '',
        CriticalGrowthFactorsG12 : '',
        CriticalGrowthFactorsH12 : '',
        CriticalGrowthFactorsE13 : '',
        CriticalGrowthFactorsF13 : '',
        CriticalGrowthFactorsG13 : '',
        CriticalGrowthFactorsH13 : '',
        CriticalGrowthFactorsE14 : '',
        CriticalGrowthFactorsF14 : '',
        CriticalGrowthFactorsG14 : '',
        CriticalGrowthFactorsH14 : '',
        CriticalGrowthFactorsE15 : '',
        CriticalGrowthFactorsF15 : '',
        CriticalGrowthFactorsG15 : '',
        CriticalGrowthFactorsH15 : '',
        CriticalGrowthFactorsE16 : '',
        CriticalGrowthFactorsF16 : '',
        CriticalGrowthFactorsG16 : '',
        CriticalGrowthFactorsH16 : '',
        CriticalGrowthFactorsE17 : '',
        CriticalGrowthFactorsF17 : '',
        CriticalGrowthFactorsG17 : '',
        CriticalGrowthFactorsH17 : '',
        CriticalGrowthFactorsE18 : '',
        CriticalGrowthFactorsF18 : '',
        CriticalGrowthFactorsG18 : '',
        CriticalGrowthFactorsH18 : '',
        CriticalGrowthFactorsE19 : '',
        CriticalGrowthFactorsF19 : '',
        CriticalGrowthFactorsG19 : '',
        CriticalGrowthFactorsH19 : '',
        CriticalGrowthFactorsE20 : '',
        CriticalGrowthFactorsF20 : '',
        CriticalGrowthFactorsG20 : '',
        CriticalGrowthFactorsH20 : '',
        CriticalGrowthFactorsE21 : '',
        CriticalGrowthFactorsF21 : '',
        CriticalGrowthFactorsG21 : '',
        CriticalGrowthFactorsH21 : '',
        CriticalGrowthFactorsE22 : '',
        CriticalGrowthFactorsF22 : '',
        CriticalGrowthFactorsG22 : '',
        CriticalGrowthFactorsH22 : '',
        CriticalGrowthFactorsE23 : '',
        CriticalGrowthFactorsF23 : '',
        CriticalGrowthFactorsG23 : '',
        CriticalGrowthFactorsH23 : '',
        CriticalGrowthFactorsE24 : '',
        CriticalGrowthFactorsF24 : '',
        CriticalGrowthFactorsG24 : '',
        CriticalGrowthFactorsH24 : '',
        CriticalGrowthFactorsE25 : '',
        CriticalGrowthFactorsF25 : '',
        CriticalGrowthFactorsG25 : '',
        CriticalGrowthFactorsH25 : '',
        CriticalGrowthFactorsE26 : '',
        CriticalGrowthFactorsF26 : '',
        CriticalGrowthFactorsG26 : '',
        CriticalGrowthFactorsH26 : '',
        CriticalGrowthFactorsE27 : '',
        CriticalGrowthFactorsF27 : '',
        CriticalGrowthFactorsG27 : '',
        CriticalGrowthFactorsH27 : '',
        CriticalGrowthFactorsE28 : '',
        CriticalGrowthFactorsF28 : '',
        CriticalGrowthFactorsG28 : '',
        CriticalGrowthFactorsH28 : '',
        CriticalGrowthFactorsE29 : '',
        CriticalGrowthFactorsF29 : '',
        CriticalGrowthFactorsG29 : '',
        CriticalGrowthFactorsH29 : '',
        CriticalGrowthFactorsE30 : '',
        CriticalGrowthFactorsF30 : '',
        CriticalGrowthFactorsG30 : '',
        CriticalGrowthFactorsH30 : '',
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getCriticalGrowthFactors({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.growthPlanning)
            if(response!= undefined  && response.data.growthPlanning){
                let growthPlanning = response.data.growthPlanning[0];
                console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key]
                    }
                }
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyCriticalGrowthFactors({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }
   
    return (
        <div className="content-body">
    <UserDropdown />
    
            {/* row */}
           <div className="container-fluid">
                <div className="head-title">
                    <h2>Critical Growth Factors</h2>	 
                    <p>Critical Grwoth Factors are the core actions that underlie an organization’s ability to outpace the growth of their competition, and must be accomplished at the highest level possible in order to achieve the overall goals and mission of the organization.</p>           					
                </div> 
                <Spinner />
                <div className="card">
                    <div className="card-body pl-0 fivestep">                                            
                    <table className="table table-responsive">
                        <thead>
                        <tr className="thead-primary">
                            <th className="bluetab" rowSpan={2} />
                            <th>Critical Factors</th>
                            <th>Description</th>
                            <th>Importance</th>
                            <th>Urgency</th>
                            <th>Overall Score</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><img src="../images/svg/8.png" /></td>
                            <td className="bgradiusf">What are the critical growth factors?</td>
                            <td className="bgradiusf">Why is it critical and what impact could it have?</td>
                            <td className="bgradiusf">What is the relative importance compared to other critical growth factors?  (1=Low; 10=High)</td>
                            <td className="bgradiusf">How urgent is it that we address?  (1=Low; 10=High)</td>
                            <td className="bgradiusf">Score of 15 or higher indicates top priority critical growth factor.</td>
                        </tr>
                        <tr>
                            <td><img src="../images/svg/8.png" /></td>
                            <td className="bgradiusf">Markets &amp; Customers</td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsE12"
                                    value={Inputs.CriticalGrowthFactorsE12}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsE12: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsF12"
                                    value={Inputs.CriticalGrowthFactorsF12}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsF12: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsG12"
                                    value={Inputs.CriticalGrowthFactorsG12}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsG12: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsH12"
                                    value={Inputs.CriticalGrowthFactorsH12}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsH12: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                        </tr> 
                        <tr>
                            <td><img src="../images/svg/8.png" /></td>
                            <td className="bgradiusf">Value Creation</td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsE13"
                                    value={Inputs.CriticalGrowthFactorsE13}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsE13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsF13"
                                    value={Inputs.CriticalGrowthFactorsF13}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsF13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsG13"
                                    value={Inputs.CriticalGrowthFactorsG13}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsG13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsH13"
                                    value={Inputs.CriticalGrowthFactorsH13}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsH13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                        </tr>                                        
                        <tr>
                            <td><img src="../images/svg/8.png" /></td>
                            <td className="bgradiusf">Innovative Offerings</td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsE14"
                                    value={Inputs.CriticalGrowthFactorsE14}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsE14: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsF14"
                                    value={Inputs.CriticalGrowthFactorsF14}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsF14: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsG14"
                                    value={Inputs.CriticalGrowthFactorsG14}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsG14: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsH14"
                                    value={Inputs.CriticalGrowthFactorsH14}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsH14: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                        </tr>
                        <tr>
                            <td><img src="../images/svg/8.png" /></td>
                            <td className="bgradiusf">Positioning &amp; Messaging</td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsE15"
                                    value={Inputs.CriticalGrowthFactorsE15}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsE15: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsF15"
                                    value={Inputs.CriticalGrowthFactorsF15}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsF15: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsG15"
                                    value={Inputs.CriticalGrowthFactorsG15}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsG15: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsH15"
                                    value={Inputs.CriticalGrowthFactorsH15}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsH15: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                        </tr>
                        <tr>
                            <td><img src="../images/svg/8.png" /></td>
                            <td className="bgradiusf">Back-End Development</td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsE16"
                                    value={Inputs.CriticalGrowthFactorsE16}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsE16: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsF16"
                                    value={Inputs.CriticalGrowthFactorsF16}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsF16: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsG16"
                                    value={Inputs.CriticalGrowthFactorsG16}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsG16: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsH16"
                                    value={Inputs.CriticalGrowthFactorsH16}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsH16: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                        </tr>
                        <tr>
                            <td><img src="../images/svg/8.png" /></td>
                            <td className="bgradiusf">Strategic Alignment</td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsE17"
                                    value={Inputs.CriticalGrowthFactorsE17}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsE17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsF17"
                                    value={Inputs.CriticalGrowthFactorsF17}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsF17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsG17"
                                    value={Inputs.CriticalGrowthFactorsG17}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsG17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsH17"
                                    value={Inputs.CriticalGrowthFactorsH17}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsH17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                        </tr>
                        <tr>
                            <td><img src="../images/svg/8.png" /></td>
                            <td className="bgradiusf">Financial Compounding (comprehensive growth plan)</td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsE18"
                                    value={Inputs.CriticalGrowthFactorsE18}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsE18: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsF18"
                                    value={Inputs.CriticalGrowthFactorsF18}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsF18: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsG18"
                                    value={Inputs.CriticalGrowthFactorsG18}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsG18: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsH18"
                                    value={Inputs.CriticalGrowthFactorsH18}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsH18: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                        </tr>
                        <tr>
                            <td><img src="../images/svg/8.png" /></td>
                            <td className="bgradiusf">Continuous Improvement</td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsE19"
                                    value={Inputs.CriticalGrowthFactorsE19}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsE19: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsF19"
                                    value={Inputs.CriticalGrowthFactorsF19}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsF19: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsG19"
                                    value={Inputs.CriticalGrowthFactorsG19}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsG19: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsH19"
                                    value={Inputs.CriticalGrowthFactorsH19}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsH19: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                        </tr>
                        <tr>
                            <td><img src="../images/svg/8.png" /></td>
                            <td className="bgradiusf">Agility</td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsE20"
                                    value={Inputs.CriticalGrowthFactorsE20}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsE20: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsF20"
                                    value={Inputs.CriticalGrowthFactorsF20}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsF20: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsG20"
                                    value={Inputs.CriticalGrowthFactorsG20}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsG20: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsH20"
                                    value={Inputs.CriticalGrowthFactorsH20}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsH20: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                        </tr>
                        <tr>
                            <td><img src="../images/svg/8.png" /></td>
                            <td className="bgradiusf">Growth Capabilities</td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsE21"
                                    value={Inputs.CriticalGrowthFactorsE21}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsE21: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsF21"
                                    value={Inputs.CriticalGrowthFactorsF21}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsF21: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsG21"
                                    value={Inputs.CriticalGrowthFactorsG21}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsG21: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsH21"
                                    value={Inputs.CriticalGrowthFactorsH21}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsH21: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                        </tr>
                        <tr>
                            <td><img src="../images/svg/8.png" /></td>
                            <td className="bgradiusf">Sales Capabilities</td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsE22"
                                    value={Inputs.CriticalGrowthFactorsE22}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsE22: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsF22"
                                    value={Inputs.CriticalGrowthFactorsF22}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsF22: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsG22"
                                    value={Inputs.CriticalGrowthFactorsG22}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsG22: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsH22"
                                    value={Inputs.CriticalGrowthFactorsH22}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsH22: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                        </tr>
                        <tr>
                            <td><img src="../images/svg/8.png" /></td>
                            <td className="bgradiusf">Marketing Capabilities</td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsE23"
                                    value={Inputs.CriticalGrowthFactorsE23}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsE23: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsF23"
                                    value={Inputs.CriticalGrowthFactorsF23}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsF23: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsG23"
                                    value={Inputs.CriticalGrowthFactorsG23}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsG23: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsH23"
                                    value={Inputs.CriticalGrowthFactorsH23}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsH23: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                        </tr>
                        <tr>
                            <td><img src="../images/svg/8.png" /></td>
                            <td className="bgradiusf">Critical Factor</td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsE24"
                                    value={Inputs.CriticalGrowthFactorsE24}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsE24: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsF24"
                                    value={Inputs.CriticalGrowthFactorsF24}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsF24: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsG24"
                                    value={Inputs.CriticalGrowthFactorsG24}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsG24: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsH24"
                                    value={Inputs.CriticalGrowthFactorsH24}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsH24: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                        </tr>
                        <tr>
                            <td><img src="../images/svg/8.png" /></td>
                            <td className="bgradiusf">Critical Factor</td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsE25"
                                    value={Inputs.CriticalGrowthFactorsE25}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsE25: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsF25"
                                    value={Inputs.CriticalGrowthFactorsF25}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsF25: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsG25"
                                    value={Inputs.CriticalGrowthFactorsG25}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsG25: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsH25"
                                    value={Inputs.CriticalGrowthFactorsH25}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsH25: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                        </tr>
                        <tr>
                            <td><img src="../images/svg/8.png" /></td>
                            <td className="bgradiusf">Critical Factor</td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsE26"
                                    value={Inputs.CriticalGrowthFactorsE26}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsE26: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsF26"
                                    value={Inputs.CriticalGrowthFactorsF26}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsF26: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsG26"
                                    value={Inputs.CriticalGrowthFactorsG26}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsG26: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsH26"
                                    value={Inputs.CriticalGrowthFactorsH26}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsH26: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                        </tr>
                        <tr>
                            <td><img src="../images/svg/8.png" /></td>
                            <td className="bgradiusf">Critical Factor</td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsE27"
                                    value={Inputs.CriticalGrowthFactorsE27}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsE27: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsF27"
                                    value={Inputs.CriticalGrowthFactorsF27}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsF27: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsG27"
                                    value={Inputs.CriticalGrowthFactorsG27}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsG27: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsH27"
                                    value={Inputs.CriticalGrowthFactorsH27}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsH27: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                        </tr>
                        <tr>
                            <td><img src="../images/svg/8.png" /></td>
                            <td className="bgradiusf">Critical Factor</td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsE28"
                                    value={Inputs.CriticalGrowthFactorsE28}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsE28: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsF28"
                                    value={Inputs.CriticalGrowthFactorsF28}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsF28: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsG28"
                                    value={Inputs.CriticalGrowthFactorsG28}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsG28: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsH28"
                                    value={Inputs.CriticalGrowthFactorsH28}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsH28: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                        </tr>
                        <tr>
                            <td><img src="../images/svg/8.png" /></td>
                            <td className="bgradiusf">Critical Factor</td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsE29"
                                    value={Inputs.CriticalGrowthFactorsE29}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsE29: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsF29"
                                    value={Inputs.CriticalGrowthFactorsF29}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsF29: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsG29"
                                    value={Inputs.CriticalGrowthFactorsG29}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsG29: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsH29"
                                    value={Inputs.CriticalGrowthFactorsH29}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsH29: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                        </tr>
                        <tr>
                            <td><img src="../images/svg/8.png" /></td>
                            <td className="bgradiusf">Critical Factor</td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsE30"
                                    value={Inputs.CriticalGrowthFactorsE30}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsE30: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsF30"
                                    value={Inputs.CriticalGrowthFactorsF30}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsF30: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsG30"
                                    value={Inputs.CriticalGrowthFactorsG30}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsG30: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                            <td><input type="text" className="form-control inputText"
                                    id="CriticalGrowthFactorsH30"
                                    value={Inputs.CriticalGrowthFactorsH30}
                                    onChange={(event) => setInputs({ ...Inputs, CriticalGrowthFactorsH30: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} 
                                /></td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
                </div>

        </div>
    )
}
