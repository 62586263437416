import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getWholeBrainMessaging, modifyWholeBrainMessaging } from "../../state/features/WholeBrainMessagingSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import { Link } from 'react-router-dom';

export default function WholeBrainMessagingContentNew() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        WholeBrainMessagingC10 : '',
        WholeBrainMessagingD10 : '',
        WholeBrainMessagingC11 : '',
        WholeBrainMessagingD11 : '',
        WholeBrainMessagingC12 : '',
        WholeBrainMessagingD12 : '',
        WholeBrainMessagingC13 : '',
        WholeBrainMessagingD13 : '',
        WholeBrainMessagingC14 : '',
        WholeBrainMessagingD14 : '',
        WholeBrainMessagingC17 : '',
        WholeBrainMessagingD17 : '',
        WholeBrainMessagingC18 : '',
        WholeBrainMessagingD18 : '',
        WholeBrainMessagingC19 : '',
        WholeBrainMessagingD19 : '',
        WholeBrainMessagingC20 : '',
        WholeBrainMessagingD20 : '',
        WholeBrainMessagingC21 : '',
        WholeBrainMessagingD21 : '',
        WholeBrainMessagingC22 : '',
        WholeBrainMessagingD22 : '',
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getWholeBrainMessaging({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.growthPlanning)
            if(response!= undefined  && response.data.growthPlanning){
                let growthPlanning = response.data.growthPlanning[0];
                // console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key]
                    }
                }
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyWholeBrainMessaging({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                //   console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        //window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
            <div className="container-fluid">
                <div className="head-title mb-4">
                    <h3 className='preheading'>Tools - Messaging</h3>
                    <h1 className='mainheading'>Whole Brain Messaging</h1>
                </div>
                <div className="opportunity-page-table">
                    <div className="opportunity-page-table-inner opportunity-abl">
                        <Spinner/>
                        <div className="table-responsive mb-3" style={{maxWidth: 1200}}>
                            <table className="table responsive-table mb-0" width="100%">
                                <thead>
                                    <tr>
                                        <th className='black-bg text-white' style={{width: 600}} colspan="2">
                                            <div className="before-your px-3">
                                                <img src={window.location.origin+"/images/whole-brain/image1235.png"} />
                                                <p className='w-100 padding-0 font20px'>Analytical  (what)</p>
                                            </div>
                                        </th>
                                        <th className='black-bg text-white' style={{width: 600}} colspan="2">
                                            <div className="before-your px-3">
                                                <img src={window.location.origin+"/images/whole-brain/image1247.png"} />
                                                <p className='w-100 padding-0 font20px'>Experimental  (why)</p>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="bg-color-blue-gray color-black" style={{width: 600}} colspan="2">
                                            <ul className='font11px text-white'>
                                                <li><strong>Values</strong> facts, ROI, logic, and critical analysis.</li>
                                                <li><strong>Communicate</strong> well-articulated ideas and accurate data with brevity.</li>
                                                <li><strong>Include</strong> performance goals, objectives and measures, ROI statements and calculators, data and research to support cost benefit analysis.</li>
                                            </ul>
                                        </td>
                                        <td className="bg-color-blue-gray color-black" style={{width: 600}} colspan="2">
                                            <ul className='font11px text-white'>
                                                <li><strong>Values</strong> the big picture, a holistic view, innovative solutions, breakthrough results, flexibility, and the future.</li>
                                                <li><strong>Communicate</strong> by exploring ideas, using metaphors and visuals, conceptual frameworks, and strategic fit.</li>
                                                <li><strong>Include</strong> stories, process visuals, and a successful future outcome.</li>
                                            </ul>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="bg-color-light-blue" style={{width: 200}}>
                                            <div className='font11px color-dark'>ROI:</div>
                                        </td>
                                        <td className="bg-color-light-blue" style={{width: 400}}>
                                            <div className="input-fiel-cn d-flex justify-content-between align-items-center color-dark">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark w-100"
                                                    id="WholeBrainMessagingC10"
                                                    value={Inputs.WholeBrainMessagingC10}
                                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingC10: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-light-blue" style={{width: 200}}>
                                            <div className='font11px color-dark'>Big Picture:</div>
                                        </td>
                                        <td className="bg-color-light-blue" style={{width: 400}}>
                                            <div className="input-fiel-cn d-flex justify-content-between align-items-center color-dark">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark w-100"
                                                    id="WholeBrainMessagingD10"
                                                    value={Inputs.WholeBrainMessagingD10}
                                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingD10: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color-light-blue">
                                            <div className='font11px color-dark'>Performance:</div>
                                        </td>
                                        <td className="bg-color-light-blue">
                                            <div className="input-fiel-cn d-flex justify-content-between align-items-center color-dark">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark w-100"
                                                    id="WholeBrainMessagingC11"
                                                    value={Inputs.WholeBrainMessagingC11}
                                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingC11: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-light-blue">
                                            <div className='font11px color-dark'>Innovative:</div>
                                        </td>
                                        <td className="bg-color-light-blue">
                                            <div className="input-fiel-cn d-flex justify-content-between align-items-center color-dark">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark w-100"
                                                    id="WholeBrainMessagingD11"
                                                    value={Inputs.WholeBrainMessagingD11}
                                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingD11: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color-light-blue">
                                            <div className='font11px color-dark'>Objectives:</div>
                                        </td>
                                        <td className="bg-color-light-blue">
                                            <div className="input-fiel-cn d-flex justify-content-between align-items-center color-dark">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark w-100"
                                                    id="WholeBrainMessagingC12"
                                                    value={Inputs.WholeBrainMessagingC12}
                                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingC12: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-light-blue">
                                            <div className='font11px color-dark'>Flexible:</div>
                                        </td>
                                        <td className="bg-color-light-blue">
                                            <div className="input-fiel-cn d-flex justify-content-between align-items-center color-dark">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark w-100"
                                                    id="WholeBrainMessagingD12"
                                                    value={Inputs.WholeBrainMessagingD12}
                                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingD12: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color-light-blue">
                                            <div className='font11px color-dark'>Measures:</div>
                                        </td>
                                        <td className="bg-color-light-blue">
                                            <div className="input-fiel-cn d-flex justify-content-between align-items-center color-dark">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark w-100"
                                                    id="WholeBrainMessagingC13"
                                                    value={Inputs.WholeBrainMessagingC13}
                                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingC13: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-light-blue">
                                            <div className='font11px color-dark'>Future:</div>
                                        </td>
                                        <td className="bg-color-light-blue">
                                            <div className="input-fiel-cn d-flex justify-content-between align-items-center color-dark">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark w-100"
                                                    id="WholeBrainMessagingD13"
                                                    value={Inputs.WholeBrainMessagingD13}
                                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingD13: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color-light-blue">
                                            <div className='font11px color-dark'>Supporting Data:</div>
                                        </td>
                                        <td className="bg-color-light-blue">
                                            <div className="input-fiel-cn d-flex justify-content-between align-items-center color-dark">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark w-100"
                                                    id="WholeBrainMessagingC14"
                                                    value={Inputs.WholeBrainMessagingC14}
                                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingC14: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-light-blue">
                                            <div className='font11px color-dark'>Strategic Fit:</div>
                                        </td>
                                        <td className="bg-color-light-blue">
                                            <div className="input-fiel-cn d-flex justify-content-between align-items-center color-dark">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark w-100"
                                                    id="WholeBrainMessagingD14"
                                                    value={Inputs.WholeBrainMessagingD14}
                                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingD14: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <thead>
                                    <tr>
                                        <th className='black-bg text-white' style={{width: 600}} colspan="2">
                                            <div className="before-your px-3">
                                                <img src={window.location.origin+"/images/whole-brain/image1237.png"} />
                                                <p className='w-100 padding-0 font20px'>Practical  (how &amp; when)</p>
                                            </div>
                                        </th>
                                        <th className='black-bg text-white' style={{width: 600}} colspan="2">
                                            <div className="before-your px-3">
                                                <img src={window.location.origin+"/images/whole-brain/image1251.png"} />
                                                <p className='w-100 padding-0 font20px'>Relational  (who)</p>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="bg-color-blue-gray color-black" style={{width: 600}} colspan="2">
                                            <ul className='font11px text-white'>
                                                <li><strong>Values</strong> proven processes, safe choices, reduced risk, and procedures.</li>
                                                <li><strong>Communicate</strong> references and case studies, step-by-step sequences, and contingency planning.</li>
                                                <li><strong>Include</strong> an organized structure and plans and details.  Also agendas, timelines, steps, discuss risks and contingency plans, proposed action plans, and resource requirements.</li>
                                            </ul>
                                        </td>
                                        <td className="bg-color-blue-gray color-black" style={{width: 600}} colspan="2">
                                            <ul className='font11px text-white'>
                                                <li><strong>Values</strong> benefits to people (customers, employees, their teams), how they will feel, and everyone having a chance to contribute.</li>
                                                <li><strong>Communicate</strong> openly and informally, find common ground, use expressive body and voice, and listen intently.</li>
                                                <li><strong>Include</strong> details about the relationship, shared values, and use partnering language (us, we, our, them).</li>
                                            </ul>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="bg-color-light-blue" style={{width: 200}}>
                                            <div className='font11px color-dark'>Proven Processes:</div>
                                        </td>
                                        <td className="bg-color-light-blue" style={{width: 400}}>
                                            <div className="input-fiel-cn d-flex justify-content-between align-items-center color-dark">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark w-100"
                                                    id="WholeBrainMessagingC17"
                                                    value={Inputs.WholeBrainMessagingC17}
                                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingC17: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-light-blue" style={{width: 200}}>
                                            <div className='font11px color-dark'>Benefits to Customers:</div>
                                        </td>
                                        <td className="bg-color-light-blue" style={{width: 400}}>
                                            <div className="input-fiel-cn d-flex justify-content-between align-items-center color-dark">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark w-100"
                                                    id="WholeBrainMessagingD17"
                                                    value={Inputs.WholeBrainMessagingD17}
                                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingD17: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="bg-color-light-blue">
                                            <div className='font11px color-dark'>Reduced Risk:</div>
                                        </td>
                                        <td className="bg-color-light-blue">
                                            <div className="input-fiel-cn d-flex justify-content-between align-items-center color-dark">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark w-100"
                                                    id="WholeBrainMessagingC18"
                                                    value={Inputs.WholeBrainMessagingC18}
                                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingC18: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-light-blue">
                                            <div className='font11px color-dark'>Benefits to Employees:</div>
                                        </td>
                                        <td className="bg-color-light-blue">
                                            <div className="input-fiel-cn d-flex justify-content-between align-items-center color-dark">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark w-100"
                                                    id="WholeBrainMessagingD18"
                                                    value={Inputs.WholeBrainMessagingD18}
                                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingD18: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color-light-blue">
                                            <div className='font11px color-dark'>Case Studies:</div>
                                        </td>
                                        <td className="bg-color-light-blue">
                                            <div className="input-fiel-cn d-flex justify-content-between align-items-center color-dark">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark w-100"
                                                    id="WholeBrainMessagingC19"
                                                    value={Inputs.WholeBrainMessagingC19}
                                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingC19: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-light-blue">
                                            <div className='font11px color-dark'>Benefit to Teams:</div>
                                        </td>
                                        <td className="bg-color-light-blue">
                                            <div className="input-fiel-cn d-flex justify-content-between align-items-center color-dark">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark w-100"
                                                    id="WholeBrainMessagingD19"
                                                    value={Inputs.WholeBrainMessagingD19}
                                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingD19: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color-light-blue">
                                            <div className='font11px color-dark'>Sequences &amp; Timeline:</div>
                                        </td>
                                        <td className="bg-color-light-blue">
                                            <div className="input-fiel-cn d-flex justify-content-between align-items-center color-dark">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark w-100"
                                                    id="WholeBrainMessagingC20"
                                                    value={Inputs.WholeBrainMessagingC20}
                                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingC20: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-light-blue">
                                            <div className='font11px color-dark'>Everyone Contributes:</div>
                                        </td>
                                        <td className="bg-color-light-blue">
                                            <div className="input-fiel-cn d-flex justify-content-between align-items-center color-dark">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark w-100"
                                                    id="WholeBrainMessagingD20"
                                                    value={Inputs.WholeBrainMessagingD20}
                                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingD20: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    {/* <tr>
                                        <td className="bg-color-light-blue">
                                            <div className="input-fiel-cn d-flex justify-content-between align-items-center color-dark">
                                                <div className='font11px color-dark'>Contingency Planning:</div>
                                                <textarea className="field-ipt bg-color-02 font11px color-dark w-100"
                                                    id="WholeBrainMessagingC21"
                                                    value={Inputs.WholeBrainMessagingC21}
                                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingC21: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-light-blue">
                                            <div className="input-fiel-cn d-flex justify-content-between align-items-center color-dark">
                                                <div className='font11px color-dark'>Promotes Open Communications:</div>
                                                <textarea className="field-ipt bg-color-02 font11px color-dark w-100"
                                                    id="WholeBrainMessagingD21"
                                                    value={Inputs.WholeBrainMessagingD21}
                                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingD21: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                    </tr> */}
                                    <tr>
                                        <td className="bg-color-light-blue">
                                            <div className='font11px color-dark'>Resource Requirements:</div>
                                        </td>
                                        <td className="bg-color-light-blue">
                                            <div className="input-fiel-cn d-flex justify-content-between align-items-center color-dark">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark w-100"
                                                    id="WholeBrainMessagingC22"
                                                    value={Inputs.WholeBrainMessagingC22}
                                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingC22: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-light-blue">
                                            <div className='font11px color-dark'>Promotes Shared Values:</div>
                                        </td>
                                        <td className="bg-color-light-blue">
                                            <div className="input-fiel-cn d-flex justify-content-between align-items-center color-dark">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark w-100"
                                                    id="WholeBrainMessagingD22"
                                                    value={Inputs.WholeBrainMessagingD22}
                                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingD22: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="nextInput">
                            <ul>
                                <li>
                                    <Link to="/tools/case-for-change" className='next-link'> Next:  Case For Change  
                                        <span className='icon-three'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                        </span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}