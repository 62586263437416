import './App.css';
import { useSelector } from "react-redux";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import ProtectedRoute from "./Routes/ProtectedRoute";
import PrivateRoute from "./Routes/PrivateRoute";

import Main from './pages/Main';
import Dashboard from './pages/Dashboard';
import Conversion from './pages/Conversion';
import ClientInputs from './pages/ClientInputs';
import BuyingLfetime from './pages/BuyingLfetime';
import AverageTransactionValue from './pages/AverageTransactionValue';
import StateOfBusiness from './pages/StateOfBusiness';
import RevanueGrowthDrivers from './pages/RevanueGrowthDrivers';
import LeadGeneration from './pages/LeadGeneration';
import Referrals from './pages/Referrals';
import PurchaseFrequency from './pages/PurchaseFrequency';
import CustomerAttrition from './pages/CustomerAttrition';
import CustomerReactivation from './pages/CustomerReactivation';
import ProfitMargin from './pages/ProfitMargin';
import The120GrowthBenchmark from './pages/The120GrowthBenchmark';
import GrowthPlanning from './pages/GrowthPlanning';
import CompetitiveAdvantage from './pages/CompetitiveAdvantage';
import FinancialPerformance from './pages/FinancialPerformance';
import CustomerAcquisition from './pages/CustomerAcquisition';
import CustomerRetention from './pages/CustomerRetention';
import CustomerTransactions from './pages/CustomerTransactions';
import Ability from './pages/Ability';
import Agility from './pages/Agility';
import LongTermGrowth from './pages/LongTermGrowth';
import TenFiveThreeOnePlanning from './pages/TenFiveThreeOnePlanning';
import FiveStepStrategy from './pages/FiveStepStrategy';
import BrandScorecard from './pages/BrandScorecard';
import AnnualGrowthPlan from './pages/AnnualGrowthPlan';
import MarketingPlan from './pages/MarketingPlan';
import SalesElevationPlan from './pages/SalesElevationPlan';
import ExecutiveSummary from './pages/ExecutiveSummary';
import ImproveYourScore from './pages/ImproveYourScore';
import GrowthDriverPerformanceOverview from './pages/GrowthDriverPerformanceOverview';
import BenchmarkScoreGapAnalysis from './pages/BenchmarkScoreGapAnalysis';
import CustomerFinancialValue from './pages/CustomerFinancialValue';
import ImpactOfThe120Growth from './pages/ImpactOfThe120Growth';
import CreatingGrowthMomentum from './pages/CreatingGrowthMomentum';
import EquityEvolutionTheBigBonus from './pages/EquityEvolutionTheBigBonus';
import TheCompoundingEffect from './pages/TheCompoundingEffect';
import WhatsDrivingGrowth from './pages/WhatsDrivingGrowth';
import CustomerCommunicationPlan from './pages/CustomerCommunicationPlan';
import PyramidOfPurpose from './pages/PyramidOfPurpose';
import GrowthProgressReport from './pages/GrowthProgressReport';
import QuarterlyAndMonthlyPlanning from './pages/QuarterlyAndMonthlyPlanning';
import StrategicFoundation from './pages/StrategicFoundation';
import CriticalGrowthFactors from './pages/CriticalGrowthFactors';
import StrategyAndInnovationMap from './pages/StrategyAndInnovationMap';
import StrategyUncertaintyMap from './pages/StrategyUncertaintyMap';
import BreakthroughStrategy from './pages/BreakthroughStrategy';
import TheBigSWOT from './pages/TheBigSWOT';
import CustomerPersonas from './pages/CustomerPersonas';
import ValuesMissionGoals from './pages/ValuesMissionGoals';
import GoToMarketStrategy from './pages/GoToMarketStrategy';
import CompetitiveAnalysis from './pages/CompetitiveAnalysis';
import CompetitiveAdvantageAudit from './pages/CompetitiveAdvantageAudit';
import VRIOFramework from './pages/VRIOFramework';
import DifferentiationDiagnostic from './pages/DifferentiationDiagnostic';
import ADLMatrix from './pages/ADLMatrix';
import PositioningMatrix from './pages/PositioningMatrix';
import FiveForcesFramework from './pages/FiveForcesFramework';
import InnovativeOfferingAnalysis from './pages/InnovativeOfferingAnalysis';
import FourForcesOfValue from './pages/FourForcesOfValue';
import MessageSelectionMatrix from './pages/MessageSelectionMatrix';
import WholeBrainMessaging from './pages/WholeBrainMessaging';
import InsightStoryboardTemplate from './pages/InsightStoryboardTemplate';
import CustomerEmpathyMap from './pages/CustomerEmpathyMap';
import SCQAFramework from './pages/SCQAFramework';
import MintoPyramid from './pages/MintoPyramid';

import Impact120GrowthROI from './pages/Impact120GrowthROI';
import Auth from './pages/Auth';
import Logout from './pages/Logout';
import Profile from './pages/Profile';
import ChangePassword from "./pages/ChangePassword";
import RevenueGrowthTools from './pages/RevenueGrowthTools';
import GrowthOptionScorecard from './pages/GrowthOptionScorecard';
import WhatsWorkingNow from './pages/WhatsWorkingNow';
import FindTheLowHangingFruit from './pages/FindTheLowHangingFruit';
import CustomerTransformationModel from './pages/CustomerTransformationModel';
import ReferralStrategyWorksheet from './pages/ReferralStrategyWorksheet';
import OpportunityRankingAnalysis from './pages/OpportunityRankingAnalysis';
import ProjectPrioritizationMatrix from './pages/ProjectPrioritizationMatrix';
import SynergyScoringWorksheet from './pages/SynergyScoringWorksheet';
import AllianceEvaluationWorksheet from './pages/AllianceEvaluationWorksheet';
import SalesCallObjectives from './pages/SalesCallObjectives';
import AccountPenetrationMatrix from './pages/AccountPenetrationMatrix';
import ObjectionResolutionWorksheet from './pages/ObjectionResolutionWorksheet';
import TargetAccountListPrioritization from './pages/TargetAccountListPrioritization';
import TargetAccountPlanning from './pages/TargetAccountPlanning';
import ValueFirstStrategyDevelopment from './pages/ValueFirstStrategyDevelopment';
import InnovationConceptDevelopment from './pages/InnovationConceptDevelopment';
import SCAMPERIdeation from './pages/SCAMPERIdeation';
import PAINstorming from './pages/PAINstorming';
import OutcomeDrivenInnovation from './pages/OutcomeDrivenInnovation';
import SixThinkingHats from './pages/SixThinkingHats';
import AgileInnovation from './pages/AgileInnovation';
import InnovationRankingMatrix from './pages/InnovationRankingMatrix';
import JobsToBeDone from './pages/JobsToBeDone';
import InsightsToActionToImpactWorksheet from './pages/InsightsToActionToImpactWorksheet';
import DisruptiveInnovation from './pages/DisruptiveInnovation';
import StepCaseForChange from './pages/StepCaseForChange';
import OptimizationAccelerator from './pages/OptimizationAccelerator';
import CustomerRetentionloop from './pages/CustomerRetentionloop';
import NetPromoterScore from './pages/NetPromoterScore';
import CustomerJourneyMapping from './pages/CustomerJourneyMapping';
import VoiceOfTheCustomerTable from './pages/VoiceOfTheCustomerTable';
import InnovateOrOptimize from './pages/InnovateOrOptimize';
import StateUpdateRoute from './Routes/StateUpdateRoute';
import ChangeStateFlagRoute from './Routes/ChangeStateFlagRoute';
import GrowthPotentialOverview from './pages/GrowthPotentialOverview';
import ReturnBackRoute from './Routes/ReturnBackRoute';
import Diagnose from './pages/Diagnose';
import Home from './pages/Home';
import Prescribe from './pages/Prescribe';
import Grow from './pages/Grow';
import StateOfTheBusiness from './pages/StateOfTheBusiness';
import YourGrowthPotential from './pages/YourGrowthPotential';
import Tools from './pages/Tools';
import HowItWorks from './pages/HowItWorks';
import PreBenchmarkSurvey from './pages/PreBenchmarkSurvey';
import { SWRConfig } from 'swr';
import { SettingsSWR } from './utils/SettingsSWR'
import ZeroInOnValueContent from './pages/ZeroInOnValueContent';
import CustomerTransformation from './pages/CustomerTransformation';
import DifferentiationDiagnosticNew from './pages/DifferentiationDiagnosticNew';
import CriticalGrowthFactorsNew from './pages/CriticalGrowthFactorsNew';
import MessageSelectionMatrixNew from './pages/MessageSelectionMatrixNew';
import GrowthOptionScorecardNew from './pages/GrowthOptionScorecardNew';
import WholeBrainMessagingNew from './pages/WholeBrainMessagingNew';
import CaseForChange from './pages/CaseForChange';
import FiveStepStrategyNew from './pages/FiveStepStrategyNew';
import BrandScorecardNew from './pages/BrandScorecardNew';
import TheBigSWOTNew from './pages/TheBigSWOTNew';
import CompetitiveAnalysisNew from './pages/CompetitiveAnalysisNew';
import ReverseEngineeringTheFuture from './pages/ReverseEngineeringTheFuture';
import FocusedMeetingPlanner from './pages/FocusedMeetingPlanner';
import InnovateOrOptimizeNew from './pages/InnovateOrOptimizeNew';
import GrowthProgressReportNew from './pages/GrowthProgressReportNew';
import WeightedDecisionMatrixNew from './pages/WeightedDecisionMatrixNew';
import TargetMarketsScorecard from './pages/TargetMarketsScorecard';
import EquityEvolutionScorecard from './pages/EquityEvolutionScorecard';
import ProfitMarginScorecard from './pages/ProfitMarginScorecard';
import CompetitiveAdvantageAuditNew from './pages/CompetitiveAdvantageAuditNew';
import InnovativeOfferingAnalysisNew from './pages/InnovativeOfferingAnalysisNew';
import PositioningScorecardNew from './pages/PositioningScorecardNew';
import CustomerDataScorecardNew from './pages/CustomerDataScorecardNew';
import CustomerAcquisitionScorecardNew from './pages/CustomerAcquisitionScorecardNew';
import CustomerRetentionScorecardNew from './pages/CustomerRetentionScorecardNew';
import AgilityEngineScorecardNew from './pages/AgilityEngineScorecardNew';
import AlignmentAssessmentNew from './pages/AlignmentAssessmentNew';
import GrowthDriverPerformanceNew from './pages/GrowthDriverPerformanceNew';
import ReturnOnInvestmentNew from './pages/ReturnOnInvestmentNew';
import TotalFinancialImpactNew from './pages/TotalFinancialImpactNew';
import GrowthCapabilitiesGapAnalysis from './pages/GrowthCapabilitiesGapAnalysis';
import RevenueGenerationScorecard from './pages/RevenueGenerationScorecard';
import ReferralGenerationScorecard from './pages/ReferralGenerationScorecard';
import ManageBranding from './pages/ManageBranding';
import PageNotFound from './pages/PageNotFound';
import AccessDenied from './pages/AccessDenied';


function App() {
  const updateStateFlag = useSelector(state => state.auth.updateStateFlag);
  
  return (
    <SWRConfig 
      value={{ fallback : SettingsSWR() }}
    >

    <BrowserRouter>
      <Routes>
        <Route path="/profile" element={<PrivateRoute><ChangeStateFlagRoute><Profile /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/manage-branding" element={<PrivateRoute><ChangeStateFlagRoute><ManageBranding /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/change-password" element={<PrivateRoute><ChangeStateFlagRoute><ChangePassword /></ChangeStateFlagRoute></PrivateRoute>} />

      {/* New routes start here */}
        <Route path="/diagnose" element={<PrivateRoute><ChangeStateFlagRoute><Diagnose /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/prescribe" element={<PrivateRoute><ChangeStateFlagRoute><Prescribe /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/grow" element={<PrivateRoute><ChangeStateFlagRoute><Grow /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/state-of-the-business" element={<PrivateRoute><ChangeStateFlagRoute><StateOfTheBusiness /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/your-growth-potential" element={<PrivateRoute><ChangeStateFlagRoute><YourGrowthPotential /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/tools" element={<PrivateRoute><ChangeStateFlagRoute><Tools /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/tools/zero-in-on-value" element={<PrivateRoute><ZeroInOnValueContent/></PrivateRoute>} />
        <Route path="/tools/customer-transformation" element={<PrivateRoute><CustomerTransformation/></PrivateRoute>} />
        <Route path="/tools/differentiation-diagnostic" element={<PrivateRoute><DifferentiationDiagnosticNew /></PrivateRoute>} />
        <Route path="/tools/growth-option-scorecard" element={<PrivateRoute><GrowthOptionScorecardNew/></PrivateRoute>} />
        <Route path="/tools/critical-growth-factors" element={<PrivateRoute><CriticalGrowthFactorsNew /></PrivateRoute>} />
        <Route path="/tools/message-selection-matrix" element={<PrivateRoute><MessageSelectionMatrixNew /></PrivateRoute>} />
        <Route path="/tools/whole-brain-messaging" element={<PrivateRoute><WholeBrainMessagingNew /></PrivateRoute>} />
        <Route path="/tools/case-for-change" element={<PrivateRoute><CaseForChange /></PrivateRoute>} />
        <Route path="/tools/five-step-strategy" element={<PrivateRoute><FiveStepStrategyNew /></PrivateRoute>} />
        <Route path="/tools/brand-scorecard" element={<PrivateRoute><BrandScorecardNew /></PrivateRoute>} />
        <Route path="/tools/the-big-swot" element={<PrivateRoute><TheBigSWOTNew /></PrivateRoute>} />
        <Route path="/tools/competitive-analysis" element={<PrivateRoute><CompetitiveAnalysisNew /></PrivateRoute>} />
        <Route path="/how-it-works" element={<PrivateRoute><ChangeStateFlagRoute><HowItWorks /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/diagnose/pre-benchmark-survey" element={<PrivateRoute><PreBenchmarkSurvey /></PrivateRoute>} />
        
        <Route path="/grow/growth-planning/quarterly-and-monthly-planning" element={<PrivateRoute><QuarterlyAndMonthlyPlanning /></PrivateRoute>} />
        <Route path="/grow/growth-planning/reverse-engineering-the-future" element={<PrivateRoute><ReverseEngineeringTheFuture /></PrivateRoute>} />
        <Route path="/grow/growth-planning/focused-meeting-planner" element={<PrivateRoute><FocusedMeetingPlanner /></PrivateRoute>} />
        <Route path="/grow/innovation-tools/innovate-or-optimize" element={<PrivateRoute><InnovateOrOptimizeNew/></PrivateRoute>} />
        <Route path="/grow/innovation-tools/growth-progress-report" element={<PrivateRoute><GrowthProgressReportNew/></PrivateRoute>} />
        
        <Route path="/diagnose/target-markets-scorecard" element={<PrivateRoute><TargetMarketsScorecard/></PrivateRoute>} />
        <Route path="/diagnose/equity-evolution-scorecard" element={<PrivateRoute><EquityEvolutionScorecard/></PrivateRoute>} />
        <Route path="/diagnose/profit-margin-scorecard" element={<PrivateRoute><ProfitMarginScorecard/></PrivateRoute>} />
        <Route path="/diagnose/competitive-advantage-audit" element={<PrivateRoute><CompetitiveAdvantageAuditNew/></PrivateRoute>} />
        <Route path="/diagnose/innovative-offering-analysis" element={<PrivateRoute><InnovativeOfferingAnalysisNew/></PrivateRoute>} />
        <Route path="/diagnose/positioning-scorecard" element={<PrivateRoute><PositioningScorecardNew/></PrivateRoute>} />
        <Route path="/diagnose/customer-data-scorecard" element={<PrivateRoute><CustomerDataScorecardNew/></PrivateRoute>} />
        <Route path="/diagnose/referral-generation-scorecard" element={<PrivateRoute><ReferralGenerationScorecard/></PrivateRoute>} />
        <Route path="/diagnose/customer-acquisition-scorecard" element={<PrivateRoute><CustomerAcquisitionScorecardNew/></PrivateRoute>} />
        <Route path="/diagnose/customer-retention-scorecard" element={<PrivateRoute><CustomerRetentionScorecardNew/></PrivateRoute>} />
        <Route path="/diagnose/agility-engine-scorecard" element={<PrivateRoute><AgilityEngineScorecardNew/></PrivateRoute>} />
        <Route path="/diagnose/alignment-assessment" element={<PrivateRoute><AlignmentAssessmentNew/></PrivateRoute>} />
        <Route path="/diagnose/revenue-generation-scorecard" element={<PrivateRoute><RevenueGenerationScorecard/></PrivateRoute>} />

        <Route path="/state-of-the-business/growth-driver-performance" element={<PrivateRoute><GrowthDriverPerformanceNew/></PrivateRoute>} />

        <Route path="/your-growth-potential/return-on-investment" element={<PrivateRoute><ReturnOnInvestmentNew/></PrivateRoute>} />
        <Route path="/your-growth-potential/total-financial-impact" element={<PrivateRoute><TotalFinancialImpactNew /></PrivateRoute>} />

        <Route path="/prescribe/weighted-decision-matrix" element={<PrivateRoute><WeightedDecisionMatrixNew/></PrivateRoute>} />

        <Route path="/state-of-the-business/growth-capabilities-gap-analysis" element={<PrivateRoute><GrowthCapabilitiesGapAnalysis /></PrivateRoute>} />
      {/* New routes end here */}

      { updateStateFlag ? 
        <Route path="/home" element={<PrivateRoute><Home /></PrivateRoute>} />
      :
        <Route path="/home" element={<PrivateRoute><StateUpdateRoute><Home /></StateUpdateRoute></PrivateRoute>} />
      }

      { updateStateFlag ? 
        <Route path="/contents" element={<PrivateRoute><Main /></PrivateRoute>} />
      :
        <Route path="/contents" element={<PrivateRoute><StateUpdateRoute><Main /></StateUpdateRoute></PrivateRoute>} />
      }
        <Route path="/contents/executive-summary" element={<PrivateRoute><ChangeStateFlagRoute><ExecutiveSummary /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/contents/improve-your-score" element={<PrivateRoute><ChangeStateFlagRoute><ImproveYourScore /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/diagnose/client-inputs" element={<PrivateRoute><ChangeStateFlagRoute><ClientInputs /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/diagnose/customer-financial-value" element={<PrivateRoute><ChangeStateFlagRoute><CustomerFinancialValue /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/your-growth-potential/growth-potential-overview" element={<PrivateRoute><ChangeStateFlagRoute><GrowthPotentialOverview /></ChangeStateFlagRoute></PrivateRoute>} />
        {/* <Route path="/your-growth-potential/total-financial-impact" element={<PrivateRoute><ChangeStateFlagRoute><ImpactOfThe120Growth /></ChangeStateFlagRoute></PrivateRoute>} /> */}
        <Route path="/your-growth-potential/creating-growth-momentum" element={<PrivateRoute><ChangeStateFlagRoute><CreatingGrowthMomentum /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/your-growth-potential/equity-evolution" element={<PrivateRoute><ChangeStateFlagRoute><EquityEvolutionTheBigBonus /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/prescribe/the-compounding-effect" element={<PrivateRoute><ChangeStateFlagRoute><TheCompoundingEffect /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/your-growth-potential/what-is-driving-growth" element={<PrivateRoute><ChangeStateFlagRoute><WhatsDrivingGrowth /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/your-growth-potential/impact-120-growth-roi" element={<PrivateRoute><ChangeStateFlagRoute><Impact120GrowthROI /></ChangeStateFlagRoute></PrivateRoute>} />

        <Route path="/state-of-business" element={<PrivateRoute><ChangeStateFlagRoute><StateOfBusiness /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/state-of-the-business/benchmark-scorecard" element={<PrivateRoute><ChangeStateFlagRoute><Dashboard /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/state-of-the-business/growth-driver-performance-overview" element={<PrivateRoute><ChangeStateFlagRoute><GrowthDriverPerformanceOverview /></ChangeStateFlagRoute></PrivateRoute>} />
        {/* <Route path="/state-of-the-business/growth-capabilities-gap-analysis" element={<PrivateRoute><ChangeStateFlagRoute><BenchmarkScoreGapAnalysis /></ChangeStateFlagRoute></PrivateRoute>} /> */}

        <Route path="/revenue-growth-drivers" element={<PrivateRoute><ChangeStateFlagRoute><RevanueGrowthDrivers /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/prescribe/lead-generation" element={<PrivateRoute><ChangeStateFlagRoute><LeadGeneration /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/prescribe/conversion" element={<PrivateRoute><ChangeStateFlagRoute><Conversion /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/prescribe/referrals" element={<PrivateRoute><ChangeStateFlagRoute><Referrals /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/prescribe/purchase-frequency" element={<PrivateRoute><ChangeStateFlagRoute><PurchaseFrequency /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/prescribe/buying-lifetime" element={<PrivateRoute><ChangeStateFlagRoute><BuyingLfetime /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/prescribe/average-transaction-value" element={<PrivateRoute><ChangeStateFlagRoute><AverageTransactionValue /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/prescribe/customer-attrition" element={<PrivateRoute><ChangeStateFlagRoute><CustomerAttrition /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/prescribe/customer-reactivation" element={<PrivateRoute><ChangeStateFlagRoute><CustomerReactivation /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/prescribe/profit-margin" element={<PrivateRoute><ChangeStateFlagRoute><ProfitMargin /></ChangeStateFlagRoute></PrivateRoute>} />

        <Route path="/120-growth-benchmark" element={<PrivateRoute><ChangeStateFlagRoute><The120GrowthBenchmark /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/diagnose/competitive-advantage" element={<PrivateRoute><ChangeStateFlagRoute><CompetitiveAdvantage /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/diagnose/financial-performance" element={<PrivateRoute><ChangeStateFlagRoute><FinancialPerformance /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/diagnose/customer-acquisition" element={<PrivateRoute><ChangeStateFlagRoute><CustomerAcquisition /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/diagnose/customer-retention" element={<PrivateRoute><ChangeStateFlagRoute><CustomerRetention /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/diagnose/customer-transactions" element={<PrivateRoute><ChangeStateFlagRoute><CustomerTransactions /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/120-growth-benchmark/ability" element={<PrivateRoute><ChangeStateFlagRoute><Ability /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/diagnose/agility" element={<PrivateRoute><ChangeStateFlagRoute><Agility /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/diagnose/enterprise-value" element={<PrivateRoute><ChangeStateFlagRoute><LongTermGrowth /></ChangeStateFlagRoute></PrivateRoute>} />

        <Route path="/growth-planning" element={<PrivateRoute><ChangeStateFlagRoute><GrowthPlanning /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/growth-planning/10-5-3-1-Planning" element={<PrivateRoute><ChangeStateFlagRoute><TenFiveThreeOnePlanning /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/growth-planning/five-step-strategy" element={<PrivateRoute><ChangeStateFlagRoute><FiveStepStrategy /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/growth-planning/brand-scorecard" element={<PrivateRoute><ChangeStateFlagRoute><BrandScorecard /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/growth-planning/annual-growth-plan" element={<PrivateRoute><ChangeStateFlagRoute><AnnualGrowthPlan /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/growth-planning/marketing-plan" element={<PrivateRoute><ChangeStateFlagRoute><MarketingPlan /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/growth-planning/sales-elevation-plan" element={<PrivateRoute><ChangeStateFlagRoute><SalesElevationPlan /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/growth-planning/customer-communication-plan" element={<PrivateRoute><ChangeStateFlagRoute><CustomerCommunicationPlan /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/growth-planning/pyramid-of-purpose" element={<PrivateRoute><ChangeStateFlagRoute><PyramidOfPurpose /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/growth-planning/growth-progress-report" element={<PrivateRoute><ChangeStateFlagRoute><GrowthProgressReport /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/growth-planning/whats-working-now" element={<PrivateRoute><ChangeStateFlagRoute><WhatsWorkingNow /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/growth-planning/quarterly-and-monthly-planning" element={<PrivateRoute><ChangeStateFlagRoute><QuarterlyAndMonthlyPlanning /></ChangeStateFlagRoute></PrivateRoute>} />
        {/* <Route path="/growth-planning/innovate-or-optimize" element={<PrivateRoute><ChangeStateFlagRoute><InnovateOrOptimize/></ChangeStateFlagRoute></PrivateRoute>} /> */}
        <Route path="/prescribe/find-the-Low-Hanging-Fruit" element={<PrivateRoute><ChangeStateFlagRoute><FindTheLowHangingFruit/></ChangeStateFlagRoute></PrivateRoute>} />

        {/* Stragic Foundation Routes start here */}
        <Route path="/strategic-foundation" element={<PrivateRoute><ChangeStateFlagRoute><StrategicFoundation /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/strategic-foundation/10-5-3-1-Planning" element={<PrivateRoute><ChangeStateFlagRoute><TenFiveThreeOnePlanning /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/strategic-foundation/critical-growth-factors" element={<PrivateRoute><ChangeStateFlagRoute><CriticalGrowthFactors /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/strategic-foundation/strategy-and-innovation-map" element={<PrivateRoute><ChangeStateFlagRoute><StrategyAndInnovationMap /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/strategic-foundation/strategy-uncertainty-map" element={<PrivateRoute><ChangeStateFlagRoute><StrategyUncertaintyMap /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/strategic-foundation/break-through-strategy" element={<PrivateRoute><ChangeStateFlagRoute><BreakthroughStrategy /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/strategic-foundation/the-big-SWOT" element={<PrivateRoute><ChangeStateFlagRoute><TheBigSWOT /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/strategic-foundation/customer-personas" element={<PrivateRoute><ChangeStateFlagRoute><CustomerPersonas /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/strategic-foundation/values-mission-goals" element={<PrivateRoute><ChangeStateFlagRoute><ValuesMissionGoals /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/strategic-foundation/go-to-market-strategy" element={<PrivateRoute><ChangeStateFlagRoute><GoToMarketStrategy /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/strategic-foundation/pyramid-of-purpose" element={<PrivateRoute><ChangeStateFlagRoute><PyramidOfPurpose /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/strategic-foundation/growth-progress-report" element={<PrivateRoute><ChangeStateFlagRoute><GrowthProgressReport /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/strategic-foundation/five-step-strategy" element={<PrivateRoute><ChangeStateFlagRoute><FiveStepStrategy /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/strategic-foundation/competitive-analysis" element={<PrivateRoute><ChangeStateFlagRoute><CompetitiveAnalysis /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/strategic-foundation/competitive-advantage-audit" element={<PrivateRoute><ChangeStateFlagRoute><CompetitiveAdvantageAudit /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/strategic-foundation/VRIO-framework" element={<PrivateRoute><ChangeStateFlagRoute><VRIOFramework /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/strategic-foundation/differentiation-diagnostic" element={<PrivateRoute><ChangeStateFlagRoute><DifferentiationDiagnostic /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/strategic-foundation/ADL-matrix" element={<PrivateRoute><ChangeStateFlagRoute><ADLMatrix /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/strategic-foundation/brand-scorecard" element={<PrivateRoute><ChangeStateFlagRoute><BrandScorecard /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/strategic-foundation/positioning-matrix" element={<PrivateRoute><ChangeStateFlagRoute><PositioningMatrix /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/strategic-foundation/five-forces-framework" element={<PrivateRoute><ChangeStateFlagRoute><FiveForcesFramework /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/strategic-foundation/innovative-offering-analysis" element={<PrivateRoute><ChangeStateFlagRoute><InnovativeOfferingAnalysis /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/strategic-foundation/four-forces-of-value" element={<PrivateRoute><ChangeStateFlagRoute><FourForcesOfValue /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/strategic-foundation/customer-transformation-model" element={<PrivateRoute><ChangeStateFlagRoute><CustomerTransformationModel/></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/strategic-foundation/message-selection-matrix" element={<PrivateRoute><ChangeStateFlagRoute><MessageSelectionMatrix /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/strategic-foundation/whole-brain-messaging" element={<PrivateRoute><ChangeStateFlagRoute><WholeBrainMessaging /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/strategic-foundation/insight-storyboard-template" element={<PrivateRoute><ChangeStateFlagRoute><InsightStoryboardTemplate /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/strategic-foundation/customer-empathy-map" element={<PrivateRoute><ChangeStateFlagRoute><CustomerEmpathyMap /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/strategic-foundation/SCQA-framework" element={<PrivateRoute><ChangeStateFlagRoute><SCQAFramework /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/strategic-foundation/minto-pyramid" element={<PrivateRoute><ChangeStateFlagRoute><MintoPyramid /></ChangeStateFlagRoute></PrivateRoute>} />
        {/* Stragic Foundation Routes end here */}

        {/* Revenue Growth routes start here */}
        <Route path="/revenue-growth-tools" element={<PrivateRoute><ChangeStateFlagRoute><RevenueGrowthTools/></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/revenue-growth-tools/growth-option-scorecard" element={<PrivateRoute><ChangeStateFlagRoute><GrowthOptionScorecard/></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/revenue-growth-tools/whats-working-now" element={<PrivateRoute><ChangeStateFlagRoute><WhatsWorkingNow/></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/revenue-growth-tools/marketing-plan" element={<PrivateRoute><ChangeStateFlagRoute><MarketingPlan /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/revenue-growth-tools/sales-elevation-plan" element={<PrivateRoute><ChangeStateFlagRoute><SalesElevationPlan /></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/revenue-growth-tools/find-the-Low-Hanging-Fruit" element={<PrivateRoute><ChangeStateFlagRoute><FindTheLowHangingFruit/></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/revenue-growth-tools/customer-transformation-model" element={<PrivateRoute><ChangeStateFlagRoute><CustomerTransformationModel/></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/revenue-growth-tools/referral-strategy-worksheet" element={<PrivateRoute><ChangeStateFlagRoute><ReferralStrategyWorksheet/></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/prescribe/opportunity-ranking-analysis" element={<PrivateRoute><ChangeStateFlagRoute><OpportunityRankingAnalysis/></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/revenue-growth-tools/project-prioritization-matrix" element={<PrivateRoute><ChangeStateFlagRoute><ProjectPrioritizationMatrix/></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/revenue-growth-tools/synergy-scoring-worksheet" element={<PrivateRoute><ChangeStateFlagRoute><SynergyScoringWorksheet/></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/revenue-growth-tools/alliance-evaluation-worksheet" element={<PrivateRoute><ChangeStateFlagRoute><AllianceEvaluationWorksheet/></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/revenue-growth-tools/sales-call-objectives" element={<PrivateRoute><ChangeStateFlagRoute><SalesCallObjectives/></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/revenue-growth-tools/account-penetration-matrix" element={<PrivateRoute><ChangeStateFlagRoute><AccountPenetrationMatrix/></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/revenue-growth-tools/objection-resolution-worksheet" element={<PrivateRoute><ChangeStateFlagRoute><ObjectionResolutionWorksheet/></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/revenue-growth-tools/target-account-list-prioritization" element={<PrivateRoute><ChangeStateFlagRoute><TargetAccountListPrioritization/></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/revenue-growth-tools/target-account-planning" element={<PrivateRoute><ChangeStateFlagRoute><TargetAccountPlanning/></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/revenue-growth-tools/value-first-strategy-development" element={<PrivateRoute><ChangeStateFlagRoute><ValueFirstStrategyDevelopment/></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/revenue-growth-tools/innovation-concept-development" element={<PrivateRoute><ChangeStateFlagRoute><InnovationConceptDevelopment/></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/revenue-growth-tools/SCAMPER-Ideation" element={<PrivateRoute><ChangeStateFlagRoute><SCAMPERIdeation/></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/revenue-growth-tools/PAINstorming" element={<PrivateRoute><ChangeStateFlagRoute><PAINstorming/></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/revenue-growth-tools/outcome-driven-innovation" element={<PrivateRoute><ChangeStateFlagRoute><OutcomeDrivenInnovation/></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/revenue-growth-tools/six-thinking-hats" element={<PrivateRoute><ChangeStateFlagRoute><SixThinkingHats/></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/revenue-growth-tools/agile-innovation" element={<PrivateRoute><ChangeStateFlagRoute><AgileInnovation/></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/revenue-growth-tools/innovation-ranking-matrix" element={<PrivateRoute><ChangeStateFlagRoute><InnovationRankingMatrix/></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/revenue-growth-tools/jobs-to-be-done" element={<PrivateRoute><ChangeStateFlagRoute><JobsToBeDone/></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/revenue-growth-tools/insights-to-action-to-impact-worksheet" element={<PrivateRoute><ChangeStateFlagRoute><InsightsToActionToImpactWorksheet/></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/revenue-growth-tools/disruptive-innovation" element={<PrivateRoute><ChangeStateFlagRoute><DisruptiveInnovation/></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/revenue-growth-tools/7-step-case-for-change" element={<PrivateRoute><ChangeStateFlagRoute><StepCaseForChange/></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/revenue-growth-tools/optimization-accelerator" element={<PrivateRoute><ChangeStateFlagRoute><OptimizationAccelerator/></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/revenue-growth-tools/customer-retention-loop" element={<PrivateRoute><ChangeStateFlagRoute><CustomerRetentionloop/></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/revenue-growth-tools/net-promoter-score" element={<PrivateRoute><ChangeStateFlagRoute><NetPromoterScore/></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/revenue-growth-tools/customer-journey-mapping" element={<PrivateRoute><ChangeStateFlagRoute><CustomerJourneyMapping/></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/revenue-growth-tools/voice-of-the-customer-table" element={<PrivateRoute><ChangeStateFlagRoute><VoiceOfTheCustomerTable/></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/revenue-growth-tools/innovate-or-optimize" element={<PrivateRoute><ChangeStateFlagRoute><InnovateOrOptimize/></ChangeStateFlagRoute></PrivateRoute>} />
        <Route path="/revenue-growth-tools/customer-communication-plan" element={<PrivateRoute><ChangeStateFlagRoute><CustomerCommunicationPlan /></ChangeStateFlagRoute></PrivateRoute>} />
        {/* Revenue Growth routes end here */}

        <Route path="/success" element={<PrivateRoute><StateUpdateRoute><ReturnBackRoute /></StateUpdateRoute></PrivateRoute>} />

        <Route path="/logout" element={<PrivateRoute><Logout /></PrivateRoute>} />

        <Route path="/" element={
          <ProtectedRoute>
            <Auth />
          </ProtectedRoute>} />

          <Route path="*" element={<PageNotFound />} />
          <Route path="/access-denied" element={<AccessDenied />} />
      </Routes>
    </BrowserRouter>

    </SWRConfig>
  );
}

export default App;
