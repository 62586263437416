import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getTargetAccountPrioritization, modifyTargetAccountPrioritization } from "../../state/features/TargetAccountPrioritizationSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import sum from "../../utils/sum";

export default function TargetAccountPrioritizationContent() {

    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        TargetAccountPrioritizationC11 : '',
        TargetAccountPrioritizationD11 : 0,
        TargetAccountPrioritizationE11 : 0,
        TargetAccountPrioritizationF11 : 0,
        TargetAccountPrioritizationG11 : 0,
        TargetAccountPrioritizationC12 : '',
        TargetAccountPrioritizationD12 : 0,
        TargetAccountPrioritizationE12 : 0,
        TargetAccountPrioritizationF12 : 0,
        TargetAccountPrioritizationG12 : 0,
        TargetAccountPrioritizationC13 : '',
        TargetAccountPrioritizationD13 : 0,
        TargetAccountPrioritizationE13 : 0,
        TargetAccountPrioritizationF13 : 0,
        TargetAccountPrioritizationG13 : 0,
        TargetAccountPrioritizationC14 : '',
        TargetAccountPrioritizationD14 : 0,
        TargetAccountPrioritizationE14 : 0,
        TargetAccountPrioritizationF14 : 0,
        TargetAccountPrioritizationG14 : 0,
        TargetAccountPrioritizationC15 : '',
        TargetAccountPrioritizationD15 : 0,
        TargetAccountPrioritizationE15 : 0,
        TargetAccountPrioritizationF15 : 0,
        TargetAccountPrioritizationG15 : 0,
        TargetAccountPrioritizationC16 : '',
        TargetAccountPrioritizationD16 : 0,
        TargetAccountPrioritizationE16 : 0,
        TargetAccountPrioritizationF16 : 0,
        TargetAccountPrioritizationG16 : 0,
        TargetAccountPrioritizationC17 : '',
        TargetAccountPrioritizationD17 : 0,
        TargetAccountPrioritizationE17 : 0,
        TargetAccountPrioritizationF17 : 0,
        TargetAccountPrioritizationG17 : 0,
        TargetAccountPrioritizationC18 : '',
        TargetAccountPrioritizationD18 : 0,
        TargetAccountPrioritizationE18 : 0,
        TargetAccountPrioritizationF18 : 0,
        TargetAccountPrioritizationG18 : 0,
        TargetAccountPrioritizationC19 : '',
        TargetAccountPrioritizationD19 : 0,
        TargetAccountPrioritizationE19 : 0,
        TargetAccountPrioritizationF19 : 0,
        TargetAccountPrioritizationG19 : 0,
        TargetAccountPrioritizationC20 : '',
        TargetAccountPrioritizationD20 : 0,
        TargetAccountPrioritizationE20 : 0,
        TargetAccountPrioritizationF20 : 0,
        TargetAccountPrioritizationG20 : 0,
        TargetAccountPrioritizationC24 : '',
        TargetAccountPrioritizationD24 : 0,
        TargetAccountPrioritizationE24 : 0,
        TargetAccountPrioritizationF24 : 0,
        TargetAccountPrioritizationG24 : 0,
        TargetAccountPrioritizationC25 : '',
        TargetAccountPrioritizationD25 : 0,
        TargetAccountPrioritizationE25 : 0,
        TargetAccountPrioritizationF25 : 0,
        TargetAccountPrioritizationG25 : 0,
        TargetAccountPrioritizationC26 : '',
        TargetAccountPrioritizationD26 : 0,
        TargetAccountPrioritizationE26 : 0,
        TargetAccountPrioritizationF26 : 0,
        TargetAccountPrioritizationG26 : 0,
        TargetAccountPrioritizationC27 : '',
        TargetAccountPrioritizationD27 : 0,
        TargetAccountPrioritizationE27 : 0,
        TargetAccountPrioritizationF27 : 0,
        TargetAccountPrioritizationG27 : 0,
        TargetAccountPrioritizationC28 : '',
        TargetAccountPrioritizationD28 : 0,
        TargetAccountPrioritizationE28 : 0,
        TargetAccountPrioritizationF28 : 0,
        TargetAccountPrioritizationG28 : 0,
        TargetAccountPrioritizationC29 : '',
        TargetAccountPrioritizationD29 : 0,
        TargetAccountPrioritizationE29 : 0,
        TargetAccountPrioritizationF29 : 0,
        TargetAccountPrioritizationG29 : 0,
        TargetAccountPrioritizationC30 : '',
        TargetAccountPrioritizationD30 : 0,
        TargetAccountPrioritizationE30 : 0,
        TargetAccountPrioritizationF30 : 0,
        TargetAccountPrioritizationG30 : 0,
        TargetAccountPrioritizationC31 : '',
        TargetAccountPrioritizationD31 : 0,
        TargetAccountPrioritizationE31 : 0,
        TargetAccountPrioritizationF31 : 0,
        TargetAccountPrioritizationG31 : 0,
        TargetAccountPrioritizationC32 : '',
        TargetAccountPrioritizationD32 : 0,
        TargetAccountPrioritizationE32 : 0,
        TargetAccountPrioritizationF32 : 0,
        TargetAccountPrioritizationG32 : 0,
        TargetAccountPrioritizationC33 : '',
        TargetAccountPrioritizationD33 : 0,
        TargetAccountPrioritizationE33 : 0,
        TargetAccountPrioritizationF33 : 0,
        TargetAccountPrioritizationG33 : 0,
        TargetAccountPrioritizationH11 : 0,
        TargetAccountPrioritizationH12 : 0,
        TargetAccountPrioritizationH13 : 0,
        TargetAccountPrioritizationH14 : 0,
        TargetAccountPrioritizationH15 : 0,
        TargetAccountPrioritizationH16 : 0,
        TargetAccountPrioritizationH17 : 0,
        TargetAccountPrioritizationH18 : 0,
        TargetAccountPrioritizationH19 : 0,
        TargetAccountPrioritizationH20 : 0,
        TargetAccountPrioritizationH24 : 0,
        TargetAccountPrioritizationH25 : 0,
        TargetAccountPrioritizationH26 : 0,
        TargetAccountPrioritizationH27 : 0,
        TargetAccountPrioritizationH28 : 0,
        TargetAccountPrioritizationH29 : 0,
        TargetAccountPrioritizationH30 : 0,
        TargetAccountPrioritizationH31 : 0,
        TargetAccountPrioritizationH32 : 0,
        TargetAccountPrioritizationH33 : 0,
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getTargetAccountPrioritization({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.revenueGrowth)
            if(response!= undefined  && response.data.revenueGrowth){
                let revenueGrowth = response.data.revenueGrowth[0];
                console.log(revenueGrowth)
                delete revenueGrowth.id;
                delete revenueGrowth.user_id;
                delete revenueGrowth.created_at;
                delete revenueGrowth.updated_at;
                const newObj = { ...Inputs }
                for (const key in revenueGrowth) {
                    if (revenueGrowth.hasOwnProperty(key)) {
                        if(revenueGrowth[key])
                        newObj[key] = revenueGrowth[key]
                    }
                }
                newObj['TargetAccountPrioritizationH11'] = sum(newObj['TargetAccountPrioritizationD11'], newObj['TargetAccountPrioritizationE11'], newObj['TargetAccountPrioritizationF11'], newObj['TargetAccountPrioritizationG11']).toFixed(2);
                newObj['TargetAccountPrioritizationH12'] = sum(newObj['TargetAccountPrioritizationD12'], newObj['TargetAccountPrioritizationE12'], newObj['TargetAccountPrioritizationF12'], newObj['TargetAccountPrioritizationG12']).toFixed(2);
                newObj['TargetAccountPrioritizationH13'] = sum(newObj['TargetAccountPrioritizationD13'], newObj['TargetAccountPrioritizationE13'], newObj['TargetAccountPrioritizationF13'], newObj['TargetAccountPrioritizationG13']).toFixed(2);
                newObj['TargetAccountPrioritizationH14'] = sum(newObj['TargetAccountPrioritizationD14'], newObj['TargetAccountPrioritizationE14'], newObj['TargetAccountPrioritizationF14'], newObj['TargetAccountPrioritizationG14']).toFixed(2);
                newObj['TargetAccountPrioritizationH15'] = sum(newObj['TargetAccountPrioritizationD15'], newObj['TargetAccountPrioritizationE15'], newObj['TargetAccountPrioritizationF15'], newObj['TargetAccountPrioritizationG15']).toFixed(2);
                newObj['TargetAccountPrioritizationH16'] = sum(newObj['TargetAccountPrioritizationD16'], newObj['TargetAccountPrioritizationE16'], newObj['TargetAccountPrioritizationF16'], newObj['TargetAccountPrioritizationG16']).toFixed(2);
                newObj['TargetAccountPrioritizationH17'] = sum(newObj['TargetAccountPrioritizationD17'], newObj['TargetAccountPrioritizationE17'], newObj['TargetAccountPrioritizationF17'], newObj['TargetAccountPrioritizationG17']).toFixed(2);
                newObj['TargetAccountPrioritizationH18'] = sum(newObj['TargetAccountPrioritizationD18'], newObj['TargetAccountPrioritizationE18'], newObj['TargetAccountPrioritizationF18'], newObj['TargetAccountPrioritizationG18']).toFixed(2);
                newObj['TargetAccountPrioritizationH19'] = sum(newObj['TargetAccountPrioritizationD19'], newObj['TargetAccountPrioritizationE19'], newObj['TargetAccountPrioritizationF19'], newObj['TargetAccountPrioritizationG19']).toFixed(2);
                newObj['TargetAccountPrioritizationH20'] = sum(newObj['TargetAccountPrioritizationD20'], newObj['TargetAccountPrioritizationE20'], newObj['TargetAccountPrioritizationF20'], newObj['TargetAccountPrioritizationG20']).toFixed(2);
                newObj['TargetAccountPrioritizationH24'] = sum(newObj['TargetAccountPrioritizationD24'], newObj['TargetAccountPrioritizationE24'], newObj['TargetAccountPrioritizationF24'], newObj['TargetAccountPrioritizationG24']).toFixed(2);
                newObj['TargetAccountPrioritizationH25'] = sum(newObj['TargetAccountPrioritizationD25'], newObj['TargetAccountPrioritizationE25'], newObj['TargetAccountPrioritizationF25'], newObj['TargetAccountPrioritizationG25']).toFixed(2);
                newObj['TargetAccountPrioritizationH26'] = sum(newObj['TargetAccountPrioritizationD26'], newObj['TargetAccountPrioritizationE26'], newObj['TargetAccountPrioritizationF26'], newObj['TargetAccountPrioritizationG26']).toFixed(2);
                newObj['TargetAccountPrioritizationH27'] = sum(newObj['TargetAccountPrioritizationD27'], newObj['TargetAccountPrioritizationE27'], newObj['TargetAccountPrioritizationF27'], newObj['TargetAccountPrioritizationG27']).toFixed(2);
                newObj['TargetAccountPrioritizationH28'] = sum(newObj['TargetAccountPrioritizationD28'], newObj['TargetAccountPrioritizationE28'], newObj['TargetAccountPrioritizationF28'], newObj['TargetAccountPrioritizationG28']).toFixed(2);
                newObj['TargetAccountPrioritizationH29'] = sum(newObj['TargetAccountPrioritizationD29'], newObj['TargetAccountPrioritizationE29'], newObj['TargetAccountPrioritizationF29'], newObj['TargetAccountPrioritizationG29']).toFixed(2);
                newObj['TargetAccountPrioritizationH30'] = sum(newObj['TargetAccountPrioritizationD30'], newObj['TargetAccountPrioritizationE30'], newObj['TargetAccountPrioritizationF30'], newObj['TargetAccountPrioritizationG30']).toFixed(2);
                newObj['TargetAccountPrioritizationH31'] = sum(newObj['TargetAccountPrioritizationD31'], newObj['TargetAccountPrioritizationE31'], newObj['TargetAccountPrioritizationF31'], newObj['TargetAccountPrioritizationG31']).toFixed(2);
                newObj['TargetAccountPrioritizationH32'] = sum(newObj['TargetAccountPrioritizationD32'], newObj['TargetAccountPrioritizationE32'], newObj['TargetAccountPrioritizationF32'], newObj['TargetAccountPrioritizationG32']).toFixed(2);
                newObj['TargetAccountPrioritizationH33'] = sum(newObj['TargetAccountPrioritizationD33'], newObj['TargetAccountPrioritizationE33'], newObj['TargetAccountPrioritizationF33'], newObj['TargetAccountPrioritizationG33']).toFixed(2);
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                let thenum = event.target.id.match(/\d+/)[0];
                let thestr = event.target.id.replace(thenum,'');
                let overallScore = 0;
                if(thestr != 'TargetAccountPrioritizationC'){
                    if(thestr == 'TargetAccountPrioritizationD'){
                        overallScore = sum(event.target.value, Inputs['TargetAccountPrioritizationE'+thenum], Inputs['TargetAccountPrioritizationF'+thenum], Inputs['TargetAccountPrioritizationG'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'TargetAccountPrioritizationE'){
                        overallScore = sum(event.target.value, Inputs['TargetAccountPrioritizationD'+thenum], Inputs['TargetAccountPrioritizationF'+thenum], Inputs['TargetAccountPrioritizationG'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'TargetAccountPrioritizationF'){
                        overallScore = sum(event.target.value, Inputs['TargetAccountPrioritizationD'+thenum], Inputs['TargetAccountPrioritizationE'+thenum], Inputs['TargetAccountPrioritizationG'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'TargetAccountPrioritizationG'){
                        overallScore = sum(event.target.value, Inputs['TargetAccountPrioritizationD'+thenum], Inputs['TargetAccountPrioritizationE'+thenum], Inputs['TargetAccountPrioritizationF'+thenum]).toFixed(2);
                    }
                    
                    setInputs({ ...Inputs, ['TargetAccountPrioritizationH'+thenum] : parseFloat(overallScore) })
                }
               
                dispatch(modifyTargetAccountPrioritization({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

  return (
    <div className="content-body">
        <UserDropdown />
        {/* row */}
        <div className="container-fluid">
            <div className="head-title">
                <p className="mb-0">Sales &amp; Sales Management </p>
                <h2 className="mb-2 mt-0">Target Account List Prioritization</h2>
                <ol className="list-unstyled mb-4 mt-0 list-decimal">
                <li className="lsit-one">In column B, enter at least 10 of your top targets for new clients.</li>
                <li className="lsit-one">Replace "Business Development Criteria" labels in Columns D through G with each of your top prioritized business development criteria. </li>
                <li className="lsit-one">For each criteria column, enter the weighting in Row 12.  The cumulative weighting should equal 100%. </li>
                <li className="lsit-one">Rate each target on your Target List by each of the criteria on a scale of 1 to 5 (1 = low, 5 = high). </li>
                <li className="lsit-one">The average weighted rank is calculated in Column H. </li>
                <li className="lsit-one">Identify your top five targets by the summed rankings to identify your prioritized Target List. </li>
                </ol>
            </div>
            <Spinner />
            <div className="card">
                <div className="card-body pl-0">
                <div className="table-responsive table-dta">
                    {/* table 1 */}
                    <table className="table mb-0">
                    <tbody>
                        <tr className="target-account-main-tr">
                        <td className="bg-color1 text-white" colSpan={3} style={{width: '28%'}}>
                            <div className="target-account comman-div-target">Top 10 Targeted Prospects</div>
                        </td>
                        <td className="bg-color1 text-white text-center-c" colSpan={1} style={{width: '16.75%'}}>
                            <div className="comman-div-target">Business Development <br /> Criteria #1</div>
                        </td>
                        <td className="bg-color1 text-white text-center-c" colSpan={1} style={{width: '16.75%'}}>
                            <div className="comman-div-target">Business Development <br /> Criteria #2</div>
                        </td>
                        <td className="bg-color1 text-white text-center-c" colSpan={1} style={{width: '16.75%'}}>
                            <div className="comman-div-target">Business Development <br /> Criteria #3</div>
                        </td>
                        <td className="bg-color1 text-white text-center-c" colSpan={1} style={{width: '16.75%'}}>
                            <div className="comman-div-target">Business Development <br /> Criteria #4</div>
                        </td>
                        <td className="bg-color1 text-white text-center-c" colSpan={1} style={{width: '5%'}}>
                            <div className="comman-div-target">Average <br /> Weighted Score</div>
                        </td>
                        </tr>
                        <tr className="target-account-main-tr">
                        <td className="bg-color3 text-white" colSpan={3}>
                            <div className="target-account comman-div-target">Weight (should total 100%)</div>
                        </td>
                        <td className="bg-color3 text-white text-center-c" colSpan={1}>
                            <div className="comman-div-target">40%</div>
                        </td>
                        <td className="bg-color3 text-white text-center-c" colSpan={1}>
                            <div className="comman-div-target">30%</div>
                        </td>
                        <td className="bg-color3 text-white text-center-c" colSpan={1}>
                            <div className="comman-div-target">20%</div>
                        </td>
                        <td className="bg-color3 text-white text-center-c" colSpan={1}>
                            <div className="comman-div-target">10%</div>
                        </td>
                        <td className="bg-color3 text-white text-center-c" colSpan={1}>
                            <div className="comman-div-target">10</div>
                        </td>
                        </tr>
                        <tr className="target-account-main-tr">
                        <td className="bg-color2 padding-0" colSpan={3}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="TargetAccountPrioritizationC11"
                                    value={Inputs.TargetAccountPrioritizationC11}
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationC11: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                    placeholder="Prospect 1" />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationD11" 
                                    value={Inputs.TargetAccountPrioritizationD11} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationD11: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                                            }
                                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                                event.preventDefault();
                                            alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationE11" 
                                    value={Inputs.TargetAccountPrioritizationE11} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationE11: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationF11" 
                                    value={Inputs.TargetAccountPrioritizationF11} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationF11: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationG11" 
                                    value={Inputs.TargetAccountPrioritizationG11} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationG11: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color3 text-white text-center-c" colSpan={1}>
                            <div className="comman-div-target">{Number(Inputs.TargetAccountPrioritizationH11).toLocaleString('en-US')}</div>
                        </td>
                        </tr>
                        <tr className="target-account-main-tr">
                        <td className="bg-color2 padding-0" colSpan={3}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="TargetAccountPrioritizationC12"
                                    value={Inputs.TargetAccountPrioritizationC12}
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationC12: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                    placeholder="Prospect 2" />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationD12" 
                                    value={Inputs.TargetAccountPrioritizationD12} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationD12: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationE12" 
                                    value={Inputs.TargetAccountPrioritizationE12} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationE12: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationF12" 
                                    value={Inputs.TargetAccountPrioritizationF12} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationF12: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationG12" 
                                    value={Inputs.TargetAccountPrioritizationG12} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationG12: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color3 text-white text-center-c" colSpan={1}>
                            <div className="comman-div-target">{Number(Inputs.TargetAccountPrioritizationH12).toLocaleString('en-US')}</div>
                        </td>
                        </tr>
                        <tr className="target-account-main-tr">
                        <td className="bg-color2 padding-0" colSpan={3}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="TargetAccountPrioritizationC13"
                                    value={Inputs.TargetAccountPrioritizationC13}
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationC13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                    placeholder="Prospect 3" />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationD13" 
                                    value={Inputs.TargetAccountPrioritizationD13} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationD13: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationE13" 
                                    value={Inputs.TargetAccountPrioritizationE13} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationE13: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationF13" 
                                    value={Inputs.TargetAccountPrioritizationF13} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationF13: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationG13" 
                                    value={Inputs.TargetAccountPrioritizationG13} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationG13: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color3 text-white text-center-c" colSpan={1}>
                            <div className="comman-div-target">{Number(Inputs.TargetAccountPrioritizationH13).toLocaleString('en-US')}</div>
                        </td>
                        </tr>
                        <tr className="target-account-main-tr">
                        <td className="bg-color2 padding-0" colSpan={3}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="TargetAccountPrioritizationC14"
                                    value={Inputs.TargetAccountPrioritizationC14}
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationC14: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationD14" 
                                    value={Inputs.TargetAccountPrioritizationD14} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationD14: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationE14" 
                                    value={Inputs.TargetAccountPrioritizationE14} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationE14: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationF14" 
                                    value={Inputs.TargetAccountPrioritizationF14} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationF14: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationG14" 
                                    value={Inputs.TargetAccountPrioritizationG14} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationG14: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color3 text-white text-center-c" colSpan={1}>
                            <div className="comman-div-target">{Number(Inputs.TargetAccountPrioritizationH14).toLocaleString('en-US')}</div>
                        </td>
                        </tr>
                        <tr className="target-account-main-tr">
                        <td className="bg-color2 padding-0" colSpan={3}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="TargetAccountPrioritizationC15"
                                    value={Inputs.TargetAccountPrioritizationC15}
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationC15: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationD15" 
                                    value={Inputs.TargetAccountPrioritizationD15} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationD15: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationE15" 
                                    value={Inputs.TargetAccountPrioritizationE15} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationE15: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationF15" 
                                    value={Inputs.TargetAccountPrioritizationF15} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationF15: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationG15" 
                                    value={Inputs.TargetAccountPrioritizationG15} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationG15: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color3 text-white text-center-c" colSpan={1}>
                            <div className="comman-div-target">{Number(Inputs.TargetAccountPrioritizationH15).toLocaleString('en-US')}</div>
                        </td>
                        </tr>
                        <tr className="target-account-main-tr">
                        <td className="bg-color2 padding-0" colSpan={3}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="TargetAccountPrioritizationC16"
                                    value={Inputs.TargetAccountPrioritizationC16}
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationC16: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationD16" 
                                    value={Inputs.TargetAccountPrioritizationD16} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationD16: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationE16" 
                                    value={Inputs.TargetAccountPrioritizationE16} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationE16: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationF16" 
                                    value={Inputs.TargetAccountPrioritizationF16} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationF16: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationG16" 
                                    value={Inputs.TargetAccountPrioritizationG16} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationG16: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color3 text-white text-center-c" colSpan={1}>
                            <div className="comman-div-target">{Number(Inputs.TargetAccountPrioritizationH16).toLocaleString('en-US')}</div>
                        </td>
                        </tr>
                        <tr className="target-account-main-tr">
                        <td className="bg-color2 padding-0" colSpan={3}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="TargetAccountPrioritizationC17"
                                    value={Inputs.TargetAccountPrioritizationC17}
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationC17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationD17" 
                                    value={Inputs.TargetAccountPrioritizationD17} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationD17: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationE17" 
                                    value={Inputs.TargetAccountPrioritizationE17} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationE17: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationF17" 
                                    value={Inputs.TargetAccountPrioritizationF17} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationF17: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationG17" 
                                    value={Inputs.TargetAccountPrioritizationG17} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationG17: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color3 text-white text-center-c" colSpan={1}>
                            <div className="comman-div-target">{Number(Inputs.TargetAccountPrioritizationH17).toLocaleString('en-US')}</div>
                        </td>
                        </tr>
                        <tr className="target-account-main-tr">
                        <td className="bg-color2 padding-0" colSpan={3}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="TargetAccountPrioritizationC18"
                                    value={Inputs.TargetAccountPrioritizationC18}
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationC18: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationD18" 
                                    value={Inputs.TargetAccountPrioritizationD18} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationD18: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationE18" 
                                    value={Inputs.TargetAccountPrioritizationE18} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationE18: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationF18" 
                                    value={Inputs.TargetAccountPrioritizationF18} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationF18: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationG18" 
                                    value={Inputs.TargetAccountPrioritizationG18} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationG18: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color3 text-white text-center-c" colSpan={1}>
                            <div className="comman-div-target">{Number(Inputs.TargetAccountPrioritizationH18).toLocaleString('en-US')}</div>
                        </td>
                        </tr>
                        <tr className="target-account-main-tr">
                        <td className="bg-color2 padding-0" colSpan={3}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="TargetAccountPrioritizationC19"
                                    value={Inputs.TargetAccountPrioritizationC19}
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationC19: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationD19" 
                                    value={Inputs.TargetAccountPrioritizationD19} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationD19: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationE19" 
                                    value={Inputs.TargetAccountPrioritizationE19} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationE19: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationF19" 
                                    value={Inputs.TargetAccountPrioritizationF19} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationF19: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationG19" 
                                    value={Inputs.TargetAccountPrioritizationG19} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationG19: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color3 text-white text-center-c" colSpan={1}>
                            <div className="comman-div-target">{Number(Inputs.TargetAccountPrioritizationH19).toLocaleString('en-US')}</div>
                        </td>
                        </tr>
                        <tr className="target-account-main-tr">
                        <td className="bg-color2 padding-0" colSpan={3}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="TargetAccountPrioritizationC20"
                                    value={Inputs.TargetAccountPrioritizationC20}
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationC20: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationD20" 
                                    value={Inputs.TargetAccountPrioritizationD20} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationD20: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationE20" 
                                    value={Inputs.TargetAccountPrioritizationE20} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationE20: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationF20" 
                                    value={Inputs.TargetAccountPrioritizationF20} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationF20: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationG20" 
                                    value={Inputs.TargetAccountPrioritizationG20} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationG20: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color3 text-white text-center-c" colSpan={1}>
                            <div className="comman-div-target">{Number(Inputs.TargetAccountPrioritizationH20).toLocaleString('en-US')}</div>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                    {/* table 2 */}
                    <table className="table mb-0 mt-5">
                    <tbody>
                        <tr className="target-account-main-tr">
                        <td className="bg-color1 text-white" colSpan={3} style={{width: '28%'}}>
                            <div className="target-account comman-div-target">Top 10 Targeted Prospects</div>
                        </td>
                        <td className="bg-color1 text-white text-center-c" colSpan={1} style={{width: '16.75%'}}>
                            <div className="comman-div-target">Business Development <br /> Criteria #1</div>
                        </td>
                        <td className="bg-color1 text-white text-center-c" colSpan={1} style={{width: '16.75%'}}>
                            <div className="comman-div-target">Business Development <br /> Criteria #2</div>
                        </td>
                        <td className="bg-color1 text-white text-center-c" colSpan={1} style={{width: '16.75%'}}>
                            <div className="comman-div-target">Business Development <br /> Criteria #3</div>
                        </td>
                        <td className="bg-color1 text-white text-center-c" colSpan={1} style={{width: '16.75%'}}>
                            <div className="comman-div-target">Business Development <br /> Criteria #4</div>
                        </td>
                        <td className="bg-color1 text-white text-center-c" colSpan={1} style={{width: '5%'}}>
                            <div className="comman-div-target">Average <br /> Weighted Score</div>
                        </td>
                        </tr>
                        <tr className="target-account-main-tr">
                        <td className="bg-color3 text-white" colSpan={3}>
                            <div className="target-account comman-div-target">Weight (should total 100%)</div>
                        </td>
                        <td className="bg-color3 text-white text-center-c" colSpan={1}>
                            <div className="comman-div-target">40%</div>
                        </td>
                        <td className="bg-color3 text-white text-center-c" colSpan={1}>
                            <div className="comman-div-target">30%</div>
                        </td>
                        <td className="bg-color3 text-white text-center-c" colSpan={1}>
                            <div className="comman-div-target">20%</div>
                        </td>
                        <td className="bg-color3 text-white text-center-c" colSpan={1}>
                            <div className="comman-div-target">10%</div>
                        </td>
                        <td className="bg-color3 text-white text-center-c" colSpan={1}>
                            <div className="comman-div-target">10</div>
                        </td>
                        </tr>
                        <tr className="target-account-main-tr">
                        <td className="bg-color2 padding-0" colSpan={3}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="TargetAccountPrioritizationC24"
                                    value={Inputs.TargetAccountPrioritizationC24}
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationC24: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                    placeholder="Prospect 1" />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationD24" 
                                    value={Inputs.TargetAccountPrioritizationD24} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationD24: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationE24" 
                                    value={Inputs.TargetAccountPrioritizationE24} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationE24: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationF24" 
                                    value={Inputs.TargetAccountPrioritizationF24} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationF24: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationG24" 
                                    value={Inputs.TargetAccountPrioritizationG24} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationG24: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color3 text-white text-center-c" colSpan={1}>
                            <div className="comman-div-target">{Number(Inputs.TargetAccountPrioritizationH24).toLocaleString('en-US')}</div>
                        </td>
                        </tr>
                        <tr className="target-account-main-tr">
                        <td className="bg-color2 padding-0" colSpan={3}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="TargetAccountPrioritizationC25"
                                    value={Inputs.TargetAccountPrioritizationC25}
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationC25: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                    placeholder="Prospect 2" />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationD25" 
                                    value={Inputs.TargetAccountPrioritizationD25} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationD25: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationE25" 
                                    value={Inputs.TargetAccountPrioritizationE25} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationE25: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationF25" 
                                    value={Inputs.TargetAccountPrioritizationF25} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationF25: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationG25" 
                                    value={Inputs.TargetAccountPrioritizationG25} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationG25: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color3 text-white text-center-c" colSpan={1}>
                            <div className="comman-div-target">{Number(Inputs.TargetAccountPrioritizationH25).toLocaleString('en-US')}</div>
                        </td>
                        </tr>
                        <tr className="target-account-main-tr">
                        <td className="bg-color2 padding-0" colSpan={3}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="TargetAccountPrioritizationC26"
                                    value={Inputs.TargetAccountPrioritizationC26}
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationC26: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                    placeholder="Prospect 3" />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationD26" 
                                    value={Inputs.TargetAccountPrioritizationD26} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationD26: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationE26" 
                                    value={Inputs.TargetAccountPrioritizationE26} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationE26: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationF26" 
                                    value={Inputs.TargetAccountPrioritizationF26} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationF26: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationG26" 
                                    value={Inputs.TargetAccountPrioritizationG26} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationG26: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color3 text-white text-center-c" colSpan={1}>
                            <div className="comman-div-target">{Number(Inputs.TargetAccountPrioritizationH26).toLocaleString('en-US')}</div>
                        </td>
                        </tr>
                        <tr className="target-account-main-tr">
                        <td className="bg-color2 padding-0" colSpan={3}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="TargetAccountPrioritizationC27"
                                    value={Inputs.TargetAccountPrioritizationC27}
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationC27: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationD27" 
                                    value={Inputs.TargetAccountPrioritizationD27} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationD27: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationE27" 
                                    value={Inputs.TargetAccountPrioritizationE27} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationE27: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationF27" 
                                    value={Inputs.TargetAccountPrioritizationF27} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationF27: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationG27" 
                                    value={Inputs.TargetAccountPrioritizationG27} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationG27: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color3 text-white text-center-c" colSpan={1}>
                            <div className="comman-div-target">{Number(Inputs.TargetAccountPrioritizationH27).toLocaleString('en-US')}</div>
                        </td>
                        </tr>
                        <tr className="target-account-main-tr">
                        <td className="bg-color2 padding-0" colSpan={3}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="TargetAccountPrioritizationC28"
                                    value={Inputs.TargetAccountPrioritizationC28}
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationC28: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationD28" 
                                    value={Inputs.TargetAccountPrioritizationD28} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationD28: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationE28" 
                                    value={Inputs.TargetAccountPrioritizationE28} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationE28: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationF28" 
                                    value={Inputs.TargetAccountPrioritizationF28} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationF28: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationG28" 
                                    value={Inputs.TargetAccountPrioritizationG28} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationG28: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color3 text-white text-center-c" colSpan={1}>
                            <div className="comman-div-target">{Number(Inputs.TargetAccountPrioritizationH28).toLocaleString('en-US')}</div>
                        </td>
                        </tr>
                        <tr className="target-account-main-tr">
                        <td className="bg-color2 padding-0" colSpan={3}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="TargetAccountPrioritizationC29"
                                    value={Inputs.TargetAccountPrioritizationC29}
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationC29: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationD29" 
                                    value={Inputs.TargetAccountPrioritizationD29} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationD29: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationE29" 
                                    value={Inputs.TargetAccountPrioritizationE29} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationE29: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationF29" 
                                    value={Inputs.TargetAccountPrioritizationF29} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationF29: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationG29" 
                                    value={Inputs.TargetAccountPrioritizationG29} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationG29: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color3 text-white text-center-c" colSpan={1}>
                            <div className="comman-div-target">{Number(Inputs.TargetAccountPrioritizationH29).toLocaleString('en-US')}</div>
                        </td>
                        </tr>
                        <tr className="target-account-main-tr">
                        <td className="bg-color2 padding-0" colSpan={3}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="TargetAccountPrioritizationC30"
                                    value={Inputs.TargetAccountPrioritizationC30}
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationC30: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationD30" 
                                    value={Inputs.TargetAccountPrioritizationD30} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationD30: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationE30" 
                                    value={Inputs.TargetAccountPrioritizationE30} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationE30: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationF30" 
                                    value={Inputs.TargetAccountPrioritizationF30} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationF30: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationG30" 
                                    value={Inputs.TargetAccountPrioritizationG30} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationG30: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color3 text-white text-center-c" colSpan={1}>
                            <div className="comman-div-target">{Number(Inputs.TargetAccountPrioritizationH30).toLocaleString('en-US')}</div>
                        </td>
                        </tr>
                        <tr className="target-account-main-tr">
                        <td className="bg-color2 padding-0" colSpan={3}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="TargetAccountPrioritizationC31"
                                    value={Inputs.TargetAccountPrioritizationC31}
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationC31: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationD31" 
                                    value={Inputs.TargetAccountPrioritizationD31} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationD31: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationE31" 
                                    value={Inputs.TargetAccountPrioritizationE31} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationE31: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationF31" 
                                    value={Inputs.TargetAccountPrioritizationF31} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationF31: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationG31" 
                                    value={Inputs.TargetAccountPrioritizationG31} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationG31: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color3 text-white text-center-c" colSpan={1}>
                            <div className="comman-div-target">{Number(Inputs.TargetAccountPrioritizationH31).toLocaleString('en-US')}</div>
                        </td>
                        </tr>
                        <tr className="target-account-main-tr">
                        <td className="bg-color2 padding-0" colSpan={3}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="TargetAccountPrioritizationC32"
                                    value={Inputs.TargetAccountPrioritizationC32}
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationC32: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationD32" 
                                    value={Inputs.TargetAccountPrioritizationD32} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationD32: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationE32" 
                                    value={Inputs.TargetAccountPrioritizationE32} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationE32: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationF32" 
                                    value={Inputs.TargetAccountPrioritizationF32} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationF32: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationG32" 
                                    value={Inputs.TargetAccountPrioritizationG32} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationG32: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color3 text-white text-center-c" colSpan={1}>
                            <div className="comman-div-target">{Number(Inputs.TargetAccountPrioritizationH32).toLocaleString('en-US')}</div>
                        </td>
                        </tr>
                        <tr className="target-account-main-tr">
                        <td className="bg-color2 padding-0" colSpan={3}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="TargetAccountPrioritizationC33"
                                    value={Inputs.TargetAccountPrioritizationC33}
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationC33: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationD33" 
                                    value={Inputs.TargetAccountPrioritizationD33} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationD33: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationE33" 
                                    value={Inputs.TargetAccountPrioritizationE33} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationE33: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationF33" 
                                    value={Inputs.TargetAccountPrioritizationF33} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationF33: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color2 text-center-c" colSpan={1}>
                            <div className="comman-div-target">
                                <input type="text" className="form-control inputText" 
                                    id="TargetAccountPrioritizationG33" 
                                    value={Inputs.TargetAccountPrioritizationG33} 
                                    onChange={(event) => setInputs({ ...Inputs, TargetAccountPrioritizationG33: event.target.value }) } 
                                    onBlur={(event) => { onBlurHandler(event) }} 
                                    disabled={isLoading} 
                                    placeholder="0" 
                                    maxLength="3" 
                                    onKeyPress={(event) => {
                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                        else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                        event.preventDefault();
                                        alert.show('Please enter a valid number.')
                                        }
                                    }}  />
                            </div>
                        </td>
                        <td className="bg-color3 text-white text-center-c" colSpan={1}>
                            <div className="comman-div-target">{Number(Inputs.TargetAccountPrioritizationH33).toLocaleString('en-US')}</div>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}
