import React from 'react'
import SidebarNew from '../components/SidebarNew';
import Content from '../components/content/ChangePasswordContent';
import Footer from '../components/Footer';

export default function ChangePassword() {
    return (
        <div>

            <div id="main-wrapper">

                <SidebarNew />

                <Content />

            </div>

            <Footer />

        </div>
    )
}
