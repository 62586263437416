import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from 'react-alert'
import UserService from "../../services/user.service";
import { clearMessage } from "../../state/features/Message";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import UserDropdown from '../UserDropdown'
import { userData } from "../../state/features/AuthenticationSlice";

export default function ProfileContent() {
    const users = useSelector(userData);
    const userID = users.data.profile.id;

    const alert = useAlert();
    const [isLoading, setisLoading] = useState(false)

    const Schema = Yup.object().shape({
        current_password: Yup.string().required('Current Password is required.')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
            ),
        new_password: Yup.string().required('New Password is required.')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
            ),
        confirm_password: Yup.string().required('Confirm Password is required.').oneOf([Yup.ref('new_password')], 'Passwords does not match.'),
    });
    const formOptions = { resolver: yupResolver(Schema) }
    const { register, setValue, formState: { errors, isSubmitting }, handleSubmit, } = useForm(formOptions);
    setValue("user_id", userID)

    const onSubmit = formValue => {
        setisLoading(true)
        const { user_id, current_password, new_password, confirm_password } = formValue
        let data = {
            current_password,
            new_password,
            confirm_password
        }
        UserService.ChangePassword(user_id, data)
            .then(() => {
                setisLoading(false)
                { alert.show("Password successfully changed.") }
            })
            .catch((error) => {
                setisLoading(false)
                { error && alert.show(error.response.data.message) }
            });
    }

    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
            {/* row */}
            <div className="container-fluid lead-generation-new">
            <div className="head-title">
                <h3 className='font-sise-20'>Change Password</h3>
            </div>
                <div className="mian-form-div-outer row">
                    <div className="col-md-12 col-lg-8 col-xl-6">
                        <div className="inner-main-div">
                            <div className="form-container sign-up-container">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <h1>Change Password</h1>
                                    <div className="row" style={{ padding: 0, margin: 0 }}>
                                        <div className="col-12 col-md-12 ">
                                            <input type="hidden" {...register("user_id")} />
                                            <input
                                                type="password"
                                                {...register("current_password")}
                                                placeholder="Current Password"
                                            // className={`form-control ${errors.current_password ? 'is-invalid' : ''}`}
                                            />
                                            <span style={{ color: 'red' }}>{errors.current_password?.message}</span>
                                        </div>
                                        <div className="col-12 col-md-12">
                                            <input
                                                type="password"
                                                {...register("new_password")}
                                                placeholder="New Password"
                                            //className={`form-control ${errors.new_password ? 'is-invalid' : ''}`}
                                            />
                                            <span style={{ color: 'red' }}>{errors.new_password?.message}</span>
                                        </div>
                                        <div className="col-12 col-md-12">
                                            <input
                                                type="password"
                                                {...register("confirm_password")}
                                                placeholder="Confirm Password"
                                            //className={`form-control ${errors.confirm_password ? 'is-invalid' : ''}`}
                                            />
                                            <span style={{ color: 'red' }}>{errors.confirm_password?.message}</span>
                                        </div>
                                    </div>
                                    {
                                        isLoading
                                            ?
                                            <button className="mt-3">
                                                <div className="d-flex align-items-center">
                                                    <strong>Update...</strong>
                                                    <div className="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                                                </div>
                                            </button>
                                            :
                                            <button type='submit' className="mt-3"> Update </button>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
