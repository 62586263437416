import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getDifferentiationDiagnostic, modifyDifferentiationDiagnostic } from "../../state/features/DifferentiationDiagnosticSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';

export default function DifferentiationDiagnosticContentNew() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        DifferentiationDiagnosticD10 : '',
        DifferentiationDiagnosticD11 : '',
        DifferentiationDiagnosticD12 : '',
        DifferentiationDiagnosticD13 : '',
        DifferentiationDiagnosticD14 : '',
        DifferentiationDiagnosticD15 : '',
        DifferentiationDiagnosticD16 : '',
        DifferentiationDiagnosticD17 : '',
        DifferentiationDiagnosticD18 : '',
        DifferentiationDiagnosticD19 : '',
        DifferentiationDiagnosticG10 : '',
        DifferentiationDiagnosticG11 : '',
        DifferentiationDiagnosticG12 : '',
        DifferentiationDiagnosticG13 : '',
        DifferentiationDiagnosticG14 : '',
        DifferentiationDiagnosticG15 : '',
        DifferentiationDiagnosticG16 : '',
        DifferentiationDiagnosticG17 : '',
        DifferentiationDiagnosticG18 : '',
        DifferentiationDiagnosticG19 : '',
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getDifferentiationDiagnostic({user_id}))
        .unwrap()
        .then((response) => {
            //console.log("response",response.data.growthPlanning)
            if(response!= undefined  && response.data.growthPlanning){
                let growthPlanning = response.data.growthPlanning[0];
                console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key]
                    }
                }
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyDifferentiationDiagnostic({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                    // console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        //window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                    console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
            {/* row */}
            <div className="container-fluid">
                <div className="head-title mb-4">
                    <h3 className='preheading'>Tools - Value Creation</h3>
                    <h1 className='mainheading color-dark'>Differentiation Diagnostic</h1>
                    <p className="body-text color-dark">You may think your offering is unique—but how many of your competitors can <strong>make the same claims</strong>?  In the left column, write down the claims your business makes. In the right column, research or consider your competitors. Do they make the same claims that you do? If so, cross out that claim. The claims that they DON’T make are what you need to bring to your customers’ attention.</p>
                </div>
                <div className="customer-transformation">
                    <div className="customer-transformation-inner">
                        <Spinner/>
                        <div className="table-responsive mb-5">
                            {/* customer transformation table 01 */}
                            <table className="table" style={{width: 1240}}>
                                <thead>
                                    <tr>
                                        {/* <th>1</th> */}
                                        <td className='black-bg text-white parsone-width' colSpan={2}>
                                            <div className="before-your d-flex justify-content-between">
                                                <p className="p-0 font16px">Your Claims</p>
                                                <img src={window.location.origin+"/images/differentaition/icon_01.png"} alt="icon_01" />
                                            </div>
                                        </td>
                                        <td className='hide-content'>hide</td>
                                        <td className='black-bg text-white parsone-width' colSpan={2}>
                                            <div className="before-your d-flex justify-content-between">
                                                <p className="p-0 font16px">Who Else Makes That Claim?</p>
                                                <img src={window.location.origin+"/images/differentaition/icon_02.png"} alt="icon_02" />
                                            </div>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px parsone-width-10' style={{width: 50}}>1</td>
                                        <td className='bg-color-light-blue padding-0' style={{width: 550}}>
                                            <div class="input-fiel-cn par-col-width par-col-width">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                id="DifferentiationDiagnosticD10"
                                                value={Inputs.DifferentiationDiagnosticD10}
                                                onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticD10: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                            />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number' style={{width: 50}}>hide</td>
                                        <td className='black-bg text-white text-center font11px parsone-width-10' style={{width: 50}}>1</td>
                                        <td className='bg-color-light-blue padding-0' style={{width: 550}}>
                                            <div class="input-fiel-cn par-col-width">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                    id="DifferentiationDiagnosticG10"
                                                    value={Inputs.DifferentiationDiagnosticG10}
                                                    onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticG10: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>2</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn par-col-width">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                    id="DifferentiationDiagnosticD11"
                                                    value={Inputs.DifferentiationDiagnosticD11}
                                                    onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticD11: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>2</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn par-col-width">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                    id="DifferentiationDiagnosticG11"
                                                    value={Inputs.DifferentiationDiagnosticG11}
                                                    onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticG11: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>3</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn par-col-width">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                    id="DifferentiationDiagnosticD12"
                                                    value={Inputs.DifferentiationDiagnosticD12}
                                                    onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticD12: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>3</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn par-col-width">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                    id="DifferentiationDiagnosticG12"
                                                    value={Inputs.DifferentiationDiagnosticG12}
                                                    onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticG12: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>4</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn par-col-width">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                    id="DifferentiationDiagnosticD13"
                                                    value={Inputs.DifferentiationDiagnosticD13}
                                                    onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticD13: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>4</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn par-col-width">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                    id="DifferentiationDiagnosticG13"
                                                    value={Inputs.DifferentiationDiagnosticG13}
                                                    onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticG13: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>5</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn par-col-width">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                    id="DifferentiationDiagnosticD14"
                                                    value={Inputs.DifferentiationDiagnosticD14}
                                                    onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticD14: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>5</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn par-col-width">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                    id="DifferentiationDiagnosticG14"
                                                    value={Inputs.DifferentiationDiagnosticG14}
                                                    onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticG14: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>6</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn par-col-width">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                    id="DifferentiationDiagnosticD15"
                                                    value={Inputs.DifferentiationDiagnosticD15}
                                                    onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticD15: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>6</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn par-col-width">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                    id="DifferentiationDiagnosticG15"
                                                    value={Inputs.DifferentiationDiagnosticG15}
                                                    onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticG15: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>7</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn par-col-width">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                    id="DifferentiationDiagnosticD16"
                                                    value={Inputs.DifferentiationDiagnosticD16}
                                                    onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticD16: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>7</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn par-col-width">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                    id="DifferentiationDiagnosticG16"
                                                    value={Inputs.DifferentiationDiagnosticG16}
                                                    onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticG16: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>8</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn par-col-width">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                    id="DifferentiationDiagnosticD17"
                                                    value={Inputs.DifferentiationDiagnosticD17}
                                                    onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticD17: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>8</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn par-col-width">
                                                <input type="text" className="field-ipt bg-color-02 font15p"
                                                    id="DifferentiationDiagnosticG17"
                                                    value={Inputs.DifferentiationDiagnosticG17}
                                                    onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticG17: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>9</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn par-col-width">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                    id="DifferentiationDiagnosticD18"
                                                    value={Inputs.DifferentiationDiagnosticD18}
                                                    onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticD18: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>9</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn par-col-width">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                    id="DifferentiationDiagnosticG18"
                                                    value={Inputs.DifferentiationDiagnosticG18}
                                                    onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticG18: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>10</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn par-col-width">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                    id="DifferentiationDiagnosticD19"
                                                    value={Inputs.DifferentiationDiagnosticD19}
                                                    onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticD19: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>10</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn par-col-width">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                    id="DifferentiationDiagnosticG19"
                                                    value={Inputs.DifferentiationDiagnosticG19}
                                                    onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticG19: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {/* customer transformation table 01 */}
                        </div>
                        <div className="table-responsive">
                            {/* customer transformation table 02 */}
                            <table className="table" style={{width: 1240}}>
                                <thead>
                                    <tr>
                                        {/* <th>1</th> */}
                                        <td className='black-bg text-white parsone-width' colSpan={2}>
                                            <div className="before-your d-flex justify-content-between">
                                                <p className="p-0">Potential Differentiators</p>
                                                <img src={window.location.origin+"/images/differentaition/icon_03.png"} alt="icon_03" />
                                            </div>
                                        </td>
                                        <td className='hide-content'>hide</td>
                                        <td className='black-bg text-white parsone-width' colSpan={2}>
                                            <div className="before-your d-flex justify-content-between">
                                                <p className="p-0">These Are NOT Differentiators</p>
                                                <img src={window.location.origin+"/images/differentaition/icon_04.png"} alt="icon_04" />
                                            </div>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px parsone-width-10' style={{width: 50}}>1</td>
                                        <td className='bg-color-light-blue padding-0' style={{width: 550}}>
                                            <div class="input-fiel-cn par-col-width">
                                                <input type="text" className="field-ipt bg-color-02 font11px" defaultValue={"Performance Leadership"} />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number' style={{width: 50}}>hide</td>
                                        <td className='black-bg text-white text-center font11px parsone-width-10' style={{width: 50}}>1</td>
                                        <td className='bg-color-light-blue padding-0' style={{width: 550}}>
                                            <div class="input-fiel-cn par-col-width">
                                                <input type="text" className="field-ipt bg-color-02 font11px" defaultValue={"Quality"} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>2</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn par-col-width">
                                                <input type="text" className="field-ipt bg-color-02 font11px" defaultValue={"Design"} />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>3</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn par-col-width">
                                                <input type="text" className="field-ipt bg-color-02 font11px" defaultValue={"Service"} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>3</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn par-col-width">
                                                <input type="text" className="field-ipt bg-color-02 font11px" defaultValue={"How Product is Made"} />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>3</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn par-col-width">
                                                <input type="text" className="field-ipt bg-color-02 font11px" defaultValue={"Dependability"} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>4</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px" defaultValue={"Where Product is Made"} />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>4</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px" defaultValue={"Your People"} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>5</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px" defaultValue={"Specialization"} />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='hide-content'>hide</td>
                                        <td className='hide-content'>hide</td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>6</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px" defaultValue={"Technology Leadership"} />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='hide-content'>hide</td>
                                        <td className='hide-content'>hide</td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>7</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px" defaultValue={"Next Generation Product"} />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='hide-content'>hide</td>
                                        <td className='hide-content'>hide</td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>8</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px" defaultValue={"Personality"} />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='hide-content'>hide</td>
                                        <td className='hide-content'>hide</td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>9</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px" defaultValue={"Attribute Ownership"} />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='hide-content'>hide</td>
                                        <td className='hide-content'>hide</td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>10</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px" defaultValue={"Sales Leadership"} />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='hide-content'>hide</td>
                                        <td className='hide-content'>hide</td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>11</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px" defaultValue={"Heritage"} />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='hide-content'>hide</td>
                                        <td className='hide-content'>hide</td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>12</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px" defaultValue={"Preference"} />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='hide-content'>hide</td>
                                        <td className='hide-content'>hide</td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>13</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px" defaultValue={"The Opposite Position"} />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='hide-content'>hide</td>
                                        <td className='hide-content'>hide</td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>14</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px" defaultValue={"Unique or Trendy Product or Service"} />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='hide-content'>hide</td>
                                        <td className='hide-content'>hide</td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>15</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px" defaultValue={"Product or Service Specifically for a Market Niche"} />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='hide-content'>hide</td>
                                        <td className='hide-content'>hide</td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>16</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px" defaultValue={"Offer Stands Out as Unique"} />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='hide-content'>hide</td>
                                        <td className='hide-content'>hide</td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>17</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px" defaultValue={"Solve a Problem"} />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='hide-content'>hide</td>
                                        <td className='hide-content'>hide</td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>18</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px" defaultValue={"Message of Value or ROI"} />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='hide-content'>hide</td>
                                        <td className='hide-content'>hide</td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>19</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px" defaultValue={"Guarantee or Risk Reversal"} />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='hide-content'>hide</td>
                                        <td className='hide-content'>hide</td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>20</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px" defaultValue={"Customer Experience"} />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='hide-content'>hide</td>
                                        <td className='hide-content'>hide</td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>21</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px" defaultValue={'Use the Word "Only"'} />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='hide-content'>hide</td>
                                        <td className='hide-content'>hide</td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>22</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px" defaultValue={"Largest Selection or One-Stop Shopping"} />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='hide-content'>hide</td>
                                        <td className='hide-content'>hide</td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>23</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px" defaultValue={"Expertise"} />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='hide-content'>hide</td>
                                        <td className='hide-content'>hide</td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px parsone-width-10'>24</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div class="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px" defaultValue={"Production Process"} />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='hide-content'>hide</td>
                                        <td className='hide-content'>hide</td>
                                    </tr>
                                </tbody>
                            </table>
                            {/* customer transformation table 02 */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
