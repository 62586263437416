import React from 'react'
import  UserDropdown  from '../UserDropdown';

export default function FourForcesOfValueContent() {
  return (
    <div className="content-body">
    <UserDropdown />
    
        {/* row */}
        <div className="container-fluid">
            <div className="head-title">
                <p>Value Creation </p>
                <h2 className="mb-5">Four Forces of Value</h2>
            </div>
            <div className="card">
                <div className="card-body pl-0">
                <div className="table-responsive">
                    <table className="table mt-0 mb-0">
                    <thead>
                        <tr>
                        <td className="bg-color1 width-5 d-hidden">1</td>
                        <td colSpan={8} className="bg-color3 text-white text-center-c font-size-20">Tangible Value</td>
                        <td className="bg-color1 width-5 d-hidden">cell</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td rowSpan={12} className="bg-color1 width-5 text-white add-position-cls">
                            <span className="style-ctn1">Professional Value</span>
                        </td>
                        <td colSpan={4} className="bg-color1 text-white">
                            <div className="forces-div">
                            <span className="forces-img-bx">
                                <img src="../images/forces-icon1.png" alt="forces-icon1" />
                            </span>
                            <span className="forces-span">Return On Investment</span>
                            </div>
                        </td>
                        <td colSpan={4} className="bg-color1 text-white">
                            <div className="forces-div2">
                            <span className="forces-span2">Measurable Improvements</span>
                            <span className="forces-img-bx2">
                                <img src="../images/forces-icon2.png" alt="forces-icon1" />
                            </span>
                            </div>
                        </td>
                        <td rowSpan={12} className="bg-color1 width-5 text-white add-position-cls">
                            <span className="style-ctn">Personal Value</span>
                        </td>
                        </tr>
                        <tr>
                        {/* <td className="bg-color1 width-5">1</td> */}
                        <td colSpan={4} className="text-font-size-14"> Quantifiable growth in revenue and profit </td>
                        <td colSpan={4} className="text-font-size-14 bg-color3 bg-color-blacl"> Build a durable competitive advantage </td>
                        {/* <td className="bg-color1 width-5">cell</td> */}
                        </tr>
                        <tr>
                        {/* <td className="bg-color1 width-5">1</td> */}
                        <td colSpan={4} className="text-font-size-14"> Increase customer retention; reduce attrition/churn </td>
                        <td colSpan={4} className="text-font-size-14 bg-color3 bg-color-blacl"> Faster and continuous progress toward your goals </td>
                        {/* <td className="bg-color1 width-5">cell</td> */}
                        </tr>
                        <tr>
                        {/* <td className="bg-color1 width-5">1</td> */}
                        <td colSpan={4} className="text-font-size-14"> Capture market share </td>
                        <td colSpan={4} className="text-font-size-14 bg-color3 bg-color-blacl"> Teams aligned around shared priorities </td>
                        {/* <td className="bg-color1 width-5">cell</td> */}
                        </tr>
                        <tr>
                        {/* <td className="bg-color1 width-5">1</td> */}
                        <td colSpan={4} className="text-font-size-14"> Improve company valuation </td>
                        <td colSpan={4} className="text-font-size-14 bg-color3 bg-color-blacl"> Reduce time between planning and execution </td>
                        {/* <td className="bg-color1 width-5">cell</td> */}
                        </tr>
                        <tr>
                        {/* <td className="bg-color1 width-5">1</td> */}
                        <td colSpan={4} className="text-font-size-14"> More leads, higher conversion, more referrals </td>
                        <td colSpan={4} className="text-font-size-14 bg-color3 bg-color-blacl"> Improved organization and focus </td>
                        {/* <td className="bg-color1 width-5">cell</td> */}
                        </tr>
                        <tr>
                        {/* <td className="bg-color1 width-5">1</td> */}
                        <td colSpan={4} className="text-font-size-14 bg-color3 bg-color-blacl"> Improved resilience; protect what you've built </td>
                        <td colSpan={4} className="text-font-size-14"> Greater clarity and control; reduce guesswork </td>
                        {/* <td className="bg-color1 width-5">cell</td> */}
                        </tr>
                        <tr>
                        {/* <td className="bg-color1 width-5">1</td> */}
                        <td colSpan={4} className="text-font-size-14 bg-color3 bg-color-blacl"> More innovation; minimal disruption to operations </td>
                        <td colSpan={4} className="text-font-size-14"> Greater repute; build reputation </td>
                        {/* <td className="bg-color1 width-5">cell</td> */}
                        </tr>
                        <tr>
                        {/* <td className="bg-color1 width-5">1</td> */}
                        <td colSpan={4} className="text-font-size-14 bg-color3 bg-color-blacl"> Greater agility and adaptability </td>
                        <td colSpan={4} className="text-font-size-14"> Less stress; greater peace of mind </td>
                        {/* <td className="bg-color1 width-5">cell</td> */}
                        </tr>
                        <tr>
                        {/* <td className="bg-color1 width-5">1</td> */}
                        <td colSpan={4} className="text-font-size-14 bg-color3 bg-color-blacl"> Build an investor-ready business </td>
                        <td colSpan={4} className="text-font-size-14"> Develop growth skills and knowledge	 </td>
                        {/* <td className="bg-color1 width-5">cell</td> */}
                        </tr>
                        <tr>
                        {/* <td className="bg-color1 width-5">1</td> */}
                        <td colSpan={4} className="text-font-size-14 bg-color3 bg-color-blacl"> Develop a growth culture </td>
                        <td colSpan={4} className="text-font-size-14"> Improve morale </td>
                        {/* <td className="bg-color1 width-5">cell</td> */}
                        </tr>
                        <tr>
                        {/* <td className="bg-color1 width-5">1</td> */}
                        <td colSpan={4} className="bg-color1 text-white">
                            <div className="forces-div">
                            <span className="forces-img-bx">
                                <img src="../images/forces-icon1.png" alt="forces-icon1" />
                            </span>
                            <span className="forces-span">Organizational Capabilities</span>
                            </div>
                        </td>
                        <td colSpan={4} className="bg-color1 text-white">
                            <div className="forces-div2">
                            <span className="forces-span2">Personal Benefits</span>
                            <span className="forces-img-bx2">
                                <img src="../images/forces-icon2.png" alt="forces-icon1" />
                            </span>
                            </div>
                        </td>
                        {/* <td className="bg-color1 width-5">cell</td> */}
                        </tr>
                        <tr>
                        <td className="bg-color1 width-5 d-hidden">1</td>
                        <td colSpan={8} className="bg-color3 text-white text-center-c font-size-20">Intangible Value</td>
                        <td className="bg-color1 width-5 d-hidden">cell</td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}
