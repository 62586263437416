import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getWholeBrainMessaging, modifyWholeBrainMessaging } from "../../state/features/WholeBrainMessagingSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';

export default function WholeBrainMessagingContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        WholeBrainMessagingC10 : '',
        WholeBrainMessagingD10 : '',
        WholeBrainMessagingC11 : '',
        WholeBrainMessagingD11 : '',
        WholeBrainMessagingC12 : '',
        WholeBrainMessagingD12 : '',
        WholeBrainMessagingC13 : '',
        WholeBrainMessagingD13 : '',
        WholeBrainMessagingC14 : '',
        WholeBrainMessagingD14 : '',
        WholeBrainMessagingC17 : '',
        WholeBrainMessagingD17 : '',
        WholeBrainMessagingC18 : '',
        WholeBrainMessagingD18 : '',
        WholeBrainMessagingC19 : '',
        WholeBrainMessagingD19 : '',
        WholeBrainMessagingC20 : '',
        WholeBrainMessagingD20 : '',
        WholeBrainMessagingC21 : '',
        WholeBrainMessagingD21 : '',
        WholeBrainMessagingC22 : '',
        WholeBrainMessagingD22 : '',
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getWholeBrainMessaging({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.growthPlanning)
            if(response!= undefined  && response.data.growthPlanning){
                let growthPlanning = response.data.growthPlanning[0];
                console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key]
                    }
                }
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyWholeBrainMessaging({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        //window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

  return (
        <div className="content-body">
            <UserDropdown />
            {/* row */}
            <div className="container-fluid">
                <div className="head-title">
                    <p> Messaging </p>
                    <h2 className="mb-5"> Whole Brain Messaging </h2>
                </div>
                <Spinner />
                <div className="card">
                    <div className="card-body pl-0">
                    <div className="table-responsive table-dta">
                        <table className="table mb-0">
                        <thead>
                            <tr>
                            <th scope="row" colSpan={6} className="bg-color1 text-white padding-10" width="45%">
                                <div className="forces-div">
                                <span className="forces-img-bx">
                                    <img src="../images/whole-icon1.png" alt="forces-icon1" />
                                </span>
                                <span className="forces-span">Analytical  (what)</span>
                                </div>
                            </th>
                            <td colSpan={6} className="bg-color1 text-white padding-10" width="45%">
                                <div className="forces-div">
                                <span className="forces-img-bx">
                                    <img src="../images/whole-icon2.png" alt="forces-icon1" />
                                </span>
                                <span className="forces-span">Experimental  (why)</span>
                                </div>
                            </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td className="bg-color3 text-white padding-15" colSpan={6}>Values facts, ROI, logic, and critical analysis.  Communicate well-articulated ideas and accurate data with brevity.  Include performance goals, objectives and measures, ROI statements and calculators, data and research to support cost benefit analysis.</td>
                            <td className="bg-color3 text-white padding-15" colSpan={6}>
                                Values the big picture, a holistic view, innovative solutions, breakthrough results, flexibility, and the future.  Communicate by exploring ideas, using metaphors and visuals, conceptual frameworks, and strategic fit.  Include stories, process visuals, and a successful future outcome.
                            </td>
                            </tr>
                            <tr>
                            <td className="text-font-size-14 text-font-size-15 height30" colSpan={2}>ROI:</td>
                            <td colSpan={4}>
                                <input type="text" className="form-control inputText"
                                    id="WholeBrainMessagingC10"
                                    value={Inputs.WholeBrainMessagingC10}
                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingC10: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td className="text-font-size-14 text-font-size-15 height30" colSpan={2}>Big Picture:</td>
                            <td colSpan={4}>
                                <input type="text" className="form-control inputText"
                                    id="WholeBrainMessagingD10"
                                    value={Inputs.WholeBrainMessagingD10}
                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingD10: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            </tr>
                            <tr>
                            <td className="text-font-size-14 text-font-size-15 height30" colSpan={2}>Performance:</td>
                            <td colSpan={4}>
                                <input type="text" className="form-control inputText"
                                    id="WholeBrainMessagingC11"
                                    value={Inputs.WholeBrainMessagingC11}
                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingC11: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td className="text-font-size-14 text-font-size-15 height30" colSpan={2}>Innovative:</td>
                            <td colSpan={4}>
                                <input type="text" className="form-control inputText"
                                    id="WholeBrainMessagingD11"
                                    value={Inputs.WholeBrainMessagingD11}
                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingD11: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            </tr>
                            <tr>
                            <td className="text-font-size-14 text-font-size-15 height30" colSpan={2}>Objectives:</td>
                            <td colSpan={4}>
                                <input type="text" className="form-control inputText"
                                    id="WholeBrainMessagingC12"
                                    value={Inputs.WholeBrainMessagingC12}
                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingC12: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td className="text-font-size-14 text-font-size-15 height30" colSpan={2}>Flexible:</td>
                            <td colSpan={4}>
                                <input type="text" className="form-control inputText"
                                    id="WholeBrainMessagingD12"
                                    value={Inputs.WholeBrainMessagingD12}
                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingD12: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            </tr>
                            <tr>
                            <td className="text-font-size-14 text-font-size-15 height30" colSpan={2}>Measures:</td>
                            <td colSpan={4}>
                                <input type="text" className="form-control inputText"
                                    id="WholeBrainMessagingC13"
                                    value={Inputs.WholeBrainMessagingC13}
                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingC13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td className="text-font-size-14 text-font-size-15 height30" colSpan={2}>Future:</td>
                            <td colSpan={4}>
                                <input type="text" className="form-control inputText"
                                    id="WholeBrainMessagingD13"
                                    value={Inputs.WholeBrainMessagingD13}
                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingD13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            </tr>
                            <tr>
                            <td className="text-font-size-14 text-font-size-15 height30" colSpan={2}>Supporting Data:</td>
                            <td colSpan={4}>
                                <input type="text" className="form-control inputText"
                                    id="WholeBrainMessagingC14"
                                    value={Inputs.WholeBrainMessagingC14}
                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingC14: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td className="text-font-size-14 text-font-size-15 height30" colSpan={2}>Strategic Fit:</td>
                            <td colSpan={4}>
                                <input type="text" className="form-control inputText"
                                    id="WholeBrainMessagingD14"
                                    value={Inputs.WholeBrainMessagingD14}
                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingD14: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            </tr>
                        </tbody>
                        </table>
                        <table className="table mb-0">
                        <thead>
                            <tr>
                            <th scope="row" colSpan={6} className="bg-color1 text-white padding-10" width="45%">
                                <div className="forces-div">
                                <span className="forces-img-bx">
                                    <img src="../images/whole-icon3.png" alt="forces-icon1" />
                                </span>
                                <span className="forces-span">Practical  (how &amp; when)</span>
                                </div>
                            </th>
                            <td colSpan={6} className="bg-color1 text-white padding-10" width="45%">
                                <div className="forces-div">
                                <span className="forces-img-bx">
                                    <img src="../images/whole-icon4.png" alt="forces-icon1" />
                                </span>
                                <span className="forces-span">Relational  (who)</span>
                                </div>
                            </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td className="bg-color3 text-white padding-15" colSpan={6}>Values proven processes, safe choices, reduced risk, and procedures.  Communicate references and case studies, step-by-step sequences, and contingency planning.  Include an organized structure and plans and details.  Also agendas, timelines, steps, discuss risks and contingency plans, proposed action plans, and resource requirements.</td>
                            <td className="bg-color3 text-white padding-15" colSpan={6}>Value benefits to people (customers, employees, their teams), how they will feel, and everyone having a chance to contribute.  Communicate openly and informally, find common ground, use expressive body and voice, and listen intently.  Include details about the relationship, shared values, and use partnering language (us, we, our, them).</td>
                            </tr>
                            <tr>
                            <td className="text-font-size-14 text-font-size-15 height30" colSpan={2}>Proven Processes:</td>
                            <td colSpan={4}>
                                <input type="text" className="form-control inputText"
                                    id="WholeBrainMessagingC17"
                                    value={Inputs.WholeBrainMessagingC17}
                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingC17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td className="text-font-size-14 text-font-size-15 height30" colSpan={2}>Benefits to Customers:</td>
                            <td colSpan={4}>
                                <input type="text" className="form-control inputText"
                                    id="WholeBrainMessagingD17"
                                    value={Inputs.WholeBrainMessagingD17}
                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingD17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            </tr>
                            <tr>
                            <td className="text-font-size-14 text-font-size-15 height30" colSpan={2}>Safe Choice &amp; Reduced Risk:</td>
                            <td colSpan={4}>
                                <input type="text" className="form-control inputText"
                                    id="WholeBrainMessagingC18"
                                    value={Inputs.WholeBrainMessagingC18}
                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingC18: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td className="text-font-size-14 text-font-size-15 height30" colSpan={2}>Benefits to Employees:</td>
                            <td colSpan={4}>
                                <input type="text" className="form-control inputText"
                                    id="WholeBrainMessagingD18"
                                    value={Inputs.WholeBrainMessagingD18}
                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingD18: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            </tr>
                            <tr>
                            <td className="text-font-size-14 text-font-size-15 height30" colSpan={2}>Case Studies:</td>
                            <td colSpan={4}>
                                <input type="text" className="form-control inputText"
                                    id="WholeBrainMessagingC19"
                                    value={Inputs.WholeBrainMessagingC19}
                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingC19: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td className="text-font-size-14 text-font-size-15 height30" colSpan={2}>Benefit to Teams:</td>
                            <td colSpan={4}>
                                <input type="text" className="form-control inputText"
                                    id="WholeBrainMessagingD19"
                                    value={Inputs.WholeBrainMessagingD19}
                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingD19: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            </tr>
                            <tr>
                            <td className="text-font-size-14 text-font-size-15 height30" colSpan={2}>Sequence, Action Plan &amp; Timeline:</td>
                            <td colSpan={4}>
                                <input type="text" className="form-control inputText"
                                    id="WholeBrainMessagingC20"
                                    value={Inputs.WholeBrainMessagingC20}
                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingC20: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td className="text-font-size-14 text-font-size-15 height30" colSpan={2}>Everyone Contributes:</td>
                            <td colSpan={4}>
                                <input type="text" className="form-control inputText"
                                    id="WholeBrainMessagingD20"
                                    value={Inputs.WholeBrainMessagingD20}
                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingD20: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            </tr>
                            <tr>
                            <td className="text-font-size-14 text-font-size-15 height30" colSpan={2}>Contingency Planning:</td>
                            <td colSpan={4}>
                                <input type="text" className="form-control inputText"
                                    id="WholeBrainMessagingC21"
                                    value={Inputs.WholeBrainMessagingC21}
                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingC21: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td className="text-font-size-14 text-font-size-15 height30" colSpan={2}>Promotes Open Communications:</td>
                            <td colSpan={4}>
                                <input type="text" className="form-control inputText"
                                    id="WholeBrainMessagingD21"
                                    value={Inputs.WholeBrainMessagingD21}
                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingD21: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            </tr>
                            <tr>
                            <td className="text-font-size-14 text-font-size-15 height30" colSpan={2}>Resource Requirements:</td>
                            <td colSpan={4}>
                                <input type="text" className="form-control inputText"
                                    id="WholeBrainMessagingC22"
                                    value={Inputs.WholeBrainMessagingC22}
                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingC22: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td className="text-font-size-14 text-font-size-15 height30" colSpan={2}>Promotes Shared Values:</td>
                            <td colSpan={4}>
                                <input type="text" className="form-control inputText"
                                    id="WholeBrainMessagingD22"
                                    value={Inputs.WholeBrainMessagingD22}
                                    onChange={(event) => setInputs({ ...Inputs, WholeBrainMessagingD22: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>
                </div>

        </div>
  )
}
