import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getSevenStepCaseForChange, modifySevenStepCaseForChange } from "../../state/features/SevenStepCaseForChangeSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';

export default function CaseForChangeContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        SevenStepCaseForChangeF18 : '',
        SevenStepCaseForChangeF19 : '',
        SevenStepCaseForChangeF20 : '',
        SevenStepCaseForChangeF21 : '',
        SevenStepCaseForChangeF22 : '',
        SevenStepCaseForChangeF23 : '',
        SevenStepCaseForChangeF24 : ''
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getSevenStepCaseForChange({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.revenueGrowth)
            if(response!= undefined  && response.data.revenueGrowth){
                let revenueGrowth = response.data.revenueGrowth[0];
                console.log(revenueGrowth)
                delete revenueGrowth.id;
                delete revenueGrowth.user_id;
                delete revenueGrowth.created_at;
                delete revenueGrowth.updated_at;
                const newObj = { ...Inputs }
                for (const key in revenueGrowth) {
                    if (revenueGrowth.hasOwnProperty(key)) {
                        if(revenueGrowth[key])
                        newObj[key] = revenueGrowth[key]
                    }
                }
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifySevenStepCaseForChange({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
            <div className="container-fluid">
                <div className="head-title">
                    <h3 className='preheading'>Tools - Messaging</h3>
                    <h1 className='mainheading'>Case for Change</h1>
                </div>
                <div className="customer-transformation">
                    <div className="customer-transformation-inner">
                        <Spinner/>
                        <div className="table-responsive">
                            <table className='table' style={{width: 1280}}>
                                <tbody>
                                    <tr>
                                        <td colSpan={4} className='bg-white'>
                                            <p className="body-text color-dark mb-2">The Case for Change template is a tool that provides the Change Manager with one place to capture all the reasons a change needs to occur. Information on this template will be used to craft communications for each relevant audience impacted by the change. </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-center text-white font48px fontExtraBold' style={{width: 100, height: 120}}>
                                            1
                                        </td>
                                        <td className="bg-color-03 ms-text-left" style={{width: 180}}>
                                            <h3 className="growth-titile font16px">Executive Summary</h3>
                                        </td>
                                        <td className='bg-color-blue-gray text-left text-white' style={{width: 300}}>
                                            <div className="font11px">What is the project?  Include scope, objectives, a timeline, milestones, success metrics, cost-benefits analysis, and ROI projection.  Explain your vision for change.</div>
                                        </td>
                                        <td className='padding-0 bg-color-02' style={{width: 700}}>
                                            <div className="input-fiel-cn">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark"
                                                id="SevenStepCaseForChangeF18"
                                                value={Inputs.SevenStepCaseForChangeF18}
                                                onChange={(event) => setInputs({ ...Inputs, SevenStepCaseForChangeF18: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-center text-white font48px fontExtraBold' style={{width: 100, height: 120}}>
                                            2
                                        </td>
                                        <td className="bg-color-03 ms-text-left" style={{width: 150}}>
                                            <h3 className="growth-titile font16px">Problem Statement</h3>
                                        </td>
                                        <td className='bg-color-blue-gray text-left text-white' style={{width: 300}}>
                                            <div className="font11px">What is the reason the project is being initiated?  How can you create urgency to solve the problem?  Can you remove obstacles that could prevent the change?</div>
                                        </td>
                                        <td className='padding-0 bg-color-02'>
                                            <div className="input-fiel-cn">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark"
                                                id="SevenStepCaseForChangeF19"
                                                value={Inputs.SevenStepCaseForChangeF19}
                                                onChange={(event) => setInputs({ ...Inputs, SevenStepCaseForChangeF19: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-center text-white font48px fontExtraBold' style={{width: 100, height: 120}}>
                                            3
                                        </td>
                                        <td className="bg-color-03 ms-text-left" style={{width: 150}}>
                                            <h3 className="growth-titile font16px">Who</h3>
                                        </td>
                                        <td className='bg-color-blue-gray text-left text-white' style={{width: 300}}>
                                            <div className="font11px">Who must be convinced the project is necessary?  Who can you get to support or champion your cause?</div>
                                        </td>
                                        <td className='padding-0 bg-color-02'>
                                            <div className="input-fiel-cn">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark"
                                                id="SevenStepCaseForChangeF20"
                                                value={Inputs.SevenStepCaseForChangeF20}
                                                onChange={(event) => setInputs({ ...Inputs, SevenStepCaseForChangeF20: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-center text-white font48px fontExtraBold' style={{width: 100, height: 120}}>
                                            4
                                        </td>
                                        <td className="bg-color-03 ms-text-left" style={{width: 150}}>
                                            <h3 className="growth-titile font16px">Funding</h3>
                                        </td>
                                        <td className='bg-color-blue-gray text-left text-white' style={{width: 300}}>
                                            <div className="font11px">What is needed in terms of action and funding?</div>
                                        </td>
                                        <td className='padding-0 bg-color-02'>
                                            <div className="input-fiel-cn">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark"
                                                id="SevenStepCaseForChangeF21"
                                                value={Inputs.SevenStepCaseForChangeF21}
                                                onChange={(event) => setInputs({ ...Inputs, SevenStepCaseForChangeF21: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-center text-white font48px fontExtraBold' style={{width: 100, height: 120}}>
                                            5
                                        </td>
                                        <td className="bg-color-03 ms-text-left" style={{width: 150}}>
                                            <h3 className="growth-titile font16px">I Don't Get It</h3>
                                        </td>
                                        <td className='bg-color-blue-gray text-left text-white' style={{width: 300}}>
                                            <div className="font11px">To overcome resistance, it's important to make sure people know why change is necessary.  List the reasons why it is necessary.</div>
                                        </td>
                                        <td className='padding-0 bg-color-02'>
                                            <div className="input-fiel-cn">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark"
                                                id="SevenStepCaseForChangeF22"
                                                value={Inputs.SevenStepCaseForChangeF22}
                                                onChange={(event) => setInputs({ ...Inputs, SevenStepCaseForChangeF22: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-center text-white font48px fontExtraBold' style={{width: 100, height: 120}}>
                                            6
                                        </td>
                                        <td className="bg-color-03 ms-text-left" style={{width: 150}}>
                                            <h3 className="growth-titile font16px">I Don't Like It</h3>
                                        </td>
                                        <td className='bg-color-blue-gray text-left text-white' style={{width: 300}}>
                                            <div className="font11px">This level is about removing as much fear as possible so people can react with less emotion.  List how you can reduce fear:</div>
                                        </td>
                                        <td className='padding-0 bg-color-02'>
                                            <div className="input-fiel-cn">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark"
                                                id="SevenStepCaseForChangeF23"
                                                value={Inputs.SevenStepCaseForChangeF23}
                                                onChange={(event) => setInputs({ ...Inputs, SevenStepCaseForChangeF23: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-center text-white font48px fontExtraBold' style={{width: 100, height: 120}}>
                                            7
                                        </td>
                                        <td className="bg-color-03 ms-text-left" style={{width: 150}}>
                                            <h3 className="growth-titile font16px">I Don't Like You</h3>
                                        </td>
                                        <td className='bg-color-blue-gray text-left text-white' style={{width: 300}}>
                                            <div className="font11px">This level typically occurs not because people dislike the idea, but because they dislike you or other leaders.  List ways to reconnect and mend broken relationships:</div>
                                        </td>
                                        <td className='padding-0 bg-color-02'>
                                            <div className="input-fiel-cn">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark"
                                                id="SevenStepCaseForChangeF24"
                                                value={Inputs.SevenStepCaseForChangeF24}
                                                onChange={(event) => setInputs({ ...Inputs, SevenStepCaseForChangeF24: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    {/* <tr>
                                        <td className='black-bg' style={{width: 100, height: 120}}>
                                        <h1 className="text-center text-white font48px fontExtraBold">1</h1>
                                        </td>
                                        <td className="bg-color-03 ms-text-left" style={{width: 150}}>
                                            <h3 className="growth-titile font16px">Executive Summary</h3>
                                        </td>
                                        <td className='bg-color-blue-gray text-left text-white' style={{width: 300}}>
                                            <div className="font11px">What is the project?  Include scope, objectives, a timeline, milestones, success metrics, cost-benefits analysis, and ROI projection.  Explain your vision for change.</div>
                                        </td>
                                        <td className='bg-color-02'>
                                            <div className="step-case-contetn2">
                                                <textarea className="inputText bg-color-02" style={{borderColor: '#d8e2f2 !important'}} 
                                                    rows={8} 
                                                    cols={24} 
                                                    id="SevenStepCaseForChangeF18"
                                                    value={Inputs.SevenStepCaseForChangeF18}
                                                    onChange={(event) => setInputs({ ...Inputs, SevenStepCaseForChangeF18: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr> */}
                                </tbody>
                            </table>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
