import sum from "../../utils/sum";
const initialState = {
    whatsDrivingGrowthE29: 0,
    whatsDrivingGrowthF29: 0,
    whatsDrivingGrowthG29: 0,
    whatsDrivingGrowthH29: 0,
    whatsDrivingGrowthI29: 0,
    whatsDrivingGrowthJ29: 0,
    whatsDrivingGrowthE30: 0,
    whatsDrivingGrowthF30: 0,
    whatsDrivingGrowthG30: 0,
    whatsDrivingGrowthH30: 0,
    whatsDrivingGrowthI30: 0,
    whatsDrivingGrowthJ30: 0,
    whatsDrivingGrowthE31: 0,
    whatsDrivingGrowthF31: 0,
    whatsDrivingGrowthG31: 0,
    whatsDrivingGrowthH31: 0,
    whatsDrivingGrowthI31: 0,
    whatsDrivingGrowthJ31: 0,
    whatsDrivingGrowthE32: 0,
    whatsDrivingGrowthF32: 0,
    whatsDrivingGrowthG32: 0,
    whatsDrivingGrowthH32: 0,
    whatsDrivingGrowthI32: 0,
    whatsDrivingGrowthJ32: 0,
    whatsDrivingGrowthE33: 0,
    whatsDrivingGrowthF33: 0,
    whatsDrivingGrowthG33: 0,
    whatsDrivingGrowthH33: 0,
    whatsDrivingGrowthI33: 0,
    whatsDrivingGrowthJ33: 0,
    whatsDrivingGrowthE34: 0,
    whatsDrivingGrowthF34: 0,
    whatsDrivingGrowthG34: 0,
    whatsDrivingGrowthH34: 0,
    whatsDrivingGrowthI34: 0,
    whatsDrivingGrowthJ34: 0,
    whatsDrivingGrowthE35: 0,
    whatsDrivingGrowthF35: 0,
    whatsDrivingGrowthG35: 0,
    whatsDrivingGrowthH35: 0,
    whatsDrivingGrowthI35: 0,
    whatsDrivingGrowthJ35: 0,
    whatsDrivingGrowthE36: 0,
    whatsDrivingGrowthF36: 0,
    whatsDrivingGrowthG36: 0,
    whatsDrivingGrowthH36: 0,
    whatsDrivingGrowthI36: 0,
    whatsDrivingGrowthJ36: 0,
    whatsDrivingGrowthD38: 0,
    whatsDrivingGrowthE38: 0,
    whatsDrivingGrowthF38: 0,
    whatsDrivingGrowthG38: 0,
    whatsDrivingGrowthH38: 0,
    whatsDrivingGrowthI38: 0,
    whatsDrivingGrowthJ38: 0,
    whatsDrivingGrowthJ39: 0,
    whatsDrivingGrowthD48: 0,
    whatsDrivingGrowthE48: 0,
    whatsDrivingGrowthD49: 0,
    whatsDrivingGrowthE49: 0,
    whatsDrivingGrowthD50: 0,
    whatsDrivingGrowthE50: 0,
    whatsDrivingGrowthD61: 0,
    whatsDrivingGrowthE61: 0,
    whatsDrivingGrowthG61: 0,
    whatsDrivingGrowthH61: 0,
    whatsDrivingGrowthI61: 0,
    whatsDrivingGrowthD62: 0,
    whatsDrivingGrowthE62: 0,
    whatsDrivingGrowthG62: 0,
    whatsDrivingGrowthH62: 0,
    whatsDrivingGrowthI62: 0,
    whatsDrivingGrowthD63: 0,
    whatsDrivingGrowthE63: 0,
    whatsDrivingGrowthG63: 0,
    whatsDrivingGrowthH63: 0,
    whatsDrivingGrowthI63: 0,
    whatsDrivingGrowthD64: 0,
    whatsDrivingGrowthE64: 0,
    whatsDrivingGrowthG64: 0,
    whatsDrivingGrowthH64: 0,
    whatsDrivingGrowthI64: 0,
    whatsDrivingGrowthD65: 0,
    whatsDrivingGrowthE65: 0,
    whatsDrivingGrowthG65: 0,
    whatsDrivingGrowthH65: 0,
    whatsDrivingGrowthI65: 0,
    whatsDrivingGrowthD66: 0,
    whatsDrivingGrowthE66: 0,
    whatsDrivingGrowthG66: 0,
    whatsDrivingGrowthH66: 0,
    whatsDrivingGrowthI66: 0,
    whatsDrivingGrowthD67: 0,
    whatsDrivingGrowthE67: 0,
    whatsDrivingGrowthG67: 0,
    whatsDrivingGrowthH67: 0,
    whatsDrivingGrowthI67: 0,
    whatsDrivingGrowthD68: 0,
    whatsDrivingGrowthE68: 0,
    whatsDrivingGrowthG68: 0,
    whatsDrivingGrowthH68: 0,
    whatsDrivingGrowthI68: 0,
    whatsDrivingGrowthD69: 0,
    whatsDrivingGrowthE69: 0,
    whatsDrivingGrowthF69: 0,
    whatsDrivingGrowthI69: 0,
    whatsDrivingGrowthE39: 0,
    whatsDrivingGrowthF39: 0,
    whatsDrivingGrowthG39: 0,
    whatsDrivingGrowthH39: 0,
    whatsDrivingGrowthI39: 0,
}
const reducer = (state = initialState, action) => {
    if (action.type === 'whatsDrivingGrowthD60') {
        return {
            ...state, //copying the original state
            whatsDrivingGrowthD60: (action.payload) ? parseFloat(action.payload) : 0
        }
    }
    else if (action.type === 'whatsDrivingGrowthImp') {
        //const whatsDrivingGrowthD60 = state.whatsDrivingGrowthD60

        const { clientInputsD11, leadGeneration, conversion, referrals, purchaseFrequency, buyingLifeTime, reducingAttrition, customerReactivation, averageTransactionValue } = action.payload

        const whatsDrivingGrowthD60 = parseFloat(clientInputsD11).toFixed(2)

        const { leadGenerationE25, leadGenerationF25, leadGenerationG25, leadGenerationH25, leadGenerationI25, } = leadGeneration
        const { conversionE33, conversionF33, conversionG33, conversionH33, conversionI33, } = conversion
        const { referralsE22, referralsF22, referralsG22, referralsH22, referralsI22, } = referrals
        const { purchaseFrequencyE22, purchaseFrequencyF22, purchaseFrequencyG22, purchaseFrequencyH22, purchaseFrequencyI22, } = purchaseFrequency
        const { buyingLifeTimeE23, buyingLifeTimeF23, buyingLifeTimeG23, buyingLifeTimeH23, buyingLifeTimeI23, } = buyingLifeTime
        const { reducingAttritionE30, reducingAttritionF30, reducingAttritionG30, reducingAttritionH30, reducingAttritionI30, } = reducingAttrition
        const { customerReactivationE22, customerReactivationF22, customerReactivationG22, customerReactivationH22, customerReactivationI22, } = customerReactivation
        const { averageTransactionValueE22, averageTransactionValueF22, averageTransactionValueG22, averageTransactionValueH22, averageTransactionValueI22, } = averageTransactionValue

        let whatsDrivingGrowthE29 = parseFloat(leadGenerationE25).toFixed(2)
        let whatsDrivingGrowthF29 = parseFloat(leadGenerationF25).toFixed(2)
        let whatsDrivingGrowthG29 = parseFloat(leadGenerationG25).toFixed(2)
        let whatsDrivingGrowthH29 = parseFloat(leadGenerationH25).toFixed(2)
        let whatsDrivingGrowthI29 = parseFloat(leadGenerationI25).toFixed(2)
        let whatsDrivingGrowthJ29 = (sum(whatsDrivingGrowthE29, whatsDrivingGrowthF29, whatsDrivingGrowthG29, whatsDrivingGrowthH29, whatsDrivingGrowthI29)).toFixed(2)

        let whatsDrivingGrowthE30 = parseFloat(conversionE33).toFixed(2)
        let whatsDrivingGrowthF30 = parseFloat(conversionF33).toFixed(2)
        let whatsDrivingGrowthG30 = parseFloat(conversionG33).toFixed(2)
        let whatsDrivingGrowthH30 = parseFloat(conversionH33).toFixed(2)
        let whatsDrivingGrowthI30 = parseFloat(conversionI33).toFixed(2)
        let whatsDrivingGrowthJ30 = (sum(whatsDrivingGrowthE30, whatsDrivingGrowthF30, whatsDrivingGrowthG30, whatsDrivingGrowthH30, whatsDrivingGrowthI30)).toFixed(2)

        let whatsDrivingGrowthE31 = parseFloat(referralsE22).toFixed(2)
        let whatsDrivingGrowthF31 = parseFloat(referralsF22).toFixed(2)
        let whatsDrivingGrowthG31 = parseFloat(referralsG22).toFixed(2)
        let whatsDrivingGrowthH31 = parseFloat(referralsH22).toFixed(2)
        let whatsDrivingGrowthI31 = parseFloat(referralsI22).toFixed(2)
        let whatsDrivingGrowthJ31 = (sum(whatsDrivingGrowthE31, whatsDrivingGrowthF31, whatsDrivingGrowthG31, whatsDrivingGrowthH31, whatsDrivingGrowthI31)).toFixed(2)

        let whatsDrivingGrowthE32 = parseFloat(purchaseFrequencyE22).toFixed(2)
        let whatsDrivingGrowthF32 = parseFloat(purchaseFrequencyF22).toFixed(2)
        let whatsDrivingGrowthG32 = parseFloat(purchaseFrequencyG22).toFixed(2)
        let whatsDrivingGrowthH32 = parseFloat(purchaseFrequencyH22).toFixed(2)
        let whatsDrivingGrowthI32 = parseFloat(purchaseFrequencyI22).toFixed(2)
        let whatsDrivingGrowthJ32 = (sum(whatsDrivingGrowthE32, whatsDrivingGrowthF32, whatsDrivingGrowthG32, whatsDrivingGrowthH32, whatsDrivingGrowthI32)).toFixed(2)

        let whatsDrivingGrowthE33 = parseFloat(buyingLifeTimeE23).toFixed(2)
        let whatsDrivingGrowthF33 = parseFloat(buyingLifeTimeF23).toFixed(2)
        let whatsDrivingGrowthG33 = parseFloat(buyingLifeTimeG23).toFixed(2)
        let whatsDrivingGrowthH33 = parseFloat(buyingLifeTimeH23).toFixed(2)
        let whatsDrivingGrowthI33 = parseFloat(buyingLifeTimeI23).toFixed(2)
        let whatsDrivingGrowthJ33 = (sum(whatsDrivingGrowthE33, whatsDrivingGrowthF33, whatsDrivingGrowthG33, whatsDrivingGrowthH33, whatsDrivingGrowthI33)).toFixed(2)

        let whatsDrivingGrowthE34 = parseFloat(reducingAttritionE30).toFixed(2)
        let whatsDrivingGrowthF34 = parseFloat(reducingAttritionF30).toFixed(2)
        let whatsDrivingGrowthG34 = parseFloat(reducingAttritionG30).toFixed(2)
        let whatsDrivingGrowthH34 = parseFloat(reducingAttritionH30).toFixed(2)
        let whatsDrivingGrowthI34 = parseFloat(reducingAttritionI30).toFixed(2)
        let whatsDrivingGrowthJ34 = (sum(whatsDrivingGrowthE34, whatsDrivingGrowthF34, whatsDrivingGrowthG34, whatsDrivingGrowthH34, whatsDrivingGrowthI34)).toFixed(2)

        let whatsDrivingGrowthE35 = parseFloat(customerReactivationE22).toFixed(2)
        let whatsDrivingGrowthF35 = parseFloat(customerReactivationF22).toFixed(2)
        let whatsDrivingGrowthG35 = parseFloat(customerReactivationG22).toFixed(2)
        let whatsDrivingGrowthH35 = parseFloat(customerReactivationH22).toFixed(2)
        let whatsDrivingGrowthI35 = parseFloat(customerReactivationI22).toFixed(2)
        let whatsDrivingGrowthJ35 = (sum(whatsDrivingGrowthE35, whatsDrivingGrowthF35, whatsDrivingGrowthG35, whatsDrivingGrowthH35, whatsDrivingGrowthI35)).toFixed(2)

        let whatsDrivingGrowthE36 = parseFloat(averageTransactionValueE22).toFixed(2)
        let whatsDrivingGrowthF36 = parseFloat(averageTransactionValueF22).toFixed(2)
        let whatsDrivingGrowthG36 = parseFloat(averageTransactionValueG22).toFixed(2)
        let whatsDrivingGrowthH36 = parseFloat(averageTransactionValueH22).toFixed(2)
        let whatsDrivingGrowthI36 = parseFloat(averageTransactionValueI22).toFixed(2)
        let whatsDrivingGrowthJ36 = (sum(whatsDrivingGrowthE36, whatsDrivingGrowthF36, whatsDrivingGrowthG36, whatsDrivingGrowthH36, whatsDrivingGrowthI36)).toFixed(2)

        let whatsDrivingGrowthD38 = parseFloat(clientInputsD11).toFixed(2)
        let whatsDrivingGrowthE38 = (sum(whatsDrivingGrowthE29, whatsDrivingGrowthE30, whatsDrivingGrowthE31, whatsDrivingGrowthE32, whatsDrivingGrowthE33, whatsDrivingGrowthE34, whatsDrivingGrowthE35, whatsDrivingGrowthE36)).toFixed(2)
        let whatsDrivingGrowthF38 = (sum(whatsDrivingGrowthF29, whatsDrivingGrowthF30, whatsDrivingGrowthF31, whatsDrivingGrowthF32, whatsDrivingGrowthF33, whatsDrivingGrowthF34, whatsDrivingGrowthF35, whatsDrivingGrowthF36)).toFixed(2)
        let whatsDrivingGrowthG38 = (sum(whatsDrivingGrowthG29, whatsDrivingGrowthG30, whatsDrivingGrowthG31, whatsDrivingGrowthG32, whatsDrivingGrowthG33, whatsDrivingGrowthG34, whatsDrivingGrowthG35, whatsDrivingGrowthG36)).toFixed(2)
        let whatsDrivingGrowthH38 = (sum(whatsDrivingGrowthH29, whatsDrivingGrowthH30, whatsDrivingGrowthH31, whatsDrivingGrowthH32, whatsDrivingGrowthH33, whatsDrivingGrowthH34, whatsDrivingGrowthH35, whatsDrivingGrowthH36)).toFixed(2)
        let whatsDrivingGrowthI38 = (sum(whatsDrivingGrowthI29, whatsDrivingGrowthI30, whatsDrivingGrowthI31, whatsDrivingGrowthI32, whatsDrivingGrowthI33, whatsDrivingGrowthI34, whatsDrivingGrowthI35, whatsDrivingGrowthI36)).toFixed(2)
        let whatsDrivingGrowthJ38 = (sum(whatsDrivingGrowthJ29, whatsDrivingGrowthJ30, whatsDrivingGrowthJ31, whatsDrivingGrowthJ32, whatsDrivingGrowthJ33, whatsDrivingGrowthJ34, whatsDrivingGrowthJ35, whatsDrivingGrowthJ36)).toFixed(2)

        let whatsDrivingGrowthE39 = (sum(whatsDrivingGrowthD38, whatsDrivingGrowthE38)).toFixed(2)
        let whatsDrivingGrowthF39 = (sum(whatsDrivingGrowthF38, whatsDrivingGrowthE39)).toFixed(2)
        let whatsDrivingGrowthG39 = (sum(whatsDrivingGrowthG38, whatsDrivingGrowthF39)).toFixed(2)
        let whatsDrivingGrowthH39 = (sum(whatsDrivingGrowthH38, whatsDrivingGrowthG39)).toFixed(2)
        let whatsDrivingGrowthI39 = (sum(whatsDrivingGrowthI38, whatsDrivingGrowthH39)).toFixed(2)
        let whatsDrivingGrowthJ39 = (sum(whatsDrivingGrowthD38, whatsDrivingGrowthE38, whatsDrivingGrowthF38, whatsDrivingGrowthG38, whatsDrivingGrowthH38, whatsDrivingGrowthI38)).toFixed(2)

        let whatsDrivingGrowthD48 = (sum(whatsDrivingGrowthJ29, whatsDrivingGrowthJ30, whatsDrivingGrowthJ31)).toFixed(2)
        let whatsDrivingGrowthE48 = (parseFloat(whatsDrivingGrowthD48) / parseFloat(whatsDrivingGrowthJ38) * 100).toFixed(2)
        let whatsDrivingGrowthD49 = (sum(whatsDrivingGrowthJ32, whatsDrivingGrowthJ33, whatsDrivingGrowthJ34, whatsDrivingGrowthJ35)).toFixed(2)
        let whatsDrivingGrowthE49 = (parseFloat(whatsDrivingGrowthD49) / parseFloat(whatsDrivingGrowthJ38) * 100).toFixed(2)
        let whatsDrivingGrowthD50 = (parseFloat(whatsDrivingGrowthJ36)).toFixed(2)
        let whatsDrivingGrowthE50 = (parseFloat(whatsDrivingGrowthD50) / parseFloat(whatsDrivingGrowthJ38) * 100).toFixed(2)

        let whatsDrivingGrowthE60 = (parseFloat(whatsDrivingGrowthD60)).toFixed(2)
        let whatsDrivingGrowthD61 = (parseFloat(whatsDrivingGrowthJ29)).toFixed(2)
        let whatsDrivingGrowthE61 = (parseFloat(whatsDrivingGrowthE60) + parseFloat(whatsDrivingGrowthD61)).toFixed(2)
        let whatsDrivingGrowthG61 = (parseFloat(whatsDrivingGrowthE60)).toFixed(2)
        let whatsDrivingGrowthH61 = (parseFloat(whatsDrivingGrowthE61)).toFixed(2)
        let whatsDrivingGrowthI61 = (parseFloat(whatsDrivingGrowthH61)).toFixed(2)

        let whatsDrivingGrowthD62 = (parseFloat(whatsDrivingGrowthJ30)).toFixed(2)
        let whatsDrivingGrowthE62 = (parseFloat(whatsDrivingGrowthE61) + parseFloat(whatsDrivingGrowthD62)).toFixed(2)
        let whatsDrivingGrowthG62 = (parseFloat(whatsDrivingGrowthE61)).toFixed(2)
        let whatsDrivingGrowthH62 = (parseFloat(whatsDrivingGrowthE62)).toFixed(2)
        let whatsDrivingGrowthI62 = (parseFloat(whatsDrivingGrowthH62)).toFixed(2)

        let whatsDrivingGrowthD63 = (parseFloat(whatsDrivingGrowthJ31)).toFixed(2)
        let whatsDrivingGrowthE63 = (parseFloat(whatsDrivingGrowthE62) + parseFloat(whatsDrivingGrowthD63)).toFixed(2)
        let whatsDrivingGrowthG63 = (parseFloat(whatsDrivingGrowthE62)).toFixed(2)
        let whatsDrivingGrowthH63 = (parseFloat(whatsDrivingGrowthE63)).toFixed(2)
        let whatsDrivingGrowthI63 = (parseFloat(whatsDrivingGrowthH63)).toFixed(2)

        let whatsDrivingGrowthD64 = (parseFloat(whatsDrivingGrowthJ32)).toFixed(2)
        let whatsDrivingGrowthE64 = (parseFloat(whatsDrivingGrowthE63) + parseFloat(whatsDrivingGrowthD64)).toFixed(2)
        let whatsDrivingGrowthG64 = (parseFloat(whatsDrivingGrowthE63)).toFixed(2)
        let whatsDrivingGrowthH64 = (parseFloat(whatsDrivingGrowthE64)).toFixed(2)
        let whatsDrivingGrowthI64 = (parseFloat(whatsDrivingGrowthH64)).toFixed(2)

        let whatsDrivingGrowthD65 = (parseFloat(whatsDrivingGrowthJ33)).toFixed(2)
        let whatsDrivingGrowthE65 = (parseFloat(whatsDrivingGrowthE64) + parseFloat(whatsDrivingGrowthD65)).toFixed(2)
        let whatsDrivingGrowthG65 = (parseFloat(whatsDrivingGrowthE64)).toFixed(2)
        let whatsDrivingGrowthH65 = (parseFloat(whatsDrivingGrowthE65)).toFixed(2)
        let whatsDrivingGrowthI65 = (parseFloat(whatsDrivingGrowthH65)).toFixed(2)

        let whatsDrivingGrowthD66 = (parseFloat(whatsDrivingGrowthJ34)).toFixed(2)
        let whatsDrivingGrowthE66 = (parseFloat(whatsDrivingGrowthE65) + parseFloat(whatsDrivingGrowthD66)).toFixed(2)
        let whatsDrivingGrowthG66 = (parseFloat(whatsDrivingGrowthE65)).toFixed(2)
        let whatsDrivingGrowthH66 = (parseFloat(whatsDrivingGrowthE66)).toFixed(2)
        let whatsDrivingGrowthI66 = (parseFloat(whatsDrivingGrowthH66)).toFixed(2)

        let whatsDrivingGrowthD67 = (parseFloat(whatsDrivingGrowthJ35)).toFixed(2)
        let whatsDrivingGrowthE67 = (parseFloat(whatsDrivingGrowthE66) + parseFloat(whatsDrivingGrowthD67)).toFixed(2)
        let whatsDrivingGrowthG67 = (parseFloat(whatsDrivingGrowthE66)).toFixed(2)
        let whatsDrivingGrowthH67 = (parseFloat(whatsDrivingGrowthE67)).toFixed(2)
        let whatsDrivingGrowthI67 = (parseFloat(whatsDrivingGrowthH67)).toFixed(2)

        let whatsDrivingGrowthD68 = (parseFloat(whatsDrivingGrowthJ36)).toFixed(2)
        let whatsDrivingGrowthE68 = (parseFloat(whatsDrivingGrowthE67) + parseFloat(whatsDrivingGrowthD68)).toFixed(2)
        let whatsDrivingGrowthG68 = (parseFloat(whatsDrivingGrowthE67)).toFixed(2)
        let whatsDrivingGrowthH68 = (parseFloat(whatsDrivingGrowthE68)).toFixed(2)
        let whatsDrivingGrowthI68 = (parseFloat(whatsDrivingGrowthH68)).toFixed(2)

        let whatsDrivingGrowthD69 = (sum(whatsDrivingGrowthD60,whatsDrivingGrowthD61,whatsDrivingGrowthD62,whatsDrivingGrowthD63,whatsDrivingGrowthD64,whatsDrivingGrowthD65,whatsDrivingGrowthD66,whatsDrivingGrowthD67, whatsDrivingGrowthD68)).toFixed(2)
        let whatsDrivingGrowthE69 = (parseFloat(whatsDrivingGrowthE68)).toFixed(2)
        let whatsDrivingGrowthF69 = (parseFloat(whatsDrivingGrowthE69)).toFixed(2)
        let whatsDrivingGrowthI69 = (parseFloat(whatsDrivingGrowthI68)).toFixed(2)


        return {
            ...state,
            whatsDrivingGrowthE29,
            whatsDrivingGrowthF29,
            whatsDrivingGrowthG29,
            whatsDrivingGrowthH29,
            whatsDrivingGrowthI29,
            whatsDrivingGrowthJ29,
            whatsDrivingGrowthE30,
            whatsDrivingGrowthF30,
            whatsDrivingGrowthG30,
            whatsDrivingGrowthH30,
            whatsDrivingGrowthI30,
            whatsDrivingGrowthJ30,
            whatsDrivingGrowthE31,
            whatsDrivingGrowthF31,
            whatsDrivingGrowthG31,
            whatsDrivingGrowthH31,
            whatsDrivingGrowthI31,
            whatsDrivingGrowthJ31,
            whatsDrivingGrowthE32,
            whatsDrivingGrowthF32,
            whatsDrivingGrowthG32,
            whatsDrivingGrowthH32,
            whatsDrivingGrowthI32,
            whatsDrivingGrowthJ32,
            whatsDrivingGrowthE33,
            whatsDrivingGrowthF33,
            whatsDrivingGrowthG33,
            whatsDrivingGrowthH33,
            whatsDrivingGrowthI33,
            whatsDrivingGrowthJ33,
            whatsDrivingGrowthE34,
            whatsDrivingGrowthF34,
            whatsDrivingGrowthG34,
            whatsDrivingGrowthH34,
            whatsDrivingGrowthI34,
            whatsDrivingGrowthJ34,
            whatsDrivingGrowthE35,
            whatsDrivingGrowthF35,
            whatsDrivingGrowthG35,
            whatsDrivingGrowthH35,
            whatsDrivingGrowthI35,
            whatsDrivingGrowthJ35,
            whatsDrivingGrowthE36,
            whatsDrivingGrowthF36,
            whatsDrivingGrowthG36,
            whatsDrivingGrowthH36,
            whatsDrivingGrowthI36,
            whatsDrivingGrowthJ36,
            whatsDrivingGrowthD38,
            whatsDrivingGrowthE38,
            whatsDrivingGrowthF38,
            whatsDrivingGrowthG38,
            whatsDrivingGrowthH38,
            whatsDrivingGrowthI38,
            whatsDrivingGrowthJ38,
            whatsDrivingGrowthJ39,
            whatsDrivingGrowthD48,
            whatsDrivingGrowthE48,
            whatsDrivingGrowthD49,
            whatsDrivingGrowthE49,
            whatsDrivingGrowthD50,
            whatsDrivingGrowthE50,
            whatsDrivingGrowthE60,
            whatsDrivingGrowthD61,
            whatsDrivingGrowthE61,
            whatsDrivingGrowthG61,
            whatsDrivingGrowthH61,
            whatsDrivingGrowthI61,
            whatsDrivingGrowthD62,
            whatsDrivingGrowthE62,
            whatsDrivingGrowthG62,
            whatsDrivingGrowthH62,
            whatsDrivingGrowthI62,
            whatsDrivingGrowthD63,
            whatsDrivingGrowthE63,
            whatsDrivingGrowthG63,
            whatsDrivingGrowthH63,
            whatsDrivingGrowthI63,
            whatsDrivingGrowthD64,
            whatsDrivingGrowthE64,
            whatsDrivingGrowthG64,
            whatsDrivingGrowthH64,
            whatsDrivingGrowthI64,
            whatsDrivingGrowthD65,
            whatsDrivingGrowthE65,
            whatsDrivingGrowthG65,
            whatsDrivingGrowthH65,
            whatsDrivingGrowthI65,
            whatsDrivingGrowthD66,
            whatsDrivingGrowthE66,
            whatsDrivingGrowthG66,
            whatsDrivingGrowthH66,
            whatsDrivingGrowthI66,
            whatsDrivingGrowthD67,
            whatsDrivingGrowthE67,
            whatsDrivingGrowthG67,
            whatsDrivingGrowthH67,
            whatsDrivingGrowthI67,
            whatsDrivingGrowthD68,
            whatsDrivingGrowthE68,
            whatsDrivingGrowthG68,
            whatsDrivingGrowthH68,
            whatsDrivingGrowthI68,
            whatsDrivingGrowthD69,
            whatsDrivingGrowthE69,
            whatsDrivingGrowthF69,
            whatsDrivingGrowthI69,
            whatsDrivingGrowthE39,
            whatsDrivingGrowthF39,
            whatsDrivingGrowthG39,
            whatsDrivingGrowthH39,
            whatsDrivingGrowthI39,
        }

    }
    else {
        return state
    }
}

export default reducer