import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, userData } from "../state/features/AuthenticationSlice";
import { useAlert } from 'react-alert'
import React, { useState, useEffect } from 'react'
import { getClientInputs } from "../state/features/ClentInputSlice";
import SidebarNew from '../components/SidebarNew';
import Footer from '../components/Footer';
import  UserDropdown  from '../components/UserDropdown';
import {Bars} from "react-loader-spinner";
import { bindActionCreators } from 'redux';
import { actionCreators } from '../state/index';
import { StatesUseEffect } from '../utils/StatesUseEffect';

function StateUpdateRoute({ children }) {
    const [isLoading, setIsLoading] = useState(false);
    const [redirectFlag, setRedirectFlag] = useState(false)
    const alert = useAlert();
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(selectUser);
    const users = useSelector(userData);
    const userID = users.data.profile.id;
    const { averageTransactionValueData, purchaseFrequencyData, buyingLifeTimeData, profitMarginData, impactof120GrowthOSData, leadGenerationData, conversionData, referralsData, reducingAttritionData, customerReactivationData } = bindActionCreators(actionCreators, dispatch)

    useEffect(() => {
        setIsLoading(true)
        // Fetch client Inputs
        dispatch(getClientInputs(userID))
        .unwrap()
        .then(async (response) => {
            if(response!= undefined){
                let clientRes = await response.data;
                if(clientRes && clientRes.clientInputs){
                    let clientInputsRes = clientRes.clientInputs
                    let improvementPercentage = clientRes.improvementPercentage
                    let leadGenerationD16 = improvementPercentage.leadGenerationD16 ? improvementPercentage.leadGenerationD16 : 0;
                    let conversionD14 = improvementPercentage.conversionD14 ? improvementPercentage.conversionD14 : 0;
                    let referralsD13 = improvementPercentage.referralsD13 ? improvementPercentage.referralsD13 : 0;
                    let purchaseFrequencyD13 = improvementPercentage.purchaseFrequencyD13 ? improvementPercentage.purchaseFrequencyD13 : 0;
                    let buyingLifeTimeD13 = improvementPercentage.buyingLifeTimeD13 ? improvementPercentage.buyingLifeTimeD13 : 0;
                    let reducingAttritionD13 = improvementPercentage.reducingAttritionD13 ? improvementPercentage.reducingAttritionD13 : 0;
                    let customerReactivationD13 = improvementPercentage.customerReactivationD13 ? improvementPercentage.customerReactivationD13 : 0;
                    let averageTransactionValueD13 = improvementPercentage.averageTransactionValueD13 ? improvementPercentage.averageTransactionValueD13 : 0;
                    let profitMarginD13 = improvementPercentage.profitMarginD13 ? improvementPercentage.profitMarginD13 : 0;
                    let purchaseFrequencyD12 = (parseFloat(clientInputsRes.clientInputsH30) / parseFloat(clientInputsRes.clientInputsH16)) ? parseFloat(clientInputsRes.clientInputsH30) / parseFloat(clientInputsRes.clientInputsH16) : 0 

                    for await (const [key, value] of Object.entries(clientInputsRes)) {
                        if((key === 'clientInputsH19' || key === 'clientInputsH30' || key === 'clientInputsH33' || key === 'clientInputsH36' || key === 'clientInputsH41'|| key === 'clientInputsH25'|| key === 'clientInputsH26') && (parseFloat(value) === 0 || value === null)){
                            setRedirectFlag(true)
                        }
                    }  
                    
                    return Promise.resolve()
                        .then(function() {
                            // console.log('averageTransactionValueD12');
                            return averageTransactionValueData("averageTransactionValueD12",clientInputsRes.clientInputsH33)
                        })
                        .then(function() {
                            // console.log('averageTransactionValueD13')
                            return averageTransactionValueData("averageTransactionValueD13",averageTransactionValueD13)
                        })
                        .then(function() {
                            // console.log('purchaseFrequencyD12');
                            return purchaseFrequencyData("purchaseFrequencyD12",purchaseFrequencyD12)
                        })
                        .then(function() {
                            // console.log('purchaseFrequencyD13')
                            return purchaseFrequencyData("purchaseFrequencyD13",purchaseFrequencyD13)
                        })
                        .then(function() {
                            // console.log('buyingLifeTimeD12');
                            return buyingLifeTimeData("buyingLifeTimeD12",clientInputsRes.clientInputsH19)
                        })
                        .then(function() {
                            // console.log('buyingLifeTimeD13')
                            return buyingLifeTimeData("buyingLifeTimeD13",buyingLifeTimeD13)
                        })
                        .then(function() {
                            // console.log('profitMarginD12');
                            return profitMarginData("profitMarginD12",clientInputsRes.clientInputsH36)
                        })
                        .then(function() {
                            // console.log('profitMarginD13')
                            return profitMarginData("profitMarginD13",profitMarginD13)
                        })
                        .then(function() {
                            // console.log('leadGenerationD15')
                            return leadGenerationData("leadGenerationD15", clientInputsRes.clientInputsH25)
                        })
                        .then(function() {
                            // console.log('leadGenerationD16')
                            return leadGenerationData("leadGenerationD16", leadGenerationD16)
                        })
                        .then(function() {
                            // console.log('conversionD13');
                            return conversionData("conversionD13", clientInputsRes.clientInputsH26)
                        })
                        .then(function() {
                            // console.log('conversionD14')
                            return conversionData("conversionD14", conversionD14)
                        })
                        .then(function() {
                            // console.log('referralsD12');
                            return referralsData("referralsD12", clientInputsRes.clientInputsH27)
                        })
                        .then(function() {
                            // console.log('referralsD13');
                            return referralsData("referralsD13",referralsD13)
                        })
                        .then(function() {
                            // console.log('reducingAttritionD12');
                            return reducingAttritionData("reducingAttritionD12", clientInputsRes.clientInputsH22)
                        })
                        .then(function() {
                            // console.log('reducingAttritionD13')
                            return reducingAttritionData("reducingAttritionD13", reducingAttritionD13)
                        })
                        .then(function() {
                            // console.log('customerReactivationD12')
                            return customerReactivationData("customerReactivationD12", clientInputsRes.clientInputsH20)
                        })
                        .then(function() {
                            // console.log('customerReactivationD13');
                            return customerReactivationData("customerReactivationD13", customerReactivationD13)
                        })
                        .then(function() {
                            // console.log(" ---- done ----"); 
                            setTimeout(function() {
                                stopLoader(); 
                            }, 8000);                          
                        });
                    
                }
                else{
                    setRedirectFlag(true)
                    setIsLoading(false)
                }
            }
        })
        .catch(() => {
            setIsLoading(false)
            { alert.show("Something went wrong! Please refresh your browser first.") }
        });
    }, []);

    // call states useEffect utils
    StatesUseEffect()

    function stopLoader(){
        setIsLoading(false);
    }

    if(redirectFlag){
        alert.show("Please complete Client Inputs form first.")
        return <Navigate to="/diagnose/client-inputs" />;  
    }
    
    // console.log("state update route",isAuthenticated)
    return isLoading  ? (
        <div>
            <div id="main-wrapper">
                <div id="disable">
                    <SidebarNew />
                </div>
                <div className="content-body content-body-new bg-white-color">
                    <UserDropdown />
                    {/* row */}
                    <div className="container-fluid lead-generation-new">
                        <div className="head-title">
                            <h3 className='font-sise-20'>Prescribe - Growth Drivers</h3>
                            <h2>Loading</h2>	
                            <span>Please wait...</span>  
                        </div>
                        <div className="card update-class-lead">
                            <div className="card-body pl-0">
                                <div className="spinner">
                                    <Bars 
                                    height="350" 
                                    width="120" 
                                    radius="9"
                                    color="#297cf8" 
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                    />   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    ) : children ;  
}

export default StateUpdateRoute;