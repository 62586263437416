import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../features/Message";
import jobsToBeDoneService from "../../services/jobsToBeDone.service";

//Get data based on user
export const getJobsToBeDone = createAsyncThunk(
  "revenueGrowth/getJobsToBeDone",
  async ({user_id}, thunkAPI) => {
    try {
      const response = await jobsToBeDoneService.getData(user_id);
      //console.log(response)
      //thunkAPI.dispatch(setMessage(response.data.message));
      return response.data
    } catch (error) {
      console.log(error)
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

//add or edit JobsToBeDone
export const modifyJobsToBeDone = createAsyncThunk(
  "revenueGrowth/modifyJobsToBeDone",
  async (postClientData , thunkAPI) => {
    try {
      const data = await jobsToBeDoneService.postData(postClientData);
      thunkAPI.dispatch(setMessage(""));
      console.log(data)
      return { revenueGrowth : data };
    } catch (error) {
      console.log('errorcode',error.code)
      if(error.code == 'ERR_BAD_REQUEST'){
        return {status : 401}
      }
      else{
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const JobsToBeDoneSlice = createSlice({
    name: 'JobsToBeDone',
    reducers: {
      
    },
    extraReducers: {
      [getJobsToBeDone.pending]: (state) => {
        state.loading = true
      },
      [getJobsToBeDone.fulfilled]: (state, { payload }) => {
        //console.log("---------get Data -----------", payload.data.JobsToBeDone)
        state.loading = false
      },
      [getJobsToBeDone.rejected]: (state) => {
        state.loading = false
      },
    },
  })

const { reducer } = JobsToBeDoneSlice;
export default reducer;