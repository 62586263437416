import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getFindTheLowHangingFruit, modifyFindTheLowHangingFruit } from "../../state/features/FindTheLowHangingFruitSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import checkRange from '../../utils/checkRange';
import QuadrantChartGraph from '../graph/QuadrantChartGraph';

import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
    ScatterController,
} from 'chart.js';

import QuadrantChartGraphNew from '../graph/QuadrantChartGraphNew';

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
    ScatterController
);

export default function LowHangingFruitContentNew() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        FindTheLowHangingFruitD18 : 0,
        FindTheLowHangingFruitE18 : 0,
        FindTheLowHangingFruitD19 : 0,
        FindTheLowHangingFruitE19 : 0,
        FindTheLowHangingFruitD20 : 0,
        FindTheLowHangingFruitE20 : 0,
        FindTheLowHangingFruitD21 : 0,
        FindTheLowHangingFruitE21 : 0,
        FindTheLowHangingFruitD22 : 0,
        FindTheLowHangingFruitE22 : 0,
        FindTheLowHangingFruitC18 : 'Activity or Tactic 1',
        FindTheLowHangingFruitC19 : 'Activity or Tactic 2',
        FindTheLowHangingFruitC20 : 'Activity or Tactic 3',
        FindTheLowHangingFruitC21 : 'Activity or Tactic 4',
        FindTheLowHangingFruitC22 : 'Activity or Tactic 5',
    });
    
    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getFindTheLowHangingFruit({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.growthPlanning)
            if(response!= undefined  && response.data.growthPlanning){
                let growthPlanning = response.data.growthPlanning[0];
                // console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key];
                    }
                }
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = async (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyFindTheLowHangingFruit({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                    // console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }
    /*
    const data = {
        xVals: [Inputs.FindTheLowHangingFruitD18, Inputs.FindTheLowHangingFruitD19, Inputs.FindTheLowHangingFruitD20, Inputs.FindTheLowHangingFruitD21, Inputs.FindTheLowHangingFruitD22],
        yVals: [Inputs.FindTheLowHangingFruitE18, Inputs.FindTheLowHangingFruitE19, Inputs.FindTheLowHangingFruitE20, Inputs.FindTheLowHangingFruitE21, Inputs.FindTheLowHangingFruitE22]
    };
    */

  return (
    <div className="content-body content-body-new bg-white-color card-box-shadow">
        <UserDropdown />
        {/* row */}
        <div className="container-fluid">
            <div className="head-title">
                <h3 className='preheading'>Prescribe - Opportunity Analysis</h3>	
                <h1 className='mainheading mb-1'>Low-Hanging Fruit</h1>	            					
            </div>
            <Spinner />
            <div className="row long-hanging-rw mb-3" style={{maxWidth: 1500}}>
                <div className="col-12 col-md-7 col-lg-7 long-hanging-cl">
                    <div className="how-do-your">
                        <h3 className='font10px color-dark'>HOW DO YOU ASSIGN EFFORT/COST and VALUE/BENEFIT?</h3>
                        <p className='font10px color-dark'>The effort axis can represent cost, capital, ease of implementation, people, timing or other effort levers. If you have actual data and analysis, then use the data to plot projects. If you don’t, you typically have a good intuition on the relative levels of efforts of different options.</p>
                    </div>
                </div>
                <div className="col-12 col-md-5 col-lg-5 long-hanging-cl">
                    <div className="instructions-title">
                        <h3 className='font10px color-dark'>INSTRUCTIONS</h3>
                        <ul className='list-instruction font10px color-dark'>
                            {/* <li>Define the prioritization dimensions</li> */}
                            <li>Brainstorm options</li>
                            <li>Define values for options (High is good, low is not good)</li>
                            <li>Sort options by total value</li>
                            <li>You now have prioritized your options</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="row" style={{maxWidth: 1500}}>
                <div className="col-12 col-lg-7">
                    <div className="long-hanging-table-pre mb-4">
                        <div className="table-responsive update-class-lead">
                            <table className="table" style={{width: 700}}>
                                <thead>
                                    <tr>
                                        <td className='hide-content'/>
                                        <td className="text-center bg-color-03 font12px fontBold text-white">Effort</td>
                                        <td className="text-center bg-color-03 font12px fontBold text-white">Value</td>
                                    </tr>
                                    {/* <tr>
                                        <td className='hide-content'/>
                                        <td className="text-center bg-color-01 font12px color-dark">
                                            Prioritization Dimension #1
                                        </td>
                                        <td className="text-center bg-color-01 font12px color-dark">
                                            Prioritization Dimension #2
                                        </td>
                                    </tr> */}
                                    <tr className="thead-primary">
                                        <td className='black-bg font14px text-white' style={{width: 400}}>Opportunity</td>
                                        <td className='black-bg font11px text-white text-center' style={{width: 150}}>(1=low; 10=high)</td>
                                        <td className='black-bg font11px text-white text-center' style={{width: 150}}>(1=low; 10=high)</td>
                                    </tr>
                                </thead>
                                <tbody className='long-tbale-body'>
                                    <tr>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01">
                                                <input type="text" className="field-ipt bg-color-01 font11px color-dark ms-text-left" 
                                                id="FindTheLowHangingFruitC18"
                                                value={Inputs.FindTheLowHangingFruitC18}
                                                onChange={(event) => setInputs({ ...Inputs, FindTheLowHangingFruitC18: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="Activity or Tactic 1"  
                                                />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01">
                                                <input type="text" className="field-ipt bg-color-01 font11px color-dark text-center"
                                                    id="FindTheLowHangingFruitD18"
                                                    value={Inputs.FindTheLowHangingFruitD18}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, FindTheLowHangingFruitD18: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }} 
                                                />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01">
                                                <input type="text" className="field-ipt bg-color-01 font11px color-dark text-center"
                                                    id="FindTheLowHangingFruitE18"
                                                    value={Inputs.FindTheLowHangingFruitE18}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, FindTheLowHangingFruitE18: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01">
                                                <input type="text" className="field-ipt bg-color-01 font11px color-dark ms-text-left" 
                                                id="FindTheLowHangingFruitC19"
                                                value={Inputs.FindTheLowHangingFruitC19}
                                                onChange={(event) => setInputs({ ...Inputs, FindTheLowHangingFruitC19: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="Activity or Tactic 1"  
                                                />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01">
                                                <input type="text" className="field-ipt bg-color-01 font11px color-dark text-center"
                                                    id="FindTheLowHangingFruitD19"
                                                    value={Inputs.FindTheLowHangingFruitD19}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, FindTheLowHangingFruitD19: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01">
                                                <input type="text" className="field-ipt bg-color-01 font11px color-dark text-center"
                                                    id="FindTheLowHangingFruitE19"
                                                    value={Inputs.FindTheLowHangingFruitE19}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, FindTheLowHangingFruitE19: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01">
                                                <input type="text" className="field-ipt bg-color-01 font11px color-dark ms-text-left" 
                                                id="FindTheLowHangingFruitC20"
                                                value={Inputs.FindTheLowHangingFruitC20}
                                                onChange={(event) => setInputs({ ...Inputs, FindTheLowHangingFruitC20: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="Activity or Tactic 1"  
                                                />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01">
                                                <input type="text" className="field-ipt bg-color-01 font11px color-dark text-center"
                                                    id="FindTheLowHangingFruitD20"
                                                    value={Inputs.FindTheLowHangingFruitD20}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, FindTheLowHangingFruitD20: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01">
                                                <input type="text" className="field-ipt bg-color-01 font11px color-dark text-center"
                                                    id="FindTheLowHangingFruitE20"
                                                    value={Inputs.FindTheLowHangingFruitE20}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, FindTheLowHangingFruitE20: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01">
                                                <input type="text" className="field-ipt bg-color-01 font11px color-dark ms-text-left" 
                                                id="FindTheLowHangingFruitC21"
                                                value={Inputs.FindTheLowHangingFruitC21}
                                                onChange={(event) => setInputs({ ...Inputs, FindTheLowHangingFruitC21: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="Activity or Tactic 1"  
                                                />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01">
                                                <input type="text" className="field-ipt bg-color-01 font11px color-dark text-center"
                                                    id="FindTheLowHangingFruitD21"
                                                    value={Inputs.FindTheLowHangingFruitD21}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, FindTheLowHangingFruitD21: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01">
                                                <input type="text" className="field-ipt bg-color-01 font11px color-dark text-center"
                                                    id="FindTheLowHangingFruitE21"
                                                    value={Inputs.FindTheLowHangingFruitE21}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, FindTheLowHangingFruitE21: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01">
                                                <input type="text" className="field-ipt bg-color-01 font11px color-dark ms-text-left" 
                                                id="FindTheLowHangingFruitC22"
                                                value={Inputs.FindTheLowHangingFruitC22}
                                                onChange={(event) => setInputs({ ...Inputs, FindTheLowHangingFruitC22: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="Activity or Tactic 1"  
                                                />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01">
                                                <input type="text" className="field-ipt bg-color-01 font11px color-dark text-center"
                                                    id="FindTheLowHangingFruitD22"
                                                    value={Inputs.FindTheLowHangingFruitD22}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, FindTheLowHangingFruitD22: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01">
                                                <input type="text" className="field-ipt bg-color-01 font11px color-dark text-center"
                                                    id="FindTheLowHangingFruitE22"
                                                    value={Inputs.FindTheLowHangingFruitE22}
                                                    onChange={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            setInputs({ ...Inputs, FindTheLowHangingFruitE22: event.target.value })
                                                        }
                                                    }}
                                                    onBlur={event => {
                                                        if(checkRange(event.target.value, 1, 10)){
                                                            onBlurHandler(event)
                                                        }
                                                    }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength={2}
                                                    onKeyPress={event => {
                                                        if(!/[0-9]/.test(event.key)){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }} />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="nextInput">
                        <ul>
                            <li>
                                <Link to="/prescribe/opportunity-ranking-analysis" className='next-link'>For a more detailed analysis, proceed to Opportunity Ranking Analysis  
                                    <span className='icon-three'>
                                        <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                        <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                        <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                    </span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-12 col-lg-5">
                    {/* <div className='black-bg' style={{borderRadius: 15}}>
                        <QuadrantChartGraph data={data} />
                    </div> */}
                    <div className="black-bg" style={{borderRadius: 15, padding: 20, height: 500}}>
                        <QuadrantChartGraphNew dataInput={{
                            "Title"      : "10 Years of 1% Improvements",
                            "vTitle"     : "Value/Benefit",
                            "hTitle"     : "Effort/Cost",
                            "subTitle"   : "",
                            "ColorCodes" : ["#FFFFFF"],
                            "GraphData"  : [
                                ['Actual', Inputs.FindTheLowHangingFruitC18, Inputs.FindTheLowHangingFruitC19, Inputs.FindTheLowHangingFruitC20, Inputs.FindTheLowHangingFruitC21, Inputs.FindTheLowHangingFruitC22,  'horizontal line', 'vertical line', 'label placement', {type: 'string', role: 'annotation'}],
                                [0, 	null, null, null, null, null, 5, 	null, null, null],
                                [Number(Inputs.FindTheLowHangingFruitD18), 	Number(Inputs.FindTheLowHangingFruitE18), 	  null, null, null, null, 5, 	null, null, Inputs.FindTheLowHangingFruitC18],
                                [2.5, 	null, null, null, null, null, 5, null, 2.25, 'Maybe'],
                                [2.5, 	null, null, null, null, null, 5, null, 8.25, 'Low'],
                                [2.5, 	null, null, null, null, null, 5, null, 7.25, 'Hanging'],
                                [2.5, 	null, null, null, null, null, 5, null, 6.25, 'Fruit'],
                                [Number(Inputs.FindTheLowHangingFruitD19), 	null, Number(Inputs.FindTheLowHangingFruitE19), 	null, null, null, 5, 	null, null, Inputs.FindTheLowHangingFruitC19],
                                [Number(Inputs.FindTheLowHangingFruitD20), 	null, null, Number(Inputs.FindTheLowHangingFruitE20), 	  null, null, 5, 	null, null, Inputs.FindTheLowHangingFruitC20],
                                [Number(Inputs.FindTheLowHangingFruitD21), 	null, null, null, Number(Inputs.FindTheLowHangingFruitE21), 	null, 5, 	null, null, Inputs.FindTheLowHangingFruitC21],
                                [Number(Inputs.FindTheLowHangingFruitD22), 	null, null, null, null, Number(Inputs.FindTheLowHangingFruitE22), 	  5, 	null, null, Inputs.FindTheLowHangingFruitC22],
                                [10, 	null, null, null, null, null, 5, 	null, null, null],
                                [7.5, 	null, null, null, null, null, null, null, 3.25, 'Net'],
                                [7.5, 	null, null, null, null, null, null, null, 2.25, 'Worth'],
                                [7.5, 	null, null, null, null, null, null, null, 1.25, 'Effort'],
                                [7.5, 	null, null, null, null, null, null, null, 8.25, 'Big'],
                                [7.5, 	null, null, null, null, null, null, null, 7.25, 'Bets'],
                                [5, 	null, null, null, null, null, null, 0, 	  null, null],
                                [5, 	null, null, null, null, null, null, 10,   null, null]
                            ]
                        }} />
                    </div>
                </div>
            </div>
            <div className="card" style={{display: "none"}}>
                <div className="card-body contentleft sideul">
                    <h4>HOW DO YOU ASSIGN VALUE?</h4>
                    <p>Value can be determined from an absolute or relative perspective. An absolute perspective is when an appropriate analysis has determined an actual value.  Relative value can be created when hypothetically comparing different options without an actual analysis.  Use relative value when there is a good relative sense that one option is more valuable than another.</p>
                    <div className="row">
                        <div className="col-md-8">
                            <h4>HOW DO YOU ASSIGN COST / EFFORT?</h4>
                            <p>The effort axis can represent cost, capital, ease of implementation, people, timing or other effort levers. Once again, you can assign levels of efforts on an absolute or relative basis. If you have actual data and analysis, then use the data to plot projects. If you don’t, you typically have a good intuition on the relative levels of efforts of different options.</p>
                            <br /><br />
                            <Spinner />
                            <div className="table-responsive">
                                <table className="table">
                                <thead>
                                    <tr>
                                    <th style={{width: '35%'}} />
                                    <th className="bgradius text-center">Prioritization Dimension <br /> #1</th>
                                    <th className="bgradius text-center">Prioritization Dimension <br /> #2</th>
                                    </tr>
                                    <tr>
                                    <th style={{width: '35%'}} />
                                    <th className="bgradiusf text-center">"Effort"</th>
                                    <th className="bgradiusf text-center">"Value"</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="thead-primary">
                                    <th>Opportunity</th>
                                    <th>(1=low; 10=high)</th>
                                    <th>(1=low; 10=high)</th>
                                    </tr>
                                    <tr>
                                    <td>Activity A</td>
                                    <td><input type="text" className="form-control inputText"
                                            id="FindTheLowHangingFruitD18"
                                            value={Inputs.FindTheLowHangingFruitD18}
                                            onChange={(event) => setInputs({ ...Inputs, FindTheLowHangingFruitD18: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading}
                                            placeholder="0"
                                            maxLength="2"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                    alert.show('Please Enter Prioritization Dimension 1 to 10.')
                                                }
                                                }} /></td>
                                    <td><input type="text" className="form-control inputText"
                                            id="FindTheLowHangingFruitE18"
                                            value={Inputs.FindTheLowHangingFruitE18}
                                            onChange={(event) => setInputs({ ...Inputs, FindTheLowHangingFruitE18: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading}
                                            placeholder="0"
                                            maxLength="2"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                    alert.show('Please Enter Prioritization Dimension 1 to 10.')
                                                }
                                                }} /></td>
                                    </tr>
                                    <tr>
                                    <td>Activity B</td>
                                    <td><input type="text" className="form-control inputText"
                                            id="FindTheLowHangingFruitD19"
                                            value={Inputs.FindTheLowHangingFruitD19}
                                            onChange={(event) => setInputs({ ...Inputs, FindTheLowHangingFruitD19: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading}
                                            placeholder="0"
                                            maxLength="2"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                    alert.show('Please Enter Prioritization Dimension 1 to 10.')
                                                }
                                                }} /></td>
                                    <td><input type="text" className="form-control inputText"
                                            id="FindTheLowHangingFruitE19"
                                            value={Inputs.FindTheLowHangingFruitE19}
                                            onChange={(event) => setInputs({ ...Inputs, FindTheLowHangingFruitE19: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading}
                                            placeholder="0"
                                            maxLength="2"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                    alert.show('Please Enter Prioritization Dimension 1 to 10.')
                                                }
                                                }} /></td>
                                    </tr>
                                    <tr>
                                    <td>Activity C</td>
                                    <td><input type="text" className="form-control inputText"
                                            id="FindTheLowHangingFruitD20"
                                            value={Inputs.FindTheLowHangingFruitD20}
                                            onChange={(event) => setInputs({ ...Inputs, FindTheLowHangingFruitD20: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading}
                                            placeholder="0"
                                            maxLength="2"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                    alert.show('Please Enter Prioritization Dimension 1 to 10.')
                                                }
                                                }} /></td>
                                    <td><input type="text" className="form-control inputText"
                                            id="FindTheLowHangingFruitE20"
                                            value={Inputs.FindTheLowHangingFruitE20}
                                            onChange={(event) => setInputs({ ...Inputs, FindTheLowHangingFruitE20: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading}
                                            placeholder="0"
                                            maxLength="2"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                    alert.show('Please Enter Prioritization Dimension 1 to 10.')
                                                }
                                                }} /></td>
                                    </tr>
                                    <tr>
                                    <td>Activity D</td>
                                    <td><input type="text" className="form-control inputText"
                                            id="FindTheLowHangingFruitD21"
                                            value={Inputs.FindTheLowHangingFruitD21}
                                            onChange={(event) => setInputs({ ...Inputs, FindTheLowHangingFruitD21: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading}
                                            placeholder="0"
                                            maxLength="2"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                    alert.show('Please Enter Prioritization Dimension 1 to 10.')
                                                }
                                                }} /></td>
                                    <td><input type="text" className="form-control inputText"
                                            id="FindTheLowHangingFruitE21"
                                            value={Inputs.FindTheLowHangingFruitE21}
                                            onChange={(event) => setInputs({ ...Inputs, FindTheLowHangingFruitE21: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading}
                                            placeholder="0"
                                            maxLength="2"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                    alert.show('Please Enter Prioritization Dimension 1 to 10.')
                                                }
                                                }} /></td>
                                    </tr>
                                    <tr>
                                    <td>Activity E</td>
                                    <td><input type="text" className="form-control inputText"
                                            id="FindTheLowHangingFruitD22"
                                            value={Inputs.FindTheLowHangingFruitD22}
                                            onChange={(event) => setInputs({ ...Inputs, FindTheLowHangingFruitD22: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading}
                                            placeholder="0"
                                            maxLength="2"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                    alert.show('Please Enter Prioritization Dimension 1 to 10.')
                                                }
                                                }} /></td>
                                    <td><input type="text" className="form-control inputText"
                                            id="FindTheLowHangingFruitE22"
                                            value={Inputs.FindTheLowHangingFruitE22}
                                            onChange={(event) => setInputs({ ...Inputs, FindTheLowHangingFruitE22: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading}
                                            placeholder="0"
                                            maxLength="2"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                    alert.show('Please Enter Prioritization Dimension 1 to 10.')
                                                }
                                                }} /></td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <h4>INSTRUCTIONS</h4>
                            <ul>
                                <li>1. Define the Prioritization Dimensions</li>
                                <li>2. Brainstorm Options</li>
                                <li>3. Define Values for Options (High is Good, Low is Not Good)</li>
                                <li>4. Sort Options by Total Value</li>
                                <li>5. You Now Have Prioritized Your Options</li>
                            </ul>
                        </div>
                    </div>
                </div>   
            </div>
        </div>
    </div>
  )
}
