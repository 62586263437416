import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getGoToMarketStrategy, modifyGoToMarketStrategy } from "../../state/features/GoToMarketStrategySlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';

export default function GoToMarketStrategyContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        GoToMarketStrategyC10 : '',
        GoToMarketStrategyC11 : '',
        GoToMarketStrategyC12 : '',
        GoToMarketStrategyC13 : '',
        GoToMarketStrategyC14 : '',
        GoToMarketStrategyC15 : '',
        GoToMarketStrategyC16 : '',
        GoToMarketStrategyC17 : '',
        GoToMarketStrategyD10 : '',
        GoToMarketStrategyD11 : '',
        GoToMarketStrategyD12 : '',
        GoToMarketStrategyD13 : '',
        GoToMarketStrategyD14 : '',
        GoToMarketStrategyD15 : '',
        GoToMarketStrategyD16 : '',
        GoToMarketStrategyD17 : '',
        GoToMarketStrategyE10 : '',
        GoToMarketStrategyE11 : '',
        GoToMarketStrategyE12 : '',
        GoToMarketStrategyE13 : '',
        GoToMarketStrategyE14 : '',
        GoToMarketStrategyE15 : '',
        GoToMarketStrategyE16 : '',
        GoToMarketStrategyE17 : '',
        GoToMarketStrategyF10 : '',
        GoToMarketStrategyF11 : '',
        GoToMarketStrategyF12 : '',
        GoToMarketStrategyF13 : '',
        GoToMarketStrategyF14 : '',
        GoToMarketStrategyF15 : '',
        GoToMarketStrategyF16 : '',
        GoToMarketStrategyF17 : '',
        GoToMarketStrategyG10 : '',
        GoToMarketStrategyG11 : '',
        GoToMarketStrategyG12 : '',
        GoToMarketStrategyG13 : '',
        GoToMarketStrategyG14 : '',
        GoToMarketStrategyG15 : '',
        GoToMarketStrategyG16 : '',
        GoToMarketStrategyG17 : '',
        GoToMarketStrategyC20 : '',
        GoToMarketStrategyC21 : '',
        GoToMarketStrategyC22 : '',
        GoToMarketStrategyC23 : '',
        GoToMarketStrategyC24 : '',
        GoToMarketStrategyC25 : '',
        GoToMarketStrategyC26 : '',
        GoToMarketStrategyF20 : '',
        GoToMarketStrategyF21 : '',
        GoToMarketStrategyF22 : '',
        GoToMarketStrategyF24 : '',
        GoToMarketStrategyF25 : '',
        GoToMarketStrategyF26 : '',
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getGoToMarketStrategy({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.growthPlanning)
            if(response!= undefined  && response.data.growthPlanning){
                let growthPlanning = response.data.growthPlanning[0];
                console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key]
                    }
                }
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyGoToMarketStrategy({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        //window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

  return (
    <div className="content-body">
    <UserDropdown />
    
        {/* row */}
        <div className="container-fluid">
            <div className="head-title bigsout">
                <h2>Go-To-Market Strategy</h2>                      		
            </div>
            <Spinner />
            <div className="card">
                <div className="card-body pl-0">
                <div className="table-responsive">
                    <table className="table">
                    <thead>
                        <tr className="thead-primary">
                        <th>Business Objectives</th>
                        <th>Target Markets</th>
                        <th>Target Customers</th>
                        <th>Offering</th>
                        <th>Channels</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyC10"
                                    value={Inputs.GoToMarketStrategyC10}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyC10: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyC11"
                                    value={Inputs.GoToMarketStrategyC11}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyC11: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyC12"
                                    value={Inputs.GoToMarketStrategyC12}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyC12: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyC13"
                                    value={Inputs.GoToMarketStrategyC13}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyC13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyC14"
                                    value={Inputs.GoToMarketStrategyC14}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyC14: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyC15"
                                    value={Inputs.GoToMarketStrategyC15}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyC15: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyC16"
                                    value={Inputs.GoToMarketStrategyC16}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyC16: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyC17"
                                    value={Inputs.GoToMarketStrategyC17}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyC17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyD10"
                                    value={Inputs.GoToMarketStrategyD10}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyD10: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyD11"
                                    value={Inputs.GoToMarketStrategyD11}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyD11: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyD12"
                                    value={Inputs.GoToMarketStrategyD12}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyD12: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyD13"
                                    value={Inputs.GoToMarketStrategyD13}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyD13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyD14"
                                    value={Inputs.GoToMarketStrategyD14}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyD14: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyD15"
                                    value={Inputs.GoToMarketStrategyD15}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyD15: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyD16"
                                    value={Inputs.GoToMarketStrategyD16}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyD16: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyD17"
                                    value={Inputs.GoToMarketStrategyD17}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyD17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyE10"
                                    value={Inputs.GoToMarketStrategyE10}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyE10: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyE11"
                                    value={Inputs.GoToMarketStrategyE11}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyE11: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyE12"
                                    value={Inputs.GoToMarketStrategyE12}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyE12: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyE13"
                                    value={Inputs.GoToMarketStrategyE13}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyE13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyE14"
                                    value={Inputs.GoToMarketStrategyE14}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyE14: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyE15"
                                    value={Inputs.GoToMarketStrategyE15}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyE15: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyE16"
                                    value={Inputs.GoToMarketStrategyE16}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyE16: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyE17"
                                    value={Inputs.GoToMarketStrategyE17}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyE17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyF10"
                                    value={Inputs.GoToMarketStrategyF10}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyF10: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyF11"
                                    value={Inputs.GoToMarketStrategyF11}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyF11: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyF12"
                                    value={Inputs.GoToMarketStrategyF12}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyF12: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyF13"
                                    value={Inputs.GoToMarketStrategyF13}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyF13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyF14"
                                    value={Inputs.GoToMarketStrategyF14}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyF14: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyF15"
                                    value={Inputs.GoToMarketStrategyF15}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyF15: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyF16"
                                    value={Inputs.GoToMarketStrategyF16}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyF16: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyF17"
                                    value={Inputs.GoToMarketStrategyF17}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyF17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyG10"
                                    value={Inputs.GoToMarketStrategyG10}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyG10: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyG11"
                                    value={Inputs.GoToMarketStrategyG11}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyG11: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyG12"
                                    value={Inputs.GoToMarketStrategyG12}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyG12: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyG13"
                                    value={Inputs.GoToMarketStrategyG13}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyG13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyG14"
                                    value={Inputs.GoToMarketStrategyG14}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyG14: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyG15"
                                    value={Inputs.GoToMarketStrategyG15}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyG15: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyG16"
                                    value={Inputs.GoToMarketStrategyG16}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyG16: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="GoToMarketStrategyG17"
                                    value={Inputs.GoToMarketStrategyG17}
                                    onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyG17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            <Spinner />
            <div className="row">
                <div className="col-md-6">
                <div className="card">
                    <div className="card-body pl-0">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                            <tr className="thead-primary">
                                <th>Marketing Strategies</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                    <td>
                                        <input type="text" className="form-control inputText"
                                        id="GoToMarketStrategyC20"
                                        value={Inputs.GoToMarketStrategyC20}
                                        onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyC20: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                                    </td>
                            </tr>
                            <tr>
                                    <td>
                                        <input type="text" className="form-control inputText"
                                        id="GoToMarketStrategyC21"
                                        value={Inputs.GoToMarketStrategyC21}
                                        onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyC21: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                                    </td>
                            </tr>
                            <tr>
                                    <td>
                                        <input type="text" className="form-control inputText"
                                        id="GoToMarketStrategyC22"
                                        value={Inputs.GoToMarketStrategyC22}
                                        onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyC22: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                                    </td>
                            </tr>
                            <tr>
                                    <td>
                                        <input type="text" className="form-control inputText"
                                        id="GoToMarketStrategyC23"
                                        value={Inputs.GoToMarketStrategyC23}
                                        onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyC23: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                                    </td>
                            </tr>
                            <tr>
                                    <td>
                                        <input type="text" className="form-control inputText"
                                        id="GoToMarketStrategyC24"
                                        value={Inputs.GoToMarketStrategyC24}
                                        onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyC24: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                                    </td>
                            </tr>
                            <tr>
                                    <td>
                                        <input type="text" className="form-control inputText"
                                        id="GoToMarketStrategyC25"
                                        value={Inputs.GoToMarketStrategyC25}
                                        onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyC25: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                                    </td>
                            </tr>
                            <tr>
                                    <td>
                                        <input type="text" className="form-control inputText"
                                        id="GoToMarketStrategyC26"
                                        value={Inputs.GoToMarketStrategyC26}
                                        onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyC26: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                                    </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    </div>
                </div>
                </div>
                <div className="col-md-6">
                <div className="card">
                    <div className="card-body pl-0">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                            <tr className="thead-primary">
                                <th>Key Metrics</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                    <td>
                                        <input type="text" className="form-control inputText"
                                        id="GoToMarketStrategyF20"
                                        value={Inputs.GoToMarketStrategyF20}
                                        onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyF20: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                                    </td>
                            </tr>
                            <tr>
                                    <td>
                                        <input type="text" className="form-control inputText"
                                        id="GoToMarketStrategyF21"
                                        value={Inputs.GoToMarketStrategyF21}
                                        onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyF21: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                                    </td>
                            </tr>
                            <tr>
                                    <td>
                                        <input type="text" className="form-control inputText"
                                        id="GoToMarketStrategyF22"
                                        value={Inputs.GoToMarketStrategyF22}
                                        onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyF22: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                                    </td>
                            </tr>
                            <tr className="thead-primary">
                                <th>Key Success Factors</th>
                            </tr>
                            <tr>
                                    <td>
                                        <input type="text" className="form-control inputText"
                                        id="GoToMarketStrategyF24"
                                        value={Inputs.GoToMarketStrategyF24}
                                        onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyF24: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                                    </td>
                            </tr>
                            <tr>
                                    <td>
                                        <input type="text" className="form-control inputText"
                                        id="GoToMarketStrategyF25"
                                        value={Inputs.GoToMarketStrategyF25}
                                        onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyF25: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                                    </td>
                            </tr>
                            <tr>
                                    <td>
                                        <input type="text" className="form-control inputText"
                                        id="GoToMarketStrategyF26"
                                        value={Inputs.GoToMarketStrategyF26}
                                        onChange={(event) => setInputs({ ...Inputs, GoToMarketStrategyF26: event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                    />
                                    </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>

    </div>
  )
}