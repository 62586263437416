import { Link } from "react-router-dom";

export default function MenuMultiList({submenu, id}) {
    const mid = Math.floor(submenu.length/2);
    return (
        <div className="duble-list">
            <ul className='list-of-data'>
                {submenu.slice(0,mid).map((menu, i) => (
                    <li className='font-sise-16' key={id+"_"+i}><Link to={menu.menu_slug}>{menu.label}</Link></li>
                ))}
            </ul>
            <ul className='list-of-data'>
                {submenu.slice(mid).map((menu, i) => (
                    <li className='font-sise-16' key={id+"_"+i}><Link to={menu.menu_slug}>{menu.label}</Link></li>
                ))}
            </ul>
        </div>
    )
}