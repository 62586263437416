import { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../state/index';
import { useSelector, useDispatch } from "react-redux";

export const StatesUseEffect = () => {
    // console.log("call states useEffect...")
    const dispatch = useDispatch()
    const { averageTransactionValueData, purchaseFrequencyData, buyingLifeTimeData, profitMarginData, impactof120GrowthOSData, leadGenerationData, conversionData, referralsData, reducingAttritionData, customerReactivationData, CustomerFinancialValuesData, creatingGrowthMomentumData } = bindActionCreators(actionCreators, dispatch)

    const clientInputs = useSelector(state => state.clientInputs)
    const CustomerFinancialValues = useSelector(state => state.CustomerFinancialValues)
    const averageTransactionValue = useSelector(state => state.averageTransactionValue)
    const purchaseFrequency = useSelector(state => state.purchaseFrequency)
    const buyingLifeTime = useSelector(state => state.buyingLifeTime)
    const profitMargin = useSelector(state => state.profitMargin)
    const impactof120GrowthOS = useSelector(state => state.impactof120GrowthOS)
    const leadGeneration = useSelector(state => state.leadGeneration)
    const conversion = useSelector(state => state.conversion)
    const referrals = useSelector(state => state.referrals)
    const reducingAttrition = useSelector(state => state.reducingAttrition)
    const customerReactivation = useSelector(state => state.customerReactivation)
    const creatingGrowthMomentum = useSelector(state => state.creatingGrowthMomentum)

    useEffect(() => {
        // console.log('--done1--');
        CustomerFinancialValuesData("CustomerFinancialValuesAllStats", {
            clientInputsH16 : clientInputs.clientInputsH16,
            clientInputsH19 : clientInputs.clientInputsH19,
            clientInputsH30 : clientInputs.clientInputsH30,
            clientInputsH33 : clientInputs.clientInputsH33,
            clientInputsH36 : clientInputs.clientInputsH36,
            averageTransactionValue,
            purchaseFrequency,
            buyingLifeTime,
            profitMargin,
            impactof120GrowthOSG27 : impactof120GrowthOS.impactof120GrowthOSG27,
            impactof120GrowthOSH27 : impactof120GrowthOS.impactof120GrowthOSH27,
            impactof120GrowthOSI27 : impactof120GrowthOS.impactof120GrowthOSI27,
            impactof120GrowthOSJ27 : impactof120GrowthOS.impactof120GrowthOSJ27,
            impactof120GrowthOSK27 : impactof120GrowthOS.impactof120GrowthOSK27
        });
    }, [clientInputs.clientInputsH16, averageTransactionValue.averageTransactionValueD13, purchaseFrequency.purchaseFrequencyD13, buyingLifeTime.buyingLifeTimeD13, profitMargin.profitMarginD13, impactof120GrowthOS.impactof120GrowthOSE26]);

    useEffect(() => {
        // console.log('--done2--');
        averageTransactionValueData("averageTransactionValue", {
            averageTransactionValueD12 : averageTransactionValue.averageTransactionValueD12,
            CustomerFinancialValues,
            clientInputsH41 : clientInputs.clientInputsH41,
        });
    }, [averageTransactionValue.averageTransactionValueD12, averageTransactionValue.averageTransactionValueD13, CustomerFinancialValues.CustomerFinancialValuesD20]);

    useEffect(() => {
        // console.log('--done3--');
        purchaseFrequencyData("purchaseFrequencyIncreasingPurchase", {
            purchaseFrequencyD12 : purchaseFrequency.purchaseFrequencyD12,
            CustomerFinancialValues,
            clientInputsH41 : clientInputs.clientInputsH41,
        });
    }, [purchaseFrequency.purchaseFrequencyD12, purchaseFrequency.purchaseFrequencyD13, CustomerFinancialValues.CustomerFinancialValuesD20]);

    useEffect(() => {
        // console.log('--done4--');
        buyingLifeTimeData("buyingLifeTimeImprovements", {
            buyingLifeTimeD12 : buyingLifeTime.buyingLifeTimeD12,
            CustomerFinancialValues,
            clientInputsH41 : clientInputs.clientInputsH41,
        });
    }, [buyingLifeTime.buyingLifeTimeD12, buyingLifeTime.buyingLifeTimeD13, CustomerFinancialValues.CustomerFinancialValuesD25]);

    useEffect(() => {
        // console.log('--done5--');
        profitMarginData("profitMarginImprovement", {
            profitMarginD12 : profitMargin.profitMarginD12,
            CustomerFinancialValues,
            clientInputsH41 : clientInputs.clientInputsH41,
        });
    }, [profitMargin.profitMarginD12, profitMargin.profitMarginD13, CustomerFinancialValues.CustomerFinancialValuesD20]);

    useEffect(() => {
        // console.log('--done6--');
        leadGenerationData("leadGenerationImprovement", {
            CustomerFinancialValues,
            clientInputsH26 : clientInputs.clientInputsH26,
            clientInputsH41 : clientInputs.clientInputsH41,
        });
    }, [clientInputs.clientInputsH25, leadGeneration.leadGenerationD16, CustomerFinancialValues.CustomerFinancialValuesD24]);

    useEffect(() => {
        // console.log('--done7--');
        conversionData("conversionRateImprovement", {
            conversionD13 : conversion.conversionD13,
            clientInputsH21 : clientInputs.clientInputsH21,
            clientInputsH25 : clientInputs.clientInputsH25,
            clientInputsH41 : clientInputs.clientInputsH41,
            leadGenerationE21 : leadGeneration.leadGenerationE21,
            leadGenerationF21 : leadGeneration.leadGenerationF21,
            leadGenerationG21 : leadGeneration.leadGenerationG21,
            leadGenerationH21 : leadGeneration.leadGenerationH21,
            CustomerFinancialValuesD24 : CustomerFinancialValues.CustomerFinancialValuesD24,
            CustomerFinancialValuesD25 : CustomerFinancialValues.CustomerFinancialValuesD25,
            CustomerFinancialValuesD26 : CustomerFinancialValues.CustomerFinancialValuesD26,
            CustomerFinancialValuesD27 : CustomerFinancialValues.CustomerFinancialValuesD27
        });    
    }, [conversion.conversionD13, conversion.conversionD14, CustomerFinancialValues.CustomerFinancialValuesD27, leadGeneration.leadGenerationH21]);

    useEffect(() => {
        // console.log('--done8--');
        referralsData("referralsImproving", {
            clientInputsH27 : clientInputs.clientInputsH27,
            CustomerFinancialValues,
            clientInputsH41 : clientInputs.clientInputsH41,
        });
    }, [referrals.referralsD13, CustomerFinancialValues.CustomerFinancialValuesD27]);

    useEffect(() => {
        // console.log('--done9--');
        reducingAttritionData("reducingAttritionImprovement", {
            reducingAttritionD12 : reducingAttrition.reducingAttritionD12,
            clientInputsH22 : clientInputs.clientInputsH22,
            clientInputsH41 : clientInputs.clientInputsH41,
            CustomerFinancialValues,
            impactof120GrowthOSG27 : impactof120GrowthOS.impactof120GrowthOSG27,
            impactof120GrowthOSH27 : impactof120GrowthOS.impactof120GrowthOSH27,
            impactof120GrowthOSI27 : impactof120GrowthOS.impactof120GrowthOSI27,
            impactof120GrowthOSJ27 : impactof120GrowthOS.impactof120GrowthOSJ27
        });
    }, [reducingAttrition.reducingAttritionD12, reducingAttrition.reducingAttritionD13, impactof120GrowthOS.impactof120GrowthOSJ27, CustomerFinancialValues.CustomerFinancialValuesD27]);

    useEffect(() => {
        // console.log('--done10--');
        buyingLifeTimeData("buyingLifeTimeImprovements", {
            buyingLifeTimeD12 : buyingLifeTime.buyingLifeTimeD12,
            CustomerFinancialValues,
            clientInputsH41 : clientInputs.clientInputsH41,
        });
    }, [buyingLifeTime.buyingLifeTimeD13, CustomerFinancialValues.CustomerFinancialValuesD20]);

    useEffect(() => {
        // console.log('--done11--');
        customerReactivationData("customerReactivation", {
            customerReactivationD12 : customerReactivation.customerReactivationD12,
            CustomerFinancialValues,
            impactof120GrowthOS,
            clientInputsH41 : clientInputs.clientInputsH41,
        });
    }, [customerReactivation.customerReactivationD12, customerReactivation.customerReactivationD13,impactof120GrowthOS.impactof120GrowthOSJ26, CustomerFinancialValues.CustomerFinancialValuesD27]);

    useEffect(() => {
        // console.log('--done12--');
        impactof120GrowthOSData("impactof120GrowthOSAll", {
            leadGeneration,
            conversion,
            referrals,
            reducingAttrition,
            buyingLifeTime,
            customerReactivation,
            purchaseFrequency,
            CustomerFinancialValues,
            averageTransactionValue,
            profitMargin,
            clientInputs,
            impactof120GrowthOS
        });
    }, [leadGeneration.leadGenerationI21, conversion.conversionI18, referrals.referralsI18, reducingAttrition.reducingAttritionG26, reducingAttrition.reducingAttritionI26, buyingLifeTime.buyingLifeTimeI19, customerReactivation.customerReactivationI18, CustomerFinancialValues.CustomerFinancialValuesD20, purchaseFrequency.purchaseFrequencyI18, averageTransactionValue.averageTransactionValueI18, profitMargin.profitMarginI18]);

    useEffect(() => {
        // console.log('--done13--');
        creatingGrowthMomentumData("creatingGrowthMomentumQuarterlyImprovement", {
            creatingGrowthMomentumM12: creatingGrowthMomentum.creatingGrowthMomentumM12,
            clientInputs,
            impactof120GrowthOS,
        });
    }, [impactof120GrowthOS.impactof120GrowthOSST42]);
}