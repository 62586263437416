import sum from "../../utils/sum";
const initialState = {
    
}
const reducer = (state = initialState, action) => {
    if (action.type === 'benchmarkScorecardAll') {
        const {benchmarkScoreGapAnalysisM20,benchmarkScoreGapAnalysisR13,benchmarkScoreGapAnalysisR14,benchmarkScoreGapAnalysisR15,benchmarkScoreGapAnalysisR16,benchmarkScoreGapAnalysisR17,benchmarkScoreGapAnalysisR18,benchmarkScoreGapAnalysisR19,benchmarkScoreGapAnalysisR20,benchmarkScoreGapAnalysisR21,benchmarkScoreGapAnalysisR22,benchmarkScoreGapAnalysisR23} = action.payload;

       


        return {
            ...state,
        }
        
    }
    else {
        return state
    }
}

export default reducer