import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import sum from "../../utils/sum";
import { Link } from 'react-router-dom';
import formatPrice from '../../utils/formatPrice';
import { getCustomerRetentionScorecard, modifyCustomerRetentionScorecard } from '../../state/features/CustomerRetentionScorecardSlice';

export default function CustomerRetentionScorecardContentNew() {

    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        CustomerRetentionScorecardD9: 0,
        CustomerRetentionScorecardD10: 0,
        CustomerRetentionScorecardD11: 0,
        CustomerRetentionScorecardD12: 0,
        CustomerRetentionScorecardD13: 0,
        CustomerRetentionScorecardE8: 0,
        CustomerRetentionScorecardF9: "",
        CustomerRetentionScorecardD15: 0,
        CustomerRetentionScorecardE14: 0,
        CustomerRetentionScorecardF15: "",
        CustomerRetentionScorecardE17: 0,
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getCustomerRetentionScorecard({user_id}))
        .unwrap()
        .then((response) => {
            // console.log("response1",response.data)
            if(response!= undefined  && response.data.clientGrowth){
                let clientGrowth = response.data.clientGrowth[0];
                // console.log(clientGrowth)
                delete clientGrowth.id;
                delete clientGrowth.user_id;
                delete clientGrowth.created_at;
                delete clientGrowth.updated_at;
                const newObj = { ...Inputs }
                for (const key in clientGrowth) {
                    if (clientGrowth.hasOwnProperty(key)) {
                        if(clientGrowth[key])
                        newObj[key] = clientGrowth[key]
                    }
                }
                
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])
    //
    
    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                event.target.value = event.target.value ? event.target.value : 0
                dispatch(modifyCustomerRetentionScorecard({ user_id, [event.target.id] : event.target.value, 'CustomerRetentionScorecardE8': String(Inputs.CustomerRetentionScorecardE8), 'CustomerRetentionScorecardE14': String(Inputs.CustomerRetentionScorecardE14), 'CustomerRetentionScorecardE17': String(Inputs.CustomerRetentionScorecardE17)}))
                .unwrap()
                .then((response) => {
                    // console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        //window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

    useEffect(() => {
        let CustomerRetentionScorecardE8 = sum(Number(Inputs.CustomerRetentionScorecardD9), Number(Inputs.CustomerRetentionScorecardD10), Number(Inputs.CustomerRetentionScorecardD11), Number(Inputs.CustomerRetentionScorecardD12), Number(Inputs.CustomerRetentionScorecardD13));
        let CustomerRetentionScorecardE14 = Number(Inputs.CustomerRetentionScorecardD15);
        let CustomerRetentionScorecardE17 = CustomerRetentionScorecardE8 + CustomerRetentionScorecardE14;

        setInputs({...Inputs, CustomerRetentionScorecardE8, CustomerRetentionScorecardE14, CustomerRetentionScorecardE17});

    }, [Inputs.CustomerRetentionScorecardD9, Inputs.CustomerRetentionScorecardD10, Inputs.CustomerRetentionScorecardD11, Inputs.CustomerRetentionScorecardD12, Inputs.CustomerRetentionScorecardD13, Inputs.CustomerRetentionScorecardD15]);


    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
            {/* row */}
            <div className="container-fluid">
                <div className="head-title">
                    <h3 className='preheading'>Diagnose - Benchmark Tools</h3>
                    <h1 className='mainheading mb-1'>Customer Retention Scorecard</h1>
                </div>
                <div className="target-markets-score-card-table">
                    <div className="table-responsive">
                        <table className="table" style={{width: 1200}}>
                            {/* table 01 */}
                            <thead>
                                <tr>
                                    <td className='black-bg text-white font18px' style={{width: 600, height: 70}}>
                                    Customer Buying Lifetime
                                    </td>
                                    <td className='black-bg text-white text-center' style={{width: 150}}>
                                        <span className='font14px'>Score</span><br/><span className='font11px'>(1 point each)</span>
                                    </td>
                                    <td className='bg-color-blue text-white text-center' style={{width: 50}}>
                                        <div className="do-you-number font14px">{formatPrice(Inputs.CustomerRetentionScorecardE8, 0)}</div>
                                    </td>
                                    <td className='black-bg text-white text-center font18px' style={{width: 400}}>
                                    Opportunities to Improve
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='color-dark font11px'>We allocate budget and staff specifically toward marketing to current customers</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerRetentionScorecardD9" 
                                                value={Inputs.CustomerRetentionScorecardD9} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerRetentionScorecardD9: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td rowSpan={5} className='bg-white padding-0'>
                                        <div className="arrow-targer-market"></div>
                                    </td>
                                    <td rowSpan={5} className='padding-0 bg-color-02'>
                                        <div className="input-fiel-cn">
                                            <textarea type="text" className="field-ipt bg-color-02 font11px color-dark"
                                            id="CustomerRetentionScorecardF9"
                                            value={Inputs.CustomerRetentionScorecardF9}
                                            onChange={(event) => setInputs({ ...Inputs, CustomerRetentionScorecardF9: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='color-dark font11px'>We communicate with all of our customers (active and inactive) at least monthly</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerRetentionScorecardD10" 
                                                value={Inputs.CustomerRetentionScorecardD10} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerRetentionScorecardD10: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='color-dark font11px'>We understand how our customer's experience compares to our top competitors</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerRetentionScorecardD11" 
                                                value={Inputs.CustomerRetentionScorecardD11} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerRetentionScorecardD11: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='color-dark font11px'>We have identified opportunities to improve our customer's experience</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerRetentionScorecardD12" 
                                                value={Inputs.CustomerRetentionScorecardD12} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerRetentionScorecardD12: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='color-dark font11px'>We have optimized our customer's buying experience</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerRetentionScorecardD13" 
                                                value={Inputs.CustomerRetentionScorecardD13} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerRetentionScorecardD13: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            {/* table 02 */}
                            <thead>
                                <tr>
                                    <td className='black-bg text-white font18px' style={{width: 600, height: 70}}>
                                    Customer Service
                                    </td>
                                    <td className='black-bg text-white text-center' style={{width: 150}}>
                                        <span className='font14px'>Score</span><br/><span className='font11px'>(1-low; 5 high)</span>
                                    </td>
                                    <td className='bg-color-blue text-white text-center' style={{width: 50}}>
                                        <div className="do-you-number font14px">{formatPrice(Inputs.CustomerRetentionScorecardE14, 0)}</div>
                                    </td>
                                    <td className='black-bg text-white text-center font18px' style={{width: 400}}>
                                    Opportunities to Improve
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='color-dark font11px'>On a scale of 1-5, what level of customer service do you believe your company provides?</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="CustomerRetentionScorecardD15" 
                                                value={Inputs.CustomerRetentionScorecardD15} 
                                                onChange={(event) => setInputs({ ...Inputs, CustomerRetentionScorecardD15: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='bg-white padding-0'></td>
                                    <td className='padding-0 bg-color-02'>
                                        <div className="input-fiel-cn">
                                            <textarea type="text" className="field-ipt bg-color-02 font11px color-dark"
                                            id="CustomerRetentionScorecardF15"
                                            value={Inputs.CustomerRetentionScorecardF15}
                                            onChange={(event) => setInputs({ ...Inputs, CustomerRetentionScorecardF15: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={4} className='hide-content' style={{height: 20}}></td>
                                </tr>
                            </tbody>
                            {/* table 03 */}
                            <thead>
                                <tr>
                                    <td className='bg-white'>
                                        <div className="nextInput">
                                            <ul>
                                                <li>
                                                    <Link className="next-link" to="/diagnose/customer-retention"> Return to Benchmark Questions
                                                        <span className="icon-three">
                                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                                        </span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td className='black-bg text-white font16px fontBold text-center'>
                                        Total Score
                                    </td>
                                    <td className='bg-color-blue text-white text-center font16px'>
                                        {formatPrice(Inputs.CustomerRetentionScorecardE17, 0)}
                                    </td>
                                    <td className='hide-content'></td>
                                </tr>
                            </thead>
                        </table>
                    </div>  
                </div>
            </div>
        </div>
    )
}