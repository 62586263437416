import React from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from "recharts";

export function WaterFallGraph({dataInput}) {

  const data = dataInput.GraphData;

  return (
    <ResponsiveContainer width="100%" height={300}>
    <BarChart
      width={480}
      height={300}
      data={data}
      margin={{
        top: 20,
        right: 20,
        left: 20,
        bottom: 5
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      {/* <Legend /> */}
      <Bar barSize={40} dataKey="pv" stackId="a" fill="transparent" />
      <Bar barSize={40} dataKey="uv" stackId="a" fill="#1E2B3C">
        {data.map((item, index) => {
          if (item.uv < 0) {
            return <Cell key={index} fill="#f5f2f2" />;
          }
          if (item.pv === 0) {
            return <Cell key={index} fill="#1E2B3C" />;
          }          
          return <Cell key={index} fill="#c2c7cd" />;
        })}
      </Bar>
    </BarChart>
    </ResponsiveContainer>
  );
}