import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import sum from "../../utils/sum";
import { Link } from 'react-router-dom';
import formatPrice from '../../utils/formatPrice';
import { getProfitMarginScorecard, modifyProfitMarginScorecard } from '../../state/features/ProfitMarginScorecardSlice';

export default function ProfitMarginScorecardContentNew() {

    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        ProfitMarginScorecardE7: 0,
        ProfitMarginScorecardE8: 0,
        ProfitMarginScorecardE9: 0,
        ProfitMarginScorecardE10: 0,
        ProfitMarginScorecardE11: 0,
        ProfitMarginScorecardE12: 0,
        ProfitMarginScorecardE13: 0,
        ProfitMarginScorecardE14: 0,
        ProfitMarginScorecardE15: 0,
        ProfitMarginScorecardE16: 0,
        ProfitMarginScorecardE18: 0,
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getProfitMarginScorecard({user_id}))
        .unwrap()
        .then((response) => {
            console.log("response2",response.data)
            if(response!= undefined  && response.data.clientGrowth){
                let clientGrowth = response.data.clientGrowth[0];
                // console.log(clientGrowth)
                delete clientGrowth.id;
                delete clientGrowth.user_id;
                delete clientGrowth.created_at;
                delete clientGrowth.updated_at;
                const newObj = { ...Inputs }
                for (const key in clientGrowth) {
                    if (clientGrowth.hasOwnProperty(key)) {
                        if(clientGrowth[key])
                        newObj[key] = clientGrowth[key]
                    }
                }
                
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])
    //
    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                event.target.value = event.target.value ? event.target.value : 0
                dispatch(modifyProfitMarginScorecard({ user_id, [event.target.id] : event.target.value, 'ProfitMarginScorecardE18': String(Inputs.ProfitMarginScorecardE18)}))
                .unwrap()
                .then((response) => {
                    // console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        //window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

    useEffect(() => {
        let ProfitMarginScorecardE18 = sum(Number(Inputs.ProfitMarginScorecardE7), Number(Inputs.ProfitMarginScorecardE8), Number(Inputs.ProfitMarginScorecardE9), Number(Inputs.ProfitMarginScorecardE10), Number(Inputs.ProfitMarginScorecardE11), Number(Inputs.ProfitMarginScorecardE12), Number(Inputs.ProfitMarginScorecardE13), Number(Inputs.ProfitMarginScorecardE14), Number(Inputs.ProfitMarginScorecardE15), Number(Inputs.ProfitMarginScorecardE16));
        // console.log(ProfitMarginScorecardE18)
        setInputs({...Inputs, ProfitMarginScorecardE18});

    }, [Inputs.ProfitMarginScorecardE7, Inputs.ProfitMarginScorecardE8, Inputs.ProfitMarginScorecardE9, Inputs.ProfitMarginScorecardE10, Inputs.ProfitMarginScorecardE11, Inputs.ProfitMarginScorecardE12, Inputs.ProfitMarginScorecardE13, Inputs.ProfitMarginScorecardE14, Inputs.ProfitMarginScorecardE15, Inputs.ProfitMarginScorecardE16]);

    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
            {/* row */}
            <div className="container-fluid">
                <div className="head-title">
                    <h3 className='preheading'>Tools - Competitive Strategy</h3>
                    <h1 className='mainheading'>Profit Margin Scorecard</h1>
                </div>
                <div className="profit-margin-score-card">
                    <div className="table-responsive">
                        <table className="table" style={{width: 1150}}>
                            <thead>
                                <tr>
                                    <td className='bg-color01 text-white text-center font16px' style={{width: 300}}>
                                    Function / Department
                                    </td>
                                    <td className='bg-color01 text-white text-center font14px' style={{widtH: 700}}>
                                    What do you regularly do to increase your profit margins?
                                    </td>
                                    <td className='bg-color01 text-white text-center' style={{width: 150}}>
                                        <span className='font14px'>Score</span><br/><span className='font10px'>(1=low; 5=high)</span>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                {/* some 01 */}
                                <tr>
                                    <td className='bg-color01 text-white text-center' rowSpan={3}>
                                        <div className="profit-margin-outer">
                                            <div className="ProfitImagesBox">
                                                <img src="../images/profit-margin/icon_01.png" alt="competitiveImg" />
                                            </div>
                                            <div className="profit-contetn-title">
                                                <p>Sales</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className='bg-color-light-blue text-white'>
                                        <div className="proprietary-infomation">
                                            <p className='font11px m-0 color-dark'>Train sales reps in effective quoting, pricing, and negotiating strategies.</p>
                                        </div>
                                    </td>
                                    <td  className='padding-0 bg-color-01'>
                                        <div className="input-f factor-inputs changes-input">
                                            <input type="text" className="bg-color-01 font11px color-dark" 
                                                id="ProfitMarginScorecardE7" 
                                                value={Inputs.ProfitMarginScorecardE7} 
                                                onChange={(event) => setInputs({ ...Inputs, ProfitMarginScorecardE7: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue text-white'>
                                        <div className="proprietary-infomation">
                                            <p className='font11px m-0 color-dark'>Give discounts and bonuses only when deserved.</p>
                                        </div>
                                    </td>
                                    <td  className='padding-0 bg-color-01'>
                                        <div className="input-f factor-inputs changes-input">
                                            <input type="text" className="bg-color-01 font11px color-dark" 
                                                id="ProfitMarginScorecardE8" 
                                                value={Inputs.ProfitMarginScorecardE8} 
                                                onChange={(event) => setInputs({ ...Inputs, ProfitMarginScorecardE8: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue text-white'>
                                        <div className="proprietary-infomation">
                                            <p className='font11px m-0 color-dark'>Sell added value.</p>
                                        </div>
                                    </td>
                                    <td  className='padding-0 bg-color-01'>
                                        <div className="input-f factor-inputs changes-input">
                                            <input type="text" className="bg-color-01 font11px color-dark" 
                                                id="ProfitMarginScorecardE9" 
                                                value={Inputs.ProfitMarginScorecardE9} 
                                                onChange={(event) => setInputs({ ...Inputs, ProfitMarginScorecardE9: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                {/* some 02 */}
                                <tr>
                                    <td className='bg-color01 text-white text-center' rowSpan={4}>
                                        <div className="profit-margin-outer">
                                            <div className="ProfitImagesBox">
                                                <img src="../images/profit-margin/icon_02.png" alt="competitiveImg" />
                                            </div>
                                            <div className="profit-contetn-title">
                                                <p>Marketing</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className='bg-blue-accent-01 text-white'>
                                        <div className="proprietary-infomation">
                                            <p className='font11px m-0 color-dark'>Systematically test pricing.</p>
                                        </div>
                                    </td>
                                    <td  className='padding-0 bg-color-01'>
                                        <div className="input-f factor-inputs changes-input">
                                            <input type="text" className="bg-color-01 font11px color-dark" 
                                                id="ProfitMarginScorecardE10" 
                                                value={Inputs.ProfitMarginScorecardE10} 
                                                onChange={(event) => setInputs({ ...Inputs, ProfitMarginScorecardE10: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-blue-accent-01 text-white'>
                                        <div className="proprietary-infomation">
                                            <p className='font11px m-0 color-dark'>Target only the most profitable areas (products & services, markets, etc).</p>
                                        </div>
                                    </td>
                                    <td  className='padding-0 bg-color-01'>
                                        <div className="input-f factor-inputs changes-input">
                                            <input type="text" className="bg-color-01 font11px color-dark" 
                                                id="ProfitMarginScorecardE11" 
                                                value={Inputs.ProfitMarginScorecardE11} 
                                                onChange={(event) => setInputs({ ...Inputs, ProfitMarginScorecardE11: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-blue-accent-01 text-white'>
                                        <div className="proprietary-infomation">
                                            <p className='font11px m-0 color-dark'>Use direct response marketing that can be held accountable for results.</p>
                                        </div>
                                    </td>
                                    <td  className='padding-0 bg-color-01'>
                                        <div className="input-f factor-inputs changes-input">
                                            <input type="text" className="bg-color-01 font11px color-dark" 
                                                id="ProfitMarginScorecardE12" 
                                                value={Inputs.ProfitMarginScorecardE12} 
                                                onChange={(event) => setInputs({ ...Inputs, ProfitMarginScorecardE12: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-blue-accent-01 text-white'>
                                        <div className="proprietary-infomation">
                                            <p className='font11px m-0 color-dark'>Focus on improving customer retention and loyalty.</p>
                                        </div>
                                    </td>
                                    <td  className='padding-0 bg-color-01'>
                                        <div className="input-f factor-inputs changes-input">
                                            <input type="text" className="bg-color-01 font11px color-dark" 
                                                id="ProfitMarginScorecardE13" 
                                                value={Inputs.ProfitMarginScorecardE13} 
                                                onChange={(event) => setInputs({ ...Inputs, ProfitMarginScorecardE13: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                {/* some 01 */}
                                <tr>
                                    <td className='bg-color01 text-white text-center' rowSpan={3}>
                                        <div className="profit-margin-outer">
                                            <div className="ProfitImagesBox">
                                                <img src="../images/profit-margin/icon_03.png" alt="competitiveImg" />
                                            </div>
                                            <div className="profit-contetn-title">
                                                <p>Purchasing & Operations</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className='bg-color-light-blue text-white'>
                                        <div className="proprietary-infomation">
                                            <p className='font11px m-0 color-dark'>Train buying staff in effective negotiation; always negotiate.</p>
                                        </div>
                                    </td>
                                    <td  className='padding-0 bg-color-01'>
                                        <div className="input-f factor-inputs changes-input">
                                            <input type="text" className="bg-color-01 font11px color-dark" 
                                                id="ProfitMarginScorecardE14" 
                                                value={Inputs.ProfitMarginScorecardE14} 
                                                onChange={(event) => setInputs({ ...Inputs, ProfitMarginScorecardE14: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue text-white'>
                                        <div className="proprietary-infomation">
                                            <p className='font11px m-0 color-dark'>Cut low margin products and services.</p>
                                        </div>
                                    </td>
                                    <td  className='padding-0 bg-color-01'>
                                        <div className="input-f factor-inputs changes-input">
                                            <input type="text" className="bg-color-01 font11px color-dark" 
                                                id="ProfitMarginScorecardE15" 
                                                value={Inputs.ProfitMarginScorecardE15} 
                                                onChange={(event) => setInputs({ ...Inputs, ProfitMarginScorecardE15: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue text-white'>
                                        <div className="proprietary-infomation">
                                            <p className='font11px m-0 color-dark'>Have a repeatable system to cut waste, reduce operating expenses, and improve efficiencies.</p>
                                        </div>
                                    </td>
                                    <td  className='padding-0 bg-color-01'>
                                        <div className="input-f factor-inputs changes-input">
                                            <input type="text" className="bg-color-01 font11px color-dark" 
                                                id="ProfitMarginScorecardE16" 
                                                value={Inputs.ProfitMarginScorecardE16} 
                                                onChange={(event) => setInputs({ ...Inputs, ProfitMarginScorecardE16: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='hide-content'>hide</td>
                                    <td className='bg-color-blue text-white text-center font16px fontExtraBold'>
                                        Total Score
                                    </td>
                                    <td  className='bg-color-blue text-white text-center font16px fontExtraBold'>
                                        {formatPrice(Inputs.ProfitMarginScorecardE18, 0)}                                        
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div> 
                    <div className="nextInput">
                        <ul>
                            <li>
                                <Link className="next-link" to="/diagnose/customer-transactions"> Return to Benchmark Questions
                                    <span className="icon-three">
                                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                                    </span>
                                </Link>
                            </li>
                        </ul>
                    </div> 
                </div>
            </div>
        </div>
    );
}