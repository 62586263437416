import React from 'react'
import Sidebar from '../components/SidebarNew';
import Content from '../components/content/FiveStepStrategyContentNew';
import Footer from '../components/Footer';

export default function FiveStepStrategyNew() {
  return (
    <div>

      <div id="main-wrapper">

        <Sidebar />

        <Content />

      </div>

      <Footer />

    </div>
  )
}
