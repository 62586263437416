import React from 'react';
import { Link, useLocation } from "react-router-dom";
import  UserDropdown  from '../UserDropdown';
import CopyRightText from '../CopyRightText';
import {useSWRConfig} from "swr";
import getMenuChildren from '../../utils/getMenuChildren';
import MenuSingleList from '../List/MenuSingleList';
import MenuMultiList from '../List/MenuMultiList';

const ToolsContent = () => {
    const { fallback, isLoading } = useSWRConfig();
    const location = useLocation();
    const submenus = getMenuChildren(location.pathname, fallback?.menus[0].children)
    return (
        <div className="content-body bg-dard-color bg_bottom">
            <UserDropdown />
            {/* row */}
            <div className="container-fluid">
                <div className="head-title">
                    <Link to="/home"><h3 className='font-sise-20'>The Growth Catalyst</h3></Link>
                    <h1 className='font-sise-42 the-revense'>Tools</h1>
                    <div className='row'>
                        <div className="col-12 col-md-8 col-lg-10">
                            <div className="leftContetn">
                                <p className='font-sise-16'>
                                    A collection of additional tools to help you reach your goals faster and more effectively.
                                </p>
                                <ul className='list-of-data'>
                                    <li className='font-sise-16'>Create greater value for your target market.</li>
                                    <li className='font-sise-16'>Build a more focused and effective growth strategy.</li>
                                    <li className='font-sise-16'>Create marketing messages that appeal to your targets.</li>
                                    <li className='font-sise-16'>Build a strategy that helps you win on the fields where you play.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-2">
                            <div className="imageBx">
                                <img src={window.location.origin+"/images/Home_page_img/Picture7.png"} width={150 + 'px'} height={150 + 'px'} alt="Revenue Growth Drivers" title="Revenue Growth Drivers" />
                            </div>
                        </div>
                    </div>
                    <div className="row card-rwo diagnose-page-tools mt-5" style={{maxWidth: 1400}}>
                        {submenus.map((menu, index) => (
                            <div className={`col-12 col-md-6 ${(menu.children.length < 9)? 'col-lg-3' : ''} mar-b`} key={menu.parent_id+"_"+index}>
                                <div className="diagnosediv prescribe-hg">
                                    <h3 className='font-sise-bold-18'>
                                        <Link to="#">{menu.label}</Link>
                                    </h3>
                                    {
                                        (menu.children.length < 9)?
                                        <MenuSingleList submenu={menu.children} id={menu.parent_id+"_"+index}/>
                                        :
                                        <MenuMultiList submenu={menu.children} id={menu.parent_id+"_"+index}/>
                                    }
                                </div>
                            </div>
                        ))}
                        {/* <div className="col-12 col-md-6 col-lg-3 mar-b">
                            <div className="diagnosediv tools-hg">
                                <h3 className='font-sise-bold-18'>
                                    <Link to="#">Value Creation</Link>
                                </h3>
                                <ul className='list-of-data'>
                                    <li className='font-sise-16'><Link to="/tools/zero-in-on-value" style={{color: 'white !important'}}>Zero In on Total Value</Link></li>
                                    <li className='font-sise-16'><Link to="/tools/customer-transformation" style={{color: 'white !important'}}>Customer Transformation</Link></li>
                                    <li className='font-sise-16'><Link to="/tools/differentiation-diagnostic" style={{color: 'white !important'}}>Differentiation Diagnostic</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mar-b">
                            <div className="diagnosediv tools-hg">
                                <h3 className='font-sise-bold-18'>
                                    <Link to="#">Growth Strategy</Link>
                                </h3>
                                <ul className='list-of-data'>
                                    <li className='font-sise-16'><Link to="/tools/growth-option-scorecard" style={{color: 'white !important'}}>Growth Option Scorecard</Link></li>
                                    <li className='font-sise-16'><Link to="/tools/critical-growth-factors" style={{color: 'white !important'}}>Critical Growth Factors</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mar-b">
                            <div className="diagnosediv tools-hg">
                                <h3 className='font-sise-bold-18'>
                                    <Link to="#">Messaging</Link>
                                </h3>
                                <ul className='list-of-data'>
                                    <li className='font-sise-16'><Link to="/tools/message-selection-matrix" style={{color: 'white !important'}}>Message Selection Matrix</Link></li>
                                    <li className='font-sise-16'><Link to="/tools/whole-brain-messaging" style={{color: 'white !important'}}>Whole Brain Messaging</Link></li>
                                    <li className='font-sise-16'><Link to="/tools/case-for-change" style={{color: 'white !important'}}>Case for Change</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mar-b">
                            <div className="diagnosediv tools-hg">
                                <h3 className='font-sise-bold-18'>
                                    <Link to="#">General Strategy</Link>
                                </h3>
                                <ul className='list-of-data'>
                                    <li className='font-sise-16'><Link to="/tools/five-step-strategy" style={{color: 'white !important'}}>Five-Step Strategy</Link></li>
                                    <li className='font-sise-16'><Link to="/tools/brand-scorecard" style={{color: 'white !important'}}>Brand Scorecard</Link></li>
                                    <li className='font-sise-16'><Link to="/tools/the-big-swot" style={{color: 'white !important'}}>The Big SWOT</Link></li>
                                    <li className='font-sise-16'><Link to="/tools/competitive-analysis" style={{color: 'white !important'}}>Competitive Analysis</Link></li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>

                <div className='copyRgight'>
                    <CopyRightText />
                </div>

                <div className='space-di'></div>

            </div>
        </div>
    );
}

export default ToolsContent;
