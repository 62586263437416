import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getCompetitiveAdvantageAudit, modifyCompetitiveAdvantageAudit } from "../../state/features/CompetitiveAdvantageAuditSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';

export default function CompetitiveAdvantageAuditContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        CompetitiveAdvantageAuditE13 : '',
        CompetitiveAdvantageAuditF13 : '',
        CompetitiveAdvantageAuditE14 : '',
        CompetitiveAdvantageAuditF14 : '',
        CompetitiveAdvantageAuditE15 : '',
        CompetitiveAdvantageAuditF15 : '',
        CompetitiveAdvantageAuditE16 : '',
        CompetitiveAdvantageAuditF16 : '',
        CompetitiveAdvantageAuditE17 : '',
        CompetitiveAdvantageAuditF17 : '',
        CompetitiveAdvantageAuditE18 : '',
        CompetitiveAdvantageAuditF18 : '',
        CompetitiveAdvantageAuditE19 : '',
        CompetitiveAdvantageAuditF19 : '',
        CompetitiveAdvantageAuditE20 : '',
        CompetitiveAdvantageAuditF20 : '',
        CompetitiveAdvantageAuditE21 : '',
        CompetitiveAdvantageAuditF21 : '',
        CompetitiveAdvantageAuditE22 : '',
        CompetitiveAdvantageAuditF22 : '',
        CompetitiveAdvantageAuditE23 : '',
        CompetitiveAdvantageAuditF23 : '',
        CompetitiveAdvantageAuditE24 : '',
        CompetitiveAdvantageAuditF24 : '',
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getCompetitiveAdvantageAudit({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.growthPlanning)
            if(response!= undefined  && response.data.growthPlanning){
                let growthPlanning = response.data.growthPlanning[0];
                console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key]
                    }
                }
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyCompetitiveAdvantageAudit({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        //window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

  return (
  <div className="content-body">
    <UserDropdown /> 
  {/* row */}
    <div className="container-fluid">
        <div className="head-title bigsout">
            <h2>Competitive Advantage Audit</h2>  
            <p>The more you can drive multiple sources of competitive advantage, the better you can defend your value proposition against competitors.  Evaluate which sources of sustainable competitive advantage you currently have, prioritize those which are important to your industry or that you think you can drive, and then brainstorm various ways to improve the different sources of sustainable competitive advantage. The following template can help guide your brainstorming.</p>                    		
        </div>
        <Spinner />
        <div className="card">
            <div className="card-body pl-0">
            <div className="table-responsive">
                <table className="table">
                <thead>
                    <tr className="thead-primary">
                    <th />
                    <th>Sources of Competitive Advantage</th>
                    <th>Current Rating</th>
                    <th>Improvement Ideas</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td className="bluetab text-center-c"><img src="../images/competitive-icon1.png" /></td>
                    <td>Sources of Competitive Advantage	Current Rating	Improvement Ideas Proprietary Information - can be knowledge, processes, customers, and other types.</td>
                    <td className="lightyello padding-0">
                        <form className="Focused-planning-f">
                            <input type="text" className="fucus-pla"
                                id="CompetitiveAdvantageAuditE13"
                                value={Inputs.CompetitiveAdvantageAuditE13}
                                onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditE13: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder={"Current Rating"}
                            />
                        </form>
                    </td>
                    <td>
                        <input type="text" className="form-control inputText"
                            id="CompetitiveAdvantageAuditF13"
                            value={Inputs.CompetitiveAdvantageAuditF13}
                            onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditF13: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    </tr>
                    <tr>
                    <td className="bluetab text-center-c"><img src="../images/competitive-icon2.png" /></td>
                    <td>Intellectual Property - proprietary formulas, systems, processes, and patents.</td>
                    <td className="lightyello padding-0">
                        <form className="Focused-planning-f">
                            <input type="text" className="fucus-pla"
                                id="CompetitiveAdvantageAuditE14"
                                value={Inputs.CompetitiveAdvantageAuditE14}
                                onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditE14: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder={"Current Rating"}
                            />
                        </form>
                    </td>
                    <td>
                        <input type="text" className="form-control inputText"
                            id="CompetitiveAdvantageAuditF14"
                            value={Inputs.CompetitiveAdvantageAuditF14}
                            onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditF14: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    </tr>
                    <tr>
                    <td className="bluetab text-center-c"><img src="../images/competitive-icon3.png" /></td>
                    <td>Innovation - uniqueness and the novel use of technology and design.  Does not have to be disruptive, but should save the customer time, money, or solve a real problem.</td>
                    <td className="lightyello padding-0">
                        <form className="Focused-planning-f">
                            <input type="text" className="fucus-pla"
                                id="CompetitiveAdvantageAuditE15"
                                value={Inputs.CompetitiveAdvantageAuditE15}
                                onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditE15: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder={"Current Rating"}
                            />
                        </form>
                    </td>
                    <td>
                        <input type="text" className="form-control inputText"
                            id="CompetitiveAdvantageAuditF15"
                            value={Inputs.CompetitiveAdvantageAuditF15}
                            onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditF15: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    </tr>
                    <tr>
                    <td className="bluetab text-center-c"><img src="../images/competitive-icon4.png" /></td>
                    <td>Price - the company with the lowest price in a market; that advantage is rarely sustainable.</td>
                    <td className="lightyello padding-0">
                        <form className="Focused-planning-f">
                            <input type="text" className="fucus-pla"
                                id="CompetitiveAdvantageAuditE16"
                                value={Inputs.CompetitiveAdvantageAuditE16}
                                onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditE16: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder={"Current Rating"}
                            />
                        </form>
                    </td>
                    <td>
                        <input type="text" className="form-control inputText"
                            id="CompetitiveAdvantageAuditF16"
                            value={Inputs.CompetitiveAdvantageAuditF16}
                            onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditF16: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    </tr>
                    <tr>
                    <td className="bluetab text-center-c"><img src="../images/competitive-icon5.png" /></td>
                    <td>Agility - the ability for a compay to recognize and adapt to changes in the marketplace.</td>
                    <td className="lightyello padding-0">
                        <form className="Focused-planning-f">
                            <input type="text" className="fucus-pla"
                                id="CompetitiveAdvantageAuditE17"
                                value={Inputs.CompetitiveAdvantageAuditE17}
                                onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditE17: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder={"Current Rating"}
                            />
                        </form>
                    </td>
                    <td>
                        <input type="text" className="form-control inputText"
                            id="CompetitiveAdvantageAuditF17"
                            value={Inputs.CompetitiveAdvantageAuditF17}
                            onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditF17: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    </tr>
                    <tr>
                    <td className="bluetab text-center-c"><img src="../images/competitive-icon5.png" /></td>
                    <td>Network Effects - the value of the product or service increases with the number of users or products.</td>
                    <td className="lightyello padding-0">
                        <form className="Focused-planning-f">
                            <input type="text" className="fucus-pla"
                                id="CompetitiveAdvantageAuditE18"
                                value={Inputs.CompetitiveAdvantageAuditE18}
                                onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditE18: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder={"Current Rating"}
                            />
                        </form>
                    </td>
                    <td>
                        <input type="text" className="form-control inputText"
                            id="CompetitiveAdvantageAuditF18"
                            value={Inputs.CompetitiveAdvantageAuditF18}
                            onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditF18: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    </tr>
                    <tr>
                    <td className="bluetab text-center-c"><img src="../images/competitive-icon5.png" /></td>
                    <td>Brand Loyalty - driven by the strength of the brand, deisgn, products, and loyalty programs.</td>
                    <td className="lightyello padding-0">
                        <form className="Focused-planning-f">
                            <input type="text" className="fucus-pla"
                                id="CompetitiveAdvantageAuditE19"
                                value={Inputs.CompetitiveAdvantageAuditE19}
                                onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditE19: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder={"Current Rating"}
                            />
                        </form>
                    </td>
                    <td>
                        <input type="text" className="form-control inputText"
                            id="CompetitiveAdvantageAuditF19"
                            value={Inputs.CompetitiveAdvantageAuditF19}
                            onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditF19: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    </tr>
                    <tr>
                    <td className="bluetab text-center-c"><img src="../images/competitive-icon5.png" /></td>
                    <td>Emotional Connection - 50% of your customer's decision-making comes from emotions.  Make them feel good or make their life easier.</td>
                    <td className="lightyello padding-0">
                        <form className="Focused-planning-f">
                            <input type="text" className="fucus-pla"
                                id="CompetitiveAdvantageAuditE20"
                                value={Inputs.CompetitiveAdvantageAuditE20}
                                onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditE20: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder={"Current Rating"}
                            />
                        </form>
                    </td>
                    <td>
                        <input type="text" className="form-control inputText"
                            id="CompetitiveAdvantageAuditF20"
                            value={Inputs.CompetitiveAdvantageAuditF20}
                            onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditF20: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    </tr>
                    <tr>
                    <td className="bluetab text-center-c"><img src="../images/competitive-icon6.png" /></td>
                    <td>Experience Innovation - start with what problem you solve for your customer, then pick the opposite experience.  How do you want them to feel?</td>
                    <td className="lightyello padding-0">
                        <form className="Focused-planning-f">
                            <input type="text" className="fucus-pla"
                                id="CompetitiveAdvantageAuditE21"
                                value={Inputs.CompetitiveAdvantageAuditE21}
                                onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditE21: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder={"Current Rating"}
                            />
                        </form>
                    </td>
                    <td>
                        <input type="text" className="form-control inputText"
                            id="CompetitiveAdvantageAuditF21"
                            value={Inputs.CompetitiveAdvantageAuditF21}
                            onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditF21: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    </tr>
                    <tr>
                    <td className="bluetab text-center-c"><img src="../images/competitive-icon7.png" /></td>
                    <td>Locked-Up Supply - occurs when there are few to no other alternatives in the supply of a product/service (DeBeers Diamonds).</td>
                    <td className="lightyello padding-0">
                        <form className="Focused-planning-f">
                            <input type="text" className="fucus-pla"
                                id="CompetitiveAdvantageAuditE22"
                                value={Inputs.CompetitiveAdvantageAuditE22}
                                onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditE22: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder={"Current Rating"}
                            />
                        </form>
                    </td>
                    <td>
                        <input type="text" className="form-control inputText"
                            id="CompetitiveAdvantageAuditF22"
                            value={Inputs.CompetitiveAdvantageAuditF22}
                            onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditF22: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    </tr>
                    <tr>
                    <td className="bluetab text-center-c"><img src="../images/competitive-icon8.png" /></td>
                    <td>Location - prime physical locations accessible to customer segments.</td>
                    <td className="lightyello padding-0">
                        <form className="Focused-planning-f">
                            <input type="text" className="fucus-pla"
                                id="CompetitiveAdvantageAuditE23"
                                value={Inputs.CompetitiveAdvantageAuditE23}
                                onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditE23: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder={"Current Rating"}
                            />
                        </form>
                    </td>
                    <td>
                        <input type="text" className="form-control inputText"
                            id="CompetitiveAdvantageAuditF23"
                            value={Inputs.CompetitiveAdvantageAuditF23}
                            onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditF23: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    </tr>
                    <tr>
                    <td className="bluetab text-center-c"><img src="../images/competitive-icon5.png" /></td>
                    <td>Scale - scale over competitors helps drive down costs and pricing through leverage when purchasing, volume production, marketing, fixed costs, and partners.</td>
                    <td className="lightyello padding-0">
                        <form className="Focused-planning-f">
                            <input type="text" className="fucus-pla"
                                id="CompetitiveAdvantageAuditE24"
                                value={Inputs.CompetitiveAdvantageAuditE24}
                                onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditE24: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder={"Current Rating"}
                            />
                        </form>
                    </td>
                    <td>
                        <input type="text" className="form-control inputText"
                            id="CompetitiveAdvantageAuditF24"
                            value={Inputs.CompetitiveAdvantageAuditF24}
                            onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditF24: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                        />
                    </td>
                    </tr>
                </tbody>
                </table>
            </div>
            </div>
        </div>
    </div>
</div>

  )
}