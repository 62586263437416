import React from 'react'
import { Link } from "react-router-dom";

export default function TopHead() {
    return (
        <div className="nav-header">
            <Link to="/home" className="brand-logo-new">
                <img src={window.location.origin+"/images/svg/Logo.png"} alt="logo" style={{marginLeft: 0}}/>
            </Link>
            <div className="nav-control">
                <div className="hamburger">
                    <span className="line" /><span className="line" /><span className="line" />
                </div>
            </div>
        </div>
    )
}
