import { useEffect } from "react";
import { useNavigate, useSearchParams  } from "react-router-dom";
function ReturnBackRoute() {
    const navigate = useNavigate();   
    const [searchParams] = useSearchParams();
    useEffect(() => {
        setTimeout(function() {
            navigate(searchParams.get('url')) // you will go one page back
        }, 2000); 
    },[]);
}

export default ReturnBackRoute;