const initialState = {
    purchaseFrequencyD12: 0,
    purchaseFrequencyD13: 0,
    purchaseFrequencyD17: 0,
    purchaseFrequencyD18: 0,
    purchaseFrequencyE17: 0,
    purchaseFrequencyE18: 0,
    purchaseFrequencyF18: 0,
    purchaseFrequencyG18: 0,
    purchaseFrequencyF17: 0,
    purchaseFrequencyG17: 0,
    purchaseFrequencyH18: 0,
    purchaseFrequencyI18: 0,
    purchaseFrequencyH17: 0,
    purchaseFrequencyI17: 0,
    purchaseFrequencyD22: 0,
    purchaseFrequencyE22: 0,
    purchaseFrequencyF22: 0,
    purchaseFrequencyG22: 0,
    purchaseFrequencyH22: 0,
    purchaseFrequencyI22: 0,
    purchaseFrequencyD23: 0,
    purchaseFrequencyE23: 0,
    purchaseFrequencyF23: 0,
    purchaseFrequencyG23: 0,
    purchaseFrequencyH23: 0,
    purchaseFrequencyI23: 0,
    purchaseFrequencyD25: 0,
    purchaseFrequencyE25: 0,
    purchaseFrequencyF25: 0,
    purchaseFrequencyG25: 0,
    purchaseFrequencyH25: 0,
    purchaseFrequencyI25: 0,
    purchaseFrequencyD26: 0,
    purchaseFrequencyE26: 0,
    purchaseFrequencyF26: 0,
    purchaseFrequencyG26: 0,
    purchaseFrequencyH26: 0,
    purchaseFrequencyI26: 0,
    purchaseFrequencyD28: 0,
    purchaseFrequencyE28: 0,
    purchaseFrequencyF28: 0,
    purchaseFrequencyG28: 0,
    purchaseFrequencyH28: 0,
    purchaseFrequencyI28: 0,
}
const reducer = (state = initialState, action) => {
    if (action.type === 'purchaseFrequencyD12') {
        return {
            ...state, //copying the original state
            purchaseFrequencyD12: (action.payload) ? parseFloat(action.payload) : 0
        }
    }
    else if (action.type === 'purchaseFrequencyD13') {
        return {
            ...state, //copying the original state
            purchaseFrequencyD13: (action.payload) ? parseFloat(action.payload) : 0
        }
    }
    else if (action.type === 'purchaseFrequencyIncreasingPurchase') {
        let purchaseFrequencyD12 = action.payload.purchaseFrequencyD12;
        let CustomerFinancialValuesD16 = action.payload.CustomerFinancialValues.CustomerFinancialValuesD16;
        let CustomerFinancialValuesD18 = action.payload.CustomerFinancialValues.CustomerFinancialValuesD18;
        let CustomerFinancialValuesD19 = action.payload.CustomerFinancialValues.CustomerFinancialValuesD19;
        let CustomerFinancialValuesD20 = action.payload.CustomerFinancialValues.CustomerFinancialValuesD20;
        let clientInputsH41 = action.payload.clientInputsH41;

        let purchaseFrequencyD17 = (parseFloat(purchaseFrequencyD12) * parseFloat(state.purchaseFrequencyD13) / 100).toFixed(1)
        let purchaseFrequencyD18 = ((parseFloat(purchaseFrequencyD12) + parseFloat(purchaseFrequencyD17))).toFixed(1)
        let purchaseFrequencyE17 = ((parseFloat(purchaseFrequencyD12) * parseFloat(4) / 100)).toFixed(1)
        let purchaseFrequencyE18 = ((parseFloat(purchaseFrequencyD12) + parseFloat(purchaseFrequencyE17))).toFixed(1)
        let purchaseFrequencyF18 = (parseFloat(purchaseFrequencyE18 * 4 / 100) + parseFloat(purchaseFrequencyE18)).toFixed(1)
        let purchaseFrequencyG18 = (parseFloat(purchaseFrequencyF18 * 4 / 100) + parseFloat(purchaseFrequencyF18)).toFixed(1)
        let purchaseFrequencyF17 = (parseFloat(purchaseFrequencyF18) - parseFloat(purchaseFrequencyD12)).toFixed(1)
        let purchaseFrequencyG17 = (parseFloat(purchaseFrequencyG18) - parseFloat(purchaseFrequencyD12)).toFixed(1)
        let purchaseFrequencyH18 = (parseFloat(purchaseFrequencyG18 * 4 / 100) + parseFloat(purchaseFrequencyG18)).toFixed(1)
        let purchaseFrequencyI18 = (parseFloat(purchaseFrequencyH18 * 4 / 100) + parseFloat(purchaseFrequencyH18)).toFixed(1)
        let purchaseFrequencyH17 = (parseFloat(purchaseFrequencyH18) - parseFloat(purchaseFrequencyD12)).toFixed(1)
        let purchaseFrequencyI17 = (parseFloat(purchaseFrequencyI18) - parseFloat(purchaseFrequencyD12)).toFixed(1)

        let purchaseFrequencyD22 = (parseFloat(purchaseFrequencyD17) * parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD20)).toFixed(2)

        let purchaseFrequencyE22 = (parseFloat(purchaseFrequencyE17) * parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD20)).toFixed(2)

        let purchaseFrequencyF22 = ((parseFloat(purchaseFrequencyF18) - parseFloat(purchaseFrequencyD12)) * parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD20)).toFixed(2)

        let purchaseFrequencyH22 = ((parseFloat(purchaseFrequencyH18) - parseFloat(purchaseFrequencyD12)) * parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD20)).toFixed(2)

        let purchaseFrequencyG22 = ((parseFloat(purchaseFrequencyG18) - parseFloat(purchaseFrequencyD12)) * parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD20)).toFixed(2)

        let purchaseFrequencyI22 = ((parseFloat(purchaseFrequencyI18) - parseFloat(purchaseFrequencyD12)) * parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD20)).toFixed(2)

        let purchaseFrequencyD23 = (parseFloat(purchaseFrequencyD17) * (parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD20)) * parseFloat(CustomerFinancialValuesD19) / 100).toFixed(2)

        let purchaseFrequencyE23 = (parseFloat(purchaseFrequencyE17) * parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD20) * parseFloat(CustomerFinancialValuesD19) / 100).toFixed(2)

        let purchaseFrequencyF23 = ((parseFloat(purchaseFrequencyF18) - parseFloat(purchaseFrequencyD12)) * parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD20) * parseFloat(CustomerFinancialValuesD19) / 100).toFixed(2)

        let purchaseFrequencyH23 = (parseFloat(purchaseFrequencyH17) * parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD20) * parseFloat(CustomerFinancialValuesD19) / 100).toFixed(2)

        let purchaseFrequencyG23 = ((parseFloat(purchaseFrequencyG18) - parseFloat(purchaseFrequencyD12)) * parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD20) * parseFloat(CustomerFinancialValuesD19) / 100).toFixed(2)

        let purchaseFrequencyI23 = ((parseFloat(purchaseFrequencyI18) - parseFloat(purchaseFrequencyD12)) * parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD20) * parseFloat(CustomerFinancialValuesD19) / 100).toFixed(2)

        let purchaseFrequencyD25 = (parseFloat(purchaseFrequencyD17) * parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD20) * parseFloat(CustomerFinancialValuesD18)).toFixed(2)

        let purchaseFrequencyE25 = (parseFloat(purchaseFrequencyE17) * parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD20) * parseFloat(CustomerFinancialValuesD18)).toFixed(2)

        let purchaseFrequencyF25 = ((parseFloat(purchaseFrequencyF18) - parseFloat(purchaseFrequencyD12)) * parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD20) * parseFloat(CustomerFinancialValuesD18)).toFixed(2)

        let purchaseFrequencyH25 = ((parseFloat(purchaseFrequencyH18) - parseFloat(purchaseFrequencyD12)) * parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD20) * parseFloat(CustomerFinancialValuesD18)).toFixed(2)

        let purchaseFrequencyG25 = ((parseFloat(purchaseFrequencyG18) - parseFloat(purchaseFrequencyD12)) * parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD20) * parseFloat(CustomerFinancialValuesD18)).toFixed(2)

        let purchaseFrequencyI25 = ((parseFloat(purchaseFrequencyI18) - parseFloat(purchaseFrequencyD12)) * parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD20) * parseFloat(CustomerFinancialValuesD18)).toFixed(2)

        let purchaseFrequencyD26 = (parseFloat(purchaseFrequencyD17) * parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD18) * parseFloat(CustomerFinancialValuesD20) * parseFloat(CustomerFinancialValuesD19) / 100).toFixed(2)

        let purchaseFrequencyE26 = (parseFloat(purchaseFrequencyE17) * parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD18) * parseFloat(CustomerFinancialValuesD20) * parseFloat(CustomerFinancialValuesD19) / 100).toFixed(2)

        let purchaseFrequencyF26 = ((parseFloat(purchaseFrequencyF18) - parseFloat(purchaseFrequencyD12)) * parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD18) * parseFloat(CustomerFinancialValuesD20) * parseFloat(CustomerFinancialValuesD19) / 100).toFixed(2)

        let purchaseFrequencyH26 = ((parseFloat(purchaseFrequencyH18) - parseFloat(purchaseFrequencyD12)) * parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD18) * parseFloat(CustomerFinancialValuesD20) * parseFloat(CustomerFinancialValuesD19) / 100).toFixed(2)

        let purchaseFrequencyG26 = ((parseFloat(purchaseFrequencyG18) - parseFloat(purchaseFrequencyD12)) * parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD18) * parseFloat(CustomerFinancialValuesD20) * parseFloat(CustomerFinancialValuesD19) / 100).toFixed(2)

        let purchaseFrequencyI26 = ((parseFloat(purchaseFrequencyI18) - parseFloat(purchaseFrequencyD12)) * parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD18) * parseFloat(CustomerFinancialValuesD20) * parseFloat(CustomerFinancialValuesD19) / 100).toFixed(2)

        let purchaseFrequencyD28 = (parseFloat(purchaseFrequencyD23) * parseFloat(clientInputsH41)).toFixed(2)
        let purchaseFrequencyE28 = (parseFloat(purchaseFrequencyE23) * parseFloat(clientInputsH41)).toFixed(2)
        let purchaseFrequencyF28 = (parseFloat(purchaseFrequencyF23) * parseFloat(clientInputsH41)).toFixed(2)
        let purchaseFrequencyH28 = (parseFloat(purchaseFrequencyH23) * parseFloat(clientInputsH41)).toFixed(2)
        let purchaseFrequencyG28 = (parseFloat(purchaseFrequencyG23) * parseFloat(clientInputsH41)).toFixed(2)
        let purchaseFrequencyI28 = (parseFloat(purchaseFrequencyI23) * parseFloat(clientInputsH41)).toFixed(2)


        return {
            ...state, //copying the original state
            purchaseFrequencyD17 : (purchaseFrequencyD17 != 'NaN') ? purchaseFrequencyD17 : 0,
            purchaseFrequencyD18 : (purchaseFrequencyD18 != 'NaN') ? purchaseFrequencyD18 : 0,
            purchaseFrequencyE17 : (purchaseFrequencyE17 != 'NaN') ? purchaseFrequencyE17 : 0,
            purchaseFrequencyE18 : (purchaseFrequencyE18 != 'NaN') ? purchaseFrequencyE18 : 0,
            purchaseFrequencyF18 : (purchaseFrequencyF18 != 'NaN') ? purchaseFrequencyF18 : 0,
            purchaseFrequencyG18 : (purchaseFrequencyG18 != 'NaN') ? purchaseFrequencyG18 : 0,
            purchaseFrequencyF17 : (purchaseFrequencyF17 != 'NaN') ? purchaseFrequencyF17 : 0,
            purchaseFrequencyG17 : (purchaseFrequencyG17 != 'NaN') ? purchaseFrequencyG17 : 0,
            purchaseFrequencyH18 : (purchaseFrequencyH18 != 'NaN') ? purchaseFrequencyH18 : 0,
            purchaseFrequencyI18 : (purchaseFrequencyI18 != 'NaN') ? purchaseFrequencyI18 : 0,
            purchaseFrequencyH17 : (purchaseFrequencyH17 != 'NaN') ? purchaseFrequencyH17 : 0,
            purchaseFrequencyI17 : (purchaseFrequencyI17 != 'NaN') ? purchaseFrequencyI17 : 0,
            purchaseFrequencyD22 : (purchaseFrequencyD22 != 'NaN') ? purchaseFrequencyD22 : 0,
            purchaseFrequencyE22 : (purchaseFrequencyE22 != 'NaN') ? purchaseFrequencyE22 : 0,
            purchaseFrequencyF22 : (purchaseFrequencyF22 != 'NaN') ? purchaseFrequencyF22 : 0,
            purchaseFrequencyG22 : (purchaseFrequencyG22 != 'NaN') ? purchaseFrequencyG22 : 0,
            purchaseFrequencyH22 : (purchaseFrequencyH22 != 'NaN') ? purchaseFrequencyH22 : 0,
            purchaseFrequencyI22 : (purchaseFrequencyI22 != 'NaN') ? purchaseFrequencyI22 : 0,
            purchaseFrequencyD23 : (purchaseFrequencyD23 != 'NaN') ? purchaseFrequencyD23 : 0,
            purchaseFrequencyE23 : (purchaseFrequencyE23 != 'NaN') ? purchaseFrequencyE23 : 0,
            purchaseFrequencyF23 : (purchaseFrequencyF23 != 'NaN') ? purchaseFrequencyF23 : 0,
            purchaseFrequencyG23 : (purchaseFrequencyG23 != 'NaN') ? purchaseFrequencyG23 : 0,
            purchaseFrequencyH23 : (purchaseFrequencyH23 != 'NaN') ? purchaseFrequencyH23 : 0,
            purchaseFrequencyI23 : (purchaseFrequencyI23 != 'NaN') ? purchaseFrequencyI23 : 0,
            purchaseFrequencyD25 : (purchaseFrequencyD25 != 'NaN') ? purchaseFrequencyD25 : 0,
            purchaseFrequencyE25 : (purchaseFrequencyE25 != 'NaN') ? purchaseFrequencyE25 : 0,
            purchaseFrequencyF25 : (purchaseFrequencyF25 != 'NaN') ? purchaseFrequencyF25 : 0,
            purchaseFrequencyG25 : (purchaseFrequencyG25 != 'NaN') ? purchaseFrequencyG25 : 0,
            purchaseFrequencyH25 : (purchaseFrequencyH25 != 'NaN') ? purchaseFrequencyH25 : 0,
            purchaseFrequencyI25 : (purchaseFrequencyI25 != 'NaN') ? purchaseFrequencyI25 : 0,
            purchaseFrequencyD26 : (purchaseFrequencyD26 != 'NaN') ? purchaseFrequencyD26 : 0,
            purchaseFrequencyE26 : (purchaseFrequencyE26 != 'NaN') ? purchaseFrequencyE26 : 0,
            purchaseFrequencyF26 : (purchaseFrequencyF26 != 'NaN') ? purchaseFrequencyF26 : 0,
            purchaseFrequencyG26 : (purchaseFrequencyG26 != 'NaN') ? purchaseFrequencyG26 : 0,
            purchaseFrequencyH26 : (purchaseFrequencyH26 != 'NaN') ? purchaseFrequencyH26 : 0,
            purchaseFrequencyI26 : (purchaseFrequencyI26 != 'NaN') ? purchaseFrequencyI26 : 0,
            purchaseFrequencyD28 : (purchaseFrequencyD28 != 'NaN') ? purchaseFrequencyD28 : 0,
            purchaseFrequencyE28 : (purchaseFrequencyE28 != 'NaN') ? purchaseFrequencyE28 : 0,
            purchaseFrequencyF28 : (purchaseFrequencyF28 != 'NaN') ? purchaseFrequencyF28 : 0,
            purchaseFrequencyG28 : (purchaseFrequencyG28 != 'NaN') ? purchaseFrequencyG28 : 0,
            purchaseFrequencyH28 : (purchaseFrequencyH28 != 'NaN') ? purchaseFrequencyH28 : 0,
            purchaseFrequencyI28 : (purchaseFrequencyI28 != 'NaN') ? purchaseFrequencyI28 : 0,
        }
        
    }
    else {
        return state
    }
}

export default reducer