import React from "react";
import { Chart } from "react-google-charts";

export const LabelingBarsGraph = ({dataInput}) => {
    const data = dataInput.GraphData;
    const Title = dataInput.Title ? dataInput.Title : "";
    const backgroundColor = dataInput.backgroundColor ? dataInput.backgroundColor : '#1e2b3c';
    const legendTextStyle = dataInput.legendTextStyle ? dataInput.legendTextStyle : {color: "#fff"};
    const titleTextStyle = dataInput.titleTextStyle ? dataInput.titleTextStyle : {color: "#fff"};
    const textStyle = dataInput.textStyle ? dataInput.textStyle : {color: "#fff"};

    const options = {
      responsive: true,
      backgroundColor,
      maintainAspectRatio: false,
      chartArea:{
        right:5,
        top: 20,
        bottom: 30,
        width: '80%',
      },
      hAxis: {
        textStyle: {
          color: '#8497b0',
          fontSize: 15,
        }
      },
      vAxis: {
        textStyle: {
          color: '#8497b0',
          fontSize: 15,
        }
      },
      annotations: {
        textStyle : {
            color : "white",
            fontSize: 15,
            bold: true,
        }
      },
      title: Title,
      bar: { groupWidth: "80%" },
      legend: { position: "none" },
      legendTextStyle,
      titleTextStyle,
    };

  return (
    <Chart
      chartType="BarChart"
      width={'100%'}
      height={300}
      data={data}
      options={options}
    />
  )
}
