import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getBreakThroughStrategy, modifyBreakThroughStrategy } from "../../state/features/BreakThroughStrategySlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';

export default function BreakthroughStrategyContent() {

    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        BreakthroughStrategyC12 : '',
        BreakthroughStrategyI12 : '',
        BreakthroughStrategyD16 :  new Date().toLocaleDateString('en-us'),
        BreakthroughStrategyF16 : '',
        BreakthroughStrategyJ16 : '',
        BreakthroughStrategyL16 : '',
        BreakthroughStrategyC18 : '',
        BreakthroughStrategyI18 : '',
        BreakthroughStrategyC20 : '',
        BreakthroughStrategyI20 : '',
        BreakthroughStrategyC22 : '',
        BreakthroughStrategyI22 : '',
        BreakthroughStrategyC24 : '',
        BreakthroughStrategyI24 : '',
        BreakthroughStrategyC26 : '',
        BreakthroughStrategyI26 : '',
        BreakthroughStrategyC28 : '',
        BreakthroughStrategyI28 : '',
        BreakthroughStrategyC30 : '',
        BreakthroughStrategyI30 : '',
        BreakthroughStrategyC33 : '',
        BreakthroughStrategyE33 : '',
        BreakthroughStrategyI33 : '',
        BreakthroughStrategyK33 : '',
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getBreakThroughStrategy({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.growthPlanning)
            if(response!= undefined  && response.data.growthPlanning){
                let growthPlanning = response.data.growthPlanning[0];
                console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key]
                    }
                }
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyBreakThroughStrategy({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

    const [minDate, setMindate] = useState(new Date().toLocaleDateString('en-us'))
  return (
   <div className="content-body">
    <UserDropdown />
    
    {/* row */}
    <div className="container-fluid">
        <div className="head-title">
            <h2>Breakthrough Strategy</h2>         					
        </div> 
        <Spinner />
        <div className="card">
            <div className="card-body pl-0">                                            
            <div className="table-responsive">
                <table className="table">
                <thead>
                    <tr className="thead-primary">
                    <th colSpan={8}>Creating Breakthrough Strategy</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td className="bgradiusf" colSpan={4} style={{width: '50%'}}>Vision:  Goal</td>
                    <td className="bgradiusf" colSpan={4} style={{width: '50%'}}>Vision:  Result </td>
                    </tr>
                    <tr>
                    <td style={{height: 200}} colSpan={4}>
                        <textarea className="from-control inputText" 
                            rows={8} 
                            cols={54} 
                            id="BreakthroughStrategyC12" 
                            value={Inputs.BreakthroughStrategyC12} 
                            onChange={(event) => setInputs({ ...Inputs, BreakthroughStrategyC12: event.target.value }) } 
                            onBlur={(event) => { onBlurHandler(event) }} 
                            disabled={isLoading}/>
                    </td>
                    <td style={{height: 200}} colSpan={4}>
                        <textarea className="from-control inputText" 
                            rows={8} 
                            cols={54} 
                            id="BreakthroughStrategyI12" 
                            value={Inputs.BreakthroughStrategyI12} 
                            onChange={(event) => setInputs({ ...Inputs, BreakthroughStrategyI12: event.target.value }) } 
                            onBlur={(event) => { onBlurHandler(event) }} 
                            disabled={isLoading}/>
                    </td>
                    </tr>
                    <tr>
                    <td className="lightyello">Today's Date:</td>
                    <td className="lightyello">
                        <input type="date" className="form-control inputText"
                            id="BreakthroughStrategyD16"
                            value={Inputs.BreakthroughStrategyD16}
                            onChange={(event) => setInputs({ ...Inputs, BreakthroughStrategyD16: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading} 
                        />
                    </td>
                    <td className="lightyello">Target Date:</td>
                    <td className="lightyello">
                        <input type="date" className="form-control inputText"
                            id="BreakthroughStrategyF16"
                            value={Inputs.BreakthroughStrategyF16}
                            onChange={(event) => setInputs({ ...Inputs, BreakthroughStrategyF16: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            min={minDate}
                            disabled={isLoading} 
                        />
                    </td>
                    <td className="lightyello">Name:</td>
                    <td className="lightyello">
                        <input type="text" className="form-control inputText"
                            id="BreakthroughStrategyJ16"
                            value={Inputs.BreakthroughStrategyJ16}
                            onChange={(event) => setInputs({ ...Inputs, BreakthroughStrategyJ16: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading} 
                        />
                    </td>
                    <td className="lightyello">Date:</td>
                    <td className="lightyello">
                        <input type="date" className="form-control inputText"
                            id="BreakthroughStrategyL16"
                            value={Inputs.BreakthroughStrategyL16}
                            onChange={(event) => setInputs({ ...Inputs, BreakthroughStrategyL16: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading} 
                        />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={4} className="bluetab">Obstacles</td>
                    <td colSpan={4} className="bluetab">Transformation Strategies</td>
                    </tr>
                    <tr>
                    <td colSpan={4} style={{height: 80}}>
                        <input type="text" className="form-control inputText"
                            id="BreakthroughStrategyC18"
                            value={Inputs.BreakthroughStrategyC18}
                            onChange={(event) => setInputs({ ...Inputs, BreakthroughStrategyC18: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading} 
                        />
                    </td>
                    <td colSpan={4} style={{height: 80}}>
                        <input type="text" className="form-control inputText"
                            id="BreakthroughStrategyI18"
                            value={Inputs.BreakthroughStrategyI18}
                            onChange={(event) => setInputs({ ...Inputs, BreakthroughStrategyI18: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading} 
                        />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={4} style={{height: 80}}>
                        <input type="text" className="form-control inputText"
                            id="BreakthroughStrategyC20"
                            value={Inputs.BreakthroughStrategyC20}
                            onChange={(event) => setInputs({ ...Inputs, BreakthroughStrategyC20: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading} 
                        />
                    </td>
                    <td colSpan={4} style={{height: 80}}>
                        <input type="text" className="form-control inputText"
                            id="BreakthroughStrategyI20"
                            value={Inputs.BreakthroughStrategyI20}
                            onChange={(event) => setInputs({ ...Inputs, BreakthroughStrategyI20: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading} 
                        />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={4} style={{height: 80}}>
                        <input type="text" className="form-control inputText"
                            id="BreakthroughStrategyC22"
                            value={Inputs.BreakthroughStrategyC22}
                            onChange={(event) => setInputs({ ...Inputs, BreakthroughStrategyC22: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading} 
                        />
                    </td>
                    <td colSpan={4} style={{height: 80}}>
                        <input type="text" className="form-control inputText"
                            id="BreakthroughStrategyI22"
                            value={Inputs.BreakthroughStrategyI22}
                            onChange={(event) => setInputs({ ...Inputs, BreakthroughStrategyI22: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading} 
                        />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={4} style={{height: 80}}>
                        <input type="text" className="form-control inputText"
                            id="BreakthroughStrategyC24"
                            value={Inputs.BreakthroughStrategyC24}
                            onChange={(event) => setInputs({ ...Inputs, BreakthroughStrategyC24: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading} 
                        />
                    </td>
                    <td colSpan={4} style={{height: 80}}>
                        <input type="text" className="form-control inputText"
                            id="BreakthroughStrategyI24"
                            value={Inputs.BreakthroughStrategyI24}
                            onChange={(event) => setInputs({ ...Inputs, BreakthroughStrategyI24: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading} 
                        />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={4} style={{height: 80}}>
                        <input type="text" className="form-control inputText"
                            id="BreakthroughStrategyC26"
                            value={Inputs.BreakthroughStrategyC26}
                            onChange={(event) => setInputs({ ...Inputs, BreakthroughStrategyC26: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading} 
                        />
                    </td>
                    <td colSpan={4} style={{height: 80}}>
                        <input type="text" className="form-control inputText"
                            id="BreakthroughStrategyI26"
                            value={Inputs.BreakthroughStrategyI26}
                            onChange={(event) => setInputs({ ...Inputs, BreakthroughStrategyI26: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading} 
                        />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={4} style={{height: 80}}>
                        <input type="text" className="form-control inputText"
                            id="BreakthroughStrategyC28"
                            value={Inputs.BreakthroughStrategyC28}
                            onChange={(event) => setInputs({ ...Inputs, BreakthroughStrategyC28: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading} 
                        />
                    </td>
                    <td colSpan={4} style={{height: 80}}>
                        <input type="text" className="form-control inputText"
                            id="BreakthroughStrategyI28"
                            value={Inputs.BreakthroughStrategyI28}
                            onChange={(event) => setInputs({ ...Inputs, BreakthroughStrategyI28: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading} 
                        />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={4} style={{height: 80}}>
                        <input type="text" className="form-control inputText"
                            id="BreakthroughStrategyC30"
                            value={Inputs.BreakthroughStrategyC30}
                            onChange={(event) => setInputs({ ...Inputs, BreakthroughStrategyC30: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading} 
                        />
                    </td>
                    <td colSpan={4} style={{height: 80}}>
                        <input type="text" className="form-control inputText"
                            id="BreakthroughStrategyI30"
                            value={Inputs.BreakthroughStrategyI30}
                            onChange={(event) => setInputs({ ...Inputs, BreakthroughStrategyI30: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading} 
                        />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={8} className="bluetab text-center">Actions</td>
                    </tr>
                    <tr>
                    <td colSpan={2} style={{height: 80}}>
                        <textarea className="from-control inputText" 
                            rows={8} 
                            cols={54} 
                            id="BreakthroughStrategyC33" 
                            value={Inputs.BreakthroughStrategyC33} 
                            onChange={(event) => setInputs({ ...Inputs, BreakthroughStrategyC33: event.target.value }) } 
                            onBlur={(event) => { onBlurHandler(event) }} 
                            disabled={isLoading}/>
                    </td>
                    <td colSpan={2} style={{height: 80}}>
                        <textarea className="from-control inputText" 
                            rows={8} 
                            cols={54} 
                            id="BreakthroughStrategyE33" 
                            value={Inputs.BreakthroughStrategyE33} 
                            onChange={(event) => setInputs({ ...Inputs, BreakthroughStrategyE33: event.target.value }) } 
                            onBlur={(event) => { onBlurHandler(event) }} 
                            disabled={isLoading}/>
                    </td>
                    <td colSpan={2} style={{height: 80}}>
                        <textarea className="from-control inputText" 
                            rows={8} 
                            cols={54} 
                            id="BreakthroughStrategyI33" 
                            value={Inputs.BreakthroughStrategyI33} 
                            onChange={(event) => setInputs({ ...Inputs, BreakthroughStrategyI33: event.target.value }) } 
                            onBlur={(event) => { onBlurHandler(event) }} 
                            disabled={isLoading}/>
                    </td>
                    <td colSpan={2} style={{height: 80}}>
                        <textarea className="from-control inputText" 
                            rows={8} 
                            cols={54} 
                            id="BreakthroughStrategyK33" 
                            value={Inputs.BreakthroughStrategyK33} 
                            onChange={(event) => setInputs({ ...Inputs, BreakthroughStrategyK33: event.target.value }) } 
                            onBlur={(event) => { onBlurHandler(event) }} 
                            disabled={isLoading}/>
                    </td>
                    </tr>
                </tbody>
                </table>
            </div>
            </div>
        </div>
        </div>

    </div>

  )
}
