import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import sum from "../../utils/sum";
import { Link } from 'react-router-dom';
import { getWeightedDecisionMatrix, modifyWeightedDecisionMatrix } from '../../state/features/WeightedDecisionMatrixSlice';
import formatPrice from '../../utils/formatPrice';
import checkRange from '../../utils/checkRange';

export default function WeightedDecisionMatrixContentNew() {

    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const colorPallete = ['#F8696B', '#FCAA78', '#FFEB84', '#B1D580', '#63BE7B'];
    const bgColorPoint = (num) => {
        if(isNaN(num)) {
            return colorPallete[2];
        }
        if(num === 5){
            return colorPallete[2];
        }else if(num > 5 && num < 6){
            return colorPallete[4];
        }else if(num > 4 && num < 5){
            return colorPallete[2];
        }else if(num >= 6){
            return colorPallete[4];
        }else if(num <= 4){
            return colorPallete[0];
        }
    }

    const [Inputs, setInputs] = useState({
        WeightedDecisionMatrixD7: "Factor 1",
        WeightedDecisionMatrixE7: "Factor 2",
        WeightedDecisionMatrixF7: "Factor 3",
        WeightedDecisionMatrixG7: "Factor 4",
        WeightedDecisionMatrixH7: "Factor 5",
        WeightedDecisionMatrixD8: 0,
        WeightedDecisionMatrixE8: 0,
        WeightedDecisionMatrixF8: 0,
        WeightedDecisionMatrixG8: 0,
        WeightedDecisionMatrixH8: 0,
        WeightedDecisionMatrixI8: 0,
        WeightedDecisionMatrixC9: "Enter Option Here",
        WeightedDecisionMatrixD9: 0,
        WeightedDecisionMatrixE9: 0,
        WeightedDecisionMatrixF9: 0,
        WeightedDecisionMatrixG9: 0,
        WeightedDecisionMatrixH9: 0,
        WeightedDecisionMatrixI9: 0,
        WeightedDecisionMatrixC10: "Option B",
        WeightedDecisionMatrixD10: 0,
        WeightedDecisionMatrixE10: 0,
        WeightedDecisionMatrixF10: 0,
        WeightedDecisionMatrixG10: 0,
        WeightedDecisionMatrixH10: 0,
        WeightedDecisionMatrixI10: 0,
        WeightedDecisionMatrixC11: "Option C",
        WeightedDecisionMatrixD11: 0,
        WeightedDecisionMatrixE11: 0,
        WeightedDecisionMatrixF11: 0,
        WeightedDecisionMatrixG11: 0,
        WeightedDecisionMatrixH11: 0,
        WeightedDecisionMatrixI11: 0,
        WeightedDecisionMatrixC12: "Option D",
        WeightedDecisionMatrixD12: 0,
        WeightedDecisionMatrixE12: 0,
        WeightedDecisionMatrixF12: 0,
        WeightedDecisionMatrixG12: 0,
        WeightedDecisionMatrixH12: 0,
        WeightedDecisionMatrixI12: 0,
        WeightedDecisionMatrixC14: "Option E",
        WeightedDecisionMatrixD14: 0,
        WeightedDecisionMatrixE14: 0,
        WeightedDecisionMatrixF14: 0,
        WeightedDecisionMatrixG14: 0,
        WeightedDecisionMatrixH14: 0,
        WeightedDecisionMatrixI14: 0,
        WeightedDecisionMatrixC15: 0,
        WeightedDecisionMatrixD15: 0,
        WeightedDecisionMatrixE15: 0,
        WeightedDecisionMatrixF15: 0,
        WeightedDecisionMatrixG15: 0,
        WeightedDecisionMatrixH15: 0,
        WeightedDecisionMatrixI15: 0,
        WeightedDecisionMatrixC16: 0,
        WeightedDecisionMatrixD16: 0,
        WeightedDecisionMatrixE16: 0,
        WeightedDecisionMatrixF16: 0,
        WeightedDecisionMatrixG16: 0,
        WeightedDecisionMatrixH16: 0,
        WeightedDecisionMatrixI16: 0,
        WeightedDecisionMatrixC17: 0,
        WeightedDecisionMatrixD17: 0,
        WeightedDecisionMatrixE17: 0,
        WeightedDecisionMatrixF17: 0,
        WeightedDecisionMatrixG17: 0,
        WeightedDecisionMatrixH17: 0,
        WeightedDecisionMatrixI17: 0,
    });

    const [Cache, setCache] = useState(Inputs);
    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getWeightedDecisionMatrix({user_id}))
        .unwrap()
        .then((response) => {
            //console.log("response",response.data.growthPlanning)
            if(response!= undefined  && response.data.growthPlanning){
                let growthPlanning = response.data.growthPlanning[0];
                // console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key]
                    }
                }

                newObj['WeightedDecisionMatrixI8'] = sum(newObj['WeightedDecisionMatrixD8'], newObj['WeightedDecisionMatrixE8'], newObj['WeightedDecisionMatrixF8'], newObj['WeightedDecisionMatrixG8'], newObj['WeightedDecisionMatrixH8']);

                newObj['WeightedDecisionMatrixI9'] = sum(newObj['WeightedDecisionMatrixD9'], newObj['WeightedDecisionMatrixE9'], newObj['WeightedDecisionMatrixF9'], newObj['WeightedDecisionMatrixG9'], newObj['WeightedDecisionMatrixH9']);
                newObj['WeightedDecisionMatrixI10'] = sum(newObj['WeightedDecisionMatrixD10'], newObj['WeightedDecisionMatrixE10'], newObj['WeightedDecisionMatrixF10'], newObj['WeightedDecisionMatrixG10'], newObj['WeightedDecisionMatrixH10']);
                newObj['WeightedDecisionMatrixI11'] = sum(newObj['WeightedDecisionMatrixD11'], newObj['WeightedDecisionMatrixE11'], newObj['WeightedDecisionMatrixF11'], newObj['WeightedDecisionMatrixG11'], newObj['WeightedDecisionMatrixH11']);
                newObj['WeightedDecisionMatrixI12'] = sum(newObj['WeightedDecisionMatrixD12'], newObj['WeightedDecisionMatrixE12'], newObj['WeightedDecisionMatrixF12'], newObj['WeightedDecisionMatrixG12'], newObj['WeightedDecisionMatrixH12']);

                newObj['WeightedDecisionMatrixD14'] = ((parseFloat(newObj['WeightedDecisionMatrixD9']) * parseFloat(newObj['WeightedDecisionMatrixD8'])) / 100).toFixed(1);
                newObj['WeightedDecisionMatrixD15'] = ((parseFloat(newObj['WeightedDecisionMatrixD10']) * parseFloat(newObj['WeightedDecisionMatrixD8'])) / 100).toFixed(1);
                newObj['WeightedDecisionMatrixD16'] = ((parseFloat(newObj['WeightedDecisionMatrixD11']) * parseFloat(newObj['WeightedDecisionMatrixD8'])) / 100).toFixed(1);
                newObj['WeightedDecisionMatrixD17'] = ((parseFloat(newObj['WeightedDecisionMatrixD12']) * parseFloat(newObj['WeightedDecisionMatrixD8'])) / 100).toFixed(1);
                
                newObj['WeightedDecisionMatrixE14'] = ((parseFloat(newObj['WeightedDecisionMatrixE9']) * parseFloat(newObj['WeightedDecisionMatrixE8'])) / 100).toFixed(1);
                newObj['WeightedDecisionMatrixE15'] = ((parseFloat(newObj['WeightedDecisionMatrixE10']) * parseFloat(newObj['WeightedDecisionMatrixE8'])) / 100).toFixed(1);
                newObj['WeightedDecisionMatrixE16'] = ((parseFloat(newObj['WeightedDecisionMatrixE11']) * parseFloat(newObj['WeightedDecisionMatrixE8'])) / 100).toFixed(1);
                newObj['WeightedDecisionMatrixE17'] = ((parseFloat(newObj['WeightedDecisionMatrixE12']) * parseFloat(newObj['WeightedDecisionMatrixE8'])) / 100).toFixed(1);

                newObj['WeightedDecisionMatrixF14'] = ((parseFloat(newObj['WeightedDecisionMatrixF9']) * parseFloat(newObj['WeightedDecisionMatrixF8'])) / 100).toFixed(1);
                newObj['WeightedDecisionMatrixF15'] = ((parseFloat(newObj['WeightedDecisionMatrixF10']) * parseFloat(newObj['WeightedDecisionMatrixF8'])) / 100).toFixed(1);
                newObj['WeightedDecisionMatrixF16'] = ((parseFloat(newObj['WeightedDecisionMatrixF11']) * parseFloat(newObj['WeightedDecisionMatrixF8'])) / 100).toFixed(1);
                newObj['WeightedDecisionMatrixF17'] = ((parseFloat(newObj['WeightedDecisionMatrixF12']) * parseFloat(newObj['WeightedDecisionMatrixF8'])) / 100).toFixed(1);

                newObj['WeightedDecisionMatrixG14'] = ((parseFloat(newObj['WeightedDecisionMatrixG9']) * parseFloat(newObj['WeightedDecisionMatrixG8'])) / 100).toFixed(1);
                newObj['WeightedDecisionMatrixG15'] = ((parseFloat(newObj['WeightedDecisionMatrixG10']) * parseFloat(newObj['WeightedDecisionMatrixG8'])) / 100).toFixed(1);
                newObj['WeightedDecisionMatrixG16'] = ((parseFloat(newObj['WeightedDecisionMatrixG11']) * parseFloat(newObj['WeightedDecisionMatrixG8'])) / 100).toFixed(1);
                newObj['WeightedDecisionMatrixG17'] = ((parseFloat(newObj['WeightedDecisionMatrixG12']) * parseFloat(newObj['WeightedDecisionMatrixG8'])) / 100).toFixed(1);

                newObj['WeightedDecisionMatrixH14'] = ((parseFloat(newObj['WeightedDecisionMatrixH9']) * parseFloat(newObj['WeightedDecisionMatrixH8'])) / 100).toFixed(1);
                newObj['WeightedDecisionMatrixH15'] = ((parseFloat(newObj['WeightedDecisionMatrixH10']) * parseFloat(newObj['WeightedDecisionMatrixH8'])) / 100).toFixed(1);
                newObj['WeightedDecisionMatrixH16'] = ((parseFloat(newObj['WeightedDecisionMatrixH11']) * parseFloat(newObj['WeightedDecisionMatrixH8'])) / 100).toFixed(1);
                newObj['WeightedDecisionMatrixH17'] = ((parseFloat(newObj['WeightedDecisionMatrixH12']) * parseFloat(newObj['WeightedDecisionMatrixH8'])) / 100).toFixed(1);

                newObj['WeightedDecisionMatrixI14'] = (sum(newObj['WeightedDecisionMatrixD14'], newObj['WeightedDecisionMatrixE14'], newObj['WeightedDecisionMatrixF14'], newObj['WeightedDecisionMatrixG14'], newObj['WeightedDecisionMatrixH14'])).toFixed(1);
                newObj['WeightedDecisionMatrixI15'] = (sum(newObj['WeightedDecisionMatrixD15'], newObj['WeightedDecisionMatrixE15'], newObj['WeightedDecisionMatrixF15'], newObj['WeightedDecisionMatrixG15'], newObj['WeightedDecisionMatrixH15'])).toFixed(1);
                newObj['WeightedDecisionMatrixI16'] = (sum(newObj['WeightedDecisionMatrixD16'], newObj['WeightedDecisionMatrixE16'], newObj['WeightedDecisionMatrixF16'], newObj['WeightedDecisionMatrixG16'], newObj['WeightedDecisionMatrixH16'])).toFixed(1);
                newObj['WeightedDecisionMatrixI17'] = (sum(newObj['WeightedDecisionMatrixD17'], newObj['WeightedDecisionMatrixE17'], newObj['WeightedDecisionMatrixF17'], newObj['WeightedDecisionMatrixG17'], newObj['WeightedDecisionMatrixH17'])).toFixed(1);

                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyWeightedDecisionMatrix({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                    // console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        //window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                    console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

    useEffect(() => {
        let WeightedDecisionMatrixE8 = 0;
        let WeightedDecisionMatrixF8 = 0;
        let WeightedDecisionMatrixG8 = 0;
        let WeightedDecisionMatrixH8 = 0;

        let WeightedDecisionMatrixI8 = sum(Number(Inputs.WeightedDecisionMatrixD8), Number(Inputs.WeightedDecisionMatrixE8), Number(Inputs.WeightedDecisionMatrixF8), Number(Inputs.WeightedDecisionMatrixG8), Number(Inputs.WeightedDecisionMatrixH8));
        if(WeightedDecisionMatrixI8 <= 100){
            setInputs({...Inputs, WeightedDecisionMatrixI8});
        }else{
            WeightedDecisionMatrixI8 = Number(Inputs.WeightedDecisionMatrixD8);
            setInputs({...Inputs, WeightedDecisionMatrixI8, WeightedDecisionMatrixE8, WeightedDecisionMatrixF8, WeightedDecisionMatrixG8, WeightedDecisionMatrixH8});
            alert.show('Weight must sum up to 100.')
        }
    }, [Inputs.WeightedDecisionMatrixD8, Inputs.WeightedDecisionMatrixE8, Inputs.WeightedDecisionMatrixF8, Inputs.WeightedDecisionMatrixG8, Inputs.WeightedDecisionMatrixH8]);

    useEffect(() => {

        let WeightedDecisionMatrixI9 = sum(Number(Inputs.WeightedDecisionMatrixD9), Number(Inputs.WeightedDecisionMatrixE9), Number(Inputs.WeightedDecisionMatrixF9), Number(Inputs.WeightedDecisionMatrixG9), Number(Inputs.WeightedDecisionMatrixH9));
        let WeightedDecisionMatrixI10 = sum(Number(Inputs.WeightedDecisionMatrixD10), Number(Inputs.WeightedDecisionMatrixE10), Number(Inputs.WeightedDecisionMatrixF10), Number(Inputs.WeightedDecisionMatrixG10), Number(Inputs.WeightedDecisionMatrixH10));
        let WeightedDecisionMatrixI11 = sum(Number(Inputs.WeightedDecisionMatrixD11), Number(Inputs.WeightedDecisionMatrixE11), Number(Inputs.WeightedDecisionMatrixF11), Number(Inputs.WeightedDecisionMatrixG11), Number(Inputs.WeightedDecisionMatrixH11));
        let WeightedDecisionMatrixI12 = sum(Number(Inputs.WeightedDecisionMatrixD12), Number(Inputs.WeightedDecisionMatrixE12), Number(Inputs.WeightedDecisionMatrixF12), Number(Inputs.WeightedDecisionMatrixG12), Number(Inputs.WeightedDecisionMatrixH12));

        let WeightedDecisionMatrixD14 = ((parseFloat(Inputs.WeightedDecisionMatrixD9) * parseFloat(Inputs.WeightedDecisionMatrixD8)) / 100).toFixed(1);
        let WeightedDecisionMatrixD15 = ((parseFloat(Inputs.WeightedDecisionMatrixD10) * parseFloat(Inputs.WeightedDecisionMatrixD8)) / 100).toFixed(1);
        let WeightedDecisionMatrixD16 = ((parseFloat(Inputs.WeightedDecisionMatrixD11) * parseFloat(Inputs.WeightedDecisionMatrixD8)) / 100).toFixed(1);
        let WeightedDecisionMatrixD17 = ((parseFloat(Inputs.WeightedDecisionMatrixD12) * parseFloat(Inputs.WeightedDecisionMatrixD8)) / 100).toFixed(1);
        
        let WeightedDecisionMatrixE14 = ((parseFloat(Inputs.WeightedDecisionMatrixE9) * parseFloat(Inputs.WeightedDecisionMatrixE8)) / 100).toFixed(1);
        let WeightedDecisionMatrixE15 = ((parseFloat(Inputs.WeightedDecisionMatrixE10) * parseFloat(Inputs.WeightedDecisionMatrixE8)) / 100).toFixed(1);
        let WeightedDecisionMatrixE16 = ((parseFloat(Inputs.WeightedDecisionMatrixE11) * parseFloat(Inputs.WeightedDecisionMatrixE8)) / 100).toFixed(1);
        let WeightedDecisionMatrixE17 = ((parseFloat(Inputs.WeightedDecisionMatrixE12) * parseFloat(Inputs.WeightedDecisionMatrixE8)) / 100).toFixed(1);

        let WeightedDecisionMatrixF14 = ((parseFloat(Inputs.WeightedDecisionMatrixF9) * parseFloat(Inputs.WeightedDecisionMatrixF8)) / 100).toFixed(1);
        let WeightedDecisionMatrixF15 = ((parseFloat(Inputs.WeightedDecisionMatrixF10) * parseFloat(Inputs.WeightedDecisionMatrixF8)) / 100).toFixed(1);
        let WeightedDecisionMatrixF16 = ((parseFloat(Inputs.WeightedDecisionMatrixF11) * parseFloat(Inputs.WeightedDecisionMatrixF8)) / 100).toFixed(1);
        let WeightedDecisionMatrixF17 = ((parseFloat(Inputs.WeightedDecisionMatrixF12) * parseFloat(Inputs.WeightedDecisionMatrixF8)) / 100).toFixed(1);

        let WeightedDecisionMatrixG14 = ((parseFloat(Inputs.WeightedDecisionMatrixG9) * parseFloat(Inputs.WeightedDecisionMatrixG8)) / 100).toFixed(1);
        let WeightedDecisionMatrixG15 = ((parseFloat(Inputs.WeightedDecisionMatrixG10) * parseFloat(Inputs.WeightedDecisionMatrixG8)) / 100).toFixed(1);
        let WeightedDecisionMatrixG16 = ((parseFloat(Inputs.WeightedDecisionMatrixG11) * parseFloat(Inputs.WeightedDecisionMatrixG8)) / 100).toFixed(1);
        let WeightedDecisionMatrixG17 = ((parseFloat(Inputs.WeightedDecisionMatrixG12) * parseFloat(Inputs.WeightedDecisionMatrixG8)) / 100).toFixed(1);

        let WeightedDecisionMatrixH14 = ((parseFloat(Inputs.WeightedDecisionMatrixH9) * parseFloat(Inputs.WeightedDecisionMatrixH8)) / 100).toFixed(1);
        let WeightedDecisionMatrixH15 = ((parseFloat(Inputs.WeightedDecisionMatrixH10) * parseFloat(Inputs.WeightedDecisionMatrixH8)) / 100).toFixed(1);
        let WeightedDecisionMatrixH16 = ((parseFloat(Inputs.WeightedDecisionMatrixH11) * parseFloat(Inputs.WeightedDecisionMatrixH8)) / 100).toFixed(1);
        let WeightedDecisionMatrixH17 = ((parseFloat(Inputs.WeightedDecisionMatrixH12) * parseFloat(Inputs.WeightedDecisionMatrixH8)) / 100).toFixed(1);

        let WeightedDecisionMatrixI14 = (sum(WeightedDecisionMatrixD14, WeightedDecisionMatrixE14, WeightedDecisionMatrixF14, WeightedDecisionMatrixG14, WeightedDecisionMatrixH14)).toFixed(1);
        let WeightedDecisionMatrixI15 = (sum(WeightedDecisionMatrixD15, WeightedDecisionMatrixE15, WeightedDecisionMatrixF15, WeightedDecisionMatrixG15, WeightedDecisionMatrixH15)).toFixed(1);
        let WeightedDecisionMatrixI16 = (sum(WeightedDecisionMatrixD16, WeightedDecisionMatrixE16, WeightedDecisionMatrixF16, WeightedDecisionMatrixG16, WeightedDecisionMatrixH16)).toFixed(1);
        let WeightedDecisionMatrixI17 = (sum(WeightedDecisionMatrixD17, WeightedDecisionMatrixE17, WeightedDecisionMatrixF17, WeightedDecisionMatrixG17, WeightedDecisionMatrixH17)).toFixed(1);

        setInputs({...Inputs, WeightedDecisionMatrixI9, WeightedDecisionMatrixI10, WeightedDecisionMatrixI11, WeightedDecisionMatrixI12, WeightedDecisionMatrixI14, WeightedDecisionMatrixI15, WeightedDecisionMatrixI16, WeightedDecisionMatrixI17, WeightedDecisionMatrixD14, WeightedDecisionMatrixD15, WeightedDecisionMatrixD16, WeightedDecisionMatrixD17, WeightedDecisionMatrixE14, WeightedDecisionMatrixE15, WeightedDecisionMatrixE16, WeightedDecisionMatrixE17, WeightedDecisionMatrixF14, WeightedDecisionMatrixF15, WeightedDecisionMatrixF16, WeightedDecisionMatrixF17, WeightedDecisionMatrixG14, WeightedDecisionMatrixG15, WeightedDecisionMatrixG16, WeightedDecisionMatrixG17, WeightedDecisionMatrixH14, WeightedDecisionMatrixH15, WeightedDecisionMatrixH16, WeightedDecisionMatrixH17})

    }, [Inputs.WeightedDecisionMatrixD9, Inputs.WeightedDecisionMatrixE9, Inputs.WeightedDecisionMatrixF9, Inputs.WeightedDecisionMatrixG9, Inputs.WeightedDecisionMatrixH9, Inputs.WeightedDecisionMatrixD10, Inputs.WeightedDecisionMatrixE10, Inputs.WeightedDecisionMatrixF10, Inputs.WeightedDecisionMatrixG10, Inputs.WeightedDecisionMatrixH10, Inputs.WeightedDecisionMatrixD11, Inputs.WeightedDecisionMatrixE11, Inputs.WeightedDecisionMatrixF11, Inputs.WeightedDecisionMatrixG11, Inputs.WeightedDecisionMatrixH11, Inputs.WeightedDecisionMatrixD12, Inputs.WeightedDecisionMatrixE12, Inputs.WeightedDecisionMatrixF12, Inputs.WeightedDecisionMatrixG12, Inputs.WeightedDecisionMatrixH12])

    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
            <div className="container-fluid">
                <div className="head-title">
                    <h3 className='preheading'>Prescribe - Opportunity Analysis</h3>
                    <h1 className='mainheading mb-1'>Weighted Decision Matrix</h1>
                </div>
                <Spinner/>
                <div className="opportunity-page-table">
                    <div className="opportunity-page-table-inner opportunity-abl">
                        <div className="table-responsive mb-3">
                            <table className="table" style={{width: 1200}}>
                                <thead>
                                    <tr>
                                        <td className='hide-content'></td>
                                        <td className='bg-color-blue-gray text-white text-center font12px fontExtraBold' colSpan={5}>Critical Growth Factors</td>
                                        <td className='hide-content'></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='black-bg text-white text-center font12px' style={{width: 300}}>Rank factors on a scale between 1-10<br/>(1=low; 10=high)</td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-fiel-cn bg-color-01">
                                                <input type="text" className="field-ipt bg-color-01 font12px fontExtraBold color-bold text-center" 
                                                id="WeightedDecisionMatrixD7"
                                                value={Inputs.WeightedDecisionMatrixD7}
                                                onChange={(event) => setInputs({ ...Inputs, WeightedDecisionMatrixD7: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="Factor 1" />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-fiel-cn bg-color-01">
                                                <input type="text" className="field-ipt bg-color-01 font12px fontExtraBold color-bold text-center"  
                                                id="WeightedDecisionMatrixE7"
                                                value={Inputs.WeightedDecisionMatrixE7}
                                                onChange={(event) => setInputs({ ...Inputs, WeightedDecisionMatrixE7: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="Factor 2" />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-fiel-cn bg-color-01">
                                                <input type="text" className="field-ipt bg-color-01 font12px fontExtraBold color-bold text-center"  
                                                id="WeightedDecisionMatrixF7"
                                                value={Inputs.WeightedDecisionMatrixF7}
                                                onChange={(event) => setInputs({ ...Inputs, WeightedDecisionMatrixF7: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="Factor 3" />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-fiel-cn bg-color-01">
                                                <input type="text" className="field-ipt bg-color-01 font12px fontExtraBold color-bold text-center"  
                                                id="WeightedDecisionMatrixG7"
                                                value={Inputs.WeightedDecisionMatrixG7}
                                                onChange={(event) => setInputs({ ...Inputs, WeightedDecisionMatrixG7: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="Factor 4" />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-fiel-cn bg-color-01">
                                                <input type="text" className="field-ipt bg-color-01 font12px fontExtraBold color-bold text-center"  
                                                id="WeightedDecisionMatrixH7"
                                                value={Inputs.WeightedDecisionMatrixH7}
                                                onChange={(event) => setInputs({ ...Inputs, WeightedDecisionMatrixH7: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="Factor 5" />
                                            </div>
                                        </td>
                                        <td className='black-bg text-white text-center font12px fontExtraBold' style={{width: 150}}>Total Score</td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-left font12px'><strong>Weight</strong> (should total 100%)</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-field-auto presentage-center">
                                                <input type="text" className="text-right-rt color-dark font12px fontExtraBold" 
                                                id="WeightedDecisionMatrixD8" 
                                                value={Inputs.WeightedDecisionMatrixD8} 
                                                onChange={(event) => setInputs({ ...Inputs, WeightedDecisionMatrixD8: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="2"
                                                max="100" 
                                                onKeyPress={(event) => {
                                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                                else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                    event.preventDefault();
                                                    alert.show('Please enter a valid number.')
                                                }
                                                }} />
                                                <div className="cneter-peb font12px fontExtraBold" >%</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-field-auto presentage-center">
                                                <input type="text" className="text-right-rt color-dark font12px fontExtraBold" 
                                                id="WeightedDecisionMatrixE8" 
                                                value={Inputs.WeightedDecisionMatrixE8} 
                                                onChange={(event) => setInputs({ ...Inputs, WeightedDecisionMatrixE8: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="2"
                                                max="100" 
                                                onKeyPress={(event) => {
                                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                                else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                    event.preventDefault();
                                                    alert.show('Please enter a valid number.')
                                                }
                                                }} />
                                                <div className="cneter-peb font12px fontExtraBold" >%</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-field-auto presentage-center">
                                                <input type="text" className="text-right-rt color-dark font12px fontExtraBold" 
                                                id="WeightedDecisionMatrixF8" 
                                                value={Inputs.WeightedDecisionMatrixF8} 
                                                onChange={(event) => setInputs({ ...Inputs, WeightedDecisionMatrixF8: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="2"
                                                max="100" 
                                                onKeyPress={(event) => {
                                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                                else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                    event.preventDefault();
                                                    alert.show('Please enter a valid number.')
                                                }
                                                }} />
                                                <div className="cneter-peb font12px fontExtraBold" >%</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-field-auto presentage-center">
                                                <input type="text" className="text-right-rt color-dark font12px fontExtraBold" 
                                                id="WeightedDecisionMatrixG8" 
                                                value={Inputs.WeightedDecisionMatrixG8} 
                                                onChange={(event) => setInputs({ ...Inputs, WeightedDecisionMatrixG8: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="2"
                                                max="100" 
                                                onKeyPress={(event) => {
                                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                                else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                    event.preventDefault();
                                                    alert.show('Please enter a valid number.')
                                                }
                                                }} />
                                                <div className="cneter-peb font12px fontExtraBold" >%</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-field-auto presentage-center">
                                                <input type="text" className="text-right-rt color-dark font12px fontExtraBold" 
                                                id="WeightedDecisionMatrixH8" 
                                                value={Inputs.WeightedDecisionMatrixH8} 
                                                onChange={(event) => setInputs({ ...Inputs, WeightedDecisionMatrixH8: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="2"
                                                max="100" 
                                                onKeyPress={(event) => {
                                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                                else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                    event.preventDefault();
                                                    alert.show('Please enter a valid number.')
                                                }
                                                }} />
                                                <div className="cneter-peb font12px fontExtraBold" >%</div>
                                            </div>
                                        </td>
                                        <td className='text-white text-center font11px fontExtraBold' style={{backgroundColor: '#8496b0'}}>
                                            {Number(Inputs.WeightedDecisionMatrixI8)}%
                                        </td>
                                    </tr>
                                    
                                    <tr>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01">
                                                <textarea className='field-ipt bg-color-01 font11px color-dark w-100'
                                                id="WeightedDecisionMatrixC9"
                                                value={Inputs.WeightedDecisionMatrixC9}
                                                onChange={(event) => setInputs({ ...Inputs, WeightedDecisionMatrixC9: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="Enter option here" />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <input type="text" className="field-ipt font11px bg-color-light-blue text-center color-dark" 
                                                id="WeightedDecisionMatrixD9" 
                                                value={Inputs.WeightedDecisionMatrixD9} 
                                                onChange={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        setInputs({ ...Inputs, WeightedDecisionMatrixD9: event.target.value })
                                                    }
                                                }}
                                                onBlur={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        onBlurHandler(event)
                                                    }
                                                }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength={2}
                                                onKeyPress={event => {
                                                    if(!/[0-9]/.test(event.key)){
                                                        event.preventDefault();
                                                        alert.show('Please enter a valid number.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <input type="text" className="field-ipt font11px bg-color-light-blue text-center color-dark" 
                                                id="WeightedDecisionMatrixE9" 
                                                value={Inputs.WeightedDecisionMatrixE9} 
                                                onChange={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        setInputs({ ...Inputs, WeightedDecisionMatrixE9: event.target.value })
                                                    }
                                                }}
                                                onBlur={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        onBlurHandler(event)
                                                    }
                                                }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength={2}
                                                onKeyPress={event => {
                                                    if(!/[0-9]/.test(event.key)){
                                                        event.preventDefault();
                                                        alert.show('Please enter a valid number.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <input type="text" className="field-ipt font11px bg-color-light-blue text-center color-dark" 
                                                id="WeightedDecisionMatrixF9" 
                                                value={Inputs.WeightedDecisionMatrixF9} 
                                                onChange={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        setInputs({ ...Inputs, WeightedDecisionMatrixF9: event.target.value })
                                                    }
                                                }}
                                                onBlur={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        onBlurHandler(event)
                                                    }
                                                }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength={2}
                                                onKeyPress={event => {
                                                    if(!/[0-9]/.test(event.key)){
                                                        event.preventDefault();
                                                        alert.show('Please enter a valid number.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <input type="text" className="field-ipt font11px bg-color-light-blue text-center color-dark" 
                                                id="WeightedDecisionMatrixG9" 
                                                value={Inputs.WeightedDecisionMatrixG9} 
                                                onChange={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        setInputs({ ...Inputs, WeightedDecisionMatrixG9: event.target.value })
                                                    }
                                                }}
                                                onBlur={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        onBlurHandler(event)
                                                    }
                                                }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength={2}
                                                onKeyPress={event => {
                                                    if(!/[0-9]/.test(event.key)){
                                                        event.preventDefault();
                                                        alert.show('Please enter a valid number.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <input type="text" className="field-ipt font11px bg-color-light-blue text-center color-dark" 
                                                id="WeightedDecisionMatrixH9" 
                                                value={Inputs.WeightedDecisionMatrixH9} 
                                                onChange={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        setInputs({ ...Inputs, WeightedDecisionMatrixH9: event.target.value })
                                                    }
                                                }}
                                                onBlur={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        onBlurHandler(event)
                                                    }
                                                }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength={2}
                                                onKeyPress={event => {
                                                    if(!/[0-9]/.test(event.key)){
                                                        event.preventDefault();
                                                        alert.show('Please enter a valid number.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center font11px fontExtraBold'>
                                            <div className="scrore-col">
                                            {Inputs.WeightedDecisionMatrixI9}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01">
                                                <textarea className='field-ipt font11px bg-color-01 color-dark w-100'
                                                id="WeightedDecisionMatrixC10"
                                                value={Inputs.WeightedDecisionMatrixC10}
                                                onChange={(event) => setInputs({ ...Inputs, WeightedDecisionMatrixC10: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="Option B" />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <input type="text" className="field-ipt font11px bg-color-light-blue text-center color-dark" 
                                                id="WeightedDecisionMatrixD10" 
                                                value={Inputs.WeightedDecisionMatrixD10} 
                                                onChange={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        setInputs({ ...Inputs, WeightedDecisionMatrixD10: event.target.value })
                                                    }
                                                }}
                                                onBlur={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        onBlurHandler(event)
                                                    }
                                                }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength={2}
                                                onKeyPress={event => {
                                                    if(!/[0-9]/.test(event.key)){
                                                        event.preventDefault();
                                                        alert.show('Please enter a valid number.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <input type="text" className="field-ipt font11px bg-color-light-blue text-center color-dark" 
                                                id="WeightedDecisionMatrixE10" 
                                                value={Inputs.WeightedDecisionMatrixE10} 
                                                onChange={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        setInputs({ ...Inputs, WeightedDecisionMatrixE10: event.target.value })
                                                    }
                                                }}
                                                onBlur={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        onBlurHandler(event)
                                                    }
                                                }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength={2}
                                                onKeyPress={event => {
                                                    if(!/[0-9]/.test(event.key)){
                                                        event.preventDefault();
                                                        alert.show('Please enter a valid number.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <input type="text" className="field-ipt font11px bg-color-light-blue text-center color-dark" 
                                                id="WeightedDecisionMatrixF10" 
                                                value={Inputs.WeightedDecisionMatrixF10} 
                                                onChange={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        setInputs({ ...Inputs, WeightedDecisionMatrixF10: event.target.value })
                                                    }
                                                }}
                                                onBlur={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        onBlurHandler(event)
                                                    }
                                                }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength={2}
                                                onKeyPress={event => {
                                                    if(!/[0-9]/.test(event.key)){
                                                        event.preventDefault();
                                                        alert.show('Please enter a valid number.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <input type="text" className="field-ipt font11px bg-color-light-blue text-center color-dark" 
                                                id="WeightedDecisionMatrixG10" 
                                                value={Inputs.WeightedDecisionMatrixG10} 
                                                onChange={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        setInputs({ ...Inputs, WeightedDecisionMatrixG10: event.target.value })
                                                    }
                                                }}
                                                onBlur={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        onBlurHandler(event)
                                                    }
                                                }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength={2}
                                                onKeyPress={event => {
                                                    if(!/[0-9]/.test(event.key)){
                                                        event.preventDefault();
                                                        alert.show('Please enter a valid number.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <input type="text" className="field-ipt font11px bg-color-light-blue text-center color-dark" 
                                                id="WeightedDecisionMatrixH10" 
                                                value={Inputs.WeightedDecisionMatrixH10} 
                                                onChange={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        setInputs({ ...Inputs, WeightedDecisionMatrixH10: event.target.value })
                                                    }
                                                }}
                                                onBlur={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        onBlurHandler(event)
                                                    }
                                                }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength={2}
                                                onKeyPress={event => {
                                                    if(!/[0-9]/.test(event.key)){
                                                        event.preventDefault();
                                                        alert.show('Please enter a valid number.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center font11px fontExtraBold'>
                                            <div className="scrore-col">
                                            {Inputs.WeightedDecisionMatrixI10}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01">
                                                <textarea className='field-ipt font11px bg-color-01 color-dark w-100'
                                                id="WeightedDecisionMatrixC11"
                                                value={Inputs.WeightedDecisionMatrixC11}
                                                onChange={(event) => setInputs({ ...Inputs, WeightedDecisionMatrixC11: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="Option c" />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <input type="text" className="field-ipt font11px bg-color-light-blue text-center color-dark" 
                                                id="WeightedDecisionMatrixD11" 
                                                value={Inputs.WeightedDecisionMatrixD11} 
                                                onChange={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        setInputs({ ...Inputs, WeightedDecisionMatrixD11: event.target.value })
                                                    }
                                                }}
                                                onBlur={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        onBlurHandler(event)
                                                    }
                                                }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength={2}
                                                onKeyPress={event => {
                                                    if(!/[0-9]/.test(event.key)){
                                                        event.preventDefault();
                                                        alert.show('Please enter a valid number.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <input type="text" className="field-ipt font11px bg-color-light-blue text-center color-dark" 
                                                id="WeightedDecisionMatrixE11" 
                                                value={Inputs.WeightedDecisionMatrixE11} 
                                                onChange={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        setInputs({ ...Inputs, WeightedDecisionMatrixE11: event.target.value })
                                                    }
                                                }}
                                                onBlur={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        onBlurHandler(event)
                                                    }
                                                }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength={2}
                                                onKeyPress={event => {
                                                    if(!/[0-9]/.test(event.key)){
                                                        event.preventDefault();
                                                        alert.show('Please enter a valid number.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <input type="text" className="field-ipt font11px bg-color-light-blue text-center color-dark" 
                                                id="WeightedDecisionMatrixF11" 
                                                value={Inputs.WeightedDecisionMatrixF11} 
                                                onChange={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        setInputs({ ...Inputs, WeightedDecisionMatrixF11: event.target.value })
                                                    }
                                                }}
                                                onBlur={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        onBlurHandler(event)
                                                    }
                                                }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength={2}
                                                onKeyPress={event => {
                                                    if(!/[0-9]/.test(event.key)){
                                                        event.preventDefault();
                                                        alert.show('Please enter a valid number.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <input type="text" className="field-ipt font11px bg-color-light-blue text-center color-dark" 
                                                id="WeightedDecisionMatrixG11" 
                                                value={Inputs.WeightedDecisionMatrixG11} 
                                                onChange={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        setInputs({ ...Inputs, WeightedDecisionMatrixG11: event.target.value })
                                                    }
                                                }}
                                                onBlur={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        onBlurHandler(event)
                                                    }
                                                }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength={2}
                                                onKeyPress={event => {
                                                    if(!/[0-9]/.test(event.key)){
                                                        event.preventDefault();
                                                        alert.show('Please enter a valid number.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <input type="text" className="field-ipt font11px bg-color-light-blue text-center color-dark" 
                                                id="WeightedDecisionMatrixH11" 
                                                value={Inputs.WeightedDecisionMatrixH11} 
                                                onChange={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        setInputs({ ...Inputs, WeightedDecisionMatrixH11: event.target.value })
                                                    }
                                                }}
                                                onBlur={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        onBlurHandler(event)
                                                    }
                                                }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength={2}
                                                onKeyPress={event => {
                                                    if(!/[0-9]/.test(event.key)){
                                                        event.preventDefault();
                                                        alert.show('Please enter a valid number.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center font11px fontExtraBold'>
                                            <div className="scrore-col">
                                            {Inputs.WeightedDecisionMatrixI11}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01">
                                                <textarea className='field-ipt font11px bg-color-01 color-dark w-100'
                                                id="WeightedDecisionMatrixC12"
                                                value={Inputs.WeightedDecisionMatrixC12}
                                                onChange={(event) => setInputs({ ...Inputs, WeightedDecisionMatrixC12: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="Option D" />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <input type="text" className="field-ipt font11px bg-color-light-blue text-center color-dark" 
                                                id="WeightedDecisionMatrixD12" 
                                                value={Inputs.WeightedDecisionMatrixD12} 
                                                onChange={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        setInputs({ ...Inputs, WeightedDecisionMatrixD12: event.target.value })
                                                    }
                                                }}
                                                onBlur={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        onBlurHandler(event)
                                                    }
                                                }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength={2}
                                                onKeyPress={event => {
                                                    if(!/[0-9]/.test(event.key)){
                                                        event.preventDefault();
                                                        alert.show('Please enter a valid number.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <input type="text" className="field-ipt font11px bg-color-light-blue text-center color-dark" 
                                                id="WeightedDecisionMatrixE12" 
                                                value={Inputs.WeightedDecisionMatrixE12} 
                                                onChange={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        setInputs({ ...Inputs, WeightedDecisionMatrixE12: event.target.value })
                                                    }
                                                }}
                                                onBlur={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        onBlurHandler(event)
                                                    }
                                                }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength={2}
                                                onKeyPress={event => {
                                                    if(!/[0-9]/.test(event.key)){
                                                        event.preventDefault();
                                                        alert.show('Please enter a valid number.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <input type="text" className="field-ipt font11px bg-color-light-blue text-center color-dark" 
                                                id="WeightedDecisionMatrixF12" 
                                                value={Inputs.WeightedDecisionMatrixF12} 
                                                onChange={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        setInputs({ ...Inputs, WeightedDecisionMatrixF12: event.target.value })
                                                    }
                                                }}
                                                onBlur={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        onBlurHandler(event)
                                                    }
                                                }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength={2}
                                                onKeyPress={event => {
                                                    if(!/[0-9]/.test(event.key)){
                                                        event.preventDefault();
                                                        alert.show('Please enter a valid number.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <input type="text" className="field-ipt font11px bg-color-light-blue text-center color-dark" 
                                                id="WeightedDecisionMatrixG12" 
                                                value={Inputs.WeightedDecisionMatrixG12} 
                                                onChange={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        setInputs({ ...Inputs, WeightedDecisionMatrixG12: event.target.value })
                                                    }
                                                }}
                                                onBlur={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        onBlurHandler(event)
                                                    }
                                                }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength={2}
                                                onKeyPress={event => {
                                                    if(!/[0-9]/.test(event.key)){
                                                        event.preventDefault();
                                                        alert.show('Please enter a valid number.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <input type="text" className="field-ipt font11px bg-color-light-blue text-center color-dark" 
                                                id="WeightedDecisionMatrixH12" 
                                                value={Inputs.WeightedDecisionMatrixH12} 
                                                onChange={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        setInputs({ ...Inputs, WeightedDecisionMatrixH12: event.target.value })
                                                    }
                                                }}
                                                onBlur={event => {
                                                    if(checkRange(event.target.value, 1, 10)){
                                                        onBlurHandler(event)
                                                    }
                                                }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength={2}
                                                onKeyPress={event => {
                                                    if(!/[0-9]/.test(event.key)){
                                                        event.preventDefault();
                                                        alert.show('Please enter a valid number.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center font11px fontExtraBold'>
                                            <div className="scrore-col">
                                            {Inputs.WeightedDecisionMatrixI12}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-left font14px fontExtraBold' colSpan={7}>Weighted Score</td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue'>
                                            <div className="font11px bg-color-light-blue color-dark">
                                                {Inputs.WeightedDecisionMatrixC9}
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="bg-color-light-blue font11px text-center color-dark">
                                                {formatPrice(Inputs.WeightedDecisionMatrixD14, 1)}
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="bg-color-light-blue font11px text-center color-dark">
                                                {formatPrice(Inputs.WeightedDecisionMatrixE14, 1)}
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="bg-color-light-blue font11px text-center color-dark">
                                               {formatPrice(Inputs.WeightedDecisionMatrixF14, 1)}
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="bg-color-light-blue font11px text-center color-dark">
                                                {formatPrice(Inputs.WeightedDecisionMatrixG14, 1)}
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="bg-color-light-blue font11px text-center color-dark">
                                                {formatPrice(Inputs.WeightedDecisionMatrixH14, 1)}
                                            </div>
                                        </td>
                                        <td className='text-center color-dark font11px fontExtraBold' style={{backgroundColor: bgColorPoint(Number(Inputs.WeightedDecisionMatrixI14))}}>
                                            <div className="scrore-col">
                                                {formatPrice(Inputs.WeightedDecisionMatrixI14, 1)}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue'>
                                            <div className="font11px bg-color-light-blue color-dark">
                                                {Inputs.WeightedDecisionMatrixC10}
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="bg-color-light-blue font11px text-center color-dark">
                                                {formatPrice(Inputs.WeightedDecisionMatrixD15, 1)}
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="bg-color-light-blue font11px text-center color-dark">
                                                {formatPrice(Inputs.WeightedDecisionMatrixE15, 1)}
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="bg-color-light-blue font11px text-center color-dark">
                                                {formatPrice(Inputs.WeightedDecisionMatrixF15, 1)}
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="bg-color-light-blue font11px text-center color-dark">
                                                {formatPrice(Inputs.WeightedDecisionMatrixG15, 1)}
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="bg-color-light-blue font11px text-center color-dark">
                                                {formatPrice(Inputs.WeightedDecisionMatrixH15, 1)}
                                            </div>
                                        </td>
                                        <td className='text-center color-dark font11px fontExtraBold' style={{backgroundColor: bgColorPoint(Number(Inputs.WeightedDecisionMatrixI15))}}>
                                            <div className="scrore-col">
                                                {formatPrice(Inputs.WeightedDecisionMatrixI15, 1)}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue'>
                                            <div className="font11px bg-color-light-blue color-dark">
                                                {Inputs.WeightedDecisionMatrixC11}
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="bg-color-light-blue font11px text-center color-dark">
                                                {formatPrice(Inputs.WeightedDecisionMatrixD16, 1)}
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="bg-color-light-blue font11px text-center color-dark">
                                                {formatPrice(Inputs.WeightedDecisionMatrixE16, 1)}
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="bg-color-light-blue font11px text-center color-dark">
                                                {formatPrice(Inputs.WeightedDecisionMatrixF16, 1)}
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="bg-color-light-blue font11px text-center color-dark">
                                                {formatPrice(Inputs.WeightedDecisionMatrixG16, 1)}
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="bg-color-light-blue font11px text-center color-dark">
                                                {formatPrice(Inputs.WeightedDecisionMatrixH16, 1)}
                                            </div>
                                        </td>
                                        <td className='text-center color-dark font11px fontExtraBold' style={{backgroundColor: bgColorPoint(Number(Inputs.WeightedDecisionMatrixI16))}}>
                                            <div className="scrore-col">
                                                {formatPrice(Inputs.WeightedDecisionMatrixI16, 1)}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue'>
                                            <div className="font11px bg-color-light-blue color-dark">
                                                {Inputs.WeightedDecisionMatrixC12}
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="bg-color-light-blue font11px text-center color-dark">
                                                {formatPrice(Inputs.WeightedDecisionMatrixD17, 1)}
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="bg-color-light-blue font11px text-center color-dark">
                                                {formatPrice(Inputs.WeightedDecisionMatrixE17, 1)}
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="bg-color-light-blue font11px text-center color-dark">
                                                {formatPrice(Inputs.WeightedDecisionMatrixF17, 1)}
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="bg-color-light-blue font11px text-center color-dark">
                                                {formatPrice(Inputs.WeightedDecisionMatrixG17, 1)}
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="bg-color-light-blue font11px text-center color-dark">
                                                {formatPrice(Inputs.WeightedDecisionMatrixH17, 1)}
                                            </div>
                                        </td>
                                        <td className='text-center color-dark font11px fontExtraBold' style={{backgroundColor: bgColorPoint(Number(Inputs.WeightedDecisionMatrixI17))}}>
                                            <div className="scrore-col">
                                                {formatPrice(Inputs.WeightedDecisionMatrixI17, 1)}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="nextInput">
                            <ul>
                                <li>
                                    <Link to="/grow" className='next-link'> Next  Grow  
                                        <span className='icon-three'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                        </span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}