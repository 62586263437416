import React from 'react'
import { Link } from "react-router-dom";
import 'bootstrap-icons/font/bootstrap-icons.css';
import  UserDropdown  from '../UserDropdown';

export default function MainContent() {
  return (
    <div className="content-body">
    <UserDropdown />
    
        {/* row */}
        <div className="container-fluid">
            <div className="head-title">
                <h2>Contents</h2>	            					
            </div>
            <div className="card">
                <div className="card-body">
                <div className="divflex">
                    <div className="leeftside">
                    <i className="bi bi-speedometer" />
                    </div>
                    <div className="righttside">
                    <h3>Introduction to the 120 Growth Catalyst™</h3>
                    <ul>
                        <li><Link to="/contents/executive-summary">Executive Summary</Link></li>
                        <li><Link to="/contents/improve-your-score">How the 120 Growth Catalyst™ Works</Link></li>
                    </ul>
                    </div>
                </div>
                <div className="divflex">
                    <div className="leeftside">
                    <i className="bi bi-activity" />
                    </div>
                    <div className="righttside">
                    <h3>State of the Business:  Benchmarking Your Performance &amp; Capabilities</h3>
                    <ul>
                        <li><Link to="/state-of-business/benchmark-Scorecard">Benchmark Scorecard</Link></li>
                        <li><Link to="/state-of-the-business/growth-driver-performance">Growth Driver Performance Overview</Link></li>
                        <li><Link to="/state-of-the-business/growth-capabilities-gap-analysis">Benchmark Score Gap Analysis</Link></li>
                    </ul>
                    </div>
                </div>
                <div className="divflex">
                    <div className="leeftside">
                    <i className="bi bi-lightbulb-off-fill" />
                    </div>
                    <div className="righttside">
                    <h3>Your Growth Potential:  Driving Short- and Long-Term Growth</h3>
                    <ul>
                        <li><Link to="/diagnose/client-inputs">Client Inputs</Link></li>
                        <li><Link to="/diagnose/customer-financial-value">Customer Financial Values</Link></li>
                        <li><Link to="/your-growth-potential/growth-potential-overview">The Power of 1%:  This is what a few 1% improvements can do for your business.</Link></li>
                        <li><Link to="/contents/impact-of-the-120-growth">Financial Impact of the 120 Growth Operating System™</Link></li>
                        <li><Link to="/your-growth-potential/creating-growth-momentum">Creating Growth Momentum</Link></li>
                        <li><Link to="/your-growth-potential/equity-evolution">Equity Evolution™ </Link></li>
                        <li><Link to="/prescribe/the-compounding-effect">Finding Growth in Your Business</Link></li>
                        <li><Link to="/your-growth-potential/what-is-driving-growth">What's Driving Growth?</Link></li>
                        <li><Link to="/your-growth-potential/return-on-investment">120 Growth Return On Investment</Link></li>
                    </ul>
                    </div>
                </div>
                <div className="divflex">
                    <div className="leeftside">
                    <i className="bi bi-tools" />
                    </div>
                    <div className="righttside">
                    <h3>Revenue Growth Drivers</h3>
                    <ul>
                        <li><Link to="/prescribe/lead-generation">Lead Generation</Link></li>
                        <li><Link to="/prescribe/conversion">Conversion</Link></li>
                        <li><Link to="/prescribe/referrals">Referrals</Link></li>
                        <li><Link to="/prescribe/purchase-frequency">Purchase Frequency</Link></li>
                        <li><Link to="/prescribe/buying-lifetime">Buying Lifetime</Link></li>
                        <li><Link to="/prescribe/customer-attrition">Reducing Attrition</Link></li>
                        <li><Link to="/prescribe/customer-reactivation">Customer Reactivation</Link></li>
                        <li><Link to="/prescribe/average-transaction-value">Average Transaction Value</Link></li>
                        <li><Link to="/prescribe/profit-margin">Profit Margin</Link></li>
                    </ul>
                    </div>
                </div>
                <div className="divflex">
                    <div className="leeftside">
                    <i className="bi bi-currency-exchange" />
                    </div>
                    <div className="righttside">
                    <h3>120 Growth Benchmark</h3>
                    <ul>
                        <li><Link to="/diagnose/competitive-advantage">Competitive Advantage</Link></li>
                        <li><Link to="/diagnose/financial-performance">Financial Performance</Link></li>
                        <li><Link to="/diagnose/customer-acquisition">Customer Acquisition</Link></li>
                        <li><Link to="/diagnose/customer-retention">Customer Retention</Link></li>
                        <li><Link to="/diagnose/customer-transactions">Customer Transactions</Link></li>
                        <li><Link to="/120-growth-benchmark/ability">Ability</Link></li>
                        <li><Link to="/diagnose/agility">Agility</Link></li>
                        <li><Link to="/120-growth-benchmark/long-term-growth">Long-Term Growth &amp; Enterprise Value</Link></li>
                    </ul>
                    </div>
                </div>
                </div>
            </div>
            </div>

    </div>
  )
}
