import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state/index';
import  UserDropdown  from '../UserDropdown';
import { getFindingGrowthInYourBusiness, modifyFindingGrowthInYourBusiness } from "../../state/features/ClientGrowthSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import { Link } from 'react-router-dom';
import CompoundEffectGraph from '../graph/CompoundEffectGraph';

export default function TheCompoundingEffectContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;

    const dispatch = useDispatch();
    const clientInputs = useSelector(state => state.clientInputs)
    const findingGrowthInYourBusiness = useSelector(state => state.findingGrowthInYourBusiness)
    
    const { findingGrowthInYourBusinessData } = bindActionCreators(actionCreators, dispatch)

    const { clientInputsH16, clientInputsH30, clientInputsH33, clientInputsH36 } = clientInputs

    const [Inputs, setInputs] = useState({
        findingGrowthInYourBusinessE17: 0,
        findingGrowthInYourBusinessE18: 0,
        findingGrowthInYourBusinessE19: 0,
        findingGrowthInYourBusinessE20: 0,
    });

    const [Cache, setCache] = useState(Inputs);
    //console.log(Inputs)

    useEffect(() => {
        findingGrowthInYourBusinessData("findingGrowthInYourBusinessImproving", {
            clientInputsH16, clientInputsH30, clientInputsH33, clientInputsH36
        });
    }, [])

    useEffect(() => {
        findingGrowthInYourBusinessData("findingGrowthInYourBusinessImproving", {
            clientInputsH16, clientInputsH30, clientInputsH33, clientInputsH36,
            findingGrowthInYourBusinessE17: Number(Inputs.findingGrowthInYourBusinessE17),
            findingGrowthInYourBusinessE18: Number(Inputs.findingGrowthInYourBusinessE18),
            findingGrowthInYourBusinessE19: Number(Inputs.findingGrowthInYourBusinessE19),
            findingGrowthInYourBusinessE20: Number(Inputs.findingGrowthInYourBusinessE20),
        });
    }, [
        Inputs.findingGrowthInYourBusinessE17,
        Inputs.findingGrowthInYourBusinessE18,
        Inputs.findingGrowthInYourBusinessE19,
        Inputs.findingGrowthInYourBusinessE20,
    ]);

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getFindingGrowthInYourBusiness({user_id}))
        .unwrap()
        .then((response) => {
            //console.log("response",response.data.clientGrowth)
            if(response!= undefined && response.data.clientGrowth[0]){
                let clientGrowth = response.data.clientGrowth[0];
                // console.log(clientGrowth)
                delete clientGrowth.id;
                delete clientGrowth.user_id;
                delete clientGrowth.created_at;
                delete clientGrowth.updated_at;
                const newObj = { ...Inputs }
                for (const key in clientGrowth) {
                    if (clientGrowth.hasOwnProperty(key)) {
                        if(clientGrowth[key])
                        newObj[key] = Number(clientGrowth[key]).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})
                    }
                }
                // console.log('newObj',newObj)
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

      const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyFindingGrowthInYourBusiness({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                    // console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        //window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

    const { findingGrowthInYourBusinessD17, findingGrowthInYourBusinessF17, findingGrowthInYourBusinessD18, findingGrowthInYourBusinessF18, findingGrowthInYourBusinessD19, findingGrowthInYourBusinessF19, findingGrowthInYourBusinessD20, findingGrowthInYourBusinessF20, findingGrowthInYourBusinessD22, findingGrowthInYourBusinessF22, findingGrowthInYourBusinessD23, findingGrowthInYourBusinessF23, findingGrowthInYourBusinessS17, findingGrowthInYourBusinessS18 } = findingGrowthInYourBusiness

    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
    
            {/* row */}
            <div className="container-fluid">
                <div className="head-title">
                    <h3 className='font-sise-20'>Prescribe - Opportunity Analysis</h3>
                    <h1 className='font-sise-42 color-drk'>The Compounding Effect</h1>	
                </div>
                <Spinner />
                <div className="row mb-2" style={{maxWidth: 1220}}>
                    <div className="col-12 col-lg-6">
                        <div className="compounding-img-bx">
                            <CompoundEffectGraph data={[
                                    ["Element", "", {role: "annotation"}, {role: 'style'}],
                                    ["Sales", Number(findingGrowthInYourBusinessF22), '$'+Number(findingGrowthInYourBusinessF22).toLocaleString('en-US'), 'stroke-color: #0075F2; stroke-width: 10; fill-color: #0075F2'],
                                    ["Profit", Number(findingGrowthInYourBusinessF23), '$'+Number(findingGrowthInYourBusinessF23).toLocaleString('en-US'), 'stroke-color: #72BDA3; stroke-width: 10; fill-color: #72BDA3'],
                                ]} 
                            />
                            
                            {/* <BarChartGraph dataInput={{
                                "Title"      : "",
                                "vTitle"     : "",
                                "hTitle"     : "",
                                "subTitle"   : "",
                                "ColorCodes" : ["#AAF0D1", "#3090C7"],
                                "GraphData"  : [
                                    ["", "Sales", "Profit"],
                                    ["", Number(findingGrowthInYourBusinessF23), Number(findingGrowthInYourBusinessF22)]
                                ],
                                "height" : "270px"
                            }} /> */}
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="total-improvement-bx black-bg" style={{width: '100%'}}>
                            <div className="total-improvement-bx1 border-bottom">
                                <img src="../images/compounding-effecting/growth.png" alt="growth-gm" />
                                <h1 className='font32px text-white text-center w-100'>Total Improvements</h1>
                            </div>
                            <div className="grossSales-prt-bx">
                                <div className='grossSales'>
                                    <h4 className='font20px text-white text-center'>Gross Sales</h4>
                                    <h2 className='font32px fontExtraBold text-white text-center'>{Number(findingGrowthInYourBusinessS17).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}%</h2>
                                </div>
                                <div className='grossSales gross-profit'>
                                    <h4 className='font20px text-white text-center'>Gross Profit</h4>
                                    <h2 className='font32px fontExtraBold text-white text-center'>{Number(findingGrowthInYourBusinessS18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}%</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="card inputbg">
                    <div className="card-body pl-0">
                        <div className="table-responsive update-class-lead">
                            <table className="table" style={{width: 1200}}>
                                <tbody>
                                    <tr>
                                        <td className='hide-content'/>
                                        <td className="black-bg font16px text-white text-center">Current</td>
                                        <td className="black-bg font16px text-white text-center">Improvement</td>
                                        <td className="black-bg font16px text-white text-center">Potential</td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font14px color-dark' style={{width: 600}}>Number of Active Customers</td>
                                        <td className='bg-color-light-blue font14px color-dark' style={{width: 200}}> {Number(findingGrowthInYourBusinessD17).toLocaleString('en-US')}</td>
                                        <td className='padding-0 bg-color-01' style={{width: 200}}>
                                            <div className="dollor-sin-pr">
                                                <div className="input-fiel-cn bg-color-01 client-inpt-field">
                                                    <input type="text"
                                                    id="findingGrowthInYourBusinessE17"
                                                    name="Inputs.findingGrowthInYourBusinessE17"
                                                    className="field-ipt bg-color-01 font14px color-dark w-100" 
                                                    placeholder={0}
                                                    value={Inputs.findingGrowthInYourBusinessE17}
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    onChange={(event) => {
                                                        setInputs({ ...Inputs, findingGrowthInYourBusinessE17: event.target.value });
                                                        findingGrowthInYourBusinessData("findingGrowthInYourBusinessE17", event.target.value)
                                                    }}
                                                    onKeyPress={(event) => {
                                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                        else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }}
                                                    disabled={isLoading}
                                                    />
                                                </div>
                                                <div className="perecentage font14px color-dark">%</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font14px color-dark' style={{width: 200}}>{Number(findingGrowthInYourBusinessF17).toLocaleString('en-US')}</td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font14px color-dark'>Average Customer Purchase Frequency</td>
                                        <td className='bg-color-light-blue font14px color-dark'> {Number(findingGrowthInYourBusinessD18).toLocaleString('en-US')}</td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="bg-color-01 dollor-sin-pr">
                                                <div className="input-fiel-cn bg-color-01 client-inpt-field">
                                                    <input type="text"
                                                    id="findingGrowthInYourBusinessE18"
                                                    name="Inputs.findingGrowthInYourBusinessE18"
                                                    className="field-ipt bg-color-01 font14px color-dark w-100"
                                                    placeholder={0}
                                                    value={Inputs.findingGrowthInYourBusinessE18}
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    onChange={(event) => {
                                                        setInputs({ ...Inputs, findingGrowthInYourBusinessE18: event.target.value });
                                                        findingGrowthInYourBusinessData("findingGrowthInYourBusinessE18", event.target.value)
                                                    }}
                                                    onKeyPress={(event) => {
                                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                        else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }}
                                                    disabled={isLoading}
                                                   
                                                    />
                                                </div>
                                                <div className="perecentage font14px color-dark">%</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font14px color-dark'> {Number(findingGrowthInYourBusinessF18).toLocaleString('en-US')}</td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font14px color-dark'>Average Transaction Value</td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font14px color-dark"> $ </div>
                                                <div className="annual-dl-number font14px color-dark">
                                                    {Number(findingGrowthInYourBusinessD19).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="input-fiel-cn bg-color-01 client-inpt-field">
                                                <input
                                                    type="text"
                                                    id="findingGrowthInYourBusinessE19"
                                                    name="Inputs.findingGrowthInYourBusinessE19"
                                                    className="field-ipt bg-color-01 font14px color-dark w-100" 
                                                    placeholder={0}
                                                    value={Inputs.findingGrowthInYourBusinessE19}
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    onChange={(event) => {
                                                        setInputs({ ...Inputs, findingGrowthInYourBusinessE19: event.target.value });
                                                        findingGrowthInYourBusinessData("findingGrowthInYourBusinessE19", event.target.value)
                                                    }}
                                                    onKeyPress={(event) => {
                                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                        else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }}
                                                    disabled={isLoading}
                                                   
                                                    />
                                                </div>
                                                <div className="perecentage font14px color-dark">%</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font14px color-dark"> $ </div>
                                                <div className="annual-dl-number font14px color-dark">
                                                    {Number(findingGrowthInYourBusinessF19).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font14px color-dark'>Profit Margin</td>
                                        <td className='bg-color-light-blue font14px color-dark'>{Number(findingGrowthInYourBusinessD20).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})} % </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="input-fiel-cn bg-color-01 client-inpt-field">
                                                <input
                                                    type="text"
                                                    id="findingGrowthInYourBusinessE20"
                                                    name="Inputs.findingGrowthInYourBusinessE20"
                                                    className="field-ipt bg-color-01 font14px color-dark w-100" 
                                                    placeholder={0}
                                                    value={Inputs.findingGrowthInYourBusinessE20}
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    onChange={(event) => {
                                                        setInputs({ ...Inputs, findingGrowthInYourBusinessE20: event.target.value });
                                                        findingGrowthInYourBusinessData("findingGrowthInYourBusinessE20", event.target.value)
                                                    }}
                                                    onKeyPress={(event) => {
                                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                        else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                    }}
                                                    disabled={isLoading}
                                                   
                                                    />
                                                </div>
                                                <div className="perecentage font14px color-dark">%</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font14px color-dark'>{Number(findingGrowthInYourBusinessF20).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})} % </td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content' colSpan={4}>...</td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font14px color-dark'>Estimated Gross Sales</td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font14px color-dark"> $ </div>
                                                <div className="annual-dl-number font14px color-dark">
                                                    {Number(findingGrowthInYourBusinessD22).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="bg-color-03" />
                                        <td className='bg-color-teal text-white'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font14px text-white"> $ </div>
                                                <div className="annual-dl-number font14px text-white">
                                                    {Number(findingGrowthInYourBusinessF22).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 }).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font14px color-dark'>Estimated Gross Profit</td>
                                        <td>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font14px color-dark"> $ </div>
                                                <div className="annual-dl-number font14px color-dark">
                                                    {Number(findingGrowthInYourBusinessD23).toLocaleString('en-US',{minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="bg-color-03" />
                                        <td className='bg-color-teal text-white'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font14px text-white"> $ </div>
                                                <div className="annual-dl-number font14px text-white">
                                                    {Number(findingGrowthInYourBusinessF23).toLocaleString('en-US',{minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="nextInput">
                            <ul>
                                <li>
                                    <Link to="/prescribe/find-the-Low-Hanging-Fruit" className='next-link'> Next: Low-Hanging Fruit
                                        <span className='icon-three'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                        </span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* <div className="card">
                    <div className="card-body pl-0 pr-0">
                        <div className="table-responsive">
                            <table className="table table-responsive-md mb-0">
                                <thead>
                                    <tr>
                                        <td scope="row" className="bg-color1 text-white" colSpan={2}>Total Improvements</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="thead-primary">
                                        <td className="bg-color1 text-white">Gross Sales</td>
                                        <td className="bg-color1 text-white text-center-c">{Number(findingGrowthInYourBusinessS17).toLocaleString('en-US')}%</td>
                                    </tr>
                                    <tr className="thead-primary">
                                        <td className="bg-color1 text-white">Gross Profit</td>
                                        <td className="bg-color1 text-white text-center-c">${Number(findingGrowthInYourBusinessS18).toLocaleString('en-US')}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div> */}
            </div>

        </div>
    )
}
