import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state/index';
import { BarChartGraph } from "../graph/BarChartGraph";
import  UserDropdown  from '../UserDropdown';
import { addEditImprovementPercentage } from "../../state/features/ClentInputSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { useAlert } from 'react-alert'
import { Spinner } from '../spinner'
import { Link } from 'react-router-dom';
import { StatesUseEffect } from '../../utils/StatesUseEffect';
import { BarChartGraphNew } from '../graph/BarChartGraphNew';

export default function AverageTransactionValueContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const users = useSelector(userData);
    const userID = users.data.profile.id;
    const { message } = useSelector((state) => state.message);

    const clientInputs = useSelector(state => state.clientInputs)
    const improvementPercentage = clientInputs.improvementPercentage
    const dispatch = useDispatch();
    const { averageTransactionValueData } = bindActionCreators(actionCreators, dispatch)
    const averageTransactionValue = useSelector(state => state.averageTransactionValue)

    const [Inputs, setInputs] = useState({
        averageTransactionValueD13: averageTransactionValue.averageTransactionValueD13,
    });
    const { clientInputsH33 } = clientInputs
    let averageTransactionValueD12 = (parseFloat(clientInputsH33)) ? parseFloat(clientInputsH33) : 0

    useEffect(() => {
        averageTransactionValueData("averageTransactionValueD12",averageTransactionValueD12);
        averageTransactionValueData("averageTransactionValueD13",improvementPercentage.averageTransactionValueD13);
    }, [])

    const { averageTransactionValueD17, averageTransactionValueE17, averageTransactionValueF17, averageTransactionValueG17, averageTransactionValueH17, averageTransactionValueI17, averageTransactionValueD18, averageTransactionValueE18, averageTransactionValueF18, averageTransactionValueG18, averageTransactionValueH18, averageTransactionValueI18, averageTransactionValueD22, averageTransactionValueE22, averageTransactionValueF22, averageTransactionValueG22, averageTransactionValueH22, averageTransactionValueI22, averageTransactionValueD23, averageTransactionValueE23, averageTransactionValueF23, averageTransactionValueG23, averageTransactionValueH23, averageTransactionValueI23, averageTransactionValueD25, averageTransactionValueE25, averageTransactionValueF25, averageTransactionValueG25, averageTransactionValueH25, averageTransactionValueI25, averageTransactionValueD26, averageTransactionValueE26, averageTransactionValueF26, averageTransactionValueG26, averageTransactionValueH26, averageTransactionValueI26, averageTransactionValueD28, averageTransactionValueE28, averageTransactionValueF28, averageTransactionValueG28, averageTransactionValueH28, averageTransactionValueI28 } = averageTransactionValue //object destructuring for averageTransactionValue

    const improvementPrcntHandler = (impPrcnt) => {
        setIsLoading(true);
        let sendData = {user_id : userID, averageTransactionValueD13: impPrcnt}
        dispatch(addEditImprovementPercentage(sendData))
        .unwrap()
        .then((response) => {
          console.log("response",response)
            if(response!= undefined){
               console.log('success')
            }
            setIsLoading(false)   // Hide loading screen 
        })
        .catch((error) => {
          console.log("error",error)
            { message && alert.show(message) }
            setIsLoading(false)   // Hide loading screen 
        });
    }

    // call states useEffect utils
    StatesUseEffect()

    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
            {/* row */}
            <div className="container-fluid lead-generation-new">
                <div className="head-title mb-5">
                    <h3 className='preheading'>Prescribe - Growth Drivers</h3>
                    <h1 className='mainheading mb-1'>Average Transaction Value</h1>	
                </div>
                <Spinner />
                <div className="row lead-genearation-rw mb-5" style={{maxWidth: 1265}}>
                    <div className="col-12 col-lg-6">
                        {/* <div className="potential-growth-ld mb-3" style={{maxWidth: 515}}>
                            <h3><strong>Potential Growth Activities or Tactics:</strong></h3>
                            <ol>
                                <li>Activity 1</li>
                                <li>Activity 2</li>
                                <li>Activity 3</li>
                            </ol>
                        </div> */}
                        <div className="lead-generation-table mt-218" style={{overflowY: 'auto'}}>
                            <div className="responsive-table">
                                <table className='table' style={{width: 515}}>
                                    <thead>
                                        <tr>
                                            <th scope='col' className='black-bg text-white fontBold' style={{width: 515}} colSpan={2}>Increasing Average Transaction Value</th>
                                            {/* <th className='black-bg hide-content'>2</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td scope='row' className='bg-color-light-blue font12px color-black' style={{width: 365}}>Average Transaction Value</td>
                                            <td className='bg-color-light-blue font12px color-black text-right-rt' style={{width: 150}}>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                {(parseFloat(clientInputsH33)) ? Number(clientInputsH33).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1] : 0}
                                                </div>
                                            </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td scope='row' className='bg-color-light-blue font12px color-black'>Projected Improvement</td>
                                            <th scope='row' className='padding-0'>
                                                <div className="dollor-sin-pr">
                                                    <div className="input-fiel-cn bg-color-01 client-inpt-field">
                                                        <input type="text" 
                                                        name="Inputs.averageTransactionValueD13"
                                                        className="field-ipt wthinherit font12px bg-color-01 text-right text-black"
                                                        placeholder={0}
                                                        value={Number(Inputs.averageTransactionValueD13).toLocaleString('en-US', {minimumFractionDigits: 1, maximumFractionDigits: 1})}
                                                        onChange={(event) => {
                                                            setInputs({ ...Inputs, averageTransactionValueD13: event.target.value });
                                                            averageTransactionValueData("averageTransactionValueD13", event.target.value);
                                                            improvementPrcntHandler(event.target.value)
                                                        }}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        disabled={isLoading}
                                                    />
                                                    </div>
                                                    <div className="perecentage">%</div>
                                                </div>
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                                <Spinner />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6" style={{minHeight: 400}}>
                        <BarChartGraphNew dataInput={{
                            "Title"      : "Transaction Value:  Impact on Financial Performance",
                            "ColorCodes" : ["#d6dce5", "#8497b0", "#1e2b3c"],
                            "GraphData"  : {
                                labels: [["Your","Projection"], "Year 1", "Year 2", "Year 3", "Year 4", "Year 5"],
                                datasets: [
                                    {
                                        label: "Profit",
                                        backgroundColor: "#1e65a7", //"#d6dce5",
                                        data: [Number(averageTransactionValueD23),Number(averageTransactionValueE23),Number(averageTransactionValueF23),Number(averageTransactionValueG23),Number(averageTransactionValueH23),Number(averageTransactionValueI23)]
                                    },
                                    {
                                        label: "Sales",
                                        backgroundColor: "#8497b0",
                                        data: [Number(averageTransactionValueD22),Number(averageTransactionValueE22),Number(averageTransactionValueF22),Number(averageTransactionValueG22),Number(averageTransactionValueH22),Number(averageTransactionValueI22)]
                                    },
                                    {
                                        label: "Valuation",
                                        backgroundColor: "#1e2b3c",
                                        data: [Number(averageTransactionValueD28),Number(averageTransactionValueE28),Number(averageTransactionValueF28),Number(averageTransactionValueG28),Number(averageTransactionValueH28),Number(averageTransactionValueI28)]
                                    }
                                ]
                            }
                        }} />
                        {/* <div className="">
                            <h3 className='text-center impact-lead-titile'><strong>Transaction Value: Impact on Financial Performance </strong></h3>
                            
                            <BarChartGraph dataInput={{
                                "Title"      : "",
                                "vTitle"     : "",
                                "hTitle"     : "",
                                "subTitle"   : "",
                                "ColorCodes" : ["#c2c7cd", "#8497b0", "#44546a"],
                                "GraphData"  : [
                                    ["", "Profit", "Sales", "Valuation"],
                                    ["Year 1", Number(averageTransactionValueE23), Number(averageTransactionValueE22), Number(averageTransactionValueE28)],
                                    ["Year 2", Number(averageTransactionValueF23), Number(averageTransactionValueF22), Number(averageTransactionValueF28)],
                                    ["Year 3", Number(averageTransactionValueG23), Number(averageTransactionValueG22), Number(averageTransactionValueG28)],
                                    ["Year 4", Number(averageTransactionValueH23), Number(averageTransactionValueH22), Number(averageTransactionValueH28)],
                                    ["Year 5", Number(averageTransactionValueI23), Number(averageTransactionValueI22), Number(averageTransactionValueI28)]
                                ]
                            }} />
                        </div> */}
                    </div>
                </div>
                <div className="card update-class-lead">
                    <div className="card-body pl-0">
                        <div className="table-responsive">
                            <table className="table table-responsive-md table-striped mb-0" style={{width: 1265}}>
                                <thead>
                                    <tr>
                                    <th className="table-primar" style={{width: 365}}/>
                                    <th colSpan={2} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Year 1 Gains</th>
                                    <th colSpan={2} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Mid-Term Gains</th>
                                    <th colSpan={5} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Long-Term Gains </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="thead-primary">
                                        <th className='black-bg fontBold' style={{width: 365}}>Improved Average Transaction Value</th>
                                        <th className='black-bg' style={{width: 150}}>Your Projection</th>
                                        <th className='black-bg' style={{width: 150}}>1% Quarterly Improvement</th>
                                        <th className='black-bg' style={{width: 150}}>2 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>3 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>4 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>5 Years of 1% Improvements</th>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>Dollar Improvement of Average Transaction</td>
                                        <td className='bg-color-blue-gray text-white font12px'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px text-white"> $ </div>
                                                <div className="annual-dl-number font12px text-white">
                                                    {Number(averageTransactionValueD17).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(averageTransactionValueE17).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(averageTransactionValueF17).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(averageTransactionValueG17).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(averageTransactionValueH17).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(averageTransactionValueI17).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'> <strong>Potentioal Improvement in Average Transaction Value</strong></td>
                                        <td className='bg-color-blue-gray text-white font12px'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px text-white"> $ </div>
                                                <div className="annual-dl-number font12px text-white">
                                                    <strong>
                                                    {Number(averageTransactionValueD18).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                    </strong>
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-teal text-white font12px'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px"><strong>
                                                    {Number(averageTransactionValueE18).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}</strong>
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-teal text-white font12px'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px"><strong>
                                                    {Number(averageTransactionValueF18).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}</strong>
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-teal text-white font12px'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px"><strong>
                                                    {Number(averageTransactionValueG18).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}</strong>
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-teal text-white font12px'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px"><strong>
                                                    {Number(averageTransactionValueH18).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}</strong>
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-teal text-white font12px'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px"><strong>
                                                    {Number(averageTransactionValueI18).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}</strong>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="card update-class-lead">
                            <div className="card-body pl-0">
                            <div className="table-responsive">
                                <table className="table table-responsive-md table-striped mb-0" style={{width: 1265}}>
                                <thead>
                                    <tr>
                                    <th style={{background:'#fff', width: 365}}><h3>Financial Improvements</h3></th>
                                    <th colSpan={2} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Year 1 Gains</th>
                                    <th colSpan={2} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Mid-Term Gains</th>
                                    <th colSpan={5} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Long-Term Gains </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="thead-primary">
                                        <th className='black-bg fontBold' style={{width: 365}}>Annual Impact of Improved Avg Transaction Value</th>
                                        <th className='black-bg' style={{width: 150}}>Your Projection</th>
                                        <th className='black-bg' style={{width: 150}}>1% Quarterly Improvement</th>
                                        <th className='black-bg' style={{width: 150}}>2 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>3 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>4 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>5 Years of 1% Improvements</th>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>Annual Sales</td>
                                        <td className='bg-color-blue-gray text-white font12px'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px text-white"> $ </div>
                                                <div className="annual-dl-number font12px text-white">
                                                    {Number(averageTransactionValueD22).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(averageTransactionValueE22).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(averageTransactionValueF22).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(averageTransactionValueG22).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(averageTransactionValueH22).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(averageTransactionValueI22).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>Annual Profit</td>
                                        <td className='bg-color-blue-gray text-white font12px'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px text-white"> $ </div>
                                                <div className="annual-dl-number font12px text-white">
                                                    {Number(averageTransactionValueD23).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(averageTransactionValueE23).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(averageTransactionValueF23).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(averageTransactionValueG23).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(averageTransactionValueH23).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(averageTransactionValueI23).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="thead-primary">
                                        <th className='black-bg fontBold'>Lifetime Impact of Improved Avg Transaction Value</th>
                                        <th className='black-bg'>Your Projection</th>
                                        <th className='black-bg'>1% Quarterly Improvement</th>
                                        <th className='black-bg'>2 Years of 1% Improvements</th>
                                        <th className='black-bg'>3 Years of 1% Improvements</th>
                                        <th className='black-bg'>4 Years of 1% Improvements</th>
                                        <th className='black-bg'>5 Years of 1% Improvements</th>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>Lifetime Sales</td>
                                        <td className='bg-color-blue-gray text-white font12px'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px text-white"> $ </div>
                                                <div className="annual-dl-number font12px text-white">
                                                    {Number(averageTransactionValueD25).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(averageTransactionValueE25).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(averageTransactionValueF25).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(averageTransactionValueG25).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(averageTransactionValueH25).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(averageTransactionValueI25).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>Lifetime Profit</td>
                                        <td className='bg-color-blue-gray text-white font12px'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px text-white"> $ </div>
                                                <div className="annual-dl-number font12px text-white">
                                                    {Number(averageTransactionValueD26).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(averageTransactionValueE26).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(averageTransactionValueF26).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(averageTransactionValueG26).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(averageTransactionValueH26).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(averageTransactionValueI26).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="thead-primary">
                                        <th className='black-bg fontBold'>Valuation Impact of Improved Avg Transaction Value</th>
                                        <th className='black-bg'>Your Projection</th>
                                        <th className='black-bg'>1% Quarterly Improvement</th>
                                        <th className='black-bg'>2 Years of 1% Improvements</th>
                                        <th className='black-bg'>3 Years of 1% Improvements</th>
                                        <th className='black-bg'>4 Years of 1% Improvements</th>
                                        <th className='black-bg'>5 Years of 1% Improvements</th>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>Estimated Valuation Impact</td>
                                        <td className='bg-color-blue-gray text-white font12px'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px text-white"> $ </div>
                                                <div className="annual-dl-number font12px text-white">
                                                    {Number(averageTransactionValueD28).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(averageTransactionValueE28).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(averageTransactionValueF28).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(averageTransactionValueG28).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(averageTransactionValueH28).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(averageTransactionValueI28).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="nextInput">
                            <ul>
                                <li>
                                    <Link to="/prescribe/profit-margin" className='next-link'> Next: Profit Margin 
                                        <span className='icon-three'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                        </span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
