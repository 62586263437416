import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state/index';
import  UserDropdown  from '../UserDropdown';
import { BarChartGraph } from "../graph/BarChartGraph";
import formatPrice from '../../utils/formatPrice';
import { Spinner } from '../spinner';

export default function EquityEvolutionTheBigBonusContent() {

    const dispatch = useDispatch();
    const { equityEvolutionTheBigBonusReducerData } = bindActionCreators(actionCreators,dispatch)

    const clientInputs = useSelector(state => state.clientInputs)
    const impactof120GrowthOS = useSelector(state => state.impactof120GrowthOS)

    const equityEvolutionTheBigBonus = useSelector(state => state.equityEvolutionTheBigBonus)

    const { clientInputsD11, clientInputsH36, clientInputsH41 } = clientInputs
    const { impactof120GrowthOSG36, impactof120GrowthOSH36, impactof120GrowthOSI36, impactof120GrowthOSJ36, impactof120GrowthOSK36 } = impactof120GrowthOS

    useEffect(() => {
        // console.log('first');
        equityEvolutionTheBigBonusReducerData("equityEvolutionTheBigBonusReducerAllStats", {
            clientInputsD11,
            clientInputsH36,
            clientInputsH41,
            impactof120GrowthOSG36,
            impactof120GrowthOSH36,
            impactof120GrowthOSI36,
            impactof120GrowthOSJ36,
            impactof120GrowthOSK36
        });
    }, []);

    const { equityEvolutionTheBigBonusD24, equityEvolutionTheBigBonusE24, equityEvolutionTheBigBonusF24, equityEvolutionTheBigBonusG24, equityEvolutionTheBigBonusH24, equityEvolutionTheBigBonusI24, equityEvolutionTheBigBonusJ24, equityEvolutionTheBigBonusK24, equityEvolutionTheBigBonusL24, equityEvolutionTheBigBonusD25, equityEvolutionTheBigBonusE25, equityEvolutionTheBigBonusF25, equityEvolutionTheBigBonusG25, equityEvolutionTheBigBonusH25, equityEvolutionTheBigBonusI25, equityEvolutionTheBigBonusJ25, equityEvolutionTheBigBonusK25, equityEvolutionTheBigBonusL25,  equityEvolutionTheBigBonusD26, equityEvolutionTheBigBonusE26, equityEvolutionTheBigBonusF26, equityEvolutionTheBigBonusG26, equityEvolutionTheBigBonusH26, equityEvolutionTheBigBonusI26, equityEvolutionTheBigBonusJ26, equityEvolutionTheBigBonusK26, equityEvolutionTheBigBonusL26,  equityEvolutionTheBigBonusD27, equityEvolutionTheBigBonusE27, equityEvolutionTheBigBonusF27, equityEvolutionTheBigBonusG27, equityEvolutionTheBigBonusH27, equityEvolutionTheBigBonusI27, equityEvolutionTheBigBonusJ27, equityEvolutionTheBigBonusK27, equityEvolutionTheBigBonusL27 } = equityEvolutionTheBigBonus //object destructuring for equityEvolutionTheBigBonus

  return (
    <div className="content-body content-body-new bg-white-color">
        <UserDropdown />
    
            {/* row */}
        <div className="container-fluid lead-generation-new">
            <div className="head-title mb-4">
                <h3 className='preheading'>Your Growth Potential</h3>
                <h1 className='mainheading mb-1'>Equity Evolution - Estimated Valuation Impact</h1>
            </div>
            <div className="equity-evolution">
                <div className="equity-evolution-inner">
                    <Spinner/>
                    <div className='row mb-4'>
                        <div className='col-12'>
                            <div className="black-bg" style={{borderRadius: 15, padding: 15, minHeight: 400, maxWidth: 1500}}>
                                <BarChartGraph dataInput={{
                                    "Title"      : '$'+Number(equityEvolutionTheBigBonusL26).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})+' in increased equity value when improving the valuation multiple from "average" to "premium", and factoring in five years of growth created from consistent 1% improvements.',
                                    "vTitle"     : "",
                                    "hTitle"     : "",
                                    "subTitle"   : "",
                                    'backgroundColor': "#1e2b3c",
                                    "ColorCodes" : ["#c2c7cd", "#0663b4", "#3881ed", "#04835d", "#2aba91", "#74bfa9"],
                                    "GraphData"  : [
                                        ["", "C", { calc: "stringify",role: 'annotation'}, "Y1", { calc: "stringify", role: 'annotation'}, "Y2", { calc: "stringify", role: 'annotation'}, "Y3", { calc: "stringify", role: 'annotation'}, "Y4", { calc: "stringify", role: 'annotation'}, "Y5", { calc: "stringify", role: 'annotation'}],
                                        ["Subpar Industry Multiple", Number(equityEvolutionTheBigBonusF24), 'C', Number(equityEvolutionTheBigBonusG24), 'Y1', Number(equityEvolutionTheBigBonusH24), 'Y2', Number(equityEvolutionTheBigBonusI24), 'Y3', Number(equityEvolutionTheBigBonusJ24), 'Y4', Number(equityEvolutionTheBigBonusK24), 'Y5'],
                                        ["Average Industry Multiple", Number(equityEvolutionTheBigBonusF25), 'C', Number(equityEvolutionTheBigBonusG25), 'Y1', Number(equityEvolutionTheBigBonusH25), 'Y2', Number(equityEvolutionTheBigBonusI25), 'Y3', Number(equityEvolutionTheBigBonusJ25), 'Y4', Number(equityEvolutionTheBigBonusK25), 'Y5'],
                                        ["Premium Industry Multiple", Number(equityEvolutionTheBigBonusF26), 'C', Number(equityEvolutionTheBigBonusG26), 'Y1', Number(equityEvolutionTheBigBonusH26), 'Y2', Number(equityEvolutionTheBigBonusI26), 'Y3', Number(equityEvolutionTheBigBonusJ26), 'Y4', Number(equityEvolutionTheBigBonusK26), 'Y5'],
                                        ["'Dream' Multiple", Number(equityEvolutionTheBigBonusF27), 'C', Number(equityEvolutionTheBigBonusG27), 'Y1', Number(equityEvolutionTheBigBonusH27), 'Y2', Number(equityEvolutionTheBigBonusI27), 'Y3', Number(equityEvolutionTheBigBonusJ27), 'Y4', Number(equityEvolutionTheBigBonusK27), 'Y5']
                                    ]
                                }} />
                            </div>
                            <div className="nextInput">
                                <ul>
                                    <li>
                                        <Link to="/your-growth-potential/return-on-investment" className='next-link'> Next:  Return On Investment
                                            <span className='icon-three'>
                                                <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                                <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                                <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                            </span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table" style={{width: 1650}}>
                            <thead>
                                <tr>
                                    <td className='black-bg text-center font11px text-white' style={{width: 300}}>Industry Specific Valuation Scenario</td>
                                    <td className='black-bg text-center font11px text-white' style={{width: 150}}>Valuation  Multiple (quality)</td>
                                    <td className='black-bg text-center font11px text-white' style={{width: 150}}>Profit/EBITDA  (quantity)</td>
                                    <td className='black-bg text-center font11px text-white' style={{width: 150}}>Current  Valuation</td>
                                    <td className='black-bg text-center font11px text-white' style={{width: 150}}>1 Year of  Growth</td>
                                    <td className='black-bg text-center font11px text-white' style={{width: 150}}>2 Years of  Growth</td>
                                    <td className='black-bg text-center font11px text-white' style={{width: 150}}>3 Years of  Growth</td>
                                    <td className='black-bg text-center font11px text-white' style={{width: 150}}>4 Years of  Growth</td>
                                    <td className='black-bg text-center font11px text-white' style={{width: 150}}>5 Years of  Growth</td>
                                    <td className='black-bg text-center font11px text-white' style={{width: 150}}>Total Improvement Over 5 Years</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="bg-color-blue-gray text-white">
                                        <div className='font11px text-white'>Subpar Industry Multiple</div>
                                    </td>
                                    {/* <td className='bg-color-light-blue font15px color-black'>
                                        <div className="annual-dollor-sign">
                                            <div className="annual-dl font11px color-dark"> $ </div>
                                            <div className="annual-dl-number font11px color-dark">
                                                {formatPrice(equityEvolutionTheBigBonusD24, 2)}
                                            </div>
                                        </div>
                                    </td> */}
                                    <td className='bg-color-light-blue text-center'>
                                        <div className="bg-color-light-blue font15px color-dark">
                                            {formatPrice(equityEvolutionTheBigBonusD24, 1)}
                                        </div>
                                    </td>
                                    <td className='bg-color-light-blue font15px color-black'>
                                        <div className="annual-dollor-sign">
                                            <div className="annual-dl font11px color-dark"> $ </div>
                                            <div className="annual-dl-number font11px color-dark">
                                                {formatPrice(equityEvolutionTheBigBonusE24, 2)}
                                            </div>
                                        </div>
                                    </td>
                                    <td className='bg-color-light-blue font15px color-black'>
                                        <div className="annual-dollor-sign">
                                            <div className="annual-dl font11px color-dark"> $ </div>
                                            <div className="annual-dl-number font11px color-dark">
                                                {formatPrice(equityEvolutionTheBigBonusF24, 2)}
                                            </div>
                                        </div>
                                    </td>
                                    <td className='bg-color-light-blue font15px color-black'>
                                        <div className="annual-dollor-sign">
                                            <div className="annual-dl font11px color-dark"> $ </div>
                                            <div className="annual-dl-number font11px color-dark">
                                                {formatPrice(equityEvolutionTheBigBonusG24, 2)}
                                            </div>
                                        </div>
                                    </td>
                                    <td className='bg-color-light-blue font15px color-black'>
                                        <div className="annual-dollor-sign">
                                            <div className="annual-dl font11px color-dark"> $ </div>
                                            <div className="annual-dl-number font11px color-dark">
                                                {formatPrice(equityEvolutionTheBigBonusH24, 2)}
                                            </div>
                                        </div>
                                    </td>
                                    <td className='bg-color-light-blue font15px color-black'>
                                        <div className="annual-dollor-sign">
                                            <div className="annual-dl font11px color-dark"> $ </div>
                                            <div className="annual-dl-number font11px color-dark">
                                                {formatPrice(equityEvolutionTheBigBonusI24, 2)}
                                            </div>
                                        </div>
                                    </td>
                                    <td className='bg-color-light-blue font15px color-black'>
                                        <div className="annual-dollor-sign">
                                            <div className="annual-dl font11px color-dark"> $ </div>
                                            <div className="annual-dl-number font11px color-dark">
                                                {formatPrice(equityEvolutionTheBigBonusJ24, 2)}
                                            </div>
                                        </div>
                                    </td>
                                    <td className='bg-color-light-blue font15px color-black'>
                                        <div className="annual-dollor-sign">
                                            <div className="annual-dl font11px color-dark"> $ </div>
                                            <div className="annual-dl-number font11px color-dark">
                                                {formatPrice(equityEvolutionTheBigBonusK24, 2)}
                                            </div>
                                        </div>
                                    </td>
                                    <td className="adl-matrix-color-bg"/>
                                </tr>
                                <tr>
                                    <td className="bg-color-blue-gray text-white">
                                        <div className='font11px text-white'>Average Industry Multiple</div>
                                    </td>
                                    {/* <td className='bg-color-01 font11px color-dark'>
                                        <div className="annual-dollor-sign">
                                            <div className="annual-dl"> $ </div>
                                            <div className="annual-dl-number">
                                                {formatPrice(equityEvolutionTheBigBonusD25, 2)}
                                            </div>
                                        </div>
                                    </td> */}
                                    <td className='bg-color-01 text-center'>
                                        <div className="bg-color-01 font15px color-dark">
                                            {formatPrice(equityEvolutionTheBigBonusD25, 1)}
                                        </div>
                                    </td>
                                    <td className='bg-color-light-blue font11px color-dark'>
                                        <div className="annual-dollor-sign">
                                            <div className="annual-dl"> $ </div>
                                            <div className="annual-dl-number">
                                                {formatPrice(equityEvolutionTheBigBonusE25, 2)}
                                            </div>
                                        </div>
                                    </td>
                                    <td className='bg-color-teal font11px color-dark'>
                                        <div className="annual-dollor-sign">
                                            <div className="annual-dl"> $ </div>
                                            <div className="annual-dl-number">
                                                {formatPrice(equityEvolutionTheBigBonusF25, 2)}
                                            </div>
                                        </div>
                                    </td>
                                    <td className='bg-color-teal font11px color-dark'>
                                        <div className="annual-dollor-sign">
                                            <div className="annual-dl"> $ </div>
                                            <div className="annual-dl-number">
                                                {formatPrice(equityEvolutionTheBigBonusG25, 2)}
                                            </div>
                                        </div>
                                    </td>
                                    <td className='bg-color-teal font11px color-dark'>
                                        <div className="annual-dollor-sign">
                                            <div className="annual-dl"> $ </div>
                                            <div className="annual-dl-number">
                                                {formatPrice(equityEvolutionTheBigBonusH25, 2)}
                                            </div>
                                        </div>
                                    </td>
                                    <td className='bg-color-light-blue font11px color-dark'>
                                        <div className="annual-dollor-sign">
                                            <div className="annual-dl"> $ </div>
                                            <div className="annual-dl-number">
                                                {formatPrice(equityEvolutionTheBigBonusI25, 2)}
                                            </div>
                                        </div>
                                    </td>
                                    <td className='bg-color-light-blue font11px color-dark'>
                                        <div className="annual-dollor-sign">
                                            <div className="annual-dl"> $ </div>
                                            <div className="annual-dl-number">
                                                {formatPrice(equityEvolutionTheBigBonusJ25, 2)}
                                            </div>
                                        </div>
                                    </td>
                                    <td className='bg-color-light-blue font11px color-dark'>
                                        <div className="annual-dollor-sign">
                                            <div className="annual-dl"> $ </div>
                                            <div className="annual-dl-number">
                                                {formatPrice(equityEvolutionTheBigBonusK25, 2)}
                                            </div>
                                        </div>
                                    </td>
                                    <td className='bg-color-blue font11px text-white'>
                                        <div className="annual-dollor-sign">
                                            <div className="annual-dl"> $ </div>
                                            <div className="annual-dl-number">
                                                {formatPrice(equityEvolutionTheBigBonusL25, 2)}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="bg-color-blue-gray text-white">  
                                        <div className='font11px text-white'>Premium Industry Multiple</div>
                                    </td>
                                    {/* <td className='bg-color-light-blue font11px color-dark'>
                                        <div className="annual-dollor-sign">
                                            <div className="annual-dl"> $ </div>
                                            <div className="annual-dl-number">
                                                {formatPrice(equityEvolutionTheBigBonusD26, 2)}
                                            </div>
                                        </div>
                                    </td> */}
                                    <td className='bg-color-light-blue text-center'>
                                        <div className="bg-color-light-blue font15px color-dark">
                                            {formatPrice(equityEvolutionTheBigBonusD26, 1)}
                                        </div>
                                    </td>
                                    <td className='bg-color-light-blue font11px color-dark'>
                                        <div className="annual-dollor-sign">
                                            <div className="annual-dl"> $ </div>
                                            <div className="annual-dl-number">
                                                {formatPrice(equityEvolutionTheBigBonusE26, 2)}
                                            </div>
                                        </div>
                                    </td>
                                    <td className='bg-color-light-blue font11px color-dark'>
                                        <div className="annual-dollor-sign">
                                            <div className="annual-dl"> $ </div>
                                            <div className="annual-dl-number">
                                                {formatPrice(equityEvolutionTheBigBonusF26, 2)}
                                            </div>
                                        </div>
                                    </td>
                                    <td className='bg-color-teal font11px color-dark'>
                                        <div className="annual-dollor-sign">
                                            <div className="annual-dl"> $ </div>
                                            <div className="annual-dl-number">
                                                {formatPrice(equityEvolutionTheBigBonusG26, 2)}
                                            </div>
                                        </div>
                                    </td>
                                    <td className='bg-color-teal font11px color-dark'>
                                        <div className="annual-dollor-sign">
                                            <div className="annual-dl"> $ </div>
                                            <div className="annual-dl-number">
                                                {formatPrice(equityEvolutionTheBigBonusH26, 2)}
                                            </div>
                                        </div>
                                    </td>
                                    <td className='bg-color-teal font11px color-dark'>
                                        <div className="annual-dollor-sign">
                                            <div className="annual-dl"> $ </div>
                                            <div className="annual-dl-number">
                                                {formatPrice(equityEvolutionTheBigBonusI26, 2)}
                                            </div>
                                        </div>
                                    </td>
                                    <td className='bg-color-teal font11px color-dark'>
                                        <div className="annual-dollor-sign">
                                            <div className="annual-dl"> $ </div>
                                            <div className="annual-dl-number">
                                                {formatPrice(equityEvolutionTheBigBonusJ26, 2)}
                                            </div>
                                        </div>
                                    </td>
                                    <td className='bg-color-teal font11px color-dark'>
                                        <div className="annual-dollor-sign">
                                            <div className="annual-dl"> $ </div>
                                            <div className="annual-dl-number">
                                                {formatPrice(equityEvolutionTheBigBonusK26, 2)}
                                            </div>
                                        </div>
                                    </td>
                                    <td className='bg-color-blue font11px text-white fontBold'>
                                        <div className="annual-dollor-sign">
                                            <div className="annual-dl"> $ </div>
                                            <div className="annual-dl-number">
                                                {formatPrice(equityEvolutionTheBigBonusL26, 2)}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="bg-color-blue-gray text-white">
                                        <div className='font11px text-white'>"Dream" Multiple</div>
                                    </td>
                                    {/* <td className='bg-color-light-blue font11px color-dark'>
                                        <div className="annual-dollor-sign">
                                            <div className="annual-dl"> $ </div>
                                            <div className="annual-dl-number">
                                                {formatPrice(equityEvolutionTheBigBonusD27, 2)}
                                            </div>
                                        </div>
                                    </td> */}
                                    <td className='bg-color-light-blue text-center'>
                                        <div className="bg-color-light-blue font15px color-dark">
                                            {formatPrice(equityEvolutionTheBigBonusD27, 1)}
                                        </div>
                                    </td>
                                    <td className='bg-color-light-blue font11px color-dark'>
                                        <div className="annual-dollor-sign">
                                            <div className="annual-dl"> $ </div>
                                            <div className="annual-dl-number">
                                                {formatPrice(equityEvolutionTheBigBonusE27, 2)}
                                            </div>
                                        </div>
                                    </td>
                                    <td className='bg-color-light-blue font11px color-dark'>
                                        <div className="annual-dollor-sign">
                                            <div className="annual-dl"> $ </div>
                                            <div className="annual-dl-number">
                                                {formatPrice(equityEvolutionTheBigBonusF27, 2)}
                                            </div>
                                        </div>
                                    </td>
                                    <td className='bg-color-light-blue font11px color-dark'>
                                        <div className="annual-dollor-sign">
                                            <div className="annual-dl"> $ </div>
                                            <div className="annual-dl-number">
                                                {formatPrice(equityEvolutionTheBigBonusG27, 2)}
                                            </div>
                                        </div>
                                    </td>
                                    <td className='bg-color-light-blue font11px color-dark'>
                                        <div className="annual-dollor-sign">
                                            <div className="annual-dl"> $ </div>
                                            <div className="annual-dl-number">
                                                {formatPrice(equityEvolutionTheBigBonusH27, 2)}
                                            </div>
                                        </div>
                                    </td>
                                    <td className='bg-color-light-blue font11px color-dark'>
                                        <div className="annual-dollor-sign">
                                            <div className="annual-dl"> $ </div>
                                            <div className="annual-dl-number">
                                                {formatPrice(equityEvolutionTheBigBonusI27, 2)}
                                            </div>
                                        </div>
                                    </td>
                                    <td className='bg-color-light-blue font11px color-dark'>
                                        <div className="annual-dollor-sign">
                                            <div className="annual-dl"> $ </div>
                                            <div className="annual-dl-number">
                                                {formatPrice(equityEvolutionTheBigBonusJ27, 2)}
                                            </div>
                                        </div>
                                    </td>
                                    <td className='bg-color-light-blue font11px color-dark'>
                                        <div className="annual-dollor-sign">
                                            <div className="annual-dl"> $ </div>
                                            <div className="annual-dl-number">
                                                {formatPrice(equityEvolutionTheBigBonusK27, 2)}
                                            </div>
                                        </div>
                                    </td>
                                    <td className='bg-color-blue font11px text-white'>
                                        <div className="annual-dollor-sign">
                                            <div className="annual-dl"> $ </div>
                                            <div className="annual-dl-number">
                                                {formatPrice(equityEvolutionTheBigBonusL27, 2)}
                                            </div>
                                        </div>
                                    </td>
                                </tr>                               
                            </tbody>
                        </table>
                    </div>

                    <div className="table-responsive mt-5">
                        <table className="table" style={{width: 1200}}>
                            <thead>
                                <tr>
                                    <td className='bg-color-blue-gray text-white font16px fontExtraBold' colSpan={4}>Median Deal Multiples by EBITDA Size of Company</td>
                                    <td colSpan={3} className='hide-content'></td>
                                </tr>
                                <tr>
                                    <td className='black-bg text-white font12px' style={{width: 300}}>Industry</td>
                                    <td className='black-bg text-white text-center font12px' style={{width: 150}}>$0-1M EBITDA</td>
                                    <td className='black-bg text-white text-center font12px' style={{width: 150}}>$1-5M EBITDA</td>
                                    <td className='black-bg text-white text-center font12px' style={{width: 150}}>$5-10M EBITDA</td>
                                    <td className='black-bg text-white text-center font12px' style={{width: 150}}>$10-25M EBITDA</td>
                                    <td className='black-bg text-white text-center font12px' style={{width: 150}}>$25-50M EBITDA</td>
                                    <td className='black-bg text-white text-center font12px' style={{width: 150}}>&gt;$50M EBITDA</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='bg-color-blue-gray text-white font12px'>Manufacturing</td>
                                    <td className='text-center font12px color-dark'>4.8</td>
                                    <td className='text-center font12px color-dark'>6.0</td>
                                    <td className='text-center font12px color-dark'>6.8</td>
                                    <td className='text-center font12px color-dark'>8.5</td>
                                    <td className='text-center font12px color-dark'>10.0</td>
                                    <td className='text-center font12px color-dark'>10.0</td>
                                </tr>
                                <tr>
                                    <td className='bg-color-blue-gray text-white font12px'>Construction &amp; Engineering</td>
                                    <td className='text-center font12px color-dark'>2.5</td>
                                    <td className='text-center font12px color-dark'>3.0</td>
                                    <td className='text-center font12px color-dark'>5.3</td>
                                    <td className='text-center font12px color-dark'>5.5</td>
                                    <td className='text-center font12px color-dark'>5.5</td>
                                    <td className='text-center font12px color-dark'>6.0</td>
                                </tr>
                                <tr>
                                    <td className='bg-color-blue-gray text-white font12px'>Consumer Goods &amp; Services</td>
                                    <td className='text-center font12px color-dark'>3.8</td>
                                    <td className='text-center font12px color-dark'>5.8</td>
                                    <td className='text-center font12px color-dark'>9.0</td>
                                    <td className='text-center font12px color-dark'>9.5</td>
                                    <td className='text-center font12px color-dark'>10.0</td>
                                    <td className='text-center font12px color-dark'>10.0</td>
                                </tr>
                                <tr>
                                    <td className='bg-color-blue-gray text-white font12px'>Wholesale &amp; Distribution</td>
                                    <td className='text-center font12px color-dark'>4.0</td>
                                    <td className='text-center font12px color-dark'>6.0</td>
                                    <td className='text-center font12px color-dark'>6.5</td>
                                    <td className='text-center font12px color-dark'>7.5</td>
                                    <td className='text-center font12px color-dark'>9.0</td>
                                    <td className='text-center font12px color-dark'>10.0</td>
                                </tr>
                                <tr>
                                    <td className='bg-color-blue-gray text-white font12px'>Business Services</td>
                                    <td className='text-center font12px color-dark'>3.5</td>
                                    <td className='text-center font12px color-dark'>6.8</td>
                                    <td className='text-center font12px color-dark'>6.5</td>
                                    <td className='text-center font12px color-dark'>7.0</td>
                                    <td className='text-center font12px color-dark'>7.5</td>
                                    <td className='text-center font12px color-dark'>10.5</td>
                                </tr>
                                <tr>
                                    <td className='bg-color-blue-gray text-white font12px'>Basic Materials &amp; Energy</td>
                                    <td className='text-center font12px color-dark'>3.0</td>
                                    <td className='text-center font12px color-dark'>4.0</td>
                                    <td className='text-center font12px color-dark'>6.5</td>
                                    <td className='text-center font12px color-dark'>6.5</td>
                                    <td className="bluetab" />
                                    <td className="bluetab" />
                                </tr>
                                <tr>
                                    <td className='bg-color-blue-gray text-white font12px'>Health Care &amp; Biotech</td>
                                    <td className='text-center font12px color-dark'>8.3</td>
                                    <td className='text-center font12px color-dark'>8.3</td>
                                    <td className='text-center font12px color-dark'>8.8</td>
                                    <td className='text-center font12px color-dark'>10.0</td>
                                    {/* <td className='text-center font12px color-dark'>8.5</td>
                                    <td className='text-center font12px color-dark'>10.0</td> */}
                                    <td className="bluetab" />
                                    <td className="bluetab" />
                                </tr>
                                <tr>
                                    <td className='bg-color-blue-gray text-white font12px'>Information Technology</td>
                                    <td className='text-center font12px color-dark'>5.5</td>
                                    <td className='text-center font12px color-dark'>8.0</td>
                                    <td className='text-center font12px color-dark'>9.0</td>
                                    <td className='text-center font12px color-dark'>10.0</td>
                                    <td className='text-center font12px color-dark'>10.0</td>
                                    {/* <td className='text-center font12px color-dark'>10.0</td> */}
                                    <td className="bluetab" />
                                </tr>
                                <tr>
                                    <td className='bg-color-blue-gray text-white font12px'>Financial Services &amp; Real Estate</td>
                                    <td className='text-center font12px color-dark'>4.8</td>
                                    <td className='text-center font12px color-dark'>8.0</td>
                                    <td className='text-center font12px color-dark'>10.0</td>
                                    {/* <td className='text-center font12px color-dark'>8.5</td>
                                    <td className='text-center font12px color-dark'>8.5</td>
                                    <td className='text-center font12px color-dark'>10.0</td> */}
                                    <td className="bluetab" />
                                    <td className="bluetab" />
                                    <td className="bluetab" />
                                </tr>
                                {/* <tr>
                                    <td className='bg-color-blue-gray text-white font12px'>Media &amp; Entertainment</td>
                                    <td className='text-center font12px color-dark'>5.0</td>
                                    <td className='text-center font12px color-dark'>6.0</td>
                                    <td className='text-center font12px color-dark'>7.0</td>
                                    <td className='text-center font12px color-dark'>8.5</td>
                                    <td className='text-center font12px color-dark'>9.0</td>
                                    <td className='text-center font12px color-dark'>10.0</td>
                                </tr> */}
                                <tr>
                                    <td className='bg-color-blue-gray text-white font12px'>Average</td>
                                    <td className='text-center font12px fontBold'>4.5</td>
                                    <td className='text-center font12px fontBold'>6.2</td>
                                    <td className='text-center font12px fontBold'>7.6</td>
                                    <td className='text-center font12px fontBold'>8.1</td>
                                    <td className='text-center font12px fontBold'>8.7</td>
                                    <td className='text-center font12px fontBold'>9.3</td>
                                </tr>
                                <tr>
                                    <td colSpan={7} className='black-bg text-white font12px'>NOTE:  This information was taken from the <strong>2021 Pepperdine University Private Capital Markets Report.</strong></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="table-responsive mt-5">
                        <table className="table" style={{width: 1800}}>
                            <thead>
                                <tr>
                                    <td className='black-bg text-white font12px' style={{width: 300}}>NAICS Industry Sector</td>
                                    <td className='black-bg text-white text-center font12px' style={{width: 150}}>2010</td>
                                    <td className='black-bg text-white text-center font12px' style={{width: 150}}>2011</td>
                                    <td className='black-bg text-white text-center font12px' style={{width: 150}}>2012</td>
                                    <td className='black-bg text-white text-center font12px' style={{width: 150}}>2013</td>
                                    <td className='black-bg text-white text-center font12px' style={{width: 150}}>2014</td>
                                    <td className='black-bg text-white text-center font12px' style={{width: 150}}>2015</td>
                                    <td className='black-bg text-white text-center font12px' style={{width: 150}}>2016</td>
                                    <td className='black-bg text-white text-center font12px' style={{width: 150}}>2017</td>
                                    <td className='black-bg text-white text-center font12px' style={{width: 150}}>2018</td>
                                    <td className='black-bg text-white text-center font12px' style={{width: 150}}>2019</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='bg-color-blue-gray text-white font12px'>Construction</td>
                                    <td className='text-center font12px color-dark'>2.8</td>
                                    <td className='text-center font12px color-dark'>3.1</td>
                                    <td className='text-center font12px color-dark'>4.1</td>
                                    <td className='text-center font12px color-dark'>4.0</td>
                                    <td className='text-center font12px color-dark'>3.4</td>
                                    <td className='text-center font12px color-dark'>3.7</td>
                                    <td className='text-center font12px color-dark'>3.3</td>
                                    <td className='text-center font12px color-dark'>3.7</td>
                                    <td className='text-center font12px color-dark'>4.0</td>
                                    <td className='text-center font12px color-dark'>3.3</td>
                                </tr>
                                <tr>
                                    <td className='bg-color-blue-gray text-white font12px'>Manufacturing</td>
                                    <td className='text-center font12px color-dark'>5.0</td>
                                    <td className='text-center font12px color-dark'>6.5</td>
                                    <td className='text-center font12px color-dark'>6.6</td>
                                    <td className='text-center font12px color-dark'>7.2</td>
                                    <td className='text-center font12px color-dark'>5.8</td>
                                    <td className='text-center font12px color-dark'>6.1</td>
                                    <td className='text-center font12px color-dark'>6.5</td>
                                    <td className='text-center font12px color-dark'>5.6</td>
                                    <td className='text-center font12px color-dark'>6.2</td>
                                    <td className='text-center font12px color-dark'>5.5</td>
                                </tr>
                                <tr>
                                    <td className='bg-color-blue-gray text-white font12px'>Wholesale Trade</td>
                                    <td className='text-center font12px color-dark'>3.9</td>
                                    <td className='text-center font12px color-dark'>4.7</td>
                                    <td className='text-center font12px color-dark'>4.8</td>
                                    <td className='text-center font12px color-dark'>4.8</td>
                                    <td className='text-center font12px color-dark'>6.2</td>
                                    <td className='text-center font12px color-dark'>6.9</td>
                                    <td className='text-center font12px color-dark'>6.4</td>
                                    <td className='text-center font12px color-dark'>5.3</td>
                                    <td className='text-center font12px color-dark'>4.3</td>
                                    <td className='text-center font12px color-dark'>4.9</td>
                                </tr>
                                <tr>
                                    <td className='bg-color-blue-gray text-white font12px'>Transportation &amp; Warehousing</td>
                                    <td className='text-center font12px color-dark'>3.1</td>
                                    <td className='text-center font12px color-dark'>2.9</td>
                                    <td className='text-center font12px color-dark'>2.6</td>
                                    <td className='text-center font12px color-dark'>3.2</td>
                                    <td className='text-center font12px color-dark'>3.2</td>
                                    <td className='text-center font12px color-dark'>3.5</td>
                                    <td className='text-center font12px color-dark'>4.2</td>
                                    <td className='text-center font12px color-dark'>3.7</td>
                                    <td className='text-center font12px color-dark'>3.1</td>
                                    <td className='text-center font12px color-dark'>3.3</td>
                                </tr>
                                <tr>
                                    <td className='bg-color-blue-gray text-white font12px'>Information</td>
                                    <td className='text-center font12px color-dark'>6.4</td>
                                    <td className='text-center font12px color-dark'>10.1</td>
                                    <td className='text-center font12px color-dark'>7.0</td>
                                    <td className='text-center font12px color-dark'>8.6</td>
                                    <td className='text-center font12px color-dark'>10.0</td>
                                    <td className='text-center font12px color-dark'>6.9</td>
                                    <td className='text-center font12px color-dark'>10.7</td>
                                    <td className='text-center font12px color-dark'>13.6</td>
                                    <td className='text-center font12px color-dark'>9.7</td>
                                    <td className="bluetab" />
                                </tr>
                                <tr>
                                    <td className='bg-color-blue-gray text-white font12px'>Finance &amp; Insurance</td>
                                    <td className='text-center font12px color-dark'>6.2</td>
                                    <td className='text-center font12px color-dark'>3.2</td>
                                    <td className='text-center font12px color-dark'>4.5</td>
                                    <td className='text-center font12px color-dark'>3.8</td>
                                    <td className='text-center font12px color-dark'>6.2</td>
                                    <td className='text-center font12px color-dark'>7.8</td>
                                    <td className='text-center font12px color-dark'>9.7</td>
                                    <td className='text-center font12px color-dark'>13.6</td>
                                    <td className='text-center font12px color-dark'>12.1</td>
                                    <td className='text-center font12px color-dark'>7.6</td>
                                </tr>
                                <tr>
                                    <td className='bg-color-blue-gray text-white font12px'>Real Estate, Rental &amp; Leasing</td>
                                    <td className='text-center font12px color-dark'>2.6</td>
                                    <td className='text-center font12px color-dark'>2.8</td>
                                    <td className='text-center font12px color-dark'>3.1</td>
                                    <td className='text-center font12px color-dark'>2.4</td>
                                    <td className='text-center font12px color-dark'>4.5</td>
                                    <td className='text-center font12px color-dark'>2.9</td>
                                    <td className='text-center font12px color-dark'>3.8</td>
                                    <td className='text-center font12px color-dark'>4.2</td>
                                    <td className='text-center font12px color-dark'>6.2</td>
                                    <td className="bluetab" />
                                </tr>
                                <tr>
                                    <td className='bg-color-blue-gray text-white font12px'>Professional, Scientific &amp; Technical Services</td>
                                    <td className='text-center font12px color-dark'>5.2</td>
                                    <td className='text-center font12px color-dark'>8.5</td>
                                    <td className='text-center font12px color-dark'>4.2</td>
                                    <td className='text-center font12px color-dark'>4.6</td>
                                    <td className='text-center font12px color-dark'>5.5</td>
                                    <td className='text-center font12px color-dark'>8.0</td>
                                    <td className='text-center font12px color-dark'>5.4</td>
                                    <td className='text-center font12px color-dark'>5.9</td>
                                    <td className='text-center font12px color-dark'>4.6</td>
                                    <td className='text-center font12px color-dark'>3.7</td>
                                </tr>
                                <tr>
                                    <td className='bg-color-blue-gray text-white font12px'>Admin, Support, Waste Mgmt. &amp; Remediation Svcs.</td>
                                    <td className='text-center font12px color-dark'>2.5</td>
                                    <td className='text-center font12px color-dark'>2.7</td>
                                    <td className='text-center font12px color-dark'>3.2</td>
                                    <td className='text-center font12px color-dark'>2.6</td>
                                    <td className='text-center font12px color-dark'>2.8</td>
                                    <td className='text-center font12px color-dark'>3.0</td>
                                    <td className='text-center font12px color-dark'>3.8</td>
                                    <td className='text-center font12px color-dark'>3.7</td>
                                    <td className='text-center font12px color-dark'>3.4</td>
                                    <td className='text-center font12px color-dark'>5.4</td>
                                </tr>
                                <tr>
                                    <td className='bg-color-blue-gray text-white font12px'>Educational Services</td>
                                    <td className='text-center font12px color-dark'>2.6</td>
                                    <td className='text-center font12px color-dark'>2.8</td>
                                    <td className='text-center font12px color-dark'>4.7</td>
                                    <td className='text-center font12px color-dark'>2.7</td>
                                    <td className='text-center font12px color-dark'>3.5</td>
                                    <td className='text-center font12px color-dark'>3.6</td>
                                    <td className='text-center font12px color-dark'>4.1</td>
                                    <td className='text-center font12px color-dark'>3.1</td>
                                    <td className='text-center font12px color-dark'>4.4</td>
                                    <td className='text-center font12px color-dark'>4.0</td>
                                </tr>
                                <tr>
                                    <td className='bg-color-blue-gray text-white font12px'>Health Care &amp; Social Assistance</td>
                                    <td className='text-center font12px color-dark'>4.3</td>
                                    <td className='text-center font12px color-dark'>5.4</td>
                                    <td className='text-center font12px color-dark'>2.6</td>
                                    <td className='text-center font12px color-dark'>4.6</td>
                                    <td className='text-center font12px color-dark'>4.2</td>
                                    <td className='text-center font12px color-dark'>3.2</td>
                                    <td className='text-center font12px color-dark'>4.2</td>
                                    <td className='text-center font12px color-dark'>3.8</td>
                                    <td className='text-center font12px color-dark'>4.4</td>
                                    <td className='text-center font12px color-dark'>4.4</td>
                                </tr>
                                <tr>
                                    <td className='bg-color-blue-gray text-white font12px'>Arts, Entertainment &amp; Recreation</td>
                                    <td className='text-center font12px color-dark'>2.1</td>
                                    <td className='text-center font12px color-dark'>2.2</td>
                                    <td className='text-center font12px color-dark'>2.5</td>
                                    <td className='text-center font12px color-dark'>3.1</td>
                                    <td className='text-center font12px color-dark'>3.6</td>
                                    <td className='text-center font12px color-dark'>3.3</td>
                                    <td className='text-center font12px color-dark'>4.6</td>
                                    <td className='text-center font12px color-dark'>3.3</td>
                                    <td className='text-center font12px color-dark'>3.1</td>
                                    <td className='text-center font12px color-dark'>3.0</td>
                                </tr>
                                <tr>
                                    <td className='bg-color-blue-gray text-white font12px'>Accomodation &amp; Food Services</td>
                                    <td className='text-center font12px color-dark'>1.9</td>
                                    <td className='text-center font12px color-dark'>1.9</td>
                                    <td className='text-center font12px color-dark'>1.8</td>
                                    <td className='text-center font12px color-dark'>1.8</td>
                                    <td className='text-center font12px color-dark'>2.1</td>
                                    <td className='text-center font12px color-dark'>2.4</td>
                                    <td className='text-center font12px color-dark'>2.4</td>
                                    <td className='text-center font12px color-dark'>2.8</td>
                                    <td className='text-center font12px color-dark'>2.8</td>
                                    <td className='text-center font12px color-dark'>2.9</td>
                                </tr>
                                <tr>
                                    <td className='bg-color-blue-gray text-white font12px'>Other Services</td>
                                    <td className='text-center font12px color-dark'>2.2</td>
                                    <td className='text-center font12px color-dark'>2.6</td>
                                    <td className='text-center font12px color-dark'>2.4</td>
                                    <td className='text-center font12px color-dark'>2.6</td>
                                    <td className='text-center font12px color-dark'>2.4</td>
                                    <td className='text-center font12px color-dark'>2.8</td>
                                    <td className='text-center font12px color-dark'>2.6</td>
                                    <td className='text-center font12px color-dark'>3.0</td>
                                    <td className='text-center font12px color-dark'>2.8</td>
                                    <td className='text-center font12px color-dark'>3.5</td>
                                </tr>
                                <tr>
                                    <td className='bg-color-blue-gray text-white font12px' style={{fontWeight: 800}}>All Sectors</td>
                                    <td className='text-center font12px color-dark fontExtraBold'>2.9</td>
                                    <td className='text-center font12px color-dark fontExtraBold'>3.5</td>
                                    <td className='text-center font12px color-dark fontExtraBold'>3.1</td>
                                    <td className='text-center font12px color-dark fontExtraBold'>3.3</td>
                                    <td className='text-center font12px color-dark fontExtraBold'>3.9</td>
                                    <td className='text-center font12px color-dark fontExtraBold'>3.8</td>
                                    <td className='text-center font12px color-dark fontExtraBold'>3.9</td>
                                    <td className='text-center font12px color-dark fontExtraBold'>4.1</td>
                                    <td className='text-center font12px color-dark fontExtraBold'>3.9</td>
                                    <td className='text-center font12px color-dark fontExtraBold'>3.8</td>
                                </tr>
                                <tr className="thead-primary">
                                    <td className="bluetab text-white font12px" colSpan={11}>NOTE:  Each data point in this chart is based on a minimum of 10 transactions of private targets.  If any industry sector does not meet this criterion, it is not included in the chart.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="table-responsive mt-5">
                        <table className="table" style={{width: 1100}}>
                            <tbody>
                                <tr>
                                    <td className='black-bg text-white font20px fontExtraBold' colSpan={2}>
                                        {/* <p>The 12 Equity Drivers activated by the Revenue Growth Operating System and the impact they can have on valuation.</p> */}
                                        <p>The 12 Equity Drivers activated by the RevGrowth Operating System</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-03 text-white font12px text-center' style={{width: 50, height: 50}}>1</td>
                                    <td className='color-dark font12px' style={{width: 1050}}><strong>Competitive Advantage</strong> - It's difficult to launch a competitor that can take your market share.  Warren Buffett calls a Durable Competitive Advantage the "moat" protecting the castle (aka Buffett's Moat).  </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-03 text-white font12px text-center' style={{width: 50, height: 50}}>2</td>
                                    <td className='color-dark font12px'><strong>Intellectual Property</strong> - Developing intellectual property is important as it can often account for a significant percentage of the company's value, even surpassing the value of physical assets.</td>
                                </tr>
                                <tr>
                                    <td className='bg-color-03 text-white font12px text-center' style={{width: 50, height: 50}}>3</td>
                                    <td className='color-dark font12px'><strong>New Business Optionality</strong> - When a company is positioned properly in the market they can launch new businesses or business segments effectively.  Allows for a varied product range.</td>
                                </tr>
                                <tr>
                                    <td className='bg-color-03 text-white font12px text-center' style={{width: 50, height: 50}}>4</td>
                                    <td className='color-dark font12px'><strong>Growth Rate</strong> - The faster you're growing, the larger future revenues should be.</td>
                                </tr>
                                <tr>
                                    <td className='bg-color-03 text-white font12px text-center' style={{width: 50, height: 50}}>5</td>
                                    <td className='color-dark font12px'><strong>Gross Margin</strong> - The percent of revenue the company retains.  How do your profit margins compare to industry averages?</td>
                                </tr>
                                <tr>
                                    <td className='bg-color-03 text-white font12px text-center' style={{width: 50, height: 50}}>6</td>
                                    <td className='color-dark font12px'><strong>Scalability</strong> - Higher revenues create higher profit margin (Marginal Profitability Calculation).</td>
                                </tr>
                                <tr>
                                    <td className='bg-color-03 text-white font12px text-center' style={{width: 50, height: 50}}>7</td>
                                    <td className='color-dark font12px'><strong>Customer Retention</strong> - Investors are highly fixated on higher customer retention rates and lower attrition/churn rates than industry averages.  High cost of switching vendors.</td>
                                </tr>
                                <tr>
                                    <td className='bg-color-03 text-white font12px text-center' style={{width: 50, height: 50}}>8</td>
                                    <td className='color-dark font12px'><strong>Customer Base</strong> - The larger your active customer base, the more value investors will find in your business.</td>
                                </tr>
                                <tr>
                                    <td className='bg-color-03 text-white font12px text-center' style={{width: 50, height: 50}}>9</td>
                                    <td className='color-dark font12px'><strong>Customer Responsiveness</strong> - customers respond/buy to your communication.  This is achieved by having a well-developed "back end", a complete and well-organized customer database (active, inactive and prospective), and higher than average Customer Lifetime Value.</td>
                                </tr>
                                <tr>
                                    <td className='bg-color-03 text-white font12px text-center' style={{width: 50, height: 50}}>10</td>
                                    <td className='color-dark font12px'><strong>Systems & Operating Procedures</strong> - All of your operating procedures should be systematized, optimized and documented.</td>
                                </tr>
                                <tr>
                                    <td className='bg-color-03 text-white font12px text-center' style={{width: 50, height: 50}}>11</td>
                                    <td className='color-dark font12px'><strong>Marketing Processes</strong> - All of your customer acquisition and customer retention processes should be systematized, optimized and documented.</td>
                                </tr>
                                <tr>
                                    <td className='bg-color-03 text-white font12px text-center' style={{width: 50, height: 50}}>12</td>
                                    <td className='color-dark font12px'><strong>Resilience</strong> - Not being dependent on a small number of major accounts or vendors/suppliers/partners. Having good management below the owner in place.  Also, having a predictable business model makes it easier to predict future cash flows.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
  )
}
