import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state/index';
import  UserDropdown  from '../UserDropdown';
import { BarChartGraph } from '../graph/BarChartGraph';

export default function CustomerRetentionloopContent() {
    const dispatch = useDispatch();
    const { CustomerRetentionLoopData } = bindActionCreators(actionCreators, dispatch)

    const clientInputs = useSelector(state => state.clientInputs)
    const customerReactivation = useSelector(state => state.customerReactivation)
    const purchaseFrequency = useSelector(state => state.purchaseFrequency)
    const buyingLifeTime = useSelector(state => state.buyingLifeTime)
    const reducingAttrition = useSelector(state => state.reducingAttrition)
    const CustomerFinancialValues = useSelector(state => state.CustomerFinancialValues)

    const customerRetentionLoop = useSelector(state => state.customerRetentionLoop)

    const { clientInputsH41 } = clientInputs
    const { CustomerFinancialValuesD16, CustomerFinancialValuesD19 } = CustomerFinancialValues

    useEffect(() => {
        console.log('first');
        CustomerRetentionLoopData("CustomerRetentionLoopAllStats", {
            clientInputsH41,
            CustomerFinancialValuesD16,
            CustomerFinancialValuesD19,
            purchaseFrequency,
            customerReactivation,
            buyingLifeTime,
            reducingAttrition
        });
    }, []);

    const { customerRetentionLoopE10, customerRetentionLoopF10, customerRetentionLoopG10, customerRetentionLoopH10, customerRetentionLoopI10, customerRetentionLoopJ10, customerRetentionLoopE11, customerRetentionLoopF11, customerRetentionLoopG11, customerRetentionLoopH11, customerRetentionLoopI11, customerRetentionLoopJ11, customerRetentionLoopE12, customerRetentionLoopF12, customerRetentionLoopG12, customerRetentionLoopH12, customerRetentionLoopI12, customerRetentionLoopJ12, customerRetentionLoopE13, customerRetentionLoopF13, customerRetentionLoopG13, customerRetentionLoopH13, customerRetentionLoopI13, customerRetentionLoopJ13, customerRetentionLoopD14, customerRetentionLoopE14, customerRetentionLoopF14, customerRetentionLoopG14, customerRetentionLoopH14, customerRetentionLoopI14, customerRetentionLoopJ14, customerRetentionLoopE18, customerRetentionLoopF18, customerRetentionLoopG18, customerRetentionLoopH18, customerRetentionLoopI18, customerRetentionLoopJ18, customerRetentionLoopE19, customerRetentionLoopF19, customerRetentionLoopG19, customerRetentionLoopH19, customerRetentionLoopI19, customerRetentionLoopJ19, customerRetentionLoopE20, customerRetentionLoopF20, customerRetentionLoopG20, customerRetentionLoopH20, customerRetentionLoopI20, customerRetentionLoopJ20 } = customerRetentionLoop //object destructuring for CustomerFinancialValues

  return (
    <div className="content-body">
        <UserDropdown />
        {/* row */}
        <div className="container-fluid">
            <div className="head-title">
                <p className="mb-0"> Customer Retention, Loyalty &amp; Experience</p>
                <h2 className="mb-5"> Customer Retention Loop </h2>
            </div>
            <div className="row curtomer-row">
                <div className="col-12 col-md-6">
                <div className="image-customer">
                    <img src="../images/customer-retention/circle.png" className="img-fluid" alt="circle" />
                </div>
                </div>
                <div className="col-12 col-md-6">
                <div className="image-customer graph-bx">
                    <BarChartGraph dataInput={{
                        "Title"      : "",
                        "vTitle"     : "",
                        "hTitle"     : "",
                        "subTitle"   : "",
                        "ColorCodes" : ["#c2c7cd", "#8497b0", "#44546a"],
                        "GraphData"  : [
                            ["", "Profit", "Sales", "Valuation"],
                            ["Year 1", Number(customerRetentionLoopF19), Number(customerRetentionLoopF18), Number(customerRetentionLoopF20)],
                            ["Year 2", Number(customerRetentionLoopG19), Number(customerRetentionLoopG18), Number(customerRetentionLoopG20)],
                            ["Year 3", Number(customerRetentionLoopH19), Number(customerRetentionLoopH18), Number(customerRetentionLoopH20)],
                            ["Year 4", Number(customerRetentionLoopI19), Number(customerRetentionLoopI18), Number(customerRetentionLoopI20)],
                            ["Year 5", Number(customerRetentionLoopJ19), Number(customerRetentionLoopJ18), Number(customerRetentionLoopJ20)],
                        ]
                    }} />
                </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12 contentleft">
                <div className="card">
                    <div className="card-body pl-0">
                    {/* table 1 */}
                    <div className="table-responsive table-dta">
                        <table className="table mb-0 customer-table">
                        <thead>
                            <tr>
                            <th colSpan={1} className="bg-color5 text-white d-hidden " />
                            <th colSpan={1} className="bg-color1 d-hidden text-white text-center-c text-font-size-15 padding-10" />
                            <th colSpan={2} className="bg-color3 text-white text-center-c text-font-size-15 padding-10">Year 1 Gains</th>
                            <th colSpan={4} className="bg-color3 text-white text-center-c text-font-size-15 padding-10">Long-Term Gains</th>
                            </tr>
                            <tr>
                            <th>
                                <div className="customer-div">Customer Retention Loop Impact</div>
                            </th>
                            <th className="bg-color1 cs-font-size text-white text-center-c">Current</th>
                            <th className="bg-color1 cs-font-size text-white text-center-c">Your <br /> Projections</th>
                            <th className="bg-color1 cs-font-size text-white text-center-c">1% Quarterly <br /> Improvement</th>
                            <th className="bg-color1 cs-font-size text-white text-center-c">2 Years of 1% <br /> Improvements</th>
                            <th className="bg-color1 cs-font-size text-white text-center-c">3 Years of 1% <br /> Improvements</th>
                            <th className="bg-color1 cs-font-size text-white text-center-c">4 Years of 1% <br /> Improvements</th>
                            <th className="bg-color1 cs-font-size text-white text-center-c">5 Years of 1% <br /> Improvements</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <th className="bg-color2 text-font-size-15">Reduced Customer Attrition</th>
                            <td className="bg-color2" />
                            <td className="bg-color2 ms-text-right">{Number(customerRetentionLoopE10).toLocaleString('en-US')}</td>
                            <td className="bg-color2 ms-text-right">{Number(customerRetentionLoopF10).toLocaleString('en-US')}</td>
                            <td className="bg-color2 ms-text-right">{Number(customerRetentionLoopG10).toLocaleString('en-US')}</td>
                            <td className="bg-color2 ms-text-right">{Number(customerRetentionLoopH10).toLocaleString('en-US')}</td>
                            <td className="bg-color2 ms-text-right">{Number(customerRetentionLoopI10).toLocaleString('en-US')}</td>
                            <td className="bg-color2 ms-text-right">{Number(customerRetentionLoopJ10).toLocaleString('en-US')}</td>
                            </tr>
                            <tr>
                            <th className="bg-color2 text-font-size-15">Extending Buying Lifetime</th>
                            <td className="bg-color2" />
                            <td className="bg-color2 ms-text-right">{Number(customerRetentionLoopE11).toLocaleString('en-US')}</td>
                            <td className="bg-color2 ms-text-right">{Number(customerRetentionLoopF11).toLocaleString('en-US')}</td>
                            <td className="bg-color2 ms-text-right">{Number(customerRetentionLoopG11).toLocaleString('en-US')}</td>
                            <td className="bg-color2 ms-text-right">{Number(customerRetentionLoopH11).toLocaleString('en-US')}</td>
                            <td className="bg-color2 ms-text-right">{Number(customerRetentionLoopI11).toLocaleString('en-US')}</td>
                            <td className="bg-color2 ms-text-right">{Number(customerRetentionLoopJ11).toLocaleString('en-US')}</td>
                            </tr>
                            <tr>
                            <th className="bg-color2 text-font-size-15">Customer Reactivation</th>
                            <td className="bg-color2" />
                            <td className="bg-color2 ms-text-right">{Number(customerRetentionLoopE12).toLocaleString('en-US')}</td>
                            <td className="bg-color2 ms-text-right">{Number(customerRetentionLoopF12).toLocaleString('en-US')}</td>
                            <td className="bg-color2 ms-text-right">{Number(customerRetentionLoopG12).toLocaleString('en-US')}</td>
                            <td className="bg-color2 ms-text-right">{Number(customerRetentionLoopH12).toLocaleString('en-US')}</td>
                            <td className="bg-color2 ms-text-right">{Number(customerRetentionLoopI12).toLocaleString('en-US')}</td>
                            <td className="bg-color2 ms-text-right">{Number(customerRetentionLoopJ12).toLocaleString('en-US')}</td>
                            </tr>
                            <tr>
                            <th className="bg-color3 text-white"> - Retained Customers from Retention Activities</th>
                            <td className="bg-color1" />
                            <td className="bg-color3 text-white ms-text-right">{Number(customerRetentionLoopE13).toLocaleString('en-US')}</td>
                            <td className="bg-color3 text-white ms-text-right">{Number(customerRetentionLoopF13).toLocaleString('en-US')}</td>
                            <td className="bg-color3 text-white ms-text-right">{Number(customerRetentionLoopG13).toLocaleString('en-US')}</td>
                            <td className="bg-color3 text-white ms-text-right">{Number(customerRetentionLoopH13).toLocaleString('en-US')}</td>
                            <td className="bg-color3 text-white ms-text-right">{Number(customerRetentionLoopI13).toLocaleString('en-US')}</td>
                            <td className="bg-color3 text-white ms-text-right">{Number(customerRetentionLoopJ13).toLocaleString('en-US')}</td>
                            </tr>
                            <tr>
                            <th className="bg-color2 text-font-size-15">Purchase Frequency</th>
                            <td className="bg-color2 ms-text-right">{Number(customerRetentionLoopD14).toLocaleString('en-US')}</td>
                            <td className="bg-color2 ms-text-right">{Number(customerRetentionLoopE14).toLocaleString('en-US')}</td>
                            <td className="bg-color2 ms-text-right">{Number(customerRetentionLoopF14).toLocaleString('en-US')}</td>
                            <td className="bg-color2 ms-text-right">{Number(customerRetentionLoopG14).toLocaleString('en-US')}</td>
                            <td className="bg-color2 ms-text-right">{Number(customerRetentionLoopH14).toLocaleString('en-US')}</td>
                            <td className="bg-color2 ms-text-right">{Number(customerRetentionLoopI14).toLocaleString('en-US')}</td>
                            <td className="bg-color2 ms-text-right">{Number(customerRetentionLoopJ14).toLocaleString('en-US')}</td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    {/* table 2 */}
                    <div className="table-responsive table-dta">
                        <table className="table mb-0 mt-4 customer-table">
                        <thead>
                            <tr>
                            <th colSpan={2}>
                                <div className="customer-div">Financial Performance</div>
                            </th>
                            {/* <th colspan="1" className="bg-color1 text-white text-center-c text-font-size-15 padding-10"></th> */}
                            <th colSpan={2} className="bg-color3 text-white text-center-c text-font-size-15 padding-10">Year 1 Gains</th>
                            <th colSpan={4} className="bg-color3 text-white text-center-c text-font-size-15 padding-10">Long-Term Gains</th>
                            </tr>
                            <tr>
                            <th className="bg-color1 cs-font-size text-white">
                                New Customer Value Calculations
                            </th>
                            <th className="bg-color1 cs-font-size text-white text-center-c">Current</th>
                            <th className="bg-color1 cs-font-size text-white text-center-c">Your <br /> Projections</th>
                            <th className="bg-color1 cs-font-size text-white text-center-c">1% Quarterly <br /> Improvement</th>
                            <th className="bg-color1 cs-font-size text-white text-center-c">2 Years of 1% <br /> Improvements</th>
                            <th className="bg-color1 cs-font-size text-white text-center-c">3 Years of 1% <br /> Improvements</th>
                            <th className="bg-color1 cs-font-size text-white text-center-c">4 Years of 1% <br /> Improvements</th>
                            <th className="bg-color1 cs-font-size text-white text-center-c">5 Years of 1% <br /> Improvements</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <th className="bg-color2 text-font-size-15">Company Annual Sales</th>
                            <td className="bg-color1" />
                            <td className="bg-color2 ms-text-left">${Number(customerRetentionLoopE18).toLocaleString('en-US')}</td>
                            <td className="bg-color2 ms-text-left">${Number(customerRetentionLoopF18).toLocaleString('en-US')}</td>
                            <td className="bg-color2 ms-text-left">${Number(customerRetentionLoopG18).toLocaleString('en-US')}</td>
                            <td className="bg-color2 ms-text-left">${Number(customerRetentionLoopH18).toLocaleString('en-US')}</td>
                            <td className="bg-color2 ms-text-left">${Number(customerRetentionLoopI18).toLocaleString('en-US')}</td>
                            <td className="bg-color2 ms-text-left">${Number(customerRetentionLoopJ18).toLocaleString('en-US')}</td>
                            </tr>
                            <tr>
                            <th className="bg-color2 text-font-size-15">Company Annual Profit</th>
                            <td className="bg-color1" />
                            <td className="bg-color2 ms-text-left">${Number(customerRetentionLoopE19).toLocaleString('en-US')}</td>
                            <td className="bg-color2 ms-text-left">${Number(customerRetentionLoopF19).toLocaleString('en-US')}</td>
                            <td className="bg-color2 ms-text-left">${Number(customerRetentionLoopG19).toLocaleString('en-US')}</td>
                            <td className="bg-color2 ms-text-left">${Number(customerRetentionLoopH19).toLocaleString('en-US')}</td>
                            <td className="bg-color2 ms-text-left">${Number(customerRetentionLoopI19).toLocaleString('en-US')}</td>
                            <td className="bg-color2 ms-text-left">${Number(customerRetentionLoopJ19).toLocaleString('en-US')}</td>
                            </tr>
                            <tr>
                            <th className="bg-color2 text-font-size-15">Estimated Valuation Impact</th>
                            <td className="bg-color1" />
                            <td className="bg-color2 ms-text-left">${Number(customerRetentionLoopE20).toLocaleString('en-US')}</td>
                            <td className="bg-color2 ms-text-left">${Number(customerRetentionLoopF20).toLocaleString('en-US')}</td>
                            <td className="bg-color2 ms-text-left">${Number(customerRetentionLoopG20).toLocaleString('en-US')}</td>
                            <td className="bg-color2 ms-text-left">${Number(customerRetentionLoopH20).toLocaleString('en-US')}</td>
                            <td className="bg-color2 ms-text-left">${Number(customerRetentionLoopI20).toLocaleString('en-US')}</td>
                            <td className="bg-color2 ms-text-left">${Number(customerRetentionLoopJ20).toLocaleString('en-US')}</td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>                                          
                </div>
            </div>
        </div>
    </div>
  )
}
