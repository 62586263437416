const initialState = {
    referralsD12: 0,
    referralsD13: 0,
    referralsD17: 0,
    referralsD18: 0,
    referralsE17: 0,
    referralsE18: 0,
    referralsF18: 0,
    referralsG18: 0,
    referralsF17: 0,
    referralsG17: 0,
    referralsH18: 0,
    referralsI18: 0,
    referralsH17: 0,
    referralsI17: 0,
    referralsD22: 0,
    referralsE22: 0,
    referralsF22: 0,
    referralsG22: 0,
    referralsH22: 0,
    referralsI22: 0,
    referralsD23: 0,
    referralsE23: 0,
    referralsF23: 0,
    referralsG23: 0,
    referralsH23: 0,
    referralsI23: 0,
    referralsD25: 0,
    referralsE25: 0,
    referralsF25: 0,
    referralsG25: 0,
    referralsH25: 0,
    referralsI25: 0,
    referralsD26: 0,
    referralsE26: 0,
    referralsF26: 0,
    referralsG26: 0,
    referralsH26: 0,
    referralsI26: 0,
    referralsD28: 0,
    referralsE28: 0,
    referralsF28: 0,
    referralsG28: 0,
    referralsH28: 0,
    referralsI28: 0,
}
const reducer = (state = initialState, action) => {
    if (action.type === 'referralsD12') {
        return {
            ...state, //copying the original state
            referralsD12: (action.payload) ? parseFloat(action.payload) : 0
        }
    }
    else if (action.type === 'referralsD13') {
        return {
            ...state, //copying the original state
            referralsD13: (action.payload) ? parseFloat(action.payload) : 0
        }
    }
    else if (action.type === 'referralsImproving') {
        let referralsD12 = action.payload.clientInputsH27;
        let CustomerFinancialValuesD24 = action.payload.CustomerFinancialValues.CustomerFinancialValuesD24;
        let CustomerFinancialValuesD25 = action.payload.CustomerFinancialValues.CustomerFinancialValuesD25;
        let CustomerFinancialValuesD26 = action.payload.CustomerFinancialValues.CustomerFinancialValuesD26;
        let CustomerFinancialValuesD27 = action.payload.CustomerFinancialValues.CustomerFinancialValuesD27;
        let clientInputsH41 = action.payload.clientInputsH41;
        
        let referralsD17 = (parseFloat(referralsD12) * parseFloat(state.referralsD13) / 100).toFixed(1)
        let referralsD18 = ((parseFloat(referralsD12) + parseFloat(referralsD17))).toFixed(1)
        let referralsE17 = ((parseFloat(referralsD12) * parseFloat(4) / 100)).toFixed(1)
        let referralsE18 = ((parseFloat(referralsD12) + parseFloat(referralsE17))).toFixed(1)
        let referralsF18 = (parseFloat(referralsE18 * 4 / 100) + parseFloat(referralsE18)).toFixed(1)
        let referralsG18 = (parseFloat(referralsF18 * 4 / 100) + parseFloat(referralsF18)).toFixed(1)
        let referralsF17 = (parseFloat(referralsF18) - parseFloat(referralsD12)).toFixed(1)
        let referralsG17 = (parseFloat(referralsG18) - parseFloat(referralsD12)).toFixed(1)
        let referralsH18 = (parseFloat(referralsG18 * 4 / 100) + parseFloat(referralsG18)).toFixed(1)
        let referralsI18 = (parseFloat(referralsH18 * 4 / 100) + parseFloat(referralsH18)).toFixed(1)
        let referralsH17 = (parseFloat(referralsH18) - parseFloat(referralsD12)).toFixed(1)
        let referralsI17 = (parseFloat(referralsI18) - parseFloat(referralsD12)).toFixed(1)

        let referralsD22 = (parseFloat(referralsD17) * parseFloat(CustomerFinancialValuesD24)).toFixed(2)
        let referralsE22 = (parseFloat(referralsE17) * parseFloat(CustomerFinancialValuesD24)).toFixed(2)
        let referralsF22 = (parseFloat(referralsF17) * parseFloat(CustomerFinancialValuesD24)).toFixed(2)
        let referralsH22 = (parseFloat(referralsH17) * parseFloat(CustomerFinancialValuesD24)).toFixed(2)
        let referralsG22 = (parseFloat(referralsG17) * parseFloat(CustomerFinancialValuesD24)).toFixed(2)
        let referralsI22 = (parseFloat(referralsI17) * parseFloat(CustomerFinancialValuesD24)).toFixed(2)

        let referralsD23 = (parseFloat(referralsD17) * parseFloat(CustomerFinancialValuesD25)).toFixed(2)
        let referralsE23 = (parseFloat(referralsE17) * parseFloat(CustomerFinancialValuesD25)).toFixed(2)
        let referralsF23 = (parseFloat(referralsF17) * parseFloat(CustomerFinancialValuesD25)).toFixed(2)
        let referralsH23 = (parseFloat(referralsH17) * parseFloat(CustomerFinancialValuesD25)).toFixed(2)
        let referralsG23 = (parseFloat(referralsG17) * parseFloat(CustomerFinancialValuesD25)).toFixed(2)
        let referralsI23 = (parseFloat(referralsI17) * parseFloat(CustomerFinancialValuesD25)).toFixed(2)

        let referralsD25 = (parseFloat(referralsD17) * parseFloat(CustomerFinancialValuesD26)).toFixed(2)
        let referralsE25 = (parseFloat(referralsE17) * parseFloat(CustomerFinancialValuesD26)).toFixed(2)
        let referralsF25 = (parseFloat(referralsF17) * parseFloat(CustomerFinancialValuesD26)).toFixed(2)
        let referralsH25 = (parseFloat(referralsH17) * parseFloat(CustomerFinancialValuesD26)).toFixed(2)
        let referralsG25 = (parseFloat(referralsG17) * parseFloat(CustomerFinancialValuesD26)).toFixed(2)
        let referralsI25 = (parseFloat(referralsI17) * parseFloat(CustomerFinancialValuesD26)).toFixed(2)

        let referralsD26 = (parseFloat(referralsD17) * parseFloat(CustomerFinancialValuesD27)).toFixed(2)
        let referralsE26 = (parseFloat(referralsE17) * parseFloat(CustomerFinancialValuesD27)).toFixed(2)
        let referralsF26 = (parseFloat(referralsF17) * parseFloat(CustomerFinancialValuesD27)).toFixed(2)
        let referralsH26 = (parseFloat(referralsH17) * parseFloat(CustomerFinancialValuesD27)).toFixed(2)
        let referralsG26 = (parseFloat(referralsG17) * parseFloat(CustomerFinancialValuesD27)).toFixed(2)
        let referralsI26 = (parseFloat(referralsI17) * parseFloat(CustomerFinancialValuesD27)).toFixed(2)

        let referralsD28 = (parseFloat(referralsD23) * parseFloat(clientInputsH41)).toFixed(2)
        let referralsE28 = (parseFloat(referralsE23) * parseFloat(clientInputsH41)).toFixed(2)
        let referralsF28 = (parseFloat(referralsF23) * parseFloat(clientInputsH41)).toFixed(2)
        let referralsH28 = (parseFloat(referralsH23) * parseFloat(clientInputsH41)).toFixed(2)
        let referralsG28 = (parseFloat(referralsG23) * parseFloat(clientInputsH41)).toFixed(2)
        let referralsI28 = (parseFloat(referralsI23) * parseFloat(clientInputsH41)).toFixed(2)


        return {
            ...state, //copying the original state
            referralsD17 : referralsD17 != 'NaN' ? referralsD17 : 0,
            referralsD18 : referralsD18 != 'NaN' ? referralsD18 : 0,
            referralsE17 : referralsE17 != 'NaN' ? referralsE17 : 0,
            referralsE18 : referralsE18 != 'NaN' ? referralsE18 : 0,
            referralsF18 : referralsF18 != 'NaN' ? referralsF18 : 0,
            referralsG18 : referralsG18 != 'NaN' ? referralsG18 : 0,
            referralsF17 : referralsF17 != 'NaN' ? referralsF17 : 0,
            referralsG17 : referralsG17 != 'NaN' ? referralsG17 : 0,
            referralsH18 : referralsH18 != 'NaN' ? referralsH18 : 0,
            referralsI18 : referralsI18 != 'NaN' ? referralsI18 : 0,
            referralsH17 : referralsH17 != 'NaN' ? referralsH17 : 0,
            referralsI17 : referralsI17 != 'NaN' ? referralsI17 : 0,
            referralsD22 : referralsD22 != 'NaN' ? referralsD22 : 0,
            referralsE22 : referralsE22 != 'NaN' ? referralsE22 : 0,
            referralsF22 : referralsF22 != 'NaN' ? referralsF22 : 0,
            referralsG22 : referralsG22 != 'NaN' ? referralsG22 : 0,
            referralsH22 : referralsH22 != 'NaN' ? referralsH22 : 0,
            referralsI22 : referralsI22 != 'NaN' ? referralsI22 : 0,
            referralsD23 : referralsD23 != 'NaN' ? referralsD23 : 0,
            referralsE23 : referralsE23 != 'NaN' ? referralsE23 : 0,
            referralsF23 : referralsF23 != 'NaN' ? referralsF23 : 0,
            referralsG23 : referralsG23 != 'NaN' ? referralsG23 : 0,
            referralsH23 : referralsH23 != 'NaN' ? referralsH23 : 0,
            referralsI23 : referralsI23 != 'NaN' ? referralsI23 : 0,
            referralsD25 : referralsD25 != 'NaN' ? referralsD25 : 0,
            referralsE25 : referralsE25 != 'NaN' ? referralsE25 : 0,
            referralsF25 : referralsF25 != 'NaN' ? referralsF25 : 0,
            referralsG25 : referralsG25 != 'NaN' ? referralsG25 : 0,
            referralsH25 : referralsH25 != 'NaN' ? referralsH25 : 0,
            referralsI25 : referralsI25 != 'NaN' ? referralsI25 : 0,
            referralsD26 : referralsD26 != 'NaN' ? referralsD26 : 0,
            referralsE26 : referralsE26 != 'NaN' ? referralsE26 : 0,
            referralsF26 : referralsF26 != 'NaN' ? referralsF26 : 0,
            referralsG26 : referralsG26 != 'NaN' ? referralsG26 : 0,
            referralsH26 : referralsH26 != 'NaN' ? referralsH26 : 0,
            referralsI26 : referralsI26 != 'NaN' ? referralsI26 : 0,
            referralsD28 : referralsD28 != 'NaN' ? referralsD28 : 0,
            referralsE28 : referralsE28 != 'NaN' ? referralsE28 : 0,
            referralsF28 : referralsF28 != 'NaN' ? referralsF28 : 0,
            referralsG28 : referralsG28 != 'NaN' ? referralsG28 : 0,
            referralsH28 : referralsH28 != 'NaN' ? referralsH28 : 0,
            referralsI28 : referralsI28 != 'NaN' ? referralsI28 : 0,
        }
        
    }
    else {
        return state
    }
}

export default reducer