import Chart from "react-google-charts"

export default function CompoundEffectGraph({data}) {

    const options = {
        // width: '100%',
        // chartArea: { width: "60%" },
        width: '80%',
        height: '350',
        chartArea:{
            right:5,
            width: '80%',
            height: '250',
        },
        vAxis: {
            format: 'currency'
        },
        hAxis: {
            textPosition: 'none'
        },
        annotations: {
            textStyle : {
                color : "#000",
                fontSize: 24,
                bold: true,
            }
        },
        legend: 'none',
        bar: {
            groupWidth: '90%',
        },
        colors: ['#72BDA3', '#075F2']
    }

    return (
        <div style={{position: 'relative'}}>
            <div style={{display: 'flex', width: 150, justifyContent: 'space-between', position: 'absolute', zIndex: 9, top: '1%', left: '45%', transform: 'translate(-45%, -10%)'}}>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><div style={{width: 15, height: 15, backgroundColor:'#0075F2', marginRight: 5}}></div> <span style={{fontSize: 14}}>Sales</span></div>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><div style={{width: 15, height: 15, backgroundColor:'#72BDA3', marginRight: 5}}></div> <span style={{fontSize: 14}}>Profit</span></div>
            </div>
            <Chart
                chartType="ColumnChart"
                width="100%"
                height='400'
                data={data}
                options={options}
            />
        </div>
    )
}