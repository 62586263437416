import React from 'react'
import  UserDropdown  from '../UserDropdown';

export default function SalesElevationPlanContent() {
  return (
    <div className="content-body">
    <UserDropdown />
    
        {/* row */}
        <div className="container-fluid">
            <div className="head-title">
                <h2>Sales Elevation Plan</h2>	            					
            </div> 
            <div className="row">
                <div className=" col-md-6 contentleft">                      
                <div className="card h-auto">
                    <div className="card-body">    
                    <a href="javascript:void(0);" className="leftbox"><i className="fas fa-redo" /></a>                           
                    <div className="d-flex justify-content-between">                                   
                        <p>Project <br />Cost to Date</p>
                        <p>$ 20000.00</p>                                   
                    </div>
                    </div>
                </div>
                <div className="card h-auto redleft">
                    <div className="card-body">  
                    <a href="javascript:void(0);" className="leftbox"><i className="fas fa-sync" /></a>                                
                    <div className="d-flex justify-content-between">                                     
                        <p>Actual <br />Cost To Date</p>
                        <p>$2100.00</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>                                	
            <div className="card">
                <div className="card-body pl-0 fivestep">                                            
                <table className="table table-responsive">
                    <thead>
                    <tr className="thead-primary">
                        <th style={{width: '30%'}}>Compaign</th>
                        <th>Target Audience</th>
                        <th>Goals</th>
                        <th>Response</th>
                        <th>Notes</th>
                        <th>Weight</th>
                        <th>Project Cost</th>
                        <th />
                        <th>Actual Cost</th>
                        <th />
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className="bgradiusf" style={{width: '30%'}}>Sales Tactic, Activity or Category</td>
                        <td className="bgradiusf" />
                        <td className="bgradiusf" />
                        <td className="bgradiusf" />
                        <td className="bgradiusf" />
                        <td className="bgradiusf" />
                        <td className="bgradiusf">Subtotal</td>
                        <td className="bgradiusf">$ 500.00</td>
                        <td className="bgradiusf">Subtotal</td>
                        <td className="bgradiusf">$ 600.00</td>
                    </tr>
                    <tr>
                        <td style={{width: '30%'}} />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td>$ 500.00</td>
                        <td />
                        <td>$600.00</td>
                        <td />
                    </tr>
                    <tr>
                        <td style={{width: '30%'}} />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                    </tr>
                    <tr>
                        <td style={{width: '30%'}} />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                    </tr>  
                    <tr>
                        <td className="bgradiusf" style={{width: '30%'}}>Sales Tactic, Activity or Category</td>
                        <td className="bgradiusf" />
                        <td className="bgradiusf" />
                        <td className="bgradiusf" />
                        <td className="bgradiusf" />
                        <td className="bgradiusf" />
                        <td className="bgradiusf">Subtotal</td>
                        <td className="bgradiusf">$ 1500.00</td>
                        <td className="bgradiusf">Subtotal</td>
                        <td className="bgradiusf">$ 600.00</td>
                    </tr>
                    <tr>
                        <td style={{width: '30%'}} />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td>$ 500.00</td>
                        <td />
                        <td>$ 500.00</td>
                        <td />
                    </tr>
                    <tr>
                        <td style={{width: '30%'}} />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td>$ 500.00</td>
                        <td />
                        <td>$ 400.00</td>
                        <td />
                    </tr>
                    <tr>
                        <td style={{width: '30%'}} />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td>$ 500.00</td>
                        <td />
                        <td>$ 600.00</td>
                        <td />
                    </tr>    
                    <tr>
                        <td style={{width: '30%'}} />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                    </tr>  
                    <tr>
                        <td className="bgradiusf" style={{width: '30%'}}>Sales Tactic, Activity or Category</td>
                        <td className="bgradiusf" />
                        <td className="bgradiusf" />
                        <td className="bgradiusf" />
                        <td className="bgradiusf" />
                        <td className="bgradiusf" />
                        <td className="bgradiusf">Subtotal</td>
                        <td className="bgradiusf">$ -</td>
                        <td className="bgradiusf">Subtotal</td>
                        <td className="bgradiusf">$ -</td>
                    </tr>
                    <tr>
                        <td style={{width: '30%'}} />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                    </tr>
                    <tr>
                        <td style={{width: '30%'}} />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                    </tr>
                    <tr>
                        <td style={{width: '30%'}} />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                    </tr>    
                    <tr>
                        <td style={{width: '30%'}} />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                    </tr>  
                    <tr>
                        <td style={{width: '30%'}} />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                    </tr> 
                    <tr>
                        <td style={{width: '30%'}} />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                    </tr> 
                    <tr>
                        <td style={{width: '30%'}} />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                    </tr>
                    <tr>
                        <td className="bgradiusf" style={{width: '30%'}}>Sales Tactic, Activity or Category</td>
                        <td className="bgradiusf" />
                        <td className="bgradiusf" />
                        <td className="bgradiusf" />
                        <td className="bgradiusf" />
                        <td className="bgradiusf" />
                        <td className="bgradiusf">Subtotal</td>
                        <td className="bgradiusf">$ -</td>
                        <td className="bgradiusf">Subtotal</td>
                        <td className="bgradiusf">$ -</td>
                    </tr>
                    <tr>
                        <td style={{width: '30%'}} />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                    </tr>
                    <tr>
                        <td style={{width: '30%'}} />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                    </tr>
                    <tr>
                        <td style={{width: '30%'}} />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                    </tr>    
                    <tr>
                        <td style={{width: '30%'}} />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                    </tr>                                                
                    </tbody>
                </table>
                </div>
            </div>
            <div className="card">
                <div className="card-body pl-0">
                <div className="table-responsive">
                    <table className="table">
                    <thead>
                        <tr className="thead-primary">
                        <th colSpan={15} style={{textAlign: 'center'}}>Q1</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td colSpan={5} style={{textAlign: 'center'}}>January</td>
                        <td colSpan={5} style={{textAlign: 'center'}}>February</td>
                        <td colSpan={5} style={{textAlign: 'center'}}>March</td>
                        </tr>
                        <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        </tr>
                        <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        </tr>
                        <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        </tr>
                        <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        </tr>
                        <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        </tr>
                        <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        </tr>
                        <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body pl-0">
                <div className="table-responsive">
                    <table className="table">
                    <thead>
                        <tr className="thead-primary">
                        <th colSpan={15} style={{textAlign: 'center'}}>Q2</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td colSpan={5} style={{textAlign: 'center'}}>April</td>
                        <td colSpan={5} style={{textAlign: 'center'}}>May</td>
                        <td colSpan={5} style={{textAlign: 'center'}}>June</td>
                        </tr>
                        <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        </tr>
                        <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        </tr>
                        <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        </tr>
                        <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        </tr>
                        <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        </tr>
                        <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        </tr>
                        <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body pl-0">
                <div className="table-responsive">
                    <table className="table">
                    <thead>
                        <tr className="thead-primary">
                        <th colSpan={15} style={{textAlign: 'center'}}>Q3</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td colSpan={5} style={{textAlign: 'center'}}>July</td>
                        <td colSpan={5} style={{textAlign: 'center'}}>August</td>
                        <td colSpan={5} style={{textAlign: 'center'}}>September</td>
                        </tr>
                        <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        </tr>
                        <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        </tr>
                        <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        </tr>
                        <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        </tr>
                        <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        </tr>
                        <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        </tr>
                        <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body pl-0">
                <div className="table-responsive">
                    <table className="table">
                    <thead>
                        <tr className="thead-primary">
                        <th colSpan={15} style={{textAlign: 'center'}}>Q4</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td colSpan={5} style={{textAlign: 'center'}}>October</td>
                        <td colSpan={5} style={{textAlign: 'center'}}>November</td>
                        <td colSpan={5} style={{textAlign: 'center'}}>Dicember</td>
                        </tr>
                        <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        </tr>
                        <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        </tr>
                        <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        </tr>
                        <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        </tr>
                        <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        </tr>
                        <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        </tr>
                        <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            </div>

    </div>
  )
}
