import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state/index';
import { Link } from "react-router-dom";
import  UserDropdown  from '../UserDropdown';

export default function CustomerFinancialValueContent() {

    const dispatch = useDispatch();
    const { CustomerFinancialValuesData } = bindActionCreators(actionCreators, dispatch)

    const clientInputs = useSelector(state => state.clientInputs)
    const averageTransactionValue = useSelector(state => state.averageTransactionValue)
    const purchaseFrequency = useSelector(state => state.purchaseFrequency)
    const buyingLifeTime = useSelector(state => state.buyingLifeTime)
    const profitMargin = useSelector(state => state.profitMargin)
    const impactof120GrowthOS = useSelector(state => state.impactof120GrowthOS)

    const CustomerFinancialValues = useSelector(state => state.CustomerFinancialValues)

    const { clientInputsH16, clientInputsH19, clientInputsH30, clientInputsH33, clientInputsH36 } = clientInputs
    const { impactof120GrowthOSG27, impactof120GrowthOSH27, impactof120GrowthOSI27, impactof120GrowthOSJ27, impactof120GrowthOSK27 } = impactof120GrowthOS

    useEffect(() => {
        // console.log('first');
       CustomerFinancialValuesData("CustomerFinancialValuesAllStats", {
            clientInputsH16,
            clientInputsH19,
            clientInputsH30,
            clientInputsH33,
            clientInputsH36,
            averageTransactionValue,
            purchaseFrequency,
            buyingLifeTime,
            profitMargin,
            impactof120GrowthOSG27,
            impactof120GrowthOSH27,
            impactof120GrowthOSI27,
            impactof120GrowthOSJ27,
            impactof120GrowthOSK27
        });
    }, []);

    const { CustomerFinancialValuesD16, CustomerFinancialValuesE16, CustomerFinancialValuesF16,CustomerFinancialValuesG16, CustomerFinancialValuesH16, CustomerFinancialValuesI16, CustomerFinancialValuesD17, CustomerFinancialValuesE17, CustomerFinancialValuesF17, CustomerFinancialValuesG17, CustomerFinancialValuesH17, CustomerFinancialValuesI17, CustomerFinancialValuesD18, CustomerFinancialValuesE18, CustomerFinancialValuesF18,CustomerFinancialValuesG18, CustomerFinancialValuesH18, CustomerFinancialValuesI18, CustomerFinancialValuesD19, CustomerFinancialValuesE19, CustomerFinancialValuesF19, CustomerFinancialValuesG19, CustomerFinancialValuesH19, CustomerFinancialValuesI19, CustomerFinancialValuesD20, CustomerFinancialValuesE20, CustomerFinancialValuesF20,CustomerFinancialValuesG20, CustomerFinancialValuesH20, CustomerFinancialValuesI20, CustomerFinancialValuesD24, CustomerFinancialValuesE24, CustomerFinancialValuesF24, CustomerFinancialValuesG24, CustomerFinancialValuesH24, CustomerFinancialValuesI24, CustomerFinancialValuesD25, CustomerFinancialValuesE25, CustomerFinancialValuesF25, CustomerFinancialValuesG25, CustomerFinancialValuesH25, CustomerFinancialValuesI25, CustomerFinancialValuesD26, CustomerFinancialValuesE26, CustomerFinancialValuesF26, CustomerFinancialValuesG26, CustomerFinancialValuesH26, CustomerFinancialValuesI26, CustomerFinancialValuesD27, CustomerFinancialValuesE27, CustomerFinancialValuesF27, CustomerFinancialValuesG27, CustomerFinancialValuesH27, CustomerFinancialValuesI27 } = CustomerFinancialValues //object destructuring for CustomerFinancialValues

    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
            {/* row */}
            <div className="container-fluid">
                <div className="head-title mb-5">
                    <h3 className='preheading'>Diagnose</h3>
                    <h1 className='mainheading mb-1'>Customer Financial Values</h1>	
                </div>
                <div className="row graph-row" style={{maxWidth: 1200}}>
                    <div className="col-12 col-md-6 col-lg-3 mb-5">
                        <div className="annual-customer bg-color-blue">
                            <p className='font12px'>Annual Customer Value -<br/>Sales  (ACV-S)</p>
                            <div className="annual-img-bx">
                                <img src="../images/annualImages/annualImg_01.png" alt="annualImage" className='annual-image-d' />
                                <h3 className='font20px'> {Number(CustomerFinancialValuesD24).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} </h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3 mb-5">
                        <div className="annual-customer bg-color-teal">
                            <p className='font12px'>Annual Customer Value -<br/>Profit  (ACV-P)</p>
                            <div className="annual-img-bx">
                                <img src="../images/annualImages/annualImg_02.png" alt="annualImage2" className='annual-image-d' />
                                <h3 className='font20px'>  {Number(CustomerFinancialValuesD25).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} </h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3 mb-5">
                        <div className="annual-customer bg-color-blue">
                            <p className='font12px'>Lifetime Customer Value -<br/>Sales  (LCV-S)</p>
                            <div className="annual-img-bx">
                                <img src="../images/annualImages/annualImg_03.png" alt="annualImage3" className='annual-image-d' />
                                <h3 className='font20px'>  {Number(CustomerFinancialValuesD26).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} </h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3 mb-5">
                        <div className="annual-customer bg-color-teal">
                            <p className='font12px'>Lifetime Customer Value -<br/>Profit  (LCV-P)</p>
                            <div className="annual-img-bx">
                                <img src="../images/annualImages/annualImg_04.png" alt="annualImage4" className='annual-image-d' />
                                <h3 className='font20px'>  {Number(CustomerFinancialValuesD27).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="annual-customer-table">
                    <div className="annual-customer-table-inner">
                        <div className="table-responsive mb-4">
                            {/* annual customer table 01 */}
                            <table className="table responsive-table" style={{width: 1550, marginBottom: 0}}>
                                <thead>
                                    <tr>
                                        <td className='black-bg text-white text-ms-left font18px' style={{width: 350}}>
                                        About Your Business
                                        </td>
                                        <td className='black-bg text-white text-center font14px' style={{width: 200}}>Current Customer <br /> Values</td>
                                        <td className='black-bg text-white text-center font14px' style={{width: 200}}>1 Year of 1%  Quarterly<br/>Improvements</td>
                                        <td className='black-bg text-white text-center font14px' style={{width: 200}}>2 Years of 1%  Quarterly<br/>Improvements</td>
                                        <td className='black-bg text-white text-center font14px' style={{width: 200}}>3 Years of 1%  Quarterly<br/>Improvements</td>
                                        <td className='black-bg text-white text-center font14px' style={{width: 200}}>4 Years of 1%  Quarterly<br/>Improvements</td>
                                        <td className='black-bg text-white text-center font14px' style={{width: 200}}>5 Years of 1%  Quarterly<br/>Improvements</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row" className='bg-color-light-blue'>
                                            <p className='font12px m-0 color-black'>Average Transaction Value</p>
                                        </th>
                                        <td className='bg-color-blue-gray'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px text-white"> <strong>$</strong> </div>
                                                <div className="annual-dl-number font12px text-white"><strong>{Number(CustomerFinancialValuesD16).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</strong></div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px color-black"> $ </div>
                                                <div className="annual-dl-number font12px color-black">{Number(CustomerFinancialValuesE16).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px color-black"> $ </div>
                                                <div className="annual-dl-number font12px color-black">{Number(CustomerFinancialValuesF16).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px color-black"> $ </div>
                                                <div className="annual-dl-number font12px color-black">{Number(CustomerFinancialValuesG16).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px color-black"> $ </div>
                                                <div className="annual-dl-number font12px color-black">{Number(CustomerFinancialValuesH16).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px color-black"> $ </div>
                                                <div className="annual-dl-number font12px color-black">{Number(CustomerFinancialValuesI16).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" className='bg-color-light-blue'>
                                            <p className='font12px m-0 color-black'>Annual Purchase Frequency</p>
                                        </th>
                                        <td className='bg-color-blue-gray'>
                                            <div className="annual-dollor-sign">
                                                <div className="only-number font12px text-white">
                                                    <strong>{Number(CustomerFinancialValuesD17).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong>
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="only-number font12px color-black">
                                                    {Number(CustomerFinancialValuesE17).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="only-number font12px color-black">
                                                    {Number(CustomerFinancialValuesF17).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="only-number font12px color-black">
                                                    {Number(CustomerFinancialValuesG17).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="only-number font12px color-black">
                                                    {Number(CustomerFinancialValuesH17).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="only-number font12px color-black">
                                                    {Number(CustomerFinancialValuesI17).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" className='bg-color-light-blue'>
                                            <p className='font12px m-0 color-black'>Average Customer Buying Lifetime</p>
                                        </th>
                                        <td className='bg-color-blue-gray'>
                                            <div className="annual-dollor-sign">
                                                <div className="only-number font12px text-white">
                                                    <strong>{Number(CustomerFinancialValuesD18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong>
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="only-number font12px color-black">
                                                    {Number(CustomerFinancialValuesE18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="only-number font12px color-black">
                                                    {Number(CustomerFinancialValuesF18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="only-number font12px color-black">
                                                    {Number(CustomerFinancialValuesG18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="only-number font12px color-black">
                                                    {Number(CustomerFinancialValuesH18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="only-number font12px color-black">
                                                    {Number(CustomerFinancialValuesI18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" className='bg-color-light-blue'>
                                            <p className='font12px m-0 color-black'>Gross Profit Margin</p>
                                        </th>
                                        <td className='bg-color-blue-gray'>
                                            <div className="annual-dollor-sign">
                                                <div className="only-number font12px text-white">
                                                    <strong>{Number(CustomerFinancialValuesD19).toLocaleString('en-US')} %</strong>
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="only-number font12px color-black">
                                                    {Number(CustomerFinancialValuesE19).toLocaleString('en-US')} %
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="only-number font12px color-black">
                                                    {Number(CustomerFinancialValuesF19).toLocaleString('en-US')} %
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="only-number font12px color-black">
                                                    {Number(CustomerFinancialValuesG19).toLocaleString('en-US')} %
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="only-number font12px color-black">
                                                    {Number(CustomerFinancialValuesH19).toLocaleString('en-US')} %
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="only-number font12px color-black">
                                                    {Number(CustomerFinancialValuesI19).toLocaleString('en-US')} %
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" className='bg-color-light-blue'>
                                            <p className='font12px m-0 color-black'>Number of Active Customers</p>
                                        </th>
                                        <td className='bg-color-blue-gray'>
                                            <div className="annual-dollor-sign">
                                                <div className="only-number font12px text-white">
                                                    <strong>{Number(CustomerFinancialValuesD20).toLocaleString('en-US')}</strong>
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="only-number font12px color-black">
                                                    {Number(CustomerFinancialValuesE20).toLocaleString('en-US')}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="only-number font12px color-black">
                                                    {Number(CustomerFinancialValuesF20).toLocaleString('en-US')}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="only-number font12px color-black">
                                                    {Number(CustomerFinancialValuesG20).toLocaleString('en-US')}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="only-number font12px color-black">
                                                    {Number(CustomerFinancialValuesH20).toLocaleString('en-US')}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="only-number font12px color-black">
                                                    {Number(CustomerFinancialValuesI20).toLocaleString('en-US')}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="table-responsive">
                            {/* annual customer table 02 */}
                            <table className="table responsive-table" style={{width: 1550, marginBottom: 0}}>
                                <thead>
                                <tr>
                                        <td className='black-bg text-white text-ms-left font18px' style={{width: 350}}>
                                        Customer Financial Values
                                        </td>
                                        <td className='black-bg text-white text-center font14px' style={{width: 200}}>Current Customer <br /> Values</td>
                                        <td className='black-bg text-white text-center font14px' style={{width: 200}}>1 Year of 1%  Quarterly<br/>Improvements</td>
                                        <td className='black-bg text-white text-center font14px' style={{width: 200}}>2 Years of 1%  Quarterly<br/>Improvements</td>
                                        <td className='black-bg text-white text-center font14px' style={{width: 200}}>3 Years of 1%  Quarterly<br/>Improvements</td>
                                        <td className='black-bg text-white text-center font14px' style={{width: 200}}>4 Years of 1%  Quarterly<br/>Improvements</td>
                                        <td className='black-bg text-white text-center font14px' style={{width: 200}}>5 Years of 1%  Quarterly<br/>Improvements</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row" className='bg-color-light-blue'>
                                            <p className='font12px m-0 color-black'>Annual Customer Value - Sales (ACV-S)</p>
                                        </th>
                                        <td className='bg-color-blue-gray'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px text-white"> <strong>$</strong> </div>
                                                <div className="annual-dl-number font12px text-white"><strong>{Number(CustomerFinancialValuesD24).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</strong></div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px color-black"> $ </div>
                                                <div className="annual-dl-number font12px color-black">{Number(CustomerFinancialValuesE24).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px color-black"> $ </div>
                                                <div className="annual-dl-number font12px color-black">{Number(CustomerFinancialValuesF24).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px color-black"> $ </div>
                                                <div className="annual-dl-number font12px color-black">{Number(CustomerFinancialValuesG24).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px color-black"> $ </div>
                                                <div className="annual-dl-number font12px color-black">{Number(CustomerFinancialValuesH24).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px color-black"> $ </div>
                                                <div className="annual-dl-number font12px color-black">{Number(CustomerFinancialValuesI24).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" className='bg-color-light-blue'>
                                            <p className='font12px m-0 color-black'>Annual Customer Value - Profit (ACV-P)</p>
                                        </th>
                                        <td className='bg-color-blue-gray'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px text-white"> <strong>$</strong> </div>
                                                <div className="annual-dl-number font12px text-white"><strong>{Number(CustomerFinancialValuesD25).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</strong></div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px color-black"> $ </div>
                                                <div className="annual-dl-number font12px color-black">{Number(CustomerFinancialValuesE25).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px color-black"> $ </div>
                                                <div className="annual-dl-number font12px color-black">{Number(CustomerFinancialValuesF25).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px color-black"> $ </div>
                                                <div className="annual-dl-number font12px color-black">{Number(CustomerFinancialValuesG25).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px color-black"> $ </div>
                                                <div className="annual-dl-number font12px color-black">{Number(CustomerFinancialValuesH25).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px color-black"> $ </div>
                                                <div className="annual-dl-number font12px color-black">{Number(CustomerFinancialValuesI25).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" className='bg-color-light-blue'>
                                            <p className='font12px m-0 color-black'>Lifetime Customer Value - Sales (LCV-S)</p>
                                        </th>
                                        <td className='bg-color-blue-gray'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px text-white"> <strong>$</strong> </div>
                                                <div className="annual-dl-number font12px text-white"><strong>{Number(CustomerFinancialValuesD26).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</strong></div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px color-black"> $ </div>
                                                <div className="annual-dl-number font12px color-black">{Number(CustomerFinancialValuesE26).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px color-black"> $ </div>
                                                <div className="annual-dl-number font12px color-black">{Number(CustomerFinancialValuesF26).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px color-black"> $ </div>
                                                <div className="annual-dl-number font12px color-black">{Number(CustomerFinancialValuesG26).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px color-black"> $ </div>
                                                <div className="annual-dl-number font12px color-black">{Number(CustomerFinancialValuesH26).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px color-black"> $ </div>
                                                <div className="annual-dl-number font12px color-black">{Number(CustomerFinancialValuesI26).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" className='bg-color-light-blue'>
                                            <p className='font12px m-0 color-black'>Lifetime Customer Value - Profit (LCV-P)</p>
                                        </th>
                                        <td className='bg-color-blue-gray'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px text-white"> <strong>$</strong> </div>
                                                <div className="annual-dl-number font12px text-white"><strong>{Number(CustomerFinancialValuesD27).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</strong></div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px color-black"> $ </div>
                                                <div className="annual-dl-number font12px color-black">{Number(CustomerFinancialValuesE27).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px color-black"> $ </div>
                                                <div className="annual-dl-number font12px color-black">{Number(CustomerFinancialValuesF27).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px color-black"> $ </div>
                                                <div className="annual-dl-number font12px color-black">{Number(CustomerFinancialValuesG27).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px color-black"> $ </div>
                                                <div className="annual-dl-number font12px color-black">{Number(CustomerFinancialValuesH27).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px color-black"> $ </div>
                                                <div className="annual-dl-number font12px color-black">{Number(CustomerFinancialValuesI27).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="nextInput">
                            <ul>
                                <li>
                                    <Link to="/home" className='next-link'> Next:  Home  
                                        <span className='icon-three'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                        </span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}