import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getSalesCallObjectives, modifySalesCallObjectives } from "../../state/features/SalesCallObjectivesSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import sum from "../../utils/sum";

export default function SalesCallObjectivesContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        SalesCallObjectivesD10 : '',
        SalesCallObjectivesE10 : 0,
        SalesCallObjectivesF10 : 0,
        SalesCallObjectivesG10 : 0,
        SalesCallObjectivesH10 : 0,
        SalesCallObjectivesI10 : 0,
        SalesCallObjectivesJ10 : 0,
        SalesCallObjectivesK10 : 0,
        SalesCallObjectivesL10 : 0,
        SalesCallObjectivesD11 : '',
        SalesCallObjectivesE11 : 0,
        SalesCallObjectivesF11 : 0,
        SalesCallObjectivesG11 : 0,
        SalesCallObjectivesH11 : 0,
        SalesCallObjectivesI11 : 0,
        SalesCallObjectivesJ11 : 0,
        SalesCallObjectivesK11 : 0,
        SalesCallObjectivesL11 : 0,
        SalesCallObjectivesD12 : '',
        SalesCallObjectivesE12 : 0,
        SalesCallObjectivesF12 : 0,
        SalesCallObjectivesG12 : 0,
        SalesCallObjectivesH12 : 0,
        SalesCallObjectivesI12 : 0,
        SalesCallObjectivesJ12 : 0,
        SalesCallObjectivesK12 : 0,
        SalesCallObjectivesL12 : 0,
        SalesCallObjectivesD13 : '',
        SalesCallObjectivesE13 : 0,
        SalesCallObjectivesF13 : 0,
        SalesCallObjectivesG13 : 0,
        SalesCallObjectivesH13 : 0,
        SalesCallObjectivesI13 : 0,
        SalesCallObjectivesJ13 : 0,
        SalesCallObjectivesK13 : 0,
        SalesCallObjectivesL13 : 0,
        SalesCallObjectivesD14 : '',
        SalesCallObjectivesE14 : 0,
        SalesCallObjectivesF14 : 0,
        SalesCallObjectivesG14 : 0,
        SalesCallObjectivesH14 : 0,
        SalesCallObjectivesI14 : 0,
        SalesCallObjectivesJ14 : 0,
        SalesCallObjectivesK14 : 0,
        SalesCallObjectivesL14 : 0,
        SalesCallObjectivesM10 : 0,
        SalesCallObjectivesM11 : 0,
        SalesCallObjectivesM12 : 0,
        SalesCallObjectivesM13 : 0,
        SalesCallObjectivesM14 : 0,
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getSalesCallObjectives({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.revenueGrowth)
            if(response!= undefined  && response.data.revenueGrowth){
                let revenueGrowth = response.data.revenueGrowth[0];
                console.log(revenueGrowth)
                delete revenueGrowth.id;
                delete revenueGrowth.user_id;
                delete revenueGrowth.created_at;
                delete revenueGrowth.updated_at;
                const newObj = { ...Inputs }
                for (const key in revenueGrowth) {
                    if (revenueGrowth.hasOwnProperty(key)) {
                        if(revenueGrowth[key])
                        newObj[key] = revenueGrowth[key]
                    }
                }
                newObj['SalesCallObjectivesM10'] = sum(newObj['SalesCallObjectivesE10'], newObj['SalesCallObjectivesF10'], newObj['SalesCallObjectivesG10'], newObj['SalesCallObjectivesH10'], newObj['SalesCallObjectivesI10'], newObj['SalesCallObjectivesJ10'], newObj['SalesCallObjectivesK10'], newObj['SalesCallObjectivesL10']).toFixed(2);
                newObj['SalesCallObjectivesM11'] = sum(newObj['SalesCallObjectivesE11'], newObj['SalesCallObjectivesF11'], newObj['SalesCallObjectivesG11'], newObj['SalesCallObjectivesH11'], newObj['SalesCallObjectivesI11'], newObj['SalesCallObjectivesJ11'], newObj['SalesCallObjectivesK11'], newObj['SalesCallObjectivesL11']).toFixed(2);
                newObj['SalesCallObjectivesM12'] = sum(newObj['SalesCallObjectivesE12'], newObj['SalesCallObjectivesF12'], newObj['SalesCallObjectivesG12'], newObj['SalesCallObjectivesH12'], newObj['SalesCallObjectivesI12'], newObj['SalesCallObjectivesJ12'], newObj['SalesCallObjectivesK12'], newObj['SalesCallObjectivesL12']).toFixed(2);
                newObj['SalesCallObjectivesM13'] = sum(newObj['SalesCallObjectivesE13'], newObj['SalesCallObjectivesF13'], newObj['SalesCallObjectivesG13'], newObj['SalesCallObjectivesH13'], newObj['SalesCallObjectivesI13'], newObj['SalesCallObjectivesJ13'], newObj['SalesCallObjectivesK13'], newObj['SalesCallObjectivesL13']).toFixed(2);
                newObj['SalesCallObjectivesM14'] = sum(newObj['SalesCallObjectivesE14'], newObj['SalesCallObjectivesF14'], newObj['SalesCallObjectivesG14'], newObj['SalesCallObjectivesH14'], newObj['SalesCallObjectivesI14'], newObj['SalesCallObjectivesJ14'], newObj['SalesCallObjectivesK14'], newObj['SalesCallObjectivesL14']).toFixed(2);
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                let thenum = event.target.id.match(/\d+/)[0];
                let thestr = event.target.id.replace(thenum,'');
                let overallScore = 0;
                if(thestr != 'SalesCallObjectivesD'){
                    if(thestr == 'SalesCallObjectivesE'){
                      event.target.value = event.target.value ? event.target.value : 0
                        overallScore = sum(event.target.value, Inputs['SalesCallObjectivesF'+thenum], Inputs['SalesCallObjectivesG'+thenum], Inputs['SalesCallObjectivesH'+thenum], Inputs['SalesCallObjectivesI'+thenum], Inputs['SalesCallObjectivesJ'+thenum], Inputs['SalesCallObjectivesK'+thenum], Inputs['SalesCallObjectivesL'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'SalesCallObjectivesF'){
                      event.target.value = event.target.value ? event.target.value : 0
                        overallScore = sum(event.target.value, Inputs['SalesCallObjectivesE'+thenum], Inputs['SalesCallObjectivesG'+thenum], Inputs['SalesCallObjectivesH'+thenum], Inputs['SalesCallObjectivesI'+thenum], Inputs['SalesCallObjectivesJ'+thenum], Inputs['SalesCallObjectivesK'+thenum], Inputs['SalesCallObjectivesL'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'SalesCallObjectivesG'){
                      event.target.value = event.target.value ? event.target.value : 0
                        overallScore = sum(event.target.value, Inputs['SalesCallObjectivesE'+thenum], Inputs['SalesCallObjectivesF'+thenum], Inputs['SalesCallObjectivesH'+thenum], Inputs['SalesCallObjectivesI'+thenum], Inputs['SalesCallObjectivesJ'+thenum], Inputs['SalesCallObjectivesK'+thenum], Inputs['SalesCallObjectivesL'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'SalesCallObjectivesH'){
                      event.target.value = event.target.value ? event.target.value : 0
                        overallScore = sum(event.target.value, Inputs['SalesCallObjectivesE'+thenum], Inputs['SalesCallObjectivesF'+thenum], Inputs['SalesCallObjectivesG'+thenum], Inputs['SalesCallObjectivesI'+thenum], Inputs['SalesCallObjectivesJ'+thenum], Inputs['SalesCallObjectivesK'+thenum], Inputs['SalesCallObjectivesL'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'SalesCallObjectivesI'){
                      event.target.value = event.target.value ? event.target.value : 0
                        overallScore = sum(event.target.value, Inputs['SalesCallObjectivesE'+thenum], Inputs['SalesCallObjectivesF'+thenum], Inputs['SalesCallObjectivesG'+thenum], Inputs['SalesCallObjectivesH'+thenum], Inputs['SalesCallObjectivesJ'+thenum], Inputs['SalesCallObjectivesK'+thenum], Inputs['SalesCallObjectivesL'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'SalesCallObjectivesJ'){
                      event.target.value = event.target.value ? event.target.value : 0
                        overallScore = sum(event.target.value, Inputs['SalesCallObjectivesE'+thenum], Inputs['SalesCallObjectivesF'+thenum], Inputs['SalesCallObjectivesG'+thenum], Inputs['SalesCallObjectivesH'+thenum], Inputs['SalesCallObjectivesI'+thenum], Inputs['SalesCallObjectivesK'+thenum], Inputs['SalesCallObjectivesL'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'SalesCallObjectivesK'){
                      event.target.value = event.target.value ? event.target.value : 0
                        overallScore = sum(event.target.value, Inputs['SalesCallObjectivesE'+thenum], Inputs['SalesCallObjectivesF'+thenum], Inputs['SalesCallObjectivesG'+thenum], Inputs['SalesCallObjectivesH'+thenum], Inputs['SalesCallObjectivesI'+thenum], Inputs['SalesCallObjectivesJ'+thenum], Inputs['SalesCallObjectivesL'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'SalesCallObjectivesL'){
                      event.target.value = event.target.value ? event.target.value : 0
                        overallScore = sum(event.target.value, Inputs['SalesCallObjectivesE'+thenum], Inputs['SalesCallObjectivesF'+thenum], Inputs['SalesCallObjectivesG'+thenum], Inputs['SalesCallObjectivesH'+thenum], Inputs['SalesCallObjectivesI'+thenum], Inputs['SalesCallObjectivesJ'+thenum], Inputs['SalesCallObjectivesK'+thenum]).toFixed(2);
                    }
                    
                    setInputs({ ...Inputs, ['SalesCallObjectivesM'+thenum] : parseFloat(overallScore) })
                }
               
                dispatch(modifySalesCallObjectives({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

  return (
    <div className="content-body">
        <UserDropdown />
        {/* row */}
        <div className="container-fluid">
            <div className="head-title">
                <p> Sales &amp; Sales Management </p>
                <h2 className="mb-5 mt-0">Sales Call Objectives</h2>
            </div>
            <Spinner />
            <div className="card">
                <div className="card-body pl-0">
                <div className="table-responsive table-dta">
                    <table className="table mb-0">
                    <tbody>
                        <tr>
                        <td className="bg-color1 text-white" colSpan={3} rowSpan={2}>
                            <div className="vendor-div-main" style={{width: 250}}>
                            <span className="text-center-c width-100 d-block font-size-20">Prospect/Client/Activity</span>
                            </div>
                        </td>
                        <td className="bg-color1 text-white text-center-c" colSpan={8}>Sales Objectives</td>
                        <td className="bg-color1 text-white text-center-c" colSpan={9}>
                            <img src="../images/optimization/worked.png" className="img-fluid" alt="worked" />
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color3 text-white">Make Immediate Sale</td>
                        <td className="bg-color3 text-white">Set Buying Criteria in Your Favor</td>
                        <td className="bg-color3 text-white">Differentiate; Out-position Competition</td>
                        <td className="bg-color3 text-white">Build a Sense of Urgency</td>
                        <td className="bg-color3 text-white">Introduce More Products &amp; Services</td>
                        <td className="bg-color3 text-white">Develop Trust, Credibility &amp; Influence</td>
                        <td className="bg-color3 text-white">Demonstrate Expertise</td>
                        <td className="bg-color3 text-white">Build TOMA &amp; Loyalty</td>
                        <td className="bg-color1 text-white">Score</td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5 text-center-c">1</td>
                        <td className="bg-color1 text-white padding-0" colSpan={2}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="SalesCallObjectivesD10"
                                    value={Inputs.SalesCallObjectivesD10}
                                    onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesD10: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                    placeholder="Prospect A" />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesE10" 
                                value={Inputs.SalesCallObjectivesE10} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesE10: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesF10" 
                                value={Inputs.SalesCallObjectivesF10} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesF10: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesG10" 
                                value={Inputs.SalesCallObjectivesG10} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesG10: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesH10" 
                                value={Inputs.SalesCallObjectivesH10} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesH10: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesI10" 
                                value={Inputs.SalesCallObjectivesI10} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesI10: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesJ10" 
                                value={Inputs.SalesCallObjectivesJ10} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesJ10: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesK10" 
                                value={Inputs.SalesCallObjectivesK10} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesK10: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesL10" 
                                value={Inputs.SalesCallObjectivesL10} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesL10: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color3 text-white text-center-c">{Number(Inputs.SalesCallObjectivesM10).toLocaleString('en-US')}</td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5 text-center-c">2</td>
                        <td className="bg-color1 text-white padding-0" colSpan={2}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="SalesCallObjectivesD11"
                                    value={Inputs.SalesCallObjectivesD11}
                                    onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesD11: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                    placeholder="Prospect B" />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesE11" 
                                value={Inputs.SalesCallObjectivesE11} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesE11: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesF11" 
                                value={Inputs.SalesCallObjectivesF11} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesF11: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesG11" 
                                value={Inputs.SalesCallObjectivesG11} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesG11: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesH11" 
                                value={Inputs.SalesCallObjectivesH11} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesH11: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesI11" 
                                value={Inputs.SalesCallObjectivesI11} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesI11: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesJ11" 
                                value={Inputs.SalesCallObjectivesJ11} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesJ11: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesK11" 
                                value={Inputs.SalesCallObjectivesK11} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesK11: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesL11" 
                                value={Inputs.SalesCallObjectivesL11} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesL11: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color3 text-white text-center-c">{Number(Inputs.SalesCallObjectivesM11).toLocaleString('en-US')}</td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5 text-center-c">3</td>
                        <td className="bg-color1 text-white padding-0" colSpan={2}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="SalesCallObjectivesD12"
                                    value={Inputs.SalesCallObjectivesD12}
                                    onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesD12: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                    placeholder="Prospect C" />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesE12" 
                                value={Inputs.SalesCallObjectivesE12} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesE12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesF12" 
                                value={Inputs.SalesCallObjectivesF12} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesF12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesG12" 
                                value={Inputs.SalesCallObjectivesG12} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesG12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesH12" 
                                value={Inputs.SalesCallObjectivesH12} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesH12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesI12" 
                                value={Inputs.SalesCallObjectivesI12} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesI12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesJ12" 
                                value={Inputs.SalesCallObjectivesJ12} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesJ12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesK12" 
                                value={Inputs.SalesCallObjectivesK12} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesK12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesL12" 
                                value={Inputs.SalesCallObjectivesL12} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesL12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color3 text-white text-center-c">{Number(Inputs.SalesCallObjectivesM12).toLocaleString('en-US')}</td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5 text-center-c">4</td>
                        <td className="bg-color1 text-white padding-0" colSpan={2}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="SalesCallObjectivesD13"
                                    value={Inputs.SalesCallObjectivesD13}
                                    onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesD13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                    placeholder="Prospect D" />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesE13" 
                                value={Inputs.SalesCallObjectivesE13} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesE13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesF13" 
                                value={Inputs.SalesCallObjectivesF13} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesF13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesG13" 
                                value={Inputs.SalesCallObjectivesG13} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesG13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesH13" 
                                value={Inputs.SalesCallObjectivesH13} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesH13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesI13" 
                                value={Inputs.SalesCallObjectivesI13} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesI13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesJ13" 
                                value={Inputs.SalesCallObjectivesJ13} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesJ13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesK13" 
                                value={Inputs.SalesCallObjectivesK13} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesK13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesL13" 
                                value={Inputs.SalesCallObjectivesL13} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesL13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color3 text-white text-center-c">{Number(Inputs.SalesCallObjectivesM13).toLocaleString('en-US')}</td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5 text-center-c">5</td>
                        <td className="bg-color1 text-white padding-0" colSpan={2}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="SalesCallObjectivesD14"
                                    value={Inputs.SalesCallObjectivesD14}
                                    onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesD14: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                    placeholder="Prospect E" />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesE14" 
                                value={Inputs.SalesCallObjectivesE14} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesE14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesF14" 
                                value={Inputs.SalesCallObjectivesF14} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesF14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesG14" 
                                value={Inputs.SalesCallObjectivesG14} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesG14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesH14" 
                                value={Inputs.SalesCallObjectivesH14} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesH14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesI14" 
                                value={Inputs.SalesCallObjectivesI14} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesI14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesJ14" 
                                value={Inputs.SalesCallObjectivesJ14} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesJ14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesK14" 
                                value={Inputs.SalesCallObjectivesK14} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesK14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="SalesCallObjectivesL14" 
                                value={Inputs.SalesCallObjectivesL14} 
                                onChange={(event) => setInputs({ ...Inputs, SalesCallObjectivesL14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        <td className="bg-color3 text-white text-center-c">{Number(Inputs.SalesCallObjectivesM14).toLocaleString('en-US')}</td>
                        </tr>
                    </tbody>
                    </table> 
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}
