import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import { getCustomerTransformation, modifyCustomerTransformation } from '../../state/features/CustomerTransformationSlice';

const CustomerTransformationContentNew = () => {

    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        CustomerTransformationD9: "",
        CustomerTransformationG9: "",
        CustomerTransformationD10: "",
        CustomerTransformationG10: "",
        CustomerTransformationD11: "",
        CustomerTransformationG11: "",
        CustomerTransformationD12: "",
        CustomerTransformationG12: "",
        CustomerTransformationD13: "",
        CustomerTransformationG13: "",
        CustomerTransformationD14: "",
        CustomerTransformationG14: "",
        CustomerTransformationD15: "",
        CustomerTransformationG15: "",
        CustomerTransformationD16: "",
        CustomerTransformationG16: "",
        CustomerTransformationD17: "",
        CustomerTransformationG17: "",
        CustomerTransformationD18: "",
        CustomerTransformationG18: "",
        CustomerTransformationC22: "",
        CustomerTransformationC23: "",
        CustomerTransformationC25: "",
        CustomerTransformationC26: "",
        CustomerTransformationC28: "",
        CustomerTransformationC29: "",
        CustomerTransformationC31: "",
        CustomerTransformationC32: "",
        CustomerTransformationC34: "",
        CustomerTransformationC35: "",
        CustomerTransformationC37: "",
        CustomerTransformationC38: "",
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getCustomerTransformation({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.growthPlanning)
            if(response!= undefined  && response.data.growthPlanning){
                let growthPlanning = response.data.growthPlanning[0];
                // console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key]
                    }
                }
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyCustomerTransformation({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                    // console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        //window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
            {/* row */}
            <div className="container-fluid">
                <div className="head-title mb-5">
                    <h3 className='preheading'>Tools - Value Creation</h3>
                    <h1 className='mainheading mb-1'>Customer Transformation</h1>
                </div>
                <div className="customer-transformation">
                    <div className="customer-transformation-inner">
                        <Spinner/>
                        <div className="table-responsive">
                            {/* customer transformation table 01 */}
                            <table className="table" style={{maxWidth: 1250}}>
                                <thead>
                                    <tr>
                                        {/* <th>1</th> */}
                                        <td className='black-bg text-white' colSpan={2}>
                                            <div className="before-your">
                                                <img src={window.location.origin+"/images/customer-transformation/customer_01.png"} />
                                                <p className='font18px'>Before Your Product or Service</p>
                                            </div>
                                        </td>
                                        <td className='hide-content'>hide</td>
                                        <td className='black-bg text-white' colSpan={2}>
                                            <div className="before-your">
                                                <img src={window.location.origin+"/images/customer-transformation/customer_02.png"} alt="customer02" />
                                                <p className='font18px'>After Your Product or Service</p>
                                            </div>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px customer-table-number' style={{width: 50}}>1</td>
                                        <td className='bg-color-light-blue padding-0' style={{width: 550}}>
                                            <div className="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                id="CustomerTransformationD9"
                                                value={Inputs.CustomerTransformationD9}
                                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationD9: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="" />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number' style={{width: 50}}>hide</td>
                                        <td className='black-bg text-white text-center font11px customer-table-number' style={{width: 50}}>1</td>
                                        <td className='bg-color-light-blue padding-0' style={{width: 550}}>
                                            <div className="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                id="CustomerTransformationG9"
                                                value={Inputs.CustomerTransformationG9}
                                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationG9: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="" />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px customer-table-number'>2</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                id="CustomerTransformationD10"
                                                value={Inputs.CustomerTransformationD10}
                                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationD10: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="" />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='black-bg text-white text-center font11px customer-table-number'>3</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                id="CustomerTransformationG10"
                                                value={Inputs.CustomerTransformationG10}
                                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationG10: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="" />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px customer-table-number'>3</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                id="CustomerTransformationD11"
                                                value={Inputs.CustomerTransformationD11}
                                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationD11: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="" />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='black-bg text-white text-center font11px customer-table-number'>3</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                id="CustomerTransformationG11"
                                                value={Inputs.CustomerTransformationG11}
                                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationG11: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="" />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px customer-table-number'>4</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                id="CustomerTransformationD12"
                                                value={Inputs.CustomerTransformationD12}
                                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationD12: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="" />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='black-bg text-white text-center font11px customer-table-number'>4</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                id="CustomerTransformationG12"
                                                value={Inputs.CustomerTransformationG12}
                                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationG12: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="" />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px customer-table-number'>5</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                id="CustomerTransformationD13"
                                                value={Inputs.CustomerTransformationD13}
                                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationD13: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="" />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='black-bg text-white text-center font11px customer-table-number'>5</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                id="CustomerTransformationG13"
                                                value={Inputs.CustomerTransformationG13}
                                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationG13: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="" />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px customer-table-number'>6</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                id="CustomerTransformationD14"
                                                value={Inputs.CustomerTransformationD14}
                                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationD14: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="" />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='black-bg text-white text-center font11px customer-table-number'>6</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                id="CustomerTransformationG14"
                                                value={Inputs.CustomerTransformationG14}
                                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationG14: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="" />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px customer-table-number'>7</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                id="CustomerTransformationD15"
                                                value={Inputs.CustomerTransformationD15}
                                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationD15: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="" />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='black-bg text-white text-center font11px customer-table-number'>7</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                id="CustomerTransformationG15"
                                                value={Inputs.CustomerTransformationG15}
                                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationG15: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="" />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px customer-table-number'>8</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                id="CustomerTransformationD16"
                                                value={Inputs.CustomerTransformationD16}
                                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationD16: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="" />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='black-bg text-white text-center font11px customer-table-number'>8</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                id="CustomerTransformationG16"
                                                value={Inputs.CustomerTransformationG16}
                                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationG16: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="" />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px customer-table-number'>9</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                id="CustomerTransformationD17"
                                                value={Inputs.CustomerTransformationD17}
                                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationD17: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="" />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='black-bg text-white text-center font11px customer-table-number'>9</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                id="CustomerTransformationG17"
                                                value={Inputs.CustomerTransformationG17}
                                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationG17: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="" />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px customer-table-number'>10</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                id="CustomerTransformationD18"
                                                value={Inputs.CustomerTransformationD18}
                                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationD18: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="" />
                                            </div>
                                        </td>
                                        <td className='hide-content customer-table-number'>hide</td>
                                        <td className='black-bg text-white text-center font11px customer-table-number'>10</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                id="CustomerTransformationG18"
                                                value={Inputs.CustomerTransformationG18}
                                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationG18: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="" />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {/* customer transformation table 01 */}
                        </div>

                        {/*  build Your customer table 01 */}
                        <h3 className="font26px fontExtraBold color-bolder mt-5">Build Your Customer Transformation Story</h3>
                        <div className="table-responsive">
                            <table className="table" style={{maxWidth: 1250}}>
                                {/* table 01 */}
                                <thead>
                                    <tr>
                                        {/* <th colSpan={5}>1</th> */}
                                        <th className='black-bg text-white'>
                                            <div className="why-whats">
                                                <img src="../images/customer-transformation/customer_03.png" alt="customer03" />
                                                <div style={{marginLeft: 20}}>
                                                    <span className='font20px'>Why - </span>
                                                    <span className='font12px'>What do you want the customer transformation story to accomplish?  How do you want the buyer to see things differently?</span>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                id="CustomerTransformationC22"
                                                value={Inputs.CustomerTransformationC22}
                                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationC22: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="" />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                id="CustomerTransformationC23"
                                                value={Inputs.CustomerTransformationC23}
                                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationC23: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="" />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                {/* table 01 */}

                                {/* table 02 */}
                                <thead>
                                    <tr>
                                        {/* <th>1</th> */}
                                        <th className='black-bg text-white'>
                                            <div className="why-whats">
                                                <img src="../images/customer-transformation/customer_04.png" alt="customer04" />
                                                <div style={{marginLeft: 20}}>
                                                    <span className='font20px'>Setting - </span>
                                                    <span className='font12px'>Pick a goal for a buyer, vertical, and capability. "Let me tell you about Jim, President of a manufacturer looking to reduce inventory costs."</span>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                id="CustomerTransformationC25"
                                                value={Inputs.CustomerTransformationC25}
                                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationC25: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="" />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                id="CustomerTransformationC26"
                                                value={Inputs.CustomerTransformationC26}
                                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationC26: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="" />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                {/* table 02 */}

                                {/* table 03 */}
                                <thead>
                                    <tr>
                                        {/* <th>1</th> */}
                                        <th className='black-bg text-white'>
                                            <div className="why-whats">
                                                <img src="../images/customer-transformation/customer_05.png" alt="customer05" />
                                                <div style={{marginLeft: 20}}>
                                                    <span className='font20px'>Complications - </span>
                                                    <span className='font12px'>Describe buyer's painful situation.  Use emotion, imagery, metaphor, analogy, and add a villian.</span>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                id="CustomerTransformationC28"
                                                value={Inputs.CustomerTransformationC28}
                                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationC28: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="" />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                id="CustomerTransformationC29"
                                                value={Inputs.CustomerTransformationC29}
                                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationC29: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="" />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                {/* table 03 */}

                                {/* table 04 */}
                                <thead>
                                    <tr>
                                        {/* <th>1</th> */}
                                        <th className='black-bg text-white'>
                                            <div className="why-whats">
                                                <img src="../images/customer-transformation/customer_06.png" alt="customer06" />
                                                <div style={{marginLeft: 20}}>
                                                    <span className='font20px'>Turning Point - </span>
                                                    <span className='font12px'>This is the "aha" moment when the buyer realized they must change - either new insight or can't take status quote any longer.</span>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                id="CustomerTransformationC31"
                                                value={Inputs.CustomerTransformationC31}
                                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationC31: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="" />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                id="CustomerTransformationC32"
                                                value={Inputs.CustomerTransformationC32}
                                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationC32: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="" />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                {/* table 04 */}

                                {/* table 05 */}
                                <thead>
                                    <tr>
                                        {/* <th>1</th> */}
                                        <th className='black-bg text-white'>
                                            <div className="why-whats">
                                                <img src="../images/customer-transformation/customer_07.png" alt="customer07" />
                                                <div style={{marginLeft: 20}}>
                                                    <span className='font20px'>Resolution - </span>
                                                    <span className='font12px'>Describe how the buyer used your offering to solve their problem, how people were affected, and quantify the value.</span>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                id="CustomerTransformationC34"
                                                value={Inputs.CustomerTransformationC34}
                                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationC34: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="" />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                id="CustomerTransformationC35"
                                                value={Inputs.CustomerTransformationC35}
                                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationC35: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="" />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                {/* table 05 */}

                                {/* table 06 */}
                                <thead>
                                    <tr>
                                        {/* <th>1</th> */}
                                        <th className='black-bg text-white'>
                                            <div className="why-whats">
                                                <img src="../images/customer-transformation/customer_08.png" alt="customer08" />
                                                <div style={{marginLeft: 20}}>
                                                    <span className='font20px'>Questions - </span>
                                                    <span className='font12px'>"That's enough about Jim, what's your situation?"  If your story doesn't resonate, move to the next insight story.</span>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                id="CustomerTransformationC37"
                                                value={Inputs.CustomerTransformationC37}
                                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationC37: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="" />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px"
                                                id="CustomerTransformationC38"
                                                value={Inputs.CustomerTransformationC38}
                                                onChange={(event) => setInputs({ ...Inputs, CustomerTransformationC38: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="" />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                {/* table 06 */}
                            </table>
                        </div>
                        {/*  build Your customer table 01 */}
                        <div className="transformation-story"  style={{maxWidth: 1250}}>
                            <h3>Transformation Story Example</h3>
                            <div className="settings-tex">
                                <p>
                                    <strong>Setting : </strong>Jim, the President of Breakthrough Glass, wanted a way to know what orders were truly profitable. 
                                </p>
                            </div>
                            <div className="settings-tex">
                                <p>
                                    <strong>Complication : </strong>Up until now, the salespeople had been using a static snapshot of profitability. Jim, however, was disappointed to see profitable deals later become money losers. This occurred because the salespeople were not able to see how their orders impacted current capacity, nor if their rush orders would negatively impact other orders. JIm needed to find a way to have a more dynamic view of profitability, something that was simple for the salespeople to use. What he envisioned was something as simple as booking a flight on-line, with a number of scheduling options according to price for the customer, and true profitability for his company. 
                                </p>
                            </div>
                            <div className="settings-tex">
                                <p>
                                    <strong>Turning Point : </strong>Jim finally had enough when a salesperson's profitable order quickly turned into a substantial money loser because his company was forced to fly parts into the plant to avoid delayed order penalties. 
                                </p>
                            </div>
                            <div className="settings-tex">
                                <p>
                                    <strong>Resolution : </strong>Fortunately, the order inspired Jim to look for a solution, and he found more than what he was looking for with ABC Company.  We provided him with the ability to see each potential order's impact on capacity, and then how it affected other orders.  The sales team was elated that they could now do sales campaigns by pooling their orders along with price incentives so that they could not just generate more sales, but more importantly, maintain profitability.  
                                </p>
                            </div>
                            <div className="settings-tex">
                                <p><strong>Question : </strong>But that's Jim's story, what's yours?</p>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomerTransformationContentNew;