import { Link } from "react-router-dom";

export default function MenuSingleList({submenu, id}) {
    return (
        <ul className='list-of-data'>
            {submenu.map((menu, i) => (
                <li className='font-sise-16' key={id+"_"+i}><Link to={menu.menu_slug}>{menu.label}</Link></li>
            ))}
        </ul>
    )
}