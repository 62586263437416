import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state/index';
import  UserDropdown  from '../UserDropdown';
import { addEditImprovementPercentage } from "../../state/features/ClentInputSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { useAlert } from 'react-alert'
import { Spinner } from '../spinner'
import { Link } from 'react-router-dom';
import { StatesUseEffect } from '../../utils/StatesUseEffect';
import { BarChartGraphNew } from '../graph/BarChartGraphNew';

export default function BuyingLfetimeContent() {
  const [isLoading, setIsLoading] = useState(false);
  const alert = useAlert();
  const users = useSelector(userData);
  const userID = users.data.profile.id;
  const { message } = useSelector((state) => state.message);

  const clientInputs = useSelector(state => state.clientInputs)
  const improvementPercentage = clientInputs.improvementPercentage
  const dispatch = useDispatch();
  const { buyingLifeTimeData } = bindActionCreators(actionCreators, dispatch)
  const buyingLifeTime = useSelector(state => state.buyingLifeTime)

  const [Inputs, setInputs] = useState({
    buyingLifeTimeD13: Number(buyingLifeTime.buyingLifeTimeD13).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1}),
  });
  const { clientInputsH19 } = clientInputs
  let buyingLifeTimeD12 =(parseFloat(clientInputsH19)) ? parseFloat(clientInputsH19) : 0

  useEffect(() => {
    buyingLifeTimeData("buyingLifeTimeD12",buyingLifeTimeD12);
    buyingLifeTimeData("buyingLifeTimeD13",improvementPercentage.buyingLifeTimeD13);
}, [])


  const { buyingLifeTimeD17, buyingLifeTimeE17, buyingLifeTimeD18, buyingLifeTimeE18, buyingLifeTimeF18, buyingLifeTimeG18, buyingLifeTimeF17, buyingLifeTimeG17, buyingLifeTimeH18, buyingLifeTimeI18, buyingLifeTimeH17, buyingLifeTimeI17,buyingLifeTimeD19,buyingLifeTimeE19,buyingLifeTimeF19,buyingLifeTimeG19,buyingLifeTimeH19,buyingLifeTimeI19, buyingLifeTimeD23, buyingLifeTimeE23, buyingLifeTimeF23, buyingLifeTimeG23, buyingLifeTimeH23, buyingLifeTimeI23,  buyingLifeTimeD24, buyingLifeTimeE24, buyingLifeTimeF24, buyingLifeTimeG24, buyingLifeTimeH24, buyingLifeTimeI24, buyingLifeTimeD26, buyingLifeTimeE26, buyingLifeTimeF26, buyingLifeTimeG26, buyingLifeTimeH26, buyingLifeTimeI26 } = buyingLifeTime //object destructuring for buyingLifeTime

  const improvementPrcntHandler = (impPrcnt) => {
        setIsLoading(true);
        let sendData = {user_id : userID, buyingLifeTimeD13: impPrcnt}
        dispatch(addEditImprovementPercentage(sendData))
        .unwrap()
        .then((response) => {
          console.log("response",response)
            if(response!= undefined){
               console.log('success')
            }
            setIsLoading(false)   // Hide loading screen 
        })
        .catch((error) => {
          console.log("error",error)
            { message && alert.show(message) }
            setIsLoading(false)   // Hide loading screen 
        });
    }

    // call states useEffect utils
    StatesUseEffect()

  return (
    <div className="content-body content-body-new bg-white-color">
    <UserDropdown />
    
      {/* row */}
        <div className="container-fluid lead-generation-new">
          <div className="head-title mb-5">
              <h3 className='preheading'>Prescribe - Growth Drivers</h3>
              <h1 className='mainheading mb-1'>Buying Lifetime</h1>	
          </div>
          <div className="row lead-genearation-rw mb-5" style={{maxWidth: 1260}}>
              <div className="col-12 col-lg-6">
                  {/* <div className="potential-growth-ld mb-3" style={{maxWidth: 510}}>
                      <h3><strong>Potential Growth Activities or Tactics:</strong></h3>
                      <ol>
                          <li>Activity 1</li>
                          <li>Activity 2</li>
                          <li>Activity 3</li>
                      </ol>
                  </div> */}
                  <div className="lead-generation-table mt-218" style={{overflowY: 'auto'}}>
                      <div className="responsive-table">
                          <table className='table' style={{width: 510}}>
                              <thead>
                                  <tr>
                                    <th scope='col' className='black-bg text-white fontBold' style={{width: 360}}>Increasing Buying Lifetime</th>
                                    <th className='black-bg hide-content' style={{width: 150}}>2</th>
                                  </tr>
                              </thead>
                              <tbody>
                              <tr>
                                  <td scope='row' className='bg-color-light-blue font12px color-black'>Customer Purchase Frequency (in years)</td>
                                  <td className='bg-color-light-blue font12px color-black text-right-rt'>{(parseFloat(clientInputsH19)) ? Number(clientInputsH19).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1}) : 0}</td>
                              </tr>
                              <tr>
                                  <td scope='row' className='bg-color-light-blue font12px color-black'>Projected Improvement</td>
                                  <th scope='row' className='padding-0'>
                                      <div className="dollor-sin-pr">
                                          <div className="input-fiel-cn bg-color-01 client-inpt-field">
                                              <input type="text" 
                                              name="Inputs.buyingLifeTimeD13"
                                              className="field-ipt wthinherit font12px bg-color-01 text-right text-black"
                                              placeholder={0}
                                              value={Inputs.buyingLifeTimeD13}
                                              onChange={(event) => {
                                                setInputs({ ...Inputs, buyingLifeTimeD13: event.target.value });
                                                buyingLifeTimeData("buyingLifeTimeD13", event.target.value);
                                                improvementPrcntHandler(event.target.value)
                                              }}
                                              onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                                }
                                              }}
                                              disabled={isLoading} />
                                          </div>
                                          <div className="perecentage">%</div>
                                      </div>
                                  </th>
                              </tr>
                          </tbody>
                      </table>
                      <Spinner />
                  </div>
              </div>
            </div>
            <div className="col-12 col-lg-6" style={{minHeight: 400}}>
              <BarChartGraphNew dataInput={{
                "Title"      : "Buying Lifetime: Impact on Financial Performance ",
                "ColorCodes" : ["#d6dce5", "#8497b0", "#1e2b3c"],
                "GraphData"  : {
                    labels: [["Your","Projection"], "Year 1", "Year 2", "Year 3", "Year 4", "Year 5"],
                    datasets: [
                        {
                            label: "Profit",
                            backgroundColor: "#1e65a7", //"#d6dce5",
                            data: [Number(buyingLifeTimeD24),Number(buyingLifeTimeE24),Number(buyingLifeTimeF24),Number(buyingLifeTimeG24),Number(buyingLifeTimeH24),Number(buyingLifeTimeI24)]
                        },
                        {
                            label: "Sales",
                            backgroundColor: "#8497b0",
                            data: [Number(buyingLifeTimeD23),Number(buyingLifeTimeE23),Number(buyingLifeTimeF23),Number(buyingLifeTimeG23),Number(buyingLifeTimeH23),Number(buyingLifeTimeI23)]
                        },
                        {
                            label: "Valuation",
                            backgroundColor: "#1e2b3c",
                            data: [Number(buyingLifeTimeD26),Number(buyingLifeTimeE26),Number(buyingLifeTimeF26),Number(buyingLifeTimeG26),Number(buyingLifeTimeH26),Number(buyingLifeTimeI26)]
                        }
                    ]
                }
              }} />
                {/* <div className="">
                    <h3 className='text-center impact-lead-titile'><strong>Buying Lifetime: Impact on Financial Performance </strong></h3>
                    
                    <BarChartGraph dataInput={{
                        "Title"      : "",
                        "vTitle"     : "",
                        "hTitle"     : "",
                        "subTitle"   : "",
                        "ColorCodes" : ["#c2c7cd", "#8497b0", "#44546a"],
                        "GraphData"  : [
                            ["", "Profit", "Sales", "Valuation"],
                            ["Year 1", Number(buyingLifeTimeE24), Number(buyingLifeTimeE23), Number(buyingLifeTimeE26)],
                            ["Year 2", Number(buyingLifeTimeF24), Number(buyingLifeTimeF23), Number(buyingLifeTimeF26)],
                            ["Year 3", Number(buyingLifeTimeG24), Number(buyingLifeTimeG23), Number(buyingLifeTimeG26)],
                            ["Year 4", Number(buyingLifeTimeH24), Number(buyingLifeTimeH23), Number(buyingLifeTimeH26)],
                            ["Year 5", Number(buyingLifeTimeI24), Number(buyingLifeTimeI23), Number(buyingLifeTimeI26)]
                        ]
                    }} />
                </div> */}
            </div>
        </div>
        <div className="card update-class-lead">
          <div className="card-body pl-0">
              <div className="table-responsive">
                  <table className="table table-responsive-md table-striped mb-0" style={{width: 1260}}>
                      <thead>
                          <tr>
                          <th className="table-primar" style={{width: 360}}/>
                          <th colSpan={2} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Year 1 Gains</th>
                          <th colSpan={2} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Mid-Term Gains</th>
                          <th colSpan={5} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Long-Term Gains </th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr className="thead-primary">
                            <th className='black-bg fontBold' style={{width: 360}}>Buying Lifetime Improvements</th>
                            <th className='black-bg' style={{width: 150}}>Your Projection</th>
                            <th className='black-bg' style={{width: 150}}>1% Quarterly Improvement</th>
                            <th className='black-bg' style={{width: 150}}>2 Years of 1% Improvements</th>
                            <th className='black-bg' style={{width: 150}}>3 Years of 1% Improvements</th>
                            <th className='black-bg' style={{width: 150}}>4 Years of 1% Improvements</th>
                            <th className='black-bg' style={{width: 150}}>5 Years of 1% Improvements</th>
                          </tr>
                        <tr>
                          <td className='bg-color-light-blue font12px color-black'>Annual Improvement in Buying Lifetime </td>
                          <td className='bg-color-blue-gray text-white font12px'> {Number(buyingLifeTimeD17).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})} </td>
                          <td className='bg-color-light-blue font12px color-black'> {Number(buyingLifeTimeE17).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})} </td>
                          <td className='bg-color-light-blue font12px color-black'> {Number(buyingLifeTimeF17).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})} </td>
                          <td className='bg-color-light-blue font12px color-black'> {Number(buyingLifeTimeG17).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})} </td>
                          <td className='bg-color-light-blue font12px color-black'> {Number(buyingLifeTimeH17).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})} </td>
                          <td className='bg-color-light-blue font12px color-black'> {Number(buyingLifeTimeI17).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})} </td>
                        </tr>
                        <tr>
                          <td className='bg-color-light-blue font12px color-black'>New Customer Buying Lifetime</td>
                          <td className='bg-color-blue-gray text-white font12px'> {Number(buyingLifeTimeD18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})} </td>
                          <td className='bg-color-light-blue font12px color-black'> {Number(buyingLifeTimeE18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})} </td>
                          <td className='bg-color-light-blue font12px color-black'> {Number(buyingLifeTimeF18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})} </td>
                          <td className='bg-color-light-blue font12px color-black'> {Number(buyingLifeTimeG18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})} </td>
                          <td className='bg-color-light-blue font12px color-black'> {Number(buyingLifeTimeH18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})} </td>
                          <td className='bg-color-light-blue font12px color-black'> {Number(buyingLifeTimeI18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})} </td>
                        </tr>
                        <tr>
                          <td className='bg-color-light-blue font12px color-black'><strong>Customers Retained by Extending Buying Lifetime</strong></td>
                          <td className='bg-color-blue-gray text-white font12px'> {Number(buyingLifeTimeD19).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})} </td>
                          <td className='bg-color-teal text-white font12px'><strong> {Number(buyingLifeTimeE19).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong> </td>
                          <td className='bg-color-teal text-white font12px'><strong> {Number(buyingLifeTimeF19).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong> </td>
                          <td className='bg-color-teal text-white font12px'><strong> {Number(buyingLifeTimeG19).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong> </td>
                          <td className='bg-color-teal text-white font12px'><strong> {Number(buyingLifeTimeH19).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong> </td>
                          <td className='bg-color-teal text-white font12px'><strong> {Number(buyingLifeTimeI19).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong> </td>
                        </tr>
                        </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="card update-class-lead">
                <div className="card-body pl-0">
                <div className="table-responsive">
                    <table className="table table-responsive-md table-striped mb-0" style={{width: 1260}}>
                    <thead>
                        <tr>
                        <th className='nowrap' style={{background:'#fff', width: 360}}><h3>Financial Improvements</h3></th>
                        <th colSpan={2} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Year 1 Gains</th>
                        <th colSpan={2} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Mid-Term Gains</th>
                        <th colSpan={5} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Long-Term Gains </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="thead-primary">
                          <th className='black-bg fontBold' style={{width: 360}}>Annual Impact of Increasing Buying Lifetime</th>
                          <th className='black-bg' style={{width: 150}}>Your Projection</th>
                          <th className='black-bg' style={{width: 150}}>1% Quarterly Improvement</th>
                          <th className='black-bg' style={{width: 150}}>2 Years of 1% Improvements</th>
                          <th className='black-bg' style={{width: 150}}>3 Years of 1% Improvements</th>
                          <th className='black-bg' style={{width: 150}}>4 Years of 1% Improvements</th>
                          <th className='black-bg' style={{width: 150}}>5 Years of 1% Improvements</th>
                  </tr>
                  <tr>
                    <td className='bg-color-light-blue font12px color-black'>Annual Sales</td>
                    <td className='bg-color-blue-gray text-white font12px'>
                      <div className="annual-dollor-sign">
                          <div className="annual-dl font12px text-white"> $ </div>
                          <div className="annual-dl-number font12px text-white">
                            {Number(buyingLifeTimeD23).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                        </div>
                      </div>
                    </td>
                    <td className='bg-color-light-blue font12px color-black'>
                      <div className="annual-dollor-sign">
                          <div className="annual-dl font12px"> $ </div>
                          <div className="annual-dl-number font12px">
                            {Number(buyingLifeTimeE23).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                          </div>
                        </div> 
                    </td>
                    <td className='bg-color-light-blue font12px color-black'>
                      <div className="annual-dollor-sign">
                          <div className="annual-dl font12px"> $ </div>
                          <div className="annual-dl-number font12px">
                            {Number(buyingLifeTimeF23).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                          </div>
                        </div> 
                    </td>
                    <td className='bg-color-light-blue font12px color-black'>
                      <div className="annual-dollor-sign">
                          <div className="annual-dl font12px"> $ </div>
                          <div className="annual-dl-number font12px">
                            {Number(buyingLifeTimeG23).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                          </div>
                        </div> 
                    </td>
                    <td className='bg-color-light-blue font12px color-black'>
                      <div className="annual-dollor-sign">
                          <div className="annual-dl font12px"> $ </div>
                          <div className="annual-dl-number font12px">
                            {Number(buyingLifeTimeH23).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                          </div>
                        </div> 
                    </td>
                    <td className='bg-color-light-blue font12px color-black'>
                      <div className="annual-dollor-sign">
                          <div className="annual-dl font12px"> $ </div>
                          <div className="annual-dl-number font12px">
                            {Number(buyingLifeTimeI23).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                          </div>
                        </div> 
                    </td>
                  </tr>
                  <tr>
                    <td className='bg-color-light-blue font12px color-black'>Annual Profit</td>
                    <td className='bg-color-blue-gray text-white font12px'>
                      <div className="annual-dollor-sign">
                          <div className="annual-dl font12px text-white"> $ </div>
                          <div className="annual-dl-number font12px text-white">
                            {Number(buyingLifeTimeD24).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                        </div>
                      </div>
                    </td>
                    <td className='bg-color-light-blue font12px color-black'>
                      <div className="annual-dollor-sign">
                          <div className="annual-dl font12px"> $ </div>
                          <div className="annual-dl-number font12px">
                            {Number(buyingLifeTimeE24).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                          </div>
                        </div> 
                    </td>
                    <td className='bg-color-light-blue font12px color-black'>
                      <div className="annual-dollor-sign">
                          <div className="annual-dl font12px"> $ </div>
                          <div className="annual-dl-number font12px">
                            {Number(buyingLifeTimeF24).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                          </div>
                        </div> 
                    </td>
                    <td className='bg-color-light-blue font12px color-black'>
                      <div className="annual-dollor-sign">
                          <div className="annual-dl font12px"> $ </div>
                          <div className="annual-dl-number font12px">
                            {Number(buyingLifeTimeG24).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                          </div>
                        </div> 
                    </td>
                    <td className='bg-color-light-blue font12px color-black'>
                      <div className="annual-dollor-sign">
                          <div className="annual-dl font12px"> $ </div>
                          <div className="annual-dl-number font12px">
                            {Number(buyingLifeTimeH24).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                          </div>
                        </div> 
                    </td>
                    <td className='bg-color-light-blue font12px color-black'>
                      <div className="annual-dollor-sign">
                          <div className="annual-dl font12px"> $ </div>
                          <div className="annual-dl-number font12px">
                            {Number(buyingLifeTimeI24).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                          </div>
                        </div> 
                    </td>
                  </tr>
                  <tr className="thead-primary">
                  <th className='black-bg fontBold'>Valuation Impact of Increasing Buying Lifetime</th>
                    <th className='black-bg'>Your Projection</th>
                    <th className='black-bg'>1% Quarterly Improvement</th>
                    <th className='black-bg'>2 Years of 1% Improvements</th>
                    <th className='black-bg'>3 Years of 1% Improvements</th>
                    <th className='black-bg'>4 Years of 1% Improvements</th>
                    <th className='black-bg'>5 Years of 1% Improvements</th>
                  </tr>
                  <tr>
                    <td className='bg-color-light-blue font12px color-black'>Estimated Valuation Impact</td>
                    <td className='bg-color-blue-gray text-white font12px'>
                      <div className="annual-dollor-sign">
                          <div className="annual-dl font12px text-white"> $ </div>
                          <div className="annual-dl-number font12px text-white">
                            {Number(buyingLifeTimeD26).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                        </div>
                      </div>
                    </td>
                    <td className='bg-color-light-blue font12px color-black'>
                      <div className="annual-dollor-sign">
                          <div className="annual-dl font12px"> $ </div>
                          <div className="annual-dl-number font12px">
                            {Number(buyingLifeTimeE26).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                          </div>
                        </div> 
                    </td>
                    <td className='bg-color-light-blue font12px color-black'>
                      <div className="annual-dollor-sign">
                          <div className="annual-dl font12px"> $ </div>
                          <div className="annual-dl-number font12px">
                            {Number(buyingLifeTimeF26).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                          </div>
                        </div> 
                    </td>
                    <td className='bg-color-light-blue font12px color-black'>
                      <div className="annual-dollor-sign">
                          <div className="annual-dl font12px"> $ </div>
                          <div className="annual-dl-number font12px">
                            {Number(buyingLifeTimeG26).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                          </div>
                        </div> 
                    </td>
                    <td className='bg-color-light-blue font12px color-black'>
                      <div className="annual-dollor-sign">
                          <div className="annual-dl font12px"> $ </div>
                          <div className="annual-dl-number font12px">
                            {Number(buyingLifeTimeH26).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                          </div>
                        </div> 
                    </td>
                    <td className='bg-color-light-blue font12px color-black'>
                      <div className="annual-dollor-sign">
                          <div className="annual-dl font12px"> $ </div>
                          <div className="annual-dl-number font12px">
                            {Number(buyingLifeTimeI26).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                          </div>
                        </div> 
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="nextInput">
                <ul>
                    <li>
                        <Link to="/prescribe/customer-attrition" className='next-link'> Next: Reducing Attrition   
                            <span className='icon-three'>
                                <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                <i className="fa fa-angle-right" aria-hidden="true"></i> 
                            </span>
                        </Link>
                    </li>
                </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}
