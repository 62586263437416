const initialState = {
    BQCompetitiveAdvantageP9: 22,
    BQCompetitiveAdvantageP10: 20,
    BQCompetitiveAdvantageP11: 19,
    BQCompetitiveAdvantageP12: 23,
    BQFinancialPerformanceP11: 23,
    BQFinancialPerformanceP12: 17,
    BQFinancialPerformanceP9: 25,
    BQFinancialPerformanceP10: 15,
    BQCustomerAcquisitionP9: 26,
    BQCustomerAcquisitionP10: 33,
    BQCustomerAcquisitionP11: 14,
    BQCustomerRetentionP9: 15,
    BQCustomerRetentionP10: 27,
    BQCustomerRetentionP11: 17,
    BQCustomerRetentionP12: 15,
    BQCustomerTransactionsP9: 20,
    BQCustomerTransactionsP10: 20,
    BQAbilityP9: 11,
    BQAbilityP10: 12,
    BQAbilityP11: 15,
    BQAbilityP12: 8,
    BQAgilityP9: 7,
    BQAgilityP10: 13,
    BQAgilityP11: 6,
    BQAgilityP12: 5,
    BQLongTermGrowthP9: 5,
    BQLongTermGrowthP10: 4,
    BQLongTermGrowthP11: 5,
    BQLongTermGrowthP18: 6,
    BQLongTermGrowthP19: 4,
    BQLongTermGrowthP20: 3,
    BQLongTermGrowthP15: 3,
    BQLongTermGrowthP16: 3,
    BQLongTermGrowthP17: 4,
    BQLongTermGrowthP12: 3,
    BQLongTermGrowthP13: 4,
    BQLongTermGrowthP14: 2,
}

const reducer = (state = initialState, action) => {
    return state
}

export default reducer