import { Link } from "react-router-dom";

export default function PageNotFound() {

    return (
        <div>
            <div id="main-wrapper">
                <div class="content-body bg-dard-color bg_bottom">
                    <div className="container d-grid align-items-center justify-content-center h-100">
                        <div className="text-center" style={{position: 'relative'}}>
                            <h1 className="error-heading">404</h1>
                            <h2 className="error-text">Page Not Found</h2>
                            <Link to="/home" className="btn-error"><i className="fa fa-angle-double-left"></i> Home</Link>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}