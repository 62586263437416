import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import sum from "../../utils/sum";
import { Link } from 'react-router-dom';
import formatPrice from '../../utils/formatPrice';
import { getEquityEvolutionScorecard, modifyEquityEvolutionScorecard } from '../../state/features/EquityEvolutionScorecardSlice';

export default function EquityEvolutionScorecardScorecardContentNew() {

    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        EquityEvolutionScorecardE9: 0,
        EquityEvolutionScorecardF9: "",
        EquityEvolutionScorecardE10: 0,
        EquityEvolutionScorecardF10: "",
        EquityEvolutionScorecardE11: 0,
        EquityEvolutionScorecardF11: "",
        EquityEvolutionScorecardE12: 0,
        EquityEvolutionScorecardF12: "",
        EquityEvolutionScorecardE13: 0,
        EquityEvolutionScorecardF13: "",
        EquityEvolutionScorecardE14: 0,
        EquityEvolutionScorecardF14: "",
        EquityEvolutionScorecardE15: 0,
        EquityEvolutionScorecardF15: "",
        EquityEvolutionScorecardE16: 0,
        EquityEvolutionScorecardF16: "",
        EquityEvolutionScorecardE17: 0,
        EquityEvolutionScorecardE18: 0,
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getEquityEvolutionScorecard({user_id}))
        .unwrap()
        .then((response) => {
            console.log("response2",response.data)
            if(response!= undefined  && response.data.clientGrowth){
                let clientGrowth = response.data.clientGrowth[0];
                // console.log(clientGrowth)
                delete clientGrowth.id;
                delete clientGrowth.user_id;
                delete clientGrowth.created_at;
                delete clientGrowth.updated_at;
                const newObj = { ...Inputs }
                for (const key in clientGrowth) {
                    if (clientGrowth.hasOwnProperty(key)) {
                        if(clientGrowth[key])
                        newObj[key] = clientGrowth[key]
                    }
                }
                
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

    }, [dispatch])
    
    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                event.target.value = event.target.value ? event.target.value : 0
                dispatch(modifyEquityEvolutionScorecard({ user_id, ...Inputs,[event.target.id] : event.target.value, 'EquityEvolutionScorecardE17': String(Inputs.EquityEvolutionScorecardE17), 'EquityEvolutionScorecardE18': String(Inputs.EquityEvolutionScorecardE18)}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        //window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

    useEffect(() => {
        let EquityEvolutionScorecardE17 = sum(Number(Inputs.EquityEvolutionScorecardE9), Number(Inputs.EquityEvolutionScorecardE10), Number(Inputs.EquityEvolutionScorecardE11), Number(Inputs.EquityEvolutionScorecardE12), Number(Inputs.EquityEvolutionScorecardE13), Number(Inputs.EquityEvolutionScorecardE14), Number(Inputs.EquityEvolutionScorecardE15), Number(Inputs.EquityEvolutionScorecardE16));
        let EquityEvolutionScorecardE18 = (parseFloat(EquityEvolutionScorecardE17) / 9).toFixed(2);

        setInputs({...Inputs, EquityEvolutionScorecardE17, EquityEvolutionScorecardE18});

    }, [Inputs.EquityEvolutionScorecardE9, Inputs.EquityEvolutionScorecardE10, Inputs.EquityEvolutionScorecardE11, Inputs.EquityEvolutionScorecardE12, Inputs.EquityEvolutionScorecardE13, Inputs.EquityEvolutionScorecardE14, Inputs.EquityEvolutionScorecardE15, Inputs.EquityEvolutionScorecardE16]);

    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
            {/* row */}
            <div className="container-fluid">
                <div className="head-title">
                    <h3 className='preheading'>Diagnose - Benchmark Tools</h3>
                    <h1 className='mainheading mb-1'>Equity Evolution Scorecard</h1>
                </div>
                <div className="competitive-advantage-audit-table changes-equity">
                    <div className="table-responsive">
                        <table className="table" style={{width: 1210}}>
                            <thead>
                                <tr>
                                    <td className='bg-color01' style={{width: 50, height: 80}}>
                                        <div className="empty-number-ddh"></div>
                                    </td>
                                    <td className='bg-color01 text-white font18px' style={{width: 600}}>
                                    Equity Drivers
                                    </td>
                                    <td className='bg-color01 text-white text-center font14px fontLight' style={{width: 150}}>
                                    Current Rating <br/> (1=low; 5= high)
                                    </td>
                                    <td className='bg-color01 text-white text-center font14px fontLight' style={{width: 400}}>
                                    Improvement Ideas
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='bg-color01 text-white text-center'>
                                        <div className="competitiveImagesBox">
                                            <img src="../images/equity-evolution/icon_01.png" alt="competitiveImg" />
                                        </div>
                                    </td>
                                    <td className='Blue-gray-bg text-white'>
                                        <div className="proprietary-infomation">
                                            <p className='font11px'><strong>Competitive Advantage - </strong> It's difficult to launch a competitor that can take your market share.</p>
                                            {/* (Buffet't's Moat) */}
                                        </div>
                                    </td>
                                    <td  className='bg-color-01 padding-0'>
                                        <div className="input-fiel-cn factor-inputs changes-input">
                                            <input type="text" className="bg-color-01 font11px color-dark" 
                                                id="EquityEvolutionScorecardE9" 
                                                value={Inputs.EquityEvolutionScorecardE9} 
                                                onChange={(event) => setInputs({ ...Inputs, EquityEvolutionScorecardE9: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='bg-color-02 padding-0'>
                                        <div className="input-fiel-cn factor-inputs">
                                            <textarea type="text" className="bg-color-02 font11px color-dark"
                                            id="EquityEvolutionScorecardF9"
                                            value={Inputs.EquityEvolutionScorecardF9}
                                            onChange={(event) => setInputs({ ...Inputs, EquityEvolutionScorecardF9: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color01 text-white text-center'>
                                        <div className="competitiveImagesBox">
                                            <img src="../images/equity-evolution/icon_02.png" alt="competitiveImg" />
                                        </div>
                                    </td>
                                    <td className='Blue-gray-bg text-white'>
                                        <div className="proprietary-infomation">
                                            <p className='font11px'><strong>New Business Optionality -</strong> Is the company positioned in a way that would make it easy to launch a new businesses or business segments effectively, allowing you to expand your product and service offering?</p>
                                        </div>
                                    </td>
                                    <td  className='bg-color-01 padding-0'>
                                        <div className="input-fiel-cn factor-inputs changes-input">
                                            <input type="text" className="bg-color-01 font11px color-dark" 
                                                id="EquityEvolutionScorecardE10" 
                                                value={Inputs.EquityEvolutionScorecardE10} 
                                                onChange={(event) => setInputs({ ...Inputs, EquityEvolutionScorecardE10: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='bg-color-02 padding-0'>
                                        <div className="input-fiel-cn factor-inputs">
                                            <textarea type="text" className="bg-color-02 font11px color-dark"
                                            id="EquityEvolutionScorecardF10"
                                            value={Inputs.EquityEvolutionScorecardF10}
                                            onChange={(event) => setInputs({ ...Inputs, EquityEvolutionScorecardF10: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color01 text-white text-center'>
                                        <div className="competitiveImagesBox">
                                            <img src="../images/equity-evolution/icon_03.png" alt="competitiveImg" />
                                        </div>
                                    </td>
                                    <td className='Blue-gray-bg text-white'>
                                        <div className="proprietary-infomation">
                                            <p className='font11px'><strong>Gross Margin - </strong>Is your profit margin lower, the same as, or higher than industry averages?</p>
                                        </div>
                                    </td>
                                    <td  className='bg-color-01 padding-0'>
                                        <div className="input-fiel-cn factor-inputs changes-input">
                                            <input type="text" className="bg-color-01 font11px color-dark" 
                                                id="EquityEvolutionScorecardE11" 
                                                value={Inputs.EquityEvolutionScorecardE11} 
                                                onChange={(event) => setInputs({ ...Inputs, EquityEvolutionScorecardE11: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='bg-color-02 padding-0'>
                                        <div className="input-fiel-cn factor-inputs">
                                            <textarea type="text" className="bg-color-02 font11px color-dark"
                                            id="EquityEvolutionScorecardF11"
                                            value={Inputs.EquityEvolutionScorecardF11}
                                            onChange={(event) => setInputs({ ...Inputs, EquityEvolutionScorecardF11: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color01 text-white text-center'>
                                        <div className="competitiveImagesBox">
                                            <img src="../images/equity-evolution/icon_04.png" alt="competitiveImg" />
                                        </div>
                                    </td>
                                    <td className='Blue-gray-bg text-white'>
                                        <div className="proprietary-infomation">
                                            <p className='font11px'><strong>Scalability - </strong>is your business easily scaled so higher revenues create higher profit (marginal profitability calculation)?</p>
                                        </div>
                                    </td>
                                    <td  className='bg-color-01 padding-0'>
                                        <div className="input-fiel-cn factor-inputs changes-input">
                                            <input type="text" className="bg-color-01 font11px color-dark" 
                                                id="EquityEvolutionScorecardE12" 
                                                value={Inputs.EquityEvolutionScorecardE12} 
                                                onChange={(event) => setInputs({ ...Inputs, EquityEvolutionScorecardE12: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='bg-color-02 padding-0'>
                                        <div className="input-fiel-cn factor-inputs">
                                            <textarea type="text" className="bg-color-02 font11px color-dark"
                                            id="EquityEvolutionScorecardF12"
                                            value={Inputs.EquityEvolutionScorecardF12}
                                            onChange={(event) => setInputs({ ...Inputs, EquityEvolutionScorecardF12: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color01 text-white text-center'>
                                        <div className="competitiveImagesBox">
                                            <img src="../images/equity-evolution/icon_05.png" alt="competitiveImg" />
                                        </div>
                                    </td>
                                    <td className='Blue-gray-bg text-white'>
                                        <div className="proprietary-infomation">
                                            <p className='font11px'><strong>Customer Base - </strong> Is your customer base smaller, similar to, or larger than your three main competitors?</p>
                                        </div>
                                    </td>
                                    <td  className='bg-color-01 padding-0'>
                                        <div className="input-fiel-cn factor-inputs changes-input">
                                            <input type="text" className="bg-color-01 font11px color-dark" 
                                                id="EquityEvolutionScorecardE13" 
                                                value={Inputs.EquityEvolutionScorecardE13} 
                                                onChange={(event) => setInputs({ ...Inputs, EquityEvolutionScorecardE13: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='bg-color-02 padding-0'>
                                        <div className="input-fiel-cn factor-inputs">
                                            <textarea type="text" className="bg-color-02 font11px color-dark"
                                            id="EquityEvolutionScorecardF13"
                                            value={Inputs.EquityEvolutionScorecardF13}
                                            onChange={(event) => setInputs({ ...Inputs, EquityEvolutionScorecardF13: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color01 text-white text-center'>
                                        <div className="competitiveImagesBox">
                                            <img src="../images/equity-evolution/icon_06.png" alt="competitiveImg" />
                                        </div>
                                    </td>
                                    <td className='Blue-gray-bg text-white'>
                                        <div className="proprietary-infomation">
                                            <p className='font11px'><strong>Customer Retention - </strong>Is your customer attrition rate higher, the same as, or lower than industry averages?</p>
                                        </div>
                                    </td>
                                    <td  className='bg-color-01 padding-0'>
                                        <div className="input-fiel-cn factor-inputs changes-input">
                                            <input type="text" className="bg-color-01 font11px color-dark" 
                                                id="EquityEvolutionScorecardE14" 
                                                value={Inputs.EquityEvolutionScorecardE14} 
                                                onChange={(event) => setInputs({ ...Inputs, EquityEvolutionScorecardE14: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='bg-color-02 padding-0'>
                                        <div className="input-fiel-cn factor-inputs">
                                            <textarea type="text" className="bg-color-02 font11px color-dark"
                                            id="EquityEvolutionScorecardF14"
                                            value={Inputs.EquityEvolutionScorecardF14}
                                            onChange={(event) => setInputs({ ...Inputs, EquityEvolutionScorecardF14: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color01 text-white text-center'>
                                        <div className="competitiveImagesBox">
                                            <img src="../images/equity-evolution/icon_07.png" alt="competitiveImg" />
                                        </div>
                                    </td>
                                    <td className='Blue-gray-bg text-white'>
                                        <div className="proprietary-infomation">
                                            <p className='font11px'><strong>Customer Responsiveness - </strong>Is your Customer Lifetime Value lower, similar to, or higher than your three main competitors?</p>
                                        </div>
                                    </td>
                                    <td  className='bg-color-01 padding-0'>
                                        <div className="input-fiel-cn factor-inputs changes-input">
                                            <input type="text" className="bg-color-01 font11px color-dark" 
                                                id="EquityEvolutionScorecardE15" 
                                                value={Inputs.EquityEvolutionScorecardE15} 
                                                onChange={(event) => setInputs({ ...Inputs, EquityEvolutionScorecardE15: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='bg-color-02 padding-0'>
                                        <div className="input-fiel-cn factor-inputs">
                                            <textarea type="text" className="bg-color-02 font11px color-dark"
                                            id="EquityEvolutionScorecardF15"
                                            value={Inputs.EquityEvolutionScorecardF15}
                                            onChange={(event) => setInputs({ ...Inputs, EquityEvolutionScorecardF15: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color01 text-white text-center'>
                                        <div className="competitiveImagesBox">
                                            <img src="../images/equity-evolution/icon_08.png" alt="competitiveImg" />
                                        </div>
                                    </td>
                                    <td className='Blue-gray-bg text-white'>
                                        <div className="proprietary-infomation">
                                            <p className='font11px'><strong>Resilience - </strong>How dependent are you on a small number of large accounts to maintain your sales revenue?.</p>
                                        </div>
                                    </td>
                                    <td  className='bg-color-01 padding-0'>
                                        <div className="input-fiel-cn factor-inputs changes-input">
                                            <input type="text" className="bg-color-01 font11px color-dark" 
                                                id="EquityEvolutionScorecardE16" 
                                                value={Inputs.EquityEvolutionScorecardE16} 
                                                onChange={(event) => setInputs({ ...Inputs, EquityEvolutionScorecardE16: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='bg-color-02 padding-0'>
                                        <div className="input-fiel-cn factor-inputs">
                                            <textarea type="text" className="bg-color-02 font11px color-dark"
                                            id="EquityEvolutionScorecardF16"
                                            value={Inputs.EquityEvolutionScorecardF16}
                                            onChange={(event) => setInputs({ ...Inputs, EquityEvolutionScorecardF16: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th className='bg-color-blue text-white' colSpan={2}>
                                        <div className="total-prentd font18px fontBold">
                                        Total
                                        </div>
                                    </th>
                                    <th  className='bg-color-blue text-white text-center'>
                                        <div className="ony-numberdsd font12px fontBold">
                                        {formatPrice(Inputs.EquityEvolutionScorecardE17, 0)}
                                        </div>
                                    </th>
                                    <th className='hide-content'>hide </th>
                                </tr>
                                <tr>
                                    <th className='bg-color-blue text-white' colSpan={2}>
                                        <div className="total-prentd font18px fontBold">
                                        Average
                                        </div>
                                    </th>
                                    <th  className='bg-color-blue text-white text-center'>
                                        <div className="ony-numberdsd font12px fontBold">
                                        {formatPrice(Inputs.EquityEvolutionScorecardE18, 1)}
                                        </div>
                                    </th>
                                    <th className='hide-content'>hide </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>  
                    <div className="nextInput">
                        <ul>
                            <li>
                                <Link className="next-link" to="/diagnose/enterprise-value"> Proceed to Growth Culture Scorecard
                                    <span className="icon-three">
                                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                                    </span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}