import React from 'react'
import SidebarNew from '../components/SidebarNew';
import Content from '../components/content/PreBenchmarkSurveyContent';

export default function PreBenchmarkSurvey() {
  return (
    <div>

      <div id="main-wrapper">

        <SidebarNew />

        <Content />

      </div>
    </div>
  )
}
