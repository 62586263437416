import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export function BarChartGraphDark({dataInput}) {
  const data = dataInput.GraphData;
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    colors: dataInput.ColorCodes,
    scales: {
      y: {
        ticks: {
            tickColor: '#8497B0',
            color: '#8497B0',
            callback: function(value, index, ticks) {
                return '$' + Number(value).toLocaleString('en-US');
            }
        },
        grid: {
          display: true,
          color: '#8497B0'
        }
      },
      x: {
        ticks: {
          tickColor: '#8497B0',
          color: '#8497B0'
        },
        grid: {
          display: false,
          color: '#BFBFBF'
        }
      }
    },
    plugins: {
      legend: {
        display: dataInput.showLegend,
        position: 'top',
        labels: {
          boxWidth: 10,
          boxHeight: 10
        }
      },
      title: {
          display: dataInput.showTitle,
          text: dataInput.Title,
          font: {
            size: 16,
            weight: 600
          }
      },
      tooltips: {
        enabled: false
      }
    },
  }
      
  return (
    // <Chart
    //   chartType="ColumnChart"
    //   width="100%"
    //   // height={height}
    //   data={data}
    //   options={options}
    // />
    <Bar options={options} data={data}/>
  );
}
