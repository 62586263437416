import { Navigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectUser } from "../state/features/AuthenticationSlice";
import { RESET_ACTION } from '../state/action-creators';
import { logout } from "../state/features/AuthenticationSlice";
import { useAlert } from 'react-alert'
import {useSWRConfig} from "swr";

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};
let permittedRoutes = ['/home', '/profile', '/manage-branding', '/change-password', '/logout', '/success'];

function PrivateRoute({ children }) {
  const location = useLocation();
  const alert = useAlert();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectUser);
  const user = JSON.parse(localStorage.getItem("user"));
 
  const { fallback, isLoading } = useSWRConfig();
 
  if(fallback?.active_menu_slugs){
    permittedRoutes = [...fallback.active_menu_slugs.split(',')];
  }
  const isPermittedRoute = permittedRoutes.includes(location.pathname);
  //console.log("isPermittedRoute",permittedRoutes)
  if(user){
    const decodedJwt = parseJwt(user.token);
    if (decodedJwt.exp * 1000 < Date.now()) {
      // console.log('exp token')
      dispatch(logout())
      dispatch(RESET_ACTION())
      alert.show("Your session has ended. Please login again.")
      return <Navigate to="/" />
    }
    // console.log("isAuthenticated private route value",isAuthenticated)
    return isPermittedRoute?
      (isAuthenticated  ? children : <Navigate to="/" />)
      :
      <Navigate to="/access-denied" />
    // return isAuthenticated  ? children : <Navigate to="/" />;
  }
  else{
    return <Navigate to="/" />
  }  
}

export default PrivateRoute;






