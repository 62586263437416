import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getSevenStepCaseForChange, modifySevenStepCaseForChange } from "../../state/features/SevenStepCaseForChangeSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';

export default function StepCaseForChangeContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        SevenStepCaseForChangeF18 : '',
        SevenStepCaseForChangeF19 : '',
        SevenStepCaseForChangeF20 : '',
        SevenStepCaseForChangeF21 : '',
        SevenStepCaseForChangeF22 : '',
        SevenStepCaseForChangeF23 : '',
        SevenStepCaseForChangeF24 : ''
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getSevenStepCaseForChange({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.revenueGrowth)
            if(response!= undefined  && response.data.revenueGrowth){
                let revenueGrowth = response.data.revenueGrowth[0];
                console.log(revenueGrowth)
                delete revenueGrowth.id;
                delete revenueGrowth.user_id;
                delete revenueGrowth.created_at;
                delete revenueGrowth.updated_at;
                const newObj = { ...Inputs }
                for (const key in revenueGrowth) {
                    if (revenueGrowth.hasOwnProperty(key)) {
                        if(revenueGrowth[key])
                        newObj[key] = revenueGrowth[key]
                    }
                }
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifySevenStepCaseForChange({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

  return (
    <div className="content-body">
        <UserDropdown />
        
        {/* row */}
        <div className="container-fluid">
            <div className="head-title">
                <p className="mb-0">Innovation</p>
                <h2>7-Step Case for Change</h2>
                <p className="mb-2">The 7-Step Case for Change template is a tool that provides the Change Manager with one place to capture all the reasons a change needs to occur. Information on this template will be used to craft communications for each relevant audience impacted by the change. </p>
            </div>
            <div className="row mb-4">
                <div className="col-12 col-md-6">
                <div className="case-for-change-main-div">
                    <h3 className="change-title">Sections 1-4 focus on the actual change project: </h3>
                    <ul className="unstyle-list">
                    <li><strong>1. Executive Summary</strong> gives a succinct description of the change.</li>
                    <li><strong>2. Problem Statement</strong> shows what benefits the change will have on the people side of the business.</li>
                    <li><strong>3. Who</strong> needs to be convinced the project is necessary?</li>
                    <li><strong>4. Funding</strong> includes costs and human assets needed to complete the project.</li>
                    <li><strong>5. I Don't Get It</strong> - you must support your case with facts, figures, ideas, and visual aids.  Make sure everyone understands why change is necessary.</li>
                    </ul>
                </div>
                </div>
                <div className="col-12 col-md-6">
                <div className="case-for-change-main-div">
                    <h3 className="change-title">Sections 5-7 help you prepare for resistance you may face that could derail your change project:: </h3>
                    <ul className="unstyle-list">
                    <li><strong>6. I Don't Like It</strong> - prepare for emotional reaction to the change.  Create excitement and remove as much fear as possible.  Get them involved in helping co-create the change to increase buy-in.</li>
                    <li><strong>7. I Don't Like You</strong> - it's possible you will encounter people who dislike or distrust the people presenting the change.  Do what you can to mend damaged relationships and rebuild trust. </li>
                    </ul>
                </div>
                </div>
            </div>
            <Spinner />
            <div className="card">
                <div className="card-body pl-0">
                <div className="table-responsive table-dta">
                    <table className="table mb-0">
                    <tbody>
                        <tr>
                        <td className="width-10 text-white bg-color1">
                            <h1 className="characters-si">1</h1>
                        </td>
                        <td colSpan={3} className="width-20 bg-color1 text-white text-font-size-16">
                            <div className="icd-contetn">Summary</div>
                        </td>
                        <td colSpan={3} className="width-20 bg-color3 text-white">
                            <div className="step-case-description">What is the project or task?  Include scope, objectives, a timeline, milestones, success metrics, cost-benefits analysis, and ROI projection.  Create and communicate your vision for change.</div>
                        </td>
                        <td colSpan={8}>
                            <div className="step-case-contetn2">
                                <textarea className="from-control inputText" 
                                    rows={8} 
                                    cols={24} 
                                    id="SevenStepCaseForChangeF18"
                                    value={Inputs.SevenStepCaseForChangeF18}
                                    onChange={(event) => setInputs({ ...Inputs, SevenStepCaseForChangeF18: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <td className="width-10 text-white bg-color1">
                            <h1 className="characters-si">2</h1>
                        </td>
                        <td colSpan={3} className="width-20 bg-color1 text-white text-font-size-16">
                            <div className="icd-contetn">Problem</div>
                        </td>
                        <td colSpan={3} className="width-20 bg-color3 text-white">
                            <div className="step-case-description">What is the reason the project is being initiated?  How can you create urgency to solve the problem?  Can you remove obstacles that could prevent the change?</div>
                        </td>
                        <td colSpan={8}>
                            <div className="step-case-contetn2">
                                <textarea className="from-control inputText" 
                                    rows={8} 
                                    cols={24} 
                                    id="SevenStepCaseForChangeF19"
                                    value={Inputs.SevenStepCaseForChangeF19}
                                    onChange={(event) => setInputs({ ...Inputs, SevenStepCaseForChangeF19: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <td className="width-10 text-white bg-color1">
                            <h1 className="characters-si">3</h1>
                        </td>
                        <td colSpan={3} className="width-20 bg-color1 text-white text-font-size-16">
                            <div className="icd-contetn">Who</div>
                        </td>
                        <td colSpan={3} className="width-20 bg-color3 text-white">
                            <div className="step-case-description">Who must be convinced the project is necessary?  Who can you get to support or champion your cause?</div>
                        </td>
                        <td colSpan={8}>
                            <div className="step-case-contetn2">
                                <textarea className="from-control inputText" 
                                    rows={8} 
                                    cols={24} 
                                    id="SevenStepCaseForChangeF20"
                                    value={Inputs.SevenStepCaseForChangeF20}
                                    onChange={(event) => setInputs({ ...Inputs, SevenStepCaseForChangeF20: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <td className="width-10 text-white bg-color1">
                            <h1 className="characters-si">4</h1>
                        </td>
                        <td colSpan={3} className="width-20 bg-color1 text-white text-font-size-16">
                            <div className="icd-contetn">Funding</div>
                        </td>
                        <td colSpan={3} className="width-20 bg-color3 text-white">
                            <div className="step-case-description">What is needed in terms of action and funding?</div>
                        </td>
                        <td colSpan={8}>
                            <div className="step-case-contetn2">
                                <textarea className="from-control inputText" 
                                    rows={8} 
                                    cols={24} 
                                    id="SevenStepCaseForChangeF21"
                                    value={Inputs.SevenStepCaseForChangeF21}
                                    onChange={(event) => setInputs({ ...Inputs, SevenStepCaseForChangeF21: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <td className="width-10 text-white bg-color1">
                            <h1 className="characters-si">5</h1>
                        </td>
                        <td colSpan={3} className="width-20 bg-color1 text-white text-font-size-16">
                            <div className="icd-contetn">I don't get it</div>
                        </td>
                        <td colSpan={3} className="width-20 bg-color3 text-white">
                            <div className="step-case-description">To overcome resistance, it's important to make sure people know why change is necessary. List the reasons why it is necessary.</div>
                        </td>
                        <td colSpan={8}>
                            <div className="step-case-contetn2">
                                <textarea className="from-control inputText" 
                                    rows={8} 
                                    cols={24} 
                                    id="SevenStepCaseForChangeF22"
                                    value={Inputs.SevenStepCaseForChangeF22}
                                    onChange={(event) => setInputs({ ...Inputs, SevenStepCaseForChangeF22: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <td className="width-10 text-white bg-color1">
                            <h1 className="characters-si">6</h1>
                        </td>
                        <td colSpan={3} className="width-20 bg-color1 text-white text-font-size-16">
                            <div className="icd-contetn">I don't like it</div>
                        </td>
                        <td colSpan={3} className="width-20 bg-color3 text-white">
                            <div className="step-case-description">This level is about removing as much fear as possible so people can react with less emotion. List how you can reduce fear:</div>
                        </td>
                        <td colSpan={8}>
                            <div className="step-case-contetn2">
                                <textarea className="from-control inputText" 
                                    rows={8} 
                                    cols={24} 
                                    id="SevenStepCaseForChangeF23"
                                    value={Inputs.SevenStepCaseForChangeF23}
                                    onChange={(event) => setInputs({ ...Inputs, SevenStepCaseForChangeF23: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <td className="width-10 text-white bg-color1">
                            <h1 className="characters-si">7</h1>
                        </td>
                        <td colSpan={3} className="width-20 bg-color1 text-white text-font-size-16">
                            <div className="icd-contetn">I don't like you</div>
                        </td>
                        <td colSpan={3} className="width-20 bg-color3 text-white">
                            <div className="step-case-description">This level typically occurs not because people dislike the idea, but because they dislike you or other leaders.  List ways to reconnect and mend broken relationships:</div>
                        </td>
                        <td colSpan={8}>
                            <div className="step-case-contetn2">
                                <textarea className="from-control inputText" 
                                    rows={8} 
                                    cols={24} 
                                    id="SevenStepCaseForChangeF24"
                                    value={Inputs.SevenStepCaseForChangeF24}
                                    onChange={(event) => setInputs({ ...Inputs, SevenStepCaseForChangeF24: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
        </div>


    </div>
  )
}
