import React from 'react'
import { Link } from "react-router-dom";
import  UserDropdown  from '../UserDropdown';

export default function GrowthPlanningContent() {
  return (
    <div className="content-body">
    <UserDropdown />
    
        {/* row */}
       <div className="container-fluid">
            <div className="head-title">
                <h2>Growth Planning Tools</h2>	           					
            </div>
            <div className="card">
                <div className="card-body">
                <div className="row">
                    <div className="col-md-9 contentleft">
                    <p>There are Nine Organic Revenue Growth Drivers.  The 120 Growth Catalyst can help you activate, align, and improve the performance of all nine, generating controlled and repeatable growth.</p>
                    </div>
                    <div className="col-md-3">
                        <img src={window.location.origin+"/images/growth-planning.png"} alt="Growth Planning" title="Growth Planning" />
                    </div>
                </div>
                </div>
            </div>
            <div className="row sameheight">
                <div className="col-md-4 contentleft table-responsive">
                <div className="card">
                    <div className="card-body">
                    <h3>Annual Planning</h3>
                    <ul>
                        <li><Link to="/120-growth-benchmark">1. 120 Growth Benchmark</Link></li>
                        <li><Link to="/growth-planning/10-5-3-1-Planning">2. 10-5-3-1 Planning</Link></li>
                        <li><Link to="/growth-planning/five-step-strategy">3. Five Step Strategy</Link></li>
                        <li><Link to="/growth-planning/brand-scorecard">4. Brand Scorecard</Link></li>
                        <li><Link to="/growth-planning/annual-growth-plan">5. Annual Growth Plan</Link></li>
                        <li><Link to="#">6. Marketing Plan</Link></li>
                        <li><Link to="#">7. Sales Elevation Plan</Link></li>
                        <li><Link to="#">8. Customer Communication Plan</Link></li>
                        <li><Link to="/growth-planning/pyramid-of-purpose">9. Pyaramid of Purpose</Link></li>
                        <li><Link to="/growth-planning/growth-progress-report">10. Growth Progress Report</Link></li>
                        <li><Link to="/growth-planning/whats-working-now">11. What's Working Now</Link></li>
                    </ul>
                    </div>   
                </div>
                </div>
                <div className="col-md-4 contentleft table-responsive">
                <div className="card">
                    <div className="card-body">
                    <h3>Quaterly &amp; Monthly Planning</h3>
                    <ul>
                        <li><Link to="/growth-planning/quarterly-and-monthly-planning">12. Quaterly &amp; Monthly Growth Planning</Link></li>
                        <li><Link to="/growth-planning/innovate-or-optimize">13. Innovate Or Optimize?</Link></li>
                        <li><Link to="/prescribe/find-the-Low-Hanging-Fruit">14. Find the Low-Hanging Fruit</Link></li>
                        <li><Link to="#">15. Marketing Plan</Link></li>
                        <li><Link to="#">16. Sales Elevation Plan</Link></li>
                        <li><Link to="#">17. Customer Communication Plan</Link></li>
                        <li><Link to="#">18. Cost Savings and waste Reduction</Link></li>
                        <li><Link to="#">19. Playbook Creation Template</Link></li>
                        <li><Link to="/growth-planning/pyramid-of-purpose">20. Pyaramid of Purpose</Link></li>
                        <li><Link to="/growth-planning/growth-progress-report">21. Growth Progress Report</Link></li>
                        <li><Link to="/growth-planning/whats-working-now">22. What's Working Now</Link></li>
                    </ul>
                    </div>   
                </div>
                </div>
                <div className="col-md-4 contentleft table-responsive">
                <div className="card">
                    <div className="card-body">
                    <h3>Weekly Planning</h3>
                    <ul>
                        <li><Link to="#">23. 15-Minute Growth Meeting</Link></li>
                        <li><Link to="#">24. Testing Secquence</Link></li>
                        <li><Link to="#">25. Testing Report</Link></li>
                        <li><Link to="#">26. Playbook Creation Template</Link></li>
                    </ul>
                    </div>   
                </div>
                </div>
            </div>
            </div>

    </div>
  )
}
