import React from 'react'
import SidebarNew from '../components/SidebarNew';
import Content from '../components/content/DashbaordContent';
import Footer from '../components/Footer';


export default function Dashboard() {
  return (
    <div>

      <div id="main-wrapper">
        
        <SidebarNew />

        <Content />

      </div>

      <Footer />

    </div>
  )
}


