import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import sum from "../../utils/sum";
import { Link } from 'react-router-dom';
import formatPrice from '../../utils/formatPrice';
import { getRevenueGenerationScorecard, modifyRevenueGenerationScorecard } from '../../state/features/RevenueGenerationScorecardSlice';

export default function RevenueGenerationScorecardContent() {

    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        RevenueGenerationScorecardF7: 0,
        RevenueGenerationScorecardF8: 0,
        RevenueGenerationScorecardF9: 0,
        RevenueGenerationScorecardF10: 0,
        RevenueGenerationScorecardF11: 0,
        RevenueGenerationScorecardF12: 0,
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getRevenueGenerationScorecard({user_id}))
        .unwrap()
        .then((response) => {
            console.log("response1",response.data)
            if(response!= undefined  && response.data.clientGrowth){
                let clientGrowth = response.data.clientGrowth[0];
                // console.log(clientGrowth)
                delete clientGrowth.id;
                delete clientGrowth.user_id;
                delete clientGrowth.created_at;
                delete clientGrowth.updated_at;
                const newObj = { ...Inputs }
                for (const key in clientGrowth) {
                    if (clientGrowth.hasOwnProperty(key)) {
                        if(clientGrowth[key])
                        newObj[key] = clientGrowth[key]
                    }
                }
                
                newObj['RevenueGenerationScorecardF12'] = sum(newObj['RevenueGenerationScorecardF7'], newObj['RevenueGenerationScorecardF8'], newObj['RevenueGenerationScorecardF9'], newObj['RevenueGenerationScorecardF10'], newObj['RevenueGenerationScorecardF11']);

                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                event.target.value = event.target.value ? event.target.value : 0
                dispatch(modifyRevenueGenerationScorecard({ user_id, ...Inputs,[event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                    // console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }
    
    useEffect(() => {
        let RevenueGenerationScorecardF12 = sum(Inputs.RevenueGenerationScorecardF7, Inputs.RevenueGenerationScorecardF8, Inputs.RevenueGenerationScorecardF9, Inputs.RevenueGenerationScorecardF10, Inputs.RevenueGenerationScorecardF11)

        setInputs({...Inputs, RevenueGenerationScorecardF12});

    }, [Inputs.RevenueGenerationScorecardF7, Inputs.RevenueGenerationScorecardF8, Inputs.RevenueGenerationScorecardF9, Inputs.RevenueGenerationScorecardF10, Inputs.RevenueGenerationScorecardF11]);

    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
            <div className="container-fluid">
                <div className="head-title">
                    <h3 className='preheading'>Tools - Competitive Strategy</h3>
                    <h1 className='mainheading mb-1'>Knowing Our Numbers Assessment</h1>
                </div>
                <Spinner />
                <div className="target-markets-score-card-table">
                    <div className="table-responsive">
                        <table className="table" style={{width: 1250}}>
                            <thead>
                                <tr>
                                    <th className='black-bg text-white font16px text-center' colSpan={2}>
                                        Activity
                                    </th>
                                    <th className='black-bg text-white font16px text-center'>
                                    Question
                                    </th>
                                    <th className='black-bg text-white text-center'>
                                        <span className='font14px'>Score</span><br/><span className='font12px'>(1=Low; 5=High)</span>
                                    </th>
                                    <th className='hide-content'></th>
                                    <th className='black-bg text-white font16px text-center'>
                                    Scoring Legend
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='black-bg text-white' rowSpan={4} style={{width: 60}}>
                                        <img src={window.location.origin+"/images/revenue-growth/image386.png"} />
                                    </td>
                                    <td className='bg-color-03 text-white font16px ms-text-left' rowSpan={4} style={{width: 150}}>
                                    Customer Retention
                                    </td>
                                    <td className='bg-color-light-blue' style={{width: 500}}>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>What is your customer attrition rate (churn rate)?</p>
                                        </div>
                                    </td>
                                    <td className='bg-color-01 padding-0' style={{width: 100}}>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                id="RevenueGenerationScorecardF7" 
                                                value={Inputs.RevenueGenerationScorecardF7} 
                                                onChange={(event) => setInputs({ ...Inputs, RevenueGenerationScorecardF7: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score Range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='hide-content' style={{width: 40}}></td>
                                    <td className='bg-color-light-blue' style={{width: 400}}>
                                        <div className="we-understand">
                                            <p className='font12px color-dark'>0 - Don't know; does not apply</p>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>Do you know how much revenue is lost each year due to customer attrition?</p>
                                        </div>
                                    </td>
                                    <td className='bg-color-01 padding-0'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                id="RevenueGenerationScorecardF8" 
                                                value={Inputs.RevenueGenerationScorecardF8} 
                                                onChange={(event) => setInputs({ ...Inputs, RevenueGenerationScorecardF8: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score Range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='hide-content'></td>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='font12px color-dark'>2 - We can estimate, but it may not be accurate</p>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>Do you know how many inactive customers are in your database right now?</p>
                                        </div>
                                    </td>
                                    <td className='bg-color-01 padding-0'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                id="RevenueGenerationScorecardF9" 
                                                value={Inputs.RevenueGenerationScorecardF9} 
                                                onChange={(event) => setInputs({ ...Inputs, RevenueGenerationScorecardF9: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score Range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='hide-content'></td>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='font12px color-dark'>3 - We can estimate with a high level of accuracy</p>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>What percentage of your inactive customers do you reactivate every year?</p>
                                        </div>
                                    </td>
                                    <td className='bg-color-01 padding-0'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                id="RevenueGenerationScorecardF10" 
                                                value={Inputs.RevenueGenerationScorecardF10} 
                                                onChange={(event) => setInputs({ ...Inputs, RevenueGenerationScorecardF10: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score Range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='hide-content'></td>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='font12px color-dark'>4 - We can get this number exactly</p>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='black-bg text-white'>
                                        <img src={window.location.origin+"/images/revenue-growth/image387.png"} />
                                    </td>
                                    <td className='bg-color-03 text-white font16px ms-text-left'>
                                    Customer Transactions
                                    </td>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>How many customers take advantage of your cross-selling, bundling, and point-of-sale promotions?</p>
                                        </div>
                                    </td>
                                    <td className='bg-color-01 padding-0'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                id="RevenueGenerationScorecardF11" 
                                                value={Inputs.RevenueGenerationScorecardF11} 
                                                onChange={(event) => setInputs({ ...Inputs, RevenueGenerationScorecardF11: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score Range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='hide-content'></td>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='font12px color-dark'>5 - We measure this regularly and know exactly</p>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='hide-content' colSpan={2}></td>
                                    <th className='bg-color-blue text-white text-center font16px fontBold'>
                                    Total
                                    </th>
                                    <th className='bg-color-blue text-white text-center font16px'>
                                    {formatPrice(Inputs.RevenueGenerationScorecardF12, 0)}
                                    </th>
                                    <td className='hide-content' colSpan={2}></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="nextInput">
                        <ul>
                            <li>
                                <Link className="next-link" to="/diagnose/customer-retention"> Return to Benchmark Questions
                                    <span className="icon-three">
                                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                                    </span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}