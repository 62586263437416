import React from 'react'
import  UserDropdown  from '../UserDropdown';

export default function TargetAccountPlanningContent() {
  return (
    <div className="content-body">
        <UserDropdown />
        {/* row */}
        <div className="container-fluid">
            <div className="head-title">
                <p className="mb-0">Sales &amp; Sales Management </p>
                <h2 className="mb-4">Target Account Planning </h2>
            </div>
            <div className="card">
                <div className="card-body pl-0">
                <div className="table-responsive table-dta">
                    {/* table 1 */}
                    <table className="table mb-0">
                    <thead>
                        <tr>
                        <th className="bg-color5 text-white">
                            <div className="target-acc">
                            Projected Cost to Date:
                            </div>
                        </th>
                        <td className="bg-color5 text-white">
                            <div className="target-acc1" />
                        </td>
                        </tr>
                        <tr>
                        <th className="bg-color1 text-white">
                            <div className="target-acc">
                            Targeted Account
                            </div>
                        </th>
                        <th className="bg-color1 text-white">Contact</th>
                        <th className="bg-color1 text-white">Key Goal/Message</th>
                        <th colSpan={4} className="bg-color1 text-white text-center-c">Whole Brain Thinking Style</th>
                        <th className="bg-color1 text-white">Vehicle Tool/Channel</th>
                        <th className="bg-color1 text-white">Response</th>
                        <th className="bg-color1 text-white">Notes</th>
                        <th className="bg-color1 text-white">Projected Cost</th>
                        <th className="bg-color1 text-white" />
                        <th className="bg-color1 text-white">Actual Cost</th>
                        <th className="bg-color1 text-white" />
                        </tr>
                    </thead>
                    {/* tbody 1 */}
                    <tbody>
                        <tr>
                        <th className="bg-color3 text-white">Top 10 Targeted Accounts</th>
                        <td className="bg-color3 text-white text-center-c" />
                        <td className="bg-color3 text-white text-center-c" />
                        <td className="bg-color3 text-white text-center-c">Analytical</td>
                        <td className="bg-color3 text-white text-center-c">Experimental</td>
                        <td className="bg-color3 text-white text-center-c">Practical</td>
                        <td className="bg-color3 text-white text-center-c">Relational</td>
                        <td className="bg-color3 text-white text-center-c" />
                        <td className="bg-color3 text-white text-center-c" />
                        <td className="bg-color3 text-white text-center-c" />
                        <td className="bg-color3 text-white text-center-c">Subtotal</td>
                        <td className="bg-color3 text-white text-center-c">
                            <div className="sub_number">
                            <div>$</div>
                            <div>-</div>
                            </div>
                        </td>
                        <td className="bg-color3 text-white text-center-c">Subtotal</td>
                        <td className="bg-color3 text-white text-center-c">
                            <div className="sub_number">
                            <div>$</div>
                            <div>-</div>
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <th className="bg-color2 text-font-size-15">Targeted Account</th>
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        </tr>
                        <tr>
                        <th className="bg-color2 text-font-size-15">Targeted Account</th>
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        </tr>
                        <tr>
                        <th className="bg-color2 text-font-size-15">Targeted Account</th>
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        </tr>
                        <tr>
                        <th className="bg-color2 text-font-size-15">Targeted Account</th>
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        </tr>
                        <tr>
                        <th className="bg-color2 text-font-size-15">Targeted Account</th>
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        </tr>
                        <tr>
                        <th className="bg-color2 text-font-size-15">Targeted Account</th>
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        </tr>
                        <tr>
                        <th className="bg-color2 text-font-size-15">Targeted Account</th>
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        </tr>
                        <tr>
                        <th className="bg-color2 text-font-size-15">Targeted Account</th>
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        </tr>
                        <tr>
                        <th className="bg-color2 text-font-size-15">Targeted Account</th>
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        </tr>
                        <tr>
                        <th className="bg-color2 text-font-size-15 border-bottom-0">Targeted Account</th>
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        </tr>
                    </tbody>
                    {/* tbody 2 */}
                    <tbody>
                        <tr>
                        <th className="bg-color3 text-white">Next 10 Targeted Accounts</th>
                        <td className="bg-color3 text-white text-center-c" />
                        <td className="bg-color3 text-white text-center-c" />
                        <td className="bg-color3 text-white text-center-c">Analytical</td>
                        <td className="bg-color3 text-white text-center-c">Experimental</td>
                        <td className="bg-color3 text-white text-center-c">Practical</td>
                        <td className="bg-color3 text-white text-center-c">Relational</td>
                        <td className="bg-color3 text-white text-center-c" />
                        <td className="bg-color3 text-white text-center-c" />
                        <td className="bg-color3 text-white text-center-c" />
                        <td className="bg-color3 text-white text-center-c">Subtotal</td>
                        <td className="bg-color3 text-white text-center-c">
                            <div className="sub_number">
                            <div>$</div>
                            <div>-</div>
                            </div>
                        </td>
                        <td className="bg-color3 text-white text-center-c">Subtotal</td>
                        <td className="bg-color3 text-white text-center-c">
                            <div className="sub_number">
                            <div>$</div>
                            <div>-</div>
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <th className="bg-color2 text-font-size-15" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        </tr>
                        <tr>
                        <th className="bg-color2 text-font-size-15" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        </tr>
                        <tr>
                        <th className="bg-color2 text-font-size-15" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        </tr>
                        <tr>
                        <th className="bg-color2 text-font-size-15" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        </tr>
                        <tr>
                        <th className="bg-color2 text-font-size-15" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        </tr>
                        <tr>
                        <th className="bg-color2 text-font-size-15" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        </tr>
                        <tr>
                        <th className="bg-color2 text-font-size-15" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        </tr>
                        <tr>
                        <th className="bg-color2 text-font-size-15" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        </tr>
                        <tr>
                        <th className="bg-color2 text-font-size-15" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        </tr>
                        <tr>
                        <th className="bg-color2 text-font-size-15 border-bottom-0" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        </tr>
                    </tbody>
                    {/* tbody 3 */}
                    <tbody>
                        <tr>
                        <th className="bg-color3 text-white">Next 10 Targeted Accounts</th>
                        <td className="bg-color3 text-white text-center-c" />
                        <td className="bg-color3 text-white text-center-c" />
                        <td className="bg-color3 text-white text-center-c">Analytical</td>
                        <td className="bg-color3 text-white text-center-c">Experimental</td>
                        <td className="bg-color3 text-white text-center-c">Practical</td>
                        <td className="bg-color3 text-white text-center-c">Relational</td>
                        <td className="bg-color3 text-white text-center-c" />
                        <td className="bg-color3 text-white text-center-c" />
                        <td className="bg-color3 text-white text-center-c" />
                        <td className="bg-color3 text-white text-center-c">Subtotal</td>
                        <td className="bg-color3 text-white text-center-c">
                            <div className="sub_number">
                            <div>$</div>
                            <div>-</div>
                            </div>
                        </td>
                        <td className="bg-color3 text-white text-center-c">Subtotal</td>
                        <td className="bg-color3 text-white text-center-c">
                            <div className="sub_number">
                            <div>$</div>
                            <div>-</div>
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <th className="bg-color2 text-font-size-15" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        </tr>
                        <tr>
                        <th className="bg-color2 text-font-size-15" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        </tr>
                        <tr>
                        <th className="bg-color2 text-font-size-15" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        </tr>
                        <tr>
                        <th className="bg-color2 text-font-size-15" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        </tr>
                        <tr>
                        <th className="bg-color2 text-font-size-15" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        </tr>
                        <tr>
                        <th className="bg-color2 text-font-size-15" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        </tr>
                        <tr>
                        <th className="bg-color2 text-font-size-15" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        </tr>
                        <tr>
                        <th className="bg-color2 text-font-size-15" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        </tr>
                        <tr>
                        <th className="bg-color2 text-font-size-15" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        </tr>
                        <tr>
                        <th className="bg-color2 text-font-size-15 border-bottom-0" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        <td className="bg-color2 text-white text-center-c" />
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body pl-0">
                <div className="table-responsive table-dta">
                    {/* table 1 */}
                    <table className="table mb-0">
                    <thead>
                        <tr>
                        <th className="bg-color5 text-white text-center-c" colSpan={15}> Q1 </th>
                        </tr>
                        <tr>
                        <th className="bg-color1 text-white text-center-c" colSpan={5}>January</th>
                        <th className="bg-color1 text-white text-center-c" colSpan={5}>February</th>
                        <th className="bg-color1 text-white text-center-c" colSpan={5}>March</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <th className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        </tr>
                        <tr>
                        <th className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                    </tbody>
                    </table>
                    {/* table 2 */}
                    <table className="table mb-0 mt-5">
                    <thead>
                        <tr>
                        <th className="bg-color5 text-white text-center-c" colSpan={15}> Q2 </th>
                        </tr>
                        <tr>
                        <th className="bg-color1 text-white text-center-c" colSpan={5}>April</th>
                        <th className="bg-color1 text-white text-center-c" colSpan={5}>May</th>
                        <th className="bg-color1 text-white text-center-c" colSpan={5}>June</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <th className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                    </tbody>
                    </table>
                    {/* table 3 */}
                    <table className="table mb-0 mt-5">
                    <thead>
                        <tr>
                        <th className="bg-color5 text-white text-center-c" colSpan={15}> Q3 </th>
                        </tr>
                        <tr>
                        <th className="bg-color1 text-white text-center-c" colSpan={5}>July</th>
                        <th className="bg-color1 text-white text-center-c" colSpan={5}>August</th>
                        <th className="bg-color1 text-white text-center-c" colSpan={5}>September</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <th className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                    </tbody>
                    </table>
                    {/* table 3 */}
                    <table className="table mb-0 mt-5">
                    <thead>
                        <tr>
                        <th className="bg-color5 text-white text-center-c" colSpan={15}> Q4 </th>
                        </tr>
                        <tr>
                        <th className="bg-color1 text-white text-center-c" colSpan={5}>October</th>
                        <th className="bg-color1 text-white text-center-c" colSpan={5}>November</th>
                        <th className="bg-color1 text-white text-center-c" colSpan={5}>December</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <th className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        <td className="bg-color3" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                        <tr>
                        <th className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        <td className="bg-color2" />
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}
