import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getInnovateOrOptimize, modifyInnovateOrOptimize } from "../../state/features/InnovateOrOptimizeSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';

export default function InnovateOrOptimizeContentNew() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        InnovateOrOptimizeD9 : '',
        InnovateOrOptimizeC13 : '',
        InnovateOrOptimizeC14 : '',
        InnovateOrOptimizeC15 : '',
        InnovateOrOptimizeC16 : '',
        InnovateOrOptimizeC17 : '',
        InnovateOrOptimizeC18 : '',
        InnovateOrOptimizeD13 : '',
        InnovateOrOptimizeD14 : '',
        InnovateOrOptimizeD15 : '',
        InnovateOrOptimizeD16 : '',
        InnovateOrOptimizeD17 : '',
        InnovateOrOptimizeD18 : '',
        InnovateOrOptimizeE13 : '',
        InnovateOrOptimizeE14 : '',
        InnovateOrOptimizeE15 : '',
        InnovateOrOptimizeE16 : '',
        InnovateOrOptimizeE17 : '',
        InnovateOrOptimizeE18 : '',
        InnovateOrOptimizeF13 : '',
        InnovateOrOptimizeF14 : '',
        InnovateOrOptimizeF15 : '',
        InnovateOrOptimizeF16 : '',
        InnovateOrOptimizeF17 : '',
        InnovateOrOptimizeF18 : '',
        InnovateOrOptimizeG13 : '',
        InnovateOrOptimizeG14 : '',
        InnovateOrOptimizeG15 : '',
        InnovateOrOptimizeG16 : '',
        InnovateOrOptimizeG17 : '',
        InnovateOrOptimizeG18 : ''
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getInnovateOrOptimize({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.growthPlanning)
            if(response!= undefined  && response.data.growthPlanning){
                let growthPlanning = response.data.growthPlanning[0];
                // console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key]
                    }
                }
                // console.log("newObj", newObj)
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyInnovateOrOptimize({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                    // console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }
    
    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
            <div className="container-fluid">
                <div className="head-title">
                    <h3 className='preheading'>Grow</h3>
                    <h1 className='mainheading mb-1'>Innovate Or Optimize</h1>
                </div>
                <div className="opportunity-page-table">
                    <div className="opportunity-page-table-inner opportunity-abl">
                        <Spinner/>
                        <div className="table-responsive mb-3">
                            {/* Transformative table 01 */}
                            <table className="table" style={{width: 1400}}>
                                <thead>
                                    <tr>
                                        <td className='black-bg text-white ms-text-left font18px'>Activity</td>
                                        <td colSpan={4} className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01">
                                                <textarea type="text" className="field-ipt bg-color-01 font12px color-dark"
                                                id="InnovateOrOptimizeD9"
                                                value={Inputs.InnovateOrOptimizeD9}
                                                onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeD9: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='hide-content' colSpan={5}></td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content' colSpan={2}></td>
                                        <td className='bg-color-blue-gray text-white text-center font14px'>Optimize</td>
                                        <td className='bg-color-blue-gray text-white text-center font14px'>Innovate</td>
                                        <td className='hide-content'></td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white' style={{width: 250}}>
                                            <div className="before-your justify-content-start">
                                                <img src={window.location.origin+"/images/innovate-optimize/image725.png"} />
                                                <span className='font14px fontBold text-white'> What Worked</span>
                                            </div>
                                        </td>
                                        <td className='black-bg text-white' style={{width: 250}}>
                                            <div className="before-your justify-content-start">
                                                <img src={window.location.origin+"/images/innovate-optimize/image726.png"} />
                                                <span className='font14px fontBold text-white'> What Didn't Work</span>
                                            </div>
                                        </td>
                                        <td className='black-bg ms-text-left text-white font11px fontLight' style={{width: 200}}>
                                            Can the activity be easily & inexpensively optimized for desired results?
                                        </td>
                                        <td className='black-bg ms-text-left text-white font11px fontLight' style={{width: 200}}>
                                            Innovate if optimization is expensive, difficult, or time prohibitive.
                                        </td>
                                        <td className='black-bg text-white' style={{width: 500}}>
                                            <div className="before-your justify-content-start">
                                                <img src={window.location.origin+"/images/innovate-optimize/image731.png"} />
                                                <span className='font14px fontBold text-white'> Ideas to Achieve Desired Improvement</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="bg-color-light-blue color-dark font11px"
                                                id="InnovateOrOptimizeC13"
                                                value={Inputs.InnovateOrOptimizeC13}
                                                onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeC13: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="bg-color-light-blue color-dark font11px"
                                                id="InnovateOrOptimizeD13"
                                                value={Inputs.InnovateOrOptimizeD13}
                                                onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeD13: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue-gray padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="bg-color-blue-gray color-dark font11px"
                                                id="InnovateOrOptimizeE13"
                                                value={Inputs.InnovateOrOptimizeE13}
                                                onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeE13: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue-gray padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="bg-color-blue-gray color-dark font11px"
                                                id="InnovateOrOptimizeF13"
                                                value={Inputs.InnovateOrOptimizeF13}
                                                onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeF13: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="bg-color-light-blue color-dark font11px"
                                                id="InnovateOrOptimizeG13"
                                                value={Inputs.InnovateOrOptimizeG13}
                                                onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeG13: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="bg-color-light-blue color-dark font11px"
                                                id="InnovateOrOptimizeC14"
                                                value={Inputs.InnovateOrOptimizeC14}
                                                onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeC14: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="bg-color-light-blue color-dark font11px"
                                                id="InnovateOrOptimizeD14"
                                                value={Inputs.InnovateOrOptimizeD14}
                                                onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeD14: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue-gray padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="bg-color-blue-gray color-dark font11px"
                                                id="InnovateOrOptimizeE14"
                                                value={Inputs.InnovateOrOptimizeE14}
                                                onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeE14: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue-gray padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="bg-color-blue-gray color-dark font11px"
                                                id="InnovateOrOptimizeF14"
                                                value={Inputs.InnovateOrOptimizeF14}
                                                onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeF14: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="bg-color-light-blue color-dark font11px"
                                                id="InnovateOrOptimizeG14"
                                                value={Inputs.InnovateOrOptimizeG14}
                                                onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeG14: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="bg-color-light-blue color-dark font11px"
                                                id="InnovateOrOptimizeC15"
                                                value={Inputs.InnovateOrOptimizeC15}
                                                onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeC15: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="bg-color-light-blue color-dark font11px"
                                                id="InnovateOrOptimizeD15"
                                                value={Inputs.InnovateOrOptimizeD15}
                                                onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeD15: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue-gray padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="bg-color-blue-gray color-dark font11px"
                                                id="InnovateOrOptimizeE15"
                                                value={Inputs.InnovateOrOptimizeE15}
                                                onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeE15: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue-gray padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="bg-color-blue-gray color-dark font11px"
                                                id="InnovateOrOptimizeF15"
                                                value={Inputs.InnovateOrOptimizeF15}
                                                onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeF15: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="bg-color-light-blue color-dark font11px"
                                                id="InnovateOrOptimizeG15"
                                                value={Inputs.InnovateOrOptimizeG15}
                                                onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeG15: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="bg-color-light-blue color-dark font11px"
                                                id="InnovateOrOptimizeC16"
                                                value={Inputs.InnovateOrOptimizeC16}
                                                onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeC16: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="bg-color-light-blue color-dark font11px"
                                                id="InnovateOrOptimizeD16"
                                                value={Inputs.InnovateOrOptimizeD16}
                                                onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeD16: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue-gray padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="bg-color-blue-gray color-dark font11px"
                                                id="InnovateOrOptimizeE16"
                                                value={Inputs.InnovateOrOptimizeE16}
                                                onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeE16: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue-gray padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="bg-color-blue-gray color-dark font11px"
                                                id="InnovateOrOptimizeF16"
                                                value={Inputs.InnovateOrOptimizeF16}
                                                onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeF16: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="bg-color-light-blue color-dark font11px"
                                                id="InnovateOrOptimizeG16"
                                                value={Inputs.InnovateOrOptimizeG16}
                                                onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeG16: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="bg-color-light-blue color-dark font11px"
                                                id="InnovateOrOptimizeC17"
                                                value={Inputs.InnovateOrOptimizeC17}
                                                onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeC17: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="bg-color-light-blue color-dark font11px"
                                                id="InnovateOrOptimizeD17"
                                                value={Inputs.InnovateOrOptimizeD17}
                                                onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeD17: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue-gray padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="bg-color-blue-gray color-dark font11px"
                                                id="InnovateOrOptimizeE17"
                                                value={Inputs.InnovateOrOptimizeE17}
                                                onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeE17: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue-gray padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="bg-color-blue-gray color-dark font11px"
                                                id="InnovateOrOptimizeF17"
                                                value={Inputs.InnovateOrOptimizeF17}
                                                onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeF17: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="bg-color-light-blue color-dark font11px"
                                                id="InnovateOrOptimizeG17"
                                                value={Inputs.InnovateOrOptimizeG17}
                                                onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeG17: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
