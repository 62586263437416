import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getOptimizationAccelerator, modifyOptimizationAccelerator } from "../../state/features/OptimizationAcceleratorSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';

export default function OptimizationAcceleratorContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        OptimizationAcceleratorD7 : '',
        OptimizationAcceleratorC10 : '',
        OptimizationAcceleratorE10 : '',
        OptimizationAcceleratorF10 : '',
        OptimizationAcceleratorC11 : '',
        OptimizationAcceleratorE11 : '',
        OptimizationAcceleratorF11 : '',
        OptimizationAcceleratorC12 : '',
        OptimizationAcceleratorE12 : '',
        OptimizationAcceleratorF12 : '',
        OptimizationAcceleratorC13 : '',
        OptimizationAcceleratorE13 : '',
        OptimizationAcceleratorF13 : '',
        OptimizationAcceleratorC14 : '',
        OptimizationAcceleratorE14 : '',
        OptimizationAcceleratorF14 : '',
        OptimizationAcceleratorC15 : '',
        OptimizationAcceleratorE15 : '',
        OptimizationAcceleratorF15 : ''
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getOptimizationAccelerator({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.revenueGrowth)
            if(response!= undefined  && response.data.revenueGrowth){
                let revenueGrowth = response.data.revenueGrowth[0];
                console.log(revenueGrowth)
                delete revenueGrowth.id;
                delete revenueGrowth.user_id;
                delete revenueGrowth.created_at;
                delete revenueGrowth.updated_at;
                const newObj = { ...Inputs }
                for (const key in revenueGrowth) {
                    if (revenueGrowth.hasOwnProperty(key)) {
                        if(revenueGrowth[key])
                        newObj[key] = revenueGrowth[key]
                    }
                }
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyOptimizationAccelerator({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }
    
  return (
    <div className="content-body">
        <UserDropdown />
        {/* row */}
        <div className="container-fluid">
            <div className="head-title">
                <p className="mb-0">Innovation</p>
                <h2 className="mb-4 mt-0">Optimization Accelerator</h2>
            </div>
            <Spinner />
            <div className="card">
                <div className="card-body pl-0">
                <div className="table-responsive table-dta">
                    {/* table 1 */}
                    <table className="table mb-5">
                    <tbody>
                        <tr>
                        <td colSpan={4} className="width-20 text-white bg-color1 padding-10">
                            <div className="optimization-div">
                            <div className="White-Hat-title-agile">Activity</div>
                            </div>
                        </td>
                        <td className="width-2 bg-color2 text-white">
                            <div className="optimization-div">
                            <div className="White-Hat-title-agile" />
                            <input type="text" className="form-control inputText"
                                id="OptimizationAcceleratorD7"
                                value={Inputs.OptimizationAcceleratorD7}
                                onChange={(event) => setInputs({ ...Inputs, OptimizationAcceleratorD7: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                            </div>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                    {/* table 2 */}
                    <table className="table mb-0">
                    <tbody>
                        <tr>
                        <td colSpan={4} className="width-20 text-white bg-color1 padding-10">
                            <div className="optimization-div">
                            <div className="White-Hat-title-agile">What Worked</div>
                            <img src="../images/optimization/worked.png" className="img-fluid" alt="worked" />
                            </div>
                        </td>
                        <td className="width-2 bg-color1 text-white">
                            <div className="optimization-div">
                            <div className="White-Hat-title-agile">What Didn't Work</div>
                            <img src="../images/optimization/what-did.png" className="img-fluid" alt="what-did" />
                            </div>
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color1">
                            <div className="optimization-div">
                            <div className="White-Hat-title-agile">Ideas for Improvement</div>
                            <img src="../images/optimization/ideas.png" className="img-fluid" alt="ideas" />
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4} className="width-20 height80">
                            <input type="text" className="form-control inputText"
                                id="OptimizationAcceleratorC10"
                                value={Inputs.OptimizationAcceleratorC10}
                                onChange={(event) => setInputs({ ...Inputs, OptimizationAcceleratorC10 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td className="width-2 ">
                            <input type="text" className="form-control inputText"
                                id="OptimizationAcceleratorE10"
                                value={Inputs.OptimizationAcceleratorE10}
                                onChange={(event) => setInputs({ ...Inputs, OptimizationAcceleratorE10 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td colSpan={4} className="width-20">
                            <input type="text" className="form-control inputText"
                                id="OptimizationAcceleratorF10"
                                value={Inputs.OptimizationAcceleratorF10}
                                onChange={(event) => setInputs({ ...Inputs, OptimizationAcceleratorF10 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4} className="width-20 height80">
                            <input type="text" className="form-control inputText"
                                id="OptimizationAcceleratorC11"
                                value={Inputs.OptimizationAcceleratorC11}
                                onChange={(event) => setInputs({ ...Inputs, OptimizationAcceleratorC11 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td className="width-2">
                            <input type="text" className="form-control inputText"
                                id="OptimizationAcceleratorE11"
                                value={Inputs.OptimizationAcceleratorE11}
                                onChange={(event) => setInputs({ ...Inputs, OptimizationAcceleratorE11 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td colSpan={4} className="width-20">
                            <input type="text" className="form-control inputText"
                                id="OptimizationAcceleratorF11"
                                value={Inputs.OptimizationAcceleratorF11}
                                onChange={(event) => setInputs({ ...Inputs, OptimizationAcceleratorF11 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4} className="width-20 height80">
                            <input type="text" className="form-control inputText"
                                id="OptimizationAcceleratorC12"
                                value={Inputs.OptimizationAcceleratorC12}
                                onChange={(event) => setInputs({ ...Inputs, OptimizationAcceleratorC12 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td className="width-2">
                            <input type="text" className="form-control inputText"
                                id="OptimizationAcceleratorE12"
                                value={Inputs.OptimizationAcceleratorE12}
                                onChange={(event) => setInputs({ ...Inputs, OptimizationAcceleratorE12 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td colSpan={4} className="width-20">
                            <input type="text" className="form-control inputText"
                                id="OptimizationAcceleratorF12"
                                value={Inputs.OptimizationAcceleratorF12}
                                onChange={(event) => setInputs({ ...Inputs, OptimizationAcceleratorF12 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4} className="width-20 height80">
                            <input type="text" className="form-control inputText"
                                id="OptimizationAcceleratorC13"
                                value={Inputs.OptimizationAcceleratorC13}
                                onChange={(event) => setInputs({ ...Inputs, OptimizationAcceleratorC13 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td className="width-2">
                            <input type="text" className="form-control inputText"
                                id="OptimizationAcceleratorE13"
                                value={Inputs.OptimizationAcceleratorE13}
                                onChange={(event) => setInputs({ ...Inputs, OptimizationAcceleratorE13 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td colSpan={4} className="width-20">
                            <input type="text" className="form-control inputText"
                                id="OptimizationAcceleratorF13"
                                value={Inputs.OptimizationAcceleratorF13}
                                onChange={(event) => setInputs({ ...Inputs, OptimizationAcceleratorF13 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4} className="width-20 height80">
                            <input type="text" className="form-control inputText"
                                id="OptimizationAcceleratorC14"
                                value={Inputs.OptimizationAcceleratorC14}
                                onChange={(event) => setInputs({ ...Inputs, OptimizationAcceleratorC14 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td className="width-2">
                            <input type="text" className="form-control inputText"
                                id="OptimizationAcceleratorE14"
                                value={Inputs.OptimizationAcceleratorE14}
                                onChange={(event) => setInputs({ ...Inputs, OptimizationAcceleratorE14 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td colSpan={4} className="width-20">
                            <input type="text" className="form-control inputText"
                                id="OptimizationAcceleratorF14"
                                value={Inputs.OptimizationAcceleratorF14}
                                onChange={(event) => setInputs({ ...Inputs, OptimizationAcceleratorF14 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4} className="width-20 height80">
                            <input type="text" className="form-control inputText"
                                id="OptimizationAcceleratorC15"
                                value={Inputs.OptimizationAcceleratorC15}
                                onChange={(event) => setInputs({ ...Inputs, OptimizationAcceleratorC15 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td className="width-2">
                            <input type="text" className="form-control inputText"
                                id="OptimizationAcceleratorE15"
                                value={Inputs.OptimizationAcceleratorE15}
                                onChange={(event) => setInputs({ ...Inputs, OptimizationAcceleratorE15 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td colSpan={4} className="width-20">
                            <input type="text" className="form-control inputText"
                                id="OptimizationAcceleratorF15"
                                value={Inputs.OptimizationAcceleratorF15}
                                onChange={(event) => setInputs({ ...Inputs, OptimizationAcceleratorF15 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}
