import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getQuestionsWithOptionsList, modifyUserScore } from "../../state/features/The120GrowthBenchmarkSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import { Link } from 'react-router-dom';

export default function LongTermGrowthContent() {

    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const userID = users.data.profile.id;
    const [questionOptionList, setQuestionOptionList] = useState([]);
    const [userSelectedOptionIds, setUserSelectedOptionIds] = useState([]);
    
    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(clearMessage());
        dispatch(getQuestionsWithOptionsList({user_id : userID, slug : "enterprise-value"}))
        .unwrap()
        .then((response) => {
          console.log("response",response.data.questionOptionList)
            if(response!= undefined){
                //console.log(response.data);
                setQuestionOptionList(response.data.questionOptionList)
                let optionIds = response.data.userScoreOption && response.data.userScoreOption.optionIds ? response.data.userScoreOption.optionIds.split(',') : []
                console.log(optionIds)
                return optionIds
            }
        })
        .then((optionIds=[]) => {
            console.log(optionIds)
            setUserSelectedOptionIds(optionIds)
        })
        .catch((error) => {
          console.log("error",error)
            { message && alert.show(message) }
        });

    }, [dispatch])

    const onChangeHandler = (event) => {
        let is_checked = (event.target.checked) ? '1' : '0';
        let nameArr = event.target.id.split('_');
        console.log(nameArr)
        let is_multiselect = nameArr[3];
        let question_id = nameArr[1];
        let group = "checkbox_"+question_id;
        if(is_multiselect == 0){
            [...document.querySelectorAll("input[name="+group+"]")].map((input) => {
                if (input.checked && input.id !== event.target.id) {
                    input.checked = false
                }
                return null;
            })
        }
        
        let sendData = {user_id : userID, option_id: event.target.value, is_checked : is_checked, question_id : question_id}
        dispatch(modifyUserScore(sendData))
        .unwrap()
        .then((response) => {
          console.log("response",response)
            if(response!= undefined && response.status && response.status == 401){
                console.log('session expired...')
                window.location.reload()
            }
            else if(response!= undefined){
                console.log('success')
            }
        })
        .catch((error) => {
          console.log("error",error.status)
            { message && alert.show(message) }
        });
    }

  return (
    <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
            {/* row */}
            <div className="container-fluid">
                <div className="head-title">
                    <h3 className='preheading'>Diagnose - Benchmark Questions</h3>
                    <h1 className="mainheading mb-1">Enterprise Value</h1>
                </div>
                <div className="row remove-card-sd">
                <Spinner />
                <div className="col-xl-12 contentleft">
                    <div className="card">
                    <div className="card-body pl-0 mt-2" style={{maxWidth: 800}}>
                    { (questionOptionList) ? questionOptionList.map((category, catKey) => {
                    return(
                    <>
                        <h4 key={'cat'+catKey} style={{padding: '22px 0px 0px 10px'}} className='font24px mb-3'>{category.category_name} </h4>
                        { (category.questions) ? category.questions.map((quesObj, quesKey) => {
                            let qNoTextArr = quesObj.q_no_text.split('-')
                            return(
                            <>
                                <div className={`questns table-responsive ${qNoTextArr.includes('SQ') ? 'mt-n-2' : 'mb-3'}`}>
                                    <table className="table" style={{width: 800, marginBottom: 0}}>
                                        <thead key={'ques'+quesKey}>
                                            {qNoTextArr.includes('SQ') ? 
                                                <>
                                                {
                                                qNoTextArr && qNoTextArr[1] != undefined ? 
                                                    <>
                                                        <tr key={'ques_'+quesKey} className="thead-primary">
                                                        <th className='black-bg font12px text-white text-center' style={{width: 45}}>{qNoTextArr[1]}</th>
                                                            <th colSpan={2} className='black-bg font12px text-white' style={{width: 755}}>
                                                                <div className="do-you-want">
                                                                    <div className="do-you-content">{quesObj.common_question}</div>
                                                                    {(quesObj.q_slug) ? 
                                                                    <div className="do-you-want-link">
                                                                        <Link to={`/diagnose/${quesObj.q_slug}`}>Go To {quesObj.q_text}</Link>
                                                                    </div>
                                                                    : ''}
                                                                </div>
                                                            </th>
                                                        </tr>
                                                        <tr key={'subQues_'+quesKey}>
                                                            <td className='hide-content' style={{width: 45}}/>
                                                            <td colSpan={2} className='bg-color-blue-gray text-white font12px' style={{width: 755}}>{quesObj.bq_question}</td>
                                                        </tr>
                                                    </>
                                                    :     
                                                    <tr key={'subQues_'+quesKey}>
                                                        <td className='hide-content' style={{width: 45}}/>
                                                        <td colSpan={2} className='bg-color-blue-gray text-white font12px' style={{width: 755}}>{quesObj.bq_question}</td>
                                                    </tr>
                                                }
                                                </>
                                                :
                                                <tr key={'ques_'+quesKey} className="thead-primary">
                                                    <th className='black-bg font12px text-white text-center' style={{width: 45}}>{quesObj.q_no_text}</th>
                                                    <th colSpan={2} className='black-bg font12px text-white' style={{width: 755}}>
                                                        <div className="do-you-want">
                                                            <div className="do-you-content">{quesObj.bq_question}</div>
                                                            {(quesObj.q_slug) ? 
                                                            <div className="do-you-want-link">
                                                                <Link to={`/diagnose/${quesObj.q_slug}`}>Go To {quesObj.q_text}</Link>
                                                            </div>
                                                            : ''}
                                                        </div>
                                                    </th>
                                                </tr>
                                            } 
                                        </thead>
                                        <tbody>
                                        { (!quesObj.q_text && quesObj.options && quesObj.options[quesKey] != null) ? quesObj.options.map((optionObj, optKey) => {
                                                return(
                                                    <>
                                                        <tr key={'opt'+optKey}>
                                                            <td className='hide-content' style={{width: 45}}/>
                                                            <td className='bg-color-light-blue text-center text-black font12px' style={{width: 45}}>{String.fromCharCode(97+optKey)}.</td>
                                                            <td className='bg-color-light-blue text-black font12px' style={{width: 710}}>
                                                                <input 
                                                                type="checkbox" 
                                                                name={`checkbox_${optionObj.question_id}`} 
                                                                id={`checkbox_${optionObj.question_id}_${optionObj.option_id}_${optionObj.is_multiselect}`}  
                                                                defaultChecked={userSelectedOptionIds.includes(optionObj.option_id.toString())}
                                                                value={optionObj.option_id} 
                                                                onChange={(event) => {
                                                                    onChangeHandler(event)
                                                                }}
                                                                /> 
                                                                &nbsp;&nbsp;{ optionObj.option }
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            })
                                            : 
                                            <tr>
                                                <td style={{width: 45}}/>
                                                <td className='bg-color1' style={{width: 45}}/>
                                                <td className='bg-color1' style={{width: 710}}/>
                                            </tr>
                                        }
                                        </tbody>
                                    </table>
                                </div>  
                            </>
                            )
                            })
                                : <h4>No data</h4>
                            }
                        </>
                        )
                    }) : <h4>No data</h4>}
                </div>
                </div>
                </div>
            </div>
        </div>
    </div>

  )
}
