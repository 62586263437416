import React, { useState, useEffect } from 'react'
import { logout, changeUpdateStateFlag } from "../state/features/AuthenticationSlice";
import { RESET_ACTION } from '../state/action-creators';
import { useDispatch } from "react-redux";
import { useAlert } from 'react-alert'
import {useSWRConfig} from "swr";

 const Logout = () => {
    const { mutate } = useSWRConfig()
    const clearCache = () => mutate(
        () => true,
        undefined,
        { revalidate: false }
    )
    const alert = useAlert();
    const dispatch = useDispatch();
    dispatch(changeUpdateStateFlag(false))
    useEffect(() => {
        console.log("logout")
        // ...clear cache on logout
        clearCache()
        dispatch(logout());
        dispatch(RESET_ACTION());
        alert.show("Logout successfully.")
    }, [])
    

    return (
       <></>
    )
}

export default Logout;