import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getSixThinkingHats, modifySixThinkingHats } from "../../state/features/SixThinkingHatsSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';

export default function SixThinkingHatsContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        SixThinkingHatsH11 : '',
        SixThinkingHatsC15 : '',
        SixThinkingHatsI15 : '',
        SixThinkingHatsO15 : '',
        SixThinkingHatsC16 : '',
        SixThinkingHatsI16 : '',
        SixThinkingHatsO16 : '',
        SixThinkingHatsC17 : '',
        SixThinkingHatsI17 : '',
        SixThinkingHatsO17 : '',
        SixThinkingHatsC18 : '',
        SixThinkingHatsI18 : '',
        SixThinkingHatsO18 : '',
        SixThinkingHatsC19 : '',
        SixThinkingHatsI19 : '',
        SixThinkingHatsO19 : '',
        SixThinkingHatsC20 : '',
        SixThinkingHatsI20 : '',
        SixThinkingHatsO20 : '',
        SixThinkingHatsC21 : '',
        SixThinkingHatsI21 : '',
        SixThinkingHatsO21 : '',
        SixThinkingHatsC25 : '',
        SixThinkingHatsI25 : '',
        SixThinkingHatsO25 : '',
        SixThinkingHatsC26 : '',
        SixThinkingHatsI26 : '',
        SixThinkingHatsO26 : '',
        SixThinkingHatsC27 : '',
        SixThinkingHatsI27 : '',
        SixThinkingHatsO27 : '',
        SixThinkingHatsC28 : '',
        SixThinkingHatsI28 : '',
        SixThinkingHatsO28 : '',
        SixThinkingHatsC29 : '',
        SixThinkingHatsI29 : '',
        SixThinkingHatsO29 : '',
        SixThinkingHatsC30 : '',
        SixThinkingHatsI30 : '',
        SixThinkingHatsO30 : '',
        SixThinkingHatsC31 : '',
        SixThinkingHatsI31 : '',
        SixThinkingHatsO31 : ''
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getSixThinkingHats({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.revenueGrowth)
            if(response!= undefined  && response.data.revenueGrowth){
                let revenueGrowth = response.data.revenueGrowth[0];
                console.log(revenueGrowth)
                delete revenueGrowth.id;
                delete revenueGrowth.user_id;
                delete revenueGrowth.created_at;
                delete revenueGrowth.updated_at;
                const newObj = { ...Inputs }
                for (const key in revenueGrowth) {
                    if (revenueGrowth.hasOwnProperty(key)) {
                        if(revenueGrowth[key])
                        newObj[key] = revenueGrowth[key]
                    }
                }
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifySixThinkingHats({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

  return (
    <div className="content-body">
        <UserDropdown />
        {/* row */}
        <div className="container-fluid">
            <div className="head-title">
                <p className="mb-0">Innovation</p>
                <h2 className="mb-0 mt-0">Six Thinking Hats</h2>
                <p className="mb-5">Six Thinking Hats&nbsp;(also known as Listening Hats) is a strategic tool that can be used while generating ideas for a new product or service. This tool is especially useful because it forces individuals on the innovation team to consider options from a completely different perspective from which they normally would. Additionally, in a group setting, the&nbsp;Listening Hats&nbsp;tool allows every member of the team to view each option from many different perspectives at the same time. Each hat represents a different way of looking at the same idea. </p>
            </div>
            <Spinner />
            <div className="card">
                <div className="card-body pl-0">
                <div className="table-responsive table-dta">
                    <table className="table mb-0">
                    <thead>
                        <tr>
                        <td colSpan={4} className="bg-color1 text-white text-center-c width-20">
                            <div className="topic-focus-title">Topic Focus (enter problem or challenge here):</div>
                        </td>
                        <td className="padding-0 width-20 border-0">
                            <form className="Focused-planning-f six-thinking-form">
                            <input type="text" className="fucus-pla" 
                                id="SixThinkingHatsH11"
                                value={Inputs.SixThinkingHatsH11}
                                onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsH11: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                            </form>
                        </td>
                        </tr>
                    </thead>
                    </table>
                    {/* table 2 */}
                    <table className="table mb-0 mt-4">
                    <tbody>
                        <tr>
                        <td colSpan={4} className="width-20 text-white bg-color3">
                            <div className="white-hats">
                            <img src="../images/st-hats/white-hats.png" className="img-fluid" alt="white-hats" />
                            <div className="White-Hat-title">White Hat</div>
                            </div>
                        </td>
                        <td className="width-2 d-hidden">
                            <div className="cell-title-d" />
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color3">
                            <div className="white-hats">
                            <img src="../images/st-hats/red-hats.png" className="img-fluid" alt="red-hats" />
                            <div className="White-Hat-title">Red Hat</div>
                            </div>
                        </td>
                        <td className="width-2 d-hidden">
                            <div className="cell-title-d" />
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color3">
                            <div className="white-hats">
                            <img src="../images/st-hats/black-hats.png" className="img-fluid" alt="black-hats" />
                            <div className="White-Hat-title">Black Hat</div>
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4} className="width-20 text-white bg-color1">
                            What do we know?  What does this tell us?
                        </td>
                        <td className="width-2 d-hidden">
                            <div className="cell-title-d" />
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color1">
                            How does this make us feel?  How does the customer feel?
                        </td>
                        <td className="width-2 d-hidden">
                            <div className="cell-title-d" />
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color1">
                            What could go wrong?  What doesn't work?
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <div className="dfsdfdslk">
                                <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsC15"
                                    value={Inputs.SixThinkingHatsC15}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsC15: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        <td className="width-2 d-hidden">
                            <div className="cell-title-d" />
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsI15"
                                    value={Inputs.SixThinkingHatsI15}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsI15: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                        </td>
                        <td className="width-2 d-hidden">
                            <div className="cell-title-d" />
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsO15"
                                    value={Inputs.SixThinkingHatsO15}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsO15: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <div className="dfsdfdslk">
                                <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsC16"
                                    value={Inputs.SixThinkingHatsC16}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsC16: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        <td className="width-2 d-hidden">
                            <div className="cell-title-d" />
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsI16"
                                    value={Inputs.SixThinkingHatsI16}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsI16: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                        </td>
                        <td className="width-2 d-hidden">
                            <div className="cell-title-d" />
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsO16"
                                    value={Inputs.SixThinkingHatsO16}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsO16: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <div className="dfsdfdslk">
                                <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsC17"
                                    value={Inputs.SixThinkingHatsC17}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsC17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        <td className="width-2 d-hidden">
                            <div className="cell-title-d" />
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsI17"
                                    value={Inputs.SixThinkingHatsI17}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsI17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                        </td>
                        <td className="width-2 d-hidden">
                            <div className="cell-title-d" />
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsO17"
                                    value={Inputs.SixThinkingHatsO17}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsO17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <div className="dfsdfdslk">
                                <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsC18"
                                    value={Inputs.SixThinkingHatsC18}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsC18: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        <td className="width-2 d-hidden">
                            <div className="cell-title-d" />
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsI18"
                                    value={Inputs.SixThinkingHatsI18}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsI18: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                        </td>
                        <td className="width-2 d-hidden">
                            <div className="cell-title-d" />
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsO18"
                                    value={Inputs.SixThinkingHatsO18}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsO18: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <div className="dfsdfdslk">
                                <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsC19"
                                    value={Inputs.SixThinkingHatsC19}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsC19: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        <td className="width-2 d-hidden">
                            <div className="cell-title-d" />
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsI19"
                                    value={Inputs.SixThinkingHatsI19}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsI19: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                        </td>
                        <td className="width-2 d-hidden">
                            <div className="cell-title-d" />
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsO19"
                                    value={Inputs.SixThinkingHatsO19}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsO19: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <div className="dfsdfdslk">
                                <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsC20"
                                    value={Inputs.SixThinkingHatsC20}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsC20: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        <td className="width-2 d-hidden">
                            <div className="cell-title-d" />
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsI20"
                                    value={Inputs.SixThinkingHatsI20}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsI20: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                        </td>
                        <td className="width-2 d-hidden">
                            <div className="cell-title-d" />
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsO20"
                                    value={Inputs.SixThinkingHatsO20}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsO20: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <div className="dfsdfdslk">
                                <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsC21"
                                    value={Inputs.SixThinkingHatsC21}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsC21: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        <td className="width-2 d-hidden">
                            <div className="cell-title-d" />
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsI21"
                                    value={Inputs.SixThinkingHatsI21}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsI21: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                        </td>
                        <td className="width-2 d-hidden">
                            <div className="cell-title-d" />
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsO21"
                                    value={Inputs.SixThinkingHatsO21}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsO21: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                        </td>
                        </tr>
                    </tbody>
                    </table>
                    {/* table 3 */}
                    <table className="table mb-0 mt-4">
                    <tbody>
                        <tr>
                        <td colSpan={4} className="width-20 text-white bg-color3">
                            <div className="white-hats">
                            <img src="../images/st-hats/blue-hats.png" className="img-fluid" alt="blue-hats" />
                            <div className="White-Hat-title">Blue Hat</div>
                            </div>
                        </td>
                        <td className="width-2 d-hidden">
                            <div className="cell-title-d" />
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color3">
                            <div className="white-hats">
                            <img src="../images/st-hats/green-hats.png" className="img-fluid" alt="green-hats" />
                            <div className="White-Hat-title">Green Hat</div>
                            </div>
                        </td>
                        <td className="width-2 d-hidden">
                            <div className="cell-title-d" />
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color3">
                            <div className="white-hats">
                            <img src="../images/st-hats/yellow-hats.png" className="img-fluid" alt="yellow-hats" />
                            <div className="White-Hat-title">Black Hat</div>
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4} className="width-20 text-white bg-color1">
                            How do we  approach this?  What are the ground rules?
                        </td>
                        <td className="width-2 d-hidden">
                            <div className="cell-title-d" />
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color1">
                            How can we innovate?  What are some alternatives?
                        </td>
                        <td className="width-2 d-hidden">
                            <div className="cell-title-d" />
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color1">
                            What works?  What are the benefits?
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <div className="dfsdfdslk">
                                <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsC25"
                                    value={Inputs.SixThinkingHatsC25}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsC25: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        <td className="width-2 d-hidden">
                            <div className="cell-title-d" />
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsI25"
                                    value={Inputs.SixThinkingHatsI25}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsI25: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                        </td>
                        <td className="width-2 d-hidden">
                            <div className="cell-title-d" />
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsO25"
                                    value={Inputs.SixThinkingHatsO25}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsO25: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <div className="dfsdfdslk">
                                <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsC26"
                                    value={Inputs.SixThinkingHatsC26}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsC26: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        <td className="width-2 d-hidden">
                            <div className="cell-title-d" />
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsI26"
                                    value={Inputs.SixThinkingHatsI26}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsI26: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                        </td>
                        <td className="width-2 d-hidden">
                            <div className="cell-title-d" />
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsO26"
                                    value={Inputs.SixThinkingHatsO26}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsO26: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <div className="dfsdfdslk">
                                <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsC27"
                                    value={Inputs.SixThinkingHatsC27}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsC27: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        <td className="width-2 d-hidden">
                            <div className="cell-title-d" />
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsI27"
                                    value={Inputs.SixThinkingHatsI27}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsI27: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                        </td>
                        <td className="width-2 d-hidden">
                            <div className="cell-title-d" />
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsO27"
                                    value={Inputs.SixThinkingHatsO27}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsO27: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <div className="dfsdfdslk">
                                <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsC28"
                                    value={Inputs.SixThinkingHatsC28}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsC28: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        <td className="width-2 d-hidden">
                            <div className="cell-title-d" />
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsI28"
                                    value={Inputs.SixThinkingHatsI28}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsI28: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                        </td>
                        <td className="width-2 d-hidden">
                            <div className="cell-title-d" />
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsO28"
                                    value={Inputs.SixThinkingHatsO28}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsO28: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <div className="dfsdfdslk">
                                <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsC29"
                                    value={Inputs.SixThinkingHatsC29}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsC29: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        <td className="width-2 d-hidden">
                            <div className="cell-title-d" />
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsI29"
                                    value={Inputs.SixThinkingHatsI29}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsI29: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                        </td>
                        <td className="width-2 d-hidden">
                            <div className="cell-title-d" />
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsO29"
                                    value={Inputs.SixThinkingHatsO29}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsO29: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <div className="dfsdfdslk">
                                <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsC30"
                                    value={Inputs.SixThinkingHatsC30}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsC30: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        <td className="width-2 d-hidden">
                            <div className="cell-title-d" />
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsI30"
                                    value={Inputs.SixThinkingHatsI30}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsI30: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                        </td>
                        <td className="width-2 d-hidden">
                            <div className="cell-title-d" />
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsO30"
                                    value={Inputs.SixThinkingHatsO30}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsO30: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <div className="dfsdfdslk">
                                <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsC31"
                                    value={Inputs.SixThinkingHatsC31}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsC31: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        <td className="width-2 d-hidden">
                            <div className="cell-title-d" />
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsI31"
                                    value={Inputs.SixThinkingHatsI31}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsI31: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                        </td>
                        <td className="width-2 d-hidden">
                            <div className="cell-title-d" />
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color2">
                            <input type="text" className="form-control inputText"
                                    id="SixThinkingHatsO31"
                                    value={Inputs.SixThinkingHatsO31}
                                    onChange={(event) => setInputs({ ...Inputs, SixThinkingHatsO31: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}
