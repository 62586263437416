import React from 'react'
import Content from '../components/content/StateOfTheBusinessContent';

export default function StateOfTheBusiness() {
  return (
    <div>

      <div id="main-wrapper">

        <Content />

      </div>
    </div>
  )
}
