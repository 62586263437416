import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getCustomerJourneyMapping, modifyCustomerJourneyMapping } from "../../state/features/CustomerJourneyMappingSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';

export default function CustomerJourneyMappingContent() {

    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        CustomerJourneyMappingF11 : '',
        CustomerJourneyMappingM11 : '',
        CustomerJourneyMappingD15 : '',
        CustomerJourneyMappingG15 : '',
        CustomerJourneyMappingJ15 : '',
        CustomerJourneyMappingM15 : '',
        CustomerJourneyMappingP15 : '',
        CustomerJourneyMappingS15 : '',
        CustomerJourneyMappingD17 : '',
        CustomerJourneyMappingG17 : '',
        CustomerJourneyMappingJ17 : '',
        CustomerJourneyMappingM17 : '',
        CustomerJourneyMappingP17 : '',
        CustomerJourneyMappingS17 : '',
        CustomerJourneyMappingD19 : '',
        CustomerJourneyMappingG19 : '',
        CustomerJourneyMappingJ19 : '',
        CustomerJourneyMappingM19 : '',
        CustomerJourneyMappingP19 : '',
        CustomerJourneyMappingS19 : '',
        CustomerJourneyMappingD21 : '',
        CustomerJourneyMappingG21 : '',
        CustomerJourneyMappingJ21 : '',
        CustomerJourneyMappingM21 : '',
        CustomerJourneyMappingP21 : '',
        CustomerJourneyMappingS21 : '',
        CustomerJourneyMappingD23 : '',
        CustomerJourneyMappingG23 : '',
        CustomerJourneyMappingJ23 : '',
        CustomerJourneyMappingM23 : '',
        CustomerJourneyMappingP23 : '',
        CustomerJourneyMappingS23 : '',
        CustomerJourneyMappingD25 : '',
        CustomerJourneyMappingG25 : '',
        CustomerJourneyMappingJ25 : '',
        CustomerJourneyMappingM25 : '',
        CustomerJourneyMappingP25 : '',
        CustomerJourneyMappingS25 : '',
        CustomerJourneyMappingD27 : '',
        CustomerJourneyMappingG27 : '',
        CustomerJourneyMappingJ27 : '',
        CustomerJourneyMappingM27 : '',
        CustomerJourneyMappingP27 : '',
        CustomerJourneyMappingS27 : '',
        CustomerJourneyMappingD29 : '',
        CustomerJourneyMappingG29 : '',
        CustomerJourneyMappingJ29 : '',
        CustomerJourneyMappingM29 : '',
        CustomerJourneyMappingP29 : '',
        CustomerJourneyMappingS29 : '',
        CustomerJourneyMappingD31 : '',
        CustomerJourneyMappingG31 : '',
        CustomerJourneyMappingJ31 : '',
        CustomerJourneyMappingM31 : '',
        CustomerJourneyMappingP31 : '',
        CustomerJourneyMappingS31 : '',
        CustomerJourneyMappingD33 : '',
        CustomerJourneyMappingG33 : '',
        CustomerJourneyMappingJ33 : '',
        CustomerJourneyMappingM33 : '',
        CustomerJourneyMappingP33 : '',
        CustomerJourneyMappingS33 : '',
        CustomerJourneyMappingD35 : '',
        CustomerJourneyMappingG35 : '',
        CustomerJourneyMappingJ35 : '',
        CustomerJourneyMappingM35 : '',
        CustomerJourneyMappingP35 : '',
        CustomerJourneyMappingS35 : '',
        CustomerJourneyMappingD37 : '',
        CustomerJourneyMappingG37 : '',
        CustomerJourneyMappingJ37 : '',
        CustomerJourneyMappingM37 : '',
        CustomerJourneyMappingP37 : '',
        CustomerJourneyMappingS37 : ''
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getCustomerJourneyMapping({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.revenueGrowth)
            if(response!= undefined  && response.data.revenueGrowth){
                let revenueGrowth = response.data.revenueGrowth[0];
                console.log(revenueGrowth)
                delete revenueGrowth.id;
                delete revenueGrowth.user_id;
                delete revenueGrowth.created_at;
                delete revenueGrowth.updated_at;
                const newObj = { ...Inputs }
                for (const key in revenueGrowth) {
                    if (revenueGrowth.hasOwnProperty(key)) {
                        if(revenueGrowth[key])
                        newObj[key] = revenueGrowth[key]
                    }
                }
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyCustomerJourneyMapping({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

  return (
    <div className="content-body">
        <UserDropdown />
        {/* row */}
        <div className="container-fluid">
            <div className="head-title">
                <p className="mb-0"> Customer Retention, Loyalty &amp; Experience</p>
                <h2 className="mb-1"> Customer Journey Mapping </h2>
                <p className="mb-5">
                Customer Journey Mapping is the process of illustrating a customer’s experience with your company or a product, from the very first touch point to becoming a loyal customer. Customer Journey Maps are based on a particular customer persona, with a defined set of attributes, motivations, and emotions. The map is used to identify potential challenges the customer faces as they interact with the company through the different stages of product research, comparison, selection, and renewal. This can reveal opportunities to better serve the customer, which in turn can increase sales and loyalty.
                </p>
            </div>
            <Spinner />
            <div className="row">
                <div className="col-xl-12 contentleft">
                <div className="card">
                    <div className="card-body pl-0">
                    {/* table 1 */}
                    <div className="table-responsive table-dta">
                        <table className="table mb-4 customer-table">
                        <thead>
                            <tr>
                            <th colSpan={1} className="bg-color1 text-white">Customer:</th>
                            <th colSpan={2} className="bg-color2 text-white padding-0">
                                <form className="values-form-deve customer-journey-int-ot">
                                <input type="text"
                                    id="CustomerJourneyMappingF11"
                                    value={Inputs.CustomerJourneyMappingF11}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingF11: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                    placeholder="Option" />
                                </form>
                            </th>
                            <th colSpan={1} className="bg-color1 text-white d-hidden" />
                            <th colSpan={1} className="bg-color1 text-white">Goals:</th>
                            <th colSpan={3} className="bg-color2 padding-0">
                                <form className="values-form-deve customer-journey-int-ot">
                                <input type="text"
                                    id="CustomerJourneyMappingM11"
                                    value={Inputs.CustomerJourneyMappingM11}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingM11: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                    placeholder="Option"  />
                                </form>
                            </th>
                            </tr>
                        </thead>
                        </table>
                    </div>
                    {/* table 1 */}
                    <div className="table-responsive table-dta">
                        <table className="table mb-0 customer-table">
                        <thead>
                            <tr>
                            <th colSpan={1} className="bg-color3 text-white d-hidden">Stage 1</th>
                            <th colSpan={1} className="bg-color3 text-white">Stage 1</th>
                            <th colSpan={1} className="bg-color3 text-white">Stage 2</th>
                            <th colSpan={1} className="bg-color3 text-white">Stage 3</th>
                            <th colSpan={1} className="bg-color3 text-white">Stage 4</th>
                            <th colSpan={1} className="bg-color3 text-white">Stage 5</th>
                            <th colSpan={1} className="bg-color3 text-white">Stage 6</th>
                            </tr>
                            <tr>
                            <th className="bg-color1 text-white cs-font-size d-hidden">Description of Stage</th>
                            <th className="bg-color1 text-white cs-font-size">Description of Stage</th>
                            <th className="bg-color1 text-white cs-font-size">Description of Stage</th>
                            <th className="bg-color1 text-white cs-font-size">Description of Stage</th>
                            <th className="bg-color1 text-white cs-font-size">Description of Stage</th>
                            <th className="bg-color1 text-white cs-font-size">Description of Stage</th>
                            <th className="bg-color1 text-white cs-font-size">Description of Stage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <th className="bg-color1 text-white" rowSpan={3}>
                                <div className="customer-journey">
                                <img src="../images/customer-journey/touchpoint.png" className="img-fluid" alt="touchpoint" />
                                <div className="touchpoint-title">
                                    Touchpoints
                                </div>
                                </div>
                            </th>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="CustomerJourneyMappingD15"
                                    value={Inputs.CustomerJourneyMappingD15}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingD15: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="CustomerJourneyMappingG15"
                                    value={Inputs.CustomerJourneyMappingG15}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingG15: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="CustomerJourneyMappingJ15"
                                    value={Inputs.CustomerJourneyMappingJ15}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingJ15: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="CustomerJourneyMappingM15"
                                    value={Inputs.CustomerJourneyMappingM15}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingM15: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="CustomerJourneyMappingP15"
                                    value={Inputs.CustomerJourneyMappingP15}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingP15: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="CustomerJourneyMappingS15"
                                    value={Inputs.CustomerJourneyMappingS15}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingS15: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="CustomerJourneyMappingD17"
                                    value={Inputs.CustomerJourneyMappingD17}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingD17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="CustomerJourneyMappingG17"
                                    value={Inputs.CustomerJourneyMappingG17}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingG17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="CustomerJourneyMappingJ17"
                                    value={Inputs.CustomerJourneyMappingJ17}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingJ17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="CustomerJourneyMappingM17"
                                    value={Inputs.CustomerJourneyMappingM17}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingM17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="CustomerJourneyMappingP17"
                                    value={Inputs.CustomerJourneyMappingP17}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingP17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="CustomerJourneyMappingS17"
                                    value={Inputs.CustomerJourneyMappingS17}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingS17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="CustomerJourneyMappingD19"
                                    value={Inputs.CustomerJourneyMappingD19}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingD19: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="CustomerJourneyMappingG19"
                                    value={Inputs.CustomerJourneyMappingG19}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingG19: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="CustomerJourneyMappingJ19"
                                    value={Inputs.CustomerJourneyMappingJ19}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingJ19: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="CustomerJourneyMappingM19"
                                    value={Inputs.CustomerJourneyMappingM19}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingM19: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="CustomerJourneyMappingP19"
                                    value={Inputs.CustomerJourneyMappingP19}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingP19: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="CustomerJourneyMappingS19"
                                    value={Inputs.CustomerJourneyMappingS19}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingS19: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <th className="bg-color1 text-white" rowSpan={3}>
                                <div className="customer-journey">
                                <img src="../images/customer-journey/thinking.png" className="img-fluid" alt="thinking" />
                                <div className="touchpoint-title">
                                    Thinking
                                </div>
                                </div>
                            </th>
                            <td className="bg-color3">
                                <input type="text" className="inputTextf"
                                    id="CustomerJourneyMappingD21"
                                    value={Inputs.CustomerJourneyMappingD21}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingD21: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3">
                                <input type="text" className="inputTextf"
                                    id="CustomerJourneyMappingG21"
                                    value={Inputs.CustomerJourneyMappingG21}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingG21: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3">
                                <input type="text" className="inputTextf"
                                    id="CustomerJourneyMappingJ21"
                                    value={Inputs.CustomerJourneyMappingJ21}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingJ21: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3">
                                <input type="text" className="inputTextf"
                                    id="CustomerJourneyMappingM21"
                                    value={Inputs.CustomerJourneyMappingM21}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingM21: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3">
                                <input type="text" className="inputTextf"
                                    id="CustomerJourneyMappingP21"
                                    value={Inputs.CustomerJourneyMappingP21}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingP21: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3">
                                <input type="text" className="inputTextf"
                                    id="CustomerJourneyMappingS21"
                                    value={Inputs.CustomerJourneyMappingS21}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingS21: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color3">
                                <input type="text" className="inputTextf"
                                    id="CustomerJourneyMappingD23"
                                    value={Inputs.CustomerJourneyMappingD23}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingD23: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3">
                                <input type="text" className="inputTextf"
                                    id="CustomerJourneyMappingG23"
                                    value={Inputs.CustomerJourneyMappingG23}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingG23: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3">
                                <input type="text" className="inputTextf"
                                    id="CustomerJourneyMappingJ23"
                                    value={Inputs.CustomerJourneyMappingJ23}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingJ23: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3">
                                <input type="text" className="inputTextf"
                                    id="CustomerJourneyMappingM23"
                                    value={Inputs.CustomerJourneyMappingM23}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingM23: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3">
                                <input type="text" className="inputTextf"
                                    id="CustomerJourneyMappingP23"
                                    value={Inputs.CustomerJourneyMappingP23}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingP23: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3">
                                <input type="text" className="inputTextf"
                                    id="CustomerJourneyMappingS23"
                                    value={Inputs.CustomerJourneyMappingS23}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingS23: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color3">
                                <input type="text" className="inputTextf"
                                    id="CustomerJourneyMappingD25"
                                    value={Inputs.CustomerJourneyMappingD25}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingD25: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3">
                                <input type="text" className="inputTextf"
                                    id="CustomerJourneyMappingG25"
                                    value={Inputs.CustomerJourneyMappingG25}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingG25: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3">
                                <input type="text" className="inputTextf"
                                    id="CustomerJourneyMappingJ25"
                                    value={Inputs.CustomerJourneyMappingJ25}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingJ25: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3">
                                <input type="text" className="inputTextf"
                                    id="CustomerJourneyMappingM25"
                                    value={Inputs.CustomerJourneyMappingM25}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingM25: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3">
                                <input type="text" className="inputTextf"
                                    id="CustomerJourneyMappingP25"
                                    value={Inputs.CustomerJourneyMappingP25}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingP25: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3">
                                <input type="text" className="inputTextf"
                                    id="CustomerJourneyMappingS25"
                                    value={Inputs.CustomerJourneyMappingS25}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingS25: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <th className="bg-color1 text-white" rowSpan={3}>
                                <div className="customer-journey">
                                <img src="../images/customer-journey/feeling.png" className="img-fluid" alt="feeling" />
                                <div className="touchpoint-title">
                                    Feeling
                                </div>
                                </div>
                            </th>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="CustomerJourneyMappingD27"
                                    value={Inputs.CustomerJourneyMappingD27}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingD27: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="CustomerJourneyMappingG27"
                                    value={Inputs.CustomerJourneyMappingG27}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingG27: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="CustomerJourneyMappingJ27"
                                    value={Inputs.CustomerJourneyMappingJ27}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingJ27: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="CustomerJourneyMappingM27"
                                    value={Inputs.CustomerJourneyMappingM27}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingM27: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="CustomerJourneyMappingP27"
                                    value={Inputs.CustomerJourneyMappingP27}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingP27: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="CustomerJourneyMappingS27"
                                    value={Inputs.CustomerJourneyMappingS27}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingS27: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="CustomerJourneyMappingD29"
                                    value={Inputs.CustomerJourneyMappingD29}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingD29: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="CustomerJourneyMappingG29"
                                    value={Inputs.CustomerJourneyMappingG29}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingG29: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="CustomerJourneyMappingJ29"
                                    value={Inputs.CustomerJourneyMappingJ29}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingJ29: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="CustomerJourneyMappingM29"
                                    value={Inputs.CustomerJourneyMappingM29}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingM29: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="CustomerJourneyMappingP29"
                                    value={Inputs.CustomerJourneyMappingP29}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingP29: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="CustomerJourneyMappingS29"
                                    value={Inputs.CustomerJourneyMappingS29}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingS29: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="CustomerJourneyMappingD31"
                                    value={Inputs.CustomerJourneyMappingD31}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingD31: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="CustomerJourneyMappingG31"
                                    value={Inputs.CustomerJourneyMappingG31}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingG31: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="CustomerJourneyMappingJ31"
                                    value={Inputs.CustomerJourneyMappingJ31}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingJ31: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="CustomerJourneyMappingM31"
                                    value={Inputs.CustomerJourneyMappingM31}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingM31: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="CustomerJourneyMappingP31"
                                    value={Inputs.CustomerJourneyMappingP31}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingP31: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="CustomerJourneyMappingS31"
                                    value={Inputs.CustomerJourneyMappingS31}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingS31: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <th className="bg-color1 text-white" rowSpan={3}>
                                <div className="customer-journey">
                                <img src="../images/customer-journey/opportunity.png" className="img-fluid" alt="opportunity" />
                                <div className="touchpoint-title">
                                    Opportunity
                                </div>
                                </div>
                            </th>
                            <td className="bg-color3">
                                <input type="text" className="inputTextf"
                                    id="CustomerJourneyMappingD33"
                                    value={Inputs.CustomerJourneyMappingD33}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingD33: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3">
                                <input type="text" className="inputTextf"
                                    id="CustomerJourneyMappingG33"
                                    value={Inputs.CustomerJourneyMappingG33}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingG33: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3">
                                <input type="text" className="inputTextf"
                                    id="CustomerJourneyMappingJ33"
                                    value={Inputs.CustomerJourneyMappingJ33}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingJ33: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3">
                                <input type="text" className="inputTextf"
                                    id="CustomerJourneyMappingM33"
                                    value={Inputs.CustomerJourneyMappingM33}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingM33: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3">
                                <input type="text" className="inputTextf"
                                    id="CustomerJourneyMappingP33"
                                    value={Inputs.CustomerJourneyMappingP33}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingP33: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3">
                                <input type="text" className="inputTextf"
                                    id="CustomerJourneyMappingS33"
                                    value={Inputs.CustomerJourneyMappingS33}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingS33: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color3">
                                <input type="text" className="inputTextf"
                                    id="CustomerJourneyMappingD35"
                                    value={Inputs.CustomerJourneyMappingD35}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingD35: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3">
                                <input type="text" className="inputTextf"
                                    id="CustomerJourneyMappingG35"
                                    value={Inputs.CustomerJourneyMappingG35}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingG35: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3">
                                <input type="text" className="inputTextf"
                                    id="CustomerJourneyMappingJ35"
                                    value={Inputs.CustomerJourneyMappingJ35}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingJ35: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3">
                                <input type="text" className="inputTextf"
                                    id="CustomerJourneyMappingM35"
                                    value={Inputs.CustomerJourneyMappingM35}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingM35: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3">
                                <input type="text" className="inputTextf"
                                    id="CustomerJourneyMappingP35"
                                    value={Inputs.CustomerJourneyMappingP35}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingP35: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3">
                                <input type="text" className="inputTextf"
                                    id="CustomerJourneyMappingS35"
                                    value={Inputs.CustomerJourneyMappingS35}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingS35: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color3">
                                <input type="text" className="inputTextf"
                                    id="CustomerJourneyMappingD37"
                                    value={Inputs.CustomerJourneyMappingD37}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingD37: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3">
                                <input type="text" className="inputTextf"
                                    id="CustomerJourneyMappingG37"
                                    value={Inputs.CustomerJourneyMappingG37}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingG37: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3">
                                <input type="text" className="inputTextf"
                                    id="CustomerJourneyMappingJ37"
                                    value={Inputs.CustomerJourneyMappingJ37}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingJ37: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3">
                                <input type="text" className="inputTextf"
                                    id="CustomerJourneyMappingM37"
                                    value={Inputs.CustomerJourneyMappingM37}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingM37: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3">
                                <input type="text" className="inputTextf"
                                    id="CustomerJourneyMappingP37"
                                    value={Inputs.CustomerJourneyMappingP37}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingP37: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color3">
                                <input type="text" className="inputTextf"
                                    id="CustomerJourneyMappingS37"
                                    value={Inputs.CustomerJourneyMappingS37}
                                    onChange={(event) => setInputs({ ...Inputs, CustomerJourneyMappingS37: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>                                          
                </div>
            </div>
        </div>
    </div>
  )
}
