import React, { useState, useEffect } from 'react'
import { useNavigate, useSearchParams  } from "react-router-dom";
import { useAlert } from 'react-alert'
import { useSelector, useDispatch } from 'react-redux';
import ManageBrandingService from "../../services/manageBranding.service";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import UserDropdown from '../UserDropdown'
import { userData } from "../../state/features/AuthenticationSlice";
import { Spinner } from '../spinner';
import {useSWRConfig} from "swr";

export default function ProfileContent() {
    const navigate = useNavigate();  
    const { fallback, mutate, isLoading } = useSWRConfig()

    const users = useSelector(userData);
    const userID = users.data.profile.id;

    const alert = useAlert();
    const [loader, setLoader] = useState(false)
    useEffect(() => {
        setLoader(true)
        if(fallback && fallback.settings.is_allow_branding == 1){
            setValue("siteName", fallback.settings.siteName)
            setValue("phone", fallback.settings.phone)
            setValue("email", fallback.settings.email)
            setValue("website", fallback.settings.website)
            setValue("copyRightText", fallback.settings.copyRightText)
            setLoader(false)
        } 
        else if(fallback && fallback.settings.is_allow_branding == 0){
            alert.show("Manage Branding feature is disabled now.")
            setTimeout(function() {
                navigate('/profile') // you will go profile page
            }, 2000); 
        }
        else{
            setLoader(false)
        }
    }, [fallback]);

    function getExtension(fileName){
        return fileName ? fileName.split('.').pop() : '';
    }
    async function getImageDimensions(file) {
        let img = new Image();
        img.src = URL.createObjectURL(file);
        await img.decode();
        let width = img.width;
        let height = img.height;
        return {
            width,
            height,
        }
    }

    const profileSchema = Yup.object().shape({
        siteName: Yup.string().nullable().required('Site name is required.'), 
        email: Yup.string().nullable().email('Enter valid email id.').required('Email id is required.'),
        phone: Yup.string().nullable().required('Phone is required.'), 
        website: Yup.string().nullable().required('Website link is required.'), 
        copyRightText: Yup.string().nullable().required('copyRightText is required.'),
        logo: Yup.mixed()
        .test({
          message: 'Please provide a supported file type',
          test: async (file, context) => {
            if(file && file[0]){
                const isValid = ['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp'].includes(await getExtension(file[0]?.name));
                if (!isValid) context?.createError();
                return isValid;
            }
            return true;
          }
        })
        .test({
          message: `Logo should be of 200 X 72 pixels or less than equal to 1250 X 500`,
          test: async (file) => {
            if(file && file[0]){
                const {width, height } = await getImageDimensions(file[0])
                console.log(width, height)
                const isValid = ((width == 200 || width <= 1250) && (height == 72 || height <= 500)) ? true : false;
                return isValid;
            }
            return true
          }
        }) 
    });
    const formOptions = { resolver: yupResolver(profileSchema) }
    const { register, setValue, formState: { errors, isSubmitting }, handleSubmit, } = useForm(formOptions);

    const onSubmit = formValue  => {
        setLoader(true)
       
        const { siteName, phone, email, website, copyRightText} = formValue
        let formData = new FormData();
        if(formValue.logo[0])
        formData.append('logo',  formValue.logo[0]);
        formData.append('user_id', userID);
        formData.append('siteName', siteName);
        formData.append('phone', phone);
        formData.append('email', email);
        formData.append('website', website);
        formData.append('copyRightText', copyRightText);

        ManageBrandingService.updateManageBranding(userID, formData)
            .then(() => {
                setLoader(false)
                mutate(`/settings/${userID}`,true);
                { alert.show("Branding successfully updated.") }
            })
            .catch((error) => {
                setLoader(false)
                { error && alert.show(error.response.data.message) }
            });
    }


    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
            {/* row */}
            <div className="container-fluid">
                <div className="head-title">
                    <h3 className='preheading'>Manage Branding</h3>
                    <h1 className='mainheading mb-3'>Settings</h1>
                </div>
                <Spinner />
                <div className="customer-transformation branding-form">
                    <div className="customer-transformation-inner">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label className='fontBold font12px' htmlFor="formGroupExampleInput">Change Site Logo</label>
                            <br/>
                            <input type="file" {...register("logo")} accept="image/*" /><br/>
                            <span style={{ color: 'red' }}>{errors.logo?.message}</span>
                        </div>
                        <div className="form-group mt-4">
                            <label className='fontBold font12px' htmlFor="formGroupExampleInput">Site Name</label>
                            <input
                                type="text"
                                className="form-control text-black font12px"
                                {...register("siteName")}
                                placeholder="Site name"
                            /> 
                            <span style={{ color: 'red' }}>{errors.siteName?.message}</span>
                        </div>
                        <div className="form-group mt-4">
                            <label className='fontBold font12px' htmlFor="formGroupExampleInput2">Site Email</label>
                            <input
                                type="text"
                                className="form-control text-black font12px"
                                {...register("email")}
                                placeholder="Email address"
                            />
                            <span style={{ color: 'red' }}>{errors.email?.message}</span>
                        </div>
                        <div className="form-group mt-4">
                            <label className='fontBold font12px' htmlFor="formGroupExampleInput3">Site Contact</label>
                            <input
                                type="text"
                                className="form-control text-black font12px"
                                {...register("phone")}
                                placeholder="Contact No."
                            />
                            <span style={{ color: 'red' }}>{errors.phone?.message}</span>
                        </div>
                        <div className="form-group mt-4">
                            <label className='fontBold font12px' htmlFor="formGroupExampleInput4">Site Website</label>
                            <input
                                type="text"
                                className="form-control text-black font12px"
                                {...register("website")}
                                placeholder="website link"
                            />
                            <span style={{ color: 'red' }}>{errors.website?.message}</span>
                        </div>
                        <div className="form-group mt-4">
                            <label className='fontBold font12px' htmlFor="formGroupExampleInput5">Site copyRightText</label>
                            <input
                                type="text"
                                className="form-control text-black font12px"
                                {...register("copyRightText")}
                                placeholder="copyRightText"
                            />
                            <span style={{ color: 'red' }}>{errors.copyRightText?.message}</span>
                        </div>
                        {
                            isLoading || loader
                                ?
                                <button className="submitBtn mt-3">
                                    <div className="d-flex align-items-center">
                                        <strong>Update...</strong>
                                        <div className="spinner-border ml-auto" role="status" aria-hidden="true" />
                                    </div>
                                </button>

                                :
                                <button type='submit' className="submitBtn mt-3"> Update </button>
                        }
                    </form>
                    </div>
                </div>
            </div>
        </div>
    )
}