import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import { Link } from 'react-router-dom';
import { getPreBenchmarkSurvey, modifyPreBenchmarkSurvey } from '../../state/features/PreBenchmarkSurveySlice';
import checkRange from '../../utils/checkRange';

const PreBenchmarkSurvey = () => {

    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        PreBenchmarkSurveyE20: "",
        PreBenchmarkSurveyE22: "",
        PreBenchmarkSurveyE24: "",
        PreBenchmarkSurveyE57: "",
        PreBenchmarkSurveyE59: "",
        PreBenchmarkSurveyE68: "",
        PreBenchmarkSurveyE70: "",
        PreBenchmarkSurveyG12: "",
        PreBenchmarkSurveyG13: "",
        PreBenchmarkSurveyG14: "",
        PreBenchmarkSurveyG15: "",
        PreBenchmarkSurveyG16: "",
        PreBenchmarkSurveyG17: "",
        PreBenchmarkSurveyG18: "",
        PreBenchmarkSurveyG20: "",
        PreBenchmarkSurveyG22: "",
        PreBenchmarkSurveyG24: "",
        PreBenchmarkSurveyG28: "",
        PreBenchmarkSurveyG29: "",
        PreBenchmarkSurveyG30: "",
        PreBenchmarkSurveyG31: "",
        PreBenchmarkSurveyG32: "",
        PreBenchmarkSurveyG36: "",
        PreBenchmarkSurveyG37: "",
        PreBenchmarkSurveyG38: "",
        PreBenchmarkSurveyG39: "",
        PreBenchmarkSurveyG40: "",
        PreBenchmarkSurveyG41: "",
        PreBenchmarkSurveyG42: "",
        PreBenchmarkSurveyG43: "",
        PreBenchmarkSurveyG44: "",
        PreBenchmarkSurveyG45: "",
        PreBenchmarkSurveyG46: "",
        PreBenchmarkSurveyG50: "",
        PreBenchmarkSurveyG51: "",
        PreBenchmarkSurveyG52: "",
        PreBenchmarkSurveyG53: "",
        PreBenchmarkSurveyG54: "",
        PreBenchmarkSurveyG55: "",
        PreBenchmarkSurveyG57: "",
        PreBenchmarkSurveyG59: "",
        PreBenchmarkSurveyG62: "",
        PreBenchmarkSurveyG63: "",
        PreBenchmarkSurveyG64: "",
        PreBenchmarkSurveyG65: "",
        PreBenchmarkSurveyG66: "",
        PreBenchmarkSurveyG68: "",
        PreBenchmarkSurveyG70: "",
        PreBenchmarkSurveyI12: "",
        PreBenchmarkSurveyI13: "",
        PreBenchmarkSurveyI14: "",
        PreBenchmarkSurveyI15: "",
        PreBenchmarkSurveyI16: "",
        PreBenchmarkSurveyI17: "",
        PreBenchmarkSurveyI18: "",
        PreBenchmarkSurveyI20: "",
        PreBenchmarkSurveyI22: "",
        PreBenchmarkSurveyI24: "",
        PreBenchmarkSurveyI28: "",
        PreBenchmarkSurveyI29: "",
        PreBenchmarkSurveyI30: "",
        PreBenchmarkSurveyI31: "",
        PreBenchmarkSurveyI32: "",
        PreBenchmarkSurveyI36: "",
        PreBenchmarkSurveyI37: "",
        PreBenchmarkSurveyI38: "",
        PreBenchmarkSurveyI39: "",
        PreBenchmarkSurveyI40: "",
        PreBenchmarkSurveyI41: "",
        PreBenchmarkSurveyI42: "",
        PreBenchmarkSurveyI43: "",
        PreBenchmarkSurveyI44: "",
        PreBenchmarkSurveyI45: "",
        PreBenchmarkSurveyI46: "",
        PreBenchmarkSurveyI50: "",
        PreBenchmarkSurveyI51: "",
        PreBenchmarkSurveyI52: "",
        PreBenchmarkSurveyI53: "",
        PreBenchmarkSurveyI54: "",
        PreBenchmarkSurveyI55: "",
        PreBenchmarkSurveyI57: "",
        PreBenchmarkSurveyI59: "",
        PreBenchmarkSurveyI62: "",
        PreBenchmarkSurveyI63: "",
        PreBenchmarkSurveyI64: "",
        PreBenchmarkSurveyI65: "",
        PreBenchmarkSurveyI66: "",
        PreBenchmarkSurveyI68: "",
        PreBenchmarkSurveyI70: "",
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getPreBenchmarkSurvey({user_id}))
        .unwrap()
        .then((response) => {
            console.log("response12",response.data)
            if(response!= undefined  && response.data.clientGrowth){
                let clientGrowth = response.data.clientGrowth[0];
                // console.log(clientGrowth)
                delete clientGrowth.id;
                delete clientGrowth.user_id;
                delete clientGrowth.created_at;
                delete clientGrowth.updated_at;
                const newObj = { ...Inputs }
                for (const key in clientGrowth) {
                    if (clientGrowth.hasOwnProperty(key)) {
                        if(clientGrowth[key])
                        newObj[key] = clientGrowth[key]
                        // console.log(clientGrowth[key])
                    }
                }
                
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyPreBenchmarkSurvey({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                    // console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
            {/* row */}
            <div className="container-fluid">
                <div className="head-title mb-5">
                    <h3 className='preheading'>Diagnose</h3>
                    <h1 className='mainheading mb-1'>Pre-Benchmark Survey</h1>
                </div>
                <div className="prebenchmark-survey">
                    <div className="prebenchmark-survey-inner">
                        <div className="table-responsive">
                            {/* table 01 */}
                            <table className="table table-01" style={{width: 1230}}>
                                <thead>
                                    <tr>
                                        <td colSpan={5}>
                                            <p className='font11px color-dark mb-3'>The purpose of this survey is to better understand your goals, challenges, and how you currently generate sales.  Your individual information will never be shared with another party.
                                            <br/><br/>
                                            <strong>Please place your answers in the orange boxes to the right or below the questions.  Please place any questions and comments in the cells to the right.</strong></p>
                                        </td>
                                    </tr>
                                    <tr className='row-one'>
                                        <td className='hide-content' colSpan={4}></td>
                                        <td className='black-bg text-white text-center font11px' >Rank on a Scale<br />of 1-5</td>
                                        <td className="hide-content" colSpan={3}>18</td>
                                    </tr>
                                    <tr className='row-two'>
                                        <td className='black-bg text-white font12px text-center' style={{width: 45}}>1</td>
                                        <td className='black-bg text-white font12px' colSpan={2}>What external factors currently make growth challenging?</td>
                                        <td className='hide-content' style={{width: 30}}></td>
                                        <td className='black-bg text-white text-center font10px' style={{width: 130}}>(1=low; 5=high)</td>
                                        <td className='hide-content' style={{width: 30}}></td>
                                        <td className='black-bg text-white font16px' style={{width: 250}}>
                                            <div className=''>Comments</div>
                                        </td>
                                        <td  className="hide-content" style={{width: 300}}></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark' style={{width: 45}}>a</td>
                                        <td className='bg-color-02 font11px color-dark' style={{width: 400}}>Difficulty finding the people or talent we need</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center  color-dark" 
                                                    id="PreBenchmarkSurveyG12" 
                                                    value={Inputs.PreBenchmarkSurveyG12} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG12: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[1-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td colSpan={2} className="padding-0">
                                            <div className='input-fiel-cn'>
                                                <input type="text" className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI12"
                                                value={Inputs.PreBenchmarkSurveyI12}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI12: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark'>b</td>
                                        <td className='bg-color-02 font11px color-dark'>Changing or new technologies</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center  color-dark" 
                                                    id="PreBenchmarkSurveyG13" 
                                                    value={Inputs.PreBenchmarkSurveyG13} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG13: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[1-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td className="padding-0" colSpan={2}>
                                            <div className='input-fiel-cn'>
                                                <input type="text" className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI13"
                                                value={Inputs.PreBenchmarkSurveyI13}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI13: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark'>c</td>
                                        <td className='bg-color-02 font11px color-dark'>Changing customer expectations</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center  color-dark" 
                                                    id="PreBenchmarkSurveyG14" 
                                                    value={Inputs.PreBenchmarkSurveyG14} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG14: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[1-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td className="padding-0" colSpan={2}>
                                            <div className='input-fiel-cn'>
                                                <input type="text"  className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI14"
                                                value={Inputs.PreBenchmarkSurveyI14}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI14: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark'>d</td>
                                        <td className='bg-color-02 font11px color-dark'>Changing regulatory environment</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="PreBenchmarkSurveyG15" 
                                                    value={Inputs.PreBenchmarkSurveyG15} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG15: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[1-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td className="padding-0" colSpan={2}>
                                            <div className='input-fiel-cn'>
                                                <input type="text" className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI15"
                                                value={Inputs.PreBenchmarkSurveyI15}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI15: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark'>e</td>
                                        <td className='bg-color-02 font11px color-dark'>Unstable market conditions or economic downturn</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="PreBenchmarkSurveyG16" 
                                                    value={Inputs.PreBenchmarkSurveyG16} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG16: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[1-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td className="padding-0" colSpan={2}>
                                            <div className='input-fiel-cn'>
                                                <input type="text"  className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI16"
                                                value={Inputs.PreBenchmarkSurveyI16}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI16: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark'>f</td>
                                        <td className='bg-color-02 font11px color-dark'>Labor issues or strikes</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="PreBenchmarkSurveyG17" 
                                                    value={Inputs.PreBenchmarkSurveyG17} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG17: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[1-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td colSpan={2} className="padding-0">
                                            <div className='input-fiel-cn'>
                                                <input type="text" className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI17"
                                                value={Inputs.PreBenchmarkSurveyI17}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI17: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark'>g</td>
                                        <td className='bg-color-02 font11px color-dark'>Geopolitical issues</td>
                                        <td className='white-bg'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="PreBenchmarkSurveyG18" 
                                                    value={Inputs.PreBenchmarkSurveyG18} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG18: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[1-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='white-bg'></td>
                                        <td colSpan={2} className="padding-0">
                                            <div className='input-fiel-cn'>
                                                <input type="text" className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI18"
                                                value={Inputs.PreBenchmarkSurveyI18}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI18: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark'>h</td>
                                        <td className='bg-color-02 font11px color-dark'>Other:  Please specify below</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0 bg-color-03'></td>
                                        <td className='hide-content'></td>
                                        <td colSpan={2} className='padding-0 bg-color-03'></td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='hide-content'>h</td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className='input-fiel-cn'>
                                                <input type="text"  className='field-ipt bg-color-01 font11px color-dark'
                                                id="PreBenchmarkSurveyE20"
                                                value={Inputs.PreBenchmarkSurveyE20}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyE20: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td className='white-bg'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="PreBenchmarkSurveyG20" 
                                                    value={Inputs.PreBenchmarkSurveyG20} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG20: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[1-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='white-bg'></td>
                                        <td colSpan={2} className="padding-0">
                                            <div className='input-fiel-cn'>
                                                <input type="text"  className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI20"
                                                value={Inputs.PreBenchmarkSurveyI20}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI20: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark'>i</td>
                                        <td className='bg-color-02 font11px color-dark'>Other:  Please specify below</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0 bg-color-03'></td>
                                        <td className='hide-content'></td>
                                        <td colSpan={2} className='padding-0 bg-color-03'></td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='hide-content'>h</td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className='input-fiel-cn'>
                                                <input type="text"  className='field-ipt bg-color-01 font11px color-dark'
                                                id="PreBenchmarkSurveyE22"
                                                value={Inputs.PreBenchmarkSurveyE22}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyE22: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="PreBenchmarkSurveyG22" 
                                                    value={Inputs.PreBenchmarkSurveyG22} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG22: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[1-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td colSpan={2} className="padding-0">
                                            <div className='input-fiel-cn'>
                                                <input type="text"  className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI22"
                                                value={Inputs.PreBenchmarkSurveyI22}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI22: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark'>j</td>
                                        <td className='bg-color-02 font11px color-dark'>Other:  Please specify below</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0 bg-color-03'></td>
                                        <td className='hide-content'></td>
                                        <td colSpan={2} className='padding-0 bg-color-03'></td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='hide-content'>h</td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className='input-fiel-cn'>
                                                <input type="text"  className='field-ipt bg-color-01 font11px color-dark'
                                                id="PreBenchmarkSurveyE24"
                                                value={Inputs.PreBenchmarkSurveyE24}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyE24: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td className='white-bg'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="PreBenchmarkSurveyG24" 
                                                    value={Inputs.PreBenchmarkSurveyG24} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG24: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[1-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='white-bg'></td>
                                        <td colSpan={2} className="padding-0">
                                            <div className='input-fiel-cn'>
                                                <input type="text"  className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI24"
                                                value={Inputs.PreBenchmarkSurveyI24}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI24: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="table-responsive mt-4">
                            {/* table 02 */}
                            <table className="table table-01" style={{width: 1230}}>
                                <thead>
                                    <tr className='row-one'>
                                        <td className='hide-content' colSpan={4}></td>
                                        <td className='black-bg text-white text-center font11px' >Rank on a Scale<br />of 1-5</td>
                                        <td className="hide-content" colSpan={3}>18</td>
                                    </tr>
                                    <tr className='row-two'>
                                        <td className='black-bg text-white font12px text-center' style={{width: 45}}>2</td>
                                        <td className='black-bg text-white font12px' colSpan={2}>What competitive challenges do you currently face?</td>
                                        <td className='hide-content' style={{width: 30}}></td>
                                        <td className='black-bg text-white text-center font10px' style={{width: 130}}>(1=low; 5=high)</td>
                                        <td className='hide-content' style={{width: 30}}></td>
                                        <td className='black-bg text-white font16px' style={{width: 250}}>
                                            <div className=''>Comments</div>
                                        </td>
                                        <td  className="hide-content" style={{width: 300}}></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark' style={{width: 45}}>a</td>
                                        <td className='bg-color-02 font11px color-dark' style={{width: 400}}>Increasing number of competitors in our marketplace</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center  color-dark" 
                                                    id="PreBenchmarkSurveyG28" 
                                                    value={Inputs.PreBenchmarkSurveyG28} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG28: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[1-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td colSpan={2} className="padding-0">
                                            <div className='input-fiel-cn'>
                                                <input type="text" className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI28"
                                                value={Inputs.PreBenchmarkSurveyI28}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI28: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark'>b</td>
                                        <td className='bg-color-02 font11px color-dark'>Competition is driving down profit margins</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center  color-dark" 
                                                    id="PreBenchmarkSurveyG29" 
                                                    value={Inputs.PreBenchmarkSurveyG29} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG29: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[1-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td className="padding-0" colSpan={2}>
                                            <div className='input-fiel-cn'>
                                                <input type="text" className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI29"
                                                value={Inputs.PreBenchmarkSurveyI29}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI29: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark'>c</td>
                                        <td className='bg-color-02 font11px color-dark'>Competition from overseas</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center  color-dark" 
                                                    id="PreBenchmarkSurveyG30" 
                                                    value={Inputs.PreBenchmarkSurveyG30} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG30: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[1-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td className="padding-0" colSpan={2}>
                                            <div className='input-fiel-cn'>
                                                <input type="text"  className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI30"
                                                value={Inputs.PreBenchmarkSurveyI30}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI30: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark'>d</td>
                                        <td className='bg-color-02 font11px color-dark'>Competition from the internet (including Amazon)</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="PreBenchmarkSurveyG31" 
                                                    value={Inputs.PreBenchmarkSurveyG31} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG31: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[1-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td className="padding-0" colSpan={2}>
                                            <div className='input-fiel-cn'>
                                                <input type="text" className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI31"
                                                value={Inputs.PreBenchmarkSurveyI31}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI31: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark'>e</td>
                                        <td className='bg-color-02 font11px color-dark'>Disintermediation (manufacturers cutting out middlemen and selling direct to customers)</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="PreBenchmarkSurveyG32" 
                                                    value={Inputs.PreBenchmarkSurveyG32} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG32: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[1-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td className="padding-0" colSpan={2}>
                                            <div className='input-fiel-cn'>
                                                <input type="text" className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI32"
                                                value={Inputs.PreBenchmarkSurveyI32}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI32: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="table-responsive mt-4">
                            {/* table 03 */}
                            <table className="table table-01" style={{width: 1230}}>
                                <thead>
                                    <tr className='row-one'>
                                        <td className='hide-content' colSpan={4}></td>
                                        <td className='black-bg text-white text-center font11px' >Rank on a Scale<br />of 1-5</td>
                                        <td className="hide-content" colSpan={3}>18</td>
                                    </tr>
                                    <tr className='row-two'>
                                        <td className='black-bg text-white font12px text-center' style={{width: 45}}>3</td>
                                        <td className='black-bg text-white font12px' colSpan={2}>Which of the following sales and marketing challenges do you presently face?</td>
                                        <td className='hide-content' style={{width: 30}}></td>
                                        <td className='black-bg text-white text-center font10px' style={{width: 130}}>(1=low; 5=high)</td>
                                        <td className='hide-content' style={{width: 30}}></td>
                                        <td className='black-bg text-white font16px' style={{width: 250}}>
                                            <div className=''>Comments</div>
                                        </td>
                                        <td  className="hide-content" style={{width: 300}}></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark' style={{width: 45}}>a</td>
                                        <td className='bg-color-02 font11px color-dark' style={{width: 400}}>Generating new customers and sales</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center  color-dark" 
                                                    id="PreBenchmarkSurveyG36" 
                                                    value={Inputs.PreBenchmarkSurveyG36} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG36: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[1-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td colSpan={2} className="padding-0">
                                            <div className='input-fiel-cn'>
                                                <input type="text" className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI36"
                                                value={Inputs.PreBenchmarkSurveyI36}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI36: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark'>b</td>
                                        <td className='bg-color-02 font11px color-dark'>Generating a higher volume of leads</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center  color-dark" 
                                                    id="PreBenchmarkSurveyG37" 
                                                    value={Inputs.PreBenchmarkSurveyG37} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG37: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[1-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td className="padding-0" colSpan={2}>
                                            <div className='input-fiel-cn'>
                                                <input type="text" className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI37"
                                                value={Inputs.PreBenchmarkSurveyI37}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI37: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark'>c</td>
                                        <td className='bg-color-02 font11px color-dark'>Generating high quality leads</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center  color-dark" 
                                                    id="PreBenchmarkSurveyG38" 
                                                    value={Inputs.PreBenchmarkSurveyG38} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG38: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[1-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td className="padding-0" colSpan={2}>
                                            <div className='input-fiel-cn'>
                                                <input type="text"  className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI38"
                                                value={Inputs.PreBenchmarkSurveyI38}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI38: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark'>d</td>
                                        <td className='bg-color-02 font11px color-dark'>Creating perceived value and benefits</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="PreBenchmarkSurveyG39" 
                                                    value={Inputs.PreBenchmarkSurveyG39} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG39: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[1-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td className="padding-0" colSpan={2}>
                                            <div className='input-fiel-cn'>
                                                <input type="text" className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI39"
                                                value={Inputs.PreBenchmarkSurveyI39}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI39: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark'>e</td>
                                        <td className='bg-color-02 font11px color-dark'>Difficulty reaching qualified prospects</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="PreBenchmarkSurveyG40" 
                                                    value={Inputs.PreBenchmarkSurveyG40} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG40: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[1-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td className="padding-0" colSpan={2}>
                                            <div className='input-fiel-cn'>
                                                <input type="text" className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI40"
                                                value={Inputs.PreBenchmarkSurveyI40}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI40: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark' style={{width: 45}}>f</td>
                                        <td className='bg-color-02 font11px color-dark' style={{width: 400}}>Selling/marketing to longer sales cycles</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center  color-dark" 
                                                    id="PreBenchmarkSurveyG41" 
                                                    value={Inputs.PreBenchmarkSurveyG41} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG41: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[1-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td colSpan={2} className="padding-0">
                                            <div className='input-fiel-cn'>
                                                <input type="text" className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI41"
                                                value={Inputs.PreBenchmarkSurveyI41}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI41: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark'>g</td>
                                        <td className='bg-color-02 font11px color-dark'>Creating more "wins" in competitive situations</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center  color-dark" 
                                                    id="PreBenchmarkSurveyG42" 
                                                    value={Inputs.PreBenchmarkSurveyG42} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG42: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[1-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td className="padding-0" colSpan={2}>
                                            <div className='input-fiel-cn'>
                                                <input type="text" className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI42"
                                                value={Inputs.PreBenchmarkSurveyI42}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI42: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark'>h</td>
                                        <td className='bg-color-02 font11px color-dark'>Improving the performance of sales teams</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center  color-dark" 
                                                    id="PreBenchmarkSurveyG43" 
                                                    value={Inputs.PreBenchmarkSurveyG43} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG43: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[1-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td className="padding-0" colSpan={2}>
                                            <div className='input-fiel-cn'>
                                                <input type="text"  className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI43"
                                                value={Inputs.PreBenchmarkSurveyI43}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI43: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark'>i</td>
                                        <td className='bg-color-02 font11px color-dark'>Getting marketing efforts recognized in a crowded marketplace</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="PreBenchmarkSurveyG44" 
                                                    value={Inputs.PreBenchmarkSurveyG44} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG44: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[1-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td className="padding-0" colSpan={2}>
                                            <div className='input-fiel-cn'>
                                                <input type="text" className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI44"
                                                value={Inputs.PreBenchmarkSurveyI44}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI44: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark'>j</td>
                                        <td className='bg-color-02 font11px color-dark'>Improving customer retention, loyalty, and lifetime value</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="PreBenchmarkSurveyG45" 
                                                    value={Inputs.PreBenchmarkSurveyG45} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG45: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[1-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td className="padding-0" colSpan={2}>
                                            <div className='input-fiel-cn'>
                                                <input type="text" className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI45"
                                                value={Inputs.PreBenchmarkSurveyI45}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI45: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark'>k</td>
                                        <td className='bg-color-02 font11px color-dark'>Improving top-of-mind awareness (TOMA) in your market</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="PreBenchmarkSurveyG46" 
                                                    value={Inputs.PreBenchmarkSurveyG46} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG46: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[1-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td className="padding-0" colSpan={2}>
                                            <div className='input-fiel-cn'>
                                                <input type="text" className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI46"
                                                value={Inputs.PreBenchmarkSurveyI46}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI46: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="table-responsive mt-4">
                            {/* table 04 */}
                            <table className="table table-01" style={{width: 1230}}>
                                <thead>
                                    <tr className='row-one'>
                                        <td className='hide-content' colSpan={4}></td>
                                        <td className='black-bg text-white text-center font11px' >Rank on a Scale<br />of 1-5</td>
                                        <td className="hide-content" colSpan={3}>18</td>
                                    </tr>
                                    <tr className='row-two'>
                                        <td className='black-bg text-white font12px text-center' style={{width: 45}}>4</td>
                                        <td className='black-bg text-white font12px' colSpan={2}>Which of the following growth objectives are important to you?</td>
                                        <td className='hide-content' style={{width: 30}}></td>
                                        <td className='black-bg text-white text-center font10px' style={{width: 130}}>(1=low; 5=high)</td>
                                        <td className='hide-content' style={{width: 30}}></td>
                                        <td className='black-bg text-white font16px' style={{width: 250}}>
                                            <div className=''>Comments</div>
                                        </td>
                                        <td  className="hide-content" style={{width: 300}}></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark' style={{width: 45}}>a</td>
                                        <td className='bg-color-02 font11px color-dark' style={{width: 400}}>Right now we're just trying to survive</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center  color-dark" 
                                                    id="PreBenchmarkSurveyG50" 
                                                    value={Inputs.PreBenchmarkSurveyG50} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG50: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[1-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td colSpan={2} className="padding-0">
                                            <div className='input-fiel-cn'>
                                                <input type="text" className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI50"
                                                value={Inputs.PreBenchmarkSurveyI50}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI50: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark'>b</td>
                                        <td className='bg-color-02 font11px color-dark'>Growing revenue and sales</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center  color-dark" 
                                                    id="PreBenchmarkSurveyG51" 
                                                    value={Inputs.PreBenchmarkSurveyG51} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG51: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[1-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td className="padding-0" colSpan={2}>
                                            <div className='input-fiel-cn'>
                                                <input type="text" className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI51"
                                                value={Inputs.PreBenchmarkSurveyI51}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI51: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark'>c</td>
                                        <td className='bg-color-02 font11px color-dark'>Increasing profit margins</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center  color-dark" 
                                                    id="PreBenchmarkSurveyG52" 
                                                    value={Inputs.PreBenchmarkSurveyG52} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG52: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[1-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td className="padding-0" colSpan={2}>
                                            <div className='input-fiel-cn'>
                                                <input type="text"  className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI52"
                                                value={Inputs.PreBenchmarkSurveyI52}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI52: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark'>d</td>
                                        <td className='bg-color-02 font11px color-dark'>Capturing market share</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="PreBenchmarkSurveyG53" 
                                                    value={Inputs.PreBenchmarkSurveyG53} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG53: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[1-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td className="padding-0" colSpan={2}>
                                            <div className='input-fiel-cn'>
                                                <input type="text" className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI53"
                                                value={Inputs.PreBenchmarkSurveyI53}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI53: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark'>e</td>
                                        <td className='bg-color-02 font11px color-dark'>Improving our company valuation</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="PreBenchmarkSurveyG54" 
                                                    value={Inputs.PreBenchmarkSurveyG54} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG54: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[1-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td className="padding-0" colSpan={2}>
                                            <div className='input-fiel-cn'>
                                                <input type="text" className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI54"
                                                value={Inputs.PreBenchmarkSurveyI54}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI54: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark'>f</td>
                                        <td className='bg-color-02 font11px color-dark'>Positioning the company to attract quality investors</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="PreBenchmarkSurveyG55" 
                                                    value={Inputs.PreBenchmarkSurveyG55} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG55: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[1-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td className="padding-0" colSpan={2}>
                                            <div className='input-fiel-cn'>
                                                <input type="text" className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI55"
                                                value={Inputs.PreBenchmarkSurveyI55}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI55: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark'>g</td>
                                        <td className='bg-color-02 font11px color-dark'>Other:  Please specify below</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0 bg-color-03'></td>
                                        <td className='hide-content'></td>
                                        <td colSpan={2} className='padding-0 bg-color-03'></td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='hide-content'>h</td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className='input-fiel-cn'>
                                                <input type="text"  className='field-ipt bg-color-01 font11px color-dark'
                                                id="PreBenchmarkSurveyE57"
                                                value={Inputs.PreBenchmarkSurveyE57}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyE57: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="PreBenchmarkSurveyG57" 
                                                    value={Inputs.PreBenchmarkSurveyG57} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG57: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[1-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td colSpan={2} className="padding-0">
                                            <div className='input-fiel-cn'>
                                                <input type="text"  className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI57"
                                                value={Inputs.PreBenchmarkSurveyI57}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI57: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark'>h</td>
                                        <td className='bg-color-02 font11px color-dark'>Other:  Please specify below</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0 bg-color-03'></td>
                                        <td className='hide-content'></td>
                                        <td colSpan={2} className='padding-0 bg-color-03'></td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='hide-content'>h</td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className='input-fiel-cn'>
                                                <input type="text"  className='field-ipt bg-color-01 font11px color-dark'
                                                id="PreBenchmarkSurveyE59"
                                                value={Inputs.PreBenchmarkSurveyE59}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyE59: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td className='white-bg'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="PreBenchmarkSurveyG59" 
                                                    value={Inputs.PreBenchmarkSurveyG59} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG59: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[1-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='white-bg'></td>
                                        <td colSpan={2} className="padding-0">
                                            <div className='input-fiel-cn'>
                                                <input type="text"  className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI59"
                                                value={Inputs.PreBenchmarkSurveyI59}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI59: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="table-responsive mt-4">
                            {/* table 04 */}
                            <table className="table table-01" style={{width: 1230}}>
                                <thead>
                                    <tr className='row-one'>
                                        <td className='hide-content' colSpan={4}></td>
                                        <td className='black-bg text-white text-center font11px' >Rank on a Scale<br />of 1-5</td>
                                        <td className="hide-content" colSpan={3}>18</td>
                                    </tr>
                                    <tr className='row-two'>
                                        <td className='black-bg text-white font12px text-center' style={{width: 45}}>5</td>
                                        <td className='black-bg text-white font12px' colSpan={2}>How do you generate your sales and revenue?</td>
                                        <td className='hide-content' style={{width: 30}}></td>
                                        <td className='black-bg text-white text-center font10px' style={{width: 130}}>(1=low; 5=high)</td>
                                        <td className='hide-content' style={{width: 30}}></td>
                                        <td className='black-bg text-white font16px' style={{width: 250}}>
                                            <div className=''>Comments</div>
                                        </td>
                                        <td  className="hide-content" style={{width: 300}}></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark' style={{width: 45}}>a</td>
                                        <td className='bg-color-02 font11px color-dark' style={{width: 400}}>Inside sales</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center  color-dark" 
                                                    id="PreBenchmarkSurveyG62" 
                                                    value={Inputs.PreBenchmarkSurveyG62} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG62: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td colSpan={2} className="padding-0">
                                            <div className='input-fiel-cn'>
                                                <input type="text" className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI62"
                                                value={Inputs.PreBenchmarkSurveyI62}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI62: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark'>b</td>
                                        <td className='bg-color-02 font11px color-dark'>Outside sales</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center  color-dark" 
                                                    id="PreBenchmarkSurveyG63" 
                                                    value={Inputs.PreBenchmarkSurveyG63} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG63: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td className="padding-0" colSpan={2}>
                                            <div className='input-fiel-cn'>
                                                <input type="text" className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI63"
                                                value={Inputs.PreBenchmarkSurveyI63}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI63: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark'>c</td>
                                        <td className='bg-color-02 font11px color-dark'>Retail sales</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center  color-dark" 
                                                    id="PreBenchmarkSurveyG64" 
                                                    value={Inputs.PreBenchmarkSurveyG64} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG64: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td className="padding-0" colSpan={2}>
                                            <div className='input-fiel-cn'>
                                                <input type="text"  className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI64"
                                                value={Inputs.PreBenchmarkSurveyI64}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI64: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark'>d</td>
                                        <td className='bg-color-02 font11px color-dark'>Internet or Ecommerce</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="PreBenchmarkSurveyG65" 
                                                    value={Inputs.PreBenchmarkSurveyG65} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG65: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td className="padding-0" colSpan={2}>
                                            <div className='input-fiel-cn'>
                                                <input type="text" className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI65"
                                                value={Inputs.PreBenchmarkSurveyI65}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI65: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark'>e</td>
                                        <td className='bg-color-02 font11px color-dark'>Direct Response Marketing</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="PreBenchmarkSurveyG66" 
                                                    value={Inputs.PreBenchmarkSurveyG66} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG66: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td className="padding-0" colSpan={2}>
                                            <div className='input-fiel-cn'>
                                                <input type="text" className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI66"
                                                value={Inputs.PreBenchmarkSurveyI66}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI66: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark'>f</td>
                                        <td className='bg-color-02 font11px color-dark'>Other  (please specify below):</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0 bg-color-03'></td>
                                        <td className='hide-content'></td>
                                        <td colSpan={2} className='padding-0 bg-color-03'></td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='hide-content'>h</td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className='input-fiel-cn'>
                                                <input type="text"  className='field-ipt bg-color-01 font11px color-dark'
                                                id="PreBenchmarkSurveyE68"
                                                value={Inputs.PreBenchmarkSurveyE68}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyE68: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="PreBenchmarkSurveyG68" 
                                                    value={Inputs.PreBenchmarkSurveyG68} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG68: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>
                                        <td colSpan={2} className="padding-0">
                                            <div className='input-fiel-cn'>
                                                <input type="text"  className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI68"
                                                value={Inputs.PreBenchmarkSurveyI68}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI68: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='bg-color-02 text-center font12px color-dark'>g</td>
                                        <td className='bg-color-02 font11px color-dark'>Other  (please specify below):</td>
                                        <td className='hide-content'></td>
                                        <td className='padding-0 bg-color-03'></td>
                                        <td className='hide-content'></td>
                                        <td colSpan={2} className='padding-0 bg-color-03'></td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content'>1</td>
                                        <td className='hide-content'>h</td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className='input-fiel-cn'>
                                                <input type="text"  className='field-ipt bg-color-01 font11px color-dark'
                                                id="PreBenchmarkSurveyE70"
                                                value={Inputs.PreBenchmarkSurveyE70}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyE70: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                        <td className='white-bg'></td>
                                        <td className='padding-0'>
                                            <div className='input-fiel-cn bg-color-01'>
                                                <input type="text" className="field-ipt font12px bg-color-01 text-center color-dark" 
                                                    id="PreBenchmarkSurveyG70" 
                                                    value={Inputs.PreBenchmarkSurveyG70} 
                                                    onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyG70: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    maxLength={1}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='white-bg'></td>
                                        <td colSpan={2} className="padding-0">
                                            <div className='input-fiel-cn'>
                                                <input type="text"  className='field-ipt bg-color-02 font11px color-dark'
                                                id="PreBenchmarkSurveyI70"
                                                value={Inputs.PreBenchmarkSurveyI70}
                                                onChange={(event) => setInputs({ ...Inputs, PreBenchmarkSurveyI70: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="nextInput">
                            <ul>
                                <li>
                                    <Link to="/diagnose/client-inputs" className='next-link'> Next:  Client Inputs  
                                        <span className='icon-three'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                        </span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PreBenchmarkSurvey;