import React from 'react';
import  UserDropdown  from '../UserDropdown';

const HowItWorksContent = () => {
    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
            {/* row */}
            <div className="container-fluid">
                <div className="head-title">
                    <h3 className='preheading'>How It Works</h3>
                    <h1 className='mainheading'>Improve your score - grow your business.</h1>                    
                </div>
                <div className="row" style={{maxWidth: 1400}}>
                    <div className="col-12 col-lg-7 mb-3">
                        <div className='listWrapper'>
                            <div className='lw-text'>
                                <h3 className='heading-title'>The higher a company scores on the benchmark, the faster they grow.</h3>
                                <p className='body-text color-bold mb-3'>The Revenue Growth OS Benchmark links quantitative and qualitative data to create a predictive measure for growth.  As companies methodically work to increase their benchmark score, their business grows.</p>
                            </div>
                            <div className='lw-img'>
                                <img src={window.location.origin+"/images/Home_page_img/graph_01.png"} alt="Revenue Growth Drivers" title="Revenue Growth Drivers" />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-5 mb-3">
                        <div className='listWrapper'>
                            <div className='lw-text'>
                                <h3 className='heading-title'>High scoring companies grow much faster:</h3>
                                <ul className='list-ht-wr'>
                                    <li className='body-text color-bold mb-3'>2nd Quartile companies grow 2.9X faster than 1st Quartile companies.</li>
                                    <li className='body-text color-bold mb-3'>3rd Quartile companies grow 5.4X faster than 1st Quartile companies.</li>
                                    <li className='body-text color-bold mb-3'>4th Quartile companies outpace 1st Quartile companies by 7X.</li>
                                </ul>
                            </div>
                            <div className='lw-img'>
                                <img src={window.location.origin+"/images/Home_page_img/image_2023_03_13T07_50_34_847Z.png"} alt="Revenue Growth Drivers" title="Revenue Growth Drivers" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HowItWorksContent;
