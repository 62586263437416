import React from 'react'
import {useSWRConfig} from "swr";

export default function CopyRightText() {
    const { fallback, isLoading } = useSWRConfig()
    return (
        <>
            <span className="text-white color-whte">
            {
            (fallback && fallback.settings.is_allow_branding == 1 && fallback.settings.copyRightText) ? 
            <>{fallback.settings.copyRightText}.</> 
            : <>Copyright 2023 by RevGrowth OS and Russ Holder.</>
            }
            </span>
            <span className="custome-color mb-0">This</span>
            <span className="custome-color">document contains proprietary information.  Do not reproduce or distribute the information herein without express written consent.</span>
        </>
    )
}
