import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
    animation: false,
    maintainAspectRatio: false,
    responsive: true,
    scales: {
        x: {
            title: {
                display: true,
                text: 'Effort / Cost',
                color: 'white',
                font: {
                    size: 14,
                    weight: 700
                }
            },
            min: 0,
            max: 10,
            ticks: {
                stepSize: 1,
                tickColor: 'white',
                color: 'white'
            },
            grid: {
                color: '#334966'
            }
        },
        y: {
            title: {
                display: true,
                text: 'Value / Benefit',
                color: 'white',
                font: {
                    size: 14,
                    weight: 700
                }
            },
            min: 0,
            max: 10,
            ticks: {
                stepSize: 1,
                tickColor: 'white',
                color: 'white'
            },
            grid: {
                color: '#334966'
            }
        }
    },
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: false,
        },
        tooltip: {
            enabled: true,
            titleColor: '#fff'
        }
    },
};

export default function QuadrantChartGraph({data, labels}) {

    const dataSets = {
        labels: labels,
        datasets: [
          {
              type: 'scatter',
              label: 'Dataset 1',
              data: data,
              borderColor: '#FFF',
              backgroundColor: '#439DFF',
              borderWidth: 2,
              pointRadius: 4,
          },
          {
              type: 'line',
              label: 'Acquisitions by year',
              data: [{x: 0, y: 5}, {x: 10, y: 5}],
              pointRadius: 0,
              borderColor: '#fff',
          },
          {
              type: 'line',
              label: 'Acquisitions by year',
              data: [{x: 5, y: 0}, {x: 5, y: 10}],
              pointRadius: 0,
              borderColor: '#fff',
          }
        ],
    };

    // return <Scatter options={options} data={data} plugins={[ chartAreaBorder ]}/>
    return <Chart options={options} type='scatter' data={dataSets} />;
}

/*
import { Chart } from "react-chartjs-2";


export default function QuadrantChartGraph({data}) {

    const chartAreaBorder = {
        id: 'quadrants',
        beforeDraw(chart, args, options) {
            const {ctx, chartArea: {left, top, right, bottom}, scales: {x, y}} = chart;
            const midX = x.getPixelForValue(5);
            const midY = y.getPixelForValue(5);
            ctx.save();
            ctx.fillStyle = '#1e2b3c';
            ctx.fillRect(left, top, midX - left, midY - top);
            ctx.fillStyle = '#1e2b3c';
            ctx.fillRect(midX, top, right - midX, midY - top);
            ctx.fillStyle = '#1e2b3c';
            ctx.fillRect(midX, midY, right - midX, bottom - midY);
            ctx.fillStyle = '#1e2b3c';
            ctx.fillRect(left, midY, midX - left, bottom - midY);

            ctx.fillStyle = '#fff';
            ctx.fillRect(0, midY, right, 2);
            ctx.fillStyle = '#fff';
            ctx.fillRect(midX, 0, 2, right);
            ctx.restore();
        }
    };

    const options = {
        type: 'bubble',
        plugins: [ chartAreaBorder ],
        options: {
            animation: true,
            aspectRatio: 1,
            scales: {
                x: {
                    title: {
                        display: true,
                        text: 'Effort / Cost'
                    },
                    min: 0,
                    max: 10,
                    ticks: {
                        stepSize: 2
                    }
                },
                y: {
                    title: {
                        display: true,
                        text: 'Value / Benefit'
                    },
                    min: 0,
                    max: 10,
                    ticks: {
                        stepSize: 1
                    }
                }
            },
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    enabled: false
                },
            }
        },
    };

    return (
        <Chart
            chartType="bubble"
            width="100%"
            data={data}
            options={options}
        />
    )
}

*/