import React from "react";
import { Chart } from "react-google-charts";

export function AreaLineChartGraph({dataInput}) {
  const data = dataInput.GraphData;
  const Title = dataInput.Title ? dataInput.Title : "";
  const backgroundColor = dataInput.backgroundColor ? dataInput.backgroundColor : '#1e2b3c';
  const legendTextStyle = dataInput.legendTextStyle ? dataInput.legendTextStyle : {color: "#fff"};
  const titleTextStyle = dataInput.titleTextStyle ? dataInput.titleTextStyle : {color: "#fff"};
  const textStyle = dataInput.textStyle ? dataInput.textStyle : {color: "#fff"};
  const vTitle = dataInput.vTitle ? dataInput.vTitle : "";
  const hTitle = dataInput.hTitle ? dataInput.hTitle : "";
  const subTitle = dataInput.subTitle ? dataInput.subTitle : "";
  const ColorCodes = dataInput.ColorCodes ? dataInput.ColorCodes : ["#c2c7cd", "#8497b0", "#44546a"];

  const options = {
    responsive: true,
    backgroundColor,
    maintainAspectRatio: false,
    chartArea:{
      right: 5,
      width: '70%',
    },
    title: Title,
    curveType: "function",
    interval: {
      i0: { style: "area", color: "#114daf ", lineWidth: 0.5 },
      i0: { style: "area", color: "#114daf ", lineWidth: 2 },
      i1: { style: "area", color: "#2cab86", lineWidth: 2 },
      i1: { style: "area", color: "#2cab86", lineWidth: 2 },
    },
    legend: { position: "top" },
    colors: ColorCodes,
    hAxis: {
      title: hTitle,
      textStyle
    },
    vAxis: {
      title: vTitle,
      textStyle,
      format: 'currency'
    },
    subtitle: subTitle,
    legendTextStyle,
    titleTextStyle,
  };

  // const options = {
  //   title: Title,
  //   curveType: "function",
  //   series: [{ color: "#fcba03" }, { color : "#4887d4"}],
  //   intervals: { style: "area" },
  //   legend: "none",
  // };

  return (
    <Chart
      chartType="LineChart"
      width={'100%'}
      height={300}
      data={data}
      options={options}
    />
  );
}