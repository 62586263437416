import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../features/Message";
import insightsToActionService from "../../services/insightsToAction.service";

//Get data based on user
export const getInsightsToAction = createAsyncThunk(
  "revenueGrowth/getInsightsToAction",
  async ({user_id}, thunkAPI) => {
    try {
      const response = await insightsToActionService.getData(user_id);
      //console.log(response)
      //thunkAPI.dispatch(setMessage(response.data.message));
      return response.data
    } catch (error) {
      console.log(error)
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

//add or edit InsightsToAction
export const modifyInsightsToAction = createAsyncThunk(
  "revenueGrowth/modifyInsightsToAction",
  async (postClientData , thunkAPI) => {
    try {
      const data = await insightsToActionService.postData(postClientData);
      thunkAPI.dispatch(setMessage(""));
      console.log(data)
      return { revenueGrowth : data };
    } catch (error) {
      console.log('errorcode',error.code)
      if(error.code == 'ERR_BAD_REQUEST'){
        return {status : 401}
      }
      else{
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const InsightsToActionSlice = createSlice({
    name: 'InsightsToAction',
    reducers: {
      
    },
    extraReducers: {
      [getInsightsToAction.pending]: (state) => {
        state.loading = true
      },
      [getInsightsToAction.fulfilled]: (state, { payload }) => {
        //console.log("---------get Data -----------", payload.data.InsightsToAction)
        state.loading = false
      },
      [getInsightsToAction.rejected]: (state) => {
        state.loading = false
      },
    },
  })

const { reducer } = InsightsToActionSlice;
export default reducer;