import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export function BarChartGraphNew({dataInput}) {
  const data = dataInput.GraphData;
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    colors: dataInput.ColorCodes,
    scales: {
      y: {
        ticks: {
          color: '#44556a',
          callback: function(value, index, ticks) {
              return '$' + Number(value).toLocaleString('en-US');
          },
          font: {
            size: 14
          }
        },
        grid: {
          display: true,
        }
      },
      x: {
        ticks: {
          color: '#44556a',
          font: {
            size: 12
          }
        },
        grid: {
          display: false,
        }
      }
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          boxWidth: 10,
          boxHeight: 10
        }
      },
      title: {
          display: true,
          text: dataInput.Title,
          font: {
            size: 16,
            weight: 600
          }
      },
      tooltips: {
        enabled: false
      }
    },
  }
  const chartAreaBorder = {
			id: 'reactangle',
			beforeDraw(chart, args, options) {
				const {ctx, chartArea: {left, top, right, bottom}, scales: {x, y}} = chart;
				ctx.save();
				// console.log(x, y)
        // console.log(Math.ceil(x.width/6), y.height)
				ctx.fillStyle = 'rgb(214, 220, 229)';
				ctx.fillRect(80, 70, Math.ceil(x.width/6), y.height);
				ctx.restore();
			}
		};
      
  return (
    // <Chart
    //   chartType="ColumnChart"
    //   width="100%"
    //   // height={height}
    //   data={data}
    //   options={options}
    // />
    <Bar options={options} data={data} plugins={[ chartAreaBorder ]}/>
  );
}
