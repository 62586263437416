import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getMessageSelection, modifyMessageSelection } from "../../state/features/MessageSelectionSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import sum from "../../utils/sum";
import { Link } from 'react-router-dom';
import formatPrice from '../../utils/formatPrice';

export default function MessageSelectionMatrixContentNew() {

    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        MessageSelectionD12 : 'Message test',
        MessageSelectionE12 : 0,
        MessageSelectionF12 : 0,
        MessageSelectionG12 : 0,
        MessageSelectionH12 : 0,
        MessageSelectionI12 : 0,
        MessageSelectionJ12 : 0,
        MessageSelectionD13 : 'Message 2',
        MessageSelectionE13 : 0,
        MessageSelectionF13 : 0,
        MessageSelectionG13 : 0,
        MessageSelectionH13 : 0,
        MessageSelectionI13 : 0,
        MessageSelectionJ13 : 0,
        MessageSelectionD14 : 'Message 3',
        MessageSelectionE14 : 0,
        MessageSelectionF14 : 0,
        MessageSelectionG14 : 0,
        MessageSelectionH14 : 0,
        MessageSelectionI14 : 0,
        MessageSelectionJ14 : 0,
        MessageSelectionD15 : 'Message 4',
        MessageSelectionE15 : 0,
        MessageSelectionF15 : 0,
        MessageSelectionG15 : 0,
        MessageSelectionH15 : 0,
        MessageSelectionI15 : 0,
        MessageSelectionJ15 : 0,
        MessageSelectionD16 : 'Message 5',
        MessageSelectionE16 : 0,
        MessageSelectionF16 : 0,
        MessageSelectionG16 : 0,
        MessageSelectionH16 : 0,
        MessageSelectionI16 : 0,
        MessageSelectionJ16 : 0,
        MessageSelectionD20 : '',
        MessageSelectionE20 : 0,
        MessageSelectionF20 : 0,
        MessageSelectionG20 : 0,
        MessageSelectionD21 : '',
        MessageSelectionE21 : 0,
        MessageSelectionF21 : 0,
        MessageSelectionG21 : 0,
        MessageSelectionD22 : '',
        MessageSelectionE22 : 0,
        MessageSelectionF22 : 0,
        MessageSelectionG22 : 0,
        MessageSelectionD23 : '',
        MessageSelectionE23 : 0,
        MessageSelectionF23 : 0,
        MessageSelectionG23 : 0,
        MessageSelectionD24 : '',
        MessageSelectionE24 : 0,
        MessageSelectionF24 : 0,
        MessageSelectionG24 : 0,
        MessageSelectionD28 : '',
        MessageSelectionE28 : 0,
        MessageSelectionF28 : 0,
        MessageSelectionG28 : 0,
        MessageSelectionH28 : 0,
        MessageSelectionD29 : '',
        MessageSelectionE29 : 0,
        MessageSelectionF29 : 0,
        MessageSelectionG29 : 0,
        MessageSelectionH29 : 0,
        MessageSelectionD30 : '',
        MessageSelectionE30 : 0,
        MessageSelectionF30 : 0,
        MessageSelectionG30 : 0,
        MessageSelectionH30 : 0,
        MessageSelectionD31 : '',
        MessageSelectionE31 : 0,
        MessageSelectionF31 : 0,
        MessageSelectionG31 : 0,
        MessageSelectionH31 : 0,
        MessageSelectionD32 : '',
        MessageSelectionE32 : 0,
        MessageSelectionF32 : 0,
        MessageSelectionG32 : 0,
        MessageSelectionH32 : 0,
        
        MessageSelectionK12 : 0,
        MessageSelectionK13 : 0,
        MessageSelectionK14 : 0,
        MessageSelectionK15 : 0,
        MessageSelectionK16 : 0,
        MessageSelectionH20 : 0,
        MessageSelectionH21 : 0,
        MessageSelectionH22 : 0,
        MessageSelectionH23 : 0,
        MessageSelectionH24 : 0,
        MessageSelectionI28 : 0,
        MessageSelectionI29 : 0,
        MessageSelectionI30 : 0,
        MessageSelectionI31 : 0,
        MessageSelectionI32 : 0,
        MessageSelectionD37 : '',
        MessageSelectionD38 : '',
        MessageSelectionD39 : '',
        MessageSelectionD40 : '',
        MessageSelectionD41 : '',
        MessageSelectionE37 : 0,
        MessageSelectionF37 : 0,
        MessageSelectionG37 : 0,
        MessageSelectionH37 : 0,
        MessageSelectionE38 : 0,
        MessageSelectionF38 : 0,
        MessageSelectionG38 : 0,
        MessageSelectionH38 : 0,
        MessageSelectionE39 : 0,
        MessageSelectionF39 : 0,
        MessageSelectionG39 : 0,
        MessageSelectionH39 : 0,
        MessageSelectionE40 : 0,
        MessageSelectionF40 : 0,
        MessageSelectionG40 : 0,
        MessageSelectionH40 : 0,
        MessageSelectionE41 : 0,
        MessageSelectionF41 : 0,
        MessageSelectionG41 : 0,
        MessageSelectionH41 : 0,
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getMessageSelection({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.growthPlanning)
            if(response!= undefined  && response.data.growthPlanning){
                let growthPlanning = response.data.growthPlanning[0];
                console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key]
                    }
                }
                newObj['MessageSelectionK12'] = sum(newObj['MessageSelectionE12'], newObj['MessageSelectionF12'],newObj['MessageSelectionG12'], newObj['MessageSelectionH12'], newObj['MessageSelectionI12'], newObj['MessageSelectionJ12']);
                newObj['MessageSelectionK13'] = sum(newObj['MessageSelectionE13'], newObj['MessageSelectionF13'],newObj['MessageSelectionG13'], newObj['MessageSelectionH13'], newObj['MessageSelectionI13'], newObj['MessageSelectionJ13']);
                newObj['MessageSelectionK14'] = sum(newObj['MessageSelectionE14'], newObj['MessageSelectionF14'],newObj['MessageSelectionG14'], newObj['MessageSelectionH14'], newObj['MessageSelectionI14'], newObj['MessageSelectionJ14']);
                newObj['MessageSelectionK15'] = sum(newObj['MessageSelectionE15'], newObj['MessageSelectionF15'],newObj['MessageSelectionG15'], newObj['MessageSelectionH15'], newObj['MessageSelectionI15'], newObj['MessageSelectionJ15']);
                newObj['MessageSelectionK16'] = sum(newObj['MessageSelectionE16'], newObj['MessageSelectionF16'],newObj['MessageSelectionG16'], newObj['MessageSelectionH16'], newObj['MessageSelectionI16'], newObj['MessageSelectionJ16']);

                newObj['MessageSelectionH20'] = sum(newObj['MessageSelectionE20'], newObj['MessageSelectionF20'],newObj['MessageSelectionG20']);
                newObj['MessageSelectionH21'] = sum(newObj['MessageSelectionE21'], newObj['MessageSelectionF21'],newObj['MessageSelectionG21']);
                newObj['MessageSelectionH22'] = sum(newObj['MessageSelectionE22'], newObj['MessageSelectionF22'],newObj['MessageSelectionG22']);
                newObj['MessageSelectionH23'] = sum(newObj['MessageSelectionE23'], newObj['MessageSelectionF23'],newObj['MessageSelectionG23']);
                newObj['MessageSelectionH24'] = sum(newObj['MessageSelectionE24'], newObj['MessageSelectionF24'],newObj['MessageSelectionG24']);

                newObj['MessageSelectionI28'] = sum(newObj['MessageSelectionE28'], newObj['MessageSelectionF28'],newObj['MessageSelectionG28'], newObj['MessageSelectionH28']);
                newObj['MessageSelectionI29'] = sum(newObj['MessageSelectionE29'], newObj['MessageSelectionF29'],newObj['MessageSelectionG29'], newObj['MessageSelectionH29']);
                newObj['MessageSelectionI30'] = sum(newObj['MessageSelectionE30'], newObj['MessageSelectionF30'],newObj['MessageSelectionG30'], newObj['MessageSelectionH30']);
                newObj['MessageSelectionI31'] = sum(newObj['MessageSelectionE31'], newObj['MessageSelectionF31'],newObj['MessageSelectionG31'], newObj['MessageSelectionH31']);
                newObj['MessageSelectionI32'] = sum(newObj['MessageSelectionE32'], newObj['MessageSelectionF32'],newObj['MessageSelectionG32'], newObj['MessageSelectionH32']);

                newObj['MessageSelectionE37'] = parseFloat(newObj['MessageSelectionK12']);
                newObj['MessageSelectionE38'] = parseFloat(newObj['MessageSelectionK13']);
                newObj['MessageSelectionE39'] = parseFloat(newObj['MessageSelectionK14']);
                newObj['MessageSelectionE40'] = parseFloat(newObj['MessageSelectionK15']);
                newObj['MessageSelectionE41'] = parseFloat(newObj['MessageSelectionK16']);

                newObj['MessageSelectionF37'] = parseFloat(newObj['MessageSelectionH20']);
                newObj['MessageSelectionF38'] = parseFloat(newObj['MessageSelectionH21']);
                newObj['MessageSelectionF39'] = parseFloat(newObj['MessageSelectionH22']);
                newObj['MessageSelectionF40'] = parseFloat(newObj['MessageSelectionH23']);
                newObj['MessageSelectionF41'] = parseFloat(newObj['MessageSelectionH24']);

                newObj['MessageSelectionG37'] = parseFloat(newObj['MessageSelectionI28']);
                newObj['MessageSelectionG38'] = parseFloat(newObj['MessageSelectionI29']);
                newObj['MessageSelectionG39'] = parseFloat(newObj['MessageSelectionI30']);
                newObj['MessageSelectionG40'] = parseFloat(newObj['MessageSelectionI31']);
                newObj['MessageSelectionG41'] = parseFloat(newObj['MessageSelectionI32']);

                newObj['MessageSelectionH37'] = sum(newObj['MessageSelectionE37'], newObj['MessageSelectionF37'],newObj['MessageSelectionG37']);
                newObj['MessageSelectionH38'] = sum(newObj['MessageSelectionE38'], newObj['MessageSelectionF38'],newObj['MessageSelectionG38']);
                newObj['MessageSelectionH39'] = sum(newObj['MessageSelectionE39'], newObj['MessageSelectionF39'],newObj['MessageSelectionG39']);
                newObj['MessageSelectionH40'] = sum(newObj['MessageSelectionE40'], newObj['MessageSelectionF40'],newObj['MessageSelectionG40']);
                newObj['MessageSelectionH41'] = sum(newObj['MessageSelectionE41'], newObj['MessageSelectionF41'],newObj['MessageSelectionG41']);
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                event.target.value = event.target.value ? event.target.value : 0
                dispatch(modifyMessageSelection({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        //window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

    useEffect(() => {
        let MessageSelectionK12 = sum(Number(Inputs.MessageSelectionE12), Number(Inputs.MessageSelectionF12),Number(Inputs.MessageSelectionG12), Number(Inputs.MessageSelectionH12), Number(Inputs.MessageSelectionI12), Number(Inputs.MessageSelectionJ12));
        let MessageSelectionK13 = sum(Number(Inputs.MessageSelectionE13), Number(Inputs.MessageSelectionF13),Number(Inputs.MessageSelectionG13), Number(Inputs.MessageSelectionH13), Number(Inputs.MessageSelectionI13), Number(Inputs.MessageSelectionJ13));
        let MessageSelectionK14 = sum(Number(Inputs.MessageSelectionE14), Number(Inputs.MessageSelectionF14),Number(Inputs.MessageSelectionG14), Number(Inputs.MessageSelectionH14), Number(Inputs.MessageSelectionI14), Number(Inputs.MessageSelectionJ14));
        let MessageSelectionK15 = sum(Number(Inputs.MessageSelectionE15), Number(Inputs.MessageSelectionF15),Number(Inputs.MessageSelectionG15), Number(Inputs.MessageSelectionH15), Number(Inputs.MessageSelectionI15), Number(Inputs.MessageSelectionJ15));
        let MessageSelectionK16 = sum(Number(Inputs.MessageSelectionE16), Number(Inputs.MessageSelectionF16),Number(Inputs.MessageSelectionG16), Number(Inputs.MessageSelectionH16), Number(Inputs.MessageSelectionI16), Number(Inputs.MessageSelectionJ16));

        let MessageSelectionH20 = sum(Number(Inputs.MessageSelectionE20), Number(Inputs.MessageSelectionF20),Number(Inputs.MessageSelectionG20));
        let MessageSelectionH21 = sum(Number(Inputs.MessageSelectionE21), Number(Inputs.MessageSelectionF21),Number(Inputs.MessageSelectionG21));
        let MessageSelectionH22 = sum(Number(Inputs.MessageSelectionE22), Number(Inputs.MessageSelectionF22),Number(Inputs.MessageSelectionG22));
        let MessageSelectionH23 = sum(Number(Inputs.MessageSelectionE23), Number(Inputs.MessageSelectionF23),Number(Inputs.MessageSelectionG23));
        let MessageSelectionH24 = sum(Number(Inputs.MessageSelectionE24), Number(Inputs.MessageSelectionF24),Number(Inputs.MessageSelectionG24));

        let MessageSelectionI28 = sum(Number(Inputs.MessageSelectionE28), Number(Inputs.MessageSelectionF28),Number(Inputs.MessageSelectionG28), Number(Inputs.MessageSelectionH28));
        let MessageSelectionI29 = sum(Number(Inputs.MessageSelectionE29), Number(Inputs.MessageSelectionF29),Number(Inputs.MessageSelectionG29), Number(Inputs.MessageSelectionH29));
        let MessageSelectionI30 = sum(Number(Inputs.MessageSelectionE30), Number(Inputs.MessageSelectionF30),Number(Inputs.MessageSelectionG30), Number(Inputs.MessageSelectionH30));
        let MessageSelectionI31 = sum(Number(Inputs.MessageSelectionE31), Number(Inputs.MessageSelectionF31),Number(Inputs.MessageSelectionG31), Number(Inputs.MessageSelectionH31));
        let MessageSelectionI32 = sum(Number(Inputs.MessageSelectionE32), Number(Inputs.MessageSelectionF32),Number(Inputs.MessageSelectionG32), Number(Inputs.MessageSelectionH32));

        let MessageSelectionE37 = parseFloat(MessageSelectionK12);
        let MessageSelectionE38 = parseFloat(MessageSelectionK13);
        let MessageSelectionE39 = parseFloat(MessageSelectionK14);
        let MessageSelectionE40 = parseFloat(MessageSelectionK15);
        let MessageSelectionE41 = parseFloat(MessageSelectionK16);

        let MessageSelectionF37 = parseFloat(MessageSelectionH20);
        let MessageSelectionF38 = parseFloat(MessageSelectionH21);
        let MessageSelectionF39 = parseFloat(MessageSelectionH22);
        let MessageSelectionF40 = parseFloat(MessageSelectionH23);
        let MessageSelectionF41 = parseFloat(MessageSelectionH24);

        let MessageSelectionG37 = parseFloat(MessageSelectionI28);
        let MessageSelectionG38 = parseFloat(MessageSelectionI29);
        let MessageSelectionG39 = parseFloat(MessageSelectionI30);
        let MessageSelectionG40 = parseFloat(MessageSelectionI31);
        let MessageSelectionG41 = parseFloat(MessageSelectionI32);

        let MessageSelectionH37 = sum(MessageSelectionE37, MessageSelectionF37,MessageSelectionG37);
        let MessageSelectionH38 = sum(MessageSelectionE38, MessageSelectionF38,MessageSelectionG38);
        let MessageSelectionH39 = sum(MessageSelectionE39, MessageSelectionF39,MessageSelectionG39);
        let MessageSelectionH40 = sum(MessageSelectionE40, MessageSelectionF40,MessageSelectionG40);
        let MessageSelectionH41 = sum(MessageSelectionE41, MessageSelectionF41,MessageSelectionG41);

        setInputs({ ...Inputs, MessageSelectionK12, MessageSelectionK13, MessageSelectionK14, MessageSelectionK15, MessageSelectionK16, MessageSelectionH20, MessageSelectionH21, MessageSelectionH22, MessageSelectionH23, MessageSelectionH24, MessageSelectionI28, MessageSelectionI29, MessageSelectionI30, MessageSelectionI31, MessageSelectionI32, MessageSelectionE37, MessageSelectionE38, MessageSelectionE39, MessageSelectionE40, MessageSelectionE41, MessageSelectionF37, MessageSelectionF38, MessageSelectionF39, MessageSelectionF40, MessageSelectionF41, MessageSelectionG37, MessageSelectionG38, MessageSelectionG39, MessageSelectionG40, MessageSelectionG41, MessageSelectionH37, MessageSelectionH38, MessageSelectionH39, MessageSelectionH40, MessageSelectionH41});

    },[Inputs.MessageSelectionE12, Inputs.MessageSelectionF12, Inputs.MessageSelectionG12, Inputs.MessageSelectionH12, Inputs.MessageSelectionI12, Inputs.MessageSelectionJ12, Inputs.MessageSelectionE13, Inputs.MessageSelectionF13, Inputs.MessageSelectionG13, Inputs.MessageSelectionH13, Inputs.MessageSelectionI13, Inputs.MessageSelectionJ13, Inputs.MessageSelectionE14, Inputs.MessageSelectionF14, Inputs.MessageSelectionG14, Inputs.MessageSelectionH14, Inputs.MessageSelectionI14, Inputs.MessageSelectionJ14, Inputs.MessageSelectionE15, Inputs.MessageSelectionF15, Inputs.MessageSelectionG15, Inputs.MessageSelectionH15, Inputs.MessageSelectionI15, Inputs.MessageSelectionJ15, Inputs.MessageSelectionE16, Inputs.MessageSelectionF16, Inputs.MessageSelectionG16, Inputs.MessageSelectionH16, Inputs.MessageSelectionI16, Inputs.MessageSelectionJ16, Inputs.MessageSelectionE20, Inputs.MessageSelectionF20, Inputs.MessageSelectionG20, Inputs.MessageSelectionE21, Inputs.MessageSelectionF21, Inputs.MessageSelectionG21, Inputs.MessageSelectionE22, Inputs.MessageSelectionF22, Inputs.MessageSelectionG22, Inputs.MessageSelectionE23, Inputs.MessageSelectionF23, Inputs.MessageSelectionG23, Inputs.MessageSelectionE24, Inputs.MessageSelectionF24, Inputs.MessageSelectionG24, Inputs.MessageSelectionE28, Inputs.MessageSelectionF28, Inputs.MessageSelectionG28, Inputs.MessageSelectionH28, Inputs.MessageSelectionE29, Inputs.MessageSelectionF29,Inputs.MessageSelectionG29, Inputs.MessageSelectionH29, Inputs.MessageSelectionE30, Inputs.MessageSelectionF30, Inputs.MessageSelectionG30, Inputs.MessageSelectionH30, Inputs.MessageSelectionE31, Inputs.MessageSelectionF31, Inputs.MessageSelectionG31, Inputs.MessageSelectionH31, Inputs.MessageSelectionE32, Inputs.MessageSelectionF32, Inputs.MessageSelectionG32, Inputs.MessageSelectionH32])
    
    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
            <div className="container-fluid">
                <div className="head-title">
                    <h3 className='preheading'>Tools - Messaging</h3>
                    <h1 className='mainheading mb-3'>Message Selection Matrix</h1>
                </div>
                <div className="opportunity-page-table">
                    <div className="opportunity-page-table-inner">
                        <Spinner/>
                        <div className="table-responsive mb-5">
                            {/* Transformative table 01 */}
                            <table className="table" style={{width: 1500}}>
                                <thead>
                                    <tr>
                                        <td rowSpan={2} colSpan={2} className='black-bg text-white ms-text-left font16px'>Message:  Premise, Big Idea, Tagline, Value Proposition, etc.</td>
                                        <td colSpan={6} className='bg-color-blue-gray text-white text-center  font16px'>Transformative</td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center'>
                                            <div className='font11px'>Startling</div>
                                        </td>
                                        <td className='black-bg text-white text-center'>
                                            <div className='font11px'>Emotionally<br/>Compelling</div>
                                        </td>
                                        <td className='black-bg text-white text-center'>
                                            <div className='font11px'>Logically<br/>Compelling</div>
                                        </td>
                                        <td className='black-bg text-white text-center'>
                                            <div className='font11px'>Credibility</div>
                                        </td>
                                        <td className='black-bg text-white text-center'>
                                            <div className='font11px'>Proof</div>
                                        </td>
                                        <td className='black-bg text-white text-center'>
                                            <div className='font11px'>Urgency</div>
                                        </td>
                                        <td className='black-bg text-white text-center'>
                                            <div className='font11px'>Score</div>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white font11px' style={{width:50}}>1</td>
                                        <td className='bg-color-01 padding-0' style={{width: 400}}>
                                            <div className="input-fiel-cn bg-color-01">
                                                <textarea className='font11px color-dark bg-color-01 w-100'
                                                id="MessageSelectionD12"
                                                value={Inputs.MessageSelectionD12}
                                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionD12: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="Message 1" />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionE12" 
                                                    value={Inputs.MessageSelectionE12} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionE12: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionF12" 
                                                    value={Inputs.MessageSelectionF12} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionF12: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionG12" 
                                                    value={Inputs.MessageSelectionG12} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionG12: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionH12" 
                                                    value={Inputs.MessageSelectionH12} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionH12: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionI12" 
                                                    value={Inputs.MessageSelectionI12} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionI12: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionJ12" 
                                                    value={Inputs.MessageSelectionJ12} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionJ12: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center' style={{width: 150}}>
                                            <div className="scrore-col font11px">
                                                {formatPrice(Inputs.MessageSelectionK12, 0)}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white font11px'>2</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01">
                                                <textarea className='font11px color-dark bg-color-01 w-100'
                                                id="MessageSelectionD13"
                                                value={Inputs.MessageSelectionD13}
                                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionD13: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="Message 2" />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionE13" 
                                                    value={Inputs.MessageSelectionE13} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionE13: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionF13" 
                                                    value={Inputs.MessageSelectionF13} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionF13: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionG13" 
                                                    value={Inputs.MessageSelectionG13} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionG13: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionH13" 
                                                    value={Inputs.MessageSelectionH13} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionH13: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionI13" 
                                                    value={Inputs.MessageSelectionI13} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionI13: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionJ13" 
                                                    value={Inputs.MessageSelectionJ13} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionJ13: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center'>
                                            <div className="scrore-col font11px">
                                                {formatPrice(Inputs.MessageSelectionK13, 0)}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white font11px'>3</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01">
                                                <textarea className='font11px color-dark bg-color-01 w-100'
                                                id="MessageSelectionD14"
                                                value={Inputs.MessageSelectionD14}
                                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionD14: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="Message 3" />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionE14" 
                                                    value={Inputs.MessageSelectionE14} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionE14: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionF14" 
                                                    value={Inputs.MessageSelectionF14} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionF14: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionG14" 
                                                    value={Inputs.MessageSelectionG14} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionG14: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionH14" 
                                                    value={Inputs.MessageSelectionH14} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionH14: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionI14" 
                                                    value={Inputs.MessageSelectionI14} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionI14: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionJ14" 
                                                    value={Inputs.MessageSelectionJ14} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionJ14: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center'>
                                            <div className="scrore-col font11px">
                                            {formatPrice(Inputs.MessageSelectionK14, 0)}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white font11px' style={{width:50, height: 50}}>4</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01">
                                                <textarea className='font11px color-dark bg-color-01 w-100'
                                                id="MessageSelectionD15"
                                                value={Inputs.MessageSelectionD15}
                                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionD15: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="Message 4" />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionE15" 
                                                    value={Inputs.MessageSelectionE15} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionE15: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionF15" 
                                                    value={Inputs.MessageSelectionF15} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionF15: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionG15" 
                                                    value={Inputs.MessageSelectionG15} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionG15: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionH15" 
                                                    value={Inputs.MessageSelectionH15} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionH15: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionI15" 
                                                    value={Inputs.MessageSelectionI15} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionI15: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionJ15" 
                                                    value={Inputs.MessageSelectionJ15} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionJ15: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center'>
                                            <div className="scrore-col font11px">
                                                {formatPrice(Inputs.MessageSelectionK15, 0)}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white font11px' style={{width:50, height: 50}}>5</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01">
                                                <textarea className='font11px color-dark bg-color-01 w-100'
                                                id="MessageSelectionD16"
                                                value={Inputs.MessageSelectionD16}
                                                onChange={(event) => setInputs({ ...Inputs, MessageSelectionD16: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="Message 5" />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionE16" 
                                                    value={Inputs.MessageSelectionE16} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionE16: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionF16" 
                                                    value={Inputs.MessageSelectionF16} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionF16: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionG16" 
                                                    value={Inputs.MessageSelectionG16} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionG16: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionH16" 
                                                    value={Inputs.MessageSelectionH16} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionH16: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionI16" 
                                                    value={Inputs.MessageSelectionI16} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionI16: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionJ16" 
                                                    value={Inputs.MessageSelectionJ16} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionJ16: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center'>
                                            <div className="scrore-col font11px">
                                                {formatPrice(Inputs.MessageSelectionK16, 0)}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="table-responsive mb-5">
                            {/* Unique table 02 */}
                            <table className="table" style={{width: 1050}}>
                                <thead>
                                    <tr>
                                        <td rowSpan={2} colSpan={2} className='black-bg text-white ms-text-left font16px'>Message:  Premise, Big Idea, Tagline, Value Proposition, etc.</td>
                                        <td colSpan={3} className='bg-color-blue-gray text-white ms-text-center font16px'>Unique</td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center'>
                                            <div className='font11px'>Unique Offer</div>
                                        </td>
                                        <td className='black-bg text-white text-center'>
                                            <div className='font11px'>Unique<br/>Mechanism</div>
                                        </td>
                                        <td className='black-bg text-white text-center'>
                                            <div className='font11px'>Outpositions<br/>Competitors</div>
                                        </td>
                                        <td className='black-bg text-white text-center'>
                                            <div className='font11px'>Score</div>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white font11px' style={{width: 50}}>1</td>
                                        <td className='bg-color-light-blue'>
                                            <div className="bg-color-light-blue font11px color-dark">
                                                {Inputs.MessageSelectionD12}
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionE20" 
                                                    value={Inputs.MessageSelectionE20} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionE20: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionF20" 
                                                    value={Inputs.MessageSelectionF20} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionF20: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionG20" 
                                                    value={Inputs.MessageSelectionG20} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionG20: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center' style={{width: 150}}>
                                            <div className="scrore-col font11px">
                                                {formatPrice(Inputs.MessageSelectionH20, 0)}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white font11px'>2</td>
                                        <td className='bg-color-light-blue'>
                                            <div className="bg-color-light-blue font11px color-dark">
                                                {Inputs.MessageSelectionD13}
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionE21" 
                                                    value={Inputs.MessageSelectionE21} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionE21: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionF21" 
                                                    value={Inputs.MessageSelectionF21} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionF21: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionG21" 
                                                    value={Inputs.MessageSelectionG21} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionG21: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center'>
                                            <div className="scrore-col font11px">
                                                {formatPrice(Inputs.MessageSelectionH21, 0)}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white font11px'>3</td>
                                        <td className='bg-color-light-blue'>
                                            <div className="bg-color-light-blue font11px color-dark">
                                                {Inputs.MessageSelectionD14}
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionE22" 
                                                    value={Inputs.MessageSelectionE22} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionE22: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionF22" 
                                                    value={Inputs.MessageSelectionF22} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionF22: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionG22" 
                                                    value={Inputs.MessageSelectionG22} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionG22: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center'>
                                            <div className="scrore-col font11px">
                                                {formatPrice(Inputs.MessageSelectionH22, 0)}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white font11px'>4</td>
                                        <td className='bg-color-light-blue'>
                                            <div className="bg-color-light-blue font11px color-dark">
                                                {Inputs.MessageSelectionD15}
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionE23" 
                                                    value={Inputs.MessageSelectionE23} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionE23: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionF23" 
                                                    value={Inputs.MessageSelectionF23} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionF23: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionG23" 
                                                    value={Inputs.MessageSelectionG23} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionG23: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center'>
                                            <div className="scrore-col font11px">
                                                {formatPrice(Inputs.MessageSelectionH23, 0)}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white font11px'>5</td>
                                        <td className='bg-color-light-blue'>
                                            <div className="bg-color-light-blue font11px color-dark">
                                                {Inputs.MessageSelectionD16}
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionE24" 
                                                    value={Inputs.MessageSelectionE24} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionE24: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionF24" 
                                                    value={Inputs.MessageSelectionF24} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionF24: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionG24" 
                                                    value={Inputs.MessageSelectionG24} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionG24: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center'>
                                            <div className="scrore-col font11px">
                                                {formatPrice(Inputs.MessageSelectionH24, 0)}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="table-responsive mb-5">
                            {/* Simple table 03 */}
                            <table className="table" style={{width: 1200}}>
                                <thead>
                                    <tr>
                                        <td rowSpan={2} colSpan={2} className='black-bg text-white text-left font16px'>Message:  Premise, Big Idea, Tagline, Value<br/>Proposition, etc.</td>
                                        <td colSpan={4} className='bg-color-blue-gray text-white text-center font16px'>Simple</td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center'>
                                            <div className='font11px'>Bold</div>
                                        </td>
                                        <td className='black-bg text-white text-center'>
                                            <div className='font11px'>Metaphor</div>
                                        </td>
                                        <td className='black-bg text-white text-center'>
                                            <div className='font11px'>Irresistible<br/>Offer</div>
                                        </td>
                                        <td className='black-bg text-white text-center'>
                                            <div className='font11px'>Brevity</div>
                                        </td>
                                        <td className='black-bg text-white text-center'>
                                            <div className='font11px'>Score</div>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white font11px' style={{width: 50}}>1</td>
                                        <td className='bg-color-light-blue'>
                                            <div className="bg-color-light-blue font11px color-dark">
                                                {Inputs.MessageSelectionD12}
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionE28" 
                                                    value={Inputs.MessageSelectionE28} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionE28: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionF28" 
                                                    value={Inputs.MessageSelectionF28} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionF28: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionG28" 
                                                    value={Inputs.MessageSelectionG28} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionG28: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionH28" 
                                                    value={Inputs.MessageSelectionH28} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionH28: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center' style={{width: 150}}>
                                            <div className="scrore-col font11px">
                                                {formatPrice(Inputs.MessageSelectionI28, 0)}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white font11px'>2</td>
                                        <td className='bg-color-light-blue'>
                                            <div className="bg-color-light-blue font11px color-dark">
                                                {Inputs.MessageSelectionD13}
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionE29" 
                                                    value={Inputs.MessageSelectionE29} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionE29: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionF29" 
                                                    value={Inputs.MessageSelectionF29} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionF29: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionG29" 
                                                    value={Inputs.MessageSelectionG29} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionG29: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionH29" 
                                                    value={Inputs.MessageSelectionH29} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionH29: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center'>
                                            <div className="scrore-col font11px">
                                                {formatPrice(Inputs.MessageSelectionI29, 0)}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white font11px'>3</td>
                                        <td className='bg-color-light-blue'>
                                            <div className="bg-color-light-blue font11px color-dark">
                                                {Inputs.MessageSelectionD14}
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionE30" 
                                                    value={Inputs.MessageSelectionE30} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionE30: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionF30" 
                                                    value={Inputs.MessageSelectionF30} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionF30: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionG30" 
                                                    value={Inputs.MessageSelectionG30} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionG30: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionH30" 
                                                    value={Inputs.MessageSelectionH30} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionH30: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center'>
                                            <div className="scrore-col font11px">
                                                {formatPrice(Inputs.MessageSelectionI30, 0)}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white font11px'>4</td>
                                        <td className='bg-color-light-blue'>
                                            <div className="bg-color-light-blue font11px color-dark">
                                                {Inputs.MessageSelectionD15}
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionE31" 
                                                    value={Inputs.MessageSelectionE31} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionE31: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionF31" 
                                                    value={Inputs.MessageSelectionF31} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionF31: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionG31" 
                                                    value={Inputs.MessageSelectionG31} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionG31: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionH31" 
                                                    value={Inputs.MessageSelectionH31} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionH31: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center'>
                                            <div className="scrore-col font11px">
                                                {formatPrice(Inputs.MessageSelectionI31, 0)}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white font11px'>5</td>
                                        <td className='bg-color-light-blue'>
                                            <div className="bg-color-light-blue font11px color-dark">
                                                {Inputs.MessageSelectionD16}
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionE32" 
                                                    value={Inputs.MessageSelectionE32} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionE32: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionF32" 
                                                    value={Inputs.MessageSelectionF32} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionF32: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionG32" 
                                                    value={Inputs.MessageSelectionG32} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionG32: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                    id="MessageSelectionH32" 
                                                    value={Inputs.MessageSelectionH32} 
                                                    onChange={(event) => setInputs({ ...Inputs, MessageSelectionH32: event.target.value }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    maxLength="1" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-5]/.test(event.key)) {
                                                            event.preventDefault();
                                                            alert.show('Please Enter Score range 1 to 5.')
                                                        }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center'>
                                            <div className="scrore-col font11px">
                                                {formatPrice(Inputs.MessageSelectionI32, 0)}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="table-responsive mb-5">
                            {/* Message Impact Score table 04 */}
                            <table className="table" style={{width: 1050}}>
                                <thead>
                                    <tr>
                                        <td rowSpan={2} colSpan={2} className='black-bg text-white text-left font16px'>Message:  Premise, Big Idea, Tagline, Value<br/>Proposition, etc.</td>
                                        <td colSpan={3} className='bg-color-blue-gray text-white text-center font16px'>Message Impact Score</td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center'>
                                            <div className='font11px'>Transformative</div>
                                        </td>
                                        <td className='black-bg text-white text-center'>
                                            <div className='font11px'>Unique</div>
                                        </td>
                                        <td className='black-bg text-white text-center'>
                                            <div className='font11px'>Simple</div>
                                        </td>
                                        <td className='black-bg text-white text-center'>
                                            <div className='font11px'>Score</div>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white font11px' style={{width: 50}}>1</td>
                                        <td className='bg-color-light-blue' style={{width: 400}}>
                                            <div className="bg-color-light-blue font11px color-dark">
                                                {Inputs.MessageSelectionD12}
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0' style={{width: 150}}>
                                            <div className="bg-color-light-blue font11px text-center color-dark">
                                                {formatPrice(Inputs.MessageSelectionE37, 0)}
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0' style={{width: 150}}>
                                            <div className="bg-color-light-blue font11px text-center color-dark">
                                                {formatPrice(Inputs.MessageSelectionF37, 0)}
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0' style={{width: 150}}>
                                            <div className="bg-color-light-blue font11px text-center color-dark">
                                                {formatPrice(Inputs.MessageSelectionG37, 0)}
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center' style={{width: 150}}>
                                            <div className="scrore-col font11px">
                                                {formatPrice(Inputs.MessageSelectionH37, 0)}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white font11px'>2</td>
                                        <td className='bg-color-light-blue'>
                                            <div className="bg-color-light-blue font11px color-dark">
                                                {Inputs.MessageSelectionD13}
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="bg-color-light-blue font11px text-center color-dark">
                                                {formatPrice(Inputs.MessageSelectionE38, 0)}
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="bg-color-light-blue font11px text-center color-dark">
                                                {formatPrice(Inputs.MessageSelectionF38, 0)}
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="bg-color-light-blue font11px text-center color-dark">
                                                {formatPrice(Inputs.MessageSelectionG38, 0)}
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center'>
                                            <div className="scrore-col font11px">
                                                {formatPrice(Inputs.MessageSelectionH38, 0)}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white font11px'>3</td>
                                        <td className='bg-color-light-blue'>
                                            <div className="bg-color-light-blue font11px color-dark">
                                                {Inputs.MessageSelectionD14}
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="bg-color-light-blue font11px text-center color-dark">
                                                {formatPrice(Inputs.MessageSelectionE39, 0)}
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="bg-color-light-blue font11px text-center color-dark">
                                                {formatPrice(Inputs.MessageSelectionF39, 0)}
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="bg-color-light-blue font11px text-center color-dark">
                                                {formatPrice(Inputs.MessageSelectionG39, 0)}
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center'>
                                            <div className="scrore-col font11px">
                                                {formatPrice(Inputs.MessageSelectionH39, 0)}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white font11px'>4</td>
                                        <td className='bg-color-light-blue'>
                                            <div className="bg-color-light-blue font11px color-dark">
                                                {Inputs.MessageSelectionD15}
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="bg-color-light-blue font11px text-center color-dark">
                                                {formatPrice(Inputs.MessageSelectionE40, 0)}
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="bg-color-light-blue font11px text-center color-dark">
                                                {formatPrice(Inputs.MessageSelectionF40, 0)}
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="bg-color-light-blue font11px text-center color-dark">
                                                {formatPrice(Inputs.MessageSelectionG40, 0)}
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center'>
                                            <div className="scrore-col font11px">
                                                {formatPrice(Inputs.MessageSelectionH40, 0)}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white font11px'>5</td>
                                        <td className='bg-color-light-blue'>
                                            <div className="bg-color-light-blue font11px color-dark">
                                                {Inputs.MessageSelectionD16}
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="bg-color-light-blue font11px text-center color-dark">
                                                {formatPrice(Inputs.MessageSelectionE41, 0)}
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="bg-color-light-blue font11px text-center color-dark">
                                                {formatPrice(Inputs.MessageSelectionF41, 0)}
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="bg-color-light-blue font11px text-center color-dark">
                                                {formatPrice(Inputs.MessageSelectionG41, 0)}
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center'>
                                            <div className="scrore-col font11px">
                                                {formatPrice(Inputs.MessageSelectionH41, 0)}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="nextInput">
                            <ul>
                                <li>
                                    <Link to="/tools/whole-brain-messaging" className='next-link'> Next:  Whole Brain Messaging  
                                        <span className='icon-three'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                        </span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
