import React from 'react'
import  UserDropdown  from '../UserDropdown';

export default function MintoPyramidContent() {

  return (
      <div className="content-body">
       <UserDropdown />
    
        {/* row */}
        <div className="container-fluid">
            <div className="head-title">
                <p>Messaging </p>
                <h2>The Minto Pyramid </h2>
                <p>The Minto Pyramid Principle helps you organized and synthesize information, find gaps in your analysis, and communicate ideas</p>
                <p><b>Step 1</b>: Synthesize and organize your data and ideas (bottom up; apply MECE).  Should organize the ideas into 3 takeaways.  Do the three takeaways align into a main insight? </p>
                <p className="mb-5"><b>Step 2:</b> Communicate your message top down (big idea or answer first).  Then communicate the 3 supporting arguments.  After that, you dive into the data supporting each argument. </p>
            </div>
            <div className="card">
                <div className="card-body pl-0">
                <div className="table-responsive table-dta">
                    <table className="table mb-0">
                    <thead>
                        <tr className="position-relative-div">
                        <td colSpan={10} className="bg-color1 text-white text-center-c padding-15 text-font-size-14">
                            <p className="mb-0">Introduction to Key Message</p>
                            <p className="mb-0">Situation &gt; Complication &gt; Questions &gt; Answers</p>
                        </td>
                        {/* <div className="over-line">helo</div> */}
                        </tr>
                    </thead>
                    </table>
                    {/* table 2 */}
                    <table className="table mb-5 mt-5">
                    <thead>
                        <tr>
                        <td colSpan={3} className="bg-color3 text-white text-center-c width-25"> 
                            Supporting Argument or Insight #1 
                        </td>
                        <td colSpan={1} className="bg-color3 text-white text-center-c width-5 d-hidden">
                            2
                        </td>
                        <td colSpan={3} className="bg-color3 text-white text-center-c width-25 position-relative-div addline">
                            Supporting Argument or Insight #1 
                        </td>
                        <td colSpan={1} className="bg-color3 text-white text-center-c width-5 d-hidden">
                            4
                        </td>
                        <td colSpan={3} className="bg-color3 text-white text-center-c width-25 position-relative-div addline">
                            Supporting Argument or Insight #1 
                        </td>
                        </tr>
                    </thead>
                    </table>
                    {/* table 3 */}
                    <table className="table mb-0">
                    <tbody>
                        <tr>
                        <td colSpan={3} className="bg-color2 border-0 width-25"> 
                            Supporting Data # 1 
                        </td>
                        <td colSpan={1} className="bg-color2 border-0 width-5 d-hidden">
                            2
                        </td>
                        <td colSpan={3} className="bg-color2 border-0 width-25">
                            Supporting Data # 1
                        </td>
                        <td colSpan={1} className="bg-color2 border-0 width-5 d-hidden">
                            4
                        </td>
                        <td colSpan={3} className="bg-color2 border-0 width-25">
                            Supporting Data # 1
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={3} className="bg-color2 border-0 width-25"> 
                            Supporting Data # 2 
                        </td>
                        <td colSpan={1} className="bg-color2 border-0 width-5 d-hidden">
                            2
                        </td>
                        <td colSpan={3} className="bg-color2 border-0 width-25">
                            Supporting Data # 2
                        </td>
                        <td colSpan={1} className="bg-color2 border-0 width-5 d-hidden">
                            4
                        </td>
                        <td colSpan={3} className="bg-color2 border-0 width-25">
                            Supporting Data # 2
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={3} className="bg-color2 border-0 width-25 position-relative-div addline3"> 
                            Supporting Data # 3 
                        </td>
                        <td colSpan={1} className="bg-color2 border-0 width-5 d-hidden">
                            2
                        </td>
                        <td colSpan={3} className="bg-color2 border-0 width-25 position-relative-div addline3">
                            Supporting Data # 3
                        </td>
                        <td colSpan={1} className="bg-color2 border-0 width-5 d-hidden">
                            4
                        </td>
                        <td colSpan={3} className="bg-color2 border-0 width-25 position-relative-div addline3">
                            Supporting Data # 3 
                        </td>
                        </tr>
                    </tbody>
                    </table>
                    {/* table 4 */}
                    <table className="table mb-0 mt-5">
                    <thead>
                        <tr>
                        <th scope="row" colSpan={10} className="bg-color1 text-white text-center-c padding-15">
                            <p className="mb-0">Conclusion</p>
                        </th>
                        </tr>
                    </thead>
                    </table>
                </div>
                </div>
            </div>
            <div className="row strategicpage">
                <div className="col-md-12 contentleft">
                <div className="card">
                    <div className="card-body">
                    <h4>General Strategy Tools</h4>
                    <ul>
                        <li><b>Key Message: </b> answer the question first.
                        - Question:  what are we trying to solve?
                        - Answer:  your solution to solve the question.
                        </li>
                        <li><b>Supporting Arguments &amp; Insights:</b> solutions supported by a logical 
                        combination of MECE arguments (HOW or WHY).
                        </li>
                        <li><b>Supporting Data: </b> each argument must be backed by 
                        supporting data (HOW or WHY)
                        </li>
                    </ul>
                    </div>   
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}
