import React from 'react'
import SidebarNew from '../components/SidebarNew';
import Content from '../components/content/DifferentiationDiagnosticContent';
import Footer from '../components/Footer';

export default function DifferentiationDiagnostic() {
  return (
    <div>
      <div id="main-wrapper">

        <SidebarNew />

        <Content />

      </div>

      <Footer />

    </div>
  )
}
