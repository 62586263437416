import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state/index';
import  UserDropdown  from '../UserDropdown';
import { addEditImprovementPercentage } from "../../state/features/ClentInputSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { useAlert } from 'react-alert'
import { Spinner } from '../spinner'
import { Link } from 'react-router-dom';
import { StatesUseEffect } from '../../utils/StatesUseEffect';
import { BarChartGraphNew } from '../graph/BarChartGraphNew';

export default function ReferralsContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const users = useSelector(userData);
    const userID = users.data.profile.id;
    const { message } = useSelector((state) => state.message);

    const clientInputs = useSelector(state => state.clientInputs)
    const improvementPercentage = clientInputs.improvementPercentage
    const dispatch = useDispatch();
    const { referralsData } = bindActionCreators(actionCreators, dispatch)
    const referrals = useSelector(state => state.referrals)

    const [Inputs, setInputs] = useState({
        referralsD13: Number(referrals.referralsD13).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1}),
    });

    const { clientInputsH27 } = clientInputs

    useEffect(() => {
        referralsData("referralsD12",clientInputsH27);
        referralsData("referralsD13",improvementPercentage.referralsD13);
    }, [])

    const { referralsD17, referralsE17, referralsD18, referralsE18, referralsF18, referralsG18, referralsF17, referralsG17, referralsH18, referralsI18, referralsH17, referralsI17, referralsD22, referralsE22, referralsF22, referralsG22, referralsH22, referralsI22, referralsD23, referralsE23, referralsF23, referralsG23, referralsH23, referralsI23, referralsD25, referralsE25, referralsF25, referralsG25, referralsH25, referralsI25, referralsD26, referralsE26, referralsF26, referralsG26, referralsH26, referralsI26, referralsD28, referralsE28, referralsF28, referralsG28, referralsH28, referralsI28 } = referrals //object destructuring for referrals

    const improvementPrcntHandler = (impPrcnt) => {
        setIsLoading(true);
        let sendData = {user_id : userID, referralsD13: impPrcnt}
        dispatch(addEditImprovementPercentage(sendData))
        .unwrap()
        .then((response) => {
          console.log("response",response)
            if(response!= undefined){
               console.log('success')
            }
            setIsLoading(false)   // Hide loading screen 
        })
        .catch((error) => {
          console.log("error",error)
            { message && alert.show(message) }
            setIsLoading(false)   // Hide loading screen 
        });
    }

    // call states useEffect utils
    StatesUseEffect()

    return (
        <div className="content-body content-body-new bg-white-color">
        <UserDropdown />
    
            {/* row */}
            <div className="container-fluid lead-generation-new">
                <div className="head-title mb-5">
                    <h3 className='preheading'>Prescribe - Growth Drivers</h3>
                    <h1 className='mainheading mb-1'>Referrals</h1>	
                </div>
                <div className="row lead-genearation-rw mb-5" style={{maxWidth: 1200}}>
                    <div className="col-12 col-lg-6">
                        {/* <div className="potential-growth-ld mb-3" style={{maxWidth: 450}}>
                            <h3><strong>Potential Growth Activities or Tactics:</strong></h3>
                            <ol>
                                <li>Activity 1</li>
                                <li>Activity 2</li>
                                <li>Activity 3</li>
                            </ol>
                        </div> */}
                        <div className="lead-generation-table mt-218" style={{overflowY: 'auto'}}>
                            <div className="responsive-table">
                                <table className='table' style={{width: 450}}>
                                    <thead>
                                        <tr>
                                            <th scope='col' className='black-bg text-white fontBold' style={{width: 300}}>Improving Referrals</th>
                                            <th className='black-bg hide-content' style={{width: 150}}>2</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td scope='row' className='bg-color-light-blue font12px color-black'>Referrals Generated Annually</td>
                                            <td className='bg-color-light-blue font12px color-black text-right-rt'>{clientInputsH27}</td>
                                        </tr>
                                        <tr>
                                            <td scope='row' className='bg-color-light-blue font12px color-black'>Projected Improvement</td>
                                            <th scope='row' className='padding-0'>
                                                <div className="dollor-sin-pr">
                                                    <div className="input-fiel-cn bg-color-01 client-inpt-field">
                                                        <input type="text" 
                                                        name="Inputs.referralsD13"
                                                        className="field-ipt wthinherit font12px bg-color-01 text-right text-black"
                                                        placeholder={0}
                                                        value={Inputs.referralsD13}
                                                        onChange={(event) => {
                                                            setInputs({ ...Inputs, referralsD13: event.target.value });
                                                            referralsData("referralsD13", event.target.value);
                                                            improvementPrcntHandler(event.target.value)
                                                        }}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                            }
                                                        }}
                                                        disabled={isLoading}
                                                        />
                                                    </div>
                                                    <div className="perecentage">%</div>
                                                </div>
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                                <Spinner />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6" style={{minHeight: 400}}>
                        <BarChartGraphNew dataInput={{
                            "Title"      : "Referrals: Impact on Financial Performance ",
                            "ColorCodes" : ["#d6dce5", "#8497b0", "#1e2b3c"],
                            "GraphData"  : {
                                labels: [["Your","Projection"], "Year 1", "Year 2", "Year 3", "Year 4", "Year 5"],
                                datasets: [
                                    {
                                        label: "Profit",
                                        backgroundColor: "#1e65a7", //"#d6dce5",
                                        data: [Number(referralsD23),Number(referralsE23),Number(referralsF23),Number(referralsG23),Number(referralsH23),Number(referralsI23)]
                                    },
                                    {
                                        label: "Sales",
                                        backgroundColor: "#8497b0",
                                        data: [Number(referralsD22),Number(referralsE22),Number(referralsF22),Number(referralsG22),Number(referralsH22),Number(referralsI22)]
                                    },
                                    {
                                        label: "Valuation",
                                        backgroundColor: "#1e2b3c",
                                        data: [Number(referralsD28),Number(referralsE28),Number(referralsF28),Number(referralsG28),Number(referralsH28),Number(referralsI28)]
                                    }
                                ]
                            }
                            }} />
                        {/* <div className="">
                            <h3 className='text-center impact-lead-titile'><strong>Referrals: Impact on Financial Performance </strong></h3>
                            
                            <BarChartGraph dataInput={{
                                "Title"      : "",
                                "vTitle"     : "",
                                "hTitle"     : "",
                                "subTitle"   : "",
                                "ColorCodes" : ["#c2c7cd", "#8497b0", "#44546a"],
                                "GraphData"  : [
                                    ["", "Profit", "Sales", "Valuation"],
                                    ["Year 1", Number(referralsE23), Number(referralsE22), Number(referralsE28)],
                                    ["Year 2", Number(referralsF23), Number(referralsF22), Number(referralsF28)],
                                    ["Year 3", Number(referralsG23), Number(referralsG22), Number(referralsG28)],
                                    ["Year 4", Number(referralsH23), Number(referralsH22), Number(referralsH28)],
                                    ["Year 5", Number(referralsI23), Number(referralsI22), Number(referralsI28)]
                                ]
                            }} />
                        </div> */}
                    </div>
                </div>
                <div className="card update-class-lead">
                    <div className="card-body pl-0">
                        <div className="table-responsive">
                            <table className="table table-responsive-md table-striped mb-0" style={{width: 1200}}>
                                <thead>
                                    <tr>
                                    <th className="table-primar" style={{width: 300}}/>
                                    <th colSpan={2} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Year 1 Gains</th>
                                    <th colSpan={2} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Mid-Term Gains</th>
                                    <th colSpan={5} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Long-Term Gains </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="thead-primary">
                                        <th className='black-bg fontBold' style={{width: 300}}>Referral Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>Your Projection</th>
                                        <th className='black-bg' style={{width: 150}}>1% Quarterly Improvement</th>
                                        <th className='black-bg' style={{width: 150}}>2 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>3 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>4 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>5 Years of 1% Improvements</th>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>New Customer from Referrals</td>
                                        <td className='bg-color-blue-gray text-white font12px'> {Number(referralsD17).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})} </td>
                                        <td className='bg-color-light-blue font12px color-black'> {Number(referralsE17).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})} </td>
                                        <td className='bg-color-light-blue font12px color-black'> {Number(referralsF17).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})} </td>
                                        <td className='bg-color-light-blue font12px color-black'> {Number(referralsG17).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})} </td>
                                        <td className='bg-color-light-blue font12px color-black'> {Number(referralsH17).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})} </td>
                                        <td className='bg-color-light-blue font12px color-black'> {Number(referralsI17).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})} </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'><strong>Total Number of Annual Referrals</strong></td>
                                        <td className='bg-color-blue-gray text-white font12px'> {Number(referralsD18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})} </td>
                                        <td className='bg-color-teal text-white font12px'><strong> {Number(referralsE18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong></td>
                                        <td className='bg-color-teal text-white font12px'><strong> {Number(referralsF18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong></td>
                                        <td className='bg-color-teal text-white font12px'><strong> {Number(referralsG18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong></td>
                                        <td className='bg-color-teal text-white font12px'><strong> {Number(referralsH18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong></td>
                                        <td className='bg-color-teal text-white font12px'><strong> {Number(referralsI18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong></td>
                                    </tr>
                                    </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="card update-class-lead">
                            <div className="card-body pl-0">
                            <div className="table-responsive">
                                <table className="table table-responsive-md table-striped mb-0" style={{width: 1200}}>
                                <thead>
                                    <tr>
                                    <th className='nowrap' style={{background:'#fff', width: 300}}><h3>Financial Impact</h3></th>
                                    <th colSpan={2} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Year 1 Gains</th>
                                    <th colSpan={2} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Mid-Term Gains</th>
                                    <th colSpan={5} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Long-Term Gains </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="thead-primary">
                                        <th className='black-bg fontBold' style={{width: 300}}>Annual Impact of Improved Referrals</th>
                                        <th className='black-bg' style={{width: 150}}>Your Projection</th>
                                        <th className='black-bg' style={{width: 150}}>1% Quarterly Improvement</th>
                                        <th className='black-bg' style={{width: 150}}>2 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>3 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>4 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>5 Years of 1% Improvements</th>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>Annual Sales</td>
                                        <td className='bg-color-blue-gray text-white font12px'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px text-white"> $ </div>
                                                <div className="annual-dl-number font12px text-white">{Number(referralsD22).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div> 
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">{Number(referralsE22).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div> 
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">{Number(referralsF22).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div> 
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">{Number(referralsG22).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">{Number(referralsH22).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">{Number(referralsI22).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>Annual Profit</td>
                                        <td className='bg-color-blue-gray text-white font12px'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px text-white"> $ </div>
                                                <div className="annual-dl-number font12px text-white">{Number(referralsD23).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div> 
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">{Number(referralsE23).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div> 
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">{Number(referralsF23).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div> 
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">{Number(referralsG23).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">{Number(referralsH23).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">{Number(referralsI23).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="thead-primary">
                                        <th className='black-bg fontBold'>Lifetime Impact of Improved Referrals</th>
                                        <th className='black-bg'>Your Projection</th>
                                        <th className='black-bg'>1% Quarterly Improvement</th>
                                        <th className='black-bg'>2 Years of 1% Improvements</th>
                                        <th className='black-bg'>3 Years of 1% Improvements</th>
                                        <th className='black-bg'>4 Years of 1% Improvements</th>
                                        <th className='black-bg'>5 Years of 1% Improvements</th>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>Lifetime Sales</td>
                                        <td className='bg-color-blue-gray text-white font12px'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px text-white"> $ </div>
                                                <div className="annual-dl-number font12px text-white">{Number(referralsD25).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div> 
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">{Number(referralsE25).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div> 
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">{Number(referralsF25).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div> 
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">{Number(referralsG25).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">{Number(referralsH25).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">{Number(referralsI25).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>Lifetime Profit</td>
                                        <td className='bg-color-blue-gray text-white font12px'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px text-white"> $ </div>
                                                <div className="annual-dl-number font12px text-white">{Number(referralsD26).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div> 
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">{Number(referralsE26).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div> 
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">{Number(referralsF26).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div> 
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">{Number(referralsG26).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">{Number(referralsH26).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">{Number(referralsI26).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="thead-primary">
                                        <th className='black-bg fontBold'>Valuation Impact of Improved Referrals</th>
                                        <th className='black-bg'>Your Projection</th>
                                        <th className='black-bg'>1% Quarterly Improvement</th>
                                        <th className='black-bg'>2 Years of 1% Improvements</th>
                                        <th className='black-bg'>3 Years of 1% Improvements</th>
                                        <th className='black-bg'>4 Years of 1% Improvements</th>
                                        <th className='black-bg'>5 Years of 1% Improvements</th>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>Estimated Valuation Impact</td>
                                        <td className='bg-color-blue-gray text-white font12px'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px text-white"> $ </div>
                                                <div className="annual-dl-number font12px text-white">{Number(referralsD28).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div> 
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">{Number(referralsE28).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div> 
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">{Number(referralsF28).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div> 
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">{Number(referralsG28).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">{Number(referralsH28).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">{Number(referralsI28).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}</div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="nextInput">
                            <ul>
                                <li>
                                    <Link to="/prescribe/purchase-frequency" className='next-link'> Next: Purchase Frequency   
                                        <span className='icon-three'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                        </span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
