import React from "react";
import { Chart } from "react-google-charts";

export function MultipleLineChartGraph({dataInput}) {

  const data = dataInput.GraphData;
  const Title = dataInput.Title ? dataInput.Title : "";
  const vTitle = dataInput.vTitle ? dataInput.vTitle : "";
  const hTitle = dataInput.hTitle ? dataInput.hTitle : "";
  const subTitle = dataInput.subTitle ? dataInput.subTitle : "";
  const ColorCodes = dataInput.ColorCodes ? dataInput.ColorCodes : ["#efbc44", "#447fef", "#0e8262", "#aa28d9"];

  const options = {
    title: Title,
    chartArea: { width: "50%" },
    colors: ColorCodes,
    hAxis: {
      title: hTitle,
      minValue: 0,
    },
    vAxis: {
      title: vTitle,
      minValue: 0,
    },
    subtitle: subTitle,
    series: {
      1: { curveType: "function" },
    },
  };

  return (
    <Chart
      chartType="LineChart"
      width="100%"
      height="272px"
      data={data}
      options={options}
    />
  );
}
