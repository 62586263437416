import React from 'react'
import SidebarNew from '../components/SidebarNew';
import Content from '../components/content/ProfitMarginScorecardContentNew';
import Footer from '../components/Footer';

export default function ProfitMarginScorecard() {
  return (
    <div>

      <div id="main-wrapper">

        <SidebarNew />

        <Content />

      </div>

      <Footer />

    </div>
  )
}
