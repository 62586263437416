import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../features/Message";
import innovationRankingService from "../../services/innovationRanking.service";

//Get data based on user
export const getInnovationRanking = createAsyncThunk(
  "revenueGrowth/getInnovationRanking",
  async ({user_id}, thunkAPI) => {
    try {
      const response = await innovationRankingService.getData(user_id);
      //console.log(response)
      //thunkAPI.dispatch(setMessage(response.data.message));
      return response.data
    } catch (error) {
      console.log(error)
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

//add or edit InnovationRanking
export const modifyInnovationRanking = createAsyncThunk(
  "revenueGrowth/modifyInnovationRanking",
  async (postClientData , thunkAPI) => {
    try {
      const data = await innovationRankingService.postData(postClientData);
      thunkAPI.dispatch(setMessage(""));
      console.log(data)
      return { revenueGrowth : data };
    } catch (error) {
      console.log('errorcode',error.code)
      if(error.code == 'ERR_BAD_REQUEST'){
        return {status : 401}
      }
      else{
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const InnovationRankingSlice = createSlice({
    name: 'InnovationRanking',
    reducers: {
      
    },
    extraReducers: {
      [getInnovationRanking.pending]: (state) => {
        state.loading = true
      },
      [getInnovationRanking.fulfilled]: (state, { payload }) => {
        //console.log("---------get Data -----------", payload.data.InnovationRanking)
        state.loading = false
      },
      [getInnovationRanking.rejected]: (state) => {
        state.loading = false
      },
    },
  })

const { reducer } = InnovationRankingSlice;
export default reducer;