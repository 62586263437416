import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import {useSWRConfig} from "swr";
import useDocumentTitle from '../utils/useDocumentTitle'

export default function UserDropdownMobile() {
    const { fallback, isLoading } = useSWRConfig()
    const { user: currentUser } = useSelector((state) => state.auth);
    useDocumentTitle()
    return (
        <div className="user-dropdown-list desktop-menu-div mobile-dropdown">
            <div className="c">
                {/* The dropdown starts here... */}
                <div className="dd">
                    <div className="dd-a"><span className="fa fa-user"> <span className="username-ds">{(fallback && fallback.settings.full_name) ? fallback.settings.full_name : `${currentUser.data.profile.first_name} ${currentUser.data.profile.last_name}`}</span> </span><span className="fa fa-angle-down" /></div>
                    <input type="checkbox" />
                    <div className="dd-c">
                        <ul>
                            <li><Link to="/profile" ><span>User Profile</span></Link></li>
                            {
                            ((fallback && fallback.settings.is_allow_branding == 1) || currentUser.data.profile.is_allow_branding == 1) ?
                            <>
                                <li><Link to="/manage-branding" ><span>Manage Branding</span></Link></li>
                            </> 
                            : <></>
                            } 
                            <li><Link to="/change-password" ><span>Change Password</span></Link></li>
                            <li><Link to="/logout" ><span>Log Out</span></Link></li>
                        </ul>
                    </div>
                </div>
                {/* ...and ends here. */}
            </div>
        </div>
    )
}
