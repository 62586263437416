import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import  UserDropdown  from '../UserDropdown';

import { getBenchmarkScoreGapAnalysis } from "../../state/features/BenchmarkScoreGapAnalysisSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import { useAlert } from 'react-alert'

export default function GrowthDriverPerformanceOverviewContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;

    const dispatch = useDispatch();
    const [Inputs, setInputs] = useState({
        grade_1  : 'bg-color6',
        grade_2  : 'bg-color6',
        grade_3  : 'bg-color6',
        grade_4  : 'bg-color6',
        grade_5  : 'bg-color6',
        grade_6  : 'bg-color6',
        grade_7  : 'bg-color6',
        grade_8  : 'bg-color6',
        grade_9  : 'bg-color6',
        grade_10 : 'bg-color6',
        grade_11 : 'bg-color6',
        grade_12 : 'bg-color6',
        grade_13 : 'bg-color6',
        grade_14 : 'bg-color6',
        grade_15 : 'bg-color6',
        grade_16 : 'bg-color6',
        grade_17 : 'bg-color6',
        grade_18 : 'bg-color6',
        grade_19 : 'bg-color6',
        grade_20 : 'bg-color6',
        grade_21 : 'bg-color6',
        grade_22 : 'bg-color6',
        grade_23 : 'bg-color6',
        grade_24 : 'bg-color6',
        grade_25 : 'bg-color6',
        grade_26 : 'bg-color6',
        grade_27 : 'bg-color6',
        grade_28 : 'bg-color6',
        grade_29 : 'bg-color6',
        grade_30 : 'bg-color6',
        grade_31 : 'bg-color6',
        grade_32 : 'bg-color6',
        grade_33 : 'bg-color6',
        grade_34 : 'bg-color6',
        grade_35 : 'bg-color6',
        grade_36 : 'bg-color6',
        grade_37 : 'bg-color6',
    })

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getBenchmarkScoreGapAnalysis({user_id}))
        .unwrap()
        .then((response) => {
            const newBenchmarkScoreObj = { ...Inputs }
            let benchmarkCategoryWiseUserScoreList = response.data.benchmarkCategoryWiseUserScoreList ? response.data.benchmarkCategoryWiseUserScoreList : '';
            if(benchmarkCategoryWiseUserScoreList){
                benchmarkCategoryWiseUserScoreList.map((typeObj, typeKey) => {
                    if(typeObj.category){
                        typeObj.category.map((list, listKey) => {
                            let target = (!isNaN(list.bqc_total_score) && list.bqc_total_score) ? parseFloat(list.bqc_total_score) : 0;
                            let userScore = (!isNaN(list.user_score) && list.user_score ? parseFloat(list.user_score) : 0)
                            let grade = Math.round((userScore / target) * 100);
                            newBenchmarkScoreObj["grade_"+list.id] = grade;
                            console.log('--loop--')
                            if((listKey+1) == typeObj.category.length){
                                setInputs(newBenchmarkScoreObj);
                                console.log('key--', listKey);
                                return response
                            }
                        })
                    }else{
                        return response;
                    }
                })
            }else{
                return response;
            }
        })
        .then(() => {
            setIsLoading(false)   // Hide loading screen         
        })
        .catch((error) => {
            console.log("error",error)
            setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });
    }, [dispatch])

  return (
    <div className="content-body">
    <UserDropdown />
    
        {/* row */}
        <div className="container-fluid">
            <div className="head-title">
                <h2>Growth Driver Performance Overview</h2>	            					
            </div>  
            <Spinner />              
            <div className="card">
                <div className="card-body pl-0">
                <div className="table-responsive">
                    <table className="table">
                    <thead>
                        <tr>
                        <th className="bgradius" colSpan={2} style={{textAlign: 'center'}}>Strategic Foundation</th>
                        <th className="bgradius" style={{textAlign: 'center'}} colSpan={3}>Short-Term Growth (Revenue)</th>
                        <th className="bgradius" colSpan={2}>Countinuous Improvement</th>
                        <th className="bgradius" colSpan={4}>Long-Term Growth (Valuation)</th>
                        <th />
                        </tr>
                    </thead>

                    <tbody>
                        <tr className="thead-primary">
                        <th>Competitive Advantage</th>
                        <th>Financial Performance</th>
                        <th>Customer Acquisition</th>
                        <th>Customer Retention</th>
                        <th>Customer Transactions</th>
                        <th>Ability</th>
                        <th>Agility</th>
                        <th>Strategic Value</th>
                        <th>Customers</th>
                        <th>Operations</th>
                        <th>Growth Plateform</th>
                        <th className={'color-dg'}>Best Practice</th>
                        </tr>
                        <tr>
                        <td className={(Inputs.grade_1 > 59 ? 'color-g' : (Inputs.grade_1 < 39 && Inputs.grade_1 > 19 ? 'color-r' : (Inputs.grade_1 < 19 ? 'color-dr' : 'bg-color6')))}>Markets &amp; Customers</td>
                        <td className={(Inputs.grade_7 > 59 ? 'color-g' : (Inputs.grade_7 < 39 && Inputs.grade_7 > 19 ? 'color-r' : (Inputs.grade_7 < 19 ? 'color-dr' : 'bg-color6')))}>Customer Financial Value</td>
                        <td className={(Inputs.grade_9 > 59 ? 'color-g' : (Inputs.grade_9 < 39 && Inputs.grade_9 > 19 ? 'color-r' : (Inputs.grade_9 < 19 ? 'color-dr' : 'bg-color6')))}>Lead Generation</td>
                        <td className={(Inputs.grade_12 > 59 ? 'color-g' : (Inputs.grade_12 < 39 && Inputs.grade_12 > 19 ? 'color-r' : (Inputs.grade_12 < 19 ? 'color-dr' : 'bg-color6')))}>Perchase Frequency</td>
                        <td className={(Inputs.grade_16 > 59 ? 'color-g' : (Inputs.grade_16 < 39 && Inputs.grade_16 > 19 ? 'color-r' : (Inputs.grade_1 < 19 ? 'color-dr' : 'bg-color6')))}>Transaction Value</td>
                        <td className={(Inputs.grade_18 > 59 ? 'color-g' : (Inputs.grade_18 < 39 && Inputs.grade_18 > 19 ? 'color-r' : (Inputs.grade_18 < 19 ? 'color-dr' : 'bg-color6')))}>Growth Capabilities</td>
                        <td className={(Inputs.grade_22 > 59 ? 'color-g' : (Inputs.grade_22 < 39 && Inputs.grade_22 > 19 ? 'color-r' : (Inputs.grade_22 < 19 ? 'color-dr' : 'bg-color6')))}>Countinuous Testing</td>
                        <td className={(Inputs.grade_24 > 59 ? 'color-g' : (Inputs.grade_24 < 39 && Inputs.grade_24 > 19 ? 'color-r' : (Inputs.grade_24 < 19 ? 'color-dr' : 'bg-color6')))}>Compatitive Advantage</td>
                        <td className={(Inputs.grade_35 > 59 ? 'color-g' : (Inputs.grade_35 < 39 && Inputs.grade_35 > 19 ? 'color-r' : (Inputs.grade_35 < 19 ? 'color-dr' : 'bg-color6')))}>Resilience</td>
                        <td className={(Inputs.grade_32 > 59 ? 'color-g' : (Inputs.grade_32 < 39 && Inputs.grade_32 > 19 ? 'color-r' : (Inputs.grade_32 < 19 ? 'color-dr' : 'bg-color6')))}>Customer Base</td>
                        <td className={(Inputs.grade_29 > 59 ? 'color-g' : (Inputs.grade_29 < 39 && Inputs.grade_29 > 19 ? 'color-r' : (Inputs.grade_29 < 19 ? 'color-dr' : 'bg-color6')))}>Growth Rate</td>
                        <td className={'color-g'}>Above Average</td>                                  
                        </tr>
                        <tr>
                        <td className={(Inputs.grade_2 > 59 ? 'color-g' : (Inputs.grade_2 < 39 && Inputs.grade_2 > 19 ? 'color-r' : (Inputs.grade_2 < 19 ? 'color-dr' : 'bg-color6')))}>Value Creation</td>
                        <td className={(Inputs.grade_8 > 59 ? 'color-g' : (Inputs.grade_8 < 39 && Inputs.grade_8 > 19 ? 'color-r' : (Inputs.grade_8 < 19 ? 'color-dr' : 'bg-color6')))}>Back-end Development</td>
                        <td className={(Inputs.grade_10 > 59 ? 'color-g' : (Inputs.grade_10 < 39 && Inputs.grade_10 > 19 ? 'color-r' : (Inputs.grade_10 < 19 ? 'color-dr' : 'bg-color6')))}>Conversion</td>
                        <td className={(Inputs.grade_13 > 59 ? 'color-g' : (Inputs.grade_13 < 39 && Inputs.grade_13 > 19 ? 'color-r' : (Inputs.grade_13 < 19 ? 'color-dr' : 'bg-color6')))}>Buying Lifetime</td>
                        <td className={(Inputs.grade_17 > 59 ? 'color-g' : (Inputs.grade_17 < 39 && Inputs.grade_17 > 19 ? 'color-r' : (Inputs.grade_17 < 19 ? 'color-dr' : 'bg-color6')))}>Profit Margin</td>
                        <td className={(Inputs.grade_19 > 59 ? 'color-g' : (Inputs.grade_19 < 39 && Inputs.grade_19 > 19 ? 'color-r' : (Inputs.grade_19 < 19 ? 'color-dr' : 'bg-color6')))}>Growth Leadership</td>
                        <td className={(Inputs.grade_23 > 59 ? 'color-g' : (Inputs.grade_23 < 39 && Inputs.grade_23 > 19 ? 'color-r' : (Inputs.grade_23 < 19 ? 'color-dr' : 'bg-color6')))}>Countinuous Learning</td>
                        <td className={(Inputs.grade_27 > 59 ? 'color-g' : (Inputs.grade_27 < 39 && Inputs.grade_27 > 19 ? 'color-r' : (Inputs.grade_27 < 19 ? 'color-dr' : 'bg-color6')))}>Intellectual Property</td>
                        <td className={(Inputs.grade_36 > 59 ? 'color-g' : (Inputs.grade_36 < 39 && Inputs.grade_36 > 19 ? 'color-r' : (Inputs.grade_36 < 19 ? 'color-dr' : 'bg-color6')))}>Marketing Processes</td>
                        <td className={(Inputs.grade_33 > 59 ? 'color-g' : (Inputs.grade_33 < 39 && Inputs.grade_33 > 19 ? 'color-r' : (Inputs.grade_33 < 19 ? 'color-dr' : 'bg-color6')))}>Customer Retention</td>
                        <td className={(Inputs.grade_30 > 59 ? 'color-g' : (Inputs.grade_30 < 39 && Inputs.grade_30 > 19 ? 'color-r' : (Inputs.grade_30 < 19 ? 'color-dr' : 'bg-color6')))}>Gross Margins</td>                                  
                        <td className={'bg-color6'}>Average</td>                                  
                        </tr>
                        <tr>
                        <td className={(Inputs.grade_3 > 59 ? 'color-g' : (Inputs.grade_3 < 39 && Inputs.grade_3 > 19 ? 'color-r' : (Inputs.grade_3 < 19 ? 'color-dr' : 'bg-color6')))}>Products And Services</td>
                        <td className={(Inputs.grade_6 > 59 ? 'color-g' : (Inputs.grade_6 < 39 && Inputs.grade_6 > 19 ? 'color-r' : (Inputs.grade_6 < 19 ? 'color-dr' : 'bg-color6')))}>Financial Compounding </td>
                        <td className={(Inputs.grade_11 > 59 ? 'color-g' : (Inputs.grade_11 < 39 && Inputs.grade_11 > 19 ? 'color-r' : (Inputs.grade_11 < 19 ? 'color-dr' : 'bg-color6')))}>Refferals</td>
                        <td className={(Inputs.grade_14 > 59 ? 'color-g' : (Inputs.grade_14 < 39 && Inputs.grade_14 > 19 ? 'color-r' : (Inputs.grade_14 < 19 ? 'color-dr' : 'bg-color6')))}>Reduce Attrition</td>
                        <td />
                        <td className={(Inputs.grade_20 > 59 ? 'color-g' : (Inputs.grade_20 < 39 && Inputs.grade_20 > 19 ? 'color-r' : (Inputs.grade_20 < 19 ? 'color-dr' : 'bg-color6')))}>Growth Playbook</td>
                        <td className={(Inputs.grade_24 > 59 ? 'color-g' : (Inputs.grade_24 < 39 && Inputs.grade_24 > 19 ? 'color-r' : (Inputs.grade_24 < 19 ? 'color-dr' : 'bg-color6')))}>Countinuous Adaptation</td>
                        <td className={(Inputs.grade_28 > 59 ? 'color-g' : (Inputs.grade_28 < 39 && Inputs.grade_28 > 19 ? 'color-r' : (Inputs.grade_28 < 19 ? 'color-dr' : 'bg-color6')))}>New Business Optionality</td>
                        <td className={(Inputs.grade_37 > 59 ? 'color-g' : (Inputs.grade_37 < 39 && Inputs.grade_37 > 19 ? 'color-r' : (Inputs.grade_37 < 19 ? 'color-dr' : 'bg-color6')))}>System and Playbook</td>
                        <td className={(Inputs.grade_34 > 59 ? 'color-g' : (Inputs.grade_34 < 39 && Inputs.grade_34 > 19 ? 'color-r' : (Inputs.grade_34 < 19 ? 'color-dr' : 'bg-color6')))}>Customer Responsiveness</td>
                        <td className={(Inputs.grade_31 > 59 ? 'color-g' : (Inputs.grade_31 < 39 && Inputs.grade_31 > 19 ? 'color-r' : (Inputs.grade_31 < 19 ? 'color-dr' : 'bg-color6')))}>Scalability</td>                                
                        <td className={'color-r'}>Below Average</td>                                  
                        </tr>
                        <tr>
                        <td className={(Inputs.grade_4 > 59 ? 'color-g' : (Inputs.grade_4 < 39 && Inputs.grade_4 > 19 ? 'color-r' : (Inputs.grade_4 < 19 ? 'color-dr' : 'bg-color6')))}>Positioning &amp; Messaging</td>
                        <td className={(Inputs.grade_5 > 59 ? 'color-g' : (Inputs.grade_5 < 39 && Inputs.grade_5 > 19 ? 'color-r' : (Inputs.grade_5 < 19 ? 'color-dr' : 'bg-color6')))}>Strategic Alignment</td>
                        <td />
                        <td className={(Inputs.grade_15 > 59 ? 'color-g' : (Inputs.grade_15 < 39 && Inputs.grade_15 > 19 ? 'color-r' : (Inputs.grade_15 < 19 ? 'color-dr' : 'bg-color6')))}>Reacivation</td>
                        <td />
                        <td className={(Inputs.grade_21 > 59 ? 'color-g' : (Inputs.grade_21 < 39 && Inputs.grade_21 > 19 ? 'color-r' : (Inputs.grade_21 < 19 ? 'color-dr' : 'bg-color6')))}>Growth Culture</td>
                        <td className={(Inputs.grade_25 > 59 ? 'color-g' : (Inputs.grade_25 < 39 && Inputs.grade_25 > 19 ? 'color-r' : (Inputs.grade_25 < 19 ? 'color-dr' : 'bg-color6')))}>Countinuous Improvement</td>
                        <td />
                        <td />
                        <td />
                        <td />                                
                        <td className={'color-dr'}>Poor Performance</td>                                  
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            <div className="linkbtn">
                <Link to="/state-of-the-business/growth-capabilities-gap-analysis"><i className="fa fa-arrow-left" /> Proceed to Benchmark Score Gap Analysis</Link>
            </div>
        </div>
    </div>
  )
}
