import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state/index';
import { LabelingBarsGraph } from "../graph/LabelingBarsGraph";
import  UserDropdown  from '../UserDropdown';
import { addEditImprovementPercentage } from "../../state/features/ClentInputSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { useAlert } from 'react-alert'
import { Spinner } from '../spinner'
import { Link } from 'react-router-dom';
import { StatesUseEffect } from '../../utils/StatesUseEffect';
import { BarChartGraph } from "../graph/BarChartGraph";
import { BarChartGraphNew } from '../graph/BarChartGraphNew';

export default function CustomerReactivationContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const users = useSelector(userData);
    const userID = users.data.profile.id;
    const { message } = useSelector((state) => state.message);

    const clientInputs = useSelector(state => state.clientInputs)
    const improvementPercentage = clientInputs.improvementPercentage
    const dispatch = useDispatch();
    const { customerReactivationData } = bindActionCreators(actionCreators, dispatch)
    const customerReactivation = useSelector(state => state.customerReactivation)

    const [Inputs, setInputs] = useState({
        customerReactivationD13: Number(customerReactivation.customerReactivationD13).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1}),
    });
    const { clientInputsH20 } = clientInputs
    let customerReactivationD12 = (parseFloat(clientInputsH20)) ? parseFloat(clientInputsH20) : 0

    useEffect(() => {
        customerReactivationData("customerReactivationD12", customerReactivationD12);
        customerReactivationData("customerReactivationD13", improvementPercentage.customerReactivationD13);
    }, [])

    const { customerReactivationD17, customerReactivationE17, customerReactivationF17, customerReactivationG17, customerReactivationH17, customerReactivationI17, customerReactivationD18, customerReactivationE18, customerReactivationF18, customerReactivationG18, customerReactivationH18, customerReactivationI18, customerReactivationD22, customerReactivationE22, customerReactivationF22, customerReactivationG22, customerReactivationH22, customerReactivationI22, customerReactivationD23, customerReactivationE23, customerReactivationF23, customerReactivationG23, customerReactivationH23, customerReactivationI23, customerReactivationD25, customerReactivationE25, customerReactivationF25, customerReactivationG25, customerReactivationH25, customerReactivationI25, customerReactivationD26, customerReactivationE26, customerReactivationF26, customerReactivationG26, customerReactivationH26, customerReactivationI26, customerReactivationD28, customerReactivationE28, customerReactivationF28, customerReactivationG28, customerReactivationH28, customerReactivationI28, customerReactivationD31, customerReactivationD32, customerReactivationD33, customerReactivationD34, customerReactivationD35 } = customerReactivation //object destructuring for customerReactivation

    const improvementPrcntHandler = (impPrcnt) => {
        setIsLoading(true);
        let sendData = {user_id : userID, customerReactivationD13: impPrcnt}
        dispatch(addEditImprovementPercentage(sendData))
        .unwrap()
        .then((response) => {
          console.log("response",response)
            if(response!= undefined){
               console.log('success')
            }
            setIsLoading(false)   // Hide loading screen 
        })
        .catch((error) => {
          console.log("error",error)
            { message && alert.show(message) }
            setIsLoading(false)   // Hide loading screen 
        });
    }

     // call states useEffect utils
     StatesUseEffect()

    return (
        <div className="content-body content-body-new bg-white-color">
        <UserDropdown />
    
            {/* row */}
            <div className="container-fluid lead-generation-new">
                <div className="head-title mb-5">
                    <h3 className='preheading'>Prescribe - Growth Drivers</h3>
                    <h1 className='mainheading mb-1'>Customer Reactivation</h1>	
                </div>
                <Spinner/>
                <div className="row lead-genearation-rw mb-5" style={{maxWidth: 1260}}>
                    <div className="col-12 col-lg-6">
                        {/* <div className="potential-growth-ld mb-3" style={{maxWidth: 510}}>
                            <h3><strong>Potential Growth Activities or Tactics:</strong></h3>
                            <ol>
                                <li>Activity 1</li>
                                <li>Activity 2</li>
                                <li>Activity 3</li>
                            </ol>
                        </div> */}
                        <div className="lead-generation-table mt-218" style={{overflowY: 'auto'}}>
                            <div className="responsive-table">
                                <table className='table' style={{width: 510}}>
                                    <thead>
                                        <tr>
                                            <th scope='col' className='black-bg text-white fontBold' style={{width: 360}}>Customer Reactivation</th>
                                            <th className='black-bg hide-content' style={{width: 150}}>2</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td scope='row' className='bg-color-light-blue font12px color-black'>Total Inactive Customers</td>
                                            <td className='bg-color-light-blue font12px color-black text-right-rt'>{(parseFloat(clientInputsH20)) ? Number(clientInputsH20).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1}) : 0}</td>
                                        </tr>
                                        <tr>
                                            <td scope='row' className='bg-color-light-blue font12px color-black'>Projected Improvement</td>
                                            <th scope='row' className='padding-0'>
                                                <div className="dollor-sin-pr">
                                                    <div className="input-fiel-cn bg-color-01 client-inpt-field">
                                                        <input type="text" 
                                                        name="Inputs.customerReactivationD13"
                                                        className="field-ipt wthinherit font12px bg-color-01 text-right text-black"
                                                        placeholder={0}
                                                        value={Inputs.customerReactivationD13}
                                                        onChange={(event) => {
                                                            setInputs({ ...Inputs, customerReactivationD13: event.target.value });
                                                            customerReactivationData("customerReactivationD13", event.target.value);
                                                            improvementPrcntHandler(event.target.value)
                                                        }}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        disabled={isLoading}
                                                    />
                                                    </div>
                                                    <div className="perecentage">%</div>
                                                </div>
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                                <Spinner />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6" style={{minHeight: 400}}>
                        <BarChartGraphNew dataInput={{
                            "Title"      : "Customer Reactivation:  Impact on Financial Performance",
                            "ColorCodes" : ["#d6dce5", "#8497b0", "#1e2b3c"],
                            "GraphData"  : {
                                labels: [["Your","Projection"], "Year 1", "Year 2", "Year 3", "Year 4", "Year 5"],
                                datasets: [
                                    {
                                        label: "Profit",
                                        backgroundColor: "#1e65a7", //"#d6dce5",
                                        data: [Number(customerReactivationD23),Number(customerReactivationE23),Number(customerReactivationF23),Number(customerReactivationG23),Number(customerReactivationH23),Number(customerReactivationI23)]
                                    },
                                    {
                                        label: "Sales",
                                        backgroundColor: "#8497b0",
                                        data: [Number(customerReactivationD22),Number(customerReactivationE22),Number(customerReactivationF22),Number(customerReactivationG22),Number(customerReactivationH22),Number(customerReactivationI22)]
                                    },
                                    {
                                        label: "Valuation",
                                        backgroundColor: "#1e2b3c",
                                        data: [Number(customerReactivationD28),Number(customerReactivationE28),Number(customerReactivationF28),Number(customerReactivationG28),Number(customerReactivationH28),Number(customerReactivationI28)]
                                    }
                                ]
                            }
                        }} />
                        {/* <div className="">
                            <h3 className='text-center impact-lead-titile'><strong>Customer Reactivation:  Impact on Financial Performance</strong></h3>
                            
                            <BarChartGraph dataInput={{
                                "Title"      : "",
                                "vTitle"     : "",
                                "hTitle"     : "",
                                "subTitle"   : "",
                                "ColorCodes" : ["#8497b0", "#c2c7cd", "#44546a"],
                                "GraphData"  : [
                                    ["", "Profit", "Sales", "Valuation"],
                                    [["Your","Projection"], Number(customerReactivationD23),Number(customerReactivationD22), Number(customerReactivationD28)],
                                    ["Year 1", Number(customerReactivationE23),Number(customerReactivationE22), Number(customerReactivationE28)],
                                    ["Year 2", Number(customerReactivationF23),Number(customerReactivationF22), Number(customerReactivationF28)],
                                    ["Year 3", Number(customerReactivationG23),Number(customerReactivationG22), Number(customerReactivationG28)],
                                    ["Year 4", Number(customerReactivationH23),Number(customerReactivationH22), Number(customerReactivationH28)],
                                    ["Year 5", Number(customerReactivationI23),Number(customerReactivationI22), Number(customerReactivationI28)]
                                ]
                            }} />
                        </div> */}
                    </div>
                </div>
                <div className="card update-class-lead">
                    <div className="card-body pl-0">
                        <div className="table-responsive">
                            <table className="table table-responsive-md table-striped mb-0" style={{width: 1260}}>
                                <thead>
                                    <tr>
                                    <th className="table-primar" style={{width: 360}}/>
                                    <th colSpan={2} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Year 1 Gains</th>
                                    <th colSpan={2} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Mid-Term Gains</th>
                                    <th colSpan={5} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Long-Term Gains </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="thead-primary">
                                        <th className='black-bg fontBold' style={{width: 360}}>Customers Reactivated Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>Your Projection</th>
                                        <th className='black-bg' style={{width: 150}}>1% Quarterly Improvement</th>
                                        <th className='black-bg' style={{width: 150}}>2 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>3 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>4 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>5 Years of 1% Improvements</th>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>Number of inactive Customers</td>
                                        <td className='bg-color-blue-gray text-white font12px'> {Number(customerReactivationD17).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})} </td>
                                        <td className='bg-color-light-blue font12px color-black'> {Number(customerReactivationE17).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})} </td>
                                        <td className='bg-color-light-blue font12px color-black'> {Number(customerReactivationF17).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})} </td>
                                        <td className='bg-color-light-blue font12px color-black'> {Number(customerReactivationG17).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})} </td>
                                        <td className='bg-color-light-blue font12px color-black'> {Number(customerReactivationH17).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})} </td>
                                        <td className='bg-color-light-blue font12px color-black'> {Number(customerReactivationI17).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})} </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'><strong>Customers Reactivated Annually</strong></td>
                                        <td className='bg-color-blue-gray text-white font12px'> <strong>{Number(customerReactivationD18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong> </td>
                                        <td className='bg-color-teal text-white font12px'><strong> {Number(customerReactivationE18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong> </td>
                                        <td className='bg-color-teal text-white font12px'><strong> {Number(customerReactivationF18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong> </td>
                                        <td className='bg-color-teal text-white font12px'><strong> {Number(customerReactivationG18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong> </td>
                                        <td className='bg-color-teal text-white font12px'><strong> {Number(customerReactivationH18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong> </td>
                                        <td className='bg-color-teal text-white font12px'><strong> {Number(customerReactivationI18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}</strong> </td>
                                    </tr>
                                    </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="card update-class-lead">
                            <div className="card-body pl-0">
                            <div className="table-responsive">
                                <table className="table table-responsive-md table-striped mb-0" style={{width: 1260}}>
                                <thead>
                                    <tr>
                                    <th className='nowrap' style={{background:'#fff', width: 360}}><h3>Financial Improvements</h3></th>
                                    <th colSpan={2} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Year 1 Gains</th>
                                    <th colSpan={2} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Mid-Term Gains</th>
                                    <th colSpan={5} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Long-Term Gains </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="thead-primary">
                                        <th className='black-bg fontBold' style={{width: 360}}>Annual Impact of Increasing Customer Reactivation</th>
                                        <th className='black-bg' style={{width: 150}}>Your Projection</th>
                                        <th className='black-bg' style={{width: 150}}>1% Quarterly Improvement</th>
                                        <th className='black-bg' style={{width: 150}}>2 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>3 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>4 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>5 Years of 1% Improvements</th>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>Annual Sales</td>
                                        <td className='bg-color-blue-gray text-white font12px'>     
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px text-white"> $ </div>
                                                <div className="annual-dl-number font12px text-white">
                                                    {Number(customerReactivationD22).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div> 
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(customerReactivationE22).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(customerReactivationF22).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(customerReactivationG22).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(customerReactivationH22).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(customerReactivationI22).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>Annual Profit</td>
                                        <td className='bg-color-blue-gray text-white font12px'>     
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px text-white"> $ </div>
                                                <div className="annual-dl-number font12px text-white">
                                                    {Number(customerReactivationD23).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div> 
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(customerReactivationE23).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(customerReactivationF23).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(customerReactivationG23).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(customerReactivationH23).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(customerReactivationI23).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="thead-primary">
                                        <th className='black-bg fontBold'>Lifetime Impact of Increasing Customer Reactivation</th>
                                        <th className='black-bg'>Your Projection</th>
                                        <th className='black-bg'>1% Quarterly Improvement</th>
                                        <th className='black-bg'>2 Years of 1% Improvements</th>
                                        <th className='black-bg'>3 Years of 1% Improvements</th>
                                        <th className='black-bg'>4 Years of 1% Improvements</th>
                                        <th className='black-bg'>5 Years of 1% Improvements</th>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>Lifetime Sales</td>
                                        <td className='bg-color-blue-gray text-white font12px'>     
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px text-white"> $ </div>
                                                <div className="annual-dl-number font12px text-white">
                                                    {Number(customerReactivationD25).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div> 
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(customerReactivationE25).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(customerReactivationF25).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(customerReactivationG25).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(customerReactivationH25).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(customerReactivationI25).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>Lifetime Profit</td>
                                        <td className='bg-color-blue-gray text-white font12px'>     
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px text-white"> $ </div>
                                                <div className="annual-dl-number font12px text-white">
                                                    {Number(customerReactivationD26).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div> 
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(customerReactivationE26).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(customerReactivationF26).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(customerReactivationG26).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(customerReactivationH26).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(customerReactivationI26).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="thead-primary">
                                        <th className='black-bg fontBold'>Valuation Impact of Increasing Customer Reactivation</th>
                                        <th className='black-bg'>Your Projection</th>
                                        <th className='black-bg'>1% Quarterly Improvement</th>
                                        <th className='black-bg'>2 Years of 1% Improvements</th>
                                        <th className='black-bg'>3 Years of 1% Improvements</th>
                                        <th className='black-bg'>4 Years of 1% Improvements</th>
                                        <th className='black-bg'>5 Years of 1% Improvements</th>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>Estimated Valuation Impact</td>
                                        <td className='bg-color-blue-gray text-white font12px'>     
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px text-white"> $ </div>
                                                <div className="annual-dl-number font12px text-white">
                                                    {Number(customerReactivationD28).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div> 
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(customerReactivationE28).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(customerReactivationF28).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(customerReactivationG28).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(customerReactivationH28).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(customerReactivationI28).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="card update-class-lead">
                            <div className="card-body pl-0">
                            <div className="table-responsive">
                                <table className="table table-responsive-md table-striped mb-0" style={{width: 1260}}>
                                    <tbody>
                                    <tr className="thead-primary">
                                        <th className='black-bg fontBold' style={{width: 360}}>5-Year Total Impact from Customer Reactivation</th>
                                        <th className='black-bg' style={{width: 150}}>Impact</th>
                                        <th className='black-bg hide-content' colSpan={5} style={{width: 750}}></th>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>Valuation Impact</td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(customerReactivationD31).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <th className='black-bg hide-content' colSpan={5} style={{width: 750}}></th>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>Lifetime Profits</td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(customerReactivationD32).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <th className='black-bg hide-content' colSpan={5} style={{width: 750}}></th>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>Lifetime Sales</td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(customerReactivationD33).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <th className='black-bg hide-content' colSpan={5} style={{width: 750}}></th>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>Annual Profits</td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(customerReactivationD34).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <th className='black-bg hide-content' colSpan={5} style={{width: 750}}></th>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>Annual Sales</td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(customerReactivationD35).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <th className='black-bg hide-content' colSpan={5} style={{width: 750}}></th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="nextInput">
                            <ul>
                                <li>
                                    <Link to="/prescribe/average-transaction-value" className='next-link'> Next: Average Transaction Value  
                                        <span className='icon-three'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                        </span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
}
