import React from "react";
import { Chart } from "react-google-charts";

export function ComboChartGraph({dataInput}) {

  const data = dataInput.GraphData;
  const Title = dataInput.Title ? dataInput.Title : "";
  const vTitle = dataInput.vTitle ? dataInput.vTitle : "";
  const hTitle = dataInput.hTitle ? dataInput.hTitle : "";
  const subTitle = dataInput.subTitle ? dataInput.subTitle : "";
  const ColorCodes = dataInput.ColorCodes ? dataInput.ColorCodes : ["#c2c7cd", "#8497b0", "#44546a"];
  const backgroundColor = dataInput.backgroundColor ? dataInput.backgroundColor : '#f1f7f9'

  const options = {
    // chartArea: { 
    //   // width: '70%', height: '80%',
    //   right: 20,
    //   left: 100
    // },
    title: Title,
    titlePosition: 'top',
    titleTextStyle: {
      color: 'white',
      bold: true,
      fontSize: 20
    },
    vAxis: { title: vTitle, textStyle:{color: '#bdbdc1'}, format: 'currency' },
    hAxis: { title: hTitle, textStyle:{color: '#bdbdc1'} },
    seriesType: "bars",
    colors: ColorCodes,
    backgroundColor: backgroundColor,
    series: {
      1: { type: "line" },
    },
    annotations: {
      boxStyle: {
        padding: 4,
        stroke: '#0077f6',
        strokeWidth: 1,
        gradient: {
          // Start color for gradient.
          color1: '#0077f6',
          // Finish color for gradient.
          color2: '#0077f6',
          x1: '0%', y1: '0%',
          x2: '100%', y2: '100%',
          useObjectBoundingBoxUnits: false
        }
      },
      alwaysOutside: false,
      textStyle: {
        fontSize: 20,
        bold: true,
        italic: false,
        color: 'white',
      },
    },
    tooltip: { trigger: 'none' },
    legendTextStyle: { color: '#bdbdc1' },
    // titleTextStyle: { color: '#FFF', fontSize: '18' },
    legend: { position: 'top', alignment: 'center' },
  };

  return (
    <Chart
      chartType="ComboChart"
      width="100%"
      height="400px"
      data={data}
      options={options}
    />
  );
}
