import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getInnovativeOfferingAnalysis, modifyInnovativeOfferingAnalysis } from "../../state/features/InnovativeOfferingAnalysisSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import sum from "../../utils/sum";

export default function InnovativeOfferingAnalysisContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        InnovativeOfferingAnalysisE13 : '',
        InnovativeOfferingAnalysisF13 : 0,
        InnovativeOfferingAnalysisH13 : 0,
        InnovativeOfferingAnalysisJ13 : 0,
        InnovativeOfferingAnalysisL13 : 0,
        InnovativeOfferingAnalysisN13 : 0,
        InnovativeOfferingAnalysisE14 : '',
        InnovativeOfferingAnalysisF14 : 0,
        InnovativeOfferingAnalysisH14 : 0,
        InnovativeOfferingAnalysisJ14 : 0,
        InnovativeOfferingAnalysisL14 : 0,
        InnovativeOfferingAnalysisN14 : 0,
        InnovativeOfferingAnalysisE15 : '',
        InnovativeOfferingAnalysisF15 : 0,
        InnovativeOfferingAnalysisH15 : 0,
        InnovativeOfferingAnalysisJ15 : 0,
        InnovativeOfferingAnalysisL15 : 0,
        InnovativeOfferingAnalysisN15 : 0,
        InnovativeOfferingAnalysisE16 : '',
        InnovativeOfferingAnalysisF16 : 0,
        InnovativeOfferingAnalysisH16 : 0,
        InnovativeOfferingAnalysisJ16 : 0,
        InnovativeOfferingAnalysisL16 : 0,
        InnovativeOfferingAnalysisN16 : 0,
        InnovativeOfferingAnalysisE17 : '',
        InnovativeOfferingAnalysisF17 : 0,
        InnovativeOfferingAnalysisH17 : 0,
        InnovativeOfferingAnalysisJ17 : 0,
        InnovativeOfferingAnalysisL17 : 0,
        InnovativeOfferingAnalysisN17 : 0,
        InnovativeOfferingAnalysisE18 : '',
        InnovativeOfferingAnalysisF18 : 0,
        InnovativeOfferingAnalysisH18 : 0,
        InnovativeOfferingAnalysisJ18 : 0,
        InnovativeOfferingAnalysisL18 : 0,
        InnovativeOfferingAnalysisN18 : 0,
        InnovativeOfferingAnalysisE19 : '',
        InnovativeOfferingAnalysisF19 : 0,
        InnovativeOfferingAnalysisH19 : 0,
        InnovativeOfferingAnalysisJ19 : 0,
        InnovativeOfferingAnalysisL19 : 0,
        InnovativeOfferingAnalysisN19 : 0,
        InnovativeOfferingAnalysisE20 : '',
        InnovativeOfferingAnalysisF20 : 0,
        InnovativeOfferingAnalysisH20 : 0,
        InnovativeOfferingAnalysisJ20 : 0,
        InnovativeOfferingAnalysisL20 : 0,
        InnovativeOfferingAnalysisN20 : 0,
        InnovativeOfferingAnalysisE21 : '',
        InnovativeOfferingAnalysisF21 : 0,
        InnovativeOfferingAnalysisH21 : 0,
        InnovativeOfferingAnalysisJ21 : 0,
        InnovativeOfferingAnalysisL21 : 0,
        InnovativeOfferingAnalysisN21 : 0,
        InnovativeOfferingAnalysisE22 : '',
        InnovativeOfferingAnalysisF22 : 0,
        InnovativeOfferingAnalysisH22 : 0,
        InnovativeOfferingAnalysisJ22 : 0,
        InnovativeOfferingAnalysisL22 : 0,
        InnovativeOfferingAnalysisN22 : 0,
        InnovativeOfferingAnalysisF24 : 0,
        InnovativeOfferingAnalysisH24 : 0,
        InnovativeOfferingAnalysisJ24 : 0,
        InnovativeOfferingAnalysisL24 : 0,
        InnovativeOfferingAnalysisN24 : 0,
        InnovativeOfferingAnalysisP24 : 0,
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getInnovativeOfferingAnalysis({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.growthPlanning)
            if(response!= undefined  && response.data.growthPlanning){
                let growthPlanning = response.data.growthPlanning[0];
                console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key]
                    }
                }
                newObj['InnovativeOfferingAnalysisF24'] = sum(newObj['InnovativeOfferingAnalysisF13'], newObj['InnovativeOfferingAnalysisF14'], newObj['InnovativeOfferingAnalysisF15'], newObj['InnovativeOfferingAnalysisF16'], newObj['InnovativeOfferingAnalysisF17'], newObj['InnovativeOfferingAnalysisF18'], newObj['InnovativeOfferingAnalysisF19'], newObj['InnovativeOfferingAnalysisF20'], newObj['InnovativeOfferingAnalysisF21'], newObj['InnovativeOfferingAnalysisF22']);
                newObj['InnovativeOfferingAnalysisH24'] = sum(newObj['InnovativeOfferingAnalysisH13'], newObj['InnovativeOfferingAnalysisH14'], newObj['InnovativeOfferingAnalysisH15'], newObj['InnovativeOfferingAnalysisH16'], newObj['InnovativeOfferingAnalysisH17'], newObj['InnovativeOfferingAnalysisH18'], newObj['InnovativeOfferingAnalysisH19'], newObj['InnovativeOfferingAnalysisH20'], newObj['InnovativeOfferingAnalysisH21'], newObj['InnovativeOfferingAnalysisH22']);
                newObj['InnovativeOfferingAnalysisJ24'] = sum(newObj['InnovativeOfferingAnalysisJ13'], newObj['InnovativeOfferingAnalysisJ14'], newObj['InnovativeOfferingAnalysisJ15'], newObj['InnovativeOfferingAnalysisJ16'], newObj['InnovativeOfferingAnalysisJ17'], newObj['InnovativeOfferingAnalysisJ18'], newObj['InnovativeOfferingAnalysisJ19'], newObj['InnovativeOfferingAnalysisJ20'], newObj['InnovativeOfferingAnalysisJ21'], newObj['InnovativeOfferingAnalysisJ22']);
                newObj['InnovativeOfferingAnalysisL24'] = sum(newObj['InnovativeOfferingAnalysisL13'], newObj['InnovativeOfferingAnalysisL14'], newObj['InnovativeOfferingAnalysisL15'], newObj['InnovativeOfferingAnalysisL16'], newObj['InnovativeOfferingAnalysisL17'], newObj['InnovativeOfferingAnalysisL18'], newObj['InnovativeOfferingAnalysisL19'], newObj['InnovativeOfferingAnalysisL20'], newObj['InnovativeOfferingAnalysisL21'], newObj['InnovativeOfferingAnalysisL22']);
                newObj['InnovativeOfferingAnalysisN24'] = sum(newObj['InnovativeOfferingAnalysisN13'], newObj['InnovativeOfferingAnalysisN14'], newObj['InnovativeOfferingAnalysisN15'], newObj['InnovativeOfferingAnalysisN16'], newObj['InnovativeOfferingAnalysisN17'], newObj['InnovativeOfferingAnalysisN18'], newObj['InnovativeOfferingAnalysisN19'], newObj['InnovativeOfferingAnalysisN20'], newObj['InnovativeOfferingAnalysisN21'], newObj['InnovativeOfferingAnalysisN22']);
                newObj['InnovativeOfferingAnalysisP24'] = sum(newObj['InnovativeOfferingAnalysisF24'], newObj['InnovativeOfferingAnalysisH24'], newObj['InnovativeOfferingAnalysisJ24'], newObj['InnovativeOfferingAnalysisL24'], newObj['InnovativeOfferingAnalysisN24']) / 10;
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                event.target.value = event.target.value ? event.target.value : 0
                dispatch(modifyInnovativeOfferingAnalysis({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        //window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

    useEffect(() => {
        let InnovativeOfferingAnalysisF24 = sum(Inputs.InnovativeOfferingAnalysisF13, Inputs.InnovativeOfferingAnalysisF14, Inputs.InnovativeOfferingAnalysisF15, Inputs.InnovativeOfferingAnalysisF16, Inputs.InnovativeOfferingAnalysisF17, Inputs.InnovativeOfferingAnalysisF18, Inputs.InnovativeOfferingAnalysisF19, Inputs.InnovativeOfferingAnalysisF20, Inputs.InnovativeOfferingAnalysisF21, Inputs.InnovativeOfferingAnalysisF22);

        let InnovativeOfferingAnalysisH24 = sum(Inputs.InnovativeOfferingAnalysisH13, Inputs.InnovativeOfferingAnalysisH14, Inputs.InnovativeOfferingAnalysisH15, Inputs.InnovativeOfferingAnalysisH16, Inputs.InnovativeOfferingAnalysisH17, Inputs.InnovativeOfferingAnalysisH18, Inputs.InnovativeOfferingAnalysisH19, Inputs.InnovativeOfferingAnalysisH20, Inputs.InnovativeOfferingAnalysisH21, Inputs.InnovativeOfferingAnalysisH22);
        let InnovativeOfferingAnalysisJ24 = sum(Inputs.InnovativeOfferingAnalysisJ13, Inputs.InnovativeOfferingAnalysisJ14, Inputs.InnovativeOfferingAnalysisJ15, Inputs.InnovativeOfferingAnalysisJ16, Inputs.InnovativeOfferingAnalysisJ17, Inputs.InnovativeOfferingAnalysisJ18, Inputs.InnovativeOfferingAnalysisJ19, Inputs.InnovativeOfferingAnalysisJ20, Inputs.InnovativeOfferingAnalysisJ21, Inputs.InnovativeOfferingAnalysisJ22);
        let InnovativeOfferingAnalysisL24 = sum(Inputs.InnovativeOfferingAnalysisL13, Inputs.InnovativeOfferingAnalysisL14, Inputs.InnovativeOfferingAnalysisL15, Inputs.InnovativeOfferingAnalysisL16, Inputs.InnovativeOfferingAnalysisL17, Inputs.InnovativeOfferingAnalysisL18, Inputs.InnovativeOfferingAnalysisL19, Inputs.InnovativeOfferingAnalysisL20, Inputs.InnovativeOfferingAnalysisL21, Inputs.InnovativeOfferingAnalysisL22);
        let InnovativeOfferingAnalysisN24 = sum(Inputs.InnovativeOfferingAnalysisN13, Inputs.InnovativeOfferingAnalysisN14, Inputs.InnovativeOfferingAnalysisN15, Inputs.InnovativeOfferingAnalysisN16, Inputs.InnovativeOfferingAnalysisN17, Inputs.InnovativeOfferingAnalysisN18, Inputs.InnovativeOfferingAnalysisN19, Inputs.InnovativeOfferingAnalysisN20, Inputs.InnovativeOfferingAnalysisN21, Inputs.InnovativeOfferingAnalysisN22);
        let InnovativeOfferingAnalysisP24 = sum(InnovativeOfferingAnalysisF24, InnovativeOfferingAnalysisH24, InnovativeOfferingAnalysisJ24, InnovativeOfferingAnalysisL24, InnovativeOfferingAnalysisN24) / 10;

        setInputs({ ...Inputs, InnovativeOfferingAnalysisF24, InnovativeOfferingAnalysisH24, InnovativeOfferingAnalysisJ24, InnovativeOfferingAnalysisL24, InnovativeOfferingAnalysisN24, InnovativeOfferingAnalysisP24});

    },[Inputs.InnovativeOfferingAnalysisF13, Inputs.InnovativeOfferingAnalysisF14, Inputs.InnovativeOfferingAnalysisF15, Inputs.InnovativeOfferingAnalysisF16, Inputs.InnovativeOfferingAnalysisF17, Inputs.InnovativeOfferingAnalysisF18, Inputs.InnovativeOfferingAnalysisF19, Inputs.InnovativeOfferingAnalysisF20, Inputs.InnovativeOfferingAnalysisF21, Inputs.InnovativeOfferingAnalysisF22, Inputs.InnovativeOfferingAnalysisH13, Inputs.InnovativeOfferingAnalysisH14, Inputs.InnovativeOfferingAnalysisH15, Inputs.InnovativeOfferingAnalysisH16, Inputs.InnovativeOfferingAnalysisH17, Inputs.InnovativeOfferingAnalysisH18, Inputs.InnovativeOfferingAnalysisH19, Inputs.InnovativeOfferingAnalysisH20, Inputs.InnovativeOfferingAnalysisH21, Inputs.InnovativeOfferingAnalysisH22, Inputs.InnovativeOfferingAnalysisJ13, Inputs.InnovativeOfferingAnalysisJ14, Inputs.InnovativeOfferingAnalysisJ15, Inputs.InnovativeOfferingAnalysisJ16, Inputs.InnovativeOfferingAnalysisJ17, Inputs.InnovativeOfferingAnalysisJ18, Inputs.InnovativeOfferingAnalysisJ19, Inputs.InnovativeOfferingAnalysisJ20, Inputs.InnovativeOfferingAnalysisJ21, Inputs.InnovativeOfferingAnalysisJ22, Inputs.InnovativeOfferingAnalysisL13, Inputs.InnovativeOfferingAnalysisL14, Inputs.InnovativeOfferingAnalysisL15, Inputs.InnovativeOfferingAnalysisL16, Inputs.InnovativeOfferingAnalysisL17, Inputs.InnovativeOfferingAnalysisL18, Inputs.InnovativeOfferingAnalysisL19, Inputs.InnovativeOfferingAnalysisL20, Inputs.InnovativeOfferingAnalysisL21, Inputs.InnovativeOfferingAnalysisL22, Inputs.InnovativeOfferingAnalysisN13, Inputs.InnovativeOfferingAnalysisN14, Inputs.InnovativeOfferingAnalysisN15, Inputs.InnovativeOfferingAnalysisN16, Inputs.InnovativeOfferingAnalysisN17, Inputs.InnovativeOfferingAnalysisN18, Inputs.InnovativeOfferingAnalysisN19, Inputs.InnovativeOfferingAnalysisN20, Inputs.InnovativeOfferingAnalysisN21, Inputs.InnovativeOfferingAnalysisN22])

  return (
    <div className="content-body">
    <UserDropdown />
    
        {/* row */}
        <div className="container-fluid">
            <div className="head-title">
                <p>Competitive Strategy</p>
                <h2>Innovative Offering Analysis</h2>
                <p>Customers see your products, services, and relationships from a competitive position.   Unless you constantly innovate to improve products, services, and releationship, you will slide toward the left of the chart.  Breakthrough is not necessarily the best category, but an organization must decide where it wants to be in the future so resources and investment can be properly allocated today. </p>
                <p className="mb-5">Where are you today, and where would you like to be int he future?  Now compare the two entries.  What will it take you to get there?  (becomes the basis for planning, investment, and resource allocation) </p>
            </div>
            <Spinner />
            <div className="card">
                <div className="card-body pl-0">
                <div className="table-responsive">
                    <table className="table mt-0 mb-0">
                    <thead>
                        <tr>
                        <td colSpan={3} rowSpan={2} className="width-25 font-size-20 bg-color1 text-white text-center-c">Product or Service</td>
                        <td colSpan={5} className="bg-color3 font-size-20 text-white text-center-c">Value Delivered</td>
                        </tr>
                        <tr>
                        <td className="bg-color1 text-white text-center-c">Worthless (0)</td>
                        <td className="bg-color1 text-white text-center-c">Lacking (2)</td>
                        <td className="bg-color1 text-white text-center-c">Competitive  (5)</td>
                        <td className="bg-color1 text-white text-center-c">Distinct  (8)</td>
                        <td className="bg-color1 text-white text-center-c">Breakthrough  (10)</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td className="width-5">1</td>
                        <td colSpan={2}>
                            <input type="text" className="form-control inputText"
                                id="InnovativeOfferingAnalysisE13"
                                value={Inputs.InnovativeOfferingAnalysisE13}
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisE13: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Product or Service" />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisF13" 
                                value={Inputs.InnovativeOfferingAnalysisF13} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisF13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/0/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter 0 only.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisH13" 
                                value={Inputs.InnovativeOfferingAnalysisH13} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisH13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-2]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 2.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisJ13" 
                                value={Inputs.InnovativeOfferingAnalysisJ13} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisJ13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisL13" 
                                value={Inputs.InnovativeOfferingAnalysisL13} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisL13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-8]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 8.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisN13" 
                                value={Inputs.InnovativeOfferingAnalysisN13} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisN13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        </tr> 
                        <tr>
                        <td className="width-5">2</td>
                        <td colSpan={2}>
                            <input type="text" className="form-control inputText"
                                id="InnovativeOfferingAnalysisE14"
                                value={Inputs.InnovativeOfferingAnalysisE14}
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisE14: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Product or Service" />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisF14" 
                                value={Inputs.InnovativeOfferingAnalysisF14} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisF14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter 0 only.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisH14" 
                                value={Inputs.InnovativeOfferingAnalysisH14} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisH14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-2]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 2.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisJ14" 
                                value={Inputs.InnovativeOfferingAnalysisJ14} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisJ14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisL14" 
                                value={Inputs.InnovativeOfferingAnalysisL14} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisL14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-8]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 8.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisN14" 
                                value={Inputs.InnovativeOfferingAnalysisN14} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisN14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        </tr> 
                        <tr>
                        <td className="width-5">3</td>
                        <td colSpan={2}>
                            <input type="text" className="form-control inputText"
                                id="InnovativeOfferingAnalysisE15"
                                value={Inputs.InnovativeOfferingAnalysisE15}
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisE15: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Product or Service" />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisF15" 
                                value={Inputs.InnovativeOfferingAnalysisF15} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisF15: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter 0 only.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisH15" 
                                value={Inputs.InnovativeOfferingAnalysisH15} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisH15: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-2]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 2.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisJ15" 
                                value={Inputs.InnovativeOfferingAnalysisJ15} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisJ15: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisL15" 
                                value={Inputs.InnovativeOfferingAnalysisL15} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisL15: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-8]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 8.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisN15" 
                                value={Inputs.InnovativeOfferingAnalysisN15} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisN15: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        </tr>   
                        <tr>
                        <td className="width-5">4</td>
                        <td colSpan={2}>
                            <input type="text" className="form-control inputText"
                                id="InnovativeOfferingAnalysisE16"
                                value={Inputs.InnovativeOfferingAnalysisE16}
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisE16: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Product or Service" />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisF16" 
                                value={Inputs.InnovativeOfferingAnalysisF16} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisF16: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter 0 only.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisH16" 
                                value={Inputs.InnovativeOfferingAnalysisH16} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisH16: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-2]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 2.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisJ16" 
                                value={Inputs.InnovativeOfferingAnalysisJ16} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisJ16: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisL16" 
                                value={Inputs.InnovativeOfferingAnalysisL16} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisL16: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-8]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 8.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisN16" 
                                value={Inputs.InnovativeOfferingAnalysisN16} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisN16: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        </tr> 
                        <tr>
                        <td className="width-5">5</td>
                        <td colSpan={2}>
                            <input type="text" className="form-control inputText"
                                id="InnovativeOfferingAnalysisE17"
                                value={Inputs.InnovativeOfferingAnalysisE17}
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisE17: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Product or Service" />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisF17" 
                                value={Inputs.InnovativeOfferingAnalysisF17} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisF17: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter 0 only.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisH17" 
                                value={Inputs.InnovativeOfferingAnalysisH17} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisH17: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-2]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 2.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisJ17" 
                                value={Inputs.InnovativeOfferingAnalysisJ17} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisJ17: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisL17" 
                                value={Inputs.InnovativeOfferingAnalysisL17} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisL17: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-8]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 8.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisN17" 
                                value={Inputs.InnovativeOfferingAnalysisN17} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisN17: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        </tr> 
                        <tr>
                        <td className="width-5">6</td>
                        <td colSpan={2}>
                            <input type="text" className="form-control inputText"
                                id="InnovativeOfferingAnalysisE18"
                                value={Inputs.InnovativeOfferingAnalysisE18}
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisE18: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Product or Service" />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisF18" 
                                value={Inputs.InnovativeOfferingAnalysisF18} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisF18: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter 0 only.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisH18" 
                                value={Inputs.InnovativeOfferingAnalysisH18} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisH18: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-2]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 2.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisJ18" 
                                value={Inputs.InnovativeOfferingAnalysisJ18} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisJ18: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisL18" 
                                value={Inputs.InnovativeOfferingAnalysisL18} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisL18: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-8]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 8.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisN18" 
                                value={Inputs.InnovativeOfferingAnalysisN18} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisN18: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        </tr> 
                        <tr>
                        <td className="width-5">7</td>
                        <td colSpan={2}>
                            <input type="text" className="form-control inputText"
                                id="InnovativeOfferingAnalysisE19"
                                value={Inputs.InnovativeOfferingAnalysisE19}
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisE19: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Product or Service" />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisF19" 
                                value={Inputs.InnovativeOfferingAnalysisF19} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisF19: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter 0 only.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisH19" 
                                value={Inputs.InnovativeOfferingAnalysisH19} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisH19: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-2]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 2.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisJ19" 
                                value={Inputs.InnovativeOfferingAnalysisJ19} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisJ19: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisL19" 
                                value={Inputs.InnovativeOfferingAnalysisL19} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisL19: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-8]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 8.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisN19" 
                                value={Inputs.InnovativeOfferingAnalysisN19} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisN19: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        </tr> 
                        <tr>
                        <td className="width-5">8</td>
                        <td colSpan={2}>
                            <input type="text" className="form-control inputText"
                                id="InnovativeOfferingAnalysisE20"
                                value={Inputs.InnovativeOfferingAnalysisE20}
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisE20: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Product or Service" />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisF20" 
                                value={Inputs.InnovativeOfferingAnalysisF20} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisF20: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter 0 only.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisH20" 
                                value={Inputs.InnovativeOfferingAnalysisH20} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisH20: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-2]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 2.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisJ20" 
                                value={Inputs.InnovativeOfferingAnalysisJ20} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisJ20: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisL20" 
                                value={Inputs.InnovativeOfferingAnalysisL20} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisL20: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-8]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 8.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisN20" 
                                value={Inputs.InnovativeOfferingAnalysisN20} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisN20: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-8]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 8.')
                                    }
                                }} />
                        </td>
                        </tr> 
                        <tr>
                        <td className="width-5">9</td>
                        <td colSpan={2}>
                            <input type="text" className="form-control inputText"
                                id="InnovativeOfferingAnalysisE21"
                                value={Inputs.InnovativeOfferingAnalysisE21}
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisE21: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Product or Service" />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisF21" 
                                value={Inputs.InnovativeOfferingAnalysisF21} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisF21: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter 0 only.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisH21" 
                                value={Inputs.InnovativeOfferingAnalysisH21} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisH21: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-2]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 2.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisJ21" 
                                value={Inputs.InnovativeOfferingAnalysisJ21} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisJ21: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisL21" 
                                value={Inputs.InnovativeOfferingAnalysisL21} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisL21: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-8]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 8.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisN21" 
                                value={Inputs.InnovativeOfferingAnalysisN21} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisN21: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        </tr> 
                        <tr>
                        <td className="width-5">10</td>
                        <td colSpan={2}>
                            <input type="text" className="form-control inputText"
                                id="InnovativeOfferingAnalysisE22"
                                value={Inputs.InnovativeOfferingAnalysisE22}
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisE22: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Product or Service" />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisF22" 
                                value={Inputs.InnovativeOfferingAnalysisF22} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisF22: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter 0 only.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisH22" 
                                value={Inputs.InnovativeOfferingAnalysisH22} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisH22: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-2]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 2.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisJ22" 
                                value={Inputs.InnovativeOfferingAnalysisJ22} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisJ22: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisL22" 
                                value={Inputs.InnovativeOfferingAnalysisL22} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisL22: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-8]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 8.')
                                    }
                                }} />
                        </td>
                        <td className="text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="InnovativeOfferingAnalysisN22" 
                                value={Inputs.InnovativeOfferingAnalysisN22} 
                                onChange={(event) => setInputs({ ...Inputs, InnovativeOfferingAnalysisN22: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                  if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                  }
                                }} />
                        </td>
                        </tr> 
                        <tr l>
                        <td className="width-5 d-hidden">10</td>
                        <td colSpan={2} className="bg-color1 text-white">Average</td>
                        <td className="text-center-c bg-color1 text-white">
                            {Inputs.InnovativeOfferingAnalysisF24}
                        </td>
                        <td className="text-center-c bg-color1 text-white">
                            {Inputs.InnovativeOfferingAnalysisH24}
                        </td>
                        <td className="text-center-c bg-color1 text-white">
                            {Inputs.InnovativeOfferingAnalysisJ24}
                        </td>
                        <td className="text-center-c bg-color1 text-white">
                            {Inputs.InnovativeOfferingAnalysisL24}
                        </td>
                        <td className="text-center-c bg-color1 text-white">
                            {Inputs.InnovativeOfferingAnalysisN24}
                        </td>
                        <td className="text-center-c bg-color3 text-white width-10">
                            {Inputs.InnovativeOfferingAnalysisP24}
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}
