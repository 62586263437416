import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../features/Message";
import zeroInOnValueService from "../../services/zeroInOnValue.service";

//Get data based on user
export const getZeroInOnValue = createAsyncThunk(
    "strategicFoundation/getZeroInOnValue",
    async ({user_id}, thunkAPI) => {
      try {
        const response = await zeroInOnValueService.getData(user_id);
        //console.log(response)
        //thunkAPI.dispatch(setMessage(response.data.message));
        return response.data
      } catch (error) {
        console.log(error)
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
);

//add or edit ZeroInOnValue
export const modifyZeroInOnValue = createAsyncThunk(
    "strategicFoundation/modifyZeroInOnValue",
    async (postClientData , thunkAPI) => {
      try {
        const data = await zeroInOnValueService.postData(postClientData);
        thunkAPI.dispatch(setMessage(""));
        console.log(data)
        return { growthPlanning : data };
      } catch (error) {
        console.log('errorcode',error.code)
        if(error.code == 'ERR_BAD_REQUEST'){
          return {status : 401}
        }
        else{
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          thunkAPI.dispatch(setMessage(message));
          return thunkAPI.rejectWithValue();
        }
      }
    }
);


export const ZeroInOnValueSlice = createSlice({
    name: 'ZeroInOnValue',
    reducers: {
      
    },
    extraReducers: {
      [getZeroInOnValue.pending]: (state) => {
        state.loading = true
      },
      [getZeroInOnValue.fulfilled]: (state, { payload }) => {
        //console.log("---------get Data -----------", payload.data.ZeroInOnValue)
        state.loading = false
      },
      [getZeroInOnValue.rejected]: (state) => {
        state.loading = false
      },
    },
  })

const { reducer } = ZeroInOnValueSlice;
export default reducer;