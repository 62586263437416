import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state/index';
import { ComboChartGraph } from '../graph/ComboChartGraph';
import { Link } from "react-router-dom";
import  UserDropdown  from '../UserDropdown';
import { Spinner } from '../spinner';

export default function CreatingGrowthMomentumContent() {
    const dispatch = useDispatch();
    const clientInputs = useSelector(state => state.clientInputs)
    const impactof120GrowthOS = useSelector(state => state.impactof120GrowthOS)
    const creatingGrowthMomentum = useSelector(state => state.creatingGrowthMomentum)
    const { creatingGrowthMomentumData } = bindActionCreators(actionCreators, dispatch)
    //console.log(creatingGrowthMomentum);

    const [Inputs, setInputs] = useState({
        creatingGrowthMomentumM12: creatingGrowthMomentum.creatingGrowthMomentumM12,
    });

    useEffect(() => {
        creatingGrowthMomentumData("creatingGrowthMomentumM12", creatingGrowthMomentum.creatingGrowthMomentumM12);
    }, [])

    useEffect(() => {
        creatingGrowthMomentumData("creatingGrowthMomentumQuarterlyImprovement", {
            clientInputs,
            impactof120GrowthOS,
        });
    }, [Inputs.creatingGrowthMomentumM12]);

    const { creatingGrowthMomentumK10, creatingGrowthMomentumI12, creatingGrowthMomentumJ12, creatingGrowthMomentumK12, creatingGrowthMomentumL12, creatingGrowthMomentumI13, creatingGrowthMomentumJ13, creatingGrowthMomentumK13, creatingGrowthMomentumL13, creatingGrowthMomentumM13, creatingGrowthMomentumI14, creatingGrowthMomentumJ14, creatingGrowthMomentumK14, creatingGrowthMomentumL14, creatingGrowthMomentumM14, creatingGrowthMomentumI15, creatingGrowthMomentumJ15, creatingGrowthMomentumK15, creatingGrowthMomentumL15, creatingGrowthMomentumM15, creatingGrowthMomentumI16, creatingGrowthMomentumJ16, creatingGrowthMomentumK16, creatingGrowthMomentumL16, creatingGrowthMomentumM16, creatingGrowthMomentumI17, creatingGrowthMomentumJ17, creatingGrowthMomentumK17, creatingGrowthMomentumL17, creatingGrowthMomentumM17, creatingGrowthMomentumG55, creatingGrowthMomentumG56, creatingGrowthMomentumG57, creatingGrowthMomentumG58, creatingGrowthMomentumG59, creatingGrowthMomentumG60} = creatingGrowthMomentum

    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
        
            {/* row */}
            <div className="container-fluid lead-generation-new">
                <div className="head-title mb-5">
                    <h3 className='preheading'>Your Growth Potential</h3>
                    <h1 className='mainheading mb-1'>Creating Growth Momentum</h1>
                </div>
                <div className="customer-transformation">
                    <div className="customer-transformation-inner">
                        <Spinner/>
                        <div className='row'>
                            <div className='col-xl-5 col-lg-5 col-12 mb-3'>
                                <p className='font16px color-dark'>This is how 5 years of 1% improvements impact your growth trajectory:</p>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className='col-xl-5 col-lg-5 col-12 mb-3'>
                                <div className='black-bg' style={{borderRadius: 15, minHeight: 400}}>
                                    <h3 className="text-center text-white font18px">5 Years of 1% Improvements</h3>
                                    <ComboChartGraph dataInput={{
                                        "Title"      : '$'+Number(creatingGrowthMomentumJ17).toLocaleString('en-US')+' of increased revenue above current trend.',
                                        "vTitle"     : "",
                                        "hTitle"     : "",
                                        "subTitle"   : "",
                                        "ColorCodes" : ["#33C1B1", "#FFFFFF", "#0B39C1"],
                                        "GraphData"  : [
                                            ["", "Potential", "Current Trend"],
                                            ["0", Number(creatingGrowthMomentumJ12), Number(creatingGrowthMomentumG55)],
                                            ["1", Number(creatingGrowthMomentumJ13), Number(creatingGrowthMomentumG56)],
                                            ["2", Number(creatingGrowthMomentumJ14), Number(creatingGrowthMomentumG57)], 
                                            ["3", Number(creatingGrowthMomentumJ15), Number(creatingGrowthMomentumG58)],
                                            ["4", Number(creatingGrowthMomentumJ16), Number(creatingGrowthMomentumG59)],
                                            ["5", Number(creatingGrowthMomentumJ17), Number(creatingGrowthMomentumG60)],
                                        ],
                                        "backgroundColor" : "transparent"
                                    }} />
                                </div>
                            </div>
                            <div className='col-xl-7 col-lg-7 col-12 mb-3'>
                                <div className="table-responsive">
                                    <table className="table" style={{width: 800}}>
                                        <thead>
                                            <tr>
                                                <td colSpan={3}className="adl-matrix-color-bg text-white font16px">CAGR at 1% Quarterly Improvement:</td>
                                                <td className='bg-color-teal text-center text-white font18px fontBold'><strong>{creatingGrowthMomentumK10}%</strong></td>
                                                <td colSpan={2} />
                                            </tr>
                                            <tr className="thead-primary">
                                                <td className='black-bg text-white font14px text-center' style={{width: 50}}><div style={{width: 30}}>Yr</div></td>
                                                <td className='black-bg text-white font14px text-center' style={{width: 150}}><div style={{width: 150}}>Starting Revenue</div></td>
                                                <td className='black-bg text-white font14px text-center' style={{width: 150}}><div style={{width: 150}}>Year-End Revenue</div></td>
                                                <td className='black-bg text-white font14px text-center' style={{width: 150}}><div style={{width: 150}}>Annual Growth $</div></td>
                                                <td className='black-bg text-white font14px text-center' style={{width: 150}}><div style={{width: 150}}>CAGR</div></td>
                                                <td className='black-bg text-white font14px text-center' style={{width: 150}}><div style={{width: 150}}>Cumulative Growth</div></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='black-bg text-white text-center font14px'>0</td>
                                                <td className='bg-color-light-blue'>
                                                    <div className="annual-dollor-sign">
                                                        <div className="annual-dl font14px color-dark"> $ </div>
                                                        <div className="annual-dl-number font14px color-dark">
                                                            {Number(creatingGrowthMomentumI12).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='bg-color-light-blue'>
                                                    <div className="annual-dollor-sign">
                                                        <div className="annual-dl font14px color-dark"> $ </div>
                                                        <div className="annual-dl-number font14px color-dark">
                                                            {Number(creatingGrowthMomentumJ12).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='bg-color-light-blue'>
                                                    <div className="annual-dollor-sign">
                                                        <div className="annual-dl font14px color-dark"> $ </div>
                                                        <div className="annual-dl-number font14px color-dark">
                                                            {Number(creatingGrowthMomentumK12).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='bg-color-light-blue font14px color-dark ms-text-right'>{creatingGrowthMomentumL12}%</td>
                                                <td className='adl-matrix-color-bg'></td>
                                            </tr>
                                            <tr>
                                                <td className='black-bg text-white text-center font14px'>1</td>
                                                <td className='bg-color-light-blue'>
                                                    <div className="annual-dollor-sign">
                                                        <div className="annual-dl font14px color-dark"> $ </div>
                                                        <div className="annual-dl-number font14px color-dark">
                                                            {Number(creatingGrowthMomentumI13).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='bg-color-light-blue'>
                                                    <div className="annual-dollor-sign">
                                                        <div className="annual-dl font14px color-dark"> $ </div>
                                                        <div className="annual-dl-number font14px color-dark">
                                                            {Number(creatingGrowthMomentumJ13).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='bg-color-light-blue'>
                                                    <div className="annual-dollor-sign">
                                                        <div className="annual-dl font14px color-dark"> $ </div>
                                                        <div className="annual-dl-number font14px color-dark">
                                                            {Number(creatingGrowthMomentumK13).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='bg-color-light-blue font14px color-dark fontBold ms-text-right'>{creatingGrowthMomentumL13}%</td>
                                                <td className='bg-color-blue font14px fontBold text-white ms-text-right'>{creatingGrowthMomentumM13}%</td>
                                            </tr>
                                            <tr>
                                                <td className='black-bg text-white text-center font14px'>2</td>
                                                <td className='bg-color-light-blue'>
                                                    <div className="annual-dollor-sign">
                                                        <div className="annual-dl font14px color-dark"> $ </div>
                                                        <div className="annual-dl-number font14px color-dark">
                                                            {Number(creatingGrowthMomentumI14).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='bg-color-light-blue'>
                                                    <div className="annual-dollor-sign">
                                                        <div className="annual-dl font14px color-dark"> $ </div>
                                                        <div className="annual-dl-number font14px color-dark">
                                                            {Number(creatingGrowthMomentumJ14).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='bg-color-light-blue'>
                                                    <div className="annual-dollor-sign">
                                                        <div className="annual-dl font14px color-dark"> $ </div>
                                                        <div className="annual-dl-number font14px color-dark">
                                                            {Number(creatingGrowthMomentumK14).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='bg-color-light-blue font14px fontBold color-dark ms-text-right'>{creatingGrowthMomentumL14}%</td>
                                                <td className='bg-color-blue font14px fontBold text-white ms-text-right'>{creatingGrowthMomentumM14}%</td>
                                            </tr>
                                            <tr>
                                                <td className='black-bg text-white text-center font14px'>3</td>
                                                <td className='bg-color-light-blue'>
                                                    <div className="annual-dollor-sign">
                                                        <div className="annual-dl font14px color-dark"> $ </div>
                                                        <div className="annual-dl-number font14px color-dark">
                                                            {Number(creatingGrowthMomentumI15).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='bg-color-light-blue'>
                                                    <div className="annual-dollor-sign">
                                                        <div className="annual-dl font14px color-dark"> $ </div>
                                                        <div className="annual-dl-number font14px color-dark">
                                                            {Number(creatingGrowthMomentumJ15).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='bg-color-light-blue'>
                                                    <div className="annual-dollor-sign">
                                                        <div className="annual-dl font14px color-dark"> $ </div>
                                                        <div className="annual-dl-number font14px color-dark">
                                                            {Number(creatingGrowthMomentumK15).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='bg-color-light-blue font14px fontBold color-dark ms-text-right'>{creatingGrowthMomentumL15}%</td>
                                                <td className='bg-color-blue font14px fontBold text-white ms-text-right'>{creatingGrowthMomentumM15}%</td>
                                            </tr>
                                            <tr>
                                                <td className='black-bg text-white text-center font14px'>4</td>
                                                <td className='bg-color-light-blue'>
                                                    <div className="annual-dollor-sign">
                                                        <div className="annual-dl font14px color-dark"> $ </div>
                                                        <div className="annual-dl-number font14px color-dark">
                                                            {Number(creatingGrowthMomentumI16).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='bg-color-light-blue'>
                                                    <div className="annual-dollor-sign">
                                                        <div className="annual-dl font14px color-dark"> $ </div>
                                                        <div className="annual-dl-number font14px color-dark">
                                                            {Number(creatingGrowthMomentumJ16).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='bg-color-light-blue'>
                                                    <div className="annual-dollor-sign">
                                                        <div className="annual-dl font14px color-dark"> $ </div>
                                                        <div className="annual-dl-number font14px color-dark">
                                                            {Number(creatingGrowthMomentumK16).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='bg-color-light-blue font14px fontBold color-dark ms-text-right'>{creatingGrowthMomentumL16}%</td>
                                                <td className='bg-color-blue font14px fontBold text-white ms-text-right'>{creatingGrowthMomentumM16}%</td>
                                            </tr>
                                            <tr>
                                                <td className='black-bg text-white text-center font14px'>5</td>
                                                <td className='bg-color-light-blue'>
                                                    <div className="annual-dollor-sign">
                                                        <div className="annual-dl font14px color-dark"> $ </div>
                                                        <div className="annual-dl-number font14px color-dark">
                                                            {Number(creatingGrowthMomentumI17).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='bg-color-light-blue'>
                                                    <div className="annual-dollor-sign">
                                                        <div className="annual-dl font14px color-dark"> $ </div>
                                                        <div className="annual-dl-number font14px color-dark">
                                                            {Number(creatingGrowthMomentumJ17).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='bg-color-light-blue'>
                                                    <div className="annual-dollor-sign">
                                                        <div className="annual-dl font14px color-dark"> $ </div>
                                                        <div className="annual-dl-number font14px color-dark">
                                                            {Number(creatingGrowthMomentumK17).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='bg-color-light-blue font14px fontBold color-dark ms-text-right'>{creatingGrowthMomentumL17}%</td>
                                                <td className='bg-color-blue font14px fontBold text-white ms-text-right'>{creatingGrowthMomentumM17}%</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="row lead-genearation-rw mt-4">
                    <div className="col-12 col-md-6 graph-blue-bg">
                    <ComboChartGraph dataInput={{
                        "Title"      : "5 Years of 1% Improvements",
                        "vTitle"     : "",
                        "hTitle"     : "",
                        "subTitle"   : "",
                        "ColorCodes" : ["#31906E", "#FFFFFF", "#0B39C1"],
                        "GraphData"  : [
                            ["", "Potential", "Current Trend"],
                            ["0", Number(creatingGrowthMomentumJ12), Number(creatingGrowthMomentumG55)],
                            ["1", Number(creatingGrowthMomentumJ13), Number(creatingGrowthMomentumG56)],
                            ["2", Number(creatingGrowthMomentumJ14), Number(creatingGrowthMomentumG57)], 
                            ["3", Number(creatingGrowthMomentumJ15), Number(creatingGrowthMomentumG58)],
                            ["4", Number(creatingGrowthMomentumJ16), Number(creatingGrowthMomentumG59)],
                            ["5", Number(creatingGrowthMomentumJ17), Number(creatingGrowthMomentumG60)],
                        ],
                        "backgroundColor" : "#1E2B3C"
                    }} />
                    </div>
                    <div className="col-12 col-md-6 mt-4">
                        <div className="card update-class-lead">
                            <div className="card-body pl-0">
                                <div className="table-responsive">
                                        <table className="table table-responsive-md table-striped mb-0">
                                            <thead>
                                                <tr>
                                                    <th colSpan={3}className="adl-matrix-color-bg">CAGR at 1% Quarterly Improvement:</th>
                                                    <td style={{ textAlign: 'center' }}  className='bg-color-teal text-white font15px'><strong>{creatingGrowthMomentumK10}%</strong></td>
                                                    <th colSpan={2} />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="thead-primary">
                                                    <th className='black-bg'>Yr</th>
                                                    <th className='black-bg'>Starting Revenue</th>
                                                    <th className='black-bg'>Year-end Revenue</th>
                                                    <th className='black-bg'>Annual Growth $</th>
                                                    <th className='black-bg'>CAGR</th>
                                                    <th className='black-bg'>Camulative Growth</th>
                                                </tr>
                                                <tr>
                                                    <th className='black-bg' style={{ textAlign: 'center' }}>0</th>
                                                    <td className='bg-color-light-blue font15px color-black'>
                                                        <div className="annual-dollor-sign">
                                                            <div className="annual-dl font15px"> $ </div>
                                                            <div className="annual-dl-number font15px">
                                                                {Number(creatingGrowthMomentumI12).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='bg-color-light-blue font15px color-black'>
                                                        <div className="annual-dollor-sign">
                                                            <div className="annual-dl font15px"> $ </div>
                                                            <div className="annual-dl-number font15px">
                                                                {Number(creatingGrowthMomentumJ12).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='bg-color-light-blue font15px color-black'>
                                                        <div className="annual-dollor-sign">
                                                            <div className="annual-dl font15px"> $ </div>
                                                            <div className="annual-dl-number font15px">
                                                                {Number(creatingGrowthMomentumK12).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='bg-color-light-blue font15px color-black'>{creatingGrowthMomentumL12}%</td>
                                                    <td className='adl-matrix-color-bg'></td>
                                                </tr>
                                                <tr>
                                                    <th className='black-bg' style={{ textAlign: 'center' }}>1</th>
                                                    <td className='bg-color-light-blue font15px color-black'>
                                                        <div className="annual-dollor-sign">
                                                            <div className="annual-dl font15px"> $ </div>
                                                            <div className="annual-dl-number font15px">
                                                                {Number(creatingGrowthMomentumI13).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='bg-color-light-blue font15px color-black'>
                                                        <div className="annual-dollor-sign">
                                                            <div className="annual-dl font15px"> $ </div>
                                                            <div className="annual-dl-number font15px">
                                                                {Number(creatingGrowthMomentumJ13).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='bg-color-light-blue font15px color-black'>
                                                        <div className="annual-dollor-sign">
                                                            <div className="annual-dl font15px"> $ </div>
                                                            <div className="annual-dl-number font15px">
                                                                {Number(creatingGrowthMomentumK13).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='bg-color-light-blue font15px color-black'>{creatingGrowthMomentumL13}%</td>
                                                    <td className='bg-color-blue font15px text-white'>{creatingGrowthMomentumM13}%</td>
                                                </tr>
                                                <tr>
                                                    <th className='black-bg' style={{ textAlign: 'center' }}>2</th>
                                                    <td className='bg-color-light-blue font15px color-black'>
                                                        <div className="annual-dollor-sign">
                                                            <div className="annual-dl font15px"> $ </div>
                                                            <div className="annual-dl-number font15px">
                                                                {Number(creatingGrowthMomentumI14).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='bg-color-light-blue font15px color-black'>
                                                        <div className="annual-dollor-sign">
                                                            <div className="annual-dl font15px"> $ </div>
                                                            <div className="annual-dl-number font15px">
                                                                {Number(creatingGrowthMomentumJ14).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='bg-color-light-blue font15px color-black'>
                                                        <div className="annual-dollor-sign">
                                                            <div className="annual-dl font15px"> $ </div>
                                                            <div className="annual-dl-number font15px">
                                                                {Number(creatingGrowthMomentumK14).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='bg-color-light-blue font15px color-black'>{creatingGrowthMomentumL14}%</td>
                                                    <td className='bg-color-blue font15px text-white'>{creatingGrowthMomentumM14}%</td>
                                                </tr>
                                                <tr>
                                                    <th className='black-bg' style={{ textAlign: 'center' }}>3</th>
                                                    <td className='bg-color-light-blue font15px color-black'>
                                                        <div className="annual-dollor-sign">
                                                            <div className="annual-dl font15px"> $ </div>
                                                            <div className="annual-dl-number font15px">
                                                                {Number(creatingGrowthMomentumI15).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='bg-color-light-blue font15px color-black'>
                                                        <div className="annual-dollor-sign">
                                                            <div className="annual-dl font15px"> $ </div>
                                                            <div className="annual-dl-number font15px">
                                                                {Number(creatingGrowthMomentumJ15).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='bg-color-light-blue font15px color-black'>
                                                        <div className="annual-dollor-sign">
                                                            <div className="annual-dl font15px"> $ </div>
                                                            <div className="annual-dl-number font15px">
                                                                {Number(creatingGrowthMomentumK15).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='bg-color-light-blue font15px color-black'>{creatingGrowthMomentumL15}%</td>
                                                    <td className='bg-color-blue font15px text-white'>{creatingGrowthMomentumM15}%</td>
                                                </tr>
                                                <tr>
                                                    <th className='black-bg' style={{ textAlign: 'center' }}>4</th>
                                                    <td className='bg-color-light-blue font15px color-black'>
                                                        <div className="annual-dollor-sign">
                                                            <div className="annual-dl font15px"> $ </div>
                                                            <div className="annual-dl-number font15px">
                                                                {Number(creatingGrowthMomentumI16).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='bg-color-light-blue font15px color-black'>
                                                        <div className="annual-dollor-sign">
                                                            <div className="annual-dl font15px"> $ </div>
                                                            <div className="annual-dl-number font15px">
                                                                {Number(creatingGrowthMomentumJ16).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='bg-color-light-blue font15px color-black'>
                                                        <div className="annual-dollor-sign">
                                                            <div className="annual-dl font15px"> $ </div>
                                                            <div className="annual-dl-number font15px">
                                                                {Number(creatingGrowthMomentumK16).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='bg-color-light-blue font15px color-black'>{creatingGrowthMomentumL16}%</td>
                                                    <td className='bg-color-blue font15px text-white'>{creatingGrowthMomentumM16}%</td>
                                                </tr>
                                                <tr>
                                                    <th className='black-bg' style={{ textAlign: 'center' }}>5</th>
                                                    <td className='bg-color-light-blue font15px color-black'>
                                                        <div className="annual-dollor-sign">
                                                            <div className="annual-dl font15px"> $ </div>
                                                            <div className="annual-dl-number font15px">
                                                                {Number(creatingGrowthMomentumI17).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='bg-color-light-blue font15px color-black'>
                                                        <div className="annual-dollor-sign">
                                                            <div className="annual-dl font15px"> $ </div>
                                                            <div className="annual-dl-number font15px">
                                                                {Number(creatingGrowthMomentumJ17).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='bg-color-light-blue font15px color-black'>
                                                        <div className="annual-dollor-sign">
                                                            <div className="annual-dl font15px"> $ </div>
                                                            <div className="annual-dl-number font15px">
                                                                {Number(creatingGrowthMomentumK17).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('$')[1]}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='bg-color-light-blue font15px color-black'>{creatingGrowthMomentumL17}%</td>
                                                    <td className='bg-color-blue font15px text-white'>{creatingGrowthMomentumM17}%</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="nextInput">
                    <ul>
                        <li>
                            <Link to="/your-growth-potential/what-is-driving-growth" className='next-link'> Next:  What's Driving Growth 
                                <span className='icon-three'>
                                    <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                    <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                    <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                </span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
