import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state/index';
import UserDropdown from '../UserDropdown';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { getClientInputs, updateClientInputs, clientInputsGrowthRate, clientInputsCAGR, clientInputsD11, clientInputsD12, clientInputsD13, clientInputsH16, clientInputsH17, clientInputsH18, clientInputsH19, clientInputsH20, clientInputsH21, clientInputsH22, clientInputsH23, clientInputsH24, clientInputsH25, clientInputsH26, clientInputsH27, clientInputsH30, clientInputsH31, clientInputsH32, clientInputsH33, clientInputsH36, clientInputsH37, clientInputsH38, clientInputsH41 } from "../../state/features/ClentInputSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner'
import { useNavigate, createSearchParams, useLocation  } from 'react-router-dom';
import { Link } from 'react-router-dom';
import formatPrice from '../../utils/formatPrice';

const ClientInputsContent = () => {
    const navigate = useNavigate();
    const location = useLocation();
    
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const users = useSelector(userData);
    const userID = users.data.profile.id;

    //form validations schema 
    const validationSchema = Yup.object().shape({
        clientInputsD11: Yup.string().required('Field is required.'),
        clientInputsD12: Yup.string().required('Field is required.'),
        clientInputsD13: Yup.string().required('Field is required.'),
        clientInputsH16: Yup.string().required('Field is required.'),
        clientInputsH17: Yup.string().required('Field is required.'),
        clientInputsH18: Yup.string().required('Field is required.'),
        clientInputsH19: Yup.string().required('Field is required.'),
        clientInputsH20: Yup.string().required('Field is required.'),
        clientInputsH21: Yup.string().required('Field is required.'),
        clientInputsH22: Yup.string().required('Field is required.'),
        clientInputsH24: Yup.string().required('Field is required.'),
        clientInputsH25: Yup.string().required('Field is required.'),
        clientInputsH26: Yup.string().required('Field is required.'),
        clientInputsH27: Yup.string().required('Field is required.'),
        clientInputsH30: Yup.string().required('Field is required.'),
        clientInputsH31: Yup.string().required('Field is required.'),
        clientInputsH32: Yup.string().required('Field is required.'),
        clientInputsH33: Yup.string().required('Field is required.'),
        clientInputsH36: Yup.string().required('Field is required.'),
        clientInputsH37: Yup.string().required('Field is required.'),
        clientInputsH38: Yup.string().required('Field is required.'),
        clientInputsH41: Yup.string().required('Field is required.'),
    })

    const formOptions = { resolver: yupResolver(validationSchema) }
    const { setValue, register, formState: { errors, isSubmitting }, handleSubmit, } = useForm(formOptions);

    const currentYear = new Date().getFullYear(); // previousYear
    const previousYear1 = currentYear - 1;
    const previousYear2 = previousYear1 - 1;
    const previousYear3 = previousYear2 - 1;

    // redux dispatch
    const dispatch = useDispatch();
    const { clientInputsData } = bindActionCreators(actionCreators, dispatch)
    const clientInputs = useSelector(state => state.clientInputs)
    const { message } = useSelector((state) => state.message);
    
    const [Inputs, setInputs] = useState({
        clientInputsD11: clientInputs.clientInputsD11 ? clientInputs.clientInputsD11 : 0,
        clientInputsD12: clientInputs.clientInputsD12 ? clientInputs.clientInputsD12 : 0,
        clientInputsD13: clientInputs.clientInputsD13 ? clientInputs.clientInputsD13 : 0,
        clientInputsH16: clientInputs.clientInputsH16,
        clientInputsH17: clientInputs.clientInputsH17,
        clientInputsH18: clientInputs.clientInputsH18,
        clientInputsH19: clientInputs.clientInputsH19 == 0 ? '' : clientInputs.clientInputsH19,
        clientInputsH20: clientInputs.clientInputsH20,
        clientInputsH21: clientInputs.clientInputsH21,
        clientInputsH22: clientInputs.clientInputsH22,
        clientInputsH23: clientInputs.clientInputsH23,
        clientInputsH24: clientInputs.clientInputsH24,
        clientInputsH25: clientInputs.clientInputsH25 == 0 ? '' : clientInputs.clientInputsH25,
        clientInputsH26: clientInputs.clientInputsH26 == 0 ? '' : clientInputs.clientInputsH26,
        clientInputsH27: clientInputs.clientInputsH27,
        clientInputsH30: clientInputs.clientInputsH30 == 0 ? '' : clientInputs.clientInputsH30,
        clientInputsH31: clientInputs.clientInputsH31,
        clientInputsH32: clientInputs.clientInputsH32,
        clientInputsH33: clientInputs.clientInputsH33 == 0 ? '' : clientInputs.clientInputsH33,
        clientInputsH36: clientInputs.clientInputsH36 == 0 ? '' : clientInputs.clientInputsH36,
        clientInputsH37: clientInputs.clientInputsH37,
        clientInputsH38: clientInputs.clientInputsH38,
        clientInputsH41: clientInputs.clientInputsH41 == 0 ? '' : clientInputs.clientInputsH41,
        clientInputsJ11: '',
        clientInputsJ12: '',
        clientInputsJ13: '',
        clientInputsJ16: '',
        clientInputsJ17: '',
        clientInputsJ18: '',
        clientInputsJ19: '',
        clientInputsJ20: '',
        clientInputsJ21: '',
        clientInputsJ22: '',
        clientInputsJ23: '',
        clientInputsJ24: '',
        clientInputsJ25: '',
        clientInputsJ26: '',
        clientInputsJ27: '',
        clientInputsJ30: '',
        clientInputsJ31: '',
        clientInputsJ32: '',
        clientInputsJ33: '',
        clientInputsJ36: '',
        clientInputsJ37: '',
        clientInputsJ38: '',
        clientInputsJ41: ''
    });

    useEffect(() => {
        dispatch(clearMessage());
        setIsLoading(true)
        dispatch(getClientInputs(userID))
            .unwrap()
            .then(async (response) => {
                if(response!= undefined){
                let clientRes = response.data;
                    if(clientRes && clientRes.clientInputs){
                    let clientInputsRes = clientRes.clientInputs
                    delete clientInputsRes.id;
                    delete clientInputsRes.user_id;
                    delete clientInputsRes.created_at;
                    delete clientInputsRes.updated_at;
                    const newObj = { ...Inputs }
                    for await (const [key, value] of Object.entries(clientInputsRes)) {
                        if (clientInputsRes.hasOwnProperty(key)) {
                            if(value)
                            newObj[key] = value
                        }
                        setValue(key, value)
                    }
                    // console.log(newObj)
                    setInputs(newObj);
                    }
                } 
            })
            .then(async () => {
            setIsLoading(false)
            })
            .catch(() => {
                setIsLoading(false)
                { alert.show("Something went wrong! Please refresh your browser first.") }
            });
    }, [dispatch])

    useEffect(() => {
        clientInputsData(clientInputsGrowthRate);
        clientInputsData(clientInputsCAGR);
    }, [Inputs.clientInputsD11, Inputs.clientInputsD12, Inputs.clientInputsD13]);

    useEffect(() => {
        clientInputsData(clientInputsH23, Inputs.clientInputsH16)
    },[Inputs.clientInputsH16])

    // console.log(errors)
    const onSubmit = formValue => {
        formValue.user_id = userID
        const {clientInputsE11,clientInputsF11,clientInputsE12,clientInputsH23 } = clientInputs
        formValue.clientInputsE11 = clientInputsE11.toString()
        formValue.clientInputsF11 = clientInputsF11.toString()
        formValue.clientInputsE12 = clientInputsE12.toString()
        formValue.clientInputsH23 = clientInputsH23.toString()
        //console.log("form data-------",formValue)
        setIsLoading(true)
        dispatch(updateClientInputs(formValue))
        .unwrap()
        .then((response) => {
            // console.log("response",response.user.data.data.clientInputs.user_id)
            if(response!= undefined){
                let userId = response.user.data.data.clientInputs.user_id
                // console.log(" data",userId)
                navigate({
                pathname: '/success',
                search: `?${createSearchParams({url: encodeURI(location.pathname)})}`,
                });
            }
            setIsLoading(false)
            { alert.show(response.user.data.message) }
        })
        .catch((error) => {
        setIsLoading(false)
            // console.log("error",error)
            { message && alert.show(message) }
        });
    }

    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
            {/* row */}
            <div className="container-fluid">
                <div className="head-title">
                    <h3 className='preheading'>Diagnose</h3>
                    <h1 className='mainheading'>Client Inputs</h1>
                    <div className="row graph-row">
                        <div className="col-12 col-md-12 mb-5">
                            <div className='tp-content-grf' style={{maxWidth: 1000}}>
                                <p className='font11px color-dark'>Please insert numbers below as they relate to your company, customers, and financial situation.  These numbers are important and will be used for numerous calculations throughout the Growth Catalyst.  The questions in bold are required, so please provide your best answer.  If you don't know an answer, make your best guess, and note that it is an approximation in the adjacent comment section.  You are welcome to contact us with any questions.</p>
                                <p className='font11px color-dark'>Please place your answers in the orange boxes to the right or below the questions.  Please place any questions and comments in the cells to the right.</p>
                                <span><strong><span className="asterisk">***</span>NOTE: : You must click on the blue save button below to calculate any changes.</strong></span>
                            </div>
                        </div>
                        
                        <Spinner />
                        <div className="client-Input">
                            <form className="client-Input-inner" onSubmit={handleSubmit(onSubmit)}>
                                
                                <div className="table-responsive mb-4">
                                    
                                    <table className="table client-input mb-0" style={{width: 1300}}>
                                        <thead>
                                            <tr>
                                                <td colSpan={2} className='black-bg text-white font16px'>Annual Revenue</td>
                                                <td className='black-bg text-white font16px'>Growth Rate</td>
                                                <td className='black-bg text-white font16px'>CAGR</td>
                                                <td className='hide-content'></td>
                                                <td className='hide-content'></td>
                                                <td className='black-bg text-white font16px' style={{width: 300}}>Comments</td>
                                                <td className='hide-content' style={{width: 300}}></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='bg-color-02 text-ms-left font11px fontExtraBold color-dark' style={{width: 100}}>{previousYear1}</td>
                                                <td className='padding-0 bg-color-01' style={{width: 200}}>
                                                    <div className="dollor-sin-pr">
                                                        <div className="dollor-sin color-dark font11px">$</div>
                                                        <div className="input-fiel-cn bg-color-01">
                                                            <input type="text"
                                                            disabled={isLoading}
                                                            {...register("clientInputsD11")}
                                                            className={`field-ipt font11px bg-color-01 ms-text-right color-dark ${errors.clientInputsD11 ? 'is-invalid' : ''}`}
                                                            //placeholder="0"
                                                            value={Inputs.clientInputsD11.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                            onChange={(event) => { setInputs({ ...Inputs, clientInputsD11: event.target.value.replace(/,/g, '') }); clientInputsData(clientInputsD11, event.target.value.replace(/,/g, '')) }}
                                                            onKeyPress={(event) => {
                                                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                                event.preventDefault();
                                                                }
                                                                else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                                event.preventDefault();
                                                                alert.show('Please enter a valid number.')
                                                                }
                                                            }} />
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='bg-color-02 font11px color-dark ms-text-right' style={{width: 150}}>{formatPrice(clientInputs.clientInputsE11, 1)} %</td>
                                                <td className='bg-color-02 font11px color-dark ms-text-right' style={{width: 100}}>
                                                    {formatPrice(clientInputs.clientInputsF11, 1)} %
                                                </td>
                                                <td className='hide-content' style={{width: 100}}></td>
                                                <td className='hide-content' style={{width: 50}}></td>
                                                <td colSpan={2} className='bg-color-02 padding-0'>
                                                    <div className='input-fiel-cn client-int-new'>
                                                        <input type="text" 
                                                        className='field-ipt font11px color-dark bg-color-02'
                                                        disabled={isLoading}
                                                        {...register("clientInputsJ11")}
                                                        value={Inputs.clientInputsJ11.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                        onChange={(event) => setInputs({ ...Inputs, clientInputsJ11: event.target.value.replace(/,/g, '') })} />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='bg-color-02 text-ms-left font11px fontExtraBold color-dark' style={{width: 100}}>{previousYear2}</td>
                                                <td className='padding-0 bg-color-01'>
                                                    <div className="dollor-sin-pr">
                                                        <div className="dollor-sin color-dark font11px">$</div>
                                                        <div className="input-fiel-cn bg-color-01">
                                                            <input type="text"
                                                            disabled={isLoading}
                                                            {...register("clientInputsD12")}
                                                            className={`field-ipt font11px bg-color-01 ms-text-right color-dark ${errors.clientInputsD12 ? 'is-invalid' : ''}`}
                                                            //placeholder="0"
                                                            value={Inputs.clientInputsD12.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                            onChange={(event) => { setInputs({ ...Inputs, clientInputsD12: event.target.value.replace(/,/g, '') }); clientInputsData(clientInputsD12, event.target.value.replace(/,/g, '')) }}
                                                            onKeyPress={(event) => {
                                                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                                event.preventDefault();
                                                                }
                                                                else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                                event.preventDefault();
                                                                alert.show('Please enter a valid number.')
                                                                }
                                                            }} />
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='bg-color-02 font11px color-dark ms-text-right'>{formatPrice(clientInputs.clientInputsE12, 1)} %</td>
                                                <td className='hide-content' style={{width: 100}}></td>
                                                <td className='hide-content' style={{width: 100}}></td>
                                                <td className='hide-content' style={{width: 50}}></td>
                                                <td colSpan={2} className='bg-color-02 padding-0'>
                                                    <div className='input-fiel-cn client-int-new'>
                                                        <input type="text" 
                                                        className='field-ipt font11px color-dark bg-color-02' 
                                                        disabled={isLoading}
                                                        {...register("clientInputsJ12")}
                                                        value={Inputs.clientInputsJ12.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                        onChange={(event) => setInputs({ ...Inputs, clientInputsJ12: event.target.value.replace(/,/g, '') })}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='bg-color-02 text-ms-left font11px fontExtraBold color-dark' style={{width: 100}}>{previousYear3}</td>
                                                <td className='padding-0 bg-color-01'>
                                                    <div className="dollor-sin-pr">
                                                        <div className="dollor-sin color-dark font11px">$</div>
                                                        <div className="input-fiel-cn bg-color-01">
                                                            <input type="text"
                                                            disabled={isLoading}
                                                            {...register("clientInputsD13")}
                                                            className={`field-ipt font11px bg-color-01 ms-text-right color-dark ${errors.clientInputsD13 ? 'is-invalid' : ''}`}
                                                            //placeholder="0"
                                                            value={Inputs.clientInputsD13.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                            onChange={(event) => { setInputs({ ...Inputs, clientInputsD13: event.target.value.replace(/,/g, '') }); clientInputsData(clientInputsD13, event.target.value.replace(/,/g, '')) }}
                                                            onKeyPress={(event) => {
                                                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                                event.preventDefault();
                                                                }
                                                                else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                                event.preventDefault();
                                                                alert.show('Please enter a valid number.')
                                                                }
                                                            }} />
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='padding-0 bg-color-03'></td>
                                                <td className='hide-content' style={{width: 100}}></td>
                                                <td className='hide-content' style={{width: 100}}></td>
                                                <td className='hide-content' style={{width: 50}}></td>
                                                <td colSpan={2} className='bg-color-02 padding-0'>
                                                    <div className='input-fiel-cn client-int-new'>
                                                        <input type="text" 
                                                        className='field-ipt font11px color-dark bg-color-02' 
                                                        disabled={isLoading}
                                                        {...register("clientInputsJ13")}
                                                        value={Inputs.clientInputsJ13.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                        onChange={(event) => setInputs({ ...Inputs, clientInputsJ13: event.target.value.replace(/,/g, '') })}/>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="table-responsive mb-4">
                                    
                                    <table className="table client-input mb-0" style={{width: 1300}}>
                                        <thead>
                                            <tr>
                                                <td className='black-bg text-white font16px text-white' style={{width: 300}}>
                                                Your Customers
                                                </td>
                                                <td className='hide-content' style={{width: 250}}></td>
                                                <td className='hide-content' style={{width: 100}}></td>
                                                <td className='hide-content' style={{width: 50}}>7</td>
                                                <td className='black-bg text-white font16px' style={{width: 300}}>Comments</td>
                                                <td className='hide-content' style={{width: 300}}></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='bg-color-02 font11px fontBold color-dark' colSpan={2} style={{width: 550}}>
                                                    Active Customers in {previousYear1}:
                                                </td>
                                                <td className='padding-0 bg-color-01' style={{width: 100}}>
                                                    <div className="input-fiel-cn bg-color-01 client-inpt-field">
                                                        <input type="text"
                                                        {...register("clientInputsH16")}
                                                        className={`field-ipt font11px bg-color-01 text-right color-dark ${errors.clientInputsH16 ? 'is-invalid' : ''}`}
                                                        //placeholder={0}
                                                        value={Inputs.clientInputsH16.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                        onChange={(event) => { setInputs({ ...Inputs, clientInputsH16: event.target.value.replace(/,/g, '') }) }}
                                                        onKeyPress={(event) => {
                                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                        else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                        }}  />
                                                    </div>
                                                </td>
                                                <td className='hide-content' style={{width: 50}}>7</td>
                                                <td colSpan={2} className='bg-color-02 padding-0'>
                                                    <div className='input-fiel-cn client-int-new'>
                                                        <input type="text" 
                                                        disabled={isLoading}
                                                        {...register("clientInputsJ16")}
                                                        className={`field-ipt font11px color-dark bg-color-02`}
                                                        value={Inputs.clientInputsJ16.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                        onChange={(event) => setInputs({ ...Inputs, clientInputsJ16: event.target.value.replace(/,/g, '') })} />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='bg-color-02 font11px color-dark' colSpan={2}>
                                                    Active Customers in {previousYear2}:
                                                </td>
                                                <td className='Blue-gray-bg padding-0'>
                                                    <div className="input-fiel-cn Blue-gray-bg client-inpt-field">
                                                        <input type="text"
                                                        {...register("clientInputsH17")}
                                                        className={`field-ipt font11px Blue-gray-bg text-right text-white ${errors.clientInputsH17 ? 'is-invalid' : ''}`}
                                                        //placeholder={0}
                                                        value={Inputs.clientInputsH17.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                        onChange={(event) => { setInputs({ ...Inputs, clientInputsH17: event.target.value.replace(/,/g, '') }) }}
                                                        onKeyPress={(event) => {
                                                            if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                            event.preventDefault();
                                                            }
                                                            else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                            }
                                                        }} />
                                                    </div>
                                                </td>
                                                <td className='hide-content'>7</td>
                                                <td colSpan={2} className='bg-color-02 padding-0'>
                                                    <div className='input-fiel-cn client-int-new'>
                                                        <input type="text"
                                                        disabled={isLoading}
                                                        {...register("clientInputsJ17")}
                                                        className={`field-ipt font11px color-dark bg-color-02`}
                                                        value={Inputs.clientInputsJ17.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                        onChange={(event) => setInputs({ ...Inputs, clientInputsJ17: event.target.value.replace(/,/g, '') })} />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='bg-color-02 font11px color-dark' colSpan={2}>
                                                    Active Customers in {previousYear3}:
                                                </td>
                                                <td className='Blue-gray-bg padding-0'>
                                                    <div className="input-fiel-cn Blue-gray-bg client-inpt-field">
                                                        <input type="text"
                                                        {...register("clientInputsH18")}
                                                        className={`field-ipt font11px Blue-gray-bg text-right text-white ${errors.clientInputsH18 ? 'is-invalid' : ''}`}
                                                        placeholder={0}
                                                        value={Inputs.clientInputsH18.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                        onChange={(event) => { setInputs({ ...Inputs, clientInputsH18: event.target.value.replace(/,/g, '') }) }}
                                                        onKeyPress={(event) => {
                                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                        else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                        }} />
                                                    </div>
                                                </td>
                                                <td className='hide-content'>7</td>
                                                <td colSpan={2} className='bg-color-02 padding-0'>
                                                    <div className='input-fiel-cn client-int-new'>
                                                        <input type="text"
                                                        disabled={isLoading}
                                                        {...register("clientInputsJ18")}
                                                        className={`field-ipt font11px color-dark bg-color-02`}
                                                        value={Inputs.clientInputsJ18.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                        onChange={(event) => setInputs({ ...Inputs, clientInputsJ18: event.target.value.replace(/,/g, '') })} />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='bg-color-02 font11px fontBold color-dark' colSpan={2}>
                                                Average Customer Buying Lifetime (years):
                                                </td>
                                                <td className='bg-color-01 padding-0'>
                                                    <div className="input-fiel-cn bg-color-01 client-inpt-field">
                                                        <input type="text"
                                                        {...register("clientInputsH19")}
                                                        className={`field-ipt font11px bg-color-01 text-right color-dark ${errors.clientInputsH19 ? 'is-invalid' : ''}`}
                                                        //placeholder={0}
                                                        value={Inputs.clientInputsH19.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                        onChange={(event) => { setInputs({ ...Inputs, clientInputsH19: event.target.value.replace(/,/g, '') }) }}
                                                        onKeyPress={(event) => {
                                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                        else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                        }} />
                                                    </div>
                                                </td>
                                                <td className='hide-content'>7</td>
                                                <td colSpan={2} className='bg-color-02 padding-0'>
                                                    <div className='input-fiel-cn bg-color-02 client-int-new'>
                                                        <input type="text"
                                                        disabled={isLoading}
                                                        {...register("clientInputsJ19")}
                                                        className={`field-ipt font11px color-dark bg-color-02`}
                                                        value={Inputs.clientInputsJ19.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                        onChange={(event) => setInputs({ ...Inputs, clientInputsJ19: event.target.value.replace(/,/g, '') })}  />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='bg-color-02 font11px fontBold color-dark' colSpan={2}>
                                                Total number of inactive customers from the last 3 years:
                                                </td>
                                                <td className='bg-color-01 padding-0'>
                                                    <div className="input-fiel-cn bg-color-01 client-inpt-field">
                                                        <input type="text"
                                                            {...register("clientInputsH20")}
                                                            className={`field-ipt font11px bg-color-01 text-right color-dark ${errors.clientInputsH20 ? 'is-invalid' : ''}`}
                                                            //placeholder={0}
                                                            value={Inputs.clientInputsH20.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                            onChange={(event) => { setInputs({ ...Inputs, clientInputsH20: event.target.value.replace(/,/g, '') }) }}
                                                            onKeyPress={(event) => {
                                                            if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                            else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                                event.preventDefault();
                                                                alert.show('Please enter a valid number.')
                                                            }
                                                            }}  />
                                                    </div>
                                                </td>
                                                <td className='hide-content'>7</td>
                                                <td colSpan={2} className='bg-color-02 padding-0'>
                                                    <div className='input-fiel-cn client-int-new'>
                                                        <input type="text"
                                                        disabled={isLoading}
                                                        {...register("clientInputsJ20")}
                                                        className={`field-ipt font11px bg-color-02 color-dark`}
                                                        value={Inputs.clientInputsJ20.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                        onChange={(event) => setInputs({ ...Inputs, clientInputsJ20: event.target.value.replace(/,/g, '') })} />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='bg-color-02 font11px fontBold color-dark' colSpan={2}>
                                                Total number of un-sold, un-closed prospects from the last two years:
                                                </td>
                                                <td className='bg-color-01 padding-0'>
                                                    <div className="input-fiel-cn bg-color-01 client-inpt-field">
                                                        <input type="text"
                                                            {...register("clientInputsH21")}
                                                            className={`field-ipt font11px bg-color-01 text-right color-dark ${errors.clientInputsH21 ? 'is-invalid' : ''}`}
                                                            //placeholder={0}
                                                            value={Inputs.clientInputsH21.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                            onChange={(event) => { setInputs({ ...Inputs, clientInputsH21: event.target.value.replace(/,/g, '') }) }}
                                                            onKeyPress={(event) => {
                                                            if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                            else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                                event.preventDefault();
                                                                alert.show('Please enter a valid number.')
                                                            }
                                                            }} />
                                                    </div>
                                                </td>
                                                <td className='hide-content'>7</td>
                                                <td colSpan={2} className='bg-color-02 padding-0'>
                                                    <div className='input-fiel-cn client-int-new'>
                                                        <input type="text" 
                                                        disabled={isLoading}
                                                        {...register("clientInputsJ21")}
                                                        className={`field-ipt font11px color-dark bg-color-02`}
                                                        value={Inputs.clientInputsJ21.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                        onChange={(event) => setInputs({ ...Inputs, clientInputsJ21: event.target.value.replace(/,/g, '') })} />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='bg-color-02 font11px fontBold color-dark' colSpan={2}>
                                                Current Customer Attrition Rate (opposite of customer retention):
                                                </td>
                                                <td className='bg-color-01 padding-0'>
                                                    <div className="input-fiel-cn bg-color-01 client-inpt-field">
                                                        <div className="dollor-sin-pr">
                                                            <div className="input-fiel-cn bg-color-01 client-inpt-field">
                                                                <input type="text"
                                                                {...register("clientInputsH22")}
                                                                className={`field-ipt font11px bg-color-01 text-right color-dark ${errors.clientInputsH22 ? 'is-invalid' : ''}`}
                                                                //placeholder={0}
                                                                value={Inputs.clientInputsH22.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                                onChange={(event) => { setInputs({ ...Inputs, clientInputsH22: event.target.value.replace(/,/g, '') }) }}
                                                                onKeyPress={(event) => {
                                                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                                else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                                    event.preventDefault();
                                                                    alert.show('Please enter a valid number.')
                                                                }
                                                                }} />
                                                            </div>
                                                            <div className="perecentage">%</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='hide-content'>7</td>
                                                <td colSpan={2} className='bg-color-02 padding-0'>
                                                    <div className='input-fiel-cn client-int-new'>
                                                        <input type="text"
                                                        disabled={isLoading}
                                                        {...register("clientInputsJ22")}
                                                        className={`field-ipt font11px color-dark bg-color-02`}
                                                        value={Inputs.clientInputsJ22.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                        onChange={(event) => setInputs({ ...Inputs, clientInputsJ22: event.target.value.replace(/,/g, '') })} />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='bg-color-02 font11px color-dark' colSpan={2}>
                                                -  Customers from {previousYear2} who also purchased in {previousYear1}:
                                                </td>
                                                <td className='Blue-gray-bg padding-0'>
                                                    <div className="input-fiel-cn Blue-gray-bg client-inpt-field">
                                                        <input type="text"
                                                        {...register("clientInputsH24")}
                                                        className={`field-ipt font11px Blue-gray-bg text-right text-white ${errors.clientInputsH24 ? 'is-invalid' : ''}`}
                                                        //placeholder={0}
                                                        value={Inputs.clientInputsH24.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                        onChange={(event) => { setInputs({ ...Inputs, clientInputsH24: event.target.value.replace(/,/g, '') }) }}
                                                        onKeyPress={(event) => {
                                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                        else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                        }} />
                                                    </div>
                                                </td>
                                                <td className='hide-content'>7</td>
                                                <td colSpan={2} className='bg-color-02 padding-0'>
                                                    <div className='input-fiel-cn client-int-new'>
                                                        <input type="text"
                                                        disabled={isLoading}
                                                        {...register("clientInputsJ24")}
                                                        className={`field-ipt font11px color-dark bg-color-02`}
                                                        value={Inputs.clientInputsJ24.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                        onChange={(event) => setInputs({ ...Inputs, clientInputsJ24: event.target.value.replace(/,/g, '') })} />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='bg-color-02 font11px fontBold color-dark' colSpan={2}>
                                                Number of Leads Generated last year:
                                                </td>
                                                <td className='bg-color-01 padding-0'>
                                                    <div className="input-fiel-cn bg-color-01 client-inpt-field">
                                                        <input type="text"
                                                        {...register("clientInputsH25")}
                                                        className={`field-ipt font11px bg-color-01 text-right color-dark ${errors.clientInputsH25 ? 'is-invalid' : ''}`}
                                                        //={0}
                                                        value={Inputs.clientInputsH25.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                        onChange={(event) => { setInputs({ ...Inputs, clientInputsH25: event.target.value.replace(/,/g, '') }) }}
                                                        onKeyPress={(event) => {
                                                            if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                            event.preventDefault();
                                                            }
                                                            else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                            }
                                                        }} />
                                                    </div>
                                                </td>
                                                <td className='hide-content'>7</td>
                                                <td colSpan={2} className='bg-color-02 padding-0'>
                                                    <div className='input-fiel-cn client-int-new'>
                                                        <input type="text"
                                                        disabled={isLoading}
                                                        {...register("clientInputsJ25")}
                                                        className={`field-ipt font11px color-dark bg-color-02`}
                                                        value={Inputs.clientInputsJ25.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                        onChange={(event) => setInputs({ ...Inputs, clientInputsJ25: event.target.value.replace(/,/g, '') })} />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='bg-color-02 font11px fontBold color-dark' colSpan={2}>
                                                Average Lead Conversion Rate (closing ratio):
                                                </td>
                                                <td className='bg-color-01 padding-0'>
                                                    <div className="input-fiel-cn bg-color-01 client-inpt-field">
                                                        <div className="dollor-sin-pr">
                                                            <div className="input-fiel-cn bg-color-01 client-inpt-field">
                                                            <input type="text"
                                                            disabled={isLoading}
                                                            {...register("clientInputsH26")}
                                                            className={`field-ipt font11px bg-color-01 text-right color-dark ${errors.clientInputsH26 ? 'is-invalid' : ''}`}
                                                            max="100"
                                                            //placeholder={0}
                                                            value={Inputs.clientInputsH26.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                            onChange={(event) => { setInputs({ ...Inputs, clientInputsH26: event.target.value.replace(/,/g, '') }) }}
                                                            onKeyPress={(event) => {
                                                            if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                            else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                                event.preventDefault();
                                                                alert.show('Please enter a valid number.')
                                                            }
                                                            }} />
                                                            </div>
                                                            <div className="perecentage font11px color-dark">%</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='hide-content'>7</td>
                                                <td colSpan={2} className='bg-color-02 padding-0'>
                                                    <div className='input-fiel-cn client-int-new'>
                                                        <input type="text"
                                                        disabled={isLoading}
                                                        {...register("clientInputsJ26")}
                                                        className={`field-ipt font11px color-dark bg-color-02`}
                                                        value={Inputs.clientInputsJ26.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                        onChange={(event) => setInputs({ ...Inputs, clientInputsJ26: event.target.value.replace(/,/g, '') })}  />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='bg-color-02 font11px fontBold color-dark' colSpan={2}>
                                                Referrals generated and converted to customers last year:
                                                </td>
                                                <td className='bg-color-01 padding-0'>
                                                    <div className="input-fiel-cn bg-color-01 client-inpt-field">
                                                        <input type="text"
                                                        {...register("clientInputsH27")}
                                                        className={`field-ipt font11px bg-color-01 text-right color-dark ${errors.clientInputsH27 ? 'is-invalid' : ''}`}
                                                        //placeholder={0}
                                                        value={Inputs.clientInputsH27.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                        onChange={(event) => { setInputs({ ...Inputs, clientInputsH27: event.target.value.replace(/,/g, '') }) }}
                                                        onKeyPress={(event) => {
                                                            if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                            event.preventDefault();
                                                            }
                                                            else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                            }
                                                        }}  />
                                                    </div>
                                                </td>
                                                <td className='hide-content'>7</td>
                                                <td colSpan={2} className='bg-color-02 padding-0'>
                                                    <div className='input-fiel-cn client-int-new'>
                                                        <input type="text" 
                                                        disabled={isLoading}
                                                        {...register("clientInputsJ27")}
                                                        className={`field-ipt font11px color-dark bg-color-02`}
                                                        value={Inputs.clientInputsJ27.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                        onChange={(event) => setInputs({ ...Inputs, clientInputsJ27: event.target.value.replace(/,/g, '') })}/>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="table-responsive mb-4">
                                    
                                    <table className="table client-input mb-0" style={{width: 1300}}>
                                        <thead>
                                            <tr>
                                                <td className='black-bg text-white font16px text-white' style={{width: 300}}>
                                                Customer Transactions
                                                </td>
                                                <td className='hide-content' style={{width: 250}}></td>
                                                <td className='hide-content' style={{width: 100}}></td>
                                                <td className='hide-content' style={{width: 50}}>7</td>
                                                <td className='black-bg text-white font16px' style={{width: 300}}>Comments</td>
                                                <td className='hide-content' style={{width: 300}}></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='bg-color-02 font11px fontBold color-dark' colSpan={2}>
                                                Number of total transactions in {previousYear1}:
                                                </td>
                                                <td className='bg-color-01 padding-0'>
                                                    <div className="input-fiel-cn bg-color-01 client-inpt-field">
                                                        <input type="text"
                                                        {...register("clientInputsH30")}
                                                        className={`field-ipt font11px bg-color-01 text-right color-dark ${errors.clientInputsH30 ? 'is-invalid' : ''}`}
                                                        //placeholder="0"
                                                        value={Inputs.clientInputsH30.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                        onChange={(event) => { setInputs({ ...Inputs, clientInputsH30: event.target.value.replace(/,/g, '') }) }}
                                                        onKeyPress={(event) => {
                                                            if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                            event.preventDefault();
                                                            }
                                                            else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                            }
                                                        }} />
                                                    </div>
                                                </td>
                                                <td className='hide-content'>7</td>
                                                <td colSpan={2} className='bg-color-02 padding-0'>
                                                    <div className='input-fiel-cn client-int-new'>
                                                        <input type="text"
                                                        disabled={isLoading}
                                                        {...register("clientInputsJ30")}
                                                        className={`field-ipt font11px color-dark bg-color-02`}
                                                        value={Inputs.clientInputsJ30.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                        onChange={(event) => setInputs({ ...Inputs, clientInputsJ30: event.target.value.replace(/,/g, '') })} />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='bg-color-02 font11px color-dark' colSpan={2}>
                                                Number of total transactions in {previousYear2}:
                                                </td>
                                                <td className='Blue-gray-bg padding-0'>
                                                    <div className="input-fiel-cn Blue-gray-bg client-inpt-field">
                                                        <input type="text"
                                                        {...register("clientInputsH31")}
                                                        className={`field-ipt font11px Blue-gray-bg text-right text-white ${errors.clientInputsH31 ? 'is-invalid' : ''}`}
                                                        //placeholder={"0"}
                                                        value={Inputs.clientInputsH31.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                        onChange={(event) => { setInputs({ ...Inputs, clientInputsH31: event.target.value.replace(/,/g, '') }) }}
                                                        onKeyPress={(event) => {
                                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                        else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                        }} />
                                                    </div>
                                                </td>
                                                <td className='hide-content'>7</td>
                                                <td colSpan={2} className='bg-color-02 padding-0'>
                                                    <div className='input-fiel-cn client-int-new'>
                                                        <input type="text"
                                                        disabled={isLoading}
                                                        {...register("clientInputsJ31")}
                                                        className={`field-ipt font11px color-dark bg-color-02`}
                                                        value={Inputs.clientInputsJ31.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                        onChange={(event) => setInputs({ ...Inputs, clientInputsJ31: event.target.value.replace(/,/g, '') })}  />
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td className='bg-color-02 font11px color-dark' colSpan={2}>
                                                Number of total transactions in {previousYear3}:
                                                </td>
                                                <td className='Blue-gray-bg padding-0'>
                                                    <div className="input-fiel-cn Blue-gray-bg color-text-white client-inpt-field">
                                                        <input type="text"
                                                        {...register("clientInputsH32")}
                                                        className={`field-ipt font11px Blue-gray-bg color-text-white text-right text-white ${errors.clientInputsH32 ? 'is-invalid' : ''}`}
                                                        //placeholder={0}
                                                        value={Inputs.clientInputsH32.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                        onChange={(event) => { setInputs({ ...Inputs, clientInputsH32: event.target.value.replace(/,/g, '') }) }}
                                                        onKeyPress={(event) => {
                                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                        else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                        }} />
                                                    </div>
                                                </td>
                                                <td className='hide-content'>7</td>
                                                <td colSpan={2} className='bg-color-02 padding-0'>
                                                    <div className='input-fiel-cn client-int-new'>
                                                        <input type="text"
                                                        disabled={isLoading}
                                                        {...register("clientInputsJ32")}
                                                        className={`field-ipt font11px color-dark bg-color-02`}
                                                        value={Inputs.clientInputsJ32.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                        onChange={(event) => setInputs({ ...Inputs, clientInputsJ32: event.target.value.replace(/,/g, '') })} />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='bg-color-02 font11px fontBold color-dark' colSpan={2}>
                                                Average Transaction Value in {previousYear1}:
                                                </td>
                                                <td className='bg-color-01 padding-0'>
                                                    <div className="dollor-sin-pr">
                                                        <div className="dollor-sin">$</div>
                                                        <div className="input-fiel-cn bg-color-01 client-inpt-field">
                                                            <input type="text"
                                                            {...register("clientInputsH33")}
                                                            className={`field-ipt font11px bg-color-01 text-right color-dark ${errors.clientInputsH33 ? 'is-invalid' : ''}`}
                                                            //placeholder="0"
                                                            value={Inputs.clientInputsH33.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                            onChange={(event) => { setInputs({ ...Inputs, clientInputsH33: event.target.value.replace(/,/g, '') }) }}
                                                            onKeyPress={(event) => {
                                                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                                event.preventDefault();
                                                                }
                                                                else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                                event.preventDefault();
                                                                alert.show('Please enter a valid number.')
                                                                }
                                                            }} />
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='hide-content'>7</td>
                                                <td colSpan={2} className='bg-color-02 padding-0'>
                                                    <div className='input-fiel-cn client-int-new'>
                                                        <input type="text"
                                                        disabled={isLoading}
                                                        {...register("clientInputsJ33")}
                                                        className={`field-ipt font11px color-dark bg-color-02`}
                                                        value={Inputs.clientInputsJ33.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                        onChange={(event) => setInputs({ ...Inputs, clientInputsJ33: event.target.value.replace(/,/g, '') })} />
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="table-responsive mb-4">
                                    
                                    <table className="table client-input mb-0" style={{width: 1300}}>
                                        <thead>
                                            <tr>
                                                <td className='black-bg text-white font16px text-white' style={{width: 300}}>
                                                Gross Profit Margin
                                                </td>
                                                <td className='hide-content' style={{width: 250}}></td>
                                                <td className='hide-content' style={{width: 100}}></td>
                                                <td className='hide-content' style={{width: 50}}>7</td>
                                                <td className='black-bg text-white font16px' style={{width: 300}}>Comments</td>
                                                <td className='hide-content' style={{width: 300}}></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='bg-color-02 font11px fontBold color-dark' colSpan={2}>
                                                Gross Profit Margin in {previousYear1}:
                                                </td>
                                                <td className='bg-color-01 padding-0'>
                                                    <div className="input-fiel-cn bg-color-01 client-inpt-field">
                                                        <div className="dollor-sin-pr">
                                                            <div className="input-fiel-cn bg-color-01 client-inpt-field">
                                                            <input type="text"
                                                            {...register("clientInputsH36")}
                                                            className={`field-ipt font11px bg-color-01 text-right color-dark ${errors.clientInputsH36 ? 'is-invalid' : ''}`}
                                                            max="100"
                                                            //placeholder={0}
                                                            value={Inputs.clientInputsH36.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                            onChange={(event) => { setInputs({ ...Inputs, clientInputsH36: event.target.value.replace(/,/g, '') }) }}
                                                            onKeyPress={(event) => {
                                                            if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                            else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                                event.preventDefault();
                                                                alert.show('Please enter a valid number.')
                                                            }
                                                            }} />
                                                            </div>
                                                            <div className="perecentage font11px color-dark">%</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='hide-content'>7</td>
                                                <td colSpan={2} className='bg-color-02 padding-0'>
                                                    <div className='input-fiel-cn client-int-new'>
                                                        <input type="text"
                                                        disabled={isLoading}
                                                        {...register("clientInputsJ36")}
                                                        className={`field-ipt font11px color-dark bg-color-02`}
                                                        value={Inputs.clientInputsJ36.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                        onChange={(event) => setInputs({ ...Inputs, clientInputsJ36: event.target.value.replace(/,/g, '') })} />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='bg-color-02 font11px color-dark' colSpan={2}>
                                                Gross Profit Margin in {previousYear2}:
                                                </td>
                                                <td className='Blue-gray-bg padding-0'>
                                                    <div className="input-fiel-cn Blue-gray-bg color-text-white client-inpt-field">
                                                        <div className="dollor-sin-pr">
                                                            <div className="input-fiel-cn bg-color-01 client-inpt-field">
                                                            <input type="text"
                                                            {...register("clientInputsH37")}
                                                            className={`field-ipt font11px Blue-gray-bg color-text-white text-right text-white ${errors.clientInputsH37 ? 'is-invalid' : ''}`}
                                                            max="100"
                                                            //placeholder={0}
                                                            value={Inputs.clientInputsH37.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                            onChange={(event) => { setInputs({ ...Inputs, clientInputsH37: event.target.value.replace(/,/g, '') }) }}
                                                            onKeyPress={(event) => {
                                                            if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                            else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                                event.preventDefault();
                                                                alert.show('Please enter a valid number.')
                                                            }
                                                            }} />
                                                            </div>
                                                            <div className="perecentage text-white font11px">%</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='hide-content'>7</td>
                                                <td colSpan={2} className='bg-color-02 padding-0'>
                                                    <div className='input-fiel-cn client-int-new'>
                                                        <input type="text"
                                                        disabled={isLoading}
                                                        {...register("clientInputsJ37")}
                                                        className={`field-ipt font11px color-dark bg-color-02`}
                                                        value={Inputs.clientInputsJ37.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                        onChange={(event) => setInputs({ ...Inputs, clientInputsJ37: event.target.value.replace(/,/g, '') })} />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='bg-color-02 font11px color-dark' colSpan={2}>
                                                Gross Profit Margin in {previousYear3}:
                                                </td>
                                                <td className='Blue-gray-bg padding-0'>
                                                    <div className="input-fiel-cn Blue-gray-bg color-text-white client-inpt-field">
                                                        <div className="dollor-sin-pr">
                                                            <div className="input-fiel-cn bg-color-01 client-inpt-field">
                                                            <input type="text"
                                                            {...register("clientInputsH38")}
                                                            className={`field-ipt font11px Blue-gray-bg color-text-white text-right text-white ${errors.clientInputsH38 ? 'is-invalid' : ''}`}
                                                            max="100"
                                                            //placeholder={0}
                                                            value={Inputs.clientInputsH38.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                            onChange={(event) => { setInputs({ ...Inputs, clientInputsH38: event.target.value.replace(/,/g, '') }) }}
                                                            onKeyPress={(event) => {
                                                            if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                            else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                                event.preventDefault();
                                                                alert.show('Please enter a valid number.')
                                                            }
                                                            }} />
                                                            </div>
                                                            <div className="perecentage text-white font11px ">%</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='hide-content'>7</td>
                                                <td colSpan={2} className='bg-color-02 padding-0'>
                                                    <div className='input-fiel-cn client-int-new'>
                                                        <input type="text"
                                                        disabled={isLoading}
                                                        {...register("clientInputsJ38")}
                                                        className={`field-ipt font11px color-dark bg-color-02`}
                                                        value={Inputs.clientInputsJ38.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                        onChange={(event) => setInputs({ ...Inputs, clientInputsJ38: event.target.value.replace(/,/g, '') })} />
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="table-responsive mb-4">
                                    
                                    <table className="table client-input mb-0" style={{width: 1300}}>
                                        <thead>
                                            <tr>
                                                <td className='black-bg text-white font16px text-white' style={{width: 300}}>
                                                Valuation
                                                </td>
                                                <td className='hide-content' style={{width: 250}}></td>
                                                <td className='hide-content' style={{width: 100}}></td>
                                                <td className='hide-content' style={{width: 50}}>7</td>
                                                <td className='black-bg text-white font16px' style={{width: 300}}>Comments</td>
                                                <td className='hide-content' style={{width: 300}}></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='bg-color-02 font11px fontBold color-dark' colSpan={2}>
                                                Most Current Valuation Multiple
                                                </td>
                                                <td className='padding-0'>
                                                    <div className="input-fiel-cn bg-color-01 client-inpt-field">
                                                        <input type="text"
                                                        {...register("clientInputsH41")}
                                                        className={`field-ipt font11px bg-color-01 text-right color-dark ${errors.clientInputsH41 ? 'is-invalid' : ''}`}
                                                        //placeholder="0"
                                                        value={formatPrice(Inputs.clientInputsH41, 1).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                        onChange={(event) => { setInputs({ ...Inputs, clientInputsH41: event.target.value.replace(/,/g, '') }) }}
                                                        onKeyPress={(event) => {
                                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                        else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                            event.preventDefault();
                                                            alert.show('Please enter a valid number.')
                                                        }
                                                        }} />
                                                    </div>
                                                </td>
                                                <td className='hide-content'>7</td>
                                                <td colSpan={6} className='bg-color-02 padding-0'>
                                                    <div className='input-fiel-cn client-int-new'>
                                                        <input type="text"
                                                        disabled={isLoading}
                                                        {...register("clientInputsJ41")}
                                                        className={`field-ipt font11px color-dark bg-color-02`}
                                                        value={Inputs.clientInputsJ41.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                                        onChange={(event) => setInputs({ ...Inputs, clientInputsJ41: event.target.value.replace(/,/g, '') })} />
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {
                                    isLoading
                                    ?
                                    <button className="btn btn-blue float-end m-2" style={{ pointerEvents: 'none'}}>
                                        <div className="d-flex align-items-center">
                                            <strong>Please wait...</strong>
                                            <div className="spinner-border ml-auto" role="status" aria-hidden="true" />
                                        </div>
                                    </button>
                                    :
                                    <button type="submit" className="btn btn-blue float-end m-2" style={{ backgroundColor: '#0075f2 !important' }}>Save</button>
                                }
                                <div className="nextInput">
                                    <ul>
                                        <li>
                                            <Link to="/diagnose/customer-financial-value" className='next-link'> Next:  Customer Financial Values  
                                                <span className='icon-three'>
                                                    <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                                    <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                                    <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                                </span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ClientInputsContent;