import axios from "axios";
import authHeader from "./auth-header";
import { trackPromise } from 'react-promise-tracker';

const getData = async (userID) => {
    return await trackPromise(
        axios({
            method: "post",
            url: "clientInputs/getClientInputsByUserId",
            data: {user_id:userID},
            headers: authHeader()
        })
    )
};
const postData = (data) => {
    return trackPromise(
        axios({
            method: "post",
            url: "clientInputs/addEditClientInputs",
            data,
            headers: authHeader()
        })
    )
};

const addEditImprovementPercentage = (data) => {
    return trackPromise (
        axios({
            method: "post",
            url: "clientInputs/addEditImprovementPercentage",
            data,
            headers: authHeader()
        })
    )
};

const clientInputService = {
    getData,
    postData,
    addEditImprovementPercentage
};
export default clientInputService;
