import React from 'react';
import { Link, useLocation } from "react-router-dom";
import  UserDropdown  from '../UserDropdown';
import CopyRightText from '../CopyRightText';
import {useSWRConfig} from "swr";
import getMenuChildren from '../../utils/getMenuChildren';
import MenuSingleList from '../List/MenuSingleList';
import MenuMultiList from '../List/MenuMultiList';

const YourGrowthPotentialContent = () => {
    const { fallback, isLoading } = useSWRConfig();
    const location = useLocation();
    const submenus = getMenuChildren(location.pathname, fallback?.menus[0].children)
    return (
        <div className="content-body bg-dard-color bg_bottom">
            <UserDropdown />
            {/* row */}
            <div className="container-fluid">
                <div className="head-title">
                    <Link to="/home"><h3 className='font-sise-20'>The Growth Catalyst</h3></Link>
                    <h1 className='font-sise-42 the-revense'>Your Growth Potential</h1>
                    <div className='row'>
                        <div className="col-12 col-md-8 col-lg-10">
                            <div className="leftContetn">
                                <p className='font-sise-16'>
                                Deep-dive your organization's potential to drive sustained, predictable growth using the RevGrowth OS and the Growth Catalyst and gain key insights into growth opportunities.
                                </p>
                                <ul className='list-of-data'>
                                    <li className='font-sise-16'>Identify your growth potential over your current trend with the RevGrowth OS.</li>
                                    <li className='font-sise-16'>Pinpoint exactly which growth drivers have the greatest impact on your success.</li>
                                    <li className='font-sise-16'>Calculate your Return On Investment in both the short- and long-term.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-2">
                            <div className="imageBx">
                                <img src={window.location.origin+"/images/Home_page_img/Picture6.png"} width={150 + 'px'} height={150 + 'px'} alt="Revenue Growth Drivers" title="Revenue Growth Drivers" />
                            </div>
                        </div>
                    </div>
                    <div className="row card-rwo diagnose-page mt-5" style={{maxWidth: 1400}}>
                        {submenus.map((menu, index) => (
                            <div className={`col-12 col-md-6 ${(menu.children.length < 9)? 'col-lg-3' : ''} mar-b`} key={menu.parent_id+"_"+index}>
                                <div className="diagnosediv prescribe-hg">
                                    <h3 className='font-sise-bold-18'>
                                        <Link to="#">{menu.label}</Link>
                                    </h3>
                                    {
                                        (menu.children.length < 9)?
                                        <MenuSingleList submenu={menu.children} id={menu.parent_id+"_"+index}/>
                                        :
                                        <MenuMultiList submenu={menu.children} id={menu.parent_id+"_"+index}/>
                                    }
                                </div>
                            </div>
                        ))}
                        {/* <div className="col-12 col-md-6 col-lg-3 mar-b">
                            <div className="diagnosediv prescribe-hg">
                                <h3 className='font-sise-bold-18'>
                                    <Link to="#">Your Growth Potential</Link>
                                </h3>
                                <ul className='list-of-data'>
                                    <Link to="/your-growth-potential/growth-potential-overview"><li className='font-sise-16'>Growth Potential Overview</li></Link>
                                    <Link to="/your-growth-potential/creating-growth-momentum"><li className='font-sise-16'>Creating Momentum</li></Link>
                                    <Link to="/your-growth-potential/what-is-driving-growth"><li className='font-sise-16'>What's Driving Growth</li></Link>
                                    <Link to="/your-growth-potential/equity-evolution"><li className='font-sise-16'>Equity Evolution</li></Link>
                                    <Link to="/your-growth-potential/return-on-investment"><li className='font-sise-16'>Return On Investment</li></Link>
                                    <Link to="/your-growth-potential/total-financial-impact"><li className='font-sise-16'>Total Financial Impact</li></Link>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>

                <div className='copyRgight'>
                    <CopyRightText />
                </div>

                <div className='space-di'></div>

            </div>
        </div>
    );
}

export default YourGrowthPotentialContent;
