import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import sum from "../../utils/sum";
import { Link } from 'react-router-dom';
import formatPrice from '../../utils/formatPrice';
import { getAgilityEngineScorecard, modifyAgilityEngineScorecard } from '../../state/features/AgilityEngineScorecardSlice';

export default function AgilityEngineScorecardContentNew() {

    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        AgilityEngineScorecardE8: 0,
        AgilityEngineScorecardD9: 0,
        AgilityEngineScorecardF9: "",
        AgilityEngineScorecardD10: 0,
        AgilityEngineScorecardE11: 0,
        AgilityEngineScorecardF12: "",
        AgilityEngineScorecardD12: 0,
        AgilityEngineScorecardD13: 0,
        AgilityEngineScorecardD14: 0,
        AgilityEngineScorecardD15: 0,
        AgilityEngineScorecardD16: 0,
        AgilityEngineScorecardD17: 0,
        AgilityEngineScorecardD18: 0,
        AgilityEngineScorecardD19: 0,
        AgilityEngineScorecardD20: 0,
        AgilityEngineScorecardD21: 0,
        AgilityEngineScorecardE22: 0,
        AgilityEngineScorecardF23: "",
		AgilityEngineScorecardD23: 0,
		AgilityEngineScorecardD24: 0,
		AgilityEngineScorecardD25: 0,
		AgilityEngineScorecardD26: 0,
		AgilityEngineScorecardD27: 0,
		AgilityEngineScorecardD28: 0,
		AgilityEngineScorecardD29: 0,
        AgilityEngineScorecardE30: 0,
		AgilityEngineScorecardF31: "",
		AgilityEngineScorecardD31: 0,
		AgilityEngineScorecardD32: 0,
		AgilityEngineScorecardD33: 0,
		AgilityEngineScorecardD34: 0,
        AgilityEngineScorecardE36: 0,
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getAgilityEngineScorecard({user_id}))
        .unwrap()
        .then((response) => {
            // console.log("response3",response.data)
            if(response!= undefined  && response.data.clientGrowth){
                let clientGrowth = response.data.clientGrowth[0];
                // console.log(clientGrowth)
                delete clientGrowth.id;
                delete clientGrowth.user_id;
                delete clientGrowth.created_at;
                delete clientGrowth.updated_at;
                const newObj = { ...Inputs }
                for (const key in clientGrowth) {
                    if (clientGrowth.hasOwnProperty(key)) {
                        if(clientGrowth[key])
                        newObj[key] = clientGrowth[key]
                    }
                }
                
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

    }, [dispatch])
    
    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                event.target.value = event.target.value ? event.target.value : 0
                dispatch(modifyAgilityEngineScorecard({ user_id, [event.target.id] : event.target.value, 'AgilityEngineScorecardE8': String(Inputs.AgilityEngineScorecardE8), 'AgilityEngineScorecardE11': String(Inputs.AgilityEngineScorecardE11), 'AgilityEngineScorecardE22': String(Inputs.AgilityEngineScorecardE22), 'AgilityEngineScorecardE30': String(Inputs.AgilityEngineScorecardE30), 'AgilityEngineScorecardE36': String(Inputs.AgilityEngineScorecardE36)}))
                .unwrap()
                .then((response) => {
                    // console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        //window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

    useEffect(() => {

        let AgilityEngineScorecardE8 = sum(Number(Inputs.AgilityEngineScorecardD9), Number(Inputs.AgilityEngineScorecardD10));
        let AgilityEngineScorecardE11 = sum(Number(Inputs.AgilityEngineScorecardD12), Number(Inputs.AgilityEngineScorecardD13), Number(Inputs.AgilityEngineScorecardD14), Number(Inputs.AgilityEngineScorecardD15), Number(Inputs.AgilityEngineScorecardD16), Number(Inputs.AgilityEngineScorecardD17), Number(Inputs.AgilityEngineScorecardD18), Number(Inputs.AgilityEngineScorecardD19), Number(Inputs.AgilityEngineScorecardD20), Number(Inputs.AgilityEngineScorecardD21));
        let AgilityEngineScorecardE22 = sum(Number(Inputs.AgilityEngineScorecardD23), Number(Inputs.AgilityEngineScorecardD24), Number(Inputs.AgilityEngineScorecardD25), Number(Inputs.AgilityEngineScorecardD26), Number(Inputs.AgilityEngineScorecardD27), Number(Inputs.AgilityEngineScorecardD28), Number(Inputs.AgilityEngineScorecardD29));
        let AgilityEngineScorecardE30 = sum(Number(Inputs.AgilityEngineScorecardD31), Number(Inputs.AgilityEngineScorecardD32), Number(Inputs.AgilityEngineScorecardD33), Number(Inputs.AgilityEngineScorecardD34));
        let AgilityEngineScorecardE36 = sum(AgilityEngineScorecardE8, AgilityEngineScorecardE11, AgilityEngineScorecardE22, AgilityEngineScorecardE30);

        setInputs({...Inputs, AgilityEngineScorecardE8, AgilityEngineScorecardE11, AgilityEngineScorecardE22, AgilityEngineScorecardE30, AgilityEngineScorecardE36});

    }, [Inputs.AgilityEngineScorecardD9, Inputs.AgilityEngineScorecardD10, Inputs.AgilityEngineScorecardD12, Inputs.AgilityEngineScorecardD13, Inputs.AgilityEngineScorecardD14, Inputs.AgilityEngineScorecardD15, Inputs.AgilityEngineScorecardD16, Inputs.AgilityEngineScorecardD17, Inputs.AgilityEngineScorecardD18, Inputs.AgilityEngineScorecardD19, Inputs.AgilityEngineScorecardD20, Inputs.AgilityEngineScorecardD21, Inputs.AgilityEngineScorecardD23, Inputs.AgilityEngineScorecardD24, Inputs.AgilityEngineScorecardD25, Inputs.AgilityEngineScorecardD26, Inputs.AgilityEngineScorecardD27, Inputs.AgilityEngineScorecardD28, Inputs.AgilityEngineScorecardD29, Inputs.AgilityEngineScorecardD31, Inputs.AgilityEngineScorecardD32, Inputs.AgilityEngineScorecardD33, Inputs.AgilityEngineScorecardD34]);

    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
            <div className="container-fluid">
                <div className="head-title">
                    <h3 className='preheading'>Diagnose - Benchmark Tools</h3>
                    <h1 className='mainheading mb-1'>Agility Engine Scorecard</h1>
                </div>
                <div className="target-markets-score-card-table">
                    <div className="table-responsive">
                        <table className="table" style={{width: 1200}}>
                            {/* table 01 */}
                            <thead>
                                <tr>
                                    <td className='black-bg text-white'>
                                        <div className="before-your justify-content-between">
                                            <p className='font18px'>Growth Capabilities</p>
                                            <img src={window.location.origin+"/images/agility-engine-scorecard/image417.png"} />
                                        </div>
                                    </td>
                                    <td className='black-bg text-white text-center'>
                                    <p><strong className='scre-fonts font14px'>Score</strong><br/><small className='font10px'>(1=Low; 5=High)</small></p>
                                    </td>
                                    <td className='bg-color-blue text-white text-center'>
                                        <div className="do-you-number font146x">{formatPrice(Inputs.AgilityEngineScorecardE8, 0)}</div>
                                    </td>
                                    <td className='black-bg text-white text-center'>
                                        <div className="opportunity-improve">
                                            <p className='font18px'>Opportunities to Improve</p>
                                        </div>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='bg-color-light-blue color-black font11px color-dark'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>Employees are encouraged and rewarded to innovate and improve company quality, service, productivity, processes, and results.</p>
                                        </div>
                                    </td>
                                    <td className='bg-color-01 padding-0'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="AgilityEngineScorecardD9" 
                                                value={Inputs.AgilityEngineScorecardD9} 
                                                onChange={(event) => setInputs({ ...Inputs, AgilityEngineScorecardD9: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td rowSpan={2} className='bg-white padding-0'>
                                        <div className="arrow-targer-market"></div>
                                    </td>
                                    <td rowSpan={2} className='bg-color-02 padding-0'>
                                        <div className="input-fiel-cn">
                                            <textarea type="text" className="field-ipt bg-color-02 font11px color-dark"
                                            id="AgilityEngineScorecardF9"
                                            value={Inputs.AgilityEngineScorecardF9}
                                            onChange={(event) => setInputs({ ...Inputs, AgilityEngineScorecardF9: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue color-black font11px color-dark'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>We test new tactics and activities on a regular basis, searching for ways to improve.</p>
                                        </div>
                                    </td>
                                    <td className='bg-color-01 padding-0'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="AgilityEngineScorecardD10" 
                                                value={Inputs.AgilityEngineScorecardD10} 
                                                onChange={(event) => setInputs({ ...Inputs, AgilityEngineScorecardD10: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>

                            <thead>
                                <tr>
                                    <td className='black-bg text-white'>
                                        <div className="before-your justify-content-between">
                                            <p className='font18px'>Growth Leadership</p>
                                            <img src={window.location.origin+"/images/agility-engine-scorecard/image418.png"} />
                                        </div>
                                    </td>
                                    <td className='black-bg text-white text-center'>
                                    <p><strong className='scre-fonts font14px'>Score</strong><br/><small className='font10px'>(1=Low; 5=High)</small></p>
                                    </td>
                                    <td className='bg-color-blue text-white text-center'>
                                        <div className="do-you-number font146x">{formatPrice(Inputs.AgilityEngineScorecardE11, 0)}</div>
                                    </td>
                                    <td className='black-bg text-white text-center'>
                                        <div className="opportunity-improve">
                                            <p className='font18px'>Opportunities to Improve</p>
                                        </div>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='bg-color-light-blue color-black font11px color-dark'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>The vision for growth and change is clear and understood by the entire leadership team</p>
                                        </div>
                                    </td>
                                    <td className='bg-color-01 padding-0'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="AgilityEngineScorecardD12" 
                                                value={Inputs.AgilityEngineScorecardD12} 
                                                onChange={(event) => setInputs({ ...Inputs, AgilityEngineScorecardD12: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td rowSpan={10} className='bg-white padding-0'>
                                        <div className="arrow-targer-market"></div>
                                    </td>
                                    <td rowSpan={10} className='bg-color-02 padding-0'>
                                        <div className="input-fiel-cn">
                                            <textarea type="text" className="field-ipt bg-color-02 font11px color-dark"
                                            id="AgilityEngineScorecardF12"
                                            value={Inputs.AgilityEngineScorecardF12}
                                            onChange={(event) => setInputs({ ...Inputs, AgilityEngineScorecardF12: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue color-black font11px color-dark'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>The leadership team is unified and engaged around a clear set of priorities to achieve this vision </p>
                                        </div>
                                    </td>
                                    <td className='bg-color-01 padding-0'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="AgilityEngineScorecardD13" 
                                                value={Inputs.AgilityEngineScorecardD13} 
                                                onChange={(event) => setInputs({ ...Inputs, AgilityEngineScorecardD13: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue color-black font11px color-dark'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>We regularly set and monitor growth targets for our teams to work toward</p>
                                        </div>
                                    </td>
                                    <td className='bg-color-01 padding-0'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="AgilityEngineScorecardD14" 
                                                value={Inputs.AgilityEngineScorecardD14} 
                                                onChange={(event) => setInputs({ ...Inputs, AgilityEngineScorecardD14: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue color-black font11px color-dark'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>We implement activities that best align with the overall strategy, revenue models, that are easy to implement, and have the potential to create excellent ROI.</p>
                                        </div>
                                    </td>
                                    <td className='bg-color-01 padding-0'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="AgilityEngineScorecardD15" 
                                                value={Inputs.AgilityEngineScorecardD15} 
                                                onChange={(event) => setInputs({ ...Inputs, AgilityEngineScorecardD15: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue color-black font11px color-dark'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>The organization provides resources (time, money, personnel, support, etc) earmarked specifically for strategic planning.</p>
                                        </div>
                                    </td>
                                    <td className='bg-color-01 padding-0'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="AgilityEngineScorecardD16" 
                                                value={Inputs.AgilityEngineScorecardD16} 
                                                onChange={(event) => setInputs({ ...Inputs, AgilityEngineScorecardD16: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue color-black font11px color-dark'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>We are always moving forward toward our goals, even if it's not as fast as we want.</p>
                                        </div>
                                    </td>
                                    <td className='bg-color-01 padding-0'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="AgilityEngineScorecardD17" 
                                                value={Inputs.AgilityEngineScorecardD17} 
                                                onChange={(event) => setInputs({ ...Inputs, AgilityEngineScorecardD17: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue color-black font11px color-dark'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>Follow a comprehensive, repeatable set of procedures in our strategic planning process.</p>
                                        </div>
                                    </td>
                                    <td className='bg-color-01 padding-0'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="AgilityEngineScorecardD18" 
                                                value={Inputs.AgilityEngineScorecardD18} 
                                                onChange={(event) => setInputs({ ...Inputs, AgilityEngineScorecardD18: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue color-black font11px color-dark'>
                                        <div className="we-understand">
                                            {/* <p className='font11px color-dark'>You create a work environment and incentive program that motivates employees to innovate and improve quality, productivity, systems, and procedures.</p> */}
                                            <p className='font11px color-dark'>We create a work environment and incentive program that motivates employees to innovate and improve quality, productivity, systems, and procedures.</p>
                                        </div>
                                    </td>
                                    <td className='bg-color-01 padding-0'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="AgilityEngineScorecardD19" 
                                                value={Inputs.AgilityEngineScorecardD19} 
                                                onChange={(event) => setInputs({ ...Inputs, AgilityEngineScorecardD19: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue color-black font11px color-dark'>
                                        <div className="we-understand">
                                            {/* <p className='font11px color-dark'>You update your strategic plan on a regular basis as new data becomes available</p> */}
                                            <p className='font11px color-dark'>We update our strategic plan on a regular basis as new data becomes available.</p>
                                        </div>
                                    </td>
                                    <td className='bg-color-01 padding-0'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="AgilityEngineScorecardD20" 
                                                value={Inputs.AgilityEngineScorecardD20} 
                                                onChange={(event) => setInputs({ ...Inputs, AgilityEngineScorecardD20: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue color-black font11px color-dark'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>They effectively prioritize the most important projects and tasks, working on them in that order</p>
                                        </div>
                                    </td>
                                    <td className='bg-color-01 padding-0'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="AgilityEngineScorecardD21" 
                                                value={Inputs.AgilityEngineScorecardD21} 
                                                onChange={(event) => setInputs({ ...Inputs, AgilityEngineScorecardD21: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>

                            <thead>
                                <tr>
                                    <td className='black-bg text-white'>
                                        <div className="before-your justify-content-between">
                                            <p className='font18px'>Growth Culture</p>
                                            <img src={window.location.origin+"/images/agility-engine-scorecard/image419.png"} />
                                        </div>
                                    </td>
                                    <td className='black-bg text-white text-center'>
                                    <p><strong className='scre-fonts font14px'>Score</strong><br/><small className='font10px'>(1=Low; 5=High)</small></p>
                                    </td>
                                    <td className='bg-color-blue text-white text-center'>
                                        <div className="do-you-number font146x">{formatPrice(Inputs.AgilityEngineScorecardE22, 0)}</div>
                                    </td>
                                    <td className='black-bg text-white text-center'>
                                        <div className="opportunity-improve">
                                            <p className='font18px'>Opportunities to Improve</p>
                                        </div>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='bg-color-light-blue color-black font11px color-dark'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>We are committed to growth and creating opportunities to grow.</p>
                                        </div>
                                    </td>
                                    <td className='bg-color-01 padding-0'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="AgilityEngineScorecardD23" 
                                                value={Inputs.AgilityEngineScorecardD23} 
                                                onChange={(event) => setInputs({ ...Inputs, AgilityEngineScorecardD23: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td rowSpan={7} className='bg-white padding-0'>
                                        <div className="arrow-targer-market"></div>
                                    </td>
                                    <td rowSpan={7} className='bg-color-02 padding-0'>
                                        <div className="input-fiel-cn">
                                            <textarea type="text" className="field-ipt bg-color-02 font11px color-dark"
                                            id="AgilityEngineScorecardF23"
                                            value={Inputs.AgilityEngineScorecardF23}
                                            onChange={(event) => setInputs({ ...Inputs, AgilityEngineScorecardF23: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue color-black font11px color-dark'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>We are always striving to create competitive advantage in our marketplace.</p>
                                        </div>
                                    </td>
                                    <td className='bg-color-01 padding-0'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="AgilityEngineScorecardD24" 
                                                value={Inputs.AgilityEngineScorecardD24} 
                                                onChange={(event) => setInputs({ ...Inputs, AgilityEngineScorecardD24: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue color-black font11px color-dark'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>Our employees understand how they personally fit into the company's plan to grow and are motivated to contribute.</p>
                                        </div>
                                    </td>
                                    <td className='bg-color-01 padding-0'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="AgilityEngineScorecardD25" 
                                                value={Inputs.AgilityEngineScorecardD25} 
                                                onChange={(event) => setInputs({ ...Inputs, AgilityEngineScorecardD25: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue color-black font11px color-dark'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>We know smart thinking, effort, and attitude determine our outcomes.</p>
                                        </div>
                                    </td>
                                    <td className='bg-color-01 padding-0'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="AgilityEngineScorecardD26" 
                                                value={Inputs.AgilityEngineScorecardD26} 
                                                onChange={(event) => setInputs({ ...Inputs, AgilityEngineScorecardD26: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue color-black font11px color-dark'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>All employees understand our company vision, mission, goals, the reasons behind them, and our basic strategy.</p>
                                        </div>
                                    </td>
                                    <td className='bg-color-01 padding-0'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="AgilityEngineScorecardD27" 
                                                value={Inputs.AgilityEngineScorecardD27} 
                                                onChange={(event) => setInputs({ ...Inputs, AgilityEngineScorecardD27: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue color-black font11px color-dark'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>All employees understand the unique value we provide to our market</p>
                                        </div>
                                    </td>
                                    <td className='bg-color-01 padding-0'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="AgilityEngineScorecardD28" 
                                                value={Inputs.AgilityEngineScorecardD28} 
                                                onChange={(event) => setInputs({ ...Inputs, AgilityEngineScorecardD28: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue color-black font11px color-dark'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>We are inspired by the success of others on our team.</p>
                                        </div>
                                    </td>
                                    <td className='bg-color-01 padding-0'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="AgilityEngineScorecardD29" 
                                                value={Inputs.AgilityEngineScorecardD29} 
                                                onChange={(event) => setInputs({ ...Inputs, AgilityEngineScorecardD29: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>

                            <thead>
                                <tr>
                                    <td className='black-bg text-white'>
                                        <div className="before-your justify-content-between">
                                            <p className='font18px'>Agility</p>
                                            <img src={window.location.origin+"/images/agility-engine-scorecard/image426.png"} />
                                        </div>
                                    </td>
                                    <td className='black-bg text-white text-center'>
                                    <p><strong className='scre-fonts font14px'>Score</strong><br/><small className='font10px'>(1=Low; 5=High)</small></p>
                                    </td>
                                    <td className='bg-color-blue text-white text-center'>
                                        <div className="do-you-number font146x">{formatPrice(Inputs.AgilityEngineScorecardE30, 0)}</div>
                                    </td>
                                    <td className='black-bg text-white text-center'>
                                        <div className="opportunity-improve">
                                            <p className='font18px'>Opportunities to Improve</p>
                                        </div>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='bg-color-light-blue color-black font11px color-dark'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>We strive to be the most agile company in our industry.</p>
                                        </div>
                                    </td>
                                    <td className='bg-color-01 padding-0'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="AgilityEngineScorecardD31" 
                                                value={Inputs.AgilityEngineScorecardD31} 
                                                onChange={(event) => setInputs({ ...Inputs, AgilityEngineScorecardD31: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td rowSpan={4} className='bg-white padding-0'>
                                        <div className="arrow-targer-market"></div>
                                    </td>
                                    <td rowSpan={4} className='bg-color-02 padding-0'>
                                        <div className="input-fiel-cn">
                                            <textarea type="text" className="field-ipt bg-color-02 font11px color-dark"
                                            id="AgilityEngineScorecardF31"
                                            value={Inputs.AgilityEngineScorecardF31}
                                            onChange={(event) => setInputs({ ...Inputs, AgilityEngineScorecardF31: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue color-black font11px color-dark'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>Continuous adaptation and improvement is part of our culture.</p>
                                        </div>
                                    </td>
                                    <td className='bg-color-01 padding-0'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="AgilityEngineScorecardD32" 
                                                value={Inputs.AgilityEngineScorecardD32} 
                                                onChange={(event) => setInputs({ ...Inputs, AgilityEngineScorecardD32: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue color-black font11px color-dark'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>We have captured in writing the best practices of sales, marketing, and customer service so they don't leave the company if a critical employee leaves.</p>
                                        </div>
                                    </td>
                                    <td className='bg-color-01 padding-0'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="AgilityEngineScorecardD33" 
                                                value={Inputs.AgilityEngineScorecardD33} 
                                                onChange={(event) => setInputs({ ...Inputs, AgilityEngineScorecardD33: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue color-black font11px color-dark'>
                                        <div className="we-understand">
                                            <p className='font11px color-dark'>We are constantly learning and improving our performance.</p>
                                        </div>
                                    </td>
                                    <td className='bg-color-01 padding-0'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="AgilityEngineScorecardD34" 
                                                value={Inputs.AgilityEngineScorecardD34} 
                                                onChange={(event) => setInputs({ ...Inputs, AgilityEngineScorecardD34: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <thead>
                                <tr>
                                    <th className='bg-white'>
                                        <div className="nextInput">
                                            <ul>
                                                <li>
                                                    <Link className="next-link" to="/diagnose/agility"> Return to Agility
                                                        <span className="icon-three">
                                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                                        </span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </th>
                                    <th className='black-bg text-white'>
                                        <div className="score-point-each">
                                            <p><strong className='scre-fonts fontExtrBold font16px'>Total Score</strong></p>
                                        </div>
                                    </th>
                                    <th className='bg-color-blue text-white text-center'>
                                        <div className="do-you-number font16px">{formatPrice(Inputs.AgilityEngineScorecardE36, 0)}</div>
                                    </th>
                                    <th className='hide-content'>
                                        <div className="opportunity-improve">
                                            <p>Opportunities to Improve</p>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}