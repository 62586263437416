import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import { getFocusedMeetingPlanner, modifyFocusedMeetingPlanner } from '../../state/features/FocusedMeetingPlannerSlice';

export default function FocusedMeetingPlannerContent() {

    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;

    const [Inputs, setInputs] = useState({
        FocusedMeetingPlannerE71: "",
        FocusedMeetingPlannerE72: "",
        FocusedMeetingPlannerE73: "",
        FocusedMeetingPlannerE8: "",
        FocusedMeetingPlannerE11: "",
        FocusedMeetingPlannerE12: "",
        FocusedMeetingPlannerE13: "",
        FocusedMeetingPlannerE14: "",
        FocusedMeetingPlannerE15: "",
        FocusedMeetingPlannerE16: "",
        FocusedMeetingPlannerE17: "",
        FocusedMeetingPlannerE18: "",
        FocusedMeetingPlannerE19: "",
        FocusedMeetingPlannerE20: "",
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getFocusedMeetingPlanner({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.growthPlanning)
            if(response!= undefined  && response.data.growthPlanning){
                let growthPlanning = response.data.growthPlanning[0];
                // console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key]
                    }
                }
                
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyFocusedMeetingPlanner({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                    // console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        //window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
            <div className="container-fluid">
                <div className="head-title">
                    <h3 className='preheading'>Grow</h3>
                    <h1 className='mainheading mb-1'>Focused Meeting Planner</h1>
                </div>
                <div className="customer-transformation">
                    <div className="customer-transformation-inner">
                        <Spinner/>
                        <div className="table-responsive mb-3">
                            <table className='table' style={{width: 1300}}>
                                <thead>
                                    <tr>
                                        <td className='black-bg text-white ms-text-left font16px' colSpan={2}>
                                        Meeting Announcement Script
                                        </td>
                                        <td className='bg-color-02 color-dark text-left font12px'>
                                        The purpose of this meeting is to inform you about __________, to discuss __________, and to decide on __________.
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='bg-color-blue-gray text-white ms-text-left font16px' colSpan={2}>
                                        Write Your Script Here
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01">
                                                <textarea className="field-ipt bg-color-01 font11px color-dark"
                                                id="FocusedMeetingPlannerE8"
                                                value={Inputs.FocusedMeetingPlannerE8}
                                                onChange={(event) => setInputs({ ...Inputs, FocusedMeetingPlannerE8: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content' colSpan={3}></td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white ms-text-left font16px' style={{width: 200}}>
                                            Purpose
                                        </td>
                                        <td className='black-bg text-white ms-text-left font16px' style={{width: 300}}>
                                            Description
                                        </td>
                                        <td className='black-bg text-white ms-text-left font16px' style={{width: 800}}>
                                            Meeting Preparation Notes
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-left black-bg' style={{height: 150}}>
                                            <h3 className="font16px text-white fontLight">Information Sharing</h3>
                                        </td>
                                        <td className='bg-color-blue-gray text-left text-white p-2'>
                                            <div className="font11px text-white fontLight">Typically to align and inform team or stakeholders.  Mostly one way communication with minimal discussion and interaction.  </div>
                                        </td>
                                        <td className='bg-color-02 padding-0'>
                                            <div className="input-fiel-cn bg-color-02">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark w-100"
                                                id="FocusedMeetingPlannerE11"
                                                value={Inputs.FocusedMeetingPlannerE11}
                                                onChange={(event) => setInputs({ ...Inputs, FocusedMeetingPlannerE11: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-left black-bg' style={{height: 150}}>
                                            <h3 className="font16px text-white fontLight">Discussion</h3>
                                        </td>
                                        <td className='bg-color-blue-gray text-left text-white p-2'>
                                            <div className="font11px text-white fontLight">Identify option for decision makers to consider.  Must include people with the right knowledge and experience to influence discussion.  More effective in smaller groups.</div>
                                        </td>
                                        <td className='bg-color-02 padding-0'>
                                            <div className="input-fiel-cn bg-color-02">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark w-100"
                                                id="FocusedMeetingPlannerE12"
                                                value={Inputs.FocusedMeetingPlannerE12}
                                                onChange={(event) => setInputs({ ...Inputs, FocusedMeetingPlannerE12: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-left black-bg' style={{height: 150}}>
                                            <h3 className="font16px text-white fontLight">Decision Making</h3>
                                        </td>
                                        <td className='bg-color-blue-gray text-left text-white p-2'>
                                            <div className="font11px text-white fontLight">Must include decision makers and specify the decision to be made.  Don't let it become a discussion meeting.</div>
                                        </td>
                                        <td className='bg-color-02 padding-0'>
                                            <div className="input-fiel-cn bg-color-02">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark w-100"
                                                id="FocusedMeetingPlannerE13"
                                                value={Inputs.FocusedMeetingPlannerE13}
                                                onChange={(event) => setInputs({ ...Inputs, FocusedMeetingPlannerE13: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="table-responsive">
                            <table className='table' style={{width: 1300}}>
                                <thead>
                                    <tr>
                                        <td className='black-bg text-white ms-text-left font16px p-2' style={{width: 200}}>
                                        SMART Meeting Preparation
                                        </td>
                                        <td className='black-bg text-white ms-text-left font16px p-2' style={{width: 300}}>
                                            Description
                                        </td>
                                        <td className='black-bg text-white ms-text-left font16px p-2' style={{width: 800}}>
                                            Meeting Preparation Notes
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='black-bg' style={{height: 150}}>
                                            <h1 className="text-center text-white font48px fontExtraBold">S</h1>
                                        </td>
                                        <td className='bg-color-blue-gray text-left text-white p-2'>
                                            <div className="font11px text-white fontLight">State exactly what you want to accomplish with the meeting  (Who, What, Where, Why, How).</div>
                                        </td>
                                        <td className='bg-color-02 padding-0'>
                                            <div className="input-fiel-cn bg-color-02">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark w-100"
                                                id="FocusedMeetingPlannerE16"
                                                value={Inputs.FocusedMeetingPlannerE16}
                                                onChange={(event) => setInputs({ ...Inputs, FocusedMeetingPlannerE16: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg' style={{height: 150}}>
                                            <h1 className="text-center text-white font48px fontExtraBold">M</h1>
                                        </td>
                                        <td className='bg-color-blue-gray text-left text-white p-2'>
                                            <div className="font11px text-white fontLight">What is the metric that will demonstrate the extent to which the goals have been met?</div>
                                        </td>
                                        <td className='bg-color-02 padding-0'>
                                            <div className="input-fiel-cn bg-color-02">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark w-100"
                                                id="FocusedMeetingPlannerE17"
                                                value={Inputs.FocusedMeetingPlannerE17}
                                                onChange={(event) => setInputs({ ...Inputs, FocusedMeetingPlannerE17: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg' style={{height: 150}}>
                                            <h1 className="text-center text-white font48px fontExtraBold">A</h1>
                                        </td>
                                        <td className='bg-color-blue-gray text-left text-white p-2'>
                                            <div className="font11px text-white fontLight">Is the goal within your ability to achieve?</div>
                                        </td>
                                        <td className='bg-color-02 padding-0'>
                                            <div className="input-fiel-cn bg-color-02">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark w-100"
                                                id="FocusedMeetingPlannerE18"
                                                value={Inputs.FocusedMeetingPlannerE18}
                                                onChange={(event) => setInputs({ ...Inputs, FocusedMeetingPlannerE18: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg' style={{height: 150}}>
                                            <h1 className="text-center text-white font48px fontExtraBold">R</h1>
                                        </td>
                                        <td className='bg-color-blue-gray text-left text-white p-2'>
                                            <div className="font11px text-white fontLight">How does the goal tie into your responsibilities?  Is it relevant to your priorities?</div>
                                        </td>
                                        <td className='bg-color-02 padding-0'>
                                            <div className="input-fiel-cn bg-color-02">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark w-100"
                                                id="FocusedMeetingPlannerE19"
                                                value={Inputs.FocusedMeetingPlannerE19}
                                                onChange={(event) => setInputs({ ...Inputs, FocusedMeetingPlannerE19: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg' style={{height: 150}}>
                                            <h1 className="text-center text-white font48px fontExtraBold">T</h1>
                                        </td>
                                        <td className='bg-color-blue-gray text-left text-white p-2'>
                                            <div className="font11px text-white fontLight">By when will the goal be achieved?  What are the deadlines?</div>
                                        </td>
                                        <td className='bg-color-02 padding-0'>
                                            <div className="input-fiel-cn bg-color-02">
                                                <textarea className="field-ipt bg-color-02 font11px color-dark w-100"
                                                id="FocusedMeetingPlannerE20"
                                                value={Inputs.FocusedMeetingPlannerE20}
                                                onChange={(event) => setInputs({ ...Inputs, FocusedMeetingPlannerE20: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}