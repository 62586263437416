import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getGrowthProgressReport, modifyGrowthProgressReport } from "../../state/features/GrowthProgressReportSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';

export default function GrowthProgressReportContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        GrowthProgressReportC14 : '',
        GrowthProgressReportC26 : '',
        GrowthProgressReportD14 : '',
        GrowthProgressReportD26 : '',
        GrowthProgressReportE14 : '',
        GrowthProgressReportE26 : '',
        GrowthProgressReportF14 : '',
        GrowthProgressReportF26 : ''
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getGrowthProgressReport({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.growthPlanning)
            if(response!= undefined){
                let growthPlanning = response.data.growthPlanning[0];
                console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key]
                    }
                }
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyGrowthProgressReport({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

  return (
    <div className="content-body">
    <UserDropdown />
    
        {/* row */}
        <div className="container-fluid">
            <div className="head-title">
                <h2>Growth Progress Report</h2>	            					
            </div> 
            <Spinner />
            <div className="card">
                <div className="card-body pl-0">
                <div className="table-responsive">
                    <table className="table table-responsive">
                    <thead>
                        <tr className="thead-primary">
                        <th>Plan</th>
                        <th>Optimism</th>
                        <th>Momentum</th>
                        <th>Motivation</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td className="bgradiusf">Forging Your Future</td>
                        <td className="bgradiusf">Taking Pride in Achievements</td>
                        <td className="bgradiusf">Building Confidance</td>
                        <td className="bgradiusf">Looking Farward</td>
                        </tr>
                        <tr>
                        <td>What are the strategic goals you would like to achieve over the next Quarter and years?</td>
                        <td>What you have achievedin the past quarter that makes you proud?</td>
                        <td>What areas the focus, progress or performance make you the most confident?</td>
                        <td>What new opportunities or strategies give you the gretest sence of excitement?</td>
                        </tr>
                        <tr>
                        <td style={{height: 150}}><textarea className="from-control inputText" 
                            rows={8} 
                            cols={24} 
                            id="GrowthProgressReportC14" 
                            value={Inputs.GrowthProgressReportC14} 
                            onChange={(event) => setInputs({ ...Inputs, GrowthProgressReportC14: event.target.value }) } 
                            onBlur={(event) => { onBlurHandler(event) }} 
                            disabled={isLoading}/></td>
                        <td style={{height: 150}}><textarea className="from-control inputText" 
                            rows={8} 
                            cols={24} 
                            id="GrowthProgressReportD14" 
                            value={Inputs.GrowthProgressReportD14} 
                            onChange={(event) => setInputs({ ...Inputs, GrowthProgressReportD14: event.target.value }) } 
                            onBlur={(event) => { onBlurHandler(event) }} 
                            disabled={isLoading}/></td>
                        <td style={{height: 150}}><textarea className="from-control inputText" 
                            rows={8} 
                            cols={24} 
                            id="GrowthProgressReportE14" 
                            value={Inputs.GrowthProgressReportE14} 
                            onChange={(event) => setInputs({ ...Inputs, GrowthProgressReportE14: event.target.value }) } 
                            onBlur={(event) => { onBlurHandler(event) }} 
                            disabled={isLoading}/></td>
                        <td style={{height: 150}}><textarea className="from-control inputText" 
                            rows={8} 
                            cols={24} 
                            id="GrowthProgressReportF14" 
                            value={Inputs.GrowthProgressReportF14} 
                            onChange={(event) => setInputs({ ...Inputs, GrowthProgressReportF14: event.target.value }) } 
                            onBlur={(event) => { onBlurHandler(event) }} 
                            disabled={isLoading}/></td>
                        </tr>
                        <tr className="thead-primary">
                        <th>Achievement 1</th>
                        <th>Achievement 2</th>
                        <th>Achievement 3</th>
                        <th>Achievement 4</th>
                        </tr>
                        <tr>
                        <td style={{height: 100}}><textarea className="from-control inputText" 
                            rows={6} 
                            cols={24} 
                            id="GrowthProgressReportC26" 
                            value={Inputs.GrowthProgressReportC26} 
                            onChange={(event) => setInputs({ ...Inputs, GrowthProgressReportC26: event.target.value }) } 
                            onBlur={(event) => { onBlurHandler(event) }} 
                            disabled={isLoading}/></td>
                        <td style={{height: 100}}><textarea className="from-control inputText" 
                            rows={6} 
                            cols={24} 
                            id="GrowthProgressReportD26" 
                            value={Inputs.GrowthProgressReportD26} 
                            onChange={(event) => setInputs({ ...Inputs, GrowthProgressReportD26: event.target.value }) } 
                            onBlur={(event) => { onBlurHandler(event) }} 
                            disabled={isLoading}/></td>
                        <td style={{height: 100}}><textarea className="from-control inputText" 
                            rows={6} 
                            cols={24} 
                            id="GrowthProgressReportE26" 
                            value={Inputs.GrowthProgressReportE26} 
                            onChange={(event) => setInputs({ ...Inputs, GrowthProgressReportE26: event.target.value }) } 
                            onBlur={(event) => { onBlurHandler(event) }} 
                            disabled={isLoading}/></td>
                        <td style={{height: 100}}><textarea className="from-control inputText" 
                            rows={6} 
                            cols={24} 
                            id="GrowthProgressReportF26" 
                            value={Inputs.GrowthProgressReportF26} 
                            onChange={(event) => setInputs({ ...Inputs, GrowthProgressReportF26: event.target.value }) } 
                            onBlur={(event) => { onBlurHandler(event) }} 
                            disabled={isLoading}/></td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>                             	
            </div>

    </div>
  )
}
