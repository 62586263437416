import React from 'react'
import SidebarNew from '../components/SidebarNew';
import Content from '../components/content/HowItWorksContent';

export default function HowItWorks() {
  return (
    <div>
      <div id="main-wrapper">

        <SidebarNew />

        <Content />

      </div>
    </div>
  )
}
