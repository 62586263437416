const initialState = {
    
}
const reducer = (state = initialState, action) => {
    if(action.type === "equityEvolutionTheBigBonusReducerAllStats") {
        
        let clientInputsD11 = action.payload.clientInputsD11
        let clientInputsH36 = action.payload.clientInputsH36
        let clientInputsH41 = action.payload.clientInputsH41
        let impactof120GrowthOSG36 = action.payload.impactof120GrowthOSG36
        let impactof120GrowthOSH36 = action.payload.impactof120GrowthOSH36
        let impactof120GrowthOSI36 = action.payload.impactof120GrowthOSI36
        let impactof120GrowthOSJ36 = action.payload.impactof120GrowthOSJ36
        let impactof120GrowthOSK36 = action.payload.impactof120GrowthOSK36  

        let equityEvolutionTheBigBonusD25 = parseFloat(clientInputsH41).toFixed(1);
        let equityEvolutionTheBigBonusD24 = (parseFloat(equityEvolutionTheBigBonusD25) - 2).toFixed(1);
        let equityEvolutionTheBigBonusD26 = (parseFloat(equityEvolutionTheBigBonusD25) + 2).toFixed(1);
        let equityEvolutionTheBigBonusD27 = (parseFloat(equityEvolutionTheBigBonusD25) + 4).toFixed(1);

        let equityEvolutionTheBigBonusE24 = (parseFloat(clientInputsD11) * parseFloat(clientInputsH36)/100).toFixed(2);
        let equityEvolutionTheBigBonusE25 = (parseFloat(clientInputsD11) * parseFloat(clientInputsH36)/100).toFixed(2);
        let equityEvolutionTheBigBonusE26 = (parseFloat(clientInputsD11) * parseFloat(clientInputsH36)/100).toFixed(2);
        let equityEvolutionTheBigBonusE27 = (parseFloat(clientInputsD11) * parseFloat(clientInputsH36)/100).toFixed(2);

        let equityEvolutionTheBigBonusF24 = (parseFloat(equityEvolutionTheBigBonusE24) * parseFloat(equityEvolutionTheBigBonusD24)).toFixed(2);
        let equityEvolutionTheBigBonusF25 = (parseFloat(equityEvolutionTheBigBonusE25) * parseFloat(equityEvolutionTheBigBonusD25)).toFixed(2);
        let equityEvolutionTheBigBonusF26 = (parseFloat(equityEvolutionTheBigBonusE26) * parseFloat(equityEvolutionTheBigBonusD26)).toFixed(2);
        let equityEvolutionTheBigBonusF27 = (parseFloat(equityEvolutionTheBigBonusE27) * parseFloat(equityEvolutionTheBigBonusD27)).toFixed(2);

        let equityEvolutionTheBigBonusG24 = (parseFloat(equityEvolutionTheBigBonusD24) * parseFloat(impactof120GrowthOSG36)).toFixed(2);
        let equityEvolutionTheBigBonusG25 = (parseFloat(equityEvolutionTheBigBonusD25) * parseFloat(impactof120GrowthOSG36)).toFixed(2);
        let equityEvolutionTheBigBonusG26 = (parseFloat(equityEvolutionTheBigBonusD26) * parseFloat(impactof120GrowthOSG36)).toFixed(2);
        let equityEvolutionTheBigBonusG27 = (parseFloat(equityEvolutionTheBigBonusD27) * parseFloat(impactof120GrowthOSG36)).toFixed(2);

        let equityEvolutionTheBigBonusH24 = (parseFloat(equityEvolutionTheBigBonusD24) * parseFloat(impactof120GrowthOSH36)).toFixed(2);
        let equityEvolutionTheBigBonusH25 = (parseFloat(equityEvolutionTheBigBonusD25) * parseFloat(impactof120GrowthOSH36)).toFixed(2);
        let equityEvolutionTheBigBonusH26 = (parseFloat(equityEvolutionTheBigBonusD26) * parseFloat(impactof120GrowthOSH36)).toFixed(2);
        let equityEvolutionTheBigBonusH27 = (parseFloat(equityEvolutionTheBigBonusD27) * parseFloat(impactof120GrowthOSH36)).toFixed(2);

        let equityEvolutionTheBigBonusI24 = (parseFloat(equityEvolutionTheBigBonusD24) * parseFloat(impactof120GrowthOSI36)).toFixed(2);
        let equityEvolutionTheBigBonusI25 = (parseFloat(equityEvolutionTheBigBonusD25) * parseFloat(impactof120GrowthOSI36)).toFixed(2);
        let equityEvolutionTheBigBonusI26 = (parseFloat(equityEvolutionTheBigBonusD26) * parseFloat(impactof120GrowthOSI36)).toFixed(2);
        let equityEvolutionTheBigBonusI27 = (parseFloat(equityEvolutionTheBigBonusD27) * parseFloat(impactof120GrowthOSI36)).toFixed(2);

        let equityEvolutionTheBigBonusJ24 = (parseFloat(equityEvolutionTheBigBonusD24) * parseFloat(impactof120GrowthOSJ36)).toFixed(2);
        let equityEvolutionTheBigBonusJ25 = (parseFloat(equityEvolutionTheBigBonusD25) * parseFloat(impactof120GrowthOSJ36)).toFixed(2);
        let equityEvolutionTheBigBonusJ26 = (parseFloat(equityEvolutionTheBigBonusD26) * parseFloat(impactof120GrowthOSJ36)).toFixed(2);
        let equityEvolutionTheBigBonusJ27 = (parseFloat(equityEvolutionTheBigBonusD27) * parseFloat(impactof120GrowthOSJ36)).toFixed(2);

        let equityEvolutionTheBigBonusK24 = (parseFloat(equityEvolutionTheBigBonusD24) * parseFloat(impactof120GrowthOSK36)).toFixed(2);
        let equityEvolutionTheBigBonusK25 = (parseFloat(equityEvolutionTheBigBonusD25) * parseFloat(impactof120GrowthOSK36)).toFixed(2);
        let equityEvolutionTheBigBonusK26 = (parseFloat(equityEvolutionTheBigBonusD26) * parseFloat(impactof120GrowthOSK36)).toFixed(2);
        let equityEvolutionTheBigBonusK27 = (parseFloat(equityEvolutionTheBigBonusD27) * parseFloat(impactof120GrowthOSK36)).toFixed(2);

        let equityEvolutionTheBigBonusL25 = (parseFloat(equityEvolutionTheBigBonusK25) - parseFloat(equityEvolutionTheBigBonusF25)).toFixed(2);
        let equityEvolutionTheBigBonusL26 = (parseFloat(equityEvolutionTheBigBonusK26) - parseFloat(equityEvolutionTheBigBonusF25)).toFixed(2);
        let equityEvolutionTheBigBonusL27 = (parseFloat(equityEvolutionTheBigBonusK27) - parseFloat(equityEvolutionTheBigBonusF25)).toFixed(2);

        return {
            ...state,
            equityEvolutionTheBigBonusD24,
            equityEvolutionTheBigBonusD25,
            equityEvolutionTheBigBonusD26,
            equityEvolutionTheBigBonusD27,
            equityEvolutionTheBigBonusE24,
            equityEvolutionTheBigBonusE25,
            equityEvolutionTheBigBonusE26,
            equityEvolutionTheBigBonusE27,
            equityEvolutionTheBigBonusF24,
            equityEvolutionTheBigBonusF25,
            equityEvolutionTheBigBonusF26,
            equityEvolutionTheBigBonusF27,
            equityEvolutionTheBigBonusG24,
            equityEvolutionTheBigBonusG25,
            equityEvolutionTheBigBonusG26,
            equityEvolutionTheBigBonusG27,
            equityEvolutionTheBigBonusH24,
            equityEvolutionTheBigBonusH25,
            equityEvolutionTheBigBonusH26,
            equityEvolutionTheBigBonusH27,
            equityEvolutionTheBigBonusI24,
            equityEvolutionTheBigBonusI25,
            equityEvolutionTheBigBonusI26,
            equityEvolutionTheBigBonusI27,
            equityEvolutionTheBigBonusJ24,
            equityEvolutionTheBigBonusJ25,
            equityEvolutionTheBigBonusJ26,
            equityEvolutionTheBigBonusJ27,
            equityEvolutionTheBigBonusK24,
            equityEvolutionTheBigBonusK25,
            equityEvolutionTheBigBonusK26,
            equityEvolutionTheBigBonusK27,
            equityEvolutionTheBigBonusL25,
            equityEvolutionTheBigBonusL26,
            equityEvolutionTheBigBonusL27,
        }
        
    }
    else {
        return state
    }

}

export default reducer