import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getInsightStoryboardTemplate, modifyInsightStoryboardTemplate } from "../../state/features/InsightStoryboardTemplateSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';

export default function InsightStoryboardTemplateContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        InsightStoryboardTemplateE7 : '',
        InsightStoryboardTemplateF10 : '',
        InsightStoryboardTemplateF11 : '',
        InsightStoryboardTemplateF12 : '',
        InsightStoryboardTemplateF13 : '',
        InsightStoryboardTemplateF14 : '',
        InsightStoryboardTemplateF15 : '',
        InsightStoryboardTemplateF16 : '',
        InsightStoryboardTemplateF17 : '',
        InsightStoryboardTemplateF18 : '',
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getInsightStoryboardTemplate({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.growthPlanning)
            if(response!= undefined  && response.data.growthPlanning){
                let growthPlanning = response.data.growthPlanning[0];
                console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key]
                    }
                }
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyInsightStoryboardTemplate({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        //window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

  return (
    <div className="content-body">
        <UserDropdown />
        {/* row */}
       <div className="container-fluid">
            <div className="head-title">
                <p>Messaging</p>
                <h2>Insight Storyboard Template </h2>
                <p>The Insight Storyboard is a short report that delivers a valuable insight to gain the attention of the C-suite.  To do this, it must be astonishing in both content and presentation. </p>
                <p>Always about the prospct and never about you, it should provide C-levels actionable insights into markets and trends that help them see the future and identify opportunities.  Your research and unique insight must align precisely, or your effort won't have credibility. </p>
                <p className="mb-5">C-levels aren't looking for solutions, they're more "big picture", looking for the opportunity to bolster their company's position in the next 5 years.  Each Insight Storyboard must have at least one bold insight or big idea of future opportunity.  The Insight Storyboard is not a presentation of value or a solution, but an invitation to a high-level conversation about future opportunity. </p>
            </div>
            <Spinner />
            <div className="card">
                <div className="card-body pl-0">
                <div className="table-responsive table-dta">                                
                    {/* table 1 */}
                    <table className="table mb-0 mt-0">
                    <thead>
                        <tr>
                        <td colSpan={3} className="width-25 bg-color1 text-font-size-14 text-white">Hypothesis Statement to Deliver Insight:</td>
                        <td colSpan={7} className="bg-color4 padding-0 text-white">
                            <form className="Focused-planning-f">
                            <input type="text" className="fucus-pla"
                                id="InsightStoryboardTemplateE7"
                                value={Inputs.InsightStoryboardTemplateE7}
                                onChange={(event) => setInputs({ ...Inputs, InsightStoryboardTemplateE7: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder={"I believe this: __________.  If this is true, then __________."}
                            />
                            </form>
                        </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td className="bg-color1 text-center-c text-white d-hidden" colSpan={3}>Unique Offer
                        </td>
                        <td className="bg-color1 text-center-c text-white width-25">Key Questions</td>
                        <td className="bg-color1 text-center-c text-white">Content Ideas &amp; Notes</td>
                        </tr>
                        <tr>
                        <td className="bg-color1 text-center-c bg-color5 padding-15 text-white width-10" colSpan={2}>
                            <div className="images-bs-div">
                            <img src="../images/insight-icon1.png" className="img-fluid" align="insight-icon1" />
                            </div>
                        </td>
                        <td className="bg-color1 text-font-size-16 text-white">Cover</td>
                        <td className="bg-color3 text-white width-25">Title should include company name; subtitle should include specific result or insight.  Big Idea should be on the cover.</td>
                        <td className="bg-color3 text-center-c">
                            <input type="text" className="form-control inputTextf text-white" 
                                id="InsightStoryboardTemplateF10"
                                value={Inputs.InsightStoryboardTemplateF10}
                                onChange={(event) => setInputs({ ...Inputs, InsightStoryboardTemplateF10: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color1 text-center-c bg-color5 padding-15 text-white width-10" colSpan={2}>
                            <div className="images-bs-div">
                            <img src="../images/insight-icon2.png" className="img-fluid" align="insight-icon1" />
                            </div>
                        </td>
                        <td className="bg-color1 text-font-size-16 text-white">Problem or Opportunity</td>
                        <td className="bg-color3 text-white width-25">Introduce your research; highlight trends and market dynamics.</td>
                        <td className="bg-color3 text-center-c">
                            <input type="text" className="form-control inputTextf text-white" 
                                id="InsightStoryboardTemplateF11"
                                value={Inputs.InsightStoryboardTemplateF11}
                                onChange={(event) => setInputs({ ...Inputs, InsightStoryboardTemplateF11: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color1 text-center-c bg-color5 padding-15 text-white width-10" colSpan={2}>
                            <div className="images-bs-div">
                            <img src="../images/insight-icon2.png" className="img-fluid" align="insight-icon1" />
                            </div>
                        </td>
                        <td className="bg-color1 text-font-size-16 text-white">Emerging Factors</td>
                        <td className="bg-color3 text-white width-25">What has changed?  What is on the horizon?  Here's what we learned in our research:  trends, threats, opportunities.</td>
                        <td className="bg-color3 text-center-c">
                            <input type="text" className="form-control inputTextf text-white" 
                                id="InsightStoryboardTemplateF12"
                                value={Inputs.InsightStoryboardTemplateF12}
                                onChange={(event) => setInputs({ ...Inputs, InsightStoryboardTemplateF12: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color1 text-center-c bg-color5 padding-15 text-white width-10" colSpan={2}>
                            <div className="images-bs-div">
                            <img src="../images/insight-icon3.png" className="img-fluid" align="insight-icon1" />
                            </div>
                        </td>
                        <td className="bg-color1 text-font-size-16 text-white">Implications</td>
                        <td className="bg-color3 text-white width-25">Problems, challenges, and opportunities resulting from research data (trends, threats, etc.).</td>
                        <td className="bg-color3 text-center-c">
                            <input type="text" className="form-control inputTextf text-white" 
                                id="InsightStoryboardTemplateF13"
                                value={Inputs.InsightStoryboardTemplateF13}
                                onChange={(event) => setInputs({ ...Inputs, InsightStoryboardTemplateF13: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color1 text-center-c bg-color5 padding-15 text-white width-10" colSpan={2}>
                            <div className="images-bs-div">
                            <img src="../images/insight-icon4.png" className="img-fluid" align="insight-icon1" />
                            </div>
                        </td>
                        <td className="bg-color1 text-font-size-16 text-white">Examine Alternatives</td>
                        <td className="bg-color3 text-white width-25">Here's what we think you're doing about it now…; review best practices; typical solutions don't work all the time or for everyone; doesn't produce desired outcome; doesn't solve emerging problems.</td>
                        <td className="bg-color3 text-center-c">
                            <input type="text" className="form-control inputTextf text-white" 
                                id="InsightStoryboardTemplateF14"
                                value={Inputs.InsightStoryboardTemplateF14}
                                onChange={(event) => setInputs({ ...Inputs, InsightStoryboardTemplateF14: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color1 text-center-c bg-color5 padding-15 text-white width-10" colSpan={2}>
                            <div className="images-bs-div">
                            <img src="../images/insight-icon5.png" className="img-fluid" align="insight-icon1" />
                            </div>
                        </td>
                        <td className="bg-color1 text-font-size-16 text-white">Expand on Opportunity</td>
                        <td className="bg-color3 text-white width-25">Here's where we see an opportunity.  Imagine if you could…; Here's the opportunity despite your progress…</td>
                        <td className="bg-color3 text-center-c">
                            <input type="text" className="form-control inputTextf text-white" 
                                id="InsightStoryboardTemplateF15"
                                value={Inputs.InsightStoryboardTemplateF15}
                                onChange={(event) => setInputs({ ...Inputs, InsightStoryboardTemplateF15: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color1 text-center-c bg-color5 padding-15 text-white width-10" colSpan={2}>
                            <div className="images-bs-div">
                            <img src="../images/insight-icon6.png" className="img-fluid" align="insight-icon1" />
                            </div>
                        </td>
                        <td className="bg-color1 text-font-size-16 text-white">Explore Possibilities</td>
                        <td className="bg-color3 text-white width-25">Summarize specific outcomes:  increase revenue, increase profit, reduce risk, inrease efficiency, create competitive advantage.  Show the value over 5 years into the future.</td>
                        <td className="bg-color3 text-center-c">
                            <input type="text" className="form-control inputTextf text-white" 
                                id="InsightStoryboardTemplateF16"
                                value={Inputs.InsightStoryboardTemplateF16}
                                onChange={(event) => setInputs({ ...Inputs, InsightStoryboardTemplateF16: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color1 text-center-c bg-color5 padding-15 text-white width-10" colSpan={2}>
                            <div className="images-bs-div">
                            <img src="../images/insight-icon7.png" className="img-fluid" align="insight-icon1" />
                            </div>
                        </td>
                        <td className="bg-color1 text-font-size-16 text-white">Set the Meeting</td>
                        <td className="bg-color3 text-white width-25">Define date and time; preschedule Zoom call.  Who should attend:  CEO, Pres, CFO, heads of marketing and sales.</td>
                        <td className="bg-color3 text-center-c">
                            <input type="text" className="form-control inputTextf text-white" 
                                id="InsightStoryboardTemplateF17"
                                value={Inputs.InsightStoryboardTemplateF17}
                                onChange={(event) => setInputs({ ...Inputs, InsightStoryboardTemplateF17: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color1 text-center-c bg-color5 padding-15 text-white width-10" colSpan={2}>
                            <div className="images-bs-div">
                            <img src="../images/insight-icon8.png" className="img-fluid" align="insight-icon1" />
                            </div>
                        </td>
                        <td className="bg-color1 text-font-size-16 text-white">List Sources</td>
                        <td className="bg-color3 text-white width-25">Show the highly credible sources of the data you used to create your insight.</td>
                        <td className="bg-color3 text-center-c">
                            <input type="text" className="form-control inputTextf text-white" 
                                id="InsightStoryboardTemplateF18"
                                value={Inputs.InsightStoryboardTemplateF18}
                                onChange={(event) => setInputs({ ...Inputs, InsightStoryboardTemplateF18: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            </div>

    </div>
  )
}
