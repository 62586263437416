import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getAllianceEvaluation, modifyAllianceEvaluation } from "../../state/features/AllianceEvaluationSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import sum from "../../utils/sum";

export default function AllianceEvaluationWorksheetContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        AllianceEvaluationD11 : '',
        AllianceEvaluationE11 : 0,
        AllianceEvaluationF11 : 0,
        AllianceEvaluationG11 : 0,
        AllianceEvaluationH11 : 0,
        AllianceEvaluationI11 : 0,
        AllianceEvaluationJ11 : 0,
        AllianceEvaluationK11 : 0,
        AllianceEvaluationL11 : 0,
        AllianceEvaluationM11 : 0,
        AllianceEvaluationD12 : '',
        AllianceEvaluationE12 : 0,
        AllianceEvaluationF12 : 0,
        AllianceEvaluationG12 : 0,
        AllianceEvaluationH12 : 0,
        AllianceEvaluationI12 : 0,
        AllianceEvaluationJ12 : 0,
        AllianceEvaluationK12 : 0,
        AllianceEvaluationL12 : 0,
        AllianceEvaluationM12 : 0,
        AllianceEvaluationD13 : '',
        AllianceEvaluationE13 : 0,
        AllianceEvaluationF13 : 0,
        AllianceEvaluationG13 : 0,
        AllianceEvaluationH13 : 0,
        AllianceEvaluationI13 : 0,
        AllianceEvaluationJ13 : 0,
        AllianceEvaluationK13 : 0,
        AllianceEvaluationL13 : 0,
        AllianceEvaluationM13 : 0,
        AllianceEvaluationD14 : '',
        AllianceEvaluationE14 : 0,
        AllianceEvaluationF14 : 0,
        AllianceEvaluationG14 : 0,
        AllianceEvaluationH14 : 0,
        AllianceEvaluationI14 : 0,
        AllianceEvaluationJ14 : 0,
        AllianceEvaluationK14 : 0,
        AllianceEvaluationL14 : 0,
        AllianceEvaluationM14 : 0,
        AllianceEvaluationD15 : '',
        AllianceEvaluationE15 : 0,
        AllianceEvaluationF15 : 0,
        AllianceEvaluationG15 : 0,
        AllianceEvaluationH15 : 0,
        AllianceEvaluationI15 : 0,
        AllianceEvaluationJ15 : 0,
        AllianceEvaluationK15 : 0,
        AllianceEvaluationL15 : 0,
        AllianceEvaluationM15 : 0,
        AllianceEvaluationD19 : '',
        AllianceEvaluationE19 : 0,
        AllianceEvaluationF19 : 0,
        AllianceEvaluationG19 : 0,
        AllianceEvaluationH19 : 0,
        AllianceEvaluationI19 : 0,
        AllianceEvaluationJ19 : 0,
        AllianceEvaluationK19 : 0,
        AllianceEvaluationL19 : 0,
        AllianceEvaluationM19 : 0,
        AllianceEvaluationD20 : '',
        AllianceEvaluationE20 : 0,
        AllianceEvaluationF20 : 0,
        AllianceEvaluationG20 : 0,
        AllianceEvaluationH20 : 0,
        AllianceEvaluationI20 : 0,
        AllianceEvaluationJ20 : 0,
        AllianceEvaluationK20 : 0,
        AllianceEvaluationL20 : 0,
        AllianceEvaluationM20 : 0,
        AllianceEvaluationD21 : '',
        AllianceEvaluationE21 : 0,
        AllianceEvaluationF21 : 0,
        AllianceEvaluationG21 : 0,
        AllianceEvaluationH21 : 0,
        AllianceEvaluationI21 : 0,
        AllianceEvaluationJ21 : 0,
        AllianceEvaluationK21 : 0,
        AllianceEvaluationL21 : 0,
        AllianceEvaluationM21 : 0,
        AllianceEvaluationD22 : '',
        AllianceEvaluationE22 : 0,
        AllianceEvaluationF22 : 0,
        AllianceEvaluationG22 : 0,
        AllianceEvaluationH22 : 0,
        AllianceEvaluationI22 : 0,
        AllianceEvaluationJ22 : 0,
        AllianceEvaluationK22 : 0,
        AllianceEvaluationL22 : 0,
        AllianceEvaluationM22 : 0,
        AllianceEvaluationD23 : '',
        AllianceEvaluationE23 : 0,
        AllianceEvaluationF23 : 0,
        AllianceEvaluationG23 : 0,
        AllianceEvaluationH23 : 0,
        AllianceEvaluationI23 : 0,
        AllianceEvaluationJ23 : 0,
        AllianceEvaluationK23 : 0,
        AllianceEvaluationL23 : 0,
        AllianceEvaluationM23 : 0,
        AllianceEvaluationD27 : '',
        AllianceEvaluationE27 : 0,
        AllianceEvaluationF27 : 0,
        AllianceEvaluationG27 : 0,
        AllianceEvaluationH27 : 0,
        AllianceEvaluationI27 : 0,
        AllianceEvaluationJ27 : 0,
        AllianceEvaluationD28 : '',
        AllianceEvaluationE28 : 0,
        AllianceEvaluationF28 : 0,
        AllianceEvaluationG28 : 0,
        AllianceEvaluationH28 : 0,
        AllianceEvaluationI28 : 0,
        AllianceEvaluationJ28 : 0,
        AllianceEvaluationD29 : '',
        AllianceEvaluationE29 : 0,
        AllianceEvaluationF29 : 0,
        AllianceEvaluationG29 : 0,
        AllianceEvaluationH29 : 0,
        AllianceEvaluationI29 : 0,
        AllianceEvaluationJ29 : 0,
        AllianceEvaluationD30 : '',
        AllianceEvaluationE30 : 0,
        AllianceEvaluationF30 : 0,
        AllianceEvaluationG30 : 0,
        AllianceEvaluationH30 : 0,
        AllianceEvaluationI30 : 0,
        AllianceEvaluationJ30 : 0,
        AllianceEvaluationD31 : '',
        AllianceEvaluationE31 : 0,
        AllianceEvaluationF31 : 0,
        AllianceEvaluationG31 : 0,
        AllianceEvaluationH31 : 0,
        AllianceEvaluationI31 : 0,
        AllianceEvaluationJ31 : 0,
        AllianceEvaluationD35 : '',
        AllianceEvaluationE35 : 0,
        AllianceEvaluationF35 : 0,
        AllianceEvaluationG35 : 0,
        AllianceEvaluationH35 : 0,
        AllianceEvaluationI35 : 0,
        AllianceEvaluationJ35 : 0,
        AllianceEvaluationD36 : '',
        AllianceEvaluationE36 : 0,
        AllianceEvaluationF36 : 0,
        AllianceEvaluationG36 : 0,
        AllianceEvaluationH36 : 0,
        AllianceEvaluationI36 : 0,
        AllianceEvaluationJ36 : 0,
        AllianceEvaluationD37 : '',
        AllianceEvaluationE37 : 0,
        AllianceEvaluationF37 : 0,
        AllianceEvaluationG37 : 0,
        AllianceEvaluationH37 : 0,
        AllianceEvaluationI37 : 0,
        AllianceEvaluationJ37 : 0,
        AllianceEvaluationD38 : '',
        AllianceEvaluationE38 : 0,
        AllianceEvaluationF38 : 0,
        AllianceEvaluationG38 : 0,
        AllianceEvaluationH38 : 0,
        AllianceEvaluationI38 : 0,
        AllianceEvaluationJ38 : 0,
        AllianceEvaluationD39 : '',
        AllianceEvaluationE39 : 0,
        AllianceEvaluationF39 : 0,
        AllianceEvaluationG39 : 0,
        AllianceEvaluationH39 : 0,
        AllianceEvaluationI39 : 0,
        AllianceEvaluationJ39 : 0,
        AllianceEvaluationN11 : 0,
        AllianceEvaluationN12 : 0,
        AllianceEvaluationN13 : 0,
        AllianceEvaluationN14 : 0,
        AllianceEvaluationN15 : 0,
        AllianceEvaluationN19 : 0,
        AllianceEvaluationN20 : 0,
        AllianceEvaluationN21 : 0,
        AllianceEvaluationN22 : 0,
        AllianceEvaluationN23 : 0,
        AllianceEvaluationK27 : 0,
        AllianceEvaluationK28 : 0,
        AllianceEvaluationK29 : 0,
        AllianceEvaluationK30 : 0,
        AllianceEvaluationK31 : 0,
        AllianceEvaluationK35 : 0,
        AllianceEvaluationK36 : 0,
        AllianceEvaluationK37 : 0,
        AllianceEvaluationK38 : 0,
        AllianceEvaluationK39 : 0,
        AllianceEvaluationD44 : '',
        AllianceEvaluationE44 : 0,
        AllianceEvaluationF44 : 0,
        AllianceEvaluationG44 : 0,
        AllianceEvaluationH44 : 0,
        AllianceEvaluationD45 : '',
        AllianceEvaluationE45 : 0,
        AllianceEvaluationF45 : 0,
        AllianceEvaluationG45 : 0,
        AllianceEvaluationH45 : 0,
        AllianceEvaluationD46 : '',
        AllianceEvaluationE46 : 0,
        AllianceEvaluationF46 : 0,
        AllianceEvaluationG46 : 0,
        AllianceEvaluationH46 : 0,
        AllianceEvaluationD47 : '',
        AllianceEvaluationE47 : 0,
        AllianceEvaluationF47 : 0,
        AllianceEvaluationG47 : 0,
        AllianceEvaluationH47 : 0,
        AllianceEvaluationD48 : '',
        AllianceEvaluationE48 : 0,
        AllianceEvaluationF48 : 0,
        AllianceEvaluationG48 : 0,
        AllianceEvaluationH48 : 0,
        AllianceEvaluationI44 : 0,
        AllianceEvaluationI45 : 0,
        AllianceEvaluationI46 : 0,
        AllianceEvaluationI47 : 0,
        AllianceEvaluationI48 : 0,
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getAllianceEvaluation({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.revenueGrowth)
            if(response!= undefined  && response.data.revenueGrowth){
                let revenueGrowth = response.data.revenueGrowth[0];
                console.log(revenueGrowth)
                delete revenueGrowth.id;
                delete revenueGrowth.user_id;
                delete revenueGrowth.created_at;
                delete revenueGrowth.updated_at;
                const newObj = { ...Inputs }
                for (const key in revenueGrowth) {
                    if (revenueGrowth.hasOwnProperty(key)) {
                        if(revenueGrowth[key])
                        newObj[key] = revenueGrowth[key]
                    }
                }
                newObj['AllianceEvaluationN11'] = sum(newObj['AllianceEvaluationE11'], newObj['AllianceEvaluationF11'], newObj['AllianceEvaluationG11'], newObj['AllianceEvaluationH11'], newObj['AllianceEvaluationI11'], newObj['AllianceEvaluationJ11'], newObj['AllianceEvaluationK11'], newObj['AllianceEvaluationL11'], newObj['AllianceEvaluationM11']).toFixed(2);
                newObj['AllianceEvaluationE44'] = newObj['AllianceEvaluationN11'];
                newObj['AllianceEvaluationN12'] = sum(newObj['AllianceEvaluationE12'], newObj['AllianceEvaluationF12'], newObj['AllianceEvaluationG12'], newObj['AllianceEvaluationH12'], newObj['AllianceEvaluationI12'], newObj['AllianceEvaluationJ12'], newObj['AllianceEvaluationK12'], newObj['AllianceEvaluationL12'], newObj['AllianceEvaluationM12']).toFixed(2);
                newObj['AllianceEvaluationE45'] = newObj['AllianceEvaluationN12']; 
                newObj['AllianceEvaluationN13'] = sum(newObj['AllianceEvaluationE13'], newObj['AllianceEvaluationF13'], newObj['AllianceEvaluationG13'], newObj['AllianceEvaluationH13'], newObj['AllianceEvaluationI13'], newObj['AllianceEvaluationJ13'], newObj['AllianceEvaluationK13'], newObj['AllianceEvaluationL13'], newObj['AllianceEvaluationM13']).toFixed(2);
                newObj['AllianceEvaluationE46'] = newObj['AllianceEvaluationN13']; 
                newObj['AllianceEvaluationN14'] = sum(newObj['AllianceEvaluationE14'], newObj['AllianceEvaluationF14'], newObj['AllianceEvaluationG14'], newObj['AllianceEvaluationH14'], newObj['AllianceEvaluationI14'], newObj['AllianceEvaluationJ14'], newObj['AllianceEvaluationK14'], newObj['AllianceEvaluationL14'], newObj['AllianceEvaluationM14']).toFixed(2);
                newObj['AllianceEvaluationE47'] = newObj['AllianceEvaluationN14']; 
                newObj['AllianceEvaluationN15'] = sum(newObj['AllianceEvaluationE15'], newObj['AllianceEvaluationF15'], newObj['AllianceEvaluationG15'], newObj['AllianceEvaluationH15'], newObj['AllianceEvaluationI15'], newObj['AllianceEvaluationJ15'], newObj['AllianceEvaluationK15'], newObj['AllianceEvaluationL15'], newObj['AllianceEvaluationM15']).toFixed(2);
                newObj['AllianceEvaluationE48'] = newObj['AllianceEvaluationN15']; 
                newObj['AllianceEvaluationN19'] = sum(newObj['AllianceEvaluationE19'], newObj['AllianceEvaluationF19'], newObj['AllianceEvaluationG19'], newObj['AllianceEvaluationH19'], newObj['AllianceEvaluationI19'], newObj['AllianceEvaluationJ19'], newObj['AllianceEvaluationK19'], newObj['AllianceEvaluationL19'], newObj['AllianceEvaluationM19']).toFixed(2);
                newObj['AllianceEvaluationG44'] = newObj['AllianceEvaluationN19'];
                newObj['AllianceEvaluationN20'] = sum(newObj['AllianceEvaluationE20'], newObj['AllianceEvaluationF20'], newObj['AllianceEvaluationG20'], newObj['AllianceEvaluationH20'], newObj['AllianceEvaluationI20'], newObj['AllianceEvaluationJ20'], newObj['AllianceEvaluationK20'], newObj['AllianceEvaluationL20'], newObj['AllianceEvaluationM20']).toFixed(2);
                newObj['AllianceEvaluationG45'] = newObj['AllianceEvaluationN20'];
                newObj['AllianceEvaluationN21'] = sum(newObj['AllianceEvaluationE21'], newObj['AllianceEvaluationF21'], newObj['AllianceEvaluationG21'], newObj['AllianceEvaluationH21'], newObj['AllianceEvaluationI21'], newObj['AllianceEvaluationJ21'], newObj['AllianceEvaluationK21'], newObj['AllianceEvaluationL21'], newObj['AllianceEvaluationM21']).toFixed(2);
                newObj['AllianceEvaluationG46'] = newObj['AllianceEvaluationN21'];
                newObj['AllianceEvaluationN22'] = sum(newObj['AllianceEvaluationE22'], newObj['AllianceEvaluationF22'], newObj['AllianceEvaluationG22'], newObj['AllianceEvaluationH22'], newObj['AllianceEvaluationI22'], newObj['AllianceEvaluationJ22'], newObj['AllianceEvaluationK22'], newObj['AllianceEvaluationL22'], newObj['AllianceEvaluationM22']).toFixed(2);
                newObj['AllianceEvaluationG47'] = newObj['AllianceEvaluationN22'];
                newObj['AllianceEvaluationN23'] = sum(newObj['AllianceEvaluationE23'], newObj['AllianceEvaluationF23'], newObj['AllianceEvaluationG23'], newObj['AllianceEvaluationH23'], newObj['AllianceEvaluationI23'], newObj['AllianceEvaluationJ23'], newObj['AllianceEvaluationK23'], newObj['AllianceEvaluationL23'], newObj['AllianceEvaluationM23']).toFixed(2);
                newObj['AllianceEvaluationG48'] = newObj['AllianceEvaluationN23'];

                newObj['AllianceEvaluationK27'] = sum(newObj['AllianceEvaluationE27'], newObj['AllianceEvaluationF27'], newObj['AllianceEvaluationG27'], newObj['AllianceEvaluationH27'], newObj['AllianceEvaluationI27'], newObj['AllianceEvaluationJ27']).toFixed(2);
                newObj['AllianceEvaluationF44'] = newObj['AllianceEvaluationK27'];
                newObj['AllianceEvaluationK28'] = sum(newObj['AllianceEvaluationE28'], newObj['AllianceEvaluationF28'], newObj['AllianceEvaluationG28'], newObj['AllianceEvaluationH28'], newObj['AllianceEvaluationI28'], newObj['AllianceEvaluationJ28']).toFixed(2);
                newObj['AllianceEvaluationF45'] = newObj['AllianceEvaluationK28'];
                newObj['AllianceEvaluationK29'] = sum(newObj['AllianceEvaluationE29'], newObj['AllianceEvaluationF29'], newObj['AllianceEvaluationG29'], newObj['AllianceEvaluationH29'], newObj['AllianceEvaluationI29'], newObj['AllianceEvaluationJ29']).toFixed(2);
                newObj['AllianceEvaluationF46'] = newObj['AllianceEvaluationK29'];
                newObj['AllianceEvaluationK30'] = sum(newObj['AllianceEvaluationE30'], newObj['AllianceEvaluationF30'], newObj['AllianceEvaluationG30'], newObj['AllianceEvaluationH30'], newObj['AllianceEvaluationI30'], newObj['AllianceEvaluationJ30']).toFixed(2);
                newObj['AllianceEvaluationF47'] = newObj['AllianceEvaluationK30'];
                newObj['AllianceEvaluationK31'] = sum(newObj['AllianceEvaluationE31'], newObj['AllianceEvaluationF31'], newObj['AllianceEvaluationG31'], newObj['AllianceEvaluationH31'], newObj['AllianceEvaluationI31'], newObj['AllianceEvaluationJ31']).toFixed(2);
                newObj['AllianceEvaluationF48'] = newObj['AllianceEvaluationK31'];
                newObj['AllianceEvaluationK35'] = sum(newObj['AllianceEvaluationE35'], newObj['AllianceEvaluationF35'], newObj['AllianceEvaluationG35'], newObj['AllianceEvaluationH35'], newObj['AllianceEvaluationI35'], newObj['AllianceEvaluationJ35']).toFixed(2);
                newObj['AllianceEvaluationH44'] = newObj['AllianceEvaluationK35']; 
                newObj['AllianceEvaluationK36'] = sum(newObj['AllianceEvaluationE36'], newObj['AllianceEvaluationF36'], newObj['AllianceEvaluationG36'], newObj['AllianceEvaluationH36'], newObj['AllianceEvaluationI36'], newObj['AllianceEvaluationJ36']).toFixed(2);
                newObj['AllianceEvaluationH45'] = newObj['AllianceEvaluationK36']; 
                newObj['AllianceEvaluationK37'] = sum(newObj['AllianceEvaluationE37'], newObj['AllianceEvaluationF37'], newObj['AllianceEvaluationG37'], newObj['AllianceEvaluationH37'], newObj['AllianceEvaluationI37'], newObj['AllianceEvaluationJ37']).toFixed(2);
                newObj['AllianceEvaluationH46'] = newObj['AllianceEvaluationK37']; 
                newObj['AllianceEvaluationK38'] = sum(newObj['AllianceEvaluationE38'], newObj['AllianceEvaluationF38'], newObj['AllianceEvaluationG38'], newObj['AllianceEvaluationH38'], newObj['AllianceEvaluationI38'], newObj['AllianceEvaluationJ38']).toFixed(2);
                newObj['AllianceEvaluationH47'] = newObj['AllianceEvaluationK38']; 
                newObj['AllianceEvaluationK39'] = sum(newObj['AllianceEvaluationE39'], newObj['AllianceEvaluationF39'], newObj['AllianceEvaluationG39'], newObj['AllianceEvaluationH39'], newObj['AllianceEvaluationI39'], newObj['AllianceEvaluationJ39']).toFixed(2);
                newObj['AllianceEvaluationH48'] = newObj['AllianceEvaluationK39']; 

                newObj['AllianceEvaluationI44'] = sum(newObj['AllianceEvaluationE44'], newObj['AllianceEvaluationF44'], newObj['AllianceEvaluationG44'], newObj['AllianceEvaluationH44']).toFixed(2);
                newObj['AllianceEvaluationI45'] = sum(newObj['AllianceEvaluationE45'], newObj['AllianceEvaluationF45'], newObj['AllianceEvaluationG45'], newObj['AllianceEvaluationH45']).toFixed(2);
                newObj['AllianceEvaluationI46'] = sum(newObj['AllianceEvaluationE46'], newObj['AllianceEvaluationF46'], newObj['AllianceEvaluationG46'], newObj['AllianceEvaluationH46']).toFixed(2);
                newObj['AllianceEvaluationI47'] = sum(newObj['AllianceEvaluationE47'], newObj['AllianceEvaluationF47'], newObj['AllianceEvaluationG47'], newObj['AllianceEvaluationH47']).toFixed(2);
                newObj['AllianceEvaluationI48'] = sum(newObj['AllianceEvaluationE48'], newObj['AllianceEvaluationF48'], newObj['AllianceEvaluationG48'], newObj['AllianceEvaluationH48']).toFixed(2);

                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                event.target.value = event.target.value ? event.target.value : 0
                let thenum = event.target.id.match(/\d+/)[0];
                let thestr = event.target.id.replace(thenum,'');
                let overallScore = 0;
                if(thestr != 'AllianceEvaluationD' && thenum <= 23){
                    if(thestr == 'AllianceEvaluationE'){
                        overallScore = sum(event.target.value, Inputs['AllianceEvaluationF'+thenum], Inputs['AllianceEvaluationG'+thenum], Inputs['AllianceEvaluationH'+thenum], Inputs['AllianceEvaluationI'+thenum], Inputs['AllianceEvaluationJ'+thenum], Inputs['AllianceEvaluationK'+thenum], Inputs['AllianceEvaluationL'+thenum], Inputs['AllianceEvaluationM'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'AllianceEvaluationF'){
                        overallScore = sum(event.target.value, Inputs['AllianceEvaluationE'+thenum], Inputs['AllianceEvaluationG'+thenum], Inputs['AllianceEvaluationH'+thenum], Inputs['AllianceEvaluationI'+thenum], Inputs['AllianceEvaluationJ'+thenum], Inputs['AllianceEvaluationK'+thenum], Inputs['AllianceEvaluationL'+thenum], Inputs['AllianceEvaluationM'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'AllianceEvaluationG'){
                        overallScore = sum(event.target.value, Inputs['AllianceEvaluationE'+thenum], Inputs['AllianceEvaluationF'+thenum], Inputs['AllianceEvaluationH'+thenum], Inputs['AllianceEvaluationI'+thenum], Inputs['AllianceEvaluationJ'+thenum], Inputs['AllianceEvaluationK'+thenum], Inputs['AllianceEvaluationL'+thenum], Inputs['AllianceEvaluationM'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'AllianceEvaluationH'){
                        overallScore = sum(event.target.value, Inputs['AllianceEvaluationE'+thenum], Inputs['AllianceEvaluationF'+thenum], Inputs['AllianceEvaluationG'+thenum], Inputs['AllianceEvaluationI'+thenum], Inputs['AllianceEvaluationJ'+thenum], Inputs['AllianceEvaluationK'+thenum], Inputs['AllianceEvaluationL'+thenum], Inputs['AllianceEvaluationM'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'AllianceEvaluationI'){
                        overallScore = sum(event.target.value, Inputs['AllianceEvaluationE'+thenum], Inputs['AllianceEvaluationF'+thenum], Inputs['AllianceEvaluationG'+thenum], Inputs['AllianceEvaluationH'+thenum], Inputs['AllianceEvaluationJ'+thenum], Inputs['AllianceEvaluationK'+thenum], Inputs['AllianceEvaluationL'+thenum], Inputs['AllianceEvaluationM'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'AllianceEvaluationJ'){
                        overallScore = sum(event.target.value, Inputs['AllianceEvaluationE'+thenum], Inputs['AllianceEvaluationF'+thenum], Inputs['AllianceEvaluationG'+thenum], Inputs['AllianceEvaluationH'+thenum], Inputs['AllianceEvaluationI'+thenum], Inputs['AllianceEvaluationK'+thenum], Inputs['AllianceEvaluationL'+thenum], Inputs['AllianceEvaluationM'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'AllianceEvaluationK'){
                        overallScore = sum(event.target.value, Inputs['AllianceEvaluationE'+thenum], Inputs['AllianceEvaluationF'+thenum], Inputs['AllianceEvaluationG'+thenum], Inputs['AllianceEvaluationH'+thenum], Inputs['AllianceEvaluationI'+thenum], Inputs['AllianceEvaluationJ'+thenum], Inputs['AllianceEvaluationL'+thenum], Inputs['AllianceEvaluationM'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'AllianceEvaluationL'){
                        overallScore = sum(event.target.value, Inputs['AllianceEvaluationE'+thenum], Inputs['AllianceEvaluationF'+thenum], Inputs['AllianceEvaluationG'+thenum], Inputs['AllianceEvaluationH'+thenum], Inputs['AllianceEvaluationI'+thenum], Inputs['AllianceEvaluationJ'+thenum], Inputs['AllianceEvaluationK'+thenum], Inputs['AllianceEvaluationM'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'AllianceEvaluationM'){
                        overallScore = sum(event.target.value, Inputs['AllianceEvaluationE'+thenum], Inputs['AllianceEvaluationF'+thenum], Inputs['AllianceEvaluationG'+thenum], Inputs['AllianceEvaluationH'+thenum], Inputs['AllianceEvaluationI'+thenum], Inputs['AllianceEvaluationJ'+thenum], Inputs['AllianceEvaluationK'+thenum], Inputs['AllianceEvaluationL'+thenum]).toFixed(2);
                    }
                    const newObj = {...Inputs};
                    newObj['AllianceEvaluationN'+thenum] = parseFloat(overallScore);
                    newObj['AllianceEvaluationE44'] = thenum == 11 ? newObj['AllianceEvaluationN'+thenum] : newObj['AllianceEvaluationN11'];
                    newObj['AllianceEvaluationE45'] = thenum == 12 ? newObj['AllianceEvaluationN'+thenum] :  newObj['AllianceEvaluationN12']; 
                    newObj['AllianceEvaluationE46'] = thenum == 13 ? newObj['AllianceEvaluationN'+thenum] : newObj['AllianceEvaluationN13']; 
                    newObj['AllianceEvaluationE47'] = thenum == 14 ? newObj['AllianceEvaluationN'+thenum] : newObj['AllianceEvaluationN14']; 
                    newObj['AllianceEvaluationE48'] = thenum == 15 ? newObj['AllianceEvaluationN'+thenum] : newObj['AllianceEvaluationN15']; 
                    newObj['AllianceEvaluationG44'] = thenum == 19 ? newObj['AllianceEvaluationN'+thenum] : newObj['AllianceEvaluationN19'];
                    newObj['AllianceEvaluationG45'] = thenum == 20 ? newObj['AllianceEvaluationN'+thenum] : newObj['AllianceEvaluationN20'];
                    newObj['AllianceEvaluationG46'] = thenum == 21 ? newObj['AllianceEvaluationN'+thenum] : newObj['AllianceEvaluationN21'];
                    newObj['AllianceEvaluationG47'] = thenum == 22 ? newObj['AllianceEvaluationN'+thenum] : newObj['AllianceEvaluationN22'];
                    newObj['AllianceEvaluationG48'] = thenum == 23 ? newObj['AllianceEvaluationN'+thenum] : newObj['AllianceEvaluationN23'];

                    newObj['AllianceEvaluationI44'] = sum(newObj['AllianceEvaluationE44'], newObj['AllianceEvaluationF44'], newObj['AllianceEvaluationG44'], newObj['AllianceEvaluationH44']).toFixed(2);
                    newObj['AllianceEvaluationI45'] = sum(newObj['AllianceEvaluationE45'], newObj['AllianceEvaluationF45'], newObj['AllianceEvaluationG45'], newObj['AllianceEvaluationH45']).toFixed(2);
                    newObj['AllianceEvaluationI46'] = sum(newObj['AllianceEvaluationE46'], newObj['AllianceEvaluationF46'], newObj['AllianceEvaluationG46'], newObj['AllianceEvaluationH46']).toFixed(2);
                    newObj['AllianceEvaluationI47'] = sum(newObj['AllianceEvaluationE47'], newObj['AllianceEvaluationF47'], newObj['AllianceEvaluationG47'], newObj['AllianceEvaluationH47']).toFixed(2);
                    newObj['AllianceEvaluationI48'] = sum(newObj['AllianceEvaluationE48'], newObj['AllianceEvaluationF48'], newObj['AllianceEvaluationG48'], newObj['AllianceEvaluationH48']).toFixed(2);
                    
                    setInputs(newObj);
                }
                else if(thestr != 'AllianceEvaluationD' && thenum > 23 && thenum <= 39){
                    if(thestr == 'AllianceEvaluationE'){
                        overallScore = sum(event.target.value, Inputs['AllianceEvaluationF'+thenum], Inputs['AllianceEvaluationG'+thenum], Inputs['AllianceEvaluationH'+thenum], Inputs['AllianceEvaluationI'+thenum], Inputs['AllianceEvaluationJ'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'AllianceEvaluationF'){
                        overallScore = sum(event.target.value, Inputs['AllianceEvaluationE'+thenum], Inputs['AllianceEvaluationG'+thenum], Inputs['AllianceEvaluationH'+thenum], Inputs['AllianceEvaluationI'+thenum], Inputs['AllianceEvaluationJ'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'AllianceEvaluationG'){
                        overallScore = sum(event.target.value, Inputs['AllianceEvaluationE'+thenum], Inputs['AllianceEvaluationF'+thenum], Inputs['AllianceEvaluationH'+thenum], Inputs['AllianceEvaluationI'+thenum], Inputs['AllianceEvaluationJ'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'AllianceEvaluationH'){
                        overallScore = sum(event.target.value, Inputs['AllianceEvaluationE'+thenum], Inputs['AllianceEvaluationF'+thenum], Inputs['AllianceEvaluationG'+thenum], Inputs['AllianceEvaluationI'+thenum], Inputs['AllianceEvaluationJ'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'AllianceEvaluationI'){
                        overallScore = sum(event.target.value, Inputs['AllianceEvaluationE'+thenum], Inputs['AllianceEvaluationF'+thenum], Inputs['AllianceEvaluationG'+thenum], Inputs['AllianceEvaluationH'+thenum], Inputs['AllianceEvaluationJ'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'AllianceEvaluationJ'){
                        overallScore = sum(event.target.value, Inputs['AllianceEvaluationE'+thenum], Inputs['AllianceEvaluationF'+thenum], Inputs['AllianceEvaluationG'+thenum], Inputs['AllianceEvaluationH'+thenum], Inputs['AllianceEvaluationI'+thenum]).toFixed(2);
                    }

                    const newObj = {...Inputs};
                    newObj['AllianceEvaluationK'+thenum] = parseFloat(overallScore);

                    newObj['AllianceEvaluationF44'] = thenum == 27 ? newObj['AllianceEvaluationK'+thenum] : newObj['AllianceEvaluationK27'];
                    newObj['AllianceEvaluationF45'] = thenum == 28 ? newObj['AllianceEvaluationK'+thenum] : newObj['AllianceEvaluationK28'];
                    newObj['AllianceEvaluationF46'] = thenum == 29 ? newObj['AllianceEvaluationK'+thenum] : newObj['AllianceEvaluationK29'];
                    newObj['AllianceEvaluationF47'] = thenum == 30 ? newObj['AllianceEvaluationK'+thenum] : newObj['AllianceEvaluationK30'];
                    newObj['AllianceEvaluationF48'] = thenum == 31 ? newObj['AllianceEvaluationK'+thenum] : newObj['AllianceEvaluationK31'];
                    newObj['AllianceEvaluationH44'] = thenum == 35 ? newObj['AllianceEvaluationK'+thenum] : newObj['AllianceEvaluationK35']; 
                    newObj['AllianceEvaluationH45'] = thenum == 36 ? newObj['AllianceEvaluationK'+thenum] : newObj['AllianceEvaluationK36']; 
                    newObj['AllianceEvaluationH46'] = thenum == 37 ? newObj['AllianceEvaluationK'+thenum] : newObj['AllianceEvaluationK37']; 
                    newObj['AllianceEvaluationH47'] = thenum == 38 ? newObj['AllianceEvaluationK'+thenum] : newObj['AllianceEvaluationK38']; 
                    newObj['AllianceEvaluationH48'] = thenum == 39 ? newObj['AllianceEvaluationK'+thenum] : newObj['AllianceEvaluationK39']; 

                    newObj['AllianceEvaluationI44'] = sum(newObj['AllianceEvaluationE44'], newObj['AllianceEvaluationF44'], newObj['AllianceEvaluationG44'], newObj['AllianceEvaluationH44']).toFixed(2);
                    newObj['AllianceEvaluationI45'] = sum(newObj['AllianceEvaluationE45'], newObj['AllianceEvaluationF45'], newObj['AllianceEvaluationG45'], newObj['AllianceEvaluationH45']).toFixed(2);
                    newObj['AllianceEvaluationI46'] = sum(newObj['AllianceEvaluationE46'], newObj['AllianceEvaluationF46'], newObj['AllianceEvaluationG46'], newObj['AllianceEvaluationH46']).toFixed(2);
                    newObj['AllianceEvaluationI47'] = sum(newObj['AllianceEvaluationE47'], newObj['AllianceEvaluationF47'], newObj['AllianceEvaluationG47'], newObj['AllianceEvaluationH47']).toFixed(2);
                    newObj['AllianceEvaluationI48'] = sum(newObj['AllianceEvaluationE48'], newObj['AllianceEvaluationF48'], newObj['AllianceEvaluationG48'], newObj['AllianceEvaluationH48']).toFixed(2);
                    
                    setInputs(newObj);
                }
               
                dispatch(modifyAllianceEvaluation({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }
    
  return (
    <div className="content-body">
        <UserDropdown />
        {/* row */}
        <div className="container-fluid">
            <div className="head-title">
                <p>Opportunity Analysis </p>
                <h2 className="mb-5 mt-0">Alliance Evaluation Worksheet</h2>
            </div>
            <Spinner />
            <div className="card">
                <div className="card-body pl-0">
                <div className="table-responsive table-dta">
                    <table className="table mb-0">
                    <tbody>
                        <tr>
                        <td className="bg-color1 text-white" colSpan={3} rowSpan={2}>
                            <div className="vendor-div-main" style={{width: 250}}>
                            <span className="text-center-c width-100 d-block font-size-20">Potential Alliance Partner</span>
                            </div>
                        </td>
                        <td className="bg-color3 text-white text-center-c" colSpan={9}>Strategic Fit</td>
                        </tr>
                        <tr>
                        <td className="bg-color1 text-white">Compatible Target Markets</td>
                        <td className="bg-color1 text-white">Access to New Markets</td>
                        <td className="bg-color1 text-white">Openness to Opportunities</td>
                        <td className="bg-color1 text-white">Access to New Markets</td>
                        <td className="bg-color1 text-white">Compatible Cultures</td>
                        <td className="bg-color1 text-white">Offerings Create Synergy</td>
                        <td className="bg-color1 text-white">Partnership Lends Credibility</td>
                        <td className="bg-color1 text-white">Provides Competitive Advantage</td>
                        <td className="bg-color1 text-white">Partner Has Strong IP</td>
                        <td className="bg-color3 text-white">Score</td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5">1</td>
                        <td className="bg-color1 text-white padding-0" colSpan={2}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="AllianceEvaluationD11"
                                    value={Inputs.AllianceEvaluationD11}
                                    onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationD11: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                    placeholder="Partner 1" />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationE11" 
                                value={Inputs.AllianceEvaluationE11} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationE11: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationF11" 
                                value={Inputs.AllianceEvaluationF11} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationF11: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationG11" 
                                value={Inputs.AllianceEvaluationG11} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationG11: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationH11" 
                                value={Inputs.AllianceEvaluationH11} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationH11: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationI11" 
                                value={Inputs.AllianceEvaluationI11} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationI11: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationJ11" 
                                value={Inputs.AllianceEvaluationJ11} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationJ11: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationK11" 
                                value={Inputs.AllianceEvaluationK11} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationK11: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationL11" 
                                value={Inputs.AllianceEvaluationL11} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationL11: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationM11" 
                                value={Inputs.AllianceEvaluationM11} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationM11: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color3 text-white text-center-c">
                            {Number(Inputs.AllianceEvaluationN11).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5">2</td>
                        <td className="bg-color1 text-white padding-0" colSpan={2}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="AllianceEvaluationD12"
                                    value={Inputs.AllianceEvaluationD12}
                                    onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationD12: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                    placeholder="Partner 2" />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationE12" 
                                value={Inputs.AllianceEvaluationE12} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationE12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationF12" 
                                value={Inputs.AllianceEvaluationF12} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationF12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationG12" 
                                value={Inputs.AllianceEvaluationG12} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationG12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationH12" 
                                value={Inputs.AllianceEvaluationH12} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationH12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationI12" 
                                value={Inputs.AllianceEvaluationI12} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationI12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationJ12" 
                                value={Inputs.AllianceEvaluationJ12} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationJ12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationK12" 
                                value={Inputs.AllianceEvaluationK12} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationK12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationL12" 
                                value={Inputs.AllianceEvaluationL12} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationL12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationM12" 
                                value={Inputs.AllianceEvaluationM12} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationM12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color3 text-white text-center-c">
                            {Number(Inputs.AllianceEvaluationN12).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5">3</td>
                        <td className="bg-color1 text-white padding-0" colSpan={2}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="AllianceEvaluationD13"
                                    value={Inputs.AllianceEvaluationD13}
                                    onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationD13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                    placeholder="Partner 3" />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationE13" 
                                value={Inputs.AllianceEvaluationE13} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationE13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationF13" 
                                value={Inputs.AllianceEvaluationF13} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationF13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationG13" 
                                value={Inputs.AllianceEvaluationG13} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationG13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationH13" 
                                value={Inputs.AllianceEvaluationH13} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationH13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationI13" 
                                value={Inputs.AllianceEvaluationI13} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationI13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationJ13" 
                                value={Inputs.AllianceEvaluationJ13} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationJ13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationK13" 
                                value={Inputs.AllianceEvaluationK13} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationK13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationL13" 
                                value={Inputs.AllianceEvaluationL13} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationL13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationM13" 
                                value={Inputs.AllianceEvaluationM13} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationM13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color3 text-white text-center-c">
                            {Number(Inputs.AllianceEvaluationN13).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5">4</td>
                        <td className="bg-color1 text-white padding-0" colSpan={2}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="AllianceEvaluationD14"
                                    value={Inputs.AllianceEvaluationD14}
                                    onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationD14: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                    placeholder="Partner 4" />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationE14" 
                                value={Inputs.AllianceEvaluationE14} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationE14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationF14" 
                                value={Inputs.AllianceEvaluationF14} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationF14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationG14" 
                                value={Inputs.AllianceEvaluationG14} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationG14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationH14" 
                                value={Inputs.AllianceEvaluationH14} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationH14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationI14" 
                                value={Inputs.AllianceEvaluationI14} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationI14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationJ14" 
                                value={Inputs.AllianceEvaluationJ14} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationJ14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationK14" 
                                value={Inputs.AllianceEvaluationK14} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationK14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationL14" 
                                value={Inputs.AllianceEvaluationL14} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationL14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationM14" 
                                value={Inputs.AllianceEvaluationM14} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationM14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color3 text-white text-center-c">
                            {Number(Inputs.AllianceEvaluationN14).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5">5</td>
                        <td className="bg-color1 text-white padding-0" colSpan={2}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="AllianceEvaluationD15"
                                    value={Inputs.AllianceEvaluationD15}
                                    onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationD15: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                    placeholder="Partner 5" />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationE15" 
                                value={Inputs.AllianceEvaluationE15} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationE15: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationF15" 
                                value={Inputs.AllianceEvaluationF15} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationF15: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationG15" 
                                value={Inputs.AllianceEvaluationG15} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationG15: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationH15" 
                                value={Inputs.AllianceEvaluationH15} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationH15: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationI15" 
                                value={Inputs.AllianceEvaluationI15} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationI15: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationJ15" 
                                value={Inputs.AllianceEvaluationJ15} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationJ15: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationK15" 
                                value={Inputs.AllianceEvaluationK15} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationK15: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationL15" 
                                value={Inputs.AllianceEvaluationL15} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationL15: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationM15" 
                                value={Inputs.AllianceEvaluationM15} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationM15: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color3 text-white text-center-c">
                            {Number(Inputs.AllianceEvaluationN15).toLocaleString('en-US')}
                        </td>
                        </tr>
                    </tbody>
                    </table>
                    {/* table 2 */}
                    <table className="table mb-0 mt-3">
                    <tbody>
                        <tr>
                        <td className="bg-color1 text-white" colSpan={3} rowSpan={2}>
                            <div className="vendor-div-main" style={{width: 250}}>
                            <span className="text-center-c width-100 d-block font-size-20">Potential Alliance Partner</span>
                            </div>
                        </td>
                        <td className="bg-color3 text-white text-center-c" colSpan={9}>Growth Driver Impact</td>
                        </tr>
                        <tr>
                        <td className="bg-color1 text-white">Lead Generation</td>
                        <td className="bg-color1 text-white">Conversion</td>
                        <td className="bg-color1 text-white">Referrals</td>
                        <td className="bg-color1 text-white">Purchase Frequency</td>
                        <td className="bg-color1 text-white">Buying Lifetime</td>
                        <td className="bg-color1 text-white">Reduce Attrition</td>
                        <td className="bg-color1 text-white">Reactivation</td>
                        <td className="bg-color1 text-white">Transaction Value</td>
                        <td className="bg-color1 text-white">Profit Margin</td>
                        {/* <td className="bg-color1 text-white">Reaches Target Market</td> */}
                        <td className="bg-color3 text-white">Score</td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5">1</td>
                        <td className="bg-color2" colSpan={2}>
                            <input type="text" className="form-control inputText"
                                id="AllianceEvaluationD19"
                                value={Inputs.AllianceEvaluationD19}
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationD19: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder={"Partner 1"} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationE19" 
                                value={Inputs.AllianceEvaluationE19} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationE19: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationF19" 
                                value={Inputs.AllianceEvaluationF19} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationF19: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationG19" 
                                value={Inputs.AllianceEvaluationG19} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationG19: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationH19" 
                                value={Inputs.AllianceEvaluationH19} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationH19: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationI19" 
                                value={Inputs.AllianceEvaluationI19} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationI19: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationJ19" 
                                value={Inputs.AllianceEvaluationJ19} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationJ19: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationK19" 
                                value={Inputs.AllianceEvaluationK19} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationK19: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationL19" 
                                value={Inputs.AllianceEvaluationL19} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationL19: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationM19" 
                                value={Inputs.AllianceEvaluationM19} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationM19: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color3 text-white text-center-c">
                            {Number(Inputs.AllianceEvaluationN19).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5">2</td>
                        <td className="bg-color2" colSpan={2}>
                            <input type="text" className="form-control inputText"
                                id="AllianceEvaluationD20"
                                value={Inputs.AllianceEvaluationD20}
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationD20: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder={"Partner 2"} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationE20" 
                                value={Inputs.AllianceEvaluationE20} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationE20: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationF20" 
                                value={Inputs.AllianceEvaluationF20} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationF20: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationG20" 
                                value={Inputs.AllianceEvaluationG20} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationG20: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationH20" 
                                value={Inputs.AllianceEvaluationH20} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationH20: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationI20" 
                                value={Inputs.AllianceEvaluationI20} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationI20: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationJ20" 
                                value={Inputs.AllianceEvaluationJ20} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationJ20: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationK20" 
                                value={Inputs.AllianceEvaluationK20} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationK20: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationL20" 
                                value={Inputs.AllianceEvaluationL20} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationL20: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationM20" 
                                value={Inputs.AllianceEvaluationM20} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationM20: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color3 text-white text-center-c">
                            {Number(Inputs.AllianceEvaluationN20).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5">3</td>
                        <td className="bg-color2" colSpan={2}>
                            <input type="text" className="form-control inputText"
                                id="AllianceEvaluationD21"
                                value={Inputs.AllianceEvaluationD21}
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationD21: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder={"Partner 3"} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationE21" 
                                value={Inputs.AllianceEvaluationE21} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationE21: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationF21" 
                                value={Inputs.AllianceEvaluationF21} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationF21: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationG21" 
                                value={Inputs.AllianceEvaluationG21} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationG21: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationH21" 
                                value={Inputs.AllianceEvaluationH21} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationH21: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationI21" 
                                value={Inputs.AllianceEvaluationI21} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationI21: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationJ21" 
                                value={Inputs.AllianceEvaluationJ21} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationJ21: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationK21" 
                                value={Inputs.AllianceEvaluationK21} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationK21: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationL21" 
                                value={Inputs.AllianceEvaluationL21} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationL21: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationM21" 
                                value={Inputs.AllianceEvaluationM21} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationM21: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color3 text-white text-center-c">
                            {Number(Inputs.AllianceEvaluationN21).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5">4</td>
                        <td className="bg-color2" colSpan={2}>
                            <input type="text" className="form-control inputText"
                                id="AllianceEvaluationD22"
                                value={Inputs.AllianceEvaluationD22}
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationD22: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder={"Partner 4"} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationE22" 
                                value={Inputs.AllianceEvaluationE22} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationE22: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationF22" 
                                value={Inputs.AllianceEvaluationF22} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationF22: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationG22" 
                                value={Inputs.AllianceEvaluationG22} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationG22: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationH22" 
                                value={Inputs.AllianceEvaluationH22} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationH22: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationI22" 
                                value={Inputs.AllianceEvaluationI22} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationI22: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationJ22" 
                                value={Inputs.AllianceEvaluationJ22} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationJ22: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationK22" 
                                value={Inputs.AllianceEvaluationK22} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationK22: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationL22" 
                                value={Inputs.AllianceEvaluationL22} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationL22: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationM22" 
                                value={Inputs.AllianceEvaluationM22} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationM22: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color3 text-white text-center-c">
                            {Number(Inputs.AllianceEvaluationN22).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5">5</td>
                        <td className="bg-color2" colSpan={2}>
                            <input type="text" className="form-control inputText"
                                id="AllianceEvaluationD23"
                                value={Inputs.AllianceEvaluationD23}
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationD23: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder={"Partner 5"} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationE23" 
                                value={Inputs.AllianceEvaluationE23} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationE23: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationF23" 
                                value={Inputs.AllianceEvaluationF23} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationF23: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationG23" 
                                value={Inputs.AllianceEvaluationG23} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationG23: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationH23" 
                                value={Inputs.AllianceEvaluationH23} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationH23: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationI23" 
                                value={Inputs.AllianceEvaluationI23} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationI23: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationJ23" 
                                value={Inputs.AllianceEvaluationJ23} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationJ23: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationK23" 
                                value={Inputs.AllianceEvaluationK23} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationK23: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationL23" 
                                value={Inputs.AllianceEvaluationL23} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationL23: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationM23" 
                                value={Inputs.AllianceEvaluationM23} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationM23: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color3 text-white text-center-c">
                            {Number(Inputs.AllianceEvaluationN23).toLocaleString('en-US')}
                        </td>
                        </tr>
                    </tbody>
                    </table> 
                    {/* table 3 */}
                    <table className="table mb-0 mt-3">
                    <tbody>
                        <tr>
                        <td className="bg-color1 text-white" colSpan={3} rowSpan={2}>
                            <div className="vendor-div-main" style={{width: 250}}>
                            <span className="text-center-c width-100 d-block font-size-20">Potential Alliance Partner</span>
                            </div>
                        </td>
                        <td className="bg-color3 text-white text-center-c" colSpan={6}>Return On Investment Potential</td>
                        </tr>
                        <tr>
                        <td className="bg-color1 text-white text-center-c width-10">Growth Driver Impact</td>
                        <td className="bg-color1 text-white text-center-c">Short-Term ROI Potential</td>
                        <td className="bg-color1 text-white text-center-c">Long-Term ROI Potential</td>
                        <td className="bg-color1 text-white text-center-c">Cost to Implement</td>
                        <td className="bg-color1 text-white text-center-c">Access to Customers</td>
                        <td className="bg-color1 text-white text-center-c">Program is Repeatable</td>
                        <td className="bg-color3 text-white text-center-c width-10">ROI</td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5 text-center-c">1</td>
                        <td className="bg-color2" colSpan={2}>
                            <input type="text" className="form-control inputText"
                                id="AllianceEvaluationD27"
                                value={Inputs.AllianceEvaluationD27}
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationD27: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder={"Partner 1"} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationE27" 
                                value={Inputs.AllianceEvaluationE27} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationE27: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationF27" 
                                value={Inputs.AllianceEvaluationF27} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationF27: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationG27" 
                                value={Inputs.AllianceEvaluationG27} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationG27: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationH27" 
                                value={Inputs.AllianceEvaluationH27} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationH27: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationI27" 
                                value={Inputs.AllianceEvaluationI27} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationI27: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationJ27" 
                                value={Inputs.AllianceEvaluationJ27} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationJ27: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color3 text-white text-center-c">
                            {Number(Inputs.AllianceEvaluationK27).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5 text-center-c">2</td>
                        <td className="bg-color2" colSpan={2}>
                            <input type="text" className="form-control inputText"
                                id="AllianceEvaluationD28"
                                value={Inputs.AllianceEvaluationD28}
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationD28: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder={"Partner 2"} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationE28" 
                                value={Inputs.AllianceEvaluationE28} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationE28: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationF28" 
                                value={Inputs.AllianceEvaluationF28} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationF28: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationG28" 
                                value={Inputs.AllianceEvaluationG28} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationG28: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationH28" 
                                value={Inputs.AllianceEvaluationH28} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationH28: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationI28" 
                                value={Inputs.AllianceEvaluationI28} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationI28: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationJ28" 
                                value={Inputs.AllianceEvaluationJ28} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationJ28: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color3 text-white text-center-c">
                            {Number(Inputs.AllianceEvaluationK28).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5 text-center-c">3</td>
                        <td className="bg-color2" colSpan={2}>
                            <input type="text" className="form-control inputText"
                                id="AllianceEvaluationD29"
                                value={Inputs.AllianceEvaluationD29}
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationD29: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder={"Partner 3"} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationE29" 
                                value={Inputs.AllianceEvaluationE29} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationE29: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationF29" 
                                value={Inputs.AllianceEvaluationF29} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationF29: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationG29" 
                                value={Inputs.AllianceEvaluationG29} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationG29: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationH29" 
                                value={Inputs.AllianceEvaluationH29} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationH29: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationI29" 
                                value={Inputs.AllianceEvaluationI29} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationI29: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationJ29" 
                                value={Inputs.AllianceEvaluationJ29} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationJ29: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color3 text-white text-center-c">
                            {Number(Inputs.AllianceEvaluationK29).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5 text-center-c">4</td>
                        <td className="bg-color2" colSpan={2}>
                            <input type="text" className="form-control inputText"
                                id="AllianceEvaluationD30"
                                value={Inputs.AllianceEvaluationD30}
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationD30: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder={"Partner 4"} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationE30" 
                                value={Inputs.AllianceEvaluationE30} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationE30: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationF30" 
                                value={Inputs.AllianceEvaluationF30} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationF30: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationG30" 
                                value={Inputs.AllianceEvaluationG30} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationG30: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationH30" 
                                value={Inputs.AllianceEvaluationH30} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationH30: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationI30" 
                                value={Inputs.AllianceEvaluationI30} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationI30: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationJ30" 
                                value={Inputs.AllianceEvaluationJ30} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationJ30: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color3 text-white text-center-c">
                            {Number(Inputs.AllianceEvaluationK30).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5 text-center-c">5</td>
                        <td className="bg-color2" colSpan={2}>
                            <input type="text" className="form-control inputText"
                                id="AllianceEvaluationD31"
                                value={Inputs.AllianceEvaluationD31}
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationD31: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder={"Partner 5"} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationE31" 
                                value={Inputs.AllianceEvaluationE31} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationE31: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationF31" 
                                value={Inputs.AllianceEvaluationF31} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationF31: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationG31" 
                                value={Inputs.AllianceEvaluationG31} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationG31: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationH31" 
                                value={Inputs.AllianceEvaluationH31} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationH31: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationI31" 
                                value={Inputs.AllianceEvaluationI31} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationI31: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationJ31" 
                                value={Inputs.AllianceEvaluationJ31} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationJ31: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color3 text-white text-center-c">
                            {Number(Inputs.AllianceEvaluationK31).toLocaleString('en-US')}
                        </td>
                        </tr>
                    </tbody>
                    </table> 
                    {/* table 4 */}
                    <table className="table mb-0 mt-3">
                    <tbody>
                        <tr>
                        <td className="bg-color1 text-white" colSpan={3} rowSpan={2}>
                            <div className="vendor-div-main" style={{width: 250}}>
                            <span className="text-center-c width-100 d-block font-size-20">Potential Alliance Partner</span>
                            </div>
                        </td>
                        <td className="bg-color3 text-white text-center-c" colSpan={6}>Ability to Execute</td>
                        </tr>
                        <tr>
                        <td className="bg-color1 text-white text-center-c width-10">Organizational Readiness</td>
                        <td className="bg-color1 text-white text-center-c">Leadership On-Board</td>
                        <td className="bg-color1 text-white text-center-c">Partner Marketing Systems</td>
                        <td className="bg-color1 text-white text-center-c">Capabilities to Execute</td>
                        <td className="bg-color1 text-white text-center-c">Partner Resilience</td>
                        <td className="bg-color1 text-white text-center-c">Potential Risk</td>
                        <td className="bg-color3 text-white text-center-c width-10">Score</td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5 text-center-c">1</td>
                        <td className="bg-color2" colSpan={2}>
                            <input type="text" className="form-control inputText"
                                id="AllianceEvaluationD35"
                                value={Inputs.AllianceEvaluationD35}
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationD35: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder={"Partner 1"} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationE35" 
                                value={Inputs.AllianceEvaluationE35} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationE35: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationF35" 
                                value={Inputs.AllianceEvaluationF35} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationF35: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationG35" 
                                value={Inputs.AllianceEvaluationG35} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationG35: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationH35" 
                                value={Inputs.AllianceEvaluationH35} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationH35: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationI35" 
                                value={Inputs.AllianceEvaluationI35} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationI35: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationJ35" 
                                value={Inputs.AllianceEvaluationJ35} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationJ35: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color3 text-white text-center-c">
                            {Number(Inputs.AllianceEvaluationK35).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5 text-center-c">2</td>
                        <td className="bg-color2" colSpan={2}>
                            <input type="text" className="form-control inputText"
                                id="AllianceEvaluationD36"
                                value={Inputs.AllianceEvaluationD36}
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationD36: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder={"Partner 2"} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationE36" 
                                value={Inputs.AllianceEvaluationE36} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationE36: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationF36" 
                                value={Inputs.AllianceEvaluationF36} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationF36: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationG36" 
                                value={Inputs.AllianceEvaluationG36} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationG36: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationH36" 
                                value={Inputs.AllianceEvaluationH36} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationH36: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationI36" 
                                value={Inputs.AllianceEvaluationI36} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationI36: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationJ36" 
                                value={Inputs.AllianceEvaluationJ36} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationJ36: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color3 text-white text-center-c">
                            {Number(Inputs.AllianceEvaluationK36).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5 text-center-c">3</td>
                        <td className="bg-color2" colSpan={2}>
                            <input type="text" className="form-control inputText"
                                id="AllianceEvaluationD37"
                                value={Inputs.AllianceEvaluationD37}
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationD37: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder={"Partner 3"} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationE37" 
                                value={Inputs.AllianceEvaluationE37} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationE37: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationF37" 
                                value={Inputs.AllianceEvaluationF37} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationF37: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationG37" 
                                value={Inputs.AllianceEvaluationG37} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationG37: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationH37" 
                                value={Inputs.AllianceEvaluationH37} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationH37: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationI37" 
                                value={Inputs.AllianceEvaluationI37} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationI37: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationJ37" 
                                value={Inputs.AllianceEvaluationJ37} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationJ37: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color3 text-white text-center-c">
                            {Number(Inputs.AllianceEvaluationK37).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5 text-center-c">4</td>
                        <td className="bg-color2" colSpan={2}>
                            <input type="text" className="form-control inputText"
                                id="AllianceEvaluationD38"
                                value={Inputs.AllianceEvaluationD38}
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationD38: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder={"Partner 4"} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationE38" 
                                value={Inputs.AllianceEvaluationE38} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationE38: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationF38" 
                                value={Inputs.AllianceEvaluationF38} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationF38: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationG38" 
                                value={Inputs.AllianceEvaluationG38} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationG38: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationH38" 
                                value={Inputs.AllianceEvaluationH38} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationH38: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationI38" 
                                value={Inputs.AllianceEvaluationI38} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationI38: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationJ38" 
                                value={Inputs.AllianceEvaluationJ38} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationJ38: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color3 text-white text-center-c">
                            {Number(Inputs.AllianceEvaluationK38).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5 text-center-c">5</td>
                        <td className="bg-color2" colSpan={2}>
                            <input type="text" className="form-control inputText"
                                id="AllianceEvaluationD39"
                                value={Inputs.AllianceEvaluationD39}
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationD39: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder={"Partner 5"} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationE39" 
                                value={Inputs.AllianceEvaluationE39} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationE39: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationF39" 
                                value={Inputs.AllianceEvaluationF39} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationF39: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationG39" 
                                value={Inputs.AllianceEvaluationG39} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationG39: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationH39" 
                                value={Inputs.AllianceEvaluationH39} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationH39: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationI39" 
                                value={Inputs.AllianceEvaluationI39} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationI39: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AllianceEvaluationJ39" 
                                value={Inputs.AllianceEvaluationJ39} 
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationJ39: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="10" 
                                onKeyPress={(event) => {
                                if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if(event.target.value !== '-' && event.target.value !== '+' && isNaN(event.target.value)){
                                    event.preventDefault();
                                    alert.show('Please enter a valid number.')
                                }
                                }} />
                        </td>
                        <td className="bg-color3 text-white text-center-c">
                            {Number(Inputs.AllianceEvaluationK39).toLocaleString('en-US')}
                        </td>
                        </tr>
                    </tbody>
                    </table> 
                    {/* table 5 */}
                    <table className="table mb-0 mt-3">
                    <tbody>
                        <tr>
                        <td className="bg-color1 text-white" colSpan={3} rowSpan={2}>
                            <div className="vendor-div-main">
                            <span className="text-center-c width-100 d-block font-size-20">Potential Alliance Partner</span>
                            </div>
                        </td>
                        <td className="bg-color3 text-white text-center-c" colSpan={4}>Total Impact of Partnership</td>
                        </tr>
                        <tr>
                        <td className="bg-color1 text-white text-center-c ">Strategic Fit</td>
                        <td className="bg-color1 text-white text-center-c">ROI Potential</td>
                        <td className="bg-color1 text-white text-center-c">Growth Driver Impact</td>
                        <td className="bg-color1 text-white text-center-c">Ability to Execute</td>
                        <td className="bg-color1 text-white text-center-c">Score</td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5 text-center-c">1</td>
                        <td className="bg-color2" colSpan={2}>
                            <input type="text" className="form-control inputText"
                                id="AllianceEvaluationD44"
                                value={Inputs.AllianceEvaluationD44}
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationD44: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder={"Partner 1"} />

                        </td>
                        <td className="bg-color2 text-center-c">
                            {Number(Inputs.AllianceEvaluationE44).toLocaleString('en-US')}
                        </td>
                        <td className="bg-color2 text-center-c">
                            {Number(Inputs.AllianceEvaluationF44).toLocaleString('en-US')}
                        </td>
                        <td className="bg-color2 text-center-c">
                            {Number(Inputs.AllianceEvaluationG44).toLocaleString('en-US')}
                        </td>
                        <td className="bg-color2 text-center-c">
                            {Number(Inputs.AllianceEvaluationH44).toLocaleString('en-US')}
                        </td>
                        <td className="bg-color2 text-center-c">
                            {Number(Inputs.AllianceEvaluationI44).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5 text-center-c">2</td>
                        <td className="bg-color2" colSpan={2}>
                            <input type="text" className="form-control inputText"
                                id="AllianceEvaluationD45"
                                value={Inputs.AllianceEvaluationD45}
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationD45: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder={"Partner 2"} />

                        </td>
                        <td className="bg-color2 text-center-c">
                            {Number(Inputs.AllianceEvaluationE45).toLocaleString('en-US')}
                        </td>
                        <td className="bg-color2 text-center-c">
                            {Number(Inputs.AllianceEvaluationF45).toLocaleString('en-US')}
                        </td>
                        <td className="bg-color2 text-center-c">
                            {Number(Inputs.AllianceEvaluationG45).toLocaleString('en-US')}
                        </td>
                        <td className="bg-color2 text-center-c">
                            {Number(Inputs.AllianceEvaluationH45).toLocaleString('en-US')}
                        </td>
                        <td className="bg-color2 text-center-c">
                            {Number(Inputs.AllianceEvaluationI45).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5 text-center-c">3</td>
                        <td className="bg-color2" colSpan={2}>
                            <input type="text" className="form-control inputText"
                                id="AllianceEvaluationD46"
                                value={Inputs.AllianceEvaluationD46}
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationD46: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder={"Partner 3"} />

                        </td>
                        <td className="bg-color2 text-center-c">
                            {Number(Inputs.AllianceEvaluationE46).toLocaleString('en-US')}
                        </td>
                        <td className="bg-color2 text-center-c">
                            {Number(Inputs.AllianceEvaluationF46).toLocaleString('en-US')}
                        </td>
                        <td className="bg-color2 text-center-c">
                            {Number(Inputs.AllianceEvaluationG46).toLocaleString('en-US')}
                        </td>
                        <td className="bg-color2 text-center-c">
                            {Number(Inputs.AllianceEvaluationH46).toLocaleString('en-US')}
                        </td>
                        <td className="bg-color2 text-center-c">
                            {Number(Inputs.AllianceEvaluationI46).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5 text-center-c">4</td>
                        <td className="bg-color2" colSpan={2}>
                            <input type="text" className="form-control inputText"
                                id="AllianceEvaluationD47"
                                value={Inputs.AllianceEvaluationD47}
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationD47: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder={"Partner 4"} />

                        </td>
                        <td className="bg-color2 text-center-c">
                            {Number(Inputs.AllianceEvaluationE47).toLocaleString('en-US')}
                        </td>
                        <td className="bg-color2 text-center-c">
                            {Number(Inputs.AllianceEvaluationF47).toLocaleString('en-US')}
                        </td>
                        <td className="bg-color2 text-center-c">
                            {Number(Inputs.AllianceEvaluationG47).toLocaleString('en-US')}
                        </td>
                        <td className="bg-color2 text-center-c">
                            {Number(Inputs.AllianceEvaluationH47).toLocaleString('en-US')}
                        </td>
                        <td className="bg-color2 text-center-c">
                            {Number(Inputs.AllianceEvaluationI47).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5 text-center-c">5</td>
                        <td className="bg-color2" colSpan={2}>
                            <input type="text" className="form-control inputText"
                                id="AllianceEvaluationD48"
                                value={Inputs.AllianceEvaluationD48}
                                onChange={(event) => setInputs({ ...Inputs, AllianceEvaluationD48: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder={"Partner 5"} />

                        </td>
                        <td className="bg-color2 text-center-c">
                            {Number(Inputs.AllianceEvaluationE48).toLocaleString('en-US')}
                        </td>
                        <td className="bg-color2 text-center-c">
                            {Number(Inputs.AllianceEvaluationF48).toLocaleString('en-US')}
                        </td>
                        <td className="bg-color2 text-center-c">
                            {Number(Inputs.AllianceEvaluationG48).toLocaleString('en-US')}
                        </td>
                        <td className="bg-color2 text-center-c">
                            {Number(Inputs.AllianceEvaluationH48).toLocaleString('en-US')}
                        </td>
                        <td className="bg-color2 text-center-c">
                            {Number(Inputs.AllianceEvaluationI48).toLocaleString('en-US')}
                        </td>
                        </tr>
                    </tbody>
                    </table>                              
                </div>
                </div>
            </div>
        </div>

    </div>
  )
}
