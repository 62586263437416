import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state/index';
import  UserDropdown  from '../UserDropdown';

import { getBenchmarkScoreGapAnalysis } from "../../state/features/BenchmarkScoreGapAnalysisSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import { useAlert } from 'react-alert'

export default function DashbaordContent() {
  const [isLoading, setIsLoading] = useState(false);
  const alert = useAlert();
  const { message } = useSelector((state) => state.message);
  const users = useSelector(userData);
  const user_id = users.data.profile.id;
  
  const dispatch = useDispatch();
  const benchmarkScoreGapAnalysis = useSelector(state => state.benchmarkScoreGapAnalysis)
  const { benchmarkScoreGapAnalysisData } = bindActionCreators(actionCreators, dispatch)

  const [Inputs, setInputs] = useState({
    benchmarkScoreGapAnalysisM20: 0,
    benchmarkScoreGapAnalysisR13: 0,
    benchmarkScoreGapAnalysisR14: 0,
    benchmarkScoreGapAnalysisR15: 0,
    benchmarkScoreGapAnalysisR16: 0,
    benchmarkScoreGapAnalysisR17: 0,
    benchmarkScoreGapAnalysisR18: 0,
    benchmarkScoreGapAnalysisR19: 0,
    benchmarkScoreGapAnalysisR20: 0,
    benchmarkScoreGapAnalysisR21: 0,
    benchmarkScoreGapAnalysisR22: 0,
    benchmarkScoreGapAnalysisR23: 0,
    benchmarkScoreGapAnalysisR24: 0
});

useEffect(() => {
  console.log('R23 set...', Inputs)
  benchmarkScoreGapAnalysisData("benchmarkScoreGapAnalysisAll", {
      benchmarkScoreGapAnalysisR13 : Inputs.benchmarkScoreGapAnalysisR13, benchmarkScoreGapAnalysisR14 : Inputs.benchmarkScoreGapAnalysisR14, benchmarkScoreGapAnalysisR15 : Inputs.benchmarkScoreGapAnalysisR15, benchmarkScoreGapAnalysisR16 : Inputs.benchmarkScoreGapAnalysisR16, benchmarkScoreGapAnalysisR17 : Inputs.benchmarkScoreGapAnalysisR17, benchmarkScoreGapAnalysisR18 : Inputs.benchmarkScoreGapAnalysisR18, benchmarkScoreGapAnalysisR19 : Inputs.benchmarkScoreGapAnalysisR19, benchmarkScoreGapAnalysisR20 : Inputs.benchmarkScoreGapAnalysisR20, benchmarkScoreGapAnalysisR21 : Inputs.benchmarkScoreGapAnalysisR21, benchmarkScoreGapAnalysisR22 : Inputs.benchmarkScoreGapAnalysisR22, benchmarkScoreGapAnalysisR23 : Inputs.benchmarkScoreGapAnalysisR23,
      benchmarkScoreGapAnalysisM20 : Inputs.benchmarkScoreGapAnalysisM20
  });
}, [Inputs.benchmarkScoreGapAnalysisM20]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(clearMessage());
    dispatch(getBenchmarkScoreGapAnalysis({user_id}))
    .unwrap()
    .then((response) => {
        const newBenchmarkScoreObj = { ...Inputs }
        let indexR = 12;
        let sbqUserTotalScore = 0;
        let bqTotalUserScoreList = response.data.benchmarkTotalUserScoreList ? response.data.benchmarkTotalUserScoreList : '';
        if(bqTotalUserScoreList){
            bqTotalUserScoreList.map((scoreList, scoreKey) => {
                let bqUserScore = scoreList.user_score ? scoreList.user_score : 0;
                let bqTarget = scoreList.bq_total_score ? scoreList.bq_total_score : 0;
                let bqGrade = Math.round(parseFloat(bqUserScore) / parseFloat(bqTarget) * 100);
                indexR = indexR + 1;
                newBenchmarkScoreObj["benchmarkScoreGapAnalysisR"+indexR] = bqGrade;
                sbqUserTotalScore = sbqUserTotalScore + parseFloat(bqUserScore);
                console.log('--loop--')
                if((scoreKey+1) == bqTotalUserScoreList.length){
                    newBenchmarkScoreObj["benchmarkScoreGapAnalysisM20"] = sbqUserTotalScore;
                    setInputs(newBenchmarkScoreObj);
                    console.log('key--', scoreKey);
                    setIsLoading(false)   // Hide loading screen
                    return response
                }
            })
        }else{
            setIsLoading(false)   // Hide loading screen
            return response;
        }          
    })
    .catch((error) => {
      console.log("error",error)
      setIsLoading(false)   // Hide loading screen 
        { message && alert.show(message) }
    });

  }, [dispatch])
 
  const { benchmarkScoreGapAnalysisM20,benchmarkScoreGapAnalysisR13,benchmarkScoreGapAnalysisR14,benchmarkScoreGapAnalysisR15,benchmarkScoreGapAnalysisR16,benchmarkScoreGapAnalysisR17,benchmarkScoreGapAnalysisR18,benchmarkScoreGapAnalysisR19,benchmarkScoreGapAnalysisR20,benchmarkScoreGapAnalysisR21,benchmarkScoreGapAnalysisR22,benchmarkScoreGapAnalysisR23 } = benchmarkScoreGapAnalysis

    return (
      <div className="content-body content-body-new bg-white-color">
        <UserDropdown />
        <div className="container-fluid">
          <div className="head-title">
            <h3 className='preheading'>State of the Business</h3>
            <h1 className='mainheading mb-1'>Benchmark Scorecard</h1>
          </div>
          <div className='row mb-3' style={{maxWidth: 1200}}>
            <div className='col-12 col-lg-3'>
              <div className="bench1 mb-3">
                <p className='fontExtraBold ms-text-right'>Overall<br/>Benchmark Score</p>
                <div className="benchbody" style={{height: 150, display: 'grid'}}>
                  <h2 className="d-flex justify-content-between align-items-center font48px text-white fontExtraBold">
                    <img src={window.location.origin+"/images/benchmarkscore/image765.png"} alt="img1" style={{width: 100, height: 100, imageRendering: 'crisp-edges'}} />
                    {Number(benchmarkScoreGapAnalysisM20).toLocaleString('en-US')}
                  </h2>
                </div>
              </div>
            </div>
            <div className='col-12 col-lg-9 d-grid align-items-end'>
              <h3 className='font20px fontExtraBold'>Short-Term Growth  (revenue)</h3>
              <div className='row'>
                <div className='col-12 col-md-6 col-lg-4'>
                  <div className="bench1 mb-3 green">
                    <p className='ms-text-right'>Customer Acquisition</p>
                    <div className="benchbody">
                      <h2 className="d-flex justify-content-between align-items-center font36px fontExtraBold">
                        <img src={window.location.origin+"/images/benchmarkscore/image770.png"} alt="img" style={{width: 80, height: 80, imageRendering: 'crisp-edges'}} />
                        {Number(benchmarkScoreGapAnalysisR15).toLocaleString('en-US')}
                      </h2>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6 col-lg-4'>
                  <div className="bench1 mb-3 green">
                    <p className='ms-text-right'>Customer Retention</p>
                    <div className="benchbody">
                      <h2 className="d-flex justify-content-between align-items-center font36px fontExtraBold">
                        <img src={window.location.origin+"/images/benchmarkscore/image774.png"} alt="img" style={{width: 80, height: 80, imageRendering: 'crisp-edges'}} />
                        {Number(benchmarkScoreGapAnalysisR16).toLocaleString('en-US')}
                      </h2>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6 col-lg-4'>
                  <div className="bench1 mb-3 green">
                    <p className='ms-text-right'>Customer Transactions</p>
                    <div className="benchbody">
                      <h2 className="d-flex justify-content-between align-items-center font36px fontExtraBold">
                        <img src={window.location.origin+"/images/benchmarkscore/image777.png"} alt="img" style={{width: 80, height: 80, imageRendering: 'crisp-edges'}} />
                        {Number(benchmarkScoreGapAnalysisR17).toLocaleString('en-US')}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row mb-3' style={{maxWidth: 1200}}>
            <div className='col-12 col-lg-6'>
              <h3 className='font20px fontExtraBold'>Strategic Foundation</h3>
              <div className='row'>
                <div className='col-12 col-md-6 col-lg-6'>
                  <div className="bench1 mb-3 darkblue">
                    <p className='ms-text-right'>Competitive Advantage</p>
                    <div className="benchbody">
                      <h2 className="d-flex justify-content-between align-items-center font36px fontExtraBold">
                        <img src={window.location.origin+"/images/benchmarkscore/image781.png"} alt="img" style={{width: 80, height: 80, imageRendering: 'crisp-edges'}} />
                        {Number(benchmarkScoreGapAnalysisR13).toLocaleString('en-US')}
                      </h2>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6 col-lg-6'>
                  <div className="bench1 mb-3 darkblue">
                    <p className='ms-text-right'>Financial Performance</p>
                    <div className="benchbody">
                      <h2 className="d-flex justify-content-between align-items-center font36px fontExtraBold">
                        <img src={window.location.origin+"/images/benchmarkscore/image784.png"} alt="img" style={{width: 80, height: 80, imageRendering: 'crisp-edges'}} />
                        {Number(benchmarkScoreGapAnalysisR14).toLocaleString('en-US')}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 col-lg-6'>
              <h3 className='font20px fontExtraBold'>Agility Engine</h3>
              <div className='row'>
                <div className='col-12 col-md-6 col-lg-6'>
                  <div className="bench1 mb-3 lightblue">
                    <p className='ms-text-right'>Ability</p>
                    <div className="benchbody">
                      <h2 className="d-flex justify-content-between align-items-center font36px fontExtraBold">
                        <img src={window.location.origin+"/images/benchmarkscore/image790.png"} alt="img" style={{width: 80, height: 80, imageRendering: 'crisp-edges'}} />
                        {Number(benchmarkScoreGapAnalysisR18).toLocaleString('en-US')}
                      </h2>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6 col-lg-6'>
                  <div className="bench1 mb-3 lightblue">
                    <p className='ms-text-right'>Agility</p>
                    <div className="benchbody">
                      <h2 className="d-flex justify-content-between align-items-center font36px fontExtraBold">
                        <img src={window.location.origin+"/images/benchmarkscore/image794.png"} alt="img" style={{width: 80, height: 80, imageRendering: 'crisp-edges'}} />
                        {Number(benchmarkScoreGapAnalysisR19).toLocaleString('en-US')}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className='row mb-3' style={{maxWidth: 1200}}>
            <h3 className='font20px fontExtraBold'>Enterprise Value (valuation)</h3>
            <div className='col-12 col-md-6 col-lg-3'>
              <div className="bench1 mb-3 black-bg">
                <p className='ms-text-right'>Strategic Value</p>
                <div className="benchbody">
                  <h2 className="d-flex justify-content-between align-items-center font36px fontExtraBold">
                    <img src={window.location.origin+"/images/benchmarkscore/image799.png"} alt="img" style={{width: 80, height: 80, imageRendering: 'crisp-edges'}} />
                    {Number(benchmarkScoreGapAnalysisR20).toLocaleString('en-US')}
                  </h2>
                </div>
              </div>
            </div>
            <div className='col-12 col-md-6 col-lg-3'>
              <div className="bench1 mb-3 black-bg">
                  <p className='ms-text-right'>Customers</p>
                  <div className="benchbody">
                    <h2 className="d-flex justify-content-between align-items-center font36px fontExtraBold">
                      <img src={window.location.origin+"/images/benchmarkscore/image802.png"} alt="img" style={{width: 80, height: 80, imageRendering: 'crisp-edges'}} />
                      {Number(benchmarkScoreGapAnalysisR21).toLocaleString('en-US')}
                    </h2>
                  </div>
                </div>
            </div>
            <div className='col-12 col-md-6 col-lg-3'>
              <div className="bench1 mb-3 black-bg">
                  <p className='ms-text-right'>Operations</p>
                  <div className="benchbody">
                    <h2 className="d-flex justify-content-between align-items-center font36px fontExtraBold">
                      <img src={window.location.origin+"/images/benchmarkscore/image805.png"} alt="img" style={{width: 80, height: 80, imageRendering: 'crisp-edges'}} />
                      {Number(benchmarkScoreGapAnalysisR22).toLocaleString('en-US')}
                    </h2>
                  </div>
                </div>
            </div>
            <div className='col-12 col-md-6 col-lg-3'>
              <div className="bench1 mb-3 black-bg">
                  <p className='ms-text-right'>Growth Platform</p>
                  <div className="benchbody">
                    <h2 className="d-flex justify-content-between align-items-center font36px fontExtraBold">
                      <img src={window.location.origin+"/images/benchmarkscore/image808.png"} alt="img" style={{width: 80, height: 80, imageRendering: 'crisp-edges'}} />
                      {Number(benchmarkScoreGapAnalysisR23).toLocaleString('en-US')}
                    </h2>
                  </div>
                </div>
            </div>
          </div>
          <div className="nextInput">
            <ul>
                <li>
                    <Link to="/state-of-the-business/growth-capabilities-gap-analysis" className='next-link'>Next: Capabilities Gap Analysis
                        <span className='icon-three'>
                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                        </span>
                    </Link>
                </li>
            </ul>
          </div>
        </div>
      </div>
                
    )
}
