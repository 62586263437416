import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getFiveStepStrategyGrowthPlanning, modifyFiveStepStrategyGrowthPlanning } from "../../state/features/fiveStepStrategySlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';

export default function FiveStepStrategyContentNew() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        fiveStepStrategyC13 : '',
        fiveStepStrategyC14 : '',
        fiveStepStrategyC15 : '',
        fiveStepStrategyC16 : '',
        fiveStepStrategyD13 : '',
        fiveStepStrategyD14 : '',
        fiveStepStrategyD15 : '',
        fiveStepStrategyD16 : '',
        fiveStepStrategyE13 : '',
        fiveStepStrategyE14 : '',
        fiveStepStrategyE15 : '',
        fiveStepStrategyE16 : '',
        fiveStepStrategyF13 : '',
        fiveStepStrategyF14 : '',
        fiveStepStrategyF15 : '',
        fiveStepStrategyF16 : '',
        fiveStepStrategyG13 : '',
        fiveStepStrategyG14 : '',
        fiveStepStrategyG15 : '',
        fiveStepStrategyG16 : ''
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getFiveStepStrategyGrowthPlanning({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.growthPlanning)
            if(response!= undefined){
                let growthPlanning = response.data.growthPlanning[0];
                console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key]
                    }
                }
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyFiveStepStrategyGrowthPlanning({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
            <div className="container-fluid">
                <div className="head-title">
                    <h3 className='preheading'>Tools - Competitive Strategy</h3>
                    <h1 className='mainheading mb-3'>Five-Step Strategy</h1>
                </div>
                <div className="customer-transformation">
                    <div className="customer-transformation-inner">
                        <Spinner/>
                        <div className="table-responsive">
                            <table className="table" style={{width: 1250}}>
                                <thead>
                                    <tr>
                                        <td className='' colSpan={3}>
                                            <p className='body-text color-dark'>
                                                <strong>Lafley & Martin’s Five Step Strategy Model</strong> requires a thorough understanding of the organization as well as the industry it resides in.  The model involves answering a series of questions which are designed to break business down into five strategic options to ensure success.
                                            </p>
                                        </td>
                                        <td className='hide-content' colSpan={3}></td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white' style={{width: 250}}>
                                            <div className="before-your d-flex justify-content-between">
                                                <p className="p-0 font14px">Winning Aspiration</p>
                                                <img src={window.location.origin+"/images/five-step-strategy/image1280.png"} alt="icon_01" />
                                            </div>
                                        </td>
                                        <td className='black-bg text-white' style={{width: 250}}>
                                            <div className="before-your d-flex justify-content-between">
                                                <p className="p-0 font14px">Where to Play</p>
                                                <img src={window.location.origin+"/images/five-step-strategy/image1281.png"} alt="icon_01" />
                                            </div>
                                        </td>
                                        <td className='black-bg text-white' style={{width: 250}}>
                                            <div className="before-your d-flex justify-content-between">
                                                <p className="p-0 font14px">How to Win</p>
                                                <img src={window.location.origin+"/images/five-step-strategy/image1282.png"} alt="icon_01" />
                                            </div>
                                        </td>
                                        <td className='black-bg text-white' style={{width: 250}}>
                                            <div className="before-your d-flex justify-content-between">
                                                <p className="p-0 font14px">Capabilities</p>
                                                <img src={window.location.origin+"/images/five-step-strategy/image1288.png"} alt="icon_01" />
                                            </div>
                                        </td>
                                        <td className='black-bg text-white' style={{width: 250}}>
                                            <div className="before-your d-flex justify-content-between">
                                                <p className="p-0 font14px">Management Systems</p>
                                                <img src={window.location.origin+"/images/five-step-strategy/image1290.png"} alt="icon_01" />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-03 text-white ms-text-left font12px'>
                                            What is your winning aspiration?
                                        </td>
                                        <td className='bg-color-03 text-white ms-text-left font12px'>
                                            In what markets will you play?
                                        </td>
                                        <td className='bg-color-03 text-white ms-text-left font12px'>
                                            How will you win in your chosen markets?
                                        </td>
                                        <td className='bg-color-03 text-white ms-text-left font12px'>
                                            What capabilities are required to win?
                                        </td>
                                        <td className='bg-color-03 text-white ms-text-left font12px'>
                                            What management systems are required?
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="bg-color-light-blue padding-0" style={{height: 100}}>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea className="field-ipt bg-color-light-blue font12px color-dark"
                                                    id="fiveStepStrategyC13"
                                                    value={Inputs.fiveStepStrategyC13}
                                                    onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyC13: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-light-blue padding-0" style={{height: 100}}>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea className="field-ipt bg-color-light-blue font12px color-dark"
                                                    id="fiveStepStrategyC14"
                                                    value={Inputs.fiveStepStrategyC14}
                                                    onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyC14: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-light-blue padding-0" style={{height: 100}}>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea className="field-ipt bg-color-light-blue font12px color-dark"
                                                    id="fiveStepStrategyC15"
                                                    value={Inputs.fiveStepStrategyC15}
                                                    onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyC15: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-light-blue padding-0" style={{height: 100}}>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea className="field-ipt bg-color-light-blue font12px color-dark"
                                                    id="fiveStepStrategyC16"
                                                    value={Inputs.fiveStepStrategyC16}
                                                    onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyC16: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-light-blue padding-0" style={{height: 100}}>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea className="field-ipt bg-color-light-blue font12px color-dark"
                                                    id="fiveStepStrategyD13"
                                                    value={Inputs.fiveStepStrategyD13}
                                                    onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyD13: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color-light-blue padding-0" style={{height: 100}}>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea className="field-ipt bg-color-light-blue font12px color-dark"
                                                    id="fiveStepStrategyD14"
                                                    value={Inputs.fiveStepStrategyD14}
                                                    onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyD14: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-light-blue padding-0" style={{height: 100}}>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea className="field-ipt bg-color-light-blue font12px color-dark"
                                                    id="fiveStepStrategyD15"
                                                    value={Inputs.fiveStepStrategyD15}
                                                    onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyD15: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-light-blue padding-0" style={{height: 100}}>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea className="field-ipt bg-color-light-blue font12px color-dark"
                                                    id="fiveStepStrategyD16"
                                                    value={Inputs.fiveStepStrategyD16}
                                                    onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyD16: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-light-blue padding-0" style={{height: 100}}>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea className="field-ipt bg-color-light-blue font12px color-dark"
                                                    id="fiveStepStrategyE13"
                                                    value={Inputs.fiveStepStrategyE13}
                                                    onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyE13: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-light-blue padding-0" style={{height: 100}}>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea className="field-ipt bg-color-light-blue font12px color-dark"
                                                    id="fiveStepStrategyE14"
                                                    value={Inputs.fiveStepStrategyE14}
                                                    onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyE14: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color-light-blue padding-0" style={{height: 100}}>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea className="field-ipt bg-color-light-blue font12px color-dark"
                                                    id="fiveStepStrategyE15"
                                                    value={Inputs.fiveStepStrategyE15}
                                                    onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyE15: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-light-blue padding-0" style={{height: 100}}>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea className="field-ipt bg-color-light-blue font12px color-dark"
                                                    id="fiveStepStrategyE16"
                                                    value={Inputs.fiveStepStrategyE16}
                                                    onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyE16: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-light-blue padding-0" style={{height: 100}}>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea className="field-ipt bg-color-light-blue font12px color-dark"
                                                    id="fiveStepStrategyF13"
                                                    value={Inputs.fiveStepStrategyF13}
                                                    onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyF13: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-light-blue padding-0" style={{height: 100}}>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea className="field-ipt bg-color-light-blue font12px color-dark"
                                                    id="fiveStepStrategyF14"
                                                    value={Inputs.fiveStepStrategyF14}
                                                    onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyF14: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-light-blue padding-0" style={{height: 100}}>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea className="field-ipt bg-color-light-blue font12px color-dark"
                                                    id="fiveStepStrategyF15"
                                                    value={Inputs.fiveStepStrategyF15}
                                                    onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyF15: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color-light-blue padding-0" style={{height: 100}}>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea className="field-ipt bg-color-light-blue font12px color-dark"
                                                    id="fiveStepStrategyF16"
                                                    value={Inputs.fiveStepStrategyF16}
                                                    onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyF16: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-light-blue padding-0" style={{height: 100}}>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea className="field-ipt bg-color-light-blue font12px color-dark"
                                                    id="fiveStepStrategyG13"
                                                    value={Inputs.fiveStepStrategyG13}
                                                    onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyG13: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-light-blue padding-0" style={{height: 100}}>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea className="field-ipt bg-color-light-blue font12px color-dark"
                                                    id="fiveStepStrategyG14"
                                                    value={Inputs.fiveStepStrategyG14}
                                                    onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyG14: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-light-blue padding-0" style={{height: 100}}>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea className="field-ipt bg-color-light-blue font12px color-dark"
                                                    id="fiveStepStrategyG15"
                                                    value={Inputs.fiveStepStrategyG15}
                                                    onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyG15: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-light-blue padding-0" style={{height: 100}}>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea className="field-ipt bg-color-light-blue font12px color-dark"
                                                    id="fiveStepStrategyG16"
                                                    value={Inputs.fiveStepStrategyG16}
                                                    onChange={(event) => setInputs({ ...Inputs, fiveStepStrategyG16: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
