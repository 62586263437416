import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getInsightsToAction, modifyInsightsToAction } from "../../state/features/InsightsToActionSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';

export default function InsightsToActionToImpactWorksheetContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        InsightsToActionC11 : '',
        InsightsToActionI11 : '',
        InsightsToActionC13 : '',
        InsightsToActionI13 : '',
        InsightsToActionC15 : '',
        InsightsToActionI15 : '',
        InsightsToActionC17 : '',
        InsightsToActionI17 : '',
        InsightsToActionC19 : '',
        InsightsToActionI19 : '',
        InsightsToActionC21 : '',
        InsightsToActionI21 : '',
        InsightsToActionC23 : '',
        InsightsToActionI23 : '',
        InsightsToActionC26 : '',
        InsightsToActionE26 : '',
        InsightsToActionI26 : '',
        InsightsToActionK26 : ''
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getInsightsToAction({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.revenueGrowth)
            if(response!= undefined  && response.data.revenueGrowth){
                let revenueGrowth = response.data.revenueGrowth[0];
                console.log(revenueGrowth)
                delete revenueGrowth.id;
                delete revenueGrowth.user_id;
                delete revenueGrowth.created_at;
                delete revenueGrowth.updated_at;
                const newObj = { ...Inputs }
                for (const key in revenueGrowth) {
                    if (revenueGrowth.hasOwnProperty(key)) {
                        if(revenueGrowth[key])
                        newObj[key] = revenueGrowth[key]
                    }
                }
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyInsightsToAction({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }
    
  return (
    <div className="content-body">
        <UserDropdown />
        {/* row */}
        <div className="container-fluid">
            <div className="head-title">
                <p className="mb-0">Innovation</p>
                <h2 className="mb-5 mt-0">Insights to Action to Impact Worksheet</h2>
            </div>
            <Spinner />
            <div className="card">
                <div className="card-body pl-0">
                <div className="table-responsive table-dta">
                    {/* table 1 */}
                    <table className="table mb-0">
                    <tbody>
                        <tr>
                        <th colSpan={10} className="bg-color1 text-white">Turn Insights into Action and Impact</th>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                        <td className="bg-color3 text-white">
                            <div className="insights-to-action">
                            <div className="White-Hat-title-agile">Insights</div>
                            <img src="../images/impect-worksheet/insights.png" className="img-fluid" alt="insights" />
                            </div>
                        </td>
                        <td className="width-10 bg-color7 d-hidden">
                            <div className="images-pa-insight">
                            <img src="../images/impect-worksheet/arrow.png" className="img-fluid" alt="arrow" />
                            </div>
                        </td>
                        <td className="bg-color3 text-white">
                            <div className="insights-to-action">
                            <div className="White-Hat-title-agile">Action</div>
                            <img src="../images/impect-worksheet/action.png" className="img-fluid" alt="action" />
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <td className=" text-white">
                            <div className="insights-to-action">
                            <div className="White-Hat-title-agile" />
                                <input type="text" className="form-control inputText"
                                    id="InsightsToActionC11"
                                    value={Inputs.InsightsToActionC11}
                                    onChange={(event) => setInputs({ ...Inputs, InsightsToActionC11 : event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        <td className="width-10 bg-color7 text-center-c">
                            <div className="images-pa-insight">
                            <img src="../images/impect-worksheet/arrow.png" className="img-fluid" alt="arrow" />
                            </div>
                        </td>
                        <td className="text-white">
                            <div className="insights-to-action">
                            <div className="White-Hat-title-agile" />
                                <input type="text" className="form-control inputText"
                                    id="InsightsToActionI11"
                                    value={Inputs.InsightsToActionI11}
                                    onChange={(event) => setInputs({ ...Inputs, InsightsToActionI11 : event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <td className=" text-white">
                            <div className="insights-to-action">
                            <div className="White-Hat-title-agile" />
                                <input type="text" className="form-control inputText"
                                    id="InsightsToActionC13"
                                    value={Inputs.InsightsToActionC13}
                                    onChange={(event) => setInputs({ ...Inputs, InsightsToActionC13 : event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        <td className="width-10 bg-color7 text-center-c d-hidden">
                            <div className="images-pa-insight">
                            <img src="../images/impect-worksheet/arrow.png" className="img-fluid" alt="arrow" />
                            </div>
                        </td>
                        <td className="text-white">
                            <div className="insights-to-action">
                            <div className="White-Hat-title-agile" />
                                <input type="text" className="form-control inputText"
                                    id="InsightsToActionI13"
                                    value={Inputs.InsightsToActionI13}
                                    onChange={(event) => setInputs({ ...Inputs, InsightsToActionI13 : event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <td className=" text-white">
                            <div className="insights-to-action">
                            <div className="White-Hat-title-agile" />
                                <input type="text" className="form-control inputText"
                                    id="InsightsToActionC15"
                                    value={Inputs.InsightsToActionC15}
                                    onChange={(event) => setInputs({ ...Inputs, InsightsToActionC15 : event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        <td className="width-10 bg-color7 text-center-c">
                            <div className="images-pa-insight">
                            <img src="../images/impect-worksheet/arrow.png" className="img-fluid" alt="arrow" />
                            </div>
                        </td>
                        <td className="text-white">
                            <div className="insights-to-action">
                            <div className="White-Hat-title-agile" />
                                <input type="text" className="form-control inputText"
                                    id="InsightsToActionI15"
                                    value={Inputs.InsightsToActionI15}
                                    onChange={(event) => setInputs({ ...Inputs, InsightsToActionI15 : event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <td className=" text-white">
                            <div className="insights-to-action">
                            <div className="White-Hat-title-agile" />
                                <input type="text" className="form-control inputText"
                                    id="InsightsToActionC17"
                                    value={Inputs.InsightsToActionC17}
                                    onChange={(event) => setInputs({ ...Inputs, InsightsToActionC17 : event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        <td className="width-10 bg-color7 text-center-c d-hidden">
                            <div className="images-pa-insight">
                            <img src="../images/impect-worksheet/arrow.png" className="img-fluid" alt="arrow" />
                            </div>
                        </td>
                        <td className="text-white">
                            <div className="insights-to-action">
                            <div className="White-Hat-title-agile" />
                                <input type="text" className="form-control inputText"
                                    id="InsightsToActionI17"
                                    value={Inputs.InsightsToActionI17}
                                    onChange={(event) => setInputs({ ...Inputs, InsightsToActionI17 : event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <td className=" text-white">
                            <div className="insights-to-action">
                            <div className="White-Hat-title-agile" />
                                <input type="text" className="form-control inputText"
                                    id="InsightsToActionC19"
                                    value={Inputs.InsightsToActionC19}
                                    onChange={(event) => setInputs({ ...Inputs, InsightsToActionC19 : event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        <td className="width-10 bg-color7 text-center-c">
                            <div className="images-pa-insight">
                            <img src="../images/impect-worksheet/arrow.png" className="img-fluid" alt="arrow" />
                            </div>
                        </td>
                        <td className="text-white">
                            <div className="insights-to-action">
                            <div className="White-Hat-title-agile" />
                                <input type="text" className="form-control inputText"
                                    id="InsightsToActionI19"
                                    value={Inputs.InsightsToActionI19}
                                    onChange={(event) => setInputs({ ...Inputs, InsightsToActionI19 : event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <td className=" text-white">
                            <div className="insights-to-action">
                            <div className="White-Hat-title-agile" />
                                <input type="text" className="form-control inputText"
                                    id="InsightsToActionC21"
                                    value={Inputs.InsightsToActionC21}
                                    onChange={(event) => setInputs({ ...Inputs, InsightsToActionC21 : event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        <td className="width-10 bg-color7 text-center-c d-hidden">
                            <div className="images-pa-insight">
                            <img src="../images/impect-worksheet/arrow.png" className="img-fluid" alt="arrow" />
                            </div>
                        </td>
                        <td className="text-white">
                            <div className="insights-to-action">
                            <div className="White-Hat-title-agile" />
                                <input type="text" className="form-control inputText"
                                    id="InsightsToActionI21"
                                    value={Inputs.InsightsToActionI21}
                                    onChange={(event) => setInputs({ ...Inputs, InsightsToActionI21 : event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <td className=" text-white">
                            <div className="insights-to-action">
                            <div className="White-Hat-title-agile" />
                                <input type="text" className="form-control inputText"
                                    id="InsightsToActionC23"
                                    value={Inputs.InsightsToActionC23}
                                    onChange={(event) => setInputs({ ...Inputs, InsightsToActionC23 : event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        <td className="width-10 bg-color7 text-center-c">
                            <div className="images-pa-insight">
                            <img src="../images/impect-worksheet/arrow.png" className="img-fluid" alt="arrow" />
                            </div>
                        </td>
                        <td className="text-white">
                            <div className="insights-to-action">
                            <div className="White-Hat-title-agile" />
                                <input type="text" className="form-control inputText"
                                    id="InsightsToActionI23"
                                    value={Inputs.InsightsToActionI23}
                                    onChange={(event) => setInputs({ ...Inputs, InsightsToActionI23 : event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                    {/* table 2 */}
                    <table className="table mb-0">
                    <tbody>
                        <tr>
                        <th colSpan={13} className="bg-color1 text-white">
                            <div className="insights-to-action-d">
                            <div className="image-insghts">
                                <img src="../images/impect-worksheet/potential-left.png" className="img-fluid" alt="potential" />
                            </div>
                            <div className="insights-title">Potential Impact</div>
                            <div className="image-insghts">
                                <img src="../images/impect-worksheet/potential-left.png" className="img-fluid" alt="potential" />
                            </div>
                            </div>
                        </th>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                        <td colSpan={3} className="width-20" rowSpan={3}>
                            <div className="insightswidth-set">
                                <textarea className="from-control inputText" 
                                rows={8} 
                                cols={24} 
                                id="InsightsToActionC26" 
                                value={Inputs.InsightsToActionC26} 
                                onChange={(event) => setInputs({ ...Inputs, InsightsToActionC26: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading}/>
                            </div>
                        </td>
                        <td colSpan={3} className="width-20" rowSpan={3}>
                            <div className="insightswidth-set">
                                <textarea className="from-control inputText" 
                                rows={8} 
                                cols={24} 
                                id="InsightsToActionE26" 
                                value={Inputs.InsightsToActionE26} 
                                onChange={(event) => setInputs({ ...Inputs, InsightsToActionE26: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading}/>
                            </div>
                        </td>
                        <td colSpan={1} className="d-hidden" rowSpan={3}>
                            <div className="images-pa-insight">
                            <img src="../images/impect-worksheet/arrow.png" className="img-fluid" alt="arrow" />
                            </div>
                        </td>
                        <td colSpan={3} className="width-20" rowSpan={3}>
                            <div className="insightswidth-set">
                                <textarea className="from-control inputText" 
                                rows={8} 
                                cols={24} 
                                id="InsightsToActionI26" 
                                value={Inputs.InsightsToActionI26} 
                                onChange={(event) => setInputs({ ...Inputs, InsightsToActionI26: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading}/>
                            </div>
                        </td>
                        <td colSpan={3} className="width-20">
                            <div className="insightswidth-set">
                                <textarea className="from-control inputText" 
                                rows={8} 
                                cols={24} 
                                id="InsightsToActionK26" 
                                value={Inputs.InsightsToActionK26} 
                                onChange={(event) => setInputs({ ...Inputs, InsightsToActionK26: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading}/>
                            </div>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}
