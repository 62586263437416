import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getAccountPenetrationMatrix, modifyAccountPenetrationMatrix } from "../../state/features/AccountPenetrationMatrixSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import sum from "../../utils/sum";

export default function AccountPenetrationMatrixContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        AccountPenetrationMatrixD12 : '',
        AccountPenetrationMatrixE12 : 0,
        AccountPenetrationMatrixF12 : 0,
        AccountPenetrationMatrixG12 : 0,
        AccountPenetrationMatrixH12 : 0,
        AccountPenetrationMatrixI12 : 0,
        AccountPenetrationMatrixJ12 : 0,
        AccountPenetrationMatrixK12 : 0,
        AccountPenetrationMatrixL12 : 0,
        AccountPenetrationMatrixD13 : '',
        AccountPenetrationMatrixE13 : 0,
        AccountPenetrationMatrixF13 : 0,
        AccountPenetrationMatrixG13 : 0,
        AccountPenetrationMatrixH13 : 0,
        AccountPenetrationMatrixI13 : 0,
        AccountPenetrationMatrixJ13 : 0,
        AccountPenetrationMatrixK13 : 0,
        AccountPenetrationMatrixL13 : 0,
        AccountPenetrationMatrixD14 : '',
        AccountPenetrationMatrixE14 : 0,
        AccountPenetrationMatrixF14 : 0,
        AccountPenetrationMatrixG14 : 0,
        AccountPenetrationMatrixH14 : 0,
        AccountPenetrationMatrixI14 : 0,
        AccountPenetrationMatrixJ14 : 0,
        AccountPenetrationMatrixK14 : 0,
        AccountPenetrationMatrixL14 : 0,
        AccountPenetrationMatrixD15 : '',
        AccountPenetrationMatrixE15 : 0,
        AccountPenetrationMatrixF15 : 0,
        AccountPenetrationMatrixG15 : 0,
        AccountPenetrationMatrixH15 : 0,
        AccountPenetrationMatrixI15 : 0,
        AccountPenetrationMatrixJ15 : 0,
        AccountPenetrationMatrixK15 : 0,
        AccountPenetrationMatrixL15 : 0,
        AccountPenetrationMatrixD16 : '',
        AccountPenetrationMatrixE16 : 0,
        AccountPenetrationMatrixF16 : 0,
        AccountPenetrationMatrixG16 : 0,
        AccountPenetrationMatrixH16 : 0,
        AccountPenetrationMatrixI16 : 0,
        AccountPenetrationMatrixJ16 : 0,
        AccountPenetrationMatrixK16 : 0,
        AccountPenetrationMatrixL16 : 0,
        AccountPenetrationMatrixD17 : '',
        AccountPenetrationMatrixE17 : 0,
        AccountPenetrationMatrixF17 : 0,
        AccountPenetrationMatrixG17 : 0,
        AccountPenetrationMatrixH17 : 0,
        AccountPenetrationMatrixI17 : 0,
        AccountPenetrationMatrixJ17 : 0,
        AccountPenetrationMatrixK17 : 0,
        AccountPenetrationMatrixL17 : 0,
        AccountPenetrationMatrixD18 : '',
        AccountPenetrationMatrixE18 : 0,
        AccountPenetrationMatrixF18 : 0,
        AccountPenetrationMatrixG18 : 0,
        AccountPenetrationMatrixH18 : 0,
        AccountPenetrationMatrixI18 : 0,
        AccountPenetrationMatrixJ18 : 0,
        AccountPenetrationMatrixK18 : 0,
        AccountPenetrationMatrixL18 : 0,
        AccountPenetrationMatrixD19 : '',
        AccountPenetrationMatrixE19 : 0,
        AccountPenetrationMatrixF19 : 0,
        AccountPenetrationMatrixG19 : 0,
        AccountPenetrationMatrixH19 : 0,
        AccountPenetrationMatrixI19 : 0,
        AccountPenetrationMatrixJ19 : 0,
        AccountPenetrationMatrixK19 : 0,
        AccountPenetrationMatrixL19 : 0,
        AccountPenetrationMatrixD20 : '',
        AccountPenetrationMatrixE20 : 0,
        AccountPenetrationMatrixF20 : 0,
        AccountPenetrationMatrixG20 : 0,
        AccountPenetrationMatrixH20 : 0,
        AccountPenetrationMatrixI20 : 0,
        AccountPenetrationMatrixJ20 : 0,
        AccountPenetrationMatrixK20 : 0,
        AccountPenetrationMatrixL20 : 0,
        AccountPenetrationMatrixD21 : '',
        AccountPenetrationMatrixE21 : 0,
        AccountPenetrationMatrixF21 : 0,
        AccountPenetrationMatrixG21 : 0,
        AccountPenetrationMatrixH21 : 0,
        AccountPenetrationMatrixI21 : 0,
        AccountPenetrationMatrixJ21 : 0,
        AccountPenetrationMatrixK21 : 0,
        AccountPenetrationMatrixL21 : 0,
        AccountPenetrationMatrixM12 : 0,
        AccountPenetrationMatrixM13 : 0,
        AccountPenetrationMatrixM14 : 0,
        AccountPenetrationMatrixM15 : 0,
        AccountPenetrationMatrixM16 : 0,
        AccountPenetrationMatrixM17 : 0,
        AccountPenetrationMatrixM18 : 0,
        AccountPenetrationMatrixM19 : 0,
        AccountPenetrationMatrixM20 : 0,
        AccountPenetrationMatrixM21 : 0,
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getAccountPenetrationMatrix({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.revenueGrowth)
            if(response!= undefined  && response.data.revenueGrowth){
                let revenueGrowth = response.data.revenueGrowth[0];
                console.log(revenueGrowth)
                delete revenueGrowth.id;
                delete revenueGrowth.user_id;
                delete revenueGrowth.created_at;
                delete revenueGrowth.updated_at;
                const newObj = { ...Inputs }
                for (const key in revenueGrowth) {
                    if (revenueGrowth.hasOwnProperty(key)) {
                        if(revenueGrowth[key])
                        newObj[key] = revenueGrowth[key]
                    }
                }
                newObj['AccountPenetrationMatrixM12'] = sum(newObj['AccountPenetrationMatrixE12'], newObj['AccountPenetrationMatrixF12'], newObj['AccountPenetrationMatrixG12'], newObj['AccountPenetrationMatrixH12'], newObj['AccountPenetrationMatrixI12'], newObj['AccountPenetrationMatrixJ12'], newObj['AccountPenetrationMatrixK12'], newObj['AccountPenetrationMatrixL12']).toFixed(2);
                newObj['AccountPenetrationMatrixM13'] = sum(newObj['AccountPenetrationMatrixE13'], newObj['AccountPenetrationMatrixF13'], newObj['AccountPenetrationMatrixG13'], newObj['AccountPenetrationMatrixH13'], newObj['AccountPenetrationMatrixI13'], newObj['AccountPenetrationMatrixJ13'], newObj['AccountPenetrationMatrixK13'], newObj['AccountPenetrationMatrixL13']).toFixed(2);
                newObj['AccountPenetrationMatrixM14'] = sum(newObj['AccountPenetrationMatrixE14'], newObj['AccountPenetrationMatrixF14'], newObj['AccountPenetrationMatrixG14'], newObj['AccountPenetrationMatrixH14'], newObj['AccountPenetrationMatrixI14'], newObj['AccountPenetrationMatrixJ14'], newObj['AccountPenetrationMatrixK14'], newObj['AccountPenetrationMatrixL14']).toFixed(2);
                newObj['AccountPenetrationMatrixM15'] = sum(newObj['AccountPenetrationMatrixE15'], newObj['AccountPenetrationMatrixF15'], newObj['AccountPenetrationMatrixG15'], newObj['AccountPenetrationMatrixH15'], newObj['AccountPenetrationMatrixI15'], newObj['AccountPenetrationMatrixJ15'], newObj['AccountPenetrationMatrixK15'], newObj['AccountPenetrationMatrixL15']).toFixed(2);
                newObj['AccountPenetrationMatrixM16'] = sum(newObj['AccountPenetrationMatrixE16'], newObj['AccountPenetrationMatrixF16'], newObj['AccountPenetrationMatrixG16'], newObj['AccountPenetrationMatrixH16'], newObj['AccountPenetrationMatrixI16'], newObj['AccountPenetrationMatrixJ16'], newObj['AccountPenetrationMatrixK16'], newObj['AccountPenetrationMatrixL16']).toFixed(2);
                newObj['AccountPenetrationMatrixM17'] = sum(newObj['AccountPenetrationMatrixE17'], newObj['AccountPenetrationMatrixF17'], newObj['AccountPenetrationMatrixG17'], newObj['AccountPenetrationMatrixH17'], newObj['AccountPenetrationMatrixI17'], newObj['AccountPenetrationMatrixJ17'], newObj['AccountPenetrationMatrixK17'], newObj['AccountPenetrationMatrixL17']).toFixed(2);
                newObj['AccountPenetrationMatrixM18'] = sum(newObj['AccountPenetrationMatrixE18'], newObj['AccountPenetrationMatrixF18'], newObj['AccountPenetrationMatrixG18'], newObj['AccountPenetrationMatrixH18'], newObj['AccountPenetrationMatrixI18'], newObj['AccountPenetrationMatrixJ18'], newObj['AccountPenetrationMatrixK18'], newObj['AccountPenetrationMatrixL18']).toFixed(2);
                newObj['AccountPenetrationMatrixM19'] = sum(newObj['AccountPenetrationMatrixE19'], newObj['AccountPenetrationMatrixF19'], newObj['AccountPenetrationMatrixG19'], newObj['AccountPenetrationMatrixH19'], newObj['AccountPenetrationMatrixI19'], newObj['AccountPenetrationMatrixJ19'], newObj['AccountPenetrationMatrixK19'], newObj['AccountPenetrationMatrixL19']).toFixed(2);
                newObj['AccountPenetrationMatrixM20'] = sum(newObj['AccountPenetrationMatrixE20'], newObj['AccountPenetrationMatrixF20'], newObj['AccountPenetrationMatrixG20'], newObj['AccountPenetrationMatrixH20'], newObj['AccountPenetrationMatrixI20'], newObj['AccountPenetrationMatrixJ20'], newObj['AccountPenetrationMatrixK20'], newObj['AccountPenetrationMatrixL20']).toFixed(2);
                newObj['AccountPenetrationMatrixM21'] = sum(newObj['AccountPenetrationMatrixE21'], newObj['AccountPenetrationMatrixF21'], newObj['AccountPenetrationMatrixG21'], newObj['AccountPenetrationMatrixH21'], newObj['AccountPenetrationMatrixI21'], newObj['AccountPenetrationMatrixJ21'], newObj['AccountPenetrationMatrixK21'], newObj['AccountPenetrationMatrixL21']).toFixed(2);
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                event.target.value = event.target.value ? event.target.value : 0
                let thenum = event.target.id.match(/\d+/)[0];
                let thestr = event.target.id.replace(thenum,'');
                let overallScore = 0;
                if(thestr != 'AccountPenetrationMatrixD'){
                    if(thestr == 'AccountPenetrationMatrixE'){
                        overallScore = sum(event.target.value, Inputs['AccountPenetrationMatrixF'+thenum], Inputs['AccountPenetrationMatrixG'+thenum], Inputs['AccountPenetrationMatrixH'+thenum], Inputs['AccountPenetrationMatrixI'+thenum], Inputs['AccountPenetrationMatrixJ'+thenum], Inputs['AccountPenetrationMatrixK'+thenum], Inputs['AccountPenetrationMatrixL'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'AccountPenetrationMatrixF'){
                        overallScore = sum(event.target.value, Inputs['AccountPenetrationMatrixE'+thenum], Inputs['AccountPenetrationMatrixG'+thenum], Inputs['AccountPenetrationMatrixH'+thenum], Inputs['AccountPenetrationMatrixI'+thenum], Inputs['AccountPenetrationMatrixJ'+thenum], Inputs['AccountPenetrationMatrixK'+thenum], Inputs['AccountPenetrationMatrixL'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'AccountPenetrationMatrixG'){
                        overallScore = sum(event.target.value, Inputs['AccountPenetrationMatrixE'+thenum], Inputs['AccountPenetrationMatrixF'+thenum], Inputs['AccountPenetrationMatrixH'+thenum], Inputs['AccountPenetrationMatrixI'+thenum], Inputs['AccountPenetrationMatrixJ'+thenum], Inputs['AccountPenetrationMatrixK'+thenum], Inputs['AccountPenetrationMatrixL'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'AccountPenetrationMatrixH'){
                        overallScore = sum(event.target.value, Inputs['AccountPenetrationMatrixE'+thenum], Inputs['AccountPenetrationMatrixF'+thenum], Inputs['AccountPenetrationMatrixG'+thenum], Inputs['AccountPenetrationMatrixI'+thenum], Inputs['AccountPenetrationMatrixJ'+thenum], Inputs['AccountPenetrationMatrixK'+thenum], Inputs['AccountPenetrationMatrixL'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'AccountPenetrationMatrixI'){
                        overallScore = sum(event.target.value, Inputs['AccountPenetrationMatrixE'+thenum], Inputs['AccountPenetrationMatrixF'+thenum], Inputs['AccountPenetrationMatrixG'+thenum], Inputs['AccountPenetrationMatrixH'+thenum], Inputs['AccountPenetrationMatrixJ'+thenum], Inputs['AccountPenetrationMatrixK'+thenum], Inputs['AccountPenetrationMatrixL'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'AccountPenetrationMatrixJ'){
                        overallScore = sum(event.target.value, Inputs['AccountPenetrationMatrixE'+thenum], Inputs['AccountPenetrationMatrixF'+thenum], Inputs['AccountPenetrationMatrixG'+thenum], Inputs['AccountPenetrationMatrixH'+thenum], Inputs['AccountPenetrationMatrixI'+thenum], Inputs['AccountPenetrationMatrixK'+thenum], Inputs['AccountPenetrationMatrixL'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'AccountPenetrationMatrixK'){
                        overallScore = sum(event.target.value, Inputs['AccountPenetrationMatrixE'+thenum], Inputs['AccountPenetrationMatrixF'+thenum], Inputs['AccountPenetrationMatrixG'+thenum], Inputs['AccountPenetrationMatrixH'+thenum], Inputs['AccountPenetrationMatrixI'+thenum], Inputs['AccountPenetrationMatrixJ'+thenum], Inputs['AccountPenetrationMatrixL'+thenum]).toFixed(2);
                    }
                    else if(thestr == 'AccountPenetrationMatrixL'){
                        overallScore = sum(event.target.value, Inputs['AccountPenetrationMatrixE'+thenum], Inputs['AccountPenetrationMatrixF'+thenum], Inputs['AccountPenetrationMatrixG'+thenum], Inputs['AccountPenetrationMatrixH'+thenum], Inputs['AccountPenetrationMatrixI'+thenum], Inputs['AccountPenetrationMatrixJ'+thenum], Inputs['AccountPenetrationMatrixK'+thenum]).toFixed(2);
                    }
                    
                    setInputs({ ...Inputs, ['AccountPenetrationMatrixM'+thenum] : parseFloat(overallScore) })
                }
               
                dispatch(modifyAccountPenetrationMatrix({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }
    
  return (
    <div className="content-body">
        <UserDropdown />

        {/* row */}
        <div className="container-fluid">
            <div className="head-title">
                <p> Sales &amp; Sales Management </p>
                <h2 className="mb-5 mt-0">Account Penetration Matrix</h2>
            </div>
            <Spinner />
            <div className="card">
                <div className="card-body pl-0">
                <div className="table-responsive table-dta">
                    <table className="table mb-0">
                    <tbody>
                        <tr>
                        <td className="bg-color1 text-white" colSpan={3} rowSpan={3}>
                            <div className="vendor-div-main" style={{width: 250}}>
                            <span className="text-center-c width-100 d-block font-size-20">Prospect or Client</span>
                            </div>
                        </td>
                        <td className="bg-color1 text-white text-center-c" colSpan={8}>Products &amp; Services</td>
                        <td className="bg-color1 text-white text-center-c" colSpan={9} rowSpan={2}>
                            <img src="../images/optimization/worked.png" className="img-fluid" alt="worked" />
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color3 text-white padding-0" colSpan={2}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    disabled={true}
                                    value="Product/Service A"
                                    placeholder="Product/Service A" />
                            </form>
                        </td>
                        <td className="bg-color3 text-white padding-0" colSpan={2}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    disabled={true}
                                    value="Product/Service B"
                                    placeholder="Product/Service B" />
                            </form>
                        </td>
                        <td className="bg-color3 text-white padding-0" colSpan={2}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    disabled={true}
                                    value="Product/Service C"
                                    placeholder="Product/Service C" />
                            </form>
                        </td>
                        <td className="bg-color3 text-white padding-0" colSpan={2}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    disabled={true}
                                    value="Product/Service D"
                                    placeholder="Product/Service D" />
                            </form>
                        </td>
                        {/* <td className="bg-color1 text-white">Score</td> */}
                        </tr>
                        <tr>
                        <td className="bg-color2 text-center-c">Told</td>
                        <td className="bg-color3 text-center-c text-font-size-15">Sold</td>
                        <td className="bg-color2 text-center-c">Told</td>
                        <td className="bg-color3 text-center-c text-font-size-15">Sold</td>
                        <td className="bg-color2 text-center-c">Told</td>
                        <td className="bg-color3 text-center-c text-font-size-15">Sold</td>
                        <td className="bg-color2 text-center-c">Told</td>
                        <td className="bg-color3 text-center-c text-font-size-15">Sold</td>
                        <td className="bg-color1 text-white text-center-c">Score</td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5 text-center-c">1</td>
                        <td className="bg-color1 text-white padding-0" colSpan={2}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="AccountPenetrationMatrixD12"
                                    value={Inputs.AccountPenetrationMatrixD12}
                                    onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixD12: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                    placeholder="Prospect A" />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixE12" 
                                value={Inputs.AccountPenetrationMatrixE12} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixE12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }}  />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixF12" 
                                value={Inputs.AccountPenetrationMatrixF12} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixF12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }}  />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixG12" 
                                value={Inputs.AccountPenetrationMatrixG12} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixG12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixH12" 
                                value={Inputs.AccountPenetrationMatrixH12} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixH12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixI12" 
                                value={Inputs.AccountPenetrationMatrixI12} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixI12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixJ12" 
                                value={Inputs.AccountPenetrationMatrixJ12} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixJ12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixK12" 
                                value={Inputs.AccountPenetrationMatrixK12} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixK12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixL12" 
                                value={Inputs.AccountPenetrationMatrixL12} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixL12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color1 text-white text-center-c">
                            {Number(Inputs.AccountPenetrationMatrixM12).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5 text-center-c">2</td>
                        <td className="bg-color1 text-white padding-0" colSpan={2}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="AccountPenetrationMatrixD13"
                                    value={Inputs.AccountPenetrationMatrixD13}
                                    onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixD13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                    placeholder="Prospect B" />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixE13" 
                                value={Inputs.AccountPenetrationMatrixE13} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixE13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixF13" 
                                value={Inputs.AccountPenetrationMatrixF13} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixF13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixG13" 
                                value={Inputs.AccountPenetrationMatrixG13} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixG13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }}  />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixH13" 
                                value={Inputs.AccountPenetrationMatrixH13} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixH13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }}  />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixI13" 
                                value={Inputs.AccountPenetrationMatrixI13} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixI13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixJ13" 
                                value={Inputs.AccountPenetrationMatrixJ13} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixJ13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixK13" 
                                value={Inputs.AccountPenetrationMatrixK13} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixK13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixL13" 
                                value={Inputs.AccountPenetrationMatrixL13} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixL13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color1 text-white text-center-c">
                            {Number(Inputs.AccountPenetrationMatrixM13).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5 text-center-c">3</td>
                        <td className="bg-color1 text-white padding-0" colSpan={2}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="AccountPenetrationMatrixD14"
                                    value={Inputs.AccountPenetrationMatrixD14}
                                    onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixD14: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                    placeholder="Prospect C" />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixE14" 
                                value={Inputs.AccountPenetrationMatrixE14} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixE14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }}  />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixF14" 
                                value={Inputs.AccountPenetrationMatrixF14} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixF14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixG14" 
                                value={Inputs.AccountPenetrationMatrixG14} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixG14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }}  />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixH14" 
                                value={Inputs.AccountPenetrationMatrixH14} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixH14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixI14" 
                                value={Inputs.AccountPenetrationMatrixI14} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixI14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }}  />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixJ14" 
                                value={Inputs.AccountPenetrationMatrixJ14} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixJ14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixK14" 
                                value={Inputs.AccountPenetrationMatrixK14} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixK14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }}  />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixL14" 
                                value={Inputs.AccountPenetrationMatrixL14} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixL14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color1 text-white text-center-c">
                            {Number(Inputs.AccountPenetrationMatrixM14).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5 text-center-c">4</td>
                        <td className="bg-color1 text-white padding-0" colSpan={2}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="AccountPenetrationMatrixD15"
                                    value={Inputs.AccountPenetrationMatrixD15}
                                    onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixD15: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                    placeholder="Prospect D" />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixE15" 
                                value={Inputs.AccountPenetrationMatrixE15} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixE15: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }}  />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixF15" 
                                value={Inputs.AccountPenetrationMatrixF15} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixF15: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixG15" 
                                value={Inputs.AccountPenetrationMatrixG15} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixG15: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }}  />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixH15" 
                                value={Inputs.AccountPenetrationMatrixH15} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixH15: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixI15" 
                                value={Inputs.AccountPenetrationMatrixI15} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixI15: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }}  />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixJ15" 
                                value={Inputs.AccountPenetrationMatrixJ15} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixJ15: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixK15" 
                                value={Inputs.AccountPenetrationMatrixK15} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixK15: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }}  />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixL15" 
                                value={Inputs.AccountPenetrationMatrixL15} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixL15: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color1 text-white text-center-c">
                            {Number(Inputs.AccountPenetrationMatrixM15).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5 text-center-c">5</td>
                        <td className="bg-color1 text-white padding-0" colSpan={2}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="AccountPenetrationMatrixD16"
                                    value={Inputs.AccountPenetrationMatrixD16}
                                    onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixD16: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                    placeholder="Prospect E" />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixE16" 
                                value={Inputs.AccountPenetrationMatrixE16} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixE16: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }}  />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixF16" 
                                value={Inputs.AccountPenetrationMatrixF16} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixF16: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixG16" 
                                value={Inputs.AccountPenetrationMatrixG16} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixG16: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }}  />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixH16" 
                                value={Inputs.AccountPenetrationMatrixH16} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixH16: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixI16" 
                                value={Inputs.AccountPenetrationMatrixI16} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixI16: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }}  />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixJ16" 
                                value={Inputs.AccountPenetrationMatrixJ16} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixJ16: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixK16" 
                                value={Inputs.AccountPenetrationMatrixK16} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixK16: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }}  />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixL16" 
                                value={Inputs.AccountPenetrationMatrixL16} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixL16: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color1 text-white text-center-c">
                            {Number(Inputs.AccountPenetrationMatrixM16).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5 text-center-c">6</td>
                        <td className="bg-color1 text-white padding-0" colSpan={2}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="AccountPenetrationMatrixD17"
                                    value={Inputs.AccountPenetrationMatrixD17}
                                    onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixD17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                    placeholder="Prospect F" />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixE17" 
                                value={Inputs.AccountPenetrationMatrixE17} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixE17: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }}  />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixF17" 
                                value={Inputs.AccountPenetrationMatrixF17} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixF17: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixG17" 
                                value={Inputs.AccountPenetrationMatrixG17} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixG17: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }}  />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixH17" 
                                value={Inputs.AccountPenetrationMatrixH17} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixH17: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixI17" 
                                value={Inputs.AccountPenetrationMatrixI17} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixI17: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }}  />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixJ17" 
                                value={Inputs.AccountPenetrationMatrixJ17} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixJ17: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixK17" 
                                value={Inputs.AccountPenetrationMatrixK17} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixK17: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }}  />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixL17" 
                                value={Inputs.AccountPenetrationMatrixL17} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixL17: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color1 text-white text-center-c">
                            {Number(Inputs.AccountPenetrationMatrixM17).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5 text-center-c">7</td>
                        <td className="bg-color1 text-white padding-0" colSpan={2}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="AccountPenetrationMatrixD18"
                                    value={Inputs.AccountPenetrationMatrixD18}
                                    onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixD18: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                    placeholder="Prospect G" />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixE18" 
                                value={Inputs.AccountPenetrationMatrixE18} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixE18: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }}  />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixF18" 
                                value={Inputs.AccountPenetrationMatrixF18} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixF18: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixG18" 
                                value={Inputs.AccountPenetrationMatrixG18} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixG18: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }}  />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixH18" 
                                value={Inputs.AccountPenetrationMatrixH18} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixH18: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixI18" 
                                value={Inputs.AccountPenetrationMatrixI18} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixI18: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }}  />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixJ18" 
                                value={Inputs.AccountPenetrationMatrixJ18} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixJ18: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixK18" 
                                value={Inputs.AccountPenetrationMatrixK18} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixK18: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }}  />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixL18" 
                                value={Inputs.AccountPenetrationMatrixL18} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixL18: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color1 text-white text-center-c">
                            {Number(Inputs.AccountPenetrationMatrixM18).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5 text-center-c">8</td>
                        <td className="bg-color1 text-white padding-0" colSpan={2}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="AccountPenetrationMatrixD19"
                                    value={Inputs.AccountPenetrationMatrixD19}
                                    onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixD19: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                    placeholder="Prospect H" />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixE19" 
                                value={Inputs.AccountPenetrationMatrixE19} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixE19: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }}  />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixF19" 
                                value={Inputs.AccountPenetrationMatrixF19} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixF19: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixG19" 
                                value={Inputs.AccountPenetrationMatrixG19} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixG19: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }}  />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixH19" 
                                value={Inputs.AccountPenetrationMatrixH19} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixH19: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixI19" 
                                value={Inputs.AccountPenetrationMatrixI19} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixI19: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }}  />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixJ19" 
                                value={Inputs.AccountPenetrationMatrixJ19} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixJ19: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixK19" 
                                value={Inputs.AccountPenetrationMatrixK19} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixK19: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }}  />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixL19" 
                                value={Inputs.AccountPenetrationMatrixL19} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixL19: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color1 text-white text-center-c">
                            {Number(Inputs.AccountPenetrationMatrixM19).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5 text-center-c">9</td>
                        <td className="bg-color1 text-white padding-0" colSpan={2}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="AccountPenetrationMatrixD20"
                                    value={Inputs.AccountPenetrationMatrixD20}
                                    onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixD20: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                    placeholder="Prospect I" />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixE20" 
                                value={Inputs.AccountPenetrationMatrixE20} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixE20: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }}  />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixF20" 
                                value={Inputs.AccountPenetrationMatrixF20} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixF20: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixG20" 
                                value={Inputs.AccountPenetrationMatrixG20} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixG20: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }}  />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixH20" 
                                value={Inputs.AccountPenetrationMatrixH20} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixH20: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixI20" 
                                value={Inputs.AccountPenetrationMatrixI20} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixI20: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }}  />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixJ20" 
                                value={Inputs.AccountPenetrationMatrixJ20} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixJ20: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixK20" 
                                value={Inputs.AccountPenetrationMatrixK20} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixK20: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }}  />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixL20" 
                                value={Inputs.AccountPenetrationMatrixL20} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixL20: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color1 text-white text-center-c">
                            {Number(Inputs.AccountPenetrationMatrixM20).toLocaleString('en-US')}
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-5 text-center-c">10</td>
                        <td className="bg-color1 text-white padding-0" colSpan={2}>
                            <form className="Focused-planning-f">
                                <input type="text" className="fucus-pla"
                                    id="AccountPenetrationMatrixD21"
                                    value={Inputs.AccountPenetrationMatrixD21}
                                    onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixD21: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                    placeholder="Prospect J" />
                            </form>
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixE21" 
                                value={Inputs.AccountPenetrationMatrixE21} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixE21: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }}  />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixF21" 
                                value={Inputs.AccountPenetrationMatrixF21} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixF21: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixG21" 
                                value={Inputs.AccountPenetrationMatrixG21} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixG21: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }}  />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixH21" 
                                value={Inputs.AccountPenetrationMatrixH21} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixH21: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixI21" 
                                value={Inputs.AccountPenetrationMatrixI21} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixI21: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }}  />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixJ21" 
                                value={Inputs.AccountPenetrationMatrixJ21} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixJ21: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="AccountPenetrationMatrixK21" 
                                value={Inputs.AccountPenetrationMatrixK21} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixK21: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }}  />
                        </td>
                        <td className="bg-color3 text-font-size-15 text-center-c">
                            <input type="text" className="form-control text-white bg-color3" 
                                id="AccountPenetrationMatrixL21" 
                                value={Inputs.AccountPenetrationMatrixL21} 
                                onChange={(event) => setInputs({ ...Inputs, AccountPenetrationMatrixL21: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="1" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bg-color1 text-white text-center-c">
                            {Number(Inputs.AccountPenetrationMatrixM21).toLocaleString('en-US')}
                        </td>
                        </tr>
                    </tbody>
                    </table>
                    {/* table 2 */} 
                    <table className="table mb-0">
                    <tbody>
                        <tr>
                        <td className="bg-color1 width-50 text-white text-center-c" colSpan={2}>
                            <div className="Told-title">Told</div>
                        </td>
                        <td className="bg-color1 width-50 text-white text-center-c" colSpan={2}>
                            <div className="Told-title">Sold</div>
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-50 text-font-size-15" colSpan={2}>
                            <div className="Told-title">0 - have not told</div>
                        </td>
                        <td className="bg-color3 width-50 text-font-size-15" colSpan={2}>
                            <div className="Told-title">3 - sold once</div>
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-50 text-font-size-15" colSpan={2}>
                            <div className="Told-title">1 - briefly mentioned</div>
                        </td>
                        <td className="bg-color3 width-50 text-font-size-15" colSpan={2}>
                            <div className="Told-title">4 - sold multiple times</div>
                        </td>
                        </tr>
                        <tr>
                        <td className="bg-color2 width-50 text-font-size-15" colSpan={2}>
                            <div className="Told-title">2 - meaningful conversation</div>
                        </td>
                        <td className="bg-color3 width-50 text-font-size-15" colSpan={2}>
                            <div className="Told-title">5 - received referrals</div>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}
