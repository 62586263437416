import sum from "../../utils/sum";
const initialState = {
    benchmarkScoreGapAnalysisR13: 0,
    benchmarkScoreGapAnalysisR14: 0,
    benchmarkScoreGapAnalysisR15: 0,
    benchmarkScoreGapAnalysisR16: 0,
    benchmarkScoreGapAnalysisR17: 0,
    benchmarkScoreGapAnalysisR18: 0,
    benchmarkScoreGapAnalysisR19: 0,
    benchmarkScoreGapAnalysisR20: 0,
    benchmarkScoreGapAnalysisR21: 0,
    benchmarkScoreGapAnalysisR22: 0,
    benchmarkScoreGapAnalysisR23: 0,
    benchmarkScoreGapAnalysisR24: 0,
    benchmarkScoreGapAnalysisM20: 0,
}
const reducer = (state = initialState, action) => {
    if (action.type === 'benchmarkScoreGapAnalysisAll') {
        const benchmarkScore = action.payload
        let benchmarkScoreGapAnalysisR13 = parseFloat(benchmarkScore.benchmarkScoreGapAnalysisR13).toFixed(2)
        let benchmarkScoreGapAnalysisR14 = parseFloat(benchmarkScore.benchmarkScoreGapAnalysisR14).toFixed(2)
        let benchmarkScoreGapAnalysisR15 = parseFloat(benchmarkScore.benchmarkScoreGapAnalysisR15).toFixed(2)
        let benchmarkScoreGapAnalysisR16 = parseFloat(benchmarkScore.benchmarkScoreGapAnalysisR16).toFixed(2)
        let benchmarkScoreGapAnalysisR17 = parseFloat(benchmarkScore.benchmarkScoreGapAnalysisR17).toFixed(2)
        let benchmarkScoreGapAnalysisR18 = parseFloat(benchmarkScore.benchmarkScoreGapAnalysisR18).toFixed(2)
        let benchmarkScoreGapAnalysisR19 = parseFloat(benchmarkScore.benchmarkScoreGapAnalysisR19).toFixed(2)
        let benchmarkScoreGapAnalysisR20 = parseFloat(benchmarkScore.benchmarkScoreGapAnalysisR20).toFixed(2)
        let benchmarkScoreGapAnalysisR21 = parseFloat(benchmarkScore.benchmarkScoreGapAnalysisR21).toFixed(2)
        let benchmarkScoreGapAnalysisR22 = parseFloat(benchmarkScore.benchmarkScoreGapAnalysisR22).toFixed(2)
        let benchmarkScoreGapAnalysisR23 = parseFloat(benchmarkScore.benchmarkScoreGapAnalysisR23).toFixed(2)
        let benchmarkScoreGapAnalysisR24 = (sum(benchmarkScoreGapAnalysisR13, benchmarkScoreGapAnalysisR14, benchmarkScoreGapAnalysisR15, benchmarkScoreGapAnalysisR16, benchmarkScoreGapAnalysisR17, benchmarkScoreGapAnalysisR18, benchmarkScoreGapAnalysisR19, benchmarkScoreGapAnalysisR20, benchmarkScoreGapAnalysisR21, benchmarkScoreGapAnalysisR22, benchmarkScoreGapAnalysisR23)).toFixed(2)
        let benchmarkScoreGapAnalysisM20 = parseFloat(benchmarkScore.benchmarkScoreGapAnalysisM20).toFixed(2)

        return {
            ...state,
            benchmarkScoreGapAnalysisR13: (benchmarkScoreGapAnalysisR13 !== 'NaN') ? benchmarkScoreGapAnalysisR13 : 0,
            benchmarkScoreGapAnalysisR14: (benchmarkScoreGapAnalysisR14 !== 'NaN') ? benchmarkScoreGapAnalysisR14 : 0,
            benchmarkScoreGapAnalysisR15: (benchmarkScoreGapAnalysisR15 !== 'NaN') ? benchmarkScoreGapAnalysisR15 : 0,
            benchmarkScoreGapAnalysisR16: (benchmarkScoreGapAnalysisR16 !== 'NaN') ? benchmarkScoreGapAnalysisR16 : 0,
            benchmarkScoreGapAnalysisR17: (benchmarkScoreGapAnalysisR17 !== 'NaN') ? benchmarkScoreGapAnalysisR17 : 0,
            benchmarkScoreGapAnalysisR18: (benchmarkScoreGapAnalysisR18 !== 'NaN') ? benchmarkScoreGapAnalysisR18 : 0,
            benchmarkScoreGapAnalysisR19: (benchmarkScoreGapAnalysisR19 !== 'NaN') ? benchmarkScoreGapAnalysisR19 : 0,
            benchmarkScoreGapAnalysisR20: (benchmarkScoreGapAnalysisR20 !== 'NaN') ? benchmarkScoreGapAnalysisR20 : 0,
            benchmarkScoreGapAnalysisR21: (benchmarkScoreGapAnalysisR21 !== 'NaN') ? benchmarkScoreGapAnalysisR21 : 0,
            benchmarkScoreGapAnalysisR22: (benchmarkScoreGapAnalysisR22 !== 'NaN') ? benchmarkScoreGapAnalysisR22 : 0,
            benchmarkScoreGapAnalysisR23: (benchmarkScoreGapAnalysisR23 !== 'NaN') ? benchmarkScoreGapAnalysisR23 : 0,
            benchmarkScoreGapAnalysisR24: (benchmarkScoreGapAnalysisR24 !== 'NaN') ? benchmarkScoreGapAnalysisR24 : 0,
            benchmarkScoreGapAnalysisM20: (benchmarkScoreGapAnalysisM20 !== 'NaN') ? benchmarkScoreGapAnalysisM20 : 0,
        }
    }
    else {
        return state
    }
}

export default reducer