import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from 'react-alert'
import { login, register as signUp } from "../state/features/AuthenticationSlice";
import { clearMessage } from "../state/features/Message";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

export default function Auth() {
    const [isLoading, setisLoading] = useState(false)
    const alert = useAlert();
    const [activeRightPanel, setactiveRightPanel] = useState('');
    const handleLoginAnimation = () => {
        setactiveRightPanel('')
    }
    const handleSignupAnimation = () => {
        setactiveRightPanel('right-panel-active');
    }
    //define states
    const { isLoggedIn } = useSelector((state) => state.auth);
    const { message } = useSelector((state) => state.message);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(clearMessage());
    }, [dispatch]);
    const initialValues = {
        email: "",
        password: "",
    };

    //form validations schema 
    const signupSchema = Yup.object().shape({
        firstName: Yup.string().required('First name is required.'),
        lastName: Yup.string().required('Last name is required.'),
        email: Yup.string().email('Enter valid email id.').required('Email id is required.'),
        password: Yup.string().required('Password is required.')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
            ),
        confirmPwd: Yup.string().required('Password is required.').oneOf([Yup.ref('password')], 'Passwords does not match.'),
    })
    const loginSchema = Yup.object().shape({
        email: Yup.string().email('Enter valid email id.').required('Email id is required.'),
        password: Yup.string().required('Password is required.')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
            ),
    })
    //validation schema end
    const formOptions = { resolver: yupResolver(signupSchema) }
    const formOptionsLogin = { resolver: yupResolver(loginSchema) }
    const { register, formState: { errors, isSubmitting }, handleSubmit, } = useForm(formOptionsLogin);
    const { register: register2, formState: { errors: errors2 }, handleSubmit: handleSubmit2, } = useForm(formOptions);
    //login submit handler
    const onSubmit = formValue => {
        setisLoading(true)
        //console.log(JSON.stringify(formValue));//print form data to console
        dispatch(login(formValue))
        .unwrap()
        .then((response) => {
            setisLoading(false)
            if(response!= undefined){
                let userId = response.user.data.profile.id
                console.log("after login data",userId)
            }
            console.log("redirect to dashboard")
            { alert.show("Logged in successfully.") }
        })
        .catch((error) => {
            setisLoading(false)
            { error ? alert.show(error) : alert.show("Please enter valid credentials.") }
        });
    }

    //signup submit handler
    const onSubmitSignup = formValue => {
        dispatch(signUp(formValue))
            .unwrap()
            .then(() => {
                console.log("redirect to login")
                console.log(message)
                { alert.show("Successfully signup.") }
                setactiveRightPanel('')
            })
            .catch(() => {
                { message && alert.show(message) }
            });

    }
    //   console.log(watch("email"))

    return (
        <div id="main-wrapper">
            <div className="login-form-main">
                <div className={`container ${activeRightPanel}`} id="container" >
                    <div className="form-container sign-up-container">
                        <form onSubmit={handleSubmit2(onSubmitSignup)}>
                            <h1>Create Account</h1>
                            <span className="mb-3">or use your email for registration</span>
                            <div className="row" style={{ padding: 0, margin: 0 }}>
                                <div className="col-6 col-md-6 pl-0">
                                    <input
                                        type="text"
                                        {...register2("firstName")}
                                        placeholder="First name"
                                        className={`form-control ${errors2.firstName ? 'is-invalid' : ''}`}
                                    />
                                    <span style={{ color: 'red' }}>{errors2.firstName?.message}</span>
                                </div>
                                <div className="col-6 col-md-6 pr-0">
                                    <input
                                        type="text"
                                        {...register2("lastName")}
                                        placeholder="Last name"
                                        className={`form-control ${errors2.lastName ? 'is-invalid' : ''}`}
                                    />
                                    <span style={{ color: 'red' }}>{errors2.lastName?.message}</span>
                                </div>
                            </div>
                            <input
                                type="text"
                                {...register2("email")}
                                placeholder="Email address"
                                className={`form-control ${errors2.email ? 'is-invalid' : ''}`}
                            />
                            <span style={{ color: 'red' }}>{errors2.email?.message}</span>
                            <input
                                type="password"
                                {...register2("password")}
                                placeholder="Password"
                                className={`form-control ${errors2.password ? 'is-invalid' : ''}`}
                            />
                            <span style={{ color: 'red' }}>{errors2.password?.message}</span>
                            <input
                                type="password"
                                {...register2("confirmPwd")}
                                placeholder="Confirm Password"
                                className={`form-control ${errors2.confirmPwd ? 'is-invalid' : ''}`}
                            />
                            <span style={{ color: 'red' }}>{errors2.confirmPwd?.message}</span>
                            {
                                isLoading
                                ?
                                <button className="mt-3" style={{ pointerEvents: 'none'}}>
                                    <div className="d-flex align-items-center">
                                        <strong>Sign Up...</strong>
                                        <div className="spinner-border ml-auto" role="status" aria-hidden="true" />
                                    </div>
                                </button>

                                :
                                
                                <button className="mt-3" type='submit'>Sign Up</button>
                            }
                            
                        </form>
                    </div>
                    <div className="form-container sign-in-container">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <h1>Sign in</h1>
                            <span className="mb-3">or use your account</span>
                            <input
                                type="text"
                                {...register("email")}
                                placeholder="Email address"
                                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                            />
                            <span style={{ color: 'red' }}>{errors.email?.message}</span>
                            <input
                                type="password"
                                {...register("password")}
                                placeholder="Password"
                                className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                            />
                            <span style={{ color: 'red' }}>{errors.password?.message}</span>
                            {/* <Link to="#">Forgot your password?</Link> */}
                           
                            {
                                isLoading
                                ?
                                <button type="button" style={{ pointerEvents: 'none'}} disabled>
                                    <span className="spinner-border spinner-border-sm"></span> Log In..
                                </button>
                                :
                                <button type='submit'>Log In</button>
                            }
                            
                        </form>

                    </div>
                    <div className="overlay-container">
                        <div className="overlay">
                            <div className="overlay-panel overlay-left">
                                <div className="logo-image">
                                    <Link to="#" className="brand-logo">
                                        <img src="images/svg/Logo.png" alt="logo" />
                                    </Link>
                                </div>
                                <p>To keep connected with us please login with your personal info</p>
                                
                                <button className="ghost" id="signIn" onClick={handleLoginAnimation}>Sign In</button>
                            </div>
                            <div className="overlay-panel overlay-right">
                                <div className="logo-image">
                                    <Link to="#" className="brand-logo">
                                        <img src="images/svg/Logo.png" alt="logo" />
                                    </Link>
                                </div>
                                <p>Enter your personal details and start journey with us</p>
                                <button className="ghost" id="signUp" onClick={handleSignupAnimation}>Sign Up</button>
                            </div>
                        </div>
                    </div>
                </div>
                <footer>
                    <p>
                        Copyright 2023 by RevGrowth OS and Russ Holder.
                    </p>
                </footer>
            </div>
        </div>

    )
}
