import axios from "axios";
import authHeader from "./auth-header";
import { trackPromise } from 'react-promise-tracker';

const getData = (user_id) => {
    return trackPromise (
        axios({
            method: "post",
            url: "revenueGrowth/getPAINstorming",
            data: {user_id},
            headers: authHeader()
        })
    )
};
const postData = (data) => {
    return trackPromise (
        axios({
            method: "post",
            url: "revenueGrowth/modifyPAINstorming",
            data,
            headers: authHeader()
        })
    )
};

const PAINstormingService = {
    getData,
    postData,
};
export default PAINstormingService;