import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getFiveForcesFramework, modifyFiveForcesFramework } from "../../state/features/FiveForcesFrameworkSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';

export default function FiveStepStrategyContent() {

    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        FiveForcesFrameworkE12 : '',
        FiveForcesFrameworkE15 : '',
        FiveForcesFrameworkF15 : '',
        FiveForcesFrameworkG15 : '',
        FiveForcesFrameworkE16 : '',
        FiveForcesFrameworkF16 : '',
        FiveForcesFrameworkG16 : '',
        FiveForcesFrameworkE17 : '',
        FiveForcesFrameworkF17 : '',
        FiveForcesFrameworkG17 : '',
        FiveForcesFrameworkE18 : '',
        FiveForcesFrameworkF18 : '',
        FiveForcesFrameworkG18 : '',
        FiveForcesFrameworkE19 : '',
        FiveForcesFrameworkF19 : '',
        FiveForcesFrameworkG19 : '',
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getFiveForcesFramework({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.growthPlanning)
            if(response!= undefined  && response.data.growthPlanning){
                let growthPlanning = response.data.growthPlanning[0];
                console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key]
                    }
                }
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyFiveForcesFramework({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        //window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

  return (
    <div className="content-body">
    <UserDropdown />
        {/* row */}
        <div className="container-fluid">
            <div className="head-title bigsout">
                <h2>Five Forces Framework</h2>  
                <p>The Five Forces Model, also called Porter’s Five Forces Framework, is a tool for analyzing a business’ competition and competitive environment. The model looks at five forces within any industry to determine the state of the competitive the environment and as a result, the implications for how profitable the industry could be and business strategies for driving success.</p>  
                <p>Use this template to determine the Five Forces that impact your company or product.</p> 		
            </div>
            <Spinner />
            <div className="card">
                <div className="card-body pl-0">
                <div className="table-responsive">
                    <table className="table">
                    <thead>
                        <tr>
                        <th colSpan={1} />
                        <th style={{width: '30%'}} className="bluetab">Overall Assessment of Competitive Intensity</th>
                        <th className="lightyello" colSpan={3}>
                            <input type="text" className="form-control inputText"
                                id="FiveForcesFrameworkE12"
                                value={Inputs.FiveForcesFrameworkE12}
                                onChange={(event) => setInputs({ ...Inputs, FiveForcesFrameworkE12: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <th colSpan={1} />
                        <th className="bluetab">Sources of Competitive Advantage</th>
                        <th className="bluetab">Assessment <br /> (1=Low; 5=High)</th>
                        <th className="bluetab">Major Players</th>
                        <th className="bluetab">Overview of Key Dynamics and Impact to the Organization </th>
                        </tr>
                        <tr>
                        <td className="bluetab"><img src="../images/svg/icon-1.png" /></td>
                        <td className="bgradiusf">Industry Rivalry - the number of competitors and their ability to undercut your business.  The more competitors with equivalent products and services, the less power a company has.</td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="FiveForcesFrameworkE15"
                                value={Inputs.FiveForcesFrameworkE15}
                                onChange={(event) => setInputs({ ...Inputs, FiveForcesFrameworkE15: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="0"
                                maxLength="1"
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                    }}
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="FiveForcesFrameworkF15"
                                value={Inputs.FiveForcesFrameworkF15}
                                onChange={(event) => setInputs({ ...Inputs, FiveForcesFrameworkF15: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="FiveForcesFrameworkG15"
                                value={Inputs.FiveForcesFrameworkG15}
                                onChange={(event) => setInputs({ ...Inputs, FiveForcesFrameworkG15: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td className="bluetab"><img src="../images/svg/icon-1.png" /></td>
                        <td className="bgradiusf">Buyer Power - the ability customers have to drive prices down.  How many customers, how significant are they, and how much would it cost to replace them?</td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="FiveForcesFrameworkE16"
                                value={Inputs.FiveForcesFrameworkE16}
                                onChange={(event) => setInputs({ ...Inputs, FiveForcesFrameworkE16: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="0"
                                maxLength="1"
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                    }}
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="FiveForcesFrameworkF16"
                                value={Inputs.FiveForcesFrameworkF16}
                                onChange={(event) => setInputs({ ...Inputs, FiveForcesFrameworkF16: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="FiveForcesFrameworkG16"
                                value={Inputs.FiveForcesFrameworkG16}
                                onChange={(event) => setInputs({ ...Inputs, FiveForcesFrameworkG16: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td className="bluetab"><img src="../images/svg/icon-1.png" /></td>
                        <td className="bgradiusf">Supplier Power - how easily can suppliers drive up the costs; often affected by the number of suppliers in an industry.</td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="FiveForcesFrameworkE17"
                                value={Inputs.FiveForcesFrameworkE17}
                                onChange={(event) => setInputs({ ...Inputs, FiveForcesFrameworkE17: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="0"
                                maxLength="1"
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                    }}
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="FiveForcesFrameworkF17"
                                value={Inputs.FiveForcesFrameworkF17}
                                onChange={(event) => setInputs({ ...Inputs, FiveForcesFrameworkF17: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="FiveForcesFrameworkG17"
                                value={Inputs.FiveForcesFrameworkG17}
                                onChange={(event) => setInputs({ ...Inputs, FiveForcesFrameworkG17: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td className="bluetab"><img src="../images/svg/icon-1.png" /></td>
                        <td className="bgradiusf">New Entrants - the less time and money it costs for a new competitor to enter the market and effectively compete, the more of a threat.</td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="FiveForcesFrameworkE18"
                                value={Inputs.FiveForcesFrameworkE18}
                                onChange={(event) => setInputs({ ...Inputs, FiveForcesFrameworkE18: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="0"
                                maxLength="1"
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                    }}
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="FiveForcesFrameworkF18"
                                value={Inputs.FiveForcesFrameworkF18}
                                onChange={(event) => setInputs({ ...Inputs, FiveForcesFrameworkF18: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="FiveForcesFrameworkG18"
                                value={Inputs.FiveForcesFrameworkG18}
                                onChange={(event) => setInputs({ ...Inputs, FiveForcesFrameworkG18: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td className="bluetab"><img src="../images/svg/icon-1.png" /></td>
                        <td className="bgradiusf">Potential Substitutes - products and services that can be used in place of your products or services pose a threat.</td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="FiveForcesFrameworkE19"
                                value={Inputs.FiveForcesFrameworkE19}
                                onChange={(event) => setInputs({ ...Inputs, FiveForcesFrameworkE19: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="0"
                                maxLength="1"
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                    }}
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="FiveForcesFrameworkF19"
                                value={Inputs.FiveForcesFrameworkF19}
                                onChange={(event) => setInputs({ ...Inputs, FiveForcesFrameworkF19: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="FiveForcesFrameworkG19"
                                value={Inputs.FiveForcesFrameworkG19}
                                onChange={(event) => setInputs({ ...Inputs, FiveForcesFrameworkG19: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}
