import React from 'react';
import { NavLink, Link  } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useState } from 'react';
import useWindowDimensions from '../utils/useWindowDimensions';
import { useEffect } from 'react';
import UserDropdownMobile from './UserDropdownMobile';
import {useSWRConfig} from "swr";
import CopyRightText from './CopyRightText';

export default function SidebarNew() {
    const { fallback, isLoading } = useSWRConfig()
    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
    });
    const setDimension = () => {
        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight
        })
    }
    
    useEffect(() => {
        window.addEventListener('resize', setDimension);
        
        return(() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize])
    const { user: currentUser } = useSelector((state) => state.auth);
    const [toggle, setToggle] = useState(false);
    useState(()=>{
        // console.log(screenSize.dynamicWidth)
        if(screenSize.dynamicWidth > 767){
            setToggle(true)
        }
    }, [screenSize.dynamicWidth])

    return (
        <>
            <div className="nav-header">
                <Link to="/home" className="brand-logo-new">
                    {
                        (fallback && fallback.settings.is_allow_branding == 1 && fallback.settings.logo) ? 
                        <><img src={"https://api.growthoperatingsystem.app/images/brandingLogo/"+fallback.settings.logo} alt="logo" /></> 
                        : <><img src={window.location.origin+"/images/svg/Logo.png"} alt="logo" /></>
                    }
                </Link>
                <div className="nav-control nav-control-new">
                    <div className="hamburger" onClick={()=>setToggle(!toggle)}>
                        <span className="line" />
                        <span className="line" />
                        <span className="line" />
                    </div>
                </div>
            </div>
            {toggle && <div className="sidebarNew">
                <div className="nav-header-new">
                    <Link to="/home" className="brand-logo-new">
                        {
                        (fallback && fallback.settings.is_allow_branding == 1 && fallback.settings.logo) ? 
                        <><img src={"https://api.growthoperatingsystem.app/images/brandingLogo/"+fallback.settings.logo} alt="logo" /></> 
                        : <><img src={window.location.origin+"/images/svg/Logo.png"} alt="logo" /></>
                        }
                    </Link>
                    {/* <div className="nav-control">
                        <div className="hamburger">
                            <span className="line" /><span className="line" /><span className="line" />
                        </div>
                    </div> */}
                </div>
                <div className="dlabnav-scroll-new">
                    <ul className="metismenu-mew" id="menu">
                        <UserDropdownMobile />
                        <li>
                            <NavLink to="/diagnose" className="has-arrow" aria-expanded="false">
                                <img src={window.location.origin+"/images/Home_page_img/sidebar-icon/diagnose.png"} alt="img" />
                                <span className="nav-text"> Diagnose </span>
                            </NavLink >
                        </li>
                        <li>
                            <NavLink to="/prescribe" className="has-arrow" aria-expanded="false">
                            <img src={window.location.origin+"/images/Home_page_img/sidebar-icon/prescribe.png"} alt="img"/>
                            <span className="nav-text"> Prescribe </span>
                            </NavLink >
                        </li>
                        <li><NavLink to="/grow" className="has-arrow" aria-expanded="false">
                            <img src={window.location.origin+"/images/Home_page_img/sidebar-icon/grow.png"} alt="img"/>
                            <span className="nav-text"> Grow </span>
                        </NavLink >
                        </li>
                        <hr className='border-bottom' />
                        <li><NavLink className="has-arrow" to="/state-of-the-business" aria-expanded="false">
                            <img src={window.location.origin+"/images/Home_page_img/sidebar-icon/business.png"} alt="img"/>
                            <span className="nav-text"> State of the Business </span>
                        </NavLink >
                        </li>
                        <li><NavLink to="/your-growth-potential" className="has-arrow" aria-expanded="false">
                            <img src={window.location.origin+"/images/Home_page_img/sidebar-icon/growth.png"} alt="img"/>
                            <span className="nav-text"> Your Growth Potential </span>
                        </NavLink >
                        </li>
                        <li><NavLink to="/tools" className="has-arrow" aria-expanded="false">
                            <img src={window.location.origin+"/images/Home_page_img/sidebar-icon/tools.png"} alt="img"/>
                            <span className="nav-text"> Tools </span>
                        </NavLink >
                        </li>
                        <li><NavLink to="/how-it-works" className="has-arrow" aria-expanded="false">
                            <img src={window.location.origin+"/images/Home_page_img/sidebar-icon/settings.png"} alt="img"/>
                            <span className="nav-text"> How It Works </span>
                        </NavLink >
                        </li>
                    </ul>
                    <address className='contact-info'>
                        <h3 className='font-sise-bold-wt-24'>Contact:</h3>
                        <p>
                        {
                        (fallback && fallback.settings.is_allow_branding == 1 && fallback.settings.phone) ? 
                        <>P : {fallback.settings.phone}</> 
                        : <>P : (864) 501-4095</>
                        }
                        </p>
                        <p>
                        {
                        (fallback && fallback.settings.is_allow_branding == 1 && fallback.settings.email) ? 
                        <>E : {fallback.settings.email}</> 
                        : <>E : info@RevenueGrowthOS.com</>
                        }
                        </p>
                        <p>
                        {
                        (fallback && fallback.settings.is_allow_branding == 1 && fallback.settings.website) ? 
                        <>W : {fallback.settings.website}</> 
                        : <>W : www.RevenueGrowthOS.com</>
                        }
                        </p>
                    </address>
                    <div className='sidebar-footer'>
                        <CopyRightText />
                    </div>
                </div>
            </div>}
            
        </>
    )
}
