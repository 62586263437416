import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getDisruptiveInnovation, modifyDisruptiveInnovation } from "../../state/features/DisruptiveInnovationSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';

export default function DisruptiveInnovationContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        DisruptiveInnovationC12 : '',
        DisruptiveInnovationD12 : '',
        DisruptiveInnovationE12 : '',
        DisruptiveInnovationF12 : '',
        DisruptiveInnovationC13 : '',
        DisruptiveInnovationD13 : '',
        DisruptiveInnovationE13 : '',
        DisruptiveInnovationF13 : '',
        DisruptiveInnovationC14 : '',
        DisruptiveInnovationD14 : '',
        DisruptiveInnovationE14 : '',
        DisruptiveInnovationF14 : '',
        DisruptiveInnovationC15 : '',
        DisruptiveInnovationD15 : '',
        DisruptiveInnovationE15 : '',
        DisruptiveInnovationF15 : '',
        DisruptiveInnovationC16 : '',
        DisruptiveInnovationD16 : '',
        DisruptiveInnovationE16 : '',
        DisruptiveInnovationF16 : '',
        DisruptiveInnovationG12 : 0,
        DisruptiveInnovationG13 : 0,
        DisruptiveInnovationG14 : 0,
        DisruptiveInnovationG15 : 0,
        DisruptiveInnovationG16 : 0,
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getDisruptiveInnovation({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.revenueGrowth)
            if(response!= undefined  && response.data.revenueGrowth){
                let revenueGrowth = response.data.revenueGrowth[0];
                console.log(revenueGrowth)
                delete revenueGrowth.id;
                delete revenueGrowth.user_id;
                delete revenueGrowth.created_at;
                delete revenueGrowth.updated_at;
                const newObj = { ...Inputs }
                for (const key in revenueGrowth) {
                    if (revenueGrowth.hasOwnProperty(key)) {
                        if(revenueGrowth[key])
                        newObj[key] = revenueGrowth[key]
                    }
                }
                newObj['DisruptiveInnovationG12'] = (revenueGrowth['DisruptiveInnovationD12'] ? parseFloat(revenueGrowth['DisruptiveInnovationD12']) : 0) + (revenueGrowth['DisruptiveInnovationE12'] ? parseFloat(revenueGrowth['DisruptiveInnovationE12']) : 0) + (revenueGrowth['DisruptiveInnovationF12'] ? parseFloat(revenueGrowth['DisruptiveInnovationF12']) : 0);
                newObj['DisruptiveInnovationG13'] = (revenueGrowth['DisruptiveInnovationD13'] ? parseFloat(revenueGrowth['DisruptiveInnovationD13']) : 0) + (revenueGrowth['DisruptiveInnovationE13'] ? parseFloat(revenueGrowth['DisruptiveInnovationE13']) : 0) + (revenueGrowth['DisruptiveInnovationF13'] ? parseFloat(revenueGrowth['DisruptiveInnovationF13']) : 0);
                newObj['DisruptiveInnovationG14'] = (revenueGrowth['DisruptiveInnovationD14'] ? parseFloat(revenueGrowth['DisruptiveInnovationD14']) : 0) + (revenueGrowth['DisruptiveInnovationE14'] ? parseFloat(revenueGrowth['DisruptiveInnovationE14']) : 0) + (revenueGrowth['DisruptiveInnovationF14'] ? parseFloat(revenueGrowth['DisruptiveInnovationF14']) : 0);
                newObj['DisruptiveInnovationG15'] = (revenueGrowth['DisruptiveInnovationD15'] ? parseFloat(revenueGrowth['DisruptiveInnovationD15']) : 0) + (revenueGrowth['DisruptiveInnovationE15'] ? parseFloat(revenueGrowth['DisruptiveInnovationE15']) : 0) + (revenueGrowth['DisruptiveInnovationF15'] ? parseFloat(revenueGrowth['DisruptiveInnovationF15']) : 0);
                newObj['DisruptiveInnovationG16'] = (revenueGrowth['DisruptiveInnovationD16'] ? parseFloat(revenueGrowth['DisruptiveInnovationD16']) : 0) + (revenueGrowth['DisruptiveInnovationE16'] ? parseFloat(revenueGrowth['DisruptiveInnovationE16']) : 0) + (revenueGrowth['DisruptiveInnovationF16'] ? parseFloat(revenueGrowth['DisruptiveInnovationF16']) : 0);

                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                let thenum = event.target.id.match(/\d+/)[0];
                let thestr = event.target.id.replace(thenum,'');
                let overallScore = 0;
                if(thestr == 'DisruptiveInnovationD'){
                    overallScore = (event.target.value ? parseFloat(event.target.value) : 0) + (Inputs['DisruptiveInnovationE'+thenum] ? parseFloat(Inputs['DisruptiveInnovationE'+thenum]) : 0) + (Inputs['DisruptiveInnovationF'+thenum] ? parseFloat(Inputs['DisruptiveInnovationF'+thenum]) : 0);
                    setInputs({ ...Inputs, ['DisruptiveInnovationG'+thenum] : parseFloat(overallScore) })
                }
                else if(thestr == 'DisruptiveInnovationE'){
                    overallScore = (Inputs['DisruptiveInnovationD'+thenum] ? parseFloat(Inputs['DisruptiveInnovationD'+thenum]) : 0) + (event.target.value ? parseFloat(event.target.value) : 0) + (Inputs['DisruptiveInnovationF'+thenum] ? parseFloat(Inputs['DisruptiveInnovationF'+thenum]) : 0);
                    setInputs({ ...Inputs, ['DisruptiveInnovationG'+thenum] : parseFloat(overallScore) })
                }
                else if(thestr == 'DisruptiveInnovationF'){
                    overallScore = (Inputs['DisruptiveInnovationD'+thenum] ? parseFloat(Inputs['DisruptiveInnovationD'+thenum]) : 0) + (Inputs['DisruptiveInnovationE'+thenum] ? parseFloat(Inputs['DisruptiveInnovationE'+thenum]) : 0) + (event.target.value ? parseFloat(event.target.value) : 0);
                    setInputs({ ...Inputs, ['DisruptiveInnovationG'+thenum] : parseFloat(overallScore) })
                }                
                
                dispatch(modifyDisruptiveInnovation({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value, ['DisruptiveInnovationG'+thenum] : parseFloat(overallScore)});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

  return (
    <div className="content-body">
        <UserDropdown />
        {/* row */}
        <div className="container-fluid">
            <div className="head-title">
                <p className="mb-0">Innovation</p>
                <h2 className="mb-0 mt-0">Disruptive Innovation</h2>
                <p className="mb-5">A disruptive innovation is one using new technologies or business models to make existing products and services accessible to the market. To be considered disruptive, the product or service should be Desirable, Viable and Feasible.  Disruptive innovations tend to be created more in start-up companies than larger organizations. Established businesses are more interested in creating innovations that can sustain their companies versus cannibalize their existing products and services. </p>
            </div>
            <Spinner />
            <div className="card">
                <div className="card-body pl-0">
                <div className="table-responsive table-dta">
                    {/* table 2 */}
                    <table className="table mb-0">
                    <tbody>
                        <tr>
                        <td colSpan={4} className="width-20 text-white bg-color1 padding-10 d-hidden">
                            <div className="disruptive-d">
                            <div className="White-Hat-title-agile">Jobs To Be Done</div>
                            <img src="../images/disruptive-innovation/jobs.png" className="img-fluid" alt="jobs" />
                            </div>
                        </td>
                        <td className="width-2 bg-color1 text-white padding-10">
                            <div className="disruptive-d">
                            <div className="White-Hat-title-agile">Desirability</div>
                            <img src="../images/disruptive-innovation/desirability.png" className="img-fluid" alt="desirability" />
                            </div>
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color1 padding-10">
                            <div className="disruptive-d">
                            <div className="White-Hat-title-agile">10X Better</div>
                            <img src="../images/disruptive-innovation/better.png" className="img-fluid" alt="better" />
                            </div>
                        </td>
                        <td className="width-2 bg-color1 text-white padding-10">
                            <div className="disruptive-d">
                            <div className="White-Hat-title-agile">10X Cost Improvement</div>
                            <img src="../images/disruptive-innovation/cost.png" className="img-fluid" alt="cost" />
                            </div>
                        </td>
                        <td className="width-2 bg-color1 text-white padding-10">
                            <div className="disruptive-d">
                            <div className="White-Hat-title-agile">Disruptive Potential</div>
                            <img src="../images/disruptive-innovation/disruptive-potential.png" className="img-fluid" alt="disruptive-potential" />
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4} className="width-20 text-white bg-color1 padding-15">
                            Ideas
                        </td>
                        <td className="width-2 text-white bg-color3">
                            Addresses significant and growing customer problem (1=Low; 10=High)
                        </td>
                        <td colSpan={4} className="width-20 text-white bg-color3">
                            Unique solution that's 10X simpler or better than existing options (1=Low; 10=High)
                        </td>
                        <td className="width-2 text-white bg-color3">
                            Provides a solution that's 10X cheaper; enabling new business models (1=Low; 10=High)
                        </td>
                        <td className="width-2 text-white bg-color3">
                            Score Total from Desirability + Feasability + Viability
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4} className="width-20 padding-15">
                            <input type="text" className="form-control inputText"
                                id="DisruptiveInnovationC12"
                                value={Inputs.DisruptiveInnovationC12}
                                onChange={(event) => setInputs({ ...Inputs, DisruptiveInnovationC12 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td className="width-2">
                            <input type="text" className="form-control inputText" 
                                id="DisruptiveInnovationD12" 
                                value={Inputs.DisruptiveInnovationD12} 
                                onChange={(event) => setInputs({ ...Inputs, DisruptiveInnovationD12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter number between 1 to 10.')
                                    }
                                }} />
                        </td>
                        <td colSpan={4} className="width-20">
                            <input type="text" className="form-control inputText" 
                                id="DisruptiveInnovationE12" 
                                value={Inputs.DisruptiveInnovationE12} 
                                onChange={(event) => setInputs({ ...Inputs, DisruptiveInnovationE12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter number between 1 to 10.')
                                    }
                                }} />
                        </td>
                        <td className="width-2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="DisruptiveInnovationF12" 
                                value={Inputs.DisruptiveInnovationF12} 
                                onChange={(event) => setInputs({ ...Inputs, DisruptiveInnovationF12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter number between 1 to 10.')
                                    }
                                }} />
                        </td>
                        <td className="width-2 color-r text-center-c">
                            {Inputs.DisruptiveInnovationG12}
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4} className="width-20 padding-15">
                            <input type="text" className="form-control inputText"
                                id="DisruptiveInnovationC13"
                                value={Inputs.DisruptiveInnovationC13}
                                onChange={(event) => setInputs({ ...Inputs, DisruptiveInnovationC13 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td className="width-2">
                            <input type="text" className="form-control inputText" 
                                id="DisruptiveInnovationD13" 
                                value={Inputs.DisruptiveInnovationD13} 
                                onChange={(event) => setInputs({ ...Inputs, DisruptiveInnovationD13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter number between 1 to 10.')
                                    }
                                }} />
                        </td>
                        <td colSpan={4} className="width-20">
                            <input type="text" className="form-control inputText" 
                                id="DisruptiveInnovationE13" 
                                value={Inputs.DisruptiveInnovationE13} 
                                onChange={(event) => setInputs({ ...Inputs, DisruptiveInnovationE13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter number between 1 to 10.')
                                    }
                                }} />
                        </td>
                        <td className="width-2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="DisruptiveInnovationF13" 
                                value={Inputs.DisruptiveInnovationF13} 
                                onChange={(event) => setInputs({ ...Inputs, DisruptiveInnovationF13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter number between 1 to 10.')
                                    }
                                }} />
                        </td>
                        <td className="width-2 color-y text-center-c">
                            {Inputs.DisruptiveInnovationG13}
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4} className="width-20 padding-15">
                            <input type="text" className="form-control inputText"
                                id="DisruptiveInnovationC14"
                                value={Inputs.DisruptiveInnovationC14}
                                onChange={(event) => setInputs({ ...Inputs, DisruptiveInnovationC14 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td className="width-2">
                            <input type="text" className="form-control inputText" 
                                id="DisruptiveInnovationD14" 
                                value={Inputs.DisruptiveInnovationD14} 
                                onChange={(event) => setInputs({ ...Inputs, DisruptiveInnovationD14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter number between 1 to 10.')
                                    }
                                }} />
                        </td>
                        <td colSpan={4} className="width-20">
                            <input type="text" className="form-control inputText" 
                                id="DisruptiveInnovationE14" 
                                value={Inputs.DisruptiveInnovationE14} 
                                onChange={(event) => setInputs({ ...Inputs, DisruptiveInnovationE14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter number between 1 to 10.')
                                    }
                                }} />
                        </td>
                        <td className="width-2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="DisruptiveInnovationF14" 
                                value={Inputs.DisruptiveInnovationF14} 
                                onChange={(event) => setInputs({ ...Inputs, DisruptiveInnovationF14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter number between 1 to 10.')
                                    }
                                }} />
                        </td>
                        <td className="width-2 color-g text-center-c">
                            {Inputs.DisruptiveInnovationG14}
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4} className="width-20 padding-15">
                            <input type="text" className="form-control inputText"
                                id="DisruptiveInnovationC15"
                                value={Inputs.DisruptiveInnovationC15}
                                onChange={(event) => setInputs({ ...Inputs, DisruptiveInnovationC15 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td className="width-2">
                            <input type="text" className="form-control inputText" 
                                id="DisruptiveInnovationD15" 
                                value={Inputs.DisruptiveInnovationD15} 
                                onChange={(event) => setInputs({ ...Inputs, DisruptiveInnovationD15: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter number between 1 to 10.')
                                    }
                                }} />
                        </td>
                        <td colSpan={4} className="width-20">
                            <input type="text" className="form-control inputText" 
                                id="DisruptiveInnovationE15" 
                                value={Inputs.DisruptiveInnovationE15} 
                                onChange={(event) => setInputs({ ...Inputs, DisruptiveInnovationE15: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter number between 1 to 10.')
                                    }
                                }} />
                        </td>
                        <td className="width-2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="DisruptiveInnovationF15" 
                                value={Inputs.DisruptiveInnovationF15} 
                                onChange={(event) => setInputs({ ...Inputs, DisruptiveInnovationF15: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter number between 1 to 10.')
                                    }
                                }} />
                        </td>
                        <td className="width-2 color-g text-center-c">
                            {Inputs.DisruptiveInnovationG15}
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4} className="width-20 padding-15">
                            <input type="text" className="form-control inputText"
                                id="DisruptiveInnovationC16"
                                value={Inputs.DisruptiveInnovationC16}
                                onChange={(event) => setInputs({ ...Inputs, DisruptiveInnovationC16 : event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        <td className="width-2">
                            <input type="text" className="form-control inputText" 
                                id="DisruptiveInnovationD16" 
                                value={Inputs.DisruptiveInnovationD16} 
                                onChange={(event) => setInputs({ ...Inputs, DisruptiveInnovationD16: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter number between 1 to 10.')
                                    }
                                }} />
                        </td>
                        <td colSpan={4} className="width-20">
                            <input type="text" className="form-control inputText" 
                                id="DisruptiveInnovationE16" 
                                value={Inputs.DisruptiveInnovationE16} 
                                onChange={(event) => setInputs({ ...Inputs, DisruptiveInnovationE16: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter number between 1 to 10.')
                                    }
                                }} />
                        </td>
                        <td className="width-2 text-center-c">
                            <input type="text" className="form-control inputText" 
                                id="DisruptiveInnovationF16" 
                                value={Inputs.DisruptiveInnovationF16} 
                                onChange={(event) => setInputs({ ...Inputs, DisruptiveInnovationF16: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter number between 1 to 10.')
                                    }
                                }} />
                        </td>
                        <td className="width-2 color-r text-center-c">
                            {Inputs.DisruptiveInnovationG16}
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
        </div>

    </div>
  )
}
