import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getVRIOFramework, modifyVRIOFramework } from "../../state/features/VRIOFrameworkSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import sum from "../../utils/sum";

export default function VRIOFrameworkContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        VRIOFrameworkC12 : '',
        VRIOFrameworkD12 : 0,
        VRIOFrameworkE12 : 0,
        VRIOFrameworkF12 : 0,
        VRIOFrameworkG12 : 0,
        VRIOFrameworkI12 : '',
        VRIOFrameworkC13 : '',
        VRIOFrameworkD13 : 0,
        VRIOFrameworkE13 : 0,
        VRIOFrameworkF13 : 0,
        VRIOFrameworkG13 : 0,
        VRIOFrameworkI13 : '',
        VRIOFrameworkC14 : '',
        VRIOFrameworkD14 : 0,
        VRIOFrameworkE14 : 0,
        VRIOFrameworkF14 : 0,
        VRIOFrameworkG14 : 0,
        VRIOFrameworkI14 : '',
        VRIOFrameworkC15 : '',
        VRIOFrameworkD15 : 0,
        VRIOFrameworkE15 : 0,
        VRIOFrameworkF15 : 0,
        VRIOFrameworkG15 : 0,
        VRIOFrameworkI15 : '',
        VRIOFrameworkC16 : '',
        VRIOFrameworkD16 : 0,
        VRIOFrameworkE16 : 0,
        VRIOFrameworkF16 : 0,
        VRIOFrameworkG16 : 0,
        VRIOFrameworkI16 : '',
        VRIOFrameworkC17 : '',
        VRIOFrameworkD17 : 0,
        VRIOFrameworkE17 : 0,
        VRIOFrameworkF17 : 0,
        VRIOFrameworkG17 : 0,
        VRIOFrameworkI17 : '',
        VRIOFrameworkH12 : 0,
        VRIOFrameworkH13 : 0,
        VRIOFrameworkH14 : 0,
        VRIOFrameworkH15 : 0,
        VRIOFrameworkH16 : 0,
        VRIOFrameworkH17 : 0,
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getVRIOFramework({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.growthPlanning)
            if(response!= undefined  && response.data.growthPlanning){
                let growthPlanning = response.data.growthPlanning[0];
                console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key]
                    }
                }
                newObj['VRIOFrameworkH12'] = (sum(newObj['VRIOFrameworkD12'], newObj['VRIOFrameworkE12'], newObj['VRIOFrameworkF12'], newObj['VRIOFrameworkG12']) / 4).toFixed(2);
                newObj['VRIOFrameworkH13'] = (sum(newObj['VRIOFrameworkD13'], newObj['VRIOFrameworkE13'], newObj['VRIOFrameworkF13'], newObj['VRIOFrameworkG13']) / 4).toFixed(2);
                newObj['VRIOFrameworkH14'] = (sum(newObj['VRIOFrameworkD14'], newObj['VRIOFrameworkE14'], newObj['VRIOFrameworkF14'], newObj['VRIOFrameworkG14']) / 4).toFixed(2);
                newObj['VRIOFrameworkH15'] = (sum(newObj['VRIOFrameworkD15'], newObj['VRIOFrameworkE15'], newObj['VRIOFrameworkF15'], newObj['VRIOFrameworkG15']) / 4).toFixed(2);
                newObj['VRIOFrameworkH16'] = (sum(newObj['VRIOFrameworkD16'], newObj['VRIOFrameworkE16'], newObj['VRIOFrameworkF16'], newObj['VRIOFrameworkG16']) / 4).toFixed(2);
                newObj['VRIOFrameworkH17'] = (sum(newObj['VRIOFrameworkD17'], newObj['VRIOFrameworkE17'], newObj['VRIOFrameworkF17'], newObj['VRIOFrameworkG17']) / 4).toFixed(2);
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyVRIOFramework({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        //window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

    useEffect(() => {
        let VRIOFrameworkH12 = (sum(Inputs.VRIOFrameworkD12, Inputs.VRIOFrameworkE12, Inputs.VRIOFrameworkF12, Inputs.VRIOFrameworkG12) / 4).toFixed(2);
        let VRIOFrameworkH13 = (sum(Inputs.VRIOFrameworkD13, Inputs.VRIOFrameworkE13, Inputs.VRIOFrameworkF13, Inputs.VRIOFrameworkG13) / 4).toFixed(2);
        let VRIOFrameworkH14 = (sum(Inputs.VRIOFrameworkD14, Inputs.VRIOFrameworkE14, Inputs.VRIOFrameworkF14, Inputs.VRIOFrameworkG14) / 4).toFixed(2);
        let VRIOFrameworkH15 = (sum(Inputs.VRIOFrameworkD15, Inputs.VRIOFrameworkE15, Inputs.VRIOFrameworkF15, Inputs.VRIOFrameworkG15) / 4).toFixed(2);
        let VRIOFrameworkH16 = (sum(Inputs.VRIOFrameworkD16, Inputs.VRIOFrameworkE16, Inputs.VRIOFrameworkF16, Inputs.VRIOFrameworkG16) / 4).toFixed(2);
        let VRIOFrameworkH17 = (sum(Inputs.VRIOFrameworkD17, Inputs.VRIOFrameworkE17, Inputs.VRIOFrameworkF17, Inputs.VRIOFrameworkG17) / 4).toFixed(2);

        setInputs({ ...Inputs, VRIOFrameworkH12 : VRIOFrameworkH12 != 'NaN' ? VRIOFrameworkH12 : 0,  VRIOFrameworkH13 : VRIOFrameworkH13 != 'NaN' ? VRIOFrameworkH13 : 0, VRIOFrameworkH14 : VRIOFrameworkH14 != 'NaN' ? VRIOFrameworkH14 : 0, VRIOFrameworkH15 : VRIOFrameworkH15 != 'NaN' ? VRIOFrameworkH15 : 0, VRIOFrameworkH16 : VRIOFrameworkH16 != 'NaN' ? VRIOFrameworkH16 : 0, VRIOFrameworkH17 : VRIOFrameworkH17 != 'NaN' ? VRIOFrameworkH17 : 0});

    },[Inputs.VRIOFrameworkD12, Inputs.VRIOFrameworkE12, Inputs.VRIOFrameworkF12, Inputs.VRIOFrameworkG12,Inputs.VRIOFrameworkD13, Inputs.VRIOFrameworkE13, Inputs.VRIOFrameworkF13, Inputs.VRIOFrameworkG13, Inputs.VRIOFrameworkD14, Inputs.VRIOFrameworkE14, Inputs.VRIOFrameworkF14, Inputs.VRIOFrameworkG14, Inputs.VRIOFrameworkD15, Inputs.VRIOFrameworkE15, Inputs.VRIOFrameworkF15, Inputs.VRIOFrameworkG15, Inputs.VRIOFrameworkD16, Inputs.VRIOFrameworkE16, Inputs.VRIOFrameworkF16, Inputs.VRIOFrameworkG16, Inputs.VRIOFrameworkD17, Inputs.VRIOFrameworkE17, Inputs.VRIOFrameworkF17, Inputs.VRIOFrameworkG17])

  return (
    <div className="content-body">
        <UserDropdown />
        {/* row */}
       <div className="container-fluid">
            <div className="head-title bigsout">
                <h2>VRIO Framework</h2>  
                <p>VRIO Framework is an internal corporate investigation method used to identify and evaluate resources, with the goal of increasing competitive advantage. VRIO is an acronym for four criteria that are important for a company to consider: Value, Rarity, Imitability, and Organization.</p>   
                <p>Use this template to assess various resources/capabilities that you intend to use for competitive advantage.</p>   		
            </div>
            <Spinner />
            <div className="card">
                <div className="card-body pl-0">
                <div className="table-responsive">
                    <table className="table">
                    <thead>
                        <tr className="thead-primary">
                        <th>Product / Feature</th>
                        <th>Value</th>
                        <th>Rarity</th>
                        <th>Imitability</th>
                        <th>Organized</th>
                        <th>Score</th>
                        <th>Recommendations</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td className="bgradiusf">(1=low; 5=high)</td>
                        <td className="bgradiusf">How important is the value it delivers?</td>
                        <td className="bgradiusf">How rare is the product, feature, or value?</td>
                        <td className="bgradiusf">Is it difficult to imitate?</td>
                        <td className="bgradiusf">Is the business organized to fully support it?</td>
                        <td className="bgradiusf">(1=low; 5=high)</td>
                        <td className="bgradiusf">Enter recommendation for improvement.</td>
                        </tr>
                        <tr>
                        <td className="bgradiusf">
                            <input type="text" className="text-white inputTextf form-control"
                                id="VRIOFrameworkC12"
                                value={Inputs.VRIOFrameworkC12}
                                onChange={(event) => setInputs({ ...Inputs, VRIOFrameworkC12: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Product / Feature" />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText" 
                                id="VRIOFrameworkD12" 
                                value={Inputs.VRIOFrameworkD12} 
                                onChange={(event) => setInputs({ ...Inputs, VRIOFrameworkD12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText" 
                                id="VRIOFrameworkE12" 
                                value={Inputs.VRIOFrameworkE12} 
                                onChange={(event) => setInputs({ ...Inputs, VRIOFrameworkE12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText" 
                                id="VRIOFrameworkF12" 
                                value={Inputs.VRIOFrameworkF12} 
                                onChange={(event) => setInputs({ ...Inputs, VRIOFrameworkF12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText" 
                                id="VRIOFrameworkG12" 
                                value={Inputs.VRIOFrameworkG12} 
                                onChange={(event) => setInputs({ ...Inputs, VRIOFrameworkG12: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bgradiusf">
                            {Number(Inputs.VRIOFrameworkH12).toLocaleString('en-US')}
                        </td>
                        <td>
                            <input type="text" className="inputText form-control"
                                id="VRIOFrameworkI12"
                                value={Inputs.VRIOFrameworkI12}
                                onChange={(event) => setInputs({ ...Inputs, VRIOFrameworkI12: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        </tr>
                        <tr>
                        <td className="bgradiusf">
                            <input type="text" className="text-white inputTextf form-control"
                                id="VRIOFrameworkC13"
                                value={Inputs.VRIOFrameworkC13}
                                onChange={(event) => setInputs({ ...Inputs, VRIOFrameworkC13: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Product / Feature" />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText" 
                                id="VRIOFrameworkD13" 
                                value={Inputs.VRIOFrameworkD13} 
                                onChange={(event) => setInputs({ ...Inputs, VRIOFrameworkD13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText" 
                                id="VRIOFrameworkE13" 
                                value={Inputs.VRIOFrameworkE13} 
                                onChange={(event) => setInputs({ ...Inputs, VRIOFrameworkE13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText" 
                                id="VRIOFrameworkF13" 
                                value={Inputs.VRIOFrameworkF13} 
                                onChange={(event) => setInputs({ ...Inputs, VRIOFrameworkF13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText" 
                                id="VRIOFrameworkG13" 
                                value={Inputs.VRIOFrameworkG13} 
                                onChange={(event) => setInputs({ ...Inputs, VRIOFrameworkG13: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bgradiusf">
                            {Number(Inputs.VRIOFrameworkH13).toLocaleString('en-US')}
                        </td>
                        <td>
                            <input type="text" className="inputText form-control"
                                id="VRIOFrameworkI13"
                                value={Inputs.VRIOFrameworkI13}
                                onChange={(event) => setInputs({ ...Inputs, VRIOFrameworkI13: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        </tr>
                        <tr>
                        <td className="bgradiusf">
                            <input type="text" className="text-white inputTextf form-control"
                                id="VRIOFrameworkC14"
                                value={Inputs.VRIOFrameworkC14}
                                onChange={(event) => setInputs({ ...Inputs, VRIOFrameworkC14: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Product / Feature" />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText" 
                                id="VRIOFrameworkD14" 
                                value={Inputs.VRIOFrameworkD14} 
                                onChange={(event) => setInputs({ ...Inputs, VRIOFrameworkD14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText" 
                                id="VRIOFrameworkE14" 
                                value={Inputs.VRIOFrameworkE14} 
                                onChange={(event) => setInputs({ ...Inputs, VRIOFrameworkE14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText" 
                                id="VRIOFrameworkF14" 
                                value={Inputs.VRIOFrameworkF14} 
                                onChange={(event) => setInputs({ ...Inputs, VRIOFrameworkF14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText" 
                                id="VRIOFrameworkG14" 
                                value={Inputs.VRIOFrameworkG14} 
                                onChange={(event) => setInputs({ ...Inputs, VRIOFrameworkG14: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bgradiusf">
                            {Number(Inputs.VRIOFrameworkH14).toLocaleString('en-US')}
                        </td>
                        <td>
                            <input type="text" className="inputText form-control"
                                id="VRIOFrameworkI14"
                                value={Inputs.VRIOFrameworkI14}
                                onChange={(event) => setInputs({ ...Inputs, VRIOFrameworkI14: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        </tr>
                        <tr>
                        <td className="bgradiusf">
                            <input type="text" className="text-white inputTextf form-control"
                                id="VRIOFrameworkC15"
                                value={Inputs.VRIOFrameworkC15}
                                onChange={(event) => setInputs({ ...Inputs, VRIOFrameworkC15: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Product / Feature" />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText" 
                                id="VRIOFrameworkD15" 
                                value={Inputs.VRIOFrameworkD15} 
                                onChange={(event) => setInputs({ ...Inputs, VRIOFrameworkD15: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText" 
                                id="VRIOFrameworkE15" 
                                value={Inputs.VRIOFrameworkE15} 
                                onChange={(event) => setInputs({ ...Inputs, VRIOFrameworkE15: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText" 
                                id="VRIOFrameworkF15" 
                                value={Inputs.VRIOFrameworkF15} 
                                onChange={(event) => setInputs({ ...Inputs, VRIOFrameworkF15: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText" 
                                id="VRIOFrameworkG15" 
                                value={Inputs.VRIOFrameworkG15} 
                                onChange={(event) => setInputs({ ...Inputs, VRIOFrameworkG15: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bgradiusf">
                            {Number(Inputs.VRIOFrameworkH15).toLocaleString('en-US')}
                        </td>
                        <td>
                            <input type="text" className="inputText form-control"
                                id="VRIOFrameworkI15"
                                value={Inputs.VRIOFrameworkI15}
                                onChange={(event) => setInputs({ ...Inputs, VRIOFrameworkI15: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        </tr>
                        <tr>
                        <td className="bgradiusf">
                            <input type="text" className="text-white inputTextf form-control"
                                id="VRIOFrameworkC16"
                                value={Inputs.VRIOFrameworkC16}
                                onChange={(event) => setInputs({ ...Inputs, VRIOFrameworkC16: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Product / Feature" />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText" 
                                id="VRIOFrameworkD16" 
                                value={Inputs.VRIOFrameworkD16} 
                                onChange={(event) => setInputs({ ...Inputs, VRIOFrameworkD16: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText" 
                                id="VRIOFrameworkE16" 
                                value={Inputs.VRIOFrameworkE16} 
                                onChange={(event) => setInputs({ ...Inputs, VRIOFrameworkE16: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText" 
                                id="VRIOFrameworkF16" 
                                value={Inputs.VRIOFrameworkF16} 
                                onChange={(event) => setInputs({ ...Inputs, VRIOFrameworkF16: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText" 
                                id="VRIOFrameworkG16" 
                                value={Inputs.VRIOFrameworkG16} 
                                onChange={(event) => setInputs({ ...Inputs, VRIOFrameworkG16: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bgradiusf">
                            {Number(Inputs.VRIOFrameworkH16).toLocaleString('en-US')}
                        </td>
                        <td>
                            <input type="text" className="inputText form-control"
                                id="VRIOFrameworkI16"
                                value={Inputs.VRIOFrameworkI16}
                                onChange={(event) => setInputs({ ...Inputs, VRIOFrameworkI16: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        </tr>
                        <tr>
                        <td className="bgradiusf">
                            <input type="text" className="text-white inputTextf form-control"
                                id="VRIOFrameworkC17"
                                value={Inputs.VRIOFrameworkC17}
                                onChange={(event) => setInputs({ ...Inputs, VRIOFrameworkC17: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Product / Feature" />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText" 
                                id="VRIOFrameworkD17" 
                                value={Inputs.VRIOFrameworkD17} 
                                onChange={(event) => setInputs({ ...Inputs, VRIOFrameworkD17: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText" 
                                id="VRIOFrameworkE17" 
                                value={Inputs.VRIOFrameworkE17} 
                                onChange={(event) => setInputs({ ...Inputs, VRIOFrameworkE17: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText" 
                                id="VRIOFrameworkF17" 
                                value={Inputs.VRIOFrameworkF17} 
                                onChange={(event) => setInputs({ ...Inputs, VRIOFrameworkF17: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText" 
                                id="VRIOFrameworkG17" 
                                value={Inputs.VRIOFrameworkG17} 
                                onChange={(event) => setInputs({ ...Inputs, VRIOFrameworkG17: event.target.value }) } 
                                onBlur={(event) => { onBlurHandler(event) }} 
                                disabled={isLoading} 
                                placeholder="0" 
                                maxLength="2" 
                                onKeyPress={(event) => {
                                    if (!/[1-5]/.test(event.key)) {
                                        event.preventDefault();
                                        alert.show('Please Enter Score range 1 to 5.')
                                    }
                                }} />
                        </td>
                        <td className="bgradiusf">
                            {Number(Inputs.VRIOFrameworkH17).toLocaleString('en-US')}
                        </td>
                        <td>
                            <input type="text" className="inputText form-control"
                                id="VRIOFrameworkI17"
                                value={Inputs.VRIOFrameworkI17}
                                onChange={(event) => setInputs({ ...Inputs, VRIOFrameworkI17: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} />
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}
