import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getTheBigSWOT, modifyTheBigSWOT } from "../../state/features/TheBigSWOTSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';

export default function TheBigSWOTContent() {

    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        BigSWOTC17 : '',
        BigSWOTH30 : '',
        BigSWOTJ30 : '',
        BigSWOTK30 : '',
        BigSWOTM30 : '',
        BigSWOTH31 : '',
        BigSWOTJ31 : '',
        BigSWOTK31 : '',
        BigSWOTM31 : '',
        BigSWOTH32 : '',
        BigSWOTJ32 : '',
        BigSWOTK32 : '',
        BigSWOTM32 : '',
        BigSWOTH34 : '',
        BigSWOTJ34 : '',
        BigSWOTK34 : '',
        BigSWOTM34 : '',
        BigSWOTH35 : '',
        BigSWOTJ35 : '',
        BigSWOTK35 : '',
        BigSWOTM35 : '',
        BigSWOTH36 : '',
        BigSWOTJ36 : '',
        BigSWOTK36 : '',
        BigSWOTM36 : '',
        BigSWOTH38 : '',
        BigSWOTJ38 : '',
        BigSWOTK38 : '',
        BigSWOTM38 : '',
        BigSWOTH39 : '',
        BigSWOTJ39 : '',
        BigSWOTK39 : '',
        BigSWOTM39 : '',
        BigSWOTH40 : '',
        BigSWOTJ40 : '',
        BigSWOTK40 : '',
        BigSWOTM40 : '',
        BigSWOTH42 : '',
        BigSWOTJ42 : '',
        BigSWOTK42 : '',
        BigSWOTM42 : '',
        BigSWOTH43 : '',
        BigSWOTJ43 : '',
        BigSWOTK43 : '',
        BigSWOTM43 : '',
        BigSWOTH44 : '',
        BigSWOTJ44 : '',
        BigSWOTK44 : '',
        BigSWOTM44 : '',
        BigSWOTH45 : '',
        BigSWOTJ45 : '',
        BigSWOTK45 : '',
        BigSWOTM45 : '',
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getTheBigSWOT({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.growthPlanning)
            if(response!= undefined  && response.data.growthPlanning){
                let growthPlanning = response.data.growthPlanning[0];
                console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key]
                    }
                }
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyTheBigSWOT({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

  return (
    <div className="content-body">
    <UserDropdown />
        {/* row */}
        <div className="container-fluid">
            <div className="head-title bigsout">
                <h2>The Big SWOT</h2>  
                <p><b>SWOT Analysis</b> is a strategic planning tool that explores an organization’s internal and external environment, including strengths, weaknesses, opportunities, and threats. Perform your SWOT Analysis for either your entire business or a specific segment of your business.</p>     
                <p>Sometimes called an Internal-External Analysis (IE Analysis), SWOT analysis is used widely by many teams, organizations, and consultants to quickly assess the most relevant aspects of a company’s current and potential future strategy. When creating your SWOT, consider the following questions:</p>
                <ul>
                <li><b>Strengths:</b>  What are our internal core competencies and source of competitive differentiation?</li>
                <li><b>Weaknesses:</b>  What internal capability gaps or weaknesses exist that might cause problems in the future?</li>
                <li><b>Opportunities:</b> What external market opportunities that we should pursue?</li>
                <li><b>Threats:</b> What external threats exists that we should address?</li>
                </ul>  		
                <p>Existing organizations can use a SWOT analysis as part of their traditional planning processes. New ventures and start-ups can use SWOT analysis to gain insight into potentially future challenges and ways to create competitive differentiation. Even nonprofit organizations can use SWOT analysis. The goal is to create a simple structure that engages and aligns teams and organizations around the current state and future strategies and plans for the business.</p>			
            </div>  
            <Spinner />                                            	
            <div className="card">
                <div className="card-body pl-0">                                            
                <div className="table-responsive">
                    <table className="table">
                    <thead>
                        <tr className="thead-primary">
                        <th>Purpose</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td className="lightyello">
                            <input type="text" className="form-control text-left"
                                id="BigSWOTC17"
                                value={Inputs.BigSWOTC17}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTC17: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                                placeholder="Enter the purpose of the analysis here" 
                            />
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                <tr>
                                    <th className="bluetab"><strong>S</strong></th>
                                    <th className="bgradiusf">Internal STRENGTHS</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="bgradiusf">1</td>
                                    <td>What do our customers love most?</td>
                                </tr>
                                <tr>
                                    <td className="bgradiusf">2</td>
                                    <td>What are we more efficient at?</td>
                                </tr>
                                <tr>
                                    <td className="bgradiusf">3</td>
                                    <td>What can we do for less money?</td>
                                </tr>
                                <tr>
                                    <td className="bgradiusf">4</td>
                                    <td>What can we do in less time?</td>
                                </tr>
                                <tr>
                                    <td className="bgradiusf">5</td>
                                    <td>What makes us stand out?</td>
                                </tr>
                                
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                <tr>
                                    <th className="bluetab"><strong>W</strong></th>
                                    <th className="bgradiusf">Internal WEAKNESSSES</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="bgradiusf">1</td>
                                    <td>Where do we lack efficiency?</td>
                                </tr>
                                <tr>
                                    <td className="bgradiusf">2</td>
                                    <td>Where are we wasting money?</td>
                                </tr>
                                <tr>
                                    <td className="bgradiusf">3</td>
                                    <td>Where are we wasting time and resources?</td>
                                </tr>
                                <tr>
                                    <td className="bgradiusf">4</td>
                                    <td>What do our competitors do better?</td>
                                </tr>
                                <tr>
                                    <td className="bgradiusf">5</td>
                                    <td>What are our top customer complaints?</td>
                                </tr>
                                
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                <tr>
                                    <th className="bluetab"><strong>O</strong></th>
                                    <th className="bgradiusf">External OPPORTUNITIES</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="bgradiusf">1</td>
                                    <td>What is missing in our market?</td>
                                </tr>
                                <tr>
                                    <td className="bgradiusf">2</td>
                                    <td>What could we create or do better than a competitor?</td>
                                </tr>
                                <tr>
                                    <td className="bgradiusf">3</td>
                                    <td>What new trends are occurring?</td>
                                </tr>
                                <tr>
                                    <td className="bgradiusf">4</td>
                                    <td>What new technology could we use?</td>
                                </tr>
                                <tr>
                                    <td className="bgradiusf">5</td>
                                    <td>What openings in the market are there?</td>
                                </tr>
                                
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                <tr>
                                    <th className="bluetab"><strong>T</strong></th>
                                    <th className="bgradiusf">External THREATS</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="bgradiusf">1</td>
                                    <td>What changes are occurring in our market's environment?</td>
                                </tr>
                                <tr>
                                    <td className="bgradiusf">2</td>
                                    <td>What technologies could replace what we do?</td>
                                </tr>
                                <tr>
                                    <td className="bgradiusf">3</td>
                                    <td>What changes are occurring in the way we're being discovered?</td>
                                </tr>
                                <tr>
                                    <td className="bgradiusf">4</td>
                                    <td>What social changes could threaten us?</td>
                                </tr>
                                <tr>
                                    <td className="bgradiusf">5</td>
                                    <td>Are there any threatening government policies or regulations?</td>
                                </tr>
                                
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body pl-0">
                <div className="table-responsive">
                    <table className="table">
                    <thead>
                        <tr className="thead-primary">
                        <th colSpan={5}>Comprehensive SWOT Analysis Categories</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td className="bgradiusf">External: Targets</td>
                        <td className="bgradiusf">Rank Order</td>
                        <td className="bgradiusf">Opportunities</td>
                        <td className="bgradiusf">Rank Order</td>
                        <td className="bgradiusf">Threats</td>
                        </tr>
                        <tr>
                        <td>Customer Segments</td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTH30"
                                value={Inputs.BigSWOTH30}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTH30: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTJ30"
                                value={Inputs.BigSWOTJ30}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTJ30: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTK30"
                                value={Inputs.BigSWOTK30}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTK30: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTM30"
                                value={Inputs.BigSWOTM30}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTM30: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        </tr>
                        <tr>
                        <td>Markets</td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTH31"
                                value={Inputs.BigSWOTH31}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTH31: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTJ31"
                                value={Inputs.BigSWOTJ31}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTJ31: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTK31"
                                value={Inputs.BigSWOTK31}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTK31: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTM31"
                                value={Inputs.BigSWOTM31}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTM31: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        </tr>
                        <tr>
                        <td>Geographies</td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTH32"
                                value={Inputs.BigSWOTH32}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTH32: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTJ32"
                                value={Inputs.BigSWOTJ32}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTJ32: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTK32"
                                value={Inputs.BigSWOTK32}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTK32: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTM32"
                                value={Inputs.BigSWOTM32}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTM32: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        </tr>
                        <tr>
                        <td className="bgradiusf">Internal:  Value Propositions</td>
                        <td className="bgradiusf">Rank Order</td>
                        <td className="bgradiusf">Strengths Relative to Competitors</td>
                        <td className="bgradiusf">Rank Order</td>
                        <td className="bgradiusf">Weaknesses Relative to Competitors</td>
                        </tr>
                        <tr>
                        <td>Products</td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTH34"
                                value={Inputs.BigSWOTH34}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTH34: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTJ34"
                                value={Inputs.BigSWOTJ34}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTJ34: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTK34"
                                value={Inputs.BigSWOTK34}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTK34: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTM34"
                                value={Inputs.BigSWOTM34}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTM34: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        </tr>
                        <tr>
                        <td>Services</td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTH35"
                                value={Inputs.BigSWOTH35}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTH35: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTJ35"
                                value={Inputs.BigSWOTJ35}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTJ35: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTK35"
                                value={Inputs.BigSWOTK35}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTK35: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTM35"
                                value={Inputs.BigSWOTM35}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTM35: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        </tr>
                        <tr>
                        <td>Pricing</td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTH36"
                                value={Inputs.BigSWOTH36}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTH36: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTJ36"
                                value={Inputs.BigSWOTJ36}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTJ36: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTK36"
                                value={Inputs.BigSWOTK36}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTK36: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTM36"
                                value={Inputs.BigSWOTM36}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTM36: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        </tr>
                        <tr>
                        <td className="bgradiusf">Internal:  Go-To-Market</td>
                        <td className="bgradiusf">Rank Order</td>
                        <td className="bgradiusf">Strengths Relative to Competitors</td>
                        <td className="bgradiusf">Rank Order</td>
                        <td className="bgradiusf">Weaknesses Relative to Competitors</td>
                        </tr>
                        <tr>
                        <td>Distribution</td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTH38"
                                value={Inputs.BigSWOTH38}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTH38: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTJ38"
                                value={Inputs.BigSWOTJ38}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTJ38: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTK38"
                                value={Inputs.BigSWOTK38}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTK38: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTM38"
                                value={Inputs.BigSWOTM38}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTM38: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        </tr>
                        <tr>
                        <td>Marketing</td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTH39"
                                value={Inputs.BigSWOTH39}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTH39: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTJ39"
                                value={Inputs.BigSWOTJ39}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTJ39: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTK39"
                                value={Inputs.BigSWOTK39}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTK39: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTM39"
                                value={Inputs.BigSWOTM39}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTM39: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        </tr>
                        <tr>
                        <td>Sales</td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTH40"
                                value={Inputs.BigSWOTH40}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTH40: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTJ40"
                                value={Inputs.BigSWOTJ40}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTJ40: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTK40"
                                value={Inputs.BigSWOTK40}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTK40: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTM40"
                                value={Inputs.BigSWOTM40}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTM40: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        </tr>
                        <tr>
                        <td className="bgradiusf">Internal:  The Organization</td>
                        <td className="bgradiusf">Rank Order</td>
                        <td className="bgradiusf">Strengths Relative to Competitors</td>
                        <td className="bgradiusf">Rank Order</td>
                        <td className="bgradiusf">Weaknesses Relative to Competitors</td>
                        </tr>
                        <tr>
                        <td>Processes / Functions</td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTH42"
                                value={Inputs.BigSWOTH42}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTH42: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTJ42"
                                value={Inputs.BigSWOTJ42}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTJ42: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTK42"
                                value={Inputs.BigSWOTK42}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTK42: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTM42"
                                value={Inputs.BigSWOTM42}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTM42: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        </tr>
                        <tr>
                        <td>People</td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTH43"
                                value={Inputs.BigSWOTH43}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTH43: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTJ43"
                                value={Inputs.BigSWOTJ43}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTJ43: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTK43"
                                value={Inputs.BigSWOTK43}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTK43: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTM43"
                                value={Inputs.BigSWOTM43}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTM43: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        </tr>
                        <tr>
                        <td>Infrastructure</td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTH44"
                                value={Inputs.BigSWOTH44}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTH44: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTJ44"
                                value={Inputs.BigSWOTJ44}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTJ44: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTK44"
                                value={Inputs.BigSWOTK44}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTK44: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTM44"
                                value={Inputs.BigSWOTM44}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTM44: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        </tr>
                        <tr>
                        <td>Partnerships</td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTH45"
                                value={Inputs.BigSWOTH45}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTH45: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTJ45"
                                value={Inputs.BigSWOTJ45}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTJ45: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTK45"
                                value={Inputs.BigSWOTK45}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTK45: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control inputText"
                                id="BigSWOTM45"
                                value={Inputs.BigSWOTM45}
                                onChange={(event) => setInputs({ ...Inputs, BigSWOTM45: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading} 
                            />
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body pl-0">
                <div className="table-responsive">
                    <table className="table">
                    <thead>
                        <tr className="thead-primary">
                        <th style={{textAlign: 'center'}} colSpan={2}>Action Items &amp; Goals</th>
                        </tr>                                    
                    </thead>
                    <tbody>
                        <tr>
                        <td>1</td>
                        <td>Which opportunities should we pursue? How can we use our strengths to help us achieve our goals?</td>
                        </tr>
                        <tr>
                        <td>2</td>
                        <td>Which weaknesses need to be improved so we are less susceptible to external threats?</td>
                        </tr>
                        <tr>
                        <td>3</td>
                        <td>What strategies can we put into place to be prepared for threats?</td>
                        </tr>
                        <tr>
                        <td>4</td>
                        <td>How can we use our strengths to make best use of any existing opportunities?</td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            </div>

    </div>
  )
}
