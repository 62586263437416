import React from "react";
import { Line } from "react-chartjs-2";
import { Chart } from "react-google-charts";

export function MultipleLineChartGraphNew({dataInput}) {

  const data = dataInput.GraphData;
  const Title = dataInput.Title ? dataInput.Title : "";
  const vTitle = dataInput.vTitle ? dataInput.vTitle : "";
  const hTitle = dataInput.hTitle ? dataInput.hTitle : "";
  const subTitle = dataInput.subTitle ? dataInput.subTitle : "";
  const ColorCodes = dataInput.ColorCodes ? dataInput.ColorCodes : ["#efbc44", "#447fef", "#0e8262", "#aa28d9"];

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    title: Title,
    scales: {
        x: {
            ticks: {
                tickColor: 'white',
                color: 'white'
            },
            grid: {
                display: false
            }
        },
        y: {
            ticks: {
                tickColor: 'white',
                color: 'white',
                callback: function(value, index, ticks) {
                  return '$' + Number(value).toLocaleString('en-US');
                }
            },
            grid: {
                color: '#334966'
            }
        }
    },
    plugins: {
      legend: {
        position: 'right',
        labels: {
          boxWidth: 15,
          boxHeight: 2
        }
      },
      title: {
        display: true,
        text: dataInput.Title,
        color: 'white',
        font: {
          size: 24,
          weight: 600
        }
      }
    }
  };

  return (
    // <Chart
    //   chartType="line"
    //   width="100%"
    //   data={data}
    //   options={options}
    // />
    <Line
    data={data}
    options={options}
    />
  );
}
