import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getStrategyAndInnovationMap, modifyStrategyAndInnovationMap } from "../../state/features/StrategyAndInnovationMapSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';

export default function StrategyAndInnovationMapContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        StrategyAndInnovationMapE12 : '',
        StrategyAndInnovationMapF12 : '',
        StrategyAndInnovationMapG12 : '',
        StrategyAndInnovationMapH12 : '',
        StrategyAndInnovationMapI12 : '',
        StrategyAndInnovationMapJ12 : '',
        StrategyAndInnovationMapK12 : '',
        StrategyAndInnovationMapE13 : '',
        StrategyAndInnovationMapF13 : '',
        StrategyAndInnovationMapG13 : '',
        StrategyAndInnovationMapH13 : '',
        StrategyAndInnovationMapI13 : '',
        StrategyAndInnovationMapJ13 : '',
        StrategyAndInnovationMapK13 : '',
        StrategyAndInnovationMapE14 : '',
        StrategyAndInnovationMapF14 : '',
        StrategyAndInnovationMapG14 : '',
        StrategyAndInnovationMapH14 : '',
        StrategyAndInnovationMapI14 : '',
        StrategyAndInnovationMapJ14 : '',
        StrategyAndInnovationMapK14 : '',
        StrategyAndInnovationMapE15 : '',
        StrategyAndInnovationMapF15 : '',
        StrategyAndInnovationMapG15 : '',
        StrategyAndInnovationMapH15 : '',
        StrategyAndInnovationMapI15 : '',
        StrategyAndInnovationMapJ15 : '',
        StrategyAndInnovationMapK15 : '',
        StrategyAndInnovationMapE16 : '',
        StrategyAndInnovationMapF16 : '',
        StrategyAndInnovationMapG16 : '',
        StrategyAndInnovationMapH16 : '',
        StrategyAndInnovationMapI16 : '',
        StrategyAndInnovationMapJ16 : '',
        StrategyAndInnovationMapK16 : '',
        StrategyAndInnovationMapE17 : '',
        StrategyAndInnovationMapF17 : '',
        StrategyAndInnovationMapG17 : '',
        StrategyAndInnovationMapH17 : '',
        StrategyAndInnovationMapI17 : '',
        StrategyAndInnovationMapJ17 : '',
        StrategyAndInnovationMapK17 : '',
        StrategyAndInnovationMapE18 : '',
        StrategyAndInnovationMapF18 : '',
        StrategyAndInnovationMapG18 : '',
        StrategyAndInnovationMapH18 : '',
        StrategyAndInnovationMapI18 : '',
        StrategyAndInnovationMapJ18 : '',
        StrategyAndInnovationMapK18 : '',
        StrategyAndInnovationMapE19 : '',
        StrategyAndInnovationMapF19 : '',
        StrategyAndInnovationMapG19 : '',
        StrategyAndInnovationMapH19 : '',
        StrategyAndInnovationMapI19 : '',
        StrategyAndInnovationMapJ19 : '',
        StrategyAndInnovationMapK19 : '',
        StrategyAndInnovationMapE20 : '',
        StrategyAndInnovationMapF20 : '',
        StrategyAndInnovationMapG20 : '',
        StrategyAndInnovationMapH20 : '',
        StrategyAndInnovationMapI20 : '',
        StrategyAndInnovationMapJ20 : '',
        StrategyAndInnovationMapK20 : '',
        StrategyAndInnovationMapE21 : '',
        StrategyAndInnovationMapF21 : '',
        StrategyAndInnovationMapG21 : '',
        StrategyAndInnovationMapH21 : '',
        StrategyAndInnovationMapI21 : '',
        StrategyAndInnovationMapJ21 : '',
        StrategyAndInnovationMapK21 : '',
        StrategyAndInnovationMapE22 : '',
        StrategyAndInnovationMapF22 : '',
        StrategyAndInnovationMapG22 : '',
        StrategyAndInnovationMapH22 : '',
        StrategyAndInnovationMapI22 : '',
        StrategyAndInnovationMapJ22 : '',
        StrategyAndInnovationMapK22 : '',
        StrategyAndInnovationMapE23 : '',
        StrategyAndInnovationMapF23 : '',
        StrategyAndInnovationMapG23 : '',
        StrategyAndInnovationMapH23 : '',
        StrategyAndInnovationMapI23 : '',
        StrategyAndInnovationMapJ23 : '',
        StrategyAndInnovationMapK23 : '',
        StrategyAndInnovationMapE24 : '',
        StrategyAndInnovationMapF24 : '',
        StrategyAndInnovationMapG24 : '',
        StrategyAndInnovationMapH24 : '',
        StrategyAndInnovationMapI24 : '',
        StrategyAndInnovationMapJ24 : '',
        StrategyAndInnovationMapK24 : '',
        StrategyAndInnovationMapE25 : '',
        StrategyAndInnovationMapF25 : '',
        StrategyAndInnovationMapG25 : '',
        StrategyAndInnovationMapH25 : '',
        StrategyAndInnovationMapI25 : '',
        StrategyAndInnovationMapJ25 : '',
        StrategyAndInnovationMapK25 : '',
        StrategyAndInnovationMapE26 : '',
        StrategyAndInnovationMapF26 : '',
        StrategyAndInnovationMapG26 : '',
        StrategyAndInnovationMapH26 : '',
        StrategyAndInnovationMapI26 : '',
        StrategyAndInnovationMapJ26 : '',
        StrategyAndInnovationMapK26 : '',
        StrategyAndInnovationMapE27 : '',
        StrategyAndInnovationMapF27 : '',
        StrategyAndInnovationMapG27 : '',
        StrategyAndInnovationMapH27 : '',
        StrategyAndInnovationMapI27 : '',
        StrategyAndInnovationMapJ27 : '',
        StrategyAndInnovationMapK27 : '',
        StrategyAndInnovationMapE28 : '',
        StrategyAndInnovationMapF28 : '',
        StrategyAndInnovationMapG28 : '',
        StrategyAndInnovationMapH28 : '',
        StrategyAndInnovationMapI28 : '',
        StrategyAndInnovationMapJ28 : '',
        StrategyAndInnovationMapK28 : '',
        StrategyAndInnovationMapE29 : '',
        StrategyAndInnovationMapF29 : '',
        StrategyAndInnovationMapG29 : '',
        StrategyAndInnovationMapH29 : '',
        StrategyAndInnovationMapI29 : '',
        StrategyAndInnovationMapJ29 : '',
        StrategyAndInnovationMapK29 : '',
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getStrategyAndInnovationMap({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.growthPlanning)
            if(response!= undefined  && response.data.growthPlanning){
                let growthPlanning = response.data.growthPlanning[0];
                console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key]
                    }
                }
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyStrategyAndInnovationMap({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        //window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

  return (
    <div className="content-body">
    <UserDropdown />
    
        {/* row */}
        <div className="container-fluid">
            <div className="head-title">
                <h2>Strategy &amp; Innovation Map</h2>	 
                <p>A <b>Strategy &amp; Innovation Map</b> is a clear visual representation of the major short-, mid-, and long-term strategic objectives of an organization. This representation is useful for communicating the organization’s strategy to employees at all levels of the organization. In this way, everyone can clearly see where the organization is going and how their function fits in to the strategy.</p>
                <p>Strategy Maps typically include time points for key events - like when the organization should implement each step of the plan or arrive at a desired state. While a Strategy Map cannot be used to predict whether the organization will actually attain its goals, its visual display can help your team determine what steps need to be taken to get there.  A Strategy Map is often used by leadership to communicate its Strategic Plan.</p>           					
            </div> 
            <Spinner />
            <div className="card">
                <div className="card-body pl-0 fivestep">                                            
                <table className="table table-responsive">
                    <thead>
                    <tr>
                        <th colSpan={2} />
                        <th colSpan={2} className="bgradius">Short-Term</th>
                        <th colSpan={2} className="bgradius">Mid-Term</th>
                        <th colSpan={3} className="bgradius">Long-Term</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th colSpan={2} />
                        <th className="bluetab">1-3 months</th>
                        <th className="bluetab">4-6 months</th>
                        <th className="bluetab">6-12 months</th>
                        <th className="bluetab">12-24 months</th>
                        <th className="bluetab">24-36 months</th>
                        <th className="bluetab">36-60 months</th>
                        <th className="bluetab">60-120 Months</th>
                    </tr>
                    <tr>
                        <td><img src="../images/svg/8.png" /></td>
                        <td className="bgradiusf">Strategic Goals</td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapE12"
                                value={Inputs.StrategyAndInnovationMapE12}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapE12: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapF12"
                                value={Inputs.StrategyAndInnovationMapF12}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapF12: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapG12"
                                value={Inputs.StrategyAndInnovationMapG12}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapG12: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapH12"
                                value={Inputs.StrategyAndInnovationMapH12}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapH12: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapI12"
                                value={Inputs.StrategyAndInnovationMapI12}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapI12: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapJ12"
                                value={Inputs.StrategyAndInnovationMapJ12}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapJ12: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapK12"
                                value={Inputs.StrategyAndInnovationMapK12}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapK12: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td><img src="../images/svg/8.png" /></td>
                        <td className="bgradiusf">Strategic Focus</td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapE13"
                                value={Inputs.StrategyAndInnovationMapE13}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapE13: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapF13"
                                value={Inputs.StrategyAndInnovationMapF13}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapF13: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapG13"
                                value={Inputs.StrategyAndInnovationMapG13}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapG13: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapH13"
                                value={Inputs.StrategyAndInnovationMapH13}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapH13: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapI13"
                                value={Inputs.StrategyAndInnovationMapI13}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapI13: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapJ13"
                                value={Inputs.StrategyAndInnovationMapJ13}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapJ13: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapK13"
                                value={Inputs.StrategyAndInnovationMapK13}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapK13: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td><img src="../images/svg/8.png" /></td>
                        <td className="bgradiusf">Business Model</td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapE14"
                                value={Inputs.StrategyAndInnovationMapE14}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapE14: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapF14"
                                value={Inputs.StrategyAndInnovationMapF14}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapF14: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapG14"
                                value={Inputs.StrategyAndInnovationMapG14}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapG14: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapH14"
                                value={Inputs.StrategyAndInnovationMapH14}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapH14: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapI14"
                                value={Inputs.StrategyAndInnovationMapI14}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapI14: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapJ14"
                                value={Inputs.StrategyAndInnovationMapJ14}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapJ14: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapK14"
                                value={Inputs.StrategyAndInnovationMapK14}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapK14: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                    </tr>                                        
                    <tr>
                        <td><img src="../images/svg/8.png" /></td>
                        <td className="bgradiusf">Scope</td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapE15"
                                value={Inputs.StrategyAndInnovationMapE15}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapE15: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapF15"
                                value={Inputs.StrategyAndInnovationMapF15}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapF15: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapG15"
                                value={Inputs.StrategyAndInnovationMapG15}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapG15: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapH15"
                                value={Inputs.StrategyAndInnovationMapH15}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapH15: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapI15"
                                value={Inputs.StrategyAndInnovationMapI15}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapI15: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapJ15"
                                value={Inputs.StrategyAndInnovationMapJ15}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapJ15: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapK15"
                                value={Inputs.StrategyAndInnovationMapK15}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapK15: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td><img src="../images/svg/8.png" /></td>
                        <td className="bgradiusf">Key Risks &amp; Barriers</td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapE16"
                                value={Inputs.StrategyAndInnovationMapE16}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapE16: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapF16"
                                value={Inputs.StrategyAndInnovationMapF16}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapF16: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapG16"
                                value={Inputs.StrategyAndInnovationMapG16}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapG16: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapH16"
                                value={Inputs.StrategyAndInnovationMapH16}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapH16: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapI16"
                                value={Inputs.StrategyAndInnovationMapI16}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapI16: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapJ16"
                                value={Inputs.StrategyAndInnovationMapJ16}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapJ16: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapK16"
                                value={Inputs.StrategyAndInnovationMapK16}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapK16: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td><img src="../images/svg/8.png" /></td>
                        <td className="bgradiusf">Partnerships</td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapE17"
                                value={Inputs.StrategyAndInnovationMapE17}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapE17: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapF17"
                                value={Inputs.StrategyAndInnovationMapF17}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapF17: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapG17"
                                value={Inputs.StrategyAndInnovationMapG17}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapG17: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapH17"
                                value={Inputs.StrategyAndInnovationMapH17}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapH17: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapI17"
                                value={Inputs.StrategyAndInnovationMapI17}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapI17: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapJ17"
                                value={Inputs.StrategyAndInnovationMapJ17}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapJ17: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapK17"
                                value={Inputs.StrategyAndInnovationMapK17}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapK17: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td><img src="../images/svg/8.png" /></td>
                        <td className="bgradiusf">Target Market &amp; Customers</td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapE18"
                                value={Inputs.StrategyAndInnovationMapE18}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapE18: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapF18"
                                value={Inputs.StrategyAndInnovationMapF18}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapF18: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapG18"
                                value={Inputs.StrategyAndInnovationMapG18}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapG18: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapH18"
                                value={Inputs.StrategyAndInnovationMapH18}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapH18: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapI18"
                                value={Inputs.StrategyAndInnovationMapI18}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapI18: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapJ18"
                                value={Inputs.StrategyAndInnovationMapJ18}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapJ18: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapK18"
                                value={Inputs.StrategyAndInnovationMapK18}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapK18: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td><img src="../images/svg/8.png" /></td>
                        <td className="bgradiusf">Customer Needs</td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapE19"
                                value={Inputs.StrategyAndInnovationMapE19}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapE19: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapF19"
                                value={Inputs.StrategyAndInnovationMapF19}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapF19: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapG19"
                                value={Inputs.StrategyAndInnovationMapG19}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapG19: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapH19"
                                value={Inputs.StrategyAndInnovationMapH19}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapH19: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapI19"
                                value={Inputs.StrategyAndInnovationMapI19}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapI19: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapJ19"
                                value={Inputs.StrategyAndInnovationMapJ19}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapJ19: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapK19"
                                value={Inputs.StrategyAndInnovationMapK19}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapK19: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td><img src="../images/svg/8.png" /></td>
                        <td className="bgradiusf">Competition</td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapE20"
                                value={Inputs.StrategyAndInnovationMapE20}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapE20: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapF20"
                                value={Inputs.StrategyAndInnovationMapF20}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapF20: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapG20"
                                value={Inputs.StrategyAndInnovationMapG20}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapG20: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapH20"
                                value={Inputs.StrategyAndInnovationMapH20}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapH20: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapI20"
                                value={Inputs.StrategyAndInnovationMapI20}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapI20: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapJ20"
                                value={Inputs.StrategyAndInnovationMapJ20}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapJ20: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapK20"
                                value={Inputs.StrategyAndInnovationMapK20}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapK20: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td><img src="../images/svg/8.png" /></td>
                        <td className="bgradiusf">Your Solutions</td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapE21"
                                value={Inputs.StrategyAndInnovationMapE21}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapE21: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapF21"
                                value={Inputs.StrategyAndInnovationMapF21}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapF21: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapG21"
                                value={Inputs.StrategyAndInnovationMapG21}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapG21: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapH21"
                                value={Inputs.StrategyAndInnovationMapH21}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapH21: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapI21"
                                value={Inputs.StrategyAndInnovationMapI21}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapI21: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapJ21"
                                value={Inputs.StrategyAndInnovationMapJ21}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapJ21: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapK21"
                                value={Inputs.StrategyAndInnovationMapK21}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapK21: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td><img src="../images/svg/8.png" /></td>
                        <td className="bgradiusf">Products &amp; Services</td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapE22"
                                value={Inputs.StrategyAndInnovationMapE22}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapE22: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapF22"
                                value={Inputs.StrategyAndInnovationMapF22}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapF22: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapG22"
                                value={Inputs.StrategyAndInnovationMapG22}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapG22: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapH22"
                                value={Inputs.StrategyAndInnovationMapH22}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapH22: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapI22"
                                value={Inputs.StrategyAndInnovationMapI22}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapI22: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapJ22"
                                value={Inputs.StrategyAndInnovationMapJ22}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapJ22: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapK22"
                                value={Inputs.StrategyAndInnovationMapK22}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapK22: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td><img src="../images/svg/8.png" /></td>
                        <td className="bgradiusf">Marketing</td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapE23"
                                value={Inputs.StrategyAndInnovationMapE23}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapE23: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapF23"
                                value={Inputs.StrategyAndInnovationMapF23}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapF23: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapG23"
                                value={Inputs.StrategyAndInnovationMapG23}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapG23: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapH23"
                                value={Inputs.StrategyAndInnovationMapH23}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapH23: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapI23"
                                value={Inputs.StrategyAndInnovationMapI23}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapI23: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapJ23"
                                value={Inputs.StrategyAndInnovationMapJ23}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapJ23: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapK23"
                                value={Inputs.StrategyAndInnovationMapK23}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapK23: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td><img src="../images/svg/8.png" /></td>
                        <td className="bgradiusf">Sales</td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapE24"
                                value={Inputs.StrategyAndInnovationMapE24}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapE24: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapF24"
                                value={Inputs.StrategyAndInnovationMapF24}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapF24: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapG24"
                                value={Inputs.StrategyAndInnovationMapG24}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapG24: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapH24"
                                value={Inputs.StrategyAndInnovationMapH24}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapH24: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapI24"
                                value={Inputs.StrategyAndInnovationMapI24}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapI24: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapJ24"
                                value={Inputs.StrategyAndInnovationMapJ24}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapJ24: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapK24"
                                value={Inputs.StrategyAndInnovationMapK24}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapK24: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td><img src="../images/svg/8.png" /></td>
                        <td className="bgradiusf">Operations</td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapE25"
                                value={Inputs.StrategyAndInnovationMapE25}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapE25: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapF25"
                                value={Inputs.StrategyAndInnovationMapF25}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapF25: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapG25"
                                value={Inputs.StrategyAndInnovationMapG25}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapG25: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapH25"
                                value={Inputs.StrategyAndInnovationMapH25}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapH25: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapI25"
                                value={Inputs.StrategyAndInnovationMapI25}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapI25: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapJ25"
                                value={Inputs.StrategyAndInnovationMapJ25}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapJ25: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapK25"
                                value={Inputs.StrategyAndInnovationMapK25}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapK25: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td><img src="../images/svg/8.png" /></td>
                        <td className="bgradiusf">Technology</td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapE26"
                                value={Inputs.StrategyAndInnovationMapE26}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapE26: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapF26"
                                value={Inputs.StrategyAndInnovationMapF26}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapF26: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapG26"
                                value={Inputs.StrategyAndInnovationMapG26}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapG26: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapH26"
                                value={Inputs.StrategyAndInnovationMapH26}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapH26: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapI26"
                                value={Inputs.StrategyAndInnovationMapI26}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapI26: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapJ26"
                                value={Inputs.StrategyAndInnovationMapJ26}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapJ26: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapK26"
                                value={Inputs.StrategyAndInnovationMapK26}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapK26: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td><img src="../images/svg/8.png" /></td>
                        <td className="bgradiusf">People</td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapE27"
                                value={Inputs.StrategyAndInnovationMapE27}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapE27: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapF27"
                                value={Inputs.StrategyAndInnovationMapF27}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapF27: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapG27"
                                value={Inputs.StrategyAndInnovationMapG27}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapG27: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapH27"
                                value={Inputs.StrategyAndInnovationMapH27}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapH27: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapI27"
                                value={Inputs.StrategyAndInnovationMapI27}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapI27: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapJ27"
                                value={Inputs.StrategyAndInnovationMapJ27}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapJ27: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapK27"
                                value={Inputs.StrategyAndInnovationMapK27}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapK27: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td><img src="../images/svg/8.png" /></td>
                        <td className="bgradiusf">Capabilities to Develop</td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapE28"
                                value={Inputs.StrategyAndInnovationMapE28}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapE28: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapF28"
                                value={Inputs.StrategyAndInnovationMapF28}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapF28: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapG28"
                                value={Inputs.StrategyAndInnovationMapG28}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapG28: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapH28"
                                value={Inputs.StrategyAndInnovationMapH28}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapH28: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapI28"
                                value={Inputs.StrategyAndInnovationMapI28}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapI28: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapJ28"
                                value={Inputs.StrategyAndInnovationMapJ28}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapJ28: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapK28"
                                value={Inputs.StrategyAndInnovationMapK28}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapK28: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td><img src="../images/svg/8.png" /></td>
                        <td className="bgradiusf">Key Metrics</td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapE29"
                                value={Inputs.StrategyAndInnovationMapE29}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapE29: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapF29"
                                value={Inputs.StrategyAndInnovationMapF29}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapF29: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapG29"
                                value={Inputs.StrategyAndInnovationMapG29}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapG29: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapH29"
                                value={Inputs.StrategyAndInnovationMapH29}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapH29: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapI29"
                                value={Inputs.StrategyAndInnovationMapI29}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapI29: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapJ29"
                                value={Inputs.StrategyAndInnovationMapJ29}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapJ29: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                        <td>
                            <textarea className="from-control inputText" 
                                rows={2} 
                                cols={20}
                                id="StrategyAndInnovationMapK29"
                                value={Inputs.StrategyAndInnovationMapK29}
                                onChange={(event) => setInputs({ ...Inputs, StrategyAndInnovationMapK29: event.target.value }) }
                                onBlur={(event) => { onBlurHandler(event) }}
                                disabled={isLoading}
                            />
                        </td>
                    </tr>
                    </tbody>
                </table>
                </div>
            </div>
            </div>

    </div>
  )
}
