import React from 'react'
import  UserDropdown  from '../UserDropdown';

export default function ADLMatrixContent() {
  return (
    <div className="content-body">
    <UserDropdown />
    {/* row */}
   <div className="container-fluid">
        <div className="head-title">
            <p>Competitive Strategy</p>
            <h2>ADL Matrix</h2>
            <p>The <b>ADL Matrix</b> helps companies better understand the competitive position of their products, services or business units in the market.  To effectively use this matrix, senior leaders need to consider: </p>
            <ul className="unstyle-list">
            <li>1.  Amount of competitive influence or market share the product, service or business unit has in the market </li>
            <li>2.  Maturity of the industry in which this specific unit is offered </li>
            <li className="mb-5">The various combinations of these two factors produce a matrix that will indicate where the company’s product, service or business unit portfolio sits within its external environment.  The ADL Matrix can be used as an initial step for executive decisions concerning how to hold or improve the overall company’s market position. </li>
            </ul>
        </div>
        <div className="card">
            <div className="card-body pl-0">
            <div className="table-responsive">
                <table className="table mt-0 mb-0">
                <thead>
                    <tr>
                    <td className="width-20 d-hidden" />
                    <td colSpan={4} className="width-20 text-center-c text-white bg-color1 text-font-size-15">Industry Maturity</td>
                    </tr>
                    <tr>
                    <td className="width-20 d-hidden" />
                    <td className="width-20 text-center-c text-white bg-color1 text-font-size-15">Embryonic</td>
                    <td className="width-20 text-center-c text-white bg-color1 text-font-size-15">Growth</td>
                    <td className="width-20 text-center-c text-white bg-color1 text-font-size-15">Mature</td>
                    <td className="width-20 text-center-c text-white bg-color1 text-font-size-15">Aging</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td className="width-20 adl-matrix-color-bg text-white"> 
                        <div className="vendor-div-main">
                        <div className="img-bx-d">
                            <img src="../images/adl-icon1.png" className="img-fluid" alt="vender-icon01" />
                            <span className="text-title-vender font-size-20">Dominant</span>
                        </div>
                        </div>
                    </td>
                    <td className="width-20 padding-15 color-dg text-white"><b>Hold Position and Push for Market Share</b> - aim to maintain control by keeping other offerings/competitors out.</td>
                    <td className="width-20 padding-15 color-gs">
                        <b>Hold to Improve Position and Share</b> - aim to maintain competitiveness while pushing for additional share.
                    </td>
                    <td className="width-20 padding-15 color-gl">
                        <b>Hold Position &amp; Grow with Industry</b> - maintain market share and grow with the market.
                    </td>
                    <td className="width-20 padding-15">
                        <b>Hold Position &amp; Milk the Market</b> - aim to maximize revenues and profits while holding market share.
                    </td>
                    </tr>   
                    <tr>
                    <td className="width-20 adl-matrix-color-bg text-white"> 
                        <div className="vendor-div-main">
                        <div className="img-bx-d">
                            <img src="../images/adl-icon2.png" className="img-fluid" alt="vender-icon01" />
                            <span className="text-title-vender font-size-20">Strong</span>
                        </div>
                        </div>
                    </td>
                    <td className="width-20 padding-15 color-gs"><b>Improve Position &amp; Push for Share</b> - push to increase market share and strengthen your brand.</td>
                    <td className="width-20 padding-15 color-gl">
                        <b>Improve Position &amp; Push for Share</b> - attempt to grow market share while improving competitiveness.
                    </td>
                    <td className="width-20 padding-15">
                        <b>Hold Position &amp; Grow with Industry</b> - maintain market share and grow with the market.
                    </td>
                    <td className="width-20 padding-15">
                        <b>Hold Position or Harvest</b> - hold market position while harvesting profits.
                    </td>
                    </tr>  
                    <tr>
                    <td className="width-20 adl-matrix-color-bg text-white"> 
                        <div className="vendor-div-main">
                        <div className="img-bx-d">
                            <img src="../images/adl-icon3.png" className="img-fluid" alt="vender-icon01" />
                            <span className="text-title-vender font-size-20">Favorable</span>
                        </div>
                        </div>
                    </td>
                    <td className="width-20 padding-15 color-gl"><b>Selective Improve Position or Selective Push for Share</b> - find ways to improve share or position, making bets on most impactful choices.</td>
                    <td className="width-20 padding-15">
                        <b>Invest to Boost Position &amp; Share</b> - Identify strategic game-changing investments or acquisitions that can dramatically improve position and share.
                    </td>
                    <td className="width-20 padding-15">
                        <b>Find Niche &amp; Protect It </b> - find a niche that can allow you to protect your position and grow within it
                    </td>
                    <td className="width-20 padding-15 color-ph">
                        <b>Harvest, Phased Out Abandon</b> - managing costs, harvest profits and create a phased plan to leave the industry in the medium term.
                    </td>
                    </tr> 
                    <tr>
                    <td className="width-20 adl-matrix-color-bg text-white"> 
                        <div className="vendor-div-main">
                        <div className="img-bx-d">
                            <img src="../images/adl-icon4.png" className="img-fluid" alt="vender-icon01" />
                            <span className="text-title-vender font-size-20">Tenable</span>
                        </div>
                        </div>
                    </td>
                    <td className="width-20 padding-15"><b>Selective Push for Position</b> -  find ways to improve position, making best on most impactful choices.</td>
                    <td className="width-20 padding-15">
                        <b>Find Niche &amp; Protect It</b> - find a niche in which your business can survive and grow within it.
                    </td>
                    <td className="width-20 padding-15 color-ph">
                        <b>Find Niche or Abandon Market</b> - find a niche in the immediate to short term or prepare to withdraw from the industry.
                    </td>
                    <td className="width-20 padding-15 color-rl">
                        <b>Phased Out Abandon Market</b> - create a phased plan to withdraw from the industry in the short to medium term.
                    </td>
                    </tr> 
                    <tr>
                    <td className="width-20 adl-matrix-color-bg text-white"> 
                        <div className="vendor-div-main">
                        <div className="img-bx-d">
                            <img src="../images/adl-icon5.png" className="img-fluid" alt="vender-icon01" />
                            <span className="text-title-vender font-size-20">Weak</span>
                        </div>
                        </div>
                    </td>
                    <td className="width-20 padding-15"><b>Up or Out</b> -  find ways to make a dramatic improvement in position or leave the market.</td>
                    <td className="width-20 padding-15 color-ph">
                        <b>Turnaround or Abandon Market</b> - define a plan to improve competitive position in the short to medium term or plan for withdrawal.
                    </td>
                    <td className="width-20 padding-15 color-rl">
                        <b>Turnaround or Abandon Market </b> - dramitcally improve competitive position or plan for a quick withdrawal.
                    </td>
                    <td className="width-20 padding-15 color-dr text-white">
                        <b>Abandon Market</b> - execute plan to leave market in the shortest term possible.
                    </td>
                    </tr>
                </tbody>
                </table>
            </div>
            </div>
        </div>
    </div>
    </div>
  )
}