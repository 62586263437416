import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { getOpportunityRankingAnalysis, modifyOpportunityRankingAnalysis } from '../../state/features/OpportunityRankingAnalysisSlice';
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import sum from "../../utils/sum";
import formatPrice from '../../utils/formatPrice';

const OpportunityRankingAnalysisContentNew = () => {

    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        OpportunityRankingAnalysisD10 : 'Activity A',
        OpportunityRankingAnalysisE10 : 0,
        OpportunityRankingAnalysisF10 : 0,
        OpportunityRankingAnalysisG10 : 0,
        OpportunityRankingAnalysisH10 : 0,
        OpportunityRankingAnalysisI10 : 0,
        OpportunityRankingAnalysisJ10 : 0,
        OpportunityRankingAnalysisK10 : 0,
        OpportunityRankingAnalysisL10 : 0,
        OpportunityRankingAnalysisM10 : 0,
        OpportunityRankingAnalysisD11 : 'Activity B',
        OpportunityRankingAnalysisE11 : 0,
        OpportunityRankingAnalysisF11 : 0,
        OpportunityRankingAnalysisG11 : 0,
        OpportunityRankingAnalysisH11 : 0,
        OpportunityRankingAnalysisI11 : 0,
        OpportunityRankingAnalysisJ11 : 0,
        OpportunityRankingAnalysisK11 : 0,
        OpportunityRankingAnalysisL11 : 0,
        OpportunityRankingAnalysisM11 : 0,
        OpportunityRankingAnalysisD12 : 'Activity C',
        OpportunityRankingAnalysisE12 : 0,
        OpportunityRankingAnalysisF12 : 0,
        OpportunityRankingAnalysisG12 : 0,
        OpportunityRankingAnalysisH12 : 0,
        OpportunityRankingAnalysisI12 : 0,
        OpportunityRankingAnalysisJ12 : 0,
        OpportunityRankingAnalysisK12 : 0,
        OpportunityRankingAnalysisL12 : 0,
        OpportunityRankingAnalysisM12 : 0,
        OpportunityRankingAnalysisD13 : 'Activity D',
        OpportunityRankingAnalysisE13 : 0,
        OpportunityRankingAnalysisF13 : 0,
        OpportunityRankingAnalysisG13 : 0,
        OpportunityRankingAnalysisH13 : 0,
        OpportunityRankingAnalysisI13 : 0,
        OpportunityRankingAnalysisJ13 : 0,
        OpportunityRankingAnalysisK13 : 0,
        OpportunityRankingAnalysisL13 : 0,
        OpportunityRankingAnalysisM13 : 0,
        OpportunityRankingAnalysisD14 : 'Activity E',
        OpportunityRankingAnalysisE14 : 0,
        OpportunityRankingAnalysisF14 : 0,
        OpportunityRankingAnalysisG14 : 0,
        OpportunityRankingAnalysisH14 : 0,
        OpportunityRankingAnalysisI14 : 0,
        OpportunityRankingAnalysisJ14 : 0,
        OpportunityRankingAnalysisK14 : 0,
        OpportunityRankingAnalysisL14 : 0,
        OpportunityRankingAnalysisM14 : 0,
        OpportunityRankingAnalysisD18 : '',
        OpportunityRankingAnalysisE18 : 0,
        OpportunityRankingAnalysisF18 : 0,
        OpportunityRankingAnalysisG18 : 0,
        OpportunityRankingAnalysisH18 : 0,
        OpportunityRankingAnalysisI18 : 0,
        OpportunityRankingAnalysisJ18 : 0,
        OpportunityRankingAnalysisD19 : '',
        OpportunityRankingAnalysisE19 : 0,
        OpportunityRankingAnalysisF19 : 0,
        OpportunityRankingAnalysisG19 : 0,
        OpportunityRankingAnalysisH19 : 0,
        OpportunityRankingAnalysisI19 : 0,
        OpportunityRankingAnalysisJ19 : 0,
        OpportunityRankingAnalysisD20 : '',
        OpportunityRankingAnalysisE20 : 0,
        OpportunityRankingAnalysisF20 : 0,
        OpportunityRankingAnalysisG20 : 0,
        OpportunityRankingAnalysisH20 : 0,
        OpportunityRankingAnalysisI20 : 0,
        OpportunityRankingAnalysisJ20 : 0,
        OpportunityRankingAnalysisD21 : '',
        OpportunityRankingAnalysisE21 : 0,
        OpportunityRankingAnalysisF21 : 0,
        OpportunityRankingAnalysisG21 : 0,
        OpportunityRankingAnalysisH21 : 0,
        OpportunityRankingAnalysisI21 : 0,
        OpportunityRankingAnalysisJ21 : 0,
        OpportunityRankingAnalysisD22 : '',
        OpportunityRankingAnalysisE22 : 0,
        OpportunityRankingAnalysisF22 : 0,
        OpportunityRankingAnalysisG22 : 0,
        OpportunityRankingAnalysisH22 : 0,
        OpportunityRankingAnalysisI22 : 0,
        OpportunityRankingAnalysisJ22 : 0,
        OpportunityRankingAnalysisD26 : '',
        OpportunityRankingAnalysisE26 : 0,
        OpportunityRankingAnalysisF26 : 0,
        OpportunityRankingAnalysisG26 : 0,
        OpportunityRankingAnalysisH26 : 0,
        OpportunityRankingAnalysisI26 : 0,
        OpportunityRankingAnalysisJ26 : 0,
        OpportunityRankingAnalysisK26 : 0,
        OpportunityRankingAnalysisL26 : 0,
        OpportunityRankingAnalysisM26 : 0,
        OpportunityRankingAnalysisN26 : 0,
        OpportunityRankingAnalysisD27 : '',
        OpportunityRankingAnalysisE27 : 0,
        OpportunityRankingAnalysisF27 : 0,
        OpportunityRankingAnalysisG27 : 0,
        OpportunityRankingAnalysisH27 : 0,
        OpportunityRankingAnalysisI27 : 0,
        OpportunityRankingAnalysisJ27 : 0,
        OpportunityRankingAnalysisK27 : 0,
        OpportunityRankingAnalysisL27 : 0,
        OpportunityRankingAnalysisM27 : 0,
        OpportunityRankingAnalysisN27 : 0,
        OpportunityRankingAnalysisD28 : '',
        OpportunityRankingAnalysisE28 : 0,
        OpportunityRankingAnalysisF28 : 0,
        OpportunityRankingAnalysisG28 : 0,
        OpportunityRankingAnalysisH28 : 0,
        OpportunityRankingAnalysisI28 : 0,
        OpportunityRankingAnalysisJ28 : 0,
        OpportunityRankingAnalysisK28 : 0,
        OpportunityRankingAnalysisL28 : 0,
        OpportunityRankingAnalysisM28 : 0,
        OpportunityRankingAnalysisN28 : 0,
        OpportunityRankingAnalysisD29 : '',
        OpportunityRankingAnalysisE29 : 0,
        OpportunityRankingAnalysisF29 : 0,
        OpportunityRankingAnalysisG29 : 0,
        OpportunityRankingAnalysisH29 : 0,
        OpportunityRankingAnalysisI29 : 0,
        OpportunityRankingAnalysisJ29 : 0,
        OpportunityRankingAnalysisK29 : 0,
        OpportunityRankingAnalysisL29 : 0,
        OpportunityRankingAnalysisM29 : 0,
        OpportunityRankingAnalysisN29 : 0,
        OpportunityRankingAnalysisD30 : '',
        OpportunityRankingAnalysisE30 : 0,
        OpportunityRankingAnalysisF30 : 0,
        OpportunityRankingAnalysisG30 : 0,
        OpportunityRankingAnalysisH30 : 0,
        OpportunityRankingAnalysisI30 : 0,
        OpportunityRankingAnalysisJ30 : 0,
        OpportunityRankingAnalysisK30 : 0,
        OpportunityRankingAnalysisL30 : 0,
        OpportunityRankingAnalysisM30 : 0,
        OpportunityRankingAnalysisN30 : 0,
        OpportunityRankingAnalysisD34 : '',
        OpportunityRankingAnalysisE34 : 0,
        OpportunityRankingAnalysisF34 : 0,
        OpportunityRankingAnalysisG34 : 0,
        OpportunityRankingAnalysisH34 : 0,
        OpportunityRankingAnalysisI34 : 0,
        OpportunityRankingAnalysisJ34 : 0,
        OpportunityRankingAnalysisK34 : 0,
        OpportunityRankingAnalysisD35 : '',
        OpportunityRankingAnalysisE35 : 0,
        OpportunityRankingAnalysisF35 : 0,
        OpportunityRankingAnalysisG35 : 0,
        OpportunityRankingAnalysisH35 : 0,
        OpportunityRankingAnalysisI35 : 0,
        OpportunityRankingAnalysisJ35 : 0,
        OpportunityRankingAnalysisK35 : 0,
        OpportunityRankingAnalysisD36 : '',
        OpportunityRankingAnalysisE36 : 0,
        OpportunityRankingAnalysisF36 : 0,
        OpportunityRankingAnalysisG36 : 0,
        OpportunityRankingAnalysisH36 : 0,
        OpportunityRankingAnalysisI36 : 0,
        OpportunityRankingAnalysisJ36 : 0,
        OpportunityRankingAnalysisK36 : 0,
        OpportunityRankingAnalysisD37 : '',
        OpportunityRankingAnalysisE37 : 0,
        OpportunityRankingAnalysisF37 : 0,
        OpportunityRankingAnalysisG37 : 0,
        OpportunityRankingAnalysisH37 : 0,
        OpportunityRankingAnalysisI37 : 0,
        OpportunityRankingAnalysisJ37 : 0,
        OpportunityRankingAnalysisK37 : 0,
        OpportunityRankingAnalysisD38 : '',
        OpportunityRankingAnalysisE38 : 0,
        OpportunityRankingAnalysisF38 : 0,
        OpportunityRankingAnalysisG38 : 0,
        OpportunityRankingAnalysisH38 : 0,
        OpportunityRankingAnalysisI38 : 0,
        OpportunityRankingAnalysisJ38 : 0,
        OpportunityRankingAnalysisK38 : 0,
        OpportunityRankingAnalysisD43 : '',
        OpportunityRankingAnalysisD44 : '',
        OpportunityRankingAnalysisD45 : '',
        OpportunityRankingAnalysisD46 : '',
        OpportunityRankingAnalysisD47 : '',
        OpportunityRankingAnalysisN10 : 0,
        OpportunityRankingAnalysisN11 : 0,
        OpportunityRankingAnalysisN12 : 0,
        OpportunityRankingAnalysisN13 : 0,
        OpportunityRankingAnalysisN14 : 0,
        OpportunityRankingAnalysisK18 : 0,
        OpportunityRankingAnalysisK19 : 0,
        OpportunityRankingAnalysisK20 : 0,
        OpportunityRankingAnalysisK21 : 0,
        OpportunityRankingAnalysisK22 : 0,
        OpportunityRankingAnalysisO26 : 0,
        OpportunityRankingAnalysisO27 : 0,
        OpportunityRankingAnalysisO28 : 0,
        OpportunityRankingAnalysisO29 : 0,
        OpportunityRankingAnalysisO30 : 0,
        OpportunityRankingAnalysisL34 : 0,
        OpportunityRankingAnalysisL35 : 0,
        OpportunityRankingAnalysisL36 : 0,
        OpportunityRankingAnalysisL37 : 0,
        OpportunityRankingAnalysisL38 : 0,
        OpportunityRankingAnalysisM34 : 0,
        OpportunityRankingAnalysisM35 : 0,
        OpportunityRankingAnalysisM36 : 0,
        OpportunityRankingAnalysisM37 : 0,
        OpportunityRankingAnalysisM38 : 0,
        OpportunityRankingAnalysisE43 : 0,
        OpportunityRankingAnalysisE44 : 0,
        OpportunityRankingAnalysisE45 : 0,
        OpportunityRankingAnalysisE46 : 0,
        OpportunityRankingAnalysisE47 : 0,
        OpportunityRankingAnalysisF43 : 0,
        OpportunityRankingAnalysisF44 : 0,
        OpportunityRankingAnalysisF45 : 0,
        OpportunityRankingAnalysisF46 : 0,
        OpportunityRankingAnalysisF47 : 0,
        OpportunityRankingAnalysisG43 : 0,
        OpportunityRankingAnalysisG44 : 0,
        OpportunityRankingAnalysisG45 : 0,
        OpportunityRankingAnalysisG46 : 0,
        OpportunityRankingAnalysisG47 : 0,
        OpportunityRankingAnalysisH43 : 0,
        OpportunityRankingAnalysisH44 : 0,
        OpportunityRankingAnalysisH45 : 0,
        OpportunityRankingAnalysisH46 : 0,
        OpportunityRankingAnalysisH47 : 0,
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getOpportunityRankingAnalysis({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.revenueGrowth)
            if(response!= undefined  && response.data.revenueGrowth){
                let revenueGrowth = response.data.revenueGrowth[0];
                // console.log(revenueGrowth)
                delete revenueGrowth.id;
                delete revenueGrowth.user_id;
                delete revenueGrowth.created_at;
                delete revenueGrowth.updated_at;
                const newObj = { ...Inputs }
                for (const key in revenueGrowth) {
                    if (revenueGrowth.hasOwnProperty(key)) {
                        if(revenueGrowth[key])
                        newObj[key] = revenueGrowth[key]
                    }
                }
                newObj['OpportunityRankingAnalysisN10'] = sum(newObj['OpportunityRankingAnalysisE10'], newObj['OpportunityRankingAnalysisF10'], newObj['OpportunityRankingAnalysisG10'], newObj['OpportunityRankingAnalysisH10'], newObj['OpportunityRankingAnalysisI10'], newObj['OpportunityRankingAnalysisJ10'], newObj['OpportunityRankingAnalysisK10'], newObj['OpportunityRankingAnalysisL10'], newObj['OpportunityRankingAnalysisM10']);
                newObj['OpportunityRankingAnalysisN11'] = sum(newObj['OpportunityRankingAnalysisE11'], newObj['OpportunityRankingAnalysisF11'], newObj['OpportunityRankingAnalysisG11'], newObj['OpportunityRankingAnalysisH11'], newObj['OpportunityRankingAnalysisI11'], newObj['OpportunityRankingAnalysisJ11'], newObj['OpportunityRankingAnalysisK11'], newObj['OpportunityRankingAnalysisL11'], newObj['OpportunityRankingAnalysisM11']);
                newObj['OpportunityRankingAnalysisN12'] = sum(newObj['OpportunityRankingAnalysisE12'], newObj['OpportunityRankingAnalysisF12'], newObj['OpportunityRankingAnalysisG12'], newObj['OpportunityRankingAnalysisH12'], newObj['OpportunityRankingAnalysisI12'], newObj['OpportunityRankingAnalysisJ12'], newObj['OpportunityRankingAnalysisK12'], newObj['OpportunityRankingAnalysisL12'], newObj['OpportunityRankingAnalysisM12']);
                newObj['OpportunityRankingAnalysisN13'] = sum(newObj['OpportunityRankingAnalysisE13'], newObj['OpportunityRankingAnalysisF13'], newObj['OpportunityRankingAnalysisG13'], newObj['OpportunityRankingAnalysisH13'], newObj['OpportunityRankingAnalysisI13'], newObj['OpportunityRankingAnalysisJ13'], newObj['OpportunityRankingAnalysisK13'], newObj['OpportunityRankingAnalysisL13'], newObj['OpportunityRankingAnalysisM13']);
                newObj['OpportunityRankingAnalysisN14'] = sum(newObj['OpportunityRankingAnalysisE14'], newObj['OpportunityRankingAnalysisF14'], newObj['OpportunityRankingAnalysisG14'], newObj['OpportunityRankingAnalysisH14'], newObj['OpportunityRankingAnalysisI14'], newObj['OpportunityRankingAnalysisJ14'], newObj['OpportunityRankingAnalysisK14'], newObj['OpportunityRankingAnalysisL14'], newObj['OpportunityRankingAnalysisM14']);

                newObj['OpportunityRankingAnalysisE43'] = parseFloat(newObj['OpportunityRankingAnalysisN10']);
                newObj['OpportunityRankingAnalysisE44'] = parseFloat(newObj['OpportunityRankingAnalysisN11']);
                newObj['OpportunityRankingAnalysisE45'] = parseFloat(newObj['OpportunityRankingAnalysisN12']);
                newObj['OpportunityRankingAnalysisE46'] = parseFloat(newObj['OpportunityRankingAnalysisN13']);
                newObj['OpportunityRankingAnalysisE47'] = parseFloat(newObj['OpportunityRankingAnalysisN14']);

                newObj['OpportunityRankingAnalysisF18'] = parseFloat(newObj['OpportunityRankingAnalysisE18']) * 3; 
                newObj['OpportunityRankingAnalysisF19'] = parseFloat(newObj['OpportunityRankingAnalysisE19']) * 3; 
                newObj['OpportunityRankingAnalysisF20'] = parseFloat(newObj['OpportunityRankingAnalysisE20']) * 3; 
                newObj['OpportunityRankingAnalysisF21'] = parseFloat(newObj['OpportunityRankingAnalysisE21']) * 3; 
                newObj['OpportunityRankingAnalysisF22'] = parseFloat(newObj['OpportunityRankingAnalysisE22']) * 3; 

                newObj['OpportunityRankingAnalysisH18'] = (parseFloat(newObj['OpportunityRankingAnalysisF18']) * parseFloat(newObj['OpportunityRankingAnalysisG18']) / 100).toFixed(2); 
                newObj['OpportunityRankingAnalysisH19'] = (parseFloat(newObj['OpportunityRankingAnalysisF19']) * parseFloat(newObj['OpportunityRankingAnalysisG19']) / 100).toFixed(2); 
                newObj['OpportunityRankingAnalysisH20'] = (parseFloat(newObj['OpportunityRankingAnalysisF20']) * parseFloat(newObj['OpportunityRankingAnalysisG20']) / 100).toFixed(2); 
                newObj['OpportunityRankingAnalysisH21'] = (parseFloat(newObj['OpportunityRankingAnalysisF21']) * parseFloat(newObj['OpportunityRankingAnalysisG21']) / 100).toFixed(2); 
                newObj['OpportunityRankingAnalysisH22'] = (parseFloat(newObj['OpportunityRankingAnalysisF22']) * parseFloat(newObj['OpportunityRankingAnalysisG22']) / 100).toFixed(2);

                newObj['OpportunityRankingAnalysisJ18'] = (parseFloat(newObj['OpportunityRankingAnalysisH18']) - parseFloat(newObj['OpportunityRankingAnalysisI18'])); 
                newObj['OpportunityRankingAnalysisJ19'] = (parseFloat(newObj['OpportunityRankingAnalysisH19']) - parseFloat(newObj['OpportunityRankingAnalysisI19'])); 
                newObj['OpportunityRankingAnalysisJ20'] = (parseFloat(newObj['OpportunityRankingAnalysisH20']) - parseFloat(newObj['OpportunityRankingAnalysisI20'])); 
                newObj['OpportunityRankingAnalysisJ21'] = (parseFloat(newObj['OpportunityRankingAnalysisH21']) - parseFloat(newObj['OpportunityRankingAnalysisI21'])); 
                newObj['OpportunityRankingAnalysisJ22'] = (parseFloat(newObj['OpportunityRankingAnalysisH22']) - parseFloat(newObj['OpportunityRankingAnalysisI22']));

                newObj['OpportunityRankingAnalysisK18'] = ((parseFloat(newObj['OpportunityRankingAnalysisJ18']) / parseFloat(newObj['OpportunityRankingAnalysisI18'])) * 100).toFixed(2); 
                newObj['OpportunityRankingAnalysisK19'] = ((parseFloat(newObj['OpportunityRankingAnalysisJ19']) / parseFloat(newObj['OpportunityRankingAnalysisI19'])) * 100).toFixed(2); 
                newObj['OpportunityRankingAnalysisK20'] = ((parseFloat(newObj['OpportunityRankingAnalysisJ20']) / parseFloat(newObj['OpportunityRankingAnalysisI20'])) * 100).toFixed(2); 
                newObj['OpportunityRankingAnalysisK21'] = ((parseFloat(newObj['OpportunityRankingAnalysisJ21']) / parseFloat(newObj['OpportunityRankingAnalysisI21'])) * 100).toFixed(2); 
                newObj['OpportunityRankingAnalysisK22'] = ((parseFloat(newObj['OpportunityRankingAnalysisJ22']) / parseFloat(newObj['OpportunityRankingAnalysisI22'])) * 100).toFixed(2);

                newObj['OpportunityRankingAnalysisF43'] = parseFloat(newObj['OpportunityRankingAnalysisK18']);
                newObj['OpportunityRankingAnalysisF44'] = parseFloat(newObj['OpportunityRankingAnalysisK19']);
                newObj['OpportunityRankingAnalysisF45'] = parseFloat(newObj['OpportunityRankingAnalysisK20']);
                newObj['OpportunityRankingAnalysisF46'] = parseFloat(newObj['OpportunityRankingAnalysisK21']);
                newObj['OpportunityRankingAnalysisF47'] = parseFloat(newObj['OpportunityRankingAnalysisK22']);

                newObj['OpportunityRankingAnalysisO26'] = sum(newObj['OpportunityRankingAnalysisE26'], newObj['OpportunityRankingAnalysisF26'], newObj['OpportunityRankingAnalysisG26'], newObj['OpportunityRankingAnalysisH26'], newObj['OpportunityRankingAnalysisI26'], newObj['OpportunityRankingAnalysisJ26'], newObj['OpportunityRankingAnalysisK26'], newObj['OpportunityRankingAnalysisL26'], newObj['OpportunityRankingAnalysisM26'], newObj['OpportunityRankingAnalysisN26'])
                newObj['OpportunityRankingAnalysisO27'] = sum(newObj['OpportunityRankingAnalysisE27'], newObj['OpportunityRankingAnalysisF27'], newObj['OpportunityRankingAnalysisG27'], newObj['OpportunityRankingAnalysisH27'], newObj['OpportunityRankingAnalysisI27'], newObj['OpportunityRankingAnalysisJ27'], newObj['OpportunityRankingAnalysisK27'], newObj['OpportunityRankingAnalysisL27'], newObj['OpportunityRankingAnalysisM27'], newObj['OpportunityRankingAnalysisN27'])
                newObj['OpportunityRankingAnalysisO28'] = sum(newObj['OpportunityRankingAnalysisE28'], newObj['OpportunityRankingAnalysisF28'], newObj['OpportunityRankingAnalysisG28'], newObj['OpportunityRankingAnalysisH28'], newObj['OpportunityRankingAnalysisI28'], newObj['OpportunityRankingAnalysisJ28'], newObj['OpportunityRankingAnalysisK28'], newObj['OpportunityRankingAnalysisL28'], newObj['OpportunityRankingAnalysisM28'], newObj['OpportunityRankingAnalysisN28'])
                newObj['OpportunityRankingAnalysisO29'] = sum(newObj['OpportunityRankingAnalysisE29'], newObj['OpportunityRankingAnalysisF29'], newObj['OpportunityRankingAnalysisG29'], newObj['OpportunityRankingAnalysisH29'], newObj['OpportunityRankingAnalysisI29'], newObj['OpportunityRankingAnalysisJ29'], newObj['OpportunityRankingAnalysisK29'], newObj['OpportunityRankingAnalysisL29'], newObj['OpportunityRankingAnalysisM29'], newObj['OpportunityRankingAnalysisN29'])
                newObj['OpportunityRankingAnalysisO30'] = sum(newObj['OpportunityRankingAnalysisE30'], newObj['OpportunityRankingAnalysisF30'], newObj['OpportunityRankingAnalysisG30'], newObj['OpportunityRankingAnalysisH30'], newObj['OpportunityRankingAnalysisI30'], newObj['OpportunityRankingAnalysisJ30'], newObj['OpportunityRankingAnalysisK30'], newObj['OpportunityRankingAnalysisL30'], newObj['OpportunityRankingAnalysisM30'], newObj['OpportunityRankingAnalysisN30'])

                newObj['OpportunityRankingAnalysisG43'] = parseFloat(newObj['OpportunityRankingAnalysisO26']);
                newObj['OpportunityRankingAnalysisG44'] = parseFloat(newObj['OpportunityRankingAnalysisO27']);
                newObj['OpportunityRankingAnalysisG45'] = parseFloat(newObj['OpportunityRankingAnalysisO28']);
                newObj['OpportunityRankingAnalysisG46'] = parseFloat(newObj['OpportunityRankingAnalysisO29']);
                newObj['OpportunityRankingAnalysisG47'] = parseFloat(newObj['OpportunityRankingAnalysisO30']);

                newObj['OpportunityRankingAnalysisM34'] = sum(newObj['OpportunityRankingAnalysisE34'], newObj['OpportunityRankingAnalysisF34'], newObj['OpportunityRankingAnalysisG34'], newObj['OpportunityRankingAnalysisH34'], newObj['OpportunityRankingAnalysisI34'], newObj['OpportunityRankingAnalysisJ34'], newObj['OpportunityRankingAnalysisK34'], newObj['OpportunityRankingAnalysisL34'])
                newObj['OpportunityRankingAnalysisM35'] = sum(newObj['OpportunityRankingAnalysisE35'], newObj['OpportunityRankingAnalysisF35'], newObj['OpportunityRankingAnalysisG35'], newObj['OpportunityRankingAnalysisH35'], newObj['OpportunityRankingAnalysisI35'], newObj['OpportunityRankingAnalysisJ35'], newObj['OpportunityRankingAnalysisK35'], newObj['OpportunityRankingAnalysisL35'])
                newObj['OpportunityRankingAnalysisM36'] = sum(newObj['OpportunityRankingAnalysisE36'], newObj['OpportunityRankingAnalysisF36'], newObj['OpportunityRankingAnalysisG36'], newObj['OpportunityRankingAnalysisH36'], newObj['OpportunityRankingAnalysisI36'], newObj['OpportunityRankingAnalysisJ36'], newObj['OpportunityRankingAnalysisK36'], newObj['OpportunityRankingAnalysisL36'])
                newObj['OpportunityRankingAnalysisM37'] = sum(newObj['OpportunityRankingAnalysisE37'], newObj['OpportunityRankingAnalysisF37'], newObj['OpportunityRankingAnalysisG37'], newObj['OpportunityRankingAnalysisH37'], newObj['OpportunityRankingAnalysisI37'], newObj['OpportunityRankingAnalysisJ37'], newObj['OpportunityRankingAnalysisK37'], newObj['OpportunityRankingAnalysisL37'])
                newObj['OpportunityRankingAnalysisM38'] = sum(newObj['OpportunityRankingAnalysisE38'], newObj['OpportunityRankingAnalysisF38'], newObj['OpportunityRankingAnalysisG38'], newObj['OpportunityRankingAnalysisH38'], newObj['OpportunityRankingAnalysisI38'], newObj['OpportunityRankingAnalysisJ38'], newObj['OpportunityRankingAnalysisK38'], newObj['OpportunityRankingAnalysisL38'])

                newObj['OpportunityRankingAnalysisH43'] = parseFloat(newObj['OpportunityRankingAnalysisM34']);
                newObj['OpportunityRankingAnalysisH44'] = parseFloat(newObj['OpportunityRankingAnalysisM35']);
                newObj['OpportunityRankingAnalysisH45'] = parseFloat(newObj['OpportunityRankingAnalysisM36']);
                newObj['OpportunityRankingAnalysisH46'] = parseFloat(newObj['OpportunityRankingAnalysisM37']);
                newObj['OpportunityRankingAnalysisH47'] = parseFloat(newObj['OpportunityRankingAnalysisM38']);
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            let targetValue = event.target.value.replace(/,/g, '');
            if(Cache[event.target.id] !== targetValue){
                targetValue = targetValue ? targetValue : 0
                dispatch(modifyOpportunityRankingAnalysis({ user_id, [event.target.id] : targetValue}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        //window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : targetValue});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

    useEffect(() => {
        let OpportunityRankingAnalysisN10 = sum(Inputs.OpportunityRankingAnalysisE10, Inputs.OpportunityRankingAnalysisF10, Inputs.OpportunityRankingAnalysisG10, Inputs.OpportunityRankingAnalysisH10, Inputs.OpportunityRankingAnalysisI10, Inputs.OpportunityRankingAnalysisJ10, Inputs.OpportunityRankingAnalysisK10, Inputs.OpportunityRankingAnalysisL10, Inputs.OpportunityRankingAnalysisM10);
        let OpportunityRankingAnalysisN11 = sum(Inputs.OpportunityRankingAnalysisE11, Inputs.OpportunityRankingAnalysisF11, Inputs.OpportunityRankingAnalysisG11, Inputs.OpportunityRankingAnalysisH11, Inputs.OpportunityRankingAnalysisI11, Inputs.OpportunityRankingAnalysisJ11, Inputs.OpportunityRankingAnalysisK11, Inputs.OpportunityRankingAnalysisL11, Inputs.OpportunityRankingAnalysisM11);
        let OpportunityRankingAnalysisN12 = sum(Inputs.OpportunityRankingAnalysisE12, Inputs.OpportunityRankingAnalysisF12, Inputs.OpportunityRankingAnalysisG12, Inputs.OpportunityRankingAnalysisH12, Inputs.OpportunityRankingAnalysisI12, Inputs.OpportunityRankingAnalysisJ12, Inputs.OpportunityRankingAnalysisK12, Inputs.OpportunityRankingAnalysisL12, Inputs.OpportunityRankingAnalysisM12);
        let OpportunityRankingAnalysisN13 = sum(Inputs.OpportunityRankingAnalysisE13, Inputs.OpportunityRankingAnalysisF13, Inputs.OpportunityRankingAnalysisG13, Inputs.OpportunityRankingAnalysisH13, Inputs.OpportunityRankingAnalysisI13, Inputs.OpportunityRankingAnalysisJ13, Inputs.OpportunityRankingAnalysisK13, Inputs.OpportunityRankingAnalysisL13, Inputs.OpportunityRankingAnalysisM13);
        let OpportunityRankingAnalysisN14 = sum(Inputs.OpportunityRankingAnalysisE14, Inputs.OpportunityRankingAnalysisF14, Inputs.OpportunityRankingAnalysisG14, Inputs.OpportunityRankingAnalysisH14, Inputs.OpportunityRankingAnalysisI14, Inputs.OpportunityRankingAnalysisJ14, Inputs.OpportunityRankingAnalysisK14, Inputs.OpportunityRankingAnalysisL14, Inputs.OpportunityRankingAnalysisM14)

        let OpportunityRankingAnalysisE43 = parseFloat(OpportunityRankingAnalysisN10);
        let OpportunityRankingAnalysisE44 = parseFloat(OpportunityRankingAnalysisN11);
        let OpportunityRankingAnalysisE45 = parseFloat(OpportunityRankingAnalysisN12);
        let OpportunityRankingAnalysisE46 = parseFloat(OpportunityRankingAnalysisN13);
        let OpportunityRankingAnalysisE47 = parseFloat(OpportunityRankingAnalysisN14);

        setInputs({ ...Inputs, OpportunityRankingAnalysisN10, OpportunityRankingAnalysisN11, OpportunityRankingAnalysisN12, OpportunityRankingAnalysisN13, OpportunityRankingAnalysisN14, OpportunityRankingAnalysisE43, OpportunityRankingAnalysisE44, OpportunityRankingAnalysisE45, OpportunityRankingAnalysisE46, OpportunityRankingAnalysisE47 })
    }, [Inputs.OpportunityRankingAnalysisE10, Inputs.OpportunityRankingAnalysisF10, Inputs.OpportunityRankingAnalysisG10, Inputs.OpportunityRankingAnalysisH10, Inputs.OpportunityRankingAnalysisI10, Inputs.OpportunityRankingAnalysisJ10, Inputs.OpportunityRankingAnalysisK10, Inputs.OpportunityRankingAnalysisL10, Inputs.OpportunityRankingAnalysisM10, Inputs.OpportunityRankingAnalysisE11, Inputs.OpportunityRankingAnalysisF11, Inputs.OpportunityRankingAnalysisG11, Inputs.OpportunityRankingAnalysisH11, Inputs.OpportunityRankingAnalysisI11, Inputs.OpportunityRankingAnalysisJ11, Inputs.OpportunityRankingAnalysisK11, Inputs.OpportunityRankingAnalysisL11, Inputs.OpportunityRankingAnalysisM11, Inputs.OpportunityRankingAnalysisE12, Inputs.OpportunityRankingAnalysisF12, Inputs.OpportunityRankingAnalysisG12, Inputs.OpportunityRankingAnalysisH12, Inputs.OpportunityRankingAnalysisI12, Inputs.OpportunityRankingAnalysisJ12, Inputs.OpportunityRankingAnalysisK12, Inputs.OpportunityRankingAnalysisL12, Inputs.OpportunityRankingAnalysisM12, Inputs.OpportunityRankingAnalysisE13, Inputs.OpportunityRankingAnalysisF13, Inputs.OpportunityRankingAnalysisG13, Inputs.OpportunityRankingAnalysisH13, Inputs.OpportunityRankingAnalysisI13, Inputs.OpportunityRankingAnalysisJ13, Inputs.OpportunityRankingAnalysisK13, Inputs.OpportunityRankingAnalysisL13, Inputs.OpportunityRankingAnalysisM13, Inputs.OpportunityRankingAnalysisE14, Inputs.OpportunityRankingAnalysisF14, Inputs.OpportunityRankingAnalysisG14, Inputs.OpportunityRankingAnalysisH14, Inputs.OpportunityRankingAnalysisI14, Inputs.OpportunityRankingAnalysisJ14, Inputs.OpportunityRankingAnalysisK14, Inputs.OpportunityRankingAnalysisL14, Inputs.OpportunityRankingAnalysisM14]);

    useEffect(() => {
        let OpportunityRankingAnalysisF18 = Number(Inputs.OpportunityRankingAnalysisE18) * 3;
        let OpportunityRankingAnalysisH18 = parseFloat(OpportunityRankingAnalysisF18) * parseFloat(Number(Inputs.OpportunityRankingAnalysisG18)/100);
        let OpportunityRankingAnalysisJ18 = OpportunityRankingAnalysisH18 - Number(Inputs.OpportunityRankingAnalysisI18);
        let OpportunityRankingAnalysisK18 = (OpportunityRankingAnalysisJ18 / Number(Inputs.OpportunityRankingAnalysisI18)) * 100;

        let OpportunityRankingAnalysisF19 = Number(Inputs.OpportunityRankingAnalysisE19) * 3;
        let OpportunityRankingAnalysisH19 = parseFloat(OpportunityRankingAnalysisF19) * parseFloat(Number(Inputs.OpportunityRankingAnalysisG19)/100);
        let OpportunityRankingAnalysisJ19 = OpportunityRankingAnalysisH19 - Number(Inputs.OpportunityRankingAnalysisI19);
        let OpportunityRankingAnalysisK19 = (OpportunityRankingAnalysisJ19 / Number(Inputs.OpportunityRankingAnalysisI19)) * 100;

        let OpportunityRankingAnalysisF20 = Number(Inputs.OpportunityRankingAnalysisE20) * 3;
        let OpportunityRankingAnalysisH20 = parseFloat(OpportunityRankingAnalysisF20) * parseFloat(Number(Inputs.OpportunityRankingAnalysisG20)/100);
        let OpportunityRankingAnalysisJ20 = OpportunityRankingAnalysisH20 - Number(Inputs.OpportunityRankingAnalysisI20);
        let OpportunityRankingAnalysisK20 = (OpportunityRankingAnalysisJ20 / Number(Inputs.OpportunityRankingAnalysisI20)) * 100;

        let OpportunityRankingAnalysisF21 = Number(Inputs.OpportunityRankingAnalysisE21) * 3;
        let OpportunityRankingAnalysisH21 = parseFloat(OpportunityRankingAnalysisF21) * parseFloat(Number(Inputs.OpportunityRankingAnalysisG21)/100);
        let OpportunityRankingAnalysisJ21 = OpportunityRankingAnalysisH21 - Number(Inputs.OpportunityRankingAnalysisI21);
        let OpportunityRankingAnalysisK21 = (OpportunityRankingAnalysisJ21 / Number(Inputs.OpportunityRankingAnalysisI21)) * 100;

        let OpportunityRankingAnalysisF22 = Number(Inputs.OpportunityRankingAnalysisE22) * 3;
        let OpportunityRankingAnalysisH22 = parseFloat(OpportunityRankingAnalysisF22) * parseFloat(Number(Inputs.OpportunityRankingAnalysisG22)/100);
        let OpportunityRankingAnalysisJ22 = OpportunityRankingAnalysisH22 - Number(Inputs.OpportunityRankingAnalysisI22);
        let OpportunityRankingAnalysisK22 = (OpportunityRankingAnalysisJ22 / Number(Inputs.OpportunityRankingAnalysisI22)) * 100;

        let OpportunityRankingAnalysisF43 = parseFloat(OpportunityRankingAnalysisK18).toFixed(1);
        let OpportunityRankingAnalysisF44 = parseFloat(OpportunityRankingAnalysisK19).toFixed(1);
        let OpportunityRankingAnalysisF45 = parseFloat(OpportunityRankingAnalysisK20).toFixed(1);
        let OpportunityRankingAnalysisF46 = parseFloat(OpportunityRankingAnalysisK21).toFixed(1);
        let OpportunityRankingAnalysisF47 = parseFloat(OpportunityRankingAnalysisK22).toFixed(1);

        setInputs({ ...Inputs, OpportunityRankingAnalysisF18, OpportunityRankingAnalysisH18, OpportunityRankingAnalysisJ18, OpportunityRankingAnalysisK18, OpportunityRankingAnalysisF19, OpportunityRankingAnalysisH19, OpportunityRankingAnalysisJ19, OpportunityRankingAnalysisK19, OpportunityRankingAnalysisF20, OpportunityRankingAnalysisH20, OpportunityRankingAnalysisJ20, OpportunityRankingAnalysisK20, OpportunityRankingAnalysisF21, OpportunityRankingAnalysisH21, OpportunityRankingAnalysisJ21, OpportunityRankingAnalysisK21, OpportunityRankingAnalysisF22, OpportunityRankingAnalysisH22, OpportunityRankingAnalysisJ22, OpportunityRankingAnalysisK22, OpportunityRankingAnalysisF43, OpportunityRankingAnalysisF44, OpportunityRankingAnalysisF45, OpportunityRankingAnalysisF46, OpportunityRankingAnalysisF47 });
    }, [Inputs.OpportunityRankingAnalysisE18, Inputs.OpportunityRankingAnalysisG18, Inputs.OpportunityRankingAnalysisI18, Inputs.OpportunityRankingAnalysisE19, Inputs.OpportunityRankingAnalysisG19, Inputs.OpportunityRankingAnalysisI19, Inputs.OpportunityRankingAnalysisE20, Inputs.OpportunityRankingAnalysisG20, Inputs.OpportunityRankingAnalysisI20, Inputs.OpportunityRankingAnalysisE21, Inputs.OpportunityRankingAnalysisG21, Inputs.OpportunityRankingAnalysisI21, Inputs.OpportunityRankingAnalysisE22, Inputs.OpportunityRankingAnalysisG22, Inputs.OpportunityRankingAnalysisI22]);

    useEffect(() => {
        let OpportunityRankingAnalysisO26 = sum(Number(Inputs.OpportunityRankingAnalysisE26), Number(Inputs.OpportunityRankingAnalysisF26), Number(Inputs.OpportunityRankingAnalysisG26), Number(Inputs.OpportunityRankingAnalysisH26), Number(Inputs.OpportunityRankingAnalysisI26), Number(Inputs.OpportunityRankingAnalysisJ26), Number(Inputs.OpportunityRankingAnalysisK26), Number(Inputs.OpportunityRankingAnalysisL26), Number(Inputs.OpportunityRankingAnalysisM26));

        let OpportunityRankingAnalysisO27 = sum(Number(Inputs.OpportunityRankingAnalysisE27), Number(Inputs.OpportunityRankingAnalysisF27), Number(Inputs.OpportunityRankingAnalysisG27), Number(Inputs.OpportunityRankingAnalysisH27), Number(Inputs.OpportunityRankingAnalysisI27), Number(Inputs.OpportunityRankingAnalysisJ27), Number(Inputs.OpportunityRankingAnalysisK27), Number(Inputs.OpportunityRankingAnalysisL27), Number(Inputs.OpportunityRankingAnalysisM27));

        let OpportunityRankingAnalysisO28 = sum(Number(Inputs.OpportunityRankingAnalysisE28), Number(Inputs.OpportunityRankingAnalysisF28), Number(Inputs.OpportunityRankingAnalysisG28), Number(Inputs.OpportunityRankingAnalysisH28), Number(Inputs.OpportunityRankingAnalysisI28), Number(Inputs.OpportunityRankingAnalysisJ28), Number(Inputs.OpportunityRankingAnalysisK28), Number(Inputs.OpportunityRankingAnalysisL28), Number(Inputs.OpportunityRankingAnalysisM28));

        let OpportunityRankingAnalysisO29 = sum(Number(Inputs.OpportunityRankingAnalysisE29), Number(Inputs.OpportunityRankingAnalysisF29), Number(Inputs.OpportunityRankingAnalysisG29), Number(Inputs.OpportunityRankingAnalysisH29), Number(Inputs.OpportunityRankingAnalysisI29), Number(Inputs.OpportunityRankingAnalysisJ29), Number(Inputs.OpportunityRankingAnalysisK29), Number(Inputs.OpportunityRankingAnalysisL29), Number(Inputs.OpportunityRankingAnalysisM29));

        let OpportunityRankingAnalysisO30 = sum(Number(Inputs.OpportunityRankingAnalysisE30), Number(Inputs.OpportunityRankingAnalysisF30), Number(Inputs.OpportunityRankingAnalysisG30), Number(Inputs.OpportunityRankingAnalysisH30), Number(Inputs.OpportunityRankingAnalysisI30), Number(Inputs.OpportunityRankingAnalysisJ30), Number(Inputs.OpportunityRankingAnalysisK30), Number(Inputs.OpportunityRankingAnalysisL30), Number(Inputs.OpportunityRankingAnalysisM30));

        let OpportunityRankingAnalysisG43 = parseFloat(OpportunityRankingAnalysisO26);
        let OpportunityRankingAnalysisG44 = parseFloat(OpportunityRankingAnalysisO27);
        let OpportunityRankingAnalysisG45 = parseFloat(OpportunityRankingAnalysisO28);
        let OpportunityRankingAnalysisG46 = parseFloat(OpportunityRankingAnalysisO29);
        let OpportunityRankingAnalysisG47 = parseFloat(OpportunityRankingAnalysisO30);

        setInputs({ ...Inputs, OpportunityRankingAnalysisO26, OpportunityRankingAnalysisO27, OpportunityRankingAnalysisO28, OpportunityRankingAnalysisO29, OpportunityRankingAnalysisO30, OpportunityRankingAnalysisG43, OpportunityRankingAnalysisG44, OpportunityRankingAnalysisG45, OpportunityRankingAnalysisG46, OpportunityRankingAnalysisG47 });

    }, [Inputs.OpportunityRankingAnalysisE26, Inputs.OpportunityRankingAnalysisF26, Inputs.OpportunityRankingAnalysisG26, Inputs.OpportunityRankingAnalysisH26, Inputs.OpportunityRankingAnalysisI26, Inputs.OpportunityRankingAnalysisJ26, Inputs.OpportunityRankingAnalysisK26, Inputs.OpportunityRankingAnalysisL26, Inputs.OpportunityRankingAnalysisM26, Inputs.OpportunityRankingAnalysisE27, Inputs.OpportunityRankingAnalysisF27, Inputs.OpportunityRankingAnalysisG27, Inputs.OpportunityRankingAnalysisH27, Inputs.OpportunityRankingAnalysisI27, Inputs.OpportunityRankingAnalysisJ27, Inputs.OpportunityRankingAnalysisK27, Inputs.OpportunityRankingAnalysisL27, Inputs.OpportunityRankingAnalysisM27, Inputs.OpportunityRankingAnalysisE28, Inputs.OpportunityRankingAnalysisF28, Inputs.OpportunityRankingAnalysisG28, Inputs.OpportunityRankingAnalysisH28, Inputs.OpportunityRankingAnalysisI28, Inputs.OpportunityRankingAnalysisJ28, Inputs.OpportunityRankingAnalysisK28, Inputs.OpportunityRankingAnalysisL28, Inputs.OpportunityRankingAnalysisM28, Inputs.OpportunityRankingAnalysisE29, Inputs.OpportunityRankingAnalysisF29, Inputs.OpportunityRankingAnalysisG29, Inputs.OpportunityRankingAnalysisH29, Inputs.OpportunityRankingAnalysisI29, Inputs.OpportunityRankingAnalysisJ29, Inputs.OpportunityRankingAnalysisK29, Inputs.OpportunityRankingAnalysisL29, Inputs.OpportunityRankingAnalysisM29, Inputs.OpportunityRankingAnalysisE30, Inputs.OpportunityRankingAnalysisF30, Inputs.OpportunityRankingAnalysisG30, Inputs.OpportunityRankingAnalysisH30, Inputs.OpportunityRankingAnalysisI30, Inputs.OpportunityRankingAnalysisJ30, Inputs.OpportunityRankingAnalysisK30, Inputs.OpportunityRankingAnalysisL30, Inputs.OpportunityRankingAnalysisM30 ]);

    useEffect(() => {
        let OpportunityRankingAnalysisM34 = sum(Number(Inputs.OpportunityRankingAnalysisE34), Number(Inputs.OpportunityRankingAnalysisF34), Number(Inputs.OpportunityRankingAnalysisG34), Number(Inputs.OpportunityRankingAnalysisH34), Number(Inputs.OpportunityRankingAnalysisI34), Number(Inputs.OpportunityRankingAnalysisJ34), Number(Inputs.OpportunityRankingAnalysisK34), Number(Inputs.OpportunityRankingAnalysisL34));

        let OpportunityRankingAnalysisM35 = sum(Number(Inputs.OpportunityRankingAnalysisE35), Number(Inputs.OpportunityRankingAnalysisF35), Number(Inputs.OpportunityRankingAnalysisG35), Number(Inputs.OpportunityRankingAnalysisH35), Number(Inputs.OpportunityRankingAnalysisI35), Number(Inputs.OpportunityRankingAnalysisJ35), Number(Inputs.OpportunityRankingAnalysisK35), Number(Inputs.OpportunityRankingAnalysisL35));

        let OpportunityRankingAnalysisM36 = sum(Number(Inputs.OpportunityRankingAnalysisE36), Number(Inputs.OpportunityRankingAnalysisF36), Number(Inputs.OpportunityRankingAnalysisG36), Number(Inputs.OpportunityRankingAnalysisH36), Number(Inputs.OpportunityRankingAnalysisI36), Number(Inputs.OpportunityRankingAnalysisJ36), Number(Inputs.OpportunityRankingAnalysisK36), Number(Inputs.OpportunityRankingAnalysisL36));

        let OpportunityRankingAnalysisM37 = sum(Number(Inputs.OpportunityRankingAnalysisE37), Number(Inputs.OpportunityRankingAnalysisF37), Number(Inputs.OpportunityRankingAnalysisG37), Number(Inputs.OpportunityRankingAnalysisH37), Number(Inputs.OpportunityRankingAnalysisI37), Number(Inputs.OpportunityRankingAnalysisJ37), Number(Inputs.OpportunityRankingAnalysisK37), Number(Inputs.OpportunityRankingAnalysisL37));

        let OpportunityRankingAnalysisM38 = sum(Number(Inputs.OpportunityRankingAnalysisE38), Number(Inputs.OpportunityRankingAnalysisF38), Number(Inputs.OpportunityRankingAnalysisG38), Number(Inputs.OpportunityRankingAnalysisH38), Number(Inputs.OpportunityRankingAnalysisI38), Number(Inputs.OpportunityRankingAnalysisJ38), Number(Inputs.OpportunityRankingAnalysisK38), Number(Inputs.OpportunityRankingAnalysisL38));

        let OpportunityRankingAnalysisH43 = parseFloat(OpportunityRankingAnalysisM34);
        let OpportunityRankingAnalysisH44 = parseFloat(OpportunityRankingAnalysisM35);
        let OpportunityRankingAnalysisH45 = parseFloat(OpportunityRankingAnalysisM36);
        let OpportunityRankingAnalysisH46 = parseFloat(OpportunityRankingAnalysisM37);
        let OpportunityRankingAnalysisH47 = parseFloat(OpportunityRankingAnalysisM38);

        setInputs({ ...Inputs, OpportunityRankingAnalysisM34, OpportunityRankingAnalysisM35, OpportunityRankingAnalysisM36, OpportunityRankingAnalysisM37, OpportunityRankingAnalysisM38, OpportunityRankingAnalysisH43, OpportunityRankingAnalysisH44, OpportunityRankingAnalysisH45, OpportunityRankingAnalysisH46, OpportunityRankingAnalysisH47 });

    }, [Inputs.OpportunityRankingAnalysisE34, Inputs.OpportunityRankingAnalysisF34, Inputs.OpportunityRankingAnalysisG34, Inputs.OpportunityRankingAnalysisH34, Inputs.OpportunityRankingAnalysisI34, Inputs.OpportunityRankingAnalysisJ34, Inputs.OpportunityRankingAnalysisK34, Inputs.OpportunityRankingAnalysisL34, Inputs.OpportunityRankingAnalysisE35, Inputs.OpportunityRankingAnalysisF35, Inputs.OpportunityRankingAnalysisG35, Inputs.OpportunityRankingAnalysisH35, Inputs.OpportunityRankingAnalysisI35, Inputs.OpportunityRankingAnalysisJ35, Inputs.OpportunityRankingAnalysisK35, Inputs.OpportunityRankingAnalysisL35, Inputs.OpportunityRankingAnalysisE36, Inputs.OpportunityRankingAnalysisF36, Inputs.OpportunityRankingAnalysisG36, Inputs.OpportunityRankingAnalysisH36, Inputs.OpportunityRankingAnalysisI36, Inputs.OpportunityRankingAnalysisJ36, Inputs.OpportunityRankingAnalysisK36, Inputs.OpportunityRankingAnalysisL36, Inputs.OpportunityRankingAnalysisE37, Inputs.OpportunityRankingAnalysisF37, Inputs.OpportunityRankingAnalysisG37, Inputs.OpportunityRankingAnalysisH37, Inputs.OpportunityRankingAnalysisI37, Inputs.OpportunityRankingAnalysisJ37, Inputs.OpportunityRankingAnalysisK37, Inputs.OpportunityRankingAnalysisL37, Inputs.OpportunityRankingAnalysisE38, Inputs.OpportunityRankingAnalysisF38, Inputs.OpportunityRankingAnalysisG38, Inputs.OpportunityRankingAnalysisH38, Inputs.OpportunityRankingAnalysisI38, Inputs.OpportunityRankingAnalysisJ38, Inputs.OpportunityRankingAnalysisK38, Inputs.OpportunityRankingAnalysisL38]);

    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
            {/* row */}
            <div className="container-fluid">
                <div className="head-title">
                    <h3 className='preheading'>Prescribe - Opportunity Analysis</h3>
                    <h1 className='mainheading mb-1'>Opportunity Ranking Analysis</h1>
                </div>
                <Spinner />
                <div className="opportunity-page-table">
                    <div className="opportunity-page-table-inner opportunity-abl">
                        <div className="table-responsive mb-3">
                            {/* annual customer table 01 */}
                            <table className="table" style={{width: 1900}}>
                                <thead>
                                    <tr>
                                        <td rowSpan={2} colSpan={2} className='black-bg text-white text-center font18px'>Tactic or Activity</td>
                                        <td colSpan={9} className='bg-color-blue-gray text-white text-center font16px'>Growth Driver Impact</td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px'>
                                            Lead Generation
                                        </td>
                                        <td className='black-bg text-white text-center font11px'>
                                            Conversion
                                        </td>
                                        <td className='black-bg text-white text-center font11px'>
                                            Referrals
                                        </td>
                                        <td className='black-bg text-white text-center font11px'>
                                            Purchase Frequency
                                        </td>
                                        <td className='black-bg text-white text-center font11px'>
                                            Buying Lifetime
                                        </td>
                                        <td className='black-bg text-white text-center font11px'>
                                            Reduce Attrition
                                        </td>
                                        <td className='black-bg text-white text-center font11px'>
                                            Reactivation
                                        </td>
                                        <td className='black-bg text-white text-center font11px'>
                                            Transaction Value
                                        </td>
                                        <td className='black-bg text-white text-center font11px'>
                                            Profit Margin
                                        </td>
                                        <td className='black-bg text-white text-center font11px'>
                                            Score
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white font11px' style={{width: 50}}>1</td>
                                        <td className='bg-color-01 padding-0' style={{width: 350}}>
                                            <div className="input-fiel-cn bg-color-01">
                                                <textarea className="field-ipt bg-color-01 font11px color-dark w-100"
                                                id="OpportunityRankingAnalysisD10"
                                                value={Inputs.OpportunityRankingAnalysisD10}
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisD10: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="Enter an activity" />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center"
                                                id="OpportunityRankingAnalysisE10" 
                                                value={Inputs.OpportunityRankingAnalysisE10} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisE10: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1"  
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center"
                                                id="OpportunityRankingAnalysisF10" 
                                                value={Inputs.OpportunityRankingAnalysisF10} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisF10: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisG10" 
                                                value={Inputs.OpportunityRankingAnalysisG10} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisG10: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisH10" 
                                                value={Inputs.OpportunityRankingAnalysisH10} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisH10: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisI10" 
                                                value={Inputs.OpportunityRankingAnalysisI10} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisI10: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisJ10" 
                                                value={Inputs.OpportunityRankingAnalysisJ10} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisJ10: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisK10" 
                                                value={Inputs.OpportunityRankingAnalysisK10} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisK10: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisL10" 
                                                value={Inputs.OpportunityRankingAnalysisL10} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisL10: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisM10" 
                                                value={Inputs.OpportunityRankingAnalysisM10} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisM10: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center font11px' style={{width: 150}}>
                                            <div className="scrore-col">
                                                {Inputs.OpportunityRankingAnalysisN10}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white font11px'>2</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01">
                                                <textarea className="field-ipt bg-color-01 font11px color-dark w-100"
                                                id="OpportunityRankingAnalysisD11"
                                                value={Inputs.OpportunityRankingAnalysisD11}
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisD11: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="Activity B" />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisE11" 
                                                value={Inputs.OpportunityRankingAnalysisE11} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisE11: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisF11" 
                                                value={Inputs.OpportunityRankingAnalysisF11} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisF11: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisG11" 
                                                value={Inputs.OpportunityRankingAnalysisG11} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisG11: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisH11" 
                                                value={Inputs.OpportunityRankingAnalysisH11} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisH11: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisI11" 
                                                value={Inputs.OpportunityRankingAnalysisI11} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisI11: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center"
                                                id="OpportunityRankingAnalysisJ11" 
                                                value={Inputs.OpportunityRankingAnalysisJ11} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisJ11: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisK11" 
                                                value={Inputs.OpportunityRankingAnalysisK11} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisK11: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisL11" 
                                                value={Inputs.OpportunityRankingAnalysisL11} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisL11: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisM11" 
                                                value={Inputs.OpportunityRankingAnalysisM11} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisM11: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center font11px'>
                                            <div className="scrore-col">
                                                {Inputs.OpportunityRankingAnalysisN11}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white font11px'>3</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01">
                                                <textarea className="field-ipt bg-color-01 font11px color-dark w-100"
                                                id="OpportunityRankingAnalysisD12"
                                                value={Inputs.OpportunityRankingAnalysisD12}
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisD12: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="Activity C" />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisE12" 
                                                value={Inputs.OpportunityRankingAnalysisE12} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisE12: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisF12" 
                                                value={Inputs.OpportunityRankingAnalysisF12} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisF12: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisG12" 
                                                value={Inputs.OpportunityRankingAnalysisG12} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisG12: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisH12" 
                                                value={Inputs.OpportunityRankingAnalysisH12} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisH12: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisI12" 
                                                value={Inputs.OpportunityRankingAnalysisI12} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisI12: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisJ12" 
                                                value={Inputs.OpportunityRankingAnalysisJ12} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisJ12: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisK12" 
                                                value={Inputs.OpportunityRankingAnalysisK12} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisK12: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisL12" 
                                                value={Inputs.OpportunityRankingAnalysisL12} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisL12: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisM12" 
                                                value={Inputs.OpportunityRankingAnalysisM12} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisM12: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center font11px'>
                                            <div className="scrore-col">
                                            {Inputs.OpportunityRankingAnalysisN12}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white font11px'>4</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01">
                                                <textarea className="field-ipt bg-color-01 font11px color-dark w-100"
                                                id="OpportunityRankingAnalysisD13"
                                                value={Inputs.OpportunityRankingAnalysisD13}
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisD13: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="Activity D" />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisE13" 
                                                value={Inputs.OpportunityRankingAnalysisE13} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisE13: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisF13" 
                                                value={Inputs.OpportunityRankingAnalysisF13} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisF13: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisG13" 
                                                value={Inputs.OpportunityRankingAnalysisG13} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisG13: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisH13" 
                                                value={Inputs.OpportunityRankingAnalysisH13} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisH13: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisI13" 
                                                value={Inputs.OpportunityRankingAnalysisI13} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisI13: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisJ13" 
                                                value={Inputs.OpportunityRankingAnalysisJ13} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisJ13: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />Z
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisK13" 
                                                value={Inputs.OpportunityRankingAnalysisK13} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisK13: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisL13" 
                                                value={Inputs.OpportunityRankingAnalysisL13} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisL13: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisM13" 
                                                value={Inputs.OpportunityRankingAnalysisM13} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisM13: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center font11px'>
                                            <div className="scrore-col">
                                            {Inputs.OpportunityRankingAnalysisN13}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white font11px'>5</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01">
                                                <textarea className="field-ipt bg-color-01 font11px color-dark w-100"
                                                id="OpportunityRankingAnalysisD14"
                                                value={Inputs.OpportunityRankingAnalysisD14}
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisD14: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="Activity E" />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisE14" 
                                                value={Inputs.OpportunityRankingAnalysisE14} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisE14: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisF14" 
                                                value={Inputs.OpportunityRankingAnalysisF14} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisF14: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisG14" 
                                                value={Inputs.OpportunityRankingAnalysisG14} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisG14: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisH14" 
                                                value={Inputs.OpportunityRankingAnalysisH14} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisH14: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisI14" 
                                                value={Inputs.OpportunityRankingAnalysisI14} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisI14: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisJ14" 
                                                value={Inputs.OpportunityRankingAnalysisJ14} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisJ14: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisK14" 
                                                value={Inputs.OpportunityRankingAnalysisK14} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisK14: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisL14" 
                                                value={Inputs.OpportunityRankingAnalysisL14} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisL14: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisM14" 
                                                value={Inputs.OpportunityRankingAnalysisM14} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisM14: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center font11px'>
                                            <div className="scrore-col">
                                            {Inputs.OpportunityRankingAnalysisN14}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="table-responsive mb-3">
                            {/* table 02 return of investment */}
                            <table className="table" style={{width: 1450}}>
                                <thead>
                                    <tr>
                                        <td rowSpan={2} colSpan={2} className='black-bg text-white text-center font18px'>Tactic or Activity</td>
                                        <td colSpan={6} className='bg-color-blue-gray text-white text-center font16px'>Return On Investment Potential</td>
                                        <td className='hide-content'>7</td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px'>
                                            1-Year Impact
                                        </td>
                                        <td className='black-bg text-white text-center font11px'>
                                            3-Year Impact
                                        </td>
                                        <td className='black-bg text-white text-center font11px'>
                                            Possibility of Success
                                        </td>
                                        <td className='black-bg text-white text-center font11px'>
                                            3-Yr Adjustable Impact
                                        </td>
                                        <td className='black-bg text-white text-center font11px'>
                                            Cost to Implement
                                        </td>
                                        <td className='black-bg text-white text-center font11px'>
                                            Total Revenue Impact
                                        </td>
                                        <td className='black-bg text-white text-center font11px'>
                                            ROI
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white' style={{width: 50}}>1</td>
                                        <td className='bg-color-light-blue color-dark font11px' style={{width: 350}}>
                                            {Inputs.OpportunityRankingAnalysisD10}
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin color-dark font11px">$</div>
                                                <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt font11px bg-color-01 ms-text-right color-dark" 
                                                    id="OpportunityRankingAnalysisE18" 
                                                    value={Inputs.OpportunityRankingAnalysisE18.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')} 
                                                    onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisE18: event.target.value.replace(/,/g, '') }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    onKeyPress={(event) => {
                                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                        event.preventDefault();
                                                        }
                                                        else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                        event.preventDefault();
                                                        alert.show('Please enter a valid number.')
                                                        }
                                                    }}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue' style={{width: 150}}>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font11px color-dark"> $ </div>
                                                <div className="annual-dl-number font11px color-dark">
                                                    {formatPrice(Inputs.OpportunityRankingAnalysisF18, 2)}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0' style={{width: 150}}>
                                            <div className="input-field-auto presentage-center">
                                                <input type="text" className="text-right-rt font11px color-dark" 
                                                id="OpportunityRankingAnalysisG18" 
                                                value={Inputs.OpportunityRankingAnalysisG18} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisG18: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="3"
                                                max="100" 
                                                onInput={(event) => {
                                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                        event.preventDefault();
                                                    }else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                        event.preventDefault();
                                                        alert.show('Please enter a valid number.')
                                                    }
                                                    
                                                }} />
                                                <div className="cneter-peb font11px color-dark">%</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue' style={{width: 150}}>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font11px color-dark"> $ </div>
                                                <div className="annual-dl-number font11px color-dark">
                                                    {formatPrice(Inputs.OpportunityRankingAnalysisH18, 2)}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font11px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt bg-color-01 ms-text-right font11px color-dark" 
                                                    id="OpportunityRankingAnalysisI18" 
                                                    value={Inputs.OpportunityRankingAnalysisI18.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')} 
                                                    onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisI18: event.target.value.replace(/,/g, '') }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    onKeyPress={(event) => {
                                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                        event.preventDefault();
                                                        }
                                                        else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                        event.preventDefault();
                                                        alert.show('Please enter a valid number.')
                                                        }
                                                    }} />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue' style={{width: 150}}>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font11px color-dark"> $ </div>
                                                <div className="annual-dl-number font11px color-dark">
                                                    {formatPrice(Inputs.OpportunityRankingAnalysisJ18, 2)}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center font11px' style={{width: 150}}>
                                            <div className="scrore-col">{formatPrice(Inputs.OpportunityRankingAnalysisK18, 1)}%</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white'>2</td>
                                        <td className='bg-color-light-blue color-dark font11px'>
                                            {Inputs.OpportunityRankingAnalysisD11}
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font11px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt font11px color-dark bg-color-01 text-right" 
                                                    id="OpportunityRankingAnalysisE19" 
                                                    value={Inputs.OpportunityRankingAnalysisE19.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')} 
                                                    onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisE19: event.target.value.replace(/,/g, '') }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    onKeyPress={(event) => {
                                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                        event.preventDefault();
                                                        }
                                                        else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                        event.preventDefault();
                                                        alert.show('Please enter a valid number.')
                                                        }
                                                    }}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font11px color-dark"> $ </div>
                                                <div className="annual-dl-number font11px color-dark">
                                                    {formatPrice(Inputs.OpportunityRankingAnalysisF19, 2)}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-field-auto presentage-center">
                                                <input type="text" className="text-right-rt font11px color-dark" 
                                                id="OpportunityRankingAnalysisG19" 
                                                value={Inputs.OpportunityRankingAnalysisG19.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisG19: event.target.value.replace(/,/g, '') }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="4"
                                                max="100" 
                                                onKeyPress={(event) => {
                                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                    event.preventDefault();
                                                    }
                                                    else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                    event.preventDefault();
                                                    alert.show('Please enter a valid number.')
                                                    }
                                                }} />
                                                <div className="cneter-peb font11px color-dark">%</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font11px color-dark"> $ </div>
                                                <div className="annual-dl-number font11px color-dark">
                                                    {formatPrice(Inputs.OpportunityRankingAnalysisH19, 2)}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font11px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt font11px color-dark bg-color-01 text-right" 
                                                    id="OpportunityRankingAnalysisI19" 
                                                    value={Inputs.OpportunityRankingAnalysisI19.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')} 
                                                    onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisI19: event.target.value.replace(/,/g, '') }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    onKeyPress={(event) => {
                                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                        event.preventDefault();
                                                        }
                                                        else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                        event.preventDefault();
                                                        alert.show('Please enter a valid number.')
                                                        }
                                                    }} />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font11px color-dark"> $ </div>
                                                <div className="annual-dl-number font11px color-dark">
                                                    {formatPrice(Inputs.OpportunityRankingAnalysisJ19, 2)}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center font11px'>
                                            <div className="scrore-col">{formatPrice(Inputs.OpportunityRankingAnalysisK19, 1)} %</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white font11px'>3</td>
                                        <td className='bg-color-light-blue color-dark font11px'>
                                            {Inputs.OpportunityRankingAnalysisD12}
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font11px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt font11px color-dark bg-color-01 text-right" 
                                                    id="OpportunityRankingAnalysisE20" 
                                                    value={Inputs.OpportunityRankingAnalysisE20.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')} 
                                                    onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisE20: event.target.value.replace(/,/g, '') })} 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    onKeyPress={(event) => {
                                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                        event.preventDefault();
                                                        }
                                                        else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                        event.preventDefault();
                                                        alert.show('Please enter a valid number.')
                                                        }
                                                    }}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font11px color-dark"> $ </div>
                                                <div className="annual-dl-number font11px color-dark">
                                                    {formatPrice(Inputs.OpportunityRankingAnalysisF20, 2)}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-field-auto presentage-center">
                                                <input type="text" className="text-right-rt font11px color-dark" 
                                                id="OpportunityRankingAnalysisG20" 
                                                value={Inputs.OpportunityRankingAnalysisG20.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisG20: event.target.value.replace(/,/g, '') }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="4"
                                                max="100" 
                                                onKeyPress={(event) => {
                                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                    event.preventDefault();
                                                    }
                                                    else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                    event.preventDefault();
                                                    alert.show('Please enter a valid number.')
                                                    }
                                                }} />
                                                <div className="cneter-peb font11px color-dark">%</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font11px color-dark"> $ </div>
                                                <div className="annual-dl-number font11px color-dark">
                                                    {formatPrice(Inputs.OpportunityRankingAnalysisH20, 2)}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font11px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt font11px color-dark bg-color-01 text-right" 
                                                    id="OpportunityRankingAnalysisI20" 
                                                    value={Inputs.OpportunityRankingAnalysisI20.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')} 
                                                    onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisI20: event.target.value.replace(/,/g, '') }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    onKeyPress={(event) => {
                                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                        event.preventDefault();
                                                        }
                                                        else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                        event.preventDefault();
                                                        alert.show('Please enter a valid number.')
                                                        }
                                                    }} />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font11px color-dark"> $ </div>
                                                <div className="annual-dl-number font11px color-dark">
                                                    {formatPrice(Inputs.OpportunityRankingAnalysisJ20, 2)}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center font11px'>
                                            <div className="scrore-col">{formatPrice(Inputs.OpportunityRankingAnalysisK20, 1)} %</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white'>4</td>
                                        <td className='bg-color-light-blue color-dark font11px'>
                                            {Inputs.OpportunityRankingAnalysisD13}
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font11px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt font11px color-dark bg-color-01 text-right" 
                                                    id="OpportunityRankingAnalysisE21" 
                                                    value={Inputs.OpportunityRankingAnalysisE21.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')} 
                                                    onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisE21: event.target.value.replace(/,/g, '') }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    onKeyPress={(event) => {
                                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                        event.preventDefault();
                                                        }
                                                        else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                        event.preventDefault();
                                                        alert.show('Please enter a valid number.')
                                                        }
                                                    }}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font11px color-dark"> $ </div>
                                                <div className="annual-dl-number font11px color-dark">
                                                    {formatPrice(Inputs.OpportunityRankingAnalysisF21, 2)}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-field-auto presentage-center">
                                                <input type="text" className="text-right-rt font11px color-dark" 
                                                id="OpportunityRankingAnalysisG21" 
                                                value={Inputs.OpportunityRankingAnalysisG21.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisG21: event.target.value.replace(/,/g, '') }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="4"
                                                max="100" 
                                                onKeyPress={(event) => {
                                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                    event.preventDefault();
                                                    }
                                                    else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                    event.preventDefault();
                                                    alert.show('Please enter a valid number.')
                                                    }
                                                }} />
                                                <div className="cneter-peb font11px color-dark">%</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font11px color-dark"> $ </div>
                                                <div className="annual-dl-number font11px color-dark">
                                                    {formatPrice(Inputs.OpportunityRankingAnalysisH21, 2)}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font11px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt font11px color-dark bg-color-01 text-right" 
                                                    id="OpportunityRankingAnalysisI21" 
                                                    value={Inputs.OpportunityRankingAnalysisI21.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')} 
                                                    onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisI21: event.target.value.replace(/,/g, '') }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    onKeyPress={(event) => {
                                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                        event.preventDefault();
                                                        }
                                                        else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                        event.preventDefault();
                                                        alert.show('Please enter a valid number.')
                                                        }
                                                    }} />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font11px color-dark"> $ </div>
                                                <div className="annual-dl-number font11px color-dark">
                                                    {formatPrice(Inputs.OpportunityRankingAnalysisJ21, 2)}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center font11px'>
                                            <div className="scrore-col">{formatPrice(Inputs.OpportunityRankingAnalysisK21, 1)} %</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white'>5</td>
                                        <td className='bg-color-light-blue color-dark font11px'>
                                            {Inputs.OpportunityRankingAnalysisD14}
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font11px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt font11px color-dark bg-color-01 text-right" 
                                                    id="OpportunityRankingAnalysisE22" 
                                                    value={Inputs.OpportunityRankingAnalysisE22.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')} 
                                                    onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisE22: event.target.value.replace(/,/g, '') }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    onKeyPress={(event) => {
                                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                        event.preventDefault();
                                                        }
                                                        else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                        event.preventDefault();
                                                        alert.show('Please enter a valid number.')
                                                        }
                                                    }}  />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font11px color-dark"> $ </div>
                                                <div className="annual-dl-number font11px color-dark">
                                                    {formatPrice(Inputs.OpportunityRankingAnalysisF22, 2)}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-field-auto presentage-center">
                                                <input type="text" className="text-right-rt font11px color-dark" 
                                                id="OpportunityRankingAnalysisG22" 
                                                value={Inputs.OpportunityRankingAnalysisG22.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisG22: event.target.value.replace(/,/g, '') }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="4"
                                                max="100" 
                                                onKeyPress={(event) => {
                                                    if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                    event.preventDefault();
                                                    }
                                                    else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                    event.preventDefault();
                                                    alert.show('Please enter a valid number.')
                                                    }
                                                }} />
                                                <div className="cneter-peb font11px color-dark">%</div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font11px color-dark"> $ </div>
                                                <div className="annual-dl-number font11px color-dark">
                                                    {formatPrice(Inputs.OpportunityRankingAnalysisH22, 2)}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="dollor-sin-pr">
                                                <div className="dollor-sin font11px color-dark">$</div>
                                                <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                    <input type="text" className="field-ipt font11px color-dark bg-color-01 text-right" 
                                                    id="OpportunityRankingAnalysisI22" 
                                                    value={Inputs.OpportunityRankingAnalysisI22.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')} 
                                                    onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisI22: event.target.value.replace(/,/g, '') }) } 
                                                    onBlur={(event) => { onBlurHandler(event) }} 
                                                    disabled={isLoading} 
                                                    placeholder="0" 
                                                    onKeyPress={(event) => {
                                                        if (!/^[+-]?\d*\.?\d{0,9}$/.test(event.key)) {
                                                        event.preventDefault();
                                                        }
                                                        else if(event.target.value.replace(/,/g, '') !== '-' && event.target.value.replace(/,/g, '') !== '+' && isNaN(event.target.value.replace(/,/g, ''))){
                                                        event.preventDefault();
                                                        alert.show('Please enter a valid number.')
                                                        }
                                                    }} />
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font11px color-dark"> $ </div>
                                                <div className="annual-dl-number font11px color-dark">
                                                    {formatPrice(Inputs.OpportunityRankingAnalysisJ22, 2)}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center font11px'>
                                            <div className="scrore-col">{formatPrice(Inputs.OpportunityRankingAnalysisK22, 1)} %</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="table-responsive mb-3">
                            {/* strategic table 03 */}
                            <table className="table" style={{width: 1900}}>
                                <thead>
                                    <tr>
                                        <td rowSpan={2} colSpan={2} className='black-bg text-white text-center font18px'>Tactic or Activity</td>
                                        <td colSpan={9} className='bg-color-blue-gray text-white text-center font16px'>Strategic Fit</td>
                                        <td className='hide-content'></td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px'>
                                            Short-Term Financial
                                        </td>
                                        <td className='black-bg text-white text-center font11px'>
                                            Long-Term Financial
                                        </td>
                                        <td className='black-bg text-white text-center font11px'>
                                            Creates Competitive Advantage
                                        </td>
                                        <td className='black-bg text-white text-center font11px'>
                                            Supports Overall Strategy
                                        </td>
                                        <td className='black-bg text-white text-center font11px'>
                                            Creates Synergy
                                        </td>
                                        <td className='black-bg text-white text-center font11px'>
                                            Level of Risk
                                        </td>
                                        <td className='black-bg text-white text-center font11px'>
                                            Brand Building & Harmony
                                        </td>
                                        <td className='black-bg text-white text-center font11px'>
                                            Strong, Differentiated Message
                                        </td>
                                        <td className='black-bg text-white text-center font11px'>
                                            Reaches Target Market
                                        </td>
                                        <td className='black-bg text-white text-center font11px'>
                                            Score
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white font11px' style={{width: 50}}>1</td>
                                        <td className='bg-color-light-blue color-dark font11px' style={{width: 350}}>
                                            {Inputs.OpportunityRankingAnalysisD10}
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01'
                                                id="OpportunityRankingAnalysisE26" 
                                                value={Inputs.OpportunityRankingAnalysisE26} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisE26: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                            <input type="text" className='font11px color-dark bg-color-01'
                                            id="OpportunityRankingAnalysisF26" 
                                            value={Inputs.OpportunityRankingAnalysisF26} 
                                            onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisF26: event.target.value }) } 
                                            onBlur={(event) => { onBlurHandler(event) }} 
                                            disabled={isLoading} 
                                            placeholder="0" 
                                            maxLength="1" 
                                            onKeyPress={(event) => {
                                                if (!/[0-5]/.test(event.key)) {
                                                    event.preventDefault();
                                                    alert.show('Please Enter Score range 1 to 5.')
                                                }
                                            }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text"className='font11px color-dark bg-color-01'
                                                id="OpportunityRankingAnalysisG26" 
                                                value={Inputs.OpportunityRankingAnalysisG26} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisG26: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />   
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01'
                                                id="OpportunityRankingAnalysisH26" 
                                                value={Inputs.OpportunityRankingAnalysisH26} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisH26: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01'
                                                id="OpportunityRankingAnalysisI26" 
                                                value={Inputs.OpportunityRankingAnalysisI26} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisI26: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01'
                                                id="OpportunityRankingAnalysisJ26" 
                                                value={Inputs.OpportunityRankingAnalysisJ26} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisJ26: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01'
                                                id="OpportunityRankingAnalysisK26" 
                                                value={Inputs.OpportunityRankingAnalysisK26} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisK26: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01'
                                                id="OpportunityRankingAnalysisL26" 
                                                value={Inputs.OpportunityRankingAnalysisL26} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisL26: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01'
                                                id="OpportunityRankingAnalysisM26" 
                                                value={Inputs.OpportunityRankingAnalysisM26} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisM26: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center font11px' style={{width: 150}}>
                                            <div className="scrore-col">
                                            {Inputs.OpportunityRankingAnalysisO26}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white'>2</td>
                                        <td className='bg-color-light-blue color-dark font11px'>
                                            {Inputs.OpportunityRankingAnalysisD11}
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text"className='font11px color-dark bg-color-01'
                                                id="OpportunityRankingAnalysisE27" 
                                                value={Inputs.OpportunityRankingAnalysisE27} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisE27: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01'
                                                id="OpportunityRankingAnalysisF27" 
                                                value={Inputs.OpportunityRankingAnalysisF27} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisF27: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01'
                                                id="OpportunityRankingAnalysisG27" 
                                                value={Inputs.OpportunityRankingAnalysisG27} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisG27: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01'
                                                id="OpportunityRankingAnalysisH27" 
                                                value={Inputs.OpportunityRankingAnalysisH27} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisH27: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01'
                                                id="OpportunityRankingAnalysisI27" 
                                                value={Inputs.OpportunityRankingAnalysisI27} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisI27: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01'
                                                id="OpportunityRankingAnalysisJ27" 
                                                value={Inputs.OpportunityRankingAnalysisJ27} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisJ27: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01'
                                                id="OpportunityRankingAnalysisK27" 
                                                value={Inputs.OpportunityRankingAnalysisK27} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisK27: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01'
                                                id="OpportunityRankingAnalysisL27" 
                                                value={Inputs.OpportunityRankingAnalysisL27} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisL27: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01'
                                                id="OpportunityRankingAnalysisM27" 
                                                value={Inputs.OpportunityRankingAnalysisM27} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisM27: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center font11px'>
                                            <div className="scrore-col">
                                            {Inputs.OpportunityRankingAnalysisO27}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white'>3</td>
                                        <td className='bg-color-light-blue color-dark font11px'>
                                            {Inputs.OpportunityRankingAnalysisD12}
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01'
                                                id="OpportunityRankingAnalysisE28" 
                                                value={Inputs.OpportunityRankingAnalysisE28} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisE28: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01'
                                                id="OpportunityRankingAnalysisF28" 
                                                value={Inputs.OpportunityRankingAnalysisF28} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisF28: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01'
                                                id="OpportunityRankingAnalysisG28" 
                                                value={Inputs.OpportunityRankingAnalysisG28} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisG28: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01'
                                                id="OpportunityRankingAnalysisH28" 
                                                value={Inputs.OpportunityRankingAnalysisH28} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisH28: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01'
                                                id="OpportunityRankingAnalysisI28" 
                                                value={Inputs.OpportunityRankingAnalysisI28} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisI28: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01'
                                                id="OpportunityRankingAnalysisJ28" 
                                                value={Inputs.OpportunityRankingAnalysisJ28} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisJ28: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01'
                                                id="OpportunityRankingAnalysisK28" 
                                                value={Inputs.OpportunityRankingAnalysisK28} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisK28: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01'
                                                id="OpportunityRankingAnalysisL28" 
                                                value={Inputs.OpportunityRankingAnalysisL28} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisL28: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01'
                                                id="OpportunityRankingAnalysisM28" 
                                                value={Inputs.OpportunityRankingAnalysisM28} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisM28: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center font11px'>
                                            <div className="scrore-col">
                                            {Inputs.OpportunityRankingAnalysisO28}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white'>4</td>
                                        <td className='bg-color-light-blue color-dark font11px'>
                                            {Inputs.OpportunityRankingAnalysisD13}
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01' 
                                                id="OpportunityRankingAnalysisE29" 
                                                value={Inputs.OpportunityRankingAnalysisE29} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisE29: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01' 
                                                id="OpportunityRankingAnalysisF29" 
                                                value={Inputs.OpportunityRankingAnalysisF29} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisF29: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01' 
                                                id="OpportunityRankingAnalysisG29" 
                                                value={Inputs.OpportunityRankingAnalysisG29} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisG29: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01' 
                                                id="OpportunityRankingAnalysisH29" 
                                                value={Inputs.OpportunityRankingAnalysisH29} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisH29: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01' 
                                                id="OpportunityRankingAnalysisI29" 
                                                value={Inputs.OpportunityRankingAnalysisI29} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisI29: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01' 
                                                id="OpportunityRankingAnalysisJ29" 
                                                value={Inputs.OpportunityRankingAnalysisJ29} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisJ29: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01' 
                                                id="OpportunityRankingAnalysisK29" 
                                                value={Inputs.OpportunityRankingAnalysisK29} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisK29: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01' 
                                                id="OpportunityRankingAnalysisL29" 
                                                value={Inputs.OpportunityRankingAnalysisL29} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisL29: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01' 
                                                id="OpportunityRankingAnalysisM29" 
                                                value={Inputs.OpportunityRankingAnalysisM29} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisM29: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center font11px'>
                                            <div className="scrore-col">
                                            {Inputs.OpportunityRankingAnalysisO29}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white'>5</td>
                                        <td className='bg-color-light-blue color-dark font11px'>
                                            {Inputs.OpportunityRankingAnalysisD14}
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01' 
                                                id="OpportunityRankingAnalysisE30" 
                                                value={Inputs.OpportunityRankingAnalysisE30} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisE30: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01' 
                                                id="OpportunityRankingAnalysisF30" 
                                                value={Inputs.OpportunityRankingAnalysisF30} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisF30: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01' 
                                                id="OpportunityRankingAnalysisG30" 
                                                value={Inputs.OpportunityRankingAnalysisG30} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisG30: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01' 
                                                id="OpportunityRankingAnalysisH30" 
                                                value={Inputs.OpportunityRankingAnalysisH30} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisH30: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01' 
                                                id="OpportunityRankingAnalysisI30" 
                                                value={Inputs.OpportunityRankingAnalysisI30} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisI30: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01' 
                                                id="OpportunityRankingAnalysisJ30" 
                                                value={Inputs.OpportunityRankingAnalysisJ30} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisJ30: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01' 
                                                id="OpportunityRankingAnalysisK30" 
                                                value={Inputs.OpportunityRankingAnalysisK30} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisK30: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01' 
                                                id="OpportunityRankingAnalysisL30" 
                                                value={Inputs.OpportunityRankingAnalysisL30} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisL30: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-field-auto bg-color-01 input-fiel-cn">
                                                <input type="text" className='font11px color-dark bg-color-01' 
                                                id="OpportunityRankingAnalysisM30" 
                                                value={Inputs.OpportunityRankingAnalysisM30} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisM30: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center font11px'>
                                            <div className="scrore-col">
                                            {Inputs.OpportunityRankingAnalysisO30}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="table-responsive mb-3">
                            {/* ability table 04 */}
                            <table className="table" style={{width: 1750}}> 
                                <thead>
                                    <tr>
                                        <td rowSpan={2} colSpan={2} className='black-bg text-white text-center font18px'>Tactic or Activity</td>
                                        <td colSpan={8} className='bg-color-blue-gray text-white text-center font16px'>Ability to Execute</td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px'>
                                            Do You Have the Skills?
                                        </td>
                                        <td className='black-bg text-white text-center font11px'>
                                            Time to Prepare
                                        </td>
                                        <td className='black-bg text-white text-center font11px'>
                                            Time to Execute
                                        </td>
                                        <td className='black-bg text-white text-center font11px'>
                                            Training Requirements
                                        </td>
                                        <td className='black-bg text-white text-center font11px'>
                                            Is It Testable?
                                        </td>
                                        <td className='black-bg text-white text-center font11px'>
                                            Is It Optimizable?
                                        </td>
                                        <td className='black-bg text-white text-center font11px'>
                                            Is It Repeatable?
                                        </td>
                                        <td className='black-bg text-white text-center font11px'>
                                            Time to Results
                                        </td>
                                        <td className='black-bg text-white text-center font11px'>
                                            Score
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white font11px' style={{width: 50}}>1</td>
                                        <td className='bg-color-light-blue color-dark font11px' style={{width: 350}}>
                                            {Inputs.OpportunityRankingAnalysisD10}
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font15px color-dark bg-color-01 text-center"
                                                id="OpportunityRankingAnalysisE34" 
                                                value={Inputs.OpportunityRankingAnalysisE34} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisE34: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisF34" 
                                                value={Inputs.OpportunityRankingAnalysisF34} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisF34: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisG34" 
                                                value={Inputs.OpportunityRankingAnalysisG34} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisG34: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisH34" 
                                                value={Inputs.OpportunityRankingAnalysisH34} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisH34: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisI34" 
                                                value={Inputs.OpportunityRankingAnalysisI34} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisI34: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisJ34" 
                                                value={Inputs.OpportunityRankingAnalysisJ34} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisJ34: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisK34" 
                                                value={Inputs.OpportunityRankingAnalysisK34} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisK34: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0' style={{width: 150}}>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisL34" 
                                                value={Inputs.OpportunityRankingAnalysisL34} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisL34: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center font11px' style={{width: 150}}>
                                            <div className="scrore-col">
                                            {Inputs.OpportunityRankingAnalysisM34}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white font11px'>2</td>
                                        <td className='bg-color-light-blue color-dark font11px'>
                                            {Inputs.OpportunityRankingAnalysisD11}
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisE35" 
                                                value={Inputs.OpportunityRankingAnalysisE35} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisE35: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisF35" 
                                                value={Inputs.OpportunityRankingAnalysisF35} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisF35: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisG35" 
                                                value={Inputs.OpportunityRankingAnalysisG35} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisG35: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisH35" 
                                                value={Inputs.OpportunityRankingAnalysisH35} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisH35: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisI35" 
                                                value={Inputs.OpportunityRankingAnalysisI35} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisI35: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisJ35" 
                                                value={Inputs.OpportunityRankingAnalysisJ35} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisJ35: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisK35" 
                                                value={Inputs.OpportunityRankingAnalysisK35} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisK35: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisL35" 
                                                value={Inputs.OpportunityRankingAnalysisL35} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisL35: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center font11px'>
                                            <div className="scrore-col">
                                            {Inputs.OpportunityRankingAnalysisM35}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white font11px'>3</td>
                                        <td className='bg-color-light-blue color-dark font11px'>
                                            {Inputs.OpportunityRankingAnalysisD12}
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisE36" 
                                                value={Inputs.OpportunityRankingAnalysisE36} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisE36: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisF36" 
                                                value={Inputs.OpportunityRankingAnalysisF36} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisF36: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisG36" 
                                                value={Inputs.OpportunityRankingAnalysisG36} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisG36: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisH36" 
                                                value={Inputs.OpportunityRankingAnalysisH36} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisH36: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisI36" 
                                                value={Inputs.OpportunityRankingAnalysisI36} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisI36: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisJ36" 
                                                value={Inputs.OpportunityRankingAnalysisJ36} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisJ36: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisK36" 
                                                value={Inputs.OpportunityRankingAnalysisK36} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisK36: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisL36" 
                                                value={Inputs.OpportunityRankingAnalysisL36} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisL36: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center font11px'>
                                            <div className="scrore-col">
                                            {Inputs.OpportunityRankingAnalysisM36}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white font11px'>4</td>
                                        <td className='bg-color-light-blue color-dark font11px'>
                                            {Inputs.OpportunityRankingAnalysisD13}
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisE37" 
                                                value={Inputs.OpportunityRankingAnalysisE37} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisE37: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisF37" 
                                                value={Inputs.OpportunityRankingAnalysisF37} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisF37: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisG37" 
                                                value={Inputs.OpportunityRankingAnalysisG37} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisG37: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisH37" 
                                                value={Inputs.OpportunityRankingAnalysisH37} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisH37: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisI37" 
                                                value={Inputs.OpportunityRankingAnalysisI37} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisI37: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisJ37" 
                                                value={Inputs.OpportunityRankingAnalysisJ37} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisJ37: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisK37" 
                                                value={Inputs.OpportunityRankingAnalysisK37} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisK37: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisL37" 
                                                value={Inputs.OpportunityRankingAnalysisL37} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisL37: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center font11px'>
                                            <div className="scrore-col">
                                            {Inputs.OpportunityRankingAnalysisM37}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white font11px'>5</td>
                                        <td className='bg-color-light-blue color-dark font11px'>
                                            {Inputs.OpportunityRankingAnalysisD14}
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisE38" 
                                                value={Inputs.OpportunityRankingAnalysisE38} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisE38: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisF38" 
                                                value={Inputs.OpportunityRankingAnalysisF38} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisF38: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisG38" 
                                                value={Inputs.OpportunityRankingAnalysisG38} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisG38: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisH38" 
                                                value={Inputs.OpportunityRankingAnalysisH38} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisH38: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisI38" 
                                                value={Inputs.OpportunityRankingAnalysisI38} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisI38: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisJ38" 
                                                value={Inputs.OpportunityRankingAnalysisJ38} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisJ38: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisK38" 
                                                value={Inputs.OpportunityRankingAnalysisK38} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisK38: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 client-inpt-field opportunity-inpt">
                                                <input type="text" className="field-ipt font11px color-dark bg-color-01 text-center" 
                                                id="OpportunityRankingAnalysisL38" 
                                                value={Inputs.OpportunityRankingAnalysisL38} 
                                                onChange={(event) => setInputs({ ...Inputs, OpportunityRankingAnalysisL38: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                        <td className='bg-color-blue text-white text-center font11px'>
                                            <div className="scrore-col">
                                            {Inputs.OpportunityRankingAnalysisM38}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="table-responsive mb-3">
                            {/* total impacts table 05 */}
                            <table className="table" style={{width: 1150}}>
                                <thead>
                                    <tr>
                                        <td rowSpan={2} colSpan={2} className='black-bg text-white text-center font18px'>Tactic or Activity</td>
                                        <td colSpan={4} className='bg-color-blue text-white text-center font16px'>Total Impact of Activity</td>
                                        <td className='hide-content'>hide</td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg text-white text-center font11px'>
                                            Growth Driver Impact
                                        </td>
                                        <td className='black-bg text-white text-center font11px'>
                                            On-Strategy
                                        </td>
                                        <td className='black-bg text-white text-center font11px'>
                                            Ability to Execute
                                        </td>
                                        <td className='black-bg text-white text-center font11px'>
                                            ROI
                                        </td>
                                        {/* <td className='black-bg text-white text-center font11px'>
                                            Is It Testable?
                                        </td> */}
                                        <td className='black-bg text-white text-center font11px fontExtraBold'>
                                            Total Score
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white font11px' style={{width: 50}}>1</td>
                                        <td className='bg-color-light-blue color-dark font11px' style={{width: 350}}>
                                            {Inputs.OpportunityRankingAnalysisD10}
                                        </td>
                                        <td className='bg-color-light-blue color-dark font11px text-center' style={{width: 150}}>
                                            {Inputs.OpportunityRankingAnalysisE43}
                                        </td>
                                        <td className='bg-color-light-blue color-dark font11px text-center' style={{width: 150}}>
                                            {Inputs.OpportunityRankingAnalysisG43}
                                        </td>
                                        <td className='bg-color-light-blue color-dark font11px text-center' style={{width: 150}}>
                                            {Inputs.OpportunityRankingAnalysisH43}
                                        </td>
                                        <td className='bg-color-light-blue color-dark font11px text-center' style={{width: 150}}>
                                            {Inputs.OpportunityRankingAnalysisF43 + " %"}
                                        </td>
                                        <td className='bg-color-blue text-white text-center font11px' style={{width: 150}}>
                                            <div className="scrore-col">
                                            {Inputs.OpportunityRankingAnalysisE43 + Inputs.OpportunityRankingAnalysisG43 + Inputs.OpportunityRankingAnalysisH43}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white font11px'>2</td>
                                        <td className='bg-color-light-blue color-dark font11px'>
                                            {Inputs.OpportunityRankingAnalysisD11}
                                        </td>
                                        <td className='bg-color-light-blue color-dark font11px text-center'>
                                            {Inputs.OpportunityRankingAnalysisE44}
                                        </td>
                                        <td className='bg-color-light-blue color-dark font11px text-center'>
                                            {Inputs.OpportunityRankingAnalysisG44}
                                        </td>
                                        <td className='bg-color-light-blue color-dark font11px text-center'>
                                            {Inputs.OpportunityRankingAnalysisH44}
                                        </td>
                                        <td className='bg-color-light-blue color-dark font11px text-center'>
                                            {Inputs.OpportunityRankingAnalysisF44 + " %"}
                                        </td>
                                        <td className='bg-color-blue text-white text-center font11px'>
                                            <div className="scrore-col">
                                            {Inputs.OpportunityRankingAnalysisE44 + Inputs.OpportunityRankingAnalysisG44 + Inputs.OpportunityRankingAnalysisH44}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white font11px'>3</td>
                                        <td className='bg-color-light-blue color-dark font11px'>
                                            {Inputs.OpportunityRankingAnalysisD12}
                                        </td>
                                        <td className='bg-color-light-blue color-dark font11px text-center'>
                                            {Inputs.OpportunityRankingAnalysisE45}
                                        </td>
                                        <td className='bg-color-light-blue color-dark font11px text-center'>
                                            {Inputs.OpportunityRankingAnalysisG45}
                                        </td>
                                        <td className='bg-color-light-blue color-dark font11px text-center'>
                                            {Inputs.OpportunityRankingAnalysisH45}
                                        </td>
                                        <td className='bg-color-light-blue color-dark font11px text-center'>
                                            {Inputs.OpportunityRankingAnalysisF45 + " %"}
                                        </td>
                                        <td className='bg-color-blue text-white text-center font11px'>
                                            <div className="scrore-col">
                                            {Inputs.OpportunityRankingAnalysisE45 + Inputs.OpportunityRankingAnalysisG45 + Inputs.OpportunityRankingAnalysisH45}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white font11px'>4</td>
                                        <td className='bg-color-light-blue color-dark font11px'>
                                            {Inputs.OpportunityRankingAnalysisD13}
                                        </td>
                                        <td className='bg-color-light-blue color-dark font11px text-center'>
                                            {Inputs.OpportunityRankingAnalysisE46}
                                        </td>
                                        <td className='bg-color-light-blue color-dark font11px text-center'>
                                            {Inputs.OpportunityRankingAnalysisG46}
                                        </td>
                                        <td className='bg-color-light-blue color-dark font11px text-center'>
                                            {Inputs.OpportunityRankingAnalysisH46}
                                        </td>
                                        <td className='bg-color-light-blue color-dark font11px text-center'>
                                            {Inputs.OpportunityRankingAnalysisF46 + " %"}
                                        </td>
                                        <td className='bg-color-blue text-white text-center font11px'>
                                            <div className="scrore-col">
                                            {Inputs.OpportunityRankingAnalysisE46 + Inputs.OpportunityRankingAnalysisG46 + Inputs.OpportunityRankingAnalysisH46}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-03 text-center text-white font11px'>5</td>
                                        <td className='bg-color-light-blue color-dark font11px'>
                                            {Inputs.OpportunityRankingAnalysisD14}
                                        </td>
                                        <td className='bg-color-light-blue color-dark font11px text-center'>
                                            {Inputs.OpportunityRankingAnalysisE47}
                                        </td>
                                        <td className='bg-color-light-blue color-dark font11px text-center'>
                                            {Inputs.OpportunityRankingAnalysisG47}
                                        </td>
                                        <td className='bg-color-light-blue color-dark font11px text-center'>
                                            {Inputs.OpportunityRankingAnalysisH47}
                                        </td>
                                        <td className='bg-color-light-blue color-dark font11px text-center'>
                                            {Inputs.OpportunityRankingAnalysisF47 + " %"}
                                        </td>
                                        <td className='bg-color-blue text-white text-center font11px'>
                                            <div className="scrore-col">
                                            {Inputs.OpportunityRankingAnalysisE47 + Inputs.OpportunityRankingAnalysisG47 + Inputs.OpportunityRankingAnalysisH47}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="nextInput">
                            <ul>
                                <li>
                                    <Link to="/prescribe/weighted-decision-matrix" className='next-link'> Next:  Weighted Decision Matrix  
                                        <span className='icon-three'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                        </span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OpportunityRankingAnalysisContentNew;