import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getTheBigSWOT, modifyTheBigSWOT } from "../../state/features/TheBigSWOTSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';

export default function TheBigSWOTContentNew() {

    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        BigSWOTC17 : '',
        BigSWOTH30 : '',
        BigSWOTJ30 : '',
        BigSWOTK30 : '',
        BigSWOTM30 : '',
        BigSWOTH31 : '',
        BigSWOTJ31 : '',
        BigSWOTK31 : '',
        BigSWOTM31 : '',
        BigSWOTH32 : '',
        BigSWOTJ32 : '',
        BigSWOTK32 : '',
        BigSWOTM32 : '',
        BigSWOTH34 : '',
        BigSWOTJ34 : '',
        BigSWOTK34 : '',
        BigSWOTM34 : '',
        BigSWOTH35 : '',
        BigSWOTJ35 : '',
        BigSWOTK35 : '',
        BigSWOTM35 : '',
        BigSWOTH36 : '',
        BigSWOTJ36 : '',
        BigSWOTK36 : '',
        BigSWOTM36 : '',
        BigSWOTH38 : '',
        BigSWOTJ38 : '',
        BigSWOTK38 : '',
        BigSWOTM38 : '',
        BigSWOTH39 : '',
        BigSWOTJ39 : '',
        BigSWOTK39 : '',
        BigSWOTM39 : '',
        BigSWOTH40 : '',
        BigSWOTJ40 : '',
        BigSWOTK40 : '',
        BigSWOTM40 : '',
        BigSWOTH42 : '',
        BigSWOTJ42 : '',
        BigSWOTK42 : '',
        BigSWOTM42 : '',
        BigSWOTH43 : '',
        BigSWOTJ43 : '',
        BigSWOTK43 : '',
        BigSWOTM43 : '',
        BigSWOTH44 : '',
        BigSWOTJ44 : '',
        BigSWOTK44 : '',
        BigSWOTM44 : '',
        BigSWOTH45 : '',
        BigSWOTJ45 : '',
        BigSWOTK45 : '',
        BigSWOTM45 : '',
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getTheBigSWOT({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.growthPlanning)
            if(response!= undefined  && response.data.growthPlanning){
                let growthPlanning = response.data.growthPlanning[0];
                // console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key]
                    }
                }
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyTheBigSWOT({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                    // console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
            {/* row */}
            <div className="container-fluid">
                <div className="head-title bigsout mb-2">
                    <h3 className='preheading'>Tools - Competitive Strategy</h3>
                    <h1 className='mainheading'>The Big SWOT</h1>
                </div>
                <div className="opportunity-page-table">
                    <div className="opportunity-page-table-inner opportunity-abl">
                        <div className='mb-3' style={{maxWidth: 1350}}>
                            <p className='body-text color-light'><b>SWOT Analysis</b> is a strategic planning tool that explores an organization’s internal and external environment, including strengths, weaknesses, opportunities, and threats. Perform your SWOT Analysis for either your entire business or a specific segment of your business.<br/>
                            Sometimes called an Internal-External Analysis (IE Analysis), SWOT analysis is used widely by many teams, organizations, and consultants to quickly assess the most relevant aspects of a company’s current and potential future strategy. When creating your SWOT, consider the following questions:</p>
                            <ul>
                                <li className='body-text color-light'><b>Strengths:</b>  What are our internal core competencies and source of competitive differentiation?</li>
                                <li className='body-text color-light'><b>Weaknesses:</b>  What internal capability gaps or weaknesses exist that might cause problems in the future?</li>
                                <li className='body-text color-light'><b>Opportunities:</b> What external market opportunities that we should pursue?</li>
                                <li className='body-text color-light'><b>Threats:</b> What external threats exists that we should address?</li>
                            </ul>  		
                            {/* <p className='body-text color-light'>Existing organizations can use a SWOT analysis as part of their traditional planning processes. New ventures and start-ups can use SWOT analysis to gain insight into potentially future challenges and ways to create competitive differentiation. Even nonprofit organizations can use SWOT analysis. The goal is to create a simple structure that engages and aligns teams and organizations around the current state and future strategies and plans for the business.</p>	 */}
                            <p className='body-text color-light'>The goal is to create a simple structure that engages and aligns teams and organizations around the current state and future strategies and plans for the business.</p>
                        </div>
                        <div className="table-responsive mb-3">
                            <table className="table" style={{width: 1350}}>
                                <thead>
                                    <tr>
                                        <td colSpan={11} className='black-bg text-white ms-text-left font18px'>Purpose</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan={11} className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01">
                                                <input type="text" className='field-ipt font11px bg-color-01 color-dark'
                                                id="BigSWOTC17"
                                                value={Inputs.BigSWOTC17}
                                                onChange={(event) => setInputs({ ...Inputs, BigSWOTC17: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading}
                                                placeholder="Enter the purpose of the analysis here" />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='hide-content' colSpan={11}></td>
                                    </tr>
                                    <tr>
                                        <td className='black-bg' style={{width: 50, height: 80}}>
                                            <h3 className="mb-0 text-center text-white font24px">S</h3>
                                        </td>
                                        <td className="bg-color-03 ms-text-left" style={{width: 250}}>
                                            <h3 className="mb-0 font14px text-white">Internal <strong>STRENGTHS</strong></h3>
                                        </td>
                                        <td className='hide-content' style={{width: 50}}></td>

                                        <td className='black-bg' style={{width: 50, height: 80}}>
                                            <h3 className="mb-0 text-center text-white font24px">W</h3>
                                        </td>
                                        <td className="bg-color-03 ms-text-left" style={{width: 250}}>
                                            <h3 className="mb-0 font14px text-white">Internal <strong>WEAKNESSSES</strong></h3>
                                        </td>
                                        <td className='hide-content' style={{width: 50}}></td>
                                        
                                        <td className='black-bg' style={{width: 50, height: 80}}>
                                            <h3 className="mb-0 text-center text-white font24px">O</h3>
                                        </td>
                                        <td className="bg-color-03 ms-text-left" style={{width: 250}}> 
                                            <h3 className="mb-0 font14px text-white">External <strong>OPPORTUNITIES</strong></h3>
                                        </td>
                                        <td className='hide-content' style={{width: 50}}></td>
                                        
                                        <td className='black-bg' style={{width: 50, height: 80}}>
                                            <h3 className="mb-0 text-center text-white font24px">T</h3>
                                        </td>
                                        <td className="bg-color-03 ms-text-left" style={{width: 250}}>
                                            <h3 className="mb-0 font14px text-white">External <strong>THREATS</strong></h3>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color-03 p-1 font11px text-white text-center" style={{width: 50, height: 60}}>
                                            1
                                        </td>
                                        <td className='bg-color-01'>
                                            <div className="bg-color-01 color-dark font11px">
                                            What do our customers love most?
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>

                                        <td className="bg-color-03 p-1 font11px text-white text-center" style={{width: 50, height: 60}}>
                                            1
                                        </td>
                                        <td className='bg-color-01'>
                                            <div className="bg-color-01 color-dark font11px">
                                            Where do we lack efficiency?
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>

                                        <td className="bg-color-03 p-1 font11px text-white text-center" style={{width: 50, height: 60}}>
                                            1
                                        </td>
                                        <td className='bg-color-01'>
                                            <div className="bg-color-01 color-dark font11px">
                                            What is missing in our market?
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>

                                        <td className="bg-color-03 p-1 font11px text-white text-center" style={{width: 50, height: 60}}>
                                            1
                                        </td>
                                        <td className='bg-color-01'>
                                            <div className="bg-color-01 color-dark font11px">
                                            What changes are occurring in our market's environment?
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color-03 p-1 font11px text-white text-center" style={{width: 50, height: 60}}>
                                            2
                                        </td>
                                        <td className='bg-color-01'>
                                            <div className="bg-color-01 color-dark font11px">
                                            What are we more efficient at?
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>

                                        <td className="bg-color-03 p-1 font11px text-white text-center" style={{width: 50, height: 60}}>
                                            2
                                        </td>
                                        <td className='bg-color-01'>
                                            <div className="bg-color-01 color-dark font11px">
                                            Where are we wasting money?
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>

                                        <td className="bg-color-03 p-1 font11px text-white text-center" style={{width: 50, height: 60}}>
                                            2
                                        </td>
                                        <td className='bg-color-01'>
                                            <div className="bg-color-01 color-dark font11px">
                                            What could we create or do better than a competitor?
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>

                                        <td className="bg-color-03 p-1 font11px text-white text-center" style={{width: 50, height: 60}}>
                                            2
                                        </td>
                                        <td className='bg-color-01'>
                                            <div className="bg-color-01 color-dark font11px">
                                            What technologies could replace what we do?
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color-03 p-1 font11px text-white text-center" style={{width: 50, height: 60}}>
                                            3
                                        </td>
                                        <td className='bg-color-01'>
                                            <div className="bg-color-01 color-dark font11px">
                                            What can we do for less money?
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>

                                        <td className="bg-color-03 p-1 font11px text-white text-center" style={{width: 50, height: 60}}>
                                            3
                                        </td>
                                        <td className='bg-color-01'>
                                            <div className="bg-color-01 color-dark font11px">
                                            Where are we wasting time and resources?
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>

                                        <td className="bg-color-03 p-1 font11px text-white text-center" style={{width: 50, height: 60}}>
                                            3
                                        </td>
                                        <td className='bg-color-01'>
                                            <div className="bg-color-01 color-dark font11px">
                                            What new trends are occurring?
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>

                                        <td className="bg-color-03 p-1 font11px text-white text-center" style={{width: 50, height: 60}}>
                                            3
                                        </td>
                                        <td className='bg-color-01'>
                                            <div className="bg-color-01 color-dark font11px">
                                            What changes are occurring in the way we're being discovered?
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="bg-color-03 p-1 font11px text-white text-center" style={{width: 50, height: 60}}>
                                            4
                                        </td>
                                        <td className='bg-color-01'>
                                            <div className="bg-color-01 color-dark font11px">
                                            What can we do in less time?
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>

                                        <td className="bg-color-03 p-1 font11px text-white text-center" style={{width: 50, height: 60}}>
                                            4
                                        </td>
                                        <td className='bg-color-01'>
                                            <div className="bg-color-01 color-dark font11px">
                                            What do our competitors do better?
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>

                                        <td className="bg-color-03 p-1 font11px text-white text-center" style={{width: 50, height: 60}}>
                                            4
                                        </td>
                                        <td className='bg-color-01'>
                                            <div className="bg-color-01 color-dark font11px">
                                            What new technology could we use?
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>

                                        <td className="bg-color-03 p-1 font11px text-white text-center" style={{width: 50, height: 60}}>
                                            4
                                        </td>
                                        <td className='bg-color-01'>
                                            <div className="bg-color-01 color-dark font11px">
                                            What social changes could threaten us?
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color-03 p-1 font11px text-white text-center" style={{width: 50, height: 60}}>
                                            5
                                        </td>
                                        <td className='bg-color-01'>
                                            <div className="bg-color-01 color-dark font11px">
                                            What makes us stand out?
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>

                                        <td className="bg-color-03 p-1 font11px text-white text-center" style={{width: 50, height: 60}}>
                                            5
                                        </td>
                                        <td className='bg-color-01'>
                                            <div className="bg-color-01 color-dark font11px">
                                            What are our top customer complaints?
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>

                                        <td className="bg-color-03 p-1 font11px text-white text-center" style={{width: 50, height: 60}}>
                                            5
                                        </td>
                                        <td className='bg-color-01'>
                                            <div className="bg-color-01 color-dark font11px">
                                            What openings in the market are there?
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>

                                        <td className="bg-color-03 p-1 font11px text-white text-center" style={{width: 50, height: 60}}>
                                            5
                                        </td>
                                        <td className='bg-color-01'>
                                            <div className="bg-color-01 color-dark font11px">
                                            Are there any threatening government policies or regulations?
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color-03 p-1 font11px text-white text-center" style={{width: 50, height: 60}}>
                                            6
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 color-dark font11px">
                                                <textarea className='field-ipt font11px bg-color-01 color-dark'
                                                id="input_6_1"
                                                value={""} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>

                                        <td className="bg-color-03 p-1 font11px text-white text-center" style={{width: 50, height: 60}}>
                                            6
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 color-dark font11px">
                                                <textarea className='field-ipt font11px bg-color-01 color-dark'
                                                id="input_6_2"
                                                value={""} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>

                                        <td className="bg-color-03 p-1 font11px text-white text-center" style={{width: 50, height: 60}}>
                                            6
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 color-dark font11px">
                                                <textarea className='field-ipt font11px bg-color-01 color-dark'
                                                id="input_6_3"
                                                value={""} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>

                                        <td className="bg-color-03 p-1 font11px text-white text-center" style={{width: 50, height: 60}}>
                                            6
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 color-dark font11px">
                                                <textarea className='field-ipt font11px bg-color-01 color-dark'
                                                id="input_6_4"
                                                value={""} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color-03 p-1 font11px text-white text-center" style={{width: 50, height: 60}}>
                                            7
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 color-dark font11px">
                                                <textarea className='field-ipt font11px bg-color-01 color-dark'
                                                id="input_7_1"
                                                value={""} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>

                                        <td className="bg-color-03 p-1 font11px text-white text-center" style={{width: 50, height: 60}}>
                                            7
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 color-dark font11px">
                                                <textarea className='field-ipt font11px bg-color-01 color-dark'
                                                id="input_7_2"
                                                value={""} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>

                                        <td className="bg-color-03 p-1 font11px text-white text-center" style={{width: 50, height: 60}}>
                                            7
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 color-dark font11px">
                                                <textarea className='field-ipt font11px bg-color-01 color-dark'
                                                id="input_7_3"
                                                value={""} />
                                            </div>
                                        </td>
                                        <td className='hide-content'></td>

                                        <td className="bg-color-03 p-1 font11px text-white text-center" style={{width: 50, height: 60}}>
                                            7
                                        </td>
                                        <td className='bg-color-01 padding-0'>
                                            <div className="input-fiel-cn bg-color-01 color-dark font11px">
                                                <textarea className='field-ipt font11px bg-color-01 color-dark'
                                                id="input_7_4"
                                                value={""} />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="table-responsive mb-3">
                            <table className="table" style={{width: 1350}}>
                                <thead>
                                    <tr>
                                        <td colSpan={11} className='black-bg text-white ms-text-left font16px'>Big SWOT Analysis Categories</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="bg-color-03 text-white font11px" style={{width: 650}}>
                                            External:  Targets
                                        </td>
                                        <td className="bg-color-03 text-white font11px" style={{width: 100}}>
                                            Rank Order
                                        </td>
                                        <td className="bg-color-03 text-white font11px" style={{width: 250}}>
                                            Opportunities
                                        </td>
                                        <td className="bg-color-03 text-white font11px" style={{width: 100}}>
                                            Rank Order
                                        </td>
                                        <td className="bg-color-03 text-white font11px" style={{width: 250}}>
                                            Threats
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color-light-blue">
                                            <div className="bg-color-light-blue color-dark font11px">
                                            Customer Segments
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTH30"
                                                    value={Inputs.BigSWOTH30}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTH30: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTJ30"
                                                    value={Inputs.BigSWOTJ30}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTJ30: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTK30"
                                                    value={Inputs.BigSWOTK30}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTK30: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTM30"
                                                    value={Inputs.BigSWOTM30}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTM30: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color-light-blue">
                                            <div className="bg-color-light-blue color-dark font11px">
                                            Markets
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTH31"
                                                    value={Inputs.BigSWOTH31}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTH31: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTJ31"
                                                    value={Inputs.BigSWOTJ31}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTJ31: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTK31"
                                                    value={Inputs.BigSWOTK31}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTK31: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTM31"
                                                    value={Inputs.BigSWOTM31}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTM31: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color-light-blue">
                                            <div className="bg-color-light-blue color-dark font11px">
                                            Geographies
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTH32"
                                                    value={Inputs.BigSWOTH32}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTH32: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTJ32"
                                                    value={Inputs.BigSWOTJ32}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTJ32: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTK32"
                                                    value={Inputs.BigSWOTK32}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTK32: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTM32"
                                                    value={Inputs.BigSWOTM32}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTM32: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color-03 text-white font11px">
                                            Internal:  Value Propositions
                                        </td>
                                        <td className="bg-color-03 text-white font11px">
                                            Rank Order
                                        </td>
                                        <td className="bg-color-03 text-white font11px">
                                            Strengths Relative to Competitors
                                        </td>
                                        <td className="bg-color-03 text-white font11px">
                                            Rank Order
                                        </td>
                                        <td className="bg-color-03 text-white font11px">
                                            Weaknesses Relative to Competitors
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color-light-blue">
                                            <div className="bg-color-light-blue color-dark font11px">
                                            Products
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTH34"
                                                    value={Inputs.BigSWOTH34}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTH34: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTJ34"
                                                    value={Inputs.BigSWOTJ34}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTJ34: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTK34"
                                                    value={Inputs.BigSWOTK34}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTK34: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTM34"
                                                    value={Inputs.BigSWOTM34}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTM34: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color-light-blue">
                                            <div className="bg-color-light-blue color-dark font11px">
                                            Services
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTH35"
                                                    value={Inputs.BigSWOTH35}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTH35: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTJ35"
                                                    value={Inputs.BigSWOTJ35}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTJ35: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTK35"
                                                    value={Inputs.BigSWOTK35}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTK35: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTM35"
                                                    value={Inputs.BigSWOTM35}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTM35: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color-light-blue">
                                            <div className="bg-color-light-blue color-dark font11px">
                                            Pricing
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTH36"
                                                    value={Inputs.BigSWOTH36}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTH36: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTJ36"
                                                    value={Inputs.BigSWOTJ36}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTJ36: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTK36"
                                                    value={Inputs.BigSWOTK36}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTK36: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTM36"
                                                    value={Inputs.BigSWOTM36}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTM36: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color-03 text-white font11px">
                                            Internal:  Go-To-Market
                                        </td>
                                        <td className="bg-color-03 text-white font11px">
                                            Rank Order
                                        </td>
                                        <td className="bg-color-03 text-white font11px">
                                            Strengths Relative to Competitors
                                        </td>
                                        <td className="bg-color-03 text-white font11px">
                                            Rank Order
                                        </td>
                                        <td className="bg-color-03 text-white font11px">
                                            Weaknesses Relative to Competitors
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color-light-blue">
                                            <div className="bg-color-light-blue color-dark font11px">
                                            Distribution
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTH38"
                                                    value={Inputs.BigSWOTH38}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTH38: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTJ38"
                                                    value={Inputs.BigSWOTJ38}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTJ38: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTK38"
                                                    value={Inputs.BigSWOTK38}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTK38: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTM38"
                                                    value={Inputs.BigSWOTM38}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTM38: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color-light-blue">
                                            <div className="bg-color-light-blue color-dark font11px">
                                            Marketing
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTH39"
                                                    value={Inputs.BigSWOTH39}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTH39: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTJ39"
                                                    value={Inputs.BigSWOTJ39}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTJ39: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTK39"
                                                    value={Inputs.BigSWOTK39}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTK39: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTM39"
                                                    value={Inputs.BigSWOTM39}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTM39: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color-light-blue">
                                            <div className="bg-color-light-blue color-dark font11px">
                                            Sales
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTH40"
                                                    value={Inputs.BigSWOTH40}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTH40: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTJ40"
                                                    value={Inputs.BigSWOTJ40}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTJ40: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTK40"
                                                    value={Inputs.BigSWOTK40}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTK40: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTM40"
                                                    value={Inputs.BigSWOTM40}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTM40: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color-03 text-white font11px">
                                            Internal:  The Organization
                                        </td>
                                        <td className="bg-color-03 text-white font11px">
                                            Rank Order
                                        </td>
                                        <td className="bg-color-03 text-white font11px">
                                            Strengths Relative to Competitors
                                        </td>
                                        <td className="bg-color-03 text-white font11px">
                                            Rank Order
                                        </td>
                                        <td className="bg-color-03 text-white font11px">
                                            Weaknesses Relative to Competitors
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color-light-blue">
                                            <div className="bg-color-light-blue color-dark font11px">
                                            Processes / Functions
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTH42"
                                                    value={Inputs.BigSWOTH42}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTH42: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTJ42"
                                                    value={Inputs.BigSWOTJ42}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTJ42: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTK42"
                                                    value={Inputs.BigSWOTK42}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTK42: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTM42"
                                                    value={Inputs.BigSWOTM42}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTM42: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color-light-blue">
                                            <div className="bg-color-light-blue color-dark font11px">
                                            People
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTH43"
                                                    value={Inputs.BigSWOTH43}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTH43: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTJ43"
                                                    value={Inputs.BigSWOTJ43}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTJ43: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTK43"
                                                    value={Inputs.BigSWOTK43}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTK43: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTM43"
                                                    value={Inputs.BigSWOTM43}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTM43: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color-light-blue">
                                            <div className="bg-color-light-blue color-dark font11px">
                                            Infrastructure
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTH44"
                                                    value={Inputs.BigSWOTH44}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTH44: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTJ44"
                                                    value={Inputs.BigSWOTJ44}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTJ44: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTK44"
                                                    value={Inputs.BigSWOTK44}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTK44: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTM44"
                                                    value={Inputs.BigSWOTM44}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTM44: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-color-light-blue">
                                            <div className="bg-color-light-blue color-dark font11px">
                                            Partnerships
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTH45"
                                                    value={Inputs.BigSWOTH45}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTH45: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTJ45"
                                                    value={Inputs.BigSWOTJ45}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTJ45: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTK45"
                                                    value={Inputs.BigSWOTK45}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTK45: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                        <td className="bg-color-02 padding-0">
                                            <div className="bg-color-02 input-fiel-cn">
                                                <input type="text" className="field-ipt bg-color-02 font11px color-dark"
                                                    id="BigSWOTM45"
                                                    value={Inputs.BigSWOTM45}
                                                    onChange={(event) => setInputs({ ...Inputs, BigSWOTM45: event.target.value }) }
                                                    onBlur={(event) => { onBlurHandler(event) }}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="table-responsive mb-3">
                            <table className="table" style={{width: 1350}}>
                                <thead>
                                    <tr>
                                        <td colSpan={2} className='black-bg text-white ms-text-left font18px'>Action Items &amp; Goals</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row" className='bg-color-03 text-center text-white font11px' style={{width: 50}}>1</th>
                                        <td className='bg-color-01' style={{width: 1300}}>
                                            <div className="bg-color-01 color-dark font11px">
                                            Which opportunities should we pursue? How can we use our strengths to help us achieve our goals?
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" className='bg-color-03 text-center text-white font11px' style={{width: 50}}>2</th>
                                        <td className='bg-color-01'>
                                            <div className="bg-color-01 color-dark font11px">
                                            Which weaknesses need to be improved so we are less susceptible to external threats?
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" className='bg-color-03 text-center text-white font11px' style={{width: 50}}>3</th>
                                        <td className='bg-color-01'>
                                            <div className="bg-color-01 color-dark font11px">
                                            What strategies can we put into place to be prepared for threats?
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" className='bg-color-03 text-center text-white font11px' style={{width: 50}}>4</th>
                                        <td className='bg-color-01'>
                                            <div className="bg-color-01 color-dark font11px">
                                            How can we use our strengths to make best use of any existing opportunities?
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" className='bg-color-03 text-center text-white font11px' style={{width: 50}}>5</th>
                                        <td className='bg-color-01'>
                                            <div className="bg-color-01 input-fiel-cn">
                                                <textarea className="field-ipt bg-color-01 font11px color-dark"
                                                    id="BigSWOTH452"
                                                    value={""}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
