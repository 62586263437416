import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getPositioningMatrix, modifyPositioningMatrix } from "../../state/features/PositioningMatrixSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';

export default function PositioningMatrixContent() {

    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        PositioningMatrixE11 : '',
        PositioningMatrixF11 : '',
        PositioningMatrixG11 : '',
        PositioningMatrixH11 : '',
        PositioningMatrixI11 : '',
        PositioningMatrixJ11 : '',
        PositioningMatrixE12 : '',
        PositioningMatrixF12 : '',
        PositioningMatrixG12 : '',
        PositioningMatrixH12 : '',
        PositioningMatrixI12 : '',
        PositioningMatrixJ12 : '',
        PositioningMatrixE13 : '',
        PositioningMatrixF13 : '',
        PositioningMatrixG13 : '',
        PositioningMatrixH13 : '',
        PositioningMatrixI13 : '',
        PositioningMatrixJ13 : '',
        PositioningMatrixE14 : '',
        PositioningMatrixF14 : '',
        PositioningMatrixG14 : '',
        PositioningMatrixH14 : '',
        PositioningMatrixI14 : '',
        PositioningMatrixJ14 : '',
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getPositioningMatrix({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.growthPlanning)
            if(response!= undefined  && response.data.growthPlanning){
                let growthPlanning = response.data.growthPlanning[0];
                console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key]
                    }
                }
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyPositioningMatrix({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        //window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }
    
    return (
        <div className="content-body">
            <UserDropdown />
            {/* row */}
           <div className="container-fluid">
                <div className="head-title">
                    <h2>Positioning Matrix</h2>		
                    <p>Positioning&nbsp;is the way the market thinks about your brand. What makes your brand distinct? How do your products and services uniquely serve customer needs, and why would customers be attracted to them? &nbsp;Answering these questions will help you define your brand’s market position and target customer segments. From there, evaluate how your brand’s current position differs from its desired position and clarify what must change in order to hone and solidify your brand identity. &nbsp;A clear market position goes hand-in-hand with a strong brand identity, which strengthens the connection between the brand and the customer.</p>
                </div>
                <Spinner />
                <div className="card">
                    <div className="card-body pl-0 pr-0">
                    <div className="table-responsive">         			
                        <table className="table table-striped table-responsive-md">            				
                        <thead>
                            <tr>
                            <th colSpan={2} />
                            <th className="bluetab">Your Company</th>
                            <th className="bluetab">Competitor A</th>
                            <th className="bluetab">Competitor B</th>
                            <th className="bluetab">Competitor C</th>
                            <th className="bluetab">Competitor D</th>
                            <th className="bluetab">Competitor E</th>
                            </tr> 
                        </thead>            				
                        <tbody>             				               					         					
                            <tr>
                            <td className="bluetab text-center-c padding-10 width-5">
                                <div className="positing-icon-div" style={{width: '4em'}}>
                                <img src="../images/positing-matrix/icon1.png" className="img-fluid" alt="icon1" />
                                </div>
                            </td>
                            <td className="bgradiusf">Target Customers</td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="PositioningMatrixE11"
                                    value={Inputs.PositioningMatrixE11}
                                    onChange={(event) => setInputs({ ...Inputs, PositioningMatrixE11: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="PositioningMatrixF11"
                                    value={Inputs.PositioningMatrixF11}
                                    onChange={(event) => setInputs({ ...Inputs, PositioningMatrixF11: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="PositioningMatrixG11"
                                    value={Inputs.PositioningMatrixG11}
                                    onChange={(event) => setInputs({ ...Inputs, PositioningMatrixG11: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="PositioningMatrixH11"
                                    value={Inputs.PositioningMatrixH11}
                                    onChange={(event) => setInputs({ ...Inputs, PositioningMatrixH11: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="PositioningMatrixI11"
                                    value={Inputs.PositioningMatrixI11}
                                    onChange={(event) => setInputs({ ...Inputs, PositioningMatrixI11: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="PositioningMatrixJ11"
                                    value={Inputs.PositioningMatrixJ11}
                                    onChange={(event) => setInputs({ ...Inputs, PositioningMatrixJ11: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            </tr>
                            <tr>
                            <td className="bluetab text-center-c padding-10 width-5">
                                <div className="positing-icon-div">
                                <img src="../images/positing-matrix/icon2.png" className="img-fluid" alt="icon2" />
                                </div>
                            </td>
                            <td className="bgradiusf">Key Benefits</td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="PositioningMatrixE12"
                                    value={Inputs.PositioningMatrixE12}
                                    onChange={(event) => setInputs({ ...Inputs, PositioningMatrixE12: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="PositioningMatrixF12"
                                    value={Inputs.PositioningMatrixF12}
                                    onChange={(event) => setInputs({ ...Inputs, PositioningMatrixF12: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="PositioningMatrixG12"
                                    value={Inputs.PositioningMatrixG12}
                                    onChange={(event) => setInputs({ ...Inputs, PositioningMatrixG12: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="PositioningMatrixH12"
                                    value={Inputs.PositioningMatrixH12}
                                    onChange={(event) => setInputs({ ...Inputs, PositioningMatrixH12: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="PositioningMatrixI12"
                                    value={Inputs.PositioningMatrixI12}
                                    onChange={(event) => setInputs({ ...Inputs, PositioningMatrixI12: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="PositioningMatrixJ12"
                                    value={Inputs.PositioningMatrixJ12}
                                    onChange={(event) => setInputs({ ...Inputs, PositioningMatrixJ12: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            </tr>
                            <tr>
                            <td className="bluetab text-center-c padding-10 width-5">
                                <div className="positing-icon-div">
                                <img src="../images/positing-matrix/icon3.png" className="img-fluid" alt="icon3" />
                                </div>
                            </td>
                            <td className="bgradiusf">Pricing</td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="PositioningMatrixE13"
                                    value={Inputs.PositioningMatrixE13}
                                    onChange={(event) => setInputs({ ...Inputs, PositioningMatrixE13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="PositioningMatrixF13"
                                    value={Inputs.PositioningMatrixF13}
                                    onChange={(event) => setInputs({ ...Inputs, PositioningMatrixF13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="PositioningMatrixG13"
                                    value={Inputs.PositioningMatrixG13}
                                    onChange={(event) => setInputs({ ...Inputs, PositioningMatrixG13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="PositioningMatrixH13"
                                    value={Inputs.PositioningMatrixH13}
                                    onChange={(event) => setInputs({ ...Inputs, PositioningMatrixH13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="PositioningMatrixI13"
                                    value={Inputs.PositioningMatrixI13}
                                    onChange={(event) => setInputs({ ...Inputs, PositioningMatrixI13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="PositioningMatrixJ13"
                                    value={Inputs.PositioningMatrixJ13}
                                    onChange={(event) => setInputs({ ...Inputs, PositioningMatrixJ13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            </tr>
                            <tr>
                            <td className="bluetab text-center-c padding-10 width-5">
                                <div className="positing-icon-div">
                                <img src="../images/positing-matrix/icon4.png" className="img-fluid" alt="icon4" />
                                </div>
                            </td>
                            <td className="bgradiusf">Value Proposition</td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="PositioningMatrixE14"
                                    value={Inputs.PositioningMatrixE14}
                                    onChange={(event) => setInputs({ ...Inputs, PositioningMatrixE14: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="PositioningMatrixF14"
                                    value={Inputs.PositioningMatrixF14}
                                    onChange={(event) => setInputs({ ...Inputs, PositioningMatrixF14: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="PositioningMatrixG14"
                                    value={Inputs.PositioningMatrixG14}
                                    onChange={(event) => setInputs({ ...Inputs, PositioningMatrixG14: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="PositioningMatrixH14"
                                    value={Inputs.PositioningMatrixH14}
                                    onChange={(event) => setInputs({ ...Inputs, PositioningMatrixH14: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="PositioningMatrixI14"
                                    value={Inputs.PositioningMatrixI14}
                                    onChange={(event) => setInputs({ ...Inputs, PositioningMatrixI14: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="PositioningMatrixJ14"
                                    value={Inputs.PositioningMatrixJ14}
                                    onChange={(event) => setInputs({ ...Inputs, PositioningMatrixJ14: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            </tr>                                
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>
                </div>

        </div>

    )
}
