import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getInnovateOrOptimize, modifyInnovateOrOptimize } from "../../state/features/InnovateOrOptimizeSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';

export default function InnovateOrOptimizeContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        InnovateOrOptimizeD9 : '',
        InnovateOrOptimizeC13 : '',
        InnovateOrOptimizeC14 : '',
        InnovateOrOptimizeC15 : '',
        InnovateOrOptimizeC16 : '',
        InnovateOrOptimizeC17 : '',
        InnovateOrOptimizeC18 : '',
        InnovateOrOptimizeD13 : '',
        InnovateOrOptimizeD14 : '',
        InnovateOrOptimizeD15 : '',
        InnovateOrOptimizeD16 : '',
        InnovateOrOptimizeD17 : '',
        InnovateOrOptimizeD18 : '',
        InnovateOrOptimizeE13 : '',
        InnovateOrOptimizeE14 : '',
        InnovateOrOptimizeE15 : '',
        InnovateOrOptimizeE16 : '',
        InnovateOrOptimizeE17 : '',
        InnovateOrOptimizeE18 : '',
        InnovateOrOptimizeF13 : '',
        InnovateOrOptimizeF14 : '',
        InnovateOrOptimizeF15 : '',
        InnovateOrOptimizeF16 : '',
        InnovateOrOptimizeF17 : '',
        InnovateOrOptimizeF18 : '',
        InnovateOrOptimizeG13 : '',
        InnovateOrOptimizeG14 : '',
        InnovateOrOptimizeG15 : '',
        InnovateOrOptimizeG16 : '',
        InnovateOrOptimizeG17 : '',
        InnovateOrOptimizeG18 : ''
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getInnovateOrOptimize({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.growthPlanning)
            if(response!= undefined  && response.data.growthPlanning){
                let growthPlanning = response.data.growthPlanning[0];
                console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key]
                    }
                }
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyInnovateOrOptimize({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }
    
  return (
    <div className="content-body">
    <UserDropdown />
    
        {/* row */}
        <div className="container-fluid">
            <div className="head-title">
                <h2>Innovate Or Optimize</h2>	            					
            </div>
            <Spinner />
            <div className="card">
                <div className="card-body contentleft pl-0">
                <div className="table-responsive typetext">
                    <table className="table">
                    <thead>
                        <tr className="thead-primary">
                        <th>Activity</th>
                        <th><input type="text"
                            id="InnovateOrOptimizeD9"
                            value={Inputs.InnovateOrOptimizeD9}
                            onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeD9: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                             /></th>
                        </tr>
                    </thead>
                    </table>
                </div>
                <div className="table-responsive">
                    <table className="table">
                    <thead>
                        <tr>
                        <th />                               
                        <th />                               
                        <th className="bgradius">Optimize</th>                               
                        <th className="bgradius">Innovate</th>                               
                        <th />                               
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="thead-primary">
                        <th>What Worked</th>
                        <th>What Didn't Work</th>
                        <th><span>Can the activitybe easily &amp; inexpensively optimized for dezired results?</span></th>
                        <th><span>Innovate if optimazation is expensive , difficult, or time prohibitive.</span></th>
                        <th>Ideas to achieve Desired Improvement</th>
                        </tr>
                        <tr>
                        <td><input type="text" className="inputText"
                            id="InnovateOrOptimizeC13"
                            value={Inputs.InnovateOrOptimizeC13}
                            onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeC13: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                             /></td>
                        <td><input type="text" className="inputText"
                            id="InnovateOrOptimizeD13"
                            value={Inputs.InnovateOrOptimizeD13}
                            onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeD13: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                             /></td>
                        <td className="bgradiusf"><input type="text" className="inputTextf"
                            id="InnovateOrOptimizeE13"
                            value={Inputs.InnovateOrOptimizeE13}
                            onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeE13: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                             /></td>
                        <td className="bgradiusf"><input type="text" className="inputTextf"
                            id="InnovateOrOptimizeF13"
                            value={Inputs.InnovateOrOptimizeF13}
                            onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeF13: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                             /></td>
                        <td><input type="text" className="inputText"
                            id="InnovateOrOptimizeG13"
                            value={Inputs.InnovateOrOptimizeG13}
                            onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeG13: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                             /></td>
                        </tr>
                        <tr>
                        <td><input type="text" className="inputText"
                            id="InnovateOrOptimizeC14"
                            value={Inputs.InnovateOrOptimizeC14}
                            onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeC14: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                             /></td>
                        <td><input type="text" className="inputText"
                            id="InnovateOrOptimizeD14"
                            value={Inputs.InnovateOrOptimizeD14}
                            onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeD14: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                             /></td>
                        <td className="bgradiusf"><input type="text" className="inputTextf"
                            id="InnovateOrOptimizeE14"
                            value={Inputs.InnovateOrOptimizeE14}
                            onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeE14: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                             /></td>
                        <td className="bgradiusf"><input type="text" className="inputTextf"
                            id="InnovateOrOptimizeF14"
                            value={Inputs.InnovateOrOptimizeF14}
                            onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeF14: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                             /></td>
                        <td><input type="text" className="inputText"
                            id="InnovateOrOptimizeG14"
                            value={Inputs.InnovateOrOptimizeG14}
                            onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeG14: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                             /></td>
                        </tr>
                        <tr>
                        <td><input type="text" className="inputText"
                            id="InnovateOrOptimizeC15"
                            value={Inputs.InnovateOrOptimizeC15}
                            onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeC15: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                             /></td>
                        <td><input type="text" className="inputText"
                            id="InnovateOrOptimizeD15"
                            value={Inputs.InnovateOrOptimizeD15}
                            onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeD15: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                             /></td>
                        <td className="bgradiusf"><input type="text" className="inputTextf"
                            id="InnovateOrOptimizeE15"
                            value={Inputs.InnovateOrOptimizeE15}
                            onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeE15: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                             /></td>
                        <td className="bgradiusf"><input type="text" className="inputTextf"
                            id="InnovateOrOptimizeF15"
                            value={Inputs.InnovateOrOptimizeF15}
                            onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeF15: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                             /></td>
                        <td><input type="text" className="inputText"
                            id="InnovateOrOptimizeG15"
                            value={Inputs.InnovateOrOptimizeG15}
                            onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeG15: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                             /></td>
                        </tr>
                        <tr>
                        <td><input type="text" className="inputText"
                            id="InnovateOrOptimizeC16"
                            value={Inputs.InnovateOrOptimizeC16}
                            onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeC16: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                             /></td>
                        <td><input type="text" className="inputText"
                            id="InnovateOrOptimizeD16"
                            value={Inputs.InnovateOrOptimizeD16}
                            onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeD16: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                             /></td>
                        <td className="bgradiusf"><input type="text" className="inputTextf"
                            id="InnovateOrOptimizeE16"
                            value={Inputs.InnovateOrOptimizeE16}
                            onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeE16: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                             /></td>
                        <td className="bgradiusf"><input type="text" className="inputTextf"
                            id="InnovateOrOptimizeF16"
                            value={Inputs.InnovateOrOptimizeF16}
                            onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeF16: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                             /></td>
                        <td><input type="text" className="inputText"
                            id="InnovateOrOptimizeG16"
                            value={Inputs.InnovateOrOptimizeG16}
                            onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeG16: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                             /></td>
                        </tr>
                        <tr>
                        <td><input type="text" className="inputText"
                            id="InnovateOrOptimizeC17"
                            value={Inputs.InnovateOrOptimizeC17}
                            onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeC17: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                             /></td>
                        <td><input type="text" className="inputText"
                            id="InnovateOrOptimizeD17"
                            value={Inputs.InnovateOrOptimizeD17}
                            onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeD17: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                             /></td>
                        <td className="bgradiusf"><input type="text" className="inputTextf"
                            id="InnovateOrOptimizeE17"
                            value={Inputs.InnovateOrOptimizeE17}
                            onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeE17: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                             /></td>
                        <td className="bgradiusf"><input type="text" className="inputTextf"
                            id="InnovateOrOptimizeF17"
                            value={Inputs.InnovateOrOptimizeF17}
                            onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeF17: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                             /></td>
                        <td><input type="text" className="inputText"
                            id="InnovateOrOptimizeG17"
                            value={Inputs.InnovateOrOptimizeG17}
                            onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeG17: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                             /></td>
                        </tr>
                        <tr>
                        <td><input type="text" className="inputText"
                            id="InnovateOrOptimizeC18"
                            value={Inputs.InnovateOrOptimizeC18}
                            onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeC18: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                             /></td>
                        <td><input type="text" className="inputText"
                            id="InnovateOrOptimizeD18"
                            value={Inputs.InnovateOrOptimizeD18}
                            onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeD18: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                             /></td>
                        <td className="bgradiusf"><input type="text" className="inputTextf"
                            id="InnovateOrOptimizeE18"
                            value={Inputs.InnovateOrOptimizeE18}
                            onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeE18: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                             /></td>
                        <td className="bgradiusf"><input type="text" className="inputTextf"
                            id="InnovateOrOptimizeF18"
                            value={Inputs.InnovateOrOptimizeF18}
                            onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeF18: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                             /></td>
                        <td><input type="text" className="inputText"
                            id="InnovateOrOptimizeG18"
                            value={Inputs.InnovateOrOptimizeG18}
                            onChange={(event) => setInputs({ ...Inputs, InnovateOrOptimizeG18: event.target.value }) }
                            onBlur={(event) => { onBlurHandler(event) }}
                            disabled={isLoading}
                             /></td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>   
            </div>
            </div>

    </div>

  )
}
