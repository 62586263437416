import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getYearGrowthPlanning, modifyYearGrowthPlanning } from "../../state/features/growthPlanningSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import { Link } from 'react-router-dom';

const ReverseEngineeringTheFutureContentNew = () => {

    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        tenYearPlanningC12 : '',
        tenYearPlanningC13 : '',
        tenYearPlanningC14 : '',
        tenYearPlanningC15 : '',
        tenYearPlanningC16 : '',
        tenYearPlanningC18 : '',
        tenYearPlanningC19 : '',
        tenYearPlanningC20 : '',
        tenYearPlanningC21 : '',
        tenYearPlanningC22 : '',
        fiveYearPlanningE12 : '',
        fiveYearPlanningE13 : '',
        fiveYearPlanningE14 : '',
        fiveYearPlanningE15 : '',
        fiveYearPlanningE16 : '',
        fiveYearPlanningE18 : '',
        fiveYearPlanningE19 : '',
        fiveYearPlanningE20 : '',
        fiveYearPlanningE21 : '',
        fiveYearPlanningE22 : '',
        threeYearPlanningG12 : '',
        threeYearPlanningG13 : '',
        threeYearPlanningG14 : '',
        threeYearPlanningG15 : '',
        threeYearPlanningG16 : '',
        threeYearPlanningG18 : '',
        threeYearPlanningG19 : '',
        threeYearPlanningG20 : '',
        threeYearPlanningG21 : '',
        threeYearPlanningG22 : '',
        oneYearPlanningI12 : '',
        oneYearPlanningI13 : '',
        oneYearPlanningI14 : '',
        oneYearPlanningI15 : '',
        oneYearPlanningI16 : '',
        oneYearPlanningI18 : '',
        oneYearPlanningI19 : '',
        oneYearPlanningI20 : '',
        oneYearPlanningI21 : '',
        oneYearPlanningI22 : ''
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getYearGrowthPlanning({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.growthPlanning)
            if(response!= undefined){
                let growthPlanning = response.data.growthPlanning[0];
                console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key]
                    }
                }
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyYearGrowthPlanning({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                    // console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
            {/* row */}
            <div className="container-fluid">
                <div className="head-title">
                    <h3 className='preheading'>Grow</h3>
                    <h1 className='mainheading mb-1'>Reverse Engineering the Future</h1>
                    
                </div>
                <Spinner/>
                <div className="reverser-engineering-planning">
                    <div className="reverser-engineering-planning-inner">
                        <div className="table-responsive">
                            {/* Reverse Growth planning table 01 */}
                            <table className="table" style={{width: 1350}}>
                                <thead>
                                    <tr>
                                        <td colSpan={5}>
                                        <p className='body-text font12px color-dark'><strong>Reverse Engineering the Future</strong> is an alignment tool that helps ensure that your short-term goals and plans are in alignment with your mid-term and long-term goals.</p>
                                        </td>
                                    </tr>
                                    <tr className='pere-graph-fist-tr'>
                                        <td className='black-bg' style={{width: 300}}>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <p className='ms-text-left font18px text-white mb-0'>10-Year Goals</p>
                                                <img src={window.location.origin+"/images/reverse-engineering/image688.png"}  alt="img_01" style={{width:40, height: 40}}/>
                                            </div>
                                        </td>
                                        <td className='bg-color-white text-white' style={{width: 50}}>
                                            <div id='pointer'></div>
                                        </td>
                                        <td className='black-bg' style={{width: 300}}>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <p className='ms-text-left font18px text-white mb-0'>5-Year Goals</p>
                                                <img src={window.location.origin+"/images/reverse-engineering/image692.png"}  alt="img_01" style={{width:40, height: 40}}/>
                                            </div>
                                        </td>
                                        <td className='bg-color-white text-white' style={{width: 50}}>
                                            <div id='pointer'></div>
                                        </td>
                                        <td className='black-bg' style={{width: 300}}>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <p className='ms-text-left font18px text-white mb-0'>3-Year Goals</p>
                                                <img src={window.location.origin+"/images/reverse-engineering/image695.png"}  alt="img_01" style={{width:40, height: 40}}/>
                                            </div>
                                        </td>
                                        <td className='bg-color-white text-white' style={{width: 50}}>
                                            <div id='pointer'></div>
                                        </td>
                                        <td className='black-bg' style={{width: 300}}>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <p className='ms-text-left font18px text-white mb-0'>1-Year Goals</p>
                                                <img src={window.location.origin+"/images/reverse-engineering/image697.png"}  alt="img_01" style={{width:40, height: 40}}/>
                                            </div>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="tenYearPlanningC12"
                                                value={Inputs.tenYearPlanningC12}
                                                onChange={(event) => setInputs({ ...Inputs, tenYearPlanningC12: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>                                                
                                        </td>
                                        <td className='hide-content'>hide</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="fiveYearPlanningE12"
                                                value={Inputs.fiveYearPlanningE12}
                                                onChange={(event) => setInputs({ ...Inputs, fiveYearPlanningE12: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>                                                
                                        </td>
                                        <td className='hide-content'>hide</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="threeYearPlanningG12"
                                                value={Inputs.threeYearPlanningG12}
                                                onChange={(event) => setInputs({ ...Inputs, threeYearPlanningG12: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>                                                
                                        </td>
                                        <td className='hide-content'>hide</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="oneYearPlanningI12"
                                                value={Inputs.oneYearPlanningI12}
                                                onChange={(event) => setInputs({ ...Inputs, oneYearPlanningI12: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div>                                                
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="tenYearPlanningC13"
                                                value={Inputs.tenYearPlanningC13}
                                                onChange={(event) => setInputs({ ...Inputs, tenYearPlanningC13: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div> 
                                        </td>
                                        <td className='hide-content'>hide</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="fiveYearPlanningE13"
                                                value={Inputs.fiveYearPlanningE13}
                                                onChange={(event) => setInputs({ ...Inputs, fiveYearPlanningE13: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div> 
                                        </td>
                                        <td className='hide-content'>hide</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="threeYearPlanningG13"
                                                value={Inputs.threeYearPlanningG13}
                                                onChange={(event) => setInputs({ ...Inputs, threeYearPlanningG13: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div> 
                                        </td>
                                        <td className='hide-content'>hide</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="oneYearPlanningI13"
                                                value={Inputs.oneYearPlanningI13}
                                                onChange={(event) => setInputs({ ...Inputs, oneYearPlanningI13: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div> 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="tenYearPlanningC14"
                                                value={Inputs.tenYearPlanningC14}
                                                onChange={(event) => setInputs({ ...Inputs, tenYearPlanningC14: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div> 
                                        </td>
                                        <td className='hide-content'>hide</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="fiveYearPlanningE14"
                                                value={Inputs.fiveYearPlanningE14}
                                                onChange={(event) => setInputs({ ...Inputs, fiveYearPlanningE14: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div> 
                                        </td>
                                        <td className='hide-content'>hide</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="threeYearPlanningG14"
                                                value={Inputs.threeYearPlanningG14}
                                                onChange={(event) => setInputs({ ...Inputs, threeYearPlanningG14: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div> 
                                        </td>
                                        <td className='hide-content'>hide</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="oneYearPlanningI14"
                                                value={Inputs.oneYearPlanningI14}
                                                onChange={(event) => setInputs({ ...Inputs, oneYearPlanningI14: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div> 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="tenYearPlanningC15"
                                                value={Inputs.tenYearPlanningC15}
                                                onChange={(event) => setInputs({ ...Inputs, tenYearPlanningC15: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div> 
                                        </td>
                                        <td className='hide-content'>hide</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="fiveYearPlanningE15"
                                                value={Inputs.fiveYearPlanningE15}
                                                onChange={(event) => setInputs({ ...Inputs, fiveYearPlanningE15: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div> 
                                        </td>
                                        <td className='hide-content'>hide</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="threeYearPlanningG15"
                                                value={Inputs.threeYearPlanningG15}
                                                onChange={(event) => setInputs({ ...Inputs, threeYearPlanningG15: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div> 
                                        </td>
                                        <td className='hide-content'>hide</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="oneYearPlanningI15"
                                                value={Inputs.oneYearPlanningI15}
                                                onChange={(event) => setInputs({ ...Inputs, oneYearPlanningI15: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div> 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="tenYearPlanningC16"
                                                value={Inputs.tenYearPlanningC16}
                                                onChange={(event) => setInputs({ ...Inputs, tenYearPlanningC16: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div> 
                                        </td>
                                        <td className='hide-content'>hide</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="fiveYearPlanningE16"
                                                value={Inputs.fiveYearPlanningE16}
                                                onChange={(event) => setInputs({ ...Inputs, fiveYearPlanningE16: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div> 
                                        </td>
                                        <td className='hide-content'>hide</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="threeYearPlanningG16"
                                                value={Inputs.threeYearPlanningG16}
                                                onChange={(event) => setInputs({ ...Inputs, threeYearPlanningG16: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div> 
                                        </td>
                                        <td className='hide-content'>hide</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="oneYearPlanningI16"
                                                value={Inputs.oneYearPlanningI16}
                                                onChange={(event) => setInputs({ ...Inputs, oneYearPlanningI16: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div> 
                                        </td>
                                    </tr>
                                </tbody>
                                <thead>
                                    <tr className='pere-graph-fist-tr'>
                                        <th className='Blue-gray-bg text-white font14px'>
                                            Key Results
                                        </th>
                                        <th className='bg-color-white text-white'>
                                            <div className="width-list">
                                                <div id='pointer'></div>
                                            </div>
                                        </th>
                                        <th className='Blue-gray-bg text-white font14px'>
                                            Key Results
                                        </th>
                                        <th className='bg-color-white text-white'>
                                            <div className="width-list">
                                                <div id='pointer'></div>
                                            </div>
                                        </th>
                                        <th className='Blue-gray-bg text-white font14px'>
                                            Key Results
                                        </th>
                                        <th className='bg-color-white text-white'>
                                            <div className="width-list">
                                                <div id='pointer'></div>
                                            </div>
                                        </th>
                                        <th className='Blue-gray-bg text-white font14px'>
                                            Key Results
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="tenYearPlanningC18"
                                                value={Inputs.tenYearPlanningC18}
                                                onChange={(event) => setInputs({ ...Inputs, tenYearPlanningC18: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div> 
                                        </td>
                                        <td className='hide-content'>hide</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="fiveYearPlanningE18"
                                                value={Inputs.fiveYearPlanningE18}
                                                onChange={(event) => setInputs({ ...Inputs, fiveYearPlanningE18: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div> 
                                        </td>
                                        <td className='hide-content'>hide</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="threeYearPlanningG18"
                                                value={Inputs.threeYearPlanningG18}
                                                onChange={(event) => setInputs({ ...Inputs, threeYearPlanningG18: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div> 
                                        </td>
                                        <td className='hide-content'>hide</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="oneYearPlanningI18"
                                                value={Inputs.oneYearPlanningI18}
                                                onChange={(event) => setInputs({ ...Inputs, oneYearPlanningI18: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div> 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="tenYearPlanningC19"
                                                value={Inputs.tenYearPlanningC19}
                                                onChange={(event) => setInputs({ ...Inputs, tenYearPlanningC19: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div> 
                                        </td>
                                        <td className='hide-content'>hide</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="fiveYearPlanningE19"
                                                value={Inputs.fiveYearPlanningE19}
                                                onChange={(event) => setInputs({ ...Inputs, fiveYearPlanningE19: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div> 
                                        </td>
                                        <td className='hide-content'>hide</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="threeYearPlanningG19"
                                                value={Inputs.threeYearPlanningG19}
                                                onChange={(event) => setInputs({ ...Inputs, threeYearPlanningG19: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div> 
                                        </td>
                                        <td className='hide-content'>hide</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="oneYearPlanningI19"
                                                value={Inputs.oneYearPlanningI19}
                                                onChange={(event) => setInputs({ ...Inputs, oneYearPlanningI19: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div> 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="tenYearPlanningC20"
                                                value={Inputs.tenYearPlanningC20}
                                                onChange={(event) => setInputs({ ...Inputs, tenYearPlanningC20: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div> 
                                        </td>
                                        <td className='hide-content'>hide</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="fiveYearPlanningE20"
                                                value={Inputs.fiveYearPlanningE20}
                                                onChange={(event) => setInputs({ ...Inputs, fiveYearPlanningE20: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div> 
                                        </td>
                                        <td className='hide-content'>hide</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="threeYearPlanningG20"
                                                value={Inputs.threeYearPlanningG20}
                                                onChange={(event) => setInputs({ ...Inputs, threeYearPlanningG20: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div> 
                                        </td>
                                        <td className='hide-content'>hide</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="oneYearPlanningI20"
                                                value={Inputs.oneYearPlanningI20}
                                                onChange={(event) => setInputs({ ...Inputs, oneYearPlanningI20: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div> 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="tenYearPlanningC21"
                                                value={Inputs.tenYearPlanningC21}
                                                onChange={(event) => setInputs({ ...Inputs, tenYearPlanningC21: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div> 
                                        </td>
                                        <td className='hide-content'>hide</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="fiveYearPlanningE21"
                                                value={Inputs.fiveYearPlanningE21}
                                                onChange={(event) => setInputs({ ...Inputs, fiveYearPlanningE21: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div> 
                                        </td>
                                        <td className='hide-content'>hide</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="threeYearPlanningG21"
                                                value={Inputs.threeYearPlanningG21}
                                                onChange={(event) => setInputs({ ...Inputs, threeYearPlanningG21: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div> 
                                        </td>
                                        <td className='hide-content'>hide</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="oneYearPlanningI21"
                                                value={Inputs.oneYearPlanningI21}
                                                onChange={(event) => setInputs({ ...Inputs, oneYearPlanningI21: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div> 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="tenYearPlanningC22"
                                                value={Inputs.tenYearPlanningC22}
                                                onChange={(event) => setInputs({ ...Inputs, tenYearPlanningC22: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div> 
                                        </td>
                                        <td className='hide-content'>hide</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="fiveYearPlanningE22"
                                                value={Inputs.fiveYearPlanningE22}
                                                onChange={(event) => setInputs({ ...Inputs, fiveYearPlanningE22: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div> 
                                        </td>
                                        <td className='hide-content'>hide</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="threeYearPlanningG22"
                                                value={Inputs.threeYearPlanningG22}
                                                onChange={(event) => setInputs({ ...Inputs, threeYearPlanningG22: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div> 
                                        </td>
                                        <td className='hide-content'>hide</td>
                                        <td className='bg-color-light-blue padding-0'>
                                            <div className="input-fiel-cn bg-color-light-blue">
                                                <textarea type="text" className="field-ipt bg-color-light-blue font11px color-dark"
                                                id="oneYearPlanningI22"
                                                value={Inputs.oneYearPlanningI22}
                                                onChange={(event) => setInputs({ ...Inputs, oneYearPlanningI22: event.target.value }) }
                                                onBlur={(event) => { onBlurHandler(event) }}
                                                disabled={isLoading} />
                                            </div> 
                                        </td>
                                    </tr>
                                </tbody>
                                    {/* colunm second table */}
                            </table>
                            {/* Reverse Growth planning table 01 */}
                        </div>
                        <div className="nextInput">
                            <ul>
                                <li>
                                    <Link to="/" className='next-link'> Next:  Sustained Growth Planning Calendar  
                                        <span className='icon-three'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                        </span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReverseEngineeringTheFutureContentNew;