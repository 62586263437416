import React from 'react';
import { Link, useLocation } from "react-router-dom";
import  UserDropdown  from '../UserDropdown';
import CopyRightText from '../CopyRightText';
import {useSWRConfig} from "swr";
import getMenuChildren from '../../utils/getMenuChildren';
import MenuSingleList from '../List/MenuSingleList';
import MenuMultiList from '../List/MenuMultiList';

const DiagnoseContent = () => {
    const { fallback, isLoading } = useSWRConfig();
    const location = useLocation();
    const submenus = getMenuChildren(location.pathname, fallback?.menus[0].children)
    return (
        <div className="content-body bg-dard-color bg_bottom">
            <UserDropdown />
            {/* row */}
            <div className="container-fluid">
                <div className="head-title">
                    <Link to="/home"><h3 className='font-sise-20'>The Growth Catalyst</h3></Link>
                    <h1 className='font-sise-42 the-revense'>Diagnose</h1>
                    <div className='row'>
                        <div className="col-12 col-md-8 col-lg-10">
                            <div className="leftContetn">
                                <p className='font-sise-16'>
                                    Collect the quantitative and qualitative data needed to build your sustainable, predictable growth strategy.
                                </p>
                                <ul className='list-of-data'>
                                    <li className='font-sise-16'>Pre-benchmark data is required to perform accurate calculations and predictive level of benefit.</li>
                                    <li className='font-sise-16'>Benchmark questions compare your performance and capabilities to best practice.</li>
                                    <li className='font-sise-16'>Benchmark tools help compare your performance to competitors.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-2">
                            <div className="imageBx">
                                <img src={window.location.origin+"/images/Home_page_img/Picture2.png"} width={150 + 'px'} height={150 + 'px'} alt="Revenue Growth Drivers" title="Revenue Growth Drivers" />
                            </div>
                        </div>
                    </div>
                    <div className="row card-rwo diagnose-page mt-5" style={{maxWidth: 1400}}>
                        {submenus.map((menu, index) => (
                            <div className={`col-12 col-md-6 ${(menu.children.length < 9)? 'col-lg-3' : ''} mar-b`} key={menu.parent_id+"_"+index}>
                                <div className="diagnosediv prescribe-hg">
                                    <h3 className='font-sise-bold-18'>
                                        <Link to="#">{menu.label}</Link>
                                    </h3>
                                    {
                                        (menu.children.length < 9)?
                                        <MenuSingleList submenu={menu.children} id={menu.parent_id+"_"+index}/>
                                        :
                                        <MenuMultiList submenu={menu.children} id={menu.parent_id+"_"+index}/>
                                    }
                                </div>
                            </div>
                        ))}
                        {/* <div className="col-12 col-md-6 col-lg-3 mar-b">
                            <div className="diagnosediv">
                                <h3 className='font-sise-bold-18'>
                                    <Link to="#">Pre-Benchmark Data</Link>
                                </h3>
                                <ul className='list-of-data'>
                                    <li className='font-sise-16'><Link to="/diagnose/pre-benchmark-survey">Pre-Benchmark Survey</Link></li>
                                    <li className='font-sise-16'><Link to="/diagnose/client-inputs">Client Inputs</Link></li>
                                    <li className='font-sise-16'><Link to="/diagnose/customer-financial-value">Customer Financial Values</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mar-b">
                            <div className="diagnosediv">
                                <h3 className='font-sise-bold-18'>
                                    <Link to="#">Benchmark Questions</Link>
                                </h3>
                                <ul className='list-of-data'>
                                    
                                    <li className='font-sise-16'><Link to="/diagnose/competitive-advantage">Competitive Advantage</Link></li>
                                    <li className='font-sise-16'><Link to="/diagnose/financial-performance">Financial Performance</Link></li>
                                    <li className='font-sise-16'><Link to="/diagnose/customer-acquisition">Customer Acquisition</Link></li>
                                    <li className='font-sise-16'><Link to="/diagnose/customer-retention">Customer Retention</Link></li>
                                    <li className='font-sise-16'><Link to="/diagnose/customer-transactions">Customer Transactions</Link></li>
                                    <li className='font-sise-16'><Link to="/diagnose/agility">Agility Engine</Link></li>
                                    <li className='font-sise-16'><Link to="/diagnose/enterprise-value">Enterprise Value</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 mar-b">
                            <div className="diagnosediv">
                                <h3 className='font-sise-bold-18'>
                                    <Link to="#">Benchmarking Tools</Link>
                                </h3>
                                <div className="duble-list">
                                    <ul className='list-of-data'>
                                        <li className='font-sise-16'><Link to="/diagnose/target-markets-scorecard">Target Markets Scorecard</Link></li>
                                        <li className='font-sise-16'><Link to="/diagnose/competitive-advantage-audit">Competitive Advantage Audit</Link></li>
                                        <li className='font-sise-16'><Link to="/diagnose/innovative-offering-analysis">Innovative Offering Analysis</Link></li>
                                        <li className='font-sise-16'><Link to="/diagnose/positioning-scorecard">Positioning Scorecard</Link></li>
                                        <li className='font-sise-16'><Link to="/diagnose/alignment-assessment">Alignment Assessment</Link></li>
                                        <li className='font-sise-16'><Link to="/diagnose/customer-data-scorecard">Customer Data Scorecard</Link></li>
                                    </ul>
                                    <ul className='list-of-data'>
                                        <li className='font-sise-16'><Link to="/diagnose/positioning-scorecard">Positioning & Messaging Scorecard</Link></li>
                                        <li className='font-sise-16'><Link to="/diagnose/customer-acquisition-scorecard">Customer Acquisition Scorecard</Link></li>
                                        <li className='font-sise-16'><Link to="/diagnose/customer-retention-scorecard">Customer Retention Scorecard</Link></li>
                                        <li className='font-sise-16'><Link to="/diagnose/profit-margin-scorecard">Profit Margin Scorecard</Link></li>
                                        <li className='font-sise-16'><Link to="/diagnose/agility-engine-scorecard">Agility Engine Scorecard</Link></li>
                                        <li className='font-sise-16'><Link to="/diagnose/equity-evolution-scorecard">Equity Evolution Scorecard</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>

                <div className='copyRgight'>
                    <CopyRightText />
                </div>

                <div className='space-di'></div>

            </div>
        </div>
    );
}

export default DiagnoseContent;
