import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state/index';
import { BarChartGraph } from "../graph/BarChartGraph";
import  UserDropdown  from '../UserDropdown';
import { addEditImprovementPercentage } from "../../state/features/ClentInputSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { useAlert } from 'react-alert'
import { Spinner } from '../spinner'
import { Link } from 'react-router-dom';
import { StatesUseEffect } from '../../utils/StatesUseEffect';
import { BarChartGraphNew } from '../graph/BarChartGraphNew';

export default function ProfitMarginContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const users = useSelector(userData);
    const userID = users.data.profile.id;
    const { message } = useSelector((state) => state.message);

    const clientInputs = useSelector(state => state.clientInputs)
    const improvementPercentage = clientInputs.improvementPercentage
    const dispatch = useDispatch();
    const { profitMarginData } = bindActionCreators(actionCreators, dispatch)
    const profitMargin = useSelector(state => state.profitMargin)
    const [Inputs, setInputs] = useState({
        profitMarginD13: Number(profitMargin.profitMarginD13).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1}),
    });

    const { clientInputsH36 } = clientInputs
    let profitMarginD12 = parseFloat(clientInputsH36) ? parseFloat(clientInputsH36) : 0

    useEffect(() => {
        profitMarginData("profitMarginD12",profitMarginD12);
        profitMarginData("profitMarginD13",improvementPercentage.profitMarginD13);
    }, [])

    const { profitMarginD17, profitMarginE17, profitMarginF17, profitMarginG17, profitMarginH17, profitMarginI17, profitMarginD18, profitMarginE18, profitMarginF18, profitMarginG18, profitMarginH18, profitMarginI18, profitMarginD22, profitMarginE22, profitMarginF22, profitMarginG22, profitMarginH22, profitMarginI22, profitMarginD24, profitMarginE24, profitMarginF24, profitMarginG24, profitMarginH24, profitMarginI24, profitMarginD26, profitMarginE26, profitMarginF26, profitMarginG26, profitMarginH26, profitMarginI26 } = profitMargin //object destructuring for profitMargin

    const improvementPrcntHandler = (impPrcnt) => {
        setIsLoading(true);
        let sendData = {user_id : userID, profitMarginD13: impPrcnt}
        dispatch(addEditImprovementPercentage(sendData))
        .unwrap()
        .then((response) => {
          console.log("response",response)
            if(response!= undefined){
               console.log('success')
            }
            setIsLoading(false)   // Hide loading screen 
        })
        .catch((error) => {
          console.log("error",error)
            { message && alert.show(message) }
            setIsLoading(false)   // Hide loading screen 
        });
    }

    // call states useEffect utils
    StatesUseEffect()

    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
    
            {/* row */}
            <div className="container-fluid lead-generation-new">
                <div className="head-title mb-5">
                    <h3 className='preheading'>Prescribe - Growth Drivers</h3>
                    <h1 className='mainheading mb-1'>Profit Margin</h1>	
                </div>
                <Spinner />
                <div className="row lead-genearation-rw mb-5" style={{maxWidth: 1260}}>
                    <div className="col-12 col-lg-6">
                        {/* <div className="potential-growth-ld mb-3" style={{maxWidth: 510}}>
                            <h3><strong>Potential Growth Activities or Tactics:</strong></h3>
                            <ol>
                                <li>Activity 1</li>
                                <li>Activity 2</li>
                                <li>Activity 3</li>
                            </ol>
                        </div> */}
                        <div className="lead-generation-table mt-218" style={{overflowY: 'auto'}}>
                            <div className="responsive-table">
                                <table className='table' style={{width: 510}}>
                                    <thead>
                                        <tr>
                                            <th scope='col' className='black-bg text-white fontBold' style={{width: 360}}>Improving Profit Margin</th>
                                            <th className='black-bg hide-content' style={{width: 150}}>2</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td scope='row' className='bg-color-light-blue font12px color-black'>Gross Profit Margin</td>
                                            <td className='bg-color-light-blue font12px color-black text-right-rt'>{Number(profitMarginD12).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}%</td>
                                        </tr>
                                        <tr>
                                            <td scope='row' className='bg-color-light-blue font12px color-black'>Projected Improvement</td>
                                            <th scope='row' className='padding-0'>
                                                <div className="dollor-sin-pr">
                                                    <div className="input-fiel-cn bg-color-01 client-inpt-field">
                                                        <input type="text" 
                                                        name="Inputs.profitMarginD13"
                                                        className="field-ipt wthinherit font12px bg-color-01 text-right text-black" 
                                                        placeholder="0"
                                                        value={Inputs.profitMarginD13}
                                                        onChange={(event) => {
                                                            setInputs({ ...Inputs, profitMarginD13: event.target.value });
                                                            profitMarginData("profitMarginD13", event.target.value);
                                                            improvementPrcntHandler(event.target.value)
                                                        }}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        disabled={isLoading}
                                                    />
                                                    </div>
                                                    <div className="perecentage">%</div>
                                                </div>
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                                <Spinner />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6" style={{minHeight: 400}}>
                        <BarChartGraphNew dataInput={{
                            "Title"      : "Gross Profit Margin:  Impact on Financial Performance",
                            "ColorCodes" : ["#d6dce5", "#8497b0", "#1e2b3c"],
                            "GraphData"  : {
                                labels: [["Your","Projection"], "Year 1", "Year 2", "Year 3", "Year 4", "Year 5"],
                                datasets: [
                                    {
                                        label: "Profit",
                                        backgroundColor: "#8497b0",
                                        data: [Number(profitMarginD22),Number(profitMarginE22),Number(profitMarginF22),Number(profitMarginG22),Number(profitMarginH22),Number(profitMarginI22)]
                                    },
                                    {
                                        label: "Valuation",
                                        backgroundColor: "#1e2b3c",
                                        data: [Number(profitMarginD26),Number(profitMarginE26),Number(profitMarginF26),Number(profitMarginG26),Number(profitMarginH26),Number(profitMarginI26)]
                                    }
                                ]
                            }
                        }} />
                        {/* <div className="">
                            <h3 className='text-center impact-lead-titile'><strong>Gross Profit Margin:  Impact on Financial Performance</strong></h3>
                            <BarChartGraph dataInput={{
                                "Title"      : "",
                                "vTitle"     : "",
                                "hTitle"     : "",
                                "subTitle"   : "",
                                "ColorCodes" : ["#8497b0", "#44546a"],
                                "GraphData"  : [
                                    ["", "Profit", "Valuation"],
                                    ["Year 1", Number(profitMarginE22), Number(profitMarginE26)],
                                    ["Year 2", Number(profitMarginF22), Number(profitMarginF26)],
                                    ["Year 3", Number(profitMarginG22), Number(profitMarginG26)],
                                    ["Year 4", Number(profitMarginH22), Number(profitMarginH26)],
                                    ["Year 5", Number(profitMarginI22), Number(profitMarginI26)]
                                ]
                            }} />
                        </div> */}
                    </div>
                </div>
                <div className="card update-class-lead">
                    <div className="card-body pl-0">
                        <div className="table-responsive">
                            <table className="table table-responsive-md table-striped mb-0" style={{width: 1260}}>
                                <thead>
                                    <tr>
                                    <th className="table-primar" style={{width: 360}}/>
                                    <th colSpan={2} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Year 1 Gains</th>
                                    <th colSpan={2} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Mid-Term Gains</th>
                                    <th colSpan={5} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Long-Term Gains </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="thead-primary">
                                        <th className='black-bg fontBold' style={{width: 360}}>Improved Gross Profit Margin</th>
                                        <th className='black-bg' style={{width: 150}}>Your Projection</th>
                                        <th className='black-bg' style={{width: 150}}>1% Quarterly Improvement</th>
                                        <th className='black-bg' style={{width: 150}}>2 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>3 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>4 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>5 Years of 1% Improvements</th>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>Percent Improvement of Gross Profit Margin</td>
                                        <td className='bg-color-blue-gray text-white font12px'>
                                        {Number(profitMarginD17).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}% 
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'> {Number(profitMarginE17).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}% </td>
                                        <td className='bg-color-light-blue font12px color-black'> {Number(profitMarginF17).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}% </td>
                                        <td className='bg-color-light-blue font12px color-black'> {Number(profitMarginG17).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}% </td>
                                        <td className='bg-color-light-blue font12px color-black'> {Number(profitMarginH17).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}% </td>
                                        <td className='bg-color-light-blue font12px color-black'> {Number(profitMarginI17).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}% </td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black fontBold'> Potential New Gross Profit Margin</td>
                                        <td className='bg-color-blue-gray text-white font12px'>
                                        <strong>{Number(profitMarginD18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}%</strong> 
                                        </td>
                                        <td className='bg-color-teal text-white font12px'><strong> {Number(profitMarginE18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}%</strong> </td>
                                        <td className='bg-color-teal text-white font12px'><strong> {Number(profitMarginF18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}%</strong> </td>
                                        <td className='bg-color-teal text-white font12px'><strong> {Number(profitMarginG18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}%</strong> </td>
                                        <td className='bg-color-teal text-white font12px'><strong> {Number(profitMarginH18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}%</strong> </td>
                                        <td className='bg-color-teal text-white font12px'><strong> {Number(profitMarginI18).toLocaleString('en-US',{minimumFractionDigits: 1, maximumFractionDigits: 1})}%</strong> </td>
                                    </tr>
                                    </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="card update-class-lead">
                            <div className="card-body pl-0">
                            <div className="table-responsive">
                                <table className="table table-responsive-md table-striped mb-0" style={{width: 1260}}>
                                <thead>
                                    <tr>
                                    <th className='nowrap' style={{background:'#fff', width: 360}}><h3>Financial Improvements</h3></th>
                                    <th colSpan={2} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Year 1 Gains</th>
                                    <th colSpan={2} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Mid-Term Gains</th>
                                    <th colSpan={5} style={{textAlign: 'center', width: 300}} className="bg-color-blue-gray text-white fontBold">Long-Term Gains </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="thead-primary">
                                        <th className='black-bg fontBold' style={{width: 360}}>Annual Impact of Improving Gross Profit Margin</th>
                                        <th className='black-bg' style={{width: 150}}>Your Projection</th>
                                        <th className='black-bg' style={{width: 150}}>1% Quarterly Improvement</th>
                                        <th className='black-bg' style={{width: 150}}>2 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>3 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>4 Years of 1% Improvements</th>
                                        <th className='black-bg' style={{width: 150}}>5 Years of 1% Improvements</th>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>Annual Profit</td>
                                        <td className='bg-color-blue-gray text-white font12px'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px text-white"> $ </div>
                                                <div className="annual-dl-number font12px text-white">
                                                     {Number(profitMarginD22).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]} 
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(profitMarginE22).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(profitMarginF22).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(profitMarginG22).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(profitMarginH22).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(profitMarginI22).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="thead-primary">
                                        <th className='black-bg fontBold'>Lifetime Impact of Improving Gross Profit Margin</th>
                                        <th className='black-bg'>Your Projection</th>
                                        <th className='black-bg'>1% Quarterly Improvement</th>
                                        <th className='black-bg'>2 Years of 1% Improvements</th>
                                        <th className='black-bg'>3 Years of 1% Improvements</th>
                                        <th className='black-bg'>4 Years of 1% Improvements</th>
                                        <th className='black-bg'>5 Years of 1% Improvements</th>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>Lifetime Profit</td>
                                        <td className='bg-color-blue-gray text-white font12px'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px text-white"> $ </div>
                                                <div className="annual-dl-number font12px text-white">
                                                     {Number(profitMarginD24).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]} 
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(profitMarginE24).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(profitMarginF24).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(profitMarginG24).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(profitMarginH24).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(profitMarginI24).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="thead-primary">
                                        <th className='black-bg fontBold'>Valuation Impact of Improving Gross Profit Margin</th>
                                        <th className='black-bg'>Your Projection</th>
                                        <th className='black-bg'>1% Quarterly Improvement</th>
                                        <th className='black-bg'>2 Years of 1% Improvements</th>
                                        <th className='black-bg'>3 Years of 1% Improvements</th>
                                        <th className='black-bg'>4 Years of 1% Improvements</th>
                                        <th className='black-bg'>5 Years of 1% Improvements</th>
                                    </tr>
                                    <tr>
                                        <td className='bg-color-light-blue font12px color-black'>Estimated Valuation Impact</td>
                                        <td className='bg-color-blue-gray text-white font12px'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px text-white"> $ </div>
                                                <div className="annual-dl-number font12px text-white">
                                                     {Number(profitMarginD26).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]} 
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(profitMarginE26).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(profitMarginF26).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(profitMarginG26).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(profitMarginH26).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='bg-color-light-blue font12px color-black'>
                                            <div className="annual-dollor-sign">
                                                <div className="annual-dl font12px"> $ </div>
                                                <div className="annual-dl-number font12px">
                                                    {Number(profitMarginI26).toLocaleString('en-US', { style: 'currency', currency: 'USD'}).split('$')[1]}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="nextInput">
                            <ul>
                                <li>
                                    <Link to="/prescribe/the-compounding-effect" className='next-link'> Next: The Compounding Effect
                                        <span className='icon-three'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                        </span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
