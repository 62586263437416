import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import sum from "../../utils/sum";
import { Link } from 'react-router-dom';
import formatPrice from '../../utils/formatPrice';
import { getPositioningScorecard, modifyPositioningScorecard } from '../../state/features/PositioningScorecardSlice';

export default function PositioningScorecardContentNew() {

    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        PositioningScorecardD9: 0,
        PositioningScorecardD10: 0,
        PositioningScorecardD11: 0,
        PositioningScorecardD12: 0,
        PositioningScorecardD13: 0,
        PositioningScorecardE8: "",
        PositioningScorecardF9: "",
        PositioningScorecardD15: 0,
        PositioningScorecardD16: 0,
        PositioningScorecardD17: 0,
        PositioningScorecardD18: 0,
        PositioningScorecardD19: 0,
        PositioningScorecardE14: "",
        PositioningScorecardF15: "",
        PositioningScorecardE20: "",
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getPositioningScorecard({user_id}))
        .unwrap()
        .then((response) => {
            console.log("response",response.data)
            if(response!= undefined  && response.data.clientGrowth){
                let clientGrowth = response.data.clientGrowth[0];
                // console.log(clientGrowth)
                delete clientGrowth.id;
                delete clientGrowth.user_id;
                delete clientGrowth.created_at;
                delete clientGrowth.updated_at;
                const newObj = { ...Inputs }
                for (const key in clientGrowth) {
                    if (clientGrowth.hasOwnProperty(key)) {
                        if(clientGrowth[key])
                        newObj[key] = clientGrowth[key]
                    }
                }
                
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])
      
    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                event.target.value = event.target.value ? event.target.value : 0
                dispatch(modifyPositioningScorecard({ user_id, [event.target.id] : event.target.value, 'PositioningScorecardE8': String(Inputs.PositioningScorecardE8), 'PositioningScorecardE14': String(Inputs.PositioningScorecardE14), 'PositioningScorecardE20': String(Inputs.PositioningScorecardE20)}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        //window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }


    useEffect(() => {
        let PositioningScorecardE8 = sum(Inputs.PositioningScorecardD9, Inputs.PositioningScorecardD10, Inputs.PositioningScorecardD11, Inputs.PositioningScorecardD12, Inputs.PositioningScorecardD13);
        let PositioningScorecardE14 = sum(Inputs.PositioningScorecardD15, Inputs.PositioningScorecardD16, Inputs.PositioningScorecardD17, Inputs.PositioningScorecardD18, Inputs.PositioningScorecardD19);
        let PositioningScorecardE20 = PositioningScorecardE8 + PositioningScorecardE14;
        // console.log(Inputs.PositioningScorecardD9, Inputs.PositioningScorecardD10, Inputs.PositioningScorecardD11)
        setInputs({...Inputs, PositioningScorecardE8, PositioningScorecardE14, PositioningScorecardE20});

    }, [Inputs.PositioningScorecardD9, Inputs.PositioningScorecardD10, Inputs.PositioningScorecardD11, Inputs.PositioningScorecardD12, Inputs.PositioningScorecardD13, Inputs.PositioningScorecardD15, Inputs.PositioningScorecardD16, Inputs.PositioningScorecardD17, Inputs.PositioningScorecardD18, Inputs.PositioningScorecardD19]);

    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
            <div className="container-fluid">
                <div className="head-title">
                    <h3 className='preheading'>Diagnose - Benchmark Tools</h3>
                    <h1 className='mainheading'>Positioning Scorecard</h1>
                </div>
                <div className="target-markets-score-card-table">
                    <div className="table-responsive">
                        <table className="table" style={{width: 1400}}>
                            {/* table 01 */}
                            <thead>
                                <tr>
                                    <td className='black-bg text-white font14px' style={{width: 700, height: 70}}>
                                    Which of the following statements are true regarding your  marketing message?
                                    </td>
                                    <td className='black-bg text-white text-center' style={{width: 150}}>
                                        <span className='font14px'>Score</span><br/><span className='font11px'>(1 point each)</span>
                                    </td>
                                    <td className='bg-color-blue text-white text-center' style={{width: 50}}>
                                        <div className="do-you-number font14px">{formatPrice(Inputs.PositioningScorecardE8, 0)}</div>
                                    </td>
                                    <td className='black-bg text-white text-center font14px' style={{width: 500}}>
                                    Opportunities to Improve
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='color-dark font11px'>It's clear, simple, and easily understood.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="PositioningScorecardD9" 
                                                value={Inputs.PositioningScorecardD9} 
                                                onChange={(event) => setInputs({ ...Inputs, PositioningScorecardD9: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td rowSpan={5} className='bg-white padding-0'>
                                        <div className="arrow-targer-market"></div>
                                    </td>
                                    <td rowSpan={5} className='padding-0 bg-color-02'>
                                        <div className="input-fiel-cn">
                                            <textarea type="text" className="field-ipt bg-color-02 font11px color-dark"
                                            id="PositioningScorecardF9"
                                            value={Inputs.PositioningScorecardF9}
                                            onChange={(event) => setInputs({ ...Inputs, PositioningScorecardF9: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='color-dark font11px'>We understand the problems our customers need solved, and the drivers of those problems.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="PositioningScorecardD10" 
                                                value={Inputs.PositioningScorecardD10} 
                                                onChange={(event) => setInputs({ ...Inputs, PositioningScorecardD10: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='color-dark font11px'>We have quantified the benefits of using our offerings and use them in all messaging.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="PositioningScorecardD11" 
                                                value={Inputs.PositioningScorecardD11} 
                                                onChange={(event) => setInputs({ ...Inputs, PositioningScorecardD11: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='color-dark font11px'>We have created believable proof points to support our message and use them in all messaging.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="PositioningScorecardD12" 
                                                value={Inputs.PositioningScorecardD12} 
                                                onChange={(event) => setInputs({ ...Inputs, PositioningScorecardD12: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='color-dark font11px'>We have created an inspiring brand story that differentiates us from competitors.</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="PositioningScorecardD13" 
                                                value={Inputs.PositioningScorecardD13} 
                                                onChange={(event) => setInputs({ ...Inputs, PositioningScorecardD13: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            {/* table 02 */}
                            <thead>
                                <tr>
                                    <td className='black-bg text-white font14px' style={{width: 700, height: 70}}>
                                    Which of the following statements are true regarding your value proposition?
                                    </td>
                                    <td className='black-bg text-white text-center' style={{width: 150}}>
                                        <span className='font14px'>Score</span><br/><span className='font11px'>(1 point each)</span>
                                    </td>
                                    <td className='bg-color-blue text-white text-center' style={{width: 50}}>
                                        <div className="do-you-number font14px">{formatPrice(Inputs.PositioningScorecardE14, 0)}</div>
                                    </td>
                                    <td className='black-bg text-white text-center font14px' style={{width: 500}}>
                                    Opportunities to Improve
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='color-dark font11px'>Targeted to a specific market</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="PositioningScorecardD15" 
                                                value={Inputs.PositioningScorecardD15} 
                                                onChange={(event) => setInputs({ ...Inputs, PositioningScorecardD15: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td rowSpan={5} className='bg-white padding-0'>
                                        <div className="arrow-targer-market"></div>
                                    </td>
                                    <td rowSpan={5} className='padding-0 bg-color-02'>
                                        <div className="input-fiel-cn">
                                            <textarea type="text" className="field-ipt bg-color-02 font11px color-dark"
                                            id="PositioningScorecardF15"
                                            value={Inputs.PositioningScorecardF15}
                                            onChange={(event) => setInputs({ ...Inputs, PositioningScorecardF15: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='color-dark font11px'>Clear and easy to understand</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="PositioningScorecardD16" 
                                                value={Inputs.PositioningScorecardD16} 
                                                onChange={(event) => setInputs({ ...Inputs, PositioningScorecardD16: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='color-dark font11px'>Demonstrates ROI</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="PositioningScorecardD17" 
                                                value={Inputs.PositioningScorecardD17} 
                                                onChange={(event) => setInputs({ ...Inputs, PositioningScorecardD17: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='color-dark font11px'>Effectively differentiates you from competitors</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="PositioningScorecardD18" 
                                                value={Inputs.PositioningScorecardD18} 
                                                onChange={(event) => setInputs({ ...Inputs, PositioningScorecardD18: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-light-blue'>
                                        <div className="we-understand">
                                            <p className='color-dark font11px'>Difficult for competitors to copy</p>
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-color-01'>
                                        <div className="input-fiel-cn bg-color-01">
                                            <input type="text" className="field-ipt font11px bg-color-01 text-center color-dark" 
                                                id="PositioningScorecardD19" 
                                                value={Inputs.PositioningScorecardD19} 
                                                onChange={(event) => setInputs({ ...Inputs, PositioningScorecardD19: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[1]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score 1.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            {/* table 03 */}
                            <thead>
                                <tr>
                                    <td className='bg-white'>
                                        <div className="nextInput">
                                            <ul>
                                                <li>
                                                    <Link className="next-link" to="/diagnose/competitive-advantage"> Return to Benchmark Questions
                                                        <span className="icon-three">
                                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                                        </span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td className='black-bg text-white text-center font16px fontBold'>
                                    Total
                                    </td>
                                    <td className='bg-color-blue text-white text-center font16px'>
                                    {formatPrice(Inputs.PositioningScorecardE20, 0)}
                                    </td>
                                    <td className='hide-content'></td>
                                </tr>
                            </thead>
                        </table>
                    </div>  
                </div>
            </div>
        </div>
    )
}