import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';
import sum from "../../utils/sum";
import { Link } from 'react-router-dom';
import formatPrice from '../../utils/formatPrice';
import { getCompetitiveAdvantageAudit, modifyCompetitiveAdvantageAudit } from '../../state/features/CompetitiveAdvantageAuditSlice';

export default function CompetitiveAdvantageAuditContentNew() {

    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        CompetitiveAdvantageAuditE12: 0,
        CompetitiveAdvantageAuditF12: 0,
        CompetitiveAdvantageAuditG12: 0,
        CompetitiveAdvantageAuditH12: "",
        CompetitiveAdvantageAuditE13: 0,
        CompetitiveAdvantageAuditF13: 0,
        CompetitiveAdvantageAuditG13: 0,
        CompetitiveAdvantageAuditH13: "",
        CompetitiveAdvantageAuditE14: 0,
        CompetitiveAdvantageAuditF14: 0,
        CompetitiveAdvantageAuditG14: 0,
        CompetitiveAdvantageAuditH14: "",
        CompetitiveAdvantageAuditE15: 0,
        CompetitiveAdvantageAuditF15: 0,
        CompetitiveAdvantageAuditG15: 0,
        CompetitiveAdvantageAuditH15: "",
        CompetitiveAdvantageAuditE16: 0,
        CompetitiveAdvantageAuditF16: 0,
        CompetitiveAdvantageAuditG16: 0,
        CompetitiveAdvantageAuditH16: "",
        CompetitiveAdvantageAuditE17: 0,
        CompetitiveAdvantageAuditF17: 0,
        CompetitiveAdvantageAuditG17: 0,
        CompetitiveAdvantageAuditH17: "",
        CompetitiveAdvantageAuditE18: 0,
        CompetitiveAdvantageAuditF18: 0,
        CompetitiveAdvantageAuditG18: 0,
        CompetitiveAdvantageAuditH18: "",
        CompetitiveAdvantageAuditE19: 0,
        CompetitiveAdvantageAuditF19: 0,
        CompetitiveAdvantageAuditG19: 0,
        CompetitiveAdvantageAuditH19: "",
        CompetitiveAdvantageAuditE20: 0,
        CompetitiveAdvantageAuditF20: 0,
        CompetitiveAdvantageAuditG20: 0,
        CompetitiveAdvantageAuditH20: "",
        CompetitiveAdvantageAuditE21: 0,
        CompetitiveAdvantageAuditF21: 0,
        CompetitiveAdvantageAuditG21: 0,
        CompetitiveAdvantageAuditH21: "",
        CompetitiveAdvantageAuditE22: 0,
        CompetitiveAdvantageAuditF22: 0,
        CompetitiveAdvantageAuditG22: 0,
        CompetitiveAdvantageAuditH22: "",
        CompetitiveAdvantageAuditE23: 0,
        CompetitiveAdvantageAuditF23: 0,
        CompetitiveAdvantageAuditG23: 0,
        CompetitiveAdvantageAuditH23: "",
        CompetitiveAdvantageAuditE24: 0,
    });

    const colorPallete = ['#F8696B', '#FCAA78', '#F79993', '#B1D580', '#63BE7B'];
    const bgColorPoint = (num) => {
        if(isNaN(num)) {
            return colorPallete[2];
        }
        if(num === 3){
            return colorPallete[4];
        }else if(num > 3 && num < 5){
            return colorPallete[3];
        }else if(num >= 5 && num < 7){
            return colorPallete[2];
        }else if(num >=7){
            return colorPallete[0];
        }else if(num > -1 && num < 3){
            return colorPallete[4];
        }else if(num <= -1){
            return colorPallete[4];
        }
    }

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getCompetitiveAdvantageAudit({user_id}))
        .unwrap()
        .then((response) => {
            console.log("response1",response.data)
            if(response!= undefined  && response.data.growthPlanning){
                let growthPlanning = response.data.growthPlanning[0];
                // console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key]
                    }
                }
                newObj['CompetitiveAdvantageAuditE24'] = sum(newObj['CompetitiveAdvantageAuditE12'], newObj['CompetitiveAdvantageAuditE13'], newObj['CompetitiveAdvantageAuditE14'], newObj['CompetitiveAdvantageAuditE15'], newObj['CompetitiveAdvantageAuditE16'], newObj['CompetitiveAdvantageAuditE17'], newObj['CompetitiveAdvantageAuditE18'], newObj['CompetitiveAdvantageAuditE19'], newObj['CompetitiveAdvantageAuditE20'], newObj['CompetitiveAdvantageAuditE21'], newObj['CompetitiveAdvantageAuditE22'], newObj['CompetitiveAdvantageAuditE23'])

                newObj['CompetitiveAdvantageAuditG12'] = parseFloat((Number(newObj['CompetitiveAdvantageAuditF12']) * 2) - Number(newObj['CompetitiveAdvantageAuditE12']));
                newObj['CompetitiveAdvantageAuditG13'] = parseFloat((Number(newObj['CompetitiveAdvantageAuditF13']) * 2) - Number(newObj['CompetitiveAdvantageAuditE13']));
                newObj['CompetitiveAdvantageAuditG14'] = parseFloat((Number(newObj['CompetitiveAdvantageAuditF14']) * 2) - Number(newObj['CompetitiveAdvantageAuditE14']));
                newObj['CompetitiveAdvantageAuditG15'] = parseFloat((Number(newObj['CompetitiveAdvantageAuditF15']) * 2) - Number(newObj['CompetitiveAdvantageAuditE15']));
                newObj['CompetitiveAdvantageAuditG16'] = parseFloat((Number(newObj['CompetitiveAdvantageAuditF16']) * 2) - Number(newObj['CompetitiveAdvantageAuditE16']));
                newObj['CompetitiveAdvantageAuditG17'] = parseFloat((Number(newObj['CompetitiveAdvantageAuditF17']) * 2) - Number(newObj['CompetitiveAdvantageAuditE17']));
                newObj['CompetitiveAdvantageAuditG18'] = parseFloat((Number(newObj['CompetitiveAdvantageAuditF18']) * 2) - Number(newObj['CompetitiveAdvantageAuditE18']));
                newObj['CompetitiveAdvantageAuditG19'] = parseFloat((Number(newObj['CompetitiveAdvantageAuditF19']) * 2) - Number(newObj['CompetitiveAdvantageAuditE19']));
                newObj['CompetitiveAdvantageAuditG20'] = parseFloat((Number(newObj['CompetitiveAdvantageAuditF20']) * 2) - Number(newObj['CompetitiveAdvantageAuditE20']));
                newObj['CompetitiveAdvantageAuditG21'] = parseFloat((Number(newObj['CompetitiveAdvantageAuditF21']) * 2) - Number(newObj['CompetitiveAdvantageAuditE21']));
                newObj['CompetitiveAdvantageAuditG22'] = parseFloat((Number(newObj['CompetitiveAdvantageAuditF22']) * 2) - Number(newObj['CompetitiveAdvantageAuditE22']));
                newObj['CompetitiveAdvantageAuditG23'] = parseFloat((Number(newObj['CompetitiveAdvantageAuditF23']) * 2) - Number(newObj['CompetitiveAdvantageAuditE23']));

                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                
                dispatch(modifyCompetitiveAdvantageAudit({ user_id, [event.target.id] : event.target.value, 'CompetitiveAdvantageAuditE24':String(Inputs.CompetitiveAdvantageAuditE24), 'CompetitiveAdvantageAuditG12': String(Inputs.CompetitiveAdvantageAuditG12), 'CompetitiveAdvantageAuditG13': String(Inputs.CompetitiveAdvantageAuditG13), 'CompetitiveAdvantageAuditG14': String(Inputs.CompetitiveAdvantageAuditG14), 'CompetitiveAdvantageAuditG15': String(Inputs.CompetitiveAdvantageAuditG15), 'CompetitiveAdvantageAuditG16': String(Inputs.CompetitiveAdvantageAuditG16), 'CompetitiveAdvantageAuditG17': String(Inputs.CompetitiveAdvantageAuditG17), 'CompetitiveAdvantageAuditG18': String(Inputs.CompetitiveAdvantageAuditG18), 'CompetitiveAdvantageAuditG19': String(Inputs.CompetitiveAdvantageAuditG19), 'CompetitiveAdvantageAuditG20': String(Inputs.CompetitiveAdvantageAuditG20), 'CompetitiveAdvantageAuditG21': String(Inputs.CompetitiveAdvantageAuditG21), 'CompetitiveAdvantageAuditG22': String(Inputs.CompetitiveAdvantageAuditG22), 'CompetitiveAdvantageAuditG23': String(Inputs.CompetitiveAdvantageAuditG23)}))
                .unwrap()
                .then((response) => {
                    // console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        //window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }


    useEffect(() => {
        let CompetitiveAdvantageAuditE24 = sum(Number(Inputs.CompetitiveAdvantageAuditE12), Number(Inputs.CompetitiveAdvantageAuditE13), Number(Inputs.CompetitiveAdvantageAuditE14), Number(Inputs.CompetitiveAdvantageAuditE15), Number(Inputs.CompetitiveAdvantageAuditE16), Number(Inputs.CompetitiveAdvantageAuditE17), Number(Inputs.CompetitiveAdvantageAuditE18), Number(Inputs.CompetitiveAdvantageAuditE19), Number(Inputs.CompetitiveAdvantageAuditE20), Number(Inputs.CompetitiveAdvantageAuditE21), Number(Inputs.CompetitiveAdvantageAuditE22), Number(Inputs.CompetitiveAdvantageAuditE23))

        let CompetitiveAdvantageAuditG12 = parseFloat((Number(Inputs.CompetitiveAdvantageAuditF12) * 2) - Number(Inputs.CompetitiveAdvantageAuditE12));
        let CompetitiveAdvantageAuditG13 = parseFloat((Number(Inputs.CompetitiveAdvantageAuditF13) * 2) - Number(Inputs.CompetitiveAdvantageAuditE13));
        let CompetitiveAdvantageAuditG14 = parseFloat((Number(Inputs.CompetitiveAdvantageAuditF14) * 2) - Number(Inputs.CompetitiveAdvantageAuditE14));
        let CompetitiveAdvantageAuditG15 = parseFloat((Number(Inputs.CompetitiveAdvantageAuditF15) * 2) - Number(Inputs.CompetitiveAdvantageAuditE15));
        let CompetitiveAdvantageAuditG16 = parseFloat((Number(Inputs.CompetitiveAdvantageAuditF16) * 2) - Number(Inputs.CompetitiveAdvantageAuditE16));
        let CompetitiveAdvantageAuditG17 = parseFloat((Number(Inputs.CompetitiveAdvantageAuditF17) * 2) - Number(Inputs.CompetitiveAdvantageAuditE17));
        let CompetitiveAdvantageAuditG18 = parseFloat((Number(Inputs.CompetitiveAdvantageAuditF18) * 2) - Number(Inputs.CompetitiveAdvantageAuditE18));
        let CompetitiveAdvantageAuditG19 = parseFloat((Number(Inputs.CompetitiveAdvantageAuditF19) * 2) - Number(Inputs.CompetitiveAdvantageAuditE19));
        let CompetitiveAdvantageAuditG20 = parseFloat((Number(Inputs.CompetitiveAdvantageAuditF20) * 2) - Number(Inputs.CompetitiveAdvantageAuditE20));
        let CompetitiveAdvantageAuditG21 = parseFloat((Number(Inputs.CompetitiveAdvantageAuditF21) * 2) - Number(Inputs.CompetitiveAdvantageAuditE21));
        let CompetitiveAdvantageAuditG22 = parseFloat((Number(Inputs.CompetitiveAdvantageAuditF22) * 2) - Number(Inputs.CompetitiveAdvantageAuditE22));
        let CompetitiveAdvantageAuditG23 = parseFloat((Number(Inputs.CompetitiveAdvantageAuditF23) * 2) - Number(Inputs.CompetitiveAdvantageAuditE23));

        setInputs({...Inputs, CompetitiveAdvantageAuditE24, CompetitiveAdvantageAuditG12, CompetitiveAdvantageAuditG13, CompetitiveAdvantageAuditG14, CompetitiveAdvantageAuditG15, CompetitiveAdvantageAuditG16, CompetitiveAdvantageAuditG17, CompetitiveAdvantageAuditG18, CompetitiveAdvantageAuditG19, CompetitiveAdvantageAuditG20, CompetitiveAdvantageAuditG21, CompetitiveAdvantageAuditG22, CompetitiveAdvantageAuditG23});

    }, [Inputs.CompetitiveAdvantageAuditE12, Inputs.CompetitiveAdvantageAuditE13, Inputs.CompetitiveAdvantageAuditE14, Inputs.CompetitiveAdvantageAuditE15, Inputs.CompetitiveAdvantageAuditE16, Inputs.CompetitiveAdvantageAuditE17, Inputs.CompetitiveAdvantageAuditE18, Inputs.CompetitiveAdvantageAuditE19, Inputs.CompetitiveAdvantageAuditE20, Inputs.CompetitiveAdvantageAuditE21, Inputs.CompetitiveAdvantageAuditE22, Inputs.CompetitiveAdvantageAuditE23, Inputs.CompetitiveAdvantageAuditF12, Inputs.CompetitiveAdvantageAuditF13, Inputs.CompetitiveAdvantageAuditF14, Inputs.CompetitiveAdvantageAuditF15, Inputs.CompetitiveAdvantageAuditF16, Inputs.CompetitiveAdvantageAuditF17, Inputs.CompetitiveAdvantageAuditF18, Inputs.CompetitiveAdvantageAuditF19, Inputs.CompetitiveAdvantageAuditF20, Inputs.CompetitiveAdvantageAuditF21, Inputs.CompetitiveAdvantageAuditF22, Inputs.CompetitiveAdvantageAuditF23]);

    return (
        <div className="content-body content-body-new bg-white-color">
            <UserDropdown />
            {/* row */}
            <div className="container-fluid">
                <div className="head-title mb-4">
                    <h3 className='preheading'>Diagnose - Benchmark Tools</h3>
                    <h1 className='mainheading'>Competitive Advantage Audit</h1>
                    <p  className='body-text color-black'>There are 12 major sources of competitive advantage.  The more competitive advantage you have, the better you can can defend your value proposition against competitors. </p>
                    <ul className='competitive-advantages-list'>
                        <li>What sources of competitive advantage do you currently have, and what sources are a priority for you to develop? </li>
                        <li>Only the "Current Rating" is required for benchmarking.  Priority and Improvement Ideas are to help guide future brainstorming, but not required for benchmark scoring.</li>
                    </ul>
                </div>
                <div className="competitive-advantage-audit-table">
                    <div className="table-responsive">
                        <table className="table responsive-table" style={{width: 1400}}>
                            <thead>
                                <tr>
                                    <td className='bg-color01' style={{width: 50}}>
                                        <div className="empty-number-ddh"></div>
                                    </td>
                                    <td className='bg-color01 text-white font14px' style={{width: 400}}>
                                    Sources of Competitive Advantage  
                                    </td>
                                    <td className='bg-color01 text-white text-center' style={{width: 150}}>
                                        <span className='font12px'>Current Rating</span><br/>
                                        <span className='font10px'>(1=low; 5= high)</span>
                                        {/* <div className="current-ration">
                                            <p>Current <br></br> Rating <br></br> (1=low; 5= high)</p>
                                        </div> */}
                                    </td>
                                    <td className='bg-color01 text-white text-center' style={{width: 150}}>
                                        <span className='font12px'>Priority</span><br/>
                                        <span className='font10px'>(1=low; 5= high)</span>
                                        {/* <div className="priorty-ration">
                                            <p>Priority <br></br> (1=low; 5=high)</p>
                                        </div> */}
                                    </td>
                                    <td className='bg-color01 text-white text-center font12px' style={{width: 150}}>
                                    Priority Score
                                    </td>
                                    <td className='bg-color01 text-white text-center font12px' style={{width: 500}}>
                                    Improvement Ideas
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='bg-color01 text-white text-center'>
                                        <div className="competitiveImagesBox">
                                            <img src="../images/competitive-advantage/icon_01.png" alt="competitiveImg" />
                                        </div>
                                    </td>
                                    <td className='Blue-gray-bg text-white'>
                                        <div className="proprietary-infomation">
                                            <p><strong>Proprietary Information -</strong> can be knowledge,  processes, customers, and other types.</p>
                                        </div>
                                    </td>
                                    <td  className='padding-0'>
                                        <div className="input-fiel-cn changes-input">
                                            <input type="text" className="bg-color-01 font12px" 
                                                id="CompetitiveAdvantageAuditE12" 
                                                value={Inputs.CompetitiveAdvantageAuditE12} 
                                                onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditE12: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-orange-color'>
                                        <div className="input-fiel-cn changes-input">
                                            <input type="text" className="bg-orange-color font12px" 
                                                id="CompetitiveAdvantageAuditF12" 
                                                value={Inputs.CompetitiveAdvantageAuditF12} 
                                                onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditF12: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='text-center' style={{color: '#000', backgroundColor: bgColorPoint(Number(Inputs.CompetitiveAdvantageAuditG12))}}>
                                            <div className="scrore-col font12px">
                                                {formatPrice(Inputs.CompetitiveAdvantageAuditG12, 1)}
                                            </div>
                                    </td>
                                    <td className='padding-0'>
                                        <div className="input-fiel-cn">
                                            <textarea type="text" className="bg-color-02 font12px color-black w-100"
                                            id="CompetitiveAdvantageAuditH12"
                                            value={Inputs.CompetitiveAdvantageAuditH12}
                                            onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditH12: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading} />
                                            
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color01 text-white text-center'>
                                        <div className="competitiveImagesBox">
                                            <img src="../images/competitive-advantage/icon_02.png" alt="competitiveImg" />
                                        </div>
                                    </td>
                                    <td className='Blue-gray-bg text-white'>
                                        <div className="proprietary-infomation">
                                            <p><strong>Intellectual Property - </strong>proprietary formulas, systems, processes, and patents.</p>
                                        </div>
                                    </td>
                                    <td  className='padding-0'>
                                        <div className="input-fiel-cn changes-input">
                                            <input type="text" className="bg-color-01 font12px" 
                                                id="CompetitiveAdvantageAuditE13" 
                                                value={Inputs.CompetitiveAdvantageAuditE13} 
                                                onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditE13: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-orange-color'>
                                        <div className="input-fiel-cn changes-input">
                                            <input type="text" className="bg-orange-color font12px" 
                                                id="CompetitiveAdvantageAuditF13" 
                                                value={Inputs.CompetitiveAdvantageAuditF13} 
                                                onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditF13: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='text-center' style={{color: '#000', backgroundColor: bgColorPoint(Number(Inputs.CompetitiveAdvantageAuditG13))}}>
                                        <div className="scrore-col font12px">
                                            {formatPrice(Inputs.CompetitiveAdvantageAuditG13, 1)}
                                        </div>
                                    </td>
                                    <td className='padding-0'>
                                        <div className="input-fiel-cn">
                                            <textarea type="text" className="bg-color-02 font12px color-black"
                                            id="CompetitiveAdvantageAuditH13"
                                            value={Inputs.CompetitiveAdvantageAuditH13}
                                            onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditH13: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading} />
                                            
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color01 text-white text-center'>
                                        <div className="competitiveImagesBox">
                                            <img src="../images/competitive-advantage/icon_03.png" alt="competitiveImg" />
                                        </div>
                                    </td>
                                    <td className='Blue-gray-bg text-white'>
                                        <div className="proprietary-infomation">
                                            <p><strong>Innovation - </strong>uniqueness and the use of technology and design.  Does not have to be disruptive, but should save the customer time, money, or solve a real problem.</p>
                                        </div>
                                    </td>
                                    <td  className='padding-0'>
                                        <div className="input-fiel-cn changes-input">
                                            <input type="text" className="bg-color-01 font12px" 
                                                id="CompetitiveAdvantageAuditE14" 
                                                value={Inputs.CompetitiveAdvantageAuditE14} 
                                                onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditE14: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-orange-color'>
                                        <div className="input-fiel-cn changes-input">
                                            <input type="text" className="bg-orange-color font12px" 
                                                id="CompetitiveAdvantageAuditF14" 
                                                value={Inputs.CompetitiveAdvantageAuditF14} 
                                                onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditF14: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='text-center' style={{color: '#000', backgroundColor: bgColorPoint(Number(Inputs.CompetitiveAdvantageAuditG14))}}>
                                        <div className="scrore-col font12px">
                                            {formatPrice(Inputs.CompetitiveAdvantageAuditG14, 1)}
                                        </div>
                                    </td>
                                    <td className='padding-0'>
                                        <div className="input-fiel-cn">
                                            <textarea type="text" className="bg-color-02 font12px color-black"
                                            id="CompetitiveAdvantageAuditH14"
                                            value={Inputs.CompetitiveAdvantageAuditH14}
                                            onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditH14: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading} />
                                            
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color01 text-white text-center'>
                                        <div className="competitiveImagesBox">
                                            <img src="../images/competitive-advantage/icon_04.svg" alt="competitiveImg" />
                                        </div>
                                    </td>
                                    <td className='Blue-gray-bg text-white'>
                                        <div className="proprietary-infomation">
                                            <p><strong>Price - </strong>the company with the lowest price in a  market; that advantage is rarely sustainable.</p>
                                        </div>
                                    </td>
                                    <td  className='padding-0'>
                                        <div className="input-fiel-cn changes-input">
                                            <input type="text" className="bg-color-01 font12px" 
                                                id="CompetitiveAdvantageAuditE15" 
                                                value={Inputs.CompetitiveAdvantageAuditE15} 
                                                onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditE15: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-orange-color'>
                                        <div className="input-fiel-cn changes-input">
                                            <input type="text" className="bg-orange-color font12px" 
                                                id="CompetitiveAdvantageAuditF15" 
                                                value={Inputs.CompetitiveAdvantageAuditF15} 
                                                onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditF15: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='text-center' style={{color: '#000', backgroundColor: bgColorPoint(Number(Inputs.CompetitiveAdvantageAuditG15))}}>
                                        <div className="scrore-col font12px">
                                            {formatPrice(Inputs.CompetitiveAdvantageAuditG15, 1)}
                                        </div>
                                    </td>
                                    <td className='padding-0'>
                                        <div className="input-fiel-cn">
                                            <textarea type="text" className="bg-color-02 font12px color-black"
                                            id="CompetitiveAdvantageAuditH15"
                                            value={Inputs.CompetitiveAdvantageAuditH15}
                                            onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditH15: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading} />
                                            
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color01 text-white text-center'>
                                        <div className="competitiveImagesBox">
                                            <img src="../images/competitive-advantage/icon_05.png" alt="competitiveImg" />
                                        </div>
                                    </td>
                                    <td className='Blue-gray-bg text-white'>
                                        <div className="proprietary-infomation">
                                            <p><strong>Agility - </strong>the ability for a company to recognize and  adapt to changes in the marketplace.</p>
                                        </div>
                                    </td>
                                    <td  className='padding-0'>
                                        <div className="input-fiel-cn changes-input">
                                            <input type="text" className="bg-color-01 font12px" 
                                                id="CompetitiveAdvantageAuditE16" 
                                                value={Inputs.CompetitiveAdvantageAuditE16} 
                                                onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditE16: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-orange-color'>
                                        <div className="input-fiel-cn changes-input">
                                            <input type="text" className="bg-orange-color font12px" 
                                                id="CompetitiveAdvantageAuditF16" 
                                                value={Inputs.CompetitiveAdvantageAuditF16} 
                                                onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditF16: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='text-center' style={{color: '#000', backgroundColor: bgColorPoint(Number(Inputs.CompetitiveAdvantageAuditG16))}}>
                                        <div className="scrore-col font12px">
                                            {formatPrice(Inputs.CompetitiveAdvantageAuditG16, 1)}
                                        </div>
                                    </td>
                                    <td className='padding-0'>
                                        <div className="input-fiel-cn">
                                            <textarea type="text" className="bg-color-02 font12px color-black"
                                            id="CompetitiveAdvantageAuditH16"
                                            value={Inputs.CompetitiveAdvantageAuditH16}
                                            onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditH16: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading} />
                                            
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color01 text-white text-center'>
                                        <div className="competitiveImagesBox">
                                            <img src="../images/competitive-advantage/icon_06.png" alt="competitiveImg" />
                                        </div>
                                    </td>
                                    <td className='Blue-gray-bg text-white'>
                                        <div className="proprietary-infomation">
                                            <p><strong>Network Effects - </strong>the value of the product or  service increases with the number of users or  products.</p>
                                        </div>
                                    </td>
                                    <td  className='padding-0'>
                                        <div className="input-fiel-cn changes-input">
                                            <input type="text" className="bg-color-01 font12px" 
                                                id="CompetitiveAdvantageAuditE17" 
                                                value={Inputs.CompetitiveAdvantageAuditE17} 
                                                onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditE17: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-orange-color'>
                                        <div className="input-fiel-cn changes-input">
                                            <input type="text" className="bg-orange-color font12px" 
                                                id="CompetitiveAdvantageAuditF17" 
                                                value={Inputs.CompetitiveAdvantageAuditF17} 
                                                onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditF17: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='text-center' style={{color: '#000', backgroundColor: bgColorPoint(Number(Inputs.CompetitiveAdvantageAuditG17))}}>
                                        <div className="scrore-col font12px">
                                            {formatPrice(Inputs.CompetitiveAdvantageAuditG17, 1)}
                                        </div>
                                    </td>
                                    <td className='padding-0'>
                                        <div className="input-fiel-cn">
                                            <textarea type="text" className="bg-color-02 font12px color-black"
                                            id="CompetitiveAdvantageAuditH17"
                                            value={Inputs.CompetitiveAdvantageAuditH17}
                                            onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditH17: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading} />
                                            
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color01 text-white text-center'>
                                        <div className="competitiveImagesBox">
                                            <img src="../images/competitive-advantage/icon_07.png" alt="competitiveImg" />
                                        </div>
                                    </td>
                                    <td className='Blue-gray-bg text-white'>
                                        <div className="proprietary-infomation">
                                            <p><strong>Brand Loyalty - </strong>driven by the strength of the  brand, design, products, and loyalty programs.</p>
                                        </div>
                                    </td>
                                    <td  className='padding-0'>
                                        <div className="input-fiel-cn changes-input">
                                            <input type="text" className="bg-color-01 font12px" 
                                                id="CompetitiveAdvantageAuditE18" 
                                                value={Inputs.CompetitiveAdvantageAuditE18} 
                                                onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditE18: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-orange-color'>
                                        <div className="input-fiel-cn changes-input">
                                            <input type="text" className="bg-orange-color font12px" 
                                                id="CompetitiveAdvantageAuditF18" 
                                                value={Inputs.CompetitiveAdvantageAuditF18} 
                                                onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditF18: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='text-center' style={{color: '#000', backgroundColor: bgColorPoint(Number(Inputs.CompetitiveAdvantageAuditG18))}}>
                                        <div className="scrore-col font12px">
                                            {formatPrice(Inputs.CompetitiveAdvantageAuditG18, 1)}
                                        </div>
                                    </td>
                                    <td className='padding-0'>
                                        <div className="input-fiel-cn">
                                            <textarea type="text" className="bg-color-02 font12px color-black"
                                            id="CompetitiveAdvantageAuditH18"
                                            value={Inputs.CompetitiveAdvantageAuditH18}
                                            onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditH18: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading} />
                                            
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color01 text-white text-center'>
                                        <div className="competitiveImagesBox">
                                            <img src="../images/competitive-advantage/icon_08.png" alt="competitiveImg" />
                                        </div>
                                    </td>
                                    <td className='Blue-gray-bg text-white'>
                                        <div className="proprietary-infomation">
                                            <p><strong>Emotional Connection - </strong>50% of your customer's  decision-making comes from emotions.  Make them  feel good, safe, or make their life easier.</p>
                                        </div>
                                    </td>
                                    <td  className='padding-0'>
                                        <div className="input-fiel-cn changes-input">
                                            <input type="text" className="bg-color-01 font12px" 
                                                id="CompetitiveAdvantageAuditE19" 
                                                value={Inputs.CompetitiveAdvantageAuditE19} 
                                                onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditE19: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-orange-color'>
                                        <div className="input-fiel-cn changes-input">
                                            <input type="text" className="bg-orange-color font12px" 
                                                id="CompetitiveAdvantageAuditF19" 
                                                value={Inputs.CompetitiveAdvantageAuditF19} 
                                                onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditF19: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='text-center' style={{color: '#000', backgroundColor: bgColorPoint(Number(Inputs.CompetitiveAdvantageAuditG19))}}>
                                        <div className="scrore-col font12px">
                                            {formatPrice(Inputs.CompetitiveAdvantageAuditG19, 1)}
                                        </div>
                                    </td>
                                    <td className='padding-0'>
                                        <div className="input-fiel-cn">
                                            <textarea type="text" className="bg-color-02 font12px color-black"
                                            id="CompetitiveAdvantageAuditH19"
                                            value={Inputs.CompetitiveAdvantageAuditH19}
                                            onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditH19: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading} />
                                            
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color01 text-white text-center'>
                                        <div className="competitiveImagesBox">
                                            <img src="../images/competitive-advantage/icon_09.png" alt="competitiveImg" />
                                        </div>
                                    </td>
                                    <td className='Blue-gray-bg text-white'>
                                        <div className="proprietary-infomation">
                                            <p><strong>Experience Innovation - </strong>start with what problem  you solve for your customer, then pick the opposite  experience.  How do you want them to feel?</p>
                                        </div>
                                    </td>
                                    <td  className='padding-0'>
                                        <div className="input-fiel-cn changes-input">
                                            <input type="text" className="bg-color-01 font12px" 
                                                id="CompetitiveAdvantageAuditE20" 
                                                value={Inputs.CompetitiveAdvantageAuditE20} 
                                                onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditE20: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-orange-color'>
                                        <div className="input-fiel-cn changes-input">
                                            <input type="text" className="bg-orange-color font12px" 
                                                id="CompetitiveAdvantageAuditF20" 
                                                value={Inputs.CompetitiveAdvantageAuditF20} 
                                                onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditF20: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='text-center' style={{color: '#000', backgroundColor: bgColorPoint(Number(Inputs.CompetitiveAdvantageAuditG20))}}>
                                        <div className="scrore-col font12px">
                                            {formatPrice(Inputs.CompetitiveAdvantageAuditG20, 1)}
                                        </div>
                                    </td>
                                    <td className='padding-0'>
                                        <div className="input-fiel-cn">
                                            <textarea type="text" className="bg-color-02 font12px color-black"
                                            id="CompetitiveAdvantageAuditH20"
                                            value={Inputs.CompetitiveAdvantageAuditH20}
                                            onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditH20: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading} />
                                            
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color01 text-white text-center'>
                                        <div className="competitiveImagesBox">
                                            <img src="../images/competitive-advantage/icon_10.png" alt="competitiveImg" />
                                        </div>
                                    </td>
                                    <td className='Blue-gray-bg text-white'>
                                        <div className="proprietary-infomation">
                                            <p><strong>Locked-Up Supply - </strong> occurs when there are few  to no other alternatives in the supply of a  product/service (DeBeers Diamonds).</p>
                                        </div>
                                    </td>
                                    <td  className='padding-0'>
                                        <div className="input-fiel-cn changes-input">
                                            <input type="text" className="bg-color-01 font12px" 
                                                id="CompetitiveAdvantageAuditE21" 
                                                value={Inputs.CompetitiveAdvantageAuditE21} 
                                                onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditE21: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='padding-0 '>
                                        <div className="input-fiel-cn changes-input">
                                            <input type="text" className="bg-orange-color font12px" 
                                                id="CompetitiveAdvantageAuditF21" 
                                                value={Inputs.CompetitiveAdvantageAuditF21} 
                                                onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditF21: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='text-center' style={{color: '#000', backgroundColor: bgColorPoint(Number(Inputs.CompetitiveAdvantageAuditG21))}}>
                                        <div className="scrore-col font12px">
                                            {formatPrice(Inputs.CompetitiveAdvantageAuditG21, 1)}
                                        </div>
                                    </td>
                                    <td className='padding-0'>
                                        <div className="input-fiel-cn">
                                            <textarea type="text" className="bg-color-02 font12px color-black"
                                            id="CompetitiveAdvantageAuditH21"
                                            value={Inputs.CompetitiveAdvantageAuditH21}
                                            onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditH21: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading} />
                                            
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color01 text-white text-center'>
                                        <div className="competitiveImagesBox">
                                            <img src="../images/competitive-advantage/icon_11.png" alt="competitiveImg" />
                                        </div>
                                    </td>
                                    <td className='Blue-gray-bg text-white'>
                                        <div className="proprietary-infomation">
                                            <p><strong>Location - </strong>prime physical locations accessible to  customer segments.</p>
                                        </div>
                                    </td>
                                    <td  className='padding-0'>
                                        <div className="input-fiel-cn changes-input">
                                            <input type="text" className="bg-color-01 font12px" 
                                                id="CompetitiveAdvantageAuditE22" 
                                                value={Inputs.CompetitiveAdvantageAuditE22} 
                                                onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditE22: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-orange-color'>
                                        <div className="input-fiel-cn changes-input">
                                            <input type="text" className="bg-orange-color font12px" 
                                                id="CompetitiveAdvantageAuditF22" 
                                                value={Inputs.CompetitiveAdvantageAuditF22} 
                                                onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditF22: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='text-center' style={{color: '#000', backgroundColor: bgColorPoint(Number(Inputs.CompetitiveAdvantageAuditG22))}}>
                                        <div className="scrore-col font12px">
                                            {formatPrice(Inputs.CompetitiveAdvantageAuditG22, 1)}
                                        </div>
                                    </td>
                                    <td className='padding-0'>
                                        <div className="input-fiel-cn">
                                            <textarea type="text" className="bg-color-02 font12px color-black"
                                            id="CompetitiveAdvantageAuditH22"
                                            value={Inputs.CompetitiveAdvantageAuditH22}
                                            onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditH22: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading} />
                                            
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color01 text-white text-center'>
                                        <div className="competitiveImagesBox">
                                            <img src="../images/competitive-advantage/icon_12.png" alt="competitiveImg" />
                                        </div>
                                    </td>
                                    <td className='Blue-gray-bg text-white'>
                                        <div className="proprietary-infomation">
                                            <p><strong>Scale - </strong>scale over competitors helps drive down costs and  pricing through leverage when purchasing, volume  production, marketing, fixed costs, and partners.</p>
                                        </div>
                                    </td>
                                    <td  className='padding-0'>
                                        <div className="input-fiel-cn changes-input">
                                            <input type="text" className="bg-color-01 font12px" 
                                                id="CompetitiveAdvantageAuditE23" 
                                                value={Inputs.CompetitiveAdvantageAuditE23} 
                                                onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditE23: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='padding-0 bg-orange-color'>
                                        <div className="input-fiel-cn changes-input">
                                            <input type="text" className="bg-orange-color font12px" 
                                                id="CompetitiveAdvantageAuditF23" 
                                                value={Inputs.CompetitiveAdvantageAuditF23} 
                                                onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditF23: event.target.value }) } 
                                                onBlur={(event) => { onBlurHandler(event) }} 
                                                disabled={isLoading} 
                                                placeholder="0" 
                                                maxLength="1" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-5]/.test(event.key)) {
                                                        event.preventDefault();
                                                        alert.show('Please Enter Score range 1 to 5.')
                                                    }
                                            }} />
                                        </div>
                                    </td>
                                    <td className='text-center' style={{color: '#000', backgroundColor: bgColorPoint(Number(Inputs.CompetitiveAdvantageAuditG23))}}>
                                        <div className="scrore-col font12px">
                                            {formatPrice(Inputs.CompetitiveAdvantageAuditG23, 1)}
                                        </div>
                                    </td>
                                    <td className='padding-0'>
                                        <div className="input-fiel-cn">
                                            <textarea type="text" className="bg-color-02 font12px color-black"
                                            id="CompetitiveAdvantageAuditH23"
                                            value={Inputs.CompetitiveAdvantageAuditH23}
                                            onChange={(event) => setInputs({ ...Inputs, CompetitiveAdvantageAuditH23: event.target.value }) }
                                            onBlur={(event) => { onBlurHandler(event) }}
                                            disabled={isLoading} />
                                            
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='bg-color-blue text-white' colSpan={2}>
                                        <div className="proprietary-infomation">
                                            <p><strong className='total-prive-dfd'>Total</strong></p>
                                        </div>
                                    </td>
                                    <td  className='bg-color-blue text-white text-center'>
                                        <div className="proprietary-infomation">
                                            <p><strong className='total-prive-dfd'>{formatPrice(Inputs.CompetitiveAdvantageAuditE24, 0)}</strong></p>
                                        </div>
                                    </td>
                                    <td className='bg-color-03 text-white text-center padidng-ty'></td>
                                    <td className='bg-color-03 text-white text-center padidng-ty'></td>
                                    <td className='hide-content'></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>  
                    <div className="nextInput">
                        <ul>
                            <li>
                                <Link className="next-link" to="/diagnose/competitive-advantage"> Return to Benchmark Questions
                                    <span className="icon-three">
                                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                                    </span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}