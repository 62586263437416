const initialState = {
    profitMarginD12: 0,
    profitMarginD13: 0,
    profitMarginD17: 0,
    profitMarginE17: 0,
    profitMarginF17: 0,
    profitMarginG17: 0,
    profitMarginH17: 0,
    profitMarginI17: 0,
    profitMarginD18: 0,
    profitMarginE18: 0,
    profitMarginF18: 0,
    profitMarginG18: 0,
    profitMarginH18: 0,
    profitMarginI18: 0,
    profitMarginD22: 0,
    profitMarginE22: 0,
    profitMarginF22: 0,
    profitMarginG22: 0,
    profitMarginH22: 0,
    profitMarginI22: 0,
    profitMarginD24: 0,
    profitMarginE24: 0,
    profitMarginF24: 0,
    profitMarginG24: 0,
    profitMarginH24: 0,
    profitMarginI24: 0,
    profitMarginD26: 0,
    profitMarginE26: 0,
    profitMarginF26: 0,
    profitMarginG26: 0,
    profitMarginH26: 0,
    profitMarginI26: 0,
}

const reducer = (state = 0, action) => {
    if (action.type === 'profitMarginD12') {
        return {
            ...state, //copying the original state
            profitMarginD12: (action.payload) ? parseFloat(action.payload) : 0
        }
    } else if (action.type === 'profitMarginD13') {
        return {
            ...state, //copying the original state
            profitMarginD13: (action.payload) ? parseFloat(action.payload) : 0
        }
    }
    else if (action.type === 'profitMarginImprovement') {
        let profitMarginD12 = action.payload.profitMarginD12;
        let CustomerFinancialValuesD16 = action.payload.CustomerFinancialValues.CustomerFinancialValuesD16;
        let CustomerFinancialValuesD17 = action.payload.CustomerFinancialValues.CustomerFinancialValuesD17;
        let CustomerFinancialValuesD18 = action.payload.CustomerFinancialValues.CustomerFinancialValuesD18;
        let CustomerFinancialValuesD20 = action.payload.CustomerFinancialValues.CustomerFinancialValuesD20;
        let clientInputsH41 = action.payload.clientInputsH41;

        
        let profitMarginD17 = (parseFloat(profitMarginD12) * parseFloat(state.profitMarginD13) / 100).toFixed(1)
        let profitMarginE17 = (parseFloat(profitMarginD12) * 4 / 100).toFixed(1)
        let profitMarginE18 = (parseFloat(profitMarginD12) + parseFloat(profitMarginE17)).toFixed(1)
        let profitMarginF18 = (parseFloat(profitMarginE18) * (4 / 100 + 1)).toFixed(1)
        let profitMarginG18 = (parseFloat(profitMarginF18) * (4 / 100 + 1)).toFixed(1)
        let profitMarginF17 = (parseFloat(profitMarginF18) - parseFloat(profitMarginD12)).toFixed(1)
        let profitMarginG17 = (parseFloat(profitMarginG18) - parseFloat(profitMarginD12)).toFixed(1)
        let profitMarginH18 = (parseFloat(profitMarginG18) * (4 / 100 + 1)).toFixed(1)
        let profitMarginH17 = (parseFloat(profitMarginH18) - parseFloat(profitMarginD12)).toFixed(1)
        let profitMarginI18 = (parseFloat(profitMarginH18) * (4 / 100 + 1)).toFixed(1)
        let profitMarginI17 = (parseFloat(profitMarginI18) - parseFloat(profitMarginD12)).toFixed(1)
        let profitMarginD18 = (parseFloat(profitMarginD12) + parseFloat(profitMarginD17)).toFixed(1)

        let profitMarginD22 = (parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD17) * parseFloat(CustomerFinancialValuesD20) * parseFloat(profitMarginD17) / 100).toFixed(2)
        let profitMarginE22 = (parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD17) * parseFloat(CustomerFinancialValuesD20) * parseFloat(profitMarginE17) / 100).toFixed(2)
        let profitMarginF22 = (parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD17) * parseFloat(CustomerFinancialValuesD20) * parseFloat(profitMarginF17) / 100).toFixed(2)
        let profitMarginG22 = (parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD17) * parseFloat(CustomerFinancialValuesD20) * parseFloat(profitMarginG17) / 100).toFixed(2)
        let profitMarginH22 = (parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD17) * parseFloat(CustomerFinancialValuesD20) * parseFloat(profitMarginH17) / 100).toFixed(2)
        let profitMarginI22 = (parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD17) * parseFloat(CustomerFinancialValuesD20) * parseFloat(profitMarginI17) / 100).toFixed(2)

        let profitMarginD24 = (parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD17) * parseFloat(CustomerFinancialValuesD20) * parseFloat(CustomerFinancialValuesD18) * parseFloat(profitMarginD17) / 100).toFixed(2)
        let profitMarginE24 = (parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD17) * parseFloat(CustomerFinancialValuesD20) * parseFloat(CustomerFinancialValuesD18) * parseFloat(profitMarginE17) / 100).toFixed(2)
        let profitMarginF24 = (parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD17) * parseFloat(CustomerFinancialValuesD20) * parseFloat(CustomerFinancialValuesD18) * parseFloat(profitMarginF17) / 100).toFixed(2)
        let profitMarginG24 = (parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD17) * parseFloat(CustomerFinancialValuesD20) * parseFloat(CustomerFinancialValuesD18) * parseFloat(profitMarginG17) / 100).toFixed(2)
        let profitMarginH24 = (parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD17) * parseFloat(CustomerFinancialValuesD20) * parseFloat(CustomerFinancialValuesD18) * parseFloat(profitMarginH17) / 100).toFixed(2)
        let profitMarginI24 = (parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD17) * parseFloat(CustomerFinancialValuesD20) * parseFloat(CustomerFinancialValuesD18) * parseFloat(profitMarginI17) / 100).toFixed(2)

        let profitMarginD26 = parseFloat(profitMarginD22) * parseFloat(clientInputsH41).toFixed(2)
        let profitMarginE26 = (parseFloat(profitMarginE22) * parseFloat(clientInputsH41)).toFixed(2)
        let profitMarginF26 = (parseFloat(profitMarginF22) * parseFloat(clientInputsH41)).toFixed(2)
        let profitMarginG26 = (parseFloat(profitMarginG22) * parseFloat(clientInputsH41)).toFixed(2)
        let profitMarginH26 = (parseFloat(profitMarginH22) * parseFloat(clientInputsH41)).toFixed(2)
        let profitMarginI26 = (parseFloat(profitMarginI22) * parseFloat(clientInputsH41)).toFixed(2)
        return {
            ...state, //copying the original state
            profitMarginD17 : (profitMarginD17 != 'NaN') ? profitMarginD17 : 0,
            profitMarginE17 : (profitMarginE17 != 'NaN') ? profitMarginE17 : 0,
            profitMarginF17 : (profitMarginF17 != 'NaN') ? profitMarginF17 : 0,
            profitMarginG17 : (profitMarginG17 != 'NaN') ? profitMarginG17 : 0,
            profitMarginH17 : (profitMarginH17 != 'NaN') ? profitMarginH17 : 0,
            profitMarginI17 : (profitMarginI17 != 'NaN') ? profitMarginI17 : 0,
            profitMarginD18 : (profitMarginD18 != 'NaN') ? profitMarginD18 : 0,
            profitMarginE18 : (profitMarginE18 != 'NaN') ? profitMarginE18 : 0,
            profitMarginF18 : (profitMarginF18 != 'NaN') ? profitMarginF18 : 0,
            profitMarginG18 : (profitMarginG18 != 'NaN') ? profitMarginG18 : 0,
            profitMarginH18 : (profitMarginH18 != 'NaN') ? profitMarginH18 : 0,
            profitMarginI18 : (profitMarginI18 != 'NaN') ? profitMarginI18 : 0,
            profitMarginD22 : (profitMarginD22 != 'NaN') ? profitMarginD22 : 0,
            profitMarginE22 : (profitMarginE22 != 'NaN') ? profitMarginE22 : 0,
            profitMarginF22 : (profitMarginF22 != 'NaN') ? profitMarginF22 : 0,
            profitMarginG22 : (profitMarginG22 != 'NaN') ? profitMarginG22 : 0,
            profitMarginH22 : (profitMarginH22 != 'NaN') ? profitMarginH22 : 0,
            profitMarginI22 : (profitMarginI22 != 'NaN') ? profitMarginI22 : 0,
            profitMarginD24 : (profitMarginD24 != 'NaN') ? profitMarginD24 : 0,
            profitMarginE24 : (profitMarginE24 != 'NaN') ? profitMarginE24 : 0,
            profitMarginF24 : (profitMarginF24 != 'NaN') ? profitMarginF24 : 0,
            profitMarginG24 : (profitMarginG24 != 'NaN') ? profitMarginG24 : 0,
            profitMarginH24 : (profitMarginH24 != 'NaN') ? profitMarginH24 : 0,
            profitMarginI24 : (profitMarginI24 != 'NaN') ? profitMarginI24 : 0,
            profitMarginD26 : (profitMarginD26 != 'NaN') ? profitMarginD26 : 0,
            profitMarginE26 : (profitMarginE26 != 'NaN') ? profitMarginE26 : 0,
            profitMarginF26 : (profitMarginF26 != 'NaN') ? profitMarginF26 : 0,
            profitMarginG26 : (profitMarginG26 != 'NaN') ? profitMarginG26 : 0,
            profitMarginH26 : (profitMarginH26 != 'NaN') ? profitMarginH26 : 0,
            profitMarginI26 : (profitMarginI26 != 'NaN') ? profitMarginI26 : 0,

        }
        
    }
    // else if (action.type === "INITIAL_STATE") {
    //     state = initialState;
    //     return state
    // }
    else {
        return state
    }
}

export default reducer