import { Link } from "react-router-dom";


export default function AccessDenied() {
    return (
        <div style={{position: 'relative',
            margin: 0,
            padding: 0,
            boxSizing: 'border-box'}}>
            <div className="denied-body">
                <div><img src="/images/lock.png" width="100"/></div>
                <div className="message text-center">
                    <h1>Access to this page is restricted</h1>
                    <p>Please check with the site admin if you believe this is a mistake.</p>
                    <Link to="/home" className="font12px"><i className="fa fa-angle-double-left"></i> Home</Link>
                </div>
            </div>
        </div>
    )
}