import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import { Provider } from 'react-redux';
import { store } from './state/store'
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import axios from "axios";
import { ClearBrowserCacheBoundary } from 'react-clear-browser-cache';


let persistor = persistStore(store);

const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE
}


axios.defaults.baseURL = "https://api.growthoperatingsystem.app/api/v1/";
//axios.defaults.baseURL = "http://127.0.0.1:5001/api/v1/";
axios.defaults.headers.post['Content-Type'] = 'application/json';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ClearBrowserCacheBoundary auto fallback='Loading' duration={60000}>
    <React.StrictMode>
      <AlertProvider template={AlertTemplate} {...options}>

        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>

      </AlertProvider>
    </React.StrictMode>
  </ClearBrowserCacheBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
