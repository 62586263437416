import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getInnovationConceptDevelopment, modifyInnovationConceptDevelopment } from "../../state/features/InnovationConceptDevelopmentSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';

export default function InnovationConceptDevelopmentContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        InnovationConceptDevelopmentE11 : '',
        InnovationConceptDevelopmentE12 : '',
        InnovationConceptDevelopmentE13 : '',
        InnovationConceptDevelopmentE14 : '',
        InnovationConceptDevelopmentE15 : '',
        InnovationConceptDevelopmentE16 : '',
        InnovationConceptDevelopmentE17 : '',
        InnovationConceptDevelopmentE18 : '',
        InnovationConceptDevelopmentF20 : '',
        InnovationConceptDevelopmentF21 : '',
        InnovationConceptDevelopmentF22 : ''
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getInnovationConceptDevelopment({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.revenueGrowth)
            if(response!= undefined  && response.data.revenueGrowth){
                let revenueGrowth = response.data.revenueGrowth[0];
                console.log(revenueGrowth)
                delete revenueGrowth.id;
                delete revenueGrowth.user_id;
                delete revenueGrowth.created_at;
                delete revenueGrowth.updated_at;
                const newObj = { ...Inputs }
                for (const key in revenueGrowth) {
                    if (revenueGrowth.hasOwnProperty(key)) {
                        if(revenueGrowth[key])
                        newObj[key] = revenueGrowth[key]
                    }
                }
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyInnovationConceptDevelopment({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }
    
  return (
    <div className="content-body">
        <UserDropdown />
        
        {/* row */}
        <div className="container-fluid">
            <div className="head-title">
                <p className="mb-0">Innovation</p>
                <h2>Innovation Concept Development</h2>
                <p className="mb-5"><b>Innovation Concept Development : </b> is the starting point for a business idea. It includes the type of service or product, the target customer demographic, and describes what is unique about it that will give this idea a competitive advantage. The process of Concept Development results in a portfolio of business concepts that can be prioritized and then used to develop a business plan. </p>
            </div>
            <Spinner />
            <div className="card">
                <div className="card-body pl-0">
                <div className="table-responsive table-dta">
                    <table className="table mb-0">
                    <tbody>
                        <tr>
                        <td className="width-6 padding-10 bg-color1">
                            <div className="image-id">
                            <img src="../images/ic-Development/icd-icon1.png" className="img-fluid" alt="forces-icon1" />
                            </div>
                        </td>
                        <td colSpan={3} className="width-20 bg-color1 text-white text-font-size-16">
                            <div className="icd-contetn">
                            Concept Name:
                            </div>
                        </td>
                        <td colSpan={8}>
                            <div className="icd-contetn2">
                                <input type="text" className="form-control inputText"
                                    id="InnovationConceptDevelopmentE11"
                                    value={Inputs.InnovationConceptDevelopmentE11}
                                    onChange={(event) => setInputs({ ...Inputs, InnovationConceptDevelopmentE11: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <td className="width-6 padding-10 bg-color1">
                            <div className="image-id">
                            <img src="../images/ic-Development/icd-icon2.png" className="img-fluid" alt="forces-icon1" />
                            </div>
                        </td>
                        <td colSpan={3} className="width-20 bg-color1 text-white text-font-size-16">
                            <div className="icd-contetn">Target Market / Customer</div>
                        </td>
                        <td colSpan={8}>
                            <div className="icd-contetn2">
                                <input type="text" className="form-control inputText"
                                    id="InnovationConceptDevelopmentE12"
                                    value={Inputs.InnovationConceptDevelopmentE12}
                                    onChange={(event) => setInputs({ ...Inputs, InnovationConceptDevelopmentE12: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <td className="width-6 padding-10 bg-color1">
                            <div className="image-id">
                            <img src="../images/ic-Development/icd-icon3.png" className="img-fluid" alt="forces-icon1" />
                            </div>
                        </td>
                        <td colSpan={3} className="width-20 bg-color1 text-white text-font-size-16">
                            <div className="icd-contetn">Problem to Be Solved</div>
                        </td>
                        <td colSpan={8}>
                            <div className="icd-contetn2">
                                <input type="text" className="form-control inputText"
                                    id="InnovationConceptDevelopmentE13"
                                    value={Inputs.InnovationConceptDevelopmentE13}
                                    onChange={(event) => setInputs({ ...Inputs, InnovationConceptDevelopmentE13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <td className="width-6 padding-10 bg-color1">
                            <div className="image-id">
                            <img src="../images/ic-Development/icd-icon4.png" className="img-fluid" alt="forces-icon1" />
                            </div>
                        </td>
                        <td colSpan={3} className="width-20 bg-color1 text-white text-font-size-16">
                            <div className="icd-contetn">Big Idea Description</div>
                        </td>
                        <td colSpan={8}>
                            <div className="icd-contetn2">
                                <input type="text" className="form-control inputText"
                                    id="InnovationConceptDevelopmentE14"
                                    value={Inputs.InnovationConceptDevelopmentE14}
                                    onChange={(event) => setInputs({ ...Inputs, InnovationConceptDevelopmentE14: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <td className="width-6 padding-10 bg-color1">
                            <div className="image-id">
                            <img src="../images/ic-Development/icd-icon5.png" className="img-fluid" alt="forces-icon1" />
                            </div>
                        </td>
                        <td colSpan={3} className="width-20 bg-color1 text-white text-font-size-16">
                            <div className="icd-contetn">Value Proposition</div>
                        </td>
                        <td colSpan={8}>
                            <div className="icd-contetn2">
                                <input type="text" className="form-control inputText"
                                    id="InnovationConceptDevelopmentE15"
                                    value={Inputs.InnovationConceptDevelopmentE15}
                                    onChange={(event) => setInputs({ ...Inputs, InnovationConceptDevelopmentE15: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <td className="width-6 padding-10 bg-color1">
                            <div className="image-id">
                            <img src="../images/ic-Development/icd-icon6.png" className="img-fluid" alt="forces-icon1" />
                            </div>
                        </td>
                        <td colSpan={3} className="width-20 bg-color1 text-white text-font-size-16">
                            <div className="icd-contetn">Risks / Barriers</div>
                        </td>
                        <td colSpan={8}>
                            <div className="icd-contetn2">
                                <input type="text" className="form-control inputText"
                                    id="InnovationConceptDevelopmentE16"
                                    value={Inputs.InnovationConceptDevelopmentE16}
                                    onChange={(event) => setInputs({ ...Inputs, InnovationConceptDevelopmentE16: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <td className="width-6 padding-10 bg-color1">
                            <div className="image-id">
                            <img src="../images/ic-Development/icd-icon7.png" className="img-fluid" alt="forces-icon1" />
                            </div>
                        </td>
                        <td colSpan={3} className="width-20 bg-color1 text-white text-font-size-16">
                            <div className="icd-contetn">Time Frame</div>
                        </td>
                        <td colSpan={8}>
                            <div className="icd-contetn2">
                                <input type="text" className="form-control inputText"
                                    id="InnovationConceptDevelopmentE17"
                                    value={Inputs.InnovationConceptDevelopmentE17}
                                    onChange={(event) => setInputs({ ...Inputs, InnovationConceptDevelopmentE17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <td className="width-6 padding-10 bg-color1">
                            <div className="image-id">
                            <img src="../images/ic-Development/icd-icon8.png" className="img-fluid" alt="forces-icon1" />
                            </div>
                        </td>
                        <td colSpan={3} className="width-20 bg-color1 text-white text-font-size-16">
                            <div className="icd-contetn">Launch Date</div>
                        </td>
                        <td colSpan={8}>
                            <div className="icd-contetn2">
                                <input type="text" className="form-control inputText"
                                    id="InnovationConceptDevelopmentE18"
                                    value={Inputs.InnovationConceptDevelopmentE18}
                                    onChange={(event) => setInputs({ ...Inputs, InnovationConceptDevelopmentE18: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={10} className="width-100 bg-color3 text-center-c padding-15 text-white text-font-size-16">
                            Opportunity Assessment
                        </td>
                        </tr>
                        <tr>
                        <td className="width-6 padding-10 bg-color1">
                            <div className="image-id">
                            <img src="../images/ic-Development/icd-icon9.png" className="img-fluid" alt="forces-icon1" />
                            </div>
                        </td>
                        <td colSpan={3} className="width-20 bg-color1 text-white text-font-size-16">
                            <div className="icd-contetn">Business Impact</div>
                        </td>
                        <td colSpan={8} className="text-font-size-16">
                            <div className="icd-contetn2"> Does this meet a big customer or market need?  Does this help us achieve our vision and strategy?  (1=low; 10=high) </div>
                        </td>
                        <td className=" padding-0">
                            <form className="Focused-planning-f ic-Development-inp">
                                <input type="text" className="fucus-pla"
                                        id="InnovationConceptDevelopmentF20"
                                        value={Inputs.InnovationConceptDevelopmentF20}
                                        onChange={(event) => setInputs({ ...Inputs, InnovationConceptDevelopmentF20 : event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                        placeholder="0"
                                        maxLength="2"
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                                alert.show('Please Enter number between 1 to 10.')
                                            }
                                            }}/>
                            </form>
                        </td>
                        </tr>
                        <tr>
                        <td className="width-6 padding-10 bg-color1">
                            <div className="image-id">
                            <img src="../images/ic-Development/icd-icon10.png" className="img-fluid" alt="forces-icon1" />
                            </div>
                        </td>
                        <td colSpan={3} className="width-20 bg-color1 text-white text-font-size-16">
                            <div className="icd-contetn">Barriers to Implementation</div>
                        </td>
                        <td colSpan={8} className="text-font-size-16">
                            <div className="icd-contetn2"> Is the technology available and the market ready?  Do we have the capabilities and ability to deliver?  (1=low; 10=high) </div>
                        </td>
                        <td className=" padding-0">
                            <form className="Focused-planning-f ic-Development-inp">
                                <input type="text" className="fucus-pla"
                                        id="InnovationConceptDevelopmentF21"
                                        value={Inputs.InnovationConceptDevelopmentF21}
                                        onChange={(event) => setInputs({ ...Inputs, InnovationConceptDevelopmentF21 : event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                        placeholder="0"
                                        maxLength="2"
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                                alert.show('Please Enter number between 1 to 10.')
                                            }
                                        }}/>
                            </form>
                        </td>
                        </tr>
                        <tr>
                        <td className="width-6 padding-10 bg-color1">
                            <div className="image-id">
                            <img src="../images/ic-Development/icd-icon11.png" className="img-fluid" alt="forces-icon1" />
                            </div>
                        </td>
                        <td colSpan={3} className="width-20 bg-color1 text-white text-font-size-16">
                            <div className="icd-contetn">Size of Opportunity</div>
                        </td>
                        <td colSpan={8} className="text-font-size-16">
                            <div className="icd-contetn2"> What is the revenue potential or value?  How big of an opportunity is this?  (1=low; 10=high) </div>
                        </td>
                        <td className=" padding-0">
                            <form className="Focused-planning-f ic-Development-inp">
                                <input type="text" className="fucus-pla"
                                        id="InnovationConceptDevelopmentF22"
                                        value={Inputs.InnovationConceptDevelopmentF22}
                                        onChange={(event) => setInputs({ ...Inputs, InnovationConceptDevelopmentF22 : event.target.value }) }
                                        onBlur={(event) => { onBlurHandler(event) }}
                                        disabled={isLoading}
                                        placeholder="0"
                                        maxLength="2"
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                                alert.show('Please Enter number between 1 to 10.')
                                            }
                                            }}/>
                            </form>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}
