import sum from "../../utils/sum";
const initialState = {
    customerRetentionLoopE10 : 0,
    customerRetentionLoopF10 : 0,
    customerRetentionLoopG10 : 0,
    customerRetentionLoopH10 : 0,
    customerRetentionLoopI10 : 0,
    customerRetentionLoopJ10 : 0,
    customerRetentionLoopE11 : 0,
    customerRetentionLoopF11 : 0,
    customerRetentionLoopG11 : 0,
    customerRetentionLoopH11 : 0,
    customerRetentionLoopI11 : 0,
    customerRetentionLoopJ11 : 0,
    customerRetentionLoopE12 : 0,
    customerRetentionLoopF12 : 0,
    customerRetentionLoopG12 : 0,
    customerRetentionLoopH12 : 0,
    customerRetentionLoopI12 : 0,
    customerRetentionLoopJ12 : 0,
    customerRetentionLoopE13 : 0,
    customerRetentionLoopF13 : 0,
    customerRetentionLoopG13 : 0,
    customerRetentionLoopH13 : 0,
    customerRetentionLoopI13 : 0,
    customerRetentionLoopJ13 : 0,
    customerRetentionLoopD14 : 0,
    customerRetentionLoopE14 : 0,
    customerRetentionLoopF14 : 0,
    customerRetentionLoopG14 : 0,
    customerRetentionLoopH14 : 0,
    customerRetentionLoopI14 : 0,
    customerRetentionLoopJ14 : 0,
    customerRetentionLoopE18 : 0,
    customerRetentionLoopF18 : 0,
    customerRetentionLoopG18 : 0,
    customerRetentionLoopH18 : 0,
    customerRetentionLoopI18 : 0,
    customerRetentionLoopJ18 : 0,
    customerRetentionLoopE19 : 0,
    customerRetentionLoopF19 : 0,
    customerRetentionLoopG19 : 0,
    customerRetentionLoopH19 : 0,
    customerRetentionLoopI19 : 0,
    customerRetentionLoopJ19 : 0,
    customerRetentionLoopE20 : 0,
    customerRetentionLoopF20 : 0,
    customerRetentionLoopG20 : 0,
    customerRetentionLoopH20 : 0,
    customerRetentionLoopI20 : 0,
    customerRetentionLoopJ20 : 0,
}
const reducer = (state = initialState, action) => {
    if(action.type === "CustomerRetentionLoopAllStats") {
        const clientInputsH41 = action.payload.clientInputsH41;
        const CustomerFinancialValuesD16 = action.payload.CustomerFinancialValuesD16;
        const CustomerFinancialValuesD19 = action.payload.CustomerFinancialValuesD19;
        const { customerReactivationD18, customerReactivationE18, customerReactivationF18, customerReactivationG18, customerReactivationH18, customerReactivationI18} = action.payload.customerReactivation  
        const { purchaseFrequencyD12, purchaseFrequencyD18, purchaseFrequencyE18, purchaseFrequencyF18, purchaseFrequencyG18, purchaseFrequencyH18, purchaseFrequencyI18 } = action.payload.purchaseFrequency
        const { buyingLifeTimeD19, buyingLifeTimeE19, buyingLifeTimeF19, buyingLifeTimeG19, buyingLifeTimeH19, buyingLifeTimeI19 } = action.payload.buyingLifeTime
        const { reducingAttritionD26, reducingAttritionE26, reducingAttritionF26, reducingAttritionG26, reducingAttritionH26, reducingAttritionI26 } = action.payload.reducingAttrition
        
        let customerRetentionLoopE10 = parseFloat(reducingAttritionD26).toFixed(2);
        let customerRetentionLoopF10 = parseFloat(reducingAttritionE26).toFixed(2);
        let customerRetentionLoopG10 = parseFloat(reducingAttritionF26).toFixed(2);
        let customerRetentionLoopH10 = parseFloat(reducingAttritionG26).toFixed(2);
        let customerRetentionLoopI10 = parseFloat(reducingAttritionH26).toFixed(2);
        let customerRetentionLoopJ10 = parseFloat(reducingAttritionI26).toFixed(2);

        let customerRetentionLoopE11 = parseFloat(buyingLifeTimeD19).toFixed(2);
        let customerRetentionLoopF11 = parseFloat(buyingLifeTimeE19).toFixed(2);
        let customerRetentionLoopG11 = parseFloat(buyingLifeTimeF19).toFixed(2);
        let customerRetentionLoopH11 = parseFloat(buyingLifeTimeG19).toFixed(2);
        let customerRetentionLoopI11 = parseFloat(buyingLifeTimeH19).toFixed(2);
        let customerRetentionLoopJ11 = parseFloat(buyingLifeTimeI19).toFixed(2);

        let customerRetentionLoopE12 = parseFloat(customerReactivationD18).toFixed(2);
        let customerRetentionLoopF12 = parseFloat(customerReactivationE18).toFixed(2);
        let customerRetentionLoopG12 = parseFloat(customerReactivationF18).toFixed(2);
        let customerRetentionLoopH12 = parseFloat(customerReactivationG18).toFixed(2);
        let customerRetentionLoopI12 = parseFloat(customerReactivationH18).toFixed(2);
        let customerRetentionLoopJ12 = parseFloat(customerReactivationI18).toFixed(2);

        let customerRetentionLoopE13 = sum(customerRetentionLoopE10, customerRetentionLoopE11, customerRetentionLoopE12).toFixed(2);
        let customerRetentionLoopF13 = sum(customerRetentionLoopF10, customerRetentionLoopF11, customerRetentionLoopF12).toFixed(2);
        let customerRetentionLoopG13 = sum(customerRetentionLoopG10, customerRetentionLoopG11, customerRetentionLoopG12).toFixed(2);
        let customerRetentionLoopH13 = sum(customerRetentionLoopH10, customerRetentionLoopH11, customerRetentionLoopH12).toFixed(2);
        let customerRetentionLoopI13 = sum(customerRetentionLoopI10, customerRetentionLoopI11, customerRetentionLoopI12).toFixed(2);
        let customerRetentionLoopJ13 = sum(customerRetentionLoopJ10, customerRetentionLoopJ11, customerRetentionLoopJ12).toFixed(2);

        let customerRetentionLoopD14 = parseFloat(purchaseFrequencyD12).toFixed(2);
        let customerRetentionLoopE14 = parseFloat(purchaseFrequencyD18).toFixed(2);
        let customerRetentionLoopF14 = parseFloat(purchaseFrequencyE18).toFixed(2);
        let customerRetentionLoopG14 = parseFloat(purchaseFrequencyF18).toFixed(2);
        let customerRetentionLoopH14 = parseFloat(purchaseFrequencyG18).toFixed(2);
        let customerRetentionLoopI14 = parseFloat(purchaseFrequencyH18).toFixed(2);
        let customerRetentionLoopJ14 = parseFloat(purchaseFrequencyI18).toFixed(2);

        let customerRetentionLoopE18 = (parseFloat(customerRetentionLoopE13) * parseFloat(customerRetentionLoopE14) * parseFloat(CustomerFinancialValuesD16)).toFixed(2);
        let customerRetentionLoopF18 = (parseFloat(customerRetentionLoopF13) * parseFloat(customerRetentionLoopF14) * parseFloat(CustomerFinancialValuesD16)).toFixed(2);
        let customerRetentionLoopG18 = (parseFloat(customerRetentionLoopG13) * parseFloat(customerRetentionLoopG14) * parseFloat(CustomerFinancialValuesD16)).toFixed(2);
        let customerRetentionLoopH18 = (parseFloat(customerRetentionLoopH13) * parseFloat(customerRetentionLoopH14) * parseFloat(CustomerFinancialValuesD16)).toFixed(2);
        let customerRetentionLoopI18 = (parseFloat(customerRetentionLoopI13) * parseFloat(customerRetentionLoopI14) * parseFloat(CustomerFinancialValuesD16)).toFixed(2);
        let customerRetentionLoopJ18 = (parseFloat(customerRetentionLoopJ13) * parseFloat(customerRetentionLoopJ14) * parseFloat(CustomerFinancialValuesD16)).toFixed(2);

        let customerRetentionLoopE19 = (parseFloat(customerRetentionLoopE13) * parseFloat(customerRetentionLoopE14) * parseFloat(CustomerFinancialValuesD16) * parseFloat(CustomerFinancialValuesD19) / 100).toFixed(2);
        let customerRetentionLoopF19 = (parseFloat(customerRetentionLoopF18) * parseFloat(CustomerFinancialValuesD19) / 100).toFixed(2);
        let customerRetentionLoopG19 = (parseFloat(customerRetentionLoopG18) * parseFloat(CustomerFinancialValuesD19) / 100).toFixed(2);
        let customerRetentionLoopH19 = (parseFloat(customerRetentionLoopH18) * parseFloat(CustomerFinancialValuesD19) / 100).toFixed(2);
        let customerRetentionLoopI19 = (parseFloat(customerRetentionLoopI18) * parseFloat(CustomerFinancialValuesD19) / 100).toFixed(2);
        let customerRetentionLoopJ19 = (parseFloat(customerRetentionLoopJ18) * parseFloat(CustomerFinancialValuesD19) / 100).toFixed(2);

        let customerRetentionLoopE20 = (parseFloat(customerRetentionLoopE19) * parseFloat(clientInputsH41)).toFixed(2);
        let customerRetentionLoopF20 = (parseFloat(customerRetentionLoopF19) * parseFloat(clientInputsH41)).toFixed(2);
        let customerRetentionLoopG20 = (parseFloat(customerRetentionLoopG19) * parseFloat(clientInputsH41)).toFixed(2);
        let customerRetentionLoopH20 = (parseFloat(customerRetentionLoopH19) * parseFloat(clientInputsH41)).toFixed(2);
        let customerRetentionLoopI20 = (parseFloat(customerRetentionLoopI19) * parseFloat(clientInputsH41)).toFixed(2);
        let customerRetentionLoopJ20 = (parseFloat(customerRetentionLoopJ19) * parseFloat(clientInputsH41)).toFixed(2);

        return {
            ...state,
            customerRetentionLoopE10 : (customerRetentionLoopE10 != 'NaN') ? customerRetentionLoopE10 : 0,
            customerRetentionLoopF10 : (customerRetentionLoopF10 != 'NaN') ? customerRetentionLoopF10 : 0,
            customerRetentionLoopG10 : (customerRetentionLoopG10 != 'NaN') ? customerRetentionLoopG10 : 0,
            customerRetentionLoopH10 : (customerRetentionLoopH10 != 'NaN') ? customerRetentionLoopH10 : 0,
            customerRetentionLoopI10 : (customerRetentionLoopI10 != 'NaN') ? customerRetentionLoopI10 : 0,
            customerRetentionLoopJ10 : (customerRetentionLoopJ10 != 'NaN') ? customerRetentionLoopJ10 : 0,
            customerRetentionLoopE11 : (customerRetentionLoopE11 != 'NaN') ? customerRetentionLoopE11 : 0,
            customerRetentionLoopF11 : (customerRetentionLoopF11 != 'NaN') ? customerRetentionLoopF11 : 0,
            customerRetentionLoopG11 : (customerRetentionLoopG11 != 'NaN') ? customerRetentionLoopG11 : 0,
            customerRetentionLoopH11 : (customerRetentionLoopH11 != 'NaN') ? customerRetentionLoopH11 : 0,
            customerRetentionLoopI11 : (customerRetentionLoopI11 != 'NaN') ? customerRetentionLoopI11 : 0,
            customerRetentionLoopJ11 : (customerRetentionLoopJ11 != 'NaN') ? customerRetentionLoopJ11 : 0,
            customerRetentionLoopE12 : (customerRetentionLoopE12 != 'NaN') ? customerRetentionLoopE12 : 0,
            customerRetentionLoopF12 : (customerRetentionLoopF12 != 'NaN') ? customerRetentionLoopF12 : 0,
            customerRetentionLoopG12 : (customerRetentionLoopG12 != 'NaN') ? customerRetentionLoopG12 : 0,
            customerRetentionLoopH12 : (customerRetentionLoopH12 != 'NaN') ? customerRetentionLoopH12 : 0,
            customerRetentionLoopI12 : (customerRetentionLoopI12 != 'NaN') ? customerRetentionLoopI12 : 0,
            customerRetentionLoopJ12 : (customerRetentionLoopJ12 != 'NaN') ? customerRetentionLoopJ12 : 0,
            customerRetentionLoopE13 : (customerRetentionLoopE13 != 'NaN') ? customerRetentionLoopE13 : 0,
            customerRetentionLoopF13 : (customerRetentionLoopF13 != 'NaN') ? customerRetentionLoopF13 : 0,
            customerRetentionLoopG13 : (customerRetentionLoopG13 != 'NaN') ? customerRetentionLoopG13 : 0,
            customerRetentionLoopH13 : (customerRetentionLoopH13 != 'NaN') ? customerRetentionLoopH13 : 0,
            customerRetentionLoopI13 : (customerRetentionLoopI13 != 'NaN') ? customerRetentionLoopI13 : 0,
            customerRetentionLoopJ13 : (customerRetentionLoopJ13 != 'NaN') ? customerRetentionLoopJ13 : 0,
            customerRetentionLoopD14 : (customerRetentionLoopD14 != 'NaN') ? customerRetentionLoopD14 : 0,
            customerRetentionLoopE14 : (customerRetentionLoopE14 != 'NaN') ? customerRetentionLoopE14 : 0,
            customerRetentionLoopF14 : (customerRetentionLoopF14 != 'NaN') ? customerRetentionLoopF14 : 0,
            customerRetentionLoopG14 : (customerRetentionLoopG14 != 'NaN') ? customerRetentionLoopG14 : 0,
            customerRetentionLoopH14 : (customerRetentionLoopH14 != 'NaN') ? customerRetentionLoopH14 : 0,
            customerRetentionLoopI14 : (customerRetentionLoopI14 != 'NaN') ? customerRetentionLoopI14 : 0,
            customerRetentionLoopJ14 : (customerRetentionLoopJ14 != 'NaN') ? customerRetentionLoopJ14 : 0,
            customerRetentionLoopE18 : (customerRetentionLoopE18 != 'NaN') ? customerRetentionLoopE18 : 0,
            customerRetentionLoopF18 : (customerRetentionLoopF18 != 'NaN') ? customerRetentionLoopF18 : 0,
            customerRetentionLoopG18 : (customerRetentionLoopG18 != 'NaN') ? customerRetentionLoopG18 : 0,
            customerRetentionLoopH18 : (customerRetentionLoopH18 != 'NaN') ? customerRetentionLoopH18 : 0,
            customerRetentionLoopI18 : (customerRetentionLoopI18 != 'NaN') ? customerRetentionLoopI18 : 0,
            customerRetentionLoopJ18 : (customerRetentionLoopJ18 != 'NaN') ? customerRetentionLoopJ18 : 0,
            customerRetentionLoopE19 : (customerRetentionLoopE19 != 'NaN') ? customerRetentionLoopE19 : 0,
            customerRetentionLoopF19 : (customerRetentionLoopF19 != 'NaN') ? customerRetentionLoopF19 : 0,
            customerRetentionLoopG19 : (customerRetentionLoopG19 != 'NaN') ? customerRetentionLoopG19 : 0,
            customerRetentionLoopH19 : (customerRetentionLoopH19 != 'NaN') ? customerRetentionLoopH19 : 0,
            customerRetentionLoopI19 : (customerRetentionLoopI19 != 'NaN') ? customerRetentionLoopI19 : 0,
            customerRetentionLoopJ19 : (customerRetentionLoopJ19 != 'NaN') ? customerRetentionLoopJ19 : 0,
            customerRetentionLoopE20 : (customerRetentionLoopE20 != 'NaN') ? customerRetentionLoopE20 : 0,
            customerRetentionLoopF20 : (customerRetentionLoopF20 != 'NaN') ? customerRetentionLoopF20 : 0,
            customerRetentionLoopG20 : (customerRetentionLoopG20 != 'NaN') ? customerRetentionLoopG20 : 0,
            customerRetentionLoopH20 : (customerRetentionLoopH20 != 'NaN') ? customerRetentionLoopH20 : 0,
            customerRetentionLoopI20 : (customerRetentionLoopI20 != 'NaN') ? customerRetentionLoopI20 : 0,
            customerRetentionLoopJ20 : (customerRetentionLoopJ20 != 'NaN') ? customerRetentionLoopJ20 : 0,
        }
        
    }
    else {
        return state
    }

}

export default reducer