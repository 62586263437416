import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getVoiceOfTheCustomerTable, modifyVoiceOfTheCustomerTable } from "../../state/features/VoiceOfTheCustomerTableSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';

export default function VoiceOfTheCustomerTableContent() {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        VoiceOfTheCustomerTableG13 : '',
        VoiceOfTheCustomerTableC17 : '',
        VoiceOfTheCustomerTableG17 : '',
        VoiceOfTheCustomerTableL17 : '',
        VoiceOfTheCustomerTableC18 : '',
        VoiceOfTheCustomerTableG18 : '',
        VoiceOfTheCustomerTableL18 : '',
        VoiceOfTheCustomerTableC19 : '',
        VoiceOfTheCustomerTableG19 : '',
        VoiceOfTheCustomerTableL19 : '',
        VoiceOfTheCustomerTableC20 : '',
        VoiceOfTheCustomerTableG20 : '',
        VoiceOfTheCustomerTableL20 : '',
        VoiceOfTheCustomerTableC21 : '',
        VoiceOfTheCustomerTableG21 : '',
        VoiceOfTheCustomerTableL21 : '',
        VoiceOfTheCustomerTableC22 : '',
        VoiceOfTheCustomerTableG22 : '',
        VoiceOfTheCustomerTableL22 : '',
        VoiceOfTheCustomerTableC25 : '',
        VoiceOfTheCustomerTableG25 : '',
        VoiceOfTheCustomerTableL25 : '',
        VoiceOfTheCustomerTableC26 : '',
        VoiceOfTheCustomerTableG26 : '',
        VoiceOfTheCustomerTableL26 : '',
        VoiceOfTheCustomerTableC27 : '',
        VoiceOfTheCustomerTableG27 : '',
        VoiceOfTheCustomerTableL27 : ''
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getVoiceOfTheCustomerTable({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.revenueGrowth)
            if(response!= undefined  && response.data.revenueGrowth){
                let revenueGrowth = response.data.revenueGrowth[0];
                console.log(revenueGrowth)
                delete revenueGrowth.id;
                delete revenueGrowth.user_id;
                delete revenueGrowth.created_at;
                delete revenueGrowth.updated_at;
                const newObj = { ...Inputs }
                for (const key in revenueGrowth) {
                    if (revenueGrowth.hasOwnProperty(key)) {
                        if(revenueGrowth[key])
                        newObj[key] = revenueGrowth[key]
                    }
                }
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyVoiceOfTheCustomerTable({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                  console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

  return (
    <div className="content-body">
        <UserDropdown />
        {/* row */}
       <div className="container-fluid">
            <div className="head-title">
                <p className="mb-0"> Customer Retention, Loyalty &amp; Experience</p>
                <h2 className="mb-1"> Voice of the Customer Table </h2>
                <ul className="list-unstyled">
                <li className="net-page-lit mb-2">
                    The Voice of the Customer Table is a template which helps business leaders and managers frame the inputs received through the process of carrying out a Voice of the Customer (VOC) exercise.   
                </li>
                <li className="net-page-lit mb-2">
                    Data can be gathered as part of a Voice of the Customer exercise in a variety of ways, including through the conducting of surveys and focus groups. These methods can be helpful in validating what an organization already believes to be true. Other methodologies include the Gemba Walk, a visit to listen to and observe actual customers while they are using a product or service to determine what they are doing (or not doing). 
                </li>
                <li className="net-page-lit mb-5">
                    Customers usually don’t explain their needs clearly or articulately, and business leaders and product designers are left to pick up the pieces to understand what the customer really values. That’s where the Voice of the Customer Table comes in. Once business leaders have gathered enough data and prioritized the needs of their customers, they can work to identify product features that will have the greatest impact for those customers.   
                </li>
                </ul>
            </div>
            <Spinner />
            <div className="row">
                <div className="col-xl-12 contentleft">
                <div className="card">
                    <div className="card-body pl-0">
                    {/* table 1 */}
                    <div className="table-responsive table-dta">
                        <table className="table mb-4 customer-table">
                        <thead>
                            <tr>
                            <th colSpan={1} className="bg-color1 text-white">Customer Profile:</th>
                            <th colSpan={2} className="bg-color2 text-white padding-0">
                                <form className="values-form-deve">
                                <input type="text"
                                    id="VoiceOfTheCustomerTableG13"
                                    value={Inputs.VoiceOfTheCustomerTableG13}
                                    onChange={(event) => setInputs({ ...Inputs, VoiceOfTheCustomerTableG13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                    placeholder="Option" style={{borderRadius: 0}}/>
                                </form>
                            </th>
                            </tr>
                        </thead>
                        </table>
                    </div>
                    {/* table 2 */}
                    <div className="table-responsive table-dta">
                        <table className="table mb-0 customer-table">
                        <thead>
                            <tr>
                            <th colSpan={1} className="bg-color1 text-white padding-10">
                                <div className="voice-of-the-main-div">
                                <div className="situation-title">Situation</div>
                                <img src="../images/voice-of-the-customer/situation.png" className="img-fluid" alt="situation" />
                                </div>
                            </th>
                            <th colSpan={1} className="bg-color1 text-white padding-10">
                                <div className="voice-of-the-main-div">
                                <div className="situation-title">Voice of Customer (VOC)</div>
                                <img src="../images/voice-of-the-customer/voice.png" className="img-fluid" alt="voice" />
                                </div>
                            </th>
                            <th colSpan={1} className="bg-color1 text-white padding-10">
                                <div className="voice-of-the-main-div">
                                <div className="situation-title">Restated as Customer Need</div>
                                <img src="../images/voice-of-the-customer/customer.png" className="img-fluid" alt="customer" />
                                </div>
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td className="bg-color3 text-white">What is the current issue?</td>
                            <td className="bg-color3 text-white">What is the stated problem or question?</td>
                            <td className="bg-color3 text-white">What is the underlying customer needed?</td>
                            </tr>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="VoiceOfTheCustomerTableC17"
                                    value={Inputs.VoiceOfTheCustomerTableC17}
                                    onChange={(event) => setInputs({ ...Inputs, VoiceOfTheCustomerTableC17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="VoiceOfTheCustomerTableG17"
                                    value={Inputs.VoiceOfTheCustomerTableG17}
                                    onChange={(event) => setInputs({ ...Inputs, VoiceOfTheCustomerTableG17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="VoiceOfTheCustomerTableL17"
                                    value={Inputs.VoiceOfTheCustomerTableL17}
                                    onChange={(event) => setInputs({ ...Inputs, VoiceOfTheCustomerTableL17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="VoiceOfTheCustomerTableC18"
                                    value={Inputs.VoiceOfTheCustomerTableC18}
                                    onChange={(event) => setInputs({ ...Inputs, VoiceOfTheCustomerTableC18: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="VoiceOfTheCustomerTableG18"
                                    value={Inputs.VoiceOfTheCustomerTableG18}
                                    onChange={(event) => setInputs({ ...Inputs, VoiceOfTheCustomerTableG18: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="VoiceOfTheCustomerTableL18"
                                    value={Inputs.VoiceOfTheCustomerTableL18}
                                    onChange={(event) => setInputs({ ...Inputs, VoiceOfTheCustomerTableL18: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="VoiceOfTheCustomerTableC19"
                                    value={Inputs.VoiceOfTheCustomerTableC19}
                                    onChange={(event) => setInputs({ ...Inputs, VoiceOfTheCustomerTableC19: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="VoiceOfTheCustomerTableG19"
                                    value={Inputs.VoiceOfTheCustomerTableG19}
                                    onChange={(event) => setInputs({ ...Inputs, VoiceOfTheCustomerTableG19: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="VoiceOfTheCustomerTableL19"
                                    value={Inputs.VoiceOfTheCustomerTableL19}
                                    onChange={(event) => setInputs({ ...Inputs, VoiceOfTheCustomerTableL19: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="VoiceOfTheCustomerTableC20"
                                    value={Inputs.VoiceOfTheCustomerTableC20}
                                    onChange={(event) => setInputs({ ...Inputs, VoiceOfTheCustomerTableC20: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="VoiceOfTheCustomerTableG20"
                                    value={Inputs.VoiceOfTheCustomerTableG20}
                                    onChange={(event) => setInputs({ ...Inputs, VoiceOfTheCustomerTableG20: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="VoiceOfTheCustomerTableL20"
                                    value={Inputs.VoiceOfTheCustomerTableL20}
                                    onChange={(event) => setInputs({ ...Inputs, VoiceOfTheCustomerTableL20: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="VoiceOfTheCustomerTableC21"
                                    value={Inputs.VoiceOfTheCustomerTableC21}
                                    onChange={(event) => setInputs({ ...Inputs, VoiceOfTheCustomerTableC21: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="VoiceOfTheCustomerTableG21"
                                    value={Inputs.VoiceOfTheCustomerTableG21}
                                    onChange={(event) => setInputs({ ...Inputs, VoiceOfTheCustomerTableG21: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="VoiceOfTheCustomerTableL21"
                                    value={Inputs.VoiceOfTheCustomerTableL21}
                                    onChange={(event) => setInputs({ ...Inputs, VoiceOfTheCustomerTableL21: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="VoiceOfTheCustomerTableC22"
                                    value={Inputs.VoiceOfTheCustomerTableC22}
                                    onChange={(event) => setInputs({ ...Inputs, VoiceOfTheCustomerTableC22: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="VoiceOfTheCustomerTableG22"
                                    value={Inputs.VoiceOfTheCustomerTableG22}
                                    onChange={(event) => setInputs({ ...Inputs, VoiceOfTheCustomerTableG22: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="VoiceOfTheCustomerTableL22"
                                    value={Inputs.VoiceOfTheCustomerTableL22}
                                    onChange={(event) => setInputs({ ...Inputs, VoiceOfTheCustomerTableL22: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    {/* table 3 */}
                    <div className="table-responsive table-dta">
                        <table className="table mb-0 mt-4 customer-table">
                        <thead>
                            <tr>
                            <th colSpan={1} className="bg-color3 text-white padding-10">
                                <div className="voice-of-the-main-div">
                                <div className="situation-title">Implications &amp; Opportunities</div>
                                </div>
                            </th>
                            <th colSpan={1} className="bg-color3 text-white padding-10">
                                <div className="voice-of-the-main-div">
                                <div className="situation-title">Implications &amp; Opportunities</div>
                                </div>
                            </th>
                            <th colSpan={1} className="bg-color3 text-white padding-10">
                                <div className="voice-of-the-main-div">
                                <div className="situation-title">Implications &amp; Opportunities</div>
                                </div>
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="VoiceOfTheCustomerTableC25"
                                    value={Inputs.VoiceOfTheCustomerTableC25}
                                    onChange={(event) => setInputs({ ...Inputs, VoiceOfTheCustomerTableC25: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="VoiceOfTheCustomerTableG25"
                                    value={Inputs.VoiceOfTheCustomerTableG25}
                                    onChange={(event) => setInputs({ ...Inputs, VoiceOfTheCustomerTableG25: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="VoiceOfTheCustomerTableL25"
                                    value={Inputs.VoiceOfTheCustomerTableL25}
                                    onChange={(event) => setInputs({ ...Inputs, VoiceOfTheCustomerTableL25: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="VoiceOfTheCustomerTableC26"
                                    value={Inputs.VoiceOfTheCustomerTableC26}
                                    onChange={(event) => setInputs({ ...Inputs, VoiceOfTheCustomerTableC26: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="VoiceOfTheCustomerTableG26"
                                    value={Inputs.VoiceOfTheCustomerTableG26}
                                    onChange={(event) => setInputs({ ...Inputs, VoiceOfTheCustomerTableG26: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="VoiceOfTheCustomerTableL26"
                                    value={Inputs.VoiceOfTheCustomerTableL26}
                                    onChange={(event) => setInputs({ ...Inputs, VoiceOfTheCustomerTableL26: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="VoiceOfTheCustomerTableC27"
                                    value={Inputs.VoiceOfTheCustomerTableC27}
                                    onChange={(event) => setInputs({ ...Inputs, VoiceOfTheCustomerTableC27: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="VoiceOfTheCustomerTableG27"
                                    value={Inputs.VoiceOfTheCustomerTableG27}
                                    onChange={(event) => setInputs({ ...Inputs, VoiceOfTheCustomerTableG27: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            <td className="bg-color2">
                                <input type="text" className="inputText"
                                    id="VoiceOfTheCustomerTableL27"
                                    value={Inputs.VoiceOfTheCustomerTableL27}
                                    onChange={(event) => setInputs({ ...Inputs, VoiceOfTheCustomerTableL27: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading} />
                            </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>                                          
                </div>
            </div>
        </div>
    </div>
  )
}
