import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import  UserDropdown  from '../UserDropdown';
import { useSelector, useDispatch } from 'react-redux';

import { getDifferentiationDiagnostic, modifyDifferentiationDiagnostic } from "../../state/features/DifferentiationDiagnosticSlice";
import { userData } from "../../state/features/AuthenticationSlice";
import { clearMessage } from "../../state/features/Message";
import { Spinner } from '../spinner';

export default function DifferentiationDiagnosticContent() {

    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const { message } = useSelector((state) => state.message);
    const users = useSelector(userData);
    const user_id = users.data.profile.id;
    const [Inputs, setInputs] = useState({
        DifferentiationDiagnosticD10 : '',
        DifferentiationDiagnosticD11 : '',
        DifferentiationDiagnosticD12 : '',
        DifferentiationDiagnosticD13 : '',
        DifferentiationDiagnosticD14 : '',
        DifferentiationDiagnosticD15 : '',
        DifferentiationDiagnosticD16 : '',
        DifferentiationDiagnosticD17 : '',
        DifferentiationDiagnosticD18 : '',
        DifferentiationDiagnosticD19 : '',
        DifferentiationDiagnosticG10 : '',
        DifferentiationDiagnosticG11 : '',
        DifferentiationDiagnosticG12 : '',
        DifferentiationDiagnosticG13 : '',
        DifferentiationDiagnosticG14 : '',
        DifferentiationDiagnosticG15 : '',
        DifferentiationDiagnosticG16 : '',
        DifferentiationDiagnosticG17 : '',
        DifferentiationDiagnosticG18 : '',
        DifferentiationDiagnosticG19 : '',
    });

    const [Cache, setCache] = useState(Inputs);

    // redux dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(clearMessage());
        dispatch(getDifferentiationDiagnostic({user_id}))
        .unwrap()
        .then((response) => {
          //console.log("response",response.data.growthPlanning)
            if(response!= undefined  && response.data.growthPlanning){
                let growthPlanning = response.data.growthPlanning[0];
                console.log(growthPlanning)
                delete growthPlanning.id;
                delete growthPlanning.user_id;
                delete growthPlanning.created_at;
                delete growthPlanning.updated_at;
                const newObj = { ...Inputs }
                for (const key in growthPlanning) {
                    if (growthPlanning.hasOwnProperty(key)) {
                        if(growthPlanning[key])
                        newObj[key] = growthPlanning[key]
                    }
                }
                setInputs(newObj);
                setCache(newObj);
                setIsLoading(false)   // Hide loading screen 
            }
            else{
                setIsLoading(false)
            }
        })
        .catch((error) => {
          console.log("error",error)
          setIsLoading(false)   // Hide loading screen 
            { message && alert.show(message) }
        });

      }, [dispatch])

    const onBlurHandler = (event) => {
        if (Cache.hasOwnProperty(event.target.id)) {
            if(Cache[event.target.id] !== event.target.value){
                dispatch(modifyDifferentiationDiagnostic({ user_id, [event.target.id] : event.target.value}))
                .unwrap()
                .then((response) => {
                    // console.log("response",response)
                    if(response!= undefined && response.status && response.status == 401){
                        console.log('session expired...')
                        //window.location.reload()
                    }
                    else if(response!= undefined){
                        console.log('success')
                        setCache({ ...Cache, [event.target.id] : event.target.value});
                    }
                })
                .catch((error) => {
                  console.log("error",error.status)
                    { message && alert.show(message) }
                });
            }
        }    
    }

  return (
    <div className="content-body">
        <UserDropdown />
        {/* row */}
        <div className="container-fluid">
            <div className="head-title bigsout">
                <h2>Differentiation Diagnostic</h2>  
                <p>You may think your offering is unique—but how many of your competitors can make the same claims about their products? That is known as an “equally credible claim.” In the left column, write down the claims your business makes. In the right column, research or consider your competitors. Do they make the same claims that you do? If so, cross out that claim. The claims that they DON’T make are what you need to bring to your customers’ attention.</p>   		
            </div>
            <Spinner />
            <div className="row differnt">
                <div className="col-md-6">
                <div className="card">
                    <div className="card-body pl-0">
                    <div className="table-responsive">
                        <table className="table">
                        <thead>
                            <tr className="thead-primary">
                            <th colSpan={2}>Your Claims</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td>1</td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="DifferentiationDiagnosticD10"
                                    value={Inputs.DifferentiationDiagnosticD10}
                                    onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticD10: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            </tr>
                            <tr>
                            <td>2</td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="DifferentiationDiagnosticD11"
                                    value={Inputs.DifferentiationDiagnosticD11}
                                    onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticD11: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            </tr>
                            <tr>
                            <td>3</td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="DifferentiationDiagnosticD12"
                                    value={Inputs.DifferentiationDiagnosticD12}
                                    onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticD12: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            </tr>
                            <tr>
                            <td>4</td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="DifferentiationDiagnosticD13"
                                    value={Inputs.DifferentiationDiagnosticD13}
                                    onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticD13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            </tr>
                            <tr>
                            <td>5</td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="DifferentiationDiagnosticD14"
                                    value={Inputs.DifferentiationDiagnosticD14}
                                    onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticD14: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            </tr>
                            <tr>
                            <td>6</td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="DifferentiationDiagnosticD15"
                                    value={Inputs.DifferentiationDiagnosticD15}
                                    onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticD15: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            </tr>
                            <tr>
                            <td>7</td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="DifferentiationDiagnosticD16"
                                    value={Inputs.DifferentiationDiagnosticD16}
                                    onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticD16: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            </tr>
                            <tr>
                            <td>8</td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="DifferentiationDiagnosticD17"
                                    value={Inputs.DifferentiationDiagnosticD17}
                                    onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticD17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            </tr>
                            <tr>
                            <td>9</td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="DifferentiationDiagnosticD18"
                                    value={Inputs.DifferentiationDiagnosticD18}
                                    onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticD18: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            </tr>
                            <tr>
                            <td>10</td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="DifferentiationDiagnosticD19"
                                    value={Inputs.DifferentiationDiagnosticD19}
                                    onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticD19: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>
                </div>
                <div className="col-md-6">
                <div className="card">
                    <div className="card-body pl-0">
                    <div className="table-responsive">
                        <table className="table">
                        <thead>
                            <tr className="thead-primary">
                            <th colSpan={2}>Who Else Makes That Claim?</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td>1</td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="DifferentiationDiagnosticG10"
                                    value={Inputs.DifferentiationDiagnosticG10}
                                    onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticG10: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            </tr>
                            <tr>
                            <td>2</td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="DifferentiationDiagnosticG11"
                                    value={Inputs.DifferentiationDiagnosticG11}
                                    onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticG11: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            </tr>
                            <tr>
                            <td>3</td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="DifferentiationDiagnosticG12"
                                    value={Inputs.DifferentiationDiagnosticG12}
                                    onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticG12: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            </tr>
                            <tr>
                            <td>4</td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="DifferentiationDiagnosticG13"
                                    value={Inputs.DifferentiationDiagnosticG13}
                                    onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticG13: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            </tr>
                            <tr>
                            <td>5</td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="DifferentiationDiagnosticG14"
                                    value={Inputs.DifferentiationDiagnosticG14}
                                    onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticG14: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            </tr>
                            <tr>
                            <td>6</td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="DifferentiationDiagnosticG15"
                                    value={Inputs.DifferentiationDiagnosticG15}
                                    onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticG15: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            </tr>
                            <tr>
                            <td>7</td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="DifferentiationDiagnosticG16"
                                    value={Inputs.DifferentiationDiagnosticG16}
                                    onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticG16: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            </tr>
                            <tr>
                            <td>8</td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="DifferentiationDiagnosticG17"
                                    value={Inputs.DifferentiationDiagnosticG17}
                                    onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticG17: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            </tr>
                            <tr>
                            <td>9</td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="DifferentiationDiagnosticG18"
                                    value={Inputs.DifferentiationDiagnosticG18}
                                    onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticG18: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            </tr>
                            <tr>
                            <td>10</td>
                            <td>
                                <input type="text" className="form-control inputText"
                                    id="DifferentiationDiagnosticG19"
                                    value={Inputs.DifferentiationDiagnosticG19}
                                    onChange={(event) => setInputs({ ...Inputs, DifferentiationDiagnosticG19: event.target.value }) }
                                    onBlur={(event) => { onBlurHandler(event) }}
                                    disabled={isLoading}
                                />
                            </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="row differnt">
                <div className="col-md-6">
                <div className="card">
                    <div className="card-body pl-0">
                    <div className="table-responsive">
                        <table className="table">
                        <thead>
                            <tr className="thead-primary">
                            <th colSpan={2}>Potential Differentiators</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td>1</td>
                            <td>Performance Leadership</td>
                            </tr>
                            <tr>
                            <td>2</td>
                            <td>Design</td>
                            </tr>
                            <tr>
                            <td>3</td>
                            <td>How Product is Made</td>
                            </tr>
                            <tr>
                            <td>4</td>
                            <td>Where Product is Made</td>
                            </tr>
                            <tr>
                            <td>5</td>
                            <td>Specialization</td>
                            </tr>
                            <tr>
                            <td>6</td>
                            <td>Technology Leadership</td>
                            </tr>
                            <tr>
                            <td>7</td>
                            <td>Next Generation Product</td>
                            </tr>
                            <tr>
                            <td>8</td>
                            <td>Personality</td>
                            </tr>
                            <tr>
                            <td>9</td>
                            <td>Attribute Ownership</td>
                            </tr>
                            <tr>
                            <td>10</td>
                            <td>Sales Leadership</td>
                            </tr>
                            <tr>
                            <td>11</td>
                            <td>Heritage</td>
                            </tr>
                            <tr>
                            <td>12</td>
                            <td>Preference</td>
                            </tr>
                            <tr>
                            <td>13</td>
                            <td>The Opposite Position</td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>
                </div>
                <div className="col-md-6">
                <div className="card">
                    <div className="card-body pl-0">
                    <div className="table-responsive">
                        <table className="table">
                        <thead>
                            <tr className="thead-primary">
                            <th colSpan={2}>These Are NOT Differentiators</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td>1</td>
                            <td>Quality</td>
                            </tr>
                            <tr>
                            <td>2</td>
                            <td>Service</td>
                            </tr>
                            <tr>
                            <td>3</td>
                            <td>Dependability</td>
                            </tr>
                            <tr>
                            <td>4</td>
                            <td>Your People</td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}
